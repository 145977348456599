/// <reference path="../../../typings/browser.d.ts" />
import { BuilderDocument, EditableField, Layer, Section, SimpleEditorField } from '@deltasierra/shared';

export class BuilderEditor {
    public static readonly SID = 'BuilderEditor';

    public fields: SimpleEditorField[] = [];

    public addField(
        layer: BuilderDocument | Layer | Section,
        editableField: EditableField,
        currentValue: unknown,
    ): void {
        const fieldComponent: SimpleEditorField = {
            config: editableField,
            layer,
            value: currentValue,
        };
        this.fields.push(fieldComponent);
    }

    public deleteFieldsForLayer(layerId: number): void {
        this.fields = this.fields.filter(field => field.config.layerId !== layerId);
    }

    public deleteField(
        layerId: number,
        path: string,
        control: string,
    ): { deletedField: SimpleEditorField; index: number } | null {
        const index = this.fields.findIndex(
            field => field.config.layerId === layerId && field.config.path === path && field.config.control === control,
        );

        if (index < 0) {
            return null;
        } else {
            const deletedField = this.fields[index];
            this.fields.splice(index, 1);
            return { deletedField, index };
        }
    }

    public deleteTemplateField(
        path: string,
        control: string,
    ): { deletedField: SimpleEditorField; index: number } | null {
        let deletedField = null;
        const index = this.fields.findIndex(field => field.config.path === path && field.config.control === control);
        if (index < 0) {
            return deletedField;
        }
        const deletedFields = this.fields.splice(index, 1);
        deletedField = deletedFields[0];
        return { deletedField, index };
    }

    public hasField(layerId: number, path: string, control: string): boolean {
        return this.fields.some(
            field => field.config.layerId === layerId && field.config.path === path && field.config.control === control,
        );
    }

    public hasTemplateField(path: string, control: string): boolean {
        return this.fields.some(field => field.config.path === path && field.config.control === control);
    }

    public clear(): void {
        this.fields = [];
    }
}

angular.module('app').factory(BuilderEditor.SID, [() => BuilderEditor]);
