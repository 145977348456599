/// <reference path="../../../typings/browser.d.ts" />
import { Untyped } from '@deltasierra/shared';
import { MvIdentity } from '../account/mvIdentity';
import { DataUtils } from '../common/dataUtils';
import { InteractionUtils } from '../common/interactionUtils';
import { I18nService } from '../i18n/i18nService';
import { BaseTryThisCtrl } from './common';
import { TryThisVoteable } from './models';
import { MvTryThis } from './mvTryThis';
import IScope = angular.IScope;
import ILocationService = angular.ILocationService;
import IPromise = angular.IPromise;
import ITimeoutService = angular.ITimeoutService;
import IQService = angular.IQService;

const CHUNK_SIZE = 3;

export class MvTryThisDetailsCtrl extends BaseTryThisCtrl {
    static SID = 'mvTryThisDetailsCtrl';

    tryThisListOffset = 0;

    tryThisListLimit = 10;

    loading = {
        trythis: false,
        trythises: false,
        vote: false,
    };

    tryThisId: number;

    static readonly $inject: string[] = [
        '$q',
        '$scope',
        '$timeout',
        '$routeParams',
        '$location',
        'Slug',
        MvIdentity.SID,
        MvTryThis.SID,
        InteractionUtils.SID,
        DataUtils.SID,
        I18nService.SID,
    ];

    constructor(
        $q: IQService,
        $scope: IScope,
        $timeout: ITimeoutService,
        $routeParams: Untyped,
        $location: ILocationService,
        Slug: Untyped,
        identity: MvIdentity,
        mvTryThis: MvTryThis,
        interactionUtils: InteractionUtils,
        dataUtils: DataUtils,
        public i18nService: I18nService,
    ) {
        super($q, $scope, $timeout, $location, Slug, identity, mvTryThis, interactionUtils, dataUtils, i18nService);
        this.chunkedTryThese = null;
        this.tryThisId = $routeParams.tryThisId;
        void interactionUtils
            .handleRemoteSimple(this, 'retrieve learn article', 'trythis', mvTryThis.getTryThis(this.tryThisId))
            .then(trythis => {
                this.trythis = trythis;
            });
    }

    protected loadTryThisData(): IPromise<any> {
        return this.initTryThisItems();
    }

    private initTryThisItems(): IPromise<void> {
        if (this.location) {
            return this.interactionUtils
                .handleRemoteSimple(
                    this,
                    'retrieve all learn articles',
                    'trythises',
                    this.mvTryThis.getTryThese(
                        this.location.clientId,
                        this.tryThisListLimit,
                        this.tryThisListOffset,
                        undefined,
                    ),
                )
                .then((tsitems: TryThisVoteable[]) => {
                    const filteredTryThisList = this.dataUtils.filterByPredicate(
                        (trythis: TryThisVoteable) => trythis.id != this.tryThisId,
                        tsitems,
                    );

                    this.chunkedTryThese = this.dataUtils.chunk(CHUNK_SIZE, filteredTryThisList);
                });
        } else {
            return this.$q.resolve();
        }
    }
}

angular.module('app').controller(MvTryThisDetailsCtrl.SID, MvTryThisDetailsCtrl);
