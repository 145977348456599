import * as React from 'react';
import { HelpPopper, Loading, Translate } from '@deltasierra/components';
import { SEND_GRID_MINIMUM_RECIPIENT_COUNT } from '@deltasierra/shared';
import { PublishToClubReadyStep1Data } from '../../../PublishToClubReadyStep1/types/publish-to-club-ready-step-1-data';
import { StyledAnchor, StyledDSFormGroup, StyledLabel, StyledDSAlert } from '../../../common';
import { useClubReadyListCounts } from './hooks/use-club-ready-list-counts';
import { ClubReadyListCountList } from './components/ClubReadyListCountList';
import { ClubReadySuppressedCountList } from './components/ClubReadySuppressedCountList';

export enum ClubReadyListCountStates {
    HIDDEN = 'HIDDEN',
    INVALID = 'INVALID',
    VALID = 'VALID',
}

export type ClubReadyCountContainerProps = {
    step1Data: PublishToClubReadyStep1Data;
    locationIds: string[];
    setListCountState: (isValid: ClubReadyListCountStates) => void;
};

export function ClubReadyCountContainer({
    locationIds,
    setListCountState,
    step1Data,
}: ClubReadyCountContainerProps): JSX.Element {
    const {
        error,
        loading,
        maxUserCountLimitReached,
        refetch,
        userCountListTotals,
    } = useClubReadyListCounts(locationIds, step1Data);

    const totalRecipientCount = React.useMemo(() => {
        if (userCountListTotals) {
            return (
                userCountListTotals.active.userCount +
                userCountListTotals.inactive.userCount +
                userCountListTotals.prospects.userCount +
                userCountListTotals.cooledOffProspects.userCount +
                userCountListTotals.custom.userCount
            );
        }
        return null;
    }, [userCountListTotals]);

    const isRecipientCountValid = React.useMemo(
        () => totalRecipientCount !== null && totalRecipientCount >= SEND_GRID_MINIMUM_RECIPIENT_COUNT,
        [totalRecipientCount],
    );

    React.useEffect(() => {
        if (maxUserCountLimitReached) {
            setListCountState(ClubReadyListCountStates.HIDDEN);
        } else if (isRecipientCountValid) {
            setListCountState(ClubReadyListCountStates.VALID);
        } else {
            setListCountState(ClubReadyListCountStates.INVALID);
        }
    }, [isRecipientCountValid, maxUserCountLimitReached, setListCountState]);

    const shouldHideCounts = maxUserCountLimitReached;

    if (loading) {
        return <Loading inline size="small" />;
    }

    if (error || !userCountListTotals) {
        return (
            <StyledAnchor href="#" onClick={refetch}>
                <Translate keyId="COMMON.RETRY" />
            </StyledAnchor>
        );
    }

    return (
        <>
            {!isRecipientCountValid && !maxUserCountLimitReached && (
                <StyledDSAlert severity="error">
                    <Translate
                        keyId="BUILD.PUBLISH.CLUB_READY.SELECTED_LISTS_DOES_NOT_CONTAIN_ENOUGH_USERS_TO_SEND_TO"
                        options={{ count: SEND_GRID_MINIMUM_RECIPIENT_COUNT }}
                    />
                </StyledDSAlert>
            )}
            <StyledDSFormGroup>
                <StyledLabel component="label" variant="inherit">
                    <Translate keyId="BUILD.PUBLISH.CLUB_READY.SEND_TO" />
                    <HelpPopper margin="left" popperIcon="none">
                        <Translate keyId="BUILD.PUBLISH.CLUB_READY.SEND_TO_INFO" />
                    </HelpPopper>
                </StyledLabel>
                <ClubReadyListCountList
                    hideCounts={shouldHideCounts}
                    step1Data={step1Data}
                    userCountListTotals={userCountListTotals}
                />
            </StyledDSFormGroup>
            {!shouldHideCounts && (
                <StyledDSFormGroup>
                    <StyledLabel component="label" variant="inherit">
                        <Translate keyId="BUILD.PUBLISH.CLUB_READY.SELECTED_LISTS_SUMMARY" />
                        <HelpPopper margin="left" popperIcon="none">
                            <Translate keyId="BUILD.PUBLISH.CLUB_READY.SUPPRESSED_LIST_INFO" />
                        </HelpPopper>
                    </StyledLabel>
                    <ClubReadySuppressedCountList totals={userCountListTotals} />
                </StyledDSFormGroup>
            )}
        </>
    );
}

ClubReadyCountContainer.displayName = 'ClubReadyCountContainer';
