import { AgencyOptionsFilter } from '@deltasierra/shared';
import { ExpressionCallback } from '../../common/angularData';
import {
    AngularjsDropdownMultiselectEvents,
    AngularjsDropdownMultiselectExtraSettings,
    AngularjsDropdownMultiselectTranslationTexts,
} from '../../typings/angularjsDropdownMultiselect/angularjsDropdownMultiselect';
import { I18nService } from '../../i18n';
import { DataUtils } from '../../common/dataUtils';
import { MvIdentity } from '../../account/mvIdentity';

interface AgencyOption {
    id: keyof AgencyOptionsFilter;
    label: string;
}

const NUM_AGENCY_OPTIONS = 2;

export class AgencyOptionsPickerController {
    public static SID = 'AgencyOptionsPickerController';

    public static readonly $inject: string[] = [I18nService.SID, DataUtils.SID, MvIdentity.SID];

    public readonly change!: ExpressionCallback<{ agencyOptions: AgencyOptionsFilter }>;

    public onlyLocationDraft: boolean;

    public options: AgencyOption[];

    public selectedOptions: AgencyOption[];

    public readonly preselectedAgencyOptions?: AgencyOptionsFilter;

    public multiselectSettings: AngularjsDropdownMultiselectExtraSettings = {
        checkBoxes: true,
        keyboardControls: true,
        searchField: 'label' as keyof AgencyOption,
        showCheckAll: false,
        showUncheckAll: true,
        smartButtonMaxItems: NUM_AGENCY_OPTIONS,
    };

    public multiselectEvents: AngularjsDropdownMultiselectEvents = {
        onSelectionChanged: () => this.onAgencyOptionsChanged(),
    };

    public multiselectTexts: AngularjsDropdownMultiselectTranslationTexts = {
        buttonDefaultText: this.i18nService.text.build.agencyFilters.title(),
        uncheckAll: this.i18nService.text.common.all(),
    };

    public constructor(
        protected readonly i18nService: I18nService,
        protected readonly dataUtils: DataUtils,
        protected readonly identity: MvIdentity,
    ) {
        this.options = [
            {
                id: 'onlyLocationDraft',
                label: this.i18nService.text.build.agencyFilters.onlyLocationDrafts(),
            },
        ];

        if (identity.isManager()) {
            this.options.push(
                {
                    id: 'hideDraft',
                    label: this.i18nService.text.build.agencyFilters.hideUnpublished(),
                },
                {
                    id: 'hidePublished',
                    label: this.i18nService.text.build.agencyFilters.hidePublished(),
                },
                {
                    id: 'hideMobile',
                    label: this.i18nService.text.build.agencyFilters.hideMobile(),
                },
                {
                    id: 'multiImage',
                    label: this.i18nService.text.build.agencyFilters.isMultiImage(),
                },
                {
                    id: 'onlyMobile',
                    label: this.i18nService.text.build.agencyFilters.onlyMobile(),
                },
                {
                    id: 'allClients',
                    label: this.i18nService.text.build.allClients(),
                },
            );
        }

        if (this.preselectedAgencyOptions) {
            this.selectedOptions = this.populateSelectedOptions(
                this.preselectedAgencyOptions.allClients,
                this.preselectedAgencyOptions.hideDraft,
                this.preselectedAgencyOptions.hidePublished,
            );
            this.onlyLocationDraft = this.preselectedAgencyOptions.onlyLocationDraft;
        } else {
            this.selectedOptions = [];
            this.onlyLocationDraft = false;
        }
    }

    public populateSelectedOptions(allClients: boolean, hideDraft: boolean, hidePublished: boolean): AgencyOption[] {
        const options: AgencyOption[] = [];
        if (allClients) {
            const option = this.dataUtils.findBy('id', this.options, 'allClients');
            if (option) {
                options.push(option);
            }
        }
        if (hideDraft) {
            const option = this.dataUtils.findBy('id', this.options, 'hideDraft');
            if (option) {
                options.push(option);
            }
        }
        if (hideDraft) {
            const option = this.dataUtils.findBy('id', this.options, 'hidePublished');
            if (option) {
                options.push(option);
            }
        }
        return options;
    }

    public convertOptionsToEditableFieldsFilter(options: AgencyOption[]): AgencyOptionsFilter {
        const filter: AgencyOptionsFilter = {
            allClients: false,
            hideDraft: false,
            hideMobile: false,
            hidePublished: false,
            multiImage: false,
            onlyLocationDraft: false,
            onlyMobile: false,
        };
        for (const option of options) {
            filter[option.id] = true;
        }
        return filter;
    }

    public onAgencyOptionsChanged(): void {
        this.onlyLocationDraft = !!this.dataUtils.findBy('id', this.selectedOptions, 'onlyLocationDraft');

        this.change({
            agencyOptions: this.convertOptionsToEditableFieldsFilter(this.selectedOptions),
        });
    }

    public toggleOnlyLocationDraft(): void {
        this.onlyLocationDraft = !this.onlyLocationDraft;

        const locationDraftsOption = this.dataUtils.findBy('id', this.options, 'onlyLocationDraft');

        if (locationDraftsOption) {
            if (this.onlyLocationDraft) {
                this.selectedOptions.push(locationDraftsOption);
            } else {
                this.selectedOptions = this.selectedOptions.filter(option => option.id !== 'onlyLocationDraft');
            }
        }

        this.change({
            agencyOptions: this.convertOptionsToEditableFieldsFilter(this.selectedOptions),
        });
    }
}

angular.module('app').controller(AgencyOptionsPickerController.SID, AgencyOptionsPickerController);
