import { Button } from '@deltasierra/components';
import { t, PostRankings, PostRankingsGroups } from '@deltasierra/shared';
import * as React from 'react';
import { OneWayBinding } from '../../common/angularData';
import { withAngularIntegration } from '../../common/componentUtils/reactComponentRegistration';
import { Translate } from '../../directives/Translate';
import { PlatformLogo } from '../../platforms/components/PlatformLogo';
import { TopFacebookPosts } from './TopFacebookPosts';

export type FacebookTopPostsPanelProps = {
    postRankings: PostRankingsGroups;
};

export const FacebookTopPostsPanel: React.FunctionComponent<FacebookTopPostsPanelProps> = ({ postRankings }) => {
    const hasOrganic = postRankings.organic.reach.length > 0;
    const hasPaid = postRankings.paid.reach.length > 0;
    return (
        <div className="panel panel-primary">
            <div className="panel-heading">
                <PlatformLogo name="facebook" size={32} />
                <h3 className="panel-title">
                    <Translate keyId="REPORT.FACEBOOK_TOP_POSTS" />
                </h3>
            </div>
            <div className="panel-body">
                <div className="facebook-top-posts">
                    {hasOrganic && <FacebookTopPostsRow rankings={postRankings.organic} title={t('REPORT.ORGANIC')} />}
                    {hasOrganic && hasPaid && <hr />}
                    {hasPaid && <FacebookTopPostsRow rankings={postRankings.paid} title={t('REPORT.PAID')} />}
                </div>
            </div>
        </div>
    );
};

type FacebookTopPostsRowProps = {
    rankings: PostRankings;
    title: string;
};

const FacebookTopPostsRow: React.FunctionComponent<FacebookTopPostsRowProps> = ({ rankings, title }) => {
    const [limit, ButtonComponent] = usePostLimit();
    return (
        <div className="row">
            {rankings.reach.length > 1 && (
                <div className="row">
                    <ButtonComponent />
                </div>
            )}
            <h3 className="sub-heading">{title}</h3>
            <div className="col-sm-4">
                <TopFacebookPosts limit={limit} posts={rankings.reach} title={t('REPORT.REACHED')} />
            </div>
            <div className="col-sm-4">
                <TopFacebookPosts limit={limit} posts={rankings.consumptions} title={t('REPORT.CLICKED')} />
            </div>
            <div className="col-sm-4">
                <TopFacebookPosts limit={limit} posts={rankings.positiveFeedback} title={t('REPORT.ENGAGEMENT')} />
            </div>
        </div>
    );
};

function usePostLimit() {
    const [isLimited, setLimited] = React.useState(true);
    const max = 3;
    const min = 1;
    const limit = isLimited ? min : max;
    const ButtonComponent = () => (
        <Button className="pull-right expand-button hide-printing" size="sm" theme="primary" onClick={() => setLimited(val => !val)}>
            {isLimited ? (
                <>
                    <span className="glyphicon glyphicon-plus icon-space" />
                    <Translate keyId="REPORT.SHOW_TOP_N" options={{ count: max }} />
                </>
            ) : (
                <>
                    <span className="glyphicon glyphicon-minus icon-space" />
                    <Translate keyId="REPORT.COLLAPSE_POSTS" />
                </>
            )}
        </Button>
    );
    return [limit, ButtonComponent] as const;
}

export default withAngularIntegration(FacebookTopPostsPanel, 'facebookTopPostsPanel', {
    postRankings: OneWayBinding,
});
