import { paramsAndBody, justParams, justBody, emptyRequest,
    PrintProviderApi,
    ICreatePrintProviderBody,
    PrintProviderId,
    GetPrintOrderNumberResponse
, LocationId, ClientId, BuilderTemplateId, BuilderTemplateFormatId, PrintProviderSKUGroupId } from '@deltasierra/shared';
import { $httpSID } from '../../common/angularData';
import { invokeApiRoute } from '../../common/httpUtils';


import IHttpService = angular.IHttpService;


export class PrintProviderApiClient {
    static SID = 'PrintProviderApiClient';

    static readonly $inject : string[] = [
        $httpSID,
    ];

    constructor(
        protected readonly $http : IHttpService,
    ) {

    }

    createOnceOffPrintProvider(data: ICreatePrintProviderBody) {
        return invokeApiRoute(this.$http, PrintProviderApi.createOnceOffPrintProvider, justBody(data));
    }

    createLocationPrintProvider(id: LocationId, data: ICreatePrintProviderBody) {
        return invokeApiRoute(this.$http, PrintProviderApi.location.createPrintProvider, paramsAndBody({ id }, data));
    }

    createClientPrintProvider(id: ClientId, data: ICreatePrintProviderBody) {
        return invokeApiRoute(this.$http, PrintProviderApi.client.createPrintProvider, paramsAndBody({ id }, data));
    }

    getLocationPrintProvider(id: LocationId) {
        return invokeApiRoute(this.$http, PrintProviderApi.location.getPrintProvider, justParams({ id }));
    }

    getAvailableLocationPrintProviders(id: LocationId) {
        return invokeApiRoute(this.$http, PrintProviderApi.location.getAvailableProviders, justParams({ id }));
    }

    getPrintOrderNumber() : angular.IPromise<GetPrintOrderNumberResponse> {
        return invokeApiRoute(this.$http, PrintProviderApi.getPrintOrderNumber, emptyRequest());
    }

    getClientPrintProvider(id: ClientId) {
        return invokeApiRoute(this.$http, PrintProviderApi.client.getPrintProvider, justParams({ id }));
    }

    getClientExclusivePrintProvider(clientId: ClientId, builderTemplateId: BuilderTemplateId) {
        return invokeApiRoute(
            this.$http,
            PrintProviderApi.client.getExclusivePrintProvider,
            {
                params: {
                    id: clientId,
                },
                body: undefined,
                query: {
                    builderTemplateId,
                },
            },
        );
    }

    getPrintProviderSKUGroup(printProviderId: PrintProviderId, builderTemplateFormatId: BuilderTemplateFormatId, locationId : LocationId) {
        return invokeApiRoute(
            this.$http,
            PrintProviderApi.getSkuGroup,
            {
                params: {
                    id: printProviderId,
                },
                body: undefined,
                query: {
                    builderTemplateFormatId,
                    locationId,
                },
            },
        );
    }

    setPrintProviderSKUGroup(printProviderId: PrintProviderId, printProviderSKUGroupId: PrintProviderSKUGroupId | null) {
        return invokeApiRoute(
            this.$http,
            PrintProviderApi.setPrintProviderSKUGroup,
            paramsAndBody({
                id: printProviderId,
            }, {
                printProviderSKUGroupId,
            }),
        );
    }

    deletePrintProvider(id: PrintProviderId) {
        return invokeApiRoute(this.$http, PrintProviderApi.deletePrintProvider, justParams({ id }));
    }
}

angular.module('app').service(PrintProviderApiClient.SID, PrintProviderApiClient);
