/// <reference path="../../../../typings/browser.d.ts" />
import { ExpressionBinding, OneWayBinding, OptionalOneWayBinding, simpleComponent } from '../../common/angularData';
import { BuilderTemplateCategoryCriteriaController } from './builderTemplateCategoryCriteriaController';

export const BuilderTemplateCategoryCriteriaSID = 'dsBuilderTemplateCategoryCriteria';

export const BuilderTemplateCategoryCriteriaConfig = simpleComponent(
    BuilderTemplateCategoryCriteriaController,
    'partials/templateGallery/builderTemplateCategoryCriteria/builderTemplateCategoryCriteriaTemplate',
    {
        categories: OneWayBinding,
        change: ExpressionBinding,
        preselectedGroupTypes: OptionalOneWayBinding,
    },
);

angular.module('app').directive(BuilderTemplateCategoryCriteriaSID, [() => BuilderTemplateCategoryCriteriaConfig]);
