import { ReportTable, ReportTableColumn, ReportTableEntry, SortOrder } from './reportTable';

export class ReportTableController {
    static readonly ROOT_ENTRY_LIMIT = 8;

    reportTable!: ReportTable<any, any>;

    isShowingAll = false;

    SortOrder = SortOrder;

    static readonly $inject : string[] = [
    ];

    canTableHaveExpandableEntries() {
        return (
            this.isATree() ||
            (
                this.canTableHaveRowFooters() &&
                this.reportTable.options.rowFooter &&
                this.reportTable.options.rowFooter.getIsHiddenWhenCollapsed
            )
        );
    }

    canTableHaveRowFooters() {
        return !!this.reportTable.options.rowFooter;
    }

    getEntryIndentationWidth(entry: ReportTableEntry<any>) {
        const INDENTATION_WIDTH = 18;
        const depth = this.reportTable.data.getEntryDepth(entry);
        return depth * INDENTATION_WIDTH;
    }

    isATree() {
        return !!this.reportTable.options.tree;
    }

    toggleEntryIsExpanded(entry: ReportTableEntry<any>) {
        if (!this.isATree()) {
            return false;
        }

        const isExpanded = this.reportTable.data.getIsEntryExpanded(entry);
        this.reportTable.data.setIsEntryExpanded(entry, !isExpanded);
    }

    getHeaderHint(col: ReportTableColumn<any, any, any>) {
        return col && col.getHeaderHint && col.getHeaderHint();
    }

    getRootEntryLimit() {
        return this.isShowingAll ? Number.MAX_VALUE : ReportTableController.ROOT_ENTRY_LIMIT;
    }

    getRootEntryLimitDifference() {
        if (!this.reportTable.data.getEntries()) {
            return 0;
        }
        return this.reportTable.data.getEntries().length - ReportTableController.ROOT_ENTRY_LIMIT;
    }

    isOverRootEntryLimit(): boolean {
        const entries =
            !this.isShowingAll &&
            this.reportTable &&
            this.reportTable.data.getEntries();

        const result = entries && entries.length > ReportTableController.ROOT_ENTRY_LIMIT;

        return result;
    }

    isSortedByColumn(column: ReportTableColumn<any, any, any>) {
        const sortedByColumn =
            this.reportTable.options.sortedByColumn &&
            this.reportTable.options.columns[this.reportTable.options.sortedByColumn.columnIndex];
        return column == sortedByColumn;
    }

    isSortedByOrder(order: SortOrder) {
        return this.reportTable.options.sortedByColumn && this.reportTable.options.sortedByColumn.order === order;
    }

    shouldDisplayTotals() {
        return (
            (!this.reportTable.options.totals || this.reportTable.options.totals.isEnabled) &&
            this.reportTable.data.getTotals() &&
            (this.reportTable.data.getEntries().length > 1)
        );
    }

    shouldDisplayRowFooter(entry: ReportTableEntry<any>) {
        if (!this.reportTable.options.rowFooter) {
            return false;
        }

        if (this.reportTable.options.rowFooter.getIsHiddenWhenCollapsed) {
            const isHiddenWhenCollapsed = this.reportTable.options.rowFooter.getIsHiddenWhenCollapsed(entry);

            if (isHiddenWhenCollapsed) {
                const isExpanded = this.reportTable.data.getIsEntryExpanded(entry);

                if (isHiddenWhenCollapsed && !isExpanded) {
                    return false;
                }
            }
        }

        return !!this.reportTable.options.rowFooter.getTemplateUrl(entry);
    }

    shouldDisplayExpandOrCollapseToggle(entry: ReportTableEntry<any>) {
        return this.reportTable.data.canExpandOrCollapseEntry(entry);
    }

    showAll() {
        this.isShowingAll = true;
    }

    sortByColumn(column: ReportTableColumn<any, any, any>) {
        const newColumnIndex = this.reportTable.options.columns.indexOf(column);

        const oldColumnIndex =
            this.reportTable.options.sortedByColumn &&
            this.reportTable.options.sortedByColumn.columnIndex;

        const newSortOrder = oldColumnIndex === newColumnIndex && this.reportTable.options.sortedByColumn ? -this.reportTable.options.sortedByColumn.order : SortOrder.Asc;

        this.reportTable.data.sortByColumn({
            columnIndex: newColumnIndex,
            order: newSortOrder,
        });
    }
}

function walkEntries<TEntry>(entries: Array<ReportTableEntry<any>>, reportTable: ReportTable<TEntry, any>, action: (entry: ReportTableEntry<any>, depth: number) => void, depth = 0) {
    const childDepth = depth + 1;
    for (const entry of entries) {
        action(entry, depth);

        const childEntries = reportTable.data.getIsEntryExpanded(entry) ? reportTable.data.getChildEntries(entry) : ReportTable.EMPTY_ENTRY_ARRAY;

        if (childEntries) {
            walkEntries(childEntries, reportTable, action, childDepth);
        }
    }
}
export function flattenReportTableNestedEntries<T>(entries: Array<ReportTableEntry<T>>, reportTable: ReportTable<any, any>, rootEntryLimit: number) {
    const result : Array<ReportTableEntry<T>> = [];
    let rootEntryCount = 0;
    let canAddMoreEntries = true;
    walkEntries(entries, reportTable, (entry, depth) => {
        entry.reportTableEntryDepth = depth;

        const isRootEntry = entry.reportTableEntryDepth == 0;

        if (canAddMoreEntries && isRootEntry) {
            if (rootEntryCount == rootEntryLimit) {
                canAddMoreEntries = false;
            } else {
                rootEntryCount++;
            }
        }

        if (canAddMoreEntries) {
            result.push(entry);
        }
    });
    return result;
}

function flattenReportTableNestedEntriesFilter() {
    return flattenReportTableNestedEntries;
}

angular.module('app').filter('flattenReportTableNestedEntries', [flattenReportTableNestedEntriesFilter]);
