import { UploadId } from '@deltasierra/shared';
import { isRecordType } from '@deltasierra/type-utilities';

export type FileUploadErrorProperties = {
    message: string;
    error?: Error;
    uploadId?: string;
    legacyUploadId?: UploadId;
};

export class FileUploadError extends Error {
    public readonly code = 'FILE_UPLOAD_ERROR';

    public readonly uploadId?: string;

    public readonly legacyUploadId?: UploadId;

    public readonly innerError?: Error;

    public constructor(properties?: FileUploadErrorProperties) {
        super(properties?.message ?? 'Failed to upload file');
        this.uploadId = properties?.uploadId;
        this.legacyUploadId = properties?.legacyUploadId;
        this.innerError = properties?.error;
    }
}

export function isFileUploadError(error: unknown): error is FileUploadError {
    return isRecordType(error) && !!error && error.code === 'FILE_UPLOAD_ERROR';
}

type FileUploadStarting = {
    status: 'starting';
};
type FileUploadStarted = {
    status: 'started';
    uploadId: string;
    legacyUploadId: UploadId;
};
type FileUploadProgress = {
    status: 'progress';
    totalBytes: number;
    uploadedBytes: number;
};
type FileUploadFinalizing = {
    status: 'finalizing';
};
export type FileUploadStatus = FileUploadFinalizing | FileUploadProgress | FileUploadStarted | FileUploadStarting;
