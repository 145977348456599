import * as React from 'react';
import { Upload } from '@deltasierra/shared';
import { useAngularScope, useAngularComponentRenderer } from '../../../common/componentUtils/angularRendering';

export type UploadBlockProps = {
    upload: Upload;
    deleteUpload?: (upload: Upload) => void;
    titleVisible?: boolean;
    width?: number;
    height?: number;
};

export const UploadBlock: React.FC<UploadBlockProps> = ({ upload, deleteUpload, titleVisible, width, height }) => {
    const scope = useAngularScope<UploadBlockProps>();
    scope.current.upload = upload;
    scope.current.deleteUpload = deleteUpload;
    scope.current.titleVisible = titleVisible;
    scope.current.width = width;
    scope.current.height = height;

    return useAngularComponentRenderer(
        '<ds-upload-block upload="upload" delete="deleteUpload()" title-visible="titleVisible" width="width" height="width">',
        scope,
    );
};
UploadBlock.displayName = 'UploadBlock';
