import { Client, ClientId, Four51ClientConfig } from '@deltasierra/shared';
import {
    $routeSID,
    actualComponent,
    ChangesObject,
    ILifecycleHooks,
    IRoute,
    OneWayBinding,
} from '../../../common/angularData';
import { InteractionUtils } from '../../../common/interactionUtils';
import { MvNotifier } from '../../../common/mvNotifier';
import { I18nService } from '../../../i18n';
import { MvLocation } from '../../../locations/mvLocation';
import { MvClient } from '../../../clients/mvClient';

export class Four51ClientConfigCtrl implements ILifecycleHooks {
    public static readonly SID = 'Four51ClientConfigCtrl';

    // Props
    public client!: Client;

    // State
    public newStore?: string | null;

    public newCompany?: string | null;

    public newSsoKey?: string | null;

    // Futures
    public readonly updateFour51Config = this.interactionUtils.createFuture('update Four51 credentials', (context: {
        clientId: ClientId;
        four51Config: Four51ClientConfig | null;
    }) => this.mvClient.updateFour51Company(
            context.clientId,
            {
                four51Config: context.four51Config,
            },
        ));

    static $inject = [
        $routeSID,
        InteractionUtils.SID,
        MvNotifier.SID,
        I18nService.SID,
        MvLocation.SID,
        MvClient.SID,
    ];

    constructor(
        protected readonly $route: IRoute,
        protected readonly interactionUtils: InteractionUtils,
        protected readonly mvNotifier: MvNotifier,
        protected readonly i18n: I18nService,
        protected readonly mvLocation: MvLocation,
        protected readonly mvClient: MvClient,
    ) {

    }

    $onChanges(changesObj: ChangesObject<this>) {
        if (changesObj.client &&
            (
                changesObj.client.isFirstChange() ||
                changesObj.client.previousValue !== changesObj.client.currentValue
            )
        ) {
            this.newStore = changesObj.client.currentValue.four51Store;
            this.newCompany = changesObj.client.currentValue.four51Company;
            this.newSsoKey = changesObj.client.currentValue.four51SsoKey;
        }
    }

    onSubmitUpdateConfig() {
        return this.updateFour51Config.run({
            clientId: this.client.id,
            four51Config: this.newStore && this.newCompany && this.newSsoKey ? {
                four51Store: this.newStore,
                four51Company: this.newCompany,
                four51SsoKey: this.newSsoKey,
            } : null,
        }).then(() => {
            this.mvNotifier.success(this.i18n.text.apps.four51.companyUpdated());
            this.$route.reload();
        });
    }
}

export const four51ClientConfigSID = 'fourfiftyoneClientConfig';

export const four51ClientConfigConfig = actualComponent(
    Four51ClientConfigCtrl,
    '/partials/integration/auth/four51/four51ClientConfig',
    {
        client: OneWayBinding,
    },
);

angular.module('app').component(four51ClientConfigSID, four51ClientConfigConfig);
