import { DSWell, DSTypography, Translate } from '@deltasierra/components';
import React from 'react';
import { LocationGroupsThumbnailList } from '../../LocationGroupsThumbnailList';

export type LocationGroupsDashboardWellProps = {
    locationId: string;
};

export function LocationGroupsDashboardWell({ locationId }: LocationGroupsDashboardWellProps): JSX.Element {
    return (
        <DSWell>
            <DSTypography variant="h3">
                <Translate keyId="AGENCY.CLIENT.LOCATIONS.HEADERS.YOUR_BRANDS" />
            </DSTypography>
            <LocationGroupsThumbnailList locationId={locationId} />
        </DSWell>
    );
}
LocationGroupsDashboardWell.displayName = 'LocationGroupsDashboardWell';
