import { isNotNullOrUndefined } from '@deltasierra/type-utilities';
import { BuilderTemplateGalleryUrlMap } from '../../../../../pages/builderTemplateGallery/Router.url';
import { LCOFragmentForLocationGroupThumbnail } from './__graphqlTypes/LCOFragmentForLocationGroupThumbnail';

export function useLocationGroupBuilderTemplateGalleryLink(
    locationCategoryOption: LCOFragmentForLocationGroupThumbnail,
): string | null {
    const builderTemplateCategoryLegacyIds = locationCategoryOption.builderTemplateCategories
        .map(btc => btc.legacyId)
        .filter(isNotNullOrUndefined);

    if (!(builderTemplateCategoryLegacyIds.length > 0)) {
        return null;
    }

    return `${BuilderTemplateGalleryUrlMap.index.url()}?category=${builderTemplateCategoryLegacyIds.join(',')}`;
}
