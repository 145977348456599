import { DSList, DSListItem, DSListItemText, DSListSubheader, Loading, Translate } from '@deltasierra/components';
import { useEffectOnUpdateOnly } from '@deltasierra/react-hooks';
import { isNotNullOrUndefined } from '@deltasierra/shared';
import * as React from 'react';
import styled from 'styled-components';
import { HashtagsSelectItem } from './HashtagsSelectItem';
import { useGetHashtagSets } from './useGetHashtagSets';

const StyledDSList = styled(DSList)`
    max-height: 200px;
    overflow: auto;
`;

const StyledDSListSubheader = styled(DSListSubheader)`
    line-height: 24px;
    font-size: 1rem;
`;

const StyledDSListItem: typeof DSListItem = styled(DSListItem)`
    margin-top: ${props => props.theme.spacing(0.5)}px;
    margin-bottom: ${props => props.theme.spacing(0.5)}px;
    padding-top: 0;
    padding-bottom: 0;
`;

const StyledListItemText = styled(DSListItemText)`
    line-height: 24px;
    color: ${props => props.theme.typography.body1.color ?? 'black'};
    padding-left: ${props => props.theme.spacing(2)}px;
    padding-right: ${props => props.theme.spacing(2)}px;
`;

export type HashtagsSelectListProps = {
    disabledCheck?: (hashtags: ReadonlyArray<string>) => boolean;
    existingHashtags?: string[];
    isShowing?: boolean;
    locationId: string;
    onAdd: (hashtags: string[]) => void;
};

export function HashtagsSelectList({
    disabledCheck,
    existingHashtags,
    isShowing,
    locationId,
    onAdd,
}: HashtagsSelectListProps): JSX.Element {
    const { clientHashtags, loading, locationHashtags, refetch } = useGetHashtagSets(locationId);

    const disabledClientHashtags = React.useMemo(
        () =>
            clientHashtags
                .map(hashtag => (disabledCheck?.(hashtag.hashtags) ? hashtag.id : undefined))
                .filter(isNotNullOrUndefined),
        [clientHashtags, disabledCheck],
    );
    const disabledLocationHashtags = React.useMemo(
        () =>
            locationHashtags
                .map(hashtag => (disabledCheck?.(hashtag.hashtags) ? hashtag.id : undefined))
                .filter(isNotNullOrUndefined),
        [disabledCheck, locationHashtags],
    );

    const existingCheck = React.useCallback(
        (hashtags: ReadonlyArray<string>) => hashtags.every(hashtag => existingHashtags?.includes(hashtag) ?? true),
        [existingHashtags],
    );

    const existingClientHashtags = React.useMemo(
        () =>
            clientHashtags
                .map(hashtag => (existingCheck(hashtag.hashtags) ? hashtag.id : undefined))
                .filter(isNotNullOrUndefined),
        [clientHashtags, existingCheck],
    );
    const existingLocationHashtags = React.useMemo(
        () =>
            locationHashtags
                .map(hashtag => (existingCheck(hashtag.hashtags) ? hashtag.id : undefined))
                .filter(isNotNullOrUndefined),
        [existingCheck, locationHashtags],
    );

    useEffectOnUpdateOnly(() => {
        if (isShowing) {
            refetch();
        }
    }, [refetch, isShowing]);

    return (
        <StyledDSList>
            {loading && (
                <DSListItem>
                    <Loading inline size="small" />
                </DSListItem>
            )}
            <StyledDSListSubheader disableSticky>
                <Translate keyId="BUILD.PUBLISH.HASHTAGS.CLIENT_HASHTAGS" />
            </StyledDSListSubheader>
            {clientHashtags.length > 0
                ? clientHashtags.map(hashtagSet => (
                    <HashtagsSelectItem
                        disabled={disabledClientHashtags.includes(hashtagSet.id)}
                        existing={existingClientHashtags.includes(hashtagSet.id)}
                        hashtagSet={hashtagSet}
                        key={hashtagSet.id}
                        onAdd={onAdd}
                    />
                ))
             : (
                <StyledDSListItem disabled>
                    <StyledListItemText>
                        <Translate keyId="BUILD.PUBLISH.HASHTAGS.NO_HASHTAG_SETS" />
                    </StyledListItemText>
                </StyledDSListItem>
            )}
            <StyledDSListSubheader disableSticky>
                <Translate keyId="BUILD.PUBLISH.HASHTAGS.LOCATION_HASHTAGS" />
            </StyledDSListSubheader>
            {locationHashtags.length > 0
                ? locationHashtags.map(hashtagSet => (
                    <HashtagsSelectItem
                        disabled={disabledLocationHashtags.includes(hashtagSet.id)}
                        existing={existingLocationHashtags.includes(hashtagSet.id)}
                        hashtagSet={hashtagSet}
                        key={hashtagSet.id}
                        onAdd={onAdd}
                    />
                ))
             : (
                <StyledDSListItem disabled>
                    <StyledListItemText>
                        <Translate keyId="BUILD.PUBLISH.HASHTAGS.NO_HASHTAG_SETS" />
                    </StyledListItemText>
                </StyledDSListItem>
            )}
        </StyledDSList>
    );
}
HashtagsSelectList.displayName = 'HashtagsSelectList';
