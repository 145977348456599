import { ReportTable, StatsEntry, TextAlignClass } from '../reportTable/reportTable';
import { GoogleStats } from '../../integration/stats/googleStatsService';
import { compareNumbers } from '../compare';
import { CreateReportTableOptions } from './common';

export function createGoogleReportTable(options: CreateReportTableOptions) {
    return new ReportTable<StatsEntry<GoogleStats>, GoogleStats>({
        columns: [
            {
                label: () => options.i18n.text.report.location(),
                cssClass: TextAlignClass.Left,
                format: options.formatters.formatString,
                getValue: entry => entry.location.locationTitle,
                getTotal: () => options.i18n.text.report.total(),
            },
            {
                label: () => options.i18n.text.report.returningVisitors(),
                cssClass: TextAlignClass.Right,
                format: options.formatters.formatNumber,
                getValue: entry => entry.users.returning,
                getTotal: totals => totals.users.returning,
                compare: compareNumbers,
            },
            {
                label: () => options.i18n.text.report.newVisitors(),
                cssClass: TextAlignClass.Right,
                format: options.formatters.formatNumber,
                getValue: entry => entry.users.new,
                getTotal: totals => totals.users.new,
                compare: compareNumbers,
            },
            {
                label: () => options.i18n.text.report.totalVisitors(),
                cssClass: TextAlignClass.Right,
                format: options.formatters.formatNumber,
                getValue: entry => entry.users.count,
                getTotal: totals => totals.users.count,
                compare: compareNumbers,
            },
        ],
    });
}
