/// <reference path="../../../_references.d.ts" />

import { PRINT_RECOMMENDED_PIXELS_PER_INCH } from '@deltasierra/shared';
import { $modalInstanceSID, $modalSID } from '../../../common/angularUIBootstrapData';
import { ModalInstance, ModalService } from '../../../typings/angularUIBootstrap/modalService';

export class ImageDpiPromptController {
    static SID = 'ImageDpiPromptController';

    static readonly $inject: string[] = [
        $modalInstanceSID,
        'dpi',
    ];

    constructor(
        private $modalInstance: ModalInstance,
        public readonly dpi: number,
    ) {
        this.dpi ||= PRINT_RECOMMENDED_PIXELS_PER_INCH;
    }

    confirm() {
        this.$modalInstance.close(this.dpi);
    }

    cancel() {
        this.$modalInstance.dismiss();
    }
}

export class ImageDpiPromptService {
    static SID = 'ImageDpiPromptService';

    static readonly $inject : string[] = [
        $modalSID,
    ];

    constructor(
        private $modal : ModalService,
    ) {
    }

    show(defaultDpi? : number) : ng.IPromise<number> {
        const modalInstance = this.$modal.open({
            animation: true,
            backdrop: 'static',
            keyboard: true,
            templateUrl: 'partials/contentBuilder/print/imageDpiPromptService/template',
            controller: ImageDpiPromptController,
            controllerAs: 'ctrl',
            resolve: {
                dpi: () => defaultDpi,
            },
        });

        return modalInstance.result;
    }
}

angular.module('app')
    .controller(ImageDpiPromptController.SID, ImageDpiPromptController)
    .service(ImageDpiPromptService.SID, ImageDpiPromptService);
