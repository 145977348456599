/// <reference path="../../../../typings/browser.d.ts" />
import { SubmitAuthCodeForStripeAccountBody } from '@deltasierra/shared';
import { InteractionUtils } from '../../common/interactionUtils';
import { I18nService } from '../../i18n/i18nService';
import { $locationSID } from '../../common/angularData';
import { StripeApiClient } from './stripeApiClient';

export class StripeAuthResponseCtrl {
    static SID = 'StripeAuthResponseCtrl';

    // Future for trying to create a StripeConfiguration after posting to stripe with the code param
    submitAuthForStripeAccount = this.interactionUtils.createFuture(
        this.i18n.text.payments.stripe.submitAuthCodeToStripe(),
        async (context: { data: SubmitAuthCodeForStripeAccountBody }) => {
            const response = await this.stripeApiClient.submitAuthCodeForStripeAccount(context.data);
            if (response.stripeConfiguration) {
                this.success = true;
            }
        },
    );

    unknownError = false;

    accessDenied = false;

    success = false;

    static readonly $inject: string[] = [$locationSID, InteractionUtils.SID, I18nService.SID, StripeApiClient.SID];

    constructor(
        protected readonly $location: ng.ILocationService,
        protected readonly interactionUtils: InteractionUtils,
        protected readonly i18n: I18nService,
        protected readonly stripeApiClient: StripeApiClient,
    ) {
        const params = this.$location.search();

        // TODO: Check if it's possible for them to change the status to read only
        if (params.state && params.code && params.scope === 'read_write') {
            const data: SubmitAuthCodeForStripeAccountBody = {
                code: params.code,
                token: params.state,
            };
            void this.submitAuthForStripeAccount.run({ data });
        } else if (params.error === 'access_denied' || params.error_description === 'The user denied your request') {
            this.accessDenied = true;
        } else {
            this.unknownError = true;
        }
    }
}

angular.module('app').controller(StripeAuthResponseCtrl.SID, StripeAuthResponseCtrl);
