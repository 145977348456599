import { gql } from '@apollo/client';

export const LOCATION_CATEGORIES_FOR_CLIENT = gql`
    query GetLocationCategoriesForClient($clientId: ID!) {
        client(id: $clientId) {
            id
            locationCategories {
                id
                categoryOptions {
                    id
                    label
                }
            }
        }
    }
`;
