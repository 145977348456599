import { useMutation } from '@apollo/client';
import * as React from 'react';
import { swallowHandledErrors } from '../../graphql/swallow-handled-errors';
import {
    UseExportReportButtonState,
    useExportReportButtonStateReducer,
} from './use-export-report-button-state-reducer';
import { useExportReportButtonStateTransitions } from './use-export-report-button-state-transitions';
import { useGenerateReportRequestPoller } from './use-generate-report-request-poller';
import {
    UseExportReportButtonMutationVariables,
    UseExportReportButtonMutation,
    UseExportReportButtonInputs,
    UseExportReportButtonQuery,
} from './types';

export function useExportReportButton({
    mutation,
    mutationVariables,
    pollerFn,
    query,
}: Omit<UseExportReportButtonInputs<UseExportReportButtonMutationVariables, UseExportReportButtonQuery>, 'title'>): {
    downloadLinkRef: React.Dispatch<React.SetStateAction<HTMLLinkElement | undefined>>;
    loading: boolean;
    onClickButton: () => void;
    state: UseExportReportButtonState;
} {
    const [downloadLinkElement, setDownloadLinkRef] = React.useState<HTMLLinkElement | undefined>();

    const [state, dispatch] = useExportReportButtonStateReducer();

    const [
        generateReportMutation,
        { data: mutationData, error: mutationError, loading: mutationLoading, reset: mutationReset },
    ] = useMutation<UseExportReportButtonMutation, UseExportReportButtonMutationVariables>(mutation, {
        errorPolicy: 'all',
        notifyOnNetworkStatusChange: true,
        onError: swallowHandledErrors,
        variables: mutationVariables,
    });

    const {
        poll,
        pollCount,
        pollIntervalMs,
        reset: resetPoller,
    } = useGenerateReportRequestPoller(state, dispatch, pollerFn, query);

    const reset = React.useCallback(() => {
        resetPoller();
        mutationReset();
    }, [mutationReset, resetPoller]);

    const onClickButton = React.useCallback(() => {
        dispatch({
            type: 'start',
        });
    }, [dispatch]);

    const runGenerateReportMutation = React.useCallback(() => {
        void generateReportMutation();
    }, [generateReportMutation]);

    useExportReportButtonStateTransitions(state, dispatch, {
        downloadLinkElement,
        generateReportMutationData: mutationData ?? null,
        generateReportMutationError: mutationError,
        generateReportMutationLoading: mutationLoading,
        poll,
        pollCount,
        pollIntervalMs,
        reset,
        runGenerateReportMutation,
    });

    return {
        downloadLinkRef: setDownloadLinkRef,
        loading: state.name === 'requesting' || state.name === 'polling',
        onClickButton,
        state,
    };
}
