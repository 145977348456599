import * as React from 'react';
import styled from 'styled-components';
import { TimePeriod } from '@deltasierra/dates';
import { DSList, DSListItem, DSListItemText, ThemedNewDateRangePicker, Translate } from '@deltasierra/components';

export type DateRangePickerProps = {
    dates: TimePeriod;
    onClickLastMonth: () => void;
    onClickThisMonth: () => void;
    setSinceUntil: (since: Date | undefined, until: Date | undefined) => void;
};

const StyledList = styled(DSList)`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const StyledListItem = styled(DSListItem)`
    padding: 20px 10px 10px 10px;
`;

export function DateRangePicker({
    dates,
    onClickLastMonth,
    onClickThisMonth,
    setSinceUntil,
}: DateRangePickerProps): JSX.Element {
    return (
        <ThemedNewDateRangePicker
            value={[dates.startDate, dates.endDate]}
            onChange={([since, until]) => setSinceUntil(since, until)}
        >
            <StyledList>
                <StyledListItem button>
                    <DSListItemText onClick={onClickThisMonth}>
                        <Translate keyId="COMMON.THIS_MONTH" />
                    </DSListItemText>
                </StyledListItem>
                <StyledListItem button>
                    <DSListItemText onClick={onClickLastMonth}>
                        <Translate keyId="COMMON.LAST_MONTH" />
                    </DSListItemText>
                </StyledListItem>
            </StyledList>
        </ThemedNewDateRangePicker>
    );
}
DateRangePicker.displayName = 'DateRangePicker';
