/* eslint-disable no-prototype-builtins */
import { Untyped, UploadId } from '@deltasierra/shared';

export class CachedFile {
    constructor(
        public file: File,
        public uploadId?: UploadId | null,
    ) {
    }
}

export interface IBaseFileCache {
    get(key: string): File | null;
    set(key: string, value: File) : Untyped;
    remove(key: string) : Untyped;
    clear() : Untyped;
    getFileMap(): { [key : string] : File };
}

export class FileCache implements IBaseFileCache {

    private files : { [key: string]: CachedFile } = { };

    private getFile(key: string): File | null {
        const cache = this.files[key];
        return cache != null ? cache.file : null;
    }

    get(key : string) : File | null {
        return this.getFile(key);
    }

    set(key : string, value : File) {
        this.files[key] = new CachedFile(value);
    }

    remove(key : string) {
        if (this.files[key]) {
            delete this.files[key];
        }
    }

    clear() {
        for (const key in this.files) {
            if (this.files.hasOwnProperty(key)) {
                const URL = window.URL || (window as Untyped).webkitURL;
                // Assume keys are object URLs
                try {
                    URL.revokeObjectURL(key);
                } catch (err) {
                    console.log(`Failed to revoke object URL from file cache: ${key}`);
                }
            }
        }
        this.files = {};
    }

    getFileMap() : { [key : string] : File } {
        const map = {};
        Object.keys(this.files)
            .filter(x => this.files.hasOwnProperty(x))
            .forEach(x => {
                (map as Untyped)[x] = this.getFile(x);
            });

        return map;
    }

    getByUploadId(uploadId: UploadId) : CachedFile | undefined {
        for (const key in this.files) {
            if (this.files.hasOwnProperty(key)) {
                if (this.files[key].uploadId == uploadId) {
                    return this.files[key];
                }
            }
        }
    }

    getCache(key: string) : CachedFile {
        return this.files[key];
    }
}
