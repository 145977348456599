import { DateTime } from 'luxon';

/**
 * @deprecated - This is only to be used in the old flow and will be removed soon
 * @param createdAt - The created at date
 * @returns boolean
 */
export function isOldFlowCreatedAtWithinSafeRange(createdAt: Date): boolean {
    return DateTime.now() > DateTime.fromJSDate(createdAt).plus({ minutes: 30 });
}
