/// <reference path="../../../typings/browser.d.ts" />
import { assertNever, Comment, SpecialRequest, TryThis, Upload } from '@deltasierra/shared';
import { isNullOrUndefined } from '@deltasierra/type-utilities';
import { IScope } from 'angular';
import { MvIdentity } from '../account/mvIdentity';
import { $scopeSID } from '../common/angularData';
import { DataUtils } from '../common/dataUtils';
import { InteractionUtils } from '../common/interactionUtils';
import { MvNotifier } from '../common/mvNotifier';
import { UploadContext, UploadService } from '../common/uploadService';
import { I18nService } from '../i18n/i18nService';
import { MvSpecialRequest } from '../specialRequests/mvSpecialRequest';
import { MvTryThis } from '../trythis/mvTryThis';

interface CommentInput {
    commentText: string;
    isPrivate: boolean;
    uploads: Upload[];
}

type TextPart = {
    type: 'text';
    value: string;
};

type LinkPart = {
    href: string;
    target: string;
    type: 'link';
    value: string;
};

type FixedCommentPart = LinkPart | TextPart;

type FixedComment = Comment & {
    parts: FixedCommentPart[];
};

export class CommentsController {
    public static readonly $inject: string[] = [
        $scopeSID,
        MvIdentity.SID,
        MvNotifier.SID,
        DataUtils.SID,
        InteractionUtils.SID,
        MvTryThis.SID,
        MvSpecialRequest.SID,
        UploadService.SID,
        I18nService.SID,
    ];

    public readonly PARENT_TYPE_TRY_THIS = 'tryThis';

    public readonly PARENT_TYPE_SPECIAL_REQUEST = 'specialRequest';

    public uploadContext?: UploadContext;

    public inputData = this.createInputData();

    public loading = {
        save: false,
    };

    public allowPrivate!: boolean;

    public allowAttachments!: boolean;

    public heading!: string;

    public rows!: number;

    public modelType!: 'specialRequest' | 'tryThis';

    public model!: SpecialRequest | TryThis;

    public fixedComments!: FixedComment[];

    public constructor(
        private $scope: IScope,
        private mvIdentity: MvIdentity,
        private mvNotifier: MvNotifier,
        private dataUtils: DataUtils,
        private interactionUtils: InteractionUtils,
        private mvTryThis: MvTryThis,
        private mvSpecialRequest: MvSpecialRequest,
        private uploadService: UploadService,
        private i18n: I18nService,
    ) {
        this.fixedComments = [];

        this.$scope.$watchCollection(
            () => this.model?.comments ?? [],
            (newModel: Comment[]) => {
                this.fixedComments = newModel.map(comment => {
                    const linkRegex = /((?:http[s]?:\/\/|tel:|mailto:)[^\s]+)/g;

                    const commentParts = comment.comment.split(linkRegex);

                    return {
                        ...comment,
                        parts: commentParts.map<FixedCommentPart>(part =>
                            linkRegex.test(part)
                                ? {
                                      href: part,
                                      target: part.startsWith('http') ? '_blank' : '',
                                      type: 'link',
                                      value: part,
                                  }
                                : { type: 'text', value: part },
                        ),
                    };
                });
            },
        );
    }

    public $onInit(): void {
        this.allowPrivate = !!this.allowPrivate;
        this.allowAttachments = !!this.allowAttachments;
        this.heading ||= this.i18n.text.common.comments.commentsHeadingDefault();
        this.rows ||= 1;
    }

    public canDelete() {
        return this.modelType === this.PARENT_TYPE_TRY_THIS && this.mvIdentity.isManager();
    }

    public canMakePrivateComments() {
        return this.allowPrivate && this.mvIdentity.isManager();
    }

    public submitComment() {
        return this.interactionUtils
            .handleRemote(this, 'add comment', 'save', this.innerSubmitComment())
            .then((comment: any) => {
                comment.createdBy = this.mvIdentity.currentUser;
                if (isNullOrUndefined(this.model.comments)) {
                    this.model.comments = [comment];
                } else {
                    this.model.comments.push(comment);
                }
                this.inputData = this.createInputData();
            });
    }

    public innerDeleteComment(commentObj: Comment) {
        switch (this.modelType) {
            case this.PARENT_TYPE_SPECIAL_REQUEST:
                throw new Error(this.i18n.text.common.comments.cannotDeleteComments());
            case this.PARENT_TYPE_TRY_THIS:
                return this.mvTryThis.deleteComment(this.model.id, commentObj.id);
            default:
                assertNever(this.modelType);
                throw new Error(this.i18n.text.common.comments.unknownParent() + this.modelType);
        }
    }

    public async deleteComment(commentObj: Comment): Promise<void> {
        if (!this.canDelete()) {
            this.mvNotifier.unexpectedError(this.i18n.text.common.comments.cannotDeleteComments());
            return Promise.resolve();
        }
        return this.interactionUtils
            .handleRemote(this, 'delete comment', 'save', this.innerDeleteComment(commentObj))
            .then(() => {
                this.dataUtils.removeById(commentObj.id, this.model.comments || []);
            });
    }

    public attachFiles($files: File[]) {
        this.uploadService.upload($files, 'comment', this.inputData.uploads, this);
    }

    public deleteUpload(upload: Upload) {
        this.dataUtils.removeObject(upload, this.inputData.uploads);
        // TODO: remove on the server
    }

    public isAnUploadInProgress() {
        return this.uploadContext && this.uploadContext.uploadInProgress;
    }

    public getUploadGlyph(ext: string) {
        return this.uploadService.getUploadGlyph(ext);
    }

    private createInputData(): CommentInput {
        return {
            commentText: '',
            isPrivate: false,
            uploads: [],
        };
    }

    private innerSubmitComment() {
        if (this.modelType === this.PARENT_TYPE_TRY_THIS) {
            return this.mvTryThis.addComment(this.model.id, this.inputData.commentText);
        } else if (this.modelType === this.PARENT_TYPE_SPECIAL_REQUEST) {
            return this.mvSpecialRequest.addComment(
                this.model.id,
                this.inputData.commentText,
                this.inputData.uploads,
                this.inputData.isPrivate,
            );
        } else {
            throw new Error(this.i18n.text.common.comments.unknownParent() + this.modelType);
        }
    }
}

export const commentsSID = 'comments';
export const commentsConfig = {
    bindToController: true,
    controller: CommentsController,
    controllerAs: 'ctrl',
    replace: true,
    restrict: 'E',
    scope: {
        allowAttachments: '@?',
        allowPrivate: '@?',
        heading: '@?',
        model: '=',
        modelType: '@',
        rows: '@?',
    },
    templateUrl: '/partials/comments/comments',
};

angular.module('app').directive(commentsSID, [() => commentsConfig]);
