/// <reference path="../../../../typings/browser.d.ts" />
import {
    CampaignMonitorClient,
    CampaignMonitorDraftData,
    CampaignMonitorSendCampaignData,
    CampaignMonitorSendPreviewData,
    ClientDetails,
    LocationId,
    Segment,
    SubscriberList,
    TimezoneInfo,
} from '@deltasierra/shared';
import { IHttpPromise, IHttpService, IPromise } from 'angular';
import { getData } from '../../common/httpUtils';

export class CampaignMonitorPublishService {
    public static SID = 'campaignMonitorPublishService';

    public static readonly $inject: string[] = ['$http'];

    public constructor(private $http: IHttpService) {}

    public getClients(locationId: LocationId): IPromise<CampaignMonitorClient[]> {
        return this.$http
            .get<CampaignMonitorClient[]>(`/api/publish/campaignMonitor/location/${locationId}/clients`)
            .then(getData);
    }

    public getClientDetails(locationId: LocationId, campaignMonitorClientId: string): IHttpPromise<ClientDetails> {
        return this.$http.get(`/api/publish/campaignMonitor/location/${locationId}/clients/${campaignMonitorClientId}`);
    }

    public getClientTimezone(locationId: LocationId, campaignMonitorClientId: string): IHttpPromise<TimezoneInfo> {
        return this.$http.get(
            `/api/publish/campaignMonitor/location/${locationId}/clients/${campaignMonitorClientId}/timezone`,
        );
    }

    public getLists(locationId: LocationId, campaignMonitorClientId: string): IHttpPromise<SubscriberList[]> {
        return this.$http.get(
            `/api/publish/campaignMonitor/location/${locationId}/clients/${campaignMonitorClientId}/lists`,
        );
    }

    public getSegments(locationId: LocationId, campaignMonitorClientId: string): IHttpPromise<Segment[]> {
        return this.$http.get(
            `/api/publish/campaignMonitor/location/${locationId}/clients/${campaignMonitorClientId}/segments`,
        );
    }

    public saveDraftCampaign(
        locationId: LocationId,
        campaignMonitorClientId: string,
        campaignData: CampaignMonitorDraftData,
    ): angular.IHttpPromise<{ campaignId: string }> {
        return this.$http.post<{ campaignId: string }>(
            `/api/publish/campaignMonitor/location/${locationId}/clients/${campaignMonitorClientId}/campaigns`,
            campaignData,
        );
    }

    public sendPreview(
        locationId: LocationId,
        campaignMonitorClientId: string,
        campaignId: string,
        sendPreviewData: CampaignMonitorSendPreviewData,
    ): angular.IHttpPromise<unknown> {
        return this.$http.post(
            // eslint-disable-next-line max-len
            `/api/publish/campaignMonitor/location/${locationId}/clients/${campaignMonitorClientId}/campaigns/${campaignId}/preview`,
            sendPreviewData,
        );
    }

    public sendCampaign(
        locationId: LocationId,
        campaignMonitorClientId: string,
        campaignId: string,
        sendCampaignData: CampaignMonitorSendCampaignData,
    ): angular.IHttpPromise<unknown> {
        return this.$http.post(
            // eslint-disable-next-line max-len
            `/api/publish/campaignMonitor/location/${locationId}/clients/${campaignMonitorClientId}/campaigns/${campaignId}/send`,
            sendCampaignData,
        );
    }
}

angular.module('app').service(CampaignMonitorPublishService.SID, CampaignMonitorPublishService);
