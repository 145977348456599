import { ModalInstance } from '../../typings/angularUIBootstrap/modalService';
import { $modalInstanceSID } from '../angularUIBootstrapData';
import { CommonModalButton, CommonModalData } from './commonModalService';

export class BasicModalController<TValue> {
    public static readonly $inject: string[] = [
        $modalInstanceSID,
        'modalData' as keyof BasicModalController<undefined>, // ModalData is injected by the commonModalService
    ];

    constructor(
        protected readonly modalInstance : ModalInstance,
        public readonly modalData: CommonModalData<TValue>,
    ) {

    }

    /**
     * All button clicks are treated as "close".
     * We could use "dismiss" to reject the promise, but promise rejections are unexpected and break type safety.
     * Instead, you could use a Result value.
     *
     * @param button
     * @returns
     */
    onClickButton(button: CommonModalButton<TValue>): void {
        return this.modalInstance.close(button.value);
    }
}
