import { gql } from '@apollo/client';

export const PLATFORM_NOT_CONNECTED_MESSAGE_QUERY = gql`
    query PlatformNotConnectedMessageQuery($id: ID!) {
        location(id: $id) {
            id
            title
        }
    }
`;
