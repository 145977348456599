import { Loading } from '@deltasierra/components';
import React from 'react';

export type LazyComponentProps = {
    /**
     * Callback function to perform the actual loading.
     *
     * @example
     * async () => import('./path/to/Component.tsx').ComponentName}
     */
    loader: Parameters<typeof React.lazy>[0];
};

/**
 * Lazy route loader - great for code splitting!
 *
 * @param param0 - The options
 * @param param0.loader - The async function to call the module
 * @example
 * <Route path="/custom/url/path/:id" element={<LazyComponent loader={
 *     () => import('./path/to/Component.tsx').ComponentName
 * }  />} />
 * @returns React.ReactElement
 * @see https://reactjs.org/docs/code-splitting.html#route-based-code-splitting
 */
export function LazyComponent({ loader }: LazyComponentProps): JSX.Element {
    const ActualLazyComponent = React.lazy(async () => loader());
    return (
        <React.Suspense fallback={<Loading />}>
            <ActualLazyComponent />
        </React.Suspense>
    );
}
LazyComponent.displayName = 'LazyComponent';
