import { BuilderTemplateId, t } from '@deltasierra/shared';
import OpenInNew from '@material-ui/icons/OpenInNew';
import * as React from 'react';
import styled from 'styled-components';
import { EndAdornment } from '../../../campaign/components/CampaignEventForm/TemplateOrCategoryPicker/TemplateSelect/EndAdornment';
import { useTemplateSelect } from './useTemplateSelector';

const PlannerTemplateSelectorWrapper = styled.div`
    width: 100%;
`;

const PlannerTemplateSelectorContainer = styled.div`
    align-items: center;
    display: flex;
`;

const PlannerTemplateSelectorInputContainer = styled.div`
    position: relative;
    width: 100%;
`;

const PlannerTemplateSelectortLink = styled.a`
    align-items: 'center';
    display: 'flex';
    line-height: 2rem;
`;

const PlannerTemplateSelectThumbnail = styled.img`
    margin-right: 0.8rem;
    height: 3.2rem;
    width: 3.2rem;
    border-radius: 6px;
    border: 1px solid ${({ theme }) => theme.palette.primary.main};
`;

const PlannerTemplateSelectInput = styled.input`
    border-radius: 6px;
    padding-right: 2rem;
`;

const PlannerTemplateSelectEndSpan = styled.span`
    display: flex;
    align-items: center;

    position: absolute;
    bottom: 0;
    right: 0;
    top: 0;
    // Not sure why this is necessary but it is
    z-index: 1000;
    width: 2rem;
`;

export interface PlannerTemplateSelectorProps {
    initialId?: BuilderTemplateId | null;
    onChange?: (templateId: BuilderTemplateId | null) => void;
}

export function PlannerTemplateSelector(props: PlannerTemplateSelectorProps): JSX.Element {
    const description = t('PLAN.ASSOCIATED_BUILDER_RESOURCES.PASTE_A_TEMPLATE_URL');
    const [inputProps, state, thumbnail] = useTemplateSelect(props.initialId ?? null, props.onChange);
    return (
        <PlannerTemplateSelectorWrapper>
            <PlannerTemplateSelectorContainer>
                {thumbnail && <PlannerTemplateSelectThumbnail src={thumbnail} />}
                <PlannerTemplateSelectorInputContainer className="input-group">
                    <PlannerTemplateSelectInput
                        aria-label={description}
                        className="form-control full-width"
                        placeholder={description}
                        {...inputProps}
                    />
                    <PlannerTemplateSelectEndSpan>
                        <EndAdornment state={state} />
                    </PlannerTemplateSelectEndSpan>
                </PlannerTemplateSelectorInputContainer>
            </PlannerTemplateSelectorContainer>
            <PlannerTemplateSelectortLink href="/builderTemplateGallery" rel="noreferrer noopener" target="_blank">
                {t('BUILD.TEMPLATE_GALLERY')}
                <OpenInNew fontSize="inherit" style={{ marginLeft: '4px' }} />
            </PlannerTemplateSelectortLink>
        </PlannerTemplateSelectorWrapper>
    );
}
PlannerTemplateSelector.displayName = 'PlannerTemplateSelector';
