/// <reference path="../../../../typings/browser.d.ts" />
import { OneWayBinding } from '../../common/angularData';
import { MvContentBuilderFileFormatCtrl } from './mvContentBuilderFileFormatCtrl';

export const contentBuilderFileFormatConfig = {
    restrict: 'E',
    scope: {},
    templateUrl: '/partials/contentBuilder/publish/fileFormats',
    controller: MvContentBuilderFileFormatCtrl,
    controllerAs: 'ctrl',
    bindToController: {
        location: '<',
        fileFormatOptions: '=',
        contentBuilder: '=',
        selectedBuilderTemplateFormats: '=',
        selectedPlatform: '=',
        isExclusivePrintProvider: OneWayBinding,
    },
};

export const contentBuilderFileFormatSID = 'contentBuilderFileFormat';

angular.module('app').directive(contentBuilderFileFormatSID, [() => contentBuilderFileFormatConfig]);
