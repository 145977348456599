/* eslint-disable id-length */
/// <reference path="../../_references.d.ts" />

import { areColorsEqual,
    Color,
    ColorScheme,
    colorSchemeHelpers,
    convertToScheme,
    getColorScheme,
    HSV,
    parseColor,
    RGB,
 Nullable } from '@deltasierra/shared';

import { round } from '@deltasierra/math-utilities';
import { $scopeSID, $timeoutSID, $windowSID, OptionalTwoWayBinding } from '../angularData';

angular.module('app').directive('dsPercent', [
    () => ({
        link(scope, element, attrs, ngModel: ng.INgModelController) {
            // Don't do anything unless we have a model
            if (ngModel) {
                ngModel.$parsers.push(value => round(value / 100, 2));
                ngModel.$formatters.push(value => round(value * 100));
            }
        },
        require: 'ngModel',
        restrict: 'A',
    }),
]);

export interface Dialog {
    visible: boolean;
    tab: string;
    position: { x: number; y: number };
    formName: string;
    satLumCanvas: HTMLCanvasElement;
    hueCanvas: HTMLCanvasElement;
    originalColor: string;
    hsv: HSV;
    scheme?: ColorScheme;
    values: Color;
    textValue: string;
}

export interface Swatch {
    name: string;
    colour: string;
}
export interface SwatchGroup {
    title: string;
    swatches: Swatch[];
}

class ColorPickerController {
    // eslint-disable-next-line sort-keys
    private static TRANSPARENT_BLACK_HSV = { h: 0, s: 0, v: 0, a: 0 };

    private static DEFAULT_SCHEME: ColorScheme = 'hsl';

    public element?: JQuery;

    public canvas?: HTMLCanvasElement | null;

    public color?: string;

    public customSwatches?: SwatchGroup[];

    public originalColor?: string;

    public selectedSwatchGroup?: SwatchGroup;

    public keepOpen?: boolean;

    public dialog: Nullable<Dialog> = {
        formName: ColorPickerController.generateFormName(),
        hsv: null,
        hueCanvas: null,
        originalColor: null,
        position: { x: 0, y: 0 },
        satLumCanvas: null,
        scheme: null,
        tab: 'picker',
        textValue: null,
        values: null,
        visible: this.keepOpen ?? false,
    };

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public static readonly $inject = [$scopeSID, $windowSID, $timeoutSID];

    public constructor(
        private $scope: ng.IScope,
        private $window: ng.IWindowService,
        private $timeout: ng.ITimeoutService,
    ) {}

   public $onInit(): void {
        this.$scope.$watch(
            () => !!this.element,
            () => this.elementUpdated(),
        );

        this.$scope.$watch(
            () => JSON.stringify({ color: this.color, hasCanvas: !!this.canvas }),
            () => this.colorChanged(),
        );

        this.$window.addEventListener('click', event => this.windowElementClicked(event));

        this.$scope.$watch(
            () => this.dialog.visible,
            () => this.render(),
        );

        this.$scope.$watch(() => this.dialog.scheme, this.schemeChanged.bind(this));

        this.$scope.$watch(
            () => JSON.stringify(this.dialog.values),
            () => this.valuesChanged(),
        );

        this.$scope.$watch(
            () => this.dialog.hsv,
            () => this.hsvChanged(),
        );

        this.$scope.$watch(
            () => this.dialog.textValue,
            () => this.textValueChanged(),
        );
    }

    public satLumMouseDown(event: MouseEvent) {
        if (event.buttons === 1) {
            this.updateSatLumFromXY(event.offsetX, event.offsetY);
        }
    }

    public satLumMouseMove(event: MouseEvent) {
        if (event.buttons === 1) {
            this.updateSatLumFromXY(event.offsetX, event.offsetY);
        }
    }

    public selectSwatch(swatch: Swatch) {
        this.updateFromTextValue(swatch.colour);
    }

    public toRgbString(color: string) {
        const values = parseColor(color);
        const rgb = convertToScheme(values, 'rgb') as RGB;
        return colorSchemeHelpers.rgb.toString(rgb, 'rgba');
    }

    public getColorSchemeFromString(color: string) {
        return getColorScheme(parseColor(color))?.toString();
    }

    public show() {
        if (this.dialog.visible || !this.element) {
            return;
        }

        const $button = this.element.find('.color-picker__button');
        const buttonPosition = $button.position();

        this.originalColor = this.color;
        this.dialog.position = {
            x: buttonPosition.left,
            y: buttonPosition.top + $button.outerHeight() + 1,
        };
        this.dialog.tab = 'picker';
        this.dialog.visible = true;
    }

    public selectSwatchGroup(group: SwatchGroup) {
        this.selectedSwatchGroup = group;
        this.selectTab(group.title);
    }

    public cancel() {
        if (!this.keepOpen) {
            this.color = this.originalColor;
            this.dialog.visible = false;
        }
    }

    public selectTab(tab: string) {
        this.dialog.tab = tab;
    }

    public hueMouseDown(event: MouseEvent) {
        if (event.buttons === 1) {
            this.updateHueFromXY(event.offsetX, event.offsetY);
        }
    }

    public hueMouseMove(event: MouseEvent) {
        if (event.buttons === 1) {
            this.updateHueFromXY(event.offsetX, event.offsetY);
        }
    }

    private hueCoordToHsv(x: number, y: number): HSV {
        if (this.dialog.hueCanvas) {
            const { height } = this.dialog.hueCanvas;

            // eslint-disable-next-line no-param-reassign
            y = Math.max(0, Math.min(height, y));

            const { a, s, v } = this.dialog.hsv || ColorPickerController.TRANSPARENT_BLACK_HSV;

            const h = (y / height) * 360;

            // eslint-disable-next-line sort-keys
            const hsv = { h, s, v, a };

            return hsv;
        } else {
            return { h: 0, s: 0, v: 0 };
        }
    }

    private updateHueFromXY(x: number, y: number) {
        this.dialog.hsv = this.hueCoordToHsv(x, y);
        this.dialog.values =
            convertToScheme(this.dialog.hsv, this.dialog.scheme || ColorPickerController.DEFAULT_SCHEME) || null;
    }

    private renderButtonTransparency(context: CanvasRenderingContext2D, width: number, height: number) {
        if (this.canvas) {
            const tileSize = 5;
            context.fillStyle = '#fff';
            context.fillRect(0, 0, width, height);

            context.fillStyle = '#888';

            for (let y = 0; y * tileSize < this.canvas.height; y++) {
                for (let x = 0; x * tileSize < this.canvas.width; x++) {
                    if ((x + y) % 2 === 0) {
                        context.fillRect(x * tileSize, y * tileSize, tileSize, tileSize);
                    }
                }
            }
        }
    }

    private renderButtonColor(context: CanvasRenderingContext2D, width: number, height: number) {
        if (this.color) {
            context.fillStyle = this.toRgbString(this.color);
            context.fillRect(0, 0, width, height);
        }
    }

    private render() {
        this.renderButton();
        this.renderDialog();
    }

    private colorChanged() {
        this.dialog.values = this.color ? parseColor(this.color) || null : null;
        this.dialog.scheme = getColorScheme(this.dialog.values || undefined);

        this.updateHsvToMatchColorIfNeeded();
        this.updateTextValueToMatchColorIfNeeded();

        this.render();
    }

    private updateHsvToMatchColorIfNeeded() {
        const rgb =
            this.dialog.values && this.dialog.scheme
                ? colorSchemeHelpers.byName(this.dialog.scheme).toRgb(this.dialog.values, { round: false })
                : null;

        if (this.dialog.hsv) {
            const rgbFromHsv = colorSchemeHelpers.hsv.toRgb(this.dialog.hsv, { round: false });

            // Multiple HSV colors can translate to the same RGB/CMYK/HSL color
            // So only need to update this.dialog.hsv if hsv doesn't already
            // Translate to the color. Otherwise, translating from the color
            // Back to HSV can result in a slightly different HSV
            if (colorSchemeHelpers.rgb.areEqual(rgb, rgbFromHsv)) {
                return;
            }
        }

        if (!rgb && !this.dialog.hsv) {
            return;
        }

        this.dialog.hsv = rgb ? colorSchemeHelpers.hsv.fromRgb(rgb) : null;
    }

    private updateTextValueToMatchColorIfNeeded() {
        const rgb =
            this.dialog.values && this.dialog.scheme
                ? colorSchemeHelpers.byName(this.dialog.scheme).toRgb(this.dialog.values, { round: false })
                : null;

        if (this.dialog.textValue) {
            const values = parseColor(this.dialog.textValue);
            if (values) {
                const hexRgb = convertToScheme(values, 'rgb');
                if (colorSchemeHelpers.rgb.areEqual(rgb, hexRgb || null)) {
                    return;
                }
            }
        }

        this.dialog.textValue = (rgb ? colorSchemeHelpers.rgb.toString(rgb, 'hex') || '' : '').toUpperCase();
    }

    private schemeChanged(newScheme: ColorScheme | null | undefined, oldScheme: ColorScheme | null | undefined) {
        const valuesScheme = getColorScheme(this.dialog.values || undefined);

        /*
         * This rule is ignored because valuesSchema can be undefined and newScheme can be null | undefined. It's too complicated to
         * workout what the original intention was and to change to strict equality
         */
        // eslint-disable-next-line eqeqeq
        if (valuesScheme != newScheme) {
            // If values are not already in the new scheme we need to convert them
            let currentColor: Color | null = null;

            // If we couldn't determine the scheme of the values
            if (!valuesScheme) {
                // If they had a scheme previously, try to sanitise them with that scheme
                if (oldScheme && this.dialog.values) {
                    currentColor = colorSchemeHelpers.byName(oldScheme).sanitise(this.dialog.values);
                }
            } else {
                currentColor = this.dialog.values;
            }

            // If there's no color, just use transparent black as a fallback
            // eslint-disable-next-line sort-keys
            currentColor ||= { r: 0, g: 0, b: 0, a: 0 };

            this.dialog.values =
                convertToScheme(currentColor, this.dialog.scheme || ColorPickerController.DEFAULT_SCHEME) || null;
        }
    }

    private hsvChanged() {
        this.render();
    }

    private textValueChanged() {
        if (this.dialog.textValue) {
            this.updateFromTextValue(this.dialog.textValue);
        }
    }

    private updateFromTextValue(colorString: string) {
        const values = parseColor(colorString);

        if (values) {
            const noUpdateRequired = areColorsEqual(values, this.dialog.values);
            if (noUpdateRequired) {
                return;
            }

            const scheme = getColorScheme(values);
            switch (scheme) {
                case 'rgb':
                case 'hsl':
                case 'cmyk':
                    this.dialog.scheme = scheme;
                    this.dialog.values = values;
                    break;

                case 'hsv':
                    this.dialog.scheme = 'hsl';
                    this.dialog.values = convertToScheme(values, this.dialog.scheme) || null;
                    break;

                default:
                    // Do nothing
                    return;
            }
            this.render();
        }
    }

    private renderButton() {
        if (!this.canvas) {
            return;
        }

        const { height, width } = this.canvas;
        const context = this.canvas.getContext('2d');
        if (context) {
            this.renderButtonTransparency(context, width, height);
            this.renderButtonColor(context, width, height);
        }
    }

    private windowElementClicked(event: Event) {
        return this.$timeout(() => {
            if (!this.dialog.visible) {
                return;
            }

            let focusedElement: JQuery | ng.IAugmentedJQuery = angular.element(event.target!);

            if (!this.element) {
                if (!this.keepOpen) {
                    this.dialog.visible = false;
                }
                return;
            }

            let previousElement: Element | null = null;
            let isElementOnDialog = false;

            // Ignore this eslint rule because it's too difficult to work out the original intention
            // eslint-disable-next-line eqeqeq
            while (focusedElement && focusedElement[0] != previousElement && !isElementOnDialog) {
                isElementOnDialog = focusedElement[0] === this.element[0];
                previousElement = focusedElement[0];
                focusedElement = focusedElement.parent();
            }

            if (!isElementOnDialog && !this.keepOpen) {
                this.dialog.visible = false;
            }
        });
    }

    // eslint-disable-next-line max-statements
    private renderSatLumSpectrum() {
        if (!this.dialog.satLumCanvas) {
            return;
        }

        const context = this.dialog.satLumCanvas.getContext('2d');
        if (context) {
            const originX = 0;
            const originY = 0;
            const { height, width } = this.dialog.satLumCanvas;

            const { h } = this.dialog.hsv || ColorPickerController.TRANSPARENT_BLACK_HSV;

            const imageData = context.createImageData(width, height);
            const data = imageData.data;

            for (let y = 0; y < height; y++) {
                const v = 1 - y / (height - 1);

                for (let x = 0; x < width; x++) {
                    const index = y * width * 4 + x * 4;
                    const s = x / (width - 1);
                    const rgb = colorSchemeHelpers.hsv.toRgb({ h, s, v });

                    data[index] = rgb.r;
                    data[index + 1] = rgb.g;
                    data[index + 2] = rgb.b;
                    data[index + 3] = 255;
                }
            }

            context.putImageData(imageData, originX, originY);

            if (this.dialog.hsv) {
                const y = height * (1 - this.dialog.hsv.v);
                const x = width * this.dialog.hsv.s;
                this.renderSelectedPoint(context, x, y);
            }
        }
    }

    private renderSelectedPoint(context: CanvasRenderingContext2D, x: number, y: number) {
        context.save();
        try {
            context.strokeStyle = '#fff';
            context.beginPath();
            context.lineWidth = 3;
            context.arc(x, y, 5, 0, 360);
            context.stroke();
        } finally {
            context.restore();
        }
    }

    private renderHueSpectrum() {
        if (!this.dialog.hueCanvas) {
            return;
        }

        const { height, width } = this.dialog.hueCanvas;

        const context = this.dialog.hueCanvas.getContext('2d');
        if (context) {
            context.fillStyle = '#fff';
            context.fillRect(0, 0, width, height);

            context.lineWidth = 1;

            for (let heightPos = 0; heightPos < height; heightPos++) {
                const hue = Math.round(360 * (heightPos / (height - 1)));

                const color = `hsl(${Math.round(hue)}, 100%, 50%)`;

                context.strokeStyle = color;

                context.beginPath();
                context.moveTo(0, heightPos);
                context.lineTo(width, heightPos);
                context.stroke();
            }

            const x = width / 2;
            const y = ((this.dialog.hsv ? this.dialog.hsv.h : 0) / 360) * height;

            this.renderSelectedPoint(context, x, y);
        }
    }

    private renderDialog() {
        if (!this.dialog.visible) {
            return;
        }

        // If we want to keep the colour picker open, render the picker over where the colour button should be.
        if (this.keepOpen && this.element) {
            const $button = this.element.find('.color-picker__button');
            const buttonPosition = $button.position();
            this.dialog.position = {
                x: buttonPosition.left,
                y: buttonPosition.top,
            };
        }

        this.renderHueSpectrum();
        this.renderSatLumSpectrum();
    }

    /**
     * Generates a unique name to use in our form inputs
     *
     * @returns The form name
     */
    private static generateFormName(): string {
        return `color-picker-${new Date().valueOf()}`;
    }

    private elementUpdated() {
        if (this.element) {
            this.canvas = this.element.find('.color-picker__button__color-preview')[0] as HTMLCanvasElement;
            this.dialog.satLumCanvas = this.element.find(
                '.color-picker__dialog__spectrums__sat-lum',
            )[0] as HTMLCanvasElement;
            this.dialog.hueCanvas = this.element.find('.color-picker__dialog__spectrums__hue')[0] as HTMLCanvasElement;
        } else {
            this.canvas = null;
            this.dialog.satLumCanvas = null;
            this.dialog.hueCanvas = null;
        }
    }

    private valuesChanged() {
        if (!this.dialog.values) {
            return;
        }

        this.dialog.scheme ||= getColorScheme(this.dialog.values);

        if (this.dialog.scheme) {
            const helper = colorSchemeHelpers.byName(this.dialog.scheme);

            // No match? Try sanitising the values
            if (!helper.isMatch(this.dialog.values)) {
                this.dialog.values = helper.sanitise(this.dialog.values);
            }

            const color = helper.toString(this.dialog.values);

            if (color) {
                this.color = color;
            }
        }
    }

    private satLumCoordToHsv(x: number, y: number): HSV {
        if (this.dialog.satLumCanvas) {
            const { height, width } = this.dialog.satLumCanvas;

            // eslint-disable-next-line no-param-reassign
            x = Math.max(0, Math.min(width, x));
            // eslint-disable-next-line no-param-reassign
            y = Math.max(0, Math.min(height, y));

            const s = x / width;
            const v = 1 - y / height;

            const { a, h } = this.dialog.hsv || ColorPickerController.TRANSPARENT_BLACK_HSV;

            // eslint-disable-next-line sort-keys
            const hsv = { h, s, v, a };

            return hsv;
        } else {
            return { h: 0, s: 0, v: 0 };
        }
    }

    private updateSatLumFromXY(x: number, y: number) {
        this.dialog.hsv = this.satLumCoordToHsv(x, y);
        this.dialog.values =
            convertToScheme(this.dialog.hsv, this.dialog.scheme || ColorPickerController.DEFAULT_SCHEME) || null;
    }
}


interface IColorPickerScope extends ng.IScope {
    ctrl: ColorPickerController;
}

export const dsColorPickerConfig = {
    restrict: 'E',
    scope: {
        color: '=',
        customSwatches: '=?',
        keepOpen: OptionalTwoWayBinding,
    },
};

function ColorPickerDirective(): ng.IDirective<IColorPickerScope> {
    return {
        templateUrl: '/partials/common/colorPickerDirective/template',
        ...dsColorPickerConfig,
        bindToController: true,
        controller: ColorPickerController,
        controllerAs: 'ctrl',
        link: (scope: IColorPickerScope, element: JQuery) => {
            scope.ctrl.element = element;
        },
    };
}

export const dsColorPickerSID = 'dsColorPicker';

angular.module('app').directive(dsColorPickerSID, [ColorPickerDirective]);
