import { DSButton, DSTypography } from '@deltasierra/components';
import { useToggle } from '@deltasierra/react-hooks';
import { t } from '@deltasierra/shared';
import * as React from 'react';
import { MoreLocationsModal } from './MoreLocationsModal';

export type MoreLocationsButtonProps = {
    disabled?: boolean;
    postArtifactId: string;
    render?: (params: { toggle: (toggle: 'toggle' | 'toggleOff' | 'toggleOn') => void }) => React.ReactNode;
};

export const MoreLocationsButton: React.FC<MoreLocationsButtonProps> = props => {
    const [isOpen, toggle] = useToggle();
    return (
        <>
            {props.render
                ? props.render({ toggle })
             : (
                <DefaultMoreLocationsButton disabled={props.disabled} onClick={() => toggle('toggle')} />
            )}
            <MoreLocationsModal
                isOpen={isOpen}
                postArtifactId={props.postArtifactId}
                onClose={() => toggle('toggleOff')}
            />
        </>
    );
};

MoreLocationsButton.displayName = 'MoreLocationsButton';

const DefaultMoreLocationsButton: React.FC<{ disabled?: boolean; onClick: () => void }> = ({ disabled, onClick }) => (
    <DSButton color="primary" disabled={disabled} style={{ marginBottom: '5px' }} variant="text" onClick={onClick}>
        <DSTypography color="textSecondary" component="div" variant="body1">
            {t('COMMON.SHOW_ALL')}
        </DSTypography>
    </DSButton>
);

DefaultMoreLocationsButton.displayName = 'DefaultMoreLocationsButton';
