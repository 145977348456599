/// <reference path="../../../typings/browser.d.ts" />
import {
    AnyError,
    ApiRequestData,
    ApiRoute,
    ApiRouteUrl,
    assertNever,
    BaseRouteData,
    convertDateStringsToDates,
    HttpMethod,
    ObjectOrVoid,
    ServerRoute,
    ServerRouteUrl,
} from '@deltasierra/shared';
import type { IHttpPromise, IHttpPromiseCallbackArg, IHttpService, IPromise, IRequestShortcutConfig } from 'angular';
import { Observable } from 'rxjs';
import { ajax, AjaxError, AjaxTimeoutError } from 'rxjs/ajax';
import { catchError, map } from 'rxjs/operators';

export function getData<T>(response: IHttpPromiseCallbackArg<T>): T {
    return response.data!;
}

function httpMethodToString(httpMethod: HttpMethod): string {
    switch (httpMethod) {
        case HttpMethod.Delete:
            return 'delete';
        case HttpMethod.Get:
            return 'get';
        case HttpMethod.Head:
            return 'head';
        case HttpMethod.Options:
            return 'options'; // NOTE: may not actually be accepted by $http!
        case HttpMethod.Patch:
            return 'patch';
        case HttpMethod.Post:
            return 'post';
        case HttpMethod.Put:
            return 'put';
        default:
            throw assertNever(httpMethod);
    }
}

export function invokeApiRouteReturningHttpPromise<
    TResponse extends ObjectOrVoid,
    TParams extends ObjectOrVoid,
    TBody extends ObjectOrVoid,
    TQuery extends ObjectOrVoid,
>(
    $http: IHttpService,
    apiRoute: ApiRoute<TResponse, TParams, TBody, TQuery>,
    requestData: ApiRequestData<TParams, TBody, TQuery>,
    config: IRequestShortcutConfig = {},
): IHttpPromise<TResponse> {
    if (requestData.body) {
        config.data = requestData.body;
    }
    const url: ApiRouteUrl = apiRoute.constructUrlWithQueryString(requestData);
    return $http<TResponse>({
        method: httpMethodToString(apiRoute.httpMethod),
        url,
        ...config,
    });
}

export function invokeApiRoute<
    TResponse extends ObjectOrVoid,
    TParams extends ObjectOrVoid,
    TBody extends ObjectOrVoid,
    TQuery extends ObjectOrVoid,
>(
    $http: IHttpService,
    apiRoute: ApiRoute<TResponse, TParams, TBody, TQuery>,
    requestData: ApiRequestData<TParams, TBody, TQuery>,
    config: IRequestShortcutConfig = {},
): IPromise<TResponse> {
    return invokeApiRouteReturningHttpPromise($http, apiRoute, requestData, config).then(getData);
}

export function getServerRoute<TParams extends ObjectOrVoid, TQuery extends ObjectOrVoid>(
    serverRoute: ServerRoute<TParams, TQuery>,
    requestData: BaseRouteData<TParams, TQuery>,
): ServerRouteUrl {
    const url: ServerRouteUrl = serverRoute.constructUrlWithQueryString(requestData);
    return url;
}

/**
 * Hit the backend as an observable!
 *
 * @example
 * invokeApiRouteAsObservable(AssetLibraryEndpoints.getCollections, justParams({ locationId: action.payload })).pipe(
 *   map(collections => ...),
 * );
 * @param apiRoute - The API endpoint to hit
 * @param requestData - Any request data to send along
 * @returns Observable
 */
export function invokeApiRouteAsObservable<
    TResponse extends ObjectOrVoid,
    TParams extends ObjectOrVoid,
    TBody extends ObjectOrVoid,
    TQuery extends ObjectOrVoid,
>(
    apiRoute: ApiRoute<TResponse, TParams, TBody, TQuery>,
    requestData: ApiRequestData<TParams, TBody, TQuery>,
): Observable<TResponse> {
    return ajax({
        body: requestData.body,
        headers: {
            'Content-Type': 'application/json',
        },
        method: httpMethodToString(apiRoute.httpMethod),
        responseType: 'json',
        url: apiRoute.constructUrlWithQueryString(requestData),
    }).pipe(
        map(response => response.response as TResponse),
        map(data => convertDateStringsToDates(data, true) as TResponse),
        catchError((error: AjaxError | AnyError) => {
            if (isRxjsAjaxError(error)) {
                throw error.response;
            } else {
                throw error;
            }
        }),
    );
}

function isRxjsAjaxError(error: AnyError): error is AjaxError | AjaxTimeoutError {
    const ajaxErrorNames = ['AjaxError', 'AjaxTimeoutError'];
    return ajaxErrorNames.indexOf(error.name) >= 0;
}
