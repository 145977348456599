import { MvMultipleLocationMultiImagePublishCtrl } from './multiImagePublishCtrl';

export const multipleLocationMultiImagePublishConfig = {
    bindToController: {
        chosenLocations: '=',
        commonData: '=',
        contentBuilder: '=',
        finishCallback: '&',
        originalLocation: '=',
        publishCtrl: '=',
        publishMultiImageCallback: '&',
    },
    controller: MvMultipleLocationMultiImagePublishCtrl,
    controllerAs: 'ctrl',
    restrict: 'E',
    scope: {},
    templateUrl: '/partials/contentBuilder/publish/multipleLocationPublish',
};

function createDirective(): ng.IDirective<ng.IScope> {
    return multipleLocationMultiImagePublishConfig;
}

export const multipleLocationMultiImageMPublishSID = 'multipleLocationMultiImagePublish';

angular.module('app').directive(multipleLocationMultiImageMPublishSID, [createDirective]);
