/// <reference path="../../../../typings/browser.d.ts" />

import { ExternalService, User } from '@deltasierra/shared';


export enum PublishTypes {
    Default,
    Four51,
    FitwareMemberEngager,
    Stripe
}

interface PublishResultNotification {
    assignedUser: User;
    phone?: string;
}

export interface PublishResultOptions {
    notification?: PublishResultNotification;
    extraInfo?: string;
    publishType?: PublishTypes;
}

export class PublishResult {
    public readonly notification?: PublishResultNotification;

    public readonly extraInfo?: string;

    public readonly publishType?: PublishTypes;

    constructor(
        public readonly platform: ExternalService,
        public readonly scheduledTime: Date | null,
        options: PublishResultOptions = {},
    ) {
        this.notification = options.notification;
        this.extraInfo = options.extraInfo;
        this.publishType = options.publishType;
    }
}
