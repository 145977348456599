import { DSButton, Translate } from '@deltasierra/components';
import * as React from 'react';
import { useAuthRoles } from '../../../common/componentUtils/angularServiceHooks';
import { withAngularIntegration } from '../../../common/componentUtils/reactComponentRegistration';
import { useConfig } from '../../../common/config';

export function PlannerCampaignsLinkButton(): JSX.Element {
    const [config] = useConfig();
    const { isAdmin, isManager } = useAuthRoles();
    const showButton = config?.features.campaigns.enabled && (isManager || isAdmin);
    return (
        <>
            {showButton && (
                <a href="/plan/campaigns">
                    <DSButton color="primary">
                        <Translate keyId="PLAN.CAMPAIGNS.CAMPAIGNS" />
                    </DSButton>
                </a>
            )}
        </>
    );
}
PlannerCampaignsLinkButton.displayName = 'PlannerCampaignsLinkButton';

export const angularPlannerCampaignsLinkButton = withAngularIntegration(
    PlannerCampaignsLinkButton,
    'plannerCampaignsLinkButton',
    {},
);
