/// <reference path="../../_references.d.ts" />

import { GoogleAdWordsRecommendationsRowFooterController } from './controller';

export const GoogleAdWordsRecommendationsRowFooterDirectiveSID = 'dsGoogleAdwordsRecommendationsRowFooter';

function GoogleAdWordsRecommendationsRowFooterDirective() {
    return {
        templateUrl: '/partials/reports/googleAdWordsRecommendationsRowFooter/template',
        restrict: 'E',
        replace: true,
        scope: {
            entry: '<',
            reportTable: '<',
        },
        controller: GoogleAdWordsRecommendationsRowFooterController,
        controllerAs: 'ctrl',
        bindToController: true,
    };
}

angular.module('app').directive(GoogleAdWordsRecommendationsRowFooterDirectiveSID, [GoogleAdWordsRecommendationsRowFooterDirective]);
