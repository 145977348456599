/// <reference path="../../../../../typings/browser.d.ts" />
import { contains } from '@deltasierra/array-utilities';
import { PublishableService } from '@deltasierra/integrations/integration-types';
import {
    BuilderTemplateFormat,
    CLIENTS_TO_DISABLE_AUTOMATED_EMAILS,
    ClientId,
    LocationId,
    LocationIdHierarchy,
    assignedLocationToLocationIdHierarchy,
} from '@deltasierra/shared';
import { isNullOrUndefined } from '@deltasierra/type-utilities';
import { IScope } from 'angular';
import { MvAuth } from '../../../account/mvAuth';
import {
    $routeParamsSID,
    $scopeSID,
    $windowSID,
    IRouteParams,
    OneWayBinding,
    OptionalOneWayBinding,
    simpleComponent,
} from '../../../common/angularData';
import { InteractionUtils } from '../../../common/interactionUtils';
import { GraphqlService } from '../../../graphql/GraphqlService';
import { I18nService } from '../../../i18n';
import { PlannerUIService } from '../../../planner/plannerUIService';
import { GetIsClubReadyPublishingDisabled } from '../../../scheduledPosts/hooks/__graphqlTypes/GetIsClubReadyPublishingDisabled';
import { GET_IS_CLUB_READY_PUBLISHING_DISABLED } from '../../../scheduledPosts/hooks/use-disable-club-ready-publishing';
import { BuilderConstants, builderConstantsSID } from '../../builderConstants';
import { PublishResult } from '../../publish/publishResult';
import { PlatformAvailabilityAndAccess, PublishingPlatformsService } from '../../publish/publishingPlatformsService';
import { EmailBuilder } from '../emailBuilder';
import { EmailPublishData } from '../emailPublishData';

interface EmailBuilderPublishScopeExtraProperties {
    emailBuilder: EmailBuilder; // Required for the iframe directive.
}

export interface EmailBuilderPublishCtrlScope extends IScope, EmailBuilderPublishScopeExtraProperties {}

enum EmailBuilderPublishView {
    Platform,
    MultipleLocations,
    PublishToPlatform,
}

type FitwareAutomatedEmailPlatform = 'fitwareAutomatedEmail';

const MultipleLocationPublishingPlatforms = ['fitware', 'fitwareAutomatedEmail', 'clubReady'];

export class EmailBuilderPublishCtrl {
    public static readonly SID = 'EmailBuilderPublishCtrl';

    public static readonly $inject: string[] = [
        $scopeSID,
        $windowSID,
        $routeParamsSID,
        InteractionUtils.SID,
        MvAuth.SID,
        builderConstantsSID,
        PlannerUIService.SID,
        PublishingPlatformsService.SID,
        I18nService.SID,
        GraphqlService.SID,
    ];

    // Component Inputs
    public readonly emailBuilder!: EmailBuilder;

    public readonly publishData!: EmailPublishData;

    public readonly builderTemplateFormats!: BuilderTemplateFormat[];

    public isViaMemberEngager?: boolean;

    public view: EmailBuilderPublishView = EmailBuilderPublishView.Platform;

    public readonly EmailBuilderPublishView = EmailBuilderPublishView;

    public platform?: FitwareAutomatedEmailPlatform | PublishableService;

    public platformAccess?: PlatformAvailabilityAndAccess;

    public multipleLocationsData = {
        canAccessMultipleLocations: false,
        multipleLocationsRequired: false,
    };

    public fitwareAutomatedEmailCode?: string;

    public highlightingEnabled = false;

    public get isClubReadyDisabled(): boolean {
        return !this.hasLoadedClubreadyFlag || this._isClubReadyDisabled;
    }

    public readonly fetchMultipleLocationAccess = this.interactionUtils.createFuture(
        'fetch multiple location access',
        _context =>
            this.mvAuth.canAccessMultipleLocations().then(result => {
                this.multipleLocationsData.canAccessMultipleLocations = result.result;
            }),
    );

    public readonly fetchPlatformAccess = this.interactionUtils.createFuture(
        'fetch platform access',
        (context: {
            builderTemplateFormats: BuilderTemplateFormat[];
            clientId: ClientId;
            isAnimation: boolean;
            locationId: LocationId;
        }) =>
            this.publishingPlatformsService
                .fetchPlatformAccess(
                    context.locationId,
                    context.clientId,
                    context.builderTemplateFormats,
                    context.isAnimation,
                )
                .then(result => {
                    this.platformAccess = result;
                    return this.platformAccess;
                }),
    );
    /* eslint-enable no-invalid-this */

    private hasLoadedClubreadyFlag = false;

    private _isClubReadyDisabled = false;

    // eslint-disable-next-line max-params
    public constructor(
        protected readonly $scope: EmailBuilderPublishCtrlScope,
        protected readonly $window: ng.IWindowService,
        protected readonly $routeParams: IRouteParams,
        protected readonly interactionUtils: InteractionUtils,
        protected readonly mvAuth: MvAuth,
        protected readonly builderConstants: BuilderConstants,
        protected readonly plannerUIService: PlannerUIService,
        protected readonly publishingPlatformsService: PublishingPlatformsService,
        public readonly i18n: I18nService,
        protected readonly graphqlService: GraphqlService,
    ) {}

    public $onInit(): void {
        if (isNullOrUndefined(this.isViaMemberEngager)) {
            this.isViaMemberEngager = false;
        }
        void this.refreshPlatformAccess();
        void this.fetchMultipleLocationAccess.run({});
        void this.getIsClubReadyDisabledConfig();
        const windowFocused = () => {
            if (this.view === EmailBuilderPublishView.Platform) {
                return this.refreshPlatformAccess();
            }
            return Promise.resolve();
        };
        this.$window.addEventListener('focus', windowFocused);
        this.$scope.$on('$destroy', () => {
            this.$window.removeEventListener('focus', windowFocused);
        });
        if (this.$routeParams.automatedEmailCode) {
            this.fitwareAutomatedEmailCode = this.$routeParams.automatedEmailCode;
            this.platform = 'fitwareAutomatedEmail';
        }

        this.cancelPublishing = this.cancelPublishing.bind(this);
        this.finishPublishing = this.finishPublishing.bind(this);
    }

    public cancelPublishing(): void {
        this.$scope.$emit(this.builderConstants.EVENTS.PUBLISH_CANCEL);
    }

    public finishPublishing(publishResult: PublishResult): void {
        this.$scope.$emit(this.builderConstants.EVENTS.PUBLISH_FINISH, publishResult);
    }

    public constructPlannerUrl(): string | undefined {
        if (this.publishData.plannerDetails) {
            return this.plannerUIService.getPlannerUrl(this.publishData.plannerDetails);
        } else {
            return undefined;
        }
    }

    public refreshPlatformAccess(): ng.IPromise<void> {
        return this.fetchPlatformAccess
            .run({
                builderTemplateFormats: this.builderTemplateFormats,
                clientId: this.publishData.location.clientId,
                isAnimation: false,
                locationId: this.publishData.location.id,
            })
            .then(platformAccess => platformAccess.run());
    }

    public isAnyPlatformAccessCheckLoading(): boolean {
        return !!this.platformAccess && this.platformAccess.isAnyPlatformAccessCheckLoading();
    }

    public hasNoPlatformsAvailable(): boolean {
        return !this.platformAccess || !this.platformAccess.hasAnyPlatforms();
    }

    public choosePlatform(): void {
        if (this.platform) {
            if (
                this.multipleLocationsData.multipleLocationsRequired &&
                this.multipleLocationsData.canAccessMultipleLocations &&
                this.isMultipleLocationsSupported()
            ) {
                this.view = EmailBuilderPublishView.MultipleLocations;
            } else {
                this.publishData.locations = [assignedLocationToLocationIdHierarchy(this.publishData.location)];
                this.view = EmailBuilderPublishView.PublishToPlatform;
            }
        }
    }

    public chooseLocations(locations: LocationIdHierarchy[]): void {
        this.publishData.locations = locations;
        this.view = EmailBuilderPublishView.PublishToPlatform;
    }

    public isMultipleLocationsSupported(): boolean {
        return (
            !!this.platform &&
            !this.publishData.plannerId && // You can't multi-location publish for a planner event from one location.
            contains(MultipleLocationPublishingPlatforms, this.platform) // Supported platforms
        );
    }

    public shouldOnlyShowFitwareAutomatedEmailPlatform(): boolean {
        return !!this.fitwareAutomatedEmailCode;
    }

    // Check list of hardcoded Client IDs to disable Member Engager automated emails for. https://digitalstack.atlassian.net/browse/DPS-471
    public shouldHideAutomatedEmailsForClient(): boolean {
        return CLIENTS_TO_DISABLE_AUTOMATED_EMAILS.includes(this.publishData.location.clientId);
    }

    public onGoBackFromPlatformPublish(): void {
        this.view = EmailBuilderPublishView.Platform;
    }

    private async getIsClubReadyDisabledConfig(): Promise<void> {
        const gqlClient = this.graphqlService.getClient();
        const configResult = await gqlClient.query<GetIsClubReadyPublishingDisabled>({
            fetchPolicy: 'cache-first',
            notifyOnNetworkStatusChange: true,
            query: GET_IS_CLUB_READY_PUBLISHING_DISABLED,
        });
        if (configResult.errors) {
            throw new Error('Failed to fetch builder config');
        }

        this.hasLoadedClubreadyFlag = true;

        if (configResult.data) {
            this._isClubReadyDisabled = configResult.data.config.clubReadyConfig.disableClubReadyPublishing;
        }
    }
}

export const emailBuilderPublishSID = 'emailBuilderPublish';

export const emailBuilderPublishConfig = simpleComponent(
    EmailBuilderPublishCtrl,
    '/partials/contentBuilder/email/publish/emailBuilderPublish',
    {
        builderTemplateFormats: OneWayBinding,
        emailBuilder: OneWayBinding,
        isViaMemberEngager: OptionalOneWayBinding,
        publishData: OneWayBinding,
    },
);

angular.module('app').directive(emailBuilderPublishSID, [() => emailBuilderPublishConfig]);
