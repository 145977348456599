import { Loading } from '@deltasierra/components';
import {
    justParams,
    t,
    BuilderTemplateApi,
    BuilderTemplateId,
    BuilderTemplateInfo,
} from '@deltasierra/shared';
import moment from 'moment-timezone';
import * as React from 'react';
import { OneWayBinding } from '../../common/angularData';
import { useAngularServiceContext } from '../../common/componentUtils/angularServiceContexts';
import { DataLoadingSuspense } from '../../common/componentUtils/asyncComponents';
import { withAngularIntegration } from '../../common/componentUtils/reactComponentRegistration';
import { useFetchFromApiEndpoint } from '../../common/futures';

type BuilderTemplateInfoPanelProp = {
    templateId: BuilderTemplateId;
};

const BuilderTemplateInfoPanel: React.FunctionComponent<BuilderTemplateInfoPanelProp> = ({ templateId }) => {
    const i18n = useAngularServiceContext('I18nService');
    const mvIdentity = useAngularServiceContext('mvIdentity');
    const currentUser = mvIdentity.currentUser;

    const requestData = React.useMemo(() => justParams({ templateId }), [templateId]);
    const [getBuilderTemplateResponse] = useFetchFromApiEndpoint(
        i18n.text.build.fetchingBuilderTemplateInfo(),
        BuilderTemplateApi.getBuilderTemplateInfo,
        requestData,
    );

    return (
        <DataLoadingSuspense loading={() => <Loading />} waitFor={[getBuilderTemplateResponse]}>
            {(templateInfo: BuilderTemplateInfo | null) => (
                <div>
                    <div className="form-group">
                        <label>{`${t('COMMON.CREATED')}:`}</label>
                        {templateInfo?.createdBy && <div>{templateInfo.createdBy}</div>}
                        {templateInfo && currentUser && (
                            <div>
                                {moment
                                    .tz(templateInfo.createdAt.toUTCString(), currentUser.timezone)
                                    .format('YYYY-MM-DD HH:mm')}
                            </div>
                        )}
                        {!templateInfo && <div>{'-'}</div>}
                    </div>
                    <div className="form-group">
                        <label>{`${t('COMMON.LAST_UPDATED')}:`}</label>
                        {templateInfo?.updatedBy && <div>{templateInfo.updatedBy}</div>}
                        {templateInfo && templateInfo.updatedAt && currentUser && (
                            <div>
                                {moment
                                    .tz(templateInfo.updatedAt.toUTCString(), currentUser.timezone)
                                    .format('YYYY-MM-DD HH:mm')}
                            </div>
                        )}
                        {!templateInfo && <div>{'-'}</div>}
                    </div>
                </div>
            )}
        </DataLoadingSuspense>
    );
};

export default withAngularIntegration(BuilderTemplateInfoPanel, 'builderTemplateInfoPanel', {
    templateId: OneWayBinding,
});
