import { justParams, ClientId, BuilderFontApi, BuilderFontConfig } from '@deltasierra/shared';


import { invokeApiRoute } from '../common/httpUtils';
import { $httpSID } from '../common/angularData';
import IHttpService = angular.IHttpService;
import IPromise = angular.IPromise;

export class BuilderFontApiClient {
    static SID = 'BuilderFontApiClient';

    static readonly $inject : string[] = [
        $httpSID,
    ];

    constructor(
        protected readonly $http : IHttpService,
    ) {

    }

    getFontsForClient(clientId: ClientId): IPromise<BuilderFontConfig> {
        return invokeApiRoute(
            this.$http,
            BuilderFontApi.getFontsForClient,
            justParams({
                clientId,
            }),
        );
    }
}

angular.module('app').service(BuilderFontApiClient.SID, BuilderFontApiClient);
