import { gql } from '@apollo/client';

export const LOCATION_FRAGMENT_FOR_LOCATIONS_CHECKLIST = gql`
    fragment LocationFragmentForLocationsChecklist on Location {
        id
        title
    }
`;

export const CLIENT_FRAGMENT_FOR_LOCATIONS_CHECKLIST = gql`
    fragment ClientFragmentForLocationsChecklist on Client {
        id
        title
    }
`;
