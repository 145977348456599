import * as React from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { SubChannel } from '@deltasierra/shared';
import { oneLine } from 'common-tags';
import { useAngularServiceContext } from '../../componentUtils/angularServiceContexts';
import {
    GetInstagramDirectScheduledContentCountForLocations,
    GetInstagramDirectScheduledContentCountForLocationsVariables,
} from './__graphqlTypes/GetInstagramDirectScheduledContentCountForLocations';
import { InstagramDirectRateLimitedLocations } from './InstagramDirectRateLimitedLocations';

const GET_INSTAGRAM_DIRECT_SCHEDULED_CONTENT_COUNT_FOR_LOCATIONS = gql`
    query GetInstagramDirectScheduledContentCountForLocations($locationIds: [ID!]!, $date: DateTime) {
        locations(ids: $locationIds) {
            edges {
                node {
                    id
                    title
                    instagram {
                        ... on Instagram {
                            id
                            rateLimit(input: { date: $date }) {
                                ... on InstagramRateLimit {
                                    direct {
                                        limitReached
                                        remaining
                                    }
                                }
                            }
                            account {
                                id
                            }
                        }
                    }
                }
            }
        }
    }
`;

type RateLimitedLocationsState = {
    failedLocationIds: string[];
    isLoading: boolean;
    isRateLimited: boolean;
    rateLimitedLocationIds: string[];
};

type LocationIdAndTitle = {
    id: string;
    title: string;
};

export const useRateLimitedLocations = (
    platform: string,
    locationIds: string[],
    subChannel?: SubChannel | null,
    date?: Date | null,
): [RateLimitedLocationsState, JSX.Element] => {
    const sentryService = useAngularServiceContext('SentryService');

    const [loadInstagramDirectRateLimit, { data, loading }] = useLazyQuery<
        GetInstagramDirectScheduledContentCountForLocations,
        GetInstagramDirectScheduledContentCountForLocationsVariables
    >(GET_INSTAGRAM_DIRECT_SCHEDULED_CONTENT_COUNT_FOR_LOCATIONS, {
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
        notifyOnNetworkStatusChange: true,
        variables: { date: date?.toISOString(), locationIds },
    });

    const locations: { rateLimited: LocationIdAndTitle[]; failed: LocationIdAndTitle[] } = React.useMemo(() => {
        if (!loading && data) {
            const postPerInstagramPageId: { [key: string]: number | undefined } = {};
            data.locations.edges.forEach(edge => {
                const instagramPageId =
                    edge.node.instagram.__typename === 'Instagram' && edge.node.instagram.account?.id;
                if (instagramPageId) {
                    const postCount = postPerInstagramPageId[instagramPageId];
                    postPerInstagramPageId[instagramPageId] = (postCount ?? 0) + 1;
                }
            });
            const rateLimitedLocations: LocationIdAndTitle[] = [];
            const failedLocations: LocationIdAndTitle[] = [];
            data.locations.edges.forEach(edge => {
                if (
                    edge.node.instagram.__typename === 'Instagram' &&
                    edge.node.instagram.account &&
                    edge.node.instagram.rateLimit.__typename === 'InstagramRateLimit'
                ) {
                    const instagramPageId = edge.node.instagram.account.id;
                    const postsRemaining = edge.node.instagram.rateLimit.direct.remaining;
                    const postsToPage = postPerInstagramPageId[instagramPageId] ?? 1;
                    if (edge.node.instagram.rateLimit.direct.limitReached || postsRemaining - postsToPage < 0) {
                        rateLimitedLocations.push({ id: edge.node.id, title: edge.node.title });
                        const captureObject = {
                            instagramPageId,
                            limitReached: edge.node.instagram.rateLimit.direct.limitReached,
                            postsRemaining,
                            postsToPage,
                        };
                        sentryService.captureException(
                            oneLine`API rate limit exceeded for location: ${edge.node.title}.
                            \n${JSON.stringify(captureObject)}`,
                            captureObject,
                        );
                    }
                } else {
                    failedLocations.push({ id: edge.node.id, title: edge.node.title });
                }
            });
            return { failed: failedLocations, rateLimited: rateLimitedLocations };
        }
        return { failed: [], rateLimited: [] };
    }, [data, loading, sentryService]);

    const isRateLimited = React.useMemo(() => locations.rateLimited.length > 0, [locations.rateLimited.length]);

    React.useEffect(() => {
        if (platform === 'instagram' && subChannel === 'direct') {
            loadInstagramDirectRateLimit();
        }
    }, [loadInstagramDirectRateLimit, platform, subChannel]);

    const render = React.useMemo(() => {
        if (platform === 'instagram' && subChannel === 'direct') {
            return (
                <InstagramDirectRateLimitedLocations
                    failedLocations={locations.failed}
                    isLoading={loading}
                    rateLimitedLocations={locations.rateLimited}
                />
            );
        } else {
            return <></>;
        }
    }, [platform, subChannel, locations.failed, locations.rateLimited, loading]);

    const result: [RateLimitedLocationsState, JSX.Element] = [
        {
            failedLocationIds: locations.failed.map(location => location.id),
            isLoading: loading,
            isRateLimited,
            rateLimitedLocationIds: locations.rateLimited.map(location => location.id),
        },
        render,
    ];
    return result;
};
