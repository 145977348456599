/// <reference path="../../../../../typings/browser.d.ts" />

import { Activity, Channel, NewChannelData, sanitiseChannelLabel, Untyped, PlatformId, Platform } from '@deltasierra/shared';


import { MvActivity } from '../../../activities/mvActivity';
import { $routeSID } from '../../../common/angularData';
import { DataUtils } from '../../../common/dataUtils';
import { InteractionUtils } from '../../../common/interactionUtils';
import { MvNotifier } from '../../../common/mvNotifier';
import { I18nService } from '../../../i18n/i18nService';

interface ChannelTypeEntry {
    label: string;
    cssClass: string;
}

export class ChannelAdderController {
    public static readonly $inject: string[] = [
        $routeSID,
        InteractionUtils.SID,
        DataUtils.SID,
        MvNotifier.SID,
        MvActivity.SID,
        I18nService.SID,
    ];

    public static SID = 'ChannelAdderController';

    public CHANNEL_TYPES = Object.freeze({
        GENERAL: {
            cssClass: '',
            label: 'general',
        },
        SELF_SERVICE: {
            cssClass: 'text-success',
            label: 'self service',
        },
        SUPPORTED: {
            cssClass: 'text-info',
            label: 'supported',
        },
    });

    // From directive:
    public buildingPlatforms!: Platform[];

    public activity!: Activity;

    public newChannel: NewChannelData | null = null;

    public readonly saveNewChannel = this.interactionUtils.createFuture(this.i18n.text.agency.client.activities.saveNewChannel(), () =>
        this.mvActivity.addChannel(this.activity.clientId, this.newChannel!).then((channel: Channel) => {
            this.mvNotifier.notify(this.i18n.text.agency.client.location.activities.migrateChannel.channelAdded());
            return this.$route.reload();
        }),
    );

    // eslint-disable-next-line max-params
    public constructor(
        private readonly $route: Untyped,
        private readonly interactionUtils: InteractionUtils,
        private readonly dataUtils: DataUtils,
        private readonly mvNotifier: MvNotifier,
        private readonly mvActivity: MvActivity,
        private readonly i18n: I18nService,
    ) {}

    // Need to declare like this (rather than method) because our
    // Current version of angular wouldn't allow binding or using an arrow function within the template?
    // Seems to have been fixed somewhere between angular 1.5.11 and 1.6.5
    // (See it working as a method fine here in 1.6.5: https://codepen.io/codeandcats/pen/eEzjVa/)
    public isChannelLabelUnique = (label: Untyped): boolean => {
        label = sanitiseChannelLabel(label);
        const channels = this.activity.channels || [];
        const isUnique = !channels.some(channel => label === sanitiseChannelLabel(channel.label));
        return isUnique;
    };

    public getChannelTypeLabel(channel: Channel): string {
        return this.getChannelType(channel).label;
    }

    public initNewChannel(channelType: ChannelTypeEntry): void {
        this.newChannel = {
            activityId: this.activity.id,
            isSelfService: channelType === this.CHANNEL_TYPES.SELF_SERVICE,
            isSupported: channelType === this.CHANNEL_TYPES.SUPPORTED,
            label: '',
            platformId: null,
        };
    }

    public cancel(): void {
        this.newChannel = null;
    }

    public prepopulateChannelLabel(): void {
        if (this.newChannel) {
            if (this.newChannel.platformId) {
                const platform: Platform | null = this.dataUtils.findBy(
                    'id',
                    this.buildingPlatforms,
                    PlatformId.from(this.newChannel.platformId),
                );
                if (platform) {
                    this.newChannel.label = platform.label;
                } else {
                    this.newChannel.label = '';
                }
            } else {
                this.newChannel.label = '';
            }
        }
    }

    private getChannelType(channel: Channel): ChannelTypeEntry {
        if (channel.isSelfService) {
            return this.CHANNEL_TYPES.SELF_SERVICE;
        } else if (channel.isSupported) {
            return this.CHANNEL_TYPES.SUPPORTED;
        } else {
            return this.CHANNEL_TYPES.GENERAL;
        }
    }
}

angular.module('app').controller(ChannelAdderController.SID, ChannelAdderController);
