import { InstagramPerformanceStats, LocationPeriod, HttpResponseCode } from '@deltasierra/shared';


import { addDifference, BaseMetricProviderService, Metric, InsufficientDataRecorded } from './common';

export class InstagramMetricProviderService extends BaseMetricProviderService {
    static SID = 'InstagramMetricProviderService';

    getMetricRows(params: LocationPeriod): ng.IPromise<Array<Metric<any>>> {
        const followers: Metric<number> = {
            label: this.i18n.text.dashboard.performanceSummary.metrics.instagramFollowers(),
            iconUrl: '/img/dashboard/icon-instagram.png',
            values: null,
            columnSettings: {
                default: {
                    decimals: 0,
                    filter: 'number',
                },
            },
        };

        return this.$http
            .get<InstagramPerformanceStats>(`/api/performance/instagram/${params.locationId}/${params.year}/${params.month}`)
            .then(res => {
                if (res.status == HttpResponseCode.NoContent) {
                    followers.error = new InsufficientDataRecorded();
                } else {
                    followers.values = addDifference(res.data!.followedByCount);
                }

                return [followers];
            }, this.getHttpErrorHandlerForMetrics(followers));
    }
}

angular.module('app').service(InstagramMetricProviderService.SID, InstagramMetricProviderService);
