/// <reference path="../../../../../../../typings/browser.d.ts" />
import { OneWayBinding, ExpressionBinding } from '../../../../../common/angularData';
import { PrintRequestDetailsController } from './controller';

export const printRequestDetailsSID = 'printRequestDetails';

export const printRequestDetailsConfig = {
    bindings: {
        didReviewArtwork: OneWayBinding,
        onClickBack: ExpressionBinding,
        onClickSecondaryReview: ExpressionBinding,
        paymentDisplayAmount: OneWayBinding,
        publishData: OneWayBinding,
        quantityDisplayAmount: OneWayBinding,
        requestDetails: OneWayBinding,
        skuDetails: OneWayBinding,
        showFullDetails: OneWayBinding,
    },
    controller: PrintRequestDetailsController,
    controllerAs: 'ctrl',
    templateUrl: '/partials/contentBuilder/publish/print/common/printRequestDetails/template',
    transclude: true,
};

angular.module('app').component(printRequestDetailsSID, printRequestDetailsConfig);
