import { formatSizeOption, PrintProvider } from '@deltasierra/shared';

import { SKUDetails } from '../../sku/basePublishPrintSKUController';
import { ExpressionCallback } from './../../../../../common/angularData';
import { PrintPublishData, PublishPrintRequestDetails } from './../../basePublishPrintController';

export class PrintRequestDetailsController {
    public static $inject: string[] = [];

    // Props
    public didReviewArtwork!: boolean;

    public onClickBack!: ExpressionCallback<void>;

    public onClickSecondaryReview!: ExpressionCallback<void>;

    public paymentDisplayAmount!: string;

    public publishData!: PrintPublishData;

    public quantityDisplayAmount!: string;

    public requestDetails!: PublishPrintRequestDetails;

    public skuDetails!: SKUDetails | null;

    public showFullDetails!: boolean;

    public getFormattedSizeOption(): string {
        return formatSizeOption(this.publishData.fileFormatChoice);
    }

    public getPrintProvider(): PrintProvider {
        return this.publishData.selectedPrintProvider!;
    }

    public hasShippingData(): boolean {
        const items = [
            this.requestDetails.shippingAttention,
            this.requestDetails.shippingCompanyName,
            this.requestDetails.shippingLineOne,
            this.requestDetails.shippingLineTwo,
            this.requestDetails.shippingLineThree,
        ];
        return items.some(Boolean);
    }
}
