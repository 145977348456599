import { gql } from '@apollo/client';

export const RESTART_BLOCKED_PUBLISH_MUTATION = gql`
    mutation RestartBlockedPublishMutation($input: RestartScheduledPublishesInput!) {
        restartScheduledPublishes(input: $input) {
            ... on RestartScheduledPublishesSuccess {
                scheduledPublishes {
                    id
                }
            }
            ... on RestartScheduledPublishesError {
                message
            }
        }
    }
`;

export const MARK_BLOCKED_PUBLISH_MUTATION = gql`
    mutation MarkBlockedPublishMutation($input: MarkScheduledPublishesInput!) {
        markScheduledPublishes(input: $input) {
            ... on MarkScheduledPublishesSuccess {
                scheduledPublishes {
                    id
                }
            }
            ... on MarkScheduledPublishesError {
                message
            }
        }
    }
`;
