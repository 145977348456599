import * as React from 'react';
import { AutocompleteBold, DSTextField, ErrorMessage, Translate } from '@deltasierra/components';
import { t } from '@deltasierra/shared';
import { useQuery } from '@apollo/client';
import { useCurrentClientState } from '../ClientPicker/hooks';
import { LOCATION_CATEGORIES_FOR_CLIENT } from './LocationCategoryPicker.query';
import {
    GetLocationCategoriesForClient,
    GetLocationCategoriesForClientVariables,
} from './__graphqlTypes/GetLocationCategoriesForClient';

type LocationCategoryPickerProps = {
    selectedCategoryId: string | null;
    onChange?: (value: string | null) => void;
    disabled?: boolean;
};

type LocationCategoryPickerWrappedProps = LocationCategoryPickerProps & {
    clientId: string;
};

function LocationCategoryPickerWrapped({
    clientId,
    disabled = false,
    onChange,
    selectedCategoryId,
}: LocationCategoryPickerWrappedProps): JSX.Element {
    const { data, loading } = useQuery<GetLocationCategoriesForClient, GetLocationCategoriesForClientVariables>(
        LOCATION_CATEGORIES_FOR_CLIENT,
        { variables: { clientId } },
    );

    const options = data?.client?.locationCategories.flatMap(category => category.categoryOptions) || [];
    const selectedValue = options.find(option => option.id === selectedCategoryId);

    React.useEffect(() => {
        if (options.length && !!selectedCategoryId && !options.some(option => option.id === selectedCategoryId)) {
            onChange?.(null);
        }
    });

    return (
        <>
            <AutocompleteBold
                disabled={disabled}
                getOptionLabel={option => option.label}
                loading={loading}
                options={options}
                renderInput={params => <DSTextField placeholder={t('COMMON.ALL_LOCATIONS')} {...params} />}
                renderOption={option => option.label}
                value={selectedValue ?? null}
                onChange={(_, value) => {
                    onChange?.(value?.id || null);
                }}
            />
        </>
    );
}

LocationCategoryPickerWrapped.displayName = 'LocationCategoryPickerWrapped';

export function LocationCategoryPicker({
    disabled,
    onChange,
    selectedCategoryId,
}: LocationCategoryPickerProps): JSX.Element {
    const clientId = useCurrentClientState();

    return clientId ? (
        <LocationCategoryPickerWrapped
            clientId={clientId}
            disabled={disabled}
            selectedCategoryId={selectedCategoryId}
            onChange={onChange}
        />
    ) : (
        <ErrorMessage message="">
            <Translate keyId="ERRORS.BRAND.NO_CLIENT_SELECTED" />
        </ErrorMessage>
    );
}

LocationCategoryPicker.displayName = 'LocationCategoryPicker';
