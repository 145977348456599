/* eslint-disable max-lines-per-function */
import { gql, useApolloClient, useMutation } from '@apollo/client';
import {
    DSButton,
    DSDialog,
    DSDialogActions,
    DSDialogContent,
    DSDialogTitle,
    DSFormControl,
    DSFormControlLabel,
    DSRadio,
    DSRadioGroup,
    DSTable,
    DSTableBody,
    HelpPopper,
    Loading,
    Translate,
} from '@deltasierra/components';
import { useRadioInput } from '@deltasierra/react-hooks';
import { t } from '@deltasierra/shared';
import * as React from 'react';
import { isOldFlowCreatedAtWithinSafeRange, isScheduledTimeWithinSafeRange } from '@deltasierra/integrations/clubready';
import { useAngularServiceContext } from '../../../common/componentUtils/angularServiceContexts';
import { ClubReadyPostArtifactScheduledWorkflowType } from '../../../../../__graphqlTypes/globalTypes';
import { ScheduledPostRow } from '../ScheduledPostRow';
import { MoreLocationsButton } from '../MoreLocationsButton';
import {
    DeletePostMutationForModal,
    DeletePostMutationForModalVariables,
} from './__graphqlTypes/DeletePostMutationForModal';
import {
    DeletePostMutationGroupForModal,
    DeletePostMutationGroupForModalVariables,
} from './__graphqlTypes/DeletePostMutationGroupForModal';
import { useWorkflow } from './use-workflow';
import { GetPostArtifactForModalsQueryPostArtifact, usePostArtifact } from './use-post-artifact';

const DELETE_POST_MUTATION = gql`
    mutation DeletePostMutationForModal($input: DeletePostArtifactsInput!) {
        deletePostArtifacts(input: $input) {
            __typename
            ... on DeletePostArtifactsPayload {
                workflow {
                    id
                    status {
                        message
                        state
                    }
                }
            }
        }
    }
`;

const DELETE_POST_GROUP_MUTATION = gql`
    mutation DeletePostMutationGroupForModal($input: DeletePostArtifactGroupInput!) {
        deletePostArtifactGroup(input: $input) {
            __typename
            ... on DeletePostArtifactGroupPayload {
                workflow {
                    id
                    status {
                        message
                        state
                    }
                }
            }
        }
    }
`;

export type DeleteScheduledPostModalProps = {
    isOpen: boolean;
    postId: string;
    onCancel?: () => void;
    onConfirmDelete?: () => void;
};

export const DeleteScheduledPostModal: React.FC<DeleteScheduledPostModalProps> = props => (
    <DSDialog maxWidth="md" open={props.isOpen}>
        {props.isOpen && <DeleteScheduledPostModalInnerWrapper {...props} />}
    </DSDialog>
);
DeleteScheduledPostModal.displayName = 'DeleteScheduledPostModal';

function DeleteScheduledPostModalInnerWrapper(props: DeleteScheduledPostModalProps): JSX.Element {
    const post = usePostArtifact(props.postId);

    if (!post) {
        return <Loading />;
    } else {
        return <DeleteScheduledPostModalInner {...props} post={post} />;
    }
}

export type DeleteScheduledPostModalInnerProps = {
    post: GetPostArtifactForModalsQueryPostArtifact;
    onCancel?: () => void;
    onConfirmDelete?: () => void;
};

export const DeleteScheduledPostModalInner: React.FC<DeleteScheduledPostModalInnerProps> = props => {
    const client = useApolloClient();

    const notifier = useAngularServiceContext('mvNotifier');

    const { onCancel, onConfirmDelete, post } = props;

    const [value, onChange] = useRadioInput(['one', 'all'], 'one');

    const isUsingScheduleService =
        post.meta?.__typename === 'ClubReadyPostArtifactMeta' &&
        post.meta.scheduledWorkflowType === ClubReadyPostArtifactScheduledWorkflowType.SCHEDULE_SERVICE;

    const isClubReadyPostWithinDeleteTimeRange = React.useMemo(() => {
        if (
            post.timestamps.scheduled &&
            post.timestamps.created &&
            post.platform.name === 'clubReady'
        ) {
            if (isUsingScheduleService && post.timestamps.scheduled) {
                return isScheduledTimeWithinSafeRange(new Date(post.timestamps.scheduled));
            }
            if (!isUsingScheduleService && post.timestamps.created) {
                return isOldFlowCreatedAtWithinSafeRange(new Date(post.timestamps.created));
            }
        }
        return false;
    }, [
        isUsingScheduleService,
        post.platform.name,
        post.timestamps.created,
        post.timestamps.scheduled,
    ]);

    const shouldDisableClubReadyPostDelete =
        post.platform.name === 'clubReady' && !isClubReadyPostWithinDeleteTimeRange;

    const [deletePost, { loading: postLoading }] = useMutation<
        DeletePostMutationForModal,
        DeletePostMutationForModalVariables
    >(DELETE_POST_MUTATION);
    const [deleteGroup, { loading: groupLoading }] = useMutation<
        DeletePostMutationGroupForModal,
        DeletePostMutationGroupForModalVariables
    >(DELETE_POST_GROUP_MUTATION);

    const [getStatus, { isRunning: isJobRunning }] = useWorkflow({
        onComplete: job => {
            const postIdInCache = client.cache.identify({ __typename: 'PostArtifact', id: post.id });
            client.cache.evict({ id: postIdInCache });
            client.cache.gc();
            notifier.success(t('SCHEDULE.PAGE.DIALOGS.DELETE.SUCCESSFULLY_DELETED_POST'));
            onConfirmDelete?.();
        },
        onError: error => {
            if (error) {
                // If we have a translated custom error message returned
                notifier.unexpectedError(error);
            } else {
                notifier.unexpectedError(t('SCHEDULE.PAGE.DIALOGS.DELETE.FAILED_TO_DELETE'));
            }
        },
    });

    const handleConfirmDelete = async () => {
        if (value === 'one') {
            const result = await deletePost({ variables: { input: { postArtifactIds: [post.id] } } });
            if (result.data?.deletePostArtifacts.__typename === 'DeletePostArtifactsPayload') {
                getStatus({ variables: { id: result.data.deletePostArtifacts.workflow.id } });
            } else {
                onConfirmDelete?.();
            }
        } else {
            const result = await deleteGroup({ variables: { input: { postArtifactGroupId: post.parent!.id } } });
            if (result.data?.deletePostArtifactGroup.__typename === 'DeletePostArtifactGroupPayload') {
                getStatus({ variables: { id: result.data.deletePostArtifactGroup.workflow.id } });
            } else {
                onConfirmDelete?.();
            }
        }
    };

    return (
        <>
            <DSDialogTitle>
                <Translate keyId="SCHEDULE.PAGE.DIALOGS.DELETE.TITLE" />
            </DSDialogTitle>
            <DSDialogContent>
                <DSTable>
                    <DSTableBody>
                        {/* Setting inactive to false so display looks good */}
                        <ScheduledPostRow
                            disableLinkToPost
                            disableReschedule
                            hideActions
                            hideLocationCount
                            post={post}
                        />
                    </DSTableBody>
                </DSTable>
                <br />
                <DSFormControl>
                    <DSRadioGroup value={value} onChange={onChange}>
                        <DSFormControlLabel
                            control={<DSRadio />}
                            label={t('SCHEDULE.PAGE.DIALOGS.DELETE.OPTION_FOR_SINGLE', {
                                location: post.location.title,
                            })}
                            value="one"
                        />
                        <span>
                            {(post.parent?.locationCount ?? 1) > 1 && (
                                <DSFormControlLabel
                                    control={<DSRadio />}
                                    disabled={!post.parent}
                                    label={t('SCHEDULE.PAGE.DIALOGS.DELETE.OPTION_FOR_MULTIPLE', {
                                        location: post.location.title,
                                        num: (post.parent?.locationCount ?? 1) - 1,
                                    })}
                                    value="all"
                                />
                            )}
                            {post.parent && post.parent.locations.edges.length > 1 && (
                                <MoreLocationsButton postArtifactId={post.id} />
                            )}
                        </span>
                    </DSRadioGroup>
                </DSFormControl>
            </DSDialogContent>
            <DSDialogActions>
                <DSButton variant="outlined" onClick={onCancel}>
                    <Translate keyId="COMMON.CANCEL" />
                </DSButton>
                <DSButton
                    color="error"
                    disabled={isJobRunning || postLoading || groupLoading || shouldDisableClubReadyPostDelete}
                    variant="contained"
                    onClick={handleConfirmDelete}
                >
                    <Translate keyId="SCHEDULE.PAGE.DIALOGS.DELETE.CONFIRM" />
                </DSButton>
                {shouldDisableClubReadyPostDelete && (
                    <HelpPopper margin="left" popperIcon="none">
                        {
                            isUsingScheduleService
                                ? (
                                    <Translate keyId="SCHEDULE.PAGE.DIALOGS.DELETE.DISABLED_SCHEDULING" />
                                ) : (
                                    <Translate keyId="SCHEDULE.PAGE.DIALOGS.DELETE.DISABLED_LEGACY" />
                                )
                        }
                    </HelpPopper>
                )}
            </DSDialogActions>
        </>
    );
};
DeleteScheduledPostModalInner.displayName = 'DeleteScheduledPostModalInner';
