import { SwitchField } from '@deltasierra/components';
import { noop } from '@deltasierra/object-utilities';
import * as React from 'react';
import { withAngularIntegration } from '../common/componentUtils/reactComponentRegistration';
import { OneWayBinding, OptionalExpressionBinding } from '../common/angularData';

export type ShowOldEmailTemplatesSwitchProps = {
    includeOldEmailTemplatesFallback: boolean;
    onChange?: (value: boolean) => void;
};

export const ShowOldEmailTemplatesSwitch: React.FC<ShowOldEmailTemplatesSwitchProps> = ({
    includeOldEmailTemplatesFallback,
    onChange = noop,
}) => (
    <SwitchField
        label={''}
        value={includeOldEmailTemplatesFallback}
        onChange={onChange}
    />
);
ShowOldEmailTemplatesSwitch.displayName = 'ShowOldEmailTemplatesSwitch';

export const ShowOldEmailTemplatesSwitchWithAngular = withAngularIntegration(
    ShowOldEmailTemplatesSwitch,
    'showOldEmailTemplatesSwitch',
    {
        includeOldEmailTemplatesFallback: OneWayBinding,
        onChange: OptionalExpressionBinding,
    },
);
