/// <reference path="../../../../typings/browser.d.ts" />
import { BasicUserDto, UserId } from '@deltasierra/shared';
import { InteractionUtils } from '../../common/interactionUtils';
import { MvClient } from '../../clients/mvClient';
import { DataUtils } from '../../common/dataUtils';
import { MvNotifier } from '../../common/mvNotifier';
import { I18nService } from '../../i18n/i18nService';
import { AgencyUserApiClient } from '../../agencies/agencyUserApiClient';
import { agencyTabNavEntries } from '../tabNavEntries';
import { Paging } from '../../common/paging';

interface BasicUserDtoWithSearchField extends BasicUserDto {
    fullText : string;
}

export class AgencyClientUsersController {
    public static SID = 'agencyClientUsersController';

    tabNavEntries = agencyTabNavEntries;

    users? : BasicUserDtoWithSearchField[];

    usersFiltered? : BasicUserDtoWithSearchField[];

    usersVisible? : BasicUserDtoWithSearchField[];

    getUsers = this.interactionUtils.createFuture(
        this.i18n.text.agency.users.interactionGetClientUsers(),
        () => {
            if (this.onlyShowOrphans) {
                return this.agencyUserApiClient.getOrphanClientUsers();
            } else {
                return this.agencyUserApiClient.getClientUsers();
            }
        },
    );

    deleteUser = this.interactionUtils.createFuture<any, { userId : UserId }>(
        this.i18n.text.agency.users.interactionDeleteUser(),
        (context : { userId : UserId }) => this.mvClient.deleteClientUser(context.userId),
    );

    onlyShowOrphans = false;

    userFilter : string | undefined;

    paging: Paging<BasicUserDtoWithSearchField> = new Paging<BasicUserDtoWithSearchField>({
        initialShowPerPage: 25,
    });

    static readonly $inject : string[] = [
        InteractionUtils.SID,
        DataUtils.SID,
        MvNotifier.SID,
        MvClient.SID,
        AgencyUserApiClient.SID,
        I18nService.SID,
    ];

    constructor(
        private interactionUtils : InteractionUtils,
        private dataUtils : DataUtils,
        private mvNotifier : MvNotifier,
        private mvClient : MvClient,
        private agencyUserApiClient : AgencyUserApiClient,
        private i18n : I18nService,
    ) {
        void this.loadUsers();
    }

    loadUsers() {
        return this.getUsers.run({})
            .then((users : BasicUserDto[]) => this.users = users.map(
                (user) : BasicUserDtoWithSearchField => ({
                        ...user,
                        fullText: `${user.firstName} ${user.lastName} ${user.username}`,
                    }),
            ));
    }

    clickDeleteUser(user : BasicUserDto) {
        return this.deleteUser.run({ userId: user.id })
            .then(() => {
                this.dataUtils.removeById(user.id, this.users!);
                this.mvNotifier.notify(this.i18n.text.agency.client.location.users.userEdit.userDeleted({ firstName: user.firstName, lastName: user.lastName }));
            });
    }

    onFilterChange() {
        this.paging.resetPage();
    }
}

angular.module('app').controller(AgencyClientUsersController.SID, AgencyClientUsersController);
