/// <reference path="../../../typings/browser.d.ts" />
import { I18nService } from '../i18n';
import { confirmModalSID, ConfirmModal } from './confirmModal';
import IScope = angular.IScope;
import IAugmentedJQuery = angular.IAugmentedJQuery;
import IAttributes = angular.IAttributes;

export const ngConfirmClickSID = 'ngConfirmClick';
export const ngConfirmClickConfig : ng.IDirective<ng.IScope> = {
    priority: -1,
    restrict: 'A',
};

angular.module('app').directive(ngConfirmClickSID, [confirmModalSID,
I18nService.SID,
    function (confirmModal : ConfirmModal, i18n: I18nService) {
        return {
            ...ngConfirmClickConfig,
            link(scope : IScope, element : IAugmentedJQuery, attrs : IAttributes) {
                element.bind('click', (e : JQueryMouseEventObject) => {
                    const message = attrs.ngConfirmClick;
                    if (message) {
                        e.stopImmediatePropagation();
                        e.preventDefault();
                        let title = attrs.ngConfirmTitle;
                        if (!title) {
                            title = i18n.text.common.areYouSure();
                        }
                        confirmModal.open(
                            title,
                            message,
                            () => {
                                const clickAction = attrs.ngClick;
                                scope.$eval(clickAction);
                            },
                        );
                    }
                });
            },
        };
    }]);
