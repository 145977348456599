import { convertDateStringsToDates } from '@deltasierra/shared';


export interface IModelUtils {
    convertDateStringsToDates<T>(input: T): Array<Date | T> | Date | T;
}

export class ModelUtils implements IModelUtils {
    public static readonly $inject: string[] = [];

    public convertDateStringsToDates<T>(input: T): Array<Date | T> | Date | T {
        return convertDateStringsToDates(input, true);
    }
}

export const SID = 'ModelUtils';

angular.module('app').service(SID, ModelUtils);
