import { MultiItemPicker } from '@deltasierra/components';
import { withAngularIntegration } from '../../../common/componentUtils/reactComponentRegistration';
import {
    ExpressionBinding,
    OneWayBinding,
    OptionalExpressionBinding,
    OptionalOneWayBinding,
} from '../../../common/angularData';

export default withAngularIntegration(MultiItemPicker, 'multiItemPicker', {
    disableAdd: OptionalOneWayBinding,
    disableNavigator: OptionalOneWayBinding,
    disableRemove: OptionalOneWayBinding,
    items: OneWayBinding,
    onAddNew: OptionalExpressionBinding,
    onChange: ExpressionBinding,
    onDrop: OptionalExpressionBinding,
    onRemove: OptionalExpressionBinding,
    renderNotifier: OptionalOneWayBinding,
    thumbnailSize: OptionalOneWayBinding,
    value: OneWayBinding,
});
