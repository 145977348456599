import { LightboxActionType, LightboxModal, useLightboxDispatch, useLightboxState } from '@deltasierra/components';
import * as React from 'react';
import { DateTime } from 'luxon';
import { gql, useMutation } from '@apollo/client';
import { rectifyS3UrlWithFilename } from '../../../directives/dsDownloadLink';
import { useUrlParameter } from '../../hooks/useUrlParameter';
import { useRouter } from '../../../common/routes';
import { useClipboard } from '../../../common/hooks/useClipboard';
import { TrackAssetDownload, TrackAssetDownloadVariables } from './__graphqlTypes/TrackAssetDownload';

export const TRACK_ASSET_DOWNLOAD_MUTATION = gql`
    mutation TrackAssetDownload($input: TrackAssetLibraryEventInput!) {
        trackAssetDownload(input: $input) {
            ... on TrackAssetLibraryEventSuccess {
                success
            }
            ... on TrackAssetLibraryEventError {
                message
            }
        }
    }
`;

export type CollectionAssetLightboxComponentProps = {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
};

export function CollectionAssetLightboxComponent({
    isOpen,
    setIsOpen,
}: CollectionAssetLightboxComponentProps): JSX.Element {
    const { copyToClipboard } = useClipboard();
    const router = useRouter();
    const [urlParameter, setUrlParameter] = useUrlParameter('asset');

    const [trackAssetDownload] = useMutation<
        TrackAssetDownload,
        TrackAssetDownloadVariables
    >(TRACK_ASSET_DOWNLOAD_MUTATION);

    /**
     * URL paramter logic should only be applied when in the asset library, not when
     * accessing it via the email or content builder
     */
    const shouldUseUrlParams = React.useMemo(() => {
        const parts = router.url.split('?');
        const urlPart = parts[0];
        return urlPart.endsWith('/assets/view');
    }, [router]);

    const [hasBeenOpened, setHasBeenOpened] = React.useState<boolean>(isOpen);

    const dispatch = useLightboxDispatch();
    const state = useLightboxState();
    const currentElement = state.elements[state.index];
    const downloadUrl = currentElement ? rectifyS3UrlWithFilename(currentElement.url, currentElement.title) : '';
    const mediaElementRef = React.useRef<HTMLDivElement | null>(null);

    // Track whether is has been open
    React.useEffect(() => {
        if (isOpen) {
            setHasBeenOpened(true);
        }
    }, [isOpen, setHasBeenOpened]);

    /**
     * This should only happen the first time the modal is loaded,
     * if the URL parameter is set, then we open up the asset that it is set to
     */
    React.useEffect(() => {
        if (shouldUseUrlParams) {
            if (!isOpen && !hasBeenOpened && urlParameter) {
                // Find the Asset from the url parameter
                const assetIndex = state.elements.findIndex(element => element.key === urlParameter);
                // If it matches in the list
                if (assetIndex >= 0) {
                    dispatch({ payload: assetIndex, type: LightboxActionType.SetIndex });
                    setIsOpen(true);
                } else {
                    setUrlParameter(undefined);
                }
            }
        }
    }, [
        currentElement,
        dispatch,
        isOpen,
        hasBeenOpened,
        setIsOpen,
        state,
        urlParameter,
        setUrlParameter,
        shouldUseUrlParams,
    ]);

    /**
     * Every time the modal is opened, then we want to update the URL
     */
    React.useEffect(() => {
        if (shouldUseUrlParams) {
            if (isOpen && currentElement) {
                setUrlParameter(currentElement.key);
            }
        }
    }, [isOpen, setUrlParameter, currentElement, shouldUseUrlParams]);

    const closeModal = React.useCallback(() => {
        if (shouldUseUrlParams) {
            setUrlParameter(undefined);
        }
        setIsOpen(false);
    }, [setUrlParameter, setIsOpen, shouldUseUrlParams]);

    return (
        <LightboxModal
            elementRef={mediaElementRef}
            isOpen={isOpen}
            onRequestClose={closeModal}
        >
            <LightboxModal.DefaultButtons
                showCopyUrlButton={shouldUseUrlParams}
                onClose={closeModal}
                onCopyUrl={async () => {
                    await copyToClipboard(window.location.href, 'asset link');
                }}
                onDownloadFile={() => {
                    window.open(downloadUrl);
                    void trackAssetDownload({
                        variables: {
                            input: {
                                browser: window.navigator.userAgent,
                                key: currentElement.key,
                                timestamp: DateTime.utc().toString(),
                                timezone: DateTime.now().zoneName,
                            },
                        },
                    });
                }}
                onFullscreen={() => {
                    if (!document.fullscreenElement) {
                        void mediaElementRef.current?.requestFullscreen();
                    } else {
                        void document.exitFullscreen();
                    }
                }}
            />
        </LightboxModal>
    );
}
CollectionAssetLightboxComponent.displayName = 'CollectionAssetLightboxComponent';
