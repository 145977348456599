/// <reference path="../../../typings/browser.d.ts" />

import { Comment, Currency } from '@deltasierra/shared';
import { $scopeSID } from '../common/angularData';
import { $modalInstanceSID } from '../common/angularUIBootstrapData';
import { DataUtils } from '../common/dataUtils';
import { InteractionUtils } from '../common/interactionUtils';
import { CurrencyService } from '../payments/currencyService';
import { ModalInstance } from '../typings/angularUIBootstrap/modalService';
import { MvSpecialRequest } from './mvSpecialRequest';
import IScope = angular.IScope;
import IPromise = angular.IPromise;

export class SpecialRequestQuote {
    description?: string;

    constructor(public currency: string, public amount: number | null) {
        this.currency = currency;
        this.amount = amount;
    }
}

export interface MvSpecialRequestQuoteCtrlScopeInputs {
    quote: SpecialRequestQuote;
    totalPaid: number;
    specialRequestId: number;
    originalAmount: number | null;
}

export interface MvSpecialRequestQuoteCtrlScope extends IScope, MvSpecialRequestQuoteCtrlScopeInputs {
    CURRENCIES: ReadonlyArray<Currency>;
    loading: {
        sendQuote: boolean;
    };
    sendQuote(): IPromise<void>;
    cancel(): void;
    getDifference(): number;
    getQuoteDifference(): number;
}

angular.module('app').controller('mvSpecialRequestQuoteCtrl', [
    $scopeSID,
    $modalInstanceSID,
    InteractionUtils.SID,
    MvSpecialRequest.SID,
    DataUtils.SID,
    CurrencyService.SID,
    function (
        $scope: MvSpecialRequestQuoteCtrlScope,
        $modalInstance: ModalInstance,
        interactionUtils: InteractionUtils,
        mvSpecialRequest: MvSpecialRequest,
        dataUtils: DataUtils,
        currencyService: CurrencyService,
    ) {
        $scope.CURRENCIES = currencyService.CURRENCIES;

        $scope.loading = {
            sendQuote: false,
        };

        function init() {
            if ($scope.quote.amount) {
                $scope.quote.amount = currencyService.getLargestUnit($scope.quote.currency, $scope.quote.amount);
            }
        }

        $scope.sendQuote = function () {
            const quote = angular.copy($scope.quote);
            quote.amount = currencyService.getSmallestUnit($scope.quote.currency, $scope.quote.amount || 0);
            return interactionUtils
                .handleRemote(
                    $scope,
                    'send quote',
                    'sendQuote',
                    mvSpecialRequest.sendQuote($scope.specialRequestId, quote),
                )
                .then((comment: Comment) => {
                    $modalInstance.close({
                        quote,
                        comment,
                    });
                });
        };

        $scope.cancel = function () {
            $modalInstance.dismiss();
        };

        $scope.getDifference = function () {
            const amount = currencyService.getSmallestUnit($scope.quote.currency, $scope.quote.amount || 0);
            return currencyService.getLargestUnit($scope.quote.currency, amount - $scope.totalPaid);
        };

        $scope.getQuoteDifference = function () {
            const amount = currencyService.getSmallestUnit($scope.quote.currency, $scope.quote.amount || 0);
            return currencyService.getLargestUnit($scope.quote.currency, amount - ($scope.originalAmount || 0));
        };

        init();
    },
]);
