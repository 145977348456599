/// <reference path="../../../typings/browser.d.ts" />

import { MvNotifier } from '../common/mvNotifier';
import { InteractionUtils } from '../common/interactionUtils';
import { I18nService } from '../i18n/i18nService';
import { $locationSID } from '../common/angularData';
import { MvAuth } from './mvAuth';

export class MvForgotPasswordCtrl {
    static SID = 'MvForgotPasswordCtrl';

    static readonly $inject : string[] = [
        $locationSID,
        MvNotifier.SID,
        MvAuth.SID,
        InteractionUtils.SID,
        I18nService.SID,
    ];

    readonly requestPasswordReset = this.interactionUtils.createFuture(
        () => this.i18n.text.login.requestPasswordReset(),
        () => this.mvAuth.requestPasswordReset(this.username).then(() => {
                this.$location.url('/login');
                this.notifier.notify(this.i18n.text.login.youWillReceivePasswordResetInstructionsShortly());
            },
            err => this.notifier.unexpectedError(this.i18n.text.login.errorSendingPasswordResetInstructions())));

    username = '';

    constructor(
        private readonly $location : ng.ILocationService,
        private readonly notifier : MvNotifier,
        private readonly mvAuth : MvAuth,
        private readonly interactionUtils : InteractionUtils,
        private readonly i18n: I18nService) {
    }
}

angular.module('app').controller(MvForgotPasswordCtrl.SID, MvForgotPasswordCtrl);
