import { gql } from '@apollo/client';

export const SEND_CLUB_READY_CAMPAIGN_MUTATION = gql`
    mutation SendClubReadyCampaign($locationIds: [ID!]!, $input: SendClubReadyCampaignInput!) {
        sendClubReadyCampaign(locationIds: $locationIds, input: $input) {
            ... on SendClubReadyCampaignResult {
                results {
                    locationId
                    scheduledPublish {
                        id
                        schedulingStatus
                    }
                }
            }
            ... on ClubReadyApiError {
                message
            }
        }
    }
`;
