/// <reference path="../../../../typings/browser.d.ts" />
import { $scopeSID } from '../../common/angularData';
import { openLayersSID } from './openLayersService';

export class MapLocationController {
    static SID = 'MapLocationController';

    mapStyle = 'osm_highlander';

    private static readonly MAPZ_API_KEY = 'ltnscrn-a8990742';

    private readonly TILE_SOURCE_URL = `https://tiles.mapz.com/mapproxy/v1/${MapLocationController.MAPZ_API_KEY}/tiles/1.0.0/${this.mapStyle}/EPSG3857/{z}/{x}/{-y}.png`;

    private readonly attribution = new this.ol.Attribution({
        // eslint-disable-next-line no-multi-str
        html: '© 2017 <a target="_blank" href="http://www.mapz.com">mapz.com </a>\
            - Map Data: <a target="_blank" href="http://openstreetmap.org" >OpenStreetMap</a>\
            (<a href="http://opendatacommons.org/licenses/odbl/1.0/" target="_blank">ODbL</a>)',
    });

    private readonly DEFAULT_ZOOM = 15;

    private interactiveMap!: ol.Map;

    private previewMap!: ol.Map;

    private readonly markerFeature: ol.Feature;

    private readonly markerLayer: ol.layer.Vector;

    initialLocation!: { lat: number; lon: number };

    selectedLocation: { lat: number; lon: number };

    editable = false;

    zoom: number = this.DEFAULT_ZOOM;

    static readonly $inject: string[] = [$scopeSID, openLayersSID];

    constructor($scope: ng.IScope, protected readonly ol: typeof import('openlayers')) {
        this.selectedLocation = this.initialLocation;
        const position = new this.ol.source.Vector();
        this.markerLayer = new this.ol.layer.Vector({
            source: position,
            style: new this.ol.style.Style({
                image: new this.ol.style.Icon({
                    src: '//maps.gstatic.com/mapfiles/api-3/images/autocomplete-icons_hdpi.png',
                    anchor: [0.5, 0.8],
                    offsetOrigin: 'bottom-right',
                    offset: [0, 200],
                    size: [40, 40],
                }),
            }),
        });

        const latlon = this.convertToMapzCoordinate(this.initialLocation);
        this.markerFeature = new this.ol.Feature(new this.ol.geom.Point(latlon));
        this.markerFeature.on('change', () => this.updateSelectedLocation());

        this.createMapRenders(this.initialLocation);

        position.addFeature(this.markerFeature);
        $scope.$watch(
            () => this.initialLocation,
            () => this.updateMapView(),
        );
        $scope.$watch(
            () => this.editable,
            () => this.centerView(),
        );
    }

    private createMapRenders(latlon: { lon: number; lat: number }) {
        this.interactiveMap = this.getMap('interactive-map-view', latlon);
        this.previewMap = this.getMap('preview-map-view', latlon, false);
    }

    private getMap(target: string, latlon: { lon: number; lat: number }, isInteractive = true) {
        return new this.ol.Map({
            interactions: this.getInteractions(isInteractive),
            target,
            controls: isInteractive ? this.ol.control.defaults() : new this.ol.Collection([]),
            layers: [
                new this.ol.layer.Tile({
                    source: new this.ol.source.XYZ({
                        attributions: [this.attribution],
                        url: this.TILE_SOURCE_URL,
                    }),
                }),
                this.markerLayer,
            ],
            view: new this.ol.View({
                enableRotation: false,
                center: this.convertToMapzCoordinate(latlon),
                zoom: this.zoom,
            }),
        });
    }

    private getInteractions(isInteractive: boolean) {
        if (isInteractive) {
            const translate = new this.ol.interaction.Translate({
                features: new this.ol.Collection([this.markerFeature]),
            });

            return this.ol.interaction.defaults().extend([translate]);
        }

        return new this.ol.Collection([]);
    }

    private updateMapView() {
        const newGeo = this.convertToMapzCoordinate(this.initialLocation);
        this.interactiveMap.setView(
            new this.ol.View({
                center: newGeo,
                zoom: this.interactiveMap.getView().getZoom(),
            }),
        );
        if (this.markerFeature) {
            this.markerFeature.setGeometry(new this.ol.geom.Point(newGeo));
        }
    }

    private centerView() {
        this.zoom = this.interactiveMap.getView().getZoom();
        this.interactiveMap.setView(
            new this.ol.View({
                center: this.convertToMapzCoordinate(this.selectedLocation),
                zoom: this.zoom,
            }),
        );

        this.previewMap.setView(
            new this.ol.View({
                center: this.convertToMapzCoordinate(this.selectedLocation),
                zoom: this.zoom,
            }),
        );
    }

    private updateSelectedLocation() {
        this.selectedLocation = this.getFeatureLatlon(this.markerFeature);
        this.zoom = this.interactiveMap.getView().getZoom();

        this.previewMap.setView(
            new this.ol.View({
                center: this.convertToMapzCoordinate(this.selectedLocation),
                zoom: this.zoom,
            }),
        );
    }

    private getFeatureLatlon(_feature: ol.Feature) {
        const featurePoint = <ol.geom.Point> this.markerFeature.getGeometry();
        const lonlat = this.convertFromMapzCoordinate(featurePoint.getCoordinates());
        return {
            lon: lonlat[0],
            lat: lonlat[1],
        };
    }

    private convertToMapzCoordinate(latLon: { lat: number; lon: number }) {
        return this.ol.proj.transform([latLon.lon, latLon.lat], 'EPSG:4326', 'EPSG:3857');
    }

    private convertFromMapzCoordinate(coordinate: [number, number]) {
        return this.ol.proj.transform(coordinate, 'EPSG:3857', 'EPSG:4326');
    }
}

angular.module('app').controller(MapLocationController.SID, MapLocationController);
