import { useQuery } from '@apollo/client';
import {
    DSGrid,
    DSIconButton,
    DSLoadMoreButton,
    DSTypography,
    DSWell,
    DSWellHeader,
    DSWellHeaderRightContent,
    DSWellHeaderTitle,
    Translate,
} from '@deltasierra/components';
import RefreshIcon from '@material-ui/icons/Refresh';
import React from 'react';
import styled from 'styled-components';
import { useConnectionFetchMore } from '../../../../../graphql/hooks';
import { relayConnectionToArray } from '../../../../../graphql/utils';
import { FailedPublishesTable } from './FailedPublishesTable';
import { GET_FAILED_PUBLISHES_QUERY } from './FailedPublishesWell.queries';
import { GetFailedPublishes, GetFailedPublishesVariables } from './__graphqlTypes/GetFailedPublishes';

const StyledCenter = styled.div`
    text-align: center;
`;

export function FailedPublishesWell(): JSX.Element {
    const { data, fetchMore, loading, refetch } = useQuery<GetFailedPublishes, GetFailedPublishesVariables>(
        GET_FAILED_PUBLISHES_QUERY,
        {
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true,
            variables: {
                first: 2,
            },
        },
    );
    const failedPublishes = React.useMemo(
        () => relayConnectionToArray(data?.admin.recentFailedPublishes),
        [data?.admin.recentFailedPublishes],
    );
    const [handleFetchMore, hasNextPage] = useConnectionFetchMore(data?.admin.recentFailedPublishes, async after =>
        fetchMore({ variables: { after, first: 20 } }),
    );

    return (
        <DSWell>
            <DSWellHeader>
                <DSWellHeaderTitle>
                    <Translate keyId="ADMIN.SERVICING.FAILED_PUBLISHES.TITLE" />
                </DSWellHeaderTitle>
                <DSWellHeaderRightContent>
                    <DSIconButton onClick={() => refetch()}>
                        <RefreshIcon />
                    </DSIconButton>
                </DSWellHeaderRightContent>
            </DSWellHeader>
            <DSTypography gutterBottom>
                <Translate keyId="ADMIN.SERVICING.FAILED_PUBLISHES.SUBTITLE" />
            </DSTypography>
            <DSGrid alignItems="center" container direction="row-reverse" spacing={1}>
                <DSGrid item></DSGrid>
                <DSGrid item></DSGrid>
            </DSGrid>
            <FailedPublishesTable loading={loading} rows={failedPublishes} />
            {hasNextPage && (
                <StyledCenter>
                    <DSLoadMoreButton gutterTop loading={loading} onClick={handleFetchMore} />
                </StyledCenter>
            )}
        </DSWell>
    );
}
FailedPublishesWell.displayName = 'FailedPublishesWell';
