import * as React from 'react';
import { Field, FieldProps } from 'formik';
import { timezones, t } from '@deltasierra/shared';

import { withAngularIntegration } from '../common/componentUtils/reactComponentRegistration';
import { ExpressionBinding, OneWayBinding, OptionalOneWayBinding } from '../common/angularData';
import { Translate } from './Translate';

export type SelectTimezoneProps = {
    onChange: (value: string) => void;
    timezone: string;
    name?: string;
    dataCy?: string;
};

const SelectTimezone: React.FunctionComponent<SelectTimezoneProps> = ({ dataCy, name, onChange, timezone }) => (
    <select
        className="form-control"
        data-cy={dataCy}
        name={name}
        value={timezone}
        onChange={event => onChange(event.target.value)}
    >
        {useTimezones().map(opt => (
            <option key={opt.key} value={opt.value.toString()}>
                {opt.label}
            </option>
        ))}
    </select>
);

export const SelectTimezoneFormInput: React.FunctionComponent<{ dataCy?: string; name: string }> = ({
    dataCy,
    name,
}) => (
    <Field name={name.toString()}>
        {({ field, form }: FieldProps<string>) => (
            <div className="form-group">
                <label className="control-label col-md-2 text-nowrap">
                    <Translate keyId="COMMON.TIMEZONE" />
                </label>
                <div className="col-md-10">
                    <SelectTimezone
                        dataCy={dataCy}
                        name={field.name}
                        timezone={field.value}
                        onChange={value => {
                            form.setFieldValue(field.name, value);
                            if (typeof value === 'object') {
                                form.handleChange(field);
                            }
                        }}
                    />
                </div>
            </div>
        )}
    </Field>
);
SelectTimezoneFormInput.displayName = 'SelectTimezone';

function useTimezones(): Array<{ label: string; value: string; key: string }> {
    return React.useMemo(() => timezones.map(({ keyId, value }) => ({ key: keyId, label: t(keyId), value })), []);
}

export default withAngularIntegration(SelectTimezone, 'selectTimezone', {
    dataCy: OptionalOneWayBinding,
    onChange: ExpressionBinding,
    timezone: OneWayBinding,
});
