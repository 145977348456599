/// <reference path="../../../typings/browser.d.ts" />
import moment from 'moment-timezone';
import {
    LocationId,
    LocationIdHierarchy,
    coerceDate,
    isValidDateForSpecialRequest,
    SpecialRequest,
    specialRequestHasValidDate,
    SpecialRequestStatus,
    Client,
    Channel,
    Activity,
    Upload,
    Comment,
    Untyped,
} from '@deltasierra/shared';

import type {
    ILocationService,
    IQService,
    IScope,
    IPromise,
    IAngularEvent,
    IQResolveReject,
    IHttpPromiseCallbackArg,
} from 'angular';
import { addBusinessDays } from '@deltasierra/dates';
import { DateTime } from 'luxon';
import { PaymentData } from '../payments/mvPaymentService';
import { MvNotifier } from '../common/mvNotifier';
import { MvIdentity } from '../account/mvIdentity';
import { MvLocation } from '../locations/mvLocation';
import { MvClientResource } from '../clients/mvClientResource';
import { DataUtils } from '../common/dataUtils';
import { CurrencyService } from '../payments/currencyService';
import { UploadContext, UploadScope, UploadService } from '../common/uploadService';
import { I18nService } from '../i18n/i18nService';
import { ModalService } from '../typings/angularUIBootstrap/modalService';
import { ConfirmModal } from '../common/confirmModal';
import { IKookies } from '../common/angularData';
import { MvSpecialRequestQuoteCtrlScopeInputs, SpecialRequestQuote } from './mvSpecialRequestQuoteCtrl';

interface SpecialRequestData extends SpecialRequest {
    brief?: string;
}

export abstract class BaseSpecialRequestCtrl {
    public identity = this.mvIdentity;

    public currentSpecialRequest: SpecialRequestData | null = null;

    public datePickerIsOpen = {
        dateRequiredBy: false,
    };

    public datePickerOptions = {
        dateRequiredBy: {
            dateDisabled: (date: Date, mode: 'day' | 'month' | 'year'): boolean => {
                if (mode === 'day') {
                    return !isValidDateForSpecialRequest(moment(date), this.getCreatedAtDate());
                } else {
                    return false;
                }
            },
            minDate: this.getEarliestDate(),
        },
    };

    public statuses: SpecialRequestStatus[] | null = null;

    public clientCache: { [key: number]: Client | undefined } = {};

    public currentClient: Client | null = null;

    public currentActivity: Activity | null = null;

    public locationsPromise: IPromise<void> | null = null;

    public locations?: LocationIdHierarchy[];

    public locationMap?: { [key: number]: LocationIdHierarchy };

    public uploadContext?: UploadContext;

    public loading = {
        deliverables: false,
    };

    public uploadErrorMessage?: string;


    public constructor(
        protected readonly $scope: IScope,
        protected readonly $location: ILocationService,
        protected readonly $q: IQService,
        protected readonly $kookies: IKookies,
        protected readonly $modal: ModalService,
        protected readonly uploadService: UploadService,
        protected readonly mvNotifier: MvNotifier,
        protected readonly mvIdentity: MvIdentity,
        protected readonly mvLocation: MvLocation,
        protected readonly mvSpecialRequestResource: Untyped,
        protected readonly mvClientResource: MvClientResource,
        protected readonly confirmModal: ConfirmModal,
        protected readonly dataUtils: DataUtils,
        protected readonly currencyService: CurrencyService,
        protected readonly i18n: I18nService,
    ) {}

    public openDatePicker($event: IAngularEvent, datePickerName: 'dateRequiredBy'): void {
        $event.preventDefault();
        if ($event.stopPropagation) {
            $event.stopPropagation();
        }
        this.datePickerOptions.dateRequiredBy.minDate = this.getEarliestDate(); // Bit of a hack
        this.datePickerIsOpen[datePickerName] = true;
    }

    public canEdit(): boolean {
        return this.identity.isManager() || !!(this.currentSpecialRequest && !this.currentSpecialRequest.id);
    }

    public onFileSelect($files: File[]): void {
        if (this.currentSpecialRequest) {
            // eslint-disable-next-line no-prototype-builtins
            if (!this.currentSpecialRequest.hasOwnProperty('uploads')) {
                this.currentSpecialRequest.uploads = [];
            }

            this.uploadService.upload(
                $files,
                'specialRequest',
                this.currentSpecialRequest.uploads as any,
                this.$scope as UploadScope,
            );
        }
    }

    public deleteUpload(upload: Upload): void {
        if (this.currentSpecialRequest && this.currentSpecialRequest.uploads) {
            this.dataUtils.removeObject(upload, this.currentSpecialRequest.uploads);
        }
        // TODO: remove on the server
    }

    public selectActivity(activity: Activity): void {
        this.currentActivity = activity;
    }

    public selectOtherActivity(): void {
        this.currentActivity = null;
    }

    public isChannelSelected(channelToTest: Channel): boolean {
        return this.indexOfChannel(channelToTest) > -1;
    }

    public toggleChannel(channel: Channel): void {
        if (!this.currentSpecialRequest || !this.currentSpecialRequest.channels) {
            return;
        }
        this.dataUtils.toggleById(channel, this.currentSpecialRequest.channels);
    }

    public getChannelDisplay(channel: Channel): string {
        const DEFAULT_VALUE = 'Unknown channel';
        if (!this.currentClient || !this.currentClient.activities) {
            return DEFAULT_VALUE;
        }
        const activity = this.dataUtils.findBy('id', this.currentClient.activities, channel.activityId);
        if (activity) {
            return `${activity.label} - ${channel.label}`;
        } else {
            return DEFAULT_VALUE;
        }
    }

    public onChangeLocation(newLocation: LocationIdHierarchy): ng.IPromise<LocationId> {
        if (this.currentSpecialRequest && this.locationMap) {
            const currentSpecialRequest = this.currentSpecialRequest;
            const oldLocationId = currentSpecialRequest.locationId;
            const oldLocation = this.locationMap[oldLocationId];
            if (oldLocation && newLocation.clientId !== oldLocation.clientId) {
                const deferred = this.$q.defer<LocationId>();
                this.confirmModal.open(
                    'Change client',
                    'Changing client will lose any selected deliverables. Proceed?',
                    async () => {
                        currentSpecialRequest.locationId = newLocation.locationId;
                        return this.getClientForLocation(currentSpecialRequest.locationId)
                            .then(() => this.clearChannels())
                            .then(() => deferred.resolve(newLocation.locationId))
                            .catch(() => deferred.reject());
                    },
                    () => {
                        currentSpecialRequest.locationId = oldLocationId;
                        return deferred.resolve(oldLocationId);
                    },
                );
                return deferred.promise;
            } else {
                currentSpecialRequest.locationId = newLocation.locationId;
                return this.$q.resolve(newLocation.locationId);
            }
        }
        return this.$q.resolve(newLocation.locationId);
    }

    public isADeliverableSelected(): boolean {
        return !!(
            this.currentSpecialRequest &&
            ((this.currentSpecialRequest.channels && this.currentSpecialRequest.channels.length > 0) ||
                this.currentSpecialRequest.otherChannels)
        );
    }

    public isAnUploadInProgress(): boolean {
        return !!this.uploadContext?.uploadInProgress;
    }

    public isRequiredByDateValid(): boolean {
        if (this.currentSpecialRequest?.createdAt) {
            return true;
        }
        if (!this.currentSpecialRequest?.dateRequiredBy) {
            return false;
        }
        // Don't call setHours straight on dateRequiredBy, as it will chage its value
        const dateRequiredByStartDate = new Date(
            new Date(moment.utc(this.currentSpecialRequest.dateRequiredBy).local(true).toDate()).setHours(0, 0, 0, 0),
        );
        const FIVE_BUSINESS_DAYS_LATER = addBusinessDays(new Date(new Date().setHours(0, 0, 0, 0)), 5);
        return (
            !!this.currentSpecialRequest &&
            specialRequestHasValidDate(this.currentSpecialRequest) &&
            dateRequiredByStartDate.getTime() >= FIVE_BUSINESS_DAYS_LATER.getTime()
        );
    }

    public setCurrentClient(client: Client | null): void {
        this.currentClient = client;
        if (client && client.activities && client.activities.length > 0) {
            this.currentActivity = client.activities[0];
        } else {
            this.currentActivity = null;
        }
    }

    public async getClientForLocation(locationId: LocationId): Promise<void> {
        const client = await this.getClient(locationId);
        this.setCurrentClient(client);
    }

    public convertToInt(value: string): number {
        return parseInt(value, 10);
    }

    public async createQuote(): Promise<void> {
        if (this.currentSpecialRequest) {
            const currentSpecialRequest = this.currentSpecialRequest;
            const currency = this.currentSpecialRequest.quoteCurrency || 'AUD';
            const amount = this.currentSpecialRequest.quoteAmount || null;
            const quote = new SpecialRequestQuote(currency, amount);
            const newScope: IScope & Partial<MvSpecialRequestQuoteCtrlScopeInputs> = this.$scope.$new();
            newScope.quote = quote;
            newScope.totalPaid = this.currentSpecialRequest.totalPaid;
            newScope.specialRequestId = this.currentSpecialRequest.id;
            newScope.originalAmount = amount;
            const modalInstance = this.$modal.open({
                backdrop: 'static',
                controller: 'mvSpecialRequestQuoteCtrl',
                scope: newScope,
                templateUrl: '/partials/specialRequests/specialRequestQuote',
            });
            return modalInstance.result.then(
                (result: { comment: Comment; quote: { amount: number; currency: string } }) => {
                    currentSpecialRequest.comments.push(result.comment);
                    currentSpecialRequest.quoteAmount = result.quote.amount;
                    currentSpecialRequest.quoteCurrency = result.quote.currency;
                    this.mvNotifier.notify(this.i18n.text.requests.quoteSent());
                },
            );
        } else {
            return Promise.resolve();
        }
    }

    public startPayment(): void {
        if (this.currentSpecialRequest) {
            const paymentData: PaymentData = {
                // BusinessName: hmm...
                // PhoneNumber: nope
                amount: this.getOutstandingPayment(),
                currency: this.currentSpecialRequest.quoteCurrency,
                email: this.identity.currentUser!.username,
                invoiceDescription: this.createPaymentDescription(this.currentSpecialRequest),

                r: `/specialRequests/${this.currentSpecialRequest.id}`,
                specialRequestId: this.currentSpecialRequest.id,
            };
            this.$location.path('/payments/confirm').search(paymentData);
        }
    }

    public getOutstandingPayment(): number {
        if (this.currentSpecialRequest) {
            const quoteAmount = this.currentSpecialRequest.quoteAmount || 0;
            const totalPaid = this.currentSpecialRequest.totalPaid;
            return quoteAmount - totalPaid;
        } else {
            return 0;
        }
    }

    public getLargestUnit(amount: number): number {
        if (this.currentSpecialRequest && amount) {
            return this.currencyService.getLargestUnit(this.currentSpecialRequest.quoteCurrency, amount);
        } else {
            return 0;
        }
    }

    public getCredit(): number {
        if (this.currentSpecialRequest) {
            const amount = Math.abs(this.getOutstandingPayment());
            return this.getLargestUnit(amount);
        } else {
            return 0;
        }
    }


    public saveSpecialRequest(): any {
        if (this.currentSpecialRequest) {
            return this.mvSpecialRequestResource.save(
                { id: this.currentSpecialRequest.id },
                this.currentSpecialRequest,
                (data: IHttpPromiseCallbackArg<never>) => {
                    this.mvNotifier.notify(this.i18n.text.requests.requestSaved());
                },
                (data: IHttpPromiseCallbackArg<never>) => {
                    this.mvNotifier.unexpectedErrorWithData('Failed to save special request', data);
                },
            );
        }
    }

    public async getLocations(): Promise<void> {
        this.locations = undefined;
        this.locationMap = undefined;

        // Get map of locations
        const promise = this.mvLocation
            .getAssignedLocations()
            .then(locations => {
                this.locations = locations;
                this.locationMap = this.dataUtils.createMap(value => value.locationId, locations);
            })
            .catch(data => {
                this.mvNotifier.unexpectedErrorWithData('Failed to retrieve assigned locations', data);
            });
        this.locationsPromise = promise;
        return promise;
    }

    public getStatuses(): PromiseLike<SpecialRequestStatus[]> {
        this.statuses = this.mvSpecialRequestResource.getStatuses(
            () => {
                // Do nothing
            },
            (data: IHttpPromiseCallbackArg<any>) => {
                this.mvNotifier.unexpectedErrorWithData('Failed to retrieve special request status options', data);
            },
        );
        return this.statuses!.$promise;
    }

    public getLocationById(locationId: LocationId): LocationIdHierarchy | undefined {
        if (!this.locationMap || !locationId) {
            return undefined;
        }
        const location = this.locationMap[locationId];
        return location;
    }

    public getClientAndLocationName(specialRequest: SpecialRequest): string {
        if (!specialRequest) {
            return 'Unknown - Unknown';
        }
        const location = this.getLocationById(specialRequest.locationId);
        if (!location) {
            return 'Unknown - Unknown';
        }
        return `${location.clientTitle} - ${location.locationTitle}`;
    }

    public getFormattedUtcSpecialRequestSubmittedDateString(specialRequest: SpecialRequest | undefined): string | null {
        if (specialRequest) {
            return this.getFormattedUtcDateString(specialRequest.createdAt);
        }
        return null;
    }

    public getFormattedUtcSpecialRequestDateString(specialRequest: SpecialRequest | undefined): string | null {
        if (specialRequest && specialRequest.dateRequiredBy) {
            return this.getFormattedUtcDateString(specialRequest.dateRequiredBy);
        }
        return null;
    }

    public getStatusByStep(statusStep: number): SpecialRequestStatus | null {
        if (this.statuses) {
            const status: SpecialRequestStatus | null = this.dataUtils.findBy('step', this.statuses, statusStep);
            if (status) {
                return status;
            }
        }
        return null;
    }

    public getStatusLabel(specialRequest: SpecialRequest): string | null {
        if (!specialRequest) {
            return null;
        }
        const status = this.getStatusByStep(specialRequest.statusStep);
        if (status) {
            return status.label;
        } else {
            return null;
        }
    }

    public rectifySpecialRequestDate(specialRequest: SpecialRequest): SpecialRequest {
        specialRequest.dateRequiredBy = specialRequest.dateRequiredBy
            ? coerceDate(specialRequest.dateRequiredBy)
            : null;
        return specialRequest;
    }

    public getFormattedUtcDateString(date?: Date | string): string | null {
        if (!date) {
            return null;
        }
        if (angular.isDate(date)) {
            return DateTime.fromJSDate(date as Date, { zone: 'utc' }).toFormat('dd LLL yyyy');
        } else {
            // TODO: can we remove support for string dates?
            return DateTime.fromISO(date as unknown as string, { zone: 'utc' }).toFormat('dd LLL yyyy');
        }
    }

    private getEarliestDate(): moment.Moment {
        return addBusinessDays(this.getCreatedAtDate(), 5);
    }

    private getCreatedAtDate(): moment.Moment {
        return (
            (this.currentSpecialRequest && moment(coerceDate(this.currentSpecialRequest.createdAt))) ||
            moment(moment.tz.guess())
        );
    }

    private indexOfChannel(channelToFind: Channel) {
        if (!this.currentSpecialRequest || !this.currentSpecialRequest.channels) {
            return -1;
        }
        return this.dataUtils.indexOfBy('id', this.currentSpecialRequest.channels, channelToFind.id);
    }

    private clearChannels() {
        if (this.currentSpecialRequest && this.currentSpecialRequest.channels) {
            this.currentSpecialRequest.channels.length = 0;
        }
    }

    private getClient(locationId: LocationId) {
        return (this.locationsPromise || this.$q.resolve()).then(() =>
            this.$q((resolve: IQResolveReject<Client>, reject: IQResolveReject<any>) => {
                let location;
                if (locationId) {
                    location = this.getLocationById(locationId);
                } else {
                    // eslint-disable-next-line no-console
                    console.log('No location ID set, defaulting to the first location');
                    if (this.locations && this.locations.length > 0) {
                        location = this.locations[0];
                        if (this.currentSpecialRequest) {
                            this.currentSpecialRequest.locationId = location.locationId; // Hmm, should NOT be here...
                        }
                    }
                }
                if (!location) {
                    throw new Error('Location is not found');
                }
                const clientId = location.clientId;
                if (!clientId) {
                    throw new Error('Location is not attached to a client');
                }
                let client = this.clientCache[clientId];
                if (!client) {
                    this.loading.deliverables = true;
                    client = this.mvClientResource.get(
                        {
                            id: clientId,
                        },
                        (client1: Client) => {
                            this.loading.deliverables = false;
                            this.clientCache[clientId] = client1;
                            return resolve(client1);
                        },
                        (data: IHttpPromiseCallbackArg<any>) => {
                            this.loading.deliverables = false;
                            this.mvNotifier.unexpectedErrorWithData('Failed to retrieve client details', data);
                            this.clientCache[clientId] = undefined;
                            return reject(null);
                        },
                    );
                } else {
                    return resolve(client);
                }
                return Promise.resolve();
            }),
        );
    }

    private createPaymentDescription(specialRequest: SpecialRequestData): string {
        const MAX_TITLE_LENGTH = 26; // Could be 27 to bring us up to 64
        let title = specialRequest.title;
        if (title.length > MAX_TITLE_LENGTH) {
            title = `${title.substring(0, MAX_TITLE_LENGTH - 3)}...`;
        }
        return `[DS] Special Request "${title}" (#${specialRequest.id})`;
    }
}
