import { OneWayBinding, simpleComponent } from '../../common/angularData';
import { ClientUserBreadcrumbsCtrl } from './controller';

export const ClientUserBreadcrumbsDirectiveConfig = simpleComponent(
    ClientUserBreadcrumbsCtrl,
    '/partials/clients/clientUserBreadcrumbs/template',
    {
        clientUser: OneWayBinding,
    },
);
export const ClientUserBreadcrumbsDirectiveSID = 'dsClientUserBreadcrumbs';

angular.module('app').directive(ClientUserBreadcrumbsDirectiveSID, [() => ClientUserBreadcrumbsDirectiveConfig]);
