import {
    DSLoadMoreButton,
    DSTable,
    DSTableBodyLoadable,
    DSTableCell,
    DSTableContainer,
    DSTableHead,
    DSTableRow,
    DSTextField,
    DSWell,
    DSWellHeader,
    DSWellHeaderTitle,
    Translate,
} from '@deltasierra/components';
import { useControlledInput, useDebouncedValue, useOnHitPageBottom } from '@deltasierra/react-hooks';
import * as React from 'react';
import styled from 'styled-components';
import { withAngularIntegration } from '../../../common/componentUtils/reactComponentRegistration';
import { ClientListRow } from './ClientListRow';
import { useClients } from './use-clients';

const LoadingContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: ${({ theme }) => theme.spacing(4)}px;
`;

export const ClientList: React.FC = () => {
    const [search, , searchProps] = useControlledInput();
    const debouncedSearch = useDebouncedValue(search);

    const { clients, fetchMore, hasNextPage, loading } = useClients(debouncedSearch);

    const loadingOrDebouncing = loading || search !== debouncedSearch;

    useOnHitPageBottom(fetchMore, loadingOrDebouncing);

    return (
        <DSWell>
            <DSWellHeader>
                <DSWellHeaderTitle>
                    <Translate keyId="COMMON.CLIENTS" />
                </DSWellHeaderTitle>
            </DSWellHeader>
            <DSTextField label="Filter Clients" variant="outlined" {...searchProps} />
            <DSTableContainer>
                <DSTable className="ClientList__table">
                    <DSTableHead>
                        <DSTableRow>
                            <DSTableCell>
                                <Translate keyId="COMMON.CLIENT" />
                            </DSTableCell>
                            <DSTableCell>
                                <Translate keyId="ADMIN.BRANDS.BRAND" />
                            </DSTableCell>
                            <DSTableCell align="right">
                                <Translate keyId="COMMON.LOCATIONS" />
                            </DSTableCell>
                        </DSTableRow>
                    </DSTableHead>
                    <DSTableBodyLoadable data-cy="clients-list" loading={loadingOrDebouncing} minHeight="50px">
                        {clients.length === 0 && !loading && (
                            <DSTableRow>
                                <DSTableCell align="center" colSpan={3}>
                                    <em>
                                        <Translate keyId="AGENCY.CLIENTS.ADD_NEW_CLIENT" />
                                    </em>
                                </DSTableCell>
                            </DSTableRow>
                        )}
                        {clients.map(client => (
                            <ClientListRow client={client} key={client.id} />
                        ))}
                    </DSTableBodyLoadable>
                </DSTable>
                {hasNextPage && (
                    <LoadingContainer>
                        <DSLoadMoreButton gutterTop loading={loadingOrDebouncing} onClick={fetchMore} />
                    </LoadingContainer>
                )}
            </DSTableContainer>
        </DSWell>
    );
};
ClientList.displayName = 'ClientList';

export default withAngularIntegration(ClientList, 'clientListReact', {});
