import { gql } from '@apollo/client';
import { HashtagsSelectItem } from './HashtagsSelectItem';

export const GET_CLIENT_AND_LOCATION_HASHTAGS_QUERY = gql`
    query GetClientAndLocationHashtagsQuery($locationId: ID!) {
        location(id: $locationId) {
            id
            hashtagSetsConnection {
                edges {
                    node {
                        id
                        ...HashtagSelectItemFragmentForHashtagSet
                    }
                }
            }
            client {
                id
                hashtagSetsConnection(filter: { isCore: false }) {
                    edges {
                        node {
                            id
                            ...HashtagSelectItemFragmentForHashtagSet
                        }
                    }
                }
            }
        }
    }
    ${HashtagsSelectItem.fragments.HASHTAGS_SELECT_ITEM_FRAGMENT_FOR_HASHTAG_SET}
`;
