/// <reference path="../../../typings/browser.d.ts" />

import { Agency } from '@deltasierra/shared';
import { $scopeSID } from '../common/angularData';
import { DataUtils } from '../common/dataUtils';
import { InteractionUtils } from '../common/interactionUtils';
import { MvNotifier } from '../common/mvNotifier';
import { I18nService } from '../i18n';
import { AgencyApiClient } from './agencyApiClient';
import IScope = angular.IScope;
import IPromise = angular.IPromise;
import IFormController = angular.IFormController;

interface MvAgencyListCtrlScope extends IScope {
    agencyName: string | null;
    agencies: Agency[] | null;
    localeCode: string | null;
    loading: {
        agencies: boolean;
        addAgency: boolean;
    };
    newAgencyForm: IFormController;

    addAgency(): IPromise<void> | void;
}

export const mvAgencyListCtrlSID = 'mvAgencyListCtrl';

angular.module('app').controller(mvAgencyListCtrlSID, [
    $scopeSID,
    MvNotifier.SID,
    InteractionUtils.SID,
    DataUtils.SID,
    I18nService.SID,
    AgencyApiClient.SID,
    function mvAgencyListCtrl(
        $scope: MvAgencyListCtrlScope,
        mvNotifier: MvNotifier,
        interactionUtils: InteractionUtils,
        dataUtils: DataUtils,
        i18nService: I18nService,
        agencyApiClient: AgencyApiClient,
    ) {
        $scope.agencyName = null;
        $scope.agencies = null;
        $scope.localeCode = null;

        $scope.loading = {
            addAgency: false,
            agencies: false,
        };

        function initPage() {
            return getAgencies();
        }

        function getAgencies() {
            return interactionUtils
                .handleRemoteSimple($scope, 'retrieve agencies', 'agencies', agencyApiClient.getAgencies())
                .then(dataUtils.setScopeProperty($scope, 'agencies'));
        }

        $scope.addAgency = () => {
            if ($scope.agencyName && $scope.localeCode) {
                const newAgencyData = {
                    company: $scope.agencyName,
                    localeCode: $scope.localeCode,
                };

                return interactionUtils
                    .handleRemoteSimple(
                        $scope,
                        'create agency',
                        'addAgency',
                        agencyApiClient.createAgency(newAgencyData),
                    )
                    .then(() => {
                        $scope.newAgencyForm.$setPristine();
                        $scope.newAgencyForm.$setUntouched();
                        mvNotifier.notify(i18nService.text.agencies.agencyCreated());
                        $scope.agencyName = null;
                        return getAgencies();
                    });
            } else {
                mvNotifier.expectedError(i18nService.text.agencies.agencyNameRequired());
            }
        };

        void initPage();
    },
]);
