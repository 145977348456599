/// <reference path="../../../../typings/browser.d.ts" />
import {
    Four51Api,
    GetQuantityOptionsResponse,
    GetShoppingCartResponse,
    justParams,
    LocationId,
    PrintProviderSKUId,
    ServerRouteUrl,
} from '@deltasierra/shared';
import { $httpSID, $qSID } from '../../common/angularData';
import { getServerRoute, invokeApiRoute } from '../../common/httpUtils';

export class Four51ApiClient {
    static SID = 'Four51ApiClient';

    static $inject = [$httpSID, $qSID];

    constructor(protected readonly $http: ng.IHttpService, protected readonly $q: ng.IQService) {}

    getShoppingCart(locationId: LocationId): ng.IPromise<GetShoppingCartResponse> {
        return invokeApiRoute(
            this.$http,
            Four51Api.getShoppingCart,
            justParams({
                locationId,
            }),
        );
    }

    getLogonToFour51Url(locationId: LocationId): ServerRouteUrl {
        return getServerRoute(
            Four51Api.logonToFour51,
            justParams({
                locationId,
            }),
        );
    }

    getQuantityOptions(
        locationId: LocationId,
        skuId: PrintProviderSKUId,
    ): angular.IPromise<GetQuantityOptionsResponse> {
        return invokeApiRoute(
            this.$http,
            Four51Api.getQuantityOptions,
            justParams({
                locationId,
                skuId,
            }),
        );
    }
}

angular.module('app').service(Four51ApiClient.SID, Four51ApiClient);
