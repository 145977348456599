import { FacebookStatsForLocation } from '@deltasierra/shared';
import { compareNumbers } from '../compare';
import { ReportTable, StatsEntry, TextAlignClass } from '../reportTable/reportTable';
import { CreateReportTableOptions } from './common';

export function createFacebookReportTable(
    options: CreateReportTableOptions,
): ReportTable<StatsEntry<FacebookStatsForLocation>, FacebookStatsForLocation> {
    return new ReportTable<StatsEntry<FacebookStatsForLocation>, FacebookStatsForLocation>({
        columns: [
            {
                cssClass: TextAlignClass.Left,
                format: options.formatters.formatString,
                getTotal: () => options.i18n.text.report.total(),
                getValue: entry => entry.location.locationTitle,
                label: () => options.i18n.text.report.location(),
            },
            {
                compare: compareNumbers,
                cssClass: TextAlignClass.Right,
                format: options.formatters.formatNumber,
                getTotal: totals => totals.likes.new,
                getValue: entry => entry.likes.new,
                label: () => options.i18n.text.report.newLikes(),
                popoverMessage: () => options.i18n.text.report.facebook.incorrectDataPopoverText(),
                showPopover: entry => entry.likes.new < 0,
            },
            {
                compare: compareNumbers,
                cssClass: TextAlignClass.Right,
                format: value => (value === null ? '' : options.formatters.formatPercentage(value)),
                getTotal: totals => totals.likes.growth,
                getValue: entry => entry.likes.growth,
                label: () => options.i18n.text.report.growth(),
                popoverMessage: () => options.i18n.text.report.facebook.incorrectDataPopoverText(),
                showPopover: entry => entry.likes.growth < 0,
            },
            {
                compare: compareNumbers,
                cssClass: TextAlignClass.Right,
                format: options.formatters.formatNumber,
                getTotal: totals => totals.reach.count,
                getValue: entry => entry.reach.count,
                label: () => options.i18n.text.report.reach(),
            },
            {
                compare: compareNumbers,
                cssClass: TextAlignClass.Right,
                format: options.formatters.formatNumber,
                getTotal: totals => totals.consumptions.count,
                getValue: entry => entry.consumptions.count,
                label: () => options.i18n.text.report.clicks(),
            },
            {
                compare: compareNumbers,
                cssClass: TextAlignClass.Right,
                format: options.formatters.formatPercentage,
                getTotal: totals => totals.clickRate.count,
                getValue: entry => entry.clickRate.count,
                label: () => options.i18n.text.report.clickRate(),
            },
            /**
             * @deprecated likesCommentsShares, engagementReach and engagementClicks columns
             * not currently in use, fields deprecated and removed as part of DSL-3134
             */
        ],
    });
}
