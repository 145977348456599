import { filterUnique } from '@deltasierra/array-utilities';
import { FacebookPublishData, addTermsAndConditionsToCaption } from '@deltasierra/shared';

import { FacebookFormValues, HashtagInsertType } from './facebook-form-values';

export function buildHashtagString(hashtags: string[]): string | null {
    return hashtags.length > 0 ? hashtags.join(' ') : null;
}

export function getCaptionWithTermsAndConditions({
    baseCaption,
    termsAndConditionsText,
    termsAndConditionsUrl,
}: {
    baseCaption: string;
    termsAndConditionsText: string | null;
    termsAndConditionsUrl: string | null;
}): string {
    return addTermsAndConditionsToCaption(baseCaption, termsAndConditionsText, termsAndConditionsUrl);
}

export function addHashtagsToCaption({
    baseCaption,
    hashtagsInsertType,
    hashtagsString,
}: {
    baseCaption: string;
    hashtagsInsertType: HashtagInsertType;
    hashtagsString: string | null;
}): string {
    return hashtagsInsertType === 'caption'
        ? `${baseCaption}${hashtagsString ? `\n${hashtagsString}` : ''}`
        : baseCaption;
}

export function buildCaption({
    baseCaption,
    hashtagsInsertType,
    hashtagsString,
    termsAndConditionsText,
    termsAndConditionsUrl,
}: {
    baseCaption: string;
    hashtagsString: string | null;
    hashtagsInsertType: HashtagInsertType;
    termsAndConditionsText: string | null;
    termsAndConditionsUrl: string | null;
}): string {
    const captionWithTermsAndConditions = getCaptionWithTermsAndConditions({
        baseCaption,
        termsAndConditionsText,
        termsAndConditionsUrl,
    });

    const captionWithHashtags =
        hashtagsInsertType === 'caption'
            ? `${captionWithTermsAndConditions}${hashtagsString ? `\n${hashtagsString}` : ''}`
            : captionWithTermsAndConditions;

    return captionWithHashtags;
}

export function buildFirstComment({
    hashtagsInsertType,
    hashtagsString,
}: {
    hashtagsString: string | null;
    hashtagsInsertType: HashtagInsertType;
}): string | null {
    return hashtagsInsertType === 'comment' ? hashtagsString : null;
}

export type BuildFacebookPostAdditionalOptions = {
    requiredHashtags: string[];
    termsAndConditionsText: string | null;
    termsAndConditionsUrl: string | null;
};

export function buildFacebookPublishData(
    { caption, hashtags, hashtagsInsertType, scheduledTime }: FacebookFormValues,
    { requiredHashtags, termsAndConditionsText, termsAndConditionsUrl }: BuildFacebookPostAdditionalOptions,
): FacebookPublishData {
    // The required hashtags were added to the start of the list initially, but if we decide to re-order the tags
    // We need to add them to the end of the list here, that way they will take their original order and then
    // Only if they are missing, will they be added to the end
    const finalHashtags = [...hashtags, ...requiredHashtags].filter(filterUnique());

    const hashtagsString = buildHashtagString(finalHashtags);

    const finalCaption = buildCaption({
        baseCaption: caption,
        hashtagsInsertType,
        hashtagsString,
        termsAndConditionsText,
        termsAndConditionsUrl,
    });

    const firstComment = buildFirstComment({
        hashtagsInsertType,
        hashtagsString,
    });

    return {
        description: finalCaption,
        firstComment: firstComment ?? undefined,
        scheduledTime: scheduledTime ?? undefined,
    };
}
