import { gql } from '@apollo/client';

export const PUBLISH_FACEBOOK_IMAGE = gql`
    mutation PublishFacebookImage($input: PublishFacebookImageInput!) {
        publishFacebookImage(input: $input) {
            ... on PublishResultPayload {
                workflow {
                    id
                    executionId
                    startDate
                    status {
                        state
                        message
                    }
                }
            }
            ... on PublishError {
                code
                message
            }
        }
    }
`;
