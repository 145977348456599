import { DSButton } from '@deltasierra/components';
import { useToggle } from '@deltasierra/react-hooks';
import { t } from '@deltasierra/shared';
import * as React from 'react';
import { AssociateBrandDialog } from './AssociateBrandDialog';
import { BrandForAssociateDialog } from './__graphqlTypes/BrandForAssociateDialog';

export type AssociateBrandButtonProps = {
    brand: BrandForAssociateDialog;
    disabled?: boolean;
    render?: (params: { toggle: (toggle: 'toggleOn' | 'toggleOff' | 'toggle') => void }) => React.ReactNode;
    onClose?: () => void;
};

export const AssociateBrandButton: React.FC<AssociateBrandButtonProps> = props => {
    const [isOpen, toggle] = useToggle();

    return (
        <>
            {props.render
                ? props.render({ toggle })
             : (
                <DefaultAssociateBrandButton disabled={props.disabled} onClick={() => toggle('toggle')} />
            )}
            <AssociateBrandDialog
                brand={props.brand}
                isOpen={isOpen}
                onClose={() => {
                    toggle('toggleOff');
                    props.onClose?.();
                }}
                onConfirm={() => {
                    toggle('toggleOff');
                    props.onClose?.();
                }}
            />
        </>
    );
};
AssociateBrandButton.displayName = 'AssociateBrandButton';

const DefaultAssociateBrandButton: React.FC<{ disabled?: boolean; onClick: () => void }> = ({ disabled, onClick }) => (
    <DSButton color="primary" disabled={disabled} size="medium" title={t('ADMIN.BRANDS.ASSIGN_CLIENTS')} variant="text" onClick={onClick}>
        {t('ADMIN.BRANDS.ASSIGN')}
    </DSButton>
);
DefaultAssociateBrandButton.displayName = 'DefaultAssociateBrandButton';
