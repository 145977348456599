import * as React from 'react';
import { ModalBody, ModalFooter } from '@deltasierra/components';
import { FormikProps } from 'formik';
import LocationDetailsModalForm from './LocationDetailsModalForm';
import LocationDetailsModalFooter from './LocationDetailsModalFooter';
import { FormValues } from './LocationDetailsModalFormikWrapper';

export type LocationDetailsModalBodyProps = FormikProps<FormValues> & {
    onCancel: () => void;
};

const LocationDetailsModalBody: React.FC<LocationDetailsModalBodyProps> = props => (
    <>
        <ModalBody>
            <LocationDetailsModalForm {...props} />
        </ModalBody>
        <ModalFooter>
            <LocationDetailsModalFooter {...props} />
        </ModalFooter>
    </>
);
LocationDetailsModalBody.displayName = 'LocationDetailsModalBody';

export default LocationDetailsModalBody;
