import { gql, useQuery } from '@apollo/client';
import { DSGrid, DSWell, DSWellHeader, DSWellHeaderIcon, DSWellHeaderTitle, SvgLoading } from '@deltasierra/components';
import { t, LocationId } from '@deltasierra/shared';
import * as React from 'react';
import { ContainerMain } from '../common/components';
import { PageTitleWithLocationPicker } from '../common/layout/PageTitleWithLocationPicker';
import ContentBuilderSubNavbar from '../contentBuilder/subNavbar/ContentBuilderSubNavbar';
import { PlatformLogo } from '../platforms/components/PlatformLogo';
import { PlatformWell } from '../platforms/components/PlatformPicker';
import { ScheduledPostTable } from './components/ScheduledPostTable';
import { useScheduledPage } from './hooks/use-scheduled-page';
import {
    GetLocationInfoForScheduledPage,
    GetLocationInfoForScheduledPageVariables,
} from './__graphqlTypes/GetLocationInfoForScheduledPage';

export const GET_LOCATION_INFO_FOR_SCHEDULED_PAGE = gql`
    query GetLocationInfoForScheduledPage($locationId: ID!, $hasLocation: Boolean! = false) {
        hasCurrentLocation @client @export(as: "hasLocation")
        currentLocationId @client @export(as: "locationId")
        location(id: $locationId) @include(if: $hasLocation) {
            __typename
            id
            legacyId
        }
    }
`;

export default function ScheduledPage(): JSX.Element {
    const { data: locationData } = useQuery<GetLocationInfoForScheduledPage, GetLocationInfoForScheduledPageVariables>(
        GET_LOCATION_INFO_FOR_SCHEDULED_PAGE,
    );

    const [currentPlatform, setCurrentPlatform, platforms] = useScheduledPage();

    const legacyLocationId = locationData?.location?.legacyId ? LocationId.from(locationData.location.legacyId) : null;

    return (
        <>
            <ContentBuilderSubNavbar locationId={legacyLocationId} />
            <PageTitleWithLocationPicker title={t('SCHEDULE.PAGE.HEADER_TITLE')} />
            {!locationData?.location ? (
                <SvgLoading />
            ) : (
                <ContainerMain>
                    <DSGrid container spacing={3}>
                        <DSGrid item xs={3}>
                            <PlatformWell
                                platform={currentPlatform}
                                platforms={platforms}
                                onChange={name =>
                                    setCurrentPlatform(
                                        platforms.find(platform => name === platform.name) ?? platforms[0],
                                    )
                                }
                            />
                        </DSGrid>
                        <DSGrid item xs={9}>
                            <DSWell>
                                <DSWellHeader>
                                    <DSWellHeaderIcon>
                                        <PlatformLogo name={currentPlatform.name} />
                                    </DSWellHeaderIcon>
                                    <DSWellHeaderTitle>{currentPlatform.displayName}</DSWellHeaderTitle>
                                </DSWellHeader>
                                <ScheduledPostTable locationId={locationData.location.id} platform={currentPlatform} />
                            </DSWell>
                        </DSGrid>
                    </DSGrid>
                </ContainerMain>
            )}
        </>
    );
}
ScheduledPage.displayName = 'ScheduledPage';
