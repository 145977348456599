import { LocationIdHierarchy } from '@deltasierra/shared';
import { tryExtractMessage } from '../common/exceptions';
import { I18nService } from '../i18n/i18nService';
import IQResolveReject = angular.IQResolveReject;
import IPromise = angular.IPromise;

export interface ClientErrorMessage {
    reason: string;
}

export interface ServerErrorMessage {
    code: number;
    name: string;
    reason: string;
}

enum ActionProcessorStatus {
    Pending,
    Processing,
    Failure,
    Success,
    Cancelled,
}

enum ObjectActionState {
    queued = 'queued',
    processing = 'processing',
    failed = 'failed',
    succeeded = 'succeeded',
    cancelled = 'cancelled',
    forceCancelled = 'forceCancelled',
}

export interface ActionCallbacks<T> {
    action: (object: T) => IPromise<any>;
    success: Function;
    partialSuccess: Function;
    failure: Function;
}

export interface CustomMessages {
    cancelled: string;
    forceCancelled: string;
}

export class ProcessorActionState<T> {
    state: ObjectActionState = ObjectActionState.queued;

    error: ClientErrorMessage | ServerErrorMessage | null = null;

    constructor(public object: T) {}

    getIcon(): string {
        const base = 'fa fa-fw';
        switch (this.state) {
            case ObjectActionState.queued:
                return `${base} fa-clock-o`;
            case ObjectActionState.processing:
                return `${base} fa-refresh fa-spin text-primary`;
            case ObjectActionState.failed:
                return `${base} fa-exclamation-circle text-danger`;
            case ObjectActionState.succeeded:
                return `${base} fa-check text-success`;
            case ObjectActionState.forceCancelled:
            case ObjectActionState.cancelled:
                return `${base} fa-ban text-warning`;
            default:
                return '';
        }
    }

    getErrorMessage(): string {
        const err: any = this.error;
        return tryExtractMessage(err) || err.message || err.reason || err.Cause || err.Error || err;
    }
}

export class MultipleObjectActionProcessor<T> {
    readonly CANCELLED_TASK_TIMEOUT = 5000;

    ActionProcessorStatus = ActionProcessorStatus; // Expose the enum to templates

    stepIndex = 0;

    successes: Array<ProcessorActionState<T>> = [];

    failures: Array<ProcessorActionState<T>> = [];

    status: ActionProcessorStatus = ActionProcessorStatus.Pending;

    chosenObjects: T[];

    objectsToProcess: Array<ProcessorActionState<T>>;

    objectBeingProcessed: ProcessorActionState<T> | null = null;

    processingPromiseReject: ng.IQResolveReject<ClientErrorMessage> | null = null;

    customMessages: CustomMessages;

    constructor(
        private readonly $q: angular.IQService,
        private readonly i18n: I18nService,
        private readonly $timeout: ng.ITimeoutService,
        objects: T[],
        private readonly callbacks: ActionCallbacks<T>,
        customMessages?: Partial<CustomMessages>,
    ) {
        this.chosenObjects = objects;
        this.objectsToProcess = objects.map(x => new ProcessorActionState(x));
        const defaultMessages = {
            cancelled: this.i18n.text.common.cancelledWhileInQueue(),
            forceCancelled: this.i18n.text.common.cancelledWhileProcessing(),
        };
        if (customMessages) {
            this.customMessages = {
                ...defaultMessages,
                ...customMessages,
            };
        } else {
            this.customMessages = defaultMessages;
        }
    }

    start() {
        this.stepIndex = 0;
        this.failures = [];
        this.successes = [];
        this.status = ActionProcessorStatus.Processing;
        return this.processRecursive().then(
            () =>
                this.$timeout(() => {
                    if (this.failures.length == 0) {
                        this.status = ActionProcessorStatus.Success;
                        return this.callbacks.success();
                    } else {
                        this.status = ActionProcessorStatus.Failure;
                    }
                }, 250), // Timeout is to allow the ui to animate properly
        );
    }

    private processRecursive(): IPromise<void> {
        this.objectBeingProcessed = null;
        this.processingPromiseReject = null;
        if (this.status == ActionProcessorStatus.Processing && this.stepIndex < this.objectsToProcess.length) {
            const objectState = this.objectsToProcess[this.stepIndex];
            objectState.state = ObjectActionState.processing;
            const object = objectState.object;
            this.objectBeingProcessed = objectState;

            return this.$q((resolve: IQResolveReject<IPromise<void> | void>, reject: IQResolveReject<any>) => {
                this.processingPromiseReject = reject;
                return this.callbacks
                    .action(object)
                    .catch(err => {
                        if (err && err.data && err.status && err.config && err.headers) {
                            // Does it look like an AngularJS response?
                            err = err.data;
                        }
                        if (objectState.state != ObjectActionState.forceCancelled) {
                            objectState.state = ObjectActionState.failed;
                            objectState.error = err;
                            this.failures.push(objectState);
                        }
                    })
                    .then(() => {
                        if (objectState.state != ObjectActionState.forceCancelled) {
                            this.stepIndex++;
                            if (objectState.state != ObjectActionState.failed) {
                                objectState.state = ObjectActionState.succeeded;
                                this.successes.push(objectState);
                            }
                            return resolve(this.processRecursive());
                        }
                    });
            }).catch(err => {
                objectState.state = ObjectActionState.forceCancelled;
                objectState.error = err;
                this.failures.push(objectState);
                this.stepIndex++;
                return this.processRecursive();
            });
        }

        return this.$q.resolve();
    }

    cancelCurrent() {
        if (
            this.objectBeingProcessed &&
            this.objectBeingProcessed.state == ObjectActionState.processing &&
            this.processingPromiseReject
        ) {
            this.processingPromiseReject({ reason: this.customMessages.forceCancelled });
        }
    }

    cancelAllProcessing() {
        if (this.status == ActionProcessorStatus.Processing) {
            const failed = this.objectsToProcess
                .filter(x => x.state == ObjectActionState.queued)
                .map(x => {
                    x.state = ObjectActionState.cancelled;
                    x.error = { reason: this.customMessages.cancelled };
                    return x;
                });
            this.status = ActionProcessorStatus.Cancelled;
            this.failures = this.failures.concat(failed);
            setTimeout(() => this.cancelCurrent(), this.CANCELLED_TASK_TIMEOUT); // Give it 5 seconds to finish before we ignore it
        }
    }

    retryFailures() {
        this.objectsToProcess = this.failures
            .filter(x => x.state != ObjectActionState.forceCancelled)
            .map(x => new ProcessorActionState(x.object));

        if (this.objectsToProcess.length == 0) {
            // If a user cancels the last item thats currently processing
            this.callbacks.partialSuccess();
        } else {
            this.status = ActionProcessorStatus.Pending;
            return this.start();
        }
    }

    doNotRetryFailures() {
        if (this.failures.length == this.chosenObjects.length) {
            this.callbacks.failure();
        } else {
            this.callbacks.partialSuccess();
        }
    }
}

export class MultipleLocationActionProcessor extends MultipleObjectActionProcessor<LocationIdHierarchy> {}
