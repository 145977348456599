/* eslint-disable max-lines */
/// <reference path="../../../typings/browser.d.ts" />
import { isNullOrUndefined, Untyped } from '@deltasierra/type-utilities';
import { partition } from '@deltasierra/array-utilities';
import {
    ClientId,
    LocationId,
    ApplyActionTo,
    AssignPlannerBody,
    GetPlannerOverviewDetailsBody,
    isSelfService,
    OverviewPlannerDetails,
    Planner,
    PlannerOverviewDto,
    PlannerStatus,
    PlannerStatuses,
    UpdatePlannerStatusBody,
    coerceDate,
    AgencyUserDto,
    UserId,
    tuple,
    assertDefined,
} from '@deltasierra/shared';

import * as moment from 'moment-timezone';
import type { IScope, IFilterService, IPromise, IQService, IAngularEvent } from 'angular';
import { MvLocation } from '../locations/mvLocation';
import { MvIdentity } from '../account/mvIdentity';
import { MvPlanner } from '../planner/mvPlanner';
import { DataUtils } from '../common/dataUtils';
import { InteractionUtils } from '../common/interactionUtils';
import { I18nService } from '../i18n';
import { $filterSID, $qSID, $routeSID, $scopeSID, IKookies, IRoute } from '../common/angularData';
import { $modalSID } from '../common/angularUIBootstrapData';
import { ModalService } from '../typings/angularUIBootstrap/modalService';
import { AgencyUserApiClient } from '../agencies/agencyUserApiClient';
import { Paging } from '../common/paging';
import { IFilterConfig, TableService, tableServiceSID } from '../common/tableService';
import { MvConfirmBulkActionCtrl } from './MvConfirmBulkActionCtrl';
import { MvMultiplePlannerDeleteCtrl } from './mvMultiplePlannerDeleteCtrl';

interface OverviewScope extends IScope {
    month?: Date;
}

interface PlannerOverviewModel extends PlannerOverviewDto {
    selected?: boolean;
    expanded?: boolean;
    loading?: boolean;
}

const LOCATION_PLANNERS_CONCURRENCY = 20; // Actually limited by browser request limit to about 6 in Chrome

interface DayEntry {
    date: string | null;
    display: string;
}

export class MvOverviewCtrl {
    public static readonly SID = 'mvOverviewCtrl';

    public month?: string;

    public numLocations = 0;

    public numLocationsLoaded = 0;

    public planners: PlannerOverviewModel[] | null = null;

    public filteredPlanners: PlannerOverviewModel[] = [];

    public visiblePlanners: PlannerOverviewModel[] = [];

    public channels: Untyped[] = [];

    public channelsMap = {};

    public search?: {
        client?: string;
        location?: string;
        status?: string;
        title?: string;
    };

    public searchDate: string | null = null;

    public searchChannel = '';

    public searchAssignedTo = '';

    public displayCustomization = this.initDisplayCustomization();

    public showDisplayCustomization = false;

    public currentPlannerDetails: OverviewPlannerDetails | null = null;

    public agencyManagers: AgencyUserDto[] | null = null;

    public days: DayEntry[] | null = null;

    public paging = new Paging<PlannerOverviewModel>();

    private plannerPredicates = [
        /*
         * TODO: Include object test here
         * We should also perform the object test in here, but seems pretty tricky to extract the logic from
         * AngularJS' code.
         *
         * 2022-08-17: I formatted the file (including this comment). I kept the to-do comment even though I have no
         * idea what it's referring to.
         */
        this.testDisplayCustomization.bind(this),
        this.testChannel.bind(this),
        this.testAssignedTo.bind(this),
        this.testPlannerDate.bind(this),
    ];

    public plannerPredicateFunc = this.plannerPredicateInnerFunc.bind(this);

    public fetchAgencyManagers = this.interactionUtils.createFuture('get agency users', () =>
        this.agencyUserApiClient.getManagers(),
    );

    public fetchChannels = this.interactionUtils.createFuture('get channels', async () =>
        this.mvPlanner.getAllChannels(),
    );

    public fetchPlanners = this.interactionUtils.createFuture<
        PlannerOverviewModel[],
        { startDate: Date; endDate: Date }
    >('get planner events', (context: { startDate: Date; endDate: Date }) => {
        this.numLocationsLoaded = 0;
        return this.mvLocation.getAssignedLocations().then(locations => {
            this.numLocations = locations.length;
            let lastPromise: IPromise<PlannerOverviewModel[]> = this.$q.resolve([]);
            // Split into chunks
            return this.$q
                .all<PlannerOverviewModel[]>(
                    this.dataUtils.chunk(LOCATION_PLANNERS_CONCURRENCY, locations).map(chunk => {
                        // Process chunks sequentially
                        lastPromise = lastPromise.then<PlannerOverviewModel[]>(() =>
                            // Process all locations in a chunk, in parallel
                            this.$q
                                .all<PlannerOverviewDto[]>(
                                    chunk.map(location =>
                                        // Actually get the planner events for a single location
                                        this.mvLocation
                                            .getOverviewPlanners(
                                                location.locationId,
                                                context.startDate,
                                                context.endDate,
                                            )
                                            // eslint-disable-next-line max-nested-callbacks
                                            .then(result => {
                                                this.numLocationsLoaded++;
                                                return result;
                                            }),
                                    ),
                                )
                                // Data[][] -> data[] (multiple locations -> single array)
                                .then(this.dataUtils.flatten),
                        );
                        return lastPromise;
                    }),
                )
                .then(this.dataUtils.flatten); // Data[][] -> data[] (chunks -> single array)
        });
    });

    public fetchOverviewPlannerDetails = this.interactionUtils.createHttpFuture<
        OverviewPlannerDetails,
        GetPlannerOverviewDetailsBody
    >('get planner event details', (context: GetPlannerOverviewDetailsBody) =>
        this.mvPlanner.getPlannerOverviewDetails(context),
    );

    public submitUpdateStatus = this.interactionUtils.createHttpFuture<Planner, UpdatePlannerStatusBody>(
        'update planner event status',
        (context: UpdatePlannerStatusBody) => this.mvPlanner.updatePlannerStatus(context),
    );

    public submitAssignPlanner = this.interactionUtils.createHttpFuture<Planner, AssignPlannerBody>(
        'assign planner',
        (context: AssignPlannerBody) => this.mvPlanner.assignPlanner(context),
    );

    public static readonly $inject: string[] = [
        $scopeSID,
        $filterSID,
        $qSID,
        $routeSID,
        '$kookies',
        InteractionUtils.SID,
        DataUtils.SID,
        MvPlanner.SID,
        MvIdentity.SID,
        AgencyUserApiClient.SID,
        MvLocation.SID,
        tableServiceSID,
        $modalSID,
        I18nService.SID,
    ];


    public constructor(
        private $scope: OverviewScope,
        private $filter: IFilterService,
        private $q: IQService,
        private $route: IRoute,
        private $kookies: IKookies,
        private interactionUtils: InteractionUtils,
        private dataUtils: DataUtils,
        private mvPlanner: MvPlanner,
        public identity: MvIdentity,
        private agencyUserApiClient: AgencyUserApiClient,
        private mvLocation: MvLocation,
        private tableService: TableService,
        private $modal: ModalService,
        private i18n: I18nService,
    ) {
        this.mvLocation.hasAssignedLocations();

        this.initCookies();
        this.getChannels();
        if (this.identity.isManager()) {
            this.initAgencyManagers();
        }
        this.initSearchWatchers();
        this.$scope.$on('dateUpdated', async (_event, newMonth: string) => {
            this.month = newMonth;
            return this.updateOverview(newMonth);
        });
        this.$scope.$on('dateLoaded', async (_event, newMonth: string) => {
            this.month = newMonth;
            return this.updateOverview(newMonth);
        });
    }

    /**
     * TODO: review these cookies, I don't think they work.
     */
    private initCookies() {
        const filterCookies = tuple(
            'overviewSearchdate',
            'overviewSearchclient',
            'overviewSearchlocation',
            'overviewSearchitem',
            'overviewSearchstatus',
        );
        for (const filter of filterCookies) {
            const cookieValue = this.$kookies.get(filter);
            if (cookieValue && filter === 'overviewSearchdate') {
                this.searchDate = cookieValue;
            } else {
                this.$kookies.set(filter, '', { path: '/', secure: true });
            }
        }
    }

    private initSearchWatchers() {
        const searchExpressions = ['searchChannel', 'searchAssignedTo'];
        const cb = () => this.onSearchChange();
        angular.forEach(searchExpressions, expression => {
            this.$scope.$watch(expression, cb);
        });
        this.$scope.$watch('search', cb, true);
        for (const filterConfig of this.displayCustomization.filters) {
            const filterExpression = () => filterConfig.active;
            this.$scope.$watch(filterExpression, cb);
        }
    }

    public onSearchChange(): void {
        this.paging.resetPage();
    }

    private initDisplayCustomization() {
        const ColumnConfig = this.tableService.ColumnConfig; // Tslint:disable-line:variable-name
        const FilterConfig = this.tableService.FilterConfig; // Tslint:disable-line:variable-name
        const DisplayCustomization = this.tableService.DisplayCustomization; // Tslint:disable-line:variable-name

        const columns = [
            new ColumnConfig('date', () => this.i18n.text.common.date(), true, false),
            new ColumnConfig('client', () => this.i18n.text.common.client(), true, true),
            new ColumnConfig('location', () => this.i18n.text.common.location(), true, false),
            new ColumnConfig('item', () => this.i18n.text.common.item(), true, false),
            new ColumnConfig('channels', () => this.i18n.text.common.channels(), true, true),
            new ColumnConfig('assignedTo', () => this.i18n.text.common.assignedTo(), true, true),
            new ColumnConfig('status', () => this.i18n.text.common.status(), true, true),
        ];
        const filters = [
            new FilterConfig('unsupported', 'Unsupported', true, function filter(
                this: IFilterConfig<unknown>,
                planner: Untyped,
                index: Untyped,
            ) {
                return planner.isSupported || this.active;
            }),
            new FilterConfig('contentRequired', 'Content Required', true, function filter(
                this: IFilterConfig<unknown>,
                planner: Untyped,
                index: Untyped,
            ) {
                return planner.contentSupplied || this.active;
            }),
        ];

        const displayCustomization = new DisplayCustomization('overviewDisplayCustomization', columns, filters);
        displayCustomization.load();
        displayCustomization.startWatching(this.$scope, 'displayCustomization');
        return displayCustomization;
    }

    private initAgencyManagers() {
        return this.fetchAgencyManagers.run({}).then(data => {
            this.agencyManagers = data;
        });
    }

    private getChannels() {
        return this.fetchChannels.run({}).then(data => {
            if (data) {
                this.channels = data;
                for (const channel of this.channels) {
                    (this.channelsMap as Untyped)[channel.id] = channel.label;
                }
            } else {
                this.channels = [];
                this.channelsMap = {};
            }
        });
    }

    private getPlanners(startDate: Date, endDate: Date) {
        this.planners = null;
        return this.fetchPlanners.run({ endDate, startDate }).then(data => {
            for (const planner of data) {
                if (!planner.status) {
                    planner.status = 'planned';
                }
            }
            this.planners = data;
        });
    }

    private calculateDateOptions(inMonth: string): DayEntry[] {
        const startDate = moment.utc(inMonth);
        const days: DayEntry[] = [
            {
                date: null,
                display: '- Filter date -',
            },
            ...new Array(startDate.daysInMonth()).fill(0).map((_, index) => {
                const date = startDate.clone().date(index + 1);
                return {
                    date: date.format('YYYY-MM-DD'),
                    display: date.format('ddd D'),
                };
            }),
        ];
        this.searchDate = days[0].date;
        return days;
    }

    private async updateOverview(month: string): Promise<void> {
        const queryFrom = moment.utc(month).startOf('month').toDate();
        const queryTo = moment.utc(month).startOf('month').add(1, 'month').toDate();
        this.filteredPlanners.length = 0;
        this.days = this.calculateDateOptions(month);
        await this.getPlanners(queryFrom, queryTo);
    }

    public updateStatus(
        planner: {
            loading?: boolean;
            status: string;
            id: number;
            recurringPlannerId?: number;
            date: Date | string;
            locationId: LocationId;
            clientId: ClientId;
        },
        event: IAngularEvent,
    ): IPromise<unknown> {
        event.stopPropagation();
        planner.loading = true;
        let newStatus: PlannerStatus;
        switch (planner.status) {
            case PlannerStatuses.built:
                newStatus = PlannerStatuses.scheduled;
                break;
            case PlannerStatuses.scheduled:
                newStatus = PlannerStatuses.planned;
                break;
            default:
                newStatus = PlannerStatuses.built;
                break;
        }
        const context = {
            clientId: planner.clientId,
            date: coerceDate(planner.date),
            locationId: planner.locationId,
            plannerId: planner.id,
            recurringPlannerId: planner.recurringPlannerId,
            status: newStatus,
        };
        return this.submitUpdateStatus.run(context).then((data: { status: string; id: number }) => {
            planner.status = data.status;
            planner.id = data.id;
            planner.loading = false;
        });
    }

    public onAgencyManagerSelected(
        agencyManager: AgencyUserDto | undefined,
        planner: PlannerOverviewDto,
    ): IPromise<unknown> {
        planner.assignedToId = agencyManager ? agencyManager.id : null;
        return this.assignPlanner(planner);
    }

    public assignPlanner(planner: {
        id: number;
        loading?: boolean;
        locationId: LocationId;
        assignedToId: UserId | null;
        recurringPlannerId?: number | null;
        date: Date | string;
    }): IPromise<unknown> {
        planner.loading = true;
        const context: AssignPlannerBody = {
            date: coerceDate(planner.date),
            locationId: LocationId.from(planner.locationId),
            plannerId: planner.id,
            recurringPlannerId: planner.recurringPlannerId,
            userId: planner.assignedToId ? UserId.from(planner.assignedToId) : null,
        };
        return this.submitAssignPlanner.run(context).then((data: { id: number }) => {
            planner.id = data.id;
            planner.loading = false;
        });
    }

    public getButtonClass(plannerStatus: Untyped): string | undefined {
        if (plannerStatus === 'planned') {
            return 'btn-default';
        }
        if (plannerStatus === 'built') {
            return 'btn-primary';
        }
        if (plannerStatus === 'scheduled') {
            return 'btn-success';
        }
        return undefined;
    }

    // eslint-disable-next-line max-statements
    public getPlannerClass(type: Untyped, planner: Untyped): string | undefined {
        if (!planner.isSupported) {
            if (planner.postedBy === 'manager') {
                if (type === 'item') {
                    return 'cal-primary';
                }
                if (type === 'glyph') {
                    return 'glyphicon glyphicon-ok';
                }
            } else if (isSelfService(planner)) {
                if (type === 'item') {
                    if (['built', 'scheduled'].indexOf(planner.status) > -1) {
                        return 'cal-success';
                    } else if (planner.contentSupplied) {
                        return 'cal-warning';
                    } else {
                        return 'cal-danger';
                    }
                } else if (type === 'glyph') {
                    return 'fa fa-paint-brush';
                }
            } else {
                if (type === 'item') {
                    return 'cal-default';
                }
                if (type === 'glyph') {
                    return 'glyphicon glyphicon-ok';
                }
            }
        } else if (planner.contentSupplied) {
            if (type === 'item') {
                return 'cal-success';
            }
            if (type === 'glyph') {
                return 'glyphicon glyphicon-ok';
            }
        } else {
            if (type === 'item') {
                return 'cal-danger';
            }
            if (type === 'glyph') {
                return 'glyphicon glyphicon-exclamation-sign';
            }
        }
        return undefined;
    }

    /**
     * NOTE: server now returns the full channels down, no need to get label via map
     *
     * @param planner - The planner item to display the channels for
     * @returns The channels display
     */
    public getChannelsDisplay(planner: Untyped): string {
        let output = '';
        for (let i = 0; i < planner.channels.length; i++) {
            const label = (this.channelsMap as Untyped)[planner.channels[i].id];
            output += label;
            if (i < planner.channels.length - 1) {
                output += ', ';
            }
        }
        return output;
    }

    /**
     * Only allows one planner details row to be shown at a time.
     *
     * @param planner - Toggle which planner details row is shown
     * @returns void
     */
    public async togglePlannerDetails(planner: PlannerOverviewDto & { expanded?: boolean }): Promise<void> {
        if (planner.expanded) {
            planner.expanded = false;
        } /* If (!this.currentPlannerDetails || this.currentPlannerDetails.id != planner.id) */ else {
            if (this.planners) {
                for (const otherPlanner of this.planners) {
                    otherPlanner.expanded = false;
                }
            }
            this.currentPlannerDetails = null;
            planner.expanded = true;

            const context = {
                locationId: planner.locationId,
                plannerId: planner.id,
                recurringPlannerId: planner.recurringPlannerId || undefined,
            };
            return this.fetchOverviewPlannerDetails.run(context).then(data => {
                this.currentPlannerDetails = data;
            });
        }
        return Promise.resolve();
    }

    /**
     * Closure around the filter function, so that it remains bound to the displayCustomization object.
     *
     * @param value - The planner to use in the filter
     * @param index - The index of the planner
     * @returns The result of the filter operation
     */
    public applyDisplayCustomizationFilter(value: Untyped, index: Untyped): boolean {
        return this.displayCustomization.filter(value, index);
    }

    private testDisplayCustomization(planner: Untyped, index: Untyped) {
        return this.displayCustomization.filter(planner, index);
    }

    private testChannel(planner: Untyped, index: Untyped) {
        let searchTerm = this.searchChannel;
        if (!searchTerm) {
            return true;
        }
        searchTerm = searchTerm.toLowerCase();
        for (const channel of planner.channels) {
            const channelId = channel.id;
            const label = (this.channelsMap as Untyped)[channelId];
            if (label && label.toLowerCase().indexOf(searchTerm) > -1) {
                return true;
            }
        }
        return false;
    }

    private testAssignedTo(planner: Untyped, index: Untyped) {
        const searchAssignedTo = this.searchAssignedTo;
        if (searchAssignedTo === undefined || searchAssignedTo === null || searchAssignedTo === '') {
            return true; // Null search criteria = show all
        }
        const assignedTo = planner.assignedToId;
        if (searchAssignedTo === 'nobody') {
            return isNullOrUndefined(assignedTo);
        }
        return searchAssignedTo === assignedTo;
    }

    private testPlannerDate(planner: PlannerOverviewModel, index: number) {
        const criteria = this.searchDate;
        if (!criteria) {
            return true;
        }
        return criteria === this.$filter('date')(planner.date, 'yyyy-MM-dd');
    }

    private plannerPredicateInnerFunc(planner: Untyped, index: Untyped) {
        for (const predicate of this.plannerPredicates) {
            if (!predicate(planner, index)) {
                return false;
            }
        }
        return true;
    }

    public getRelativePlannerDate(planner: Untyped): Date {
        const date = new Date(planner.date);
        const timeOffset = planner.timeOffset;

        const newDate = new Date(date.toUTCString() + timeOffset);
        return newDate;
    }

    public areAllSelected(): boolean {
        if (!this.filteredPlanners) {
            return false;
        } else {
            for (const planner of this.filteredPlanners) {
                if (!planner.selected) {
                    return false;
                }
            }
            return true;
        }
    }

    public toggleSelectAll(): void {
        if (this.filteredPlanners) {
            const newStatus = !this.areAllSelected();
            this.filteredPlanners.forEach(planner => {
                planner.selected = newStatus;
            });
        }
    }

    public deselectAll(): void {
        if (this.planners) {
            this.planners.forEach(planner => {
                planner.selected = false;
            });
        }
    }

    public getNumSelected(): number {
        if (!this.planners) {
            return 0;
        } else {
            return this.planners.reduce((prev: number, curr) => prev + (curr.selected ? 1 : 0), 0);
        }
    }

    public getSelected(): PlannerOverviewModel[] {
        if (this.planners) {
            return this.planners.filter(planner => planner.selected);
        } else {
            return [];
        }
    }

    public clickBulkDelete(): IPromise<unknown> {
        const planners = this.getSelected();
        return this.confirmBulkAction(planners, 'delete').then((confirmed: boolean) => {
            if (confirmed) {
                const plannersToDelete = this.planners ? this.planners.filter(planner => planner.selected) : [];
                const hasRecurringPlanner = !!this.dataUtils.findByPredicate(
                    planner => !isNullOrUndefined(planner.recurringPlannerId),
                    plannersToDelete,
                );
                if (hasRecurringPlanner) {
                    // Ask the user if they want to delete just this instance, or all future events
                    const modalInstance = this.$modal.open({
                        controller: 'mvPlannerEventApplyActionToModalCtrl',
                        templateUrl: '/partials/overview/bulkDeleteRecurringEventPrompt',
                    });
                    return modalInstance.result.then((applyActionTo: ApplyActionTo) =>
                        this.bulkDelete(
                            applyActionTo !== ApplyActionTo.current
                                ? this.processRecurringPlannerEventsForDeletion(planners)
                                : planners,
                            applyActionTo,
                        ),
                    );
                } else {
                    return this.bulkDelete(planners, ApplyActionTo.current);
                }
            }
            // eslint-disable-next-line promise/no-return-wrap
            return Promise.resolve();
        });
    }

    public confirmBulkAction(planners: PlannerOverviewModel[], actionDescription: string): IPromise<boolean> {
        const modalInstance = this.$modal.open({
            backdrop: 'static',
            controller: MvConfirmBulkActionCtrl,
            controllerAs: 'ctrl',
            resolve: {
                actionDescription: () => actionDescription,
                planners: () => planners,
            },
            templateUrl: '/partials/overview/confirmBulkAction',
        });
        return modalInstance.result;
    }

    public bulkDelete(planners: PlannerOverviewModel[], applyActionTo: ApplyActionTo): IPromise<void> {
        if (planners.length === 0) {
            // eslint-disable-next-line no-console
            console.log("Can't delete 0 planners!");
            return this.$q.resolve();
        }
        const modalInstance = this.$modal.open({
            backdrop: 'static',
            controller: MvMultiplePlannerDeleteCtrl,
            controllerAs: 'ctrl',
            resolve: {
                applyActionTo: () => applyActionTo,
                planners: () => planners,
            },
            templateUrl: '/partials/overview/multiplePlannerDelete',
        });
        return modalInstance.result.then(() => {
            this.$route.reload();
        });
    }

    private processRecurringPlannerEventsForDeletion(planners: PlannerOverviewModel[]): PlannerOverviewModel[] {
        // Split planner events to be deleted into recurring and non-recurring
        const [recurring, nonRecurring] = partition(planners, planner => !!planner.recurringPlannerId);

        // Group planner events according to their recurringPlannerId
        const recurringPlannerMap = new Map<number, PlannerOverviewModel[]>();
        recurring.forEach(planner => {
            assertDefined(planner.recurringPlannerId);
            if (recurringPlannerMap.has(planner.recurringPlannerId)) {
                const groupedPlanners = recurringPlannerMap.get(planner.recurringPlannerId);
                assertDefined(groupedPlanners);
                recurringPlannerMap.set(planner.recurringPlannerId, [...groupedPlanners, planner]);
            } else {
                recurringPlannerMap.set(planner.recurringPlannerId, [planner]);
            }
        });

        // Get first, earliest planner event for each recurring planner group
        const processedPlanners = Array.from(recurringPlannerMap.values()).map(recurringPlanners =>
            recurringPlanners.reduce((earliest, current) =>
                current.date.getTime() < earliest.date.getTime() ? current : earliest,
            ),
        );

        return [...processedPlanners, ...nonRecurring];
    }
}

angular.module('app').controller(MvOverviewCtrl.SID, MvOverviewCtrl);
