/// <reference path="../../../typings/browser.d.ts" />
import { $httpSID, $locationSID, $qSID, $routeParamsSID, $scopeSID, $timeoutSID } from '../common/angularData';
import { DataUtils } from '../common/dataUtils';
import { InteractionUtils } from '../common/interactionUtils';
import IScope = angular.IScope;
import IRouteParams = angular.IRouteParams;
import IHttpService = angular.IHttpService;
import IQService = angular.IQService;
import ILocationService = angular.ILocationService;

interface FinalisePaymentResponse {
    success: boolean;
    message?: string;
}

interface MvPaymentsFinaliseCtrl extends IScope {
    paymentResponse: FinalisePaymentResponse | null;
    accessCode?: string;
    redirectUrl: string;
    loading: {
        finalisePayment: boolean;
    };
}

angular.module('app').controller('mvPaymentsFinaliseCtrl', [
    $scopeSID,
    $routeParamsSID,
    $httpSID,
    $qSID,
    $locationSID,
    $timeoutSID,
    InteractionUtils.SID,
    DataUtils.SID,
    function (
        $scope: MvPaymentsFinaliseCtrl,
        $routeParams: IRouteParams,
        $http: IHttpService,
        $q: IQService,
        $location: ILocationService,
        $timeout: ng.ITimeoutService,
        interactionUtils: InteractionUtils,
        dataUtils: DataUtils,
    ) {
        $scope.paymentResponse = null;
        $scope.accessCode = $routeParams.AccessCode;
        $scope.redirectUrl = $routeParams.r || '/';
        $scope.loading = {
            finalisePayment: false,
        };
        const REDIRECT_IN_SECS = 4;

        function initPage() {
            return getPaymentResponse();
        }

        function getPaymentResponse() {
            const payload = {
                accessCode: $scope.accessCode,
            };

            return interactionUtils
                .handleRemote(
                    $scope,
                    'finalise payment',
                    'finalisePayment',
                    $http.post<FinalisePaymentResponse>('/api/payments/finalise', payload),
                )
                .then(dataUtils.setScopeProperty($scope, 'paymentResponse'))
                .then(redirectOnSuccess);
        }

        function redirectOnSuccess() {
            if ($scope.paymentResponse && $scope.paymentResponse.success) {
                return $timeout(() => {
                    $location.url($scope.redirectUrl);
                }, REDIRECT_IN_SECS * 1000);
            }
        }

        void initPage();
    },
]);

