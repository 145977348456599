import { AssignedLocation, ImageSection, Section, TextSection, Upload } from '@deltasierra/shared';
import { $scopeSID, actualComponent, OneWayBinding, OptionalOneWayBinding } from '../../../common/angularData';
import { getFieldValidationClassName } from '../../builderDocumentValidation';
import { EmailBuilder } from '../emailBuilder';

export interface SimpleEmailEditorScope extends ng.IScope {
    emailBuilder: EmailBuilder;
    getFieldValidationClassName: typeof getFieldValidationClassName;
    showImageChooser(section: ImageSection): void;
}

export class SimpleEmailEditorCtrl {
    public static readonly SID = 'SimpleEmailEditorCtrl';

    public static readonly $inject: string[] = [$scopeSID];

    // Props
    public readonly onClickShowImagePicker!: (section: ImageSection) => void;

    public readonly emailBuilder!: EmailBuilder;

    public readonly isTextSection?: (section: Section) => section is TextSection;

    public readonly location?: AssignedLocation;

    public readonly plannerImages?: Upload[];

    public constructor(protected readonly $scope: SimpleEmailEditorScope) {}

    public $onInit(): void {
        // We need to inject some properties directly into the scope, so that "editorField" components work
        this.$scope.emailBuilder = this.emailBuilder;
        this.$scope.getFieldValidationClassName = getFieldValidationClassName;
        this.$scope.showImageChooser = this.onClickShowImagePicker;
    }
}

export const simpleEmailEditorSID = 'simpleEmailEditor';

export const simpleEmailEditorConfig = actualComponent(
    SimpleEmailEditorCtrl,
    '/partials/contentBuilder/email/simpleEmailEditor/simpleEmailEditor',
    {
        emailBuilder: OneWayBinding,
        isTextSection: OneWayBinding,
        location: OptionalOneWayBinding,
        onClickShowImagePicker: OneWayBinding,
        plannerImages: OptionalOneWayBinding,
    },
);

angular.module('app').component(simpleEmailEditorSID, simpleEmailEditorConfig);
