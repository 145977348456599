import { ConversionStats } from '@deltasierra/shared';
import { ReportTable, StatsEntry, TextAlignClass } from '../reportTable/reportTable';
import { compareStrings, compareNumbers } from '../compare';
import { CreateReportTableOptions } from './common';

export function createConversionSummaryReportTable(options: CreateReportTableOptions) {
    return new ReportTable<StatsEntry<ConversionStats>, ConversionStats>({
        columns: [
            {
                label: () => options.i18n.text.report.location(),
                cssClass: TextAlignClass.Left,
                format: options.formatters.formatString,
                getValue: entry => entry.location.locationTitle,
                getTotal: () => options.i18n.text.report.total(),
                compare: compareStrings,
            },
            {
                label: () => options.i18n.text.report.platform(),
                cssClass: TextAlignClass.Left,
                format: options.formatters.formatString,
                getValue: entry => entry.platformName,
                getTotal: totals => '',
                compare: compareStrings,
            },
            {
                label: () => options.i18n.text.report.totalConversions(),
                cssClass: TextAlignClass.Right,
                format: options.formatters.formatNumber,
                getValue: entry => entry.conversionCount,
                getTotal: totals => totals.conversionCount,
                compare: compareNumbers,
            },
            {
                label: () => options.i18n.text.report.currency(),
                cssClass: TextAlignClass.Center,
                format: options.formatters.formatString,
                getValue: entry => entry.conversionCurrency,
                getTotal: totals => totals.conversionCurrency,
                compare: compareStrings,
            },
            {
                label: () => options.i18n.text.report.valuePerConversion(),
                cssClass: TextAlignClass.Right,
                format: value => value === null ? '' : options.formatters.formatCurrency(value, { format: 'number' }),
                getValue: entry => entry.conversionWorth,
                getTotal: totals => totals.conversionWorth,
                compare: compareNumbers,
            },
            {
                label: () => options.i18n.text.report.totalConversionWorth(),
                cssClass: TextAlignClass.Right,
                format: value => value === null ? '' : options.formatters.formatCurrency(value, { format: 'number' }),
                getValue: entry => entry.totalConversionWorth,
                getTotal: totals => totals.totalConversionWorth,
                compare: compareNumbers,
            },
        ],
    });
}
