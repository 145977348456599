import { DSFormGroup, DSFormLabel, DSTypography, Loading, Translate } from '@deltasierra/components';
import * as React from 'react';
import { useGetFacebookPageDescription } from './useGetFacebookPageDescription';

export type FacebookFormDescriptionSectionProps = {
    locationIds: string[];
};

export function FacebookFormDescriptionSection({ locationIds }: FacebookFormDescriptionSectionProps): JSX.Element {
    const { description, loading: descriptionLoading } = useGetFacebookPageDescription(locationIds);

    return (
        <DSFormGroup>
            <DSFormLabel>
                <Translate keyId="BUILD.PUBLISH.FACEBOOK.FACEBOOK_PAGE" />
            </DSFormLabel>
            {descriptionLoading && <Loading inline size="small" />}
            {description && <DSTypography>{description}</DSTypography>}
        </DSFormGroup>
    );
}
FacebookFormDescriptionSection.displayName = 'FacebookFormDescriptionSection';
