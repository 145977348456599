import { Translate } from '@deltasierra/components';
import { CLUB_READY_MAX_LOCATION_PUBLISH_COUNT } from '@deltasierra/integrations/clubready';
import React from 'react';
import { StyledDSAlert } from '../common';

export function LargeClubReadyPublishMessage(): JSX.Element {
    return (
        <StyledDSAlert severity="error">
            <Translate
                keyId="BUILD.PUBLISH.CLUB_READY.WARN_PUBLISH_TOO_LARGE"
                options={{ publishSize: CLUB_READY_MAX_LOCATION_PUBLISH_COUNT }}
            />
        </StyledDSAlert>
    );
}
LargeClubReadyPublishMessage.displayName = 'LargeClubReadyPublishMessage';
