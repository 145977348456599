import {
    AuthServiceApi,
    BasicInstagramDetailsWithLinkedPage,
    InstagramEndpoints,
    justParams,
    LocationId,
    t,
    TermsAndConditionsChannelData,
} from '@deltasierra/shared';

import * as React from 'react';
import { useAngularServiceContext } from '../../../common/componentUtils/angularServiceContexts';
import { invokeApiRoute } from '../../../common/httpUtils';
import { MvContentBuilderPublishCtrl } from '../mvContentBuilderPublishCtrl';

export const useTermsAndConditions = (
    publishController: MvContentBuilderPublishCtrl,
): [string | null, string | null] => {
    const termsAndConditionsText = React.useMemo(
        () =>
            publishController.contentBuilder.getChannelDatum(
                'termsAndConditions',
                (data: TermsAndConditionsChannelData) => data.text,
            ),
        [publishController.contentBuilder],
    );
    const termsAndConditionsUrl = React.useMemo(
        () =>
            publishController.contentBuilder.getChannelDatum(
                'termsAndConditions',
                (data: TermsAndConditionsChannelData) => data.url,
            ),
        [publishController.contentBuilder],
    );
    return [termsAndConditionsText, termsAndConditionsUrl];
};

export const useImageProcessingContext = (): React.MutableRefObject<{ isProcessingOnServer: boolean }> => {
    const ref = React.useRef({ isProcessingOnServer: false });
    React.useDebugValue(JSON.stringify(ref.current));
    return ref;
};

export const useGetInstagramPageDescription = (locationIds: LocationId[]): [string, boolean] => {
    const http = useAngularServiceContext('$http');
    const [response, setResponse] = React.useState<BasicInstagramDetailsWithLinkedPage[]>([]);
    const [pageDescription, setPageDescription] = React.useState('');
    const [fetched, setFetched] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const MAX_LENGTH = 5;

    React.useEffect(() => {
        async function getInstagramPageDescription() {
            const endpoint = InstagramEndpoints.getConnectedInstagramAccountForLocation;
            const requests = locationIds.slice(0, MAX_LENGTH).map(async locationId => {
                const params = justParams({ locationId });
                return Promise.resolve(invokeApiRoute(http, endpoint, params));
            });
            const info = await Promise.all(requests);
            setResponse(info);
            setFetched(true);
        }
        if (!fetched) {
            void getInstagramPageDescription();
        }
    }, [http, fetched, locationIds]);

    React.useEffect(() => {
        if (fetched) {
            let pageString = '';
            if (response.length === 0) {
                setPageDescription('-');
            } else {
                pageString = response.map(({ name }) => name).join(',');
                if (locationIds.length > MAX_LENGTH) {
                    pageString += t('COMMON.AND_X_OTHER_LOCATIONS', { count: locationIds.length - MAX_LENGTH });
                }
                setPageDescription(pageString);
            }
            setLoading(false);
        }
    }, [fetched, response, locationIds]);

    return [pageDescription, loading];
};

export const useCanPostToInstagramForLocation = (): ((locationId: LocationId) => Promise<boolean>) => {
    const http = useAngularServiceContext('$http');
    return React.useCallback(
        async (locationId: LocationId) => {
            const { result } = await invokeApiRoute(
                http,
                AuthServiceApi.isAuthorizedAndConfiguredForInstagram,
                justParams({ modelId: locationId }),
            );
            return result;
        },
        [http],
    );
};
