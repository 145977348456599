import { OverlayLoading } from '@deltasierra/components';
import { assertNever } from '@deltasierra/shared';
import { NodeViewWrapper } from '@tiptap/react';
import * as React from 'react';
import styled from 'styled-components';
import { useUploadFiles, UseUploadFileUploadedFile } from '../../../../hooks';
import { UploadFileNodeAttributes } from './upload-file-node-attributes';
import { UploadFileNodeOptions } from './upload-file-node-options';

const StyledWrapper = styled.div<{ $align: TipTapTextAlignType }>`
    position: relative;

    ${props => {
        switch (props.$align) {
            case 'center':
                return 'text-align: center;';
            case 'justify':
                return 'text-align: justify;';
            case 'left':
                return 'text-align: left;';
            case 'right':
                return 'text-align: right;';
            default:
                return assertNever(props.$align);
        }
    }}
`;

const StyledImgWrapper = styled.span<{ $selected: boolean }>`
    position: relative;
    display: inline-block;
    ${props =>
        props.$selected &&
        `
            outline: 3px solid #68cef8;
            z-index: 1000;
        `}
`;

export type UploadFileNodeViewProps = TipTapNodeViewWrapperChildProps<UploadFileNodeAttributes, UploadFileNodeOptions>;

export function UploadFileNodeView({
    deleteNode,
    editor,
    extension,
    getPos,
    node,
    selected,
}: UploadFileNodeViewProps): JSX.Element {
    const { file, textAlign = 'left', uploadCategory } = node.attrs;
    const { imageClassName } = extension.options;
    // We set a default uploadCategory, but we will not use it, if the file doesn't exist and the upload category, we just
    // Delete this node
    const [uploadFile, { hasTriedUpload, lastUploadedFile, loading }] = useUploadFiles(
        uploadCategory ?? 'emailResources',
    );

    const fileSrc = React.useMemo(
        () => (node.attrs.file ? URL.createObjectURL(node.attrs.file) : ''),
        [node.attrs.file],
    );

    const onUploadSuccess = React.useCallback(
        (upload: UseUploadFileUploadedFile) => {
            // We get this position of this node
            const pos = getPos();
            // Insert the uploaded image at that position
            editor
                .chain()
                .insertUploadedImage({
                    insertPos: pos,
                    src: upload.url ?? '',
                    textAlign,
                    uploadId: upload.id,
                    width: upload.image?.width,
                })
                .run();
            // Then delete this node
            deleteNode();
        },
        [deleteNode, editor, getPos, textAlign],
    );

    // On the first run, start the upload
    React.useEffect(() => {
        if (!uploadCategory || !file) {
            deleteNode();
            return;
        }
        // We will start an upload
        void uploadFile(file);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (loading || !hasTriedUpload) {
            return;
        }
        if (lastUploadedFile) {
            onUploadSuccess(lastUploadedFile);
        } else {
            // If we have tried to upload and we are no longer loading, we failed and should cleanup this node
            deleteNode();
        }
    }, [onUploadSuccess, lastUploadedFile, loading, hasTriedUpload, deleteNode]);

    return (
        <NodeViewWrapper>
            <StyledWrapper $align={textAlign}>
                <StyledImgWrapper $selected={selected} data-drag-handle>
                    <img className={imageClassName} src={fileSrc} />
                    <OverlayLoading />
                </StyledImgWrapper>
            </StyledWrapper>
        </NodeViewWrapper>
    );
}
UploadFileNodeView.displayName = 'UploadFileNodeView';
