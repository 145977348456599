import { DSTypography, DSWell, Translate } from '@deltasierra/components';
import {
    pluck,
    AssignedLocation,
    LocationIdHierarchy,
    MAX_FACEBOOK_CAPTION_CHARACTER_LENGTH,
    MAX_FACEBOOK_COMMENT_CHARACTER_LENGTH,
    MAX_INSTAGRAM_CAPTION_CHARACTER_LENGTH,
} from '@deltasierra/shared';
import { Formik } from 'formik';
import * as React from 'react';
import { FormButtons } from '../../common/FormButton';
import { useTermsAndConditions } from '../../common/hooks';
import { TermsAndConditionsPublishMessage } from '../../common/termsAndConditions/termsAndConditionsPublishMessage';
import { FacebookPublishType } from '../../facebook/FacebookPublishForm';
import { useRequiredHashtags } from '../../hooks';
import { MvContentBuilderPublishCtrl } from '../../mvContentBuilderPublishCtrl';
import {
    buildFacebookAndInstagramPublishData,
    getCaptionWithTermsAndConditions,
} from './build-facebook-and-instagram-publish-data';
import { FacebookAndInstagramData } from './facebook-and-instagram-data';
import { FacebookAndInstagramFormValues } from './facebook-and-instagram-form-values';
import { FacebookAndInstagramFormCaptionSection } from './FacebookAndInstagramFormCaptionSection';
import { FacebookAndInstagramFormDescriptionSection } from './FacebookAndInstagramFormDescriptionSection';
import { FacebookAndInstagramFormScheduleSection } from './FacebookAndInstagramFormScheduleSection';
import { FacebookAndInstagramHashtagsSection } from './FacebookAndInstagramHashtagsSection';
import { useValidatedFacebookAndInstagramForm } from './useValidatedFacebookAndInstagramForm';

export type PublishToFacebookAndInstagramProps = {
    location: AssignedLocation;
    locations: LocationIdHierarchy[];
    onCancel: () => void;
    onPublish: (data: FacebookAndInstagramData) => void;
    publishController: MvContentBuilderPublishCtrl;
    publishType: FacebookPublishType;
};

export function PublishToFacebookAndInstagramDirectForm({
    location,
    locations,
    onCancel,
    onPublish,
    publishController,
    publishType,
}: PublishToFacebookAndInstagramProps): JSX.Element {
    const graphqlLocationIds = React.useMemo(() => pluck('locationGraphqlId', locations), [locations]);
    const oldLocationIds = React.useMemo(() => pluck('locationId', locations), [locations]);

    const [submitted, setSubmitted] = React.useState(false);

    const { loading: requiredHashtagsLoading, requiredHashtags } = useRequiredHashtags(location.graphqlId);
    const [termsAndConditionsText, termsAndConditionsUrl] = useTermsAndConditions(publishController);

    const onSubmit = React.useCallback(
        (formValues: FacebookAndInstagramFormValues) => {
            if (!submitted) {
                setSubmitted(true);
                const publishData = buildFacebookAndInstagramPublishData(formValues, {
                    requiredHashtags,
                    termsAndConditionsText,
                    termsAndConditionsUrl,
                });

                onPublish(publishData);
            }
        },
        [submitted, termsAndConditionsText, termsAndConditionsUrl, requiredHashtags, onPublish],
    );

    const formikProps = useValidatedFacebookAndInstagramForm({
        date: publishController?.plannerDetails?.date,
        requiredHashtags,
        termsAndConditionsText,
        termsAndConditionsUrl,
    });

    const headerTranslationKey = publishController.isExportingVideo()
        ? 'BUILD.PUBLISH.MULTIPLE_PLATFORMS.PUBLISH_VIDEO_TO_FACEBOOK_AND_INSTAGRAM'
        : 'BUILD.PUBLISH.MULTIPLE_PLATFORMS.PUBLISH_IMAGE_TO_FACEBOOK_AND_INSTAGRAM';

    return (
        <Formik {...formikProps} validateOnBlur validateOnChange validateOnMount onSubmit={onSubmit}>
            {formik => (
                <>
                    <DSWell>
                        <DSTypography gutterBottom variant="h3">
                            <Translate keyId={headerTranslationKey} />
                        </DSTypography>
                        <FacebookAndInstagramFormDescriptionSection
                            locationIds={graphqlLocationIds}
                            oldLocationIds={oldLocationIds}
                        />
                        <DSTypography gutterBottom variant="h4">
                            <Translate keyId="BUILD.PUBLISH.MULTIPLE_PLATFORMS.HEADERS.POST_DETAILS" />
                        </DSTypography>
                        <FacebookAndInstagramFormCaptionSection formik={formik} />
                        <TermsAndConditionsPublishMessage
                            text={termsAndConditionsText}
                            url={termsAndConditionsUrl}
                            useNewLabel
                        />
                        <FacebookAndInstagramHashtagsSection
                            availableCaptionLength={Math.max(
                                0,
                                Math.min(
                                    MAX_INSTAGRAM_CAPTION_CHARACTER_LENGTH,
                                    MAX_FACEBOOK_CAPTION_CHARACTER_LENGTH,
                                ) -
                                    getCaptionWithTermsAndConditions({
                                        baseCaption: formik.values.caption,
                                        termsAndConditionsText,
                                        termsAndConditionsUrl,
                                    }).length,
                            )}
                            availableFirstCommentLength={Math.min(
                                MAX_INSTAGRAM_CAPTION_CHARACTER_LENGTH,
                                MAX_FACEBOOK_COMMENT_CHARACTER_LENGTH,
                            )}
                            formik={formik}
                            locationId={location.graphqlId}
                            locationIds={graphqlLocationIds}
                            requiredHashtags={requiredHashtags}
                            requiredHashtagsLoading={requiredHashtagsLoading}
                        />
                        <DSTypography gutterBottom variant="h4">
                            <Translate keyId="BUILD.PUBLISH.MULTIPLE_PLATFORMS.HEADERS.POST_SCHEDULE" />
                        </DSTypography>
                        <FacebookAndInstagramFormScheduleSection
                            formik={formik}
                            multipleLocations={locations.length > 1}
                            publishType={publishType}
                            timezone={location.timezone}
                        />
                    </DSWell>
                    <FormButtons
                        disabled={!formik.isValid || formik.isSubmitting || requiredHashtagsLoading}
                        isScheduled={!!formik.values.scheduledTime}
                        onCancel={onCancel}
                        onSubmit={formik.handleSubmit}
                    />
                </>
            )}
        </Formik>
    );
}
PublishToFacebookAndInstagramDirectForm.displayName = 'PublishToFacebookAndInstagramDirectForm';
