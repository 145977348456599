/// <reference path="../../../../typings/browser.d.ts" />
import { BuilderTemplateCategorySimpleDto } from '@deltasierra/shared';
import { $scopeSID, ExpressionCallback } from '../../common/angularData';
import { I18nService } from '../../i18n';
import {
    AngularjsDropdownMultiselectEvents,
    AngularjsDropdownMultiselectExtraSettings,
    AngularjsDropdownMultiselectTranslationTexts,
} from '../../typings/angularjsDropdownMultiselect/angularjsDropdownMultiselect';
import { TemplateGroupType, TemplateGroupTypeEnum } from '../templateGroup';
import IScope = angular.IScope;

interface CategoryOption {
    label: string;
    groupType: TemplateGroupType;
    disabled: boolean;
}

function groupTypesMatch(first: TemplateGroupType, second: TemplateGroupType): boolean {
    if (first.type !== second.type) {
        return false;
    } else if (first.type === TemplateGroupTypeEnum.Category && second.type === TemplateGroupTypeEnum.Category) {
        return first.categoryId === second.categoryId;
    } else {
        return true;
    }
}

export class BuilderTemplateCategoryCriteriaController {
    public static readonly $inject: string[] = [$scopeSID, I18nService.SID];

    public static SID = 'BuilderTemplateCategoryCriteriaController';

    public readonly categories!: BuilderTemplateCategorySimpleDto[];

    public readonly change!: ExpressionCallback<{ groupTypes: TemplateGroupType[] }>;

    public readonly preselectedGroupTypes?: TemplateGroupType[];

    // This is set in onInit
    public options!: CategoryOption[];

    public selectedOptions!: CategoryOption[];

    public readonly multiselectSettings: AngularjsDropdownMultiselectExtraSettings = {
        checkBoxes: true,
        displayProp: 'label' as keyof CategoryOption,
        enableSearch: true,
        keyboardControls: true,
        scrollable: true,
        searchField: 'label' as keyof CategoryOption,
        showCheckAll: false,
        showUncheckAll: true,
        smartButtonMaxItems: 1,
    };

    public readonly multiselectEvents: AngularjsDropdownMultiselectEvents = {
        onSelectionChanged: () => this.onCategoryChange(),
    };

    public readonly multiselectTexts: AngularjsDropdownMultiselectTranslationTexts = {
        buttonDefaultText: this.i18nService.text.common.categories(),
        dynamicButtonTextSuffix: this.i18nService.text.common.categories(),
        searchPlaceholder: this.i18nService.text.common.search(),
        uncheckAll: this.i18nService.text.common.all(),
    };

    public constructor(protected readonly $scope: IScope, protected readonly i18nService: I18nService) {
    }

    public $onInit(): void {
        this.options = this.convertToOptions(this.categories);
        this.selectCategoriesFromPreselectedGroupTypes();
        this.initWatchers();

    }

    protected initWatchers(): void {
        this.$scope.$watchCollection(
            () => this.preselectedGroupTypes,
            () => this.selectCategoriesFromPreselectedGroupTypes(),
        );
    }

    protected selectCategoriesFromPreselectedGroupTypes(): void {
        const selectedOptions = [];
        if (this.preselectedGroupTypes && this.preselectedGroupTypes.length > 0) {
            for (const option of this.options) {
                for (const preselectedGroupType of this.preselectedGroupTypes) {
                    if (groupTypesMatch(option.groupType, preselectedGroupType)) {
                        selectedOptions.push(option);
                    }
                }
            }
        }
        this.selectedOptions = selectedOptions;
        this.setCategoriesAvailability();
    }

    protected convertToOptions(categories: BuilderTemplateCategorySimpleDto[]): CategoryOption[] {
        const categoryOption: CategoryOption[] = categories.map(category => ({
            disabled: false,
            groupType: {
                categoryId: category.id,
                type: TemplateGroupTypeEnum.Category,
            },
            label: category.title,
        }));

        return [
            {
                disabled: false,
                groupType: {
                    type: TemplateGroupTypeEnum.RecentlyAdded,
                },
                label: this.i18nService.text.build.recentlyAdded(),
            },
            ...categoryOption,
        ];
    }

    protected shouldDisableCategories(): boolean {
        for (const option of this.selectedOptions) {
            if (option.groupType.type === TemplateGroupTypeEnum.RecentlyAdded) {
                return true;
            }
        }
        return false;
    }

    private setCategoriesAvailability() {
        if (this.selectedOptions.length === 0) {
            for (const option of this.options) {
                option.disabled = false;
            }
        } else {
            const categoriesDisabled = this.shouldDisableCategories();
            for (const option of this.options) {
                if (option.groupType.type === TemplateGroupTypeEnum.Category) {
                    option.disabled = categoriesDisabled;
                } else if (option.groupType.type === TemplateGroupTypeEnum.RecentlyAdded) {
                    option.disabled = !categoriesDisabled;
                }
            }
            // Remove disabled options from selection, since the user has no way to unselect a disabled option.
            this.selectedOptions = this.selectedOptions.filter(option => !option.disabled);
        }
    }

    private onCategoryChange() {
        this.setCategoriesAvailability();
        this.change({
            groupTypes: this.selectedOptions.map(category => category.groupType),
        });
    }
}

angular
    .module('app')
    .controller(BuilderTemplateCategoryCriteriaController.SID, BuilderTemplateCategoryCriteriaController);
