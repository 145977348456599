import { ExportPlatforms } from '@deltasierra/shared';
import { $scopeSID, actualComponent, ILifecycleHooks, OneWayBinding } from '../../../../common/angularData';
import { UploadContext } from '../../../../common/uploadService';
import { LocalPublishService } from '../../../../integration/publish/localPublishService';
import { BuilderConstants, builderConstantsSID } from '../../../builderConstants';
import { PublishResult } from '../../../publish/publishResult';
import { EmailPublishData } from '../../emailPublishData';
import { EmailPublishService } from '../emailPublishService';
import IScope = angular.IScope;

const UNSAFE_FILE_NAME_CHARACTERS = /[\\/:*?\0"<>|]/g;

export class PublishEmailToLocalCtrl implements ILifecycleHooks {
    public static readonly SID = 'PublishEmailToLocalCtrl';

    public static readonly $inject: string[] = [
        $scopeSID,
        builderConstantsSID,
        EmailPublishService.SID,
        LocalPublishService.SID,
    ];

    public readonly publishData!: EmailPublishData;

    public readonly uploadContext: UploadContext = new UploadContext();

    public outputObjectUrl?: string;

    public fileName?: string;

    public constructor(
        protected readonly $scope: IScope,
        protected readonly builderConstants: BuilderConstants,
        protected readonly emailPublishService: EmailPublishService,
        private readonly localPublishService: LocalPublishService,
    ) {}

    public async $onInit(): Promise<void> {
        await this.generateEmailForDownload();
    }

    public finish(): void {
        if (this.outputObjectUrl) {
            URL.revokeObjectURL(this.outputObjectUrl);
        }
        this.$scope.$emit(this.builderConstants.EVENTS.PUBLISH_FINISH, new PublishResult(ExportPlatforms.Local, null));
    }

    protected async generateEmailForDownload(): Promise<void> {
        this.fileName = this.generateImageFileName();
        const { html, upload } = await this.emailPublishService.rectifyAndUpload(
            this.publishData.builderDocument,
            this.publishData.htmlDocument,
            this.publishData.fileCache,
            this,
        );
        // Browsers don't allow cross origin download links so this has to be a blob
        // It is dumb but it works
        const blob = new Blob([html], { type: 'text/html' });
        this.outputObjectUrl = URL.createObjectURL(blob);

        // Save the local artifact
        await this.localPublishService.publishLocal(this.publishData.location.id, {
            builderTemplateId: this.publishData.templateId,
            plannerId: this.publishData.plannerId,
            uploadId: upload.id,
        });
    }

    protected generateImageFileName(): string {
        return `${(this.publishData.builderDocument.title || 'email').replace(UNSAFE_FILE_NAME_CHARACTERS, '_')}.html`;
    }
}

export const publishEmailToLocalSID = 'publishEmailToLocal';

export const publishEmailToLocalConfig = actualComponent(
    PublishEmailToLocalCtrl,
    '/partials/contentBuilder/email/publish/local/publishEmailToLocal',
    {
        publishData: OneWayBinding,
    },
);

angular.module('app').component(publishEmailToLocalSID, publishEmailToLocalConfig);
