import { gql } from '@apollo/client';
import {
    DSBasicSwitch,
    DSButton,
    DSDialogActions,
    DSDialogContent,
    DSDialogTitle,
    DSFormControlLabel,
    DSFormGroup,
    DSTextField,
    Translate,
} from '@deltasierra/components';
import { isNotNullOrUndefined, t } from '@deltasierra/shared';
import { Formik } from 'formik';
import * as React from 'react';
import { HashtagsInput } from '../../../../common/HashtagsInput';
import { HashtagSetFragmentForDialogContent } from './__graphqlTypes/HashtagSetFragmentForDialogContent';

const hashtagSetFragment = gql`
    fragment HashtagSetFragmentForDialogContent on HashtagSet {
        id
        ... on ClientHashtagSet {
            hashtags
            isCore
            title
            description
        }
        ... on LocationHashtagSet {
            hashtags
            title
            description
        }
    }
`;

export interface HashtagSetFormValues {
    description: string;
    hashtags: string[];
    isCore?: boolean;
    title: string;
}

const defaultInitialValues: HashtagSetFormValues = {
    description: '',
    hashtags: [],
    isCore: false,
    title: '',
};

function validate(values: HashtagSetFormValues): Partial<Record<keyof HashtagSetFormValues, string>> {
    const errors: Partial<Record<keyof HashtagSetFormValues, string>> = {};
    if (values.title.length === 0) {
        errors.title = t('COMMON.REQUIRED');
    }
    if (values.hashtags.length === 0) {
        errors.hashtags = t('AGENCY.HASHTAG_SETS.DIALOG.MINIMUM_ONE');
    }
    return errors;
}

export interface HashtagSetDialogContentProps {
    allowIsCore?: boolean;
    hashtagSet?: HashtagSetFragmentForDialogContent;
    headerText: string;
    onCancel?: () => void;
    onSubmit?: (values: HashtagSetFormValues) => void;
    submitButtonText: string;
}

export function HashtagSetDialogContent({
    allowIsCore,
    hashtagSet,
    headerText,
    onCancel,
    onSubmit,
    submitButtonText,
}: HashtagSetDialogContentProps): JSX.Element {
    const initialValues: HashtagSetFormValues = React.useMemo(
        () => ({
            description: hashtagSet?.description ?? defaultInitialValues.description,
            hashtags: hashtagSet?.hashtags ? [...hashtagSet.hashtags] : defaultInitialValues.hashtags,
            isCore:
                hashtagSet?.__typename === 'ClientHashtagSet' && isNotNullOrUndefined(hashtagSet.isCore)
                    ? hashtagSet.isCore
                    : defaultInitialValues.isCore,
            title: hashtagSet?.title ?? defaultInitialValues.title,
        }),
        [hashtagSet],
    );
    function handleSubmit(values: HashtagSetFormValues) {
        if (onSubmit) {
            onSubmit(values);
        }
    }
    return (
        <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit}>
            {formik => (
                <form onSubmit={formik.handleSubmit}>
                    <DSDialogTitle>{headerText}</DSDialogTitle>
                    <DSDialogContent>
                        <DSTextField
                            autoFocus
                            data-cy="manage-hashtag-form-title-input"
                            error={!!formik.errors.title}
                            fullWidth
                            helperText={formik.errors.title}
                            label={t('COMMON.TITLE')}
                            {...formik.getFieldProps('title')}
                            style={{ marginBottom: '12px' }}
                            variant="outlined"
                        />
                        <DSTextField
                            data-cy="manage-hashtag-form-description-input"
                            fullWidth
                            label={t('COMMON.DESCRIPTION')}
                            {...formik.getFieldProps('description')}
                            style={{ marginBottom: '12px' }}
                            variant="outlined"
                        />
                        <HashtagsInput
                            error={!!formik.errors.hashtags}
                            fullWidth
                            helperText={formik.errors.hashtags}
                            label={t('AGENCY.HASHTAG_SETS.HASHTAGS')}
                            placeholder={t('AGENCY.HASHTAG_SETS.DIALOG.START_BY_TYPING_YOUR_HASHTAGS')}
                            value={formik.values.hashtags}
                            onChange={hashtags => formik.setFieldValue('hashtags', hashtags)}
                        />
                        <br />
                        {allowIsCore && (
                            <DSFormGroup row>
                                <DSFormControlLabel
                                    control={
                                        <DSBasicSwitch
                                            data-cy="manage-hashtag-form-is-core-switch"
                                            {...formik.getFieldProps('isCore')}
                                            checked={formik.values.isCore}
                                        />
                                    }
                                    label={t('AGENCY.HASHTAG_SETS.DIALOG.REQUIRED_FOR_ALL_POSTS')}
                                />
                            </DSFormGroup>
                        )}
                    </DSDialogContent>
                    <DSDialogActions>
                        <DSButton color="primary" variant="outlined" onClick={onCancel}>
                            <Translate keyId="COMMON.CANCEL" />
                        </DSButton>
                        <DSButton
                            color="primary"
                            data-cy="manage-hashtag-form-submit-button"
                            type="submit"
                            variant="contained"
                        >
                            {submitButtonText}
                        </DSButton>
                    </DSDialogActions>
                </form>
            )}
        </Formik>
    );
}
HashtagSetDialogContent.displayName = 'HashtagSetDialogContent';
HashtagSetDialogContent.fragments = { hashtagSet: hashtagSetFragment };
