import { DSWell, DSWellHeader, DSWellHeaderRightContent, DSWellHeaderTitle } from '@deltasierra/components';
import * as React from 'react';
import { gql } from '@apollo/client';
import styled from 'styled-components';
import { ThumbnailViewToggle } from '../CollectionAssets/ThumbnailViewToggle';
import { AssetTableView } from '../AssetTableView';
import { AssetThumbnailView } from '../AssetThumbnailView';
import { relayConnectionToArray } from '../../../graphql/utils';
import { AssetOperationsDropdown } from '../AssetOperationsDropdown';
import { SortAssetsInput } from '../../../../../__graphqlTypes/globalTypes';
import { NoAssetsMessage } from '../NoAssetsMessage';
import { AssetConnectionForRecycledAssets } from './__graphqlTypes/AssetConnectionForRecycledAssets';

const fragments = {
    ASSET_CONNECTION_FOR_RECYCLED_ASSETS: gql`
        fragment AssetConnectionForRecycledAssets on AssetConnection {
            edges {
                node {
                    __typename
                    ... on AssetFolder {
                        id
                        title
                    }
                    ... on AssetFile {
                        id
                        title
                    }
                    ... on AssetFileOrFolder {
                        ...AssetOperationsDropdownFragment
                    }
                    ...AssetThumbnailViewFragment
                    ...AssetTableViewFragment
                }
            }
        }
        ${AssetThumbnailView.fragments.AssetThumbnailViewFragment}
        ${AssetTableView.fragments.AssetTableViewFragment}
        ${AssetOperationsDropdown.fragments.AssetOperationsDropdownFragment}
    `,
};

export interface RecycledAssetsProps {
    headerText: string;
    assetConnection: AssetConnectionForRecycledAssets;
}

export function RecycledAssets(props: RecycledAssetsProps): JSX.Element {
    const [viewOption, setViewOption] = React.useState<'list' | 'thumbnail'>('thumbnail');
    const [sort, setSort] = React.useState<SortAssetsInput | null>(null);
    return (
        <DSWell>
            <DSWellHeader>
                <DSWellHeaderTitle>{props.headerText}</DSWellHeaderTitle>
                <DSWellHeaderRightContent>
                    <AssetOperationsDropdown assets={relayConnectionToArray(props.assetConnection)} currentAsset={null} recycled />
                </DSWellHeaderRightContent>
            </DSWellHeader>

            <RightFlex>
                <ThumbnailViewToggle value={viewOption} onChange={setViewOption} />
            </RightFlex>
            {props.assetConnection.edges.length === 0 && <NoAssetsMessage />}
            {props.assetConnection.edges.length > 0 && viewOption === 'list' && (
                <AssetTableView assets={relayConnectionToArray(props.assetConnection)} setSort={setSort} sort={sort} />
            )}
            {props.assetConnection.edges.length > 0 && viewOption === 'thumbnail' && (
                <AssetThumbnailView assets={relayConnectionToArray(props.assetConnection)} setSort={setSort} sort={sort} />
            )}
        </DSWell>
    );
}
RecycledAssets.displayName = 'RecycledAssets';
RecycledAssets.fragments = fragments;

const RightFlex = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
`;
