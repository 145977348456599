import { BuilderEmailDocument,
    EditableField,
    IBuilderEmailDocument,
    SimpleEditorField,
    TextSubstitutionValues,
 assertDefined, Section, ImageSection, isImageSection, Html, AssetAndLayerId, isTextSection, isButtonSection } from '@deltasierra/shared';


import { contains } from '@deltasierra/array-utilities';


import { FileCache } from '../imageLoaderService';
import { $qSID } from '../../common/angularData';
import { BuilderEditor } from '../builderEditor';
import { BuilderCommonService } from '../common/builderCommonService';
import {
    ButtonLinkValidator,
    EmailFieldValidators,
    FieldValidationResult,
    ImageLinkValidator,
    TextLinkValidator,
} from '../builderDocumentValidation';
import { ProxyTextSectionWithHackHtmlFix } from './proxy-text-section-hack';
import { updateButtonSectionMsoHtmlLink } from './utils/buttonSectionUtils';
import IQResolveReject = angular.IQResolveReject;
import IQService = angular.IQService;

function findImageByOriginalLocation(document: IBuilderEmailDocument, originalLocation: string | null) {
    for (const section of document.sections) {
        if (isImageSection(section) && section.originalLocation === originalLocation) {
            return section;
        }
    }
    throw new Error(`No image section found with original location: ${originalLocation}`);
}

export interface GroupedSimpleEditorField {
    section: Section;
    fields: SimpleEditorField[];
}

export enum MailMergeFieldType {
    None,
    Fitware,
    ClubReady,
}

export class EmailBuilder {
    public static readonly SID = 'EmailBuilder';

    private static validators = new EmailFieldValidators()
        .add(new ButtonLinkValidator())
        .add(new ImageLinkValidator())
        .add(new TextLinkValidator());

    public textSubstitutionValues: TextSubstitutionValues = {};

    public document = new BuilderEmailDocument();

    public advancedMode = false;

    public groupedEditorFields: GroupedSimpleEditorField[] = [];

    public simpleEditorSections: Section[] = [];

    public selectedSection: Section | null = null;

    public mailMergeFieldType = MailMergeFieldType.None;

    public linkedAssetLibraryAsset: AssetAndLayerId[] = [];

    protected simpleEditor = new this.injected.BuilderEditor();

    public constructor(
        private injected: {
            $q: IQService;
            BuilderEditor: typeof BuilderEditor;
            builderCommonService: BuilderCommonService;
        },
        public fileCache: FileCache,
    ) {}

    /**
     * Returns a promise for interface consistency with ContentBuilder.
     *
     * @param document - The document to load
     * @returns Promise
     */
    public loadDocument(document: IBuilderEmailDocument): ng.IPromise<void> {
        return this.injected.$q((resolve: IQResolveReject<void>, reject: IQResolveReject<void>) => {
            try {
                this.document = document;
                document.sections = document.sections.map(section => {
                    if (isTextSection(section)) {
                        /*
                         * This is a fix for email templates
                         * @see https://digitalstack.atlassian.net/browse/DS-6276
                         */
                        return new ProxyTextSectionWithHackHtmlFix(section, document);
                    }

                    return section;
                });
                this.updateDocumentButtonMsoLinks();
                this.initEditorFromDocument(document);
                resolve();
            } catch (err) {
                reject(err);
            }
        });
    }

    public updateDocumentButtonMsoLinks(): void {
        // DS-6522: Edited links not being replaced in Outlook-specific HTML
        // Hackity hack to update outlook html link as well.
        // Might be better off done on the server?
        const updatedSections = this.document.sections.map(section => {
            if (isButtonSection(section)) {
                return updateButtonSectionMsoHtmlLink(section);
            }
            return section;
        });
        this.document.sections.forEach(section => {
            const matchingUpdatedSection = updatedSections.find(updatedSection => updatedSection.id === section.id);
            if (matchingUpdatedSection) {
                Object.assign(section, matchingUpdatedSection);
            }
        });
    }

    public validateField(section: Section, fieldPath: string): FieldValidationResult[] {
        const result = EmailBuilder.validators.validateField({
            advancedMode: this.advancedMode,
            document: this.document,
            fieldPath,
            section,
        });
        return result;
    }

    public validateDocument(): FieldValidationResult[] {
        return EmailBuilder.validators.validateDocument({
            advancedMode: this.advancedMode,
            document: this.document,
        });
    }

    // Replacing a image
    public setImageLocation(originalLocation: string, newLocation: string): void {
        const imageSection = findImageByOriginalLocation(this.document, originalLocation);
        imageSection.location = newLocation;
    }

    public setImageLocationFromFile(imageSection: ImageSection, fileOrBlob: File): void {
        const URL = (window as { webkitURL?: typeof window.URL }).webkitURL || window.URL;
        const url = URL.createObjectURL(fileOrBlob);
        this.fileCache.set(url, fileOrBlob);
        imageSection.location = url;
        imageSection.locationType = 'local';
    }

    public toggleEditableField(section: Section, fieldPath: string, controlType: string, label: string): void {
        if (this.hasEditableField(section, fieldPath, controlType)) {
            this.deleteEditableField(section, fieldPath, controlType);
        } else {
            this.addEditableField(section, fieldPath, controlType, label);
        }
    }

    public hasEditableField(layer: Section | undefined, fieldPath: string, control: string): boolean {
        if (!layer) {
            return false;
        }
        return this.simpleEditor.hasField(layer.id, fieldPath, control);
    }

    public updateDocumentFromEditor(): void {
        for (const field of this.simpleEditor.fields) {
            this.injected.builderCommonService.setFieldValue(field.layer, field.config.path, field.value);
        }
    }

    public updateEditorFromDocument(): void {
        for (const field of this.simpleEditor.fields) {
            field.value = this.injected.builderCommonService.getFieldValue<Section>(
                field.layer as Section,
                field.config.path,
            );
        }
    }

    public getSectionById(id: number): Section {
        for (const section of this.document.sections) {
            if (section.id === id) {
                return section;
            }
        }
        throw new Error(`Could not find section with ID: ${id}`);
    }

    public selectSection(section: Section | null): void {
        this.selectedSection = section;
    }

    public hasAnyEditableFieldForSection(sectionId: number): boolean {
        return this.simpleEditorSections.some(section => section.id === sectionId);
    }

    public getSelectedSimpleEditorGroup(): GroupedSimpleEditorField | undefined {
        let index = -1;
        if (this.selectedSection) {
            for (let i = 0; i < this.groupedEditorFields.length; i++) {
                if (this.groupedEditorFields[i].section === this.selectedSection) {
                    index = i;
                }
            }
        }
        if (index === -1) {
            return undefined;
        } else {
            return this.groupedEditorFields[index];
        }
    }

    public setHtmlAndSections(html: Html, sections: Section[]): void {
        if (this.document) {
            this.document.templateHtml = html;
            this.document.sections = sections;
            // Because we've replaced the sections, any existing editable fields will refer to invalid sections. So,
            // We also need to remove any existing editable fields, in both the builder document and the UI.
            this.removeAllEditableFields();
        }
    }

    protected initEditorFromDocument(document: IBuilderEmailDocument): void {
        this.simpleEditor.clear();
        for (const editableField of document.editableFields) {
            try {
                assertDefined(editableField.layerId);
                const section = this.getSectionById(editableField.layerId);
                this.initEditorField(section, editableField);
            } catch (error: unknown) {
                const { label, layerId } = editableField;
                // eslint-disable-next-line no-console
                console.log(`Can't find a section with ID '${layerId}' for editable field '${label}'`);
            }
        }
        this.regenerateGroupedEditorFields();
    }

    protected removeAllEditableFields(): void {
        this.selectedSection = null;
        this.simpleEditorSections.length = 0;
        this.groupedEditorFields.length = 0;
        this.document.editableFields.length = 0;
        this.simpleEditor.clear();
    }

    // Adding / deleting editable fields
    protected initEditorField(section: Section, editableField: EditableField): void {
        const currentValue = this.injected.builderCommonService.getFieldValue<Section>(section, editableField.path);
        this.simpleEditor.addField(section, editableField, currentValue);
    }

    protected addEditableField(section: Section, fieldPath: string, controlType: string, label: string): void {
        const editableField = {
            control: controlType,
            label,
            layerId: section.id,
            path: fieldPath,
        };
        this.document.editableFields.push(editableField); // Save the configuration
        this.initEditorField(section, editableField); // Init the UI
        this.regenerateGroupedEditorFields();
    }

    protected deleteEditableField(section: Section, fieldPath: string, control: string): void {
        const result = this.simpleEditor.deleteField(section.id, fieldPath, control);
        if (result) {
            this.regenerateGroupedEditorFields();
            this.document.editableFields.splice(result.index, 1);
        }
    }

    protected getSimpleEditorSections(): Section[] {
        const fields: Section[] = [];
        for (const field of this.simpleEditor.fields) {
            if (!contains(fields, field.layer)) {
                fields.push(field.layer as Section);
            }
        }
        return fields;
    }

    protected getGroupedEditorFields(): GroupedSimpleEditorField[] {
        // Order by section
        const sections = this.getSimpleEditorSections();
        // eslint-disable-next-line id-length
        sections.sort((a, b) => a.y - b.y);
        const groupedFields: GroupedSimpleEditorField[] = [];
        for (const section of sections) {
            groupedFields.push({
                fields: this.simpleEditor.fields.filter(field => field.config.layerId === section.id),
                section,
            });
        }
        return groupedFields;
    }

    protected regenerateGroupedEditorFields(): void {
        this.groupedEditorFields = this.getGroupedEditorFields();
        this.simpleEditorSections = this.getGroupedEditorFields().map(group => group.section);
    }
}

export type EmailBuilderFactory = (fileCache: FileCache) => EmailBuilder;

angular.module('app').factory(EmailBuilder.SID, [
    $qSID,
    BuilderEditor.SID,
    BuilderCommonService.SID,
    ($q: IQService, builderEditor: typeof BuilderEditor, builderCommonService: BuilderCommonService) =>
        ((fileCache: FileCache) =>
            new EmailBuilder(
                {
                    $q,
                    BuilderEditor: builderEditor,
                    builderCommonService,
                },
                fileCache,
            )) as EmailBuilderFactory,
]);
