/// <reference path="../../../../typings/browser.d.ts" />
import { AgencyUserDto, UserId } from '@deltasierra/shared';
import {
    actualComponent, ExpressionBinding,
    ExpressionCallback,
    ILifecycleHooks,
    OneWayBinding,
    OptionalOneWayBinding,
} from '../../common/angularData';
import { DataUtils } from '../../common/dataUtils';
import IAngularEvent = angular.IAngularEvent;

const DummyUnknownUser = {
    id: UserId.from(-1),
    firstName: '',
    lastName: 'Unknown',
};

export class AssignToAgencyManagerCtrl implements ILifecycleHooks {
    // Props
    agencyManagers?: AgencyUserDto[];

    isDisabled?: boolean;

    initialSelectedAgencyManagerId?: UserId;

    onAgencyManagerSelected!: ExpressionCallback<{ agencyManager: AgencyUserDto | undefined }>;

    // State
    readonly dummyUnknownUser = DummyUnknownUser;

    selectedAgencyManager?: AgencyUserDto;

    isAssignedToUnknownUser = false;

    shouldShowDropdown = true;

    static $inject: string[] = [DataUtils.SID];

    constructor(protected readonly dataUtils: DataUtils) {}

    public $onInit(): void {
        this.agencyManagers = angular.copy(this.agencyManagers); // Clone this so we don't mutate the input array
        if (this.initialSelectedAgencyManagerId && this.agencyManagers) {
            this.selectedAgencyManager =
                this.dataUtils.findByPredicate(
                    user => user.id === this.initialSelectedAgencyManagerId,
                    this.agencyManagers,
                ) || undefined;
            if (!this.selectedAgencyManager) {
                this.selectedAgencyManager = DummyUnknownUser;
                this.agencyManagers.splice(0, 0, DummyUnknownUser);
                this.isAssignedToUnknownUser = true;
                this.shouldShowDropdown = false;
            }
        }
    }

    onSelectAgencyManager() {
        if (!this.selectedAgencyManager || this.selectedAgencyManager.id !== DummyUnknownUser.id) {
            // Don't allow setting to the dummy unknown user.
            this.onAgencyManagerSelected({
                agencyManager: this.selectedAgencyManager,
            });
            if (this.agencyManagers) {
                this.dataUtils.removeObject(DummyUnknownUser, this.agencyManagers);
            }
        }
    }

    toggleDropdownVisibility($event: IAngularEvent) {
        $event.stopPropagation();
        this.shouldShowDropdown = !this.shouldShowDropdown;
    }
}

export const assignToAgencyManagerSID = 'assignToAgencyManager';

export const assignToAgencyManagerConfig = actualComponent(
    AssignToAgencyManagerCtrl,
    '/partials/agency/assignToAgencyManager/template',
    {
        agencyManagers: OneWayBinding,
        isDisabled: OptionalOneWayBinding,
        initialSelectedAgencyManagerId: OptionalOneWayBinding,
        onAgencyManagerSelected: ExpressionBinding,
    },
);

angular.module('app').component(assignToAgencyManagerSID, assignToAgencyManagerConfig);
