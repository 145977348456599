/* eslint-disable max-lines-per-function */
import * as React from 'react';
import { assertNever, t } from '@deltasierra/shared';
import moment from 'moment';
import { stripIndents } from 'common-tags';
import { StyledList, StyledListItem } from '../../../../common';
import { PublishToClubReadyStep1Data } from '../../../../PublishToClubReadyStep1/types/publish-to-club-ready-step-1-data';
import { ClubReadyListTotals } from '../hooks/utils';
import { ClubReadyListCount } from './ClubReadyListCount';

export type ClubReadyListCountListProps = {
    step1Data: PublishToClubReadyStep1Data;
    userCountListTotals: ClubReadyListTotals;
    hideCounts?: boolean
};

export function ClubReadyListCountList({ hideCounts = false, step1Data, userCountListTotals }: ClubReadyListCountListProps): JSX.Element {
    const activeFilterText = React.useMemo(() => {
        if (step1Data.filterType !== 'filter' || step1Data.active.listChoice === 'all') {
            return null;
        }

        if (step1Data.active.listChoice === 'withActivityBefore') {
            return `${t('BUILD.PUBLISH.CLUB_READY.BEFORE')} ${moment(step1Data.active.beforeDate).format(
                'Do MMMM YYYY',
            )}`;
        } else if (step1Data.active.listChoice === 'withActivityAfter') {
            return `${t('BUILD.PUBLISH.CLUB_READY.AFTER')} ${moment(step1Data.active.afterDate).format(
                'Do MMMM YYYY',
            )}`;
        } else {
            return assertNever(step1Data.active.listChoice);
        }
    }, [step1Data]);

    const inactiveFilterText = React.useMemo(() => {
        if (step1Data.filterType !== 'filter' || step1Data.inactive.listChoice === 'all') {
            return null;
        }

        if (step1Data.inactive.listChoice === 'withActivityBefore') {
            return `${t('BUILD.PUBLISH.CLUB_READY.BEFORE')} ${moment(step1Data.inactive.beforeDate).format(
                'Do MMMM YYYY',
            )}`;
        } else if (step1Data.inactive.listChoice === 'withActivityAfter') {
            return `${t('BUILD.PUBLISH.CLUB_READY.AFTER')} ${moment(step1Data.inactive.afterDate).format(
                'Do MMMM YYYY',
            )}`;
        } else {
            return assertNever(step1Data.inactive.listChoice);
        }
    }, [step1Data]);

    const prospectsFilterText = React.useMemo(() => {
        if (step1Data.filterType !== 'filter' || step1Data.prospects.listChoice === 'all') {
            return null;
        }

        if (step1Data.prospects.listChoice === 'addedBetween') {
            return stripIndents`
            (${moment(step1Data.prospects.from).format('Do MMMM YYYY')} 
            - 
            ${moment(step1Data.prospects.to).format('Do MMMM YYYY')})
            `;
        } else {
            return assertNever(step1Data.prospects.listChoice);
        }
    }, [step1Data]);

    const customFilterText = React.useMemo(() => {
        if (step1Data.filterType !== 'emails' || !userCountListTotals) {
            return null;
        }

        let text = '';

        if (step1Data.listDescription) {
            text += ` ${step1Data.listDescription}`;
        }
        if (step1Data.emailList && !hideCounts) {
            text += ` (${t('BUILD.PUBLISH.CLUB_READY.MATCHED_OUT_OF', {
                matches: userCountListTotals.custom.userCount,
                total: step1Data.emailList?.split('\n').length ?? 0,
            })})`;
        }
        return text;
    }, [hideCounts, step1Data, userCountListTotals]);

    return (
        <StyledList>
            {step1Data.filterType === 'filter' && (
                <>
                    {step1Data.active.send && (
                        <StyledListItem>
                            <ClubReadyListCount
                                count={userCountListTotals.active.userCount}
                                filterText={activeFilterText ?? undefined}
                                hideCount={hideCounts}
                                listName={t('BUILD.PUBLISH.CLUB_READY.ACTIVE')}
                            />
                        </StyledListItem>
                    )}
                    {step1Data.inactive.send && (
                        <StyledListItem>
                            <ClubReadyListCount
                                count={userCountListTotals.inactive.userCount}
                                filterText={inactiveFilterText ?? undefined}
                                hideCount={hideCounts}
                                listName={t('BUILD.PUBLISH.CLUB_READY.INACTIVE')}
                            />
                        </StyledListItem>
                    )}

                    {step1Data.prospects.send && (
                        <StyledListItem>
                            <ClubReadyListCount
                                count={userCountListTotals.prospects.userCount}
                                filterText={prospectsFilterText ?? undefined}
                                hideCount={hideCounts}
                                listName={t('BUILD.PUBLISH.CLUB_READY.PROSPECTS')}
                            />
                        </StyledListItem>
                    )}

                    {step1Data.cooledOffProspects.send && (
                        <StyledListItem>
                            <ClubReadyListCount
                                count={userCountListTotals.cooledOffProspects.userCount}
                                hideCount={hideCounts}
                                listName={t('BUILD.PUBLISH.CLUB_READY.COOLED_OFF_PROSPECTS')}
                            />
                        </StyledListItem>
                    )}
                </>
            )}
            {step1Data.filterType === 'emails' && (
                <StyledListItem>
                    <ClubReadyListCount
                        count={userCountListTotals.custom.userCount}
                        filterText={customFilterText ?? undefined}
                        hideCount={hideCounts}
                        listName={t('BUILD.PUBLISH.CLUB_READY.CUSTOM')}
                    />
                </StyledListItem>
            )}
        </StyledList>
    );
}
ClubReadyListCountList.displayName = 'ClubReadyListCountList';
