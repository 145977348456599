/// <reference path="../../../typings/browser.d.ts" />
import { Client } from '@deltasierra/shared';
import { I18nService } from '../i18n/i18nService';
import { MvNotifier } from '../common/mvNotifier';
import { agencyTabNavEntries } from '../agency/tabNavEntries';
import { InteractionUtils } from '../common/interactionUtils';
import { MvClient } from './mvClient';

interface NewClientData {
    title : string;
    country : string;
}

export class MvClientListCtrl {
    static SID = 'mvClientListCtrl';

    createClient = this.interactionUtils.createFuture('Create new client', (context : { newClientData : NewClientData }) => this.mvClient.createClient(context.newClientData).then((client : Client) => {
            this.mvNotifier.notify(this.i18n.text.agency.clients.addClient.clientAdded());
            client.locations = [];
            this.clients.push(client);
            this.clientName = '';
            this.country = '';
        }, reason => {
            this.mvNotifier.unexpectedError(reason);
        }));

    clients : Client[];

    loaded = false;

    clientName? : string;

    country? : string;

    tabNavEntries = agencyTabNavEntries;

    static readonly $inject : string[] = [
        MvClient.SID,
        MvNotifier.SID,
        I18nService.SID,
        InteractionUtils.SID,
    ];

    constructor(
        private readonly mvClient : MvClient,
        private readonly mvNotifier : MvNotifier,
        private readonly i18n : I18nService,
        private readonly interactionUtils : InteractionUtils,
    ) {
        this.clients = [];
        void mvClient.getClients()
            .then(clients => {
                this.clients = clients;
                this.loaded = true;
            });
    }

    addClient() {
        if (this.clientName && this.country) {
            void this.createClient.run({ newClientData: { title: this.clientName, country: this.country } });
        }
    }
}

angular.module('app').controller(MvClientListCtrl.SID, MvClientListCtrl);
