import { coerceServerErrorReasonToString, ServerError, JobFailure, parseJobFailure } from '@deltasierra/shared';
import { I18nService } from '../../../i18n/i18nService';
import { MvNotifier } from '../../../common/mvNotifier';
import IHttpPromiseCallbackArg = angular.IHttpPromiseCallbackArg;

interface Controller {
    i18nService: I18nService;
    mvNotifier: MvNotifier;
}

export function isJobFailure(result: any): result is JobFailure {
    return !!(result && result.Cause);
}

export function baseVideoPublishErrorHandler(
    controller: Controller,
    error: IHttpPromiseCallbackArg<ServerError> | JobFailure | any,
): string {
    let errorMessage: string | undefined;
    if (isJobFailure(error)) {
        errorMessage = parseJobFailure(error)!;
        controller.mvNotifier.unexpectedError(errorMessage);
    } else {
        controller.mvNotifier.unexpectedErrorWithData(
            controller.i18nService.text.build.video.failedToPublishVideo(),
            error,
        );
        if (error && error.data) {
            const data = (error as IHttpPromiseCallbackArg<ServerError>).data;
            errorMessage = data && data.reason ? coerceServerErrorReasonToString(data.reason) : String(data);
        }
    }

    if (!errorMessage) {
        errorMessage = controller.i18nService.text.build.video.failedToPublishVideo();
    }

    return errorMessage;
}
