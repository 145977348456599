/// <reference path="../../../typings/browser.d.ts" />

import { Platform } from '@deltasierra/shared';
import { getData } from '../common/httpUtils';
import IHttpService = angular.IHttpService;

export class PlatformService {
    public static SID = 'PlatformService';

    static readonly $inject: string[] = ['$http'];

    constructor(private $http: IHttpService) {}

    getAllPlatforms(): ng.IPromise<Platform[]> {
        return this.$http.get<Platform[]>('/api/platforms').then(getData);
    }

    getPublishingPlatforms(): ng.IPromise<Platform[]> {
        return this.$http.get<Platform[]>('/api/platforms/publishing').then(getData);
    }

    getBuildingPlatforms(): ng.IPromise<Platform[]> {
        return this.$http.get<Platform[]>('/api/platforms/building').then(getData);
    }
}

angular.module('app').service(PlatformService.SID, PlatformService);
