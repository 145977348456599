/// <reference path="../../../../typings/browser.d.ts" />
import {
    BuilderTemplateFormat,
    BuilderTemplateFormatId,
    ClientId,
    formatSizeOption,
    Untyped,
} from '@deltasierra/shared';
import { $scopeSID } from '../../common/angularData';
import { DataUtils } from '../../common/dataUtils';
import { InteractionUtils } from '../../common/interactionUtils';
import { I18nService } from '../../i18n';
import { MvIdentity } from './../../account/mvIdentity';
import IPromise = angular.IPromise;
import IAngularEvent = angular.IAngularEvent;
import IScope = angular.IScope;

interface FormatGroup {
    label: string;
    platformId: number;
    formats: BuilderTemplateFormat[];
    isExpanded: boolean;
}

export class BuilderTemplateGalleryFormatCtrl {
    static SID = 'BuilderTemplateGalleryFormatCtrl';

    // From directive attributes
    private clientId!: ClientId;

    private searchAllClients?: boolean;

    private change!: (locals: { formatIds: number[]; platformIds: number[] }) => any;

    private formats?: BuilderTemplateFormat[];

    public groupedFormats?: FormatGroup[];

    public selectedFormatIds!: BuilderTemplateFormatId[];

    public selectedPlatformIds!: number[];

    public loading = {
        formats: false,
    };

    static readonly $inject: string[] = [
        $scopeSID,
        I18nService.SID,
        InteractionUtils.SID,
        DataUtils.SID,
        'mvBuilderTemplateFormatResource',
        MvIdentity.SID,
    ];

    public constructor(
        private readonly $scope: IScope,
        private readonly i18nService: I18nService,
        private readonly interactionUtils: InteractionUtils,
        private readonly dataUtils: DataUtils,
        private readonly mvBuilderTemplateFormatResource: Untyped,
        private readonly identity: MvIdentity,
    ) {}

    public $onInit(): void {
        if (!this.selectedPlatformIds) {
            this.selectedPlatformIds = [];
        }
        if (!this.selectedFormatIds) {
            this.selectedFormatIds = [];
        }
        this.initWatchers();
        void this.loadTemplateFormats();
    }

    private initWatchers(): void {
        this.$scope.$watch(
            (): number => this.clientId,
            (newValue: number, oldValue: number) => this.onClientIdChange(newValue, oldValue),
        );
    }

    private onClientIdChange(newValue: number, oldValue: number): ng.IPromise<void> | void {
        if (oldValue !== newValue) {
            return this.loadTemplateFormats();
        }
    }

    private constructQueryByClientId(): { clientId: ClientId } {
        const payload = {
            clientId: <Untyped>undefined,
        };
        if (!this.searchAllClients && this.clientId) {
            payload.clientId = this.clientId;
        }
        return payload;
    }

    private loadTemplateFormats(): IPromise<any> {
        const payload = this.constructQueryByClientId();
        return this.interactionUtils
            .handleResource(
                this,
                'retrieve template formats',
                'formats',
                this.mvBuilderTemplateFormatResource.query(payload),
            )
            .then(formats => {
                this.formats = formats;
                this.groupFormats();
                this.selectPlatformsAndFormats();
                this.expandGroupsWithSelectedFormat();
            });
    }

    private selectPlatformsAndFormats() {
        const availablePlatformIds = this.dataUtils.pluckByGetter(group => group.platformId, this.groupedFormats!);
        this.selectedPlatformIds = this.selectedPlatformIds.filter(
            (id: number) => availablePlatformIds.indexOf(id) > -1,
        );
        // Exclude formats that fall under a selected platformId, since they're implicitly selected.
        const availableFormatIds = this.dataUtils.pluckId(
            this.dataUtils.flatten(
                this.dataUtils.pluckByGetter(
                    group => group.formats,
                    this.groupedFormats!.filter(group => this.selectedPlatformIds.indexOf(group.platformId) == -1),
                ),
            ),
        );
        this.selectedFormatIds = this.selectedFormatIds.filter(
            (id: BuilderTemplateFormatId) => availableFormatIds.indexOf(id) > -1,
        );
    }

    private expandGroupsWithSelectedFormat() {
        this.groupedFormats!.filter(group => this.selectedPlatformIds.indexOf(group.platformId) == -1)
            .filter(group => group.formats.some(format => this.selectedFormatIds.indexOf(format.id) > -1))
            .forEach(group => group.isExpanded = true);
    }

    private removeFormatsForSelectedPlatforms() {
        const formatIdsToRemove = this.dataUtils.pluckId(
            this.dataUtils.flatten(
                this.dataUtils.pluckByGetter(
                    group => group.formats,
                    this.groupedFormats!.filter(group => this.selectedPlatformIds.indexOf(group.platformId) > -1),
                ),
            ),
        );
        this.selectedFormatIds = this.dataUtils.relativeDifference(formatIdsToRemove, this.selectedFormatIds);
    }

    private groupFormats(): void {
        const groups: FormatGroup[] = [];
        for (const format of this.formats!) {
            let group: FormatGroup | null = this.dataUtils.findByGetter(
                (filterGroup: FormatGroup) => filterGroup.label,
                groups,
                format.groupLabel,
            );
            if (!group) {
                group = {
                    label: format.groupLabel,
                    platformId: format.platformId, // Group gets the first format's platform ID. Not ideal.
                    formats: [],
                    isExpanded: false,
                };
                groups.push(group);
            }
            group.formats.push(format);
        }
        for (const group of groups) {
            group.formats.sort((a: BuilderTemplateFormat, b: BuilderTemplateFormat) =>
                a && b ? a.label.localeCompare(b.label) : +1,
            );
        }
        groups.sort((a: FormatGroup, b: FormatGroup) => (a && b ? a.label.localeCompare(b.label) : +1));
        this.groupedFormats = groups;
    }

    getFormatLabel(format: BuilderTemplateFormat): string {
        if (!this.identity.currentUser) {
            return '';
        }
        const units = this.identity.currentUser.printUnits;
        return format.type !== 'email'
            ? formatSizeOption(format, { isPrintDocument: format.type === 'print', userPreferencePrintUnits: units })
            : format.label;
    }

    selectNoFormats(): void {
        this.selectedFormatIds.length = 0;
        this.selectedPlatformIds.length = 0;
        this.change({ formatIds: this.selectedFormatIds, platformIds: this.selectedPlatformIds });
    }

    selectFormat(format: BuilderTemplateFormat): void {
        this.dataUtils.toggle(format.id, this.selectedFormatIds);
        this.change({ formatIds: this.selectedFormatIds, platformIds: this.selectedPlatformIds });
    }

    selectPlatformId(platformId: number): void {
        const wasAdded = this.dataUtils.toggle(platformId, this.selectedPlatformIds);
        if (wasAdded) {
            this.removeFormatsForSelectedPlatforms();
        }
        this.change({ formatIds: this.selectedFormatIds, platformIds: this.selectedPlatformIds });
    }

    toggleGroup(group: FormatGroup): void {
        group.isExpanded = !group.isExpanded;
    }

    getDropdownButtonLabel(): string {
        const totalSelected = this.selectedFormatIds.length + this.selectedPlatformIds.length;
        if (totalSelected > 0) {
            return this.i18nService.text.build.formatsSelected({ count: totalSelected });
        } else {
            return this.i18nService.text.build.allFormats();
        }
    }

    suppressClick($event: IAngularEvent) {
        if ($event.stopPropagation) {
            $event.stopPropagation();
        }
    }
}

export const dsBuilderTemplateGalleryFormatSID = 'dsBuilderTemplateGalleryFormat';
export const dsBuilderTemplateGalleryFormatConfig = {
    restrict: 'E',
    scope: {},
    templateUrl: '/partials/templateGallery/builderTemplateFormatPicker/builderTemplateGalleryFormat',
    controller: BuilderTemplateGalleryFormatCtrl,
    controllerAs: 'ctrl',
    bindToController: {
        clientId: '=',
        selectedPlatformIds: '=',
        selectedFormatIds: '=',
        searchAllClients: '=?',
        change: '&',
    },
};

angular.module('app').controller(BuilderTemplateGalleryFormatCtrl.SID, BuilderTemplateGalleryFormatCtrl);
angular.module('app').directive(dsBuilderTemplateGalleryFormatSID, [() => dsBuilderTemplateGalleryFormatConfig]);
