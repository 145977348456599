
import {
    AssignedLocation,
    ClientId,
    CurrencyCode,
    CURRENCY_USD,
    findCurrencyWithDefault,
    LocationMap,
    PayableServiceCost,
    StripeChargeDto,
} from '@deltasierra/shared';
import { MvClientResource, mvClientResourceSID } from '../../clients/mvClientResource';
import { $scopeSID } from '../../common/angularData';
import { $modalInstanceSID } from '../../common/angularUIBootstrapData';
import { InteractionUtils } from '../../common/interactionUtils';
import { I18nService } from '../../i18n/i18nService';
import { StripeApiClient } from '../../integration/stripe/stripeApiClient';
import { MvLocation } from '../../locations/mvLocation';
import { PayableServiceService } from '../../payments/payableServiceService';
import { StripeChargePropsData } from '../../shoppingCart/checkout/shoppingCartOrder/StripeCheckoutWrapper';
import { ModalInstance } from '../../typings/angularUIBootstrap/modalService';
import { MvIdentity } from '../mvIdentity';
import IScope = angular.IScope;

function generateEwayDescription(title: string, mapId: number): string {
    return `[DS] Location map - ${title} (#${mapId})`;
}

type CreateNewMapSteps = 'createMap' | 'paymentDetails' | 'paymentSuccessful' | 'stripePayment';

interface SelectedMapDetails {
    center: { lon: string; lat: string };
    zoom: number;
    serviceCostId: number;
}

export class CreateNewMapController {
    public static readonly $inject: string[] = [
        $scopeSID,
        $modalInstanceSID,
        'initialLocationGeo',
        'clientId',
        'location',
        mvClientResourceSID,
        MvIdentity.SID,
        InteractionUtils.SID,
        PayableServiceService.SID,
        I18nService.SID,
        StripeApiClient.SID,
        MvLocation.SID,
    ];

    public isEditing = false;

    public searchedPlace: google.maps.places.PlaceResult | null = null;

    public selectedLocationGeo: { lat: number; lon: number } | null = null;

    public zoom = 15;

    public modalStep: CreateNewMapSteps = 'createMap';

    public serviceCost: PayableServiceCost | null = null;

    public unsupportedCurrency: CurrencyCode | null = null;

    public createChargeData: StripeChargePropsData | null = null;

    public chargeResult: StripeChargeDto | null = null;

    public orderFulfilled = false;

    public readonly getMapServicePaymentCosts = this.interactionUtils.createFuture(
        this.i18n.text.account.map.getMapServiceCost(),
        () =>
            this.payableServiceService.getPayableServiceCostForClient('LocationMap', this.clientId).then(async cost => {
                if (!cost) {
                    const clientResource = await this.clientResource.get({ id: this.clientId }).$promise;
                    this.unsupportedCurrency = clientResource.currency;
                }
                this.serviceCost = cost;
            }),
    );

    public readonly fetchStripePublishableKey = this.interactionUtils.createFuture(
        this.i18n.text.payments.stripe.fetchStripePublishableKey(),
        async () => {
            const response = await this.stripeApiClient.getStripePublishableKey();
            this.stripePublishableKey = response.stripePublishableKey;
        },
    );

    public createLocationMap = this.interactionUtils.createFuture(
        this.i18n.text.account.locationDetails.createMapLocation(),
        (context: { selectedMapDetails: SelectedMapDetails }) =>
            this.mvLocation.createLocationMap(this.location.id, context.selectedMapDetails),
    );

    public readonly PAYMENT_DESCRIPTION = '[DS] Location Map';

    private stripePublishableKey: string | null = null;

    private selectedMapDetails: SelectedMapDetails | null = null;

    private locationMap: LocationMap | null = null;

    // eslint-disable-next-line max-params
    public constructor(
        $scope: IScope,
        private readonly $modalInstance: ModalInstance,
        public initialLocationGeo: { lat: number; lon: number } | undefined,
        public readonly clientId: ClientId,
        public readonly location: AssignedLocation,
        public readonly clientResource: MvClientResource,
        public readonly identity: MvIdentity,
        private readonly interactionUtils: InteractionUtils,
        private readonly payableServiceService: PayableServiceService,
        private readonly i18n: I18nService,
        private readonly stripeApiClient: StripeApiClient,
        private readonly mvLocation: MvLocation,
    ) {
        $scope.$watch(
            () => this.searchedPlace,
            () => this.updateSearchedGeo(),
        );
        this.onSuccessfulCharge = this.onSuccessfulCharge.bind(this);
    }

    public getStripeChargePropsData(): StripeChargePropsData | undefined {
        if (this.stripePublishableKey && this.serviceCost && this.locationMap) {
            const data: StripeChargePropsData = {
                currency: this.serviceCost.currency,
                description: this.getMapInvoiceDescription(this.locationMap.id),
                localeCode: null,
                locationMapId: this.locationMap.id,
                stripeConfigurationId: null,
                stripePublishableKey: this.stripePublishableKey,
            };
            return data;
        }
        return undefined;
    }

    public onSuccessfulCharge = (chargeResult: StripeChargeDto): void => {
        this.chargeResult = chargeResult;
        this.orderFulfilled = true;
        this.modalStep = 'paymentSuccessful';
    };

    public updateSearchedGeo(): void {
        if (this.searchedPlace && this.searchedPlace.geometry && this.searchedPlace.geometry.location) {
            this.initialLocationGeo = {
                lat: this.searchedPlace.geometry.location.lat(),
                lon: this.searchedPlace.geometry.location.lng(),
            };
        }
    }

    public async createMap(): Promise<void> {
        this.modalStep = 'paymentDetails';
        await this.getMapServicePaymentCosts.run({});
        await this.fetchStripePublishableKey.run({});
        if (this.selectedLocationGeo && this.serviceCost) {
            this.selectedMapDetails = {
                center: {
                    lat: this.selectedLocationGeo.lat.toString(),
                    lon: this.selectedLocationGeo.lon.toString(),
                },

                serviceCostId: this.serviceCost.id,
                zoom: this.zoom,
            };

            const locationMap = await this.createLocationMap.run({ selectedMapDetails: this.selectedMapDetails });
            this.locationMap = locationMap;

            const createChargeData = this.getStripeChargePropsData();
            if (createChargeData) {
                this.createChargeData = createChargeData;
            }
        }
    }

    public payForMap(): void {
        if (this.getMapServicePaymentCosts.isRunning() || !this.serviceCost) {
            return; // The cost hasn't loaded so wait so the user knows the cost
        }

        if (this.createChargeData) {
            this.modalStep = 'stripePayment';
        }
    }

    public formatAmount(cost: PayableServiceCost): string {
        const currency = findCurrencyWithDefault(cost.currency, CURRENCY_USD);
        return `${currency.symbol}${cost.amountInCents / 100} ${currency.code}`;
    }

    public backToCreateMap(): void {
        this.modalStep = 'createMap';
    }

    public backToPaymentDetails(): void {
        this.modalStep = 'paymentDetails';
    }

    public dismiss(): void {
        this.$modalInstance.dismiss();
    }

    public closeAfterPayment(): void {
        this.$modalInstance.close({ paymentCompleted: true });
    }

    public editPosition(): void {
        this.isEditing = true;
    }

    public viewMapPreview(): void {
        this.isEditing = false;
    }

    private getMapInvoiceDescription(mapId: number) {
        const EWAY_MAX_LENGTH = 64;
        let title = `${this.location.client} - ${this.location.title}`;
        let description = generateEwayDescription(title, mapId);

        if (description.length > EWAY_MAX_LENGTH) {
            title = `${title.substring(0, description.length - EWAY_MAX_LENGTH - 3)}...`;
            description = generateEwayDescription(title, mapId);
        }

        return description;
    }
}
