
/// <reference path="../../../typings/browser.d.ts" />
import * as toastr from 'toastr';
import * as Sentry from '@sentry/browser';
import $ from 'jquery';
import { Subscription } from 'rxjs';
import { isNullOrUndefined } from '@deltasierra/type-utilities';
import { tryExtractMessage } from './exceptions';
import { ILifecycleHooks } from './angularData';
import { errors$ } from './errors';

const SENTRY_DEFAULT_FINGERPRINT = '{{ default }}';

const mvToastrSID = 'mvToastr';

angular.module('app').value(mvToastrSID, toastr);

toastr.options.debug = false;
toastr.options.positionClass = 'toast-bottom-full-width';
toastr.options.onclick = undefined;
toastr.options.showDuration = 300;
toastr.options.hideDuration = 1000;
toastr.options.timeOut = 5000;
toastr.options.extendedTimeOut = 1000;
toastr.options.escapeHtml = true; // Patch for https://security.snyk.io/vuln/SNYK-JS-TOASTR-2396430

const errorCloseOptions = {
    closeButton: true,
    extendedTimeOut: 60000,
    preventDuplicates: true,
    timeOut: 30000,
};

class CaughtByNotifierError extends Error {}

export type NotificationType = 'error' | 'info' | 'success' | 'warning';

export interface IMvNotifierOptions {
    type?: NotificationType;
    title?: string;
    closeButton?: boolean;
    closeHtml?: string;
    closeMethod?: string;
    closeDuration?: number;
    closeEasing?: string;
    preventDuplicates?: boolean;
    timeOut?: number;
    extendedTimeOut?: number;
    data?: any;
    bypassLogging?: boolean;

    onShown?: () => void;
    onHidden?: () => void;
    onClick?: () => void;
    // OnCloseClick? : Function; // not supported in toastr v2.0.3

    loggingMessage?: string;
}

export class MvNotifier implements ILifecycleHooks {
    public static readonly SID = 'mvNotifier';

    public static readonly $inject: string[] = [];

    private enabled = true;

    private readonly subscription: Subscription;

    public constructor() {
        this.subscription = errors$.subscribe(this.unexpectedError.bind(this));
    }

    public $onDestroy(): void {
        this.subscription.unsubscribe();
    }

    public notify(message: string, type?: NotificationType): void;

    public notify(message: string, options?: IMvNotifierOptions): void;

    // eslint-disable-next-line max-statements
    public notify(message: string, optionsOrType?: IMvNotifierOptions | NotificationType): void {
        if (!this.enabled) {
            return;
        }

        let options: IMvNotifierOptions;

        if (typeof optionsOrType === 'string') {
            options = angular.extend({}, { type: optionsOrType } as any);
        } else {
            options = angular.extend({}, optionsOrType || { type: 'success' });
        }

        options.type ||= 'success';
        options.preventDuplicates = true;

        // All toastr events use titleCase, except for onclick...
        (options as any).onclick = options.onClick;

        if (options.type === 'error') {
            options = angular.extend({}, options, errorCloseOptions);
        }

        const extractedMessage = tryExtractMessage(options.data);
        const newMessage = extractedMessage ? `${message}: ${extractedMessage}` : message;
        this.clearMessage(newMessage, 'error');
        toastr[options.type || 'info'](newMessage, options.title, options);

        if (options.type === 'error') {
            const loggingMessage = (options && options.loggingMessage) || newMessage;

            // Use SentryService once User in MvIdentity is no longer a resource
            if (!options.bypassLogging) {
                let errorToLog: Error;
                if (options.data instanceof Error) {
                    errorToLog = options.data;
                } else {
                    errorToLog = new CaughtByNotifierError(loggingMessage);
                }

                Sentry.withScope(scope => {
                    scope.setFingerprint([SENTRY_DEFAULT_FINGERPRINT]);
                    scope.setLevel(Sentry.Severity.Error);
                    Sentry.captureException(errorToLog);
                });
                console.error(errorToLog);
            } else {
                // "validation.error" isn't strictly correct. We might call mvNotifier.error() just to inform the
                // User of something.
                Sentry.withScope(scope => {
                    scope.setTags({ 'validation.error': 'true' });
                    Sentry.captureMessage(loggingMessage, Sentry.Severity.Info);
                });
                console.error(loggingMessage);
            }
        }
    }

    public expectedError(message: string, options?: IMvNotifierOptions): void {
        const commonOptions = {
            bypassLogging: true,
            type: 'error' as NotificationType,
        };
        if (options) {
            // eslint-disable-next-line no-param-reassign
            options = {
                ...options,
                ...commonOptions,
            };
        } else {
            // eslint-disable-next-line no-param-reassign
            options = commonOptions;
        }
        this.notify(message, options);
    }

    public unexpectedError(
        message: any,
        options: IMvNotifierOptions = {
            type: 'error',
        },
    ): void {
        if (options.type !== 'error') {
            // eslint-disable-next-line no-param-reassign
            options = {
                ...options,
                type: 'error',
            };
        }
        // TranslatableError can define "bypassLogging"
        if (
            isNullOrUndefined(options.bypassLogging) &&
            options.data &&
            !isNullOrUndefined(options.data.bypassLogging)
        ) {
            options.bypassLogging = options.data.bypassLogging;
        }

        // Some existing code is passing errors to "unexpectedError()" as the first argument.
        // It passes type checking because in "Promise.catch()", the error value is "any" type.
        // So, let's try and compensate, and extract the message before we hit the main notifier body.
        if (typeof message === 'object') {
            // eslint-disable-next-line no-param-reassign
            message = tryExtractMessage(message) || message;
        }
        this.notify(message, options);
    }

    public unexpectedErrorWithData(message: string, data: any): void {
        // TranslatableError can define "bypassLogging"
        let bypassLogging = false;
        if (data && !isNullOrUndefined(data.bypassLogging)) {
            bypassLogging = !!data.bypassLogging;
        }
        this.notify(message, {
            bypassLogging,
            data,
            type: 'error',
        });
    }

    public info(message: string): void {
        this.notify(message, 'info');
    }

    public success(message: string): void {
        this.notify(message, 'success');
    }

    public warning(message: string, title?: string, options?: IMvNotifierOptions): void {
        // eslint-disable-next-line no-param-reassign
        options ||= { type: 'warning' };
        options.type = 'warning';

        if (title !== undefined) {
            options.title = title;
        }
        if (!options.timeOut) {
            options.timeOut = 0;
            options.extendedTimeOut = 0;
        }
        if (!options.extendedTimeOut) {
            options.extendedTimeOut = 0;
        }

        this.notify(message, options);
    }

    public wrapErrorResponse(message: string): (res: any) => void {
        return (res: any) => this.unexpectedErrorWithData(message, res);
    }

    public clearMessage(message: string, type?: NotificationType): void {
        const $existingMessage = this.getExistingMessage(message, type);
        if ($existingMessage) {
            toastr.clear($existingMessage);
        }
    }

    public disable(): void {
        this.enabled = false;
    }

    public enable(): void {
        this.enabled = true;
    }

    private getExistingMessage(messageToFind: string, type?: NotificationType): JQuery | undefined {
        const container = toastr.getContainer();
        if (container && container.length > 0) {
            const typeClass = type ? `-${type}` : '';
            const messages = container.find(`.toast${typeClass} .toast-message`).toArray();
            for (const message of messages) {
                if (message.innerHTML === messageToFind) {
                    return $(message).parent();
                }
            }
        }
        return undefined;
    }
}

export const mvNotifierSID = MvNotifier.SID;
angular.module('app').service(MvNotifier.SID, MvNotifier);
