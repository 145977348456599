import * as React from 'react';
import { SingleFieldFormWithList } from '@deltasierra/components';
import { t, isEmailStringValid } from '@deltasierra/shared';

import { withAngularIntegration } from '../../common/componentUtils/reactComponentRegistration';
import { OneWayBinding, ExpressionBinding } from '../../common/angularData';
import { useAngularServiceContext } from '../../common/componentUtils/angularServiceContexts';

export type EmailNotificationsFormProps = {
    entries: string[] | null;
    onEntryChange: (entires: string[]) => void;
};

const EmailNotificationsForm: React.FunctionComponent<EmailNotificationsFormProps> = ({ entries, onEntryChange }) => {
    const mvIdentity = useAngularServiceContext('mvIdentity');

    const validate = React.useCallback((entry: string) => isEmailStringValid(entry.trim()), []);

    return (
        <>
            {mvIdentity.isManager() && (
                <SingleFieldFormWithList
                    entries={entries || []}
                    label={t('COMMON.EMAIL_ADDRESS')}
                    title={t('AGENCY.NOTIFICATIONS.AGENCY_EMAIL_NOTIFICATIONS')}
                    validateEntry={validate}
                    onEntryChange={entryList => onEntryChange(entryList.map(entry => entry.trim()))}
                />
            )}
        </>
    );
};

EmailNotificationsForm.displayName = 'EmailNotificationsForm';

export default withAngularIntegration(EmailNotificationsForm, 'emailNotificationsForm', {
    entries: OneWayBinding,
    onEntryChange: ExpressionBinding,
});
