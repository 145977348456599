import { AssignedLocation, BuilderTemplate, BuilderType } from '@deltasierra/shared';
import * as React from 'react';
import styled from 'styled-components';
import {
    ExpressionBinding,
    OneWayBinding,
    OptionalExpressionBinding,
    OptionalOneWayBinding,
} from '../../../common/angularData';
import { withAngularIntegration } from '../../../common/componentUtils/reactComponentRegistration';
import { useConfig } from '../../../common/config';
import { ContentBuilder } from '../../contentBuilder';
import { EmailBuilder } from '../../email/emailBuilder';
import { GetForUploadType } from '../../mvContentBuilderCtrl';
import { BuilderTemplateDraftActionsButton } from './BuilderTemplateDraftActionsButton/BuilderTemplateDraftActionsButton';

const StyledSpan = styled.span`
    padding-right: 10px;
`;

export interface BuilderTemplateSaveAreaProps {
    builderTemplate: BuilderTemplate;
    builderType: BuilderType;
    contentBuilder: ContentBuilder | EmailBuilder;
    getForUpload: GetForUploadType;
    loading?: boolean;
    location: AssignedLocation;
    onDraftSelect?: (locationDraftGraphqlId: string) => void;
    onOriginalTemplateSelect?: () => void;
}

const BuilderTemplateDraftActionArea: React.FC<BuilderTemplateSaveAreaProps> = props => {
    const [config] = useConfig();
    // Make sure we only show the area if we have a location, and this is for an existing builder template
    // Also check if the feature is enabled
    return (
        <StyledSpan>
            {config?.features.builder.builderTemplateDrafts && <BuilderTemplateDraftActionsButton {...props} />}
        </StyledSpan>
    );
};
BuilderTemplateDraftActionArea.displayName = 'BuilderTemplateDraftActionArea';

export const BuilderTemplateDraftActionAreaWithAngular = withAngularIntegration(
    BuilderTemplateDraftActionArea,
    'builderTemplateDraftActionArea',
    {
        builderTemplate: OneWayBinding,
        builderType: OneWayBinding,
        contentBuilder: OneWayBinding,
        getForUpload: ExpressionBinding,
        loading: OptionalOneWayBinding,
        location: OneWayBinding,
        onDraftSelect: OptionalExpressionBinding,
        onOriginalTemplateSelect: OptionalExpressionBinding,
    },
);
