import { DSFormGroup, DSFormLabel, Loading } from '@deltasierra/components';
import { FormikProps } from 'formik';
import * as React from 'react';
import { Translate } from '../../../../directives/Translate';
import { FacebookFormValues } from '../facebook-form-values';
import { FacebookFormHashtagsSectionInner } from './FacebookFormHashtagsSectionInner';
import { MissingAllRequiredPermissionForFirstCommentMessage } from './MissingAllRequiredPermissionForFirstCommentMessage';
import { MissingSomeRequiredPermissionForFirstCommentMessage } from './MissingSomeRequiredPermissionForFirstCommentMessage';
import { useCheckFacebookFirstCommentPermissions } from './useCheckFacebookFirstCommentPermissions';
import { useIsFacebookFirstCommentHashtagsEnabled } from './useIsFacebookFirstCommentHashtagsEnabled';

export type FacebookFormHashtagsSectionProps = {
    availableCaptionLength: number;
    availableFirstCommentLength: number;
    formik: FormikProps<FacebookFormValues>;
    locationId: string;
    locationIds: string[];
    requiredHashtags: string[];
    requiredHashtagsLoading: boolean;
};

export function FacebookFormHashtagsSection({
    availableCaptionLength,
    availableFirstCommentLength,
    formik,
    locationId,
    locationIds,
    requiredHashtags,
    requiredHashtagsLoading,
}: FacebookFormHashtagsSectionProps): JSX.Element {
    const isFeatureEnabled = useIsFacebookFirstCommentHashtagsEnabled();
    const hasRequiredPermissions = useCheckFacebookFirstCommentPermissions(locationIds);

    if (!isFeatureEnabled) {
        return <></>;
    }

    const availableCharacterLength =
        formik.values.hashtagsInsertType === 'caption' ? availableCaptionLength : availableFirstCommentLength;

    return (
        <DSFormGroup>
            <DSFormLabel>
                <Translate keyId="BUILD.PUBLISH.FACEBOOK.INPUT_LABELS.HASHTAGS" />
            </DSFormLabel>
            {hasRequiredPermissions.loading ? (
                <Loading inline size="small" />
            ) : (
                <>
                    {!hasRequiredPermissions.hasAtLeastOneWithPermission ? (
                        <MissingAllRequiredPermissionForFirstCommentMessage />
                    ) : (
                        <>
                            <FacebookFormHashtagsSectionInner
                                availableCharacterLength={availableCharacterLength}
                                formik={formik}
                                locationId={locationId}
                                locationIds={locationIds}
                                requiredHashtags={requiredHashtags}
                                requiredHashtagsLoading={requiredHashtagsLoading}
                            />
                            {hasRequiredPermissions.areSomeWithoutPermission && (
                                <MissingSomeRequiredPermissionForFirstCommentMessage />
                            )}
                        </>
                    )}
                </>
            )}
        </DSFormGroup>
    );
}
FacebookFormHashtagsSection.displayName = 'FacebookFormHashtagsSection';
FacebookFormHashtagsSection.MissingAllRequiredPermissionForFirstCommentMessage =
    MissingAllRequiredPermissionForFirstCommentMessage;
FacebookFormHashtagsSection.MissingSomeRequiredPermissionForFirstCommentMessage =
    MissingSomeRequiredPermissionForFirstCommentMessage;
FacebookFormHashtagsSection.useIsFacebookFirstCommentHashtagsEnabled = useIsFacebookFirstCommentHashtagsEnabled;
FacebookFormHashtagsSection.useCheckFacebookFirstCommentPermissions = useCheckFacebookFirstCommentPermissions;
