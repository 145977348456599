import { assertNever } from '@deltasierra/shared';
import { NodeViewWrapper } from '@tiptap/react';
import * as React from 'react';
import styled from 'styled-components';
import { UploadedImageNodeAttributes } from './uploaded-image-node-attributes';
import { UploadedImageNodeOptions } from './uploaded-image-node-options';

const StyledWrapper = styled.div<{ $align: TipTapTextAlignType }>`
    position: relative;

    ${props => {
        switch (props.$align) {
            case 'center':
                return 'text-align: center;';
            case 'justify':
                return 'text-align: justify;';
            case 'left':
                return 'text-align: left;';
            case 'right':
                return 'text-align: right;';
            default:
                return assertNever(props.$align);
        }
    }}
`;

const StyledImg = styled.img<{ $selected: boolean }>`
    ${props =>
        props.$selected &&
        `
            position: relative;
            outline: 3px solid #68cef8;
            z-index: 1000;
        `}
`;

export type UploadedImgProps = TipTapNodeViewWrapperChildProps<UploadedImageNodeAttributes, UploadedImageNodeOptions>;

export function UploadedImg({ node, selected }: UploadedImgProps): JSX.Element {
    const { class: className, src, textAlign = 'left', width } = node.attrs;

    return (
        <NodeViewWrapper>
            <StyledWrapper $align={textAlign}>
                <StyledImg $selected={selected} className={className} data-drag-handle src={src} width={width} />
            </StyledWrapper>
        </NodeViewWrapper>
    );
}
UploadedImg.displayName = 'UploadedImg';
