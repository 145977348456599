/// <reference path="../../../typings/browser.d.ts" />
import { Untyped } from '@deltasierra/shared';
import { BuilderConstants, builderConstantsSID } from './builderConstants';
import { ContentBuilderCtrlScope } from './mvContentBuilderCtrl';
import IDirective = angular.IDirective;

export const responsiveCanvasSID = 'responsiveCanvas';
export const responsiveCanvasConfig = {
    restrict: 'A',
    scope: {
        maximumHeight: '=?',
        zoomReset: '&?',
    },
};

interface ResponsiveCanvasScope extends ng.IScope {
    maximumHeight: number;
    zoomReset?: () => void;
    $parent: ContentBuilderCtrlScope;
}

angular.module('app').directive(responsiveCanvasSID, [
    builderConstantsSID,
    function (builderConstants: BuilderConstants): IDirective<ResponsiveCanvasScope> {
        return {
            ...responsiveCanvasConfig,
            link(scope: ResponsiveCanvasScope, element, attrs) {
                let currentCanvasWidth: Untyped = null;

                function resizeCanvas() {
                    const canvas = <HTMLCanvasElement>element.context;
                    const parent = canvas.parentElement;
                    if (parent && canvas.offsetParent && currentCanvasWidth != parent.clientWidth - 2) {
                        currentCanvasWidth = parent.clientWidth - 2;
                        canvas.width = currentCanvasWidth; // Border
                        if (scope.maximumHeight) {
                            if (scope.maximumHeight >= currentCanvasWidth) {
                                canvas.height = currentCanvasWidth;
                            } else {
                                canvas.height = scope.maximumHeight;
                            }
                        }
                        if (scope.zoomReset) {
                            scope.zoomReset();
                        }
                    }
                }

                window.addEventListener('resize', () => resizeCanvas());

                scope.$on('$destroy', () => {
                    window.removeEventListener('resize', () => resizeCanvas());
                });

                resizeCanvas(); // Inital resize
            },
        };
    },
]);
