/// <reference path="../../../typings/browser.d.ts" />
import { t } from '@deltasierra/shared';
import { $scopeSID } from '../common/angularData';
import { InteractionUtils } from '../common/interactionUtils';
import { MvNotifier } from '../common/mvNotifier';
import { AdminService } from './adminService';
import IScope = angular.IScope;

interface MvAdminCtrlScope extends IScope {
    loading: {
        generateThumbnails: boolean;
    };
    generateThumbnails(): void;
}

angular.module('app').controller('mvAdminCtrl', [
    $scopeSID,
    MvNotifier.SID,
    InteractionUtils.SID,
    AdminService.SID,
    function (
        $scope: MvAdminCtrlScope,
        mvNotifier: MvNotifier,
        interactionUtils: InteractionUtils,
        adminService: AdminService,
    ) {
        $scope.loading = {
            generateThumbnails: false,
        };

        $scope.generateThumbnails = function () {
            return interactionUtils
                .handleRemote($scope, 'generate thumbnails', 'generateThumbnails', adminService.generateThumbnails())
                .then(() => {
                    mvNotifier.info(t('ADMIN.NOTIFY_THUMBNAIL_GENERATION_STARTED'));
                });
        };
    },
]);
