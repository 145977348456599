import * as React from 'react';

export type PageInfoForForwards = {
    hasNextPage: boolean | null;
    endCursor: string | null;
};

/**
 * Returns an easy helper for fetching more from a connection
 *
 * @param connection - The connection
 * @param fetchMore - The fetch more function
 * @param disabled - Whether the fetching is disabled
 * @returns - A tuple of [hasMore, fetchMoreFunc]
 */
export function useConnectionFetchMore<T, AdditionalParams extends any[]>(
    connection: { pageInfo: PageInfoForForwards } | null | undefined,
    fetchMore: (cursor: string | null, ...additonalParams: AdditionalParams) => Promise<T> | T,
    disabled?: boolean,
): [fetchMore: (...additonalParams: AdditionalParams) => Promise<T | void>, hasMore: boolean] {
    const hasMore = !!connection && !!connection.pageInfo.hasNextPage;

    const memoizedFetchMore = React.useCallback(
        async (...additionalParams: AdditionalParams): Promise<void> => {
            if (connection?.pageInfo.hasNextPage && connection.pageInfo.endCursor) {
                await fetchMore(connection.pageInfo.endCursor, ...additionalParams);
            }
        },
        [connection?.pageInfo, fetchMore],
    );

    return [memoizedFetchMore, hasMore];
}
