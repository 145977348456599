import * as React from 'react';
import { DSDialog, DSDialogTitle, Translate } from '@deltasierra/components';
import { DeleteTemplateConfirmationModalContent } from './DeleteTemplateConfirmationModalContent';

export type DeleteTemplateConfirmationModalProps = {
    show: boolean;
    builderTemplateId: string;
    onCancel?: () => void;
    onConfirm?: () => void;
};

export function DeleteTemplateConfirmationModal({
    builderTemplateId,
    onCancel,
    onConfirm,
    show,
}: DeleteTemplateConfirmationModalProps): JSX.Element {
    return (
        <DSDialog open={show}>
            <DSDialogTitle>
                <Translate keyId="BUILD.BUILDER_TEMPLATE_DELETE_CONFIRMATION_MODAL.TITLE" />
            </DSDialogTitle>
            <DeleteTemplateConfirmationModalContent
                builderTemplateId={builderTemplateId}
                onCancel={onCancel}
                onConfirm={onConfirm}
            />
        </DSDialog>
    );
}
