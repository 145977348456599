import { DSFormControl, DSFormHelperText, DSInputLabel } from '@deltasierra/components';
import { EditorContent, EditorContentProps } from '@tiptap/react';
import * as React from 'react';
import styled from 'styled-components';
import { TipTapEditableContext } from './tip-tap-editable-context';
import { TipTapEditorMenu } from './TipTapEditorMenu';
import { useTipTapEditor } from './useTipTapEditor';

type StyledEditorProps = {
    $hideBorder?: boolean;
    $maxWidth?: number;
    $minHeight?: number;
    $padBottom?: number;
    $padTop?: number;
};

const StyledEditorContent = styled(EditorContent)<StyledEditorProps>`
    position: relative;

    ${props => props.$padTop && `padding-top: ${props.$padTop}px;`}
    ${props => props.$maxWidth && `max-width: ${props.$maxWidth}px;`}

    .ProseMirror {
        ${props => props.$minHeight && `min-height: ${props.$minHeight}px`};

        padding: 12px 5px;

        ${props => props.$padBottom && `padding-bottom: ${props.$padBottom}px;`}

        ${props => !props.$hideBorder && `border-bottom: 1px solid ${props.theme.palette.grey[400]};`}
        

        &:focus {
            ${props => !props.$hideBorder && `border-bottom: 2px solid ${props.theme.palette.primary.main};`}

            outline: none;
        }
    }

    .ProseMirror a {
        cursor: pointer;
        text-decoration: underline;
    }
`;

export type TipTapEditorProps = {
    children?: EditorContentProps['children'];
    editor: EditorContentProps['editor'];
    helperText?: React.ReactNode;
    hideBorder?: boolean;
    label?: React.ReactNode;
    maxWidth?: number;
    minHeight?: number;
    padBottom?: number;
    padTop?: number;
};

export function TipTapEditor({
    children,
    editor,
    helperText,
    hideBorder,
    label,
    maxWidth,
    minHeight,
    padBottom,
    padTop,
}: TipTapEditorProps): JSX.Element {
    return (
        <DSFormControl fullWidth>
            {label && (
                <DSInputLabel focused={editor?.isFocused} shrink>
                    {label}
                </DSInputLabel>
            )}
            <StyledEditorContent
                $hideBorder={hideBorder}
                $maxWidth={maxWidth}
                $minHeight={minHeight}
                $padBottom={padBottom}
                $padTop={padTop}
                editor={editor}
            >
                <TipTapEditableContext.Provider value={editor?.isEditable ?? false}>
                    {children}
                </TipTapEditableContext.Provider>
            </StyledEditorContent>
            {helperText && <DSFormHelperText>{helperText}</DSFormHelperText>}
        </DSFormControl>
    );
}
TipTapEditor.displayName = 'TipTapEditor';
TipTapEditor.useTipTapEditor = useTipTapEditor;
TipTapEditor.TipTapEditorMenu = TipTapEditorMenu;
