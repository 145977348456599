import dsTryThisMenu = require('./dsTryThisMenu');
import mvTryThis = require('./mvTryThis');
import mvTryThisCtrl = require('./mvTryThisCtrl');
import mvTryThisDetailsCtrl = require('./mvTryThisDetailsCtrl');
import mvTryThisEditCtrl = require('./mvTryThisEditCtrl');
import mvTryThisResource = require('./mvTryThisResource');
import mvTryThisTagResource = require('./mvTryThisTagResource');

export {
    dsTryThisMenu,
    mvTryThis,
    mvTryThisCtrl,
    mvTryThisDetailsCtrl,
    mvTryThisEditCtrl,
    mvTryThisResource,
    mvTryThisTagResource,
};
