import { DSFormGroup, DSFormLabel, DSTypography, Loading, Translate } from '@deltasierra/components';
import { LocationId } from '@deltasierra/shared';
import * as React from 'react';
import { useGetInstagramPageDescription } from '../common/hooks';

export type InstagramDirectFormDescriptionSectionProps = {
    oldLocationIds: LocationId[];
};

export function InstagramDirectFormDescriptionSection({
    oldLocationIds,
}: InstagramDirectFormDescriptionSectionProps): JSX.Element {
    const [instagramPageDescription, instagramLoading] = useGetInstagramPageDescription(oldLocationIds);

    return (
        <DSFormGroup>
            <DSFormLabel>
                <Translate keyId="BUILD.PUBLISH.INSTAGRAM.INSTAGRAM_PAGE" />
            </DSFormLabel>
            {instagramLoading && <Loading inline size="small" />}
            {instagramPageDescription && <DSTypography>{instagramPageDescription}</DSTypography>}
        </DSFormGroup>
    );
}
InstagramDirectFormDescriptionSection.displayName = 'InstagramDirectFormDescriptionSection';
