import * as React from 'react';
import { DSTypography, Translate } from '@deltasierra/components';
import { FlexColumnCenter, BigIcon } from '../styled';

export const GeneralErrorMessage = (): JSX.Element => (
    <FlexColumnCenter>
        <BigIcon className="far fa-exclamation-circle text-danger" />
        <DSTypography component="h2" variant="h2">
            <Translate keyId="ASSET_LIBRARY.UNABLE_TO_LOAD" />
        </DSTypography>
        <DSTypography component="h4" variant="h3">
            <Translate keyId="COMMON.CHECK_CONNECTION" />
        </DSTypography>
    </FlexColumnCenter>
);
GeneralErrorMessage.displayName = 'CollectionsErrorMessage';
