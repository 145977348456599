import {
    addTermsAndConditionsToCaption,
    AssignedLocation,
    BuilderDocumentFormat,
    BuilderTemplateId,
    ExportPlatforms,
    GalleryPlannerDetails,
    getDefaultScheduledTime,
    getOneYearMinusOneDayFromNow,
    IInstagramPublishData,
    InstagramChannelData,
    InstagramPublishMediaBody,
    INSTAGRAM_LINK_EXPIRY_WEEKS,
    IPublishVideoToInstagramRequest,
    ISize,
    LocationId,
    LocationIdHierarchy,
    PublishVideoCallback,
    t,
    TermsAndConditionsChannelData,
    Untyped,
    UploadId,
    User,
    UserDevicesStatus,
    WorkflowStartResult,
} from '@deltasierra/shared';
import type { IQService, IScope } from 'angular';
import phoneLib from 'google-libphonenumber';
import { MvIdentity } from '../../../account/mvIdentity';
import { $kookiesSID, $qSID, $scopeSID, IKookies } from '../../../common/angularData';
import { $modalSID } from '../../../common/angularUIBootstrapData';
import { InteractionUtils } from '../../../common/interactionUtils';
import { MvNotifier } from '../../../common/mvNotifier';
import { DeviceApiClient } from '../../../devices/devicesApiClient';
import { I18nService } from '../../../i18n/i18nService';
import { InstagramPublishService } from '../../../integration/publish/instagramPublishService';
import { LocationUserApiClient } from '../../../locations/locationUserApiClient';
import { ModalService } from '../../../typings/angularUIBootstrap/modalService';
import { BuilderConstants, builderConstantsSID } from '../../builderConstants';
import { ContentBuilder } from '../../contentBuilder';
import { ImageLoaderService } from '../../imageLoaderService';
import { MvContentBuilderPublishCtrl } from '../mvContentBuilderPublishCtrl';
import { PublishResult, PublishResultOptions } from '../publishResult';
import { PublishVideoResult, VideoFinishCallback } from '../publishVideoDirective/controller';
import { PublishCallback, PublishFlowCallbacks } from '../types';

export interface PublishToFacebookCtrlScope extends IScope {
    ctrl: MvContentBuilderPublishCtrl;
}

const MIN_TIME_DIFF = 10 * 60 * 1000; // Must be at least 10 minutes in the future.
const MAX_TIME_DIFF = 75 * 24 * 60 * 60 * 1000; // Must be no more than 75 days in the future.

export class CommonPublishData {
    public constructor(
        public caption: string | null,
        public scheduledTime: Date | null,
        public templateId: BuilderTemplateId,
        public plannerId?: number,
    ) {}
}

enum Views {
    SCHEDULE = 'schedule',
    NOTIFICATION = 'notification',
}

interface IInstagramScheduleController {
    onPublishVideo: PublishVideoCallback<IInstagramPublishData>;
    onFinishVideoPublish: VideoFinishCallback;
}

export class InstagramScheduleController
    implements IInstagramScheduleController, PublishFlowCallbacks<CommonPublishData> {
    public static SID = 'instagramScheduleController';

    public readonly linkExpiryInWeeks = INSTAGRAM_LINK_EXPIRY_WEEKS;

    public phoneUtil = phoneLib.PhoneNumberUtil.getInstance();

    public view: Views = Views.SCHEDULE;

    public isToBeScheduled = false;

    public caption: string | null = null;

    public captionLabel: string;

    public termsAndConditionsText: string | null = null;

    public termsAndConditionsUrl: string | null = null;

    public contactNumber: string | null = null;

    public suggestedCaption: string | null = null;

    public datePickerIsOpen = {
        scheduledTime: false,
    };

    public isPublishing = false;

    public contentBuilder!: ContentBuilder;

    public templateId!: BuilderTemplateId;

    public plannerDetails?: GalleryPlannerDetails | null = null;

    public commonData: CommonPublishData | null = null;

    public publishCtrl!: MvContentBuilderPublishCtrl;

    public location!: AssignedLocation;

    public scheduledTime: Date | null = null;

    public countryCodes: string[] = [];

    public selectedCountryCode = '';

    public chosenLocations!: LocationIdHierarchy[];

    public assignableUsers: User[] | null = null;

    public assignedUser: User | null = null;

    public assignedUserSearch: string | null = null;

    public userDevicesStatus: UserDevicesStatus = {
        hasDevice: false,
        notificationsEnabled: false,
    };

    public minDate = new Date();

    public maxDate = getOneYearMinusOneDayFromNow();

    public channelData: IInstagramPublishData | null = null;

    public outputDimensions: ISize;

    public outputVideoUrl: string | null = null;

    public readonly fetchUsers = this.interactionUtils.createFuture(this.i18n.text.common.fetchData, () =>
        this.locationUserApiClient.getAssignableUsers(this.location.clientId, this.location.id).then(users => {
            this.assignableUsers = users.map(user => {
                // AngularUI Bootstrap Typeahead doesn't search through all of a generated label, just each field.
                // By generating the label here, it lets typeahead search this property.
                (user as any).label = this.getSearchLabel(user);
                return user;
            });
        }),
    );

    public readonly fetchUserDevicesStatus = this.interactionUtils.createFuture(this.i18n.text.common.fetchData, () =>
        this.deviceApiClient.getUserDevicesStatus(this.assignedUser!.id).then(status => {
            this.userDevicesStatus = status;
        }),
    );

    private readonly COUNTRY_CODE_COOKIE_NAME = 'countryCode';

    public static readonly $inject: string[] = [
        $scopeSID,
        $qSID,
        $modalSID,
        $kookiesSID,
        builderConstantsSID,
        ImageLoaderService.SID,
        MvNotifier.SID,
        InstagramPublishService.SID,
        I18nService.SID,
        LocationUserApiClient.SID,
        InteractionUtils.SID,
        DeviceApiClient.SID,
        MvIdentity.SID,
    ];

    public constructor(
        protected $scope: PublishToFacebookCtrlScope,
        protected $q: IQService,
        protected $modal: ModalService,
        protected $kookies: IKookies,
        protected builderConstants: BuilderConstants,
        protected imageLoaderService: ImageLoaderService,
        protected mvNotifier: MvNotifier,
        protected instagramPublishService: InstagramPublishService,
        protected i18n: I18nService,
        protected locationUserApiClient: LocationUserApiClient,
        protected interactionUtils: InteractionUtils,
        protected deviceApiClient: DeviceApiClient,
        protected mvIdentity: MvIdentity,
    ) {
        this.captionLabel = this.i18n.text.build.publish.instagram.caption();
        this.populateInheritedProperties();
        this.fetchUsers.run({});

        this.plannerDetails = this.publishCtrl.plannerDetails;
        this.suggestedCaption = this.contentBuilder.getChannelDatum(
            'instagram',
            (data: InstagramChannelData) => data.caption,
        );
        this.termsAndConditionsText = this.contentBuilder.getChannelDatum(
            'termsAndConditions',
            (data: TermsAndConditionsChannelData) => data.text,
        );
        this.termsAndConditionsUrl = this.contentBuilder.getChannelDatum(
            'termsAndConditions',
            (data: TermsAndConditionsChannelData) => data.url,
        );
        if (this.plannerDetails) {
            this.caption = this.plannerDetails.text;
        }
        this.scheduledTime = getDefaultScheduledTime(15, this.plannerDetails, 19);
        if (this.publishCtrl && this.publishCtrl.fileFormatChoice) {
            this.outputDimensions = {
                height: this.publishCtrl.fileFormatChoice.height,
                width: this.publishCtrl.fileFormatChoice.width,
            };
        } else {
            // Ehhh, this shouldn't happen, but let's be safe.
            this.outputDimensions = {
                height: this.publishCtrl.contentBuilder.document.dimensions.height,
                width: this.publishCtrl.contentBuilder.document.dimensions.width,
            };
        }

        this.countryCodes = Object.keys(phoneLib.metadata.countryCodeToRegionCodeMap).map(x => `+${x}`);
        this.countryCodes.unshift('');
        this.selectedCountryCode = this.getCountryCode() || '';
        this.assignedUserSearch = this.getSearchLabel(this.mvIdentity.currentUser!);
        this.onUserSelect(this.mvIdentity.currentUser!);
    }

    public populateInheritedProperties(): void {
        this.publishCtrl = this.$scope.ctrl;
        this.chosenLocations = this.publishCtrl.locations;
        this.contentBuilder = this.publishCtrl.contentBuilder;
        this.templateId = this.publishCtrl.templateId;
        this.location = this.publishCtrl.location;
    }

    public onUserInputChange(): void {
        if (!!this.assignedUser && this.assignedUserSearch !== this.getSearchLabel(this.assignedUser)) {
            this.assignedUser = null;
        }
    }

    public onUserSelect(user: User): ng.IPromise<void> {
        this.assignedUser = user;
        return this.fetchUserDevicesStatus.run({});
    }

    public isValidMobile(): any {
        if (this.contactNumber === null || this.contactNumber === '') {
            return false;
        }

        const parsed = this.getParsedPhoneNumber();
        const isPossible = this.phoneUtil.isPossibleNumber(parsed);
        if (isPossible) {
            const isValid = this.phoneUtil.isValidNumber(parsed);
            return isValid;
        }

        return false;
    }

    public getFormattedPhoneNumber(): string | null {
        const checkMobileNumber =
            !this.userDevicesStatus.hasDevice && this.contactNumber !== '' && this.contactNumber !== null;
        if (checkMobileNumber) {
            const parsed = this.getParsedPhoneNumber();
            const phone: string = this.phoneUtil.format(parsed, phoneLib.PhoneNumberFormat.E164);
            const indexOfPlus = phone.indexOf('+');
            return indexOfPlus !== -1 ? phone.slice(indexOfPlus + 1, phone.length) : phone;
        }

        return null;
    }

    public cancel(): void {
        this.$scope.$emit(this.builderConstants.EVENTS.PUBLISH_CANCEL);
    }

    public finish(): void {
        const options: PublishResultOptions = {
            notification: {
                assignedUser: this.assignedUser!,
            },
        };
        this.$scope.$emit(
            this.builderConstants.EVENTS.PUBLISH_PUBLISHED,
            new PublishResult(ExportPlatforms.Instagram, this.scheduledTime, options),
        );
    }

    public getDoFinish(): () => void {
        return this.finish.bind(this);
    }

    public clickSchedule(): void {
        const now = new Date().valueOf();
        const sTime = this.isToBeScheduled && this.scheduledTime ? this.scheduledTime.valueOf() : null;
        if (sTime && (sTime < now || sTime - now <= MIN_TIME_DIFF)) {
            this.mvNotifier.expectedError(
                this.i18n.text.build.publish.instagram.scheduledPostMustBeAtLeastMinsInFuture({
                    minutes: `${MIN_TIME_DIFF / (60 * 1000)}`,
                }),
            );
        } else if (sTime && sTime - now >= MAX_TIME_DIFF) {
            this.mvNotifier.expectedError(
                t('BUILD.PUBLISH.INSTAGRAM.SCHEDULED_INSTAGRAM_POST_MUST_BE_NO_MORE_THAN_75_DAYS_IN_FUTURE'),
            );
        } else {
            this.view = Views.NOTIFICATION;
        }
    }

    public clickPublish(): void {
        const checkMobileNumber =
            !this.userDevicesStatus.hasDevice && this.contactNumber !== '' && this.contactNumber !== null;
        if (checkMobileNumber && this.selectedCountryCode === '') {
            return this.mvNotifier.expectedError(this.i18n.text.build.publish.instagram.selectRelevantCountryCode());
        }

        const validMobileNumber = checkMobileNumber ? this.isValidMobile() : true;
        if (validMobileNumber) {
            this.startPublish();
        } else {
            return this.mvNotifier.expectedError(this.i18n.text.build.publish.instagram.enterValidMobileNumber());
        }
    }

    public openDatePicker($event: Untyped, datePickerName: Untyped): void {
        $event.preventDefault();
        $event.stopPropagation();
        (this.datePickerIsOpen as Untyped)[datePickerName] = true;
    }

    public getDoPublish(): PublishCallback<CommonPublishData> {
        return this.doPublish.bind(this);
    }

    public async doPublish(locationId: LocationId, uploadId: UploadId, commonData: CommonPublishData): Promise<void> {
        // Call api with Instagram information
        const body: InstagramPublishMediaBody = {
            assignedUserId: this.assignedUser!.id,
            builderTemplateId: commonData.templateId,
            caption: commonData.caption,
            linkedAssetLibraryAssetIds: this.contentBuilder.linkedAssetLibraryAsset.map(value => ({
                assetId: value.asset.id,
                layerId: value.layerId,
            })),
            notificationPhoneNumber: this.getFormattedPhoneNumber(),
            plannerId: commonData.plannerId,
            scheduledTime: (commonData.scheduledTime ? commonData.scheduledTime.toISOString() : null) as any,
            uploadId,
        };

        await this.instagramPublishService.publishPhoto(locationId, body);
    }

    public onPublishVideo(request: IPublishVideoToInstagramRequest): ng.IPromise<WorkflowStartResult> {
        request.channelData = {
            assignedUserId: this.assignedUser!.id,
            caption: this.caption,
            notificationPhoneNumber: this.getFormattedPhoneNumber(),
            scheduledTime: (this.scheduledTime ? this.scheduledTime.toISOString() : null) as any,
        };
        return this.instagramPublishService.publishVideo(this.location.id, request);
    }

    public onFinishVideoPublish(result: PublishVideoResult): void {
        this.isPublishing = false;
        switch (result.success) {
            case true:
                this.finish();
                break;
            case false:
            default:
                this.cancel();
                break;
        }
    }

    public isExportingVideo(): boolean {
        return this.contentBuilder.document.format === BuilderDocumentFormat.video;
    }

    protected startPublish(): void {
        const optionalPlannerId = this.plannerDetails ? this.plannerDetails.id : undefined;
        this.scheduledTime = this.isToBeScheduled ? this.scheduledTime : null;
        if (this.termsAndConditionsText || this.termsAndConditionsUrl) {
            this.caption = addTermsAndConditionsToCaption(
                this.caption,
                this.termsAndConditionsText,
                this.termsAndConditionsUrl,
            );
        }
        const commonData = new CommonPublishData(
            this.caption === '' ? null : this.caption,
            this.scheduledTime,
            this.templateId,
            optionalPlannerId,
        );
        this.commonData = commonData;
        this.isPublishing = true;
        this.setCountryCodeCookie(this.selectedCountryCode);
    }

    private getSearchLabel(user: User) {
        return `${user.firstName} ${user.lastName} (${user.username})`;
    }

    private getCountryCode() {
        return this.$kookies.get(this.COUNTRY_CODE_COOKIE_NAME, String);
    }

    private setCountryCodeCookie(countryCode: string) {
        this.$kookies.set(this.COUNTRY_CODE_COOKIE_NAME, countryCode, { path: '/', secure: true });
    }

    private getParsedPhoneNumber(): any {
        const parsed = this.phoneUtil.parseAndKeepRawInput(
            `${this.selectedCountryCode}${this.contactNumber || ''}`,
            '',
        );
        return parsed;
    }
}

angular.module('app').controller(InstagramScheduleController.SID, InstagramScheduleController);
