/* eslint-disable max-depth */
import { gql, useMutation } from '@apollo/client';
import { Button, FormGroup, WizardStepProps } from '@deltasierra/components';
import { shallowEqual } from '@deltasierra/object-utilities';
import { isEmailStringValid, isStringTooLong, t, CreateMailchimpCampaignDto, Html } from '@deltasierra/shared';
import { Form, Formik, FormikErrors } from 'formik';
import * as React from 'react';
import { useAngularServiceContext } from '../../../../../common/componentUtils/angularServiceContexts';
import { ValidateableFormGroup } from '../../../../../common/form/ValidateableFormGroup';
import { ValidatableTextInput } from '../../../../../common/form/ValidateableTextInput';
import { useFuture } from '../../../../../common/futures';
import { Translate } from '../../../../../directives/Translate';
import { EmailPublishData } from '../../../emailPublishData';
import { PublishToMailchimpStep1Data } from './PublishToMailchimpStep1';
import { CreateMailchimpCampaign, CreateMailchimpCampaignVariables } from './__graphqlTypes/CreateMailchimpCampaign';

const CREATE_MAILCHIMP_CAMPAIGN = gql`
    mutation CreateMailchimpCampaign($mailchimpId: ID!, $input: CreateMailchimpCampaignInput!) {
        createMailchimpCampaign(id: $mailchimpId, input: $input) {
            __typename
            ... on MailchimpCampaign {
                campaignId
            }
            ... on MailchimpApiError {
                message
            }
        }
    }
`;

const FONT_STYLE_REGEX = /<style((?!<style)[\s\S])*?(@import)[\s\S]*?(<\/style>)/g;
const FONT_IMPORT_REGEX = /(@import).*?(url\().*?(\);)/g;

function regExpEscape(str: string) {
    return str.replace(/[-[\]{}()*+!<=:?./\\^$|#\s,]/g, '\\$&');
}

// eslint-disable-next-line max-statements
function fixFonts(html: string): Html {
    let newHtml = html;

    const linkTagsToAdd: string[] = [];
    // Search for instances of <style> @import url(...) </style> style tags
    const styleTagsWithImports = newHtml.match(FONT_STYLE_REGEX);
    if (styleTagsWithImports?.length) {
        for (const styleTagWithImport of styleTagsWithImports) {
            // Search for @import url(...) within the style tag
            const importStatements = styleTagWithImport.match(FONT_IMPORT_REGEX);
            if (importStatements?.length) {
                for (const importStatement of importStatements) {
                    // Get the url from the import statements
                    const url = importStatement.substring(importStatement.indexOf('(') + 1, importStatement.indexOf(')'));

                    if (url.trim().length > 0) {
                        // Compile a regex to find all <link href="${url}" /> tags
                        const hasLinkRegex = new RegExp(`(<link)((?!<link)[\\s\\S])*?("${regExpEscape(url)}")[\\s\\S]*?(>)`, 'g');
                        // eslint-disable-next-line @typescript-eslint/prefer-regexp-exec
                        const hasLinkAlreadyMatches = newHtml.match(hasLinkRegex);

                        // If we do not already have the <link> tag with the url, add it to the list
                        if (!hasLinkAlreadyMatches?.length) {
                            const toAdd = `<link href="${url}" rel="stylesheet" type="text/css" />`;
                            if (!linkTagsToAdd.includes(toAdd)) {
                                linkTagsToAdd.push(toAdd);
                            }
                        }
                    }
                }
            }
        }
    }

    // Remove instances of @import style tags
    newHtml = newHtml.replace(FONT_STYLE_REGEX, '') as Html;
    // If we need to add link tags
    if (linkTagsToAdd.length > 0) {
        // Add all the link tags to the html
        const indexOfHeadTag = newHtml.indexOf('</head>');
        newHtml = (newHtml.substring(0, indexOfHeadTag) + linkTagsToAdd.join('') + newHtml.substring(indexOfHeadTag)) as Html;
    }

    return newHtml as Html;
}

type FormValues = Pick<CreateMailchimpCampaignDto, 'fromName' | 'name' | 'replyTo' | 'subject'>;

export type PublishToMailchimpStep2Data = {
    campaignId: string;
    formValues: FormValues;
    html: Html;
};

export type PublishToMailchimpStep2Props = WizardStepProps<PublishToMailchimpStep2Data> & {
    mailchimpId: string;
    publishData: EmailPublishData;
    step1Data: PublishToMailchimpStep1Data;
    step2Data?: PublishToMailchimpStep2Data;
};

// eslint-disable-next-line max-lines-per-function
export const PublishToMailchimpStep2: React.FC<PublishToMailchimpStep2Props> = ({
    CancelButton,
    PrevButton,
    StepCount,
    gotoNextStep,
    mailchimpId,
    nextButtonProps,
    publishData,
    step1Data,
    step2Data,
}) => {
    const mvNotifier = useAngularServiceContext('mvNotifier');

    const emailPublishService = useAngularServiceContext('EmailPublishService');
    const [createMailchimpCampaign] = useMutation<CreateMailchimpCampaign, CreateMailchimpCampaignVariables>(CREATE_MAILCHIMP_CAMPAIGN);

    const { state, invoke: saveDraftCampaign } = useFuture(
        async (values: FormValues) => {
            let html;
            if (step2Data) {
                // If no values have changed, we use the same campaign we already sent
                if (shallowEqual(values, step2Data.formValues)) {
                    gotoNextStep(step2Data);
                    return;
                } else {
                    // We should probably delete the old campaign in this block here
                }
                if (step2Data.html) {
                    html = step2Data.html;
                }
            }

            // If the html is not already set, we need to refetch it, otherwise reuse it
            if (html === undefined) {
                /*  First we need to rectify all the images and convert the publish data
                    to a html string. This is probably the dumbest thing to do at this
                    stage because if it fails, we might accidentally create many copies
                    of these uploaded images... however, all the other builders follow
                    this process so changing it will be a big task... aww well
                */
                html = await emailPublishService.rectifyAllImagesAndConvertToString(
                    publishData.builderDocument,
                    publishData.htmlDocument,
                    publishData.fileCache,
                );

                html = fixFonts(html);
            }

            const response = await createMailchimpCampaign({
                variables: {
                    input: {
                        ...values,
                        builderTemplateId: publishData.templateId,
                        html,
                        linkedAssetLibraryAssetIds: publishData.linkedAssetLibraryAssetIds,
                        listId: step1Data.selectedList.id,
                        plannerId: publishData.plannerId,
                        // The segmentId is optional, so if we don't include it, there shouldn't be an issue
                        segmentId: step1Data.selectedListSegment?.id,
                    },
                    mailchimpId,
                },
            });

            if (!response.data) {
                mvNotifier.unexpectedErrorWithData('The request returned no response data', response);
                return;
            }

            const responseData = response.data;

            if (responseData.createMailchimpCampaign.__typename === 'MailchimpApiError') {
                mvNotifier.unexpectedError(responseData.createMailchimpCampaign.message);
                return;
            }

            const campaignId = responseData.createMailchimpCampaign.campaignId.toString();
            gotoNextStep({ campaignId, formValues: values, html });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [emailPublishService, createMailchimpCampaign, step1Data, mailchimpId, publishData, gotoNextStep, mvNotifier],
    );

    const initialValues: FormValues = {
        fromName: '',
        name: '',
        replyTo: '',
        subject: publishData.builderDocument.subject ?? '',
        ...step2Data?.formValues,
    };

    return (
        <>
            <Formik
                initialValues={initialValues}
                validate={values => {
                    const errors: FormikErrors<FormValues> = {};

                    if (!values.name) {
                        errors.name = t('COMMON.VALIDATION.REQUIRED');
                    } else if (isStringTooLong(values.name, 50)) {
                        errors.name = t('COMMON.VALIDATION.STRING_TOO_LONG', {
                            maxCharacters: 50,
                            name: t('BUILD.PUBLISH.MAILCHIMP.CAMPAIGN_NAME'),
                        });
                    }

                    if (!values.subject) {
                        errors.subject = t('COMMON.VALIDATION.REQUIRED');
                    }

                    if (!values.fromName) {
                        errors.fromName = t('COMMON.VALIDATION.REQUIRED');
                    }

                    if (!values.replyTo) {
                        errors.replyTo = t('COMMON.VALIDATION.REQUIRED');
                    } else if (!isEmailStringValid(values.replyTo)) {
                        errors.replyTo = t('COMMON.VALIDATION.INVALID_EMAIL');
                    }

                    return errors;
                }}
                validateOnMount
                onSubmit={async values => saveDraftCampaign(values)}
            >
                {formIk => (
                    <Form>
                        <ValidateableFormGroup fullWidth label={t('BUILD.PUBLISH.MAILCHIMP.CAMPAIGN_NAME')} name="name" required>
                            <ValidatableTextInput maxLength={50} />
                        </ValidateableFormGroup>
                        <ValidateableFormGroup fullWidth label={t('BUILD.PUBLISH.MAILCHIMP.SUBJECT')} name="subject" required>
                            <ValidatableTextInput />
                        </ValidateableFormGroup>
                        <ValidateableFormGroup fullWidth label={t('BUILD.PUBLISH.MAILCHIMP.FROM_NAME')} name="fromName" required>
                            <ValidatableTextInput />
                        </ValidateableFormGroup>
                        <ValidateableFormGroup fullWidth label={t('BUILD.PUBLISH.MAILCHIMP.REPLY_TO')} name="replyTo" required>
                            <ValidatableTextInput />
                        </ValidateableFormGroup>
                        <FormGroup fullWidth>
                            <div className="pull-left">
                                <StepCount />
                            </div>
                            <div className="pull-right">
                                <CancelButton />
                                <PrevButton />
                                <Button {...nextButtonProps} disabled={state.isLoading || !formIk.isValid} type="submit">
                                    <Translate keyId="BUILD.PUBLISH.MAILCHIMP.SAVE_DRAFT" />
                                </Button>
                            </div>
                        </FormGroup>
                    </Form>
                )}
            </Formik>
        </>
    );
};
PublishToMailchimpStep2.displayName = 'PublishToMailchimpStep2';
