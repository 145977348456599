import * as React from 'react';
import { assertNever } from '@deltasierra/shared';
import { Icon, AssetThumbnail, AssetThumbnailToolbar } from '@deltasierra/components';
import styled from 'styled-components';
import { gql } from '@apollo/client';
import { useAssetLibrarySettings, useCurrentAssetContext } from '../../contexts';
import { translateFileTypeToString, useAssetLibraryContext } from '../../context';
import { EditAssetsModal } from '../EditAssetsModal';
import { rectifyS3UrlWithFilename } from '../../../directives/dsDownloadLink';
import { AssetThumbnailViewItemFragment } from './__graphqlTypes/AssetThumbnailViewItemFragment';
import { AssetFolderThumbnailContent } from './AssetFolderThumbnailContent';
import { AssetFileThumbnailContent } from './AssetFileThumbnailContent';

const ThumbnailToolbarInner = styled.div`
    color: white;
    font-size: 12px;
    height: 22px;
    padding: 2px;
    text-align: right;
`;

const fragments = {
    AssetThumbnailViewItemFragment: gql`
        fragment AssetThumbnailViewItemFragment on AssetFileOrFolder {
            __typename
            ... on AssetFolder {
                id
                title
                expires
                selected @client
                deselected @client
                ...AssetFolderThumbnailContentFragment
            }
            ... on AssetFile {
                id
                title
                expires
                type
                selected @client
                deselected @client
                url
                ...AssetFileThumbnailContentFragment
                ...EditAssetsModalFragment
            }
        }
        ${AssetFolderThumbnailContent.fragments.AssetFolderThumbnailContentFragment}
        ${AssetFileThumbnailContent.fragments.AssetFileThumbnailContentFragment}
        ${EditAssetsModal.fragments.EditAssetsModalFragment}
    `,
};

export type AssetThumbnailViewItemProps = {
    asset: AssetThumbnailViewItemFragment;
    dimensions: Record<'height' | 'width', number>;
    hideButtons?: boolean;
    onClickPreview?: () => void;
};

function fromNow(diff: number): string {
    const millisecondsInDay = 24 * 60 * 60 * 1000;
    const days = diff / millisecondsInDay;
    if (days < 7) {
        return `${Math.floor(days)} Day(s)`;
    } else if (days < 30) {
        return `${Math.floor(days / 7)} Week(s)`;
    } else {
        return `${Math.floor(days / 30)} Month(s)`;
    }
}

/* eslint-disable max-lines-per-function */
export const AssetThumbnailViewItem: React.FunctionComponent<AssetThumbnailViewItemProps> & {
    fragments: typeof fragments;
} = ({ asset, dimensions, hideButtons, onClickPreview }) => {
    const [showEditTagModal, setShowEditTagModal] = React.useState(false);
    const { toggleAssetSelected } = useAssetLibraryContext();
    const [, setCurrentCollectionOrAssetId] = useCurrentAssetContext();
    const { removeAnchorOnFolderIcon } = useAssetLibrarySettings();

    const onDoubleClick = () => {
        if (asset.__typename === 'AssetFolder') {
            setCurrentCollectionOrAssetId(asset.id);
        } else if (asset.__typename === 'AssetFile') {
            onClickPreview?.();
        } else {
            throw assertNever(asset);
        }
    };

    const onClick: React.MouseEventHandler = (event: React.MouseEvent) => {
        const onSingleClick = () => {
            if (asset.__typename === 'AssetFolder') {
                toggleAssetSelected?.({ id: asset.id, type: 'folder' });
            } else {
                toggleAssetSelected?.({ id: asset.id, type: translateFileTypeToString(asset.type) });
            }
        };

        switch (event.detail) {
            case 1:
                onSingleClick();
                break;
            case 2:
                onDoubleClick();
                break;
            case 3:
                onDoubleClick();
                break;
            default:
                break;
        }
    };

    let transform = 'none';
    if (asset.deselected) {
        transform = 'scale(0.95, 0.95)';
    } else if (asset.selected) {
        transform = 'scale(1.02, 1.02)';
    }

    return (
        <AssetThumbnail
            data-cy="asset-thumbnail-item"
            style={{
                background: '#f0f3f4',
                height: '100%',
                opacity: asset.deselected ? 0.6 : 1,
                textAlign: 'center',
                transform,
                transition: '100ms',
            }}
            onClick={onClick}
        >
            {asset.__typename === 'AssetFolder' && (
                <AssetFolderThumbnailContent asset={asset} height={(dimensions.height * 1.8) / 3} />
            )}
            {asset.__typename === 'AssetFile' && (
                <AssetFileThumbnailContent
                    asset={asset}
                    height={asset.thumbnails ? dimensions.height : (dimensions.height * 1.8) / 3}
                />
            )}
            <AssetThumbnailToolbar>
                <ThumbnailToolbarInner className={asset.selected ? 'bg-brand-purple' : 'bg-brand-black'}>
                    {(asset.__typename === 'AssetFile' && (
                        <>
                            {!hideButtons && asset.expires !== null && (
                                <span style={{ color: 'white', float: 'left', marginRight: 'auto' }}>
                                    <Icon className="icon-space" icon="clock"></Icon>
                                    {fromNow(new Date(asset.expires).getTime() - new Date().getTime())}
                                </span>
                            )}
                            {!hideButtons && (
                                <>
                                    <i
                                        className="asset-thumbnail-icon glyphicon glyphicon-edit text-primary"
                                        data-cy="edit-asset-button"
                                        onClick={() => setShowEditTagModal(true)}
                                    />
                                    <EditAssetsModal
                                        assets={[asset]}
                                        show={showEditTagModal}
                                        onClose={() => setShowEditTagModal(false)}
                                    />
                                    <i
                                        className="asset-thumbnail-icon glyphicon glyphicon-download-alt text-primary"
                                        onClick={() => window.open(rectifyS3UrlWithFilename(asset.url, asset.title))}
                                    />
                                </>
                            )}
                            {onClickPreview && (
                                <i
                                    className="asset-thumbnail-icon glyphicon glyphicon-search text-primary"
                                    onClick={() => onClickPreview()}
                                />
                            )}
                        </>
                    )) ||
                        (asset.__typename === 'AssetFolder' && (
                            <>
                                {removeAnchorOnFolderIcon ? (
                                    <i
                                        className="asset-thumbnail-icon glyphicon glyphicon-folder-open text-primary"
                                        // TODO: write Cypress e2e test
                                        data-cy="open-folder-button"
                                        onClick={() => onDoubleClick()}
                                    />
                                ) : (
                                    <a
                                        data-cy="open-folder-button"
                                        href={`/assets/view?collection=${asset.id}`}
                                    >
                                        <i
                                            className="asset-thumbnail-icon glyphicon glyphicon-folder-open text-primary"
                                        />
                                    </a>
                                )}
                            </>
                        ))}
                </ThumbnailToolbarInner>
            </AssetThumbnailToolbar>
        </AssetThumbnail>
    );
};
/* eslint-enable max-lines-per-function */
AssetThumbnailViewItem.displayName = 'AssetThumbnailViewItem';
AssetThumbnailViewItem.fragments = fragments;
