/// <reference path="../../../typings/browser.d.ts" />
import { Untyped } from '@deltasierra/shared';
import { $filterSID, $kookiesSID, $routeParamsSID, IKookies } from './angularData';
import IFilterService = angular.IFilterService;
import IRouteParams = angular.IRouteParams;

export const dsMonthPickerSID = 'dsMonthPicker';
export const dsMonthPickerConfig = {
    restrict: 'E',
    templateUrl: '/partials/common/dsMonthPicker',
};

export interface MonthPickerScope extends ng.IScope {
    month: string;
    months: string[];
    updateDate(): void;
}

angular.module('app').directive(dsMonthPickerSID, [
    $kookiesSID,
    $filterSID,
    $routeParamsSID,
    function dsMonthPicker($kookies: IKookies, $filter: IFilterService, $routeParams: IRouteParams): ng.IDirective<MonthPickerScope> {
        const TOTAL_MONTHS = 12;
        const PREVIOUS_MONTHS = TOTAL_MONTHS / 2;
        // Const MIDPOINT_MONTH = PREVIOUS_MONTHS;

        function formatDate(date: Date): string {
            return $filter('date')(date, 'yyyy-MM-01', '+0000');
        }

        function getDate() {
            const dateString = $kookies.get('plannerMonth');
            return dateString;
        }

        function setDate(dateString: string): void {
            $kookies.set('plannerMonth', dateString, { path: '/', secure: true });
        }

        function calculateDateOptions() {
            const months = [];
            let dateCounter = new Date(); // Always calculate date options from today
            // Calculate from the start of the month (e.g. no 30th in Feb)
            dateCounter = new Date(dateCounter.getFullYear(), dateCounter.getMonth(), 1);
            const startMonth = dateCounter.getMonth() - PREVIOUS_MONTHS;
            dateCounter.setMonth(startMonth);
            for (let i = 0; i < TOTAL_MONTHS; i++) {
                dateCounter.setMonth(dateCounter.getMonth() + 1);
                const formattedDate = formatDate(dateCounter);
                months.push(formattedDate);
            }
            return months;
        }

        const directive = {
            ...dsMonthPickerConfig,
            link($scope: MonthPickerScope, element: Untyped, attributes: Untyped) {
                let initialDate: string | undefined;
                $scope.months = calculateDateOptions();
                if ($routeParams.setMonth) {
                    // Force set month
                    initialDate = formatDate(new Date($routeParams.setMonth));
                } else {
                    initialDate = getDate();
                    if (!initialDate) {
                        initialDate = $scope.months[$scope.months.length / 2];
                    }
                }
                setDate(initialDate);
                $scope.month = initialDate;
                $scope.$emit('dateLoaded', $scope.month);

                $scope.updateDate = () => {
                    setDate($scope.month);
                    $scope.$emit('dateUpdated', $scope.month);
                };

                $scope.$watch('month', () => {
                    setDate($scope.month);
                    // Do not emit an event - assume it's been changed programmatically
                });
            },
        };
        return directive;
    },
]);
