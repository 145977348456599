/// <reference path="../../../typings/browser.d.ts" />

import { forbiddenDateThreshold, Planner } from '@deltasierra/shared';
import moment from 'moment-timezone';
import * as linq from 'linq';
import { I18nService } from '../i18n';

export interface PlannersByDate {
    date: Date;
    planners: Planner[];
}

export class PlannerDateService {
    public static readonly SID = 'plannerDateService';

    private static readonly DANGER_ZONE_THRESHOLD = 7;

    private formatDate: ng.IFilterDate;

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public static readonly $inject: string[] = ['$filter', I18nService.SID];

    public constructor($filter: ng.IFilterService, private i18n: I18nService) {
        this.formatDate = $filter('date');
    }

    public isBeforeToday(date: Date): boolean {
        let today = new Date();
        today = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate()));
        return date.getTime() < today.getTime();
    }

    public isToday(date: Date): boolean {
        let today = new Date();
        today = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate()));
        return (
            date.getFullYear() === today.getFullYear() &&
            date.getMonth() === today.getMonth() &&
            date.getDate() === today.getDate()
        );
    }

    public isForbiddenDate(date: Date): boolean {
        let today = new Date();
        today = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate()));
        const forbiddenDateThresholdDate = new Date(today.valueOf());
        forbiddenDateThresholdDate.setDate(forbiddenDateThresholdDate.getDate() + forbiddenDateThreshold);
        const dateToTest = date.getTime();
        return dateToTest >= today.getTime() && dateToTest < forbiddenDateThresholdDate.getTime();
    }

    public isDangerousDate(date: Date): boolean {
        let today = new Date();
        today = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate()));
        const dangerousDateThreshold = new Date(today.valueOf());
        dangerousDateThreshold.setDate(today.getDate() + PlannerDateService.DANGER_ZONE_THRESHOLD);
        const dateToTest = date.getTime();
        return dateToTest >= today.getTime() && dateToTest < dangerousDateThreshold.getTime();
    }

    public formatDateUTC(date: Date, optionalFormat = 'yyyy-MM-dd'): string {
        return this.formatDate(date, optionalFormat, '+0000');
    }

    public getFriendlyDate(date: Date): string {
        const today = moment().startOf('day').toDate();
        const startOfDate = moment(date).startOf('day').toDate();
        const daysAway = moment(startOfDate).diff(today, 'days');

        switch (daysAway) {
            case -1:
                return this.i18n.text.common.yesterday();
            case 0:
                return this.i18n.text.common.today();
            case 1:
                return this.i18n.text.common.tomorrow();
            default:
                return this.formatDate(startOfDate, 'fullDate');
        }
    }

    public groupPlannersByDate(planners: Planner[]): PlannersByDate[] {
        const result = linq
            .from(planners)
            .groupBy(
                planner => moment.utc(planner.date).local(true).startOf('day').toDate().getTime(),
                event => event,
                (time, group) => ({
                    date: new Date(time),
                    planners: group.orderBy(planner => planner.date).toArray(),
                }),
            )
            .orderBy(group => group.date)
            .toArray();

        return result;
    }
}

angular.module('app').service(PlannerDateService.SID, PlannerDateService);
