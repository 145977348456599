import { gql, useQuery } from '@apollo/client';
import { Loading } from '@deltasierra/components';
import { t } from '@deltasierra/shared';
import * as React from 'react';
import { RecycledAssets } from './RecycledAssets';
import { GetLocationExpiredAssets, GetLocationExpiredAssetsVariables } from './__graphqlTypes/GetLocationExpiredAssets';

const GET_LOCATION_EXPIRED_ASSETS = gql`
    query GetLocationExpiredAssets($locationId: ID!) {
        location(id: $locationId) {
            id
            expired {
                ...AssetConnectionForRecycledAssets
            }
        }
    }
    ${RecycledAssets.fragments.ASSET_CONNECTION_FOR_RECYCLED_ASSETS}
`;

export interface LocationExpiredAssetsProps {
    locationId: string;
}

export function LocationExpiredAssets(props: LocationExpiredAssetsProps): JSX.Element {
    const { data, loading } = useQuery<GetLocationExpiredAssets, GetLocationExpiredAssetsVariables>(
        GET_LOCATION_EXPIRED_ASSETS,
        {
            fetchPolicy: 'network-only',
            nextFetchPolicy: 'cache-first',
            variables: { locationId: props.locationId },
        },
    );
    if (!data?.location || loading) {
        return <Loading />;
    } else {
        return <RecycledAssets assetConnection={data.location.expired} headerText={t('ASSET_LIBRARY.EXPIRED_ASSETS')} />;
    }
}
LocationExpiredAssets.displayName = 'LocationExpiredAssets';
