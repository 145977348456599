/// <reference path="../../../typings/browser.d.ts" />
import { $resourceSID } from '../common/angularData';
import IResourceService = angular.resource.IResourceService;

angular.module('app').factory('mvTryThisResource', [
    $resourceSID,
    // eslint-disable-next-line func-names
    function ($resource: IResourceService) {
        const TryThisResource = $resource(
            '/api/trythis',
            {},
            {
                update: {
                    method: 'PUT',
                    isArray: false,
                },
                delete: {
                    method: 'DELETE',
                    isArray: false,
                },
            },
        );

        return TryThisResource;
    },
]);
