import { gql, useMutation, useQuery } from '@apollo/client';
import {
    DSButton,
    DSCheckbox,
    DSDialog,
    DSDialogActions,
    DSDialogContent,
    DSDialogContentText,
    DSDialogTitle,
    DSList,
    DSListItem,
    DSListItemIcon,
    DSListItemText,
    DSLoaderButton,
    DSPaper,
    DSTextField,
    Loader,
    Translate,
} from '@deltasierra/components';
import { useControlledSearchInput } from '@deltasierra/react-hooks';
import * as React from 'react';
import { relayConnectionToArray } from '../../../../graphql/utils';
import { AssignBrandForDialog, AssignBrandForDialogVariables } from './__graphqlTypes/AssignBrandForDialog';
import { BrandForAssociateDialog } from './__graphqlTypes/BrandForAssociateDialog';
import { GetClientsForBrands } from './__graphqlTypes/GetClientsForBrands';
import { UnassignBrandForDialog, UnassignBrandForDialogVariables } from './__graphqlTypes/UnassignBrandForDialog';

export type AssociateBrandDialogProps = {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
    brand: BrandForAssociateDialog;
};

const fragments = {
    AssociateBrandDialogFragment: gql`
        fragment BrandForAssociateDialog on Brand {
            __typename
            id
            title
            clients {
                edges {
                    node {
                        __typename
                        id
                    }
                }
            }
        }
    `,
};

const GET_CLIENTS_FOR_BRANDS = gql`
    query GetClientsForBrands($endCursor: String, $title: String) {
        admin {
            id
            clients(first: 10, after: $endCursor, filter: { title: $title }) {
                edges {
                    node {
                        id
                        title
                        brand {
                            __typename
                            id
                            title
                        }
                    }
                }
                pageInfo {
                    endCursor
                    hasNextPage
                }
            }
        }
    }
`;

const ASSIGN_BRAND_FOR_DIALOG = gql`
    mutation AssignBrandForDialog($input: AssignBrandInput!) {
        assignBrand(input: $input) {
            __typename
            ... on AssignBrandPayload {
                client {
                    id
                }
            }
        }
    }
`;

const UNASSIGN_BRAND_FOR_DIALOG = gql`
    mutation UnassignBrandForDialog($input: UnassignBrandInput!) {
        unassignBrand(input: $input) {
            __typename
            ... on UnassignBrandPayload {
                client {
                    id
                }
            }
        }
    }
`;

function ControlledInput(props: { initialValue: string; onChange: (value: string) => void }): JSX.Element {
    const { inputProps } = useControlledSearchInput({
        initialValue: props.initialValue,
        onSearchTermValueChange: props.onChange,
    });
    return <DSTextField label={<Translate keyId="ADMIN.BRANDS.FILTER_CLIENTS" />} variant="outlined" {...inputProps} />;
}
ControlledInput.displayName = 'ClientSearch';

export const AssociateBrandDialog: React.FC<AssociateBrandDialogProps> & { fragments: typeof fragments } = ({
    isOpen,
    ...rest
}) => (
    <DSDialog maxWidth="md" open={isOpen}>
        <AssociateBrandDialogContent {...rest} />
    </DSDialog>
);
AssociateBrandDialog.displayName = 'AssociateBrandDialog';
AssociateBrandDialog.fragments = fragments;

export type AssociateBrandDialogContentProps = Pick<AssociateBrandDialogProps, 'brand' | 'onClose'>;

export type Deltum = {
    id: string;
    unassign: boolean;
};

/* eslint-disable max-lines-per-function */
const AssociateBrandDialogContent: React.FC<AssociateBrandDialogContentProps> = ({ brand, onClose }) => {
    const [title, setTitle] = React.useState('');

    const [selected, setSelected] = React.useState(brand.clients.edges.map(({ node }) => node.id));
    const [original, setOriginal] = React.useState(brand.clients.edges.map(({ node }) => node.id));
    const { data, loading, fetchMore } = useQuery<GetClientsForBrands>(GET_CLIENTS_FOR_BRANDS, {
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
        variables: { title },
    });

    const [assignBrand] = useMutation<AssignBrandForDialog, AssignBrandForDialogVariables>(ASSIGN_BRAND_FOR_DIALOG, {
        refetchQueries: ['GetBrandsForAdminWell'],
    });
    const [unassignBrand] = useMutation<UnassignBrandForDialog, UnassignBrandForDialogVariables>(
        UNASSIGN_BRAND_FOR_DIALOG,
        {
            refetchQueries: ['GetBrandsForAdminWell'],
        },
    );

    const clients = relayConnectionToArray(data?.admin.clients);

    const bulkToggle = (onClick: () => void) => async () => {
        await Promise.all(
            clients.map(async client => {
                const brandId = brand.id;
                const clientId = client.id;
                if (selected.find(id => id === clientId) !== original.find(id => id === clientId)) {
                    const assign = !!selected.find(id => id === clientId);
                    if (assign) {
                        const result = await assignBrand({
                            variables: { input: { brandId, clientId } },
                        });
                        if (result.data?.assignBrand.__typename !== 'AssignBrandPayload') {
                            setSelected(prev => prev.filter(id => clientId !== id));
                        }
                    } else {
                        const result = await unassignBrand({
                            variables: { input: { brandId, clientId } },
                        });
                        if (result.data?.unassignBrand.__typename !== 'UnassignBrandPayload') {
                            setSelected(prev => [...prev, clientId]);
                        }
                    }
                }
            }),
        );
        setOriginal(selected);
        onClick();
    };
    const handleToggle = (brandId: string, clientId: string) => {
        const unassign = !!selected.find(id => id === clientId);
        if (!unassign) {
            setSelected(prev => [...prev, clientId]);
        } else {
            setSelected(prev => prev.filter(id => clientId !== id));
        }
    };

    return (
        <>
            <DSDialogTitle>
                <Translate keyId="ADMIN.BRANDS.ASSIGN_CLIENTS_TO" options={{ brand: brand.title }} />
            </DSDialogTitle>
            <DSDialogContent>
                <DSDialogContentText>
                    <Translate keyId="ADMIN.BRANDS.CHECK_CLIENTS_TO_ASSIGN_TO" options={{ brand: brand.title }} />
                </DSDialogContentText>
                <DSDialogContentText>
                    <Translate keyId="ADMIN.BRANDS.CANNOT_ASSIGN_CLIENT_IF" />
                </DSDialogContentText>
                <ControlledInput initialValue={title} onChange={setTitle} />
                <DSPaper
                    elevation={0}
                    style={{
                        border: '1px solid #eee',
                        height: '240px',
                        overflowY: 'scroll',
                        padding: '4px 8px 0px 8px',
                    }}
                >
                    <DSList>
                        <Loader loading={!data}>
                            {clients.map(client => (
                                <DSListItem
                                    disabled={!!client.brand && brand.id !== client.brand?.id}
                                    key={client.id}
                                    selected={!!selected.find(id => id === client.id)}
                                    onClick={() => handleToggle(brand.id, client.id)}
                                >
                                    <DSListItemIcon>
                                        <DSCheckbox
                                            checked={!!selected.find(id => id === client.id)}
                                            color="primary"
                                            disableRipple
                                            edge="start"
                                            inputProps={{ 'aria-labelledby': client.title }}
                                            tabIndex={-1}
                                        />
                                    </DSListItemIcon>
                                    <DSListItemText>{client.title}</DSListItemText>
                                </DSListItem>
                            ))}
                        </Loader>
                    </DSList>
                </DSPaper>
            </DSDialogContent>
            <DSDialogActions style={{ padding: '8px 24px' }}>
                {data?.admin.clients.pageInfo.hasNextPage && (
                    <DSLoaderButton
                        color="primary"
                        loading={loading}
                        style={{ marginRight: 'auto' }}
                        variant="outlined"
                        onClick={async () =>
                            fetchMore({ variables: { endCursor: data.admin.clients.pageInfo.endCursor } })
                        }
                    >
                        <Translate keyId="COMMON.LOAD_MORE" />
                    </DSLoaderButton>
                )}
                <DSButton color="default" variant="outlined" onClick={onClose}>
                    <Translate keyId="COMMON.CANCEL" />
                </DSButton>
                <DSButton color="primary" onClick={bulkToggle(onClose)}>
                    <Translate keyId="COMMON.DONE" />
                </DSButton>
            </DSDialogActions>
        </>
    );
};
AssociateBrandDialogContent.displayName = 'AssociateBrandDialogContent';
