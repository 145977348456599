/* eslint-disable max-lines-per-function */
import { FetchResult, gql, useApolloClient, useMutation } from '@apollo/client';
import { t } from '@deltasierra/shared';
import { HashtagSetFormValues } from '../HashtagSetDialogContent';
import { useAngularServiceContext } from '../../../../common/componentUtils/angularServiceContexts';
import {
    DeleteClientHashtagSetForTableRow,
    DeleteClientHashtagSetForTableRowVariables,
} from './__graphqlTypes/DeleteClientHashtagSetForTableRow';
import {
    UpdateClientHashtagSetForTableRow,
    UpdateClientHashtagSetForTableRowVariables,
} from './__graphqlTypes/UpdateClientHashtagSetForTableRow';
import {
    UpdateLocationHashtagSetForTableRow,
    UpdateLocationHashtagSetForTableRowVariables,
} from './__graphqlTypes/UpdateLocationHashtagSetForTableRow';
import {
    DeleteLocationHashtagSetForTableRow,
    DeleteLocationHashtagSetForTableRowVariables,
} from './__graphqlTypes/DeleteLocationHashtagSetForTableRow';

const updateClientHashtagSetMutation = gql`
    mutation UpdateClientHashtagSetForTableRow($input: EditClientHashtagSetInput!) {
        editClientHashtagSet(input: $input) {
            ... on EditClientHashtagSetError {
                code
                message
            }
            ... on EditClientHashtagSetSuccess {
                hashtagSet {
                    id
                    description
                    title
                    hashtags
                    isCore
                }
            }
        }
    }
`;

const updateLocationHashtagSetMutation = gql`
    mutation UpdateLocationHashtagSetForTableRow($input: EditLocationHashtagSetInput!) {
        editLocationHashtagSet(input: $input) {
            ... on EditLocationHashtagSetError {
                code
                message
            }
            ... on EditLocationHashtagSetSuccess {
                hashtagSet {
                    id
                    description
                    title
                    hashtags
                }
            }
        }
    }
`;

const deleteClientHashtagSetMutation = gql`
    mutation DeleteClientHashtagSetForTableRow($hashtagSetId: ID!) {
        deleteClientHashtagSet(id: $hashtagSetId) {
            ... on DeleteClientHashtagSetError {
                code
                message
            }
            ... on DeleteClientHashtagSetSuccess {
                id
            }
        }
    }
`;

const deleteLocationHashtagSetMutation = gql`
    mutation DeleteLocationHashtagSetForTableRow($hashtagSetId: ID!) {
        deleteLocationHashtagSet(id: $hashtagSetId) {
            ... on DeleteLocationHashtagSetError {
                code
                message
            }
            ... on DeleteLocationHashtagSetSuccess {
                id
            }
        }
    }
`;

export function useHashtagRowMutations(
    id: string,
    clientOrLocation: 'client' | 'location',
): {
    deleteHashtagSet: () => Promise<
        | FetchResult<DeleteClientHashtagSetForTableRow, Record<string, any>, Record<string, any>>
        | FetchResult<DeleteLocationHashtagSetForTableRow, Record<string, any>, Record<string, any>>
    >;
    updateHashtagSet: (
        values: HashtagSetFormValues,
    ) => Promise<
        | FetchResult<UpdateClientHashtagSetForTableRow, Record<string, any>, Record<string, any>>
        | FetchResult<UpdateLocationHashtagSetForTableRow, Record<string, any>, Record<string, any>>
    >;
} {
    const apolloClient = useApolloClient();
    const notifier = useAngularServiceContext('mvNotifier');
    const [updateClient] = useMutation<UpdateClientHashtagSetForTableRow, UpdateClientHashtagSetForTableRowVariables>(
        updateClientHashtagSetMutation,
        {
            onCompleted: data => {
                if (data.editClientHashtagSet.__typename === 'EditClientHashtagSetError') {
                    notifier.unexpectedErrorWithData(
                        t('AGENCY.HASHTAG_SETS.ACTIONS.MESSAGES.COULD_NOT_UPDATE_SET'),
                        data.editClientHashtagSet.message,
                    );
                } else {
                    notifier.success(t('AGENCY.HASHTAG_SETS.ACTIONS.MESSAGES.UPDATED_SET'));
                }
            },
        },
    );
    const [updateLocation] = useMutation<
        UpdateLocationHashtagSetForTableRow,
        UpdateLocationHashtagSetForTableRowVariables
    >(updateLocationHashtagSetMutation, {
        onCompleted: data => {
            if (data.editLocationHashtagSet.__typename === 'EditLocationHashtagSetError') {
                notifier.unexpectedErrorWithData(
                    t('AGENCY.HASHTAG_SETS.ACTIONS.MESSAGES.COULD_NOT_UPDATE_SET'),
                    data.editLocationHashtagSet.message,
                );
            } else {
                notifier.success(t('AGENCY.HASHTAG_SETS.ACTIONS.MESSAGES.UPDATED_SET'));
            }
        },
    });

    const [deleteClientSet] = useMutation<
        DeleteClientHashtagSetForTableRow,
        DeleteClientHashtagSetForTableRowVariables
    >(deleteClientHashtagSetMutation, {
        onCompleted: data => {
            if (data.deleteClientHashtagSet.__typename === 'DeleteClientHashtagSetSuccess') {
                const hashtagSetCacheId = apolloClient.cache.identify({
                    __typename: 'ClientHashtagSet',
                    id: data.deleteClientHashtagSet.id,
                });
                apolloClient.cache.evict({ id: hashtagSetCacheId });
                apolloClient.cache.gc();
                notifier.success(t('AGENCY.HASHTAG_SETS.ACTIONS.MESSAGES.DELETED_SET'));
            } else {
                notifier.unexpectedErrorWithData(
                    t('AGENCY.HASHTAG_SETS.ACTIONS.MESSAGES.COULD_NOT_DELETE_SET'),
                    data.deleteClientHashtagSet.message,
                );
            }
        },
    });
    const [deleteLocationSet] = useMutation<
        DeleteLocationHashtagSetForTableRow,
        DeleteLocationHashtagSetForTableRowVariables
    >(deleteLocationHashtagSetMutation, {
        onCompleted: data => {
            if (data?.deleteLocationHashtagSet.__typename === 'DeleteLocationHashtagSetSuccess') {
                const hashtagSetCacheId = apolloClient.cache.identify({
                    __typename: 'LocationHashtagSet',
                    id: data.deleteLocationHashtagSet.id,
                });
                apolloClient.cache.evict({ id: hashtagSetCacheId });
                apolloClient.cache.gc();
                notifier.success(t('AGENCY.HASHTAG_SETS.ACTIONS.MESSAGES.CREATED_SET'));
            } else {
                notifier.unexpectedErrorWithData(
                    t('AGENCY.HASHTAG_SETS.ACTIONS.MESSAGES.COULD_NOT_DELETE_SET'),
                    data.deleteLocationHashtagSet.message,
                );
            }
        },
    });
    const updateHashtagSet = async (values: HashtagSetFormValues) =>
        clientOrLocation === 'client'
            ? updateClient({
                  variables: {
                      input: {
                          ...values,
                          id,
                      },
                  },
              })
            : updateLocation({
                  variables: {
                      input: {
                          description: values.description,
                          hashtags: values.hashtags,
                          id,
                          title: values.title,
                      },
                  },
              });
    const deleteHashtagSet = async () =>
        clientOrLocation === 'client'
            ? deleteClientSet({ variables: { hashtagSetId: id } })
            : deleteLocationSet({ variables: { hashtagSetId: id } });

    return { deleteHashtagSet, updateHashtagSet };
}
