import * as React from 'react';
import styled from 'styled-components';
import { NotifierNotificationComponentType, NotifierNotificationProps } from '../notifier-notification';
import { NotifierOptions } from '../notifier';

const StyledWrapper = styled.div`
    position: fixed;
    top: 80px;
    bottom: 0;
    right: 0;
    left: 0;
    pointer-events: none;
    z-index: ${props => props.theme.zIndex.tooltip};
`;

const StyledPositionedNotifications = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    flex-direction: column;

    pointer-events: none;
`;

const StyledNotificationPositioner = styled.div`
    position: relative;
    margin: 5px;
    pointer-events: all;
`;

export type ActiveNotification = {
    id: string;
    options: NotifierOptions;
    component: NotifierNotificationComponentType;
    props: NotifierNotificationProps;
};

export type NotifierContainerProps = {
    notifications: ActiveNotification[];
};

export function NotifierContainer({ notifications }: NotifierContainerProps): JSX.Element {
    return (
        <StyledWrapper>
            <StyledPositionedNotifications>
                {notifications.map(notification => (
                    <StyledNotificationPositioner key={notification.id}>
                        <notification.component {...notification.props} />
                    </StyledNotificationPositioner>
                ))}
            </StyledPositionedNotifications>
        </StyledWrapper>
    );
}
NotifierContainer.displayName = 'NotifierContainer';
