import { gql, useQuery } from '@apollo/client';
import { DSTypography } from '@deltasierra/components';
import { t } from '@deltasierra/shared';
import * as React from 'react';
import styled from 'styled-components';
import { GetCurrentLocationForNoAssetsMessage2 } from './__graphqlTypes/GetCurrentLocationForNoAssetsMessage2';

const GET_CURRENT_LOCATION_FOR_NO_ASSETS_MESSAGE2 = gql`
    query GetCurrentLocationForNoAssetsMessage2($locationId: ID!, $hasLocation: Boolean! = false) {
        hasCurrentLocation @client @export(as: "hasLocation")
        currentLocationId @client @export(as: "locationId")
        location(id: $locationId) @include(if: $hasLocation) {
            id
            client {
                id
                title
            }
        }
    }
`;

export function NoAssetsMessage(): JSX.Element {
    const { data } = useQuery<GetCurrentLocationForNoAssetsMessage2>(GET_CURRENT_LOCATION_FOR_NO_ASSETS_MESSAGE2);
    return (
        <CenterFlex>
            <BigIcon className="far fa-folder-open" />
            <DSTypography component="h2" variant="h2">
                {!!data?.location && <>{data.location.client.title}</>}
            </DSTypography>
            <DSTypography component="h2" variant="h2">
                {t('COMMON.NO_ASSETS_FOUND')}
            </DSTypography>
        </CenterFlex>
    );
}
NoAssetsMessage.displayName = 'NoAssetsMessage';

const CenterFlex = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const BigIcon = styled.i`
    font-size: 100px;
    padding-bottom: 10px;
`;
