import {
    AssignedLocation,
    ExportPlatforms,
    InstagramStoryDirectPublishFormData,
    ISize,
    LocationIdHierarchy,
    PreparedVideoRequestData,
} from '@deltasierra/shared';
import * as React from 'react';
import { OneWayBinding } from '../../../common/angularData';
import { withAngularIntegration } from '../../../common/componentUtils/reactComponentRegistration';
import { MultipleLocationVideoPublish } from '../multipleLocationPublish/MultipleLocationVideoPublish';
import { MvContentBuilderPublishCtrl } from '../mvContentBuilderPublishCtrl';
import { PublishResult } from '../publishResult';
import { PublishFinishCallback } from '../types';
import { usePrepareVideo, VideoUploadProgress } from '../instagramDirect/VideoUploadProgress';
import { useInstagramStoryVideoPublishCallback } from './useInstagramStoryVideoPublishCallback';
import { PublishToInstagramStoryDirectForm } from './PublishToInstagramStoryDirectForm';

export type PublishVideoToInstagramStoryDirectProps = {
    location: AssignedLocation;
    locations: LocationIdHierarchy[];
    publishController: MvContentBuilderPublishCtrl;
    onCancel: () => void;
    onFinish: (data: PublishResult) => void;
};

const PublishVideoToInstagramStoryDirect: React.FC<PublishVideoToInstagramStoryDirectProps> = props => {
    const [isPublishing, setIsPublishing] = React.useState(false);
    const [publishData, setPublishData] = React.useState<InstagramStoryDirectPublishFormData | null>(null);
    const [videoData, setVideoData] = React.useState<PreparedVideoRequestData | null>(null);
    const [outputDimensions, setoutputDimensions] = React.useState<ISize>({
        height: props.publishController.contentBuilder.document.dimensions.height,
        width: props.publishController.contentBuilder.document.dimensions.width,
    });

    const [prepareVideo, videoProcessingContext] = usePrepareVideo();

    const videoPublishCallback = useInstagramStoryVideoPublishCallback(props.publishController, videoData);

    const isUploading = videoProcessingContext.uploadContext.uploadInProgress;

    const onPublish = async (data: InstagramStoryDirectPublishFormData) => {
        setIsPublishing(true);
        setPublishData(data);

        if (props.publishController.fileFormatChoice) {
            setoutputDimensions({
                height: props.publishController.fileFormatChoice.height,
                width: props.publishController.fileFormatChoice.width,
            });
        }
        const preparedVideoData = await prepareVideo(
            props.publishController.contentBuilder.document,
            outputDimensions,
            props.publishController,
        );
        setVideoData(preparedVideoData);
    };

    const finishCallback: PublishFinishCallback = () => {
        const scheduledTime = (publishData && publishData.scheduledTime) || null;
        props.onFinish(new PublishResult(ExportPlatforms.InstagramStoryDirect, scheduledTime));
    };

    return (
        <>
            {!isPublishing && !isUploading && !videoData && (
                <PublishToInstagramStoryDirectForm {...props} onPublish={onPublish} />
            )}
            {isUploading && <VideoUploadProgress uploadContext={videoProcessingContext.uploadContext} />}
            {isPublishing && !isUploading && videoData && publishData && (
                <MultipleLocationVideoPublish
                    commonData={publishData}
                    contentBuilder={props.publishController.contentBuilder}
                    locations={props.locations}
                    originalLocation={props.location}
                    publishCallback={videoPublishCallback}
                    publishCtrl={props.publishController}
                    onCancel={props.onCancel}
                    onFinish={finishCallback}
                />
            )}
        </>
    );
};

PublishVideoToInstagramStoryDirect.displayName = 'PublishVideoToInstagramStoryDirect';

export default withAngularIntegration(PublishVideoToInstagramStoryDirect, 'publishVideoToInstagramStoryDirect', {
    location: OneWayBinding,
    locations: OneWayBinding,
    onCancel: OneWayBinding,
    onFinish: OneWayBinding,
    publishController: OneWayBinding,
});
