import {
    EmailAddress,
    EmailProcessingFailedEvent,
    EmailProcessingFinishedEvent,
    ExportPlatforms,
    FitwareSimpleTemplate,
    Html,
    IBuilderEmailDocument,
    IPublishEmailResponseLegacy,
    LocationId,
    SendFitwareAutomatedEmailRequest,
    TextSubstitutionValues,
    UploadId,
} from '@deltasierra/shared';
import {
    $scopeSID,
    actualComponent,
    ExpressionBinding,
    ExpressionCallback,
    OneWayBinding,
    OptionalOneWayBinding,
} from '../../../../common/angularData';
import { ConfirmModal, confirmModalSID } from '../../../../common/confirmModal';
import { InteractionUtils } from '../../../../common/interactionUtils';
import { SentryService } from '../../../../common/sentryService';
import { UploadContext } from '../../../../common/uploadService';
import { I18nService } from '../../../../i18n';
import { FitwarePublishService } from '../../../../integration/publish/fitwarePublishService';
import { SocketService } from '../../../../sockets/socketService';
import { BuilderConstants, builderConstantsSID } from '../../../builderConstants';
import { PublishResult } from '../../../publish/publishResult';
import { EmailPublishData } from '../../emailPublishData';
import { EmailPublishService } from '../emailPublishService';
import IScope = angular.IScope;

enum PublishStep {
    AutomatedEmailCategory,
    Subject,
    SendPreview,
    Confirm,
    Publishing,
}

interface CommonPublishData {
    automatedEmailGroupCode: string;
    builderDocument: IBuilderEmailDocument;
    subject: string;
    textSubstitutionValues: TextSubstitutionValues;
}

interface PublishAutomatedEmailToFitwareCtrlScope extends IScope {
    uploadContext?: UploadContext;
}

export class PublishAutomatedEmailToFitwareCtrl {
    public static readonly SID = 'PublishAutomatedEmailToFitwareCtrl';

    public publishData!: EmailPublishData;

    public automatedEmailCode?: string;

    public readonly onGoBack!: ExpressionCallback<Record<keyof never, unknown>>;

    public readonly subject?: string;

    public options: {
        automatedEmailCategories: FitwareSimpleTemplate[];
    } = {
        automatedEmailCategories: [],
    };

    public selected: {
        automatedEmailCategory?: FitwareSimpleTemplate;
    } = {
        automatedEmailCategory: undefined,
    };

    public PublishStep = PublishStep;

    public step = PublishStep.AutomatedEmailCategory;

    public fitwarePublishData: {
        previewRecipient: EmailAddress;
        subject: string;
    } = {
        previewRecipient: EmailAddress.from(''),
        subject: this.publishData.builderDocument.subject ?? '',
    };

    public confirmed = false;

    public commonData: CommonPublishData | undefined;

    public sendPreviewEmail = this.interactionUtils.createFuture(
        'send preview e-mail',
        (context: { locationId: LocationId; previewRecipient: EmailAddress }) =>
            this.emailPublishService
                .rectifyAllImagesAndConvertToString(
                    this.publishData.builderDocument,
                    this.publishData.htmlDocument,
                    this.publishData.fileCache,
                    this.$scope,
                )
                .then((html: Html) =>
                    this.fitwarePublishService.sendPreviewEmail(
                        context.locationId,
                        context.previewRecipient,
                        html,
                        this.fitwarePublishData.subject,
                    ),
                ),
    );

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public static readonly $inject: string[] = [
        $scopeSID,
        I18nService.SID,
        InteractionUtils.SID,
        builderConstantsSID,
        FitwarePublishService.SID,
        EmailPublishService.SID,
        SocketService.SID,
        confirmModalSID,
        SentryService.SID,
    ];

    // eslint-disable-next-line max-params
    public constructor(
        protected readonly $scope: PublishAutomatedEmailToFitwareCtrlScope,
        protected readonly i18n: I18nService,
        protected readonly interactionUtils: InteractionUtils,
        protected readonly builderConstants: BuilderConstants,
        protected readonly fitwarePublishService: FitwarePublishService,
        protected readonly emailPublishService: EmailPublishService,
        protected readonly socketService: SocketService,
        protected readonly confirmModal: ConfirmModal,
        protected readonly sentryService: SentryService,
    ) {}

    public $onInit(): void {
        this.fitwarePublishData = {
            previewRecipient: EmailAddress.from(''),
            subject: this.publishData.builderDocument.subject ?? '',
        };
        if (this.subject) {
            this.fitwarePublishData.subject = this.subject;
        }
    }

    public cancelPublishing(): void {
        this.$scope.$emit(this.builderConstants.EVENTS.PUBLISH_CANCEL);
    }

    public goToPreviousStep(): void {
        const newStep = this.step - 1;
        if (PublishStep[newStep] !== undefined) {
            this.step = newStep;
        } else {
            this.confirmModal.open(
                this.i18n.text.build.publish.goBackConfirmTitle(),
                this.i18n.text.build.publish.goBackConfirmMessage(),
                () => {
                    this.onGoBack({});
                },
            );
        }
    }

    public goToNextStep(): void {
        const newStep = this.step + 1;
        if (PublishStep[newStep] !== undefined) {
            this.step = newStep;
            if (this.step === PublishStep.Confirm) {
                // Reset confirmation status when navigating to this page, in case the user went back and changed a value
                this.confirmed = false;
            }
        }
    }

    public onSelectAutomatedEmailCategory(automatedEmailCategory: FitwareSimpleTemplate): void {
        this.selected.automatedEmailCategory = automatedEmailCategory;
    }

    public onAutomatedEmailCategoryOptionsLoaded(options: FitwareSimpleTemplate[]): void {
        this.options.automatedEmailCategories = options; // Cache options once loaded, assuming the chosen locations don't change
    }

    public onSubmitSendPreview(): ng.IPromise<void> {
        if (this.fitwarePublishData.previewRecipient.trim()) {
            return this.sendPreviewEmail
                .run({
                    locationId: this.publishData.location.id,
                    previewRecipient: this.fitwarePublishData.previewRecipient,
                })
                .then(() => {
                    this.goToNextStep();
                });
        } else {
            this.goToNextStep();
        }
        return Promise.resolve();
    }

    public onSubmitConfirm(): void {
        this.commonData = this.getCommonDataForPublish();
        this.goToNextStep();
    }

    public finish: () => void = () => {
        this.$scope.$emit(
            this.builderConstants.EVENTS.PUBLISH_FINISH,
            new PublishResult(ExportPlatforms.Fitware, null),
        );
    };

    public publish: (
        locationId: LocationId,
        uploadId: UploadId,
        commonData: CommonPublishData,
    ) => PromiseLike<IPublishEmailResponseLegacy> = (
        locationId: LocationId,
        uploadId: UploadId,
        commonData: CommonPublishData,
    ) => {
        const requestBody: SendFitwareAutomatedEmailRequest = {
            auditData: {
                builderTemplateId: this.publishData.templateId,
                linkedAssetLibraryAssetIds: this.publishData.linkedAssetLibraryAssetIds,
                plannerId: this.publishData.plannerDetails ? this.publishData.plannerDetails.id : undefined,
            },
            channelData: {
                automatedEmailCode: commonData.automatedEmailGroupCode,
                builderDocument: commonData.builderDocument,
                subject: commonData.subject,
                textSubstitutionValues: commonData.textSubstitutionValues,
            },
            uploadId,
        };
        return this.socketService.startWorkflow(
            () => this.fitwarePublishService.sendAutomatedEmail(locationId, requestBody),
            EmailProcessingFinishedEvent,
            EmailProcessingFailedEvent,
            undefined,
            () => this.sentryService.captureException('Socket message timeout alert', {}),
        );
    };

    protected getCommonDataForPublish(): CommonPublishData {
        return {
            automatedEmailGroupCode: this.selected.automatedEmailCategory?.code ?? '',
            builderDocument: this.publishData.builderDocument,
            subject: this.fitwarePublishData.subject,
            textSubstitutionValues: this.publishData.textSubstitutionValues,
        };
    }
}

export const publishAutomatedEmailToFitwareSID = 'publishAutomatedEmailToFitware';

export const publishAutomatedEmailToFitwareConfig = actualComponent(
    PublishAutomatedEmailToFitwareCtrl,
    '/partials/contentBuilder/email/publish/fitware/publishAutomatedEmailToFitware',
    {
        automatedEmailCode: OptionalOneWayBinding,
        onGoBack: ExpressionBinding,
        publishData: OneWayBinding,
        subject: OptionalOneWayBinding,
    },
);

angular.module('app').component(publishAutomatedEmailToFitwareSID, publishAutomatedEmailToFitwareConfig);
