/// <reference path="../../../typings/browser.d.ts" />
import { Currency, Untyped } from '@deltasierra/shared';
import { $locationSID, $scopeSID } from '../common/angularData';
import { CurrencyService } from './currencyService';
import { PaymentData } from './mvPaymentService';

import IScope = angular.IScope;

interface MvPaymentsCtrlScope extends IScope {
    CURRENCIES: ReadonlyArray<Currency>;
    ewayPublicApiKey: string | null;
    paymentData: PaymentData;
    submitPayment(): void;
}

angular.module('app').controller('mvPaymentsCtrl', [
    $scopeSID,
    $locationSID,
    CurrencyService.SID,
    function ($scope: MvPaymentsCtrlScope, $location: ng.ILocationService, currencyService: CurrencyService) {
        $scope.CURRENCIES = currencyService.CURRENCIES;
        $scope.ewayPublicApiKey = null;

        function rectifyQueryParams(queryParams: Untyped) {
            if (queryParams.specialRequestId) {
                queryParams.specialRequestId = parseInt(queryParams, 10);
            }
            if (queryParams.amount) {
                queryParams.amount = parseInt(queryParams.amount, 10);
                queryParams.amount = currencyService.getLargestUnit(queryParams.currency, queryParams.amount);
            }
            return queryParams;
        }

        $scope.paymentData = angular.merge(
            {
                // Address isn't implemented yet.
                // Address: {
                // Line1: '',
                // Line2: '',
                // Suburb: '',
                // State: '',
                // Postcode: ''
                // },
                email: '',
                businessName: '',
                phoneNumber: '',
                amount: 0,
                currency: 'AUD',
                invoiceDescription: '',
                invoiceNumber: null,
            },
            rectifyQueryParams($location.search()),
        );

        $scope.submitPayment = function () {
            const paymentData = angular.copy($scope.paymentData);
            paymentData.amount = currencyService.getSmallestUnit(paymentData.currency, paymentData.amount);
            $location.path('/payments/confirm').search(paymentData);
        };
    },
]);
