/// <reference path="../../../typings/browser.d.ts" />
import { $resourceSID } from '../common/angularData';
import IResourceService = angular.resource.IResourceService;

angular.module('app').factory('mvTryThisTagResource', [
    $resourceSID,
    // eslint-disable-next-line func-names
    function ($resource : IResourceService) {
    const TryThisTagResource = $resource('/api/trythis/tags', {}, {
        update: {
            method: 'PUT',
            isArray: false,
        },
        delete: {
            method: 'DELETE',
            isArray: false,
        },
    });

    return TryThisTagResource;

},
]);
