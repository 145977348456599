import { FacebookPublishData, PublishMultiImageRequest } from '@deltasierra/shared';

import React from 'react';
import { useAngularServiceContext } from '../../../common/componentUtils/angularServiceContexts';
import { useImageProcessingContext } from '../common/hooks';
import { MvContentBuilderPublishCtrl } from '../mvContentBuilderPublishCtrl';
import { MultiImagePublishCallback, MultiImagePublishCallbackArgs } from '../types';

function usePublishPhotoCallback() {
    const facebookPublishService = useAngularServiceContext('facebookPublishService');
    return React.useCallback(
        async (input: PublishMultiImageRequest<FacebookPublishData>) => facebookPublishService.publishPhoto(input),
        [facebookPublishService],
    );
}

export function useFacebookImagePublishCallback(
    publishController: MvContentBuilderPublishCtrl,
): MultiImagePublishCallback<FacebookPublishData> {
    const i18nService = useAngularServiceContext('I18nService');
    const facebookPublishService = useAngularServiceContext('facebookPublishService');
    const imagePublishService = useAngularServiceContext('ImagePublishService');
    const identity = useAngularServiceContext('mvIdentity');
    const publishPhotoCallback = usePublishPhotoCallback();
    const ImageProcessingContext = useImageProcessingContext();
    const currentUser = identity.currentUser;

    return React.useCallback<MultiImagePublishCallback<FacebookPublishData>>(
        async (args: MultiImagePublishCallbackArgs<FacebookPublishData>) => {
            const { commonData, locationGraphqlId, locationId, publishedArtifactGroupId, uploadIds } = args;
            if (currentUser && await facebookPublishService.canPostToFacebookPageForLocation(locationId)) {
                const channelData: FacebookPublishData = {
                    description: commonData.description ?? undefined,
                    firstComment: commonData.firstComment,
                    scheduledTime: commonData.scheduledTime,
                };
                const scheduledTime = commonData.scheduledTime || undefined;
                return imagePublishService.publishMultiImage(
                    ImageProcessingContext.current,
                    locationGraphqlId,
                    uploadIds,
                    publishController.templateId,
                    publishController.plannerDetails || null,
                    channelData,
                    publishPhotoCallback,
                    publishController.contentBuilder.linkedAssetLibraryAsset.map(value => ({
                        layerId: value.layerId,
                        legacyAssetId: value.asset.id,
                    })),
                    publishedArtifactGroupId,
                    scheduledTime,
                );
            } else {
                throw new Error(i18nService.text.build.publish.facebook.accessCheckFailure());
            }
        },
        [
            ImageProcessingContext,
            currentUser,
            facebookPublishService,
            i18nService.text.build.publish.facebook,
            imagePublishService,
            publishController.contentBuilder.linkedAssetLibraryAsset,
            publishController.plannerDetails,
            publishController.templateId,
            publishPhotoCallback,
        ],
    );
}
