/// <reference path="../../_references.d.ts" />

import { Channel, Activity } from '@deltasierra/shared';

import { $modalSID } from '../../common/angularUIBootstrapData';
import { ModalService } from '../../typings/angularUIBootstrap/modalService';
import { ChannelMigratorController } from './controller';

export interface ChannelMigratorServiceOptions {
    channel: Channel;
    activities: Activity[];
}

export class ChannelMigratorService {
    static SID = 'ChannelMigratorService';

    static readonly $inject : string[] = [$modalSID];

    constructor(private $modal: ModalService) {
    }

    public showModal(options: ChannelMigratorServiceOptions) : ng.IPromise<number> {
        const modalInstance = this.$modal.open({
            templateUrl: 'partials/clients/channelMigrator/template.jade',
            controller: ChannelMigratorController,
            controllerAs: 'ctrl',
            resolve: {
                channel: () => options.channel,
                activities: () => options.activities,
            },
            animation: true,
            backdrop: 'static',
            keyboard: true,
        });

        return modalInstance.result;
    }
}

angular.module('app').service(ChannelMigratorService.SID, ChannelMigratorService);
