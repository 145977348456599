import * as React from 'react';
import { Upload } from '@deltasierra/shared';
import { EmailPublishData } from '../../../../../emailPublishData';
import { useAngularServiceContext } from '../../../../../../../common/componentUtils/angularServiceContexts';

export function useRectifyEmailImagesAndUploadHtml(): (publishData: EmailPublishData) => Promise<Upload> {
    const emailPublishService = useAngularServiceContext('EmailPublishService');

    return React.useCallback(
        async (publishData: EmailPublishData): Promise<Upload> =>
            (
                await emailPublishService.rectifyAndUpload(
                    publishData.builderDocument,
                    publishData.htmlDocument,
                    publishData.fileCache,
                )
            ).upload,
        [emailPublishService],
    );
}
