import * as React from 'react';
import { useRouter } from '../routes';

/**
 * Returns a safe way to redirect the user
 *
 * @returns The redirect function
 */
export function useSafeRedirect(): (to: string) => void {
    const [to, setTo] = React.useState<string | null>(null);
    const router = useRouter();

    React.useEffect(() => {
        let timer: NodeJS.Timeout | undefined;
        if (to !== null) {
            timer = setTimeout(() => {
                router.replace(to);
                setTo(null);
            }, 20);
            return () => {
                if (timer) {
                    clearTimeout(timer);
                }
            };
        }
    }, [router, to]);

    return setTo;
}
