import { MailchimpReportEntry } from '@deltasierra/shared';
import { compareDates, compareStrings } from '../compare';
import { ReportTable, StatsEntry, TextAlignClass } from '../reportTable/reportTable';
import { CreateReportTableOptions, locationColumn, numberColumn } from './common';

export function createMailchimpReportTable(
    options: CreateReportTableOptions,
): ReportTable<StatsEntry<MailchimpReportEntry>, MailchimpReportEntry> {
    const extractTextFromAnchorHtml = (html: string) => {
        const aElement = document.createElement('a');
        aElement.innerHTML = html;
        return aElement.innerText;
    };

    const compareCampaignNameLinks = (aLink: string, bLink: string) => {
        const aText = aLink.includes('href="') ? extractTextFromAnchorHtml(aLink) : aLink;
        const bText = bLink.includes('href="') ? extractTextFromAnchorHtml(bLink) : bLink;
        return compareStrings(aText, bText);
    };

    return new ReportTable<StatsEntry<MailchimpReportEntry>, MailchimpReportEntry>({
        columns: [
            locationColumn(options, {}),
            {
                compare: compareCampaignNameLinks,
                cssClass: TextAlignClass.Left,
                format: options.formatters.formatString,
                getTotal: totals => undefined,
                getValue: entry =>
                    entry.shareUrl ? `<a href="${entry.shareUrl}" target="_blank">${entry.campaignName}</a>` : entry.campaignName,
                label: () => options.i18n.text.report.campaign(),
            },
            {
                compare: compareDates,
                cssClass: TextAlignClass.Center,
                format: options.formatters.formatDate,
                getTotal: totals => undefined,
                getValue: entry => entry.sendDate,
                label: () => options.i18n.text.report.sentDate(),
            },
            numberColumn(options, {
                getTotal: totals => totals.recipientCount,
                getValue: entry => entry.recipientCount,
                label: () => options.i18n.text.report.recipients(),
            }),
            numberColumn(options, {
                getTotal: totals => totals.uniqueOpenedCount,
                getValue: entry => entry.uniqueOpenedCount,
                label: () => options.i18n.text.report.uniqueOpened(),
            }),
            numberColumn(options, {
                getTotal: totals => totals.clickCount,
                getValue: entry => entry.clickCount,
                label: () => options.i18n.text.report.clicks(),
            }),
            numberColumn(options, {
                getTotal: totals => totals.bounceCount,
                getValue: entry => entry.bounceCount,
                label: () => options.i18n.text.report.bounced(),
            }),
        ],
    });
}
