/// <reference path="../../../typings/browser.d.ts" />
import { $windowSID } from '../common/angularData';
import IWindowService = angular.IWindowService;

// Supported by Chrome, not others
// Interface NotificationAction {
//     Action : string;
//     Title : string;
// }

type BrowserNotificationPermission = 'default' | 'denied' | 'granted';

interface BrowserNotificationStatic {
    readonly permission : BrowserNotificationPermission;
    new(title : string, options? : BrowserNotificationOptions) : BrowserNotification;
    requestPermission() : PromiseLike<BrowserNotificationPermission>;
}

interface BrowserNotificationOptions {
    body? : string; // All browsers
    dir?: 'auto' | 'ltr' | 'rtl';
    lang? : string;
    tag? : string; // Basically a group ID; will replace existing notifications with the same "tag" value.
    icon? : string; // URL
    image? : string; // URL
    requireInteraction? : boolean;
}

export interface ExtendedBrowserNotificationOptions extends BrowserNotificationOptions {
    clickHandler? : (event : MouseEvent, notification : BrowserNotification) => any;
}

export interface BrowserNotification {
    readonly body : string; // All browsers
    readonly dir: 'auto' | 'ltr' | 'rtl';
    readonly lang : string;
    readonly tag : string; // Basically a group ID; will replace existing notifications with the same "tag" value.
    readonly icon : string; // URL
    readonly image : string; // URL
    readonly requireInteraction : boolean;
    readonly title : string;
    onclick? : (event : MouseEvent) => any;
    onerror? : (event : ErrorEvent) => any;

    close() : void;
}

// Interface ChromeBrowserNotification extends BrowserNotification {
//     Readonly actions : NotificationAction[]; // Chrome
//     Readonly badge : string; // Chrome. URL
//     Readonly data : any; // Chrome
//     Readonly silent : boolean;
//     Readonly timestamp : number;
//     Readonly vibrate : number;
// }

export class BrowserNotificationService {
    static SID = 'BrowserNotificationService';

    protected Notification : BrowserNotificationStatic | undefined;

    static readonly $inject : string[] = [
        $windowSID,
    ];

    constructor(
        protected readonly $window : IWindowService,
    ) {
        void this.$onInit();
    }

    $onInit() {
        try {
            this.Notification = this.$window.Notification;
        } catch (err) {
            return; // Swallow errors if Notification does not exist on the window object
        }
        if (this.Notification && this.Notification.permission == 'default') {
            return this.Notification.requestPermission();
        }
    }

    isEnabled() {
        return this.Notification && this.Notification.permission == 'granted';
    }

    notify(title : string, options? : ExtendedBrowserNotificationOptions) : boolean {
        if (this.isEnabled()) {
            const notification = new this.Notification!(title, options);
            if (options && options.clickHandler) {
                const localClickHandler = options.clickHandler;
                notification.onclick = (event : MouseEvent) => {
                    localClickHandler(event, notification);
                    try {
                        if (this.$window.focus) {
                            this.$window.focus();
                        }
                    } catch (err) {
                        // Do nothing
                    }
                    notification.close();
                };
            }
            return true;
        } else {
            return false;
        }
    }
}

angular.module('app').service(BrowserNotificationService.SID, BrowserNotificationService);
