import { gql } from '@apollo/client';

export const CLUBREADY_CHAIN_ID_CONFIGURATION_TABLE_FRAGMENT = gql`
    fragment ClubReadyChainIdConfigurationTableFragment on Client {
        id
        title
        integrationConnection(names: ["clubReady"]) {
            edges {
                node {
                    id
                    name
                }
            }
        }
    }
`;
