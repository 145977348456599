import { gql } from '@apollo/client';

export const ADMIN_SAVE_CLUB_READY_CLIENT_CONFIG_FOR_DIALOG = gql`
    mutation AdminSaveClubReadyClientConfigForDialog($id: ID!, $input: SaveClubReadyClientConfigurationInput!) {
        saveClubReadyClientConfiguration(id: $id, input: $input) {
            __typename
            ... on ClubReadyClientConfigurationInvalidError {
                code
                message
            }
            ... on NoIntegrationConnection {
                reason
            }
            ... on IntegrationConnection {
                id
                configuration {
                    __typename
                    ... on ClubReadyClientConfiguration {
                        id
                        chainId
                    }
                    ... on ClubReadyLocationConfiguration {
                        id
                        clubId
                    }
                }
                status {
                    __typename
                    ... on IntegrationConnectionStatusConnected {
                        since
                    }
                    ... on IntegrationConnectionStatusConnectionError {
                        since
                    }
                }
            }
        }
    }
`;
