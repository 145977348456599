/// <reference path="../../../../typings/browser.d.ts" />
import {
    FacebookPublishApi,
    FacebookPublishData,
    justParams,
    LocationId,
    paramsAndBody,
    PublishMultiImageRequest,
    PublishVideoToFacebookRequest,
    WorkflowStartResult,
} from '@deltasierra/shared';
import { $httpSID } from '../../common/angularData';
import { invokeApiRoute } from '../../common/httpUtils';
import { SentryService } from '../../common/sentryService';
import { GraphqlService } from '../../graphql/GraphqlService';
import { PUBLISH_FACEBOOK_IMAGE } from './publish-facebook-image.mutation';
import { PublishFacebookImage, PublishFacebookImageVariables } from './__graphqlTypes/PublishFacebookImage';
import IHttpService = angular.IHttpService;
import IPromise = angular.IPromise;

export class FacebookPublishService {
    public static SID = 'facebookPublishService';

    public static readonly $inject: string[] = [$httpSID, GraphqlService.SID, SentryService.SID];

    public constructor(
        private $http: IHttpService,
        private readonly graphqlService: GraphqlService,
        private readonly sentryService: SentryService,
    ) {}

    public async publishPhoto(input: PublishMultiImageRequest<FacebookPublishData>): Promise<WorkflowStartResult> {
        const gqlClient = this.graphqlService.getClient();
        const result = await gqlClient.mutate<PublishFacebookImage, PublishFacebookImageVariables>({
            mutation: PUBLISH_FACEBOOK_IMAGE,
            variables: {
                input: {
                    ...input,
                    channelData: {
                        description: input.channelData.description,
                        firstComment: input.channelData.firstComment,
                    },
                    scheduledTime: input.scheduledTime?.toISOString(),
                },
            },
        });
        if (result.data?.publishFacebookImage.__typename === 'PublishError' || !result.data) {
            this.sentryService.captureException(
                'Failed to publish image to Facebook via "PUBLISH_FACEBOOK_IMAGE" GraphQL query.',
                {
                    errors:
                        result.data?.publishFacebookImage.__typename === 'PublishError'
                            ? result.data.publishFacebookImage.message
                            : undefined,
                },
            );
            throw new Error('Failed to publish image to Facebook');
        } else {
            return {
                executionArn: result.data.publishFacebookImage.workflow.id,
                executionId: result.data.publishFacebookImage.workflow.executionId,
                startDate: new Date(result.data.publishFacebookImage.workflow.startDate),
            };
        }
    }

    public publishVideo(
        locationId: LocationId,
        requestBody: PublishVideoToFacebookRequest,
    ): IPromise<WorkflowStartResult> {
        return invokeApiRoute(
            this.$http,
            FacebookPublishApi.publishVideo,
            paramsAndBody(
                {
                    locationId,
                },
                requestBody,
            ),
        );
    }

    public canPostToFacebookPageForLocation(locationId: LocationId): IPromise<boolean> {
        return invokeApiRoute(
            this.$http,
            FacebookPublishApi.canPostToFacebookPageForLocation,
            justParams({
                locationId,
            }),
        ).then(result => result.result);
    }
}

angular.module('app').service('facebookPublishService', FacebookPublishService);
