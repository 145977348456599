/// <reference path="../../../../typings/browser.d.ts" />
import { ProfilePictureController } from './controller';

export const SID = 'dsProfilePicture';

export function ProfilePictureDirective(): ng.IDirective<ng.IScope> {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            user: '<',
            quality: '@',
        },
        templateUrl: '/partials/account/profilePicture/template',
        controller: ProfilePictureController,
        controllerAs: 'ctrl',
        bindToController: true,
    };
}

angular.module('app').directive(SID, [ProfilePictureDirective]);
