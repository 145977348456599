import * as React from 'react';
import { NotifierDispatchNotificationContext, NotifierDispatchNotificationContextType } from '../contexts';

/**
 * Gets the notifier from the context
 *
 * @returns The notifier with its functions
 */
export function useNotifierDispatchNotification(): Exclude<NotifierDispatchNotificationContextType, undefined> {
    const dispatch = React.useContext(NotifierDispatchNotificationContext);
    if (!dispatch) {
        throw new Error(
            'The <NotifierDispatchNotificationProvider /> component needs to be added above this component the component tree',
        );
    }
    return dispatch;
}
