import { gql } from '@apollo/client';

export const CLIENT_FRAGMENT_FOR_COPY_TEMPLATE_MODAL = gql`
    fragment ClientFragmentForCopyTemplateModal on Client {
        id
        title
        legacyId
        brand {
            id
            title
            logo { 
                id
                filename
                thumb256x256url
            }
        }
    }
`;

export const GET_CLIENTS_FOR_COPY_TEMPLATE_MODAL_QUERY = gql`
    query GetClientsForCopyTemplateModal($after: String, $first: Int, $title: String, $brandId: ID!) {
     	 brand(id: $brandId) {
            id
            title
            clients(after: $after, first: $first, input: { filter: { title: $title } }) {
                edges {
                    node {
                        id
                        ...ClientFragmentForCopyTemplateModal
                    }
                }
                pageInfo {
                    hasNextPage
                    endCursor
                }
            }
        }
    }
    ${CLIENT_FRAGMENT_FOR_COPY_TEMPLATE_MODAL}
`;

export const GET_CURRENT_CLIENT_FOR_COPY_TEMPLATE_MODAL_QUERY = gql`
    query GetCurrentClientForCopyTemplateModal($id: ID!) {
        client(id: $id) {
            id
            ...ClientFragmentForCopyTemplateModal
        }
    }
    ${CLIENT_FRAGMENT_FOR_COPY_TEMPLATE_MODAL}
`;

export const GET_LOCATION_FOR_COPY_TEMPLATE_MODAL_QUERY = gql`
    query GetLocationForCopyTemplateModal($locationId: ID!, $hasLocation: Boolean! = false) {
        me {
            id
        }
        hasCurrentLocation @client @export(as: "hasLocation")
        currentLocationId @client @export(as: "locationId")
        location(id: $locationId) @include(if: $hasLocation) {
            id
            client {
                id
            }
        }
    }
`;