/// <reference path="../../../typings/browser.d.ts" />
import { Untyped } from '@deltasierra/shared';
import moment from 'moment-timezone';

angular.module('app').filter('outstandingPlanners', [
    function outstandingPlannersFilter() {
        return function outstandingPlanners(planners: Untyped) {
            const out = [];
            for (const planner of planners) {
                if (planner.isSupported && !planner.contentSupplied) {
                    out.push(planner);
                }
            }
            return out;
        };
    },
]);

export function iif<TInput, TTrue = true, TFalse = false>(
    input: TInput,
    trueValue: TTrue,
    falseValue: TFalse,
): typeof falseValue | typeof trueValue {
    return input ? trueValue : falseValue;
}

angular.module('app').filter('iif', [
    function iifFilter() {
        return iif;
    },
]);

export type Datetz = (date: Date, format: string, timezone: string) => string;

export const datetz: Datetz = (date: Date, format: string, timezone: string) =>
    moment(date).tz(timezone).format(format);

angular.module('app').filter('datetz', [
    function datetzFilter(): Datetz {
        return datetz;
    },
]);

angular.module('app').filter('percentage', [
    '$filter',
    ($filter: ng.IFilterService) =>
        // Decimals is the number of decimal places to show
        // IsFloat is whether the input is a decimal percentage (e.g. 0.255 = 25.5%) or
        // Not (25.5 = 25.5%). Defaults to true
        (input: number, decimals?: number, isFloat: boolean | string = true) => {
            // Have to do this stupid check because of how countup directive passes params to filters as strings 🙄
            if (isFloat === 'false') {
                // eslint-disable-next-line no-param-reassign
                isFloat = false;
            }
            return `${$filter('number')(input * (isFloat ? 100 : 1), decimals)}%`;
        },
]);
