import { AssignedLocation, LocationIdHierarchy, Upload, UploadId, AutomaticTextPlaceholders, useFallbackWhenUndefinedOrNull, PublishedArtifactGroupId, BuilderTemplateType } from '@deltasierra/shared';
import { FeatureFlag } from '@deltasierra/features/feature-flags/core';

import type { IPromise, IQService, IScope, IQResolveReject } from 'angular';

import { MvNotifier } from '../../../common/mvNotifier';
import { MultipleLocationActionProcessor } from '../../../locations/MultipleLocationActionProcessor';
import { I18nService } from '../../../i18n';
import { BuilderConstants } from '../../builderConstants';
import { UploadContext } from '../../../common/uploadService';
import { MvLocation } from '../../../locations/mvLocation';
import { PublishCallback, PublishFinishCallback } from '../types';
import { GraphqlService } from '../../../graphql/GraphqlService';
import { createPostArtifactGroup } from '../publishGroup';
import { GET_CUSTOM_MERGE_FIELDS } from '../../GetCustomMergeFields.query';
import { GetCustomMergeFields } from '../../__graphqlTypes/GetCustomMergeFields';

export abstract class BaseMultipleLocationPublishCtrl {
    public publishCallback!: () => PublishCallback<any>;

    public originalLocation!: AssignedLocation;

    public chosenLocations!: LocationIdHierarchy[];

    public commonData: any;

    public finishCallback!: () => PublishFinishCallback;

    public processor?: MultipleLocationActionProcessor;

    public uploadId: UploadId | null = null;

    public uploadContext?: UploadContext;

    protected publishedArtifactGroupId: PublishedArtifactGroupId | null = null;

    // eslint-disable-next-line max-params
    protected constructor(
        protected readonly $scope: IScope,
        protected readonly $q: IQService,
        protected readonly $timeout: ng.ITimeoutService,
        protected readonly mvNotifier: MvNotifier,
        protected readonly builderConstants: BuilderConstants,
        protected readonly i18n: I18nService,
        protected readonly mvLocation: MvLocation,
        protected readonly graphqlService: GraphqlService,
    ) {}

    public cancel(): void {
        this.$scope.$emit(this.builderConstants.EVENTS.PUBLISH_CANCEL);
    }

    protected async start(): Promise<void> {
        const result = await createPostArtifactGroup(this.graphqlService);
        this.publishedArtifactGroupId = PublishedArtifactGroupId.from(result.group.legacyId);
        this.chosenLocations.sort((locationA, locationB) =>
            locationA.locationTitle.localeCompare(locationB.locationTitle),
        );

        this.processor = new MultipleLocationActionProcessor(this.$q, this.i18n, this.$timeout, this.chosenLocations, {
            action: this.onAction.bind(this),
            failure: this.onFailure.bind(this),
            partialSuccess: this.onPartialSuccess.bind(this),
            success: this.onSuccess.bind(this),
        });
        await this.processor.start();
    }

    protected async onAction(location: LocationIdHierarchy): Promise<void> {
        const newUploadId = await this.uploadArtifactOrUseExisting(this.uploadId, location);
        await this.publishCallback()(location.locationId, newUploadId, this.commonData, this.publishedArtifactGroupId);
    }

    protected async onSuccess(): Promise<void> {
        // Reset to original location.
        await this.updateSubstitutionValuesForLocation(this.originalLocation as any);
        this.mvNotifier.notify(this.i18n.text.build.publish.published());
        this.finishCallback()();
    }

    protected onFailure(): void {
        this.mvNotifier.expectedError(this.i18n.text.build.publishingFailed());
        this.cancel();
    }

    protected onPartialSuccess(): void {
        this.mvNotifier.notify(this.i18n.text.build.publish.publishedToSomeLocations());
        this.finishCallback()();
    }

    protected async updateTextSubstitutionValuesForLocation(location: AssignedLocation, templateType?: BuilderTemplateType): Promise<void> {
        let customMergeFields: Array<{ key: string; value: string; }> = [];

        const clientFeatures = new Set(location.clientFeatures);

        if(clientFeatures.has(FeatureFlag.CUSTOM_MERGE_FIELDS) && templateType) {
            const gqlClient = this.graphqlService.getClient();

            const { data } = await gqlClient.query<GetCustomMergeFields>({
                fetchPolicy: 'cache-first',
                notifyOnNetworkStatusChange: true,
                query: GET_CUSTOM_MERGE_FIELDS,
                variables: { locationId: location.graphqlId, templateType },
            });

            customMergeFields = [...data?.location?.buildableTemplateCustomMergeFields ?? []];
        }

        // eslint-disable-next-line max-statements
        return this.$q((resolve: IQResolveReject<void>, reject: IQResolveReject<{ reason: string }>) => {
            try {
                const locationName = useFallbackWhenUndefinedOrNull(location.displayName, location.title);
                this.setTextSubstitution(AutomaticTextPlaceholders.Location, locationName);

                const reasons = [];
                if (location.phoneNumber) {
                    this.setTextSubstitution(AutomaticTextPlaceholders.Phone, location.phoneNumber);
                } else if (this.hasTextSubstitutionContent(AutomaticTextPlaceholders.Phone)) {
                    // Means that the phone number was not specified and is needed to publish
                    reasons.push(this.i18n.text.build.phoneNumberNotSet());
                }
                if (location.locationEmail) {
                    this.setTextSubstitution(AutomaticTextPlaceholders.Email, location.locationEmail);
                } else if (this.hasTextSubstitutionContent(AutomaticTextPlaceholders.Email)) {
                    // Means that the location email was not specified and is needed to publish
                    reasons.push(this.i18n.text.build.locationEmailNotSet());
                }
                if (location.websiteUrl) {
                    this.setTextSubstitution(AutomaticTextPlaceholders.Website, location.websiteUrl);
                } else if (this.hasTextSubstitutionContent(AutomaticTextPlaceholders.Website)) {
                    // Means that the website URL was not specified and is needed to publish
                    reasons.push(this.i18n.text.build.websiteUrlNotSet());
                }
                if (location.addressLineOne || location.addressLineTwo || location.addressLineThree) {
                    const address = [
                        location.addressLineOne,
                        location.addressLineTwo,
                        location.addressLineThree,
                    ].filter(xs => xs);
                    this.setTextSubstitution(AutomaticTextPlaceholders.Address, address.join('\n'));
                    this.setTextSubstitution(AutomaticTextPlaceholders.AddressSingleLine, address.join(', '));
                } else if (
                    this.hasTextSubstitutionContent(
                        AutomaticTextPlaceholders.Address,
                        AutomaticTextPlaceholders.AddressSingleLine,
                    )
                ) {
                    reasons.push(this.i18n.text.build.addressNotSet());
                }

                if (reasons.length) {
                    return reject({ reason: reasons.join('\n') });
                }

                customMergeFields.forEach(
                    customMergeField => {
                        this.setTextSubstitution(customMergeField.key, customMergeField.value);
                    },
                );

                return resolve();
            } catch (err) {
                return reject({ reason: err });
            }
        });
    }

    protected async uploadArtifactOrUseExisting(
        uploadId: UploadId | null,
        locationIdHierarchy: LocationIdHierarchy,
    ): Promise<UploadId> {
        if (!uploadId || this.hasSubstitutionContent()) {
            const location = await this.mvLocation.getAssignedLocation(locationIdHierarchy.locationId);
            await this.updateSubstitutionValuesForLocation(location);
            const { id }: Upload = await this.uploadArtifact();
            return id;
        } else {
            return this.$q.when(uploadId);
        }
    }

    protected abstract hasSubstitutionContent(): boolean;

    protected abstract hasTextSubstitutionContent(...keys: AutomaticTextPlaceholders[]): boolean;

    protected abstract setTextSubstitution(key: string, value: string): void;

    protected abstract updateSubstitutionValuesForLocation(location: AssignedLocation): IPromise<void>;

    protected abstract uploadArtifact(): IPromise<Upload>;
}
