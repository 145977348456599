import { AssignedLocation, LocationIdHierarchy } from '@deltasierra/shared';
import { useAngularComponentRenderer, useAngularScope } from '../../../common/componentUtils/angularRendering';
import { useAngularServiceContext } from '../../../common/componentUtils/angularServiceContexts';
import { ContentBuilder } from '../../contentBuilder';
import { CommonDataType } from '../common/common-data-type';
import { multipleLocationMultiImagePublishConfig } from '../multipleLocationPublish/multiImageDirective';
import { MvContentBuilderPublishCtrl } from '../mvContentBuilderPublishCtrl';
import { MultiImagePublishCallback, PublishFinishCallback } from '../types';
import { MvMultipleLocationMultiImagePublishCtrl } from './multiImagePublishCtrl';

export type MultipleLocationMultiImagePublishProps<CommonData extends CommonDataType> = {
    commonData: CommonData;
    contentBuilder: ContentBuilder;
    locations: LocationIdHierarchy[];
    onCancel: () => void;
    onFinish: PublishFinishCallback;
    originalLocation: AssignedLocation;
    publishMultiImageCallback: MultiImagePublishCallback<CommonData>;
    publishCtrl: MvContentBuilderPublishCtrl;
};

export function MultipleLocationMultiImagePublish<CommonData extends CommonDataType>(
    props: MultipleLocationMultiImagePublishProps<CommonData>,
): JSX.Element {
    const builderConstants = useAngularServiceContext('builderConstants');
    const scope = useAngularScope<{
        [K in keyof typeof multipleLocationMultiImagePublishConfig.bindToController]: MvMultipleLocationMultiImagePublishCtrl[K];
    }>(
        {
            chosenLocations: props.locations,
            commonData: props.commonData,
            contentBuilder: props.contentBuilder,
            finishCallback: props.onFinish as any,
            originalLocation: props.originalLocation,
            publishCtrl: props.publishCtrl,
            publishMultiImageCallback: props.publishMultiImageCallback as any,
        },
        {},
        {
            [builderConstants.EVENTS.PUBLISH_CANCEL]: props.onCancel,
        },
    );
    return useAngularComponentRenderer(
        '<multiple-location-multi-image-publish' +
            ' publish-ctrl="publishCtrl"' +
            ' content-builder="contentBuilder"' +
            ' original-location="originalLocation"' +
            ' chosen-locations="chosenLocations"' +
            ' common-data="commonData"' +
            ' publish-multi-image-callback="publishMultiImageCallback"' +
            ' finish-callback="finishCallback"' +
            '></multiple-location-publish>',
        scope,
    );
}
MultipleLocationMultiImagePublish.displayName = 'MultipleLocationMultiImagePublish';
