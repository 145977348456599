/// <reference path="../../../typings/browser.d.ts" />
import { Tag } from '@deltasierra/shared';
import { $resourceSID } from '../common/angularData';
import IResourceService = angular.resource.IResourceService;

export const tagResourceSID = 'tagResource';

export interface TagResourceInstance extends ng.resource.IResource<TagResource>, Tag {}

export interface TagResource extends ng.resource.IResourceClass<TagResource> {
    saveAll(tags: string[]): ng.resource.IResource<TagResource> & Tag[];
}

angular.module('app').factory(tagResourceSID, [
    $resourceSID,
    function ($resource: IResourceService) {
        const tagResource = $resource(
            '/api/tags/:id',
            { id: '@id' },
            {
                saveAll: {
                    method: 'POST',
                    isArray: true,
                    params: {},
                },
            },
        );

        return tagResource;
    },
]);
