import { Translate } from '@deltasierra/components';
import { isRecordType } from '@deltasierra/type-utilities';
import * as Sentry from '@sentry/browser';
import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { tryExtractMessage } from '../../../../exceptions';
import { NotifierInstanceContext } from '../../contexts';
import { useNotifierDispatchNotification } from '../../hooks';
import { Notifier, NotifierErrorOptions } from '../../notifier';
import { NotifierToastError, NotifierToastSuccess } from '../../NotifierToast';

const SENTRY_DEFAULT_FINGERPRINT = '{{ default }}';

const ERROR_MESSAGE_TIMEOUT = 30_000;
const ERROR_MESSAGE_EXTEND_TIMEOUT = 60_000;

class CaughtByNotifierV2Error extends Error {}

function shouldBypassLoggingForError(error: unknown): boolean {
    return isRecordType(error) && error.bypassLogging === true;
}

function getMessageForError(error: unknown): string {
    return tryExtractMessage(error);
}

export type NotifierInstanceProviderProps = {
    children: React.ReactNode;
};

export function NotifierInstanceProvider({ children }: NotifierInstanceProviderProps): JSX.Element {
    const dispatchNotification = useNotifierDispatchNotification();

    const logErrorToSentry = React.useCallback(
        (id: string, error: unknown, message: string | undefined, { isErrorSeverity = true }: NotifierErrorOptions) => {
            const shouldLogAsInfo = !isErrorSeverity || shouldBypassLoggingForError(error);

            const infoErrorMessage = message ?? getMessageForError(error);
            const errorToLog = error instanceof Error ? error : new CaughtByNotifierV2Error(infoErrorMessage);

            Sentry.withScope(scope => {
                scope.setFingerprint([SENTRY_DEFAULT_FINGERPRINT]);
                scope.setTag('exceptionId', id);
                scope.setTag('errorMessage', message);
                // If we want to bypass the logging, instead just capture the message
                if (shouldLogAsInfo) {
                    // Capture the error message as an info
                    Sentry.captureMessage(infoErrorMessage, {
                        level: Sentry.Severity.Info,
                    });
                } else {
                    Sentry.captureException(errorToLog, {
                        level: Sentry.Severity.Error,
                    });
                }
            });
        },
        [],
    );

    const notifier: Notifier = React.useMemo(
        () => ({
            error(message, options) {
                dispatchNotification(props => <NotifierToastError {...props}>{message}</NotifierToastError>, {
                    timeoutMs: 10_000,
                    ...options,
                });
            },
            handledError(message, error, options = {}) {
                const { isErrorSeverity = false, ...otherOptions } = options ?? {};
                const errorId = uuidv4();

                logErrorToSentry(errorId, error, message, { isErrorSeverity });
                dispatchNotification(props => <NotifierToastError {...props}>{message}</NotifierToastError>, {
                    extendTimeoutMs: ERROR_MESSAGE_EXTEND_TIMEOUT,
                    timeoutMs: ERROR_MESSAGE_TIMEOUT,
                    ...otherOptions,
                });
            },
            success(message, options) {
                dispatchNotification(
                    props => <NotifierToastSuccess {...props}>{message}</NotifierToastSuccess>,
                    options,
                );
            },
            unexpectedError(message, error, options = {}) {
                const { isErrorSeverity, ...otherOptions } = options ?? {};
                const errorId = uuidv4();

                logErrorToSentry(errorId, error, message, { isErrorSeverity });
                dispatchNotification(
                    props => (
                        <NotifierToastError defaultClickAsDismiss={false} errorId={errorId} {...props}>
                            {message}
                        </NotifierToastError>
                    ),
                    {
                        extendTimeoutMs: ERROR_MESSAGE_EXTEND_TIMEOUT,
                        timeoutMs: ERROR_MESSAGE_TIMEOUT,
                        ...otherOptions,
                    },
                );
            },
            unhandledError(error, options) {
                const { isErrorSeverity, ...otherOptions } = options ?? {};
                const errorId = uuidv4();

                logErrorToSentry(errorId, error, undefined, { isErrorSeverity });
                dispatchNotification(
                    props => (
                        <NotifierToastError defaultClickAsDismiss={false} errorId={errorId} {...props}>
                            <Translate keyId="ERRORS.UNEXPECTED_ERROR" />
                        </NotifierToastError>
                    ),
                    {
                        extendTimeoutMs: ERROR_MESSAGE_EXTEND_TIMEOUT,
                        timeoutMs: ERROR_MESSAGE_TIMEOUT,
                        ...otherOptions,
                    },
                );
            },
        }),
        [dispatchNotification, logErrorToSentry],
    );

    return <NotifierInstanceContext.Provider value={notifier}>{children}</NotifierInstanceContext.Provider>;
}
NotifierInstanceProvider.displayName = 'NotifierProvider';
