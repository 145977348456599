/// <reference path="../../../typings/browser.d.ts" />
import {
    CURRENCIES,
    Currency,
    CURRENCY_USD,
    findCurrency,
    isZeroDecimal,
} from '@deltasierra/shared';

export class CurrencyService {
    static SID = 'currencyService';

    CURRENCIES = CURRENCIES;

    static readonly $inject: string[] = [];

    findCurrency(code: string): Currency | null {
        return findCurrency(code);
    }

    findCurrencyWithDefault(code: string, defaultCurrency: Currency = CURRENCY_USD): Currency {
        return this.findCurrency(code) || defaultCurrency;
    }

    isZeroDecimal(code: string): boolean {
        return isZeroDecimal(code);
    }

    getSmallestUnit(code: string, amount: number) {
        if (!this.isZeroDecimal(code)) {
            return Math.round(amount * 100);
        } else {
            return Math.round(amount);
        }
    }

    getLargestUnit(code: string, amount: number) {
        if (!this.isZeroDecimal(code)) {
            return amount / 100;
        } else {
            return amount;
        }
    }
}

angular.module('app').service(CurrencyService.SID, CurrencyService);
