/// <reference path="../../../../typings/browser.d.ts" />

import { OptionalExpressionBinding, OptionalTwoWayBinding, TwoWayBinding } from '../../common/angularData';
import { TemplateThumbnailController } from './controller';

export const dsTemplateThumbnailSID = 'dsTemplateThumbnail';

export function TemplateThumbnailDirective() : ng.IDirective<ng.IScope> {
    return {
        templateUrl: '/partials/contentBuilder/templateThumbnailDirective/template',
        restrict: 'E',
        scope: {
            template: TwoWayBinding,
            isFaded: OptionalTwoWayBinding,
            plannerId: OptionalTwoWayBinding,
            tagClick: OptionalExpressionBinding,
            showMenu: OptionalExpressionBinding,
            linkToTemplate: OptionalExpressionBinding,
        },
        controller: TemplateThumbnailController,
        controllerAs: 'ctrl',
        bindToController: true,
        replace: true,
    };
}
