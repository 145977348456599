import { PublishToClubReadyStep1FormValuesProspectsOptions } from '../../PublishToClubReadyStep1';
import { ClubReadyDateRangeFilterOption } from '../../../../../../../../../__graphqlTypes/globalTypes';

export function getAddedBetweenRange(
    selection: PublishToClubReadyStep1FormValuesProspectsOptions,
): ClubReadyDateRangeFilterOption | null {
    if (selection.listChoice === 'addedBetween' && selection.from && selection.to) {
        return { from: selection.from, to: selection.to };
    } else {
        return null;
    }
}
