import { gql } from '@apollo/client';

export const GET_BUILDABLE_TEMPLATES = gql`
    query GetBuildableTemplates(
        $clientId: ID!,
        $filters: BuildableTemplateFilterInput
        $first: Int,
        $after: String
    ) {
        client(id: $clientId) {
            id
            buildableTemplates(filters: $filters, first: $first, after: $after) {
                edges {
                    node {
                        id
                        title
                        status
                        templateType
                        categories {
                            id
                            title
                        }
                        tags {
                            id
                            title
                        }
                        currentVersion {
                            id
                            thumbnailUrl
                            createdAt
                        }
                    }
                }
                pageInfo {
                    hasNextPage
                    endCursor
                }
            }
        }
    }
`;
