/// <reference path="../../../../typings/browser.d.ts" />
import {
    IPublishVideoToLocalRequest,
    LocalPublishApi,
    LocationId,
    paramsAndBody,
    PublishedArtifact,
    PublishImageToLocalRequest,
    WorkflowStartResult,
} from '@deltasierra/shared';
import { $httpSID } from '../../common/angularData';
import { invokeApiRoute } from '../../common/httpUtils';
import IHttpService = angular.IHttpService;
import IPromise = angular.IPromise;

export class LocalPublishService {
    public static SID = 'localPublishService';

    public static readonly $inject: string[] = [$httpSID];

    public constructor(private $http: IHttpService) {}

    public publishLocal(locationId: LocationId, options: PublishImageToLocalRequest): IPromise<PublishedArtifact> {
        return invokeApiRoute(
            this.$http,
            LocalPublishApi.publishLocal,
            paramsAndBody(
                {
                    locationId,
                },
                options,
            ),
        );
    }

    public publishVideo(
        locationId: LocationId,
        requestBody: IPublishVideoToLocalRequest,
    ): IPromise<WorkflowStartResult> {
        return invokeApiRoute(
            this.$http,
            LocalPublishApi.publishVideo,
            paramsAndBody(
                {
                    locationId,
                },
                requestBody,
            ),
        );
    }
}

angular.module('app').service(LocalPublishService.SID, LocalPublishService);
