/// <reference path="../../../../typings/browser.d.ts" />
import { RestrictToAttribute } from '../../common/angularData';
import { EmailTemplateIframeScope } from './emailTemplateIframeDirective';

export const hoverableEmailSectionSID = 'hoverableEmailSection';

export const hoverableEmailSectionConfig: ng.IDirective<ng.IScope> = {
    restrict: RestrictToAttribute,
};

interface HoverableEmailSectionScope extends EmailTemplateIframeScope {

}

angular.module('app').directive(hoverableEmailSectionSID, [(): ng.IDirective<HoverableEmailSectionScope> => ({
        ...hoverableEmailSectionConfig,
        link: (scope: HoverableEmailSectionScope, element, attrs) => {
            const sectionId = parseInt(attrs[hoverableEmailSectionSID], 10);
            element.on('mouseenter', event => {
                scope.$apply(() => scope.onSectionHoverEnter(sectionId));
            });
            element.on('mouseleave', event => {
                scope.$apply(() => scope.onSectionHoverLeave(sectionId));
            });
            element.on('click', event => {
                scope.$apply(() => scope.onSectionClick(sectionId));
            });
        },
    })]);
