
/// <reference path="../../../../typings/browser.d.ts" />
import {
    AssignedLocation,
    Client,
    ClientId,
    HasId,
    LocationId,
    t,
    TextSubstitutionField,
    Untyped,
    Upload,
    UploadCategory,
} from '@deltasierra/shared';
import { MvIdentity } from '../../account/mvIdentity';
import { MvClient } from '../../clients/mvClient';
import { $qSID } from '../../common/angularData';
import { $modalSID } from '../../common/angularUIBootstrapData';
import { MvNotifier } from '../../common/mvNotifier';
import { UploadContext, UploadMap, UploadScope, UploadService } from '../../common/uploadService';
import { MvLocation } from '../../locations/mvLocation';
import { ModalService } from '../../typings/angularUIBootstrap/modalService';
import { EmailBuilderCtrlScope } from '../email/mvEmailBuilderCtrl';
import { FileCache } from '../imageLoaderService';
import { ContentBuilderCtrlScope } from '../mvContentBuilderCtrl';
import IPromise = angular.IPromise;
import IScope = angular.IScope;
import IQService = angular.IQService;

export interface UploadResourcesScope extends IScope {
    stage?: number;
    totalStages?: number;
    totalImagesToUpload?: number;
    imageUploads?: Upload[];
    uploadContext?: UploadContext;
}

interface ShowModalAndUploadResourcesScope extends IScope {
    isSaving: boolean;
    fileCache: FileCache;
}

export interface ShowModalAndUploadResourcesResult {
    cleanup: () => void;
    promise: IPromise<UploadMap>;
    scope: UploadResourcesScope;
}

export interface CurrentTextSubstitutionField extends TextSubstitutionField {
    original?: TextSubstitutionField;
}

export interface TextSubstitutionScopeMixins {
    currentTextSubstitutionField: CurrentTextSubstitutionField | null;
    newTextSubstitutionField?: () => void;
    editTextSubstitutionField(field: TextSubstitutionField): void;
    deleteTextSubstitutionField(): void;
    saveTextSubstitutionField(): void;
    cancelEditingTextSubstitutionField(): void;
    addTextSubstitutionField(): void;
}

type MixinableScope = ContentBuilderCtrlScope | EmailBuilderCtrlScope;

export class BuilderCommonService {
    public static SID = 'builderCommonService';

    public static readonly $inject: string[] = [
        $qSID,
        $modalSID,
        MvNotifier.SID,
        MvLocation.SID,
        MvIdentity.SID,
        MvClient.SID,
        UploadService.SID,
    ];


    public constructor(
        private readonly $q: IQService,
        private readonly $modal: ModalService,
        private readonly mvNotifier: MvNotifier,
        private readonly mvLocation: MvLocation,
        private readonly mvIdentity: MvIdentity,
        private readonly mvClient: MvClient,
        private readonly uploadService: UploadService,
    ) {}

    public async getLocation(scope: {
        plannerDetails: { locationId: LocationId } | null;
    }): Promise<AssignedLocation | AssignedLocation> {
        let locationId;
        if (scope.plannerDetails) {
            // Set the location to the planner's location
            locationId = scope.plannerDetails.locationId;
        } else {
            locationId = await this.mvLocation.getLocationIdOrUpdateWithDefault();
        }

        return this.mvLocation.getAssignedLocation(locationId);
    }

    public indexOfId(objects: HasId[], id: number): number {
        if (objects) {
            for (let i = 0; i < objects.length; i++) {
                const obj = objects[i];
                if (obj.id === id) {
                    return i;
                }
            }
        }
        return -1;
    }

    public getFieldValue<O>(obj: O, path: string): unknown {
        const pathArray = path.split('.');
        const lastIndex = pathArray.length - 1;
        for (let i = 0; i < lastIndex; i++) {
            obj = (obj as Untyped)[pathArray[i]];
        }
        return (obj as Untyped)[pathArray[lastIndex]];
    }

    public setFieldValue<O, V>(obj: O, path: string, value: V): V {
        const pathArray = path.split('.');
        const lastIndex = pathArray.length - 1;
        for (let i = 0; i < lastIndex; i++) {
            obj = (obj as Untyped)[pathArray[i]];
        }
        (obj as Untyped)[pathArray[lastIndex]] = value;
        return value;
    }

    public findClientById(clientId: ClientId, clients: Client[] | null): Client | null {
        if (clients) {
            for (const client of clients) {
                if (client.id === clientId) {
                    return client;
                }
            }
        }
        return null;
    }

    public setClients(scope: {
        location?: AssignedLocation | null;
        clients?: Client[] | null;
    }): IPromise<Client | null> {
        return this.$q((resolve: Untyped, reject: Untyped) => {
            if (this.mvIdentity.isManager()) {
                this.mvClient
                    .getClients()
                    .then(clients => {
                        scope.clients = clients;
                    })
                    .then(() => {
                        let selectedClient = null;
                        if (scope.location) {
                            selectedClient = this.findClientById(scope.location.clientId, scope.clients!);
                        }
                        resolve(selectedClient);
                    })
                    .catch(error => {
                        this.mvNotifier.unexpectedErrorWithData('Failed to retrieve clients', error);
                        reject(error);
                    });
            } else {
                resolve(null);
            }
        });
    }

    public showModalAndUploadResources(
        scope: ShowModalAndUploadResourcesScope,
        uploadCategory: UploadCategory,
        FilesToUpload: { [key: string]: Blob | File },
        totalStages?: number,
    ): ShowModalAndUploadResourcesResult {
        scope.isSaving = true;
        const newScope: UploadResourcesScope = scope.$new();
        newScope.stage = 1;
        newScope.totalStages = totalStages || 1;
        newScope.imageUploads = [];

        const modalInstance = this.$modal.open({
            backdrop: 'static',
            controller: 'mvContentBuilderSaveTemplateCtrl',
            scope: newScope,
            templateUrl: 'contentBuilder/saveTemplateModal.html',
        });

        function cleanup() {
            scope.isSaving = false;
            modalInstance.dismiss();
            newScope.$destroy();
        }

        return {
            cleanup,
            promise: this.uploadService.uploadMap(FilesToUpload, uploadCategory, scope as UploadScope, {
                suppressNotifications: true,
            }),
            scope: newScope,
        };
    }

    public applyTextSubstitutionMixins(scope: ContentBuilderCtrlScope, builderProperty: 'contentBuilder'): void;
    public applyTextSubstitutionMixins(scope: EmailBuilderCtrlScope, builderProperty: 'emailBuilder'): void;
    public applyTextSubstitutionMixins(
        scope: MixinableScope,
        builderProperty: 'contentBuilder' | 'emailBuilder',
    ): void {
        const mvNotifier = this.mvNotifier; // Avoid ambiguity referring to "this.mvNotifier" in the function mixins.
        scope.currentTextSubstitutionField = null;

        scope.editTextSubstitutionField = (field: TextSubstitutionField) => {
            if (scope.currentTextSubstitutionField && scope.currentTextSubstitutionField.original === field) {
                scope.currentTextSubstitutionField = null;
            } else {
                scope.currentTextSubstitutionField = angular.copy(field);
                scope.currentTextSubstitutionField.original = field;
            }
        };

        scope.deleteTextSubstitutionField = () => {
            const existingFields = scope[builderProperty as keyof MixinableScope].document.textSubstitutionFields;
            const values = scope[builderProperty as keyof MixinableScope].textSubstitutionValues;
            const field = scope.currentTextSubstitutionField;
            if (field && field.original) {
                const original = field.original;
                for (let i = 0; i < existingFields.length; i++) {
                    const existingField = existingFields[i];
                    if (existingField === original) {
                        delete values[original.key];
                        existingFields.splice(i, 1);
                        return;
                    }
                }

                console.log("Couldn't find text field to delete.");
            }
        };

        // eslint-disable-next-line max-statements
        scope.saveTextSubstitutionField = () => {
            const existingFields = scope[builderProperty as keyof MixinableScope].document.textSubstitutionFields;
            const values = scope[builderProperty as keyof MixinableScope].textSubstitutionValues;
            const field = scope.currentTextSubstitutionField;
            if (field) {
                // Check for duplicate key first
                for (const existingField of existingFields) {
                    if (existingField.key === field.key && (!field.original || existingField !== field.original)) {
                        mvNotifier.expectedError(t('BUILD.NOTIFY_TEXT_PLACEHOLDER_EXISTS'));
                        return;
                    }
                }

                // Replace existing original field, if it exists.
                if (field.original) {
                    const original = field.original;
                    for (let i = 0; i < existingFields.length; i++) {
                        const existingField = existingFields[i];
                        if (existingField === original) {
                            if (field.key !== original.key) {
                                values[field.key] = values[original.key];
                                delete values[original.key];
                            }
                            delete field.original;
                            existingFields[i] = field;
                            scope.currentTextSubstitutionField = null;
                            if (scope.newTextSubstitutionField) {
                                scope.newTextSubstitutionField();
                            }
                            return;
                        }
                    }
                }
                // New field, or original not found.
                values[field.key] = '';
                delete field.original;
                existingFields.push(field);
                scope.currentTextSubstitutionField = null;
                if (scope.newTextSubstitutionField) {
                    scope.newTextSubstitutionField();
                }
            }
        };

        scope.cancelEditingTextSubstitutionField = () => {
            scope.currentTextSubstitutionField = null;
        };

        scope.addTextSubstitutionField = () => {
            scope.currentTextSubstitutionField = {
                displayName: '',
                key: '',
                original: undefined,
            };
        };
    }
}

angular.module('app').service(BuilderCommonService.SID, BuilderCommonService);
