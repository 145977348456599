import { gql } from '@apollo/client';

export const GET_TEMPLATES_FOR_TEMPLATE_GROUP = gql`
    query GetTemplatesForTemplateGroup($input: BuilderTemplateQueryInput!) {
        builderTemplates(input: $input) {
            id
            legacyId
            createdAt
            title
            isMultiImage
            isDraft
            thumbnailUrl
            documentFormat
            multiImageCount
            formatsConnection {
                edges {
                    node {
                        id
                        legacyId
                        label
                        groupLabel
                        platform {
                            id
                            legacyId
                            name
                            displayName # platform.label
                        }
                    }
                }
            }
            tags {
                id
                title
            }
            categories {
                id
                title
            }
        }
    }
`;
