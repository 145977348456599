/// <reference path="../../../../../typings/browser.d.ts" />

import { AssignedLocation } from '@deltasierra/shared';
import { noop } from '@deltasierra/object-utilities';
import { InteractionUtils } from '../../../common/interactionUtils';
import { Webinar } from '../../../trythis/models';
import { I18nService } from '../../../i18n';
import { MvTryThis } from '../../../trythis/mvTryThis';
import { MvIdentity } from '../../../account/mvIdentity';
import { $rootScopeSID, $qSID } from '../../../common/angularData';

export class LatestWebinarController {
    static SID = 'LatestWebinarController';

    location?: AssignedLocation;

    webinar?: Webinar;

    readonly fetchData = this.interactionUtils.createFuture<void, {}>(this.i18n.text.dashboard.fetchLatestWebinar(), () => this.$q
            .resolve(this.location && this.location.clientId)
            .then(clientId => clientId && this.tryThisService.getLatestWebinar(clientId))
            .then(webinar => this.webinar = webinar)
            .then(noop));

    static readonly $inject : string[] = [
        $rootScopeSID,
        $qSID,
        InteractionUtils.SID,
        I18nService.SID,
        MvIdentity.SID,
        MvTryThis.SID,
    ];

    constructor(
        private readonly $rootScope: ng.IRootScopeService,
        private readonly $q: ng.IQService,
        private readonly interactionUtils: InteractionUtils,
        private readonly i18n: I18nService,
        public readonly identity: MvIdentity,
        private readonly tryThisService: MvTryThis,
    ) {}

   public $onInit(): void {
        this.$rootScope.watchForChange(() => this.location && this.location.clientId, () => this.fetchData.run({}));
    }
}
