import * as React from 'react';
import { Button, Loading, ModalBody, ModalFooter, ModalWithHeader } from '@deltasierra/components';
import { t } from '@deltasierra/shared';
import { Formik, Field, Form, FormikHelpers } from 'formik';
import { gql, useMutation } from '@apollo/client';
import { CreateAssetFolderInput } from '../../../../../__graphqlTypes/globalTypes';
import { useAngularServiceContext } from '../../../common/componentUtils/angularServiceContexts';
import {
    CreateAssetFolderForCreateFolderButton,
    CreateAssetFolderForCreateFolderButtonVariables,
} from './__graphqlTypes/CreateAssetFolderForCreateFolderButton';

const CREATE_ASSET_FOLDER_MUTATION = gql`
    mutation CreateAssetFolderForCreateFolderButton($input: CreateAssetFolderInput!) {
        createAssetFolder(input: $input) {
            __typename
        }
    }
`;

export type CreateFolderButtonProps = {
    assetOrCollectionId: string;
};

export const CreateFolderButton: React.FC<CreateFolderButtonProps> = ({ assetOrCollectionId }) => {
    const [show, setShow] = React.useState(false);
    return (
        <>
            <button
                className="btn btn-sm btn-link"
                data-cy="create-folder-button"
                type="button"
                onClick={() => setShow(true)}
            >
                <i className="fas fa-folder-plus text-muted" title={t('ASSET_LIBRARY.NEW_FOLDER')} />
            </button>
            <ModalWithHeader
                closeOnBackdropClick
                show={show}
                title={t('ASSET_LIBRARY.CREATE_FOLDER_MODAL_TITLE')}
                onClose={() => setShow(false)}
            >
                <CreateFolderModalContent assetOrCollectionId={assetOrCollectionId} onClose={() => setShow(false)} />
            </ModalWithHeader>
        </>
    );
};
CreateFolderButton.displayName = 'CreateFolderButton';

type CreateFolderModalContentProps = {
    assetOrCollectionId: string;
    onClose: () => void;
};

const CreateFolderModalContent: React.FC<CreateFolderModalContentProps> = ({ assetOrCollectionId, onClose }) => {
    const notifier = useAngularServiceContext('mvNotifier');
    const [createFolder] = useMutation<
        CreateAssetFolderForCreateFolderButton,
        CreateAssetFolderForCreateFolderButtonVariables
    >(CREATE_ASSET_FOLDER_MUTATION, {
        refetchQueries: ['GetCollection', 'GetCollectionForCollectionAssets'],
    });
    const initialValues = {
        title: '',
    };
    const inputRef = React.useCallback((node: { focus: () => void; } | null) => {
        if (node !== null) {
            node.focus();
        }
    }, []);

    const handleSubmit = async (values: typeof initialValues, helpers: FormikHelpers<typeof initialValues>) => {
        helpers.setSubmitting(true);
        const input: CreateAssetFolderInput = {
            folderOrCollectionId: assetOrCollectionId,
            title: values.title,
        };
        const result = await createFolder({ variables: { input } });
        if (result.data?.createAssetFolder.__typename !== 'AssetFolder') {
            notifier.unexpectedError(t('COMMON.FAILED_TO', { description: t('COMMON.OP_EDIT_ASSETS') }));
            helpers.setSubmitting(false);
        } else {
            onClose();
        }
    };

    return (
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            {({ isSubmitting, isValid }) => (
                <Form>
                    <ModalBody>
                        <div className="form-group">
                            <Field
                                as="input"
                                className="form-control"
                                data-cy="folder-name-input"
                                innerRef={inputRef}
                                name="title"
                                type="text"
                            />
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        {isSubmitting && <Loading inline size="small" />}
                        <Button disabled={isSubmitting} onClick={() => onClose()}>
                            {t('COMMON.CANCEL')}
                        </Button>
                        <Button
                            dataCy="save-folder-button"
                            disabled={isSubmitting || !isValid}
                            theme="success"
                            type="submit"
                        >
                            {t('COMMON.SAVE')}
                        </Button>
                    </ModalFooter>
                </Form>
            )}
        </Formik>
    );
};

CreateFolderModalContent.displayName = 'CreateFolderModalContent';
