/// <reference path="../../../typings/browser.d.ts" />
import { ClientId, getPlatformIcon, LocationId, Planner, tuple } from '@deltasierra/shared';
import { I18nService } from '../i18n';
import { PlannerDateService } from './plannerDateService';

export const ALL_PLANNER_LEGEND_TYPES = tuple(
    'artifactRequired',
    'built',
    'noArtifactRequired',
    'noContent',
    'supported',
);

export type AllPlannerLegendTypes = typeof ALL_PLANNER_LEGEND_TYPES;

export type PlannerLegendType = AllPlannerLegendTypes[number];

export interface BasicPlannerIdDetails {
    clientId: ClientId;
    locationId: LocationId;
    date: Date;
    id?: number;
}

export interface RecurringPlannerDetails {
    isRecurringPlaceholder: true;
    recurringPlannerId: number;
}

export interface PlannerLegendEntry {
    iconClassName: string;
    itemClassName: string;
    getLabel: (agencyName?: string) => string;
    getLabelTooltip: (agencyName?: string) => string;
}

export type PlannerLegend = {
    [K in PlannerLegendType]: PlannerLegendEntry;
};

export class PlannerUIService {
    public static readonly SID = 'PlannerUIService';

    private legend: PlannerLegend = {
        artifactRequired: {

            getLabel: () => this.i18n.text.plan.readyToBuild(),
            getLabelTooltip: () => this.i18n.text.plan.readyToBuildTooltip(),
            iconClassName: 'fa fa-paint-brush',
            itemClassName: 'cal-warning',
        },
        built: {

            getLabel: () => this.i18n.text.plan.buildAndSchedule(),
            getLabelTooltip: () => this.i18n.text.plan.buildAndScheduleTooltip(),
            iconClassName: 'glyphicon glyphicon-ok',
            itemClassName: 'cal-success',
        },
        noArtifactRequired: {

            getLabel: () => this.i18n.text.plan.generalItem(),
            getLabelTooltip: () => this.i18n.text.plan.generalItemTooltip(),
            iconClassName: 'glyphicon glyphicon-calendar',
            itemClassName: 'cal-default',
        },
        noContent: {

            getLabel: () => this.i18n.text.plan.contentRequired(),
            getLabelTooltip: () => this.i18n.text.plan.contentRequiredTooltip(),
            iconClassName: 'glyphicon glyphicon-exclamation-sign',
            itemClassName: 'cal-danger',
        },
        supported: {
            getLabel: (agencyName?: string) => {
                const options = agencyName ? { agencyName, context: 'SPECIFIC' } : undefined;

                return this.i18n.text.plan.agencyToBuild(options);
            },
            getLabelTooltip: (agencyName?: string) => {
                const options = agencyName ? { agencyName, context: 'SPECIFIC' } : undefined;

                return this.i18n.text.plan.agencyToBuildTooltip(options);
            },
            iconClassName: 'fa fa-life-ring',
            itemClassName: 'cal-supported',
        },
    };


    public static readonly $inject: string[] = [PlannerDateService.SID, I18nService.SID];

    public constructor(private plannerDateService: PlannerDateService, private i18n: I18nService) {}

    public getPlannerClass(planner: Planner): PlannerLegendType {
        let result: PlannerLegendType = 'noArtifactRequired';

        if (planner.status === 'built' || planner.status === 'scheduled') {
            result = 'built';
        } else if ((planner.isArtifactRequired || planner.isSupported) && !planner.contentSupplied) {
            result = 'noContent';
        } else if (planner.isSupported) {
            result = 'supported';
        } else if (planner.isArtifactRequired) {
            result = 'artifactRequired';
        } else {
            result = 'noArtifactRequired';
        }

        return result;
    }

    public getPlannerLegendIconClassName(plannerOrLegendType: Planner | PlannerLegendType): string {
        let plannerClass: PlannerLegendType;

        if (typeof plannerOrLegendType !== 'string') {
            const planner = plannerOrLegendType;
            const platform = planner.channels && planner.channels.length && planner.channels[0].platform;
            if (platform) {
                const icon = getPlatformIcon(platform.name);
                if (icon) {
                    return `fa ${icon}`;
                }
            }
            plannerClass = this.getPlannerClass(planner);
        } else {
            plannerClass = plannerOrLegendType;
        }
        return this.legend[plannerClass].iconClassName;
    }

    public getPlannerLegendIconName(plannerOrLegendType: Planner | PlannerLegendType): string {
        if (typeof plannerOrLegendType !== 'string') {
            if (plannerOrLegendType.channels?.length && !!plannerOrLegendType.channels[0]) {
                return plannerOrLegendType.channels[0].platform?.name || '';
            }
        }
        return '';
    }

    public getPlannerLegendClassName(plannerOrLegendType: Planner | PlannerLegendType): string {
        const legendType =
            typeof plannerOrLegendType !== 'string' ? this.getPlannerClass(plannerOrLegendType) : plannerOrLegendType;

        return this.legend[legendType].itemClassName;
    }

    public getPlannerLegendLabel(legendType: PlannerLegendType, agencyName: string): string {
        return this.legend[legendType].getLabel(agencyName);
    }

    public getPlannerLegendLabelTooltip(legendType: PlannerLegendType, agencyName: string): string {
        return this.legend[legendType].getLabelTooltip(agencyName);
    }

    public isRecurringPlaceholder(planner: Record<string, any>): planner is RecurringPlannerDetails {
        const details = planner as RecurringPlannerDetails;
        return !!details.isRecurringPlaceholder;
    }

    public getPlannerUrl(planner: BasicPlannerIdDetails): string {
        const datePath = this.plannerDateService.formatDateUTC(planner.date);
        const idPart = this.isRecurringPlaceholder(planner)
            ? `recurring/${planner.recurringPlannerId}`
            : `${planner.id}`;
        const path = `/planner/${planner.clientId}/${planner.locationId}/${datePath}/${idPart}`;
        return path;
    }
}

angular.module('app').service(PlannerUIService.SID, PlannerUIService);
