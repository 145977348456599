/// <reference path="../../../typings/browser.d.ts" />
import { $locationSID } from '../common/angularData';
import { InteractionUtils } from '../common/interactionUtils';
import { Future } from '../common/Future';
import { MvIdentity } from './mvIdentity';
import { LoginFlowService } from './loginFlowService';
import ILocationService = angular.ILocationService;

type LoginActionContext = {
    password: string;
    requestToken?: string;
    username: string;
};

export class LoginController {
    public static readonly SID = 'mvLoginCtrl';

    public loginAttempts = 0;

    public username: string | null = null;

    public password: string | null = null;

    public requestToken?: string = undefined;

    public isExternalLoginRequest = false;

    public loginAction: Future<void, LoginActionContext>;

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public static readonly $inject: string[] = [$locationSID, MvIdentity.SID, LoginFlowService.SID, InteractionUtils.SID];

    public constructor(
        $location: ILocationService,
        public readonly identity: MvIdentity,
        private readonly loginFlowService: LoginFlowService,
        interactionUtils: InteractionUtils,
    ) {
        const params = $location.search();
        if (params.requestToken) {
            this.requestToken = params.requestToken;
            this.isExternalLoginRequest = true;
        }

        if (identity.isAuthenticated() && !this.isExternalLoginRequest) {
            this.loginFlowService.redirect();
        } else if (params.username) {
            this.username = params.username;
        } else if (identity.isAuthenticated()) {
            this.username = identity.currentUser!.username;
        }

        this.loginAction = interactionUtils.createFuture('login', ({ password, requestToken, username }: LoginActionContext) =>
            this.loginFlowService.login(username, password, requestToken),
        );
    }

    public signin(username: string, password: string): ng.IPromise<void> {
        return this.loginAction.run({ password, requestToken: this.requestToken, username });
    }

    public isConfirmingIdentity() {
        return this.isExternalLoginRequest && this.identity.isAuthenticated();
    }
}

angular.module('app').controller(LoginController.SID, LoginController);
