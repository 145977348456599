import { useMutation } from '@apollo/client';
import React from 'react';
import { MarkScheduledPublishesMarkType } from '../../../../../../../__graphqlTypes/globalTypes';
import { useNotifier } from '../../../../../common';
import { GET_BLOCKED_PUBLISHES_QUERY } from './BlockedPublishesWell.queries';
import {
    MARK_BLOCKED_PUBLISH_MUTATION,
    RESTART_BLOCKED_PUBLISH_MUTATION,
} from './use-blocked-publishes-actions.mutations';
import {
    MarkBlockedPublishMutation,
    MarkBlockedPublishMutationVariables,
} from './__graphqlTypes/MarkBlockedPublishMutation';
import {
    RestartBlockedPublishMutation,
    RestartBlockedPublishMutationVariables,
} from './__graphqlTypes/RestartBlockedPublishMutation';

type BulkAction = (ids: string[]) => void;

export function useBlockedPublishesActions(): {
    pushToFailed: BulkAction;
    pushToSent: BulkAction;
    running: boolean;
    sendNow: BulkAction;
} {
    const notifier = useNotifier();

    const [restartPublishes, { data, loading }] = useMutation<
        RestartBlockedPublishMutation,
        RestartBlockedPublishMutationVariables
    >(RESTART_BLOCKED_PUBLISH_MUTATION, {
        notifyOnNetworkStatusChange: true,
        refetchQueries: [GET_BLOCKED_PUBLISHES_QUERY],
    });

    const [markPublishes, { data: markData, loading: markLoading }] = useMutation<
        MarkBlockedPublishMutation,
        MarkBlockedPublishMutationVariables
    >(MARK_BLOCKED_PUBLISH_MUTATION, {
        notifyOnNetworkStatusChange: true,
        refetchQueries: [GET_BLOCKED_PUBLISHES_QUERY],
    });

    React.useEffect(() => {
        if (data?.restartScheduledPublishes.__typename === 'RestartScheduledPublishesError') {
            notifier.error(data.restartScheduledPublishes.message);
        }
    }, [data, notifier]);

    React.useEffect(() => {
        if (markData?.markScheduledPublishes.__typename === 'MarkScheduledPublishesError') {
            notifier.error(markData.markScheduledPublishes.message);
        }
    }, [markData, notifier]);

    const sendNow = React.useCallback(
        (ids: string[]) => {
            void restartPublishes({
                variables: {
                    input: {
                        scheduledPublishIds: ids,
                        scheduledTime: null,
                    },
                },
            });
        },
        [restartPublishes],
    );
    const pushToFailed = React.useCallback(
        (ids: string[]) => {
            void markPublishes({
                variables: {
                    input: {
                        as: MarkScheduledPublishesMarkType.failed,
                        scheduledPublishIds: ids,
                    },
                },
            });
        },
        [markPublishes],
    );

    const pushToSent = React.useCallback(
        (ids: string[]) => {
            void markPublishes({
                variables: {
                    input: {
                        as: MarkScheduledPublishesMarkType.success,
                        scheduledPublishIds: ids,
                    },
                },
            });
        },
        [markPublishes],
    );

    return {
        pushToFailed,
        pushToSent,
        running: loading || markLoading,
        sendNow,
    };
}
