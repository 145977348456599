import { ReportableService } from '@deltasierra/integrations/integration-types';
import { AssignedLocation, FacebookAdSetStats, Omit } from '@deltasierra/shared';

import { rollingMean } from '@deltasierra/math-utilities';
import { $httpSID } from '../../common/angularData';
import { MvLocation } from '../../locations/mvLocation';
import { BaseLocationStatsService } from './common';

export class FacebookMarketingStatsService extends BaseLocationStatsService<
    FacebookMarketingStatsForLocation,
    CombinedFacebookAdSetStats
> {
    public static readonly SID = 'facebookMarketingStatsService';

    public static readonly $inject: string[] = [$httpSID, MvLocation.SID];

    public readonly serviceName: ReportableService = 'facebookMarketing';

    public readonly displayName: string = 'Facebook Marketing';

    public constructor(
        $http: ng.IHttpService,
        protected readonly mvLocation: MvLocation,
    ) {
        super($http);
    }

    public isPlatformConfigured(location: AssignedLocation): boolean {
        return location.facebookPageId !== null;
    }

    public combineStats(stats: CombinedFacebookAdSetStats[]): CombinedFacebookAdSetStats {
        const initial: CombinedFacebookAdSetStats = {
            actionsLead: null,
            adAccountId: null,
            adSetId: null,
            costPerLead: 0,
            costPerUniqueClick: 0,
            endDate: null,
            lifetimeBudget: 0,
            name: null,
            reach: 0,
            spend: 0,
            startDate: null,
            uniqueClicks: 0,
        };
        return stats.reduce(this.reduceStats.bind(this), initial);
    }

    private reduceStats(
        previousValue: CombinedFacebookAdSetStats,
        currentValue: CombinedFacebookAdSetStats,
        currentIndex: number,
    ): CombinedFacebookAdSetStats {
        return {
            actionsLead:
                previousValue.actionsLead === null && currentValue.actionsLead === null
                    ? null
                    : (previousValue.actionsLead || 0) + (currentValue.actionsLead || 0),
            adAccountId: null,
            adSetId: null,
            costPerLead:
                !previousValue.actionsLead && !currentValue.actionsLead
                    ? null
                    : (previousValue.spend + currentValue.spend) /
                      ((previousValue.actionsLead ?? 0) + (currentValue.actionsLead ?? 0)),
            costPerUniqueClick: rollingMean(
                previousValue.costPerUniqueClick,
                currentValue.costPerUniqueClick,
                currentIndex,
            ),
            endDate: null,
            lifetimeBudget:
                !previousValue.lifetimeBudget && !currentValue.lifetimeBudget
                    ? null
                    : (previousValue.lifetimeBudget ?? 0) + (currentValue.lifetimeBudget ?? 0),
            name: null,
            reach: previousValue.reach + currentValue.reach,
            spend: previousValue.spend + currentValue.spend,
            startDate: null,
            uniqueClicks: previousValue.uniqueClicks + currentValue.uniqueClicks,
        };
    }
}

export type SimpleFacebookAdSetStats = Omit<FacebookAdSetStats, 'createdAt' | 'id' | 'updatedAt'>;

export type CombinedFacebookAdSetStats = Omit<
    SimpleFacebookAdSetStats,
    'adAccountId' | 'adSetId' | 'currencyCode' | 'endDate' | 'name' | 'startDate'
> & {
    adAccountId: null;
    adSetId: null;
    name: null;
    startDate: null;
    endDate: null;
};

export interface FacebookMarketingStatsForLocation {
    adSetInsights: SimpleFacebookAdSetStats[];
}

angular.module('app').service('facebookMarketingStatsService', FacebookMarketingStatsService);
