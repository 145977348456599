import { DSAlert } from '@deltasierra/components';
import { withStyles } from '@material-ui/core';

export const StyledDSAlert = withStyles({
    root: {
        boxShadow: 'none',
        fontSize: 14,
    },
    icon: {
        fontSize: 22,
    },
    message: {
        fontSize: 14,
        color: 'inherit',
    },
    standardInfo: {
        backgroundColor: '#F0EAFF',
        color: '#5223D7',
        '& .icon': {
            color: '#6B30FA',
        },
    },
})(DSAlert);
