import * as React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { LazyComponent, RouteDefaultRedirect } from '../common';
import { AdminRouteWrapper } from './AdminRouteWrapper';
import { RootUrlMap } from './RootRouter.url';

/**
 * This is the root router for DS
 *
 * @returns The JSX Element
 */
export function RootRouter(): JSX.Element {
    return (
        <BrowserRouter>
            <Routes>
                <Route
                    element={
                        <AdminRouteWrapper>
                            <LazyComponent
                                loader={async () => import(/* webpackChunkName: "admin-route" */ './admin/Router')}
                            />
                        </AdminRouteWrapper>
                    }
                    path="admin/*"
                />
                <Route
                    element={
                        <LazyComponent
                            loader={async () => import(/* webpackChunkName: "assets-route" */ './assets/Router')}
                        />
                    }
                    path="assets/*"
                />
                <Route
                    element={
                        <LazyComponent
                            loader={async () => import(/* webpackChunkName: "auth-route" */ './auth/Router')}
                        />
                    }
                    path={`${RootUrlMap.auth.routerUrl}/*`}
                />
                <Route
                    element={
                        <LazyComponent
                            loader={async () => import(/* webpackChunkName: "brand-route" */ './brand/Router')}
                        />
                    }
                    path={`${RootUrlMap.brand.routerUrl}/*`}
                />
                <Route
                    element={
                        <LazyComponent
                            loader={async () => import(/* webpackChunkName: "clients-route" */ './clients/Router')}
                        />
                    }
                    path="clients/*"
                />
                <Route
                    element={
                        <LazyComponent
                            loader={async () => import(/* webpackChunkName: "connect-route" */ './connect/Router')}
                        />
                    }
                    path="connect/*"
                />
                <Route
                    element={
                        <LazyComponent
                            loader={async () => import(/* webpackChunkName: "invite-route" */ './invite/Router')}
                        />
                    }
                    path="invite/*"
                />
                <Route
                    element={
                        <LazyComponent
                            loader={async () => import(/* webpackChunkName: "plan-route" */ './plan/Router')}
                        />
                    }
                    path="plan/*"
                />
                <Route
                    element={
                        <LazyComponent
                            loader={async () => import(/* webpackChunkName: "reports-route" */ './reports/Router')}
                        />
                    }
                    path={`${RootUrlMap.reports.routerUrl}/*`}
                />
                <Route
                    element={
                        <LazyComponent
                            loader={async () => import(/* webpackChunkName: "scheduled-route" */ './scheduled/Router')}
                        />
                    }
                    path="scheduled/*"
                />
                <Route
                    element={
                        <LazyComponent
                            loader={async () => import(/* webpackChunkName: "welcome-page" */ './Welcome')}
                        />
                    }
                    path="welcome"
                />
                <Route element={<RouteDefaultRedirect />} path="*" />
            </Routes>
        </BrowserRouter>
    );
}
RootRouter.displayName = 'RootRouter';
