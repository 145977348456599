import { MuiThemeProvider, muiCreateTheme } from '@deltasierra/components';
import { mergeDeep } from '@deltasierra/object-utilities';
import * as React from 'react';

export type NotifierThemeProviderProps = {
    children: React.ReactNode;
};

export function NotifierThemeProvider({ children }: NotifierThemeProviderProps): JSX.Element {
    return (
        <MuiThemeProvider
            theme={(theme: Exclude<Parameters<typeof muiCreateTheme>[0], undefined>) =>
                muiCreateTheme(
                    mergeDeep<Exclude<Parameters<typeof muiCreateTheme>[0], undefined>>(theme, {
                        overrides: {
                            MuiAlert: {
                                action: {
                                    '& button': {
                                        padding: 0,
                                    },
                                    '& svg': {
                                        fontSize: 22,
                                    },
                                    color: '#6D737C',
                                    fontSize: '26px !important',
                                    marginRight: 0,
                                    paddingLeft: 15,
                                },
                                icon: {
                                    '& svg': {
                                        fontSize: 24,
                                    },
                                    marginRight: 10,
                                    padding: 0,
                                },
                                message: {
                                    color: '#18252E',
                                    fontSize: 16,
                                    padding: 0,
                                },
                                root: {
                                    alignItems: 'center',
                                    backgroundColor: 'white',
                                    boxShadow: theme.shadows?.[5],
                                    paddingBottom: 12,
                                    paddingLeft: 14,
                                    paddingRight: 14,
                                    paddingTop: 12,
                                },
                            },
                        },
                        palette: {
                            success: {
                                main: '#6CC120',
                            },
                        },
                        shape: {
                            borderRadius: 8,
                        },
                    }),
                )
            }
        >
            {children}
        </MuiThemeProvider>
    );
}
NotifierThemeProvider.displayName = 'NotifierThemeProvider';
