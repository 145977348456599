import { AssignedLocation, LocationIdHierarchy } from '@deltasierra/shared';
import * as React from 'react';
import { OneWayBinding, StringBinding } from '../../../common/angularData';
import { withAngularIntegration } from '../../../common/componentUtils/reactComponentRegistration';
import { MvContentBuilderPublishCtrl } from '../mvContentBuilderPublishCtrl';
import { PublishResult } from '../publishResult';
import { FacebookPublishType } from './FacebookPublishForm';
import { PublishImageToFacebookStep } from './PublishImageToFacebookStep';
import { PublishVideoToFacebookStep } from './PublishVideoToFacebookStep';

export type PublishToFacebookWizardProps = {
    location: AssignedLocation;
    locations: LocationIdHierarchy[];
    onCancel: () => void;
    onFinish: (data: PublishResult) => void;
    publishController: MvContentBuilderPublishCtrl;
    publishType: FacebookPublishType;
};

export function PublishToFacebookWizard({
    location,
    locations,
    onCancel,
    onFinish,
    publishController,
    publishType,
}: PublishToFacebookWizardProps): JSX.Element {
    return publishType === 'image' ? (
        <PublishImageToFacebookStep
            location={location}
            locations={locations}
            publishController={publishController}
            onCancel={onCancel}
            onFinish={onFinish}
        />
    ) : (
        <PublishVideoToFacebookStep
            location={location}
            locations={locations}
            publishController={publishController}
            onCancel={onCancel}
            onFinish={onFinish}
        />
    );
}
PublishToFacebookWizard.displayName = 'PublishToFacebookWizard';
PublishToFacebookWizard.angular = withAngularIntegration(PublishToFacebookWizard, 'publishToFacebookWizard', {
    location: OneWayBinding,
    locations: OneWayBinding,
    onCancel: OneWayBinding,
    onFinish: OneWayBinding,
    publishController: OneWayBinding,
    publishType: StringBinding,
});
