import { gql } from '@apollo/client';

export const GET_REQUIRED_HASHTAGS_FOR_LOCATION_QUERY = gql`
    query GetRequiredHashtagsForLocationQuery($locationId: ID!) {
        location(id: $locationId) {
            id
            client {
                id
                hashtagSetsConnection(filter: { isCore: true }) {
                    edges {
                        node {
                            id
                            title
                            hashtags
                        }
                    }
                }
            }
        }
    }
`;
