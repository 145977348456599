/// <reference path="../../../typings/browser.d.ts" />
import { TextTranslators } from '@deltasierra/i18n';
import { I18nService } from '../i18n';
import { ModalInstance, ModalService } from '../typings/angularUIBootstrap/modalService';
import { $rootScopeSID } from './angularData';
import { $modalSID } from './angularUIBootstrapData';
import IRootScopeService = angular.IRootScopeService;
import IScope = angular.IScope;

export interface ConfirmModal {
    open(title: string, message: string, confirmCallback: Function, cancelCallback?: Function): ModalInstance;
    checkAndOpen(checkFunction: () => boolean, title: string, message: string, confirmCallback: Function, cancelCallback: Function): void;
}

export const confirmModalSID = 'confirmModal';

angular.module('app').service(confirmModalSID, [
    $rootScopeSID,
    $modalSID,
    function confirmModalService($rootScope: IRootScopeService, $modal: ModalService): ConfirmModal {
        function dummyFunc() {
            // Do nothing
        }

        function open(title: string, message: string, confirmCallback: () => any, cancelCallback?: () => any) {
            const modalScope: IScope & { title?: string; message?: string } = $rootScope.$new();
            modalScope.title = title;
            modalScope.message = message;
            const modalInstance = $modal.open({
                controller: 'confirmModalCtrl',
                scope: modalScope,
                templateUrl: 'common/confirmModal.html',
            });
            if (!confirmCallback) {
                // eslint-disable-next-line no-param-reassign
                confirmCallback = dummyFunc;
            }
            if (!cancelCallback) {
                // eslint-disable-next-line no-param-reassign
                cancelCallback = dummyFunc;
            }
            // eslint-disable-next-line no-console
            modalInstance.result.then(confirmCallback, cancelCallback).catch(console.error);
            return modalInstance;
        }

        function checkAndOpen(
            checkFunction: () => boolean,
            title: string,
            message: string,
            confirmCallback: () => any,
            cancelCallback: () => any,
        ) {
            if (checkFunction()) {
                open(title, message, confirmCallback, cancelCallback);
            } else {
                confirmCallback();
            }
        }

        return {
            checkAndOpen,
            open,
        };
    },
]);

angular.module('app').controller('confirmModalCtrl', [
    '$scope',
    '$modal',
    '$modalInstance',
    I18nService.SID,
    function confirmModalCtrl(
        $scope: IScope & { i18n: TextTranslators; ok: () => void; cancel: () => void },
        $modal: ModalService,
        $modalInstance: ModalInstance,
        i18n: I18nService,
    ) {
        $scope.i18n = i18n.text;

        $scope.ok = function ok() {
            $modalInstance.close();
        };

        $scope.cancel = function cancel() {
            $modalInstance.dismiss();
        };
    },
]);
