import { gql, useMutation } from '@apollo/client';
import { DSButton, DSTextField, DSWell, DSWellHeader, DSWellHeaderTitle, Loading } from '@deltasierra/components';
import { t } from '@deltasierra/shared';
import { Formik, FormikHelpers } from 'formik';
import * as React from 'react';
import styled from 'styled-components';
import { useAngularServiceContext } from '../../../common/componentUtils/angularServiceContexts';
import { withAngularIntegration } from '../../../common/componentUtils/reactComponentRegistration';
import { CountryCode, CountrySelect } from '../../../common/react/CountrySelect';
import { CreateClientForAgency, CreateClientForAgencyVariables } from './__graphqlTypes/CreateClientForAgency';

const StyledForm = styled.form`
    & > * {
        margin-top: 12px;
        margin-bottom: 12px;
    }
`;

const ButtonWrapper = styled.div`
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
`;

const CREATE_CLIENT_FOR_AGENCY = gql`
    mutation CreateClientForAgency($input: CreateClientInput!) {
        createClient(input: $input) {
            __typename
            ... on CreateClientPayload {
                client {
                    id
                    title
                }
            }
        }
    }
`;

type AddClientFormValues = {
    country: CountryCode | '';
    title: string;
};

const validateForm = (values: AddClientFormValues) => {
    const errors: Partial<Record<keyof AddClientFormValues, string>> = {};
    if (values.country === '') {
        errors.country = 'required';
    }
    if (values.title === '') {
        errors.title = 'required';
    }
    return errors;
};

export const AddClientFormWell: React.FC = () => {
    const mvNotifier = useAngularServiceContext('mvNotifier');

    const [createClient, { loading }] = useMutation<CreateClientForAgency, CreateClientForAgencyVariables>(
        CREATE_CLIENT_FOR_AGENCY,
        {
            refetchQueries: ['ClientsForClientTable'],
        },
    );

    const initialValues: AddClientFormValues = { country: '', title: '' };

    const onClickHandler = async (
        { country, title }: AddClientFormValues,
        { resetForm }: FormikHelpers<AddClientFormValues>,
    ) => {
        try {
            const result = await createClient({ variables: { input: { country, title } } });
            if (result.data?.createClient.__typename === 'CreateClientPayload') {
                mvNotifier.success(t('AGENCY.CLIENTS.ADD_CLIENT.CLIENT_ADDED'));
                resetForm();
            }
        } catch (error) {
            mvNotifier.unexpectedError(error);
        }
    };

    return (
        <DSWell className="AddClientFormWell__well">
            <DSWellHeader>
                <DSWellHeaderTitle>{t('AGENCY.CLIENTS.ADD_NEW_CLIENT')}</DSWellHeaderTitle>
            </DSWellHeader>
            <Formik initialValues={initialValues} validate={validateForm} validateOnMount onSubmit={onClickHandler}>
                {formik => (
                    <StyledForm
                        className="AddClientFormWell__form"
                        data-cy="add-client-form"
                        onSubmit={ev => {
                            ev.preventDefault();
                            formik.handleSubmit();
                        }}
                    >
                        <DSTextField
                            data-cy="add-client-title-input"
                            fullWidth
                            inputProps={{ className: 'AddClientFormWell__title-input' }}
                            label={t('AGENCY.CLIENTS.CLIENT_NAME')}
                            variant="outlined"
                            {...formik.getFieldProps('title')}
                        />
                        <CountrySelect
                            className="AddClientFormWell__country-select"
                            country={formik.values.country}
                            dataCy="add-client-country-select"
                            onChange={code => formik.setFieldValue('country', code)}
                        />
                        <ButtonWrapper>
                            {loading && <Loading size="small" style={{ marginRight: '8px' }} />}
                            <DSButton
                                className="AddClientFormWell__submit-button"
                                color="success"
                                data-cy="add-client-button"
                                disabled={!formik.isValid}
                                type="submit"
                            >
                                <i className="fas fa-plus icon-space" />
                                {t('COMMON.ADD')}
                            </DSButton>
                        </ButtonWrapper>
                    </StyledForm>
                )}
            </Formik>
        </DSWell>
    );
};
AddClientFormWell.displayName = 'AddClient';

export default withAngularIntegration(AddClientFormWell, 'addClientFormWell', {});
