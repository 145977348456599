import { LocationPeriod, FacebookPerformanceStats, HttpResponseCode, PercentageInteger } from '@deltasierra/shared';


import { Metric, addDifference, BaseMetricProviderService, InsufficientDataRecorded } from './common';

export class FacebookMetricProviderService extends BaseMetricProviderService {
    static SID = 'FacebookMetricProviderService';

    getMetricRows(params: LocationPeriod): ng.IPromise<Array<Metric<any>>> {
        const engagementReach: Metric<PercentageInteger> = {
            label: this.i18n.text.dashboard.performanceSummary.metrics.facebookEngagementReach(),
            iconUrl: '/img/dashboard/icon-facebook.png',
            values: null,
            columnSettings: {
                default: {
                    decimals: 2,
                    filter: 'percentage:2:false',
                },
                difference: {
                    decimals: 2,
                    filter: 'number',
                },
            },
        };

        const engagementClicks: Metric<PercentageInteger> = {
            label: this.i18n.text.dashboard.performanceSummary.metrics.facebookEngagementClicks(),
            iconUrl: '/img/dashboard/icon-facebook.png',
            values: null,
            columnSettings: {
                default: {
                    decimals: 2,
                    filter: 'percentage:2:false',
                },
                difference: {
                    decimals: 2,
                    filter: 'number',
                },
            },
        };

        return this.$http
            .get<FacebookPerformanceStats>(`/api/performance/facebook/${params.locationId}/${params.year}/${params.month}`)
            .then(res => {
                if (res.status == HttpResponseCode.NoContent) {
                    engagementReach.error = new InsufficientDataRecorded();
                    engagementClicks.error = new InsufficientDataRecorded();
                } else {
                    const data = res.data!;
                    engagementReach.values = addDifference({
                        clientAverage: data.engagement.reach.clientAverage * 100,
                        currentMonth: data.engagement.reach.currentMonth * 100,
                        previousMonth: data.engagement.reach.previousMonth * 100,
                    });

                    engagementClicks.values = addDifference({
                        clientAverage: data.engagement.clicks.clientAverage * 100,
                        currentMonth: data.engagement.clicks.currentMonth * 100,
                        previousMonth: data.engagement.clicks.previousMonth * 100,
                    });
                }

                return [engagementReach, engagementClicks];
            }, this.getHttpErrorHandlerForMetrics(engagementReach, engagementClicks));
    }
}

angular.module('app').service(FacebookMetricProviderService.SID, FacebookMetricProviderService);
