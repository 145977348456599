import * as React from 'react';
import { Button, DSConfirmButton, DSDialogActions, Loading, ModalBody, ModalWithHeader } from '@deltasierra/components';
import { t } from '@deltasierra/shared';
import { Formik, Field, Form, FormikHelpers } from 'formik';
import { gql, useMutation } from '@apollo/client';
import { useAngularServiceContext } from '../../../common/componentUtils/angularServiceContexts';
import { EditAssetsBulkInput } from '../../../../../__graphqlTypes/globalTypes';
import { useCurrentAssetContext } from '../../contexts';
import { EditAssetTitleMutation, EditAssetTitleMutationVariables } from './__graphqlTypes/EditAssetTitleMutation';
import { DeleteAssetsMutation, DeleteAssetsMutationVariables } from './__graphqlTypes/DeleteAssetsMutation';
import { DeleteAssetsInput } from './../../../../../__graphqlTypes/globalTypes';

const EDIT_ASSET_TITLE_MUTATION = gql`
    mutation EditAssetTitleMutation($input: EditAssetsBulkInput!) {
        editAssetsBulk(input: $input) {
            __typename
            ... on EditAssetsBulkPayload {
                assets {
                    edges {
                        node {
                            __typename
                            ... on AssetFile {
                                id
                                title
                            }
                        }
                    }
                }
            }
        }
    }
`;
const DELETE_ASSET_MUTATION = gql`
    mutation DeleteAssetsMutation($input: DeleteAssetsInput!) {
        deleteAssets(input: $input) {
            __typename
        }
    }
`;

export type EditFolderButtonProps = {
    assetOrCollectionId: string;
    backId: string;
    currentAssetTitle: string;
};

export const EditFolderButton: React.FC<EditFolderButtonProps> = ({
    assetOrCollectionId,
    backId,
    currentAssetTitle,
}) => {
    const [show, setShow] = React.useState(false);
    return (
        <>
            <button
                className="btn btn-sm btn-link"
                data-cy="edit-folder-button"
                type="button"
                onClick={() => setShow(true)}
            >
                <i className="fas fa-cog text-muted" title={t('COMMON.SETTINGS')} />
            </button>
            <ModalWithHeader
                closeOnBackdropClick
                show={show}
                title={t('ASSET_LIBRARY.EDIT_FOLDER')}
                onClose={() => setShow(false)}
            >
                <EditFolderModalContent
                    assetOrCollectionId={assetOrCollectionId}
                    backId={backId}
                    currentAssetTitle={currentAssetTitle}
                    onClose={() => setShow(false)}
                />
            </ModalWithHeader>
        </>
    );
};
EditFolderButton.displayName = 'EditFolderButton';

type EditFolderModalContentProps = {
    assetOrCollectionId: string;
    backId: string;
    currentAssetTitle: string;
    onClose: () => void;
};

const EditFolderModalContent: React.FC<EditFolderModalContentProps> = ({
    assetOrCollectionId,
    backId,
    currentAssetTitle,
    onClose,
}) => {
    const notifier = useAngularServiceContext('mvNotifier');
    const [, setCurrentAsset] = useCurrentAssetContext();
    const [editFolder] = useMutation<EditAssetTitleMutation, EditAssetTitleMutationVariables>(
        EDIT_ASSET_TITLE_MUTATION,
        {
            refetchQueries: ['GetCollection', 'GetCollectionForCollectionAssets'],
        },
    );
    const initialValues = {
        title: currentAssetTitle,
    };
    const [deleteAsset] = useMutation<DeleteAssetsMutation, DeleteAssetsMutationVariables>(DELETE_ASSET_MUTATION);

    const inputRef = React.useCallback((node: { focus: () => void; } | null) => {
        if (node !== null) {
            node.focus();
        }
    }, []);

    const handleSubmit = async (values: typeof initialValues, helpers: FormikHelpers<typeof initialValues>) => {
        helpers.setSubmitting(true);
        const asset = {
            id: assetOrCollectionId,
            title: values.title,
        };

        const input: EditAssetsBulkInput = {
            assets: [asset],
        };
        const result = await editFolder({ variables: { input } });
        if (result.data?.editAssetsBulk.__typename !== 'EditAssetsBulkPayload') {
            notifier.unexpectedError(t('COMMON.FAILED_TO', { description: t('COMMON.OP_EDIT_ASSETS') }));
            helpers.setSubmitting(false);
        } else {
            onClose();
        }
    };

    const handleDelete = async () => {
        const input: DeleteAssetsInput = {
            ids: [assetOrCollectionId],
        };

        await deleteAsset({
            variables: { input },
        });
        setCurrentAsset(backId);
    };

    return (
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            {({ isSubmitting, isValid }) => (
                <Form>
                    <ModalBody>
                        <div className="form-group">
                            <Field
                                as="input"
                                className="form-control"
                                data-cy="folder-name-input"
                                innerRef={inputRef}
                                name="title"
                                type="text"
                            />
                        </div>
                    </ModalBody>
                    <DSDialogActions>
                        {isSubmitting && <Loading inline size="small" />}
                        <DSConfirmButton
                            color="error"
                            disabled={isSubmitting}
                            message={`${t('COMMON.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_COLLECTION')}  ${t(
                                'ASSET_LIBRARY.DELETE_COLLECTION_NOTE',
                            )}`}
                            style={{ marginRight: 'auto' }}
                            variant="contained"
                            onConfirm={async () => handleDelete()}
                        >
                            {t('COMMON.DELETE')}
                        </DSConfirmButton>
                        <Button disabled={isSubmitting} onClick={() => onClose()}>
                            {t('COMMON.CANCEL')}
                        </Button>
                        <Button
                            dataCy="save-folder-button"
                            disabled={isSubmitting || !isValid}
                            theme="success"
                            type="submit"
                        >
                            {t('COMMON.SAVE')}
                        </Button>
                    </DSDialogActions>
                </Form>
            )}
        </Formik>
    );
};

EditFolderModalContent.displayName = 'EditFolderModalContent';
