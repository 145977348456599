/// <reference path="../../../../../typings/browser.d.ts" />
import { IQService, IScope } from 'angular';
import { Upload, AssignedLocation, assignedLocationToLocationIdHierarchy, BuilderEmailDocumentInspector, AutomaticTextPlaceholders } from '@deltasierra/shared';


import { BaseMultipleLocationPublishCtrl } from '../../publish/multipleLocationPublish/BaseMultipleLocationPublishCtrl';
import { MvNotifier } from '../../../common/mvNotifier';
import { BuilderConstants, builderConstantsSID } from '../../builderConstants';
import { I18nService } from '../../../i18n';
import {
    $qSID,
    $scopeSID,
    $timeoutSID,
    actualComponent,
    ExpressionBinding,
    ILifecycleHooks,
    OneWayBinding,
} from '../../../common/angularData';
import { EmailPublishData } from '../emailPublishData';
import { MvLocation } from '../../../locations/mvLocation';
import { GraphqlService } from '../../../graphql/GraphqlService';
import { EmailPublishService } from './emailPublishService';

export class MultipleLocationEmailPublishCtrl extends BaseMultipleLocationPublishCtrl implements ILifecycleHooks {
    public static readonly $inject: string[] = [
        $scopeSID,
        $qSID,
        $timeoutSID,
        MvNotifier.SID,
        builderConstantsSID,
        I18nService.SID,
        MvLocation.SID,
        EmailPublishService.SID,
        GraphqlService.SID,
    ];

    // Component inputs
    public publishData!: EmailPublishData;

    // Internal properties
    protected inspector = new BuilderEmailDocumentInspector();

    // eslint-disable-next-line max-params
    public constructor(
        $scope: IScope,
        $q: IQService,
        $timeout: ng.ITimeoutService,
        mvNotifier: MvNotifier,
        builderConstants: BuilderConstants,
        i18n: I18nService,
        mvLocation: MvLocation,
        protected readonly emailPublishService: EmailPublishService,
        graphqlService: GraphqlService,
    ) {
        super($scope, $q, $timeout, mvNotifier, builderConstants, i18n, mvLocation, graphqlService);
    }

    public $onInit(): ng.IPromise<void> {
        this.originalLocation = this.publishData.location;
        this.chosenLocations = this.publishData.locations || [
            assignedLocationToLocationIdHierarchy(this.originalLocation),
        ];
        return this.start();
    }

    protected hasSubstitutionContent(): boolean {
        return this.inspector.hasSubstitutionContent(this.publishData.builderDocument);
    }

    protected hasTextSubstitutionContent(...keys: AutomaticTextPlaceholders[]): boolean {
        return this.inspector.hasTextSubstitutionContent(this.publishData.builderDocument, ...keys);
    }

    protected setTextSubstitution(key: string, value: string): void {
        this.publishData.textSubstitutionValues[key] = value;
    }

    protected updateSubstitutionValuesForLocation(location: AssignedLocation): angular.IPromise<void> {
        return this.updateTextSubstitutionValuesForLocation(location);
    }

    protected async uploadArtifact(): Promise<Upload> {
        return (
            await this.emailPublishService.rectifyAndUpload(
                this.publishData.builderDocument,
                this.publishData.htmlDocument,
                this.publishData.fileCache,
                this,
            )
        ).upload;
    }
}

export const multipleLocationEmailPublishConfig: ng.IComponentOptions = actualComponent(
    MultipleLocationEmailPublishCtrl,
    '/partials/contentBuilder/publish/multipleLocationPublish',
    {
        commonData: OneWayBinding,
        finishCallback: ExpressionBinding,
        publishCallback: ExpressionBinding,
        publishData: OneWayBinding,
    },
);

export const multipleLocationEmailPublishSID = 'multipleLocationEmailPublish';

angular.module('app').component(multipleLocationEmailPublishSID, multipleLocationEmailPublishConfig);
