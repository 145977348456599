import { DSFormGroup, Translate } from '@deltasierra/components';
import React from 'react';
import { buildClubNameWithCountString } from '../../build-club-name-with-count-string';
import { StyledLabel, StyledTextBox, StyledTextBoxContent } from '../../common';

export type ClubNameSectionProps = {
    clubName: string | null;
    locationCount: number;
};

export function ClubNameSection({ clubName, locationCount }: ClubNameSectionProps): JSX.Element {
    const clubNameString = React.useMemo(
        () => buildClubNameWithCountString(clubName ?? '', locationCount),
        [clubName, locationCount],
    );

    return (
        <DSFormGroup>
            <StyledLabel component="label" variant="inherit">
                <Translate keyId="BUILD.PUBLISH.CLUB_READY.CLUB" />
            </StyledLabel>
            <StyledTextBox>
                <StyledTextBoxContent>{clubNameString}</StyledTextBoxContent>
            </StyledTextBox>
        </DSFormGroup>
    );
}
ClubNameSection.displayName = 'ClubNameSection';
