import { BuilderTemplateId, BuilderTemplateWithBasicAssociations } from '@deltasierra/shared';
import { ClientId } from '@deltasierra/ids';
import { AngularEventDefinition } from '../../../common/events';

export type TemplateUpdatedMetadata = {
    builderTemplate: BuilderTemplateWithBasicAssociations;
};

export const templateDraftModeUpdatedEvent = new AngularEventDefinition<TemplateUpdatedMetadata>('templateDraftModeUpdated',
    'A template\'s draft mode has been changed via an admin function, and all instances of it must be updated in the UI.');

export const templateMetadataUpdatedEvent = new AngularEventDefinition<TemplateUpdatedMetadata>('templateMetadataUpdated',
    'A template\'s metadata has been deleted via an admin function, and all instances of it must be updated in the UI.');

export const templateDeletedEvent = new AngularEventDefinition<{ builderTemplateId: BuilderTemplateId }>('templateDeleted',
    'A template has been deleted via an admin function, and all instances of it must be removed from the UI.');

export type BuildableTemplateDuplicateRequestedMetadata = {
    templateId: string;
    clientId: ClientId;
};

export const buildableTemplateDuplicateRequestedEvent = new AngularEventDefinition<BuildableTemplateDuplicateRequestedMetadata>(
    'buildableTemplateDuplicateRequested',
    'A request to duplicate a buildable template has been made.',
);

export type BuildableTemplateDeleteRequestedMetadata = {
    templateId: string;
};

export const buildableTemplateDeleteRequestedEvent = new AngularEventDefinition<BuildableTemplateDeleteRequestedMetadata>(
    'buildableTemplateDeleteRequested',
    'A request to delete a buildable template has been made.',
);

export type BuildableTemplateTogglePublishStatusRequestedMetadata = {
    templateId: string;
    clientId: ClientId;
    isDraft: boolean;
};

export const buildableTemplateTogglePublishStatusRequestedEvent = new AngularEventDefinition<BuildableTemplateTogglePublishStatusRequestedMetadata>(
    'buildableTemplateTogglePublishStatusRequested',
    'A request to toggle a buildable template\'s publish status has been made.',
);