import { Button, Icon, Loading, PuppetTextInput, PuppetTextInputProps, Translate } from '@deltasierra/components';
import { isEmailStringValid, t } from '@deltasierra/shared';
import * as React from 'react';
import styled from 'styled-components';
import { useAngularServiceContext } from '../../../../common/componentUtils/angularServiceContexts';
import { withAngularIntegration } from '../../../../common/componentUtils/reactComponentRegistration';
import Form from '../../../../common/form/Form';
import { FieldIntegrationProps, withFormField } from '../../../../common/form/helpers';
import { useFuture } from '../../../../common/futures';

type PuppetTextInputWithFieldProps = FieldIntegrationProps<string> & Omit<PuppetTextInputProps, 'name' | 'value'>;

const PuppetTextInputWithField: React.FunctionComponent<PuppetTextInputWithFieldProps> = ({
    children,
    field,
    ...props
}) => (
    <>
        <PuppetTextInput
            className="form-control"
            {...props}
            disabled={field.disabled}
            name={field.name}
            required={field.required}
            value={field.value}
            onBlur={ev => field.handleBlurEvent(ev)}
            onChange={ev => field.handleChangeEvent(ev)}
        />
        {children}
    </>
);
PuppetTextInputWithField.displayName = 'PuppetTextInputWithField';

const PuppetTextInputField = withFormField()(PuppetTextInputWithField);

const CenteredLoading = styled(Loading)`
    margin: auto auto 10px auto;
`;

// eslint-disable-next-line max-lines-per-function
export const EmailNotificationsWell: React.FC = () => {
    const mvNotifier = useAngularServiceContext('mvNotifier');
    const agencyNotificationsService = useAngularServiceContext('AgencyNotificationsService');

    const [emailAddresses, updateEmailAddresses] = React.useState([] as string[]);

    const { invoke: fetchEmailAddresses, state: fetchEmailAddressesState } = useFuture(async () => {
        updateEmailAddresses(await agencyNotificationsService.getEmailAddresses());
    }, [agencyNotificationsService]);

    const { invoke: tryAddEmail, state: addEmailState } = useFuture(
        async emailAddress => {
            if (!isEmailStringValid(emailAddress)) {
                return;
            }
            try {
                await agencyNotificationsService.addEmailAddress(emailAddress);
                await fetchEmailAddresses();
            } catch (error) {
                mvNotifier.unexpectedError(error);
            }
        },
        [agencyNotificationsService, mvNotifier, fetchEmailAddresses],
    );

    const { invoke: removeEmail, state: removeEmailState } = useFuture(
        async (emailAddress: string) => {
            try {
                await agencyNotificationsService.removeEmailAddress(emailAddress);
                await fetchEmailAddresses();
            } catch (error) {
                mvNotifier.unexpectedError(error);
            }
        },
        [agencyNotificationsService, mvNotifier, fetchEmailAddresses],
    );

    // On first load, fetch email addresses
    React.useEffect(() => {
        void fetchEmailAddresses();
    }, [fetchEmailAddresses]);

    const isLoading = fetchEmailAddressesState.isLoading || addEmailState.isLoading || removeEmailState.isLoading;

    return (
        <div className="well">
            <h3>
                <Translate keyId="AGENCY.NOTIFICATIONS.AGENCY_EMAIL_NOTIFICATIONS" />
            </h3>
            <div>
                <div className="alert alert-info">
                    <Translate keyId="AGENCY.NOTIFICATIONS.NOTIFICATIONS_WILL_BE_SENT" />
                    <ul>
                        <li>
                            <Translate keyId="AGENCY.NOTIFICATIONS.NEW_SPECIAL_REQUESTS" />
                        </li>
                        <li>
                            <Translate keyId="AGENCY.NOTIFICATIONS.NEW_COMMENTS" />
                        </li>
                    </ul>
                </div>
                {emailAddresses.length > 0 && (
                    <ul className="list-group">
                        {emailAddresses.map(emailAddress => (
                            <li className="list-group-item" key={emailAddress}>
                                <span>{emailAddress}</span>
                                <button
                                    className="pull-right close"
                                    disabled={isLoading}
                                    onClick={async () => removeEmail(emailAddress)}
                                >
                                    <span aria-hidden="true">{String.fromCharCode(215)}</span>
                                </button>
                            </li>
                        ))}
                    </ul>
                )}
                {isLoading && <CenteredLoading size="small" />}
                <Form
                    initialValues={{
                        emailAddress: '',
                    }}
                    render={(props, inputNames) => (
                        <>
                            <PuppetTextInputField
                                disabled={props.isSubmitting}
                                label={t('AGENCY.NOTIFICATIONS.EMAIL_ADDRESS')}
                                name={inputNames.emailAddress}
                                required
                                type="email"
                            >
                                <Button
                                    className="space-left"
                                    disabled={props.isSubmitting || !props.isValid}
                                    size="sm"
                                    theme="primary"
                                    type="submit"
                                >
                                    <Icon className="icon-space" icon="plus" />
                                    <Translate keyId="COMMON.ADD" />
                                </Button>
                            </PuppetTextInputField>
                        </>
                    )}
                    validate={({ emailAddress }) => {
                        if (!isEmailStringValid(emailAddress.trim())) {
                            return {
                                emailAddress: t('COMMON.VALIDATION.INVALID_EMAIL'),
                            };
                        }
                        return undefined;
                    }}
                    onSubmit={async ({ emailAddress }, helpers) => {
                        await tryAddEmail(emailAddress.trim());
                        helpers.resetForm();
                    }}
                />
            </div>
        </div>
    );
};
EmailNotificationsWell.displayName = 'EmailNotificationsWell';

export default withAngularIntegration(EmailNotificationsWell, 'emailNotificationsWell', {});
