/// <reference path="../../../../../typings/browser.d.ts" />
import { ButtonSection as IButtonSection, SectionType } from '@deltasierra/shared';
import { Section } from './section';

export class ButtonSection extends Section implements IButtonSection {
    type!: SectionType.button;

    link = '';

    text = '';

    msoHtml = '';

    constructor(id: number) {
        super(id, SectionType.button);
    }
}
