import {
    AssignedLocation,
    assignedLocationToLocationIdHierarchy,
    GetAutomatedEmailResponse,
    linkToMemberEngager,
    LocationId,
    LocationIdHierarchy,
} from '@deltasierra/shared';
import { MvAuth } from '../../../account/mvAuth';
import { MvIdentity } from '../../../account/mvIdentity';
import {
    $kookiesSID,
    $locationSID,
    $routeParamsSID,
    $routeSID,
    $scopeSID,
    IKookies,
    IRoute,
    IRouteParams,
} from '../../../common/angularData';
import { DataUtils } from '../../../common/dataUtils';
import { InteractionUtils } from '../../../common/interactionUtils';
import { MvLocation } from '../../../locations/mvLocation';
import { BuilderConstants, builderConstantsSID } from '../../builderConstants';
import { EmailPublishData } from '../../email/emailPublishData';
import { MemberEngagerApiClient } from '../memberEngagerApiClient';
import { PartialAutomatedEmailPublishData } from './contentEditor';

import ILocationService = angular.ILocationService;
import IScope = angular.IScope;

enum TemplateView {
    Default,
    EditingContent,
    MultipleLocations,
    Publishing,
    Published,
}

export class MemberEngagerAutomatedEmailCtrl {
    public static readonly SID = 'MemberEngagerAutomatedEmailCtrl';

    public TemplateView = TemplateView;

    public view = TemplateView.Default;

    public canFindNewTemplate = false;

    public linkToMemberEngager = linkToMemberEngager;

    public automatedEmailData?: GetAutomatedEmailResponse;

    public publishData?: EmailPublishData;

    public locations?: LocationIdHierarchy[];

    public location?: AssignedLocation;

    public readonly multipleLocationsData = {
        canAccessMultipleLocations: false,
        multipleLocationsRequired: false,
    };

    public highlightingEnabled = false as const;

    public fetchAutomatedEmail = this.interactionUtils.createFuture<
        void,
        { automatedEmailId: number; locationId: LocationId }
    >(
        'fetch automated e-mail',
        context =>
            this.memberEngagerApiClient
                .getAutomatedEmail(context.locationId, context.automatedEmailId)
                .then(automatedEmailData => {
                    this.automatedEmailData = automatedEmailData;
                    if (automatedEmailData.digitalStackTemplate) {
                        this.view = TemplateView.EditingContent;
                    }
                }),
        {
            onError: err => {

                console.log(err);
                // Silently swallow the error, so that users can retry loading the template.
            },
        },
    );

    public fetchLocations = this.interactionUtils.createFuture('fetch location', context =>

        this.mvLocation.getAssignedLocations().then(locations => {
            this.locations = locations;
            if (this.locationId) {

                return this.mvLocation.getAssignedLocation(this.locationId).then(location => {
                    this.location = location;
                });
            }
        }),
    );

    public readonly fetchMultipleLocationAccess = this.interactionUtils.createFuture(
        'fetch multiple location access',
        _context =>
            this.mvAuth.canAccessMultipleLocations().then(result => {
                this.multipleLocationsData.canAccessMultipleLocations = result.result;
            }),
    );

    protected automatedEmailId!: number;

    private readonly locationId?: LocationId;


    public static readonly $inject: string[] = [
        $scopeSID,
        $locationSID,
        $routeSID,
        $routeParamsSID,
        $kookiesSID,
        DataUtils.SID,
        InteractionUtils.SID,
        MvAuth.SID,
        MvLocation.SID,
        builderConstantsSID,
        MemberEngagerApiClient.SID,
        MvIdentity.SID,
    ];


    public constructor(
        protected readonly $scope: IScope,
        protected readonly $location: ILocationService,
        protected readonly $route: IRoute,
        protected readonly $routeParams: IRouteParams,
        protected readonly $kookies: IKookies,
        protected readonly dataUtils: DataUtils,
        protected readonly interactionUtils: InteractionUtils,
        protected readonly mvAuth: MvAuth,
        protected readonly mvLocation: MvLocation,
        protected readonly builderConstants: BuilderConstants,
        protected readonly memberEngagerApiClient: MemberEngagerApiClient,
        protected readonly mvIdentity: MvIdentity,
    ) {
        this.parseAutomatedEmailIdOrRedirect();
        this.locationId = this.getLocationIdOrRedirect();
        if (this.automatedEmailId && this.locationId) {
            this.fetchAutomatedEmail.run({
                automatedEmailId: this.automatedEmailId,
                locationId: this.locationId,
            });
            this.fetchLocations.run({});
            this.fetchMultipleLocationAccess.run({});
        }
        if (this.mvIdentity.isAdmin() || this.mvIdentity.isManager()) {
            this.canFindNewTemplate = true;
        }
        this.$scope.$on(builderConstants.EVENTS.PUBLISH_FINISH, () => {
            this.view = TemplateView.Published;
        });
        this.$scope.$on(builderConstants.EVENTS.PUBLISH_CANCEL, () => {
            this.redirectToFirstPage();
        });
    }

    public redirectToTemplateSelection(): void {
        if (this.automatedEmailData) {
            this.$location.url(linkToMemberEngager(this.automatedEmailData.fitwareTemplate.code));
        }
    }

    public onClickRetryLoadingAutomatedEmail(): ng.IPromise<void> {
        if (this.automatedEmailId && this.locationId) {
            return this.fetchAutomatedEmail.run({
                automatedEmailId: this.automatedEmailId,
                locationId: this.locationId,
            });
        }
        return Promise.resolve();
    }

    public onSubmitPublishSuccessForm(): void {
        return this.redirectOuttaHere();
    }

    public cancelPublishing(): void {
        // If the user has edited the e-mail content but cancels, we're left with dirty state. So, let's scrap
        //  Everything and load the page anew.
        this.$route.reload();
    }

    public publishEditingContent(publishData: PartialAutomatedEmailPublishData): void {
        if (this.automatedEmailData && this.automatedEmailData.digitalStackTemplate && this.location) {
            this.publishData = {
                builderDocument: publishData.builderDocument,
                fileCache: publishData.fileCache,
                htmlDocument: publishData.htmlDocument,
                linkedAssetLibraryAssetIds: publishData.linkedAssetLibraryAssetIds,
                location: this.location,
                locations: [assignedLocationToLocationIdHierarchy(this.location)],
                templateId: this.automatedEmailData.digitalStackTemplate.builderTemplateId,
                textSubstitutionValues: publishData.textSubstitutionValues,
            };
            if (this.multipleLocationsData.canAccessMultipleLocations) {
                this.view = TemplateView.MultipleLocations;
            } else {
                this.view = TemplateView.Publishing;
            }
        } else {
            this.redirectToFirstPage();
            throw new Error('Invalid state: tried publishing automated e-mail content, but data was missing.');
        }
    }

    public onChooseLocations(locations: LocationIdHierarchy[]): void {
        if (this.publishData) {
            this.publishData.locations = locations;
            this.view = TemplateView.Publishing;
        } else {
            this.redirectToFirstPage();
            throw new Error("Invalid state: publish data must be populated by the time we're choosing locations.");
        }
    }

    public onGoBackFromPlatformPublish(): void {
        this.view = TemplateView.Publishing;
    }

    protected redirectToFirstPage(): void {
        if (this.automatedEmailData && this.automatedEmailData.digitalStackTemplate) {
            this.view = TemplateView.EditingContent;
        } else {
            this.view = TemplateView.Default;
        }
    }

    protected redirectOuttaHere(): void {
        this.$location.url(linkToMemberEngager());
    }

    protected parseAutomatedEmailIdOrRedirect(): void {
        const rawAutomatedEmailId = this.$routeParams.id;
        if (rawAutomatedEmailId) {
            const automatedEmailId = parseInt(rawAutomatedEmailId, 10);
            if (!isNaN(automatedEmailId)) {
                this.automatedEmailId = automatedEmailId;
                return;
            }
        }
        // We always need an automated e-mail ID. If it's missing or invalid, redirect.
        this.redirectOuttaHere();
    }

    protected getLocationIdOrRedirect(): LocationId | undefined {
        const locationId = this.$kookies.get('plannerLocation', Number);
        if (locationId) {
            return LocationId.from(locationId);
        } else {
            this.redirectOuttaHere();
            return undefined;
        }
    }
}

angular.module('app').controller(MemberEngagerAutomatedEmailCtrl.SID, MemberEngagerAutomatedEmailCtrl);
