/// <reference path="../../../typings/browser.d.ts" />
import { LocationIdHierarchy } from '@deltasierra/shared';
import { $scopeSID } from '../common/angularData';
import { $modalInstanceSID } from '../common/angularUIBootstrapData';
import { ModalInstance } from '../typings/angularUIBootstrap/modalService';
import IScope = angular.IScope;

export interface MvAdditionalLocationsCtrl extends IScope {
    selectedLocations: LocationIdHierarchy[];
    preselectedLocations?: LocationIdHierarchy[];
    done(): void;
    updateSelectedLocations(locations: LocationIdHierarchy[]): void;
}

angular.module('app').controller('mvAdditionalLocationsCtrl', [
    $scopeSID,
    $modalInstanceSID,
    function ($scope: MvAdditionalLocationsCtrl, $modalInstance: ModalInstance) {
        // $scope.preselectedLocations
        $scope.selectedLocations = [];

        $scope.done = function () {
            $modalInstance.close($scope.selectedLocations);
        };

        $scope.updateSelectedLocations = function (locations: LocationIdHierarchy[]) {
            $scope.selectedLocations = locations;
        };
    },
]);
