/* eslint-disable max-lines-per-function */
import * as React from 'react';
import { gql } from '@apollo/client';
import './AssetTableView.scss';
import { SortableHeaderButton, useLightboxDispatch, LightboxActionType } from '@deltasierra/components';
import { t } from '@deltasierra/shared';
import { AssetSortColumn, SortAssetsInput, SortDirection } from '../../../../../__graphqlTypes/globalTypes';
import { selectedAssets } from '../../../graphql';
import { translateFileTypeToString } from '../../context';
import { AssetTableViewItem } from './AssetTableViewItem';
import { AssetTableViewFragment } from './__graphqlTypes/AssetTableViewFragment';

const fragments = {
    AssetTableViewFragment: gql`
        fragment AssetTableViewFragment on AssetFileOrFolder {
            __typename
            ... on AssetFile {
                id
                url
                mimeType
                tags
                title
                type
                thumbnails {
                    large {
                        url
                    }
                }
            }
            ...AssetTableViewItemFragment
        }
        ${AssetTableViewItem.fragments.AssetTableViewItemFragment}
    `,
};

export type AssetTableViewProps = {
    assets: ReadonlyArray<AssetTableViewFragment>;
    hideButtons?: boolean;
    onPreview?: (index: number) => void;
    setSort: (sort: SortAssetsInput | null) => void;
    sort: SortAssetsInput | null;
};

export const AssetTableView: React.FunctionComponent<AssetTableViewProps> & { fragments: typeof fragments } = ({
    assets,
    hideButtons,
    onPreview,
    setSort,
    sort,
}) => {
    const selectedCount = assets.filter(asset => asset.selected).length;
    const allSelected = assets.length === selectedCount;
    const someSelected = selectedCount > 0;
    const inputRef = React.useRef<HTMLInputElement>(null);
    const dispatch = useLightboxDispatch();
    const assetFoldersListLength = React.useMemo(
        () => assets.filter(asset => asset.__typename === 'AssetFolder').length,
        [assets],
    );

    React.useEffect(() => {
        if (inputRef.current) {
            inputRef.current.indeterminate = !allSelected && someSelected;
        }
    });

    const getSortDirection = (field: AssetSortColumn): 'asc' | 'desc' | null => {
        if (sort?.field !== field) {
            return null;
        }
        return sort.direction === SortDirection.ASC ? 'asc' : 'desc';
    };

    const handleClickSort = (field: AssetSortColumn) => {
        if (sort?.field === field && sort.direction === SortDirection.ASC) {
            setSort({
                direction: SortDirection.DESC,
                field,
            });
        } else {
            setSort({
                direction: SortDirection.ASC,
                field,
            });
        }
    };

    const handleClickPreview = React.useCallback(
        (index: number) => {
            // Folders are listed first, but not passed down into the lightbox component
            dispatch({ payload: index - assetFoldersListLength, type: LightboxActionType.SetIndex });
            onPreview?.(index);
        },
        [dispatch, assetFoldersListLength, onPreview],
    );

    return (
        <table className="asset-list-table table table-striped">
            <thead>
                <tr>
                    <th>
                        <input
                            checked={allSelected}
                            ref={inputRef}
                            type="checkbox"
                            onChange={event => {
                                if (event.target.checked) {
                                    selectedAssets(
                                        assets.map(asset =>
                                            asset.__typename === 'AssetFile'
                                                ? { id: asset.id, type: translateFileTypeToString(asset.type) }
                                                : { id: asset.id, type: 'folder' },
                                        ),
                                    );
                                } else {
                                    selectedAssets([]);
                                }
                            }}
                        />
                    </th>
                    <th>
                        <SortableHeaderButton
                            direction={getSortDirection(AssetSortColumn.NAME)}
                            label={t('COMMON.NAME')}
                            onClick={() => handleClickSort(AssetSortColumn.NAME)}
                        />
                    </th>
                    <th>
                        <SortableHeaderButton
                            direction={getSortDirection(AssetSortColumn.DATEADDED)}
                            label={t('COMMON.DATE_ADDED')}
                            onClick={() => handleClickSort(AssetSortColumn.DATEADDED)}
                        />
                    </th>
                    <th>
                        <SortableHeaderButton
                            direction={getSortDirection(AssetSortColumn.EXPIRY)}
                            label={t('COMMON.EXPIRY')}
                            onClick={() => handleClickSort(AssetSortColumn.EXPIRY)}
                        />
                    </th>
                    <th>
                        <SortableHeaderButton
                            direction={getSortDirection(AssetSortColumn.FILESIZE)}
                            label={t('COMMON.FILE_SIZE')}
                            onClick={() => handleClickSort(AssetSortColumn.FILESIZE)}
                        />
                    </th>
                    <th />
                </tr>
            </thead>
            <tbody>
                {assets.map((asset, index) => (
                    <AssetTableViewItem
                        asset={asset}
                        hideButtons={hideButtons}
                        key={asset.id}
                        onClickPreview={() => {
                            handleClickPreview(index);
                        }}
                    />
                ))}
            </tbody>
        </table>
    );
};

AssetTableView.displayName = 'AssetTableView';
AssetTableView.fragments = fragments;
