/// <reference path="../../../typings/browser.d.ts" />
import { ApplyActionTo, DeletePlannerBody, PlannerOverviewDto, Untyped } from '@deltasierra/shared';
import { MvNotifier } from '../common/mvNotifier';
import { I18nService } from '../i18n/i18nService';
import { MultipleObjectActionProcessor } from '../locations/MultipleLocationActionProcessor';
import { MvPlanner } from '../planner/mvPlanner';

import IQService = angular.IQService;
import IHttpPromise = angular.IHttpPromise;

export class MvMultiplePlannerDeleteCtrl {
    public static SID = 'mvMultiplePlannerDeleteCtrl';

    public static readonly $inject: string[] = [
        '$q',
        '$timeout',
        '$modalInstance',
        MvNotifier.SID,
        MvPlanner.SID,
        'planners',
        'applyActionTo',
        I18nService.SID,
    ];

    public processor: MultipleObjectActionProcessor<PlannerOverviewDto>;

    public constructor(
        private $q: IQService,
        private $timeout: ng.ITimeoutService,
        private $modalInstance: Untyped,
        private mvNotifier: MvNotifier,
        private mvPlanner: MvPlanner,
        private planners: PlannerOverviewDto[],
        private applyActionTo: ApplyActionTo,
        private i18n: I18nService,
    ) {
        this.processor = new MultipleObjectActionProcessor<PlannerOverviewDto>(
            this.$q,
            this.i18n,
            this.$timeout,
            this.planners,
            {
                action: planner => this.onAction(planner),
                success: () => this.onSuccess(),
                partialSuccess: () => this.onPartialSuccess(),
                failure: () => this.onFailure(),
            },
        );
        void this.processor.start();
    }

    public onAction(planner: PlannerOverviewDto): IHttpPromise<void> {
        const payloadData: DeletePlannerBody = {
            locationId: planner.locationId,
            plannerId: planner.id,
            applyActionTo: planner.recurringPlannerId
                ? ApplyActionTo[this.applyActionTo]
                : ApplyActionTo[ApplyActionTo.current],
            date: planner.date,
            recurringPlannerId: planner.recurringPlannerId,
        };
        return this.mvPlanner.deletePlanner(payloadData);
    }

    public onSuccess() {
        this.mvNotifier.notify(this.i18n.text.overview.deletedPlannerEvents());
        this.$modalInstance.close();
    }

    public onPartialSuccess() {
        this.mvNotifier.notify(this.i18n.text.overview.deletedSomePlannerEvents());
        this.$modalInstance.close();
    }

    public onFailure() {
        this.mvNotifier.unexpectedError(this.i18n.text.overview.failedDeletePlannerEvents());
        this.$modalInstance.dismiss(this.$q.reject());
    }
}

angular.module('app').controller(MvMultiplePlannerDeleteCtrl.SID, MvMultiplePlannerDeleteCtrl);
