import Hotjar from '@hotjar/browser';
import { assertDefined } from '@deltasierra/shared';
import { interval } from 'rxjs';
import { first, take } from 'rxjs/operators';
import { MvIdentity } from '../account/mvIdentity';
import { SentryService } from './sentryService';

export class HotjarService {
    public static readonly SID = 'HotjarService';

    public static readonly $inject: string[] = [MvIdentity.SID, SentryService.SID];

    public constructor(private readonly mvIdentity: MvIdentity, private readonly sentryService: SentryService) {}

    public init(): void {
        const hotjarSiteId: unknown = (window as any).hotjarSiteId;

        if (!hotjarSiteId) {
            return;
        } else if (typeof hotjarSiteId !== 'number') {
            // eslint-disable-next-line no-console
            console.error('Invalid Hotjar ID');
            return;
        }

        Hotjar.init(hotjarSiteId, 6);
        interval(1000)
            .pipe(
                take(20),
                first(() => Hotjar.isReady()),
            )
            .subscribe(
                () => {
                    this.setUserContext();
                },
                error => {
                    this.sentryService.captureException('Failed to load Hotjar', error);
                },
            );
    }

    public setUserContext(): void {
        if (Hotjar.isReady() && this.mvIdentity.isAuthenticated()) {
            const { currentUser } = this.mvIdentity;
            assertDefined(currentUser);
            Hotjar.identify(currentUser.id.toString(), {
                'Created At': currentUser.createdAt,
                email: currentUser.username,
                'First Name': currentUser.firstName,
                'Has Accepted Invite': currentUser.hasAcceptedInvite,
                'Last Name': currentUser.lastName,
            });
        }
    }
}

angular.module('app').service(HotjarService.SID, HotjarService);
