import { gql, useMutation } from '@apollo/client';
import { GetBuilderTemplateDraftVariables } from '../components/BuilderTemplateDraftActionArea/BuilderTemplateDraftActionsButton/__graphqlTypes/GetBuilderTemplateDraft';
import { useAngularServiceContext } from '../../common/componentUtils/angularServiceContexts';
import { GET_BUILDER_TEMPLATE_DRAFT } from '../components/BuilderTemplateDraftActionArea/BuilderTemplateDraftActionsButton/BuilderTemplateDraftActionsButton';
import {
    DeleteBuilderTemplateLocationDraftForContentBuilder,
    DeleteBuilderTemplateLocationDraftForContentBuilderVariables,
} from './__graphqlTypes/DeleteBuilderTemplateLocationDraftForContentBuilder';

const DELETE_BUILDER_TEMPLATE_DRAFT = gql`
    mutation DeleteBuilderTemplateLocationDraftForContentBuilder($input: DeleteLocationDraftsInput!) {
        deleteBuilderTemplateLocationDrafts(input: $input) {
            __typename
            ... on DeleteLocationDraftsPayload {
                __typename
            }
            ... on DeleteLocationDraftsError {
                code
                message
            }
        }
    }
`;

export interface DeleteBuilderTemplateDraftMutationResponse {
    called: boolean;
    loading: boolean;
}

export function useDeleteBuilderTemplateDraftMutation(
    refetchQueryVariables: GetBuilderTemplateDraftVariables,
): [(draftIds: string[]) => Promise<void>, DeleteBuilderTemplateDraftMutationResponse] {
    const mvNotify = useAngularServiceContext('mvNotifier');

    const handleDeleteError = () => {
        mvNotify.unexpectedError('Unable to delete template');
    };

    const draftDeleted = (data: any) => {
        if (!data || (data && data.deleteBuilderTemplateLocationDrafts.__typename === 'DeleteLocationDraftsError')) {
            handleDeleteError();
        }
    };

    const [deleteDrafts, { called, loading }] = useMutation<
        DeleteBuilderTemplateLocationDraftForContentBuilder,
        DeleteBuilderTemplateLocationDraftForContentBuilderVariables
    >(DELETE_BUILDER_TEMPLATE_DRAFT, {
        onCompleted: draftDeleted,
        onError: handleDeleteError,
        refetchQueries: [
            {
                query: GET_BUILDER_TEMPLATE_DRAFT,
                variables: refetchQueryVariables,
            },
        ],
    });

    return [
        async draftIds => {
            const input = {
                locationDraftIds: draftIds,
            };

            await deleteDrafts({
                variables: {
                    input,
                },
            });
        },
        {
            called,
            loading,
        },
    ];
}
