import { Client, Nullable, TryThis, Untyped, Upload } from '@deltasierra/shared';
import { IPromise } from 'angular';
import { MvIdentity } from '../account/mvIdentity';
import { MvClient } from '../clients/mvClient';
import { DataUtils } from '../common/dataUtils';
import { InteractionUtils } from '../common/interactionUtils';
import { MvNotifier } from '../common/mvNotifier';
import { UploadContext, UploadService } from '../common/uploadService';
import { I18nService as i18nService } from '../i18n/i18nService';
import { ModalService } from '../typings/angularUIBootstrap/modalService';
import { MvTryThis } from './mvTryThis';
import { ThumbnailSelectorController } from './thumbnailSelector/controller';
import ILocationService = angular.ILocationService;
import IWindowService = angular.IWindowService;

class ForbiddenError extends Error {
    public constructor(public message: Untyped) {
        super(message);
    }
}

type TryThisModel = Pick<TryThis, 'clientId' | 'description' | 'id' | 'tags' | 'title' | 'uploads'> & {
    deletedUploads?: Upload[];
};

export class LearnArticleEditController {
    public static SID = 'mvTryThisEditCtrl';

    public static readonly $inject: string[] = [
        '$location',
        '$q',
        '$routeParams',
        '$window',
        '$modal',
        'Slug',
        UploadService.SID,
        MvNotifier.SID,
        MvIdentity.SID,
        MvClient.SID,
        MvTryThis.SID,
        InteractionUtils.SID,
        DataUtils.SID,
        i18nService.SID,
    ];

    public identity = this.mvIdentity;

    public trythis: Nullable<TryThisModel> = {
        clientId: null,
        deletedUploads: [],
        description: null,
        id: null,
        tags: [],
        title: null,
        uploads: [],
    };

    public clientTags: Untyped[] = [];

    public addingTag = false;

    public newtag = '';

    public checkedTags: { [key: string]: boolean } = {};

    public clients: Client[] | null = null;

    public loaded = false;

    public loading = {
        addTag: false,
        clientTags: false,
        clients: false,
        deleteTryThis: false,
        saving: false,
        trythis: false,
    };

    public pageTitle: string | null = null;

    public editing = false;

    public isApplicationWide = false;

    public deletedUploads: Untyped[] = [];

    public uploadContext?: UploadContext;

    // eslint-disable-next-line max-params
    public constructor(
        private $location: ILocationService,
        private $q: ng.IQService,
        private $routeParams: Untyped,
        private $window: IWindowService,
        private $modal: ModalService,
        private Slug: Untyped,
        private uploadService: UploadService,
        private notifier: MvNotifier,
        private mvIdentity: MvIdentity,
        private mvClient: MvClient,
        private mvTryThis: MvTryThis,
        private interactionUtils: InteractionUtils,
        private dataUtils: DataUtils,
        private i18n: i18nService,
    ) {
        void this.initPage();
    }

    public initPage() {
        const promises = [];
        promises.push(this.loadClients());

        const tryThisId = this.$routeParams.trythisId;
        this.editing = !!tryThisId;
        this.pageTitle =
            (this.editing && this.i18n.text.learn.editLearnItem()) || this.i18n.text.learn.addNewLearnItem();

        if (this.editing) {
            // Editing
            const p = this.interactionUtils
                .handleRemoteSimple(this, 'retrieve learn article', 'trythis', this.mvTryThis.getTryThis(tryThisId))
                .then(this.dataUtils.setScopeProperty(this, 'trythis'))
                .then(() => {
                    if (!this.trythis.clientId && !this.mvIdentity.isAdmin()) {
                        throw new ForbiddenError('You are not authorised to edit this item');
                    }
                })
                .then(() => this.updateClient(this.trythis.tags || []))
                .then(() => {
                    this.isApplicationWide = !this.trythis.clientId;
                })
                .catch(err => {
                    if (err instanceof ForbiddenError) {
                        this.goToTryThisDetails(tryThisId);
                    } else {
                        throw err;
                    }
                });
            promises.push(p);
        }
        return this.$q.all(promises);
    }

    public loadClients() {
        return this.interactionUtils
            .handleRemoteSimple(this, 'retrieve clients', 'clients', this.mvClient.getClients())
            .then(this.dataUtils.setScopeProperty(this, 'clients'))
            .then(() => {
                this.loaded = true; // Legacy
            });
    }

    public getUploadGlyph(ext: string) {
        return this.uploadService.getUploadGlyph(ext);
    }

    public selectFile(files: File[]) {
        if (files.length != 1) {
            this.notifier.expectedError(this.i18n.text.learn.maxUploadError());
        }
        const file = files[0];
        const promise = this.$q.all(
            this.uploadService.upload([file], 'trythis', this.trythis.uploads!, this, {
                suppressNotifications: true,
            }),
        );
        return this.uploadCustomThumbnail(file, promise);
    }

    public isUploadAVideo() {
        return (
            this.trythis.uploads &&
            this.trythis.uploads.length &&
            this.uploadService.isSupportedVideoExt(this.trythis.uploads[0].ext)
        );
    }

    public changeCustomThumbnail() {
        if (this.isUploadAVideo()) {
            return this.startThumbnailModal().then(result =>
                this.uploadThumbnail(this.trythis.uploads![0], result.thumbnail),
            );
        }
    }

    public deleteUpload(upload: Upload) {
        if (this.trythis.uploads) {
            const position = $.inArray(upload, this.trythis.uploads);
            this.deletedUploads.push(this.trythis.uploads[position]);
            if (position > -1) {
                this.trythis.uploads.splice(position, 1);
            }
        }
    }
    /* END UPLOAD */

    public cancel() {
        this.$window.history.back();
    }

    public updateTryThis() {
        const newTryThisData = this.trythis;
        newTryThisData.deletedUploads = this.deletedUploads;

        const tagArray = [];
        for (const key in this.checkedTags) {
            if (this.checkedTags[key]) {
                tagArray.push(key);
            }
        }
        newTryThisData.tags = tagArray;

        if (this.trythis.id) {
            // Editing
            const tryThisId = this.trythis.id;
            return this.interactionUtils
                .handleRemoteSimple(
                    this,
                    'update learn article',
                    'saving', // TODO: translate this
                    this.mvTryThis.updateTryThis(newTryThisData),
                )
                .then(() => {
                    this.notifier.notify(
                        this.i18n.text.learn.notifyLearnUpdated({ title: newTryThisData.title ?? '' }),
                    );
                    this.goToTryThisDetails(tryThisId);
                });
        } else {
            return this.interactionUtils
                .handleRemoteSimple(
                    this,
                    'create learn article',
                    'saving', // TODO: translate this
                    this.mvTryThis.createTryThis(newTryThisData),
                )
                .then(result => {
                    this.notifier.notify(
                        this.i18n.text.learn.notifyLearnCreated({ title: newTryThisData.title ?? '' }),
                    );
                    this.goToTryThisDetails(result.id);
                });
        }
    }

    public goToTryThisDetails(tryThisId: number) {
        this.$location.path(`/trythis/${tryThisId}`);
    }

    public updateClient(existingTags: string[]) {
        this.checkedTags = {};
        if (this.trythis.clientId) {
            return this.interactionUtils
                .handleRemoteSimple(
                    this,
                    'get tags for client',
                    'clientTags',
                    this.mvTryThis.getTryThisTags(this.trythis.clientId),
                )
                .then(tags => {
                    this.clientTags = tags;
                    for (const existingTag of existingTags) {
                        for (const tag of tags) {
                            if (existingTag == tag.title) {
                                this.checkedTags[tag.title] = true;
                            }
                        }
                    }
                });
        }
    }

    public addTag() {
        return this.interactionUtils
            .handleRemoteSimple(
                this,
                'add tag',
                'addTag',
                this.mvTryThis.createTag(this.trythis.clientId!, this.newtag),
            )
            .then(tag => {
                this.newtag = '';
                if (tag.title) {
                    this.clientTags.push(tag);
                    this.checkedTags[tag.title] = true;
                }
            });
    }

    public deleteTags() {
        const tagArray: string[] = [];
        for (const key in this.checkedTags) {
            if (this.checkedTags[key]) {
                tagArray.push(key);
            }
        }

        return this.interactionUtils
            .handleRemoteSimple(
                this,
                'delete tags from client',
                'deleteTags',
                this.mvTryThis.deleteTags(this.trythis.clientId!, tagArray),
            )
            .then(this.dataUtils.setScopeProperty(this, 'clientTags'))
            .then(() => {
                this.trythis.tags = this.dataUtils.filterByPredicate(
                    tag => tagArray.indexOf(tag) == -1,
                    this.trythis.tags || [],
                );
            })
            .then(() => {
                this.checkedTags = this.dataUtils.excludeProperties(tagArray, this.checkedTags);
            });
    }

    public checkTag() {
        // Console.log(this.checkedTags);
    }

    public deleteTryThis() {
        if (this.trythis.id) {
            return this.interactionUtils
                .handleRemoteSimple(
                    this,
                    'delete learn article',
                    'deleteTryThis',
                    this.mvTryThis.deleteTryThis(this.trythis.id),
                )
                .then(() => {
                    this.notifier.notify(this.i18n.text.learn.tryThisDeleted());
                    this.$location.path('/trythis');
                });
        }
    }

    public deselectClient() {
        this.checkedTags = {};
        this.clientTags = [];
        this.trythis.clientId = null;
    }

    public slugify(trythisObj: TryThis) {
        if (trythisObj) {
            return this.Slug.slugify(trythisObj.title);
        } else {
            return '';
        }
    }

    private uploadCustomThumbnail(file: File, waitFor: IPromise<any>) {
        if (this.uploadService.isSupportedVideo(file)) {
            // Choose a thumbnail
            return this.startThumbnailModal(file).then(result =>
                this.$q.resolve(waitFor).then(() => this.uploadThumbnail(this.trythis.uploads![0], result.thumbnail)),
            );
        }
    }

    private startThumbnailModal(videoFile?: File): IPromise<{ thumbnail: File }> {
        const instance = this.$modal.open({
            controller: ThumbnailSelectorController,
            controllerAs: 'ctrl',
            templateUrl: '/partials/trythis/thumbnailSelector/template',
            resolve: {
                videoFile: () => videoFile,
            },
        });

        return instance.result;
    }

    private uploadThumbnail(upload: Upload, thumbnail: File): IPromise<void> {
        return this.uploadService
            .uploadThumbnail(upload, thumbnail, 'trythis/thumbnail', this, {
                suppressNotifications: true,
            })
            .then(response => {
                upload.thumb256x256key = response.upload.thumb256x256key;
                upload.thumb256x256url = response.upload.thumb256x256url;
            });
    }
}

angular.module('app').controller(LearnArticleEditController.SID, LearnArticleEditController);
