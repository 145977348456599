import * as React from 'react';
import { GetClientsForCopyTemplateModal_brand_clients_edges_node as ClientNode } from './__graphqlTypes/GetClientsForCopyTemplateModal';
import { TargetClientCheckbox } from './TargetClientCheckbox';

export type TargetClientCheckboxContainerProps = Pick<React.HTMLAttributes<any>, 'style'> & {
    item: ClientNode;
    selected: ReadonlyArray<string>;
    onChange: (selected: string[]) => void;
};

export const TargetClientCheckboxContainer: React.FC<TargetClientCheckboxContainerProps> = ({
    item,
    onChange,
    selected,
    ...props
}) => {
    const isChecked = selected.includes(item.id);
    const imageSrc = React.useMemo(
        () => item.brand?.logo?.thumb256x256url ?? '/img/thumbnail-other.svg',
        [item],
    )

    return (
        <TargetClientCheckbox
            {...props}
            checked={isChecked}
            type="checkbox"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (event.target.checked) {
                    onChange([...selected, item.id]);
                } else {
                    onChange(selected.filter(id => id !== item.id));
                }
            }}
        >
            <img
                height={30}
                src={imageSrc}
                style={{ borderRadius: '50%', height: '30px', objectFit: 'contain', width: '30px' }}
                width={30}
            />
            <div>{item.title}</div>
        </TargetClientCheckbox>
    );
};
TargetClientCheckboxContainer.displayName = 'TargetClientCheckboxContainer';