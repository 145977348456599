import { gql } from '@apollo/client';

export const GET_FACEBOOK_PAGE_NAMES_QUERY = gql`
    query GetFacebookPageNamesQuery($locationIds: [ID!]!) {
        locations(ids: $locationIds) {
            edges {
                node {
                    id
                    facebook {
                        ... on Facebook {
                            id
                            page {
                                id
                                name
                            }
                        }
                    }
                }
            }
        }
    }
`;
