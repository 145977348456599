import { isImageMimeType, UploadCategory, UploadId } from '@deltasierra/shared';
import { mergeMap } from 'rxjs/operators';
import { ApolloClient, FetchResult, gql } from '@apollo/client';
import { OperatorFunction, pipe } from 'rxjs';
import { getImage } from '../../UploadButton/utils/getImage';
import { InitializeFileUpload, InitializeFileUploadVariables } from './__graphqlTypes/InitializeFileUpload';
import { FileUploadError } from './common';

export type InitializeUploadContext = {
    client: Pick<ApolloClient<unknown>, 'mutate'>;
};

export type InitializeUploadInput = {
    file: File;
    category: UploadCategory;
};

export type InitializeUploadOutput = {
    file: File;
    filename: string;
    legacyUploadId: UploadId;
    signedUrl: string;
    uploadId: string;
};

export function initializeUploadStep({
    client,
}: InitializeUploadContext): OperatorFunction<InitializeUploadInput, InitializeUploadOutput> {
    return pipe(
        mergeMap(async ({ category, file }) => {
            const image = isImageMimeType(file.type) ? await getImage(file) : null;
            let initializeUploadResults: FetchResult<InitializeFileUpload>;

            try {
                initializeUploadResults = await client.mutate<InitializeFileUpload, InitializeFileUploadVariables>({
                    mutation: gql`
                        mutation InitializeFileUpload($input: InitializeUploadInput!) {
                            uploadInitialize(input: $input) {
                                upload {
                                    id
                                    legacyId
                                    filename
                                }
                                signedUrl
                            }
                        }
                    `,
                    variables: {
                        input: {
                            category,
                            fileName: file.name,
                            fileType: file.type,
                            height: image?.height,
                            size: file.size,
                            width: image?.width,
                        },
                    },
                });
            } catch (error: unknown) {
                if (error instanceof Error) {
                    throw new FileUploadError({ error, message: 'Unable to start upload' });
                } else {
                    // eslint-disable-next-line no-console
                    console.error(error);
                    throw new FileUploadError();
                }
            }

            if (!initializeUploadResults.data) {
                throw new FileUploadError();
            }

            const { signedUrl, upload } = initializeUploadResults.data.uploadInitialize;
            return {
                file,
                filename: upload.filename,
                legacyUploadId: UploadId.from(upload.legacyId),
                signedUrl,
                uploadId: upload.id,
            };
        }),
    );
}
