import { Untyped, GalleryPlannerDetails } from '@deltasierra/shared';

import { UploadService } from '../../../common/uploadService';
import { InteractionUtils } from '../../../common/interactionUtils';
import { MvPlanner } from '../../../planner/mvPlanner';
import { DataUtils } from '../../../common/dataUtils';
import { IntroService } from '../../../intro/introService';
import { IntroDataService } from '../../../intro/introDataService';

export class PlannerDetailsBarController {

    loading = {
        plannerDetails: false,
    };

    isExpanded = false;

    plannerId?: number;

    plannerDetails?: GalleryPlannerDetails;

    change?: (context : Untyped) => void;

    static readonly $inject : string[] = [
        '$scope',
        UploadService.SID,
        InteractionUtils.SID,
        DataUtils.SID,
        MvPlanner.SID,
        IntroService.SID,
        IntroDataService.SID,
    ];

    constructor(
        private $scope: ng.IScope,
        private uploadService: UploadService,
        private interactionUtils: InteractionUtils,
        private dataUtils: DataUtils,
        private mvPlanner: MvPlanner,
        private introService: IntroService,
        private introDataService: IntroDataService,
    ) {}

   public $onInit(): void {
        this.$scope.$watch(() => this.plannerId, () => this.retrievePlannerDetails());
    }

    retrievePlannerDetails() {
        if (this.plannerId && (!this.plannerDetails || this.plannerId != this.plannerDetails.id)) {
            if (this.introService.isIntroActive('build')) {
                this.plannerDetails = this.introDataService.getExamplePlanner();
                this.notifyOnPlannerDetailsChange();
            } else {
                return this.interactionUtils.handleRemote(this, 'retrieve planner details', 'plannerDetails',
                    this.mvPlanner.getPlannerGalleryDetails(this.plannerId),
                ).then(this.dataUtils.setScopeProperty(this, 'plannerDetails'))
                    .then(() => this.notifyOnPlannerDetailsChange());
            }
        }
    }

    notifyOnPlannerDetailsChange() {
        // NOTE: not called if we fail to retrieve a planner's details. Need to consider failure scenario.
        if (this.change) {
            this.change({ plannerDetails: this.plannerDetails });
        }
    }

    togglePlannerDetails() {
        this.isExpanded = !this.isExpanded;
    }

    getChannelsText() {
        if (this.plannerDetails && this.plannerDetails.channels && this.plannerDetails.channels.length > 0) {
            return this.plannerDetails.channels.join(', ');
        } else {
            return '';
        }
    }

    getUploadGlyph(ext : Untyped) {
        return this.uploadService.getUploadGlyph(ext);
    }
}
