import { DSTextField } from '@deltasierra/components';
import { useControlledSearchInput } from '@deltasierra/react-hooks';
import * as React from 'react';

export type SearchBarProps = {
    debounceTime?: number;
    initialValue?: string;
    placeholder?: string;
    onSearchChange: (val: string) => void;
    dataCy?: string;
};

/**
 * SearchBar
 * The search bar is a component that utilizes the `useControlledSearchInput`.
 * This allows the search bar to handle it's own state and only notify a parent when the debounced value changes
 * Performance should be much better, as the rest of a parent's children don't need to be rerendered on every key press
 *
 * @param props - Props object sent to the search bar
 * @param props.debounceTime - amount of time to wait before calling `onSearchChange`
 * @param props.initialValue - prefilled value in the search bar
 * @param props.placeholder - Placeholder text for the search bar
 * @param props.onSearchChange - callback for when debounced value changes
 *
 * @returns SearchBar
 */
export function SearchBar(props: SearchBarProps): JSX.Element {
    const { debounceTime, initialValue, onSearchChange, placeholder } = props;

    const { inputProps } = useControlledSearchInput({
        initialValue,
        interval: debounceTime,
        onSearchTermValueChange: onSearchChange,
    });

    return (
        <DSTextField
            InputProps={{ style: { backgroundColor: 'white', marginBottom: '12px' } }}
            aria-label={placeholder}
            data-cy={props.dataCy}
            fullWidth
            placeholder={placeholder}
            variant="outlined"
            {...inputProps}
        />
    );
}
SearchBar.displayName = 'SearchBar';
