import { Section as ISection, SectionType } from '@deltasierra/shared';

export class Section implements ISection {
    title = 'New Layer';

    visible = true;

    y = 0;

    constructor(
        public id : number,
        public type : SectionType = SectionType.text,
    ) {

    }
}
