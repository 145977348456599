/// <reference path="../../../../../typings/browser.d.ts" />
import { Html, IBuilderEmailDocument, ImageSection, isImageSection, Upload } from '@deltasierra/shared';
import { IPromise, IQResolveReject } from 'angular';
import { $qSID, $timeoutSID } from '../../../common/angularData';
import { FileCache } from '../../../common/fileCache';
import { UploadMap, UploadScope, UploadService } from '../../../common/uploadService';
import { ImageLoaderService } from '../../imageLoaderService';

interface HasLocation {
    location: string;
}

interface ImageToUpload {
    file: File | null;
    section: HasLocation;
}

export class EmailPublishService {
    public static readonly SID = 'EmailPublishService';

    public static readonly $inject: string[] = [$qSID, $timeoutSID, ImageLoaderService.SID, UploadService.SID];

    public constructor(
        protected readonly $q: ng.IQService,
        protected readonly $timeout: ng.ITimeoutService,
        protected readonly imageLoaderService: ImageLoaderService,
        protected readonly uploadService: UploadService,
    ) {}

    public rectifyAllImagesAndConvertToString(
        builderDocument: IBuilderEmailDocument,
        htmlDocument: HTMLDocument,
        fileCache: FileCache,
        uploadScope?: UploadScope,
    ): IPromise<Html> {
        return this.uploadImages(builderDocument, fileCache, uploadScope || {}).then(imageUploadMap => {
            this.rectifyImages(htmlDocument, imageUploadMap);
            this.stripScriptTags(htmlDocument.documentElement);
            return Html.from(htmlDocument.documentElement.outerHTML);
        });
    }

    public async rectifyAndUpload(
        builderDocument: IBuilderEmailDocument,
        htmlDocument: HTMLDocument,
        fileCache: FileCache,
        uploadScope?: UploadScope,
        textSubstitutionCallback?: (html: Html) => Html,
    ): Promise<{ upload: Upload; html: string }> {
        let html = await this.rectifyAllImagesAndConvertToString(
            builderDocument,
            htmlDocument,
            fileCache,
            uploadScope ?? {},
        );

        if(typeof textSubstitutionCallback === 'function') {
            html = textSubstitutionCallback(html);
        }

        const upload = await this.uploadHtml(html, uploadScope ?? {});

        return { html, upload };
    }

    /**
     * Mutates the passed in object, to disable "highlighting". (This needs to be fed to the e-mail iframe directive
     * somehow).
     * It then gets the HTML in the next digest cycle, so that AngularJS has time to see the changed value, and will
     * strip out the highlighting styles from the HTML.
     *
     * @param renderState -
     * @param renderState.highlightingEnabled -
     * @param elementSelector -
     * @returns The compiled HTMLDocument in a promise
     */
    public getCompiledTemplateHtml(
        renderState: { highlightingEnabled: boolean },
        elementSelector = '#emailTemplateDisplay',
    ): ng.IPromise<HTMLDocument> {
        const originalState = {
            highlightingEnabled: renderState.highlightingEnabled,
        };
        renderState.highlightingEnabled = false;
        return this.$timeout(
            () => {
                const value = $(elementSelector).contents()[0].cloneNode(true) as HTMLDocument;
                renderState.highlightingEnabled = originalState.highlightingEnabled;
                this.stripScriptTags(value.documentElement);
                return value;
            },
            0,
            true,
        );
    }

    protected async uploadHtml(html: Html, uploadScope: UploadScope): Promise<Upload> {
        const htmlFile = new Blob([html], {
            type: 'text/html',
        });
        const outputArray: Upload[] = [];
        return this.$q
            .all(
                this.uploadService.upload([htmlFile], 'emailHtml', outputArray, uploadScope, {
                    suppressNotifications: true,
                }),
            )
            .then(() => outputArray[0]);
    }

    protected resolveImageFile(section: ImageSection, fileCache: FileCache): IPromise<ImageToUpload> {
        return this.$q((resolve: IQResolveReject<File | null>, reject: IQResolveReject<Error>) => {
            if (section.locationType === 'local') {
                return resolve(fileCache.get(section.location!));
            } else if (section.locationType === 'external') {
                return this.imageLoaderService.loadFileFromExternal(section.location!).then(resolve).catch(reject);
            } else {
                return reject(new Error(`Unknown location type for image section: ${section.locationType}`));
            }
        }).then((file: File | null) => ({
            file,
            section: section as HasLocation,
        }));
    }

    protected uploadImages(
        document: IBuilderEmailDocument,
        fileCache: FileCache,
        uploadScope: UploadScope,
    ): IPromise<UploadMap> {
        // Re-upload all images.
        const sections = document.sections;
        const promises = [];
        for (const section of sections) {
            if (isImageSection(section)) {
                const resolvedImageFilePromise = this.resolveImageFile(section, fileCache);
                promises.push(resolvedImageFilePromise);
            }
        }
        return this.$q
            .all<ImageToUpload>(promises)
            .then((entries: ImageToUpload[]) => {
                const toUploadMap: { [key: string]: File } = {};
                for (const entry of entries) {
                    if (entry.file) {
                        toUploadMap[entry.section.location] = entry.file;
                    }
                }
                return toUploadMap;
            })
            .then(toUploadMap =>
                this.uploadService.uploadMap(toUploadMap, 'emailResources', uploadScope, {
                    suppressNotifications: true,
                }),
            );
    }

    protected rectifyImages(doc: HTMLDocument, imageUploadMap: { [key: string]: Upload }): void {
        for (const key in imageUploadMap) {
            if (Object.prototype.hasOwnProperty.call(imageUploadMap, key)) {
                const newSrc = `http:${imageUploadMap[key].url}`; // Hmmm.
                const nodes = $(doc).find(`img[src="${key}"]`);
                nodes.attr('src', newSrc);
            }
        }
    }

    private stripScriptTags(root: HTMLElement) {
        const scriptElements = root.getElementsByTagName('script');
        for (let i = 0; i < scriptElements.length; i++) {
            const element = scriptElements[i];
            if (element.parentNode) {
                element.parentNode.removeChild(element);
            } else if (typeof element.outerHTML !== 'undefined') {
                element.outerHTML = '';
            } else {
                element.textContent = '';
            }
        }
    }
}

angular.module('app').service(EmailPublishService.SID, EmailPublishService);
