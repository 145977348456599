import { createContextAndUseContextHook } from '@deltasierra/react-hooks';

export type SetCurrentAssetContext = (id: string | undefined) => void;

export const [
    CurrentAssetContext,
    useCurrentAssetContext,
] = createContextAndUseContextHook<[string | undefined, SetCurrentAssetContext]>('CurrentAssetContext');

export type AssetLibrarySettings = {
    hideOperations?: boolean;
    hideBin?: boolean;
    mediaFilter?: ReadonlyArray<('document' | 'image' | 'video')>;
    removeAnchorOnFolderIcon?: boolean;
}

export const [
    AssetLibrarySettingsContext, useAssetLibrarySettings,
] = createContextAndUseContextHook<AssetLibrarySettings>('AssetLibrarySettingsContext');
