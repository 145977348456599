/* eslint-disable sort-keys */
import { TwitterReportStats } from '@deltasierra/shared';
import { ReportTable, StatsEntry, TextAlignClass } from '../reportTable/reportTable';
import { compareNumbers } from '../compare';
import { CreateReportTableOptions } from './common';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function createTwitterReportTable(options: CreateReportTableOptions) {
    return new ReportTable<StatsEntry<TwitterReportStats>, TwitterReportStats>({
        columns: [
            {
                label: () => options.i18n.text.report.location(),
                cssClass: TextAlignClass.Left,
                format: options.formatters.formatString,
                getValue: entry => entry.location.locationTitle,
                getTotal: () => options.i18n.text.report.total(),
            },
            {
                label: () => options.i18n.text.report.followersFrom(),
                cssClass: TextAlignClass.Right,
                format: options.formatters.formatNumber,
                getValue: entry => entry.followers.count - entry.followers.new,
                getTotal: totals => totals.followers.count - totals.followers.new,
                compare: compareNumbers,
            },
            {
                label: () => options.i18n.text.report.followersTo(),
                cssClass: TextAlignClass.Right,
                format: options.formatters.formatNumber,
                getValue: entry => entry.followers.count,
                getTotal: totals => totals.followers.count,
                compare: compareNumbers,
            },
            {
                label: () => options.i18n.text.report.newFollowers(),
                cssClass: TextAlignClass.Right,
                format: options.formatters.formatNumber,
                getValue: entry => entry.followers.new,
                getTotal: totals => totals.followers.new,
                compare: compareNumbers,
            },
            {
                label: () => options.i18n.text.report.followersGrowth(),
                cssClass: TextAlignClass.Right,
                format: options.formatters.formatPercentage,
                getValue: entry => entry.followers.growth,
                getTotal: totals => totals.followers.growth,
                compare: compareNumbers,
            },
            {
                label: () => options.i18n.text.report.newPosts(),
                cssClass: TextAlignClass.Right,
                format: options.formatters.formatNumber,
                getValue: entry => entry.statuses.new,
                getTotal: totals => totals.statuses.new,
                compare: compareNumbers,
            },
            {
                label: () => options.i18n.text.report.totalPosts(),
                cssClass: TextAlignClass.Right,
                format: options.formatters.formatNumber,
                getValue: entry => entry.statuses.count,
                getTotal: totals => totals.statuses.count,
                compare: compareNumbers,
            },
        ],
    });
}
