/* eslint-disable */
import { useQuery } from '@apollo/client';
import {
    DSButton,
    DSDialog,
    DSDialogActions,
    DSDialogContent,
    DSDialogTitle,
    DSList,
    DSTypography,
    Loading,
} from '@deltasierra/components';
import { isNotNullOrUndefined, t } from '@deltasierra/shared';
import moment from 'moment-timezone';
import * as React from 'react';
import { GET_POST_ARTIFACT_GROUP } from './GetPostArtifactGroup.query';
import { GetPostArtifactGroup, GetPostArtifactGroupVariables } from './__graphqlTypes/GetPostArtifactGroup';

type LocationWithPostScheduledDate = {
    id: string;
    locationTitle: string;
    scheduledDate: Date | null;
};

export type MoreLocationsModalProps = {
    isOpen: boolean;
    postArtifactId: string;
    onClose?: () => void;
};

export const MoreLocationsModal: React.FC<MoreLocationsModalProps> = props => (
    <DSDialog maxWidth="md" open={props.isOpen}>
        {props.isOpen && <MoreLocationsModalInner {...props} />}
    </DSDialog>
);

MoreLocationsModal.displayName = 'MoreLocationsModal';

export const MoreLocationsModalInner: React.FC<MoreLocationsModalProps> = props => {
    const { data, loading } = useQuery<GetPostArtifactGroup, GetPostArtifactGroupVariables>(GET_POST_ARTIFACT_GROUP, {
        fetchPolicy: 'network-only',
        variables: { id: props.postArtifactId },
    });
    const [locationList, setLocationList] = React.useState<LocationWithPostScheduledDate[]>();
    React.useEffect(() => {
        if (!loading) {
            const postArtifact = data?.postArtifact.__typename === 'PostArtifact' ? data.postArtifact : undefined;
            const postArtifacts = postArtifact?.parent
                ? postArtifact.parent.postArtifacts.edges.map(edge => edge.node)
                : [postArtifact];
            const postArtifactsLocationAndScheduledDate = postArtifacts
                .map(node => {
                    if (!node) {
                        return null;
                    }
                    return {
                        id: node.id,
                        locationTitle: node.location.title,
                        scheduledDate: node.timestamps.scheduled ? new Date(node.timestamps.scheduled) : null,
                    };
                })
                .filter(isNotNullOrUndefined);

            const sortedLocationsWithScheduledDate = postArtifactsLocationAndScheduledDate.sort(
                (artifact1, artifact2) =>
                    artifact1.locationTitle.toLowerCase() < artifact2.locationTitle.toLowerCase() ? -1 : 1,
            );
            setLocationList(sortedLocationsWithScheduledDate);
        }
    }, [data, loading]);

    return (
        <>
            <DSDialogTitle>{t('COMMON.LOCATIONS')}</DSDialogTitle>
            <DSDialogContent>
                {loading && <Loading />}
                {!loading && locationList && (
                    <DSList>
                        {locationList.map(location => (
                            <li key={location.id}>
                                <DSTypography color="textPrimary" component="p" variant="body1">
                                    <b>{location.locationTitle}</b>
                                    {location.scheduledDate && (
                                        <>{` - ${moment
                                            .tz(location.scheduledDate.toUTCString(), moment.tz.guess())
                                            .format('h:mm A, ddd Do MMM z')}`}</>
                                    )}
                                </DSTypography>
                            </li>
                        ))}
                    </DSList>
                )}
            </DSDialogContent>
            <DSDialogActions>
                <DSButton color="primary" variant="contained" onClick={props.onClose}>
                    {t('COMMON.CLOSE')}
                </DSButton>
            </DSDialogActions>
        </>
    );
};

MoreLocationsModalInner.displayName = 'MoreLocationsModalInner';
