import { IHttpService } from 'angular';
import { WorkflowStartResult, justParams, LinkedInEndpoints, LocationId, PublishMultiImageRequest } from '@deltasierra/shared';


import { SentryService } from '../../common/sentryService';
import { invokeApiRoute } from '../../common/httpUtils';
import { $httpSID } from '../../common/angularData';
import { GraphqlService } from '../../graphql/GraphqlService';
import { PUBLISH_LINKED_IN_IMAGE } from './publish-linked-in-image.mutation';
import { PublishLinkedInImage, PublishLinkedInImageVariables } from './__graphqlTypes/PublishLinkedInImage';

export type LinkedInChannelData = { comment?: string | null };
export class LinkedInPublishService {
    public static SID = 'linkedInPublishService';

    public static readonly $inject: string[] = [$httpSID, GraphqlService.SID, SentryService.SID];

    public constructor(
        private $http: IHttpService,
        private readonly graphqlService: GraphqlService,
        private readonly sentryService: SentryService,
    ) {}

    public async publishPhoto(input: PublishMultiImageRequest<LinkedInChannelData>): Promise<WorkflowStartResult> {
        const gqlClient = this.graphqlService.getClient();
        const result = await gqlClient.mutate<PublishLinkedInImage, PublishLinkedInImageVariables>({
            mutation: PUBLISH_LINKED_IN_IMAGE,
            variables: {
                input: {
                    ...input,
                    channelData: { comment: input.channelData.comment },
                    scheduledTime: input.scheduledTime?.toISOString(),
                },
            },
        });
        if (result.data?.publishLinkedInImage.__typename === 'PublishError' || !result.data) {
            this.sentryService.captureException(
                'Failed to publish image to LinkedIn via "PUBLISH_LINKED_IN_IMAGE" GraphQL query.',
                {
                    errors:
                        result.data?.publishLinkedInImage.__typename === 'PublishError'
                            ? result.data.publishLinkedInImage.message
                            : undefined,
                },
            );
            throw new Error('Failed to publish image to LinkedIn');
        } else {
            return {
                executionArn: result.data.publishLinkedInImage.workflow.id,
                executionId: result.data.publishLinkedInImage.workflow.executionId,
                startDate: new Date(result.data.publishLinkedInImage.workflow.startDate),
            };
        }
    }

    public canPostToLinkedInPageForLocation(locationId: LocationId): ng.IPromise<boolean> {
        return invokeApiRoute(
            this.$http,
            LinkedInEndpoints.canPostForLocation,
            justParams({
                locationId,
            }),
        ).then(result => result.result);
    }
}

angular.module('app').service('linkedInPublishService', LinkedInPublishService);
