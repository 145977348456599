import { client, currentLocationId } from './index';

export class GraphqlService {
    public static readonly SID = 'GraphqlService';

    public static readonly $inject: string[] = [];

    public getClient(): typeof client {
        return client;
    }

    public async resetCache(): Promise<void> {
        this.getClient().stop();
        await this.getClient().resetStore();
    }

    public setCurrentLocationId(id: string): void {
        currentLocationId(id);
    }
}

angular.module('app').service(GraphqlService.SID, GraphqlService);
