import { useQuery } from '@apollo/client';
import { isNotNullOrUndefined, t } from '@deltasierra/shared';

import { relayConnectionToArray } from '../../../graphql/utils';
import { GET_FACEBOOK_PAGE_NAMES_QUERY } from './useGetFacebookPageDescription.queries';
import {
    GetFacebookPageNamesQuery,
    GetFacebookPageNamesQueryVariables,
} from './__graphqlTypes/GetFacebookPageNamesQuery';

export type LocationAndFacebookPageName = {
    facebookPageName?: string;
    locationId: string;
};

export function useGetFacebookPageDescription(locationIds: string[]): {
    description?: string;
    loading: boolean;
} {
    const { data, loading } = useQuery<GetFacebookPageNamesQuery, GetFacebookPageNamesQueryVariables>(
        GET_FACEBOOK_PAGE_NAMES_QUERY,
        {
            variables: {
                locationIds,
            },
        },
    );

    if (loading) {
        return {
            loading: true,
        };
    }

    const resultingIdsAndNames = relayConnectionToArray(data?.locations)
        .map(location =>
            location.facebook.__typename === 'Facebook' && location?.facebook?.page?.name
                ? {
                      facebookPageName: location.facebook.page.name,
                      locationId: location.id,
                  }
                : undefined,
        )
        .filter(isNotNullOrUndefined);

    let description;
    const MAX_PAGES = 5;
    const pageNames: string[] = resultingIdsAndNames.map(result => result.facebookPageName).slice(0, MAX_PAGES - 1);

    if (pageNames.length === 0) {
        description = t('BUILD.PUBLISH.FACEBOOK.PAGES_COUNT', { count: locationIds.length });
    } else {
        if (resultingIdsAndNames.length > MAX_PAGES) {
            const diff = resultingIdsAndNames.length - MAX_PAGES;
            pageNames.push(t('BUILD.PUBLISH.FACEBOOK.PAGE_LIST_REMAINING', { count: diff }));
        }
        description = pageNames.join(', ');
    }

    return {
        description,
        loading: false,
    };
}
