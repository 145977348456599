/// <reference path="../../../typings/browser.d.ts" />
import * as linq from 'linq';
import { GuidedTour, IntroName, assertNever } from '@deltasierra/shared';
import { IScope } from 'angular';

import { isNullOrUndefined } from '@deltasierra/type-utilities';
import { UserService } from '../account/userService';
import { $locationSID, $qSID, $rootScopeSID } from '../common/angularData';
import { ConfirmModal, confirmModalSID } from '../common/confirmModal';
import { I18nService } from '../i18n';
import { IntroDefinitionService } from './introDefinitionService';
import { IntroOptions, IntroWrapper, IntroWrapperFactory } from './introWrapper';
import IPromise = angular.IPromise;

export interface CompletionPage {
    title?: string;
    heading: string;
    subheading: string;
    tip: string;
}

export interface IntroPage {
    options: IntroOptions;
    route: string;
    softComplete?: boolean;
}
export interface IntroPages extends Array<IntroPage> {}
export interface Intro {
    name: IntroName;
    title: string;
    pages: IntroPages;
    completionPage: CompletionPage | null;
    cannotBeRestarted?: boolean;
    accessCheck?: () => boolean;
}

export class IntroService {
    public static readonly SID = 'introService';

    public static readonly $inject: string[] = [
        $rootScopeSID,
        $locationSID,
        $qSID,
        I18nService.SID,
        IntroWrapperFactory.SID,
        UserService.SID,
        IntroDefinitionService.SID,
        confirmModalSID,
    ];

    private currentIntro: Intro | null = null;

    private currentPageIndex = 0;

    private currentCompleteIntroPromise: IPromise<void> | null = null;

    private completionPage: CompletionPage | null = null;

    // eslint-disable-next-line @typescript-eslint/ban-types
    private navigationUnwatcher: Function | undefined;

    // eslint-disable-next-line max-params
    public constructor(
        private readonly $rootScope: ng.IRootScopeService,
        private readonly $location: ng.ILocationService,
        private readonly $q: ng.IQService,
        private readonly i18n: I18nService,
        private readonly introWrapperFactory: IntroWrapperFactory,
        private readonly userService: UserService,
        private readonly introDefinitionService: IntroDefinitionService,
        private readonly confirmModal: ConfirmModal,
    ) {}

    public getIntros(): Intro[] {
        return this.introDefinitionService.getIntros();
    }

    public isIntroActive(name: IntroName): boolean {
        if (this.currentIntro !== null) {
            return this.currentIntro.name === name;
        } else {
            return false;
        }
    }

    public isAnyIntroActive(): boolean {
        return this.currentIntro !== null;
    }

    public getIntroOptions(name: IntroName): IntroOptions {
        const page = this.getCurrentPage(name);
        return page.options;
    }

    public getCurrentPage(name: IntroName): IntroPage {
        const intro = this.getIntro(name);
        if (this.currentPageIndex < 0 || this.currentPageIndex >= intro.pages.length) {
            throw new Error('Ran out of pages in the current intro! Whoops.'); // TODO: translate this
        } else {
            return intro.pages[this.currentPageIndex];
        }
    }

    public setCurrentIntro(name: IntroName): void {
        this.currentIntro = this.getIntro(name);
    }

    public setUpIntro(name: IntroName, scope: IScope, onChange?: () => void): IntroWrapper {
        const onComplete = async () => {
            const page = this.getCurrentPage(name);
            if (page && page.softComplete) {
                // Do nothing
            } else if (!this.isOnLastPage(name)) {
                this.loadNextPage();
            } else {
                return this.completeIntro();
            }
            return Promise.resolve();
        };
        const wrapper = this.introWrapperFactory.create(scope, {
            onChange,
            onComplete,
            onExit: () => this.abortIntro(),
            options: this.getIntroOptions(name),
        });
        return wrapper;
    }

    public setUpAndStartIntro(
        name: IntroName,
        scope: IScope,
        elementSelectorsToWaitFor?: string[],
        onChange?: () => void,
    ): IntroWrapper {
        const isIntroActive = this.isIntroActive(name);
        const wrapper = this.setUpIntro(name, scope, onChange);
        if (isIntroActive) {
            this.startIntro(wrapper, elementSelectorsToWaitFor);
        }
        return wrapper;
    }

    public startIntro(wrapper: IntroWrapper, elementSelectorsToWaitFor?: string[]): void {
        wrapper.onElementsLoaded(() => wrapper.doStep(), elementSelectorsToWaitFor);
        this.watchNavigation(wrapper);
    }

    public loadNextPage(): void {
        if (this.currentIntro) {
            this.currentPageIndex++;
            const page = this.currentIntro.pages[this.currentPageIndex];
            if (page && !isNullOrUndefined(page.route)) {
                this.unwatchNavigation();
                this.$location.url(page.route);
            }
        }
    }

    public countIntros(): number {
        return linq.from(this.getIntros()).count(intro => !intro.accessCheck || intro.accessCheck());
    }

    public getCompletionPage(): CompletionPage | null {
        return this.completionPage;
    }

    public hideCompletionPage(): void {
        this.completionPage = null;
    }

    public abortIntro(): void {
        this.unwatchNavigation();
        this.currentIntro = null;
        this.currentPageIndex = 0;
        this.$location.url('/intro');
    }

    public completeIntro(): IPromise<void> {
        const introToComplete = this.currentIntro;
        // eslint-disable-next-line id-length
        let p: IPromise<void>;
        if (introToComplete && introToComplete.completionPage !== null) {
            this.completionPage = introToComplete.completionPage;
            this.completionPage.title = introToComplete.title;
        }
        this.abortIntro();
        if (introToComplete) {
            p = this.userService.setIntroCompleted(introToComplete.name).then(() => {
                if (introToComplete?.name === 'apps') {
                    window.location.reload();
                }
            });
        } else {
            p = this.$q.resolve();
        }
        // Do some jiggery-pokery so that we can wait for intros to be marked as complete, before refreshing them.
        if (this.currentCompleteIntroPromise) {
            // eslint-disable-next-line id-length
            p = this.currentCompleteIntroPromise.then(() => p);
        }
        p = p.then(() => {
            if (this.currentCompleteIntroPromise === p) {
                this.currentCompleteIntroPromise = null;
            }
        });
        this.currentCompleteIntroPromise = p;
        return p;
    }

    /**
     * Will wait for any calls to complete an intro to finish, before fetching the guided tour.
     * Will still perform the fetch if the "complete intro" promise is rejected.
     *
     * @returns The current guided tour
     */
    public getGuidedTour(): IPromise<GuidedTour> {
        const doGet = () => this.userService.getGuidedTour();
        return this.getCurrentCompleteIntroPromise().then(doGet, doGet);
    }

    protected isOnLastPage(name: IntroName): boolean {
        const intro = this.getIntro(name);
        return this.currentPageIndex === intro.pages.length - 1;
    }

    protected getIntro(name: IntroName): Intro {
        switch (name) {
            case 'apps':
                return this.introDefinitionService.appsIntro;
            case 'build':
                return this.introDefinitionService.buildIntro;
            case 'learn':
                return this.introDefinitionService.learnIntro;
            case 'plan':
                return this.introDefinitionService.planIntro;
            case 'report':
                return this.introDefinitionService.reportIntro;
            case 'video':
                return this.introDefinitionService.videoIntro;
            default:
                throw assertNever(name);
        }
    }

    protected getCurrentCompleteIntroPromise(): IPromise<void> {
        return this.$q.resolve(this.currentCompleteIntroPromise || undefined);
    }

    protected watchNavigation(wrapper: IntroWrapper): void {
        this.unwatchNavigation();
        this.navigationUnwatcher = this.$rootScope.$on('$locationChangeStart', (event: ng.IAngularEvent) => {
            event.preventDefault();
            this.confirmModal.open(
                this.i18n.text.intro.cancelTourTitle(),
                this.i18n.text.intro.cancelTourMessage(),
                () => {
                    this.abortIntro();
                    // Always redirect back to the Intro page, so users don't accidentally explore the rest of the app.
                },
                () => {
                    // Restart the current Intro.
                    // When you cancel the Bootstrap dialog, the Intro.js tooltip has disappeared. So, we'll just
                    // Re-start the current intro.
                    if (wrapper) {
                        this.startIntro(wrapper);
                    }
                },
            );
        });
    }

    protected unwatchNavigation(): void {
        if (this.navigationUnwatcher) {
            this.navigationUnwatcher();
        }
    }
}

angular.module('app').service(IntroService.SID, IntroService);
