import {
    PublishToClubReadyStep1FormValuesActiveOptions,
    PublishToClubReadyStep1FormValuesInactiveOptions,
} from '../../PublishToClubReadyStep1';
import {
    BeforeAfterPeriodType,
    ClubReadyBeforeAfterDateOption,
} from '../../../../../../../../../__graphqlTypes/globalTypes';

export type ClubReadyActiveInactiveListSelection =
    | PublishToClubReadyStep1FormValuesActiveOptions
    | PublishToClubReadyStep1FormValuesInactiveOptions;

export function getActivityPeriod(selection: ClubReadyActiveInactiveListSelection): ClubReadyBeforeAfterDateOption | null {
    if (selection.listChoice === 'withActivityBefore' && selection.beforeDate) {
        return { date: selection.beforeDate, period: BeforeAfterPeriodType.Before };
    } else if (selection.listChoice === 'withActivityAfter' && selection.afterDate) {
        return { date: selection.afterDate, period: BeforeAfterPeriodType.After };
    } else {
        return null;
    }
}
