import { t } from '@deltasierra/shared';
import * as React from 'react';

export type HashtagsLimitHelpBlockProps = {
    hashtags: string[];
    maxCharacters?: number;
    maxTags?: number;
};

export function HashtagsLimitHelpBlock({ hashtags, maxCharacters, maxTags }: HashtagsLimitHelpBlockProps): JSX.Element {
    const message = React.useMemo(() => {
        const parts = [];
        if (maxTags !== undefined) {
            parts.push(
                t('BUILD.PUBLISH.HASHTAGS.HASHTAG_TAG_COUNT', {
                    maxTags,
                    tagCount: hashtags.length,
                }),
            );
        }

        if (maxCharacters !== undefined) {
            parts.push(
                t('BUILD.PUBLISH.HASHTAGS.HASHTAG_CHARACTER_COUNT', {
                    characterCount: hashtags.join(' ').length,
                    maxCharacters,
                }),
            );
        }
        return parts.join(` ${t('COMMON.AND').toLowerCase()} `);
    }, [hashtags, maxCharacters, maxTags]);
    return <>{message}</>;
}
HashtagsLimitHelpBlock.displayName = 'HashtagsLimitHelpBlock';
