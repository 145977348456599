import { TextSection, SectionType, BuilderEmailDocument } from '@deltasierra/shared';


type CachedStyling = {
    tagName: string;
    className: string;
    style: string;
};

/**
 * This wrapping class allows us to override specific fields on an email builder's TextSection. This was implemented
 * as a quick fix for https://digitalstack.atlassian.net/browse/DS-6276.
 *
 * The general idea of the fix is to intercept reads and writes of the HTML property on a TextSection so that we can
 * adjust and override the formatting ourselves.
 *
 * @see https://digitalstack.atlassian.net/browse/DS-6276
 */
export class ProxyTextSectionWithHackHtmlFix implements TextSection {
    public id: number;

    public title: string;

    public type: SectionType.text;

    public visible: boolean;

    public y: number;

    private _html: TextSection['html'];

    private _styling: Partial<Record<string, CachedStyling>> = {};

    private _useHack: boolean;

    public constructor(textSection: TextSection, emailDocument: BuilderEmailDocument) {
        this.id = textSection.id;
        this.title = textSection.title;
        this.type = textSection.type;
        this.visible = textSection.visible;
        this.y = textSection.y;
        this._useHack = emailDocument.useKeepTextFormattingHackV1 ?? false;

        if (this._useHack) {
            /*
             * Remove spans, combine styling, and record all the styling used in the cache for easy access.
             */
            const wrapper = document.createElement('template');
            wrapper.innerHTML = removeSpansAndCombineStyling(textSection.html);
            const elements = wrapper.content.querySelectorAll(
                'h1:first-of-type,h2:first-of-type,h3:first-of-type,p:first-of-type',
            );
            this._styling = Array.from(elements).reduce<Partial<Record<string, CachedStyling>>>((carry, current) => {
                carry[current.tagName] = {
                    className: current.className,
                    style: current.getAttribute('style') ?? '',
                    tagName: current.tagName,
                };
                return carry;
            }, {});
            this._html = wrapper.innerHTML;
        } else {
            this._html = textSection.html;
        }
    }

    public get html(): string {
        return this._html;
    }

    public set html(value: string) {
        /*
         * Not running the formatting logic when there are no changes saves a lot of time and improved performance.
         */
        if (value === this._html) {
            return;
        }

        /*
         * Text Angular uses Rangy to record and control the cursor. If it's present we should skip formatting the
         * HTML. Why? Otherwise our formatting logic removes the span element it uses and we loose the cursor position.
         */
        if (value.includes('selectionBoundary_') || !this._useHack) {
            this._html = value;
            return;
        }

        /*
         * Re-apply styling from the cache to all p and h tags in the HTML. This is required because there's no easy
         * way to add default styling to text angular without a major rewrite.
         */
        const wrapper = document.createElement('template');
        wrapper.innerHTML = value;
        const elements = wrapper.content.querySelectorAll('h1,h2,h3,p');
        elements.forEach(element => {
            const style = this._styling[element.tagName];
            if (style) {
                element.setAttribute('style', style.style);
                element.className = style.className;
            }
        });
        this._html = wrapper.innerHTML;
    }

    public toPlainTextSection(): TextSection {
        return {
            html: this._html,
            id: this.id,
            title: this.title,
            type: this.type,
            visible: this.visible,
            y: this.y,
        };
    }

    public toJSON(): TextSection {
        return this.toPlainTextSection();
    }
}

/**
 * This function does some complex stuff to try to address DS-6276. The basic idea to first hoist all styling from
 * child span tags to to parent p and h tags. Then, remove all span tags.
 *
 * @param html - The HTML to operate on
 * @returns The formatted HTML
 */
function removeSpansAndCombineStyling(html: string): string {
    const wrapper = document.createElement('template');
    wrapper.innerHTML = html;
    const content = wrapper.content;

    const roots = wrapper.content.querySelectorAll('h1,h2,h3,p');

    for (const element of Array.from(roots)) {
        // 1. Get the first chain of spans.
        const spans = findFirstSpans(element);

        // 2. Merge the styles.
        const styles = spans.reduce((carry, current) => `${carry}${current.getAttribute('style') ?? ''}`, '');

        // 3. Merge classes.
        const classes = spans.reduce((carry, current) => `${carry} ${current.className}`, '');
        const mergedClasses = `${element.className}${classes}`;

        // 4. Apply styles and classes to roots
        const existingStyles = element.getAttribute('style') ?? '';
        const mergedStyles = `${existingStyles}${styles}`;
        if (mergedStyles.trim()) {
            element.setAttribute('style', `${existingStyles}${styles}`);
        }

        if (mergedClasses.trim()) {
            element.className = mergedClasses;
        }

        // 5. Iterate through spans and replace them with their content.
        let done = false;
        while (!done) {
            const didReplace = findAndReplaceFirstSpan(content);
            done = !didReplace;
        }
    }

    return wrapper.innerHTML;
}

/**
 * Given a parent node, find the first `span` element and replace it with its children.
 *
 * @param node - The parent node to search from
 * @returns Returns true if a replacement was made, false otherwise
 */
function findAndReplaceFirstSpan(node: ParentNode): boolean {
    const span = node.querySelector('span');
    if (!span) {
        return false;
    }
    span.replaceWith(...Array.from(span.childNodes));
    return true;
}

/**
 * Given a parent node, recursively find the first _chain_ of span elements (ie: depth first search for the first chain
 * of span elements.
 *
 * @param node - The node to begin the search from
 * @returns The list of found span nodes in the order that they were found
 */
function findFirstSpans(node: ParentNode): HTMLSpanElement[] {
    const next = node.querySelector('span');
    return next ? [next, ...findFirstSpans(next)] : [];
}
