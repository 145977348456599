import { FitwareStats } from '../../integration/stats/fitwareStatsService';
import { StatsEntry, ReportTable, TextAlignClass } from '../reportTable/reportTable';
import { compareNumbers, compareStrings } from '../compare';
import { CreateReportTableOptions } from './common';

export function createFitwareLeadsReportTable(options: CreateReportTableOptions) {
    return new ReportTable<StatsEntry<FitwareStats>, FitwareStats>({
        columns: [
            {
                label: () => options.i18n.text.report.location(),
                cssClass: TextAlignClass.Left,
                format: options.formatters.formatString,
                getValue: entry => entry.location.locationTitle,
                getTotal: () => options.i18n.text.report.total(),
                width: '25%',
            },
            {
                label: () => options.i18n.text.report.totalLeads(),
                cssClass: TextAlignClass.Right,
                format: options.formatters.formatNumber,
                getValue: entry => entry.Total,
                getTotal: totals => totals.Total,
                compare: compareNumbers,
                width: '25%',
            },
            {
                label: () => options.i18n.text.report.tourShowUps(),
                cssClass: TextAlignClass.Right,
                format: options.formatters.formatNumber,
                getValue: entry => entry.TourShowUp,
                getTotal: totals => totals.TourShowUp,
                compare: compareNumbers,
                width: '25%',
            },
            {
                label: () => options.i18n.text.report.walkInTotal(),
                cssClass: TextAlignClass.Right,
                format: options.formatters.formatNumber,
                getValue: entry => entry.WalkInTotal,
                getTotal: totals => totals.WalkInTotal,
                compare: compareNumbers,
                width: '25%',
            },
            // {
            //     Label: "Referrals POS",
            //     CssClass: TextAlignClass.Right,
            //     Format: formatNumber,
            //     GetValue: entry => entry.ReferralsPos,
            //     GetTotal: totals => totals.ReferralsPos,
            //     Compare: compareNumbers
            // },
            // {
            //     Label: "Booked Total",
            //     CssClass: TextAlignClass.Right,
            //     Format: formatNumber,
            //     GetValue: entry => entry.BookedTotal,
            //     GetTotal: totals => totals.BookedTotal,
            //     Compare: compareNumbers
            // },
        ],
    });
}

export function createFitwareSalesReportTable(options: CreateReportTableOptions) {
    return new ReportTable<StatsEntry<FitwareStats>, FitwareStats>({
        columns: [
            {
                label: () => options.i18n.text.report.location(),
                cssClass: TextAlignClass.Left,
                format: options.formatters.formatString,
                getValue: entry => entry.location.locationTitle,
                getTotal: () => options.i18n.text.report.total(),
                width: '25%',
            },
            {
                label: () => options.i18n.text.report.totalSales(),
                cssClass: TextAlignClass.Right,
                format: options.formatters.formatNumber,
                getValue: entry => entry.TotalSale,
                getTotal: totals => totals.TotalSale,
                compare: compareNumbers,
                width: '25%',
            },
            {
                label: () => options.i18n.text.report.tourSale(),
                cssClass: TextAlignClass.Right,
                format: options.formatters.formatNumber,
                getValue: entry => entry.TourSale,
                getTotal: totals => totals.TourSale,
                compare: compareNumbers,
                width: '25%',
            },
            {
                label: () => options.i18n.text.report.walkInSale(),
                cssClass: TextAlignClass.Right,
                format: options.formatters.formatNumber,
                getValue: entry => entry.WalkInSale,
                getTotal: totals => totals.WalkInSale,
                compare: compareNumbers,
                width: '25%',
            },
            // {
            //     Label: "Personal Training Sold",
            //     CssClass: TextAlignClass.Right,
            //     Format: formatNumber,
            //     GetValue: entry => entry.PersonalTrainingSold,
            //     GetTotal: totals => totals.PersonalTrainingSold,
            //     Compare: compareNumbers
            // },
            // {
            //     Label: "Membership No Contract",
            //     CssClass: TextAlignClass.Right,
            //     Format: formatNumber,
            //     GetValue: entry  => entry.MembershipNoContract,
            //     GetTotal: totals => totals.MembershipNoContract,
            //     Compare: compareNumbers
            // },
            // {
            //     Label: "Membership 12 Month",
            //     CssClass: TextAlignClass.Right,
            //     Format: formatNumber,
            //     GetValue: entry  => entry.Membership12Month,
            //     GetTotal: totals => totals.Membership12Month,
            //     Compare: compareNumbers
            // },
        ],
    });
}

export function createFitwareConversionsReportTable(options: CreateReportTableOptions) {
    return new ReportTable<StatsEntry<FitwareStats>, FitwareStats>({
        columns: [
            {
                label: () => options.i18n.text.report.location(),
                cssClass: TextAlignClass.Left,
                format: options.formatters.formatString,
                getValue: entry => entry.location.locationTitle,
                getTotal: () => options.i18n.text.report.total(),
                width: '25%',
            },
            {
                label: () => options.i18n.text.report.totalCloseRatio(),
                cssClass: TextAlignClass.Right,
                format: options.formatters.formatString,
                getValue: entry => entry.TotalCloseRatio,
                getTotal: totals => totals.TotalCloseRatio,
                compare: compareStrings,
                width: '25%',
            },
            // {
            //     Label: "Contact Ratio",
            //     CssClass: TextAlignClass.Right,
            //     Format: formatString,
            //     GetValue: entry => entry.ContactRatio,
            //     GetTotal: totals => totals.ContactRatio,
            //     Compare: compareStrings
            // },
            {
                label: () => options.i18n.text.report.tourCloseRatio(),
                cssClass: TextAlignClass.Right,
                format: options.formatters.formatString,
                getValue: entry => entry.TourCloseRatio,
                getTotal: totals => totals.TourCloseRatio,
                compare: compareStrings,
                width: '25%',
            },
            {
                label: () => options.i18n.text.report.walkInCloseRatio(),
                cssClass: TextAlignClass.Right,
                format: options.formatters.formatString,
                getValue: entry => entry.WalkInCloseRatio,
                getTotal: totals => totals.WalkInCloseRatio,
                compare: compareStrings,
                width: '25%',
            },
            // {
            //     Label: "Membership 12 Month Ratio",
            //     CssClass: TextAlignClass.Right,
            //     Format: formatString,
            //     GetValue: entry => entry.Membership12MonthRatio,
            //     GetTotal: totals => totals.Membership12MonthRatio,
            //     Compare: compareStrings
            // },
        ],
    });
}
