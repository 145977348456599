import { gql } from '@apollo/client';
import { pick, BuilderTemplateId, Url } from '@deltasierra/shared';
import * as React from 'react';
import { useDeleteBuilderTemplateDraftMutation } from '../../../hooks/useDeleteLocationDraftMutation';
import { BuilderTemplateDraftOption } from '../BuilderTemplateDraftOption';
import { DeleteTemplateDraftConfirmationModal } from './DeleteTemplateDraftConfirmationModal';
import { BuilderTemplateDraftPreviewFragment } from './__graphqlTypes/BuilderTemplateDraftPreviewFragment';

const fragments = {
    locationDraft: gql`
        fragment BuilderTemplateDraftPreviewFragment on LocationDraft {
            id
            title
            document
            updatedAt
            compositeImage {
                id
                filename
                thumb256x256url
            }
        }
    `,
};

export interface SelectedBuilderTemplateDraft {
    document: string;
    id: string;
    thumbnailUrl?: Url;
    title: string;
    updatedAt: Date;
}

export type BuilderTemplateDraftPreviewProps = {
    builderTemplateId: BuilderTemplateId;
    locationId: string;
    locationDraft: BuilderTemplateDraftPreviewFragment;
    onClick: (locationDraft: SelectedBuilderTemplateDraft) => void;
    onDelete: (deletedDraftId: string) => void;
};

export function BuilderTemplateDraftPreview({
    builderTemplateId,
    locationDraft,
    locationId,
    onClick,
    onDelete,
}: BuilderTemplateDraftPreviewProps): JSX.Element {
    const [deleteClicked, setDeleteClicked] = React.useState(false);
    const [confirmDialog, setConfirmDialog] = React.useState(false);

    const [deleteLocationDraft] = useDeleteBuilderTemplateDraftMutation({
        builderTemplateId,
        locationId,
    });

    const draftNodeToSelectedDraft = (node: BuilderTemplateDraftPreviewFragment) => ({
        ...pick(node, 'id', 'title'),
        document: node.document as unknown as string,
        thumbnailUrl: node.compositeImage?.thumb256x256url
            ? Url.from(`https:${node.compositeImage.thumb256x256url}`)
            : undefined,
        updatedAt: new Date(node.updatedAt),
    });

    const handleDraftRemove = async () => {
        setDeleteClicked(true);
        await deleteLocationDraft([locationDraft.id]);
        onDelete(locationDraft.id);
        setConfirmDialog(false);
    };

    return (
        <>
            <BuilderTemplateDraftOption
                deleteDisabled={deleteClicked}
                thumbnailUploadId={locationDraft.compositeImage?.id}
                title={locationDraft.title}
                updatedAt={new Date(locationDraft.updatedAt)}
                onClickRemove={() => setConfirmDialog(true)}
                onDraftSelect={() => onClick(draftNodeToSelectedDraft(locationDraft))}
            />
            <DeleteTemplateDraftConfirmationModal
                show={confirmDialog}
                onCancel={() => setConfirmDialog(false)}
                onConfirm={handleDraftRemove}
            />
        </>
    );
}

BuilderTemplateDraftPreview.displayName = 'BuilderTemplateDraftPreview';
BuilderTemplateDraftPreview.fragments = fragments;
