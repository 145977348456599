import * as React from 'react';
import { useField } from 'formik';
import { PuppetTextInput, PuppetTextInputProps } from '@deltasierra/components';
import { assertDefined } from '@deltasierra/shared';
import { ValidateableFieldContext } from './ValidateableFormGroup';

export type ValidateableTextInputProps = Omit<PuppetTextInputProps, 'onBlur' | 'onChange' | 'value'> & {
    name?: string;
};

export const ValidatableTextInput: React.FC<ValidateableTextInputProps> = ({ name, ...props }) => {
    const contextFieldName = React.useContext(ValidateableFieldContext);
    const fieldName = name ?? contextFieldName;
    assertDefined(fieldName);
    const [field] = useField(fieldName);
    return <PuppetTextInput className="form-control" {...props} {...field} />;
};
ValidatableTextInput.displayName = 'ValidatableTextInput';
