

/// <reference path="../../../typings/browser.d.ts" />
import {
    AnyThrowable,
    ClientId,
    Currency,
    Location,
    LocationId,
    LocationsAdminView,
    LocationSubscriptionMapDto,
    Untyped,
    UpdateConversionAmountsBody,
    UpdateNotificationEmailAddressesBody,
} from '@deltasierra/shared';
import { MvIdentity } from '../account/mvIdentity';
import { $locationSID, $qSID, $routeParamsSID, $routeSID, $scopeSID, IRoute } from '../common/angularData';
import { $modalSID } from '../common/angularUIBootstrapData';
import { Future } from '../common/Future';
import { InteractionUtils } from '../common/interactionUtils';
import { MvNotifier } from '../common/mvNotifier';
import { I18nService as i18nService } from '../i18n';
import { MvLocation } from '../locations/mvLocation';
import { BillingDetailsUpdateService } from '../payments/billingDetailsUpdate/billingDetailsUpdateService';
import { CurrencyService } from '../payments/currencyService';
import { ModalService } from '../typings/angularUIBootstrap/modalService';
import { MvClient } from './mvClient';
import { ClientResource, MvClientResource, mvClientResourceSID } from './mvClientResource';
import IPromise = angular.IPromise;
import ILocationService = angular.ILocationService;
import IScope = angular.IScope;
import IQService = angular.IQService;

interface LocationModel extends LocationsAdminView {
    // AmountInCents? : number;
    displayAmount?: string;
    isSubscriptionActive?: boolean;
}

export interface MvClientDetailCtrl extends IScope {
    loading: {
        updateCountry: boolean;
        updateCurrency: boolean;
        updateDefaultSupportedPlannerLimit: boolean;
    };
    identity: MvIdentity;
    client: ClientResource;
    locations: LocationModel[];
    subscriptionsMap: LocationSubscriptionMapDto;

    client_id: ClientId;
    locationName: string | null;
    locationTimezone: string;
    sortOrders: {
        title: string;
        signedUp: string;
        isActive: string;
        subscriptionAmount: string;
    };
    currencies: ReadonlyArray<Currency>;
    updateConversionAmounts: Future<void, Record<string, unknown>>;
    updateSort: (sortBy: string) => void;
    sortBy: string;
    sortDescending: boolean;
    sortDirection: () => string;
    isActive: (route: string) => boolean;
    fetchClient: Future<ClientResource, Record<string, unknown>>;
    fetchLocations: Future<void, Record<string, unknown>>;
    fetchLocationSubscriptions: Future<LocationSubscriptionMapDto, Record<string, unknown>>;
    addLocation: () => void;
    deleteClient: (client: ClientResource) => void;
    updateDefaultSupportedPlannerLimit: () => IPromise<void>;
    updateCountry: () => IPromise<void>;
    updateCurrency: () => IPromise<void>;
    invite: (location: LocationModel) => void;
    inviteToUpdateBillingDetails: (location: LocationModel) => void;
    getSubscriptionDisplay: (locationId: LocationId) => string;
    isSubscriptionActive: (locationId: LocationId) => boolean;
    onChangeLocationTimezone: (timezone: string) => void;
    handleNotificationEmailAddressChange: (notificationEmailAddresses: string[]) => void;
    updateNotificationEmailAddress: Future<void, { notificationEmailAddresses: string[] }>;
}

angular.module('app').controller('mvClientDetailCtrl', [
    $scopeSID,
    $qSID,
    $routeParamsSID,
    $locationSID,
    $routeSID,
    $modalSID,
    MvIdentity.SID,
    MvClient.SID,
    mvClientResourceSID,
    MvLocation.SID,
    MvNotifier.SID,
    InteractionUtils.SID,
    CurrencyService.SID,
    i18nService.SID,
    // eslint-disable-next-line max-statements
    function mvClientDetailCtrl(
        $scope: MvClientDetailCtrl,
        $q: IQService,
        $routeParams: Untyped,
        $location: ILocationService,
        $route: IRoute,
        $modal: ModalService,
        mvIdentity: MvIdentity,
        mvClient: MvClient,
        mvClientResource: MvClientResource,
        mvLocation: MvLocation,
        mvNotifier: MvNotifier,
        interactionUtils: InteractionUtils,
        currencyService: CurrencyService,
        I18nService: i18nService,
    ) {
        $scope.loading = {
            updateCountry: false,
            updateCurrency: false,
            updateDefaultSupportedPlannerLimit: false,
        };
        $scope.identity = mvIdentity;
        $scope.subscriptionsMap = {};
        $scope.client_id = ClientId.from(parseInt($routeParams.id, 10));
        $scope.locationName = null;
        $scope.locationTimezone = 'Australia/Melbourne';
        $scope.currencies = currencyService.CURRENCIES;

        $scope.sortOrders = {
            isActive: 'isSubscriptionActive',
            signedUp: 'signedUp ? 2 : invited ? 1 : 0',
            subscriptionAmount: 'displayAmount',
            title: 'title',
        };

        function initPage() {
            const fcp = $scope.fetchClient.run({});
            const flp = $scope.fetchLocations.run({});
            const flsp = $scope.fetchLocationSubscriptions.run({});
            return $q.all([fcp, $q.all([flp, flsp]).then(() => combineLocationsAndSubscriptions())]);
        }

        $scope.fetchClient = interactionUtils.createFuture('retrieve client', () => {
            $scope.client = mvClientResource.get({ id: $scope.client_id });
            return $scope.client.$promise;
        });

        $scope.fetchLocations = interactionUtils.createFuture('retrieve locations', () =>
            mvClient.getLocations($scope.client_id).then(locations => {
                $scope.locations = locations;
            }),
        );

        $scope.fetchLocationSubscriptions = interactionUtils.createFuture<
            LocationSubscriptionMapDto,
            Record<string, unknown>
        >('retrieve location subscriptions', () =>
            mvClient.getLocationSubscriptions($scope.client_id).then(subscriptionsMap => {
                $scope.subscriptionsMap = subscriptionsMap;
                return subscriptionsMap;
            }),
        );

        function combineLocationsAndSubscriptions(): void {
            for (const location of $scope.locations) {
                const subscription = $scope.subscriptionsMap[location.id];
                if (subscription) {
                    location.isSubscriptionActive = subscription.isActive;
                    const amount = currencyService.getLargestUnit(subscription.currency, subscription.amountInCents);
                    location.displayAmount = I18nService.formatCurrency(amount, {
                        code: subscription.currency,
                        format: 'code',
                    });
                } else {
                    location.isSubscriptionActive = false;
                }
            }
        }

        $scope.updateSort = sortBy => {
            if (sortBy === $scope.sortBy) {
                $scope.sortDescending = !$scope.sortDescending;
            } else {
                $scope.sortBy = sortBy;
                $scope.sortDescending = false;
            }
        };

        $scope.sortDirection = () => ($scope.sortDescending ? 'down' : 'up');

        $scope.updateSort($scope.sortOrders.title);

        $scope.isActive = route => route === $location.path();

        $scope.addLocation = () => {
            if ($scope.locationName && $scope.locationTimezone) {
                const newLocationData = {
                    timezone: $scope.locationTimezone,
                    title: $scope.locationName,
                };

                return mvLocation.createLocation($scope.client.id, newLocationData).then(
                    (location: Location) => {
                        mvNotifier.notify(I18nService.text.agency.client.locations.locationAdded());
                        $route.reload();
                    },
                    (reason: AnyThrowable) => {
                        mvNotifier.unexpectedError(reason);
                    },
                );
            }
            return Promise.resolve();
        };

        $scope.deleteClient = async () => {
            const client = $scope.client;
            await client.$delete(
                (value: Untyped) => {
                    mvNotifier.notify(
                        I18nService.text.agency.client.locations.permanentlyDeletedClient({ title: client.title }),
                    );
                    $location.path('/agency/clients');
                },
                (httpResponse: Untyped) => {
                    mvNotifier.unexpectedErrorWithData(
                        I18nService.text.agency.client.locations.errorDeletingClient(),
                        httpResponse,
                    );
                },
            );
        };

        $scope.handleNotificationEmailAddressChange = async (notificationEmailAddresses: string[]) => {
            $scope.client.notificationEmailAddresses = notificationEmailAddresses;
            await $scope.updateNotificationEmailAddress.run({ notificationEmailAddresses });
        };

        $scope.updateDefaultSupportedPlannerLimit = () =>
            interactionUtils
                .handleRemote(
                    $scope,
                    'update default supported planner limit',
                    'updateDefaultSupportedPlannerLimit',
                    mvClient.updateDefaultSupportedPlannerLimit(
                        $scope.client.id,
                        $scope.client.defaultSupportedPlannerLimit,
                    ),
                )
                .then(value => {
                    mvNotifier.notify(I18nService.text.agency.client.locations.defaultSupportedPlannerLimitUpdated());
                });

        $scope.updateCountry = async () => {
            await interactionUtils.handleRemote(
                $scope,
                'update client country',
                'updateCountry',
                mvClient.updateCountry($scope.client.id, $scope.client.country),
            );
            mvNotifier.notify(I18nService.text.agency.client.locations.countryUpdated());
        };

        $scope.updateCurrency = async () => {
            await interactionUtils.handleRemote(
                $scope,
                'update client currency',
                'updateCurrency',
                mvClient.updateCurrency($scope.client.id, $scope.client.currency),
            );
            mvNotifier.notify(I18nService.text.agency.client.locations.currencyUpdated());
        };

        $scope.updateConversionAmounts = interactionUtils.createFuture(
            I18nService.text.agency.client.updateConversionDetails(),
            () => {
                const details: UpdateConversionAmountsBody = {
                    fitwareConversionAmountInCents: $scope.client.fitwareConversionAmountInCents,
                    fitwareConversionCurrency: $scope.client.fitwareConversionCurrency,
                };

                return mvClient.updateConversionAmounts($scope.client.id, details).then(() => {
                    mvNotifier.notify(I18nService.text.agency.client.conversionAmountUpdated());
                });
            },
        );

        $scope.updateNotificationEmailAddress = interactionUtils.createFuture(
            I18nService.text.agency.client.updateNotificationEmailAddresses(),
            async (context: { notificationEmailAddresses: string[] }) => {
                const body: UpdateNotificationEmailAddressesBody = {
                    notificationEmailAddresses: context.notificationEmailAddresses,
                };
                await mvClient.updateNotificationEmailAddresses($scope.client.id, body);
                mvNotifier.notify(I18nService.text.agency.client.notificationEmailAddressesUpdated());
            },
        );

        $scope.inviteToUpdateBillingDetails = (location: LocationsAdminView) => {
            const newScope = $scope.$new();
            $modal.open({
                backdrop: 'static',
                controller: [
                    '$scope',
                    '$modalInstance',
                    MvNotifier.SID,
                    InteractionUtils.SID,
                    BillingDetailsUpdateService.SID,
                    function inviteToUpdateBillingModalCtrl(
                        $modalScope: Untyped,
                        $modalInstance: Untyped,
                        innerNotifier: MvNotifier,
                        innerInteractionUtils: InteractionUtils,
                        billingDetailsUpdateService: BillingDetailsUpdateService,
                    ) {
                        // Tslint:disable-line
                        $modalScope.loading = {
                            sendBillingDetailsUpdateRequest: false,
                        };
                        $modalScope.signatoryEmail = location.signatoryEmail;

                        $modalScope.submit = async () => {
                            await innerInteractionUtils.handleRemote(
                                $modalScope,
                                'send billing details update request',
                                'sendBillingDetailsUpdateRequest',
                                billingDetailsUpdateService.sendInvite(location.id, $modalScope.signatoryEmail),
                            );
                            innerNotifier.notify(
                                I18nService.text.agency.client.locations.requestUpdateBillingDetailsSent(),
                            );
                            $modalInstance.close();
                        };

                        $modalScope.close = () => {
                            $modalInstance.dismiss();
                        };
                    },
                ],
                scope: newScope,
                templateUrl: '/partials/clients/sendBillingDetailsUpdateRequest',
            });
        };

        $scope.onChangeLocationTimezone = (timezone: string) => {
            $scope.locationTimezone = timezone;
        };

        void initPage();
    },
]);
