import { PrintProviderSKUGroupId, PrintProviderSKUId } from '@deltasierra/shared';

import { I18nService } from '../../i18n/i18nService';
import { TabNavEntry } from '../../common/dsTabNav';

export interface AgencySKUGroupExpectedRouteParams {
    skuGroupId?: string;
    skuId?: string;
}

export function getAgencySKUGroupTabNavEntries(i18n: I18nService): ReadonlyArray<TabNavEntry> {
    return Object.freeze([
        {
            label: () => i18n.text.agency.printSkuGroups.skus(),
            path: '/agency/printProviderSKUGroups/{{ skuGroupId }}',
        },
        {
            label: () => i18n.text.agency.printSkuGroups.printProviders(),
            path: '/agency/printProviderSKUGroups/{{ skuGroupId }}/printProviders',
        },
        {
            label: () => i18n.text.common.details(),
            path: '/agency/printProviderSKUGroups/{{ skuGroupId }}/details',
        },
    ]);
}

export interface AgencySKUGroupIds {
    skuId?: PrintProviderSKUId;
    skuGroupId?: PrintProviderSKUGroupId;
}

export function extractIdsFromRouteParams($routeParams: AgencySKUGroupExpectedRouteParams): AgencySKUGroupIds {
    let skuGroupId;
    let skuId;
    if ($routeParams.skuGroupId) {
        skuGroupId = PrintProviderSKUGroupId.from(parseInt($routeParams.skuGroupId, 10));
    }
    if ($routeParams.skuId) {
        skuId = PrintProviderSKUId.from(parseInt($routeParams.skuId, 10));
    }
    return {
        skuGroupId,
        skuId,
    };
}
