import { gql } from '@apollo/client';
import { DSTableCell, DSTableRow } from '@deltasierra/components';
import * as React from 'react';
import { ClientFragmentForClientListRow } from './__graphqlTypes/ClientFragmentForClientListRow';

const clientFragment = gql`
    fragment ClientFragmentForClientListRow on Client {
        id
        title
        legacyId
        locations {
            edges {
                node {
                    id
                }
            }
        }
        brand {
            __typename
            id
            title
        }
    }
`;

export interface ClientListRowProps {
    client: ClientFragmentForClientListRow;
}

export function ClientListRow(props: ClientListRowProps): JSX.Element {
    const { client } = props;
    return (
        <DSTableRow key={client.id}>
            <DSTableCell>
                <a href={`/clients/${client.legacyId}`}>{client.title}</a>
            </DSTableCell>
            <DSTableCell>{client.brand?.title ?? '-'}</DSTableCell>
            <DSTableCell align="right">{client.locations.edges.length}</DSTableCell>
        </DSTableRow>
    );
}
ClientListRow.displayName = 'ClientListRow';
ClientListRow.fragments = { client: clientFragment };
