import * as React from 'react';
import {
    DSButton,
    DSDialogActions,
    DSDialogContent,
    DSDialogContentText,
    Loading,
    Translate,
} from '@deltasierra/components';
import { useLocationDraftsCount } from '../../hooks/use-location-drafts-count';

export type DeleteTemplateConfirmationModalContentProps = {
    builderTemplateId: string;
    onCancel?: () => void;
    onConfirm?: () => void;
};

export function DeleteTemplateConfirmationModalContent({
    builderTemplateId,
    onCancel,
    onConfirm,
}: DeleteTemplateConfirmationModalContentProps): JSX.Element {
    const [loading, locationDraftsCount] = useLocationDraftsCount(builderTemplateId);

    const handleClickCancel: React.MouseEventHandler = () => {
        onCancel?.();
    };

    const handleClickDelete: React.MouseEventHandler = () => {
        onConfirm?.();
    };

    return (
        <>
            <DSDialogContent>
                {loading && <Loading />}
                {!loading && locationDraftsCount! > 0 && (
                    <>
                        <DSDialogContentText>
                            <Translate
                                keyId="BUILD.BUILDER_TEMPLATE_DELETE_CONFIRMATION_MODAL.CONFIRMATION_MESSAGE"
                                options={{ count: locationDraftsCount! }}
                            />
                        </DSDialogContentText>
                    </>
                )}
                {!loading && locationDraftsCount === 0 && (
                    <DSDialogContentText>
                        <Translate keyId="BUILD.DELETE_CONFIRMATION" />
                    </DSDialogContentText>
                )}
            </DSDialogContent>
            <DSDialogActions>
                <DSButton color="default" disabled={loading} variant="outlined" onClick={handleClickCancel}>
                    <Translate keyId="COMMON.CANCEL" />
                </DSButton>

                <DSButton color="primary" disabled={loading} onClick={handleClickDelete}>
                    <Translate keyId="COMMON.DELETE" />
                </DSButton>
            </DSDialogActions>
        </>
    );
}
