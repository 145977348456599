import { Location, Client, ClientId, PrintProvider, PrintProviderSKUGroupId } from '@deltasierra/shared';
import { InteractionUtils } from '../../../common/interactionUtils';
import { PrintProviderApiClient } from '../printProviderApiClient';
import { $qSID } from '../../../common/angularData';
import { SKUManagementApiClient } from '../../../agency/printProviderSKUGroups/skuManagementApiClient';
import IQService = angular.IQService;

export class ConnectPrintController {
    static SID = 'ConnectPrintController';

    createNewProvider = this.interactionUtils.createFuture('Create print provider', () => {
        const newProvider = this.newProvider!;
        const payload = {
            email: newProvider.email!,
            businessName: newProvider.name!,
            address: newProvider.address != '' ? newProvider.address : null,
            phoneNumber: newProvider.phoneNumber != '' ? newProvider.phoneNumber : null,
        };

        if (this.type == 'location') {
            const location = this.model as Location;
            return this.printProviderApiClient.createLocationPrintProvider(location.id, payload);
        } else {
            const client = this.model as Client;
            return this.printProviderApiClient.createClientPrintProvider(client.id, payload);
        }
    });

    getProvider = this.interactionUtils.createFuture('Get print provider', () => {
        if (this.type == 'location') {
            const location = this.model as Location;
            return this.printProviderApiClient.getLocationPrintProvider(location.id).then(provider => {
                if (provider) {
                    return provider;
                }

                this.isClientProvider = true;
                return this.getClientProvider.run({ id: location.clientId });
            });
        } else {
            const client = this.model as Client;
            return this.getClientProvider.run({ id: client.id });
        }
    });

    getClientProvider = this.interactionUtils.createFuture('Get print provider', (context: { id: ClientId }) =>
        this.printProviderApiClient.getClientPrintProvider(context.id),
    );

    getSKUGroupName = this.interactionUtils.createFuture(
        'Get SKU Group name',
        (context: { skuGroupId: PrintProviderSKUGroupId }) =>
            this.skuManagementApiClient.getSKUGroupName(context.skuGroupId),
    );

    deletePrintProvider = this.interactionUtils.createFuture('Get print provider', () => {
        if (this.printProvider) {
            return this.printProviderApiClient.deletePrintProvider(this.printProvider.id).then(() => {
                this.printProvider = null;
                this.skuGroupName = null;
                this.providerHasSKUGroup = false;
            });
        } else {
            return this.$q.resolve();
        }
    });

    model!: Client | Location;

    type!: 'client' | 'location';

    isCreatingNew = false;

    isClientProvider = false;

    providerHasSKUGroup = false;

    newProvider?: {
        name: string | null;
        email: string | null;
        phoneNumber: string | null;
        address: string | null;
    };

    printProvider: PrintProvider | null = null;

    skuGroupName: string | null = null;

    static readonly $inject: string[] = [
        $qSID,
        InteractionUtils.SID,
        PrintProviderApiClient.SID,
        SKUManagementApiClient.SID,
    ];

    constructor(
        private readonly $q: IQService,
        private readonly interactionUtils: InteractionUtils,
        private readonly printProviderApiClient: PrintProviderApiClient,
        private readonly skuManagementApiClient: SKUManagementApiClient,
    ) {}

   public $onInit(): void {
        void this.getCurrentPrintProvider();
    }

    clickCreateNew() {
        this.isCreatingNew = true;
    }

    getCurrentPrintProvider() {
        return this.getProvider.run({}).then(provider => {
            if (provider) {
                this.printProvider = provider;
                if (provider.printProviderSKUGroupId && this.type != 'location') {
                    this.providerHasSKUGroup = true;
                    return this.getSKUGroupName.run({ skuGroupId: provider.printProviderSKUGroupId }).then(skuGroup => {
                        this.skuGroupName = skuGroup.name;
                    });
                }
            }
        });
    }

    addPrintProvider() {
        return this.createNewProvider.run({}).then(provider => {
            this.printProvider = provider;
            this.isCreatingNew = false;
            if (this.type == 'location') {
                this.isClientProvider = false;
            }
            this.newProvider = {
                name: null,
                email: null,
                phoneNumber: null,
                address: null,
            };
        });
    }

    deleteCurrentProvider() {
        return this.deletePrintProvider.run({}).then(() => {
            if (this.type == 'location') {
                return this.getCurrentPrintProvider();
            }
        });
    }
}
