import * as React from 'react';
import { withAngularIntegration } from '../componentUtils/reactComponentRegistration';
import { OptionalOneWayBinding, ExpressionBinding, OneWayBinding } from '../angularData';

export type NumberInputProps = {
    disabled?: boolean;
    label: string;
    onChange: (newValue?: number) => void;
    required?: boolean;
    value: number;
    min?: number;
    max?: number;
};

export const NumberInput: React.FunctionComponent<NumberInputProps> = ({
    disabled = false,
    label,
    max,
    min,
    onChange,
    required = false,
    value,
}) => {
    const validateInput = (newValue: string) => newValue && !isNaN(parseInt(newValue, 10)) ? parseInt(newValue, 10) : undefined;

    return (
        <div className="form-group">
            <label>{label}</label>
            <input
                className="form-control max-file-size"
                type={'number'}
                value={typeof value === 'number' ? value.toString() : ''}
                onChange={event => onChange(validateInput(event.target.value))}
                disabled={disabled}
                required={required}
                min={min}
                max={max}
            />
        </div>
    );
};

NumberInput.displayName = 'NumberInput';

export default withAngularIntegration(NumberInput, 'numberInput', {
    disabled: OptionalOneWayBinding,
    label: OneWayBinding,
    max: OptionalOneWayBinding,
    min: OptionalOneWayBinding,
    onChange: ExpressionBinding,
    required: OptionalOneWayBinding,
    value: OneWayBinding,
});
