/// <reference path="../../../../typings/browser.d.ts" />
import { IFinalisePaymentResponse } from '@deltasierra/shared';
import { IRouteParams } from '../../common/angularData';
import { InteractionUtils } from '../../common/interactionUtils';
import { BillingDetailsUpdateService } from './billingDetailsUpdateService';

export class MvBillingDetailsUpdateFinaliseCtrl {
    public static SID = 'mvBillingDetailsUpdateFinaliseCtrl';

    accessCode: string;

    billingDetailsUpdateResponse: IFinalisePaymentResponse | null = null;

    loading = {
        finaliseBillingDetailsUpdate: false,
    };

    static readonly $inject: string[] = ['$routeParams', InteractionUtils.SID, BillingDetailsUpdateService.SID];

    constructor(
        $routeParams: IRouteParams,
        private readonly interactionUtils: InteractionUtils,
        private readonly billingDetailsUpdateService: BillingDetailsUpdateService,
    ) {
        this.accessCode = $routeParams.AccessCode;
        if (this.accessCode) {
            void this.finaliseToken();
        }
    }

    private finaliseToken() {
        return this.interactionUtils
            .handleRemote(
                this,
                'finalise billing details update',
                'finaliseBillingDetailsUpdate',
                this.billingDetailsUpdateService.finaliseBillingDetailsUpdate(this.accessCode),
            )
            .then(billingDetailsUpdateResponse => this.billingDetailsUpdateResponse = billingDetailsUpdateResponse);
    }
}

angular.module('app').controller(MvBillingDetailsUpdateFinaliseCtrl.SID, MvBillingDetailsUpdateFinaliseCtrl);
