import { ChecklistItemStateHandler } from '@deltasierra/react-hooks';
import * as React from 'react';
import { LocationsChecklistItem } from './LocationsChecklistItem';
import { LocationFragmentForLocationsChecklist } from './__graphqlTypes/LocationFragmentForLocationsChecklist';

export type LocationsChecklistItemLocationProps = {
    checked: boolean;
    indeterminatable: boolean;
    indeterminate: boolean;
    location: LocationFragmentForLocationsChecklist;
    onChecked: ChecklistItemStateHandler;
};

export function LocationsChecklistItemLocation({
    checked,
    indeterminatable,
    indeterminate,
    location,
    onChecked,
}: LocationsChecklistItemLocationProps): JSX.Element {
    const onClick = React.useCallback(() => {
        if (indeterminatable) {
            if (indeterminate) {
                onChecked(location.id, { checked: true, indeterminate: false });
            } else if (checked) {
                onChecked(location.id, { checked: false });
            } else {
                onChecked(location.id, { indeterminate: true });
            }
        } else {
            onChecked(location.id, { checked: !checked });
        }
    }, [indeterminatable, checked, indeterminate, location.id, onChecked]);

    return (
        <LocationsChecklistItem
            checked={checked}
            depth={1}
            indeterminate={indeterminate}
            title={location.title}
            type="primary"
            onClick={onClick}
        />
    );
}
LocationsChecklistItemLocation.displayName = 'LocationsChecklistItemLocation';

export const MemoizedLocationsChecklistItemLocation = React.memo(LocationsChecklistItemLocation);
