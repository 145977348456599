import { Roles } from '@deltasierra/shared';
import * as React from 'react';
import styled from 'styled-components';
import { Loading, Translate } from '@deltasierra/components';
import { OneWayBinding } from '../../../common/angularData';
import { withAngularIntegration } from '../../../common/componentUtils/reactComponentRegistration';
import { useAuthUser } from '../../../common/auth';
import { CopyTemplateModal } from './CopyTemplateModal';
import { useCopyTemplate } from './useCopyTemplate';


const StyledAnchor = styled.a`
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-text-size-adjust: 100%;
    background: 0 0;
    box-sizing: border-box;
    clear: both;
    color: #333;
    display: block;
    font-family: GalanoGrotesque, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    list-style: none;
    padding: 3px 20px;
    text-decoration: none;
    white-space: nowrap;
    :hover {
        background-color: #f5f5f5;
        color: #262626;
        outline: 0;
        white-space: nowrap;
    }
`;

export type CopyTemplateAnchorProps = {
    builderTemplateId: string;
};

export function CopyTemplateAnchor({ builderTemplateId }: CopyTemplateAnchorProps): JSX.Element {
    const { handleCancel, handleClickCopy, handleCopyMutation, loading, showModal } = useCopyTemplate();

    const user = useAuthUser();
    const isAuthorised = user?.roles.includes(Roles.manager);

    return (
        <>
            {isAuthorised && (
                <>
                    <StyledAnchor onClick={handleClickCopy}>
                        <Translate keyId="BUILD.TEMPLATE_ADMIN.COPY_TO" />
                    </StyledAnchor>
                    {loading && <Loading />}
                    <CopyTemplateModal
                        builderTemplateId={builderTemplateId}
                        show={showModal}
                        onCancel={handleCancel}
                        onSubmit={handleCopyMutation}
                    />
                </>
            )}
        </>
    );
}
CopyTemplateAnchor.displayName = 'CopyTemplateAnchor';

export default withAngularIntegration(CopyTemplateAnchor, 'copyTemplateAnchor', {
    builderTemplateId: OneWayBinding,
});
