import { Agency, AgencyId, Client, ClientId, AssignedLocation, LocationId, User } from '@deltasierra/shared';


import { InteractionUtils } from '../../common/interactionUtils';
import { I18nService } from '../../i18n';
import { MvLocation } from '../../locations/mvLocation';
import { MvClientResource, mvClientResourceSID } from '../mvClientResource';
import { AgencyApiClient } from '../../agencies/agencyApiClient';
import { ClientUserExpectedRouteParams, extractIdsFromRouteParams } from '../mvClientUserEditCtrl';
import { $routeParamsSID } from '../../common/angularData';
import { MvIdentity } from '../../account/mvIdentity';

export type ClientUserBreadcrumbsNavigationSource =
    'AGENCY'
    | 'CLIENT'
    | 'LOCATION';

export class ClientUserBreadcrumbsCtrl {
    static SID = 'ClientUserBreadcrumbsCtrl';

    protected readonly navigationSource! : ClientUserBreadcrumbsNavigationSource;

    protected readonly locationId? : LocationId;

    public readonly clientId? : ClientId;

    protected readonly agencyId? : AgencyId;

    public location? : AssignedLocation;

    public client? : Client;

    public agency? : Agency;

    public clientUser! : User;

    protected readonly fetchLocation = this.interactionUtils.createFuture(this.i18nService.text.common.fetchData,
        (context : { locationId : LocationId }) => this.mvLocation.getAssignedLocation(context.locationId)
            .then(location => this.location = location));

    protected readonly fetchClient = this.interactionUtils.createFuture(this.i18nService.text.common.fetchData,
        (context : { clientId : ClientId }) => this.mvClientResource.get({ id: context.clientId }).$promise
            .then(client => this.client = client));

    protected readonly fetchAgency = this.interactionUtils.createFuture(this.i18nService.text.common.fetchData,
        (context : { agencyId : AgencyId }) => this.agencyApiClient.getAgency(context.agencyId)
            .then(agency => {
                this.agency = agency;
            }));

    static readonly $inject : string[] = [
        $routeParamsSID,
        I18nService.SID,
        InteractionUtils.SID,
        MvIdentity.SID,
        MvLocation.SID,
        mvClientResourceSID,
        AgencyApiClient.SID,
    ];

    constructor(
        protected readonly $routeParams : ClientUserExpectedRouteParams,
        protected readonly i18nService : I18nService,
        protected readonly interactionUtils : InteractionUtils,
        protected readonly mvIdentity : MvIdentity,
        protected readonly mvLocation : MvLocation,
        protected readonly mvClientResource : MvClientResource,
        protected readonly agencyApiClient : AgencyApiClient,
    ) {
        const ids = extractIdsFromRouteParams($routeParams);
        this.locationId = ids.locationId;
        this.clientId = ids.clientId;
        this.agencyId = mvIdentity.currentUser!.agencyId;
        if (this.locationId) {
            this.navigationSource = 'LOCATION';
            void this.fetchLocation.run({ locationId: this.locationId });
        } else if (this.clientId) {
            this.navigationSource = 'CLIENT';
            void this.fetchClient.run({ clientId: this.clientId });
        } else if (this.agencyId) { // Always present
            this.navigationSource = 'AGENCY';
            void this.fetchAgency.run({ agencyId: this.agencyId });
        }
    }

    public comesViaAgency() : boolean {
        return this.navigationSource == 'AGENCY';
    }

    public comesViaClient() : boolean {
        return this.navigationSource == 'CLIENT';
    }

    public comesViaLocation() : boolean {
        return this.navigationSource == 'LOCATION';
    }
}

angular.module('app').controller(ClientUserBreadcrumbsCtrl.SID, ClientUserBreadcrumbsCtrl);
