import { useMutation } from '@apollo/client';
import * as React from 'react';
import { AssignedLocation, LocationId, UploadId, AssetIdAndLayerId, BuilderTemplateId, t } from '@deltasierra/shared';
import { getEntries } from '@deltasierra/object-utilities';


import { GET_BUILDER_TEMPLATE_DRAFT } from '../components/BuilderTemplateDraftActionArea/BuilderTemplateDraftActionsButton/BuilderTemplateDraftActionsButton';
import { useAngularServiceContext } from '../../common/componentUtils/angularServiceContexts';
import { GetForUploadType } from '../mvContentBuilderCtrl';
import { SAVE_BUILDER_TEMPLATE_LOCATION_DRAFT_MUTATION } from './save-builder-template-location-draft.mutation';
import {
    SaveBuilderTemplateLocationDraftForContentBuilder,
    SaveBuilderTemplateLocationDraftForContentBuilderVariables,
} from './__graphqlTypes/SaveBuilderTemplateLocationDraftForContentBuilder';

interface SaveNewBuilderTemplateDraftMutationInput {
    builderTemplateId: BuilderTemplateId;
    compositeImageUploadId: UploadId;
    document: string;
    locationDraftId?: string;
    imageMap: Array<{ key: string; image: UploadId }>;
    linkedAssetLibraryAssetIds: AssetIdAndLayerId[];
    locationId: LocationId;
    title: string;
}

export interface SaveNewBuilderTemplateDraftMutationOptions {
    builderTemplateId: BuilderTemplateId;
    existingDraftId?: string;
    getForUpload: GetForUploadType;
    location: AssignedLocation;
    title: string;
}

export interface SaveNewBuilderTemplateDraftMutationResponse {
    locationDraftId: string;
    title: string;
    builderTemplateId: BuilderTemplateId;
    document: string;
    updatedAt: Date;
    compositeImage: {
        id: string;
        filename: string;
        thumb256x256url: string | null;
    } | null;
}

export function useSaveNewBuilderTemplateDraftMutation(
    onSave: (response: SaveNewBuilderTemplateDraftMutationResponse | undefined) => void,
): [
    (options: SaveNewBuilderTemplateDraftMutationOptions) => Promise<void>,
    SaveNewBuilderTemplateDraftMutationResponse | undefined,
] {
    const notifier = useAngularServiceContext('mvNotifier');

    const [saveLocationDraft] = useMutation<
        SaveBuilderTemplateLocationDraftForContentBuilder,
        SaveBuilderTemplateLocationDraftForContentBuilderVariables
    >(SAVE_BUILDER_TEMPLATE_LOCATION_DRAFT_MUTATION);

    const [response, setResponse] = React.useState<SaveNewBuilderTemplateDraftMutationResponse>();

    return [
        async options => {
            const { compositeImageUpload, contentBuilder, uploadMap } = await options.getForUpload();

            const input: SaveNewBuilderTemplateDraftMutationInput = {
                builderTemplateId: options.builderTemplateId,
                compositeImageUploadId: compositeImageUpload.id,
                document: JSON.stringify(contentBuilder.document),
                imageMap: getEntries(uploadMap).map(entry => ({
                    image: entry[1].id,
                    key: entry[0].toString(),
                })),
                linkedAssetLibraryAssetIds: contentBuilder.linkedAssetLibraryAsset.map(pair => ({
                    assetId: pair.asset.id,
                    layerId: pair.layerId,
                })),
                locationDraftId: options.existingDraftId,
                locationId: options.location.id,
                title: options.title,
            };

            const result = await saveLocationDraft({
                refetchQueries: [
                    {
                        query: GET_BUILDER_TEMPLATE_DRAFT,
                        variables: {
                            builderTemplateId: options.builderTemplateId,
                            locationId: options.location.graphqlId,
                        },
                    },
                ],
                variables: { input },
            });
            if (result.data?.saveBuilderTemplateLocationDraft.__typename === 'SaveLocationDraftSuccessResponse') {
                const notifierMessage = options.existingDraftId
                    ? 'BUILD.LOCATION_DRAFT.UPDATED_LOCATION_DRAFT'
                    : 'BUILD.LOCATION_DRAFT.CREATED_LOCATION_DRAFT';
                notifier.success(t(notifierMessage));
                const locationDraft = result.data.saveBuilderTemplateLocationDraft.locationDraft;
                const saveResponse = {
                    ...locationDraft,
                    builderTemplateId: BuilderTemplateId.from(locationDraft.builderTemplateId),
                    locationDraftId: locationDraft.id,
                    updatedAt: new Date(locationDraft.updatedAt),
                };
                setResponse(saveResponse);
                onSave(saveResponse);
            } else {
                notifier.unexpectedError(t('ERRORS.LOCATION_DRAFT.FAILED_TO_SAVE'));
            }
        },
        response,
    ];
}
