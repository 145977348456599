import * as React from 'react';
import { ThemeProvider } from '@deltasierra/components';
import { withAngularIntegration } from '../../../../common/componentUtils/reactComponentRegistration';
import { ClubReadyChainIdConfigurationWell } from './ClubReadyChainIdConfigurationWell/ClubReadyChainIdConfigurationWell';

function AdminClubReadyConnectPage(): JSX.Element {
    return (
        <ThemeProvider>
            <ClubReadyChainIdConfigurationWell/>
        </ThemeProvider>
    );
}

AdminClubReadyConnectPage.displayName = 'AdminClubReadyConnectPage';

export const AdminClubReadyConnectPageAngular = withAngularIntegration(
    AdminClubReadyConnectPage,
    'adminClubReadyConnectPage',
    {},
);
