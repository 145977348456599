/// <reference path="../../../typings/browser.d.ts" />
import { AgencyUserDto, getUtcDateOnly, LocationId, SpecialRequest } from '@deltasierra/shared';
import { isNotNullOrUndefined, isNullOrUndefined, Untyped } from '@deltasierra/type-utilities';
import moment from 'moment-timezone';
import linq from 'linq';
import { MvIdentity } from '../account/mvIdentity';
import { AgencyApiClient } from '../agencies/agencyApiClient';
import { AgencyUserApiClient } from '../agencies/agencyUserApiClient';
import { MvClientResource, mvClientResourceSID } from '../clients/mvClientResource';
import { $locationSID, $qSID, $scopeSID, IKookies } from '../common/angularData';
import { $modalSID } from '../common/angularUIBootstrapData';
import { ConfirmModal, confirmModalSID } from '../common/confirmModal';
import { DataUtils } from '../common/dataUtils';
import { InteractionUtils } from '../common/interactionUtils';
import { MvNotifier } from '../common/mvNotifier';
import { Paging } from '../common/paging';
import { UploadService } from '../common/uploadService';
import { I18nService } from '../i18n';
import { MvLocation } from '../locations/mvLocation';
import { CurrencyService } from '../payments/currencyService';
import { ModalService } from '../typings/angularUIBootstrap/modalService';
import { BaseSpecialRequestCtrl } from './BaseSpecialRequestCtrl';
import IScope = angular.IScope;
import ILocationService = angular.ILocationService;
import IQService = angular.IQService;

interface ExpandableSpecialRequest extends SpecialRequest {
    expanded?: boolean;
    loading?: boolean;
}

const statusClassMap: { [key: string]: string } = Object.freeze({
    'Amendments Requested': 'bg-danger',
    'Awaiting Approval': 'bg-info',
    'Awaiting Head Office Approval': 'bg-info',
    Closed: 'text-muted bg-muted',
    Complete: 'bg-success',
    Escalated: 'bg-danger',
    'In Progress': 'bg-primary',
    'More Info Required': 'bg-info',
    Open: '',
    'Quote Approved': 'bg-success',
    'Quote Rejected': 'bg-danger',
    Quoted: 'bg-info',
});
export class MvSpecialRequestsCtrl extends BaseSpecialRequestCtrl {
    public static readonly SID = 'mvSpecialRequestsCtrl';

    public fetchSpecialRequestDisclaimer = this.interactionUtils.createFuture(this.i18n.text.common.fetchData(), () =>
        this.agencyApiClient.getSpecialRequestDisclaimer().then(result => {
            this.specialRequestDisclaimer = result.specialRequestDisclaimer;
        }),
    );

    public identity = this.mvIdentity;

    public specialRequests: ExpandableSpecialRequest[] | null = null;

    public specialRequestsFiltered: ExpandableSpecialRequest[] = [];

    public visibleSpecialRequests: ExpandableSpecialRequest[] = [];

    public search = {
        assignedToId: null as Untyped,
        clientAndLocation: null as Untyped,
        createdAt: undefined as Untyped,
        dateRequiredBy: null as Untyped,
        statusStep: null as Untyped,
        title: null as Untyped,
    };

    public agencyManagers: AgencyUserDto[] | null = null;

    public loading = {
        currentSpecialRequest: false,
        deliverables: false,
        saveSpecialRequest: false,
        specialRequests: false,
    };

    public showClosed = false;

    public specialRequestDisclaimer: string | null = null;

    public specialRequestDates: Date[] = [];

    public specialRequestSubmittedDates: Date[] = [];

    public specialRequestPredicates = Object.freeze([
        this.testStatus.bind(this),
        this.testAssignedTo.bind(this),
        this.testClientAndLocation.bind(this),
        this.testTitle.bind(this),
        this.testDateRequiredBy.bind(this),
        this.testCreatedAt.bind(this),
    ]);

    public paging: Paging<ExpandableSpecialRequest> = new Paging<ExpandableSpecialRequest>();


    public static readonly $inject: string[] = [
        $scopeSID,
        $locationSID,
        $qSID,
        '$kookies',
        $modalSID,
        UploadService.SID,
        MvNotifier.SID,
        MvIdentity.SID,
        MvLocation.SID,
        'mvSpecialRequestResource',
        mvClientResourceSID,
        confirmModalSID,
        DataUtils.SID,
        CurrencyService.SID,
        I18nService.SID,
        InteractionUtils.SID,
        AgencyUserApiClient.SID,
        AgencyApiClient.SID,
    ];


    public constructor(
        $scope: IScope,
        $location: ILocationService,
        $q: IQService,
        $kookies: IKookies,
        $modal: ModalService,
        uploadService: UploadService,
        mvNotifier: MvNotifier,
        mvIdentity: MvIdentity,
        mvLocation: MvLocation,
        mvSpecialRequestResource: Untyped,
        mvClientResource: MvClientResource,
        confirmModal: ConfirmModal,
        dataUtils: DataUtils,
        currencyService: CurrencyService,
        protected i18n: I18nService,
        protected interactionUtils: InteractionUtils,
        protected agencyUserApiClient: AgencyUserApiClient,
        protected agencyApiClient: AgencyApiClient,
    ) {
        super(
            $scope,
            $location,
            $q,
            $kookies,
            $modal,
            uploadService,
            mvNotifier,
            mvIdentity,
            mvLocation,
            mvSpecialRequestResource,
            mvClientResource,
            confirmModal,
            dataUtils,
            currencyService,
            i18n,
        );
        this.getLocations();
        this.getStatuses();
        this.getSpecialRequests();
        this.initAgencyManagers();
        this.fetchSpecialRequestDisclaimer.run({});

        const params = this.$location.search();
        if (params.new === true) {
            this.newSpecialRequest();
        }
    }

    public getClosedSpecialRequests(): void {
        this.loading.specialRequests = true;
        this.mvSpecialRequestResource.getClosedSpecialRequests(
            (data: Untyped) => {
                [].push.apply(this.specialRequests, data);
                this.loading.specialRequests = false;
                this.showClosed = true;
                this.populateSpecialRequestDates();
            },
            (data: Untyped) => {
                this.mvNotifier.unexpectedErrorWithData(this.i18n.text.requests.failedToGetSpecialRequests(), data);
                this.loading.specialRequests = false;
            },
        );
    }

    public getStatusGroup = (specialRequest: SpecialRequest): unknown => {
        // Used for ordering
        if (this.getStatusLabel(specialRequest) === 'Closed') {
            return 1;
        } else {
            return -1;
        }
    };

    public toggleSpecialRequestDetails(specialRequest: SpecialRequest & { expanded?: boolean }): void {
        specialRequest.expanded = !specialRequest.expanded;
        this.contractSpecialRequestDetails(specialRequest);
        if (this.currentSpecialRequest === null) {
            this.currentSpecialRequest = this.getSpecialRequestDetails(specialRequest);
        } else if (specialRequest.id !== this.currentSpecialRequest.id) {
            this.currentSpecialRequest = this.getSpecialRequestDetails(specialRequest);
        } else {
            this.currentSpecialRequest = null;
            this.setCurrentClient(null);
        }
    }

    public async newSpecialRequest(): Promise<void> {
        const locationId = await this.mvLocation.getLocationIdOrUpdateWithDefault();
        if (locationId) {
            this.contractSpecialRequestDetails(null);
            this.currentSpecialRequest = {
                channels: [],
                locationId,
                statusStep: 1,
            } as any;
            const params = this.$location.search();
            if (params.title) {
                this.currentSpecialRequest!.title = params.title;
            }
            if (params.brief) {
                this.currentSpecialRequest!.brief = params.brief;
            }
            if (params.locationId) {
                this.currentSpecialRequest!.locationId = LocationId.from(this.convertToInt(params.locationId));
            }
            if (params.date) {
                this.currentSpecialRequest!.dateRequiredBy = new Date(params.date);
            }
            return this.getClientForLocation(locationId);
        }
        return Promise.resolve();
    }

    public async saveSpecialRequest(): Promise<unknown> {
        return this.interactionUtils
            .handleResource(
                this,
                this.i18n.text.requests.saveSpecialRequest(),
                'saveSpecialRequest',
                super.saveSpecialRequest(),
            )
            .then(data => this.handleResponse(data));
    }

    public cancelSpecialRequest(): void {
        this.currentSpecialRequest = null;
        this.contractSpecialRequestDetails(null);
    }

    public shouldShowForm(): boolean {
        return !!this.currentSpecialRequest || this.loading.currentSpecialRequest;
    }

    public getStatusClass(specialRequest: SpecialRequest): string {
        const label = this.getStatusLabel(specialRequest);
        let cssClass;
        if (label) {
            cssClass = statusClassMap[label];
        }
        if (!cssClass) {
            cssClass = '';
        }
        return cssClass;
    }

    public canSeeAssignedTo(): boolean {
        return this.mvIdentity.isManager();
    }

    public canFilterData(): boolean {
        return this.mvIdentity.isManager();
    }

    public specialRequestPredicateFunc = (specialRequest: ExpandableSpecialRequest, index: number): boolean => {
        for (const predicate of this.specialRequestPredicates) {
            if (!predicate(specialRequest, index)) {
                return false;
            }
        }
        return true;
    };

    public onAgencyManagerSelected(
        agencyManager: AgencyUserDto | undefined,
        specialRequest: ExpandableSpecialRequest,
    ): void {
        specialRequest.assignedToId = agencyManager ? agencyManager.id : null;
        this.assignSpecialRequest(specialRequest);
    }

    public assignSpecialRequest(specialRequest: ExpandableSpecialRequest): void {
        specialRequest.loading = true;
        this.mvSpecialRequestResource.assign(
            {
                id: specialRequest.id,
            },
            {
                userId: specialRequest.assignedToId,
            },
            (data: Untyped) => {
                specialRequest.loading = false;
                if (this.currentSpecialRequest && this.currentSpecialRequest.id === specialRequest.id) {
                    this.currentSpecialRequest.assignedToId = specialRequest.assignedToId;
                    // TODO: update the "lastUpdatedAt" as well
                }
            },
            (data: Untyped) => {
                this.mvNotifier.unexpectedErrorWithData('Failed to assign special request', data);
            },
        );
    }

    public getColspan(): number {
        return this.canSeeAssignedTo() ? 6 : 5;
    }

    private getSpecialRequests() {
        this.loading.specialRequests = true;
        this.specialRequests = this.mvSpecialRequestResource.query(
            () => {
                this.loading.specialRequests = false;
                this.populateSpecialRequestDates();
            },
            (data: any) => {
                this.mvNotifier.unexpectedErrorWithData(this.i18n.text.requests.failedToGetSpecialRequests(), data);
                this.loading.specialRequests = false;
            },
        );
    }

    private getDateOptions(selector: (sr: SpecialRequest) => Date) {
        if (this.specialRequests) {
            return (
                linq
                    .from(this.specialRequests)
                    .select(selector)
                    .distinct(date => date.getTime())
                    .toArray()

                    .sort((a, b) => a.getTime() - b.getTime())
            );
        } else {
            return [];
        }
    }

    private populateSpecialRequestDates() {
        this.specialRequestDates = this.getDateOptions(sr => getUtcDateOnly(sr.dateRequiredBy!)).filter(
            isNotNullOrUndefined,
        );
        this.specialRequestSubmittedDates = this.getDateOptions(sr => getUtcDateOnly(sr.createdAt)).filter(
            isNotNullOrUndefined,
        );
    }

    private initAgencyManagers() {
        if (this.canSeeAssignedTo()) {
            this.agencyUserApiClient
                .getManagers()
                .then(data => {
                    this.agencyManagers = data;
                })
                .catch(data => {
                    this.mvNotifier.unexpectedErrorWithData(this.i18n.text.requests.loadManagersFailed(), data);
                });
        }
    }

    private getSpecialRequestDetails(specialRequest: SpecialRequest) {
        this.currentSpecialRequest = null;
        this.loading.currentSpecialRequest = true;
        const specialRequestDetails = this.mvSpecialRequestResource.get(
            { id: specialRequest.id },
            (data: Untyped) => {
                // Need to manually coerce to a date, so that the datepicker input field detects the existing value.
                // (Otherwise, if the field is "required", it doesn't get detected correctly)
                this.rectifySpecialRequestDate(data);
                this.loading.currentSpecialRequest = false;
            },
            (data: Untyped) => {
                this.loading.currentSpecialRequest = false;
                this.mvNotifier.unexpectedErrorWithData(this.i18n.text.requests.failedToGetSpecialRequest(), data);
            },
        );
        specialRequestDetails.$promise
            .then(async (specialRequestDetails1: Untyped) =>
                this.getClientForLocation(specialRequestDetails1.locationId),
            )
            // eslint-disable-next-line no-console
            .catch(console.error);
        return specialRequestDetails;
    }

    private contractSpecialRequestDetails(specialRequest: SpecialRequest | null) {
        if (this.specialRequests) {
            for (const otherRequest of this.specialRequests) {
                // Lame
                if (otherRequest !== specialRequest) {
                    otherRequest.expanded = false;
                }
            }
        }
    }

    private handleResponse(data: SpecialRequest) {
        if (this.currentSpecialRequest) {
            if (!this.currentSpecialRequest.id) {
                if (this.specialRequests) {
                    this.specialRequests.push(angular.copy(data));
                }
                this.contractSpecialRequestDetails(null);
                this.currentSpecialRequest = null;
            } else {
                if (this.specialRequests) {
                    for (let i = 0; i < this.specialRequests.length; i++) {
                        const specialRequest = this.specialRequests[i];
                        if (specialRequest.id === data.id) {
                            this.specialRequests[i] = angular.copy(data); // Copy to prevent two-way binding
                            this.specialRequests[i].expanded = true;
                            break;
                        }
                    }
                }
                this.currentSpecialRequest = data;
                this.rectifySpecialRequestDate(this.currentSpecialRequest);
            }
        }
    }

    private isCriterionEmpty<T>(value: T | string | null | undefined): boolean {
        return value === undefined || value === null || value === '';
    }

    private containsText(value: string, toFind: string) {
        return value.toLocaleLowerCase().indexOf(toFind.toLocaleLowerCase()) > -1;
    }

    private testAssignedTo(specialRequest: SpecialRequest, index: number) {
        const searchAssignedTo = this.search.assignedToId;
        if (this.isCriterionEmpty(searchAssignedTo)) {
            return true; // Null search criteria = show all
        }
        const assignedTo = specialRequest.assignedToId;
        if (searchAssignedTo === 'nobody') {
            return isNullOrUndefined(assignedTo);
        }
        return searchAssignedTo === assignedTo?.toString();
    }

    private testStatus(specialRequest: SpecialRequest, index: number) {
        const toFind = this.search.statusStep;
        if (this.isCriterionEmpty(toFind)) {
            return true; // Null search criteria = show all
        }
        const value = specialRequest.statusStep;
        return value === toFind;
    }

    private testClientAndLocation(specialRequest: SpecialRequest, index: number) {
        const searchClientAndLocation = this.search.clientAndLocation;
        if (this.isCriterionEmpty(searchClientAndLocation)) {
            return true;
        }
        const clientAndLocation = this.getClientAndLocationName(specialRequest);
        return this.containsText(clientAndLocation, searchClientAndLocation);
    }

    private testTitle(specialRequest: SpecialRequest, index: number) {
        const toFind = this.search.title;
        if (this.isCriterionEmpty(toFind)) {
            return true;
        }
        const value = specialRequest.title;
        return this.containsText(value, toFind);
    }

    private testDateRequiredBy(specialRequest: SpecialRequest, index: number) {
        const toFind = this.search.dateRequiredBy;
        if (this.isCriterionEmpty(toFind)) {
            return true;
        }
        const value = specialRequest.dateRequiredBy;
        return moment(toFind).isSame(value || undefined);
    }

    private testCreatedAt(specialRequest: SpecialRequest, index: number) {
        const criteria = this.search.createdAt;
        if (this.isCriterionEmpty(criteria)) {
            return true;
        }
        const tmpDate = new Date(criteria);
        const toFind = new Date(Date.UTC(tmpDate.getFullYear(), tmpDate.getMonth(), tmpDate.getDate()));

        const tmpValue = angular.isString(specialRequest.createdAt)
            ? new Date(specialRequest.createdAt as unknown as string)
            : specialRequest.createdAt;
        const value = new Date(Date.UTC(tmpValue.getFullYear(), tmpValue.getMonth(), tmpValue.getDate()));
        return moment(toFind).isSame(value);
    }
}

angular.module('app').controller(MvSpecialRequestsCtrl.SID, MvSpecialRequestsCtrl);
