/// <reference path="../../../../typings/browser.d.ts" />
import { ReportableService } from '@deltasierra/integrations/integration-types';
import { MailchimpReportEntry } from '@deltasierra/shared';
import { $httpSID } from '../../common/angularData';
import { DataUtils } from '../../common/dataUtils';
import { BaseLocationStatsService } from './common';
import IHttpService = angular.IHttpService;

export class MailchimpStatsService extends BaseLocationStatsService<MailchimpReportEntry[], MailchimpReportEntry> {
    public static SID = 'MailchimpStatsService';

    public static readonly $inject: string[] = [$httpSID, DataUtils.SID];

    public serviceName: ReportableService = 'mailchimp';

    public displayName = 'Campaign Monitor';

    public constructor($http: IHttpService) {
        super($http);
    }

    public combineStats(stats: MailchimpReportEntry[]): MailchimpReportEntry {
        const dummy: MailchimpReportEntry = {
            additionalMailchimpData: null,
            bounceCount: 0,
            campaignName: 'dummy',
            clickCount: 0,
            recipientCount: 0,
            uniqueOpenedCount: 0,
        };
        return stats.reduce(
            (prev: MailchimpReportEntry, cur: MailchimpReportEntry) => ({
                ...prev,
                bounceCount: prev.bounceCount + cur.bounceCount,
                clickCount: prev.clickCount + cur.clickCount,
                recipientCount: prev.recipientCount + cur.recipientCount,
                uniqueOpenedCount: prev.uniqueOpenedCount + cur.uniqueOpenedCount,
            }),
            dummy,
        );
    }
}

angular.module('app').service('MailchimpStatsService', MailchimpStatsService);
