/// <reference path="../../../typings/browser.d.ts" />
import { I18nService } from '../i18n/i18nService';
import { $locationSID } from '../common/angularData';


interface IPage {
    title: () => string;
    path: string;
    isActive?: boolean;
}

export class AdminCtrl {
    public static SID = 'AdminCtrl';

    public static readonly $inject: string[] = [$locationSID, I18nService.SID];

    public pages: IPage[] = [
        { path: '/admin/siteMaintenance', title: (): string => this.i18n.text.admin.nav.siteMaintenance() },
        { path: '/admin/bannerNotification', title: (): string => this.i18n.text.admin.nav.bannerNotification() },
        { path: '/admin/tasks', title: (): string => this.i18n.text.admin.nav.tasks() },
        {
            path: '/admin/external-authentication-apps',
            title: (): string => this.i18n.text.admin.nav.authenticationApps(),
        },
        { path: '/admin/payable-services', title: (): string => this.i18n.text.admin.nav.payableServices() },
        { path: '/admin/maps-report', title: (): string => this.i18n.text.admin.nav.mapsReport() },
        { path: '/admin/brands', title: (): string => this.i18n.text.admin.nav.brands() },
        { path: '/admin/servicing', title: (): string => this.i18n.text.admin.nav.servicing() },
        { path: '/admin/clubready-connect', title: (): string => this.i18n.text.admin.nav.clubreadyConnect() },
    ];

    public constructor($location: ng.ILocationService, private i18n: I18nService) {
        const path = $location.path();

        for (const page of this.pages) {
            page.isActive = path.lastIndexOf(page.path) === 0;

            if (page.isActive) {
                this.title = page.title;
            }
        }
    }

    public title = (): string => '';
}

angular.module('app').controller(AdminCtrl.SID, AdminCtrl);
