import * as linq from 'linq';
import { Untyped, CurrencyCode } from '@deltasierra/shared';

import { ReportTable, StatsEntry, TextAlignClass } from '../reportTable/reportTable';
import { compareNumbers, compareStrings } from '../compare';
import { SimpleFacebookAdSetStats } from '../../integration/stats/facebookMarketingStatsService';
import { CreateReportTableOptions } from './common';

function getAllCurrencyCodes(entries: Array<{ currencyCode: CurrencyCode }>): CurrencyCode[] {
    const currencyCodes = linq
        .from(entries)
        .select(entry => entry.currencyCode)
        .distinct()
        .toArray();
    return currencyCodes;
}

export function containsMultipleCurrencies(entries: Array<{ currencyCode: CurrencyCode }>): boolean {
    const currencyCodes = getAllCurrencyCodes(entries);
    return currencyCodes.length > 1 || currencyCodes[0] === null;
}

export function createFacebookMarketingReportTable(
    options: CreateReportTableOptions,
): ReportTable<StatsEntry<SimpleFacebookAdSetStats>, SimpleFacebookAdSetStats> {
    const formatCurrency = (value: number, currencyCode: Untyped) => {
        if (value === null || value === Number.POSITIVE_INFINITY) {
            return '-';
        } else if (currencyCode) {
            return options.formatters.formatCurrency(value, { code: currencyCode, format: 'symbol' });
        } else {
            return options.formatters.formatCurrency(value, { format: 'number' });
        }
    };

    const result: ReportTable<StatsEntry<SimpleFacebookAdSetStats>, SimpleFacebookAdSetStats> = new ReportTable<
        StatsEntry<SimpleFacebookAdSetStats>,
        SimpleFacebookAdSetStats
    >({
        columns: [
            {
                cssClass: TextAlignClass.Left,
                format: options.formatters.formatString,
                getTotal: () => options.i18n.text.report.total(),
                getValue: entry => entry.location.locationTitle,
                label: () => options.i18n.text.report.location(),
            },
            {
                cssClass: TextAlignClass.Left,
                format: options.formatters.formatString,
                getTotal: () => undefined,
                getValue: entry => entry.name,
                label: () => options.i18n.text.report.adName(),
            },
            {
                compare: compareStrings,
                cssClass: TextAlignClass.Center,
                format: options.formatters.formatString,
                getIsVisible: reportTable =>
                    reportTable ? containsMultipleCurrencies(reportTable.data.getEntries()) : false,
                getTotal: () => null,
                getValue: entry => entry.currencyCode || '-',
                label: () => options.i18n.text.report.currency(),
            },
            {
                compare: compareNumbers,
                cssClass: TextAlignClass.Right,
                format: (value, entry) => (entry ? formatCurrency(value, entry.currencyCode) : ''),
                getTotal: totals => (containsMultipleCurrencies(result.data.getEntries()) ? null : totals.spend),
                getValue: entry => entry.spend,
                label: () => options.i18n.text.report.spend(),
            },
            {
                compare: compareNumbers,
                cssClass: TextAlignClass.Right,
                format: options.formatters.formatNumber,
                getTotal: totals => totals.reach,
                getValue: entry => entry.reach,
                label: () => options.i18n.text.report.peopleReached(),
            },
            {
                compare: compareNumbers,
                cssClass: TextAlignClass.Right,
                format: options.formatters.formatNumber,
                getTotal: totals => totals.uniqueClicks,
                getValue: entry => entry.uniqueClicks,
                label: () => options.i18n.text.report.uniqueClicks(),
            },
            {
                compare: compareNumbers,
                cssClass: TextAlignClass.Right,
                format: (value, entry) => (entry ? formatCurrency(value, entry.currencyCode) : ''),
                getTotal: totals =>
                    containsMultipleCurrencies(result.data.getEntries()) ? null : totals.costPerUniqueClick,
                getValue: entry => entry.costPerUniqueClick,
                label: () => options.i18n.text.report.costPerUniqueClick(),
            },
            {
                compare: compareNumbers,
                cssClass: TextAlignClass.Right,
                format: options.formatters.formatNumber,
                getTotal: totals => totals.actionsLead,
                getValue: entry => entry.actionsLead,
                label: () => options.i18n.text.report.leads(),
            },
            {
                compare: compareNumbers,
                cssClass: TextAlignClass.Right,
                format: (value, entry) => (entry ? formatCurrency(value, entry.currencyCode) : ''),
                getTotal: totals => (containsMultipleCurrencies(result.data.getEntries()) ? null : totals.costPerLead),
                getValue: entry => entry.costPerLead,
                label: () => options.i18n.text.report.costPerLead(),
            },
        ],
    });

    return result;
}
