import { useLocalStorage } from '@deltasierra/react-hooks';
import * as React from 'react';
import { CurrentClientIdType, CurrentClientStorageStateContext } from '../contexts';

export const CurrentClientContextLocalStorageKey = 'ds_current_client' as const;

/**
 * Use the current client state from the local storage context or the actual local storage.
 *
 * @returns The local storage actions for the current client key
 */
export function useCurrentClientLocalStorageState(): CurrentClientIdType | undefined {
    const valueFromContext = React.useContext(CurrentClientStorageStateContext);
    const [valueFromLocalStorage] = useLocalStorage<CurrentClientIdType>(CurrentClientContextLocalStorageKey);
    return valueFromContext ?? valueFromLocalStorage ?? undefined;
}
