import {
    justParams,
    paramsAndBody,
    AssignedLocation,
    CallToActionType,
    ExportPlatforms,
    GoogleMyBusinessEndpoints,
    GoogleMyBusinessPublishData,
    IPublishImageRequest,
    LocationId,
    LocationIdHierarchy,
    PublishedArtifactGroupId,
    UploadId,
} from '@deltasierra/shared';
import * as React from 'react';
import { OneWayBinding } from '../../../common/angularData';
import { useAngularServiceContext } from '../../../common/componentUtils/angularServiceContexts';
import { withAngularIntegration } from '../../../common/componentUtils/reactComponentRegistration';
import { invokeApiRoute } from '../../../common/httpUtils';
import { useImageProcessingContext } from '../common/hooks';
import { ImagePublishServiceCompletionEvent } from '../ImagePublishService';
import { MultipleLocationPublish } from '../multipleLocationPublish/MultipleLocationPublish';
import { MvContentBuilderPublishCtrl } from '../mvContentBuilderPublishCtrl';
import { PublishResult } from '../publishResult';
import { PublishCallback, PublishFinishCallback } from '../types';
import { PublishToGoogleMyBusinessForm } from './PublishToGoogleMyBusinessForm';

export type PublishToGoogleMyBusinessProps = {
    location: AssignedLocation;
    locations: LocationIdHierarchy[];
    onCancel: () => void;
    onFinish: (data: PublishResult) => void;
    publishController: MvContentBuilderPublishCtrl;
};

const PublishToGoogleMyBusiness: React.FunctionComponent<PublishToGoogleMyBusinessProps> = ({
    location,
    locations,
    onCancel,
    onFinish,
    publishController,
}) => {
    const [isPublishing, setIsPublishing] = React.useState(false);
    const [publishData, setPublishData] = React.useState<GoogleMyBusinessPublishData | null>(null);
    const publishCallback = usePublishCallback(publishController);

    const onPublish = (data: GoogleMyBusinessPublishData) => {
        setIsPublishing(true);
        /**
         * DS-10879: If it's a CALL TO ACTION post with a call button, we must omit the "url" property.
         */
        if (data.callToAction?.actionType === CallToActionType.call) {
            delete data.callToAction.url;
        }
        setPublishData(data);
    };

    const finishCallback: PublishFinishCallback = () => {
        const scheduledTime = (publishData && publishData.scheduledTime) || null;
        onFinish(new PublishResult(ExportPlatforms.GoogleMyBusiness, scheduledTime));
    };

    return (
        <>
            {!isPublishing && (
                <PublishToGoogleMyBusinessForm
                    location={location}
                    locations={locations}
                    publishController={publishController}
                    onCancel={onCancel}
                    onPublish={onPublish}
                />
            )}
            {isPublishing && publishData && (
                <MultipleLocationPublish
                    commonData={publishData}
                    contentBuilder={publishController.contentBuilder}
                    locations={locations}
                    originalLocation={location}
                    publishCallback={publishCallback}
                    publishCtrl={publishController}
                    onCancel={onCancel}
                    onFinish={finishCallback}
                />
            )}
        </>
    );
};

function usePublishCallback(
    publishController: MvContentBuilderPublishCtrl,
): PublishCallback<GoogleMyBusinessPublishData> {
    const imagePublishService = useAngularServiceContext('ImagePublishService');
    const i18n = useAngularServiceContext('I18nService');
    const canPostToLocation = useCanPostToGoogleMyBusinessForLocation();
    const publishPhotoCallback = usePublishPhotoCallback();
    const ImageProcessingContext = useImageProcessingContext();

    return React.useCallback<PublishCallback<GoogleMyBusinessPublishData>>(
        async (
            locationId: LocationId,
            uploadId: UploadId,
            commonData: GoogleMyBusinessPublishData,
            publishedArtifactGroupId: PublishedArtifactGroupId | null,
        ) => {
            if (await canPostToLocation(locationId)) {
                return imagePublishService.publishPhoto(
                    ImageProcessingContext.current,
                    locationId,
                    uploadId,
                    publishController.templateId,
                    publishController.plannerDetails || null,
                    commonData,
                    publishPhotoCallback,
                    publishController.contentBuilder.linkedAssetLibraryAsset.map(value => ({
                        assetId: value.asset.id,
                        layerId: value.layerId,
                    })),
                    publishedArtifactGroupId,
                    ImagePublishServiceCompletionEvent.PlannerCreated,
                );
            } else {
                throw new Error(i18n.text.build.publish.googleMyBusiness.accessCheckFailure());
            }
        },
        [
            ImageProcessingContext,
            canPostToLocation,
            i18n.text.build.publish.googleMyBusiness,
            imagePublishService,
            publishController.plannerDetails,
            publishController.templateId,
            publishController.contentBuilder,
            publishPhotoCallback,
        ],
    );
}

function useCanPostToGoogleMyBusinessForLocation() {
    const http = useAngularServiceContext('$http');
    return React.useCallback(
        async (locationId: LocationId) => {
            const { result } = await invokeApiRoute(
                http,
                GoogleMyBusinessEndpoints.canPostForLocation,
                justParams({ locationId }),
            );
            return result;
        },
        [http],
    );
}

function usePublishPhotoCallback() {
    const http = useAngularServiceContext('$http');
    return React.useCallback(
        (locationId: LocationId, requestBody: IPublishImageRequest<GoogleMyBusinessPublishData>) =>
            invokeApiRoute(
                http,
                GoogleMyBusinessEndpoints.publishPhoto,
                paramsAndBody(
                    {
                        locationId,
                    },
                    requestBody,
                ),
            ),
        [http],
    );
}

export default withAngularIntegration(PublishToGoogleMyBusiness, 'publishToGoogleMyBusiness', {
    location: OneWayBinding,
    locations: OneWayBinding,
    onCancel: OneWayBinding,
    onFinish: OneWayBinding,
    publishController: OneWayBinding,
});
