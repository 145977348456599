import * as React from 'react';
import { useAngularComponentRenderer, useAngularScope } from '../../componentUtils/angularRendering';
import { noop } from '@deltasierra/object-utilities';

type TimePickerScope = Pick<TimeOnlyPickerProps, 'hourStep' | 'minuteStep' | 'showMeridian' | 'meridians' | 'min'> & {
    datePickerValue: Date | null;
    onChange: () => void;
};

export type TimeOnlyPickerProps = {
    value: Date | null;
    onChange?: (date: Date | null) => void;
    hourStep?: number;
    minuteStep?: number;
    /**
     * Whether to display 12H or 24H
     */
    showMeridian?: boolean;
    /**
     * Meridian labels based on locale. To override you must supply an array like ['AM', 'PM']
     */
    meridians?: [string, string];
    min?: Date | null;
    max?: Date | null;
};

export const TimeOnlyPicker: React.FunctionComponent<TimeOnlyPickerProps> = ({
    hourStep = 1,
    minuteStep = 1,
    showMeridian = false,
    meridians = ['AM', 'PM'],
    onChange = noop,
    value,
    min,
}) => {
    const scope = useAngularScope<TimePickerScope>({ hourStep, meridians, min, minuteStep, showMeridian });

    scope.current.datePickerValue = value;
    scope.current.onChange = () => {
        onChange(scope.current.datePickerValue);
    };

    return useAngularComponentRenderer('<timepicker ng-model="datePickerValue" ng-change="onChange()" min="min"></timepicker>', scope);
};

TimeOnlyPicker.displayName = 'TimeOnlyPicker';
