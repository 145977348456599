/// <reference path="../../_references.d.ts" />

import { FontChooserController } from './controller';

export class FontChooserService {
    static SID = 'FontChooserService';

    static readonly $inject : string[] = ['$modal'];

    constructor(private $modal : any) {
    }

    public showModal() : ng.IPromise<File> {
        const modalInstance = this.$modal.open({
            animation: true,
            backdrop: 'static',
            keyboard: true,
            templateUrl: 'partials/clients/fontChooser/template.jade',
            controller: FontChooserController,
            controllerAs: 'ctrl',
        });

        return modalInstance.result;
    }
}

angular.module('app').service(FontChooserService.SID, FontChooserService);
