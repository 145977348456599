import { ClubReadyMergeFields } from '@deltasierra/shared';
import { TaRegisterTool } from '../../typings/textAngular/textAngular';
import { I18nService } from '../../i18n';

export function registerFitwareMailMergeTool(taRegisterTool: TaRegisterTool, i18n: I18nService): void {
    const toolName = 'fitwareMailMerge';
    let deferred: ng.IDeferred<void>;
    taRegisterTool(toolName, {
        action(newDeferred) {
            // Do nothing, it's handled by "insertText()"
            deferred = newDeferred;
            return false;
        },
        // Override textAngular's default button style, it causes nested buttons. Use "dropdown" for rounded corners,
        //  Or "btn-group" for hard corners (and if we have multiple tools in the same toolbar row).
        class: 'dropdown', // 'btn-group',
        display: `
        <div>
            <button class="btn btn-default btn-sm dropdown-toggle" type="button" data-toggle="dropdown" ng-disabled="isDisabled()">
                ${i18n.text.build.email.mailMerge()}
                <span class="caret"></span>
            </button>
            <ul class="dropdown-menu">
                <li ng-repeat="option in options">
                    <a href="#" ng-click="insertText(option)">{{ option }}</a>
                </li>
            </ul>
        </div>
        `,
        insertText(value: string) {
            this.$editor().wrapSelection('insertText', `[[${value}]]`);
            deferred.resolve();
        },

        options: [
            'ToFirstName',
            'ToLastName',
            'ToEmailAddress', // NOTE: this was listed as "ToEmailAddres" in the Q/A doc from Fitware,
            // I'm not sure if that misspelling is intentional.
            'FromFirstName',
            'FromLastName',
            'FromEmailAddress',
            'CCEmailAddress',
            'BCCEmailAddress',
            'locationName',
            'locationAddressStreet1',
            'locationAddressStreet2',
            'locationAddressCity',
            'locationAddressState',
            'locationAddressPostalCode',
            'locationPhoneRaw',
            'locationEmailAddress',
            'unsubscribeLink',
        ],
    });
}

export function registerClubReadyMailMergeTool(taRegisterTool: TaRegisterTool, i18n: I18nService): void {
    const toolName = 'clubReadyMailMerge';
    let deferred: ng.IDeferred<void>;
    taRegisterTool(toolName, {
        action(newDeferred) {
            deferred = newDeferred;
            return false;
        },
        class: 'dropdown',
        display: `
            <div>
                <button class="btn btn-default btn-sm dropdown-toggle" type="button" data-toggle="dropdown" ng-disabled="isDisabled()">
                    ${i18n.text.build.email.mailMerge()}
                    <span class="caret"></span>
                </button>
                <ul class="dropdown-menu">
                    <li ng-repeat="option in options">
                        <a href="#" ng-click="insertText(option)">{{ option }}</a>
                    </li>
                </ul>
            </div>
        `,
        insertText(value: string) {
            this.$editor().wrapSelection('insertText', `[[${value}]]`);
            deferred.resolve();
        },
        options: ClubReadyMergeFields,
    });
}
