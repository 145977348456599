/// <reference path="../_references.d.ts" />
import {
    displayMaintenanceNotificationSocketEvent,
    displayNotificationSocketEvent,
    MaintenanceNotification,
    ShowNotificationRequest,
} from '@deltasierra/shared';
import { MvIdentity } from '../account/mvIdentity';
import { IKookies } from '../common/angularData';
import { MvNotifier } from '../common/mvNotifier';
import { SocketService } from './socketService';

export class NotificationsSocketService {
    public static SID = 'NotificationsSocketService';

    private static dismissedMaintenanceNotificationIdCookieName =
        'dismissedMaintenanceNotificationId' as const;

    private lastMaintenanceNotification: MaintenanceNotification | null = null;

    public static readonly $inject: string[] = [MvNotifier.SID, '$kookies', '$filter', MvIdentity.SID];

    public constructor(
        private notifier: MvNotifier,
        private $kookies: IKookies,
        private $filter: ng.IFilterService,
        private identity: MvIdentity,
    ) {}

    public registerSocketListeners(socketService: SocketService): void {
        socketService.on(displayNotificationSocketEvent, data => this.notify(data));
        socketService.on(displayMaintenanceNotificationSocketEvent, data => this.notifyOfMaintenance(data));
    }

    private notify({ message, options }: ShowNotificationRequest) {
        this.notifier.notify(message, options);
    }

    private haveAlreadyDismissedMaintenanceNotification(notification: MaintenanceNotification) {
        const lastDismissedId = this.$kookies.get(
            NotificationsSocketService.dismissedMaintenanceNotificationIdCookieName,
            Number,
        );

        return !!lastDismissedId && lastDismissedId >= notification.id;
    }

    private dismissMaintenanceNotification(notification: MaintenanceNotification) {
        this.$kookies.set(NotificationsSocketService.dismissedMaintenanceNotificationIdCookieName, notification.id, {
            path: '/',
            secure: true,
        });
        this.lastMaintenanceNotification = null;
    }

    private formatMaintenanceNotificationMessage(notification: MaintenanceNotification): string {
        if (!notification) {
            return 'No site maintenance is currently scheduled'; // TODO: translate this
        }

        const startTimeFormatted = this.$filter('date')(notification.startTime, 'd MMM yyyy h:mm a');
        const downtimeFormatted = notification.expectedDowntimeInMinutes
            ? `an expected downtime of ${notification.expectedDowntimeInMinutes} minutes`
            : 'no expected downtime'; // TODO: translate this

        return `Site maintenance is scheduled for ${startTimeFormatted} with ${downtimeFormatted}. You will be logged out and lose any unsaved work at the time.`; // TODO: translate this
    }

    private notifyOfMaintenance({ notification }: { notification: MaintenanceNotification; message: string }) {
        if (!this.identity.isAuthenticated()) {
            return;
        }

        if (this.haveAlreadyDismissedMaintenanceNotification(notification)) {
            return;
        }

        if (this.lastMaintenanceNotification) {
            const lastMessage = this.formatMaintenanceNotificationMessage(this.lastMaintenanceNotification);
            this.notifier.clearMessage(lastMessage);
        }

        const message = this.formatMaintenanceNotificationMessage(notification);
        this.notifier.notify(message, {
            closeButton: false, // Close click doesn't work in toastr v2.0.3
            extendedTimeOut: 0,
            onClick: () => this.dismissMaintenanceNotification(notification),
            // OnCloseClick: () => this.dismissMaintenanceNotification(notification),
            preventDuplicates: true,
            timeOut: 0,
            title: 'Site Maintenance',
            type: 'warning',
        });

        this.lastMaintenanceNotification = notification;
    }
}

angular.module('app').service(NotificationsSocketService.SID, NotificationsSocketService);
