import {
    AgencyId,
    AgencyNotification,
    AgencyNotificationsApi,
    AgencyNotificationWithReporting,
    CreateAgencyNotificationBody,
    emptyRequest,
    paramsAndBody,
} from '@deltasierra/shared';
import { getData, invokeApiRoute } from '../../common/httpUtils';

export class AgencyNotificationsService {
    public static SID = 'AgencyNotificationsService';

    public static readonly $inject: string[] = ['$http'];

    private static url = '/api/agency/notificationEmailAddresses';

    public constructor(private $http: ng.IHttpService) {}

    public getEmailAddresses(): ng.IPromise<string[]> {
        return this.$http.get<string[]>(AgencyNotificationsService.url).then(getData);
    }

    public addEmailAddress(emailAddress: string): ng.IPromise<string[]> {
        return this.$http.post<string[]>(AgencyNotificationsService.url, { emailAddress }).then(getData);
    }

    public removeEmailAddress(emailAddress: string): ng.IPromise<string[]> {
        return this.$http
            .delete<string[]>(AgencyNotificationsService.url, {
                data: { emailAddress },
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                },
            })
            .then(getData);
    }

    public createNewNotification(
        agencyId: AgencyId,
        notification: CreateAgencyNotificationBody,
    ): ng.IPromise<AgencyNotification> {
        return invokeApiRoute(
            this.$http,
            AgencyNotificationsApi.createNotification,
            paramsAndBody(
                {
                    agencyId,
                },
                notification,
            ),
        );
    }

    public getAgencyNotifications(): ng.IPromise<AgencyNotificationWithReporting[]> {
        return invokeApiRoute(this.$http, AgencyNotificationsApi.getAgencyNotifications, emptyRequest());
    }
}

angular.module('app').service(AgencyNotificationsService.SID, AgencyNotificationsService);
