import { gql, useQuery } from '@apollo/client';
import { useConnectionFetchMore } from '../../../graphql/hooks';
import { relayConnectionToArray } from '../../../graphql/utils';
import { ClientListRow } from './ClientListRow';
import { ClientFragmentForClientListRow } from './__graphqlTypes/ClientFragmentForClientListRow';
import { ClientsForClientTable, ClientsForClientTableVariables } from './__graphqlTypes/ClientsForClientTable';

const CLIENTS_FOR_CLIENT_LIST = gql`
    query ClientsForClientTable($after: String, $filter: AgencyClientsFilterInput) {
        me {
            id
            agency {
                id
                clients(after: $after, first: 20, filter: $filter) {
                    edges {
                        node {
                            __typename
                            id
                            ...ClientFragmentForClientListRow
                        }
                    }
                    pageInfo {
                        endCursor
                        hasNextPage
                    }
                }
            }
        }
    }
    ${ClientListRow.fragments.client}
`;

export interface UseClientResponse {
    clients: ClientFragmentForClientListRow[];
    fetchMore: () => void;
    hasNextPage: boolean;
    loading: boolean;
}

export function useClients(titleSearch: string): UseClientResponse {
    const { data, fetchMore, loading } = useQuery<ClientsForClientTable, ClientsForClientTableVariables>(
        CLIENTS_FOR_CLIENT_LIST,
        {
            fetchPolicy: 'cache-and-network',
            notifyOnNetworkStatusChange: true,
            variables: { filter: { title: titleSearch } },
        },
    );

    const clients = relayConnectionToArray(data?.me.agency.clients);

    const [handleFetchMore, hasNextPage] = useConnectionFetchMore(data?.me.agency.clients, async after =>
        fetchMore({ variables: { after } }),
    );

    return { clients, fetchMore: handleFetchMore, hasNextPage, loading };
}
