import { Client, Location, SanitizedUser } from '@deltasierra/shared';
import * as React from 'react';
import { Translate } from '../../directives/Translate';
import { ExpressionBinding, OneWayBinding } from '../angularData';
import { ConfirmButton } from '../Button';
import { withAngularIntegration } from '../componentUtils/reactComponentRegistration';
import UsersTable from './UsersTable';

export type LocationUsersTableProps = {
    client: Pick<Client, 'id'>;
    dataCy?: string;
    location: Pick<Location, 'id' | 'title'>;
    onRemoveUser: (user: SanitizedUser) => void;
    users: SanitizedUser[];
};

const ClientUsersTable: React.FunctionComponent<LocationUsersTableProps> = ({
    client,
    dataCy,
    location,
    onRemoveUser,
    users,
}) => (
    <UsersTable
        data-cy={dataCy}
        extraActions={user => (
            <ConfirmButton
                confirmMessage={{
                    keyId: 'AGENCY.CLIENT.LOCATION.USERS.DELETE_CONFIRMATION',
                    options: { firstName: user.firstName, lastName: user.lastName, location: location.title },
                }}
                confirmText={{ keyId: 'COMMON.DELETE' }}
                confirmTitle={{ keyId: 'COMMON.ARE_YOU_SURE' }}
                data-cy="remove-user-button"
                size="xs"
                theme="danger"
                onClick={() => onRemoveUser(user)}
            >
                <i className="fas fa-trash-alt icon-space" />
                <span>
                    <Translate keyId="COMMON.REMOVE" />
                </span>
            </ConfirmButton>
        )}
        getEditUrl={user => `/clients/${client.id}/locations/${location.id}/users/${user.id}/edit`}
        users={users}
    />
);
ClientUsersTable.displayName = 'ClientUsersTable';

export default withAngularIntegration(ClientUsersTable, 'clientUsersTable', {
    client: OneWayBinding,
    location: OneWayBinding,
    onRemoveUser: ExpressionBinding,
    users: OneWayBinding,
});
