import * as Sentry from '@sentry/browser';
import { MvIdentity } from '../account/mvIdentity';
import { tryExtractMessage } from './exceptions';

export class SentryService {
    public static SID = 'SentryService';

    public static readonly $inject: string[] = [MvIdentity.SID];

    public constructor(private readonly mvIdentity: MvIdentity) {
        this.setUserContext();
    }

    public setUserContext(): void {
        if (this.mvIdentity.isAuthenticated()) {
            const user = this.mvIdentity.currentUser!;
            Sentry.setUser({
                email: user.username,
                id: user.id.toString(),
                username: user.username,
            });
        } else {
            Sentry.setUser(null);
        }
    }

    public captureException(message: string, data: unknown): void {
        const isError = data instanceof Error;
        const extractedMessage = [message, tryExtractMessage(data)].filter(x => x.length).join(': ');
        Sentry.withScope(scope => {
            scope.setExtras({ extractedMessage });
            Sentry.captureException(isError ? data : new Error(message));
        });
    }

    public captureValidationError(message: string): void {
        Sentry.withScope(scope => {
            scope.setTag('validation.error', 'true');
            Sentry.captureMessage(message, Sentry.Severity.Info);
        });
    }
}

angular.module('app').service(SentryService.SID, SentryService);
