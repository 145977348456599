/// <reference path="../../../../../typings/browser.d.ts" />
import { GetAllSKUsForSKUGroupDto, PrintProviderSKUGroup, PrintProviderSKUGroupId } from '@deltasierra/shared';
import { $routeParamsSID } from '../../../common/angularData';
import { TabNavEntry } from '../../../common/dsTabNav';
import { InteractionUtils } from '../../../common/interactionUtils';
import { I18nService } from '../../../i18n/i18nService';
import {
    AgencySKUGroupExpectedRouteParams,
    AgencySKUGroupIds,
    extractIdsFromRouteParams,
    getAgencySKUGroupTabNavEntries,
} from '../common';
import { SKUManagementApiClient } from '../skuManagementApiClient';

export class SKUGroupSKUSController {
    public static SID = 'SKUGroupSKUSController';

    fetchSKUGroupAndSKUs = this.interactionUtils.createFuture(
        'Get SKU Group',
        (context: { printProviderSKUGroupId: PrintProviderSKUGroupId }) =>
            this.skuManagementApiClient.getSKUGroup(context.printProviderSKUGroupId).then(skuGroup => {
                this.skuGroup = skuGroup;
                return this.skuManagementApiClient.getSKUGroupSKUs(context.printProviderSKUGroupId).then(skus => {
                    this.skus = skus;
                });
            }),
    );

    skuGroup: PrintProviderSKUGroup | null = null;

    skus: GetAllSKUsForSKUGroupDto[] = [];

    skuToEdit?: GetAllSKUsForSKUGroupDto;

    tabNavEntries: ReadonlyArray<TabNavEntry> = [];

    tabNavParams: AgencySKUGroupIds;

    static readonly $inject: string[] = [
        $routeParamsSID,
        I18nService.SID,
        InteractionUtils.SID,
        SKUManagementApiClient.SID,
    ];

    constructor(
        $routeParams: AgencySKUGroupExpectedRouteParams,
        private readonly i18n: I18nService,
        private readonly interactionUtils: InteractionUtils,
        private readonly skuManagementApiClient: SKUManagementApiClient,
    ) {
        const ids = extractIdsFromRouteParams($routeParams);
        if (ids.skuGroupId) {
            void this.fetchSKUGroupAndSKUs.run({ printProviderSKUGroupId: ids.skuGroupId });
        }
        this.tabNavParams = ids;
        this.tabNavEntries = getAgencySKUGroupTabNavEntries(this.i18n);
    }

    onClickAddSKU() {
        this.skuToEdit = undefined;
    }

    onClickEditSKU(sku: GetAllSKUsForSKUGroupDto) {
        this.skuToEdit = sku;
    }
}

angular.module('app').controller(SKUGroupSKUSController.SID, SKUGroupSKUSController);
