import { gql, useMutation, useQuery } from '@apollo/client';
import {
    DSButton,
    DSDialog,
    DSWell,
    DSWellHeader,
    DSWellHeaderRightContent,
    DSWellHeaderTitle,
    Loading,
    Translate,
} from '@deltasierra/components';
import { useToggle } from '@deltasierra/react-hooks';
import { t } from '@deltasierra/shared';
import AddIcon from '@material-ui/icons/Add';
import * as React from 'react';
import { useAngularServiceContext } from '../../../common/componentUtils/angularServiceContexts';
import { withAngularIntegration } from '../../../common/componentUtils/reactComponentRegistration';
import {
    HashtagSetDialogContent,
    HashtagSetFormValues,
} from '../../../domain/hashtagSets/components/HashtagSetDialogContent';
import { HashtagSetsTable } from '../../../domain/hashtagSets/components/HashtagSetsTable';
import { relayConnectionToArray } from '../../../graphql/utils';
import {
    CreateLocationHashtagSetForLocationHashtagPage,
    CreateLocationHashtagSetForLocationHashtagPageVariables,
} from './__graphqlTypes/CreateLocationHashtagSetForLocationHashtagPage';
import {
    CurrentLocationForLocationHashtagSets,
    CurrentLocationForLocationHashtagSetsVariables,
} from './__graphqlTypes/CurrentLocationForLocationHashtagSets';

const locationQuery = gql`
    query CurrentLocationForLocationHashtagSets($locationId: ID!, $hasLocation: Boolean! = false) {
        currentLocationId @client @export(as: "locationId")
        hasCurrentLocation @client @export(as: "hasLocation")
        location(id: $locationId) @include(if: $hasLocation) {
            id
            hashtagSetsConnection {
                edges {
                    node {
                        id
                        ...HashtagSetFragmentForTableRow
                    }
                }
            }
        }
    }
    ${HashtagSetsTable.Row.fragments.hashtagSet}
`;

const createLocationHashtagSetMutation = gql`
    mutation CreateLocationHashtagSetForLocationHashtagPage($input: CreateLocationHashtagSetInput!) {
        createLocationHashtagSet(input: $input) {
            ... on CreateLocationHashtagSetError {
                code
                message
            }
            ... on CreateLocationHashtagSetSuccess {
                hashtagSet {
                    id
                }
            }
        }
    }
`;

export function LocationHashtagSets(): JSX.Element {
    const notifier = useAngularServiceContext('mvNotifier');
    const [isCreateDialogOpen, toggleIsCreateDialogOpen] = useToggle();
    const { data } = useQuery<CurrentLocationForLocationHashtagSets, CurrentLocationForLocationHashtagSetsVariables>(
        locationQuery,
    );
    const hashtagSets = relayConnectionToArray(data?.location?.hashtagSetsConnection);
    const [create] = useMutation<
        CreateLocationHashtagSetForLocationHashtagPage,
        CreateLocationHashtagSetForLocationHashtagPageVariables
    >(createLocationHashtagSetMutation, {
        onCompleted: result => {
            if (result.createLocationHashtagSet.__typename === 'CreateLocationHashtagSetError') {
                notifier.unexpectedErrorWithData(
                    t('AGENCY.HASHTAG_SETS.ACTIONS.MESSAGES.COULD_NOT_CREATE_SET'),
                    result.createLocationHashtagSet.message,
                );
            } else {
                notifier.success(t('AGENCY.HASHTAG_SETS.ACTIONS.MESSAGES.CREATED_SET'));
            }
        },
        refetchQueries: ['CurrentLocationForLocationHashtagSets'],
    });
    const handleCreateSubmit = async (values: HashtagSetFormValues) =>
        create({
            variables: {
                input: {
                    description: values.description,
                    hashtags: values.hashtags,
                    locationId: data!.location!.id,
                    title: values.title,
                },
            },
        });

    return (
        <>
            <DSWell>
                <DSWellHeader>
                    <DSWellHeaderTitle>
                        <Translate keyId="AGENCY.HASHTAG_SETS.HASHTAG_SETS" />
                    </DSWellHeaderTitle>
                    <DSWellHeaderRightContent>
                        <DSButton
                            color="primary"
                            data-cy="manage-hashtag-add-set-button"
                            startIcon={<AddIcon />}
                            onClick={() => toggleIsCreateDialogOpen('toggleOn')}
                        >
                            <Translate keyId="AGENCY.HASHTAG_SETS.ACTIONS.ADD_HASHTAG_SET" />
                        </DSButton>
                    </DSWellHeaderRightContent>
                </DSWellHeader>
                <br />
                {!data?.location?.id && <Loading />}
                {data?.location?.id && (
                    <HashtagSetsTable>
                        {hashtagSets.map(set => (
                            <HashtagSetsTable.Row hashtagSet={set} key={set.id} />
                        ))}
                    </HashtagSetsTable>
                )}
            </DSWell>
            <DSDialog open={isCreateDialogOpen}>
                {isCreateDialogOpen && (
                    <HashtagSetDialogContent
                        headerText={t('AGENCY.HASHTAG_SETS.ACTIONS.ADD_HASHTAG_SET')}
                        submitButtonText={t('COMMON.CREATE')}
                        onCancel={() => toggleIsCreateDialogOpen('toggleOff')}
                        onSubmit={value => {
                            void handleCreateSubmit(value);
                            toggleIsCreateDialogOpen('toggleOff');
                        }}
                    />
                )}
            </DSDialog>
        </>
    );
}
LocationHashtagSets.displayName = 'LocationHashtagSets';

export const LocationHashtagSetsWithAngular = withAngularIntegration(LocationHashtagSets, 'locationHashtagSets', {});
