import * as React from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { omit } from '@deltasierra/shared';
import { useAngularServiceContext } from './componentUtils/angularServiceContexts';

/**
 * The one-stop shop React hook for everything routing related. Use this hook rather than accessing AngularJS router or React Router
 * directly. The integration between AngularJS router and React Router is pretty delicate and requires a few special considerations (such as
 * preventing full page refreshing).
 *
 * @returns Everything you need for interacting with the URL and router
 * @see https://usehooks.com/useRouter
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useRouter<
    // eslint-disable-next-line @typescript-eslint/ban-types
    TParams extends Record<string, string | undefined> = {},
    // eslint-disable-next-line @typescript-eslint/ban-types
    TQuery extends Record<string, string | undefined> = {},
>() {
    /*
     * We use the AngularJS router rather than React Router because AngularJS is still managing the URL state and it's pretty
     * challenging to rip it out and replace it with React Router. I suspect replacing AngularJS routing will be the last item on the
     * React journey.
     */
    const $location = useAngularServiceContext('$location');
    const $timeout = useAngularServiceContext('$timeout');
    const $rootScope = useAngularServiceContext('$rootScope');
    const params = useParams<Partial<TParams>>();
    const location = useLocation();
    const navigate = useNavigate();
    const [url, setUrl] = React.useState<string>($location.url());

    React.useEffect(() => {
        const unsub = $rootScope.$on('$routeUpdate', event => {
            setUrl($location.url());
        });
        return () => unsub();
    }, [setUrl, $rootScope, $location]);

    const query = React.useMemo(
        () => ({
            get: <TKey extends keyof TQuery>(key: TKey): TQuery[TKey] => $location.search()[key],
            remove: (key: keyof TQuery) => {
                void $timeout(() => {
                    $location.search(omit([key], $location.search()));
                });
            },
            set: <TKey extends keyof TQuery>(key: TKey, value: TQuery[TKey]) => {
                void $timeout(() => {
                    $location.search({ ...$location.search(), [key]: value });
                });
            },
            setAll: (newParams: URLSearchParams) => {
                void $timeout(() => {
                    $location.search(newParams.toString());
                });
            },
        }),
        [$location, $timeout],
    );

    return React.useMemo(
        () => ({
            navigate,
            location,
            params,
            push(path: string): void {
                $timeout(() => $location.url(path));
            },
            query,
            replace(path: string): void {
                $location.url(path);
                $location.replace();
            },
            url,
        }),
        [$timeout, $location, params, location, navigate, url, query],
    );
}
