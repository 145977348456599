import { Section } from '@deltasierra/shared';
import {
    actualComponent,
    ExpressionBinding,
    ExpressionCallback,
    OneWayBinding,
} from '../../../common/angularData';

export class EmailSectionSimplePickerCtrl {
    public static readonly SID = 'EmailSectionSimplePickerCtrl';

    onSelectSection!: ExpressionCallback<{ section: Section | null }>;

    selectedSection?: Section;

    sections!: Section[];

    public static readonly $inject: string[] = [
    ];

    constructor(
    ) {
        // Do nothing
    }

    getSelectedSectionIndex(): number {
        if (this.selectedSection) {
            for (let i = 0; i < this.sections.length; i++) {
                if (this.sections[i] === this.selectedSection) {
                    return i;
                }
            }
        }
        return -1;
    }

    onClickPrevious() {
        const index = this.getSelectedSectionIndex();
        const section = index > 0
            ? this.sections[index - 1]
            : undefined;
        return this.onSelectSection({
            section: section || null,
        });
    }

    onClickInstructions() {
        this.onSelectSection({
            section: null,
        });
    }

    onClickSection(section: Section) {
        this.onSelectSection({ section });
    }

    onClickNext() {
        const index = this.getSelectedSectionIndex();
        if (index < this.sections.length - 1) {
            return this.onSelectSection({
                section: this.sections[index + 1],
            });
        }
    }
}

export const emailSectionSimplePickerSID = 'emailSectionSimplePicker';

export const emailSectionSimplePickerConfig = actualComponent(
    EmailSectionSimplePickerCtrl,
    '/partials/contentBuilder/email/emailSectionSimplePicker/emailSectionSimplePicker',
    {
        onSelectSection: ExpressionBinding,
        selectedSection: OneWayBinding,
        sections: OneWayBinding,
    },
);

angular.module('app').component(emailSectionSimplePickerSID, emailSectionSimplePickerConfig);
