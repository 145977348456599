import * as React from 'react';
import { removeAll, assertNever, Upload } from '@deltasierra/shared';


export type CompletedFileUpload = { file: File; upload: Upload };

export type UploadAssetFilesState = {
    completed: CompletedFileUpload[];
    failed: File[];
    isModalShown: boolean;
    processing: File[];
    tags: string[];
};
export type UploadAssetFilesActions =
    | { payload: CompletedFileUpload[]; type: 'COMPLETED' }
    | { payload: File; type: 'REMOVE' }
    | { payload: File[]; type: 'ADD' }
    | { payload: File[]; type: 'FAILED' }
    | { payload: Pick<Upload, 'id' | 'thumb256x256url'>; type: 'UPDATE_FILE_THUMBNAIL' }
    | { payload: string; type: 'ADD_TAG' }
    | { payload: string; type: 'REMOVE_TAG' }
    | { type: 'RESET' }
    | { type: 'SHOW_MODAL' };

const initialFilesReducerState: UploadAssetFilesState = {
    completed: [],
    failed: [],
    isModalShown: false,
    processing: [],
    tags: [],
};

const reducer: React.Reducer<UploadAssetFilesState, UploadAssetFilesActions> = (state, action) => {
    if (action.type === 'ADD') {
        return { ...state, processing: [...state.processing, ...action.payload] };
    } else if (action.type === 'COMPLETED') {
        return {
            ...state,
            completed: [...state.completed, ...action.payload],
            processing: removeAll(
                action.payload.map(({ file }) => file),
                state.processing,
            ),
        };
    } else if (action.type === 'FAILED') {
        return {
            ...state,
            failed: [...state.failed, ...action.payload],
            processing: removeAll(action.payload, state.processing),
        };
    } else if (action.type === 'REMOVE') {
        return {
            ...state,
            completed: state.completed.filter(item => item.file !== action.payload),
            failed: removeAll([action.payload], state.failed),
            processing: removeAll([action.payload], state.processing),
        };
    } else if (action.type === 'ADD_TAG') {
        return state.tags.indexOf(action.payload) < 0 ? { ...state, tags: [...state.tags, action.payload] } : state;
    } else if (action.type === 'REMOVE_TAG') {
        return { ...state, tags: state.tags.filter(tag => tag !== action.payload) };
    } else if (action.type === 'SHOW_MODAL') {
        return { ...state, isModalShown: true };
    } else if (action.type === 'RESET') {
        return initialFilesReducerState;
    } else if (action.type === 'UPDATE_FILE_THUMBNAIL') {
        return {
            ...state,
            completed: state.completed.map(item =>
                item.upload.id === action.payload.id
                    ? { ...item, upload: { ...item.upload, thumb256x256url: action.payload.thumb256x256url } }
                    : item,
            ),
        };
    } else {
        return assertNever(action);
    }
};

export const useUploadAssetsState = (): [UploadAssetFilesState, React.Dispatch<UploadAssetFilesActions>] =>
    React.useReducer(reducer, initialFilesReducerState);
