/* eslint-disable max-lines */
/// <reference path="../../../typings/browser.d.ts" />
import {
    BuilderDocument,
    BuilderDocumentFormat,
    BuilderDocumentRenderer,
    BuilderFontCustomDto,
    BuilderTemplateFormat,
    CanvasRenderContext,
    convertUnits,
    DomTextMeasurer,
    Fps,
    getBleedAmount,
    getBoundarySize,
    HandleType,
    IHandle,
    IPoint,
    IRectangle,
    IRenderContext,
    IRenderDocumentForScreenOptions,
    ISize,
    isPageRotated,
    isPrintDocument,
    Layer,
    LayerGroup,
    offsetPoints,
    OverlaySupportedLayer,
    PlaceholderOverlayRenderer,
    PrintUnits,
    RectanglePoint,
    swapWidthAndHeight,
    TextSubstitutionValues,
    translatePoint,
    Untyped,
} from '@deltasierra/shared';
import { ITimeoutService } from 'angular';
import { $timeoutSID } from '../common/angularData';
import { MvNotifier } from '../common/mvNotifier';
import { ProgressModalService } from '../common/progressModalService';
import { I18nService } from '../i18n';
import { TextSubstitutionService } from './common/textSubstitutionService';
import { ContentBuilderUiContext } from './contentBuilder';
import { getFormatByName, ImageFormat } from './imageFormats';
import { ImageCache } from './imageLoaderService';
import { FileFormatChoice } from './publish/mvContentBuilderFileFormatCtrl';
import { VideoCache } from './videoLoaderService';

export interface ExportOptions {
    width?: number;
    height?: number;
    imageFormat?: ImageFormat | null;
    includeBleed?: boolean;
    transparentBackground?: boolean;
    maxFileSize?: number;
}

export class ExportData {
    public constructor(
        public dataUrl: string | null,
        public imageFormat: ImageFormat,
        public requestedImageFormat: ImageFormat,
        public requiresConversion: boolean,
    ) {}
}

export interface RenderDocumentOptions {
    dimensions?: {
        width: number;
        height: number;
    };
    canvas?: HTMLCanvasElement;
    clipToTrimBox?: boolean;
    bleedScale: number;
    pageIndex?: number;
    multiImageIndex?: number;
    transparentBackground?: boolean;
    replaceTransparentWithWhite?: boolean;
}

export interface RenderOptions {
    builderDocument: BuilderDocument;
    selectedLayerGroup: LayerGroup;
    advancedMode: boolean;
    multiImageIndex: number;
    pageIndex?: number;
    formats: BuilderTemplateFormat[];
    highlightLayers: Layer[];
    customFonts: BuilderFontCustomDto[];
}

export class ContentBuilderRenderer {
    public workCanvas: HTMLCanvasElement;

    public backgroundCanvas: HTMLCanvasElement;

    private readonly textMeasurer: DomTextMeasurer;

    private readonly substituteText: (text: string) => string;

    private readonly documentRenderer = new BuilderDocumentRenderer();

    private readonly placeholderOverlayRenderer = new PlaceholderOverlayRenderer({
        imageLayer: this.injected.i18nService.text.build.overlays.imageLayer(),
        mapLayer: this.injected.i18nService.text.build.overlays.mapLayer(),
        videoLayer: this.injected.i18nService.text.build.overlays.videoLayer(),
    });

    private readonly fps = new Fps(60);

    // eslint-disable-next-line max-params
    public constructor(
        private injected: ContentBuilderRendererFactory,
        private canvasId: string,
        private imageCache: ImageCache,
        private videoCache: VideoCache,
        private uiContext: ContentBuilderUiContext,
        private textSubstitutionValues: TextSubstitutionValues,
    ) {
        this.workCanvas = this.createWorkCanvas();
        this.backgroundCanvas = this.createBackgroundCanvas();
        this.textMeasurer = new DomTextMeasurer(
            () => document.createElement('div'),
            element => document.body.appendChild(element),
        );

        this.substituteText = text =>
            this.injected.textSubstitutionService.substitute(this.textSubstitutionValues, text);
    }

    public getDocumentAndBleedSize(
        builderDocument: BuilderDocument,
        formats: BuilderTemplateFormat[],
        pageIndex: number,
        units = PrintUnits.pixels,
    ): { height: number; width: number } {
        const trimSize = convertUnits(builderDocument.dimensions, builderDocument.dimensions.unit as any, units);
        const bleedAmount = getBleedAmount(builderDocument, units);
        const bleedScale = this.getDesignerBleedScale(builderDocument, formats);

        let result = {
            height: trimSize.height + bleedAmount * bleedScale * 2,
            width: trimSize.width + bleedAmount * bleedScale * 2,
        };

        if (isPrintDocument(builderDocument) && isPageRotated(builderDocument, pageIndex)) {
            result = swapWidthAndHeight(result);
        }

        return result;
    }

    public destroy(): void {
        this.textMeasurer.delete();
    }

    public getOverlayRectForLayerGroup(layerGroup: LayerGroup): IRectangle | null {
        const overlayRect = layerGroup.getContainingRectangle();
        if (!overlayRect) {
            return null;
        }

        overlayRect.width *= this.uiContext.zoom;
        overlayRect.height *= this.uiContext.zoom;
        return overlayRect;
    }

    public getExteriorPointsForLayerGroup(layerGroup: LayerGroup): IHandle[] {
        // We don't currently support any of the manipulation handles for groups of layers
        const overlayRect = this.getOverlayRectForLayerGroup(layerGroup);
        if (overlayRect) {
            return this.getExteriorPointsForRectangle(
                overlayRect,
                layerGroup.isMultiple() || layerGroup.selectedLayer!.lockAspectRatio,
            );
        } else {
            return [];
        }
    }

    public getExteriorPointsForRectangle(rect: IRectangle, lockAspectRatio: boolean): IHandle[] {
        const corners = this.getCornerPointsFromCenter(rect);
        const sides = !lockAspectRatio ? this.getSidePointsFromCenter(rect) : [];

        const rotationHandle = [
            {
                positionName: RectanglePoint.top,
                type: HandleType.rotate,
                x: 0,
                y: -(rect.height / 2) - Math.max(40 * this.uiContext.zoom, 15),
            },
        ];

        return sides.concat(corners).concat(rotationHandle);
    }

    public getExteriorPointsForLayer(layer: Layer): IHandle[] {
        const overlayRect = this.getLayerBoundary(layer, this.uiContext.zoom);
        // We assume 0,0 is the center of the layer
        const corners = this.getCornerPointsFromCenter(overlayRect);
        const sides = !layer.lockAspectRatio ? this.getSidePointsFromCenter(overlayRect) : [];

        const rotationHandle = [
            {
                positionName: RectanglePoint.top,
                type: HandleType.rotate,
                x: 0,
                y: -(overlayRect.height / 2) - Math.max(40 * this.uiContext.zoom, 15),
            },
        ];

        return sides.concat(corners).concat(rotationHandle);
    }

    // Returns the size of the layer including transformations
    public getLayerBoundary(layer: Layer, zoom = 1): ISize {
        // Get boundary (layer skew values are backwards (╯°□°）╯︵ ┻━┻)
        const boundary = getBoundarySize(
            { height: layer.height, width: layer.width },
            { skewX: layer.skewY, skewY: layer.skewX, zoom },
        );

        return boundary;
    }

    public render(options: RenderOptions): void {
        const canvas = document.getElementById(this.canvasId) as HTMLCanvasElement;

        if (canvas) {
            const renderContext = new CanvasRenderContext(canvas, options.customFonts, {
                textMeasurer: this.textMeasurer,
                useOldTextRenderingLineHeightFix: options.builderDocument.useOldTextRenderingLineHeightFix,
                useTextRenderingLineHeightFix: options.builderDocument.useTextRenderingLineHeightFix,
            });

            const bleedScale = this.getDesignerBleedScale(options.builderDocument, options.formats);

            this.renderBackground(renderContext);
            this.renderDocument(options.builderDocument, options.customFonts, {
                bleedScale,
                clipToTrimBox: !options.advancedMode,
                multiImageIndex: options.multiImageIndex,
                pageIndex: options.pageIndex,
            });

            if (options.advancedMode) {
                this.renderUi(options.builderDocument, bleedScale, options.pageIndex);
            }

            this.blitToCanvas(options.builderDocument, canvas);

            if (options.advancedMode) {
                this.renderSelectionBorders(options.builderDocument, options.selectedLayerGroup, canvas);
                this.renderManipulationHandles(options.builderDocument, options.selectedLayerGroup, canvas);
                this.renderLayerHighlights(options.builderDocument, options.highlightLayers, canvas);
            }

            this.fps.tick();
            if ((window as Untyped).show_fps) {
                this.renderFps(renderContext);
            }
        }
    }

    public renderSimpleToCanvasId(
        canvasId: string,
        builderDocument: BuilderDocument,
        customFonts: BuilderFontCustomDto[],
    ): void {
        const targetCanvas = document.getElementById(canvasId) as HTMLCanvasElement;
        if (!targetCanvas) {
            return;
        }
        // Render the document onto the work canvas
        this.renderDocument(builderDocument, customFonts);

        // Render background onto target canvas followed by the work canvas contents
        const targetContext = new CanvasRenderContext(targetCanvas, customFonts, {
            textMeasurer: this.textMeasurer,
            useOldTextRenderingLineHeightFix: builderDocument.useOldTextRenderingLineHeightFix,
            useTextRenderingLineHeightFix: builderDocument.useTextRenderingLineHeightFix,
        });
        this.renderBackground(targetContext);
        this.blitToCanvas(builderDocument, targetCanvas);
    }

    public async exportAsImage(
        builderDocument: BuilderDocument,
        customFonts: BuilderFontCustomDto[],
        exportOptions?: ExportOptions,
    ): Promise<ExportData> {
        const canvas = document.createElement('canvas');
        const renderOptions: RenderDocumentOptions = {
            bleedScale: 1,
            canvas,
            replaceTransparentWithWhite: exportOptions && exportOptions.imageFormat?.fileExtension === 'jpg',
            transparentBackground:
                exportOptions && exportOptions.transparentBackground !== undefined
                    ? exportOptions.transparentBackground
                    : false,
        };
        if (exportOptions && exportOptions.width && exportOptions.height) {
            renderOptions.dimensions = {
                height: exportOptions.height,
                width: exportOptions.width,
            };
        }
        this.renderDocument(builderDocument, customFonts, renderOptions);
        const pngFormat = getFormatByName('PNG');
        const exportedData = new ExportData(
            null,
            pngFormat,
            pngFormat,
            false, // Implicit...
        );
        if (exportOptions && exportOptions.imageFormat) {
            exportedData.requestedImageFormat = exportOptions.imageFormat;
            if (exportOptions.imageFormat.nativeSupport) {
                exportedData.imageFormat = angular.copy(exportOptions.imageFormat);
            } else {
                exportedData.requiresConversion = true;
            }
        }
        const quality = await this.getExportQuality(canvas, exportedData.imageFormat, exportOptions);
        exportedData.dataUrl = canvas.toDataURL(exportedData.imageFormat.mimeType, quality);
        return exportedData;
    }

    /**
     * Returns a new canvas element.
     *
     * @param builderDocument - BuilderDocument
     * @param customFonts - BuilderFontCustomDto[]
     * @param exportOptions - FileFormatChoice
     * @returns HTMLCanvasElement
     */
    public exportAsCanvas(
        builderDocument: BuilderDocument,
        customFonts: BuilderFontCustomDto[],
        exportOptions?: FileFormatChoice,
    ): HTMLCanvasElement {
        const canvas = document.createElement('canvas');
        const renderOptions = {
            bleedScale: 1,
            canvas,
            dimensions: undefined as Untyped,
        };
        if (exportOptions && exportOptions.width && exportOptions.height) {
            renderOptions.dimensions = {
                height: exportOptions.height,
                width: exportOptions.width,
            };
        }
        this.renderDocument(builderDocument, customFonts, renderOptions);
        return canvas;
    }

    /**
     * Retrieves the image export quality
     *
     * @param canvas - HTMLCanvasElement
     * @param imageFormat - ImageFormat
     * @param exportOptions - ExportOptions
     * @returns Image export quality | undefined
     */
    private async getExportQuality(
        canvas: HTMLCanvasElement,
        imageFormat: ImageFormat,
        exportOptions?: ExportOptions,
    ): Promise<number | undefined> {
        if (imageFormat.hasFileSizeLimit && exportOptions && exportOptions.maxFileSize) {
            return this.getExportQualityWithFileSizeLimit(canvas, imageFormat, exportOptions.maxFileSize);
        } else if (imageFormat.mimeType === 'image/jpeg') {
            // eslint-disable-next-line no-promise-executor-return
            return new Promise(resolve => resolve(1.0));
        } else {
            // eslint-disable-next-line no-promise-executor-return
            return new Promise(resolve => resolve(undefined));
        }
    }

    /**
     * Calculates image file size in kB.
     *
     * @param canvas - HTMLCanvasElement
     * @param mimeType - mimeType
     * @param quality - image quality
     * @returns image file size in kB
     */
    private calculateImageFileSize(canvas: HTMLCanvasElement, mimeType: string, quality: number): number {
        const dataUrl = canvas.toDataURL(mimeType, quality);
        const head = `data:${mimeType};base64,`;
        return Math.round((dataUrl.length - head.length) * (3 / 4)) / 1024;
    }

    /**
     * Retrieves the image export quality given a max file size limit.
     * If file size is above the max at standard quality (1.0), it will attempt to find the best quality while remaining below the max size.
     * Will return the standard quality and alert the user if it is not possible given the number of iterations.
     *
     * @param canvas - HTMLCanvasElement
     * @param imageFormat - ImageFormat
     * @param maxFileSize - Desired max image file size
     * @returns The quality value for the image while remaining below the max file size if possible, or else returns the standard quality.
     */
    private async getExportQualityWithFileSizeLimit(
        canvas: HTMLCanvasElement,
        imageFormat: ImageFormat,
        maxFileSize: number,
    ): Promise<number> {
        const reduceQualitySteps = 10;
        const standardQuality = 1.0;
        const fileSize = this.calculateImageFileSize(canvas, imageFormat.mimeType, standardQuality);
        if (fileSize < maxFileSize) {
            return standardQuality;
        } else {
            // Show progress modal while calculaing reduced quality
            const progressModal = this.injected.progressModalService.showModal({
                max: reduceQualitySteps,
                status: '',
                title: this.injected.i18nService.text.build.optimizingImage(),
            });
            const callback = (value: number, max: number) => progressModal.update({ max, value });

            const optimalQuality = await this.calculateReducedExportQuality(
                canvas,
                imageFormat,
                maxFileSize,
                reduceQualitySteps,
                callback,
            );
            progressModal.close();
            if (optimalQuality) {
                return optimalQuality;
            } else {
                this.injected.mvNotifier.expectedError(
                    this.injected.i18nService.text.build.templateCouldNotBeExportedUnderTheFileSizeLimit(),
                );
                return standardQuality;
            }
        }
    }

    /**
     * Recursive binary search to find the highest quality value for an image given a max file size constraint.
     * If the constaint cannot be met, this function will return undefined.
     *
     * @param canvas - HTMLCanvasElement
     * @param imageFormat - ImageFormat
     * @param maxFileSize - Desired max image file size
     * @param steps - The number of iterations when searching for the quality
     * @param updateModalCallback - function to update progress modal
     * @returns A quality value that meets the max file size constaint | undefined
     */
    private async calculateReducedExportQuality(
        canvas: HTMLCanvasElement,
        imageFormat: ImageFormat,
        maxFileSize: number,
        steps: number,
        updateModalCallback: (value: number, max: number) => void,
    ): Promise<number | undefined> {
        const helper = (
            lowerBound: number,
            upperBound: number,
            remainingSteps: number,
            optimalQuality: number | null,
            callback: (arg: number | undefined) => void,
        ): void => {
            const currentQuality = lowerBound + (upperBound - lowerBound) / 2;
            const fileSize = this.calculateImageFileSize(canvas, imageFormat.mimeType, currentQuality);
            updateModalCallback(steps - remainingSteps, steps);

            if (remainingSteps === 0) {
                callback(optimalQuality ? optimalQuality : undefined);
            } else if (fileSize < maxFileSize) {
                // SetTimeout breaks up the workload for other browser processes to run.
                // Increase quality
                this.injected.$setTimeout(
                    () => helper(currentQuality, upperBound, remainingSteps - 1, currentQuality, callback),
                    200,
                );
            } else {
                // Decrease quality
                this.injected.$setTimeout(
                    () => helper(lowerBound, currentQuality, remainingSteps - 1, optimalQuality, callback),
                    200,
                );
            }
        };
        return new Promise(resolve => {
            helper(0, 1, steps, null, resolve);
        });
    }

    private createWorkCanvas() {
        return document.createElement('canvas');
    }

    private createBackgroundCanvas() {
        const canvas = document.createElement('canvas');
        const targetCanvas = document.getElementById(this.canvasId) as HTMLCanvasElement;
        canvas.width = targetCanvas.width;
        canvas.height = targetCanvas.height;
        this.renderNonDocumentArea(canvas);
        return canvas;
    }

    private renderNonDocumentArea(canvas: HTMLCanvasElement) {
        const ctx = canvas.getContext('2d');
        if (ctx) {
            const squareSize = 10;
            const squareColour = ['#FFF', '#DDD'];
            for (let y = 0; y * squareSize < canvas.height; y++) {
                for (let x = 0; x * squareSize < canvas.width; x++) {
                    const colourIndex = (x + y) % 2;
                    ctx.fillStyle = squareColour[colourIndex];
                    ctx.fillRect(x * squareSize, y * squareSize, squareSize, squareSize);
                }
            }
        }
    }

    private applyZoom(amount: number): number {
        return amount * (1 / this.uiContext.zoom);
    }

    private getSmallestFormatDimensions(formats: BuilderTemplateFormat[]): ISize | null {
        if (!formats || formats.length === 0) {
            return null;
        }
        const smallest = formats.reduce((reduceResult, current) => {
            if (!reduceResult || current.width * current.height < reduceResult.width * reduceResult.height) {
                return current;
            } else {
                return reduceResult;
            }
        }, null as BuilderTemplateFormat | null);

        if (!smallest) {
            return null;
        }

        const result = {
            height: smallest.height,
            width: smallest.width,
        };

        return result;
    }

    /**
     * Given the builder document and supported formats (sizes), this method will return the scale
     * needed to apply to bleed when rendering in designer so designers can design templates with content
     * that covers bleed for the smallest supported size.
     *
     * More Info:
     * Content will shrink when exporting to a size smaller than what the template was designed in,
     * but bleed will remain the same fixed size across all exported template sizes. This means
     * content can shrink to the point where it's not fully covering the bleed anymore. So we need
     * to compensate for this when rendering the template in the designer by scaling the bleed up
     *
     * @param builderDocument -BuilderDoctument
     * @param formats - BuilderTemplateFormat[]
     * @returns number
     */
    private getDesignerBleedScale(builderDocument: BuilderDocument, formats: BuilderTemplateFormat[]): number {
        const documentSize = convertUnits(
            builderDocument.dimensions,
            builderDocument.dimensions.unit as any,
            PrintUnits.pixels,
        );

        const smallestFormat = this.getSmallestFormatDimensions(formats);

        if (!smallestFormat) {
            return 1;
        }

        // Assumes that formats will always be the same aspect ratio (within acceptable tollerence)
        // That means we can work out the scale from either the width or the height, but let's use whichever
        // Dimension is longest, for a more accurate result.
        const scale =
            documentSize.width > documentSize.height
                ? documentSize.width / smallestFormat.width
                : documentSize.height / smallestFormat.height;

        return scale;
    }

    private renderPlaceholderOverlay(ctx: IRenderContext, layer: OverlaySupportedLayer) {
        if (!this.uiContext.showPlaceholderOverlays) {
            return;
        }

        ctx.runInTransaction(() => this.placeholderOverlayRenderer.render(ctx, layer));
    }

    private renderBackground(renderContext: IRenderContext) {
        const targetSize = renderContext.getSize();

        if (this.backgroundCanvas.width !== targetSize.width || this.backgroundCanvas.height !== targetSize.height) {
            this.backgroundCanvas = this.createBackgroundCanvas();
        }

        renderContext.image(this.backgroundCanvas, { x: 0, y: 0 }, targetSize);
    }

    private blitToCanvas(builderDocument: BuilderDocument, targetCanvas: HTMLCanvasElement) {
        const workCanvas = this.workCanvas;

        const context = targetCanvas.getContext('2d');
        if (context) {
            const contextCenter = {
                x: Math.floor(targetCanvas.width / 2),
                y: Math.floor(targetCanvas.height / 2),
            };

            let panHorizontal = this.uiContext.panHorizontal;
            if (workCanvas.width * this.uiContext.zoom <= targetCanvas.width) {
                panHorizontal = 1.0; // Center
            }
            let panVertical = this.uiContext.panVertical;
            if (workCanvas.height * this.uiContext.zoom <= targetCanvas.height) {
                panVertical = 1.0; // Center
            }

            const left = contextCenter.x - Math.floor(workCanvas.width / 2) * panHorizontal * this.uiContext.zoom;
            const top = contextCenter.y - Math.floor(workCanvas.height / 2) * panVertical * this.uiContext.zoom;

            const scaledWidth = workCanvas.width * this.uiContext.zoom;
            const scaledHeight = workCanvas.height * this.uiContext.zoom;

            context.drawImage(workCanvas, left, top, scaledWidth, scaledHeight);
        }
    }

    private renderDocument(
        builderDocument: BuilderDocument,
        customFonts: BuilderFontCustomDto[],
        options: RenderDocumentOptions = { bleedScale: 1 },
    ): void {
        const dimensions = options.dimensions || undefined;
        const sizes = this.documentRenderer.calculateSizes(builderDocument, {
            bleedScale: options.bleedScale,
            dimensions,
            pageIndex: options.pageIndex,
        });

        const canvas = (options && options.canvas) || this.workCanvas;
        canvas.width = sizes.canvasSize.width;
        canvas.height = sizes.canvasSize.height;
        const context = new CanvasRenderContext(canvas, customFonts, {
            textMeasurer: this.textMeasurer,
            useOldTextRenderingLineHeightFix: builderDocument.useOldTextRenderingLineHeightFix,
            useTextRenderingLineHeightFix: builderDocument.useTextRenderingLineHeightFix,
        });

        const rendererOptions: IRenderDocumentForScreenOptions = {
            asVideoOverlay: options.transparentBackground,
            clipToTrimBox: options.clipToTrimBox === undefined ? true : options.clipToTrimBox,
            imageLayerToImageSource: imageLayer => {
                const data = this.imageCache.get(imageLayer.location!);
                return data;
            },
            multiImageIndex: options.multiImageIndex,
            pageIndex: options.pageIndex,
            renderPlaceHolderOverlay: (ctx, layer) => this.renderPlaceholderOverlay(ctx, layer),
            replaceTransparentWithWhite: options.replaceTransparentWithWhite,
            substituteText: this.substituteText,
            videoLayerToVideoSource: videoLayer => {
                const data = this.videoCache.get(videoLayer.location!);
                return data;
            },
        };

        return this.documentRenderer.renderDocumentForScreen(
            context,
            builderDocument,
            sizes,
            rendererOptions,
            customFonts,
        );
    }

    private renderUi(builderDocument: BuilderDocument, bleedScale: number, pageIndex?: number) {
        const context = new CanvasRenderContext(this.workCanvas, [], {
            textMeasurer: this.textMeasurer,
            useOldTextRenderingLineHeightFix: builderDocument.useOldTextRenderingLineHeightFix,
            useTextRenderingLineHeightFix: builderDocument.useTextRenderingLineHeightFix,
        });

        if (builderDocument.format === BuilderDocumentFormat.print) {
            this.renderBleedAndTrimOverlays(context, builderDocument, bleedScale, pageIndex);
        }
    }

    private renderFps(context: CanvasRenderContext) {
        context.save();
        try {
            const fps = Math.round(this.fps.average()).toString();
            const text = `${fps} FPS`;

            const fontSize = 12;

            context.fontFamily('Arial').fontSize(fontSize, 'px');

            const width = context.textWidth(text);
            const widthPadding = 3;
            const height = 20;
            let x = 5;
            let y = 5;

            context
                .opacity(0.9)
                .fillColor('#000')
                .rect({ height, width: width + widthPadding * 2, x, y })
                .fill();

            x += widthPadding;
            y += height / 2 - fontSize / 2;

            context.fillColor('#fff');

            context.text({
                fill: true,
                position: { x, y },
                stroke: false,
                text,
            });
        } finally {
            context.restore();
        }
    }

    // eslint-disable-next-line max-statements
    private renderBleedAndTrimOverlays(
        context: IRenderContext,
        builderDocument: BuilderDocument,
        bleedScale: number,
        pageIndex?: number,
    ) {
        context.save();
        try {
            context.opacity(1);

            let builderDocumentSize = builderDocument.dimensions as ISize;

            if (isPrintDocument(builderDocument) && isPageRotated(builderDocument, pageIndex || 0)) {
                builderDocumentSize = swapWidthAndHeight(builderDocumentSize);
            }

            const bleedAmount = getBleedAmount(builderDocument) * bleedScale;
            const bleedColor = 'rgba(0, 0, 0, .3)';
            context.fillColor(bleedColor);

            // Overlay bars along Top, Left, Right and Bottom
            const bleedOverlays: IRectangle[] = [
                { height: bleedAmount, width: builderDocumentSize.width + bleedAmount * 2, x: 0, y: 0 },
                { height: builderDocumentSize.height, width: bleedAmount, x: 0, y: bleedAmount },
                {
                    height: builderDocumentSize.height,
                    width: bleedAmount,
                    x: builderDocumentSize.width + bleedAmount,
                    y: bleedAmount,
                },
                {
                    height: bleedAmount,
                    width: builderDocumentSize.width + bleedAmount * 2,
                    x: 0,
                    y: builderDocumentSize.height + bleedAmount,
                },
            ];

            for (const overlay of bleedOverlays) {
                context.beginPath().rect(overlay).fill();
            }

            const dashLength = this.applyZoom(3);
            const bleedLineWidth = this.applyZoom(1);
            const dashStyle = '#888';

            context.lineDash(dashLength, dashLength).lineWidth(bleedLineWidth);

            for (let dashOffset = 0; dashOffset <= dashLength; dashOffset += dashLength) {
                context.strokeColor(dashOffset ? bleedColor : dashStyle);
                context.lineDashOffset(dashOffset);

                context
                    .drawLine(
                        { x: 0, y: bleedAmount },
                        { x: builderDocumentSize.width + bleedAmount * 2, y: bleedAmount },
                    )
                    .drawLine(
                        { x: bleedAmount, y: 0 },
                        { x: bleedAmount, y: builderDocumentSize.height + bleedAmount * 2 },
                    )
                    .drawLine(
                        { x: bleedAmount + builderDocumentSize.width, y: 0 },
                        { x: bleedAmount + builderDocumentSize.width, y: builderDocumentSize.height + bleedAmount * 2 },
                    )
                    .drawLine(
                        { x: 0, y: bleedAmount + builderDocumentSize.height },
                        { x: builderDocumentSize.width + bleedAmount * 2, y: bleedAmount + builderDocumentSize.height },
                    );
            }
        } finally {
            context.restore();
        }
    }

    private renderManipulationHandles(
        builderDocument: BuilderDocument,
        layerGroup: LayerGroup,
        targetCanvas: HTMLCanvasElement,
    ) {
        if (layerGroup.isAny(x => x.visible)) {
            const rotation =
                !layerGroup.isMultiple(true) && layerGroup.selectedLayer ? layerGroup.selectedLayer.rotation : 0;
            const overlayRect = this.getOverlayRectForLayerGroup(layerGroup);
            if (overlayRect) {
                const context = new CanvasRenderContext(targetCanvas, [], {
                    textMeasurer: this.textMeasurer,
                    useOldTextRenderingLineHeightFix: builderDocument.useOldTextRenderingLineHeightFix,
                    useTextRenderingLineHeightFix: builderDocument.useTextRenderingLineHeightFix,
                });
                context.save();
                try {
                    this.translateContext(context, { x: overlayRect.x, y: overlayRect.y }, rotation);

                    const resizeHandle = { height: 8, width: 8 };
                    const rotationHandle = { radius: 5 };

                    // Relative to the center of the object
                    const drawableHandles = this.getExteriorPointsForRectangle(
                        overlayRect,
                        layerGroup.isMultiple() || !!layerGroup.selectedLayer!.lockAspectRatio,
                    );
                    const lineWidth = this.uiContext.action === 'transform' ? 1 : 2;
                    const lines = this.getConnectingLines(overlayRect, 0);

                    context.strokeColor('rgb(63, 149, 255)');
                    lines.forEach(points => {
                        context.lineWidth(lineWidth).drawLine(...points);
                    });

                    if (this.uiContext.action === 'transform') {
                        context.fillColor('rgb(63, 149, 255)');
                        context.strokeColor('rgb(255, 255, 255)');
                        drawableHandles.forEach(handle => {
                            if (handle.type === HandleType.resize) {
                                context
                                    .beginPath()
                                    .rect({
                                        height: resizeHandle.height,
                                        width: resizeHandle.width,
                                        x: handle.x - resizeHandle.width / 2,
                                        y: handle.y - resizeHandle.height / 2,
                                    })
                                    .fillAndStroke();
                            } else if (handle.type === HandleType.rotate && !layerGroup.isMultiple()) {
                                context
                                    .beginPath()
                                    .ellipse({
                                        endAngle: 2 * Math.PI,
                                        radiusX: rotationHandle.radius,
                                        radiusY: rotationHandle.radius,
                                        rotation: 0,
                                        startAngle: 0,
                                        x: handle.x,
                                        y: handle.y,
                                    })
                                    .fillAndStroke();
                            }
                        });
                    }
                } finally {
                    context.restore();
                }
            }
        }
    }

    private renderSelectionBorders(
        builderDocument: BuilderDocument,
        layerGroup: LayerGroup,
        targetCanvas: HTMLCanvasElement,
    ) {
        if (layerGroup.isMultiple(true)) {
            const context = new CanvasRenderContext(targetCanvas, [], {
                textMeasurer: this.textMeasurer,
                useOldTextRenderingLineHeightFix: builderDocument.useOldTextRenderingLineHeightFix,
                useTextRenderingLineHeightFix: builderDocument.useTextRenderingLineHeightFix,
            });
            context.save();
            try {
                for (const layer of layerGroup.visibleLayers) {
                    context.save();
                    const rotation = layer.rotation;
                    const overlaySize = this.getLayerBoundary(layer, this.uiContext.zoom);

                    this.translateContext(context, layer.position, rotation);
                    const lineWidth = 2;
                    const lines = this.getConnectingLines(overlaySize, 0);
                    context.setLineDash([5, 8]);
                    context.strokeColor('rgb(63, 149, 255)');
                    lines.forEach(points => {
                        context
                            .lineWidth(lineWidth)
                            .lineCap('round')
                            .drawLine(...points);
                    });
                    context.restore();
                }
            } finally {
                context.restore();
            }
        }
    }

    private translateContext(context: CanvasRenderContext, point: IPoint, rotation: number) {
        const fromSize = { height: this.workCanvas.height, width: this.workCanvas.width };
        const toSize = context.getSize();
        const toPosition = translatePoint(
            point,
            fromSize,
            {
                panX: this.uiContext.panHorizontal,
                panY: this.uiContext.panVertical,
                zoom: this.uiContext.zoom,
            },
            toSize,
        );

        // Move drawable context 0,0 to the center of transalted layer
        context.translate({ x: toPosition.x, y: toPosition.y });

        // Rotate
        const radians = (Math.PI / 180) * rotation;
        context.rotate(radians);
    }

    private getCornerPointsFromCenter(rect: ISize): IHandle[] {
        const corners = [
            {
                positionName: RectanglePoint.topLeft,
                type: HandleType.resize,
                x: -(rect.width / 2),
                y: -(rect.height / 2),
            }, // Top Left
            {
                positionName: RectanglePoint.topRight,
                type: HandleType.resize,
                x: rect.width / 2,
                y: -(rect.height / 2),
            }, // Top Right
            {
                positionName: RectanglePoint.bottomRight,
                type: HandleType.resize,
                x: rect.width / 2,
                y: rect.height / 2,
            }, // Bottom Right
            {
                positionName: RectanglePoint.bottomLeft,
                type: HandleType.resize,
                x: -(rect.width / 2),
                y: rect.height / 2,
            }, // Bottom Left
        ];

        return corners;
    }

    private getConnectingLines(rect: ISize, lineWidth: number): IPoint[][] {
        const topLeft = { x: -(rect.width / 2), y: -(rect.height / 2) };
        const topRight = { x: rect.width / 2, y: -(rect.height / 2) };
        const bottomLeft = { x: -(rect.width / 2), y: rect.height / 2 };
        const bottomRight = { x: rect.width / 2, y: rect.height / 2 };
        const lines = [
            offsetPoints({ x: 0, y: -lineWidth }, ...[topLeft, topRight]),
            offsetPoints({ x: lineWidth, y: 0 }, ...[topRight, bottomRight]),
            offsetPoints({ x: 0, y: lineWidth }, ...[bottomRight, bottomLeft]),
            offsetPoints({ x: -lineWidth, y: 0 }, ...[bottomLeft, topLeft]),
        ];

        return lines;
    }

    private getSidePointsFromCenter(rect: ISize): IHandle[] {
        const sides = [
            { positionName: RectanglePoint.left, type: HandleType.resize, x: -(rect.width / 2), y: 0 }, // Left Center
            { positionName: RectanglePoint.right, type: HandleType.resize, x: rect.width / 2, y: 0 }, // Right Center
            { positionName: RectanglePoint.top, type: HandleType.resize, x: 0, y: -(rect.height / 2) }, // Center Top
            { positionName: RectanglePoint.bottom, type: HandleType.resize, x: 0, y: rect.height / 2 }, // Center Bottom
        ];

        return sides;
    }

    private renderLayerHighlights(builderDocument: BuilderDocument, layers: Layer[], targetCanvas: HTMLCanvasElement) {
        const context = new CanvasRenderContext(targetCanvas, [], {
            textMeasurer: this.textMeasurer,
            useOldTextRenderingLineHeightFix: builderDocument.useOldTextRenderingLineHeightFix,
            useTextRenderingLineHeightFix: builderDocument.useTextRenderingLineHeightFix,
        });
        for (const layer of layers) {
            context.save();
            try {
                this.translateContext(context, layer.position, layer.rotation);
                const lines = this.getConnectingLines(this.getLayerBoundary(layer, this.uiContext.zoom), 0);
                const lineWidth = this.uiContext.action === 'transform' ? 2 : 3;
                context.strokeColor('rgb(63, 149, 255)');
                lines.forEach(points => {
                    context.lineWidth(lineWidth).drawLine(...points);
                });
            } finally {
                context.restore();
            }
        }
    }
}

export class ContentBuilderRendererFactory {
    public static SID = 'ContentBuilderRendererFactory';

    public static readonly $inject: string[] = [
        '$interval',
        TextSubstitutionService.SID,
        I18nService.SID,
        MvNotifier.SID,
        ProgressModalService.SID,
        $timeoutSID,
    ];

    // eslint-disable-next-line max-params
    public constructor(
        public $interval: ng.IIntervalService,
        public textSubstitutionService: TextSubstitutionService,
        public i18nService: I18nService,
        public mvNotifier: MvNotifier,
        public progressModalService: ProgressModalService,
        public $setTimeout: ITimeoutService,
    ) {}

    public getInstance(
        canvasId: string,
        imageCache: ImageCache,
        videoCache: VideoCache,
        uiContext: Untyped,
        textSubstitutionValues: TextSubstitutionValues,
    ): ContentBuilderRenderer {
        return new ContentBuilderRenderer(this, canvasId, imageCache, videoCache, uiContext, textSubstitutionValues);
    }
}

angular.module('app').service(ContentBuilderRendererFactory.SID, ContentBuilderRendererFactory);
