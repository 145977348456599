import {
    chunk,
    chunkedAsyncMap,
    containsAll,
    createMap,
    createMapOfProperty,
    excludeBy,
    excludeProperties,
    existsBy,
    existsByPredicate,
    extendArray,
    extendArrayUnique,
    filterBy,
    filterByPredicate,
    filterNulls,
    findBy,
    findByGetter,
    findByPredicate,
    flatten,
    groupBy,
    groupByGetter,
    groupByOrdered,
    indexOfBy,
    map,
    mapKeys,
    moveDown,
    moveUp,
    pluck,
    pluckByGetter,
    pluckId,
    rectifyOrder,
    relativeDifference,
    removeAll,
    removeById,
    removeObject,
    replaceOrAddObject,
    setPropertyOnAll,
    setScopeProperty,
    sortedBy,
    sortedEntries,
    SortedEntry as InternalSortedEntry,
    sortedKeys,
    sum,
    toggle,
    toggleById,
    useFallbackWhenUndefinedOrNull,
} from '@deltasierra/shared';

export type SortedEntry<T> = InternalSortedEntry<T>;

/**
 * @deprecated Use shared/common/dataUtils instead
 */
export class DataUtils {
    public static SID = 'dataUtils';

    public chunk = chunk;

    public chunkedAsyncMap = chunkedAsyncMap;

    public containsAll = containsAll;

    public createMap = createMap;

    public createMapOfProperty = createMapOfProperty;

    public excludeBy = excludeBy;

    public excludeProperties = excludeProperties;

    public existsBy = existsBy;

    public existsByPredicate = existsByPredicate;

    public extendArray = extendArray;

    public extendArrayUnique = extendArrayUnique;

    public extractIds = pluckId;

    public filterBy = filterBy;

    public filterByPredicate = filterByPredicate;

    public filterNulls = filterNulls;

    public findBy = findBy;

    public findByGetter = findByGetter;

    public findByPredicate = findByPredicate;

    public flatten = flatten;

    public groupBy = groupBy;

    public groupByOrdered = groupByOrdered;

    public groupByGetter = groupByGetter;

    public indexOfBy = indexOfBy;

    public map = map;

    public mapKeys = mapKeys;

    public moveUp = moveUp;

    public moveDown = moveDown;

    public pluckByGetter = pluckByGetter;

    public pluckId = pluckId;

    public pluck = pluck;

    public rectifyOrder = rectifyOrder;

    public relativeDifference = relativeDifference;

    public removeAll = removeAll;

    public removeById = removeById;

    public removeObject = removeObject;

    public replaceOrAddObject = replaceOrAddObject;

    public setPropertyOnAll = setPropertyOnAll;

    public setScopeProperty = setScopeProperty;

    public sortedBy = sortedBy;

    public sortedEntries = sortedEntries;

    public sortedKeys = sortedKeys;

    public sum = sum;

    public toggle = toggle;

    public toggleById = toggleById;

    public useFallbackWhenUndefinedOrNull = useFallbackWhenUndefinedOrNull;

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public static readonly $inject: string[] = [];
}

angular.module('app').service(DataUtils.SID, DataUtils);
