/// <reference path="../../../typings/browser.d.ts" />
import { I18nService } from '../i18n';
import { ModalInstance } from '../typings/angularUIBootstrap/modalService';
import { $locationSID } from './angularData';
import { ConfirmModal, confirmModalSID } from './confirmModal';
import ILocationService = angular.ILocationService;
import IAngularEvent = angular.IAngularEvent;
import IScope = angular.IScope;

interface WatcherEntry {
    watcher: (scope?: IScope) => any;
    isCollection?: boolean;
    deep?: boolean;
}

const LOCATION_CHANGE_START_EVENT = '$locationChangeStart';

export class DirtyStateChecker {
    private isDirty = false;

    private unwatchers: Function[] = [];

    private watching = false;

    private dirtyModal: ModalInstance | null = null;

    constructor(
        private $scope: IScope,
        private $location: ILocationService,
        private confirmModal: ConfirmModal,
        private watchers: WatcherEntry[],
        private i18n: I18nService,
    ) {
        $scope.$on(LOCATION_CHANGE_START_EVENT, this.onLocationChangeStart.bind(this));
    }

    private onLocationChangeStart(event: IAngularEvent) {
        if (this.isDirty) {
            event.preventDefault();
            if (!this.dirtyModal) {
                const url = this.$location.url();
                this.dirtyModal = this.confirmModal.open(
                    this.i18n.text.common.leavePagePrompt.title(),
                    this.i18n.text.common.leavePagePrompt.confirm(),
                    () => {
                        this.isDirty = false;
                        this.dirtyModal = null;
                        this.$location.url(url);
                    },
                    () => {
                        this.dirtyModal = null;
                    },
                );
            }
        }
    }

    startWatching(): void {
        if (!this.watching) {
            for (const watcher of this.watchers) {
                let unwatcher;
                if (watcher.isCollection) {
                    unwatcher = this.$scope.$watchCollection(watcher.watcher, this.onDirtyChange.bind(this));
                } else {
                    unwatcher = this.$scope.$watch(watcher.watcher, this.onDirtyChange.bind(this), watcher.deep);
                }
                this.unwatchers.push(unwatcher);
            }
            this.watching = true;
        }
    }

    private onDirtyChange(newValue: any, oldValue: any, scope: IScope) {
        if (newValue !== oldValue) {
            this.isDirty = true;
            this.stopWatching();
        }
    }

    stopWatching(): void {
        if (this.watching) {
            for (const unwatcher of this.unwatchers) {
                unwatcher();
            }
            this.unwatchers.length = 0;
            this.watching = false;
        }
    }

    reset(): void {
        this.isDirty = false;
        this.stopWatching();
    }
}

export class DirtyStateCheckerFactory {
    public static SID = 'DirtyStateCheckerFactory';

    static readonly $inject: string[] = [$locationSID, confirmModalSID, I18nService.SID];

    constructor(private $location: ILocationService, private confirmModal: ConfirmModal, private i18n: I18nService) {}

    createChecker($scope: IScope, watchers: WatcherEntry[]): DirtyStateChecker {
        return new DirtyStateChecker($scope, this.$location, this.confirmModal, watchers, this.i18n);
    }
}

angular.module('app').service(DirtyStateCheckerFactory.SID, DirtyStateCheckerFactory);
