/// <reference path="../../../../typings/browser.d.ts" />
import { Agency, ClientId } from '@deltasierra/shared';
import { AgencyApiClient } from '../../agencies/agencyApiClient';
import { MvClient } from '../../clients/mvClient';
import { DataUtils } from '../../common/dataUtils';
import { InteractionUtils } from '../../common/interactionUtils';
import { I18nService } from '../../i18n';
import { CurrencyService } from '../../payments/currencyService';
import { ClientSubscription } from './models';

interface SubscriptionsMap {
    [key: number]: ClientSubscription;
}

export class MvSubscriptionListCtrl {
    public static SID = 'mvSubscriptionListCtrl';

    loading = {
        agencies: false,
        subscriptions: false,
    };

    agencies?: Agency[];

    subscriptionsMap?: SubscriptionsMap;

    static readonly $inject: string[] = [
        AgencyApiClient.SID,
        MvClient.SID,
        InteractionUtils.SID,
        DataUtils.SID,
        CurrencyService.SID,
        I18nService.SID,
    ];

    constructor(
        agencyApiClient: AgencyApiClient,
        private readonly mvClient: MvClient,
        private readonly interactionUtils: InteractionUtils,
        dataUtils: DataUtils,
        private readonly currencyService: CurrencyService,
        private readonly i18n: I18nService,
    ) {
        void this.interactionUtils
            .handleRemoteSimple(this, 'retrieve agencies', 'agencies', agencyApiClient.getAgencies())
            .then((agencies: Agency[]) => {
                this.agencies = agencies;
            });
        void this.interactionUtils
            .handleRemote(this, 'retrieve client subscriptions', 'subscriptions', this.mvClient.getSubscriptions())
            .then((subscriptions: ClientSubscription[]) => {
                this.subscriptionsMap = <SubscriptionsMap>dataUtils.createMap(s => s.clientId!, subscriptions);
            });
    }

    getSubscriptionDisplay(clientId: ClientId) {
        if (this.subscriptionsMap) {
            const subscription = this.subscriptionsMap[clientId];
            if (subscription) {
                const amount = this.currencyService.getLargestUnit(subscription.currency, subscription.amountInCents);
                return this.i18n.formatCurrency(amount, { format: 'code', code: subscription.currency });
            }
        }
        return 'None';
    }
}

angular.module('app').controller(MvSubscriptionListCtrl.SID, MvSubscriptionListCtrl);
