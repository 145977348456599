import { DSButton, DSGrid, Translate, WizardStepProps } from '@deltasierra/components';
import { Formik } from 'formik';
import * as React from 'react';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { CLUB_READY_MAX_LOCATION_PUBLISH_COUNT } from '@deltasierra/integrations/clubready';
import { InitialLocationData } from '../../hooks/useMultiLocationLoader';
import { FormButtonGroup, StyledForm, StyledMainTitle } from '../common';
import { PublishToClubReadyStep1Data } from './types/publish-to-club-ready-step-1-data';
import { ClubNameSection, SendToSection } from './components';
import { usePublishToClubReadyStep1Form } from './hooks/usePublishToClubReadyStep1Form';
import { LargeClubReadyPublishMessage } from './LargeClubReadyPublishMessage';

export type PublishToClubReadyStep1Props = WizardStepProps<PublishToClubReadyStep1Data> & {
    clubName: string | null;
    locationsToPublishTo: InitialLocationData[];
    step1Data?: PublishToClubReadyStep1Data;
};

export const PublishToClubReadyStep1: React.FC<PublishToClubReadyStep1Props> = ({
    clubName,
    exitWizard,
    gotoNextStep,
    locationsToPublishTo,
    step1Data,
    StepCount,
}) => {
    const formikProps = usePublishToClubReadyStep1Form(gotoNextStep, step1Data);

    const exceedsLocationCount = React.useMemo(
        () => locationsToPublishTo.length > CLUB_READY_MAX_LOCATION_PUBLISH_COUNT,
        [locationsToPublishTo.length],
    );

    return (
        <Formik {...formikProps} enableReinitialize validateOnChange validateOnMount>
            {formik => (
                <StyledForm>
                    <StyledMainTitle component="h3" variant="h3">
                        <Translate keyId="BUILD.PUBLISH.CLUB_READY.SELECT_YOUR_CLUB_READY_LISTS" />
                    </StyledMainTitle>
                    <ClubNameSection clubName={clubName} locationCount={locationsToPublishTo.length} />
                    {exceedsLocationCount && <LargeClubReadyPublishMessage />}
                    <SendToSection formik={formik} />
                    <FormButtonGroup>
                        <DSGrid alignItems="center" container direction="row" justifyContent="space-between">
                            <DSGrid item>
                                <DSButton startIcon={<ArrowBackIosIcon />} variant="outlined" onClick={exitWizard}>
                                    <Translate keyId="COMMON.BACK" />
                                </DSButton>
                            </DSGrid>
                            <DSGrid item>
                                <StepCount />
                            </DSGrid>
                            <DSGrid item>
                                <DSButton
                                    color="primary"
                                    disabled={!formik.isValid || formik.isSubmitting || exceedsLocationCount}
                                    endIcon={<ArrowForwardIosIcon />}
                                    type="submit"
                                    variant="contained"
                                >
                                    <Translate keyId="COMMON.NEXT" />
                                </DSButton>
                            </DSGrid>
                        </DSGrid>
                    </FormButtonGroup>
                </StyledForm>
            )}
        </Formik>
    );
};

PublishToClubReadyStep1.displayName = 'PublishToClubReadyStep1';
