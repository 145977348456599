/// <reference path="../../../../typings/browser.d.ts" />
import { LocationId, LocationIdHierarchy } from '@deltasierra/shared';
import {
    actualComponent,
    ExpressionBinding,
    ExpressionCallback,
    ILifecycleHooks,
    OneWayBinding,
} from '../../common/angularData';
import { DataUtils } from '../../common/dataUtils';

/**
 * This component isolates the location selection from the special requests controller(s), so that the controller
 * can test if the client has changed.
 */
export class SpecialRequestLocationPickerCtrl implements ILifecycleHooks {
    // Props
    initialLocationId!: LocationId;

    locations!: LocationIdHierarchy[];

    onLocationSelected!: ExpressionCallback<{ location: LocationIdHierarchy }, ng.IPromise<LocationId>>;

    // State
    selectedLocation?: LocationIdHierarchy;

    static $inject: string[] = [
        DataUtils.SID,
    ];

    constructor(
        protected readonly dataUtils: DataUtils,
    ) {

    }

    $onChanges(): void {
        if (this.locations && this.initialLocationId) {
            this.selectedLocation =
                this.findLocationById(this.initialLocationId) ||
                this.locations[0];
}
    }

    protected findLocationById(locationId: LocationId) {
        return this.dataUtils.findByPredicate(location => location.locationId === locationId, this.locations);
    }

    onChangeLocation() {
        if (this.selectedLocation) {
            return this.onLocationSelected({
                location: this.selectedLocation,
            }).then((newLocationId: LocationId) => {
                // The consuming SR controller can decide if it wants to accept the new location. If it declines, we need
                //  To ensure our dropdown re-selects the old location.
                const newLocation = this.findLocationById(newLocationId);
                if (newLocation) {
                    this.selectedLocation = newLocation;
                }
            });
        }
    }
}

export const specialRequestLocationPickerSID = 'specialRequestLocationPicker';

export const specialRequestLocationPickerConfig = actualComponent(
    SpecialRequestLocationPickerCtrl,
    '/partials/specialRequests/specialRequestLocationPicker/specialRequestLocationPicker',
    {
        initialLocationId: OneWayBinding,
        locations: OneWayBinding,
        onLocationSelected: ExpressionBinding,
    },
);

angular.module('app').component(specialRequestLocationPickerSID, specialRequestLocationPickerConfig);
