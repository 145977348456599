import { useAngularServiceContext } from '../../common/componentUtils/angularServiceContexts';

/**
 * Return whether or not the currently logged in user has access to the scheduled page
 *
 * @returns Whether the user has accecss
 */
export function useCanUserAccessScheduledPage(): boolean {
    const mvIdentity = useAngularServiceContext('mvIdentity');
    return mvIdentity.canPublish();
}
