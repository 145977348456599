/// <reference path="../../../../typings/browser.d.ts" />
import { themeColors } from '@deltasierra/components';
import { User } from '@deltasierra/shared';
import { $scopeSID } from '../../common/angularData';
import { UserUIService } from '../userUIService';

export type ProfilePictureQuality = 'high' | 'low';

export class ProfilePictureController {
    static SID = 'ProfilePictureController';

    private static readonly DEFAULT_BACKGROUND_COLOR = themeColors.primary;

    user!: User;

    quality?: ProfilePictureQuality;

    backgroundColor: string;

    static readonly $inject: string[] = [$scopeSID, UserUIService.SID];

    constructor($scope: ng.IScope, private readonly userUIService: UserUIService) {
        this.backgroundColor = ProfilePictureController.DEFAULT_BACKGROUND_COLOR;
    }

    getInitials(): string | null {
        return this.user && this.userUIService.getUserInitials(this.user);
    }

    hasImage(): boolean {
        return !!(this.user && this.user.profilePicture);
    }

    getImageUrl() {
        if (this.user && this.user.profilePicture) {
            if (this.quality == 'high') {
                return this.user.profilePicture.url;
            } else {
                // If low quality is acceptable, try using the thumbnail but fall-back to high quality
                // (in-case thumbnail hasn't been generated yet)
                return this.user.profilePicture.thumb256x256url || this.user.profilePicture.url;
            }
        }

        return null;
    }
}

angular.module('app').controller(ProfilePictureController.SID, ProfilePictureController);
