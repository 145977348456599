/* eslint-disable max-lines-per-function */
import { gql, useApolloClient, useMutation } from '@apollo/client';
import {
    DSButton,
    DSDialog,
    DSDialogActions,
    DSDialogContent,
    DSDialogTitle,
    DSFormControl,
    DSFormControlLabel,
    DSRadio,
    DSRadioGroup,
    DSTextField,
    DSTypography,
    Loading,
    Translate,
} from '@deltasierra/components';
import { useRadioInput } from '@deltasierra/react-hooks';
import { t } from '@deltasierra/shared';
import { Formik } from 'formik';
import * as React from 'react';
import { titleCaseFirst } from '@deltasierra/string-utilities';
import { useAngularServiceContext } from '../../../common/componentUtils/angularServiceContexts';
import { MoreLocationsButton } from '../MoreLocationsButton';
import { ScheduledPostsRowFragment_content } from '../ScheduledPostRow/__graphqlTypes/ScheduledPostsRowFragment';
import {
    EditCaptionForEditCaptionModal,
    EditCaptionForEditCaptionModalVariables,
} from './__graphqlTypes/EditCaptionForEditCaptionModal';
import { useWorkflow } from './use-workflow';
import {
    EditCaptionGroupForEditCaptionModal,
    EditCaptionGroupForEditCaptionModalVariables,
} from './__graphqlTypes/EditCaptionGroupForEditCaptionModal';
import { GetPostArtifactForModalsQueryPostArtifact, usePostArtifact } from './use-post-artifact';

export const EDIT_CAPTION_MUTATION = gql`
    mutation EditCaptionForEditCaptionModal($input: EditPostArtifactsCaptionInput!) {
        editPostArtifactsCaption(input: $input) {
            ... on EditPostArtifactsCaptionPayload {
                workflow {
                    id
                    status {
                        state
                        message
                    }
                }
            }
            ... on PostArtifactNotFoundError {
                code
                message
            }
        }
    }
`;

export const EDIT_CAPTION_GROUP_MUTATION = gql`
    mutation EditCaptionGroupForEditCaptionModal($input: EditPostArtifactGroupCaptionInput!) {
        editPostArtifactGroupCaption(input: $input) {
            ... on EditPostArtifactGroupCaptionPayload {
                workflow {
                    id
                    status {
                        state
                        message
                    }
                }
            }
            ... on PostArtifactGroupNotFoundError {
                code
                message
            }
        }
    }
`;

export interface FormValues {
    caption: string;
    firstComment: string;
}

export interface EditCaptionModalContentProps {
    post: GetPostArtifactForModalsQueryPostArtifact;
    onRequestConfirm?: () => void;
    onRequestCancel?: () => void;
}

function EditCaptionModalContent(props: EditCaptionModalContentProps): JSX.Element {
    const client = useApolloClient();
    const notifier = useAngularServiceContext('mvNotifier');

    const initialValues: FormValues = {
        caption: props.post.content.caption ?? '',
        firstComment: props.post.content.firstComment ?? '',
    };
    const [value, onChange] = useRadioInput(['one', 'all'], 'one');
    // This is set when submitting the form so we can reference it when updating the apollo cache
    const [captionReference, setCaptionReference] = React.useState(props.post.content.caption);
    const [editCaption] = useMutation<EditCaptionForEditCaptionModal, EditCaptionForEditCaptionModalVariables>(
        EDIT_CAPTION_MUTATION,
    );
    const [editCaptionGroup] = useMutation<
        EditCaptionGroupForEditCaptionModal,
        EditCaptionGroupForEditCaptionModalVariables
    >(EDIT_CAPTION_GROUP_MUTATION);

    const [getStatus, { isRunning: isJobRunning }] = useWorkflow({
        onComplete: job => {
            const postArtifactCacheId = client.cache.identify({ __typename: 'PostArtifact', id: props.post.id });
            const hasPostBeenDeleted = job.results.edges?.some(edge => edge.node.info === 'DELETED');

            if (hasPostBeenDeleted) {
                notifier.success(
                    t('SCHEDULE.PAGE.DIALOGS.RESCHEDULE.DELETED', {
                        platform: titleCaseFirst(props.post.platform.name),
                    }),
                );
                client.cache.evict({ id: postArtifactCacheId });
                client.cache.gc();
            } else {
                notifier.success(t('SCHEDULE.PAGE.DIALOGS.EDIT_CAPTION.SUCCESSFULLY_EDITED_CAPTION'));
                client.cache.modify<{ content: any }>({
                    fields: {
                        // eslint-disable-next-line camelcase
                        content(existing: ScheduledPostsRowFragment_content): any {
                            return { ...existing, caption: captionReference };
                        },
                    },
                    id: postArtifactCacheId,
                });
            }
            props.onRequestConfirm?.();
        },
        onError: (message?: string | null) => {
            if (message) {
                // If we have a translated custom error message returned
                notifier.unexpectedError(message);
            } else {
                notifier.unexpectedError(t('SCHEDULE.PAGE.DIALOGS.EDIT_CAPTION.FAILED_TO_EDIT'));
            }
        },
    });

    function validate(values: FormValues) {
        const errors: Partial<FormValues> = {};
        if (values.caption.length === 0) {
            errors.caption = t('COMMON.REQUIRED');
        }
        // TODO: validate caption length per platform
        return errors;
    }

    async function handleSubmit({ caption, firstComment }: FormValues) {
        setCaptionReference(caption);
        if (value === 'one') {
            const result = await editCaption({
                variables: { input: { caption, firstComment, postArtifactIds: [props.post.id] } },
            });
            if (result.data?.editPostArtifactsCaption.__typename === 'PostArtifactNotFoundError') {
                notifier.unexpectedErrorWithData(
                    t('SCHEDULE.PAGE.DIALOGS.EDIT_CAPTION.FAILED_TO_EDIT'),
                    result.data.editPostArtifactsCaption.message,
                );
            } else if (result.data?.editPostArtifactsCaption.__typename === 'EditPostArtifactsCaptionPayload') {
                getStatus({ variables: { id: result.data.editPostArtifactsCaption.workflow.id } });
            }
        } else {
            const result = await editCaptionGroup({
                variables: { input: { caption, firstComment, postArtifactGroupId: props.post.parent!.id } },
            });
            if (result.data?.editPostArtifactGroupCaption.__typename === 'PostArtifactGroupNotFoundError') {
                notifier.unexpectedErrorWithData(
                    t('SCHEDULE.PAGE.DIALOGS.EDIT_CAPTION.FAILED_TO_EDIT'),
                    result.data.editPostArtifactGroupCaption,
                );
            } else if (result.data?.editPostArtifactGroupCaption.__typename === 'EditPostArtifactGroupCaptionPayload') {
                getStatus({ variables: { id: result.data.editPostArtifactGroupCaption.workflow.id } });
            }
        }
    }

    return (
        <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit}>
            {formik => (
                <form onSubmit={formik.handleSubmit}>
                    <DSDialogTitle>
                        <Translate keyId="SCHEDULE.PAGE.DIALOGS.EDIT_CAPTION.TITLE" />
                    </DSDialogTitle>
                    <DSDialogContent>
                        <DSTextField
                            autoFocus
                            error={!!formik.errors.caption}
                            fullWidth
                            helperText={formik.errors.caption ?? ''}
                            label={t('SCHEDULE.POSTS.CAPTION')}
                            maxRows={3}
                            minRows={3}
                            multiline
                            {...formik.getFieldProps('caption')}
                        />
                        {props.post.content.firstComment && (
                            <DSTextField
                                autoFocus
                                error={!!formik.errors.firstComment}
                                fullWidth
                                helperText={formik.errors.firstComment ?? ''}
                                label={t('BUILD.FIRST_COMMENT')}
                                maxRows={3}
                                minRows={3}
                                multiline
                                {...formik.getFieldProps('firstComment')}
                            />
                        )}
                        {props.post.parent && props.post.parent.locations.edges.length > 1 && (
                            <DSFormControl>
                                <DSRadioGroup value={value} onChange={onChange}>
                                    <DSFormControlLabel
                                        control={<DSRadio />}
                                        label={t('SCHEDULE.PAGE.DIALOGS.EDIT_CAPTION.OPTION_FOR_SINGLE', {
                                            location: props.post.location.title,
                                        })}
                                        value="one"
                                    />
                                    <span>
                                        <DSFormControlLabel
                                            control={<DSRadio />}
                                            disabled={!props.post.parent}
                                            label={t('SCHEDULE.PAGE.DIALOGS.EDIT_CAPTION.OPTION_FOR_MULTIPLE', {
                                                location: props.post.location.title,
                                                num: (props.post.parent?.locationCount ?? 1) - 1,
                                            })}
                                            value="all"
                                        />
                                        {props.post.parent && props.post.parent.locations.edges.length > 1 && (
                                            <MoreLocationsButton postArtifactId={props.post.id} />
                                        )}
                                    </span>
                                </DSRadioGroup>
                            </DSFormControl>
                        )}
                        {isJobRunning && (
                            <DSTypography>
                                <Translate
                                    keyId="SCHEDULE.PAGE.DIALOGS.EDIT_CAPTION.CONTACTING_PLATFORM"
                                    options={{ platform: props.post.platform.displayName }}
                                />
                            </DSTypography>
                        )}
                    </DSDialogContent>
                    <DSDialogActions>
                        <DSButton color="default" disabled={isJobRunning} onClick={props.onRequestCancel}>
                            <Translate keyId="COMMON.CANCEL" />
                        </DSButton>
                        <DSButton color="primary" disabled={isJobRunning} type="submit">
                            <Translate keyId="COMMON.EDIT" />
                        </DSButton>
                    </DSDialogActions>
                </form>
            )}
        </Formik>
    );
}
EditCaptionModalContent.displayName = 'EditCaptionModalContent';

export type EditCaptionModalProps = {
    isOpen: boolean;
    onRequestConfirm?: () => void;
    onRequestCancel?: () => void;
    postId: string;
};

export function EditCaptionModal(props: EditCaptionModalProps): JSX.Element {
    return <DSDialog open={props.isOpen}>{props.isOpen && <EditCaptionModalContentWrapper {...props} />}</DSDialog>;
}
EditCaptionModal.displayName = 'EditCaptionModal';

function EditCaptionModalContentWrapper(props: EditCaptionModalProps): JSX.Element {
    const post = usePostArtifact(props.postId);

    if (!post) {
        return <Loading />;
    } else {
        return <EditCaptionModalContent {...props} post={post} />;
    }
}
