/// <reference path="../../../../typings/browser.d.ts" />
import { substituteText, TextSubstitutionValues } from '@deltasierra/shared';

export class TextSubstitutionService {
    static SID = 'textSubstitutionService';

    static readonly $inject: string[] = [];

    constructor() {
        // Do nothing
    }

    substitute(values: TextSubstitutionValues, text: string | null): string {
        return substituteText(values, text);
    }
}

angular.module('app').service(TextSubstitutionService.SID, TextSubstitutionService);
