import { Loading } from '@deltasierra/components';
import { AssignedLocation } from '@deltasierra/shared';
import React from 'react';
import { LocationGroupsDashboardWell } from './LocationGroupsDashboardWell';
import { OldLocationDashboard } from './OldLocationDashboard';
import { useShouldShowLocationGroupsDashboardWell } from './useShouldShowLocationGroupsDashboardWell';

export type LocationGroupsDashboardProps = {
    location: AssignedLocation;
};

export function LocationGroupsDashboard({ location }: LocationGroupsDashboardProps): JSX.Element {
    const locationId = location.graphqlId;

    const { loading, shouldShowGroups } = useShouldShowLocationGroupsDashboardWell(locationId);

    return (
        <>
            {loading && <Loading />}
            {!loading && (
                <>
                    {shouldShowGroups ? (
                        <LocationGroupsDashboardWell locationId={locationId} />
                    ) : (
                        <OldLocationDashboard location={location} />
                    )}
                </>
            )}
        </>
    );
}
LocationGroupsDashboard.displayName = 'LocationGroupsDashboard';
