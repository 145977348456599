import { Tag } from '@deltasierra/shared';
import { I18nService } from '../../../i18n';
import { TagService, tagServiceSID } from '../../../tags/tagService';
import { simpleComponent, TwoWayBinding } from '../../../common/angularData';
import { MvNotifier } from '../../../common/mvNotifier';

export class TemplateTagPickerController {
    static SID = 'TemplateTagPickerController';

    tags! : Tag[];

    inputValue?: '';

    static readonly $inject : string[] = [
        I18nService.SID,
        MvNotifier.SID,
        tagServiceSID,
    ];

    constructor(
        protected readonly i18nService : I18nService,
        protected readonly mvNotifier : MvNotifier,
        protected readonly tagService : TagService,
    ) {

    }

    protected tagExists(tagName : string) {
        tagName = tagName.toLowerCase();
        for (const tag of this.tags) {
            if (tag.title == tagName) {
                return true;
            }
        }
        return false;
    }

    protected processTagsResponse(tags : Tag[]) {
        for (const tag of tags) {
            // We check this after creating the tags, since the user may have entered multiple tags (separated by
            //  Spaces), and some might already exist, but some might be valid, so just check each one afterwards.
            if (!this.tagExists(tag.title)) {
                this.tags.push(tag);
            }
        }
    }

    addTag() {
        if (this.inputValue) {
            this.tagService.createTags(this.inputValue)
                .then((tags : Tag[]) => {
                    this.processTagsResponse(tags);
                    this.inputValue = '';
                }).catch((data : any) => {
                    this.mvNotifier.unexpectedErrorWithData(this.i18nService.text.build.error.failedToCreateTag(), data);
                });
        }
    }

    removeTag(tagToRemove : Tag) {
        for (let i = 0; i < this.tags.length; i++) {
            const tag = this.tags[i];
            if (tag == tagToRemove) {
                this.tags.splice(i, 1);
                return;
            }
        }
        console.log(`Couldn't find tag to delete: ${tagToRemove.title}`);
    }
}

export const templateTagPickerSID = 'templateTagPicker';
export const templateTagPickerConfig = simpleComponent(
    TemplateTagPickerController,
    '/partials/contentBuilder/common/templateTagPicker/templateTagPicker.jade',
    {
        tags: TwoWayBinding,
    },
);

angular.module('app').directive(templateTagPickerSID, [() => templateTagPickerConfig]);
