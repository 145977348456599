/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { gql, useMutation } from '@apollo/client';
import { Button, FormGroup, WizardStepProps } from '@deltasierra/components';
import { isEmailStringValid, t, SendMailchimpCampaignPreviewDto } from '@deltasierra/shared';
import { Form, Formik, FormikErrors } from 'formik';
import * as React from 'react';
import { useAngularServiceContext } from '../../../../../common/componentUtils/angularServiceContexts';
import { ValidateableFormGroup } from '../../../../../common/form/ValidateableFormGroup';
import { ValidatableTextInput } from '../../../../../common/form/ValidateableTextInput';
import { Translate } from '../../../../../directives/Translate';
import { PublishToMailchimpStep2Data } from './PublishToMailchimpStep2';
import { SendMailchimpCampaignPreview, SendMailchimpCampaignPreviewVariables } from './__graphqlTypes/SendMailchimpCampaignPreview';

const SEND_MAILCHIMP_CAMPAIGN_PREVIEW = gql`
    mutation SendMailchimpCampaignPreview($mailchimpId: ID!, $input: SendMailchimpCampaignPreviewInput!) {
        sendMailchimpCampaignPreview(id: $mailchimpId, input: $input) {
            __typename
            ... on SendMailchimpCampaignPreviewResult {
                campaignId
            }
            ... on MailchimpApiError {
                message
            }
        }
    }
`;

type FormValues = Pick<SendMailchimpCampaignPreviewDto, 'recipient'>;

export type PublishToMailchimpStep3Data = {
    campaignId: string;
    formValues: FormValues;
};

export type PublishToMailchimpStep3Props = WizardStepProps<PublishToMailchimpStep3Data> & {
    mailchimpId: string;
    step2Data: PublishToMailchimpStep2Data;
    step3Data?: PublishToMailchimpStep3Data;
};

export const PublishToMailchimpStep3: React.FC<PublishToMailchimpStep3Props> = ({
    CancelButton,
    NextButton,
    PrevButton,
    StepCount,
    gotoNextStep,
    mailchimpId,
    nextButtonProps,
    step2Data,
    step3Data,
}) => {
    const mvNotifier = useAngularServiceContext('mvNotifier');

    const [sendMailchimpCampaignPreview, { loading }] = useMutation<SendMailchimpCampaignPreview, SendMailchimpCampaignPreviewVariables>(
        SEND_MAILCHIMP_CAMPAIGN_PREVIEW,
    );

    const initialValues: FormValues = {
        recipient: '',
        ...step3Data?.formValues,
    };

    return (
        <>
            <h4>
                <Translate keyId="BUILD.PUBLISH.MAILCHIMP.PREVIEW" />
            </h4>
            <Formik
                initialValues={initialValues}
                validate={values => {
                    const errors: FormikErrors<FormValues> = {};
                    if (!values.recipient) {
                        errors.recipient = t('COMMON.VALIDATION.REQUIRED');
                    } else if (!isEmailStringValid(values.recipient)) {
                        errors.recipient = t('COMMON.VALIDATION.INVALID_EMAIL');
                    }
                    return errors;
                }}
                validateOnMount
                onSubmit={async (values, actions) => {
                    const response = await sendMailchimpCampaignPreview({
                        variables: {
                            input: {
                                campaignId: step2Data.campaignId,
                                recipient: values.recipient,
                            },
                            mailchimpId,
                        },
                    });

                    if (!response.data) {
                        mvNotifier.unexpectedErrorWithData('The request returned no response data', response);
                        return;
                    }

                    const responseData = response.data;

                    if (responseData.sendMailchimpCampaignPreview.__typename === 'MailchimpApiError') {
                        mvNotifier.unexpectedError(responseData.sendMailchimpCampaignPreview.message);
                        return;
                    }

                    const campaignId = responseData.sendMailchimpCampaignPreview.campaignId.toString();

                    gotoNextStep({ campaignId, formValues: values });
                }}
            >
                {formIk => (
                    <Form>
                        <ValidateableFormGroup fullWidth label={t('BUILD.PUBLISH.MAILCHIMP.RECIPIENT')} name="recipient" required>
                            <ValidatableTextInput />
                        </ValidateableFormGroup>
                        <FormGroup fullWidth>
                            <div className="pull-left">
                                <StepCount />
                            </div>
                            <div className="pull-right">
                                <CancelButton />
                                <PrevButton />
                                <NextButton dataToSave={step3Data} disabled={loading}>
                                    <Translate keyId="BUILD.PUBLISH.MAILCHIMP.PREVIEW_SKIP" />
                                </NextButton>
                                <Button {...nextButtonProps} disabled={loading || !formIk.isValid} type="submit">
                                    <Translate keyId="BUILD.PUBLISH.MAILCHIMP.PREVIEW_SEND" />
                                </Button>
                            </div>
                        </FormGroup>
                    </Form>
                )}
            </Formik>
        </>
    );
};
