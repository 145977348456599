import * as React from 'react';
import { Translate, TranslateProps } from '../../directives/Translate';

export type WellProps = {
    title: string | TranslateProps;
    children: React.ReactNode;
};

const Well: React.FunctionComponent<WellProps> = React.memo(({ title, children }) => (
    <div className="well">
        <h3>
            {typeof title === 'string' && title}
            {typeof title === 'object' && <Translate {...title} />}
        </h3>
        {children}
    </div>
));
Well.displayName = 'Well';

export default Well;
