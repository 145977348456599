/// <reference path="../_references.d.ts" />

import i18next from 'i18next';
import { GetSegmentKeyFunction, SegmentKey, TranslateOptions } from '@deltasierra/i18n';

import { I18nService } from './i18nService';

export const SID = 'translate';

angular.module('app').filter(SID, [
    '$rootScope',
    I18nService.SID,
    (rootScope: ng.IRootScopeService, i18n: I18nService) => {
        i18next.on('loaded', () => setTimeout(() => rootScope.$apply()));

        const translate = (key: GetSegmentKeyFunction | SegmentKey, options: TranslateOptions) => {
            const result = i18n.translate(key, options);
            return result;
        };

        return translate;
    },
]);
