/// <reference path="../../../typings/browser.d.ts" />
import { AssignedLocation, LocationId } from '@deltasierra/shared';
import { IScope } from 'angular';
import { MvIdentity } from '../account/mvIdentity';
import { ClientResource, MvClientResource, mvClientResourceSID } from '../clients/mvClientResource';
import { $locationSID, $routeParamsSID, $routeSID, $scopeSID, IRoute, IRouteParams } from '../common/angularData';
import { MvNotifier } from '../common/mvNotifier';
import { I18nService } from '../i18n';
import { MvLocation } from './mvLocation';
import ILocationService = angular.ILocationService;

export class MvLocationDetailCtrl {
    public static readonly SID = 'mvLocationDetailCtrl';

    public static readonly $inject: string[] = [
        $scopeSID,
        $routeSID,
        $routeParamsSID,
        $locationSID,
        mvClientResourceSID,
        MvLocation.SID,
        MvIdentity.SID,
        MvNotifier.SID,
        I18nService.SID,
    ];

    public client: ClientResource;

    public location?: AssignedLocation;

    public useDisplayName?: boolean;

    // eslint-disable-next-line max-params
    public constructor(
        private readonly $scope: IScope,
        private readonly $route: IRoute,
        $routeParams: IRouteParams,
        private readonly $location: ILocationService,
        mvClientResource: MvClientResource,
        private readonly mvLocation: MvLocation,
        public readonly identity: MvIdentity,
        private readonly mvNotifier: MvNotifier,
        private readonly i18n: I18nService,
    ) {
        const locationId = LocationId.from(parseInt($routeParams.id, 10));
        this.client = mvClientResource.get({ id: $routeParams.clientId });
        this.loadLocation(locationId);
        this.onChangeTimezone = this.onChangeTimezone.bind(this);
    }

    public deleteLocation(location: AssignedLocation): ng.IPromise<void> {
        return this.mvLocation
            .deleteLocation(location.clientId, location.id)
            .then(() => {
                this.mvNotifier.notify(
                    this.i18n.text.agency.client.location.notifyLocationDeleted({ location: location.title }),
                ); // TODO translate this
                this.$location.path(`/clients/${this.client.id}`);
            })
            .catch((err: any) =>
                this.mvNotifier.unexpectedError(this.i18n.text.agency.client.location.notifyLocationDeleteError(), err),
            ); // TODO translate this
    }

    public async updateLocation(): Promise<void> {
        if (this.location) {
            const details = {
                displayName: this.location.displayName,
                supportedPlannerLimit: this.location.supportedPlannerLimit || undefined,
                timezone: this.location.timezone,
                title: this.location.title,
            };

            try {
                await this.mvLocation.updateDetails(this.location.id, details);
                this.mvNotifier.notify(this.i18n.text.agency.client.location.notifyLocationUpdated()); // TODO translate this
                this.$route.reload();
            } catch {
                // TODO translate this
                this.mvNotifier.unexpectedError(this.i18n.text.agency.client.location.notifyLocationUpdateError());
            }
        }
    }

    public onChangeTimezone(timezone: string): void {
        this.location!.timezone = timezone;
    }

    public onChangeUseDisplayName(): void {
        if (this.useDisplayName) {
            this.location!.displayName = this.location!.displayName || '';
        } else {
            this.location!.displayName = null;
        }
    }

    private getUseDisplayName(): void {
        this.useDisplayName = this.location!.displayName !== undefined && this.location!.displayName !== null;
    }

    private async loadLocation(locationId: LocationId): Promise<void> {
        this.location = await this.mvLocation.getAssignedLocation(locationId);
        this.getUseDisplayName();
        this.$scope.$digest();
    }
}

angular.module('app').controller(MvLocationDetailCtrl.SID, MvLocationDetailCtrl);
