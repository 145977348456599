import { ApiRequestData, ApiRoute, ObjectOrVoid, t } from '@deltasierra/shared';
import * as React from 'react';
import { useAngularServiceContext } from '../componentUtils/angularServiceContexts';
import { invokeApiRoute } from '../httpUtils';
import { useFutureStateReducer } from './reducers';
import { createLoadingState, FutureState, FutureStateWithoutPending } from './types';

class Deferred<T> implements Pick<Promise<T>, 'catch' | 'finally' | 'then'> {
    [Symbol.toStringTag] = 'Promise' as const;

    public promise: Promise<T>;

    private _resolveSelf?: (value: PromiseLike<T> | T) => void;

    private _rejectSelf?: (reason?: any) => void;

    public constructor() {
        this.promise = new Promise((resolve, reject) => {
            this._resolveSelf = resolve;
            this._rejectSelf = reject;
        });
    }

    public async finally(onfinally?: (() => void) | null | undefined): Promise<T> {
        return this.promise.finally(onfinally);
    }

    public async then<TResult1 = T, TResult2 = never>(
        onfulfilled?: ((value: T) => PromiseLike<TResult1> | TResult1) | null | undefined,
        onrejected?: ((reason: any) => PromiseLike<TResult2> | TResult2) | null | undefined,
    ): Promise<TResult1 | TResult2> {
        return this.promise.then(onfulfilled, onrejected);
    }

    public async catch<TResult = never>(
        onrejected?: ((reason: any) => PromiseLike<TResult> | TResult) | null | undefined,
    ): Promise<T | TResult> {
        return this.promise.then(onrejected);
    }

    public resolve(val: T): void {
        this._resolveSelf?.(val);
    }

    public reject(reason: any): void {
        this._rejectSelf?.(reason);
    }
}

function defer<T>(): Deferred<T> {
    return new Deferred<T>();
}

/**
 * Futures and async operations in easy-to-use hook form!
 *
 * This hook is ideal for situations where the async operation needs to be triggered by some action performed by the user (ie: clicking the
 * save button). The async operation will only be triggered by calling the invoke function.
 *
 * @example
 * const EditUserForm = ({ user }) => {
 *     const [formState, setFormState] = React.useState(user);
 *     const {state: saveUserState, invoke: saveUser, reset} = useFuture(await editedUser => {
 *         await saveUserAsync(editedUser);
 *     }, []);
 *     return (
 *         <form onSubmit={() => saveUser(formState)}>
 *             {saveUserState.isFailed && (
 *                 <p>
 *                     There was an issue saving your changes. Please try again.
 *                     <span onClick={() => reset()}>clear message</span>
 *                 </p>
 *             )}
 *             ...
 *             <button type="submit" disabled={saveUserState.isFailed}>Save</button>
 *         </form>
 *     )
 * };
 * @param fn - The async operation to invoke
 * @param [deps] - Dependencies for the callback
 * @param [options] - Additional configuration options for the hook
 * @param options.description - description
 * @returns An object containing the current state of the data retrieval, the invoke function, and a reset function. Both the returned
 * object and properties are memoized
 */
export function useFuture<TContext extends any[], TValue, TError = any>(
    fn: (...args: TContext) => Promise<TValue>,
    deps: React.DependencyList = [],
    options: { description?: string } = {},
): { state: FutureState<TValue, TError>; invoke: (...args: TContext) => Promise<TValue>; reset: () => void } {
    const notifier = useAngularServiceContext('mvNotifier');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const [state, dispatch] = useFutureStateReducer<TValue, TError>();
    const [currentArgs, setCurrentArgs] = React.useState<TContext>([] as any);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const memoizedCallback = React.useCallback(fn, deps);
    const cachedOptions = React.useMemo(() => ({ description: options.description }), [options.description]);

    // We must resolve or reject this promise after its called or we could end up with memory leak
    const defferedPromiseRef = React.useRef<Deferred<TValue>>();

    React.useEffect(() => {
        let isCancelled = false;
        if (state.isLoading) {
            // This should always be true
            const deferredPromise = defferedPromiseRef.current;
            if (deferredPromise !== undefined) {
                memoizedCallback(...currentArgs)
                    .then(data => {
                        if (!isCancelled) {
                            dispatch({ payload: data, type: 'FINISHED' });
                        }
                        deferredPromise.resolve(data);
                    })
                    .catch(error => {
                        if (!isCancelled) {
                            dispatch({ payload: error, type: 'FAILED' });
                            if (cachedOptions.description) {
                                notifier.unexpectedErrorWithData(
                                    t('COMMON.FAILED_TO', { description: cachedOptions.description }),
                                    error,
                                );
                            }
                        }
                        // Always reject if we have an error
                        deferredPromise.reject(error);
                    });
            }
        }

        return () => {
            isCancelled = true;
        };
    }, [state, memoizedCallback, currentArgs, dispatch, cachedOptions, notifier, defferedPromiseRef]);

    const invoke = React.useCallback(
        async (...args: TContext) => {
            setCurrentArgs(args);
            defferedPromiseRef.current = defer<TValue>();
            dispatch({ type: 'START' });
            return defferedPromiseRef.current.promise;
        },
        [dispatch, setCurrentArgs, defferedPromiseRef],
    );
    const reset = React.useCallback(() => dispatch({ type: 'RESET' }), [dispatch]);
    return React.useMemo(() => ({ invoke, reset, state }), [invoke, reset, state]);
}

/**
 * Easy data fetching in hook form! This hook is build on `useFuture` and is designed to make handling data fetching super easy!
 *
 * This hook is ideal for situations where data needs to be retrieved and displayed to the user (ie: get a user's details). The dependencies
 * parameter should contain a list of all values that are used in the async operation. The async operation will be re-triggered whenever one
 * of these values change.
 *
 * @example
 * const UserProfile = ({ userId }) => {
 *      const [userFuture, refetch] = useDataRetrieval(() => fetchUser(userId), [userId]);
 *      return (
 *          <>
 *              {userFuture.isLoading && <Loading />>}
 *              {userFuture.isFinished && JSON.stringify(userFuture.value)}
 *              {userFuture.isFailed && <button onClick={refetch}>Failed to load. Retry?</button>}
 *          </>
 *      )
 * };
 * @example
 * const UserProfile = () => {
 *      const [userIdState, setUserIdState] = useState(1);
 *      const [userFuture, refetch] = useDataRetrieval(() => fetchUser(userIdState), [userIdState]);
 *      setUserIdState(2); // Will trigger rerender and useDataRetrieval will rerun with userIdState=2.
 *
 *      return (
 *          <>
 *              {userFuture.isFinished && JSON.stringify(userFuture.value)} // Render data of user with id=2
 *          </>
 *      )
 * };
 * @param fn - The function to invoke to retrieve the data asynchronously
 * @param [deps] - The dependency list
 * @param [options] - Additional configuration options for the hook
 * @param options.description - description
 * @returns A tuple containing the current state of the data retrieval and a function to allow manually triggering of the data fetching
 */
export function useDataRetrieval<TValue, TError = any>(
    fn: () => Promise<TValue>,
    deps: React.DependencyList = [],
    options: { description?: string } = {},
): [FutureStateWithoutPending<TValue, TError>, () => void] {
    const notifier = useAngularServiceContext('mvNotifier');
    const [state, dispatch] = useFutureStateReducer<TValue, TError>(createLoadingState());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const memoizedCallback = React.useCallback(fn, deps);
    const cachedOptions = React.useMemo(() => ({ description: options.description }), [options.description]);

    /*
    This useEffect is used to refetch the data when one of the dependencies change.
    Previously, once the state is in a finished state, changes to dependencies would not trigger a rerun
    and the only way was to invoke the refetch function returned by useDataRetrieval.
     */
    React.useEffect(() => {
        dispatch({ type: 'START' });
    }, [dispatch, memoizedCallback]);

    React.useEffect(() => {
        let isCancelled = false;
        if (state.isLoading) {
            memoizedCallback()
                .then(data => {
                    if (!isCancelled) {
                        dispatch({ payload: data, type: 'FINISHED' });
                    }
                })
                .catch(error => {
                    if (!isCancelled) {
                        dispatch({ payload: error, type: 'FAILED' });
                        if (cachedOptions.description) {
                            notifier.unexpectedErrorWithData(
                                t('COMMON.FAILED_TO', { description: cachedOptions.description }),
                                error,
                            );
                        }
                    }
                });
        }
        return () => {
            isCancelled = true;
        };
    }, [memoizedCallback, state, dispatch, cachedOptions, notifier]);
    return [
        state as FutureStateWithoutPending<TValue, TError>,
        React.useCallback(() => dispatch({ type: 'START' }), [dispatch]),
    ];
}

/**
 * Simple wrapper around useDataRetrieval for easier integration with API endpoints.
 *
 * @param description - The description of the data retrieval. This is used for error handling and reporting.
 * @param apiRoute - The API route to make the request to
 * @param requestData - Any data to send to the API endpoint
 * @returns DataRetrievalHookResult
 * @see useDataRetrieval
 */

export function useFetchFromApiEndpoint<
    TResponse extends ObjectOrVoid,
    TParams extends ObjectOrVoid,
    TBody extends ObjectOrVoid,
    TQuery extends ObjectOrVoid,
>(
    description: string,
    apiRoute: ApiRoute<TResponse, TParams, TBody, TQuery>,
    requestData: ApiRequestData<TParams, TBody, TQuery>,
) {
    const http = useAngularServiceContext('$http');
    const options = { description };
    return useDataRetrieval<TResponse>(
        async () => Promise.resolve(invokeApiRoute(http, apiRoute, requestData)),
        [apiRoute, http, requestData],
        options,
    );
}
