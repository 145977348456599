import * as React from 'react';
import { PublishableService, ReportableService } from '@deltasierra/integrations/integration-types';
import { OneWayBinding } from '../../common/angularData';
import AllIcon from '../../../img/logos/platform-all.svg';
import CampaignMonitorIcon from '../../../img/logos/campaignMonitor.svg';
import ClubReadyIcon from '../../../img/logos/clubReady.svg';
import FacebookIcon from '../../../img/logos/facebook.svg';
import FacebookMarketingIcon from '../../../img/logos/facebookMarketing.svg';
import FitwareIcon from '../../../img/logos/fitware.svg';
import Four51Icon from '../../../img/logos/four51.svg';
import GoogleIcon from '../../../img/logos/google.svg';
import GoogleAdWordsIcon from '../../../img/logos/googleAdWords.svg';
import GoogleMyBusinessIcon from '../../../img/logos/googleMyBusiness.svg';
import InstagramIcon from '../../../img/logos/instagram.svg';
import LinkedInIcon from '../../../img/logos/linkedIn.svg';
import MailchimpIcon from '../../../img/logos/mailchimp.svg';
import PrintIcon from '../../../img/logos/print.svg';
import SendGridIcon from '../../../img/logos/sendGrid.svg';
import TikTokIcon from '../../../img/logos/tiktok.svg';
import TvIcon from '../../../img/logos/tv.svg';
import TwitterIcon from '../../../img/logos/twitter.svg';
import VideoIcon from '../../../img/logos/video.svg';
import WebsiteIcon from '../../../img/logos/website.svg';
import { withAngularIntegration } from '../../common/componentUtils/reactComponentRegistration';

type IconsTypeKeys = Exclude<
    PublishableService | ReportableService | 'all' | 'tiktok' | 'tv' | 'video' | 'website',
    'local'
>;

type IconsType = {
    [key in IconsTypeKeys]: any;
};

const icons: IconsType = {
    all: AllIcon,
    campaignMonitor: CampaignMonitorIcon,
    clubReady: ClubReadyIcon,
    facebook: FacebookIcon,
    facebookMarketing: FacebookMarketingIcon,
    fitware: FitwareIcon,
    four51: Four51Icon,
    google: GoogleIcon,
    googleAdWords: GoogleAdWordsIcon,
    googleMyBusiness: GoogleMyBusinessIcon,
    instagram: InstagramIcon,
    linkedIn: LinkedInIcon,
    mailchimp: MailchimpIcon,
    print: PrintIcon,
    sendgrid: SendGridIcon,
    tiktok: TikTokIcon,
    tv: TvIcon,
    twitter: TwitterIcon,
    video: VideoIcon,
    website: WebsiteIcon,
};

export const getIconForPlatform = (name: string): any => icons[name] ?? '';

export type PlatformLogoProps = {
    fallbackFaClassName?: string;
    name: string;
    size?: number;
};

export const PlatformLogo: React.FC<PlatformLogoProps> = props =>
    props.name in icons ? (
        <img
            alt={props.name}
            height={`${props.size || '26'}px`}
            src={icons[props.name]}
            width={`${props.size || '26'}px`}
        />
    ) : (
        <span className={props.fallbackFaClassName} />
    );
PlatformLogo.displayName = 'PlatformLogo';

export const PlatformLogoWithAngular = withAngularIntegration(PlatformLogo, 'platformLogo', {
    fallbackFaClassName: OneWayBinding,
    name: OneWayBinding,
    size: OneWayBinding,
});
