import { AssignedLocation, ExportPlatforms, InstagramDirectPublishFormData, LocationIdHierarchy } from '@deltasierra/shared';
import * as React from 'react';
import { OneWayBinding } from '../../../common/angularData';
import { withAngularIntegration } from '../../../common/componentUtils/reactComponentRegistration';
import { MultipleLocationMultiImagePublish } from '../multipleLocationPublish/MultipleLocationMultiImagePublish';
import { MvContentBuilderPublishCtrl } from '../mvContentBuilderPublishCtrl';
import { PublishResult } from '../publishResult';
import { PublishFinishCallback } from '../types';
import { PublishToInstagramDirectForm } from './PublishToInstagramDirectForm';
import { useInstagramImagePublishCallback } from './useInstagramImagePublishCallback';

export type PublishImageToInstagramDirectProps = {
    location: AssignedLocation;
    locations: LocationIdHierarchy[];
    publishController: MvContentBuilderPublishCtrl;
    onCancel: () => void;
    onFinish: (data: PublishResult) => void;
};

const PublishImageToInstagramDirect: React.FC<PublishImageToInstagramDirectProps> = props => {
    const [isPublishing, setIsPublishing] = React.useState(false);
    const [publishData, setPublishData] = React.useState<InstagramDirectPublishFormData | null>(null);

    const publishMultiImageCallback = useInstagramImagePublishCallback(props.publishController);

    const onPublish = (data: InstagramDirectPublishFormData) => {
        setIsPublishing(true);
        setPublishData(data);
    };

    const finishCallback: PublishFinishCallback = () => {
        const scheduledTime = (publishData && publishData.scheduledTime) || null;
        props.onFinish(new PublishResult(ExportPlatforms.InstagramDirect, scheduledTime));
    };

    return (
        <>
            {!isPublishing && <PublishToInstagramDirectForm {...props} onPublish={onPublish} />}
            {isPublishing && publishData && (
                <MultipleLocationMultiImagePublish
                    commonData={publishData}
                    contentBuilder={props.publishController.contentBuilder}
                    locations={props.locations}
                    originalLocation={props.location}
                    publishCtrl={props.publishController}
                    publishMultiImageCallback={publishMultiImageCallback}
                    onCancel={props.onCancel}
                    onFinish={finishCallback}
                />
            )}
        </>
    );
};
PublishImageToInstagramDirect.displayName = 'PublishImageToInstagramDirect';

export default withAngularIntegration(PublishImageToInstagramDirect, 'publishImageToInstagramDirect', {
    location: OneWayBinding,
    locations: OneWayBinding,
    onCancel: OneWayBinding,
    onFinish: OneWayBinding,
    publishController: OneWayBinding,
});
