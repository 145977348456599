import * as React from 'react';
import { Loading } from '@deltasierra/components';
import { withAngularIntegration } from '../../../common/componentUtils/reactComponentRegistration';
import { useAppFrontendRouter } from '../../../common/hooks/useAppFrontendRouter';

function AppFrontendLink(): JSX.Element {
    const router = useAppFrontendRouter();

    if (!router) {
        return <Loading />;
    }

    return (
        <a
            href={router.get('')}
            style={{
                display: 'block',
                padding: '3px 20px',
                color: 'inherit',
            }}
        >
            <i className="fa fa-fw fa-external-link-alt" />
            <span>{'app-frontend'}</span>
        </a>
    );
}
AppFrontendLink.displayName = 'AppFrontendLink';
export const AppFrontendLinkAngular = withAngularIntegration(AppFrontendLink, 'appFrontendLink', {});
