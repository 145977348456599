import { DSTypography, InfoTooltip, Translate } from '@deltasierra/components';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import React from 'react';
import styled from 'styled-components';
import { LCO_FRAGMENT_FOR_LOCATION_GROUP_THUMBNAIL } from './LocationGroupThumbnail.fragments';
import { useLocationGroupBuilderTemplateGalleryLink } from './useLocationGroupBuilderTemplateGalleryLink';
import { LCOFragmentForLocationGroupThumbnail } from './__graphqlTypes/LCOFragmentForLocationGroupThumbnail';

const StyledTitleCaption = styled(DSTypography).attrs({
    variant: 'body1',
})`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    /* Force black even if the parent is a link (it makes it default to blue) */
    color: ${props => props.theme.palette.text.primary};
`;

const StyledImageWrapper = styled.div`
    position: relative;

    width: 100%;
    aspect-ratio: 3/2;

    display: flex;
    align-items: center;
    justify-content: center;

    border: 1px solid #aeb7c7;
    border-radius: 8px;

    overflow: hidden;

    transition: border 0.3s ease;
`;

const StyledImage = styled.img`
    height: auto;
    width: 100%;
    object-fit: contain;
    max-height: 110%;
`;

const StyledImageOverlay = styled.div`
    position: absolute;

    height: 100%;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: ${props => props.theme.palette.background.paper};

    opacity: 0;

    transition: opacity 0.3s ease;
`;

const StyledTitleWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-top: 8px;
`;

const StyledLinkWrapper = styled.a`
    &:hover {
        ${StyledTitleCaption} {
            color: ${props => props.theme.palette.primary.main};
            font-weight: bold;
        }
        ${StyledImageWrapper} {
            border-color: ${props => props.theme.palette.primary.main};
        }

        ${StyledImageOverlay} {
            opacity: 0.95;
        }
    }
`;

const StyledWrapper = styled.div`
    display: inline-block;
    width: 100%;
`;

export type LocationGroupThumbnailProps = {
    locationCategoryOption: LCOFragmentForLocationGroupThumbnail;
};

export function LocationGroupThumbnail({ locationCategoryOption }: LocationGroupThumbnailProps): JSX.Element {
    const linkUrl = useLocationGroupBuilderTemplateGalleryLink(locationCategoryOption);

    const children = (
        <>
            <StyledImageWrapper>
                <StyledImage
                    hidden={!locationCategoryOption.logo?.url}
                    src={locationCategoryOption.logo?.url ?? undefined}
                />
                {linkUrl && (
                    <StyledImageOverlay>
                        <Translate keyId="MENU.BUILD" /> <ChevronRightIcon />
                    </StyledImageOverlay>
                )}
            </StyledImageWrapper>
            <StyledTitleWrapper>
                <StyledTitleCaption role="caption">{locationCategoryOption.label}</StyledTitleCaption>
                {!linkUrl && (
                    <InfoTooltip role="alert">
                        <Translate keyId="AGENCY.CLIENT.LOCATIONS.LOCATION_GROUPS_NO_CATEGORIES_TOOLTIP" />
                    </InfoTooltip>
                )}
            </StyledTitleWrapper>
        </>
    );

    return (
        <StyledWrapper>
            {linkUrl ? <StyledLinkWrapper href={linkUrl}>{children}</StyledLinkWrapper> : children}
        </StyledWrapper>
    );
}
LocationGroupThumbnail.displayName = 'LocationGroupThumbnail';
LocationGroupThumbnail.fragments = { LCO_FRAGMENT_FOR_LOCATION_GROUP_THUMBNAIL };
