/// <reference path="../../../typings/browser.d.ts" />

import { MvNotifier } from '../common/mvNotifier';
import { InteractionUtils } from '../common/interactionUtils';
import { I18nService } from '../i18n/i18nService';
import { TranslatableError } from '../common/exceptions';
import { MvAuth } from './mvAuth';
import ILocationService = angular.ILocationService;

export class PasswordsDontMatch extends TranslatableError {
    public constructor() {
        super('LOGIN.PASSWORDS_DONT_MATCH');
    }
}

export class PasswordResetFailed extends TranslatableError {
    public constructor() {
        super('LOGIN.PASSWORD_RESET_FAILED');
    }
}

export class MvResetPasswordCtrl {
    public static SID = 'mvResetPasswordCtrl';

    public static readonly $inject: string[] = [
        '$location',
        'mvNotifier',
        '$q',
        MvAuth.SID,
        InteractionUtils.SID,
        I18nService.SID,
    ];

    public token: string;

    public username: string;

    public password: string | null = null;

    public passwordAgain: string | null = null;

    public resetPassword = this.interactionUtils.createFuture(
        () => this.i18n.text.login.resetPassword(),
        () => {
            const password = this.password;
            const passwordAgain = this.passwordAgain;
            this.password = null;
            this.passwordAgain = null;

            if (!password || !passwordAgain) {
                return this.$q.resolve();
            }

            if (password.length < 8) {
                this.mvNotifier.expectedError(this.i18n.text.login.passwordTooShort({ count: 8 }));
                return this.$q.resolve();
            }

            if (password !== passwordAgain) {
                this.mvNotifier.expectedError(this.i18n.text.login.passwordsDontMatch());
                return this.$q.resolve();
            }

            return this.mvAuth.resetPassword(this.token, this.username, password).then(
                (result: { success: boolean }) => {
                    if (!result.success) {
                        this.mvNotifier.unexpectedError(this.i18n.text.login.passwordResetFailed());
                        return;
                    }

                    void this.mvAuth.logoutUser(false);
                    this.$location.url('/login').search({ username: this.username });
                    this.mvNotifier.notify(this.i18n.text.login.passwordResetPleaseLogin());
                },
                err => {
                    if (err.data.reason === this.i18n.text.errors.requestTokenErrors.tokenInvalid()) {
                        this.mvNotifier.unexpectedError(this.i18n.text.login.problemWithInviteLink());
                    } else {
                        this.mvNotifier.unexpectedError(this.i18n.text.login.passwordResetFailed());
                    }
                },
            );
        },
    );

    // eslint-disable-next-line max-params
    public constructor(
        private $location: ILocationService,
        private mvNotifier: MvNotifier,
        private $q: ng.IQService,
        private mvAuth: MvAuth,
        private interactionUtils: InteractionUtils,
        private i18n: I18nService,
    ) {
        const params = $location.search();
        this.token = params.token;
        this.username = params.username;
        if (!this.token || !this.username) {
            $location.url('/').search({});
            this.mvNotifier.unexpectedError(i18n.text.login.problemWithLink());
        }
    }
}

angular.module('app').controller(MvResetPasswordCtrl.SID, MvResetPasswordCtrl);
