import { gql } from '@apollo/client';
import { DSBreadcrumbs, DSButton, DSTypography } from '@deltasierra/components';
import { t } from '@deltasierra/shared';
import * as React from 'react';
import { AssetOrCollectionFragmentForCollectionBreadcrumbs } from './__graphqlTypes/AssetOrCollectionFragmentForCollectionBreadcrumbs';

const fragments = {
    ASSET_OR_COLLECTION_FRAGMENT_FOR_COLLECTION_BREADCRUMBS: gql`
        fragment AssetOrCollectionFragmentForCollectionBreadcrumbs on CollectionOrAssetOrNotFoundOrPermissionError {
            __typename
            ... on AssetFolder {
                id
                title
                collection {
                    id
                    title
                }
                path {
                    id
                    title
                }
            }
            ... on Collection {
                id
                title
            }
        }
    `,
};

export interface CollectionBreadcrumbsProps {
    assetOrCollection: AssetOrCollectionFragmentForCollectionBreadcrumbs;
    onCrumbClicked?: (id: string | null) => void;
}

export function CollectionBreadcrumbs(props: CollectionBreadcrumbsProps): JSX.Element {
    if (
        !props.assetOrCollection ||
        (props.assetOrCollection.__typename !== 'AssetFolder' && props.assetOrCollection.__typename !== 'Collection')
    ) {
        return <></>;
    }
    const chooseCollection = { id: null, title: t('ASSET_LIBRARY.CHOOSE_COLLECTION') };
    const breadCrumbList =
        props.assetOrCollection.__typename === 'Collection'
            ? [chooseCollection]
            : [
                  chooseCollection,
                  extractIdAndTitle(props.assetOrCollection.collection),
                  ...props.assetOrCollection.path.map(extractIdAndTitle),
              ];

    return (
        <DSBreadcrumbs aria-label="filepath">
            {breadCrumbList.map(breadcrumb => (
                <DSButton
                    color="primary"
                    key={breadcrumb.id}
                    size="small"
                    variant="text"
                    onClick={() => props.onCrumbClicked?.(breadcrumb.id)}
                >
                    {breadcrumb.title}
                </DSButton>
            ))}
            <DSTypography color="textPrimary" variant="body2">
                {props.assetOrCollection.title}
            </DSTypography>
        </DSBreadcrumbs>
    );
}
CollectionBreadcrumbs.displayName = 'CollectionBreadcrumbs';
CollectionBreadcrumbs.fragments = fragments;

interface IdAndTitle {
    id: string | null;
    title: string;
}

function extractIdAndTitle<T extends IdAndTitle>(el: T): IdAndTitle {
    return { id: el.id, title: el.title };
}
