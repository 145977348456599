import * as React from 'react';
import { Dropdown } from '@deltasierra/components';
import styled from 'styled-components';
import { isNullOrUndefined } from '@deltasierra/type-utilities';
import { useAngularServiceContext } from '../../../common/componentUtils/angularServiceContexts';
import { withAngularIntegration } from '../../../common/componentUtils/reactComponentRegistration';

const StyledWrapper = styled.div`
    display: block;

    @media (min-width: 992px) {
        float: none;
        display: inline-block;
        vertical-align: middle;
    }
`;

const StyledDropdownToggle = styled.button`
    width: 100%;

    background: none;

    font-family: GalanoGrotesque, sans-serif;
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: #000000;
    text-align: left;

    outline: none;
    border: none;

    padding-right: 10px;
    padding-left: 10px;

    padding-top: 15px;
    padding-bottom: 15px;

    @media (max-width: 991px) {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    &:hover,
    &:active,
    &:focus {
        color: #000000;
        span {
            border-bottom: 2px solid #ff4f4c;
        }
    }

    @media (max-width: 768px) {
        img {
            margin-right: 8px;
        }
    }
`;

export const LocaleDropdown: React.FunctionComponent = () => {
    const i18nService = useAngularServiceContext('I18nService');
    const localeCode: string = i18nService.getLocaleCode();
    const [currentLocale, setCurrentLocale] = React.useState(
        i18nService.getLocales().find(locale => locale.code === localeCode),
    );

    const allLocales = i18nService.getLocales();
    const [localeList, updateLocaleList] = React.useState(allLocales);

    function onFilterChange(filterValue: string) {
        if (isNullOrUndefined(filterValue) || filterValue.trim() === '') {
            updateLocaleList(allLocales);
            return;
        }
        const lowercaseFilterValue = filterValue.toLowerCase();
        updateLocaleList(allLocales.filter(locale => locale.title.toLowerCase().includes(lowercaseFilterValue)));
    }

    if (!currentLocale) {
        throw new Error('Could not load internationalization');
    }

    return (
        <StyledWrapper>
            <Dropdown
                filterable
                getOptionTemplate={locale => (
                    <>
                        <img className="locale-flag" height="14px" src={`/img/locales/${locale.code}.png`} />
                        {locale.title}
                    </>
                )}
                getValue={locale => locale.title}
                items={localeList}
                menuStyle={{ maxHeight: '25vh', overflowY: 'scroll', width: '100%' }}
                render={({ requestToggle }) => (
                    <StyledDropdownToggle onClick={() => requestToggle()}>
                        <img className="locale-flag" height="16px" src={`/img/locales/${currentLocale.code}.png`} />
                        <span>{currentLocale.title}</span>
                    </StyledDropdownToggle>
                )}
                value={currentLocale}
                onFilterChange={onFilterChange}
                onSelect={locale => {
                    setCurrentLocale(locale);
                    return i18nService.setLocaleCode(locale.code);
                }}
            />
        </StyledWrapper>
    );
};

LocaleDropdown.displayName = 'LocaleDropdown';

export const LocaleDropdownWithAngular = withAngularIntegration(LocaleDropdown, 'localeDropdown', {});
