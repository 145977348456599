/// <reference path="../../../typings/browser.d.ts" />
import { MvIdentity } from '../account/mvIdentity';
import { IKookies, TableDisplayCustomizationCookieData, TableDisplayCustomizationCookies } from './angularData';
import IScope = angular.IScope;

function convertArrayToMap<T extends { name: string }>(values: T[]) {
    const map: { [key: string]: T } = {};
    for (const value of values) {
        const key = value.name; // Could externalise this to a callback, to make this function more generic
        map[key] = value;
    }
    return map;
}

export const tableServiceSID = 'tableService';

const $inject = ['$kookies', MvIdentity.SID];

interface IColumnConfig {
    readonly name: string;
    readonly displayName: () => string;
    visible: boolean;
    readonly managerOnly: boolean;

    isAccessible(): boolean;

    isVisible(): boolean;
}

export interface IFilterConfig<TRow> {
    readonly name: string;
    readonly displayName: string;
    active: boolean;
    readonly filter: (value: TRow, index: number) => boolean;
}

export interface IDisplayCustomization<TRow> {
    columnMap: { [key: string]: IColumnConfig };
    filterMap: { [key: string]: IFilterConfig<TRow> };
    readonly cookieName: TableDisplayCustomizationCookies;
    readonly columns: IColumnConfig[];
    readonly filters: Array<IFilterConfig<TRow>>;

    getColumn(name: string): IColumnConfig;

    getFilter(name: string): IFilterConfig<TRow>;

    numberOfVisibleColumns(): number;

    filter(value: TRow, index: number): boolean;

    save(): void;

    load(): void;

    startWatching(scope: IScope, expression: string | ((scope: IScope) => any)): void;
}

export interface TableService {
    ColumnConfig: new (
        name: string,
        displayName: () => string,
        visible: boolean,
        managerOnly: boolean,
    ) => IColumnConfig;
    FilterConfig: new <TRow>(
        name: string,
        displayName: string,
        active: boolean,
        filter: (value: TRow, index: number) => boolean,
    ) => IFilterConfig<TRow>;
    DisplayCustomization: new <TRow>(
        cookieName: TableDisplayCustomizationCookies,
        columns: IColumnConfig[],
        filters: Array<IFilterConfig<TRow>>,
    ) => IDisplayCustomization<TRow>;
}

function tableServiceFactory($kookies: IKookies, mvIdentity: MvIdentity): TableService {
    class ColumnConfig implements IColumnConfig {
        public constructor(
            public readonly name: string,
            public readonly displayName: () => string,
            public visible: boolean,
            public readonly managerOnly: boolean,
        ) {}

        public isAccessible(): boolean {
            return !this.managerOnly || mvIdentity.isManager();
        }

        public isVisible(): boolean {
            return this.isAccessible() && this.visible;
        }
    }

    class FilterConfig<TRow> implements IFilterConfig<TRow> {
        public constructor(
            public readonly name: string,
            public readonly displayName: string,
            public active: boolean,
            public readonly filter: (value: TRow, index: number) => boolean,
        ) {}
    }

    class DisplayCustomization<TRow> implements IDisplayCustomization<TRow> {
        public readonly columnMap: { [key: string]: ColumnConfig };

        public readonly filterMap: { [key: string]: FilterConfig<TRow> };

        public constructor(
            public readonly cookieName: TableDisplayCustomizationCookies,
            public readonly columns: ColumnConfig[],
            public readonly filters: Array<FilterConfig<TRow>>,
        ) {
            this.columnMap = convertArrayToMap(this.columns);
            this.filterMap = convertArrayToMap(this.filters);
        }

        public getColumn(name: string) {
            return this.columnMap[name];
        }

        public getFilter(name: string) {
            return this.filterMap[name];
        }

        public numberOfVisibleColumns(): number {
            let visibleColumns = 0;
            for (const column of this.columns) {
                if (column.isVisible()) {
                    visibleColumns++;
                }
            }
            return visibleColumns;
        }

        public filter(value: TRow, index: number) {
            for (const filterConfig of this.filters) {
                if (!filterConfig.filter(value, index)) {
                    return false;
                }
            }
            return true;
        }

        public save() {
            const columnValues: TableDisplayCustomizationCookieData['columns'] = {};
            const filterValues: TableDisplayCustomizationCookieData['filters'] = {};
            for (const column of this.columns) {
                columnValues[column.name] = column.visible;
            }
            for (const filter of this.filters) {
                filterValues[filter.name] = filter.active;
            }
            const dataToSave = {
                columns: columnValues,
                filters: filterValues,
            };
            $kookies.set(this.cookieName, dataToSave, { path: '/', secure: true });
        }

        public load() {
            const savedData = $kookies.get(this.cookieName);
            if (savedData) {
                for (const column of this.columns) {
                    const value = savedData.columns[column.name];
                    if (value !== undefined) {
                        column.visible = value;
                    }
                }
                for (const filter of this.filters) {
                    const value = savedData.filters[filter.name];
                    if (value !== undefined) {
                        filter.active = value;
                    }
                }
            }
        }

        public startWatching(scope: IScope, expression: string | ((scope: IScope) => any)): void {
            scope.$watch(
                expression as string,
                () => {
                    this.save();
                },
                true,
            );
        }
    }

    return {
        ColumnConfig,
        DisplayCustomization,
        FilterConfig,
    };
}

angular.module('app').service(tableServiceSID, [...$inject, tableServiceFactory]);
