/* eslint-disable react/display-name */
import { facebookPermalink, FacebookPostStatsEntry } from '@deltasierra/shared';
import * as React from 'react';
import { useAngularServiceContext } from '../../common/componentUtils/angularServiceContexts';
import { FileTypeIcon } from '../../common/FileTypeIcon';
import { useDataRetrieval } from '../../common/futures';
import { Translate } from '../../directives/Translate';

export type TopFacebookPostsProps = {
    posts: FacebookPostStatsEntry[];
    limit?: number;
    title: string;
};

export const TopFacebookPosts: React.FunctionComponent<TopFacebookPostsProps> = ({ limit, posts, title }) => (
    <>
        {posts.slice(0, limit).map((post, index) => (
            <div className="row facebook-top-posts-row" key={post.postId}>
                <div className="col-sm-5 text-center">
                    <PostPicture post={post} />
                </div>
                <div className="col-sm-7">
                    <div className="top-post-h">
                        <span>
                            {index === 0 ? <Translate keyId="COMMON.MOST" /> : `#${index + 1}`}
                            &nbsp;
                        </span>
                        <span className="text-lowercase">{title}</span>
                    </div>
                    <div>{post.locationTitle}</div>
                    <PostTypeIcon post={post} />
                    <div className="text-lowercase">
                        <Translate
                            keyId="REPORT.TOTAL_REACH_VS_TOTAL_PAID"
                            options={{ amount: post.impressionsUnique, paidAmount: post.impressionsPaidUnique }}
                        />
                    </div>
                    <div className="text-lowercase">
                        {post.consumptionsUnique} <Translate keyId="REPORT.CLICKS" />
                    </div>
                    <div className="text-lowercase">
                        {post.positiveFeedback || post.storyAddsUnique || 0} <Translate keyId="REPORT.ENGAGED_USERS" />
                    </div>
                </div>
            </div>
        ))}
    </>
);

type PostTypeIconProps = {
    post: FacebookPostStatsEntry;
};

const PostTypeIcon: React.FunctionComponent<PostTypeIconProps> = ({ post }) => (
    <>
        {(post.type === 'video' && (
            <div className="top-post-type-icon">
                <i className="icon-space fa fa-film" />
                <Translate keyId="COMMON.VIDEO" />
            </div>
        )) ||
            (post.type === 'photo' && (
                <div className="top-post-type-icon">
                    <i className="icon-space fa fa-image" />
                    <Translate keyId="COMMON.IMAGE" />
                </div>
            )) ||
            (!post.picture && (
                <div className="top-post-type-icon">
                    <i className="icon-space fa fa-comment" />
                    <Translate keyId="COMMON.TEXT" />
                </div>
            ))}
    </>
);

type PostPictureProps = {
    post: FacebookPostStatsEntry;
};

const PostPicture: React.FunctionComponent<PostPictureProps> = ({ post }) => {
    const $http = useAngularServiceContext('$http');
    const getFacebookPostPermalink = () => {
        const idSplit = post.postId.split('_');
        const page = idSplit[0];
        const postId = idSplit[1];
        return facebookPermalink(page, postId);
    };

    const [pictureResult] = useDataRetrieval<string>(async () => {
        const url = `/api/reporting/facebook/get-post-picture?postId=${post.postId}&locationId=${post.locationId}`;
        const { data } = await $http.get<string>(url);
        return data!;
    }, [$http, post]);

    const imageSrc = pictureResult.isFinished ? pictureResult.value : post.picture;
    const assetType =
        (post.type === 'video' && 'video/avi') || ((post.type === 'photo' || imageSrc) && 'image/jpeg') || 'text/plain';

    return (
        <a href={getFacebookPostPermalink()} rel="noreferrer" target="_blank">
            {imageSrc ? (
                <img alt={post.pageName} className="img-responsive" src={imageSrc} />
            ) : (
                <FileTypeIcon alt={post.pageName} mimeType={assetType} style={{ padding: '5px' }} />
            )}
        </a>
    );
};
