/// <reference path="../../../../typings/browser.d.ts" />
import { Section } from '@deltasierra/shared';
import { ITimeoutService } from 'angular';
import uuid from 'uuid';
import {
    $compileSID,
    $timeoutSID,
    OneWayBinding,
    OptionalOneWayBinding,
    RestrictToAttribute,
    TwoWayBinding,
} from '../../common/angularData';
import { TextSubstitutionService } from '../common/textSubstitutionService';
import { EmailBuilder } from './emailBuilder';
import IDirective = angular.IDirective;
import ICompileService = angular.ICompileService;
import IScope = angular.IScope;

export interface EmailTemplateIframeScope extends IScope {
    emailBuilder: EmailBuilder;
    isPublishing: boolean;
    highlightingEnabled: boolean;
    showAllSections?: boolean;

    // These functions are called from the templates.
    // DO NOT RENAME THEM OR CHANGE THE SIGNATURE IN ANY WAY! Doing so will break existing e-mail templates.
    getSectionById(id: number): Section | never;
    substituteText(text: string): string;
    processBoundHtml(html: string): string;
    processSimpleText(text: string): string;
    getSelectionStyle(sectionId: number): SelectionStyle;
    isSectionVisible(sectionId: number): boolean;

    onSectionHoverEnter(sectionId: number): void;
    onSectionHoverLeave(sectionId: number): void;
    onSectionClick(sectionId: number): void;
}

export const emailTemplateIframeSID = 'emailTemplateIframe';

export const emailTemplateIframeConfig: ng.IDirective<ng.IScope> = {
    restrict: RestrictToAttribute,
    scope: {
        emailBuilder: TwoWayBinding,
        highlightingEnabled: OneWayBinding,
        isPublishing: OneWayBinding,
        showAllSections: OptionalOneWayBinding,
    },
};

interface SelectionStyle {
    '-webkit-box-shadow'?: string;
    'box-shadow'?: string;
    cursor?: string;
    display?: 'none';
    'mso-hide'?: 'all';
}

angular.module('app').directive(emailTemplateIframeSID, [
    $compileSID,
    TextSubstitutionService.SID,
    $timeoutSID,

    function emailTemplateIframeDirective(
        $compile: ICompileService,
        textSubstitutionService: TextSubstitutionService,
        $timeout: ITimeoutService,
    ): IDirective<EmailTemplateIframeScope> {
        return {
            ...emailTemplateIframeConfig,

            link(scope: EmailTemplateIframeScope, element) {
                let hoveredSectionId: number | undefined;
                const domParser = new DOMParser();
                const iframeElement: HTMLIFrameElement = element[0] as HTMLIFrameElement;

                function renderTemplate() {
                    void $timeout(() => {
                        function renderInternal() {
                            if (iframeElement.contentWindow) {
                                const outputDoc = iframeElement.contentWindow.document;
                                const template = scope.emailBuilder.document.templateHtml ?? '';
                                outputDoc.open();
                                outputDoc.write(addLinkPreventionScriptToTemplate(template));
                                outputDoc.close();
                                const render = $compile(outputDoc);
                                render(scope);
                            }
                        }

                        renderInternal();
                    });
                }
                iframeElement.addEventListener('load', function handleLoad() {
                    renderTemplate();
                    iframeElement.removeEventListener('load', handleLoad);
                });

                (window as any).renderTemplate = renderTemplate;

                function isValidEditableSection(sectionId: number): boolean {
                    return (
                        scope.emailBuilder.advancedMode || scope.emailBuilder.hasAnyEditableFieldForSection(sectionId)
                    );
                }

                /**
                 * Insert some JS into the template to prevent links opening in the iframe (DS-3040).
                 *
                 * @param template - The template to inject the script in to
                 * @returns The template with the injected script
                 */
                function addLinkPreventionScriptToTemplate(template: string): string {
                    const doc = domParser.parseFromString(template, 'text/html');
                    /**
                     * DS-8940 - This bit of code prevents clicks in the time before the 'load' event is called.
                     * If you click on something too early, before the click prevention handler is registered
                     * it could navigate and cause a cross origin issue.
                     */
                    const styleTag = document.createElement('style');
                    styleTag.textContent = `
                        * {
                            pointer-events: none;
                        }
                    `;
                    const styleTagId = `click-remover-${uuid.v4()}`;
                    styleTag.id = styleTagId;
                    // Append the style tag to the head
                    doc.head.appendChild(styleTag);

                    const script = document.createElement('script');
                    // language=JavaScript
                    script.textContent = `
                        /*
                         * Event delegation in native JS.
                         * @see http://youmightnotneedjquery.com/#delegate
                         */
                        window.addEventListener('load', function () {
                            document.addEventListener('click', function(e) {
                                // loop parent nodes from the target to the delegation node
                                for (var target = e.target; target && target !== this; target = target.parentNode) {
                                    if (target.hasAttribute('href') || target.hasAttribute('ng-href')) {
                                        e.preventDefault();
                                        break;
                                    }
                                }
                            }, false);
                            document.querySelector('style[id="${styleTagId}"]').remove();
                        });
                    `;
                    // Append the script to the body
                    doc.body.appendChild(script);
                    return doc.documentElement.outerHTML;
                }

                scope.$watch(() => scope.emailBuilder.document.templateHtml, renderTemplate);

                // --- Start methods consumed by template HTML ---
                scope.getSectionById = function getSectionById(id: number) {
                    return scope.emailBuilder.getSectionById(id);
                };

                scope.substituteText = function substituteText(text) {
                    // Should maybe move this method into the emailBuilder
                    return textSubstitutionService.substitute(scope.emailBuilder.textSubstitutionValues, text);
                };

                scope.processBoundHtml = function processBoundHtml(html) {
                    return scope.substituteText(html);
                };

                scope.processSimpleText = function processSimpleText(text) {
                    return scope.substituteText(text);
                };

                /**
                 * Should have been named getSectionStyle, but oh well!
                 *
                 * @param sectionId - The ID of the section
                 * @returns The styles for the given section
                 */
                scope.getSelectionStyle = function getSelectionStyle(sectionId: number): SelectionStyle {
                    const section = scope.getSectionById(sectionId);
                    let style: SelectionStyle = {};
                    if (!scope.showAllSections && !section.visible) {
                        // This is to support legacy templates which are missing the "ng-if" statements in the processed HTML.
                        // It possibly does not work in all e-mail clients.
                        style = {
                            ...style,
                            display: 'none',
                            'mso-hide': 'all',
                        };
                    }
                    if (scope.highlightingEnabled && !scope.isPublishing && isValidEditableSection(sectionId)) {
                        if (section === scope.emailBuilder.selectedSection) {
                            style = {
                                ...style,
                                '-webkit-box-shadow':
                                    'inset 0 0 2px 1px rgba(102, 175, 233, .6),0 0 20px 5px rgba(102, 175, 233, .6)',
                                'box-shadow':
                                    'inset 0 0 2px 1px rgba(102, 175, 233, .6),0 0 20px 5px rgba(102, 175, 233, .6)',
                            };
                        } else if (!!hoveredSectionId && hoveredSectionId === sectionId) {
                            style = {
                                ...style,
                                '-webkit-box-shadow':
                                    'inset 0 0 2px 1px rgba(230, 25, 148, .6),0 0 20px 5px rgba(230, 25, 148, .6)',
                                'box-shadow':
                                    'inset 0 0 2px 1px rgba(230, 25, 148, .6),0 0 20px 5px rgba(230, 25, 148, .6)',
                                cursor: 'pointer',
                            };
                        }
                    }
                    return style;
                };

                scope.isSectionVisible = function isSectionVisible(sectionId: number): boolean {
                    return !!scope.showAllSections || scope.getSectionById(sectionId).visible;
                };

                // --- End methods consumed by template HTML ---

                scope.onSectionHoverEnter = function onSectionHoverEnter(sectionId: number): void {
                    hoveredSectionId = sectionId;
                };

                scope.onSectionHoverLeave = function onSectionHoverLeave(sectionId: number): void {
                    if (sectionId === hoveredSectionId) {
                        hoveredSectionId = undefined;
                    }
                };

                scope.onSectionClick = function onSectionClick(sectionId: number): void {
                    if (isValidEditableSection(sectionId)) {
                        const section = scope.getSectionById(sectionId);
                        scope.emailBuilder.selectSection(section);
                    }
                };
            },
        };
    },
]);
