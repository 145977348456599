/// <reference path="../../typings/browser.d.ts" />

// Set $q as the global window promise, so we can use async/await in TS.
(window as any).Promise = angular.injector(['ng']).get('$q');

// Must be an angular issue, make sure global is set
(window as any).global = window;

// Must import angular
import './i18n/config';

import * as Sentry from '@sentry/browser';
import * as SentryIntegrations from '@sentry/integrations';
import * as SentryTracing from '@sentry/tracing';

import { Settings } from 'luxon';
Settings.throwOnInvalid = true;
declare module 'luxon' {
    interface TSSettings {
        throwOnInvalid: true;
    }
}

Sentry.init({
    dsn: 'https://486cce50208b43c285b72552216055d1@o87323.ingest.sentry.io/190098',
    enabled: window.environmentType === 'production',
    environment: window.environmentName,
    integrations: [
        new SentryTracing.Integrations.BrowserTracing(),
        new SentryIntegrations.Angular({ angular }),
        new SentryIntegrations.CaptureConsole(),
    ],
    /*
     * Percentage of Sentry traces to sample and capture.
     * @see https://docs.sentry.io/product/sentry-basics/tracing/distributed-tracing/#data-sampling
     */
    tracesSampleRate: 0.01,
});

import './appModule';
import './appConfig';
import './appRun';
