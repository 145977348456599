/* eslint-disable camelcase */
import { isNotNullOrUndefined } from '@deltasierra/type-utilities';
import { relayConnectionToArray } from '../../../../../../../../../../graphql/utils';
import { GetClubReadyListSummaryRequestQuery_ClubReadyListSummaryRequest_ClubReadyListSummaryRequest_results as ClubReadyListSummaryRequestResult } from '../__graphqlTypes/GetClubReadyListSummaryRequestQuery';

type ListTotalsBase = {
    userCount: number;
};

type ActiveListTotals = ListTotalsBase & {
    suppressedFilteredUserCount: number;
    suppressedOptedOutUserCount: number;
    suppressedDuplicateUserCount: number;
    suppressedInvalidEmailUserCount: number;
};
type InactiveListTotals = ListTotalsBase & {
    suppressedFilteredUserCount: number;
    suppressedOptedOutUserCount: number;
    suppressedDuplicateUserCount: number;
    suppressedInvalidEmailUserCount: number;
};
type ProspectsListTotals = ListTotalsBase & {
    suppressedFilteredUserCount: number;
    suppressedOptedOutUserCount: number;
    suppressedDuplicateUserCount: number;
    suppressedInvalidEmailUserCount: number;
    suppressedNotInterestedUserCount: number;
};
type CooledOffProspectsListTotals = ListTotalsBase & {
    suppressedOptedOutUserCount: number;
    suppressedDuplicateUserCount: number;
    suppressedInvalidEmailUserCount: number;
};
type CustomListTotals = ListTotalsBase & {
    suppressedOptedOutUserCount: number;
    suppressedDuplicateUserCount: number;
    suppressedInvalidEmailUserCount: number;
    suppressedNotInterestedUserCount: number;
};

export type ClubReadyListTotals = {
    active: ActiveListTotals;
    inactive: InactiveListTotals;
    prospects: ProspectsListTotals;
    cooledOffProspects: CooledOffProspectsListTotals;
    custom: CustomListTotals;
};

export function getUserCountTotals(results: ClubReadyListSummaryRequestResult): ClubReadyListTotals {
    return relayConnectionToArray(results)
        .map(node => (node.__typename === 'ClubReadyListSummaryResult' && node.summary.__typename === 'ClubReadyLists'
            ? node.summary
            : undefined
        ))
        .filter(isNotNullOrUndefined)
        .reduce(
            // eslint-disable-next-line complexity
            (acc, { active, cooledOffProspects, custom, inactive, prospects }) => ({
                active: {
                    suppressedDuplicateUserCount:
                        acc.active.suppressedDuplicateUserCount + (active?.suppressedDuplicateUserCount ?? 0),
                    suppressedFilteredUserCount:
                        acc.active.suppressedFilteredUserCount + (active?.suppressedFilteredUserCount ?? 0),
                    suppressedInvalidEmailUserCount:
                        acc.active.suppressedInvalidEmailUserCount + (active?.suppressedInvalidEmailUserCount ?? 0),
                    suppressedOptedOutUserCount:
                        acc.active.suppressedOptedOutUserCount + (active?.suppressedOptedOutUserCount ?? 0),
                    userCount: acc.active.userCount + (active?.userCount ?? 0),
                },
                cooledOffProspects: {
                    suppressedDuplicateUserCount:
                        acc.cooledOffProspects.suppressedDuplicateUserCount +
                        (cooledOffProspects?.suppressedDuplicateUserCount ?? 0),
                    suppressedInvalidEmailUserCount:
                        acc.cooledOffProspects.suppressedInvalidEmailUserCount +
                        (cooledOffProspects?.suppressedInvalidEmailUserCount ?? 0),
                    suppressedOptedOutUserCount:
                        acc.cooledOffProspects.suppressedOptedOutUserCount +
                        (cooledOffProspects?.suppressedOptedOutUserCount ?? 0),
                    userCount: acc.cooledOffProspects.userCount + (cooledOffProspects?.userCount ?? 0),
                },
                custom: {
                    suppressedDuplicateUserCount:
                        acc.custom.suppressedDuplicateUserCount + (custom?.suppressedDuplicateUserCount ?? 0),
                    suppressedInvalidEmailUserCount:
                        acc.custom.suppressedInvalidEmailUserCount + (custom?.suppressedInvalidEmailUserCount ?? 0),
                    suppressedNotInterestedUserCount:
                        acc.custom.suppressedNotInterestedUserCount + (custom?.suppressedNotInterestedUserCount ?? 0),
                    suppressedOptedOutUserCount:
                        acc.custom.suppressedOptedOutUserCount + (custom?.suppressedOptedOutUserCount ?? 0),
                    userCount: acc.custom.userCount + (custom?.userCount ?? 0),
                },
                inactive: {
                    suppressedDuplicateUserCount:
                        acc.inactive.suppressedDuplicateUserCount + (inactive?.suppressedDuplicateUserCount ?? 0),
                    suppressedFilteredUserCount:
                        acc.inactive.suppressedFilteredUserCount + (inactive?.suppressedFilteredUserCount ?? 0),
                    suppressedInvalidEmailUserCount:
                        acc.inactive.suppressedInvalidEmailUserCount + (inactive?.suppressedInvalidEmailUserCount ?? 0),
                    suppressedOptedOutUserCount:
                        acc.inactive.suppressedOptedOutUserCount + (inactive?.suppressedOptedOutUserCount ?? 0),
                    userCount: acc.inactive.userCount + (inactive?.userCount ?? 0),
                },
                prospects: {
                    suppressedDuplicateUserCount:
                        acc.prospects.suppressedDuplicateUserCount + (prospects?.suppressedDuplicateUserCount ?? 0),
                    suppressedFilteredUserCount:
                        acc.prospects.suppressedFilteredUserCount + (prospects?.suppressedFilteredUserCount ?? 0),
                    suppressedInvalidEmailUserCount:
                        acc.prospects.suppressedInvalidEmailUserCount +
                        (prospects?.suppressedInvalidEmailUserCount ?? 0),
                    suppressedNotInterestedUserCount:
                        acc.prospects.suppressedNotInterestedUserCount +
                        (prospects?.suppressedNotInterestedUserCount ?? 0),
                    suppressedOptedOutUserCount:
                        acc.prospects.suppressedOptedOutUserCount + (prospects?.suppressedOptedOutUserCount ?? 0),
                    userCount: acc.prospects.userCount + (prospects?.userCount ?? 0),
                },
            }),
            {
                active: {
                    suppressedDuplicateUserCount: 0,
                    suppressedFilteredUserCount: 0,
                    suppressedInvalidEmailUserCount: 0,
                    suppressedOptedOutUserCount: 0,
                    userCount: 0,
                },
                cooledOffProspects: {
                    suppressedDuplicateUserCount: 0,
                    suppressedInvalidEmailUserCount: 0,
                    suppressedOptedOutUserCount: 0,
                    userCount: 0,
                },
                custom: {
                    suppressedDuplicateUserCount: 0,
                    suppressedInvalidEmailUserCount: 0,
                    suppressedNotInterestedUserCount: 0,
                    suppressedOptedOutUserCount: 0,
                    userCount: 0,
                },
                inactive: {
                    suppressedDuplicateUserCount: 0,
                    suppressedFilteredUserCount: 0,
                    suppressedInvalidEmailUserCount: 0,
                    suppressedOptedOutUserCount: 0,
                    userCount: 0,
                },
                prospects: {
                    suppressedDuplicateUserCount: 0,
                    suppressedFilteredUserCount: 0,
                    suppressedInvalidEmailUserCount: 0,
                    suppressedNotInterestedUserCount: 0,
                    suppressedOptedOutUserCount: 0,
                    userCount: 0,
                },
            },
        );
}
