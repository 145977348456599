import { LocationId, PreparedVideoRequestData, FacebookPublishData, IPublishVideoRequest, PublishedArtifactGroupId, UploadId } from '@deltasierra/shared';


import * as React from 'react';
import { useAngularServiceContext } from '../../../common/componentUtils/angularServiceContexts';
import { MvContentBuilderPublishCtrl } from '../mvContentBuilderPublishCtrl';
import { PublishCallback } from '../types';

const usePublishVideoCallback = () => {
    const facebookPublishService = useAngularServiceContext('facebookPublishService');
    return React.useCallback(
        (publishLocationId: LocationId, request: IPublishVideoRequest<FacebookPublishData>) =>
            facebookPublishService.publishVideo(publishLocationId, request),
        [facebookPublishService],
    );
};

export function useFacebookVideoPublishCallback(
    publishController: MvContentBuilderPublishCtrl,
    videoData: PreparedVideoRequestData | null,
): PublishCallback<FacebookPublishData> {
    const videoPublishService = useAngularServiceContext('VideoPublishService');
    const i18n = useAngularServiceContext('I18nService');
    const identity = useAngularServiceContext('mvIdentity');
    const facebookPublishService = useAngularServiceContext('facebookPublishService');
    const publishVideoCallback = usePublishVideoCallback();
    const currentUser = identity.currentUser;

    return React.useCallback<PublishCallback<FacebookPublishData>>(
        async (
            locationId: LocationId,
            uploadId: UploadId,
            commonData: FacebookPublishData,
            publishedArtifactGroupId: PublishedArtifactGroupId | null,
        ) => {
            if (!videoData) {
                // This shouldn't happen
                throw new Error('Video data has not been set.');
            }
            const canPostToFacebook = await facebookPublishService.canPostToFacebookPageForLocation(locationId);
            if (currentUser && canPostToFacebook) {
                await videoPublishService.publishVideo<FacebookPublishData>(
                    {
                        ...videoData,
                        image: uploadId,
                    },
                    publishController.templateId,
                    publishController.plannerDetails || null,
                    {
                        description: commonData.description ?? undefined,
                        firstComment: commonData.firstComment,
                        scheduledTime: commonData.scheduledTime ?? undefined,
                    },
                    request => publishVideoCallback(locationId, request),
                    publishController.contentBuilder.linkedAssetLibraryAsset.map(value => ({
                        assetId: value.asset.id,
                        layerId: value.layerId,
                    })),
                    publishedArtifactGroupId,
                );
            } else {
                throw new Error(i18n.text.build.publish.facebook.accessCheckFailure());
            }
        },
        [
            currentUser,
            facebookPublishService,
            i18n.text.build.publish.facebook,
            publishController.contentBuilder.linkedAssetLibraryAsset,
            publishController.plannerDetails,
            publishController.templateId,
            publishVideoCallback,
            videoData,
            videoPublishService,
        ],
    );
}
