import { DSCheckbox } from '@deltasierra/components';
import { FormikProps } from 'formik';
import React from 'react';
import { PublishToClubReadyStep1FormValues } from '../../../types/publish-to-club-ready-step-1-form-values';
import { StyledDSFormControlLabel, StyledListOptionBaseContainer } from '../../../../common';

type ClubReadyListOptionBaseProps = {
    children?: React.ReactChild;
    formik: FormikProps<PublishToClubReadyStep1FormValues>;
    listKey: keyof Omit<PublishToClubReadyStep1FormValues, 'emailList' | 'filterType' | 'listDescription'>;
    listName: string;
};

export function ClubReadyListOptionBase({
    children = <></>,
    formik,
    listKey,
    listName,
}: ClubReadyListOptionBaseProps): JSX.Element {
    const isChecked = formik.values[listKey].send;
    return (
        <StyledListOptionBaseContainer>
            <StyledDSFormControlLabel
                checked={isChecked}
                control={
                    <DSCheckbox
                        disableRipple
                        disableTouchRipple
                        value={formik.values[listKey].send}
                        onChange={event =>
                            formik.setFieldValue(listKey, { ...formik.values[listKey], send: event.target.checked })
                        }
                    />
                }
                label={listName}
            />
            {isChecked && children}
        </StyledListOptionBaseContainer>
    );
}
ClubReadyListOptionBase.displayName = 'ClubReadyListOptionBase';
