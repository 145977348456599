/// <reference path="../../_references.d.ts" />

import { Locale } from '@deltasierra/shared';
import { I18nService } from '../../i18n';
import { MvIdentity } from '../../account/mvIdentity';

export class LocaleMenuController {
    static SID = 'LocaleMenuController';

    static readonly $inject : string[] = [
        I18nService.SID,
        MvIdentity.SID,
    ];

    locales : ReadonlyArray<Locale>;

    constructor(private i18nService : I18nService, public identity: MvIdentity) {
        this.locales = this.i18nService.getLocales();
    }

    getCurrentLocale() : Locale | undefined {
        const localeCode = this.i18nService.getLocaleCode();
        return this.i18nService.getLocales().filter(locale => locale.code == localeCode)[0];
    }

    setCurrentLocale(locale: Locale): ng.IPromise<void> {
        return this.i18nService.setLocaleCode(locale.code);
    }
}

angular.module('app').controller(LocaleMenuController.SID, LocaleMenuController);
