import { gql } from '@apollo/client';

export const COPY_TEMPLATE_MUTATION = gql`
    mutation CopyBuilderTemplateMutation($input: CopyBuilderTemplateInput!) {
        copyBuilderTemplate(input: $input) {
            ... on CopyBuilderTemplateSuccess {
                  clients {
                  edges {
                    node {
                      id
                      title
                    }
                  }
                }
                message
            }
            ... on CopyBuilderTemplateError {
                message
            }
        }
    }
`;