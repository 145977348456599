import * as React from 'react';
import { CurrentClientIdType } from '../contexts';
import { CurrentClientStorageDispatchContext } from '../contexts/current-client-storage-dispatch-context';
import { CurrentClientStorageStateContext } from '../contexts/current-client-storage-state-context';

export type CurrentClientStorageContextProviderProps = {
    children?: React.ReactNode;
};

export function CurrentClientStorageContextProvider({
    children,
}: CurrentClientStorageContextProviderProps): JSX.Element {
    const [contextValue, setContextValue] = React.useState<CurrentClientIdType>();
    return (
        <CurrentClientStorageStateContext.Provider value={contextValue}>
            <CurrentClientStorageDispatchContext.Provider value={setContextValue}>
                {children}
            </CurrentClientStorageDispatchContext.Provider>
        </CurrentClientStorageStateContext.Provider>
    );
}
CurrentClientStorageContextProvider.displayName = 'CurrentClientRouteContextProvider';
