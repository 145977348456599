import { Translate } from '@deltasierra/components';
import * as React from 'react';
import styled from 'styled-components';
import { HashtagSetsDropdown, HashtagSetsDropdownProps } from './HashtagSetsDropdown';

const StyledSpanText = styled.span`
    color: ${props => props.theme.palette.primary.main};
    &:hover {
        cursor: pointer;
    }
`;

export type HashtagSetsDropdownButtonProps = Omit<HashtagSetsDropdownProps, 'onClose' | 'open' | 'popperAnchorEl'> & {
    popperAnchorEl?: HashtagSetsDropdownProps['popperAnchorEl'];
};
export function HashtagSetsDropdownButton({
    popperAnchorEl,
    ...dropdownProps
}: HashtagSetsDropdownButtonProps): JSX.Element {
    const anchorRef = React.useRef(null);

    const anchorEl = popperAnchorEl ?? anchorRef.current;

    const [displayHashtagMenu, setDisplayHashtagMenu] = React.useState(false);

    return (
        <>
            <StyledSpanText ref={anchorRef} onClick={() => setDisplayHashtagMenu(oldValue => !oldValue)}>
                <Translate keyId="BUILD.PUBLISH.HASHTAGS.USE_HASHTAG_SETS" />
            </StyledSpanText>
            <HashtagSetsDropdown
                {...dropdownProps}
                open={displayHashtagMenu}
                popperAnchorEl={anchorEl}
                onClickAway={() => setDisplayHashtagMenu(false)}
            />
        </>
    );
}
HashtagSetsDropdownButton.displayName = 'HashtagSetsDropdownButton';
