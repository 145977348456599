import { InteractionUtils } from '../../../common/interactionUtils';
import { PrintProviderApiClient } from '../../../integration/auth/printProviderApiClient';
import { $qSID, actualComponent, ExpressionBinding, ILifecycleHooks, OneWayBinding } from '../../../common/angularData';
import { BuilderConstants, builderConstantsSID } from '../../builderConstants';
import { MvIdentity } from '../../../account/mvIdentity';
import { PdfBuilderService } from '../../print/pdfBuilderService';
import { ConfirmModal, confirmModalSID } from '../../../common/confirmModal';
import { FileUtils } from '../../../common/fileUtils';
import { I18nService } from '../../../i18n';
import { UploadService } from '../../../common/uploadService';
import { PrintPublishService } from '../../../integration/publish/printPublishService';
import { CurrencyService } from '../../../payments/currencyService';
import { MvLocation } from '../../../locations/mvLocation';
import { DataUtils } from '../../../common/dataUtils';
import { MvNotifier } from '../../../common/mvNotifier';
import { BasePublishPrintController, NextViewMap } from './basePublishPrintController';
import { RequestDetails } from './sku/basePublishPrintSKUController';

type PublishPrintGenericViews = 'Details';

const nextViewMap: NextViewMap<PublishPrintGenericViews> = {
    Review: 'ReviewConfirmation',
    ReviewConfirmation: 'Details',
    Details: 'Publishing',
    Publishing: 'Publishing', // There isn't another view after this
};

export class PublishPrintController extends BasePublishPrintController<PublishPrintGenericViews> implements ILifecycleHooks {

    static readonly $inject: string[] = [
        $qSID,
        InteractionUtils.SID,
        DataUtils.SID,
        MvNotifier.SID,
        PrintProviderApiClient.SID,
        builderConstantsSID,
        MvIdentity.SID,
        PdfBuilderService.SID,
        confirmModalSID,
        FileUtils.SID,
        I18nService.SID,
        UploadService.SID,
        PrintPublishService.SID,
        CurrencyService.SID,
        MvLocation.SID,
    ];

    constructor(
        protected readonly $q: ng.IQService,
        protected readonly interactionUtils: InteractionUtils,
        protected readonly dataUtils: DataUtils,
        protected readonly mvNotifier: MvNotifier,
        protected readonly printProviderApiClient: PrintProviderApiClient,
        protected readonly builderConstants: BuilderConstants,
        protected readonly identity: MvIdentity,
        protected readonly pdfBuilderService: PdfBuilderService,
        protected readonly modal: ConfirmModal,
        protected readonly fileUtils: FileUtils,
        protected readonly i18n: I18nService,
        protected readonly uploadService: UploadService,
        protected readonly printPublishService: PrintPublishService,
        protected readonly currencyService: CurrencyService,
        protected readonly mvLocation: MvLocation,
    ) {
        super(
            $q,
            interactionUtils,
            dataUtils,
            mvNotifier,
            printProviderApiClient,
            builderConstants,
            identity,
            pdfBuilderService,
            modal,
            fileUtils,
            i18n,
            uploadService,
            printPublishService,
            currencyService,
            mvLocation,
            nextViewMap,
            true,
        );
    }

    $onInit() {
        super.$onInit();
        if (this.publishData.saveOtherAsNew) {
            this.saveOtherAsNew = true;
        }
    }

    public skipArtworkReview(): void {
        this.didReviewArtwork = false;
        this.setView('Details');
    }

    protected preparePlatformData(requestDetails: RequestDetails): void {
        // Do nothing
    }
}

export const publishPrintControllerSID = 'publishPrintController';

export const publishPrintControllerConfig: ng.IComponentOptions = actualComponent(
    PublishPrintController,
    '/partials/contentBuilder/publish/print/publishPrint',
    {
        publishData: OneWayBinding,
        generatePrintPdf: ExpressionBinding,
        generateImageFileName: ExpressionBinding,
        onCancel: ExpressionBinding,
        publishPublished: ExpressionBinding,
    },
);

angular.module('app').component(publishPrintControllerSID, publishPrintControllerConfig);
