import * as React from 'react';
import { CurrentClientRouteDispatchContext } from '../contexts';
import { useCurrentClientLocalStorageDispatch } from './useCurrentClientStorageDispatch';

/**
 * Use the current client dispatch function from the combined contexts
 *
 * @returns The current client dispatch update
 */
export function useCurrentClientDispatch(): ReturnType<typeof useCurrentClientLocalStorageDispatch> {
    const dispatchToLocalStorageContext = useCurrentClientLocalStorageDispatch();
    const dispatchToRouteContext = React.useContext(CurrentClientRouteDispatchContext);
    return newValue => {
        dispatchToLocalStorageContext(newValue);
        dispatchToRouteContext?.(newValue);
    };
}
