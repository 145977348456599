import { $modalSID } from '../angularUIBootstrapData';
import { ModalService } from '../../typings/angularUIBootstrap/modalService';
import { $rootScopeSID, simpleModal } from '../angularData';
import { BasicModalController } from './basicModalController';
import IRootScopeService = angular.IRootScopeService;

export enum ModalButtonStyle {
    Default = 'btn-default',
    Primary = 'btn-primary'
}

export interface CommonModalButton<TValue> {
    label: string;
    value: TValue;
    cssClass: ModalButtonStyle;
}

export interface CommonModalData<TValue> {
    title: string;
    message: string;
    buttons: Array<CommonModalButton<TValue>>;
}

export class CommonModalService {
    public static readonly SID = 'CommonModalService';

    public static readonly $inject: string[] = [
        $rootScopeSID,
        $modalSID,
    ];

    constructor(
        protected readonly $rootScope : IRootScopeService,
        protected readonly $modal : ModalService,
    ) {

    }

    openModal<TValue>(modalData: CommonModalData<TValue>): ng.IPromise<TValue> {
        return simpleModal(
            this.$modal,
            BasicModalController,
            '/partials/common/commonModalService/basicModal.jade',
            {
                modalData,
            },
        ).result;
    }
}

angular.module('app').service(CommonModalService.SID, CommonModalService);
