import * as React from 'react';
import { NavigateProps, useNavigate } from 'react-router-dom';
import { SafeRedirect } from '.';

export type RouteDefaultRedirectProps = {
    to?: NavigateProps['to'];
};

export function RouteDefaultRedirect({ to }: RouteDefaultRedirectProps): JSX.Element {
    const navigate = useNavigate();

    // eslint-disable-next-line consistent-return
    React.useEffect(() => {
        if (to) {
            /**
             * This here sucks. I hate it but its working...
             * If we don't have this timeout here it intermittently fails when loading this page because
             * the angular version we are on is SO OLD. It's too hard to debug and this is the solution :|
             */
            const timeout = setTimeout(() => {
                navigate(to, { replace: true });
            }, 200);
            return () => {
                clearTimeout(timeout);
            };
        }
    }, [navigate, to]);

    if (to) {
        return <></>;
    }
    return <SafeRedirect to={'/'} />;
}
RouteDefaultRedirect.displayName = 'RouteDefaultRedirect';
