/// <reference path="../../../../typings/browser.d.ts" />
import {
    IInstagramPublishData,
    InstagramPublishApi,
    InstagramPublishMediaBody,
    IPublishVideoToInstagramRequest,
    LocationId,
    paramsAndBody,
    PublishedArtifact,
    PublishMultiImageRequest,
    WorkflowStartResult,
} from '@deltasierra/shared';
import { IPromise } from 'angular';
import { SentryService } from '../../common';
import { $httpSID } from '../../common/angularData';
import { invokeApiRoute } from '../../common/httpUtils';
import { GraphqlService } from '../../graphql/GraphqlService';
import { PUBLISH_INSTAGRAM_DIRECT_IMAGE } from './mutations/publish-instagram-direct-image.mutation';
import {
    PublishInstagramDirectImage,
    PublishInstagramDirectImageVariables,
} from './mutations/__graphqlTypes/PublishInstagramDirectImage';
import IHttpService = angular.IHttpService;

export class InstagramPublishService {
    public static SID = 'instagramPublishService';

    public static readonly $inject: string[] = [$httpSID, GraphqlService.SID, SentryService.SID];

    public constructor(
        private $http: IHttpService,
        private graphqlService: GraphqlService,
        private sentryService: SentryService,
    ) {}

    public publishPhoto(locationId: LocationId, requestBody: InstagramPublishMediaBody): IPromise<PublishedArtifact> {
        return invokeApiRoute(
            this.$http,
            InstagramPublishApi.publishPhoto,
            paramsAndBody(
                {
                    locationId,
                },
                requestBody,
            ),
        );
    }

    public publishVideo(
        locationId: LocationId,
        requestBody: IPublishVideoToInstagramRequest,
    ): IPromise<WorkflowStartResult> {
        return invokeApiRoute(
            this.$http,
            InstagramPublishApi.publishVideo,
            paramsAndBody(
                {
                    locationId,
                },
                requestBody,
            ),
        );
    }

    public async publishPhotoDirect(
        input: PublishMultiImageRequest<IInstagramPublishData>,
    ): Promise<WorkflowStartResult> {
        const gqlClient = this.graphqlService.getClient();
        const result = await gqlClient.mutate<PublishInstagramDirectImage, PublishInstagramDirectImageVariables>({
            mutation: PUBLISH_INSTAGRAM_DIRECT_IMAGE,
            variables: {
                input: {
                    ...input,
                    channelData: {
                        caption: input.channelData.caption,
                        firstComment: input.channelData.firstComment,
                        isStory: input.channelData.isStory,
                    },
                    scheduledTime: input.scheduledTime?.toISOString(),
                },
            },
        });
        if (result.data?.publishInstagramDirectImage.__typename === 'PublishError' || !result.data) {
            this.sentryService.captureException(
                'Failed to publish image to Instagram Direct via "PUBLISH_INSTAGRAM_DIRECT_IMAGE" GraphQL query.',
                {
                    errors:
                        result.data?.publishInstagramDirectImage.__typename === 'PublishError'
                            ? result.data.publishInstagramDirectImage.message
                            : undefined,
                },
            );
            throw new Error('Failed to publish image to Instagram Direct');
        } else {
            return {
                executionArn: result.data.publishInstagramDirectImage.workflow.id,
                executionId: result.data.publishInstagramDirectImage.workflow.executionId,
                startDate: new Date(result.data.publishInstagramDirectImage.workflow.startDate),
            };
        }
    }
}

angular.module('app').service(InstagramPublishService.SID, InstagramPublishService);
