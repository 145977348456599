'use strict';

import { Section, ImageSection, Html } from '@deltasierra/shared';


import JSZip from 'jszip';
import { FileUtils, IFileNameParseResult } from '../../common/fileUtils';
import { $qSID } from '../../common/angularData';
import { I18nService } from '../../i18n';
import {
    CampaignMonitorTemplateParserSID,
    ICampaignMonitorTemplateParser,
    ICampaignMonitorTemplateParserResult,
} from './campaignMonitorTemplateParser';
import { EmailBuilderCtrlScope } from './mvEmailBuilderCtrl';
import { EmailBuilderImageProcessor } from './emailBuilderImageProcessor';
import IQResolveReject = angular.IQResolveReject;


export interface IZipFileEntryExtended extends IFileNameParseResult {
    mimeType: string;
    file: JSZip.JSZipObject;
}


export interface IZipFileValidationContext {
    templateMatches: IZipFileEntryExtended[];
    previewImageMatches: IZipFileEntryExtended[];
    images: IZipFileEntryExtended[];
    validationMessages: string[];
    parsedTemplate: ICampaignMonitorTemplateParserResult;
}

function cloneContext(context: IZipFileValidationContext): IZipFileValidationContext {
    return {
        images: (context.images && context.images.concat()) || [],
        parsedTemplate: context.parsedTemplate,
        previewImageMatches: (context.previewImageMatches && context.previewImageMatches.concat()) || [],
        templateMatches: (context.templateMatches && context.templateMatches.concat()) || [],
        validationMessages: (context.validationMessages && context.validationMessages.concat()) || [],
    };
}

function isImageSection(section: Section): section is ImageSection {
    return section && section.type === 'image';
}

export class ZipFileValidator {
    public static SID = 'zipFileValidator';

    public static readonly $inject: string[] = [
        FileUtils.SID,
        $qSID,
        I18nService.SID,
        CampaignMonitorTemplateParserSID,
    ];

    public constructor(
        private readonly fileUtils: FileUtils,
        private readonly $q: angular.IQService,
        private readonly i18n: I18nService,
        private readonly campaignMonitorTemplateParser: ICampaignMonitorTemplateParser,
    ) {}

    public validate(zip: JSZip): angular.IPromise<IZipFileValidationContext> {
        // Get all the fileNames in the zip file excluding directories and hidden files
        const fileNames: string[] = Object.getOwnPropertyNames(zip.files).filter(
            fileName => !zip.files[fileName].dir && !this.fileUtils.isHidden(fileName),
        );

        // Get some extended info on all the files in the zip
        const entries: IZipFileEntryExtended[] = fileNames.map(fileName => {
            const fileNameInfo = this.fileUtils.parseFileName(fileName);
            const extendedInfo = {
                file: zip.files[fileName],
                mimeType: this.fileUtils.getMimeType(fileName),
            };
            return angular.extend(fileNameInfo, extendedInfo);
        });

        const isImage = (mimeType: string) => mimeType.lastIndexOf('image/') === 0;

        const templateMatches = entries.filter(entry => entry.ext === '.htm' || entry.ext === '.html');
        const previewImageMatches = entries.filter(
            entry => isImage(entry.mimeType) && entry.name.toLowerCase() === 'preview',
        );
        const images = entries.filter(entry => isImage(entry.mimeType) && previewImageMatches.indexOf(entry) === -1);

        const context: IZipFileValidationContext = {
            images,
            parsedTemplate: {
                html: Html.from(''),
                sections: [],
            },
            previewImageMatches,
            templateMatches,
            validationMessages: [],
        };

        return this.$q
            .when(context)
            .then(ctx => this.validateHtml(ctx))
            .then(ctx => this.parseHtml(ctx))
            .then(ctx => this.validateImages(ctx));
    }

    private validateHtml(context: IZipFileValidationContext): angular.IPromise<IZipFileValidationContext> {
        return this.$q.resolve().then(
            (): IZipFileValidationContext => {
                const result = cloneContext(context);

                if (context.templateMatches.length === 0) {
                    result.validationMessages.push(this.i18n.text.build.email.zipValidation.htmlMissing());
                } else if (context.templateMatches.length > 1) {
                    result.validationMessages.push(this.i18n.text.build.email.zipValidation.htmlMultiple());
                }

                return result;
            },
        );
    }

    private parseHtml(context: IZipFileValidationContext): angular.IPromise<IZipFileValidationContext> {
        return this.$q(
            async (
                resolve: IQResolveReject<IZipFileValidationContext>,
                reject: IQResolveReject<IZipFileValidationContext>,
            ) => {
                if (context.templateMatches.length !== 1) {
                    return resolve(context);
                }

                const result = cloneContext(context);
                const contextString = await context.templateMatches[0].file.async('string');
                const html = Html.from(contextString);
                return this.campaignMonitorTemplateParser.processHtml(html).then(parsedTemplate => {
                    result.parsedTemplate = parsedTemplate;
                    return resolve(result);
                }, reject);
            },
        );
    }

    private validateImages(context: IZipFileValidationContext): angular.IPromise<IZipFileValidationContext> {
        const isAbsolutePath = (url: string) => url.lastIndexOf('https:') === 0 || url.lastIndexOf('http:') === 0;

        return this.$q(
            (
                resolve: IQResolveReject<IZipFileValidationContext>,
                reject: IQResolveReject<IZipFileValidationContext>,
            ) => {
                try {
                    const result = cloneContext(context);

                    if (result.parsedTemplate) {
                        // Get all the image sections that use relative paths
                        const imageSections = result.parsedTemplate.sections.filter(
                            section => isImageSection(section) && !isAbsolutePath(section.originalLocation!),
                        ) as ImageSection[];

                        // Make sure there are no image sections with links to images that don't exist
                        for (const imageSection of imageSections) {
                            if (!result.images.some(image => image.fileName === imageSection.originalLocation)) {
                                result.validationMessages.push(
                                    this.i18n.text.build.email.zipValidation.imageMissing({
                                        image: imageSection.originalLocation || 'null',
                                    }),
                                );
                            }
                        }

                        // Ensure that all supplied images are being referenced by an image section
                        // #TODO: Do we care? Should we simply warn and just not upload any unused images to server?
                        for (const image of result.images) {
                            const matchingSections = imageSections.filter(
                                section => section.originalLocation === image.fileName,
                            );

                            if (!matchingSections.length) {
                                result.validationMessages.push(
                                    this.i18n.text.build.email.zipValidation.imageUnreferenced({
                                        image: image.fileName,
                                    }),
                                );
                            }
                        }
                    } else {
                        result.images = [];
                    }

                    return resolve(result);
                } catch (err) {
                    return reject(err);
                }
            },
        );
    }
}

angular.module('app').service(ZipFileValidator.SID, ZipFileValidator);

export type IEmailBuilderZipProcessingScope = Pick<EmailBuilderCtrlScope, 'emailBuilder' | 'fileCache'>;

export class ZipFileProcessor {
    public constructor(
        private readonly $q: angular.IQService,
        private readonly scope: IEmailBuilderZipProcessingScope,
        private readonly emailBuilderImageProcessor: EmailBuilderImageProcessor,
    ) {}

    public process(context: IZipFileValidationContext): angular.IPromise<IZipFileValidationContext> {
        return this.$q
            .when(context)
            .then(ctx => this.processTemplate(ctx))
            .then(ctx => this.processImages(ctx));
    }

    private processTemplate(context: IZipFileValidationContext): angular.IPromise<IZipFileValidationContext> {
        return this.$q.resolve().then(
            (): IZipFileValidationContext => {
                if (context.parsedTemplate) {
                    this.scope.emailBuilder.document.sections = context.parsedTemplate.sections;
                    this.scope.emailBuilder.document.templateHtml = context.parsedTemplate.html;
                }
                return context;
            },
        );
    }

    private processImages(context: IZipFileValidationContext): angular.IPromise<IZipFileValidationContext> {
        return this.emailBuilderImageProcessor.processImages(context, this.scope.emailBuilder);
    }
}

export class ZipFileProcessorFactory {
    public static SID = 'zipFileProcessorFactory';

    public static readonly $inject: string[] = [$qSID, EmailBuilderImageProcessor.SID];

    public constructor(
        private readonly $q: angular.IQService,
        private readonly emailBuilderImageProcessor: EmailBuilderImageProcessor,
    ) {}

    public create(scope: IEmailBuilderZipProcessingScope): ZipFileProcessor {
        const result = new ZipFileProcessor(this.$q, scope, this.emailBuilderImageProcessor);
        return result;
    }
}

angular.module('app').service(ZipFileProcessorFactory.SID, ZipFileProcessorFactory);
