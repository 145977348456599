import { gql, useMutation } from '@apollo/client';
import { t } from '@deltasierra/shared';
import { useAngularServiceContext } from '../../common/componentUtils/angularServiceContexts';
import { CLIENT_USERS_QUERY } from './useGetClientUsers';
import { DeleteClientUser, DeleteClientUserVariables } from './__graphqlTypes/DeleteClientUser';
import { GetClientUsers, GetClientUsersVariables, GetClientUsers_client_users_edges_node } from './__graphqlTypes/GetClientUsers';

export const DELETE_CLIENT_USER_MUTATION = gql`
    mutation DeleteClientUser($id: ID!) {
        deleteUser(input: { id: $id, role: "client" }) {
            __typename
            ... on DeletedUserNode {
                id
                firstName
                lastName
            }
            ... on UserNotFoundErrorNode {
                message
            }
            ... on UserPermissionErrorNode {
                message
            }
        }
    }
`;

export function useDeleteClientUser(
    variables: GetClientUsersVariables,
    // eslint-disable-next-line camelcase
): (clientUser: GetClientUsers_client_users_edges_node) => void {
    const mvNotifier = useAngularServiceContext('mvNotifier');

    const [deleteClientUser] = useMutation<DeleteClientUser, DeleteClientUserVariables>(DELETE_CLIENT_USER_MUTATION, {
        onCompleted: data => {
            if (data.deleteUser.__typename === 'DeletedUserNode') {
                mvNotifier.success(
                    t('AGENCY.CLIENT.LOCATION.USERS.USER_EDIT.USER_DELETED', {
                        firstName: data.deleteUser.firstName,
                        lastName: data.deleteUser.lastName,
                    }),
                );
            }
        },
    });

    return clientUser => {
        void deleteClientUser({
            update(cache, data) {
                if (data.data?.deleteUser.__typename !== 'DeletedUserNode') {
                    return;
                }
                const cachedClientUsers = cache.readQuery<GetClientUsers, GetClientUsersVariables>({
                    query: CLIENT_USERS_QUERY,
                    variables,
                });
                if (!cachedClientUsers || !cachedClientUsers.client) {
                    return;
                }

                const updatedEdges = cachedClientUsers.client.users.edges.filter(edge => edge.node.id !== clientUser.id);

                cache.writeQuery<GetClientUsers, GetClientUsersVariables>({
                    data: {
                        ...cachedClientUsers,
                        client: {
                            ...cachedClientUsers.client,
                            users: {
                                __typename: cachedClientUsers.client.users.__typename,
                                edges: updatedEdges,
                                pageInfo: updatedEdges.length
                                    ? { ...cachedClientUsers.client.users.pageInfo }
                                    : {
                                          __typename: cachedClientUsers.client.users.pageInfo.__typename,
                                          endCursor: null,
                                          hasNextPage: null,
                                          startCursor: null,
                                      },
                            },
                        },
                    },
                    query: CLIENT_USERS_QUERY,
                    variables,
                });
            },
            variables: {
                id: clientUser.id,
            },
        });
    };
}
