import * as React from 'react';
import { Field, ErrorMessage } from 'formik';

export type FieldGroupProps = {
    error: string | undefined;
    inputColumnSize?: number;
    label: string;
    labelColumnSize?: number;
    name: string;
    touched?: boolean;
};

const FieldGroup: React.FC<FieldGroupProps> = ({ error, label, name, labelColumnSize = 3, inputColumnSize = 9, touched }) => (
    <div className={`form-group ${error && touched ? 'has-error' : ''}`}>
        <label className={`control-label col-sm-${labelColumnSize}`}>{label}</label>
        <div className={`col-sm-${inputColumnSize}`}>
            <Field className="form-control" name={name} />
            <ErrorMessage name={name}>{msg => <span className="text-danger">{msg}</span>}</ErrorMessage>
        </div>
    </div>
);

export default FieldGroup;
