import { WebSocketLink } from '@apollo/client/link/ws';
import { onError } from '@apollo/client/link/error';
import { ApolloClient, HttpLink, InMemoryCache, from, split, gql, makeVar } from '@apollo/client';
import { getMainDefinition, relayStylePagination } from '@apollo/client/utilities';
import fetch from 'cross-fetch';
import { getService } from '../common/angularData';
import GraphQLSchema from '../schema.json';
import { SelectableAsset } from '../assetLibrary/context';

export const NO_CURRENT_LOCATION_ID = '-' as const;
export const currentLocationId = makeVar<string>(NO_CURRENT_LOCATION_ID);

export const selectedAssets = makeVar<SelectableAsset[]>([]);

const typeDefs = gql`
    extend type PostArtifactGroup {
        posts: PostArtifactConnection!
    }

    union PostArtifactOrPostArtifactGroup = PostArtifact | PostArtifactGroup

    extend type Query {
        currentLocationId: ID!
        hasCurrentLocation: Boolean!
    }

    extend type AssetFile {
        selected: Boolean!
        deselected: Boolean!
    }

    extend type AssetFolder {
        selected: Boolean!
        deselected: Boolean!
    }
`;

const WEBSOCKET_PORT = document.location.port ? `:${document.location.port}` : '';
const WEBSOCKET_URI = `wss://${document.location.hostname}${WEBSOCKET_PORT}/graphql`;

/*
 * Heroku has a websocket timeout and will disconnect idle websocket connections. To get around that we need to
 * manually provide a timeout value that's less than Heroku's
 */
const HEROKU_WEBSOCKET_TIMEOUT = 30000;

const wsLink = new WebSocketLink({
    options: {
        inactivityTimeout: HEROKU_WEBSOCKET_TIMEOUT,
        lazy: true,
        reconnect: true,
        timeout: HEROKU_WEBSOCKET_TIMEOUT,
    },
    uri: WEBSOCKET_URI,
});

const httpLink = new HttpLink({
    fetch,
    /**
     * The old format didn't work after upgrading to NX,
     * now we have to provide a full URL
     */
    uri: `${document.location.protocol}//${document.location.hostname}:${document.location.port}/graphql`,
});

/*
 * @see https://www.apollographql.com/docs/react/data/fragments/#generating-possibletypes-automatically
 */
const possibleTypes: Record<string, string[]> = {};
GraphQLSchema.__schema.types.forEach(supertype => {
    if (supertype.possibleTypes) {
        possibleTypes[supertype.name] = supertype.possibleTypes.map(subtype => subtype.name);
    }
});

export const client = new ApolloClient({
    cache: new InMemoryCache({
        addTypename: true,
        possibleTypes,
        typePolicies: {
            Admin: {
                fields: {
                    blockedPublishes: relayStylePagination(),
                    brands: relayStylePagination(),
                    clients: relayStylePagination(),
                    recentFailedPublishes: relayStylePagination(),
                },
            },
            Agency: {
                fields: {
                    agencyNotifications: relayStylePagination(),
                    brands: relayStylePagination(),
                    clients: relayStylePagination(),
                    users: relayStylePagination(['filter']),
                },
            },
            AgencyNotification: {
                fields: {
                    userReports: relayStylePagination(),
                },
            },
            ApiKeys: {
                keyFields: false,
                merge: true,
            },
            AppPermissions: {
                keyFields: false,
                merge: true,
            },
            AssetFile: {
                fields: {
                    deselected: {
                        read(_, { readField }) {
                            const isSelected = readField<boolean>('selected');
                            return selectedAssets().length > 0 && !isSelected;
                        },
                    },
                    selected: {
                        read(_, { readField }) {
                            const id = readField<string>('id');
                            const selected = selectedAssets();
                            return id ? !!selected.find(asset => asset.id === id) : false;
                        },
                    },
                },
            },
            AssetFolder: {
                fields: {
                    assets: relayStylePagination(),
                    deselected: {
                        read(_, { readField }) {
                            const isSelected = readField<boolean>('selected');
                            return selectedAssets().length > 0 && !isSelected;
                        },
                    },
                    selected: {
                        read(_, { readField }) {
                            const id = readField<string>('id');
                            const selected = selectedAssets();
                            return id ? !!selected.find(asset => asset.id === id) : false;
                        },
                    },
                },
            },
            Brand: {
                fields: {
                    clients: relayStylePagination(),
                },
            },
            BuilderTemplate: {
                fields: {
                    formatsConnection: relayStylePagination(),
                },
            },
            BuilderTemplateCategoryUsageBreakdownByType: {
                keyFields: false,
                merge: true,
            },
            BuiltTemplatesByTime: {
                keyFields: false,
                merge: true,
            },
            Campaign: {
                fields: {
                    rolloutsConnection: relayStylePagination(),
                },
            },
            CampaignMonitorLocationReportSummary: {
                fields: {
                    recipients: relayStylePagination(),
                },
            },
            CampaignMonitorLocationReport: {
                fields: {
                    emailCampaigns: relayStylePagination(),
                },
                keyFields: ['id'],
            },
            Client: {
                fields: {
                    builderTemplateCategoriesConnection: relayStylePagination(),
                    campaignsConnection: relayStylePagination(),
                    /*
                     * TODO: Reconfigure Relay-style pagination
                     * This has been removed because it was causing the Asset Library page to not load. The error
                     * message is "cannot read property 'edges' of undefined" on the "incoming" parameter in the
                     * "merge" function.
                     * collections: relayStylePagination(),
                     */
                    expired: relayStylePagination(),
                    locationCategoryOptionsConnection: relayStylePagination(['filter', ['label']]),
                    locations: relayStylePagination(['filter', ['title']]),
                    recycled: relayStylePagination(),
                    users: relayStylePagination(['filter', 'sort']),
                },
            },
            ClientFeatures: {
                keyFields: false,
                merge: true,
            },
            ClientReport: {
                fields: {
                    locationEngagement: relayStylePagination(['filter', 'sort']),
                    popularBuilderTemplatesConnection: relayStylePagination(),
                    popularCategories: relayStylePagination(['locationCategoryOptionId', 'since', 'until']),
                },
            },
            ClubReady: {
                fields: {
                    lists: relayStylePagination(['filter']),
                },
            },
            // Fix for https://digitalstack.atlassian.net/browse/DSL-1349
            ClubReadyList: {
                keyFields: false,
                merge: false,
            },
            Collection: {
                fields: {
                    assets: relayStylePagination(),
                },
            },
            CollectionLimits: {
                keyFields: false,
                merge: true,
            },
            Config: {
                keyFields: false,
                merge: true,
            },
            ConnectedPlatforms: {
                fields: {
                    pastPosts: relayStylePagination(),
                    posts: relayStylePagination(),
                    scheduledPosts: relayStylePagination(),
                },
            },
            FacebookAdSetInsights: {
                keyFields: false,
                merge: true,
            },
            FacebookAdsLocationReport: {
                fields: {
                    adSetInsights: relayStylePagination(),
                },
            },
            FacebookGenderInsight: {
                keyFields: false,
                merge: true,
            },
            FacebookGenderInsights: {
                keyFields: false,
                merge: true,
            },
            FacebookLocationLikesReport: {},
            FacebookLocationPostReport: {
                fields: {
                    posts: relayStylePagination(),
                },
            },
            FacebookLocationReport: {
                fields: {
                    demographics: {
                        keyArgs: false,
                        merge: true,
                    },
                    onlineFollowers: {
                        keyArgs: false,
                        merge: true,
                    },
                },
            },
            FacebookOnlineFollowersInsight: {
                keyFields: false,
                merge: true,
            },
            FacebookTopLocationInsights: {
                keyFields: false,
                merge: true,
            },
            FacebookTopLocationInsightsLocation: {
                keyFields: false,
                merge: true,
            },
            FacebookTopPostTimeInsight: {
                keyFields: false,
                merge: true,
            },
            FacebookTopPostTimeInsightTime: {
                keyFields: false,
            },
            Features: {
                keyFields: false,
                merge: true,
            },
            FeaturesBrandsDashboardConfig: {
                keyFields: false,
                merge: true,
            },
            FeaturesBuilderConfig: {
                keyFields: false,
                merge: true,
            },
            FeaturesCampaignsConfig: {
                keyFields: false,
                merge: true,
            },
            FeaturesPlannerConfig: {
                keyFields: false,
                merge: true,
            },
            GoogleAds: {
                fields: {
                    availableCampaigns: {
                        keyArgs: false,
                        merge: false,
                    },
                },
            },
            GoogleMyBusiness: {
                fields: {
                    locations: relayStylePagination(),
                },
                keyFields: ['id'],
            },
            GoogleMyBusinessReportRecentPosts: {
                keyFields: false,
            },
            GoogleMyBusinessReportTopReviews: {
                keyFields: false,
            },
            GoogleMyBusinessReports: {
                fields: {
                    recentPosts: {
                        keyArgs: false,
                        merge: true,
                    },
                    search: {
                        keyArgs: false,
                        merge: true,
                    },
                    topReviews: {
                        keyArgs: false,
                        merge: true,
                    },
                    views: {
                        keyArgs: false,
                        merge: true,
                    },
                },
            },
            GoogleMyBusinessSearchReport: {
                keyFields: false,
            },
            GoogleMyBusinessViewsReport: {
                keyFields: false,
            },
            HRef: {
                keyFields: false,
                merge: true,
            },
            InstagramAudienceInsightsReport: {
                keyFields: false,
            },
            InstagramBusinessAccountReport: {
                keyFields: false,
                merge: true,
            },
            InstagramReports: {
                fields: {
                    audienceInsights: {
                        keyArgs: false,
                        merge: true,
                    },
                    businessAccount: {
                        keyArgs: false,
                        merge: true,
                    },
                },
            },
            JobTimestamps: {
                keyFields: false,
                merge: true,
            },
            Location: {
                fields: {
                    builderTemplateCategoriesConnection: relayStylePagination(),
                    clientCollections: relayStylePagination(),
                    /*
                     * TODO: Reconfigure Relay-style pagination
                     * This has been removed because it was causing the Asset Library page to not load. The error
                     * message is "cannot read property 'edges' of undefined" on the "incoming" parameter in the
                     * "merge" function.
                     * collections: relayStylePagination(),
                     */
                    expired: relayStylePagination(),
                    fitwareScheduledEmails: relayStylePagination(),
                    locationDrafts: relayStylePagination(),
                    recycled: relayStylePagination(),
                },
            },
            LocationReporting: {
                keyFields: false,
                merge: true,
            },
            NoIntegrationConnection: {
                keyFields: false,
                merge: true,
            },
            PopularBuilderTemplate: {
                keyFields: false,
                merge: true,
            },
            Query: {
                fields: {
                    apps: relayStylePagination(),
                    assetsSearch: relayStylePagination(),
                    currentLocationId: {
                        read() {
                            return currentLocationId();
                        },
                    },
                    hasCurrentLocation: {
                        read() {
                            const locationId = currentLocationId();
                            return !!locationId && locationId !== NO_CURRENT_LOCATION_ID;
                        },
                    },
                },
            },
            ReportFeaturesConfig: {
                keyFields: false,
                merge: true,
            },
            SendgridEmailCampaignReport: {
                fields: {
                    users: relayStylePagination(),
                },
            },
            SendgridLocationReport: {
                fields: {
                    emailCampaigns: relayStylePagination(),
                },
                keyFields: ['id'],
            },
            SharedWithClientsOrLocationsUnion: {
                keyFields: false,
                merge: true,
            },
            Thumbnail: {
                keyFields: false,
                merge: true,
            },
            ThumbnailCommonSizes: {
                keyFields: false,
                merge: true,
            },
            User: {
                fields: {
                    clients: relayStylePagination(),
                    locations: relayStylePagination(['filter', ['title']]),
                    users: relayStylePagination(),
                },
            },
        },
    }),
    link: from([
        onError(({ graphQLErrors, networkError }) => {
            const $rootScope = getService('$rootScope');
            const mvNotifier = getService('mvNotifier');
            const i18n = getService('I18nService');

            if (graphQLErrors) {
                graphQLErrors.forEach(error => {
                    if (error.extensions?.exception?.status === 401) {
                        $rootScope.$broadcast('unauthenticated');
                        mvNotifier.expectedError(i18n.text.errors.unauthorizedErrors.pleaseLogin());
                        // NOTE: never re-enabled; the page *must* be refreshed to get any more notifications!
                        mvNotifier.disable();
                    } else if (error.extensions?.exception?.status === 403) {
                        $rootScope.$broadcast('unauthorized');
                        mvNotifier.expectedError(i18n.text.errors.forbiddenErrors.accessForbidden());
                    } else {
                        mvNotifier.unexpectedError(error);
                    }
                });
            }

            if (networkError) {
                /*
                 * TODO: Check if this is due to no internet connection or because the connection was closed
                 * We previsouly assumed that any network errors were related to no internet connections. However,
                 * Apollo Client network errors also happen when a connection is interrupted such as refreshing
                 * the page while there is an already running query. For the moment we can just log the error to the
                 * console until we come up with a better solution.
                 *
                 * //mvNotifier.expectedError(i18n.text.errors.noNetworkConnectionError());
                 */
                // eslint-disable-next-line no-console
                console.error(networkError);
            }
        }),
        split(
            ({ query }) => {
                const definition = getMainDefinition(query);
                return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
            },
            wsLink,
            httpLink,
        ),
    ]),
    typeDefs,
});
