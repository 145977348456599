import { gql } from '@apollo/client';

export const GET_SUPPRESSED_EMAILS_REPORT_REQUEST_QUERY = gql`
    query GetSuppressedEmailsReportRequestQuery($reportRequestId: ID!) {
        SuppressedEmailsReportRequest(id: $reportRequestId) {
            ... on SuppressedEmailsReportRequest {
                id
                status
                generatedReport {
                    id
                    upload {
                        id
                        signedUrl
                    }
                }
            }
            ... on Error {
                message
            }
        }
    }
`;
