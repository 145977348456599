import * as React from 'react';

export type ClubReadySuppressedCountProps = {
    suppressionName: string;
    count: number;
};

export function ClubReadySuppressedCount({ count, suppressionName }: ClubReadySuppressedCountProps): JSX.Element {
    const countWrapped = React.useMemo(() => ` (${count})`, [count]);
    const suppressionNameCountAndInfo = `${suppressionName}${countWrapped}`;

    return <>{suppressionNameCountAndInfo}</>;
}
ClubReadySuppressedCount.displayName = 'ClubReadySuppressedCount';
