import { BannerNotification } from '@deltasierra/shared';
import { BannerNotificationsSocketService } from '../../sockets';

export default class BannerNotificationDisplayController {
    public static SID = 'BannerNotificationDisplayController';

    public static readonly $inject: string[] = [BannerNotificationsSocketService.SID];

    public constructor(private readonly bannerNotificationsSocketService: BannerNotificationsSocketService) {}

    public get currentBannerNotification(): BannerNotification | null {
        return this.bannerNotificationsSocketService.currentBannerNotification;
    }

    public getClass(): string {
        const bannerNotification = this.currentBannerNotification;

        if (!bannerNotification) {
            return '';
        }

        let base = `alert-${bannerNotification.type}`;

        if (bannerNotification.dismissable) {
            base += ' dismissable';
        }
        return base;
    }
}

angular.module('app').controller(BannerNotificationDisplayController.SID, BannerNotificationDisplayController);
