import * as React from 'react';

export type SVGComponentProps = Omit<React.ImgHTMLAttributes<HTMLImageElement>, 'src'> & {
    size?: number;
};

export type SVGComponent = React.FunctionComponent<SVGComponentProps> & {
    src: string;
};

export function createSVGComponentFromSVG(src: string, name: string): SVGComponent {
    const func: SVGComponent = ({ size = 24, ...props }) => {
        const sizePx = size ? `${size}px` : undefined;
        return <img height={sizePx} src={src} width={sizePx} {...props} />;
    };
    func.displayName = name;
    func.src = src;
    return func;
}
