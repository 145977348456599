import * as React from 'react';

export type LoadingProps = {
    /**
     * Size of the loading component
     *
     * @default 'normal'
     */
    size?: 'normal' | 'small';
    /**
     * Whether or not the loading component should be displayed inline
     *
     * @default false
     */
    inline?: boolean;
};

/**
 * Simple spinner/loading component.
 */
export const Loading: React.FunctionComponent<LoadingProps> = React.memo(({ size = 'normal', inline = false }) => (
    <div className={`loading${size === 'small' ? '-small' : ''}`} style={{ display: inline ? 'inline-block' : 'block' }} />
));
Loading.displayName = 'Loading';

export type LoaderProps = LoadingProps & { loading: boolean };

export const Loader: React.FC<LoaderProps> = props => <>{props.loading ? <Loading {...props} /> : props.children}</>;
Loader.displayName = 'Loader';
