import * as React from 'react';
import { DateTime } from 'luxon';
import { DateOnly, DateOnlyRange, TimePeriod } from '@deltasierra/dates';
import { useLocalStorage } from '@deltasierra/react-hooks';
import { isFailureResult } from '@deltasierra/type-utilities';
import { useRouter } from '../../../../common/routes';

export function useDateRangeForEngagementPage(): [
    range: TimePeriod,
    setDateRange: (since: Date | null | undefined, until: Date | null | undefined) => void,
] {
    const [fromUrl, setInUrl] = useDateRangeFromUrl();
    const [fromLocalStorage, setInLocalStorage] = useDateRangeFromLocalStorage();

    const setDateRangeInStorages = React.useCallback(
        (range: DateOnlyRange | null): void => {
            setInLocalStorage(range);
            setInUrl(range);
        },
        [setInLocalStorage, setInUrl],
    );

    const setDateRange = React.useCallback(
        (since: Date | null | undefined, until: Date | null | undefined): void => {
            if (since && until) {
                const range = DateOnlyRange.fromParts(DateOnly.fromLocalDate(since), DateOnly.fromLocalDate(until));
                setDateRangeInStorages(range);
            } else {
                setDateRangeInStorages(null);
            }
        },
        [setDateRangeInStorages],
    );

    const currentRange: TimePeriod = React.useMemo(() => {
        const range = fromUrl ?? fromLocalStorage ?? null;
        if (!range) {
            return dateRangeSinceUntilToTimePeriod(undefined, undefined);
        } else {
            const [since, until] = DateOnlyRange.toParts(range);
            return dateRangeSinceUntilToTimePeriod(
                DateTime.fromISO(since).startOf('day').toJSDate(),
                DateTime.fromISO(until).endOf('day').toJSDate(),
            );
        }
    }, [fromLocalStorage, fromUrl]);

    React.useEffect(() => {
        if (!fromUrl && fromLocalStorage) {
            setDateRangeInStorages(fromLocalStorage);
        } else if ((!fromLocalStorage && fromUrl) || fromUrl !== fromLocalStorage) {
            setDateRangeInStorages(fromUrl);
        }
    }, [setDateRangeInStorages, fromUrl, fromLocalStorage]);

    return [currentRange, setDateRange];
}

function useDateRangeFromUrl() {
    const router = useRouter<Record<string, never>, { range?: string }>();
    const rawRangeFromUrl = router.query.get('range');
    const currentRange = rawRangeFromUrl ? DateOnlyRange.fromString(rawRangeFromUrl) : null;

    const setDateRange = React.useCallback(
        (range: DateOnlyRange | null): void => {
            if (!range) {
                router.query.remove('range');
            } else {
                router.query.set('range', range);
            }
        },
        [router.query],
    );

    return [currentRange, setDateRange] as const;
}

export const BrandsEngagementDateRangeLocalStorageKey = 'brands.engagement.dateRange';

function useDateRangeFromLocalStorage() {
    const [rawFromLocalStorage, setLocalStorageValue] = useLocalStorage<string | null>(
        BrandsEngagementDateRangeLocalStorageKey,
    );
    const currentRange = rawFromLocalStorage ? DateOnlyRange.fromString(rawFromLocalStorage) : null;

    const setDateRange = React.useCallback(
        (range: DateOnlyRange | null): void => {
            setLocalStorageValue(range);
        },
        [setLocalStorageValue],
    );

    return [currentRange, setDateRange] as const;
}

function dateRangeSinceUntilToTimePeriod(since: Date | undefined, until: Date | undefined): TimePeriod {
    const fallbackTime = (since ? since.getTime() : new Date().getTime()) + 1;
    if (!since || !until) {
        // Defaults to last month.
        const startDate = DateTime.now().minus({ months: 1 }).toJSDate();
        const endDate = new Date(fallbackTime);
        const result = TimePeriod.of(startDate, endDate);
        if (isFailureResult(result)) {
            throw new Error('Invalid dates');
        }
        return result.value;
    }
    const startDate = since;
    const endDate = until.getTime() > since.getTime() ? until : new Date(fallbackTime);
    const result = TimePeriod.of(startDate, endDate);
    if (isFailureResult(result)) {
        throw new Error('Invalid dates');
    }
    return result.value;
}
