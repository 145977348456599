import { gql, useQuery } from '@apollo/client';
import { Loading } from '@deltasierra/components';
import { t } from '@deltasierra/shared';
import * as React from 'react';
import { RecycledAssets } from './RecycledAssets';
import { GetClientExpiredAssets, GetClientExpiredAssetsVariables } from './__graphqlTypes/GetClientExpiredAssets';

const GET_CLIENT_EXPIRED_ASSETS = gql`
    query GetClientExpiredAssets($locationId: ID!) {
        location(id: $locationId) {
            id
            client {
                id
                expired {
                    ...AssetConnectionForRecycledAssets
                }
            }
        }
    }
    ${RecycledAssets.fragments.ASSET_CONNECTION_FOR_RECYCLED_ASSETS}
`;

export interface ClientExpiredAssetsProps {
    locationId: string;
}

export function ClientExpiredAssets(props: ClientExpiredAssetsProps): JSX.Element {
    const { data, loading } = useQuery<GetClientExpiredAssets, GetClientExpiredAssetsVariables>(
        GET_CLIENT_EXPIRED_ASSETS,
        {
            fetchPolicy: 'network-only',
            nextFetchPolicy: 'cache-first',
            variables: { locationId: props.locationId },
        },
    );
    if (!data?.location?.client || loading) {
        return <Loading />;
    } else {
        return <RecycledAssets assetConnection={data?.location?.client.expired} headerText={t('ASSET_LIBRARY.EXPIRED_ASSETS')} />;
    }
}
ClientExpiredAssets.displayName = 'ClientExpiredAssets';
