/* eslint-disable max-lines-per-function */
import { useMutation, useQuery } from '@apollo/client';
import {
    DSButton,
    DSCheckbox,
    DSFormControl,
    DSGrid,
    DSLoaderButton,
    DSTypography,
    Loading,
    Translate,
    WizardStepProps,
} from '@deltasierra/components';
import { assertNever, BuilderTemplateId, t } from '@deltasierra/shared';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Formik, FormikErrors } from 'formik';
import { DateTime } from 'luxon';
import * as React from 'react';
import { SendClubReadyCampaignRecipientLists } from '../../../../../../../../__graphqlTypes/globalTypes';
import { useAngularServiceContext } from '../../../../../../common/componentUtils/angularServiceContexts';
import { relayConnectionToArray } from '../../../../../../graphql/utils';
import { EmailPublishData } from '../../../../emailPublishData';
import {
    InitialLocationData,
    MultiLocationLoaderPublishFunc,
    PublishAndReturnExecution,
} from '../../hooks/useMultiLocationLoader';
import { buildClubNameWithCountString } from '../build-club-name-with-count-string';
import {
    FormButtonGroup,
    StyledDSAlert,
    StyledDSFormControlLabel,
    StyledDSFormGroup,
    StyledForm,
    StyledLabel,
    StyledMainTitle,
} from '../common';
import { PublishToClubReadyStep1Data } from '../PublishToClubReadyStep1/types/publish-to-club-ready-step-1-data';
import { PublishToClubReadyStep2Data } from '../PublishToClubReadyStep2';
import { ClubReadyStats } from './components';
import { ClubReadyListCountStates } from './components/ClubReadyCountContainer';
import { GET_CLUB_READY_LOCATIONS_VERIFIED_SENDER } from './graphql/getClubReadyLocationsVerifiedSender.query';
import { PUBLISH_TO_CLUB_READY_STEP_3_FRAGMENT } from './graphql/publishToClubReadyStep3.fragments';
import { SEND_CLUB_READY_CAMPAIGN_MUTATION } from './graphql/sendClubReadyCampaign.mutation';
import {
    GetClubReadyLocationsVerifiedSender,
    GetClubReadyLocationsVerifiedSenderVariables,
} from './graphql/__graphqlTypes/GetClubReadyLocationsVerifiedSender';
import { PublishToClubReadyStep3Fragment } from './graphql/__graphqlTypes/PublishToClubReadyStep3Fragment';
import { SendClubReadyCampaign, SendClubReadyCampaignVariables } from './graphql/__graphqlTypes/SendClubReadyCampaign';
import { getActivityPeriod, getAddedBetweenRange, useRectifyEmailImagesAndUploadHtml } from './utils';

export type PublishToClubReadyStep3Data = {
    confirmedDetails: boolean;
};

export type PublishToClubReadyStep3Props = WizardStepProps<PublishToClubReadyStep3Data> & {
    emailTemplateId: BuilderTemplateId;
    fragment: PublishToClubReadyStep3Fragment;
    publishData: EmailPublishData;
    step1Data: PublishToClubReadyStep1Data;
    step2Data: PublishToClubReadyStep2Data;
    locationsToPublishTo: InitialLocationData[];
    startPublish: MultiLocationLoaderPublishFunc;
};

export function PublishToClubReadyStep3({
    emailTemplateId,
    fragment,
    gotoNextStep,
    gotoPrevStep,
    locationsToPublishTo,
    publishData,
    startPublish,
    step1Data,
    step2Data,
    StepCount,
}: PublishToClubReadyStep3Props): JSX.Element {
    const rectifyImagesAndUploadHtml = useRectifyEmailImagesAndUploadHtml();
    const mvNotifier = useAngularServiceContext('mvNotifier');
    const locationIds = locationsToPublishTo.map(location => location.id);
    const initialValues: PublishToClubReadyStep3Data = {
        confirmedDetails: false,
    };

    // Recipient count validation for custom sends only
    const isCustomList = step1Data.filterType === 'emails';
    const [listCountState, setListCountState] = React.useState<ClubReadyListCountStates>(
        ClubReadyListCountStates.INVALID,
    );

    const { data: verifiedSenderData, loading: loadingVerifiedSenders } = useQuery<
        GetClubReadyLocationsVerifiedSender,
        GetClubReadyLocationsVerifiedSenderVariables
    >(GET_CLUB_READY_LOCATIONS_VERIFIED_SENDER, {
        variables: { locationIds },
    });

    const [sendClubReadyCampaign, { loading }] = useMutation<SendClubReadyCampaign, SendClubReadyCampaignVariables>(
        SEND_CLUB_READY_CAMPAIGN_MUTATION,
    );

    const handleValidation = (values: typeof initialValues): FormikErrors<typeof initialValues> => {
        const errors: FormikErrors<PublishToClubReadyStep3Data> = {};
        if (!values.confirmedDetails) {
            errors.confirmedDetails = t('COMMON.VALIDATION.MUST_CONFIRM_DETAILS_TO_CONTINUE');
        }
        return errors;
    };

    async function handleSubmit(values: typeof initialValues) {
        const { id: uploadId } = await rectifyImagesAndUploadHtml(publishData);
        return startPublish(locationsToPublishTo, async () => {
            let recipientLists: SendClubReadyCampaignRecipientLists;
            if (step1Data.filterType === 'filter') {
                recipientLists = {
                    active: {
                        options: {
                            activityPeriod: getActivityPeriod(step1Data.active),
                        },
                        send: step1Data.active.send,
                    },
                    cooledOffProspects: {
                        send: step1Data.cooledOffProspects.send,
                    },
                    inactive: {
                        options: {
                            activityPeriod: getActivityPeriod(step1Data.inactive),
                        },
                        send: step1Data.inactive.send,
                    },
                    prospects: {
                        options: {
                            addedBetweenRange: getAddedBetweenRange(step1Data.prospects),
                        },

                        send: step1Data.prospects.send,
                    },
                };
            } else if (step1Data.filterType === 'emails') {
                recipientLists = {
                    custom: {
                        options: {
                            emailListUploadId: step1Data.emailListUploadId,
                            listDescription: step1Data.listDescription,
                        },
                        send: true,
                    },
                };
            } else {
                assertNever(step1Data);
            }

            const response = await sendClubReadyCampaign({
                variables: {
                    input: {
                        emailTemplateId,
                        plannerId: publishData.plannerId,
                        recipientLists,
                        scheduleTime:
                            step2Data.formValues.scheduleOption === 'schedule'
                                ? DateTime.fromISO(step2Data.formValues.scheduled).toUTC().toISO()
                                : null,
                        subject: step2Data.formValues.subject,
                        uploadId,
                    },
                    locationIds,
                },
            });
            const responseData = response.data;
            if (!responseData) {
                mvNotifier.unexpectedErrorWithData(
                    t('ERRORS.BAD_REQUEST_ERRORS.REQUEST_RETURNED_NO_RESPONSE_DATA'),
                    response,
                );
                throw new Error();
            }

            if (responseData.sendClubReadyCampaign.__typename === 'ClubReadyApiError') {
                mvNotifier.unexpectedError(responseData.sendClubReadyCampaign.message);
                throw new Error();
            }

            return responseData.sendClubReadyCampaign.results.map(
                res =>
                    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                    ({
                        locationId: res.locationId,
                        scheduledPublish: res.scheduledPublish,
                    } as PublishAndReturnExecution),
            );
        });
    }

    React.useEffect(() => {
        if (verifiedSenderData) {
            const locations = relayConnectionToArray(verifiedSenderData.locations);
            const hasUnverifiedSender = locations.some(
                location => location.clubReady.__typename === 'ClubReady' && !location.clubReady.sender?.isVerified,
            );
            if (hasUnverifiedSender) {
                mvNotifier.warning(t('BUILD.PUBLISH.CLUB_READY.NO_SENDER_INFO_FOR_ONE_OR_MORE_LOCATIONS'));
            }
        }
    }, [mvNotifier, verifiedSenderData]);

    if (loadingVerifiedSenders) {
        return <Loading />;
    }

    return (
        <>
            <Formik<typeof initialValues>
                initialValues={initialValues}
                validate={handleValidation}
                validateOnChange
                validateOnMount
                onSubmit={handleSubmit}
            >
                {formik => (
                    <StyledForm>
                        <StyledMainTitle component="h3" variant="h3">
                            <Translate keyId="BUILD.PUBLISH.FINAL_CONFIRMATION" />
                        </StyledMainTitle>
                        <StyledDSFormGroup>
                            <StyledLabel component="label" variant="inherit">
                                <Translate keyId="BUILD.PUBLISH.CLUB_READY.SENDER" />
                            </StyledLabel>
                            {fragment.sender && (
                                <DSTypography component="div" variant="body1">
                                    {`${fragment.sender.name} <${fragment.sender.email}>`}
                                </DSTypography>
                            )}
                        </StyledDSFormGroup>
                        <StyledDSFormGroup>
                            <StyledLabel component="label" variant="inherit">
                                <Translate keyId="BUILD.PUBLISH.CLUB_READY.SUBJECT" />
                            </StyledLabel>
                            <DSTypography component="div" variant="body1">
                                {step2Data.formValues.subject}
                            </DSTypography>
                        </StyledDSFormGroup>
                        <StyledDSFormGroup>
                            <StyledLabel component="label" variant="inherit">
                                <Translate keyId="BUILD.PUBLISH.CLUB_READY.CLUB" />
                            </StyledLabel>
                            <DSTypography component="div" variant="body1">
                                {buildClubNameWithCountString(fragment.clubName ?? '', locationsToPublishTo.length)}
                            </DSTypography>
                        </StyledDSFormGroup>
                        <StyledDSFormGroup>
                            <StyledLabel component="label" variant="inherit">
                                <Translate keyId="BUILD.SCHEDULED_TIME" />
                            </StyledLabel>
                            <DSTypography component="div" variant="body1">
                                {step2Data.formValues.scheduleOption === 'immediate'
                                    ? t('BUILD.PUBLISH.SEND_IMMEDIATELY')
                                    : DateTime.fromISO(step2Data.formValues.scheduled).toFormat(
                                          'MMMM dd, yyyy @ hh:mma ZZ',
                                      )}
                            </DSTypography>
                        </StyledDSFormGroup>
                        <ClubReadyStats
                            locationIds={locationIds}
                            setListCountState={updatedListCountState => setListCountState(updatedListCountState)}
                            step1Data={step1Data}
                        />
                        <StyledDSFormGroup>
                            <DSFormControl>
                                <StyledLabel component="label" variant="inherit">
                                    <Translate keyId="COMMON.CONFIRMATION" />
                                </StyledLabel>
                                <StyledDSFormControlLabel
                                    checked={formik.values.confirmedDetails}
                                    control={
                                        <DSCheckbox
                                            disableRipple
                                            onChange={event =>
                                                formik.setFieldValue('confirmedDetails', event.target.checked)
                                            }
                                        />
                                    }
                                    label={t('BUILD.PUBLISH.CLUB_READY.I_CONFIRM_THAT_THE_ABOVE_DETAILS_ARE_CORRECT')}
                                />
                                <StyledDSAlert severity="warning">
                                    <Translate keyId="BUILD.PUBLISH.CLUB_READY.MEMBER_LIST_NOTICE" />
                                </StyledDSAlert>
                            </DSFormControl>
                        </StyledDSFormGroup>
                        <FormButtonGroup>
                            <DSGrid alignItems="center" container direction="row" justifyContent="space-between">
                                <DSGrid item>
                                    <DSButton
                                        startIcon={<ArrowBackIosIcon />}
                                        variant="outlined"
                                        onClick={gotoPrevStep}
                                    >
                                        <Translate keyId="COMMON.BACK" />
                                    </DSButton>
                                </DSGrid>
                                <DSGrid item>
                                    <StepCount />
                                </DSGrid>
                                <DSGrid item>
                                    <DSLoaderButton
                                        color="primary"
                                        disabled={
                                            !formik.isValid ||
                                            formik.isSubmitting ||
                                            loading ||
                                            (isCustomList && listCountState === ClubReadyListCountStates.INVALID)
                                        }
                                        endIcon={<ArrowForwardIosIcon />}
                                        loading={formik.isSubmitting || loading}
                                        type="submit"
                                        variant="contained"
                                    >
                                        <Translate keyId="COMMON.FINISH" />
                                    </DSLoaderButton>
                                </DSGrid>
                            </DSGrid>
                        </FormButtonGroup>
                    </StyledForm>
                )}
            </Formik>
        </>
    );
}

PublishToClubReadyStep3.fragments = {
    PUBLISH_TO_CLUB_READY_STEP_3_FRAGMENT,
};
PublishToClubReadyStep3.displayName = 'PublishToClubReadyStep3';
