import { ISendToSKUPrintProviderData, Nullable, PrintProvider, PrintProviderSKUId } from '@deltasierra/shared';
import { MvIdentity } from '../../../../account/mvIdentity';
import { ExpressionCallback } from '../../../../common/angularData';
import { ConfirmModal } from '../../../../common/confirmModal';
import { DataUtils } from '../../../../common/dataUtils';
import { FileUtils } from '../../../../common/fileUtils';
import { InteractionUtils } from '../../../../common/interactionUtils';
import { MvNotifier } from '../../../../common/mvNotifier';
import { UploadService } from '../../../../common/uploadService';
import { I18nService } from '../../../../i18n';
import { PrintProviderApiClient } from '../../../../integration/auth/printProviderApiClient';
import { PrintPublishService } from '../../../../integration/publish/printPublishService';
import { MvLocation } from '../../../../locations/mvLocation';
import { CurrencyService } from '../../../../payments/currencyService';
import { BuilderConstants } from '../../../builderConstants';
import { PdfBuilderService } from '../../../print/pdfBuilderService';
import { PublishTypes } from '../../publishResult';
import { BasePublishPrintController, NextViewMap } from '../basePublishPrintController';
import { FileFormatChoice } from './../../mvContentBuilderFileFormatCtrl';

export type BaseSKUPrintPublishViews = 'Details' | 'Summary';

export interface RequestDetails {
    quantity: number;
    instructions: string;
    replyEmail: string;
    skuData?: ISendToSKUPrintProviderData;
    shippingAttention: string | null;
    shippingCompanyName: string | null;
    shippingLineOne: string | null;
    shippingLineTwo: string | null;
    shippingLineThree: string | null;
}

export interface QuantityOption {
    label?: string;
    quantity: number;
    priceAmountInCents: number;
}

export interface SKUDetails {
    id: PrintProviderSKUId;
    sku: string;
    linearPricing: boolean;
    paymentUrl: string | null;
    paymentGateway?: string | null;
    priceAmountInCents: number;
    priceCurrency: string;
    quantityOptions?: QuantityOption[];
    orderInformation?: string | null;
}

export interface SKUDetailsWithQuantityOptions extends SKUDetails {
    quantityOptions: QuantityOption[];
}

export abstract class BasePublishPrintSKUController<TViews extends string> extends BasePublishPrintController<
    BaseSKUPrintPublishViews | TViews
> {
    // Props (inputs)
    public readonly openPaymentWindow!: ExpressionCallback<{ url: URL }>;

    public readonly fileFormatChoice!: FileFormatChoice;

    // State
    public readonly publishTypes = PublishTypes;

    public skuDetails!: SKUDetails;

    public chosenPrintProvider!: PrintProvider;

    public orderNumber?: string;

    // Futures
    public readonly generatePrintOrderNumber = this.interactionUtils.createFuture('Get print order number', () =>
        this.printProviderApiClient.getPrintOrderNumber().then(result => {
            this.orderNumber = result.printOrderNumber;
        }),
    );

    protected paymentGatewayInitialiser?: () => void;

    // eslint-disable-next-line max-params
    public constructor(
        $q: ng.IQService,
        interactionUtils: InteractionUtils,
        dataUtils: DataUtils,
        mvNotifier: MvNotifier,
        printProviderApiClient: PrintProviderApiClient,
        builderConstants: BuilderConstants,
        identity: MvIdentity,
        pdfBuilderService: PdfBuilderService,
        modal: ConfirmModal,
        fileUtils: FileUtils,
        i18n: I18nService,
        uploadService: UploadService,
        printPublishService: PrintPublishService,
        currencyService: CurrencyService,
        mvLocation: MvLocation,
        nextViewMap: NextViewMap<BaseSKUPrintPublishViews | TViews>,
    ) {
        super(
            $q,
            interactionUtils,
            dataUtils,
            mvNotifier,
            printProviderApiClient,
            builderConstants,
            identity,
            pdfBuilderService,
            modal,
            fileUtils,
            i18n,
            uploadService,
            printPublishService,
            currencyService,
            mvLocation,
            nextViewMap,
            false,
        );
    }

    public $onInit(): void {
        super.$onInit();
        if (this.publishData.selectedPrintProvider) {
            this.chosenPrintProvider = this.publishData.selectedPrintProvider;
        } else {
            throw new Error('Publish data is missing selected print provider');
        }

        if (this.publishData.skuDetails) {
            this.skuDetails = this.publishData.skuDetails;
        } else {
            throw new Error('Publish data is missing SKU details');
        }
    }

    public isSKUDetailsWithQuantityOptions(skuDetails: SKUDetails | null): skuDetails is SKUDetailsWithQuantityOptions {
        return !!(skuDetails && !skuDetails.linearPricing && skuDetails.quantityOptions);
    }

    public continueFromReview(): void {
        this.nextView();
        if (!this.skuDetails.linearPricing && this.skuDetails.quantityOptions) {
            this.requestDetails.quantity = this.skuDetails.quantityOptions[0].quantity; // Preselect a valid quantity
        }
    }

    public getQuantityOptions(): QuantityOption[] {
        const skuDetails = this.skuDetails;
        if (this.isSKUDetailsWithQuantityOptions(skuDetails)) {
            return skuDetails.quantityOptions;
        } else {
            return [];
        }
    }

    public getPaymentDisplayAmount(paymentAmount?: number): string {
        if (this.skuDetails && this.requestDetails.quantity) {
            const paymentDisplayAmount =
                paymentAmount ?? this.getDecimalPaymentAmount(this.skuDetails, this.requestDetails.quantity);

            return this.formatPaymentDisplayAmount(paymentDisplayAmount);
        }
        return '---';
    }

    public skipArtworkReview(): void {
        this.didReviewArtwork = false;
        this.setView('Details');
    }

    public getExtraPublishInfo(): string | undefined {
        if (this.orderNumber) {
            return this.i18n.text.build.print.extraInfo({
                orderNumber: this.orderNumber,
            });
        } else {
            return undefined;
        }
    }

    public continueFromDetails(): ng.IPromise<void> {
        return this.generatePrintOrderNumber.run({}).then(() => {
            this.setView('Summary');
        });
    }

    public goBackFromDetails(): void {
        this.setView('Details');
    }

    protected preparePlatformData(requestDetails: RequestDetails): void {
        this.paymentGatewayInitialiser = undefined;
        if (this.requestDetails.quantity) {
            const paymentAmountInCents = this.getTotalPriceInCents(this.skuDetails, this.requestDetails.quantity);
            const paymentAmount = this.getDecimalPaymentAmount(
                this.skuDetails,
                this.requestDetails.quantity,
                paymentAmountInCents,
            );
            if (paymentAmount && (this.orderNumber || this.skuDetails.paymentGateway === 'stripe')) {
                const paymentDisplayAmount = this.formatPaymentDisplayAmount(paymentAmount);

                if (
                    this.skuDetails.paymentGateway === 'bluepay' &&
                    this.orderNumber &&
                    this.skuDetails.paymentUrl !== null
                ) {
                    // Attempt to log in to four51
                    const paymentUrl = new URL(this.skuDetails.paymentUrl);
                    paymentUrl.searchParams.set('AMOUNT', paymentAmount.toFixed(2)); // Assumed string format: 123456.78
                    paymentUrl.searchParams.set('ORDER_ID', this.orderNumber);
                    this.paymentGatewayInitialiser = () => this.openPaymentWindow({ url: paymentUrl });
                }

                requestDetails.skuData = {
                    orderNumber: this.orderNumber,
                    priceAmount: paymentDisplayAmount,
                    priceAmountInCents: paymentAmountInCents,
                    priceCurrency: this.skuDetails.priceCurrency,
                    printProviderSKUId: this.skuDetails.id,
                };
            }
        }
    }

    protected getTotalPriceInCents(skuDetails: SKUDetails, quantity: number): number {
        let sumPrice: number | undefined;
        if (this.isSKUDetailsWithQuantityOptions(skuDetails)) {
            const quantityMatch = this.dataUtils.findByPredicate(
                option => option.quantity === this.requestDetails.quantity,
                skuDetails.quantityOptions,
            );
            if (quantityMatch) {
                sumPrice = quantityMatch.priceAmountInCents;
            } else {
                throw new Error("Couldn't find the requested quantity option!");
            }
        } else {
            sumPrice = skuDetails.priceAmountInCents * quantity;
        }
        return sumPrice;
    }

    protected getDecimalPaymentAmount(skuDetails: SKUDetails, quantity: number, paymentAmountInCents?: number): number {
        const decimalPayoutAmount = paymentAmountInCents ?? this.getTotalPriceInCents(skuDetails, quantity);

        return this.currencyService.getLargestUnit(skuDetails.priceCurrency, decimalPayoutAmount);
    }

    protected formatPaymentDisplayAmount(paymentAmount: number): string {
        return this.i18n.formatCurrency(paymentAmount, {
            code: this.skuDetails.priceCurrency,
            format: 'code',
        });
    }

    protected isExclusiveArtworkReview(): boolean {
        return !!this.publishData.hasExclusivePrintProvider;
    }

    protected requestDetailsHasAllRequiredFields(
        requestDetails: Nullable<RequestDetails>,
    ): requestDetails is RequestDetails {
        return !!(requestDetails.quantity && requestDetails.replyEmail);
    }
}
