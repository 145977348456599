import { DSTypography } from '@deltasierra/components';
import * as React from 'react';
import styled from 'styled-components';

const StyledTypography = styled(DSTypography)<{ $emphasis?: boolean }>`
    margin-top: 5px;
    margin-bottom: 10px;

    color: rgb(115, 115, 115);

    ${props => props.$emphasis && 'font-style: italic;'}
`;

export type PublishHelpBlockProps = {
    children?: React.ReactNode;
    emphasis?: boolean;
};

export function PublishHelpBlock({ children, emphasis }: PublishHelpBlockProps): JSX.Element {
    return <StyledTypography $emphasis={emphasis}>{children}</StyledTypography>;
}
PublishHelpBlock.displayName = 'PublishHelpBlock';
