/// <reference path="../../../typings/browser.d.ts" />
import { t } from '@deltasierra/shared';
import { $qSID, $rootScopeSID } from '../common/angularData';
import { MvNotifier } from '../common/mvNotifier';
import IQService = angular.IQService;
import IRootScopeService = angular.IRootScopeService;
import IHttpPromiseCallbackArg = angular.IHttpPromiseCallbackArg;

angular.module('app').factory('mvAuthInterceptor', [
    $qSID,
    $rootScopeSID,
    MvNotifier.SID,
    function ($q: IQService, $rootScope: IRootScopeService, mvNotifier: MvNotifier) {
        return {
            responseError(response: IHttpPromiseCallbackArg<{}>) {
                if (response.status == 401) {
                    $rootScope.$broadcast('unauthenticated');
                    mvNotifier.expectedError(t('ACCOUNT.NOTIFY_UNAUTHENTICATED'));
                    mvNotifier.disable(); // NOTE: never re-enabled; the page *must* be refreshed to get any more notifications!
                } else if (response.status == 403) {
                    $rootScope.$broadcast('unauthorized');
                    mvNotifier.expectedError(t('ACCOUNT.NOTIFY_UNAUTHORIZED'));
                }
                return $q.reject(response);
            },
        };
    },
]);
