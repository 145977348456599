import { gql, useLazyQuery } from '@apollo/client';
import { Autocomplete, Button, Icon, Loading, Well } from '@deltasierra/components';
import { useDebounce } from '@deltasierra/react-hooks';
import { t, AssignedLocation, UserId } from '@deltasierra/shared';
import * as React from 'react';
import { OptionalOneWayBinding } from '../../../common/angularData';
import { useAngularServiceContext } from '../../../common/componentUtils/angularServiceContexts';
import { withAngularIntegration } from '../../../common/componentUtils/reactComponentRegistration';
import { useFuture } from '../../../common/futures';
import { Translate } from '../../../directives/Translate';
import { SelectedUserRelatedInfo } from './SelectedUserRelatedInfo';
import {
    GetLocationAvailableUsers,
    GetLocationAvailableUsersVariables,
    GetLocationAvailableUsers_location_availableUsers_edges_node,
} from './__graphqlTypes/GetLocationAvailableUsers';

// !!!NOTE!!!
// We must use the default value of 50 records, or at least specify a low number. Until
// We have a better react autocomplete component. This is because it renders all the results, this
// Can cause a massive impact to the DOM render time. Keep it low until Material is implemented!
const GET_LOCATION_AVAILABLE_USERS = gql`
    query GetLocationAvailableUsers($id: ID!, $filter: LocationAvailableUsersFilterInput, $first: Int = 50) {
        location(id: $id) {
            id
            availableUsers(first: $first, filter: $filter) {
                edges {
                    node {
                        id
                        legacyId
                        firstName
                        lastName
                        username
                    }
                }
            }
        }
    }
`;

type AddExistingUserWellProps = {
    location?: AssignedLocation;
};

export const AddExistingUserWell: React.FC<AddExistingUserWellProps> = ({ location }) => {
    const locationUserApiClient = useAngularServiceContext('LocationUserApiClient');
    const mvNotifier = useAngularServiceContext('mvNotifier');
    const $route = useAngularServiceContext('$route');

    const [search, setSearch] = React.useState('');
    const debouncedSearch = useDebounce(search, 750);
    // eslint-disable-next-line camelcase
    const [selected, setSelected] = React.useState<GetLocationAvailableUsers_location_availableUsers_edges_node | null>(
        null,
    );

    const { state: addUserToLocationState, invoke: addUserToLocation } = useFuture(async () => {
        if (!selected || !location) {
            return;
        }
        try {
            await locationUserApiClient.addUserToLocation(UserId.from(selected.legacyId), location.id);
            mvNotifier.notify(
                t('AGENCY.CLIENT.LOCATION.USERS.USER_ADDED', {
                    firstName: selected.firstName,
                    lastName: selected.lastName,
                    location: location.title,
                }),
            );
            $route.reload();
        } catch (reason) {
            mvNotifier.unexpectedErrorWithData(t('AGENCY.CLIENT.LOCATION.USERS.FAILED_TO_ADD_EXISTING_USER'), reason);
        }
    }, [selected, location, locationUserApiClient, mvNotifier, $route]);

    const [getLocationAvailableUsers, { loading: getLocationAvailableUsersLoading, data }] = useLazyQuery<
        GetLocationAvailableUsers,
        GetLocationAvailableUsersVariables
    >(GET_LOCATION_AVAILABLE_USERS, { fetchPolicy: 'cache-and-network' });

    const options = data?.location?.availableUsers.edges.map(edge => edge.node) ?? [];

    React.useEffect(() => {
        if (debouncedSearch) {
            getLocationAvailableUsers({
                variables: {
                    filter: { search: debouncedSearch },
                    id: location?.graphqlId || '',
                },
            });
        }
    }, [getLocationAvailableUsers, debouncedSearch, location]);

    if (location === undefined) {
        return <Loading />;
    }

    return (
        <Well title={t('AGENCY.CLIENT.LOCATION.USERS.ADD_EXISTING_USER')}>
            <label>
                <Translate keyId="AGENCY.CLIENT.LOCATION.USERS.ADD_EXISTING_USER_LABEL" />
            </label>
            <Autocomplete
                fullWidth={true}
                inputValue={search}
                isLoadingOptions={getLocationAvailableUsersLoading}
                menuStyle={{ width: '100%' }}
                notFoundMessage={t('AGENCY.CLIENT.LOCATION.USERS.ADD_EXISTING_USER_NO_MATCHES')}
                options={options}
                placeholder={t('AGENCY.CLIENT.LOCATION.USERS.ADD_EXISTING_USER_PLACEHOLDER')}
                renderListOption={user => `${user.firstName} ${user.lastName} (${user.username})`}
                onClear={() => setSelected(null)}
                onSearch={setSearch}
                onSelect={user => setSelected(user)}
            />
            <div className="help-block">
                <Translate keyId="AGENCY.CLIENT.LOCATION.USERS.ADD_EXISTING_USER_HELP_TEXT" />
            </div>
            {selected && <SelectedUserRelatedInfo selectedUser={selected} />}
            <div className="text-right">
                <Button
                    dataCy="add-existing-user-button"
                    disabled={addUserToLocationState.isLoading || !selected}
                    theme="primary"
                    onClick={async () => addUserToLocation()}
                >
                    <Icon className="icon-space" icon="plus" />
                    <Translate keyId="COMMON.ADD" />
                </Button>
            </div>
        </Well>
    );
};
AddExistingUserWell.displayName = 'AddExistingUserWell';

export const AddExistingUserWellAngular = withAngularIntegration(AddExistingUserWell, 'addExistingUserWell', {
    location: OptionalOneWayBinding,
});
