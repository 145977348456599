import { gql } from '@apollo/client';

export const GET_ADMIN_CLUBREADY_INTEGRATION_FOR_CLIENT_DIALOG = gql`
    query GetAdminClubReadyIntegrationForClientDialog($id: ID!) {
        integration(id: $id) {
            ... on Integration {
                id
                isEnabled
                name
                connection {
                    ... on IntegrationConnection {
                        id
                        configuration {
                            ... on ClubReadyClientConfiguration {
                                id
                                chainId
                            }
                        }
                    }
                }
            }
        }
    }
`;
