import { DSTypography, ProgressBar, Translate } from '@deltasierra/components';
import { t, BuilderDocument, ISize, PreparedVideoRequestData } from '@deltasierra/shared';
import * as React from 'react';
import { useAngularServiceContext } from '../../../common/componentUtils/angularServiceContexts';
import { UploadContext } from '../../../common/uploadService';
import { MvContentBuilderPublishCtrl } from '../mvContentBuilderPublishCtrl';

export type VideoUploadProgressProps = {
    uploadContext: UploadContext;
};

export const VideoUploadProgress: React.FC<VideoUploadProgressProps> = ({ uploadContext }) => (
    <div>
        <DSTypography component="h3" style={{ marginBottom: '10px' }} variant="h3">
            <Translate keyId="BUILD.VIDEO.UPLOADING" />
        </DSTypography>
        {uploadContext.currentlyUploading.map(upload => (
            <div key={upload.name}>
                <ProgressBar
                    active
                    context="success"
                    progress={upload.progress}
                    progressLabel={upload.progress < 100 ? `${upload.progress}%` : t('COMMON.PROCESSING')}
                    striped
                />
            </div>
        ))}
    </div>
);

VideoUploadProgress.displayName = 'VideoUploadProgress';

/**
 * A workaround for getting the video processing context and using it in a React component.
 * The 'prepareVideo' function in 'videoPublishService' relies on a context object which it mutates
 * to provide components info on the status of the upload. This is fine with Angular components but
 * completely goes against React principles (as direct mutations don't trigger a re-render).
 * The hook wraps some logic that forces rerenders until the prepare/upload video is completed.
 *
 * @returns - [prepareVideo, videoProcessingContext]
 */
export const usePrepareVideo = (): [
    (doc: BuilderDocument, dimensions: ISize, publishController: MvContentBuilderPublishCtrl) => PromiseLike<PreparedVideoRequestData>,
    typeof videoProcessingContext,
] => {
    const videoPublishService = useAngularServiceContext('VideoPublishService');

    const [, forceUpdate] = React.useReducer((x: number) => x + 1, 0);

    const videoProcessingContext = React.useMemo(
        () => ({
            isProcessingOnServer: false,
            timeMark: 0,
            uploadContext: new UploadContext(),
            videoDuration: 0,
        }),
        [],
    );

    const uploadStarted = React.useMemo(() => ({ flag: false }), []);

    const uploadCompleted = React.useMemo(() => ({ flag: false }), []);

    const forceUpdateWhilePreparingVideo = React.useCallback(() => {
        if (videoProcessingContext.uploadContext.uploadInProgress && !uploadStarted.flag) {
            uploadStarted.flag = true;
        }
        if (!uploadCompleted.flag && (videoProcessingContext.uploadContext.uploadInProgress || !uploadStarted.flag)) {
            setTimeout(forceUpdateWhilePreparingVideo, 500);
        }
        forceUpdate();
    }, [uploadCompleted.flag, uploadStarted, videoProcessingContext.uploadContext.uploadInProgress]);

    const prepareVideo = async (
        doc: BuilderDocument,
        dimensions: ISize,
        publishController: MvContentBuilderPublishCtrl,
    ): Promise<PreparedVideoRequestData> => {
        forceUpdateWhilePreparingVideo();
        const result = await videoPublishService.prepareVideo(doc, dimensions, {
            contentBuilder: publishController.contentBuilder,
            ctrl: videoProcessingContext,
            fileCache: publishController.contentBuilder.fileCache,
        });
        uploadCompleted.flag = true;
        return result;
    };
    return [prepareVideo, videoProcessingContext];
};
