import * as React from 'react';
import { getAngularServiceContext } from './angularServiceContexts';

class ErrorBoundaryWrappedError extends Error {
    public constructor(public readonly underlyingError: any) {
        super();
    }
}

export type ErrorBoundaryProps = {
    /**
     * A render function that takes in the thrown error, and renders a component thusly
     *
     * @function
     * @param       {Error}                 error -     -The thrown error
     * @returns     {React.ReactElement}                -The error message
     */
    error?: (error: Error) => React.ReactElement;
};

export type ErrorBoundaryState = {
    error: Error | null;
};

/**
 * A JavaScript error in a part of the UI shouldn’t break the whole app.
 * To solve this problem for React users, React 16 introduces a new concept of an “error boundary”.
 *
 * Error boundaries are React components that catch JavaScript errors anywhere in their child component tree,
 * log those errors, and display a fallback UI instead of the component tree that crashed.
 * Error boundaries catch errors during rendering, in lifecycle methods, and in constructors of the whole tree below them.
 *
 * @example
 * ```js
 *      <ErrorBoundary
 *          error={(error: MyError) => <div>Something went wrong: {error.toString}</div>}
 *      >
 *          // If MyComponent throws any type of error, catch it and render an error component gracefully
 *          <MyComponent />
 *      </ErrorBoundary>
 * ```
 * @see https://reactjs.org/docs/error-boundaries.html
 */

export class ErrorBoundary extends React.PureComponent<ErrorBoundaryProps, ErrorBoundaryState> {
    public static contextType = getAngularServiceContext('mvNotifier');

    public displayName = 'ErrorBoundary';

    public context!: React.ContextType<typeof ErrorBoundary.contextType>;

    public constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { error: null };
        this.promiseRejectionHandler.bind(this);
    }

    public componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
        console.error('ErrorBoundary caught an error in React', error);
        console.error('ErrorBoundary error component stack', errorInfo.componentStack);
        this.notifyOfError(error);
    }

    public static getDerivedStateFromError(error: unknown): ErrorBoundaryState {
        if (error instanceof Error) {
            return {
                error,
            };
        }
        return {
            error: new ErrorBoundaryWrappedError(error),
        };
    }

    public componentDidMount(): void {
        // Add an event listener to the window to catch unhandled promise rejections & stash the error in the state
        window.addEventListener('unhandledrejection', this.promiseRejectionHandler);
    }

    public componentWillUnmount(): void {
        window.removeEventListener('unhandledrejection', this.promiseRejectionHandler);
    }

    public render(): React.ReactNode {
        if (this.state.error !== null && this.props.error) {
            return this.props.error(this.state.error);
        }

        return this.props.children;
    }

    private notifyOfError(error: Error | any): void {
        this.context.unexpectedError(error);
    }

    private promiseRejectionHandler(event: PromiseRejectionEvent): void {
        this.notifyOfError(event.reason);
    }
}
