import { ExternalAuthApp, CreateNewAuthApp, UpdateExternalAuthApp, ExternalAuthToken } from '@deltasierra/shared';

import { getData } from '../common/httpUtils';
import { $httpSID } from '../common/angularData';

export class ExternalAuthService {
    static SID = 'ExternalAuthService';

    static readonly $inject : string[] = [
        $httpSID,
    ];

    constructor(
        private $http: ng.IHttpService,
    ) {
    }

    getAllExternalAuthApps(): ng.IPromise<ExternalAuthApp[]> {
        return this.$http.get<ExternalAuthApp[]>('/api/externalAuth/apps')
        .then(getData);
    }

    getExternalAuthApp(id: number): ng.IPromise<ExternalAuthApp> {
        return this.$http.get<ExternalAuthApp>(`/api/externalAuth/apps/${id}`)
        .then(getData);
    }

    getExternalAuthAppConnections(id: number): ng.IPromise<ExternalAuthToken[]> {
        return this.$http.get<ExternalAuthToken[]>(`/api/externalAuth/apps/${id}/connections`)
        .then(getData);
    }

    deleteExternalAuthApp(id: number): ng.IPromise<ExternalAuthApp> {
        return this.$http.delete<ExternalAuthApp>(`/api/externalAuth/apps/${id}`)
        .then(getData);
    }

    createExternalAuthApp(payload: CreateNewAuthApp): ng.IPromise<ExternalAuthApp> {
        return this.$http.post<ExternalAuthApp>('/api/externalAuth/apps', payload)
        .then(getData);
    }

    updateExternalAuthApp(id: number, payload: UpdateExternalAuthApp): ng.IPromise<ExternalAuthApp> {
        return this.$http.patch<ExternalAuthApp>(`/api/externalAuth/apps/${id}`, payload)
        .then(getData);
    }

    deleteExternalAuthAppConnection(token: ExternalAuthToken) {
        return this.$http.delete(`/api/externalAuth/apps/${token.appId}/connections/${token.id}`)
        .then(getData);
    }
}
angular.module('app').service(ExternalAuthService.SID, ExternalAuthService);
