import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAngularServiceContext } from '../common/componentUtils/angularServiceContexts';

type AdminRouteWrapperProps = {
    children: React.ReactNode;
};

export function AdminRouteWrapper({ children }: AdminRouteWrapperProps): JSX.Element {
    const mvIdentity = useAngularServiceContext('mvIdentity');
    const isAdmin = React.useMemo(() => mvIdentity.isAdmin(), [mvIdentity]);
    if (isAdmin) {
        return <>{children}</>;
    }
    return <Navigate replace to={{ pathname: '/' }} />;
}
AdminRouteWrapper.displayName = 'AdminRoute';
