import { gql, useMutation } from '@apollo/client';
import {
    Button,
    FileUpload,
    Loading,
    ModalBody,
    ModalFooter,
    ModalWithHeader,
    PuppetCheckedInput,
    TagsInput,
    Translate,
} from '@deltasierra/components';
import { noop } from '@deltasierra/object-utilities';
import { protocolRelativeUrlToHttpsUrl, t } from '@deltasierra/shared';
import * as React from 'react';
import { CreateAssetFileInput } from '../../../../../__graphqlTypes/globalTypes';
import { useAngularServiceContext } from '../../../common/componentUtils/angularServiceContexts';
import { DateOnlyPicker } from '../../../common/form/dates/DateOnlyPicker';
import { AssetModalFormElementContainer } from '../styled';
import { useUploadAssetsState } from './state';
import { CreateAssetFiles, CreateAssetFilesVariables } from './__graphqlTypes/CreateAssetFiles';

const CREATE_ASSET_FILES_MUTATION = gql`
    mutation CreateAssetFiles($input: CreateAssetFileInput!) {
        createAssetFile(input: $input) {
            __typename
            ... on AssetFile {
                id
                title
                thumbnails {
                    medium {
                        url
                    }
                }
                collection {
                    id
                }
                type
            }
        }
    }
`;

export type UploadAssetFilesModalProps = {
    folderOrCollectionId: string;
    show: boolean;
    onClose?: () => void;
};

export const UploadAssetFilesModal: React.FC<UploadAssetFilesModalProps> = ({ show, onClose = noop, ...props }) => (
    <ModalWithHeader
        closeOnBackdropClick={false}
        show={show}
        title={t('COMMON.UPLOAD_FILES')}
        onClose={() => onClose()}
    >
        <UploadAssetFilesModalContent onClose={onClose} {...props} />
    </ModalWithHeader>
);
UploadAssetFilesModal.displayName = 'UploadAssetFilesModal';

type UploadAssetFilesModalContentProps = {
    folderOrCollectionId: string;
    onClose: () => void;
};

// eslint-disable-next-line max-lines-per-function
const UploadAssetFilesModalContent: React.FC<UploadAssetFilesModalContentProps> = ({
    folderOrCollectionId,
    onClose,
}) => {
    const [createAssetFile, { loading: isSaving }] = useMutation<CreateAssetFiles, CreateAssetFilesVariables>(
        CREATE_ASSET_FILES_MUTATION,
        {
            update: (cache, result) => {
                if (
                    result.data?.createAssetFile.__typename === 'AssetFile' &&
                    result.data.createAssetFile.collection.id
                ) {
                    cache.modify({
                        fields: {
                            assetCount(cachedValue: number) {
                                return cachedValue + 1;
                            },
                        },
                        id: `Collection:${result.data.createAssetFile.collection.id}`,
                    });
                }
            },
        },
    );
    const uploadService = useAngularServiceContext('uploadService');
    const [tags, setTags] = React.useState<Array<{ id: string; tags: string[] }>>([{ id: 'dummy', tags: [] }]);
    const [expiryChecked, setExpiryChecked] = React.useState(false);
    const [expires, setExpires] = React.useState<Date | null>(null);
    const [fileState, fileDispatch] = useUploadAssetsState();

    React.useEffect(() => {
        const POLL_INTERVAL = 1000;
        let thumbnailPollIntervalId: number;
        const completedFilesWithoutThumbnails = fileState.completed.filter(
            ({ upload }) => upload.thumb256x256url === null,
        );

        if (fileState.completed.length > 0 && completedFilesWithoutThumbnails.length > 0) {
            thumbnailPollIntervalId = window.setInterval(() => {
                completedFilesWithoutThumbnails.forEach(async ({ upload }) => {
                    // Thumbnails are only generated for image & video files
                    const isVideoFormat = upload.contentType.includes('video');
                    if ((upload.isImage || isVideoFormat) && upload.thumb256x256url === null) {
                        const { id: uploadId, key } = upload;
                        const { thumb256x256url } = await uploadService.getUploadThumbnail(uploadId, key);
                        if (thumb256x256url !== null) {
                            fileDispatch({
                                payload: { id: upload.id, thumb256x256url },
                                type: 'UPDATE_FILE_THUMBNAIL',
                            });
                        }
                    }
                });
            }, POLL_INTERVAL);
        }

        return () => clearInterval(thumbnailPollIntervalId);
    }, [uploadService, fileDispatch, fileState.completed]);

    const handleOnSelectFiles = React.useCallback(
        (files: File[]) => {
            fileDispatch({ payload: files, type: 'ADD' });
            files.forEach(file => {
                Promise.all(uploadService.upload([file], 'assetLibrary', [], {}, { suppressNotifications: true }))
                    .then(([upload]) => {
                        fileDispatch({ payload: [{ file, upload }], type: 'COMPLETED' });
                    })
                    .catch(() => fileDispatch({ payload: [file], type: 'FAILED' }));
            });
        },
        [uploadService, fileDispatch],
    );

    const handleSave = async () => {
        const completed = fileState.completed.slice(0, fileState.completed.length - 1);
        const last = fileState.completed[fileState.completed.length - 1];
        const promises = completed.map(async ({ upload }) => {
            const input: CreateAssetFileInput = {
                expires: expires?.toISOString(),
                folderOrCollectionId,
                sizeInBytes: upload.size || 0,
                tags: tags[0]?.tags || [],
                title: upload.filename,
                url: protocolRelativeUrlToHttpsUrl(upload.url!),
            };
            await createAssetFile({
                variables: { input },
            });
        });
        await Promise.all(promises);

        await createAssetFile({
            awaitRefetchQueries: true,
            refetchQueries: ['GetCollectionForCollectionAssets'],
            variables: {
                input: {
                    expires: expires?.toISOString(),
                    folderOrCollectionId,
                    sizeInBytes: last.upload.size || 0,
                    tags: tags[0]?.tags || [],
                    title: last.upload.filename,
                    url: protocolRelativeUrlToHttpsUrl(last.upload.url!),
                },
            },
        });
        onClose();
    };

    return (
        <>
            <ModalBody>
                <FileUpload
                    completedFiles={fileState.completed.map(({ file, upload: { thumb256x256url } }) => ({
                        file,
                        thumbnail: thumb256x256url ? thumb256x256url.toString() : undefined,
                    }))}
                    dataCy="file-upload-input"
                    failedFiles={fileState.failed}
                    processingFiles={fileState.processing}
                    onRemoveFile={file => fileDispatch({ payload: file, type: 'REMOVE' })}
                    onSelectFiles={handleOnSelectFiles}
                />
                <div className=" form-horizontal row">
                    <TagsInput label={t('COMMON.TAGS')} tags={tags} onChange={setTags} />
                    <br />
                    <AssetModalFormElementContainer>
                        <div className="form-group">
                            <label className="col-sm-2 control-label">
                                <Translate keyId="COMMON.EXPIRY" />
                            </label>
                            <div className="col-sm-10">
                                <PuppetCheckedInput
                                    checked={expiryChecked}
                                    label={t('ASSET_LIBRARY.AUTOMATICALLY_EXPIRE_THESE_ASSETS')}
                                    value={'expiry'}
                                    onChange={ev => setExpiryChecked(ev.target.checked)}
                                />
                            </div>
                        </div>
                        {expiryChecked && <DateOnlyPicker value={expires} onChange={val => setExpires(val)} />}
                    </AssetModalFormElementContainer>
                </div>
            </ModalBody>
            <ModalFooter>
                {isSaving && <Loading inline size="small" />}
                <Button disabled={isSaving} onClick={() => onClose()}>
                    {t('COMMON.CANCEL')}
                </Button>
                <Button
                    dataCy="save-asset-button"
                    disabled={isSaving || fileState.processing.length !== 0 || fileState.completed.length <= 0}
                    theme="success"
                    onClick={handleSave}
                >
                    {t('COMMON.SAVE')}
                </Button>
            </ModalFooter>
        </>
    );
};
UploadAssetFilesModalContent.displayName = 'UploadAssetFilesModalContent';
