/// <reference path="../../../../typings/browser.d.ts" />
import { MapLocationController } from './controller';

export const mapLocationSID = 'dsMapLocation';
export const mapLocationConfig = {
    restrict: 'E',
    replace: true,
    scope: {
        initialLocation: '=',
        selectedLocation: '=',
        mapStyle: '=?',
        editable: '=?',
        zoom: '=?',
    },
    templateUrl: '/partials/account/mapLocation/template',
    controller: MapLocationController,
    controllerAs: 'ctrl',
    bindToController: true,
};

angular.module('app').directive(mapLocationSID, [() => mapLocationConfig]);
