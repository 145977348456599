import { useLocalStorage } from '@deltasierra/react-hooks';
import * as React from 'react';
import {
    CurrentLocationIdType,
    CurrentLocationStorageDispatchContext,
    CurrentLocationStorageDispatchContextType,
} from '../contexts';
import { CurrentLocationContextLocalStorageKey } from './useCurrentLocationStorageState';

/**
 * Use the current client from the local storage. You probably don't need to use.
 * Use `useCurrentLocation` instead.
 *
 * @returns The local storage actions for the current client key
 */
export function useCurrentLocationLocalStorageDispatch(): Exclude<
    CurrentLocationStorageDispatchContextType,
    undefined
> {
    const dispatchToContext = React.useContext(CurrentLocationStorageDispatchContext);
    const [, dispatchToLocalStorage] = useLocalStorage<CurrentLocationIdType>(CurrentLocationContextLocalStorageKey);
    return newValue => {
        dispatchToLocalStorage(newValue ?? null);
        dispatchToContext?.(newValue);
    };
}
