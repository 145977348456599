import {
    EmailAddress,
    EmailProcessingFailedEvent,
    EmailProcessingFinishedEvent,
    ExportPlatforms,
    FitwareSimpleMemberList,
    Html,
    IPublishEmailResponseLegacy,
    LocationId,
    SendFitwareEmailRequest,
    UploadId,
} from '@deltasierra/shared';
import {
    $scopeSID,
    actualComponent,
    ExpressionBinding,
    ExpressionCallback,
    OneWayBinding,
} from '../../../../common/angularData';
import { ConfirmModal, confirmModalSID } from '../../../../common/confirmModal';
import { InteractionUtils } from '../../../../common/interactionUtils';
import { SentryService } from '../../../../common/sentryService';
import { UploadContext } from '../../../../common/uploadService';
import { I18nService } from '../../../../i18n';
import { FitwarePublishService } from '../../../../integration/publish/fitwarePublishService';
import { SocketService } from '../../../../sockets/socketService';
import { BuilderConstants, builderConstantsSID } from '../../../builderConstants';
import { PublishResult } from '../../../publish/publishResult';
import { EmailPublishData } from '../../emailPublishData';
import { EmailPublishService } from '../emailPublishService';
import IScope = angular.IScope;

enum PublishStep {
    MemberLists,
    Subject,
    SendPreview,
    ScheduledTime,
    Confirm,
    Publishing,
}

interface CommonPublishData {
    memberLists: FitwareSimpleMemberList[];
    scheduledTime?: Date;
    subject: string;
}

interface PublishEmailToFitwareCtrlScope extends IScope {
    uploadContext?: UploadContext;
}

export class PublishEmailToFitwareCtrl {
    public static readonly SID = 'PublishEmailToFitwareCtrl';

    public publishData!: EmailPublishData;

    public readonly onGoBack!: ExpressionCallback<Record<keyof never, unknown>>;

    public options: {
        memberLists: FitwareSimpleMemberList[];
    } = {
        memberLists: [],
    };

    public selected: {
        memberLists: FitwareSimpleMemberList[];
    } = {
        memberLists: [],
    };

    public PublishStep = PublishStep;

    public step = PublishStep.MemberLists;

    public fitwarePublishData!: {
        previewRecipient: EmailAddress;
        scheduledTime: Date;
        subject: string;
    };

    public isToBeScheduled = false;

    public minScheduledDate: Date = new Date();

    public confirmed = false;

    public commonData: CommonPublishData | undefined;

    public readonly sendPreviewEmail = this.interactionUtils.createFuture(
        'send preview e-mail',
        (context: { locationId: LocationId; previewRecipient: EmailAddress }) =>
            this.emailPublishService
                .rectifyAllImagesAndConvertToString(
                    this.publishData.builderDocument,
                    this.publishData.htmlDocument,
                    this.publishData.fileCache,
                    this.$scope,
                )
                .then((html: Html) =>
                    this.fitwarePublishService.sendPreviewEmail(
                        context.locationId,
                        context.previewRecipient,
                        html,
                        this.fitwarePublishData.subject,
                    ),
                ),
    );

    public static readonly $inject: string[] = [
        $scopeSID,
        I18nService.SID,
        InteractionUtils.SID,
        builderConstantsSID,
        FitwarePublishService.SID,
        EmailPublishService.SID,
        SocketService.SID,
        confirmModalSID,
        SentryService.SID,
    ];

    public constructor(
        protected readonly $scope: PublishEmailToFitwareCtrlScope,
        protected readonly i18n: I18nService,
        protected readonly interactionUtils: InteractionUtils,
        protected readonly builderConstants: BuilderConstants,
        protected readonly fitwarePublishService: FitwarePublishService,
        protected readonly emailPublishService: EmailPublishService,
        protected readonly socketService: SocketService,
        protected readonly confirmModal: ConfirmModal,
        protected readonly sentryService: SentryService,
    ) {}

    public $onInit(): void {
        this.fitwarePublishData = {
            previewRecipient: EmailAddress.from(''),
            scheduledTime: new Date(),
            subject: this.publishData.builderDocument.subject ?? '',
        };
        if (this.publishData.plannerDetails?.date) {
            this.isToBeScheduled = true;
            this.fitwarePublishData.scheduledTime = this.publishData.plannerDetails.date;
        }
    }

    public getMemberListLabel(entry: FitwareSimpleMemberList): string {
        return entry.name;
    }

    public onMemberListsLoaded(options: FitwareSimpleMemberList[]): void {
        this.options.memberLists = options;
    }

    public onMemberListsSelectionChange(values: FitwareSimpleMemberList[]): void {
        this.selected.memberLists = values;
    }

    public cancelPublishing(): void {
        this.$scope.$emit(this.builderConstants.EVENTS.PUBLISH_CANCEL);
    }

    public goToNextStep(): void {
        const newStep = this.step + 1;
        if (PublishStep[newStep] !== undefined && PublishStep[newStep] !== null) {
            this.step = newStep;
            if (this.step === PublishStep.Confirm) {
                // Reset confirmation status when navigating to this page, in case the user went back and changed a value
                this.confirmed = false;
            }
        }
    }

    public goToPreviousStep(): void {
        const newStep = this.step - 1;
        if (PublishStep[newStep] !== undefined && PublishStep[newStep] !== null) {
            this.step = newStep;
        } else {
            this.confirmModal.open(
                this.i18n.text.build.publish.goBackConfirmTitle(),
                this.i18n.text.build.publish.goBackConfirmMessage(),
                () => {
                    this.onGoBack({});
                },
            );
        }
    }

    public onSubmitSendPreview(): ng.IPromise<void> {
        if (this.fitwarePublishData.previewRecipient.trim()) {
            return this.sendPreviewEmail
                .run({
                    locationId: this.publishData.location.id,
                    previewRecipient: this.fitwarePublishData.previewRecipient,
                })
                .then(() => {
                    this.goToNextStep();
                });
        } else {
            this.goToNextStep();
        }
        return Promise.resolve();
    }

    public onSubmitConfirm(): void {
        this.commonData = this.getCommonDataForPublish();
        this.goToNextStep();
    }

    public finish: () => void = () => {
        this.$scope.$emit(
            this.builderConstants.EVENTS.PUBLISH_FINISH,
            new PublishResult(
                ExportPlatforms.Fitware,
                this.isToBeScheduled ? this.fitwarePublishData.scheduledTime : null,
            ),
        );
    };

    public publish: (
        locationId: LocationId,
        uploadId: UploadId,
        commonData: CommonPublishData,
    ) => PromiseLike<IPublishEmailResponseLegacy> = (
        locationId: LocationId,
        uploadId: UploadId,
        commonData: CommonPublishData,
    ) => {
        const requestBody: SendFitwareEmailRequest = {
            auditData: {
                builderTemplateId: this.publishData.templateId,
                linkedAssetLibraryAssetIds: this.publishData.linkedAssetLibraryAssetIds,
                plannerId: this.publishData.plannerDetails ? this.publishData.plannerDetails.id : undefined,
            },
            channelData: {
                mailingLists: commonData.memberLists.map(memberList => memberList.id),
                scheduledTime: commonData.scheduledTime,
                subject: commonData.subject,
            },
            uploadId,
        };
        return this.socketService.startWorkflow(
            () => this.fitwarePublishService.sendEmail(locationId, requestBody),
            EmailProcessingFinishedEvent,
            EmailProcessingFailedEvent,
            undefined,
            () => this.sentryService.captureException('Socket message timeout alert', {}),
        );
    };

    protected getCommonDataForPublish(): CommonPublishData {
        return {
            memberLists: this.selected.memberLists,
            scheduledTime: this.isToBeScheduled ? this.fitwarePublishData.scheduledTime : undefined,
            subject: this.fitwarePublishData.subject,
        };
    }
}

export const publishEmailToFitwareSID = 'publishEmailToFitware';

export const publishEmailToFitwareConfig = actualComponent(
    PublishEmailToFitwareCtrl,
    '/partials/contentBuilder/email/publish/fitware/publishEmailToFitware',
    {
        onGoBack: ExpressionBinding,
        publishData: OneWayBinding,
    },
);

angular.module('app').component(publishEmailToFitwareSID, publishEmailToFitwareConfig);
