import { ServiceName } from '@deltasierra/integrations/integration-types';
import {
    ClientId,
    GetServiceInfoResponse,
    IAccessCheckMultiLocationPlatformName,
    IClientAccessCheckPlatformName,
    ILocationAccessCheckPlatformName,
    LocationId,
    ProfileApi,
    Roles,
    SanitizedUser,
    UpdateProfileDto,
    Upload,
    User,
    UserId,
    emptyRequest,
    getAuthorizedAndConfiguredLocationsApiRoute,
    getIsClientAuthorizedAndConfiguredApiRoute,
    getIsLocationAuthorizedAndConfiguredApiRoute,
    justBody,
    justParams,
} from '@deltasierra/shared';
import type { IHttpPromise, IHttpPromiseCallbackArg, IHttpService, IPromise, IQService, IWindowService } from 'angular';
import { $httpSID, $qSID, $windowSID } from '../common/angularData';
import { CurrentClientContextLocalStorageKey } from '../common/components/ClientPicker/hooks/useCurrentClientStorageState';
import { getData, invokeApiRoute } from '../common/httpUtils';
import { BrandsEngagementDateRangeLocalStorageKey } from '../domain/engagement/components/EngagementPickerHeader/use-date-range-for-engagement-page';
import { GraphqlService } from '../graphql/GraphqlService';
import { MvIdentity } from './mvIdentity';

interface AuthenticateResponse {
    success: boolean;
    user: SanitizedUser;
}

export type LinkModelType = 'agency' | 'client' | 'location';

export class MvAuth {
    public static readonly SID = 'mvAuth' as const;

    public static readonly $inject: string[] = [
        $httpSID,
        $windowSID,
        MvIdentity.SID,
        $qSID,
        '$rootScope',
        GraphqlService.SID,
    ];

    public constructor(
        private readonly $http: IHttpService,
        private readonly $window: IWindowService,
        private readonly mvIdentity: MvIdentity,
        private readonly $q: IQService,
        private readonly $rootScope: ng.IRootScopeService,
        protected readonly graphqlService: GraphqlService,
    ) {}

    public async authenticateUser(username: string, password: string, requestToken?: string): Promise<boolean> {
        return this.$http
            .post<AuthenticateResponse>(
                '/login',
                {
                    password,
                    username,
                },
                {
                    params: { requestToken },
                },
            )
            .then((response: IHttpPromiseCallbackArg<AuthenticateResponse>) => {
                if (response.data && response.data.success) {
                    const user = response.data.user;
                    this.mvIdentity.currentUser = user;
                    this.$rootScope.$broadcast('authenticated', user);
                    return true;
                } else {
                    return false;
                }
            });
    }

    public async updateCurrentUser(
        newUserData: UpdateProfileDto & { id?: UserId; profilePicture?: Upload },
    ): Promise<void> {
        const clonedData = {
            ...newUserData,
        };

        const profilePicture = clonedData.profilePicture;
        delete clonedData.profilePicture;
        delete clonedData.id;

        return invokeApiRoute(this.$http, ProfileApi.updateProfile, justBody(clonedData)).then(() => {
            const updatedUser: User = {
                ...this.mvIdentity.currentUser!,
                ...clonedData,
                profilePicture,
            };
            this.mvIdentity.currentUser = updatedUser;
        });
    }

    public async logoutUser(redirect = true): Promise<void> {
        await this.onBeforeLogout();
        await this.$http.post('/logout', { logout: true });
        this.onAfterLogout(redirect);
    }

    public authorizeCurrentUserForRoute(...roles: Roles[]): IPromise<boolean> {
        for (const role of roles) {
            if (this.mvIdentity.isAuthorized(role)) {
                return this.$q.resolve(true);
            }
        }
        return this.$q.reject(new Error('Not authorised'));
    }

    public authorizeAuthenticatedUserForRoute(): IPromise<boolean> {
        if (this.mvIdentity.isAuthenticated()) {
            return this.$q.resolve(true);
        } else {
            return this.$q.reject(new Error('Not authorised'));
        }
    }

    public authorizeService(
        serviceName: ServiceName,
        redirectPath: string,
        linkModelType: LinkModelType,
        linkModelId: number | null | undefined,
    ): void {
        let path = `/auth/${serviceName}?redirectPath=${encodeURIComponent(redirectPath)}`;
        if (linkModelId) {
            path += `&${linkModelType}Id=${encodeURIComponent(`${linkModelId}`)}`;
        }
        window.location.assign(path);
    }

    public deauthorizeService(
        serviceName: ServiceName,
        linkModelType: LinkModelType,
        linkModelId: number | null | undefined,
    ): IHttpPromise<unknown> {
        let path = `/api/auth/${serviceName}/${linkModelType}`;
        if (linkModelId) {
            path += `/${linkModelId}`;
        }
        return this.$http.delete(path);
    }

    public getServiceAccessInfo(
        serviceName: ServiceName,
        linkModelType: LinkModelType,
        linkModelId: number | null | undefined,
    ): ng.IPromise<GetServiceInfoResponse> {
        let path = `/api/auth/${serviceName}/${linkModelType}`;
        if (linkModelId) {
            path += `/${linkModelId}`;
        }
        return this.$http.get<GetServiceInfoResponse>(path).then(res => res.data!);
    }

    public hasServiceAccess(
        serviceName: ServiceName,
        linkModelType: LinkModelType,
        linkModelId: number,
    ): ng.IPromise<boolean> {
        let path = `/api/auth/${serviceName}/${linkModelType}`;
        if (linkModelId) {
            path += `/${linkModelId}`;
        }
        return this.$http.get<{ result: boolean }>(path).then(res => !!(res.data && res.data.result));
    }

    public async canConfigureService(
        serviceName: ServiceName,
        linkModelType: LinkModelType,
        linkModelId: number,
    ): Promise<boolean> {
        const path = `/api/auth/${serviceName}/${linkModelType}/${linkModelId}/canConfigure`;
        const response = await this.$http.get<{ canConfigure: boolean }>(path);
        return response.data!.canConfigure;
    }

    public isAuthorizedAndConfigured(
        serviceName: ILocationAccessCheckPlatformName,
        locationId: LocationId,
    ): ng.IPromise<boolean> {
        const apiRoute = getIsLocationAuthorizedAndConfiguredApiRoute(serviceName);
        return invokeApiRoute(
            this.$http,
            apiRoute,
            justParams({
                modelId: locationId,
            }),
        ).then(result => result.result);
    }

    public isClientAuthorizedAndConfigured(
        serviceName: IClientAccessCheckPlatformName,
        clientId: ClientId,
    ): ng.IPromise<boolean> {
        const apiRoute = getIsClientAuthorizedAndConfiguredApiRoute(serviceName);
        return invokeApiRoute(
            this.$http,
            apiRoute,
            justParams({
                modelId: clientId,
            }),
        ).then(result => result.result);
    }

    public async getAuthorizedAndConfiguredLocations(
        serviceName: IAccessCheckMultiLocationPlatformName,
    ): Promise<LocationId[]> {
        const apiRoute = getAuthorizedAndConfiguredLocationsApiRoute(serviceName);
        return invokeApiRoute(this.$http, apiRoute, emptyRequest());
    }

    public canAccessMultipleLocations(): ng.IPromise<{ result: boolean }> {
        return this.$http.get<{ result: boolean }>('/api/users/multipleLocationsAccess').then(getData);
    }

    public requestPasswordReset(username: string): IHttpPromise<unknown> {
        return this.$http.post('/api/requestPasswordReset', {
            username,
        });
    }

    public resetPassword(token: string, username: string, password: string): ng.IPromise<{ success: boolean }> {
        return this.$http
            .post<{ success: boolean }>('/api/resetPassword', {
                password,
                token,
                username,
            })
            .then(response => response.data!);
    }

    private async onBeforeLogout(): Promise<void> {
        // Don't wanna clear everything
        // For instance, we store banner messages read status in local storage.
        window.localStorage.removeItem(CurrentClientContextLocalStorageKey);
        window.localStorage.removeItem(BrandsEngagementDateRangeLocalStorageKey);
        await this.graphqlService.resetCache();
    }

    private onAfterLogout(redirect: boolean): void {
        this.mvIdentity.currentUser = undefined;
        this.$rootScope.$broadcast('unauthenticated');
        if (redirect) {
            this.$window.location.assign('/logout');
        }
    }
}

angular.module('app').service(MvAuth.SID, MvAuth);
