import * as React from 'react';
import { Button } from '@deltasierra/components';
import { Translate } from '../../../directives/Translate';
import { CreateCollectionModal } from './CreateCollectionModal';

export const CreateCollectionButton: React.FC = () => {
    const [modalShown, setModalShown] = React.useState<boolean>(false);

    const hideModal = React.useCallback(() => setModalShown(false), []);
    const showModal = React.useCallback(() => setModalShown(true), []);

    return (
        <>
            <Button dataCy="new-collection-button" size="xs" theme="success" onClick={showModal}>
                <span className="glyphicon glyphicon-plus icon-space" />
                <Translate keyId="COMMON.NEW_COLLECTION" />
            </Button>
            <CreateCollectionModal show={modalShown} onClose={hideModal} />
        </>
    );
};
CreateCollectionButton.displayName = 'CreateCollectionButton';
