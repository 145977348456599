/// <reference path="../../../../typings/browser.d.ts" />

import { MvContentBuilderPublishCtrl } from './mvContentBuilderPublishCtrl';

export const contentBuilderPublishSID = 'contentBuilderPublish';
export const contentBuilderPublishConfig = {
    restrict: 'E',
    scope: {},
    templateUrl: '/partials/contentBuilder/publish/publish',
    controller: MvContentBuilderPublishCtrl,
    controllerAs: 'ctrl',
    bindToController: {
        templateId: '=',
        plannerDetails: '=',
        location: '=',
        // OnFinish: '=',
        selectedBuilderTemplateFormats: '=',
        contentBuilder: '=',
        fileCache: '=',
    },
};

angular.module('app').directive(contentBuilderPublishSID, [() => contentBuilderPublishConfig]);
