import { Translate } from '@deltasierra/components';
import * as React from 'react';
import { GetClientsForCopyTemplateModal_brand_clients_edges_node as ClientNode } from './__graphqlTypes/GetClientsForCopyTemplateModal';
import { TargetClientCheckboxContainer } from './TargetClientCheckboxContainer';

export type CopyTemplateClientPickerProps = {
    clients: ReadonlyArray<ClientNode>;
    onChange: (selected: string[]) => void;
    onSearchTermChange?: (newTerm?: string) => void;
    searchTerm: string;
    selected: ReadonlyArray<string>;
};

export const CopyTemplateClientPicker: React.FC<CopyTemplateClientPickerProps> = ({
    clients,
    onChange,
    searchTerm,
    selected,
}) => (
    <>
        {clients.length === 0 ? (
            <Translate keyId="BUILD.TEMPLATE_ADMIN.COPY_NO_CLIENTS" />
        ) :
            clients
                .filter(client => client.title.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()))
                .map(client => (
                    <TargetClientCheckboxContainer
                        item={client}
                        key={client.id}
                        selected={selected}
                        onChange={onChange}
                    />
                ))
        }
    </>
);
CopyTemplateClientPicker.displayName = 'CopyTemplateClientPicker';
