/// <reference path="../../../_references.d.ts" />

import { DuplicateToPagePromptController, DuplicateToPagePromptOptions } from './controller';

export class DuplicateToPagePromptService {
    static SID = 'DuplicateToPagePromptService';

    static readonly $inject : string[] = ['$modal'];

    constructor(private $modal : any) {
    }

    public showModal(options : DuplicateToPagePromptOptions) : ng.IPromise<number> {
        const modalInstance = this.$modal.open({
            animation: true,
            backdrop: 'static',
            keyboard: true,
            templateUrl: 'partials/contentBuilder/print/duplicateToPagePrompt/template.jade',
            controller: DuplicateToPagePromptController,
            controllerAs: 'ctrl',
            resolve: { options() {
 return options;
} },
        });

        return modalInstance.result;
    }
}

angular.module('app').service(DuplicateToPagePromptService.SID, DuplicateToPagePromptService);
