import { DSButton, Translate } from '@deltasierra/components';
import { FormikProps } from 'formik';
import React from 'react';
import { DSScrollToErrorFormik } from './DSScrollToErrorFormik';

export type DSSubmitButtonFormikProps<T> = {
    children?: React.ReactChild;
    formik: FormikProps<T>;
};

export function DSSubmitButtonFormik<T>({ children, formik }: DSSubmitButtonFormikProps<T>): JSX.Element {
    const disabled = formik.isSubmitting || (formik.submitCount > 0 && !formik.isValid);
    return (
        <>
            <DSScrollToErrorFormik />
            <DSButton color="primary" disabled={disabled} variant="contained" onClick={() => formik.handleSubmit()}>
                {children ?? <Translate keyId="COMMON.SUBMIT" />}
            </DSButton>
        </>
    );
}
DSSubmitButtonFormik.displayName = 'DSSubmitButtonFormik';
