/* eslint-disable no-bitwise */
/* eslint-disable max-statements */
/// <reference path="../_references.d.ts" />
import { IQService } from 'angular';
import { FileUtils } from '../common/fileUtils';
import { $qSID } from './angularData';

/**
 * Enum of Jpeg Orientation values described as transformations.
 * All flips are horizontal and performed before rotating.
 * All rotations are in degrees clockwise.
 */
export enum JpegOrientation {
    normal = 1,
    flipped = 2,
    rotated180 = 3,
    flippedAndRotated180 = 4,
    flippedAndRotated270 = 5,
    rotated270 = 6,
    flippedAndRotated90 = 7,
    rotated90 = 8,
}

export interface JpegOrientationInfo {
    flipped: boolean;
    rotation: 0 | 90 | 180 | 270;
}

export class UnexpectedOrientationError extends Error {
    constructor(orientation: JpegOrientation) {
        super(`Unexpected orientation: ${orientation}`);
    }
}

const extractChunksFromPng = (function () {
    // Used for fast-ish conversion between uint8s and uint32s/int32s.
    // Also required in order to remain agnostic for both Node Buffers and
    // Uint8Arrays.
    const uint8 = new Uint8Array(4);
    // Const int32 = new Int32Array(uint8.buffer);
    const uint32 = new Uint32Array(uint8.buffer);

    function extractChunks(data: ArrayLike<number>) {
        const heading: Array<{ value: number; possibleLineEndingIssue?: boolean }> = [
            { value: 0x89 },
            { value: 0x50 },
            { value: 0x4e },
            { value: 0x47 },
            { value: 0x0d, possibleLineEndingIssue: true },
            { value: 0x0a, possibleLineEndingIssue: true },
            { value: 0x1a },
            { value: 0x0a, possibleLineEndingIssue: true },
        ];

        for (let index = 0; index < heading.length; index++) {
            if (data[index] !== heading[index].value) {
                const message = `Invalid .png file header${
                    heading[index].possibleLineEndingIssue
                        ? ': possibly caused by DOS-Unix line ending conversion?'
                        : ''
                }`;
                throw new Error(message);
            }
        }

        let ended = false;
        const chunks = [];
        let idx = 8;

        while (idx < data.length) {
            // Read the length of the current chunk,
            // Which is stored as a Uint32.
            uint8[3] = data[idx++];
            uint8[2] = data[idx++];
            uint8[1] = data[idx++];
            uint8[0] = data[idx++];

            // Chunk includes name/type for CRC check (see below).
            const length = uint32[0] + 4;
            const chunk = new Uint8Array(length);
            chunk[0] = data[idx++];
            chunk[1] = data[idx++];
            chunk[2] = data[idx++];
            chunk[3] = data[idx++];

            // Get the name in ASCII for identification.
            const name =
                String.fromCharCode(chunk[0]) +
                String.fromCharCode(chunk[1]) +
                String.fromCharCode(chunk[2]) +
                String.fromCharCode(chunk[3]);
            // The IHDR header MUST come first.
            if (!chunks.length && name !== 'IHDR') {
                throw new Error('IHDR header missing');
            }

            // The IEND header marks the end of the file,
            // So on discovering it break out of the loop.
            if (name === 'IEND') {
                ended = true;
                chunks.push({
                    name,
                    data: new Uint8Array(0),
                });

                break;
            }

            // Read the contents of the chunk out of the main buffer.
            for (let i = 4; i < length; i++) {
                chunk[i] = data[idx++];
            }

            // Read out the CRC value for comparison.
            // It's stored as an Int32.
            uint8[3] = data[idx++];
            uint8[2] = data[idx++];
            uint8[1] = data[idx++];
            uint8[0] = data[idx++];

            // Had to comment this code out: crc32 is undefined /:P
            // Const crcActual = int32[0]
            // Const crcExpect = crc32.buf(chunk)
            // If (crcExpect !== crcActual) {
            //     Throw new Error(
            //         'CRC values for ' + name + ' header do not match, PNG file is likely corrupted'
            //     )
            // }

            // The chunk data is now copied to remove the 4 preceding
            // Bytes used for the chunk name/type.
            const chunkData = new Uint8Array(chunk.buffer.slice(4));

            chunks.push({
                name,
                data: chunkData,
            });
        }

        if (!ended) {
            throw new Error('.png file ended prematurely: no IEND header was found');
        }

        return chunks;
    }

    return extractChunks;
})();

function byteArrayToInt32(data: Uint8Array): number {
    // Tslint:disable-next-line:no-bitwise
    const result = data[3] | (data[2] << 8) | (data[1] << 16) | (data[0] << 24);
    return result;
}

export class CouldNotDetermineDpi extends Error {
    constructor() {
        super('Could not determine DPI');
    }
}

export class UnsupportedFileType extends Error {
    constructor(extension: string) {
        super(`Unsupported File Type: ${extension}`);
    }
}

export class CannotExtractOrientationFromNonJpeg extends Error {
    constructor() {
        super('Can only extract orientation from JPEG images');
    }
}

export class ImageMetadataService {
    static SID = 'ImageMetadataService';

    static readonly $inject: string[] = [$qSID, FileUtils.SID];

    constructor(private $q: IQService, private fileUtils: FileUtils) {}

    getImageDpi(file: File): ng.IPromise<{ x: number; y: number }> {
        return this.fileUtils.fileToArrayBuffer(file).then(buffer =>
            this.$q((resolve: ng.IQResolveReject<{ x: number; y: number }>, reject: ng.IQResolveReject<any>) => {
                try {
                    const extension = this.fileUtils.getExtension(file.name).toLowerCase();

                    switch (extension) {
                        case '.jpg':
                        case '.jpeg':
                            // 'exif-parser' library is unreliable for jpegs
                            // And the js libraries that use the more reliable jfif
                            // Meta-data are node-based.
                            throw new CouldNotDetermineDpi();

                        case '.png':
                            return resolve(this.getPngDpi(buffer));

                        default:
                            throw new UnsupportedFileType(extension);
                    }
                } catch (error) {
                    return reject(error);
                }
            }),
        );
    }

    private getPngDpi(buffer: ArrayBuffer): { x: number; y: number } {
        const data = new Uint8Array(buffer);
        const chunks = extractChunksFromPng(data);

        if (chunks instanceof Array) {
            const pHYs = chunks.filter(chunk => chunk.name === 'pHYs')[0];

            if (pHYs) {
                const inchesPerCentimetre = 0.393701;
                const x = Math.round(byteArrayToInt32(pHYs.data) / 100 / inchesPerCentimetre);
                const y = Math.round(byteArrayToInt32(pHYs.data.slice(4)) / 100 / inchesPerCentimetre);
                const unit = pHYs.data[8];

                if (unit === 1 && x === y) {
                    return { x, y };
                }
            }
        }

        throw new CouldNotDetermineDpi();
    }

    getJpegOrientation(file: Blob | File): ng.IPromise<JpegOrientation | undefined> {
        return this.$q<number | undefined>(
            (resolve: ng.IQResolveReject<number | undefined>, reject: ng.IQResolveReject<any>) => {
                const reader = new FileReader();

                reader.onload = e => {
                    const view = new DataView((e.target as any).result);
                    if (view.getUint16(0, false) != 0xffd8) {
                        return reject(new CannotExtractOrientationFromNonJpeg());
                    }

                    const length = view.byteLength;
                    let offset = 2;
                    while (offset < length) {
                        const marker = view.getUint16(offset, false);
                        offset += 2;
                        if (marker == 0xffe1) {
                            offset += 2;
                            if (view.getUint32(offset, false) != 0x45786966) {
                                return resolve(undefined);
                            }
                            const little = view.getUint16(offset += 6, false) == 0x4949;
                            offset += view.getUint32(offset + 4, little);
                            const tags = view.getUint16(offset, little);
                            offset += 2;
                            for (let i = 0; i < tags; i++) {
                                if (view.getUint16(offset + i * 12, little) == 0x0112) {
                                    return resolve(view.getUint16(offset + i * 12 + 8, little));
                                }
                            }
                            // Tslint:disable-next-line:no-bitwise - Don't tell me how to live my life tslint
                        } else if ((marker & 0xff00) != 0xff00) {
                            break;
                        } else {
                            offset += view.getUint16(offset, false);
                        }
                    }
                    return resolve(undefined);
                };

                reader.readAsArrayBuffer(file);
            },
        );
    }

    getJpegOrientationInfo(orientation: JpegOrientation): JpegOrientationInfo {
        switch (orientation) {
            case JpegOrientation.normal:
                return { flipped: false, rotation: 0 };

            case JpegOrientation.flipped:
                return { flipped: true, rotation: 0 };

            case JpegOrientation.rotated180:
                return { flipped: false, rotation: 180 };

            case JpegOrientation.flippedAndRotated180:
                return { flipped: true, rotation: 180 };

            case JpegOrientation.flippedAndRotated270:
                return { flipped: true, rotation: 270 };

            case JpegOrientation.rotated270:
                return { flipped: false, rotation: 270 };

            case JpegOrientation.flippedAndRotated90:
                return { flipped: true, rotation: 90 };

            case JpegOrientation.rotated90:
                return { flipped: false, rotation: 90 };

            default:
                throw new UnexpectedOrientationError(orientation);
        }
    }
}

angular.module('app').service(ImageMetadataService.SID, ImageMetadataService);
