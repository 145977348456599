/* eslint-disable max-lines-per-function */
import * as React from 'react';
import { FluidGrid, SortableHeaderButton, useLightboxDispatch, LightboxActionType } from '@deltasierra/components';
import { gql } from '@apollo/client';
import { t } from '@deltasierra/shared';
import { translateFileTypeToString } from '../../context';
import { AssetSortColumn, SortAssetsInput, SortDirection } from '../../../../../__graphqlTypes/globalTypes';
import { selectedAssets } from '../../../graphql';
import { AssetThumbnailViewFragment } from './__graphqlTypes/AssetThumbnailViewFragment';
import { AssetThumbnailViewItem } from './AssetThumbnailViewItem';

const fragments = {
    AssetThumbnailViewFragment: gql`
        fragment AssetThumbnailViewFragment on AssetFileOrFolder {
            __typename
            ... on AssetFolder {
                id
                title
            }
            ... on AssetFile {
                id
                title
                size
                mimeType
                url
                type
                tags
                thumbnails {
                    medium {
                        url
                        width
                        height
                    }
                    large {
                        url
                    }
                }
            }
            ...AssetThumbnailViewItemFragment
        }
        ${AssetThumbnailViewItem.fragments.AssetThumbnailViewItemFragment}
    `,
};

export type AssetThumbnailViewProps = {
    assets: ReadonlyArray<AssetThumbnailViewFragment>;
    hideButtons?: boolean;
    onPreview?: () => void;
    setSort: (sort: SortAssetsInput | null) => void;
    sort: SortAssetsInput | null;
};

export const AssetThumbnailView: React.FunctionComponent<AssetThumbnailViewProps> & { fragments: typeof fragments } = ({
    assets,
    hideButtons,
    onPreview,
    setSort,
    sort,
}) => {
    const selectedCount = assets.filter(asset => asset.selected).length;
    const allSelected = assets.length === selectedCount;
    const someSelected = selectedCount > 0;
    const inputRef = React.useRef<HTMLInputElement>(null);
    const dispatch = useLightboxDispatch();
    const assetFoldersListLength = React.useMemo(
        () => assets.filter(asset => asset.__typename === 'AssetFolder').length,
        [assets],
    );

    React.useEffect(() => {
        if (inputRef.current) {
            inputRef.current.indeterminate = !allSelected && someSelected;
        }
    });

    const getSortDirection = (field: AssetSortColumn): 'asc' | 'desc' | null => {
        if (sort?.field !== field) {
            return null;
        }
        return sort.direction === SortDirection.ASC ? 'asc' : 'desc';
    };

    const handleClickSort = (field: AssetSortColumn) => {
        if (sort?.field === field && sort.direction === SortDirection.ASC) {
            setSort({
                direction: SortDirection.DESC,
                field,
            });
        } else {
            setSort({
                direction: SortDirection.ASC,
                field,
            });
        }
    };

    const handleClickPreview = React.useCallback(
        (index: number) => {
            // Folders are listed first, but not passed down into the lightbox component
            dispatch({ payload: index - assetFoldersListLength, type: LightboxActionType.SetIndex });
            onPreview?.();
        },
        [dispatch, assetFoldersListLength, onPreview],
    );

    return (
        <>
            <div
                style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'nowrap',
                    justifyContent: 'flex-start',
                }}
            >
                <div style={{ padding: '2px 8px' }}>
                    <input
                        checked={allSelected}
                        disabled={hideButtons}
                        ref={inputRef}
                        type="checkbox"
                        onChange={event => {
                            if (event.target.checked) {
                                selectedAssets(
                                    assets.map(asset =>
                                        asset.__typename === 'AssetFile'
                                            ? { id: asset.id, type: translateFileTypeToString(asset.type) }
                                            : { id: asset.id, type: 'folder' },
                                    ),
                                );
                            } else {
                                selectedAssets([]);
                            }
                        }}
                    />
                </div>
                <div style={{ padding: '2px 8px 2px 40px' }}>
                    <SortableHeaderButton
                        direction={getSortDirection(AssetSortColumn.NAME)}
                        label={t('COMMON.NAME')}
                        onClick={() => handleClickSort(AssetSortColumn.NAME)}
                    />
                </div>
                <div style={{ padding: '2px 8px 2px 40px' }}>
                    <SortableHeaderButton
                        direction={getSortDirection(AssetSortColumn.DATEADDED)}
                        label={t('COMMON.DATE_ADDED')}
                        onClick={() => handleClickSort(AssetSortColumn.DATEADDED)}
                    />
                </div>
                <div style={{ padding: '2px 8px 2px 40px' }}>
                    <SortableHeaderButton
                        direction={getSortDirection(AssetSortColumn.EXPIRY)}
                        label={t('COMMON.EXPIRY')}
                        onClick={() => handleClickSort(AssetSortColumn.EXPIRY)}
                    />
                </div>
                <div style={{ padding: '2px 8px 2px 40px' }}>
                    <SortableHeaderButton
                        direction={getSortDirection(AssetSortColumn.FILESIZE)}
                        label={t('COMMON.FILE_SIZE')}
                        onClick={() => handleClickSort(AssetSortColumn.FILESIZE)}
                    />
                </div>
            </div>
            <FluidGrid
                dataCy="asset-thumbnail-view-well"
                getDimensions={(asset: AssetThumbnailViewFragment) => ({
                    height: asset.__typename === 'AssetFile' ? asset.thumbnails?.medium.height ?? 256 : 256,
                    width: asset.__typename === 'AssetFile' ? asset.thumbnails?.medium.width ?? 256 : 256,
                })}
                itemMinWidth={75}
                items={assets}
                render={(props, asset: AssetThumbnailViewFragment, dimensions, index) => (
                    <div {...props} key={asset.id} style={{ ...props.style, height: undefined }}>
                        <AssetThumbnailViewItem
                            asset={asset}
                            dimensions={dimensions}
                            hideButtons={hideButtons}
                            onClickPreview={() => {
                                handleClickPreview(index);
                            }}
                        />
                    </div>
                )}
                rowMaxHeight={300}
                rowMinHeight={150}
                spacing={10}
            />
        </>
    );
};
AssetThumbnailView.displayName = 'AssetThumbnailView';
AssetThumbnailView.fragments = fragments;
