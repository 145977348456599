/* eslint-disable max-lines-per-function */
import * as React from 'react';
import {
    Button,
    DSTextField,
    Loading,
    ModalBody,
    ModalFooter,
    ModalWithHeader,
    PuppetCheckedInput,
    TagsInput,
    Translate,
} from '@deltasierra/components';
import { t } from '@deltasierra/shared';
import { gql, useMutation } from '@apollo/client';
import { Form, Formik, FormikHelpers } from 'formik';
import { DateOnlyPicker } from '../../../common/form/dates/DateOnlyPicker';
import { EditAssetsBulkInput } from '../../../../../__graphqlTypes/globalTypes';
import { useAngularServiceContext } from '../../../common/componentUtils/angularServiceContexts';
import { AssetModalFormElementContainer } from '../styled';
import { EditAssets, EditAssetsVariables } from './__graphqlTypes/EditAssets';
import { EditAssetsModalFragment } from './__graphqlTypes/EditAssetsModalFragment';

const EDIT_ASSETS_MUTATION = gql`
    mutation EditAssets($input: EditAssetsBulkInput!) {
        editAssetsBulk(input: $input) {
            __typename
            ... on EditAssetsBulkPayload {
                assets {
                    edges {
                        node {
                            __typename
                            ... on AssetFile {
                                id
                                tags
                                expires
                                fileName
                            }
                        }
                    }
                }
            }
        }
    }
`;

const fragments = {
    EditAssetsModalFragment: gql`
        fragment EditAssetsModalFragment on AssetFile {
            id
            tags
            expires
            created
            fileName
        }
    `,
};

export type EditAssetsModalProps = {
    assets: ReadonlyArray<EditAssetsModalFragment>;
    show: boolean;
    onClose?: () => void;
};

export const EditAssetsModal: React.FC<EditAssetsModalProps> & { fragments: typeof fragments } = props => {
    const multiAssetEdit = props.assets.length > 1;
    const modalTitle = multiAssetEdit
        ? t('COMMON.EDIT_MULTIPLE_ASSETS', { num: props.assets.length })
        : t('COMMON.EDIT_ASSET');
    return (
        <ModalWithHeader show={props.show} title={modalTitle} onClose={props.onClose}>
            <EditAssetsModalContent {...props} multiAssetEdit={multiAssetEdit} />
        </ModalWithHeader>
    );
};

const useExpiryCheckmarkStatus = (assets: ReadonlyArray<EditAssetsModalFragment>): 'all' | 'none' | 'some' =>
    React.useMemo(() => {
        const allHaveSameExpires = assets.every((asset, i, arr) => asset.expires === arr[0].expires);
        const allHaveExpiresSet = !!allHaveSameExpires && assets[0]?.expires !== null;
        if (allHaveExpiresSet) {
            return 'all' as const;
        } else if (allHaveSameExpires && !allHaveExpiresSet) {
            return 'none' as const;
        } else {
            return 'some' as const;
        }
    }, [assets]);

const EditAssetsModalContent: React.FunctionComponent<EditAssetsModalProps & { multiAssetEdit: boolean }> = ({
    assets,
    multiAssetEdit,
    onClose,
}) => {
    const notifier = useAngularServiceContext('mvNotifier');
    const [saveAssets] = useMutation<EditAssets, EditAssetsVariables>(
        EDIT_ASSETS_MUTATION,
        {
            refetchQueries: ['GetCollection', 'GetCollectionForCollectionAssets'],
        },
    );
    const expiryCheckmarkStatus = useExpiryCheckmarkStatus(assets);

    const handleSubmit = async (values: typeof initialValues, formikHelpers: FormikHelpers<typeof initialValues>) => {
        formikHelpers.setSubmitting(true);
        const input: EditAssetsBulkInput = {
            assets: values.tags.map(asset => ({
                expires: values.expiryChecked && values.expires ? values.expires.toISOString() : null,
                fileName: multiAssetEdit ? undefined : values.fileName,
                id: asset.id,
                tags: asset.tags,
            })),
        };
        const result = await saveAssets({ variables: { input } });
        if (result.data?.editAssetsBulk.__typename !== 'EditAssetsBulkPayload') {
            notifier.unexpectedError(t('COMMON.FAILED_TO', { description: t('COMMON.OP_EDIT_ASSETS') }));
            formikHelpers.setSubmitting(false);
        } else {
            onClose?.();
        }
    };

    const initialValues = {
        expires: expiryCheckmarkStatus === 'all' && assets[0].expires ? new Date(assets[0].expires) : null,
        expiryChecked: expiryCheckmarkStatus !== 'none',
        fileName: assets[0].fileName,
        tags: assets,
    };

    return (
        <Formik<typeof initialValues> initialValues={initialValues} onSubmit={handleSubmit}>
            {formik => (
                <Form>
                    <ModalBody>
                        <div className="form-horizontal">
                            {!multiAssetEdit && (
                                <AssetModalFormElementContainer>
                                    <DSTextField
                                        autoFocus
                                        data-cy="title-input"
                                        fullWidth
                                        label={t('COMMON.TITLE')}
                                        margin="normal"
                                        variant="outlined"
                                        {...formik.getFieldProps('fileName')}
                                    />
                                </AssetModalFormElementContainer>
                            )}
                            <TagsInput
                                dataCy="tags-input"
                                label={t('COMMON.TAGS')}
                                tags={formik.values.tags}
                                onChange={newTags => formik.setFieldValue('tags', newTags)}
                            />
                            <br />
                            <AssetModalFormElementContainer>
                                <div className="form-group">
                                    <label className="col-sm-2 control-label">
                                        <Translate keyId="COMMON.EXPIRY" />
                                    </label>
                                    <div className="col-sm-10">
                                        <PuppetCheckedInput
                                            {...formik.getFieldProps('expiryChecked')}
                                            checked={formik.values.expiryChecked}
                                            label={t('ASSET_LIBRARY.AUTOMATICALLY_EXPIRE_THESE_ASSETS')}
                                        />
                                    </div>
                                </div>
                                {expiryCheckmarkStatus === 'some' && (
                                    <div className="form-group">
                                        <div className="col-md-offset-2 col-sm-10 text-">
                                            <div>
                                                <em>
                                                    <Translate keyId="ASSET_LIBRARY.DIFFERENT_EXPIRY_DATE_ALERT" />
                                                </em>
                                            </div>
                                            <div>
                                                <em>
                                                    <Translate keyId="ASSET_LIBRARY.DIFFERENT_EXPIRY_DATE_INFO" />
                                                </em>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {formik.values.expiryChecked && (
                                    <DateOnlyPicker
                                        value={formik.values.expires}
                                        onChange={val => formik.setFieldValue('expires', val)}
                                    />
                                )}
                            </AssetModalFormElementContainer>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        {formik.isSubmitting && <Loading inline size="small" />}
                        <Button disabled={formik.isSubmitting} onClick={onClose}>
                            {t('COMMON.CANCEL')}
                        </Button>
                        <Button
                            dataCy="save-edit-assets-modal-button"
                            disabled={formik.isSubmitting || !formik.isValid}
                            theme="success"
                            type="submit"
                        >
                            {t('COMMON.SAVE')}
                        </Button>
                    </ModalFooter>
                </Form>
            )}
        </Formik>
    );
};
EditAssetsModalContent.displayName = 'EditAssetsModalContent';

EditAssetsModal.displayName = 'EditAssetsModal';
EditAssetsModal.fragments = fragments;
