import { ResourcePickerModal as ReactResourcePickerModal } from '@deltasierra/components';
import { withAngularIntegration } from '../common/componentUtils/reactComponentRegistration';
import { ExpressionBinding, OneWayBinding } from '../common/angularData';

export const ResourcePickerModal = withAngularIntegration(ReactResourcePickerModal, 'resourcePickerModal', {
    assetResources: OneWayBinding,
    canChooseLocationLogo: OneWayBinding,
    hasSuggestedCollection: OneWayBinding,
    location: OneWayBinding,
    media: OneWayBinding,
    onChoose: ExpressionBinding,
    onClickSelectFromAssetLibrary: ExpressionBinding,
    onClickSelectFromSuggestedCollection: ExpressionBinding,
    onClose: ExpressionBinding,
    plannerResources: OneWayBinding,
    show: OneWayBinding,
});
