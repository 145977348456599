import { gql } from '@apollo/client';

export const GET_LOCATION_FOR_FACEBOOK_PUBLISH_FORM_QUERY = gql`
    query GetLocationForFacebookPublishFormQuery($id: ID!) {
        location(id: $id) {
            id
            timezone
        }
    }
`;
