/// <reference path="../../../typings/browser.d.ts" />
import { ClientId, TryThis, TryThisSection, Untyped } from '@deltasierra/shared';
import { SlugService } from '../common/slug';
import { Webinar } from './models';
import IPromise = angular.IPromise;
import IHttpPromise = angular.IHttpPromise;
import IHttpService = angular.IHttpService;
import IQService = angular.IQService;

function getData(response: Untyped) {
    return response.data;
}

export class MvTryThis {
    static SID = 'mvTryThis';

    static readonly $inject: string[] = ['$http', '$q', 'mvTryThisResource', 'Slug'];

    constructor(
        private $http: IHttpService,
        private $q: IQService,
        private mvTryThisResource: Untyped,
        private slug: SlugService,
    ) {}

    private mapTryThisToWebinar(tryThis: TryThis | undefined): Webinar | undefined {
        const webinar: Webinar | undefined = tryThis
            ? {
                  id: tryThis.id,
                  title: tryThis.title,
                  url: `/trythis/${tryThis.id}/${this.slug.slugify(tryThis.title)}`,
                  thumbnail: tryThis.uploads![0].thumb256x256url!,
                  videoUrl: tryThis.uploads![0].url!,
              }
            : undefined;
        return webinar;
    }

    createTag(clientId: ClientId, tag: string) {
        return this.$http
            .post('/api/trythis/tags', {
                clientId,
                tag,
            })
            .then(getData);
    }

    deleteTags(clientId: ClientId, tags: string[]) {
        return this.$http
            .post('/api/trythis/tags/delete', {
                clientId,
                tags,
            })
            .then(getData);
    }

    createTryThis(newTryThisData: Untyped): IPromise<TryThis> {
        const newTryThis = new this.mvTryThisResource(newTryThisData);
        return newTryThis.$save();
    }

    updateTryThis(updatedTryThisData: Untyped) {
        const updatedTryThis = new this.mvTryThisResource(updatedTryThisData);
        const dfd = this.$q.defer();
        updatedTryThis.$update().then(
            (response: Untyped) => {
                dfd.resolve(response.trythis);
            },
            (response: Untyped) => {
                dfd.reject(response.data.reason);
            },
        );
        return dfd.promise;
    }

    getTryThese(clientId: ClientId, limit: number | undefined, offset: number, q?: string): IPromise<TryThis[]> {
        return this.$http({
            method: 'GET',
            url: '/api/trythis',
            params: {
                clientId,
                limit,
                offset,
                q,
            },
        }).then(getData);
    }

    getUncategorizedTryThese(clientId: ClientId): IHttpPromise<TryThis[]> {
        return this.$http({
            method: 'GET',
            url: '/api/trythis/uncategorized',
            params: {
                clientId,
            },
        });
    }

    getTryThis(trythisId: number): IPromise<TryThis> {
        return this.$http.get(`/api/trythis/${trythisId}`, {}).then(getData);
    }

    getLatestWebinar(clientId: ClientId): IPromise<Webinar | undefined> {
        return this.$http
            .get(`/api/trythis/webinar/latest/${clientId}`)
            .then(res => (res.data || undefined) as TryThis)
            .then(tryThis => this.mapTryThisToWebinar(tryThis));
    }

    getTryThisSections(clientId: ClientId): IHttpPromise<TryThisSection[]> {
        return this.$http.get(`/api/clients/${clientId}/tryThisSections`);
    }

    saveTryThisSections(
        clientId: ClientId,
        sections: TryThisSection[],
        deletedSections: number[],
        deletedSubSections: number[],
    ): IHttpPromise<TryThisSection[]> {
        return this.$http.post<TryThisSection[]>(`/api/clients/${clientId}/tryThisSections`, {
            sections,
            deletedSections,
            deletedSubSections,
        });
    }

    deleteTryThis(trythisId: number) {
        const dfd = this.$q.defer();
        this.$http.post('/api/trythis/delete', { trythisId }).then(
            response => {
                dfd.resolve();
            },
            response => {
                dfd.reject(response);
            },
        );
        return dfd.promise;
    }

    getTryThisTags(clientId: ClientId) {
        return this.$http.get(`/api/trythis/tags/${clientId}`, {}).then(getData);
    }

    addComment(trythisId: number, comment: string) {
        return this.$http.post('/api/trythis/comment', {
            trythisId,
            comment,
        });
    }

    deleteComment(trythisId: number, commentId: number) {
        return this.$http.post('/api/trythis/comment/delete', {
            trythisId,
            commentId,
        });
    }

    voteHelpful(trythisId: number, helpful: boolean) {
        return this.$http
            .post('/api/trythis/vote', {
                trythisId,
                helpful,
            })
            .then(getData);
    }
}

angular.module('app').service(MvTryThis.SID, MvTryThis);
