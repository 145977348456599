import { gql } from '@apollo/client';
import { BlockedPublishesTable } from './BlockedPublishesTable';

export const GET_BLOCKED_PUBLISHES_QUERY = gql`
    query GetBlockedPublishes($after: String, $first: Int) {
        admin {
            id
            blockedPublishes(after: $after, first: $first) {
                edges {
                    node {
                        id
                        ...ScheduledPublishFragmentForBlockedPublishesTable
                    }
                }
                pageInfo {
                    hasNextPage
                    endCursor
                }
            }
        }
    }
    ${BlockedPublishesTable.fragments.SCHEDULED_PUBLISH_FRAGMENT_FOR_BLOCKED_PUBLISHES_TABLE}
`;
