/* eslint-disable no-await-in-loop */
import {
    ShoppingCartOrderWithAllDetailsDto,
    PrintProviderShoppingCartOrderId,
    formatSizeOption,
    PrintProviderShoppingCartItemId,
    CartItemWithFullDetailsDto,
    getItemPriceAmountInCents,
    searchForQuantityOption,
    LocationShippingDetails,
    UploadId,
    StripeChargeDto,
} from '@deltasierra/shared';
import { ITimeoutService } from 'angular';
import { UploadApiClient } from '../../../common/uploadApiClient';
import { QuantityOption } from '../../../contentBuilder/publish/print/sku/basePublishPrintSKUController';
import { ShoppingCartApiClient } from '../../shoppingCartApiClient';
import { MvIdentity } from './../../../account/mvIdentity';
import { I18nService } from './../../../i18n';
import { InteractionUtils } from './../../../common/interactionUtils';
import {
    actualComponent,
    OneWayBinding,
    ExpressionCallback,
    ExpressionBinding,
    $timeoutSID,
} from './../../../common/angularData';
import { StripeChargePropsData } from './StripeCheckoutWrapper';

export class ShoppingCartOrderCtrl {
    // Props (inputs)
    readonly orderId!: PrintProviderShoppingCartOrderId;

    readonly onLastItemDeleted!: ExpressionCallback<{ orderId: PrintProviderShoppingCartOrderId }>;

    readonly broadcastItemChangeEvent!: ExpressionCallback<{}>;

    readonly stripePublishableKey!: string;

    // State
    order: ShoppingCartOrderWithAllDetailsDto | null = null;

    orderFulfilled = false;

    createChargeData: StripeChargePropsData | null = null;

    chargeResult: StripeChargeDto | null = null;

    // Futures
    readonly fetchOrder = this.interactionUtils.createFuture('Fetch order', async () => {
        const order = await this.shoppingCartApiClient.getOrder(this.orderId);
        this.order = order;
    });

    readonly fetchUpload = this.interactionUtils.createFuture(
        'Fetch upload',
        async (context: { uploadId: UploadId; key: string }) =>
            this.uploadApiClient.getUploadThumbnail(context.uploadId, context.key),
    );

    readonly updateItemQuantity = this.interactionUtils.createFuture(
        this.i18nService.text.shoppingCart.checkout.updateItemQuantity(),
        async (context: { itemId: PrintProviderShoppingCartItemId; quantity: number }) => {
            await this.shoppingCartApiClient.updateItemQuantity(this.orderId, context.itemId, context.quantity);
        },
    );

    readonly deleteItem = this.interactionUtils.createFuture(
        this.i18nService.text.shoppingCart.checkout.deleteItem(),
        async (context: { itemId: PrintProviderShoppingCartItemId }) => {
            if (this.order) {
                const itemCountBeforeDeletion = this.order.printProviderShoppingCartItems.length;
                await this.shoppingCartApiClient.deleteShoppingCartItem(context.itemId, this.orderId);
                if (itemCountBeforeDeletion > 1) {
                    // Update the order
                    this.broadcastItemChangeEvent({});
                    return this.fetchOrder.run({});
                } else {
                    this.onLastItemDeleted({ orderId: this.order.id });
                }
                this.broadcastItemChangeEvent({});
            }
        },
    );

    static readonly $inject: string[] = [
        I18nService.SID,
        InteractionUtils.SID,
        ShoppingCartApiClient.SID,
        UploadApiClient.SID,
        MvIdentity.SID,
        $timeoutSID,
    ];

    constructor(
        private readonly i18nService: I18nService,
        private readonly interactionUtils: InteractionUtils,
        private readonly shoppingCartApiClient: ShoppingCartApiClient,
        private readonly uploadApiClient: UploadApiClient,
        private readonly identity: MvIdentity,
        private readonly $timeout: ITimeoutService,
    ) {
        this.onSuccessfulCharge = this.onSuccessfulCharge.bind(this);
    }

    public $onInit(): void {
        void this.fetchOrder.run({ orderId: this.orderId }).then(() => {
            if (this.order) {
                const createChargeData = this.getStripeChargePropsData();
                if (createChargeData) {
                    this.createChargeData = createChargeData;
                }
                return this.rectifyImages();
            }
        });
    }

    async checkAndReplaceMissingImages() {
        if (this.order) {
            for (const item of this.order.printProviderShoppingCartItems) {
                if (this.hasMissingImage(item)) {
                    const upload = await this.fetchUpload.run({
                        uploadId: item.printProviderStats.publishedArtifact.artifactId,
                        key: item.printProviderStats.publishedArtifact.artifact.key,
                    });
                    item.printProviderStats.publishedArtifact.artifact.thumb256x256url = upload.thumb256x256url;
                }
            }
        }
    }

    clickDeleteItem(item: CartItemWithFullDetailsDto): ng.IPromise<void> {
        return this.deleteItem.run({ itemId: item.id });
    }

    getStripeChargePropsData(): StripeChargePropsData | undefined {
        if (this.order && this.order.printProvider.stripeConfigurationId) {
            const data: StripeChargePropsData = {
                currency: this.order.printProviderSKUGroup.defaultPriceCurrency,
                stripeConfigurationId: this.order.printProvider.stripeConfigurationId,
                stripePublishableKey: this.stripePublishableKey,
                printProviderShoppingCartOrderId: this.order.id,
                localeCode: this.order.printProvider.client.agency.localeCode,
            };
            return data;
        }
    }

    getShippingPriceAmountInCents(): number | undefined {
        if (this.order) {
            return this.order.uniqueLocationsCount * this.order.printProviderSKUGroup.shippingFeePriceAmountInCents;
        }
    }

    getItemPriceAmountInCents(item: CartItemWithFullDetailsDto): number | undefined {
        return getItemPriceAmountInCents(item);
    }

    getTotalPriceAmountInCents(): number | undefined {
        if (this.order) {
            let itemsFee = 0;
            const shippingFee = this.getShippingPriceAmountInCents();

            for (const item of this.order.printProviderShoppingCartItems) {
                const itemCost = this.getItemPriceAmountInCents(item);
                if (itemCost) {
                    itemsFee += itemCost;
                }
            }

            if (itemsFee) {
                return (shippingFee ? shippingFee : 0) + itemsFee;
            }
        }
    }

    getFormatLabel(width: number, height: number): string {
        if (!this.identity.currentUser) {
            return '';
        }
        const units = this.identity.currentUser.printUnits;
        const format = {
            width,
            height,
        };
        return formatSizeOption(format, { isPrintDocument: true, userPreferencePrintUnits: units }, false);
    }

    getQuantityOptions(item: CartItemWithFullDetailsDto): QuantityOption[] {
        return item.printProviderSKU.printProviderSKUQuantityOptions;
    }

    hasMissingImage(item: CartItemWithFullDetailsDto): boolean {
        return !item.printProviderStats.publishedArtifact.artifact.thumb256x256url;
    }

    hasValidQuantityOption(item: CartItemWithFullDetailsDto): boolean {
        return !!this.searchForQuantityOption(item);
    }

    hasValidShippingAddress(location: LocationShippingDetails): boolean {
        return !!location.shippingLineOne && !!location.shippingLineTwo;
    }

    locationAddressesValid(): boolean {
        if (this.order) {
            for (const item of this.order.printProviderShoppingCartItems) {
                if (!this.hasValidShippingAddress(item.printProviderStats.location)) {
                    return false;
                }
            }
        }
        return true;
    }

    onShippingDetailsChange(locationShippingDetails: LocationShippingDetails): void {
        if (this.order) {
            for (const item of this.order.printProviderShoppingCartItems) {
                if (item.printProviderStats.location.id === locationShippingDetails.id) {
                    item.printProviderStats.location = locationShippingDetails;
                }
            }
        }
    }

    onSuccessfulCharge = (chargeResult: StripeChargeDto): void => {
        this.chargeResult = chargeResult;
        this.broadcastItemChangeEvent({});
        this.orderFulfilled = true;
    };

    orderHasMissingImages(items: CartItemWithFullDetailsDto[]): boolean {
        for (const item of items) {
            if (this.hasMissingImage(item)) {
                return true;
            }
        }
        return false;
    }

    quantityUpdate(quantity: number, itemId: PrintProviderShoppingCartItemId): void {
        if (quantity) {
            void this.updateItemQuantity.run({ itemId, quantity });
        }
    }

    async rectifyImages() {
        while (this.order && this.orderHasMissingImages(this.order.printProviderShoppingCartItems)) {
            await this.$timeout(3000).then(async () => {
                await this.checkAndReplaceMissingImages();
            });
        }
        // TODO: Ideally replace with if statement
        // Argument of type '() => Promise<void>' is not assignable to parameter of type 'boolean | undefined'.
        // Type '() => Promise<void>' is not assignable to type 'true'.ts(2345)

        // If (this.orderHasMissingImages(this.order && this.order.printProviderShoppingCartItems)) {
        //     Await this.checkAndReplaceMissingImages();
        //     Await this.$timeout(3000, this.rectifyImages());
        // }
    }

    searchForQuantityOption(item: CartItemWithFullDetailsDto): QuantityOption | undefined {
        return searchForQuantityOption(item);
    }
}

export const shoppingCartOrderSID = 'shoppingCartOrder';
export const shoppingCartOrderConfig: ng.IComponentOptions = actualComponent(
    ShoppingCartOrderCtrl,
    '/partials/shoppingCart/checkout/shoppingCartOrder/shoppingCartOrder',
    {
        orderId: OneWayBinding,
        onLastItemDeleted: ExpressionBinding,
        broadcastItemChangeEvent: ExpressionBinding,
        stripePublishableKey: OneWayBinding,
    },
);

angular.module('app').component(shoppingCartOrderSID, shoppingCartOrderConfig);
