/// <reference path="../../../typings/browser.d.ts" />
import { $documentSID, ExpressionBinding, RestrictToAttribute, StringBinding } from '../common/angularData';
import { isElementVisible } from '../common/elementUtils';
import IAugmentedJQuery = angular.IAugmentedJQuery;
import IDocumentService = angular.IDocumentService;

export const lastChildInViewSID = 'lastChildInView';

export const lastChildInViewConfig = {
    restrict: RestrictToAttribute,
    scope: {
        lastChildInView: ExpressionBinding,
        lastChildInViewSelector: StringBinding,
    },
};
angular.module('app').directive(lastChildInViewSID, [
    $documentSID,
    function ($document: IDocumentService) {
        interface LastChildInViewScope extends ng.IScope {
            lastChildInView: () => void;
            lastChildInViewSelector: string;
        }

        return {
            ...lastChildInViewConfig,
            link(scope: LastChildInViewScope, element: IAugmentedJQuery) {
                function update() {
                    const lastChild = element.children(`${scope.lastChildInViewSelector}:visible`).last().get(0);
                    if (lastChild && isElementVisible(lastChild)) {
                        return scope.lastChildInView();
                    }
                }
                scope.$watch(() => element.get(0).scrollHeight, update);
                $document.bind('resize scroll', update);
                element.bind('resize scroll', update);
            },
        };
    },
]);
