/* eslint-disable max-statements */
/* eslint-disable max-lines, max-lines-per-function */
/// <reference path="../../../typings/browser.d.ts" />
import { Untyped, isNullOrUndefined } from '@deltasierra/type-utilities';
import { AnyThrowable, ApplyActionTo, Planner, AssignedLocation, assertNever } from '@deltasierra/shared';


import moment from 'moment-timezone';
import { MvLocation } from '../locations/mvLocation';
import { IntroService } from '../intro/introService';
import { I18nService as i18nService } from '../i18n/i18nService';
import { ModalInstance, ModalService } from '../typings/angularUIBootstrap/modalService';
import { MvNotifier } from '../common/mvNotifier';
import { IntroWrapper } from '../intro/introWrapper';
import { IntroDataService } from '../intro/introDataService';
import { $kookiesSID, $locationSID, $qSID, $scopeSID, $timeoutSID, $windowSID, IKookies } from '../common/angularData';
import { $modalInstanceSID, $modalSID } from '../common/angularUIBootstrapData';
import { MvPlanner } from './mvPlanner';
import { PlannerDateService } from './plannerDateService';
import { PlannerLegendType, PlannerUIService } from './plannerUIService';
import ITimeoutService = angular.ITimeoutService;
import ILocationService = angular.ILocationService;
import IWindowService = angular.IWindowService;
import IQService = angular.IQService;
import IScope = angular.IScope;

interface DateObject {
    date: Date;
    disabled: boolean;
    dateIndex: Untyped;
}

interface LegendEntry {
    className: string;
    iconClassName: string;
    label: () => string;
    tooltip: () => string;
}

type PlannerViewMode = 'month' | 'week';

export interface PlannerControllerScope extends ng.IScope {
    mobile: boolean;
    month?: string;
    maxItems: number;
    showing: string;
    planners: Planner[][] | null;
    view: PlannerViewMode;
    legend: LegendEntry[];
    agencyTitle: string | null;
    firstLoad: boolean;
    introDate: number;
    calendarDateCell: Untyped;
    currentLocation?: AssignedLocation;
    from?: Date;
    to?: Date;
    calendarDates: DateObject[];
    introPlan: IntroWrapper;
    introBuild: IntroWrapper;
    editingDate: boolean;

    startDragging: (event: Untyped, ui: Untyped, planner: Untyped) => void;
    stopDragging: (event: Untyped, ui: Untyped, planner: Untyped) => void;
    dropCallback: (event: Untyped, ui: Untyped, dateEntry: Untyped) => void;
    updateCalendar: () => void;
    updateLocation: (location: AssignedLocation) => void;
    checkAddPlanner: (dateObj: Untyped) => void;
    editPlanner: ($event: Untyped, dateObj: Untyped, planner: Untyped) => void;
    getWeek: () => Date;
    isBeforeToday: (date: DateObject) => boolean;
    isToday: (date: DateObject) => boolean;
    isDangerousDate: (date: DateObject) => boolean;
    expandCell: ($event: Untyped, date: Untyped) => void;

    getPlannerClass: (type: 'glyph' | 'item', planner: Planner) => string;
    getPlannerIcon: (planner: Planner) => string;
    getPlannerIconName: (planner: Planner) => string;
    getLegendLabel: (legendType: PlannerLegendType, agencyName: string) => string;
    getLegendLabelTooltip: (legendType: PlannerLegendType, agencyName: string) => string;
    getCellClass: (dateObj: DateObject) => string;
    isInactive: (dateObj: DateObject) => boolean;

    setView: (mode: PlannerViewMode) => void;

    handleDatePickerChange: (newDate: string) => void;

    updateMonthFromWeek: () => void;
    updateWeekFromMonth: () => void;
    requiredDataIsLoaded: () => boolean;
    goToNextWeek: () => void;
    goToPreviousWeek: () => void;
    goToNextMonth: () => void;
    goToPreviousMonth: () => void;
    getLastDate: () => Date;
    getPlannerLimit: (dateIndex: Untyped) => number;
    isAnIntroActive: () => boolean;
    isIntroDate: (date: Date) => boolean;
}

angular.module('app').controller('mvPlannerCtrl', [
    $scopeSID,
    $qSID,
    $timeoutSID,
    $locationSID,
    $windowSID,
    $modalSID,
    $kookiesSID,
    MvPlanner.SID,
    MvLocation.SID,
    MvNotifier.SID,
    PlannerDateService.SID,
    PlannerUIService.SID,
    IntroService.SID,
    IntroDataService.SID,
    i18nService.SID,
    // eslint-disable-next-line max-params
    function mvPlannerCtrl(
        $scope: PlannerControllerScope,
        $q: IQService,
        $timeout: ITimeoutService,
        $location: ILocationService,
        $window: IWindowService,
        $modal: ModalService,
        $kookies: IKookies,
        mvPlanner: MvPlanner,
        mvLocation: MvLocation,
        mvNotifier: MvNotifier,
        plannerDateService: PlannerDateService,
        plannerUIService: PlannerUIService,
        introService: IntroService,
        introDataService: IntroDataService,
        I18nService: i18nService,
    ) {
        let agencyName = '';

        $scope.mobile = angular.element($window).width() < 750;
        angular.element($window).bind('resize', () => {
            $scope.mobile = angular.element($window).width() < 750;
            $scope.$apply();
        });

        $scope.legend = [];
        $scope.maxItems = 3;
        $scope.showing = '';
        $scope.planners = [];
        $scope.view = getPlannerViewCookie();
        $scope.agencyTitle = null;
        $scope.firstLoad = true;
        $scope.month = getMonth();
        $scope.introDate = 11; // Hides any planner items in this date cell during intro
        $scope.editingDate = false;

        let dragging = false;

        function initPage() {
            const legendTypes: PlannerLegendType[] = [
                'noArtifactRequired',
                'noContent',
                'artifactRequired',
                'built',
                'supported',
            ];

            $scope.legend = legendTypes.map(legendType => ({
                className: plannerUIService.getPlannerLegendClassName(legendType),
                iconClassName: plannerUIService.getPlannerLegendIconClassName(legendType),
                label: () => plannerUIService.getPlannerLegendLabel(legendType, agencyName),
                tooltip: () => plannerUIService.getPlannerLegendLabelTooltip(legendType, agencyName),
            }));

            if (introService.isAnyIntroActive()) {
                $scope.view = 'month';
            }
        }

        $scope.startDragging = (event, ui, planner) => {
            dragging = true;
        };
        $scope.stopDragging = (event, ui, planner) => {
            setTimeout(() => {
                dragging = false;
            }, 300);
            $scope.showing = '';
        };
        $scope.dropCallback = (event, ui, dateEntry): void => {
            if (!$scope.currentLocation) {
                return;
            }
            const plannerArray = $scope.planners![dateEntry.dateIndex];
            const planner = plannerArray[plannerArray.length - 1];
            if (moment(planner.date).tz('UTC').format('YYYYMMDD') !== dateEntry.dateIndex) {
                const locationId = $scope.currentLocation.id;
                const plannerId = planner.id;
                const date = moment(dateEntry.date).tz('UTC', true).toDate();

                if (planner.recurringPlannerId !== null) {
                    $scope.editingDate = true;
                    openUpdateRecurringDateDialog()
                        .then((applyActionTo: ApplyActionTo) => {
                            if (applyActionTo) {

                                mvPlanner
                                    .updateRecurringPlannerDate(applyActionTo, date, planner)
                                    .finally(() => {
                                        $scope.editingDate = false;
                                        void updateCalendar();
                                    })
                                    .catch(() => null);
                            } else {
                                $scope.editingDate = false;
                                updateCalendar();
                            }
                        })
                        .catch(() => null);
                } else {
                    void mvPlanner.updatePlannerDate(locationId, plannerId, date).then((data: unknown) => {
                        planner.date = date;
                    });
                }
            }
        };

        $scope.calendarDateCell = {
            hoverClass: 'drop-hover',
        };

        $scope.handleDatePickerChange = (newDate: string) => {
            $scope.month = setPlannerMonthCookie(new Date(newDate));
            $scope.updateWeekFromMonth();
            void updateCalendar();
        };

        $scope.updateLocation = async (newLocation: AssignedLocation) => {
            $scope.currentLocation = newLocation;
            updateAgencyName(newLocation);
            await updateCalendar(newLocation);
        };

        function updateAgencyName(location: AssignedLocation) {
            agencyName = location.agencyName;
        }

        function openPastDateDialog(dateObj: DateObject) {
            $modal.open({
                controller: 'mvPlannerDodgyDateModalCtrl',
                templateUrl: 'planner/pastDateModal.html',
            });
        }

        function openUpdateRecurringDateDialog(): ng.IPromise<any> {
            const modalInstance = $modal.open({
                controller: 'mvPlannerEventUpdateRecurringDateCtrl',
                templateUrl: 'planner/updateRecurringDate.html',
            });
            return modalInstance.result;
        }

        function openDangerousDateDialog(dateObj: DateObject) {
            const modalInstance = $modal.open({
                controller: 'mvPlannerDodgyDateModalCtrl',
                templateUrl: 'planner/dangerousDateModal.html',
            });
            return modalInstance.result.then(result => {
                if (result === 'createPlanner') {
                    addPlanner(dateObj.date);
                }
            });
        }

        function isAnIntroActive(): boolean {
            return introService.isIntroActive('plan') || introService.isIntroActive('build');
        }

        $scope.isAnIntroActive = isAnIntroActive;

        function addPlanner(date: Date): void {
            loadNextIntroPageIfRequired();
            if (!isNullOrUndefined($scope.currentLocation)) {
                const clientId = $scope.currentLocation.clientId;
                const locationId = $scope.currentLocation.id;
                $location.path(`/planner/${clientId}/${locationId}/${moment(date).format('YYYY-MM-DD')}/add`);
            }
        }

        function loadNextIntroPageIfRequired(): void {
            if (isAnIntroActive()) {
                introService.loadNextPage();
            }
        }

        $scope.checkAddPlanner = dateObj => {
            if (!dateObj.disabled) {
                if ($scope.isBeforeToday(dateObj)) {
                    void openPastDateDialog(dateObj);
                } else if ($scope.isDangerousDate(dateObj)) {
                    void openDangerousDateDialog(dateObj);
                } else {
                    void addPlanner(dateObj.date);
                }
            }
        };

        $scope.editPlanner = ($event, dateObj, planner) => {
            if (!$scope.currentLocation) {
                return;
            }
            $event.stopPropagation();
            if (!dragging) {
                const date = dateObj.date;
                if (isAnIntroActive()) {
                    addPlanner(date);
                } else {
                    const clientId = $scope.currentLocation.clientId;
                    const locationId = $scope.currentLocation.id;
                    const plannerId = planner.id;
                    const recurringPlannerId = planner.recurringPlannerId;
                    const isRecurringPlaceholder = planner.isRecurringPlaceholder;
                    let path;
                    if (isRecurringPlaceholder) {
                        path = `/planner/${clientId}/${locationId}/${moment(date).format(
                            'YYYY-MM-DD',
                        )}/recurring/${recurringPlannerId}`;
                    } else {
                        path = `/planner/${clientId}/${locationId}/${moment(date).format('YYYY-MM-DD')}/${plannerId}`;
                    }
                    $location.path(path);
                }
            }
        };

        const SUNDAY = 0;
        const START_OF_WEEK = SUNDAY;
        const END_OF_WEEK = 6;

        function determineDateRange(): { from: Date; to: Date } {
            let from: Date;
            let to: Date;
            if ($scope.view === 'month') {
                from = moment(getMonth()).startOf('month').toDate();
                to = moment(from).endOf('month').toDate();
            } else if ($scope.view === 'week') {
                from = moment($scope.getWeek())
                    .startOf('week')
                    .day(START_OF_WEEK) // Don't use locale-aware "start of week" values
                    .toDate();
                to = moment(from).add(1, 'week').toDate();
            } else {
                throw assertNever($scope.view);
            }
            return {
                from,
                to,
            };
        }

        async function updateCalendar(newLocation = $scope.currentLocation): Promise<void> {
            if (!newLocation) {
                return Promise.resolve();
            }

            const dateRange = determineDateRange();
            $scope.from = dateRange.from;
            $scope.to = dateRange.to;

            const calendarDates: DateObject[] = [];
            let dateCounter: Date =
                $scope.view === 'month'
                    ? moment(dateRange.from.getTime())
                          .startOf('week')
                          .day(START_OF_WEEK) // Don't use locale-aware "start of week" values
                          .toDate()
                    : dateRange.from;
            const dateEnd: Date =
                $scope.view === 'month'
                    ? moment(dateRange.to.getTime()).endOf('week').day(END_OF_WEEK).toDate()
                    : dateRange.to;

            moment();
            // Watchdog loop counter, to avoid infinite iteration due to undiscovered bugs (DS-2838)
            const MAX_LOOPS = 100;
            let loopCounter = 0;
            while (dateCounter < dateEnd && loopCounter < MAX_LOOPS) {
                const dte = new Date(dateCounter.getTime()); // Clone the date object
                const dateObj: DateObject = {
                    date: dte,
                    dateIndex: moment(dte).format('YYYYMMDD'),
                    disabled: false,
                };
                if (dateCounter < dateRange.from || dateCounter > dateRange.to) {
                    dateObj.disabled = true;
                }
                calendarDates.push(dateObj);
                dateCounter = moment(dateCounter).add(1, 'day').toDate();
                loopCounter++;
            }
            $scope.calendarDates = calendarDates;
            return updateCalendarPlanners(dateRange, newLocation);
        }

        async function updateCalendarPlanners(
            dateRange: { from: Date; to: Date },
            location: AssignedLocation,
        ): Promise<void> {
            $scope.planners = null;
            const planners: Planner[][] = [];
            const isDateIndexMatch = (index: Untyped) =>
                index.toString().substr(index.toString().length - 2, 2) === String($scope.introDate);

            for (const calendarDate of $scope.calendarDates) {
                planners[calendarDate.dateIndex] = [];

                if (isAnIntroActive() && isDateIndexMatch(calendarDate.dateIndex)) {
                    planners[calendarDate.dateIndex] = [introDataService.getExamplePlanner()];
                }
            }
            const from = moment(dateRange.from).tz('UTC', true).toDate();
            // Convert from inclusive end date to exclusive end date
            const to = moment(dateRange.to).add(1, 'month').startOf('month').tz('UTC', true).toDate();

            return mvLocation
                .getPlanners(location.clientId, location.id, from, to)
                .then((data: any) => {
                    for (const planner of data) {
                        const date = moment(planner.date).utc().tz(moment.tz.guess(), true).format('YYYYMMDD');

                        const dateArray = planners[date as Untyped];

                        if (dateArray) {
                            if (!(isAnIntroActive() && isDateIndexMatch(date))) {
                                dateArray.push(planner);
                            }
                        } else if ($scope.view !== 'week') {
                            // Planner event may be outside the current week view (but must fit in month view)
                            // eslint-disable-next-line no-console
                            console.log(`Planner '${planner.id}' seems to have an invalid date, '${date}'`);
                        }
                    }

                    $scope.planners = planners;

                    if (introService.isAnyIntroActive() && $scope.firstLoad) {
                        // If plan intro active and calendar loaded
                        triggerIntro();
                    }
                    $scope.firstLoad = false;
                })
                .catch((data: AnyThrowable) => {
                    mvNotifier.unexpectedErrorWithData('Failed to retrieve planner events', data);
                });
        }

        function triggerIntro() {
            $scope.introPlan = introService.setUpAndStartIntro('plan', $scope);
            $scope.introBuild = introService.setUpAndStartIntro('build', $scope);
        }

        $scope.expandCell = ($event, date) => {
            $event.stopPropagation();
            $scope.showing = date;
        };

        $scope.getPlannerClass = (type: 'glyph' | 'item', planner: Planner) => {
            const result =
                type === 'glyph'
                    ? plannerUIService.getPlannerLegendIconClassName(planner)
                    : plannerUIService.getPlannerLegendClassName(planner);
            return result;
        };

        $scope.getPlannerIcon = planner => plannerUIService.getPlannerLegendIconClassName(planner);
        $scope.getPlannerIconName = planner => plannerUIService.getPlannerLegendIconName(planner);

        $scope.getLegendLabel = (legendType: PlannerLegendType) =>
            plannerUIService.getPlannerLegendLabel(legendType, agencyName);

        $scope.getLegendLabelTooltip = (legendType: PlannerLegendType) =>
            plannerUIService.getPlannerLegendLabelTooltip(legendType, agencyName);

        $scope.isInactive = function isInactive(dateObj) {
            if (dateObj.disabled) {
                return true;
            }
            return false;
        };

        $scope.getCellClass = function getCellClass(dateObj) {
            const classes = [];
            if (dateObj.disabled) {
                classes.push('inactive');
            } else if ($scope.showing === dateObj.dateIndex) {
                classes.push('floating');
            } else if ($scope.isBeforeToday(dateObj)) {
                classes.push('before-today');
            } else if ($scope.isDangerousDate(dateObj)) {
                classes.push('danger-zone');
            }

            if ($scope.isToday(dateObj)) {
                classes.push('today');
            }

            if ($scope.view === 'week') {
                classes.push('weekday');
            }

            classes.push(`cell-date-${dateObj.date.getDate()}`);

            return classes.join(' ');
        };

        $scope.isBeforeToday = function isBeforeToday(dateObj) {
            return moment(dateObj.date).isBefore(moment().startOf('day'));
        };

        $scope.isToday = function isToday(dateObj) {
            return moment(dateObj.date).isSame(moment().startOf('day'));
        };

        $scope.isDangerousDate = function isDangerousDate(dateObj) {
            return plannerDateService.isDangerousDate(dateObj.date);
        };

        function setPlannerViewCookie(view: PlannerViewMode): void {
            $kookies.set('plannerView', view, { path: '/' });
        }

        $scope.setView = (view: PlannerViewMode) => {
            const oldView: PlannerViewMode = $scope.view;
            $scope.view = view;
            if (oldView !== view) {
                void updateCalendar();
                if ($scope.view === 'week' && $scope.from) {
                    setPlannerWeekCookie($scope.from);
                }
                setPlannerViewCookie(view);
            }
        };

        function getPlannerViewCookie(): PlannerViewMode {
            let view: PlannerViewMode | undefined = $kookies.get('plannerView');
            if (!view) {
                view = 'month';
            }
            return view;
        }

        function getMonth(): string {
            moment();
            return (
                $scope.month ??
                $kookies.get('plannerMonth') ??
                setPlannerMonthCookie(moment().add(1, 'months').toDate())
            );
        }

        $scope.getWeek = (): Date =>
            moment($kookies.get('plannerWeek') ?? setPlannerWeekCookie(moment(getMonth()).toDate())).toDate();

        function setPlannerWeekCookie(newDate: Date) {
            const dateString: string = moment(newDate).startOf('week').day(START_OF_WEEK).format('YYYY-MM-DD');
            $kookies.set('plannerWeek', dateString, { path: '/' });
            return dateString;
        }

        function setPlannerMonthCookie(newDate: Date) {
            const dateString: string = moment(newDate).startOf('month').format('YYYY-MM-DD');
            $kookies.set('plannerMonth', dateString, { path: '/' });
            return dateString;
        }

        $scope.updateMonthFromWeek = () => {
            const week: Date = $scope.getWeek();
            const newMonth = new Date(week.getTime());
            newMonth.setDate(1);
            $scope.month = setPlannerMonthCookie(newMonth);
        };

        $scope.updateWeekFromMonth = () => {
            const newWeek: Date = moment(getMonth())
                .startOf('week')
                .day(START_OF_WEEK) // Don't use locale-aware "start of week" values
                .toDate();
            setPlannerWeekCookie(newWeek);
        };

        $scope.requiredDataIsLoaded = () => {
            if ($scope.planners && $scope.currentLocation) {
                return true;
            }
            return false;
        };

        $scope.goToNextWeek = () => {
            const week: Date = moment($scope.getWeek()).add(1, 'week').toDate();
            setPlannerWeekCookie(week);
            void updateCalendar();
            $scope.updateMonthFromWeek();
        };

        $scope.goToPreviousWeek = () => {
            const week: Date = moment($scope.getWeek()).subtract(1, 'week').toDate();
            setPlannerWeekCookie(week);
            void updateCalendar();
            $scope.updateMonthFromWeek();
        };

        $scope.goToNextMonth = () => {
            const current: Date = moment(getMonth()).add(1, 'month').toDate();
            $scope.month = setPlannerMonthCookie(current);
            $scope.updateWeekFromMonth();
            void updateCalendar();
        };

        $scope.goToPreviousMonth = () => {
            const current: Date = moment(getMonth()).subtract(1, 'month').toDate();
            $scope.month = setPlannerMonthCookie(current);
            $scope.updateWeekFromMonth();
            void updateCalendar();
        };

        $scope.getLastDate = () => {
            const lastDate: Date = $scope.to ? new Date($scope.to.toISOString()) : new Date();
            lastDate.setDate(lastDate.getDate() - 1);
            return lastDate;
        };

        $scope.getPlannerLimit = dateIndex => {
            if (
                $scope.planners &&
                ($scope.showing === dateIndex || $scope.view === 'week') &&
                $scope.planners[dateIndex]
            ) {
                return $scope.planners[dateIndex].length;
            }
            return $scope.maxItems;
        };

        initPage();
    },
]);

interface MvPlannerDodgyDateModalCtrlScope extends IScope {
    cancel(): void;
    continue(): void;
}

angular.module('app').controller('mvPlannerDodgyDateModalCtrl', [
    $scopeSID,
    $modalInstanceSID,
    function mvPlannerDodgyDateModalCtrl($scope: MvPlannerDodgyDateModalCtrlScope, $modalInstance: ModalInstance) {
        $scope.cancel = () => {
            $modalInstance.dismiss('cancel');
        };

        $scope.continue = () => {
            $modalInstance.close('createPlanner');
        };
    },
]);

export interface MvPlannerEventUpdateRecurringDateCtrlScope extends IScope {
    chooseFuture(): void;
    chooseCurrent(): void;
    cancel(): void;
}

angular.module('app').controller('mvPlannerEventUpdateRecurringDateCtrl', [
    $scopeSID,
    $modalInstanceSID,
    function mvPlannerEventUpdateRecurringDateCtrl($scope: MvPlannerEventUpdateRecurringDateCtrlScope, $modalInstance: ModalInstance) {
        $scope.chooseFuture = () => {
            $modalInstance.close('future');
        };

        $scope.chooseCurrent = () => {
            $modalInstance.close('current');
        };

        $scope.cancel = () => {
            $modalInstance.close();
        };
    },
]);
