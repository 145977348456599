import * as React from 'react';
import styled from 'styled-components';
import { DSLoadMoreButton, DSWell, DSWellHeader, DSWellHeaderTitle, Translate } from '@deltasierra/components';
import { useQuery } from '@apollo/client';
import { useConnectionFetchMore } from '../../../../../graphql/hooks';
import { GetAdminClubReadyConnectPageClients, GetAdminClubReadyConnectPageClientsVariables } from './__graphqlTypes/GetAdminClubReadyConnectPageClients';
import { GET_ADMIN_CLUBREADY_CONNECT_PAGE_CLIENTS } from './ClubReadyChainIdConfigurationWell.queries';
import { ClubReadyChainIdConfigurationTable } from './ClubReadyChainIdConfigurationTable';

const StyledCenter = styled.div`
    text-align: center;
`;

export function ClubReadyChainIdConfigurationWell(): JSX.Element {
    const [clientTitleSearch, setClientTitleSearch] = React.useState<string | null>(null);

    const { data, fetchMore, loading } = useQuery<
        GetAdminClubReadyConnectPageClients,
        GetAdminClubReadyConnectPageClientsVariables
    >(
        GET_ADMIN_CLUBREADY_CONNECT_PAGE_CLIENTS,
        {
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true,
            variables: {
                filter: {
                    title: clientTitleSearch,
                },
                first: 5,
            },
        },
    );

    const [handleFetchMore, hasNextPage] = useConnectionFetchMore(data?.admin.clients, async after =>
        fetchMore({ variables: { after, first: 10 } }),
    );

    const clients = data?.admin.clients.edges.map(edge => edge.node) ?? [];

    const handleClientSearch = (clientTitle: string | null) => {
        setClientTitleSearch(clientTitle);
    };

    return (
        <DSWell>
            <DSWellHeader>
                <DSWellHeaderTitle>
                    <Translate keyId="ADMIN.CLUBREADY_CONNECT.CHAIN_ID_CONFIGURATION_TITLE"/>
                </DSWellHeaderTitle>
            </DSWellHeader>
            <ClubReadyChainIdConfigurationTable
                clients={clients}
                isLoading={loading}
                onClientSearch={handleClientSearch}
            />
            {hasNextPage && (
                <StyledCenter>
                    <DSLoadMoreButton disabled={loading} gutterTop loading={loading} onClick={handleFetchMore} />
                </StyledCenter>
            )}
        </DSWell>
    );
}

ClubReadyChainIdConfigurationWell.displayName = 'ClubReadyChainIdConfigurationWell';
