import * as linq from 'linq';
import { convertUnits, PrintUnits, BuilderTemplateFormat, isWithinAspectRatioTolerance } from '@deltasierra/shared';


import { MvIdentity } from './../../../account/mvIdentity';

interface PrintFormatsSelectorFormat {
    id: number;
    title: string;
    disabled: boolean;
}

export class PrintFormatsSelectorController {
    public static readonly $inject: string[] = ['$scope', '$filter', MvIdentity.SID];

    // Props
    public filterByAspectRatio?: number;

    public formats!: BuilderTemplateFormat[];

    public selectedFormats!: BuilderTemplateFormat[];

    // State
    public formatsToDisplay?: PrintFormatsSelectorFormat[];

    private filterNumber!: ng.IFilterNumber;

    public constructor(
        private readonly $scope: ng.IScope,
        private readonly $filter: ng.IFilterService,
        protected readonly identity: MvIdentity,
    ) {}

    public $onInit(): void {
        this.filterNumber = this.$filter('number');
        this.$scope.$watch(
            () => this.filterByAspectRatio,
            () => this.updateFormatsToDisplay(),
        );
        this.$scope.$watch(
            () => this.formats,
            () => this.updateFormatsToDisplay(),
            true,
        );
    }

    public formatFormat(format: BuilderTemplateFormat): string {
        return `${format.label} (${this.formatPixelsAsPrintUnits(format.width)} x ${this.formatPixelsAsPrintUnits(
            format.height,
        )}`;
    }

    public toggleFormat(format: PrintFormatsSelectorFormat): void {
        const formatToToggle = linq
            .from(this.formats)
            .firstOrDefault(potentialFormat => potentialFormat.id === (format && format.id));

        if (!formatToToggle) {
            return;
        }

        const selectedIndex = this.selectedFormats.indexOf(formatToToggle);

        if (selectedIndex === -1) {
            this.selectedFormats.push(formatToToggle);
        } else {
            this.selectedFormats.splice(selectedIndex, 1);
        }

        this.updateFormatsToDisplay();
    }

    public isSelected(format: PrintFormatsSelectorFormat): boolean {
        const result = linq.from(this.selectedFormats).any(potentialFormat => potentialFormat.id === format.id);
        return result;
    }

    private getSelectedAspectRatio() {
        const format = this.selectedFormats[0];
        return format && format.width / format.height;
    }

    private updateFormatsToDisplay() {
        const selectedAspectRatio = this.getSelectedAspectRatio();

        this.formatsToDisplay = this.formats
            .filter(format => format.type === 'print')
            .filter(
                format =>
                    !this.filterByAspectRatio ||
                    isWithinAspectRatioTolerance(format.width / format.height, this.filterByAspectRatio),
            )
            .map(format => ({
                disabled:
                    !!selectedAspectRatio &&
                    !isWithinAspectRatioTolerance(format.width / format.height, selectedAspectRatio),
                id: format.id,
                title: `${format.label} (${this.formatPixelsAsPrintUnits(
                    format.width,
                )} x ${this.formatPixelsAsPrintUnits(format.height)})`,
            }));
    }

    private formatPixelsAsPrintUnits(pixels: number): string {
        if (!this.identity.currentUser) {
            return '';
        }
        const printUnits = this.identity.currentUser.printUnits || PrintUnits.millimetres;
        const result = convertUnits(pixels, PrintUnits.pixels, printUnits);

        const decimalPlaces = printUnits === PrintUnits.inches ? 2 : 0;

        const formatted = this.filterNumber(result, decimalPlaces) + printUnits;

        return formatted;
    }
}

export const dsPrintFormatsSelectorSID = 'dsPrintFormatsSelector';
export const dsPrintFormatsSelectorConfig = {
    bindToController: {
        filterByAspectRatio: '@?',
        formats: '=',
        selectedFormats: '=',
    },
    controller: PrintFormatsSelectorController,
    controllerAs: 'ctrl',
    restrict: 'E',
    scope: {},
    templateUrl: '/partials/contentBuilder/print/printFormatsSelectorDirective/template',
};

angular.module('app').directive(dsPrintFormatsSelectorSID, [() => dsPrintFormatsSelectorConfig]);
