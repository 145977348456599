/// <reference path="../../../../typings/browser.d.ts" />
import { ServiceName } from '@deltasierra/integrations/integration-types';
import {
    AssignedLocation,
    BuilderTemplateFormat,
    BuilderTemplateFormatId,
    ExportPlatforms,
    ExternalService,
    FileFormatOptions,
    formatSizeOption,
    getFullLabelForFormat,
    isContentBuilderDocument,
    isPrintDocument,
} from '@deltasierra/shared';
import { IAngularEvent } from 'angular';
import * as linq from 'linq';
import { MvClient } from '../../clients/mvClient';
import { $scopeSID } from '../../common/angularData';
import { InteractionUtils } from '../../common/interactionUtils';
import { I18nService } from '../../i18n';
import { BuilderConstants, builderConstantsSID } from '../builderConstants';
import { ContentBuilder } from '../contentBuilder';
import { formats as ALL_IMAGE_FORMATS, ImageFormat, getFormatByName } from '../imageFormats';
import { MvIdentity } from './../../account/mvIdentity';
import { BuilderFileFormatOptions } from './mvContentBuilderPublishCtrl';

export interface FileFormatChoice {
    width: number;
    height: number;
    label: string;
    imageFormat: ImageFormat | null;
    includeBleed: boolean;
    builderTemplateFormatId?: BuilderTemplateFormatId;
    maxFileSize?: number;
}

interface SizeOption {
    label: string;
    width: number;
    height: number;
    builderTemplateFormatId?: BuilderTemplateFormatId;
}

export class MvContentBuilderFileFormatCtrl {
    public static SID = 'mvContentBuilderFileFormatCtrl';

    public static readonly $inject: string[] = [
        $scopeSID,
        I18nService.SID,
        MvClient.SID,
        InteractionUtils.SID,
        builderConstantsSID, // TODO: make into a normal module
        MvIdentity.SID,
    ];

    public ExportPlatforms = ExportPlatforms;

    public selectedPlatform!: ExternalService;

    public imageOptions: FileFormatOptions | null = null;

    public canPublishToFilter: ServiceName[] = [];

    public sizeOptions: SizeOption[] = [];

    public selection: {
        sizeOption: SizeOption | null;
        imageFormat: ImageFormat;
        maxFileSize?: number;
    } = {
        imageFormat: getFormatByName('PNG'),
        maxFileSize: undefined,
        sizeOption: null,
    };

    public imageFormat: ImageFormat | null = null;

    public imageFormats: ImageFormat[] = [];

    public includeBleed = true;

    public location!: AssignedLocation;

    public fetchFileSizeOptions = this.interactionUtils.createFuture(
        this.i18n.text.build.publish.fetchFileSizeOptions(),
        () =>
            this.clientService.getPlatforms(this.location.clientId).then(availablePlatformsForClient => {
                const sizeOptions: SizeOption[] = linq
                    .from(this.selectedBuilderTemplateFormats)
                    .where(
                        format =>
                            this.canPublishToFilter.length === 0 ||
                            (!!format.canPublishTo && this.canPublishToFilter.includes(format.canPublishTo)),
                    )
                    .where(format => availablePlatformsForClient.some(platform => platform.id === format.platformId))
                    .select(format => ({
                        builderTemplateFormatId: format.id,
                        height: format.height,
                        label: getFullLabelForFormat(format),
                        width: format.width,
                    }))
                    .orderBy(option => option.label)
                    .toArray();

                if (!this.canPublishToFilter && !this.isPrintDocument()) {
                    sizeOptions.unshift({
                        builderTemplateFormatId: undefined,
                        height: this.contentBuilder.document.dimensions.height,
                        label: this.i18n.text.build.publish.originalSize(),
                        width: this.contentBuilder.document.dimensions.width,
                    });
                }

                this.sizeOptions = sizeOptions;
            }),
    );

    private fileFormatOptions!: BuilderFileFormatOptions;

    private contentBuilder!: ContentBuilder;

    private selectedBuilderTemplateFormats!: BuilderTemplateFormat[];

    private isExclusivePrintProvider!: boolean;

    public constructor(
        private readonly $scope: angular.IScope,
        private readonly i18n: I18nService,
        private readonly clientService: MvClient,
        private readonly interactionUtils: InteractionUtils,
        private readonly builderConstants: BuilderConstants,
        private readonly identity: MvIdentity,
    ) {}

   public $onInit(): void {
        (this.$scope as any).i18n = this.i18n.text;
        this.init();
        this.updateMaxFileSize = this.updateMaxFileSize.bind(this);
    }

    public updateMaxFileSize(newSize: number): void {
        this.selection.maxFileSize = newSize;
    }

    public isImageDocument(): boolean {
        return isContentBuilderDocument(this.contentBuilder.document);
    }

    public isPrintDocument(): boolean {
        return isPrintDocument(this.contentBuilder.document);
    }

    public formatSizeOption(option: SizeOption): string {
        // TODO: instead of checking if the document is a print template, can we just look at the type of the BuilderTemplateFormat?
        if (!this.identity.currentUser) {
            return '';
        }
        const config = {
            isPrintDocument: this.isPrintDocument(),
            userPreferencePrintUnits: this.identity.currentUser.printUnits,
        };
        return formatSizeOption(option, config);
    }

    public cancel(): void {
        this.$scope.$emit(this.builderConstants.EVENTS.PUBLISH_CANCEL);
    }

    public maxFileSizeInputIsValid(): boolean {
        const hasFileSizeLimit = this.selection.imageFormat.hasFileSizeLimit;
        return (
            !hasFileSizeLimit || !!(hasFileSizeLimit && this.selection.maxFileSize && this.selection.maxFileSize >= 1)
        );
    }

    public choose(): void {
        const imageFormat = this.selection.imageFormat;
        const result: FileFormatChoice = {
            builderTemplateFormatId: this.selection.sizeOption!.builderTemplateFormatId,
            height: this.selection.sizeOption!.height,
            imageFormat,
            includeBleed: isPrintDocument(this.contentBuilder.document) ? this.includeBleed : false,
            label: this.selection.sizeOption!.label,
            maxFileSize: this.selection.maxFileSize ? this.selection.maxFileSize : undefined,
            width: this.selection.sizeOption!.width,
        };
        this.$scope.$emit(this.builderConstants.EVENTS.FILE_FORMAT_CHOSEN, result);
    }

    public filterLimitedFileSizeExportOptions(imageFormat: ImageFormat): boolean {
        return (
            !imageFormat.hasFileSizeLimit ||
            (imageFormat.hasFileSizeLimit && this.selectedPlatform === this.ExportPlatforms.Local)
        );
    }

    // This is a work around for the publish flow being reset when the user presses enter while on the form.
    public keypressHandler(event: IAngularEvent): void {
        if (event.keyCode === 13) {
            event.preventDefault();
        }
    }

    private init() {
        this.imageFormats = ALL_IMAGE_FORMATS;
        this.imageOptions = this.fileFormatOptions.imageOptions;
        this.canPublishToFilter = this.fileFormatOptions.canPublishToFilter;

        this.selection = {
            imageFormat:
                (this.imageOptions && getFormatByName(this.imageOptions.defaultFormat)) || getFormatByName('PNG'),
            maxFileSize: undefined,
            sizeOption: null,
        };

        return this.fetchFileSizeOptions.run({}).then(() => {
            this.selection.sizeOption = this.getMostAppropriateSizeOption(this.sizeOptions);

            // Don't prompt the user to choose if they only have one option
            const shouldSkipForPrintDocument =
                this.isPrintDocument() && this.isExclusivePrintProvider && this.sizeOptions.length === 1;
            const shouldSkipForImageOrVideoDocument =
                !this.isPrintDocument() &&
                this.sizeOptions.length === 1 &&
                this.imageOptions &&
                this.imageOptions.formats &&
                this.imageOptions.formats.length === 1;

            if (shouldSkipForPrintDocument || shouldSkipForImageOrVideoDocument) {
                this.choose();
            }
        });
    }

    /**
     * To support SKU groups, we need to pass through a Builder Template Format ID.
     * By default, we were selecting just the first (possibly default) size, which is not related to a BTF.
     * So, we instead choose the most appropriate size option, by checking if the size option was generated from
     * a BTF, and if its width and height match the document's dimensions.
     * If there's no viable candidate, we still default to the first size option, but SKU functionality will be
     * unavailable when sending to a print provider.
     *
     * @param sizeOptions - SizeOption[]
     * @returns SizeOption
     */
    private getMostAppropriateSizeOption(sizeOptions: SizeOption[]): SizeOption {
        if (!this.isPrintDocument()) {
            return sizeOptions[0];
        } else {
            for (const sizeOption of sizeOptions) {
                if (
                    !!sizeOption.builderTemplateFormatId &&
                    sizeOption.width === this.contentBuilder.document.dimensions.width &&
                    sizeOption.height === this.contentBuilder.document.dimensions.height
                ) {
                    return sizeOption;
                }
            }
            return sizeOptions[0];
        }
    }
}

angular.module('app').controller(MvContentBuilderFileFormatCtrl.SID, MvContentBuilderFileFormatCtrl);
