/// <reference path="../../_references.d.ts" />
import { OptionalStringBinding } from '../../common/angularData';
import { LocalePickerController } from './controller';

export const localePickerSID = 'dsLocalePicker';
export const localePickerConfig: ng.IDirective<ng.IScope> = {
    bindToController: true,
    controller: LocalePickerController,
    controllerAs: 'ctrl',
    replace: true,
    require: 'ngModel',
    restrict: 'E',
    scope: {
        name: OptionalStringBinding,
    },
    templateUrl: '/partials/directives/localePicker/template',
};

angular.module('app').directive(localePickerSID, [() => localePickerConfig]);
