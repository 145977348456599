import { MultiImagePicker } from '@deltasierra/components';
import { withAngularIntegration } from '../../../common/componentUtils/reactComponentRegistration';
import {
    ExpressionBinding,
    OneWayBinding,
    OptionalExpressionBinding,
    OptionalOneWayBinding,
} from '../../../common/angularData';

export default withAngularIntegration(MultiImagePicker, 'multiImagePicker', {
    disableAdd: OptionalOneWayBinding,
    disableRemove: OptionalOneWayBinding,
    items: OneWayBinding,
    onAddNew: OptionalExpressionBinding,
    onChange: ExpressionBinding,
    onDrop: OptionalExpressionBinding,
    onRemove: OptionalExpressionBinding,
    thumbnailSize: OptionalOneWayBinding,
    value: OneWayBinding,
});
