import { LocationPeriod } from '@deltasierra/shared';
import { FacebookMetricProviderService } from './metricProviders/facebookMetricProviderService';
import { InstagramMetricProviderService } from './metricProviders/instagramMetricProviderService';
import { TwitterMetricProviderService } from './metricProviders/twitterMetricProviderService';
import { Metric, MetricProviderService } from './metricProviders/common';

export class PerformanceSummaryService {
    static SID = 'PerformanceSummaryService';

    static readonly $inject : string[] = [
        '$q',
        FacebookMetricProviderService.SID,
        InstagramMetricProviderService.SID,
        TwitterMetricProviderService.SID,
    ];

    private metricProviderServices: MetricProviderService[] = [];

    constructor(
        private $q: ng.IQService,
        facebookMetricProviderService: FacebookMetricProviderService,
        instagramMetricProviderService: InstagramMetricProviderService,
        twitterMetricProviderService: TwitterMetricProviderService,
    ) {

        this.metricProviderServices = [
            instagramMetricProviderService,
            facebookMetricProviderService,
            twitterMetricProviderService,
        ];
    }

    getMetricRows(params: LocationPeriod): ng.IPromise<Array<Metric<any>>> {
        const allMetricPromises = this.metricProviderServices
            .map(provider => provider.getMetricRows(params));

        return this.$q
            .all<Array<Metric<any>>>(allMetricPromises)
            .then((metrics: Array<Array<Metric<any>>>) => metrics.reduce((result, current) => result.concat(current), []));
    }
}

angular.module('app').service(PerformanceSummaryService.SID, PerformanceSummaryService);
