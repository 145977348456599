import * as React from 'react';
import styled from 'styled-components';
import { t } from '@deltasierra/shared';
import { DSLoaderButton, LoaderButton } from '@deltasierra/components';

const LoadMoreWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const StyledLoaderButton = styled(LoaderButton)`
    display: flex;
    align-items: center;
    padding: 6px 12px;
    min-width: 120px;
`;

const LoadMore = styled.div`
    height: 32px;
    padding: 5px 10px;
`;

export type StyledLoadMoreProps = {
    disabled: boolean;
    loading: boolean;
    onClick: () => void;
};

export const StyledLoadMore: React.FC<StyledLoadMoreProps> = ({ disabled, loading, onClick }) => (
    <LoadMoreWrapper>
        <StyledLoaderButton color="primary" disabled={disabled} loading={loading} onClick={onClick}>
            <LoadMore>{t('COMMON.LOAD_MORE')}</LoadMore>
        </StyledLoaderButton>
    </LoadMoreWrapper>
);
StyledLoadMore.displayName = 'StyledLoadMore';

// Don't want to break current users of the styledLoadMore
// Need to provide the theme to angular components
export const DSLoadMore: React.FC<StyledLoadMoreProps> = ({ disabled, loading, onClick }) => (
    <LoadMoreWrapper>
        <DSLoaderButton color="primary" disabled={disabled} loading={loading} onClick={onClick}>
            {t('COMMON.LOAD_MORE')}
        </DSLoaderButton>
    </LoadMoreWrapper>
);
DSLoadMore.displayName = 'DSLoadMore';
