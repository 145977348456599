import * as React from 'react';
import styled from 'styled-components';
import { DSTypography, Translate } from '@deltasierra/components';

const StyledLocationListWrapper = styled.div`
    max-height: 150px;
    margin-bottom: 15px;
    overflow: scroll;
`;

export type InstagramDirectRateLimitedLocationsProps = {
    rateLimitedLocations: Array<{ id: string; title: string }>;
    failedLocations: Array<{ id: string; title: string }>;
    isLoading: boolean;
};

export const InstagramDirectRateLimitedLocations: React.FC<InstagramDirectRateLimitedLocationsProps> = props => (
    <>
        {(props.rateLimitedLocations.length > 0 || props.failedLocations.length > 0) && (
            <div>
                {props.rateLimitedLocations.length > 0 && (
                    <>
                        <DSTypography component="div" gutterBottom variant="body1">
                            <Translate keyId="ERRORS.INSTAGRAM_ERRORS.API_RATE_LIMITED" />
                        </DSTypography>
                        <StyledLocationListWrapper>
                            <ul>
                                {props.rateLimitedLocations.map(location => (
                                    <li key={location.id}>{location.title}</li>
                                ))}
                            </ul>
                        </StyledLocationListWrapper>
                    </>
                )}

                {props.failedLocations.length > 0 && (
                    <>
                        <DSTypography component="div" gutterBottom variant="body1">
                            <Translate keyId="ERRORS.INSTAGRAM_ERRORS.API_RATE_LIMIT_NOT_FOUND" />
                        </DSTypography>
                        <StyledLocationListWrapper>
                            <ul>
                                {props.failedLocations.map(location => (
                                    <li key={location.id}>{location.title}</li>
                                ))}
                            </ul>
                        </StyledLocationListWrapper>
                    </>
                )}

                <DSTypography component="div" gutterBottom variant="body1">
                    <Translate keyId="ERRORS.INSTAGRAM_ERRORS.API_RATE_LIMITED_CONTINUE_POSTING" />
                </DSTypography>
            </div>
        )}
    </>
);

InstagramDirectRateLimitedLocations.displayName = 'InstagramDirectRateLimitedLocations';
