/// <reference path="../../../typings/browser.d.ts" />
import { $documentSID, ExpressionBinding, RestrictToAttribute } from '../common/angularData';
import { isElementVisible } from '../common/elementUtils';
import IAugmentedJQuery = angular.IAugmentedJQuery;
import IDocumentService = angular.IDocumentService;

export const onceInViewSID = 'onceInView';

export const onceInViewConfig = {
    restrict: RestrictToAttribute,
    scope: {
        onceInView: ExpressionBinding,
    },
};
angular.module('app').directive(onceInViewSID, [
    $documentSID,
    // $timeoutSID,
    function (
        $document: IDocumentService,
        // $timeout : ITimeoutService
    ) {
        interface OnceInViewScope extends ng.IScope {
            onceInView: () => void;
        }

        return {
            ...onceInViewConfig,
            link(scope: OnceInViewScope, element: IAugmentedJQuery) {
                const unwatchers: Function[] = [];
                function update(): boolean {
                    const isInView = isElementVisible(element.get(0));
                    if (isInView) {
                        unwatch();
                        scope.onceInView();
                    }
                    return isInView;
                }

                function watch() {
                    // Unwatchers.push(scope.$watch(() => parent.heigh, () => {
                    //     $timeout(update, 1);
                    // }));
                    $document.bind('resize scroll', update);
                    element.bind('resize scroll', update);
                }

                function unwatch() {
                    for (const unwatcher of unwatchers) {
                        unwatcher();
                    }
                    unwatchers.length = 0;
                    $document.unbind('resize scroll', update);
                    element.unbind('resize scroll', update);
                }

                if (!update()) {
                    watch();
                }
            },
        };
    },
]);
