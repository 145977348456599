import { DSButton, DSGrid, DSTypography, ProgressBar, SvgLoading, Translate } from '@deltasierra/components';
import * as React from 'react';
import { isNotNullOrUndefined, isNullOrUndefined } from '@deltasierra/shared';
import styled from 'styled-components';
import { ScheduledPublishesSchedulingStatus } from '../../../../../../../__graphqlTypes/globalTypes';
import { StyledDSAlert } from '../../../../components';
import { MultiLocationLocationLoaderData } from '../hooks/useMultiLocationLoader';
import { LocationProgress } from './LocationProgress';
import { useScheduledPublishPoller } from './useScheduledPublishPoller';

const StyledDiv = styled.div`
    text-align: right;
`;

const StyledTypography = styled(DSTypography)`
    margin: 20px 0;
`;

export interface MultiLocationLoaderProps {
    children?: React.ReactNode;
    initialLocationProgressList: MultiLocationLocationLoaderData[];
    submit: () => void;
}

export const MultiLocationLoader: React.FC<MultiLocationLoaderProps> = ({
    children,
    initialLocationProgressList,
    submit,
}) => {
    const ids = React.useMemo(
        () =>
            initialLocationProgressList
                .map(locationProgressItem => locationProgressItem.scheduledPublish)
                .filter(isNotNullOrUndefined)
                .map(filteredScheduledPublish => filteredScheduledPublish.id),
        [initialLocationProgressList],
    );

    const [startPolling, { context, isComplete, locationsProgressList, progress, progressLabel }] =
        useScheduledPublishPoller({
            initialLocationProgressList,
            queryOptions: {
                variables: {
                    ids,
                },
            },
        });

    React.useEffect(() => {
        startPolling();
    }, [startPolling]);

    return (
        <>
            {children}
            <DSTypography variant="subtitle1">
                <Translate keyId="BUILD.PUBLISH.MULTIPLE_LOCATIONS.TOTAL_PUBLISHING_PROGRESS" />
            </DSTypography>
            <ProgressBar context={context} progress={progress} progressLabel={`${progressLabel}`} />
            <DSTypography gutterBottom variant="body1">
                <Translate keyId="BUILD.PUBLISH.MULTIPLE_LOCATIONS.PUBLISHING_TO_THESE_LOCATIONS" />
            </DSTypography>
            <DSGrid container spacing={1}>
                {locationsProgressList.map(locationData => (
                    <LocationProgress key={locationData.location.id} locationData={locationData} />
                ))}
            </DSGrid>
            {isComplete ? (
                <>
                    {locationsProgressList.some(
                        locationData =>
                            locationData.scheduledPublish?.schedulingStatus ===
                                ScheduledPublishesSchedulingStatus.failed ||
                            isNullOrUndefined(locationData.scheduledPublish),
                    ) ? (
                        <StyledTypography variant="body1">{'Some locations failed to publish'}</StyledTypography>
                    ) : (
                        <StyledTypography variant="body1">
                            {'Congratulations, all locations successfully published'}
                        </StyledTypography>
                    )}
                    <StyledDSAlert severity="info" style={{ marginBottom: '1rem', marginTop: '1rem' }}>
                        <Translate keyId="BUILD.PUBLISH.MULTIPLE_LOCATIONS.EDIT_UNDO_ALERT_MESSAGE.PART_1" />
                        <a href="/scheduled">
                            <Translate keyId="BUILD.PUBLISH.MULTIPLE_LOCATIONS.EDIT_UNDO_ALERT_MESSAGE.PART_2" />
                        </a>
                        <Translate keyId="BUILD.PUBLISH.MULTIPLE_LOCATIONS.EDIT_UNDO_ALERT_MESSAGE.PART_3" />
                    </StyledDSAlert>
                    <StyledDiv>
                        <DSButton color="primary" onClick={submit}>
                            <Translate keyId="COMMON.CONTINUE" />
                        </DSButton>
                    </StyledDiv>
                </>
            ) : (
                <SvgLoading />
            )}
        </>
    );
};

MultiLocationLoader.displayName = 'MultiLocationLoader';
