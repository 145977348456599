import { ExpressionCallback, ILifecycleHooks } from '../../common/angularData';

import emojis from './emojis.min.json';

export interface EmojiDefinition {
    htmlEncoded: string;
    text: string;
}

export class EmojiPickerController implements ILifecycleHooks {
    // Props
    public onSelect!: ExpressionCallback<{ emoji: string }>;

    public placement?: 'bottom' | 'left' | 'right' | 'top';

    // State
    public buttonIcon = '😃';

    public template = 'modal/emoji-picker.html';

    public emojiCodes: EmojiDefinition[] = uncompress(emojis);

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public static readonly $inject: string[] = [];

    public $onInit(): void {
        this.placement ||= 'top';
    }

    public onSelectEmoji(emoji: EmojiDefinition) {
        this.onSelect({ emoji: emoji.text });
    }
}

function uncompress(emojiList: string[][]): EmojiDefinition[] {
    return emojiList.map(([emoji, htmlCode]) => ({
        htmlEncoded: htmlCode,
        text: emoji,
    }));
}
