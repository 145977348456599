/// <reference path="../../../typings/browser.d.ts" />
import { IPromise } from 'angular';

function debounceFactory($timeout: ng.ITimeoutService): IDebounce {
    return (func: Function, threshold: number, execAsap?: boolean) => {
        let timeoutId: IPromise<void> | null;
        return function (this: any) {
            function delayed(this: any) {
                if (!execAsap) {
                    func.apply(this);
                }
                timeoutId = null;
            }

            if (timeoutId) {
                $timeout.cancel(timeoutId);
            } else if (execAsap) {
                func.apply(this);
            }

            timeoutId = $timeout(delayed, threshold);
        };
    };
}

export type IDebounce = (func: Function, threshold: number, execAsap?: boolean) => () => void;

export const debounceFactorySID = 'debounce';

angular.module('app').factory(debounceFactorySID, ['$timeout', debounceFactory]);
