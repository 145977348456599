import { gql } from '@apollo/client';

export const SAVE_BUILDER_TEMPLATE_LOCATION_DRAFT_MUTATION = gql`
    mutation SaveBuilderTemplateLocationDraftForContentBuilder($input: SaveLocationDraftInput!) {
        saveBuilderTemplateLocationDraft(input: $input) {
            __typename
            ... on SaveLocationDraftSuccessResponse {
                locationDraft {
                    id
                    title
                    builderTemplateId
                    document
                    updatedAt
                    compositeImage {
                        id
                        filename
                        thumb256x256url
                    }
                }
            }
        }
    }
`;
