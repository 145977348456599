import { Tooltip } from '@deltasierra/components';
import { t, TemplateGroupItem } from '@deltasierra/shared';
import * as React from 'react';
import styled from 'styled-components';
import { OptionalOneWayBinding } from '../../../common/angularData';
import { withAngularIntegration } from '../../../common/componentUtils/reactComponentRegistration';

const StyledBadge = styled.span.attrs({ className: 'badge' })`
    cursor: default;
    background-color: ${({ theme }) => theme.palette.primary.main};
    border-radius: 10rem;
    text-align: center;
`;

const StyledBadgeContents = styled.div`
    display: flex;
    margin: 5px 4px;
    justify-content: center;
`;

const StyledMultiImageIcon = styled.i.attrs({ className: 'fas fa-th-large' })`
    font-size: 13px;
    margin-right: 6px;
`;

const StyledCounter = styled.span`
    font-size: 13px;
    line-height: 12.5px;
`;

export type MultiImageTemplateCounterProps = {
    builderTemplate: TemplateGroupItem;
};

function MultiImageTemplateCounter({ builderTemplate }: MultiImageTemplateCounterProps): JSX.Element {
    return builderTemplate.multiImageCount ? (
        <Tooltip tip={t('BUILD.MULTI_IMAGE.MULI_IMAGE_COUNTER_TOOLTIP', { count: builderTemplate.multiImageCount })}>
            <StyledBadge>
                <StyledBadgeContents>
                    <StyledMultiImageIcon />
                    <StyledCounter>{builderTemplate.multiImageCount}</StyledCounter>
                </StyledBadgeContents>
            </StyledBadge>
        </Tooltip>
    ) : (
        <></>
    );
}
MultiImageTemplateCounter.displayName = 'MultiImageTemplateCounter';

export const MultiImageTemplateCounterWithAngular = withAngularIntegration(
    MultiImageTemplateCounter,
    'multiImageTemplateCounter',
    {
        builderTemplate: OptionalOneWayBinding,
    },
);
