import { I18nService } from '../../i18n';

export class LocalePickerController {
    static readonly $inject : string[] = [I18nService.SID];

    localeCode?: string;

    locales = this.i18n.getLocales();

    required?: boolean;

    constructor(private i18n: I18nService) {
    }
}
