import { gql, useQuery } from '@apollo/client';
import { GetIsInstagramFirstCommentHashtagsEnabled } from './__graphqlTypes/GetIsInstagramFirstCommentHashtagsEnabled';

const GET_IS_INSTAGRAM_FIRST_COMMENT_HASHTAGS_ENABLED = gql`
    query GetIsInstagramFirstCommentHashtagsEnabled {
        config {
            id
            features {
                publishing {
                    instagramFirstCommentHashtags
                }
            }
        }
    }
`;

export function useIsInstagramFirstCommentHashtagsEnabled(): boolean {
    const { data } = useQuery<GetIsInstagramFirstCommentHashtagsEnabled>(
        GET_IS_INSTAGRAM_FIRST_COMMENT_HASHTAGS_ENABLED,
    );
    return !!data?.config.features.publishing.instagramFirstCommentHashtags;
}
