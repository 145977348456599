import { gql } from '@apollo/client';

export const PUBLISH_LINKED_IN_IMAGE = gql`
    mutation PublishLinkedInImage($input: PublishLinkedInImageInput!) {
        publishLinkedInImage(input: $input) {
            ... on PublishResultPayload {
                workflow {
                    id
                    executionId
                    startDate
                    status {
                        state
                        message
                    }
                }
            }
            ... on PublishError {
                code
                message
            }
        }
    }
`;
