import { DSChip } from '@deltasierra/components';
import { ChecklistItemStateHandler } from '@deltasierra/react-hooks';
import * as React from 'react';
import { LocationsChecklistItem } from './LocationsChecklistItem';
import { ClientFragmentForLocationsChecklist } from './__graphqlTypes/ClientFragmentForLocationsChecklist';
import { LocationFragmentForLocationsChecklist } from './__graphqlTypes/LocationFragmentForLocationsChecklist';

export type LocationsClientChecklistType = {
    checked: boolean;
    checkedCount: number;
    indeterminate: boolean;
};

export type LocationsChecklistItemClientProps = {
    checked: boolean;
    client: ClientFragmentForLocationsChecklist;
    indeterminate: boolean;
    locationCheckedCount: number;
    locations: LocationFragmentForLocationsChecklist[];
    onChecked: ChecklistItemStateHandler;
};

export function LocationsChecklistItemClient({
    checked,
    client,
    indeterminate,
    locationCheckedCount,
    locations,
    onChecked,
}: LocationsChecklistItemClientProps): JSX.Element {
    return (
        <LocationsChecklistItem
            checkboxOnly
            checked={checked}
            chip={
                <DSChip
                    color="primary"
                    label={`${locationCheckedCount}/${locations.length}`}
                    size="small"
                    variant={checked ? 'default' : 'outlined'}
                />
            }
            indeterminate={indeterminate}
            title={client.title}
            type="secondary"
            onClick={() => {
                onChecked(
                    locations.map(loc => loc.id),
                    {
                        checked: indeterminate ? true : !checked,
                        indeterminate: false,
                    },
                );
            }}
        />
    );
}
LocationsChecklistItemClient.displayName = 'LocationsChecklistItemClient';

export const MemoizedLocationsChecklistItemClient = React.memo(LocationsChecklistItemClient);
