import { BatchStatsEntry } from '@deltasierra/shared';
import { ReportTable, StatsEntry, TextAlignClass } from '../reportTable/reportTable';
import { GroupedFitwareMemberEngagerStats } from '../../integration/stats/fitwareMemberEngagerStatsService';
import {
    CreateExpandableReportTableOptions,
    CreateReportTableOptions,
    dateColumn,
    numberColumn,
} from './common';

export interface CreateFitwareMemberEngagerReportTableOptions extends CreateReportTableOptions, CreateExpandableReportTableOptions {
}

export type FitwareMemberEngagerTableRowType = BatchStatsEntry | GroupedFitwareMemberEngagerStats;
export type FitwareMemberEngagerTableRowStatsType = StatsEntry<BatchStatsEntry | GroupedFitwareMemberEngagerStats>;

function isParentNode(entry: FitwareMemberEngagerTableRowType): entry is GroupedFitwareMemberEngagerStats {
    return !!(entry as GroupedFitwareMemberEngagerStats).entries;
}

function getLocationOrRowValue(value: FitwareMemberEngagerTableRowStatsType | FitwareMemberEngagerTableRowStatsType): string {
    if (isParentNode(value)) {
        return value.location.locationTitle;
    } else {
        return value.title;
    }
}

function formatLocationOrRowValue(value: string | undefined, entry: FitwareMemberEngagerTableRowStatsType | FitwareMemberEngagerTableRowType | undefined): string {
    if (!entry) {
        return value || '';
    }
    if (isParentNode(entry)) {
        return value || '';
    } else {
        return `
            <div class="entry-label"> ${value} </div>
            <div class="description-line"> ${entry.memberListNames.join(', ')} </div>
        `;
    }
}

function getBatchStatsEntryForNode(entry: FitwareMemberEngagerTableRowType): BatchStatsEntry {
    if (isParentNode(entry)) {
        return entry.totals;
    } else {
        return entry;
    }
}

export function createFitwareMemberEngagerReportTable(options: CreateFitwareMemberEngagerReportTableOptions) {
    return new ReportTable<FitwareMemberEngagerTableRowStatsType, FitwareMemberEngagerTableRowType>({
        columns: [
            {
                label: () => options.i18n.text.report.fitwareMemberEngager.locationEmail(),
                cssClass: TextAlignClass.Left,
                format: formatLocationOrRowValue,
                getValue: getLocationOrRowValue,
                getTotal: () => options.i18n.text.report.total(),
            },
            dateColumn(options, {
                label: () => options.i18n.text.report.scheduledDate(),
                getValue: entry => !isParentNode(entry) ? entry.date : undefined,
                getTotal: totals => undefined,
            }),
            numberColumn(options, {
                label: () => options.i18n.text.report.recipients(),
                getValue: entry => getBatchStatsEntryForNode(entry).recipientStats.sent,
                getTotal: totals => getBatchStatsEntryForNode(totals).recipientStats.sent,
            }),
            numberColumn(options, {
                label: () => options.i18n.text.report.fitwareMemberEngager.opens(),
                getValue: entry => getBatchStatsEntryForNode(entry).recipientStats.opens,
                getTotal: totals => getBatchStatsEntryForNode(totals).recipientStats.opens,
            }),
            numberColumn(options, {
                label: () => options.i18n.text.report.clicks(),
                getValue: entry => getBatchStatsEntryForNode(entry).recipientStats.clicks,
                getTotal: totals => getBatchStatsEntryForNode(totals).recipientStats.clicks,
            }),
            numberColumn(options, {
                label: () => options.i18n.text.report.fitwareMemberEngager.bounces(),
                getValue: entry => getBatchStatsEntryForNode(entry).recipientStats.bounces,
                getTotal: totals => getBatchStatsEntryForNode(totals).recipientStats.bounces,
            }),
            numberColumn(options, {
                label: () => options.i18n.text.report.fitwareMemberEngager.unsubscribes(),
                getValue: entry => getBatchStatsEntryForNode(entry).recipientStats.unsubscribes,
                getTotal: totals => getBatchStatsEntryForNode(totals).recipientStats.unsubscribes,
            }),
            numberColumn(options, {
                label: () => options.i18n.text.report.fitwareMemberEngager.spamReports(),
                getValue: entry => getBatchStatsEntryForNode(entry).recipientStats.spamReports,
                getTotal: totals => getBatchStatsEntryForNode(totals).recipientStats.spamReports,
            }),
        ],
        tree: {
            getChildEntries: entry => isParentNode(entry) ? entry.entries : ReportTable.EMPTY_ENTRY_ARRAY,
            canExpandOrCollapseEntry: entry => isParentNode(entry),
            getIsEntryExpanded: entry => isParentNode(entry) ? options.getIsLocationExpanded(entry.location.locationId) : false,
            setIsEntryExpanded: (entry, value) => {
                if (isParentNode(entry)) {
                    options.setIsLocationExpanded(entry.location.locationId, value);
                }
            },
        },
    });
}
