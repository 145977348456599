import { DSDialog } from '@deltasierra/components';
import React, { useCallback } from 'react';
import { MoveFolderDialogContent, MoveFolderDialogContentProps } from './MoveFolderDialogContent';
import { useMoveAssets } from './useMoveAssets';

export type MoveFolderDialogProps = {
    assetIdsToMove: MoveFolderDialogContentProps['assetIdsToMove'];
    initialCollectionOrFolderId: MoveFolderDialogContentProps['initialCollectionOrFolderId'];
    isOpen: boolean;
    onRequestClose?: MoveFolderDialogContentProps['onRequestClose'];
};

export function MoveFolderDialog(props: MoveFolderDialogProps): JSX.Element {
    const { isOpen, onRequestClose, ...rest } = props;

    const { assetsLeftToMove, isMoving, moveAssets } = useMoveAssets({
        refetchQueries: ['GetCollectionForCollectionAssets', 'GetCollectionsForAssetLibraryWithLocation'],
    });

    const onClose = useCallback(() => {
        if (isMoving) {
            return;
        }
        onRequestClose?.();
    }, [isMoving, onRequestClose]);

    return (
        <DSDialog fullWidth maxWidth="md" open={isOpen} onClose={onClose}>
            <MoveFolderDialogContent
                {...rest}
                assetsLeftToMove={assetsLeftToMove}
                isMoving={isMoving}
                moveAssets={moveAssets}
                onRequestClose={onClose}
            />
        </DSDialog>
    );
}
MoveFolderDialog.displayName = 'MoveFolderDialog';
