/// <reference path="../../../typings/browser.d.ts" />
import {
    AssignedLocation,
    ClientId,
    LocationCategoryGroup,
    LocationCategoryOption,
    LocationCategoryOptionId,
    LocationId,
} from '@deltasierra/shared';
import { MvClientResource, mvClientResourceSID } from '../clients/mvClientResource';
import { $filterSID, $locationSID, $qSID, $routeParamsSID, $scopeSID } from '../common/angularData';
import { DataUtils } from '../common/dataUtils';
import { InteractionUtils } from '../common/interactionUtils';
import { MvNotifier } from '../common/mvNotifier';
import { NavUtils } from '../common/navUtils';
import { I18nService } from '../i18n';
import { MvLocation } from './mvLocation';
import {
    MvLocationCategoryOptionsResource,
    mvLocationCategoryOptionsResourceSID,
} from './mvLocationCategoryOptionsResource';
import IScope = angular.IScope;
import IRouteParams = angular.IRouteParams;
import ILocationService = angular.ILocationService;
import IQService = angular.IQService;
import IFilterService = angular.IFilterService;

export class MvLocationCategoriesCtrl {
    public static SID = 'mvLocationCategoriesCtrl';

    public static readonly $inject: string[] = [
        $scopeSID,
        $routeParamsSID,
        $locationSID,
        $qSID,
        $filterSID,
        mvClientResourceSID,
        MvLocation.SID,
        MvNotifier.SID,
        mvLocationCategoryOptionsResourceSID,
        InteractionUtils.SID,
        DataUtils.SID,
        NavUtils.SID,
        I18nService.SID,
    ];

    public client = null;

    public location: AssignedLocation | null = null;

    public categories: LocationCategoryGroup[] | null = null;

    public locationCategories: LocationCategoryOption[] | null = null;

    public loading: {
        categories: boolean;
        client: boolean;
        location: boolean;
        locationCategories: boolean;
        save: boolean;
    } = {
        categories: false,
        client: false,
        location: false,
        locationCategories: false,
        save: false,
    };


    public constructor(
        protected readonly $scope: IScope,
        protected readonly $routeParams: IRouteParams,
        protected readonly $location: ILocationService,
        protected readonly $q: IQService,
        protected readonly $filter: IFilterService,
        protected readonly mvClientResource: MvClientResource,
        protected readonly mvLocation: MvLocation,
        protected readonly mvNotifier: MvNotifier,
        protected readonly mvLocationCategoryOptionsResource: MvLocationCategoryOptionsResource,
        protected readonly interactionUtils: InteractionUtils,
        protected readonly dataUtils: DataUtils,
        protected readonly navUtils: NavUtils,
        private readonly i18n: I18nService,
    ) {
        void this.initPage();
    }

    public isActive(route: string): boolean {
        return this.navUtils.isActive(route);
    }

    public initPage() {
        const clientId = ClientId.from(parseInt(this.$routeParams.clientId, 10));
        const locationId = LocationId.from(parseInt(this.$routeParams.id, 10));
        return this.$q.all([
            this.interactionUtils
                .handleResource(this, 'retrieve client', 'client', this.mvClientResource.get({ id: clientId }))
                .then(client => this.client = client),
            this.interactionUtils
                .handleRemoteSimple(
                    this,
                    'retrieve location',
                    'location',
                    this.mvLocation.getAssignedLocation(locationId),
                )
                .then(location => this.location = location),
            this.interactionUtils
                .handleResource(
                    this,
                    'retrieve categories',
                    'categories',
                    this.mvLocationCategoryOptionsResource.queryWithOptions({ clientId }),
                )
                .then(categories => this.categories = categories),
            this.interactionUtils
                .handleRemoteSimple(
                    this,
                    'retrieve location categories',
                    'locationCategories',
                    this.mvLocation.getLocationCategories(locationId),
                )
                .then(locationCategories => this.locationCategories = locationCategories),
        ]);
    }

    public getCategories(): LocationCategoryOption[] {
        return this.locationCategories || [];
    }

    public mapToCategoryIds(): LocationCategoryOptionId[] {
        return this.dataUtils.extractIds(this.getCategories());
    }

    public removeExistingSelectedCategory(groupId: number): void {
        if (this.locationCategories) {
            const filter = this.$filter('filter');
            this.locationCategories = filter(
                this.getCategories(),
                (value: LocationCategoryOption) => value.groupId != groupId,
            );
        }
    }

    public isSelected(categoryOption: LocationCategoryOption): boolean {
        return this.dataUtils.existsBy('id', this.getCategories(), categoryOption.id);
    }

    public selectCategory(categoryOption: LocationCategoryOption): void {
        this.removeExistingSelectedCategory(categoryOption.groupId);
        if (!this.locationCategories) {
            this.locationCategories = [];
        }
        this.locationCategories.push(categoryOption);
    }

    public toggleCategory(categoryOption: LocationCategoryOption): void {
        this.dataUtils.toggleById(categoryOption, this.getCategories());
    }

    public hasCategory(category: LocationCategoryGroup): boolean {
        return this.dataUtils.existsBy('groupId', this.getCategories(), category.id);
    }

    public selectNone(category: LocationCategoryGroup): void {
        this.removeExistingSelectedCategory(category.id);
    }


    public saveCategories(): ng.IPromise<void> | void {
        if (this.location) {
            const categoryIds = this.mapToCategoryIds();
            return this.interactionUtils
                .handleRemoteSimple(
                    this,
                    'save location categories',
                    'save',
                    this.mvLocation.updateCategories(this.location.id, categoryIds),
                )
                .then(() => {
                    this.mvNotifier.notify(this.i18n.text.agency.client.location.categories.notifyUpdated());
                });
        }
    }
}

angular.module('app').controller(MvLocationCategoriesCtrl.SID, MvLocationCategoriesCtrl);
