/* eslint-disable max-lines-per-function */
import { DSAutocomplete, DSChip, DSTextField, DSTextFieldProps } from '@deltasierra/components';
import { filterUnique } from '@deltasierra/array-utilities';
import * as React from 'react';
import { checkHashSymbolWithinHashtag } from '../../contentBuilder/publish/common/check-hash-symbol-within-hashtag';
import { splitStringWithCharacterRemoveEmpty } from '../../contentBuilder/publish/common/split-string-with-character-remove-empty';

const EMPTY_STRING_ARRAY: string[] = [];

export type HashtagsInputProps = Pick<
    DSTextFieldProps,
    'error' | 'fullWidth' | 'helperText' | 'label' | 'placeholder'
> & {
    allowDuplicates?: boolean;
    disabledHashtags?: string[];
    innerRef?: React.MutableRefObject<HTMLElement | null>;
    onChange: (newValue: string[]) => void;
    value: string[];
};
export function HashtagsInput({
    allowDuplicates,
    disabledHashtags,
    error,
    fullWidth,
    helperText,
    innerRef,
    label,
    onChange,
    placeholder,
    value,
}: HashtagsInputProps): JSX.Element {
    const [inputValue, setInputValue] = React.useState('');

    const handleOnChange = React.useCallback(
        (_event: React.ChangeEvent<unknown> | null, newValue: string[]): void => {
            setInputValue('');

            let newValuesWithRequired = newValue;

            // If we have disabled hashtags we have some funky logic
            if (disabledHashtags) {
                // If we are clearing the list
                if (newValue.length <= 0) {
                    // Make sure the required hashtags exist in the initial order
                    newValuesWithRequired = [
                        ...value.filter(val => disabledHashtags.includes(val)),
                        // If any are missing, re-add them
                        ...disabledHashtags ?? [],
                    ].filter(filterUnique());
                    // Otherwise we are modifying the list, so we check if every hashtag exists
                } else if (!disabledHashtags.every(hashtag => newValue.includes(hashtag))) {
                    // If every hashtag does not exist in the list
                    // We deny this operation
                    return;
                }
            }

            const withHashtags = newValuesWithRequired.map(item => (item.indexOf('#') === 0 ? item : `#${item}`));
            const newHashtags = allowDuplicates ? withHashtags : withHashtags.filter(filterUnique());

            onChange(newHashtags);
        },
        [allowDuplicates, disabledHashtags, onChange, value],
    );

    const handleInputOnChange = React.useCallback(
        (_event: React.ChangeEvent<unknown>, newValue: string, reason: 'clear' | 'input' | 'reset'): void => {
            if (reason === 'input' || reason === 'clear') {
                // If the new value includes a hash not in the first character
                if (checkHashSymbolWithinHashtag(newValue)) {
                    const parts = splitStringWithCharacterRemoveEmpty(newValue, '#');

                    // If we have parts onchange them
                    if (parts.length > 0) {
                        handleOnChange(null, [...value, ...parts]);
                    }
                    // Otherwise, clear the input
                    setInputValue('');
                    return;
                }
                // If the new value includes a space
                if (newValue.includes(' ')) {
                    const parts = splitStringWithCharacterRemoveEmpty(newValue, ' ');

                    // If we have parts onchange them
                    if (parts.length > 0) {
                        handleOnChange(null, [...value, ...parts]);
                    }
                    // Otherwise, clear the input
                    setInputValue('');
                } else {
                    setInputValue(newValue);
                }
            }
        },
        [handleOnChange, value],
    );

    // This helps add the value if the user forgets to click enter
    const handleBlur = () => {
        if (inputValue.trim().length > 0) {
            handleOnChange(null, [...value, inputValue.trim()]);
        }
        setInputValue('');
    };

    const valueWithHashtags = value.map(item => (item.indexOf('#') === 0 ? item : `#${item}`));

    /*
     * Rather than making our own tagging component, we can re-use the autocomplete component and disable the
     * autocomplete part. That leaves us with an excellent tagging input.
     *
     * @see https://v4.mui.com/components/autocomplete/
     */
    return (
        <DSAutocomplete
            freeSolo
            fullWidth={fullWidth}
            handleHomeEndKeys
            inputValue={inputValue}
            multiple
            open={false}
            options={EMPTY_STRING_ARRAY}
            renderInput={params => (
                <DSTextField
                    {...params}
                    InputProps={{ ...params.InputProps, innerRef }}
                    data-cy="hashtag-input"
                    error={error}
                    helperText={helperText}
                    label={label}
                    placeholder={placeholder}
                    variant="outlined"
                />
            )}
            renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => {
                    const isDisabledHashtag = disabledHashtags?.includes(option);
                    return (
                        <DSChip
                            label={option}
                            title={option}
                            {...getTagProps({ index })}
                            deleteIcon={isDisabledHashtag ? <></> : undefined}
                            disabled={isDisabledHashtag}
                            key={option}
                        />
                    );
                })
            }
            selectOnFocus
            style={{ maxWidth: '100%' }}
            value={valueWithHashtags}
            onBlur={handleBlur}
            onChange={handleOnChange}
            onInputChange={handleInputOnChange}
        />
    );
}
HashtagsInput.displayName = 'HashtagsInput';
