/// <reference path="../../../typings/browser.d.ts" />
import {
    AssignPlannerBody,
    BasicChannelDto,
    ClientId,
    DeletePlannerBody,
    GalleryPlannerDetails,
    GetPlannerOverviewDetailsBody,
    GetPlannerUserInfoBody,
    justParams,
    LocationId,
    OverviewPlannerDetails,
    Planner,
    PlannerApi,
    PlannerPublishedArtifact,
    PlannerUserInfo,
    Untyped,
    UpdatePlannerStatusBody,
} from '@deltasierra/shared';
import { PublishableService } from '@deltasierra/integrations/integration-types';
import { $httpSID, $qSID } from '../common/angularData';
import { getData, invokeApiRoute } from '../common/httpUtils';
import { mvPlannerResourceSID } from './mvPlannerResource';
import IHttpPromise = angular.IHttpPromise;
import IPromise = angular.IPromise;
import IQService = angular.IQService;
import IHttpService = angular.IHttpService;

export class MvPlanner {
    public static readonly SID = 'mvPlanner';

    public static readonly $inject: string[] = [$httpSID, $qSID, mvPlannerResourceSID];

    public constructor(private $http: IHttpService, private $q: IQService, private mvPlannerResource: Untyped) {}

    public getUpcomingRequiringArtwork(locationId: LocationId, dateFrom: Date, dateTo: Date): IHttpPromise<Planner[]> {
        const payload = {
            dateFrom,
            dateTo,
            locationId,
        };
        return this.$http.post<Planner[]>('/api/planners/upcomingRequiringArtwork', payload);
    }

    public getUpcomingReadyToBuild(locationId: LocationId, dateFrom: Date, dateTo: Date): IHttpPromise<Planner[]> {
        const payload = {
            dateFrom,
            dateTo,
            locationId,
        };
        return this.$http.post<Planner[]>('/api/planners/upcomingReadyToBuild', payload);
    }

    public getUpcomingPlanned(locationId: LocationId, dateFrom: Date, dateTo: Date): IHttpPromise<Planner[]> {
        const payload = {
            dateFrom,
            dateTo,
            locationId,
        };
        return this.$http.post<Planner[]>('/api/planners/upcomingPlanned', payload);
    }

    public getSupportedPlannerCount(locationId: Untyped, date: Untyped): IHttpPromise<{ count: number }> {
        const payload = {
            date,
            locationId,
        };
        return this.$http.post<{ count: number }>('/api/planners/assigned/count', payload);
    }

    public getPlannerOverviewDetails(body: GetPlannerOverviewDetailsBody): IHttpPromise<OverviewPlannerDetails> {
        return this.$http.post('/api/planners/overview-details', body);
    }

    public getPlannerGalleryDetails(plannerId: number): IHttpPromise<GalleryPlannerDetails> {
        return this.$http.get(`/api/planners/${plannerId}/gallery-details`);
    }

    public getPlannerPublishedArtifacts(plannerId: number): IPromise<PlannerPublishedArtifact[]> {
        return invokeApiRoute(this.$http, PlannerApi.getPublishedArtifacts, justParams({ id: plannerId }));
    }

    public getPlannerPublishedPlatforms(plannerId: number): IHttpPromise<{ publishedPlatforms: PublishableService[] }> {
        return this.$http.get<{ publishedPlatforms: PublishableService[] }>(
            `/api/planners/${plannerId}/published-platforms`,
        );
    }

    public getPlannerUserInfo(body: GetPlannerUserInfoBody): IHttpPromise<PlannerUserInfo> {
        return this.$http.post<PlannerUserInfo>('/api/planners/info', body);
    }

    public createPlanner(newPlannerData: Untyped): IPromise<Planner> {
        const newPlanner = new this.mvPlannerResource(newPlannerData);
        const dfd = this.$q.defer<Planner>();

        newPlanner
            .$save()
            .then(
                (response: Untyped) => {
                    dfd.resolve(response);
                },
                (response: Untyped) => {
                    dfd.reject(response.data);
                },
            )

            .catch(dfd.reject);
        return dfd.promise;
    }

    public updatePlanner(updatedPlannerData: Untyped): IPromise<unknown> {
        const dfd = this.$q.defer();
        const updatedPlanner = new this.mvPlannerResource(updatedPlannerData);
        updatedPlanner
            .$update()
            .then(
                (response: Untyped) => {
                    dfd.resolve(response.planner);
                },
                (response: Untyped) => {
                    dfd.reject(response.data);
                },
            )

            .catch(dfd.reject);
        return dfd.promise;
    }

    public deletePlanner(plannerData: DeletePlannerBody): IHttpPromise<void> {
        return this.$http.post<void>('/api/planners/delete', plannerData);
    }

    public getPlanner(
        clientId: ClientId,
        locationId: LocationId,
        date: Date,
        plannerId: number,
        recurringPlannerId: number | undefined,
    ): IHttpPromise<unknown> {
        const payload = {
            clientId,
            date,
            locationId,
            plannerId,
            recurringPlannerId,
        };
        return this.$http.post('/api/planners/get', payload);
    }

    public async getAllChannels(): Promise<BasicChannelDto[]> {
        return this.$http.get<BasicChannelDto[]>('/api/planners/get-all-channels').then(getData);
    }

    public updatePlannerStatus(body: UpdatePlannerStatusBody): IHttpPromise<Planner> {
        return this.$http.post('/api/planners/update-status', body);
    }

    public updatePlannerDate(locationId: LocationId, plannerId: number, date: Untyped): IHttpPromise<unknown> {
        const payload = {
            date,
            locationId,
            plannerId,
        };
        return this.$http.post('/api/planners/update-date', payload);
    }

    public updateRecurringPlannerDate(applyActionTo: string, date: Date, planner: Untyped): IHttpPromise<Planner> {
        const payload = {
            applyActionTo,
            date,
            planner,
        };
        return this.$http.post('/api/planners/update-recurring-date', payload);
    }

    public setStatusToPlanned(plannerId: Untyped): IHttpPromise<unknown> {
        return this.$http.put(`/api/planners/${plannerId}/plan`, undefined);
    }

    public assignPlanner(body: AssignPlannerBody): IHttpPromise<Planner> {
        return this.$http.post('/api/planners/assign', body);
    }
}

angular.module('app').service(MvPlanner.SID, MvPlanner);
