import * as React from 'react';
import { ErrorMessage, Translate } from '@deltasierra/components';
import { ClientPicker } from './common/components';
import { ErrorBoundary } from './common/componentUtils/ErrorBoundary';
import { withAngularIntegration } from './common/componentUtils/reactComponentRegistration';
import { RootRouter } from './pages/RootRouter';

/**
 * This is the entry point for the React App.
 *
 * @returns React.ReactNode
 */
function DsReactApp() {
    return (
        <ErrorBoundary
            error={err => (
                <ErrorMessage>
                    <Translate keyId="ERRORS.UNEXPECTED_ERROR" />
                </ErrorMessage>
            )}
        >
            <ClientPicker.CurrentClientStorageContextProvider>
                <RootRouter />
            </ClientPicker.CurrentClientStorageContextProvider>
        </ErrorBoundary>
    );
}
DsReactApp.displayName = 'DsReactApp';

export default withAngularIntegration(DsReactApp, 'dsReactApp', {});
