/// <reference path="../../../../typings/browser.d.ts" />
import { PrintProviderShoppingCartOrderId } from '@deltasierra/shared';
import { IRootScopeService } from 'angular';
import { InteractionUtils } from '../../common/interactionUtils';
import { ShoppingCartApiClient } from '../shoppingCartApiClient';
import { DataUtils } from '../../common/dataUtils';
import { shoppingCartItemChangeEvent } from '../shoppingCartBadger/controller';
import { $rootScopeSID } from '../../common/angularData';
import { StripeApiClient } from '../../integration/stripe/stripeApiClient';
import { I18nService } from '../../i18n';

export class CheckoutController {
    public static SID = 'CheckoutController';

    readonly fetchOrders = this.interactionUtils.createFuture(this.i18n.text.shoppingCart.fetchOrders(), async () => {
        const orderIds = await this.shoppingCartApiClient.getShoppingCartOrders('pending');
        this.orderIds = orderIds;
        this.broadcastShoppingCartItemChangeEvent();
    });

    readonly fetchStripePublishableKey = this.interactionUtils.createFuture(this.i18n.text.payments.stripe.fetchStripePublishableKey(), async () => {
        const response = await this.stripeApiClient.getStripePublishableKey();
        this.stripePublishableKey = response.stripePublishableKey;
        return this.fetchOrders.run({});
    });

    orderIds: PrintProviderShoppingCartOrderId[] | null = null;

    stripePublishableKey: string | null = null;

    static readonly $inject: string[] = [
        $rootScopeSID,
        InteractionUtils.SID,
        ShoppingCartApiClient.SID,
        StripeApiClient.SID,
        DataUtils.SID,
        I18nService.SID,
    ];

    constructor(
        private readonly $rootScope: IRootScopeService,
        private readonly interactionUtils: InteractionUtils,
        private readonly shoppingCartApiClient: ShoppingCartApiClient,
        private readonly stripeApiClient: StripeApiClient,
        private readonly dataUtils: DataUtils,
        private readonly i18n: I18nService,
    ) {
        void this.fetchStripePublishableKey.run({});
    }

    public onLastItemDeleted(orderId: PrintProviderShoppingCartOrderId): void {
        if (this.orderIds) {
            const updatedIds = this.dataUtils.filterByPredicate(id => id != orderId, this.orderIds);
            this.orderIds = updatedIds;
        }
    }

    public broadcastShoppingCartItemChangeEvent(): void {
        shoppingCartItemChangeEvent.broadcast(this.$rootScope, {});
    }
}

angular.module('app').controller(CheckoutController.SID, CheckoutController);
