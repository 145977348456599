import { AssignedLocation, ExportPlatforms, InstagramStoryDirectPublishFormData, LocationIdHierarchy } from '@deltasierra/shared';
import * as React from 'react';
import { OneWayBinding } from '../../../common/angularData';
import { withAngularIntegration } from '../../../common/componentUtils/reactComponentRegistration';
import { MultipleLocationMultiImagePublish } from '../multipleLocationPublish/MultipleLocationMultiImagePublish';
import { MvContentBuilderPublishCtrl } from '../mvContentBuilderPublishCtrl';
import { PublishResult } from '../publishResult';
import { PublishFinishCallback } from '../types';
import { useInstagramStoryImagePublishCallback } from './useInstagramStoryImagePublishCallback';
import { PublishToInstagramStoryDirectForm } from './PublishToInstagramStoryDirectForm';

export type PublishImageToInstagramStoryDirectProps = {
    location: AssignedLocation;
    locations: LocationIdHierarchy[];
    publishController: MvContentBuilderPublishCtrl;
    onCancel: () => void;
    onFinish: (data: PublishResult) => void;
};

const PublishImageToInstagramStoryDirect: React.FC<PublishImageToInstagramStoryDirectProps> = props => {
    const [isPublishing, setIsPublishing] = React.useState(false);
    const [publishData, setPublishData] = React.useState<InstagramStoryDirectPublishFormData | null>(null);

    const publishMultiImageCallback = useInstagramStoryImagePublishCallback(props.publishController);

    const onPublish = (data: InstagramStoryDirectPublishFormData) => {
        setIsPublishing(true);
        setPublishData(data);
    };

    const finishCallback: PublishFinishCallback = () => {
        const scheduledTime = (publishData && publishData.scheduledTime) || null;
        props.onFinish(new PublishResult(ExportPlatforms.InstagramStoryDirect, scheduledTime));
    };

    return (
        <>
            {!isPublishing && <PublishToInstagramStoryDirectForm {...props} onPublish={onPublish} />}
            {isPublishing && publishData && (
                <MultipleLocationMultiImagePublish
                    commonData={publishData}
                    contentBuilder={props.publishController.contentBuilder}
                    locations={props.locations}
                    originalLocation={props.location}
                    publishCtrl={props.publishController}
                    publishMultiImageCallback={publishMultiImageCallback}
                    onCancel={props.onCancel}
                    onFinish={finishCallback}
                />
            )}
        </>
    );
};
PublishImageToInstagramStoryDirect.displayName = 'PublishImageToInstagramStoryDirect';

export default withAngularIntegration(PublishImageToInstagramStoryDirect, 'publishImageToInstagramStoryDirect', {
    location: OneWayBinding,
    locations: OneWayBinding,
    onCancel: OneWayBinding,
    onFinish: OneWayBinding,
    publishController: OneWayBinding,
});
