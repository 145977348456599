import { $locationSID, $windowSID } from '../common/angularData';
import { SentryService } from '../common/sentryService';
import { MvNotifier } from '../common/mvNotifier';
import { I18nService } from '../i18n';
import { HotjarService } from '../common/hotjarService';
import { MvAuth } from './mvAuth';
import { MvIdentity } from './mvIdentity';

export class LoginFlowService {
    public static readonly SID = 'LoginFlowService';

    public static readonly $inject: string[] = [
        $locationSID,
        SentryService.SID,
        MvIdentity.SID,
        MvNotifier.SID,
        MvAuth.SID,
        I18nService.SID,
        HotjarService.SID,
        $windowSID,
    ];

    private readonly ATTEMPT_LOGGING_THRESHOLD = 5;

    private loginAttempts = 0;

    // eslint-disable-next-line max-params
    public constructor(
        private readonly $location: ng.ILocationService,
        private readonly sentryService: SentryService,
        private readonly identity: MvIdentity,
        private readonly notifier: MvNotifier,
        private readonly auth: MvAuth,
        private readonly i18nService: I18nService,
        private readonly hotjarService: HotjarService,
        private readonly $window: { navigator: Navigator },
    ) {}

    public async login(username: string, password: string, token?: string): Promise<void> {
        const success = await this.auth.authenticateUser(username, password, token);
        if (success) {
            const { firstName, lastName } = this.identity.currentUser!;
            const message = this.i18nService.text.login.signedInAs({
                name: `${firstName} ${lastName}`,
            });
            this.notifier.notify(message);
            this.sentryService.setUserContext();
            this.hotjarService.setUserContext();
            if (!this.isSupportedBrowser()) {
                this.$location.path('/unsupportedBrowser').search(this.$location.search());
            } else {
                this.redirect();
            }
        } else {
            this.loginAttempts++;
            this.notifier.unexpectedError(this.i18nService.text.login.usernamePasswordIncorrectError(), {
                bypassLogging: this.loginAttempts < this.ATTEMPT_LOGGING_THRESHOLD,
            });
        }
    }

    public redirect(): void {
        const loginParams = this.$location.search();
        const redirect = loginParams.r;
        if (redirect) {
            this.$location.url(loginParams.r);
        } else {
            this.$location.path('/').search({});
        }
    }

    private isSupportedBrowser(): boolean {
        const { userAgent } = this.$window.navigator;
        return userAgent.indexOf('Chrome') > -1 && userAgent.indexOf('Edge') === -1;
    }
}

angular.module('app').service(LoginFlowService.SID, LoginFlowService);
