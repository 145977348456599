/// <reference path="../../../typings/browser.d.ts" />
import { LocationMapSale } from '@deltasierra/shared';
import { $httpSID } from '../common/angularData';

export class AdminService {
    static SID = 'adminService';

    static readonly $inject : string[] = [$httpSID];

    constructor(private $http : ng.IHttpService) {
    }

    generateThumbnails() {
        return this.$http.post('/api/admin/generateThumbnails', undefined);
    }

    purgeSessions() {
        return this.$http.post('/api/admin/purgeSessions', undefined);
    }

    testInAppNotifications() {
        return this.$http.post('/api/admin/testInAppNotifications', undefined);
    }

    generateLocationMap(locationId: number) {
        return this.$http.post(`/api/admin/generateLocationMap/${locationId}`, undefined);
    }

    getMapsReport(): ng.IPromise<LocationMapSale[]> {
        return this.$http.get<LocationMapSale[]>('/api/admin/maps/report', undefined).then(x => x.data!);
    }
}

angular.module('app').service(AdminService.SID, AdminService);
