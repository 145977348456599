import { ApolloError } from '@apollo/client';
import * as Sentry from '@sentry/browser';

/**
 * This swallows any uncaught errors if you are manually handling the graphql errors type
 * in a query or mutation (as you should probably be doing all the time)
 *
 * @see https://github.com/apollographql/react-apollo/issues/2614#issuecomment-530803601
 * @param error - The error passed in by Apollo
 */
export function swallowHandledErrors(error: ApolloError): void {
    Sentry.withScope(scope => {
        scope.setLevel(Sentry.Severity.Warning);
        scope.setTag('swallowed', 'true');
        Sentry.captureException(error);
    });
}
