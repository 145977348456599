import { gql } from '@apollo/client';

export const GET_UPLOAD_FOR_UPLOAD_THUMBNAIL_POLL = gql`
    query GetUploadForUploadThumbnailPoll($id: ID!) {
        upload(id: $id) {
            ... on Upload {
                id
                filename
                thumb256x256url
                url
                contentType
            }
            ... on UploadNotFoundError {
                code
                message
            }
        }
    }
`;
