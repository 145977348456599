import { IQService } from 'angular';
import { PublishImmediatelyCheckService } from '../common/publishImmediatelyCheck/service';
import { DataUtils } from '../../../common/dataUtils';
import { LinkedInPublishService } from '../../../integration/publish/linkedInPublishService';
import ImagePublishService, { ImagePublishServiceCompletionEvent } from '../ImagePublishService';
import { builderConstantsSID, BuilderConstants } from '../../builderConstants';
import { ImageLoaderService } from '../../imageLoaderService';
import { MvLocation } from '../../../locations/mvLocation';
import { I18nService } from '../../../i18n/i18nService';
import { $scopeSID, $qSID } from '../../../common/angularData';
import { MultiImagePublishCallback, MultiImagePublishCallbackArgs } from '../types';
import { BaseLinkedInPublishCtrl, PublishToLinkedInCtrlScope, CommonPublishData } from './baseLinkedInPublishCtrl';

export class MvContentBuilderPublishToLinkedInCtrl extends BaseLinkedInPublishCtrl {
    public static SID = 'mvContentBuilderPublishToLinkedInCtrl';

    public static readonly $inject: string[] = [
        $scopeSID,
        $qSID,
        PublishImmediatelyCheckService.SID,
        DataUtils.SID,
        LinkedInPublishService.SID,
        ImagePublishService.SID,
        builderConstantsSID,
        ImageLoaderService.SID,
        MvLocation.SID,
        I18nService.SID,
    ];

    public ImageProcessingContext = {
        isProcessingOnServer: false,
    };

    // eslint-disable-next-line max-params
    public constructor(
        $scope: PublishToLinkedInCtrlScope,
        $q: IQService,
        publishImmediatelyCheckService: PublishImmediatelyCheckService,
        dataUtils: DataUtils,
        linkedInPublishService: LinkedInPublishService,
        private readonly imagePublishService: ImagePublishService,
        builderConstants: BuilderConstants,
        imageLoaderService: ImageLoaderService,
        mvLocation: MvLocation,
        private readonly i18nService: I18nService,
    ) {
        super(
            $scope,
            $q,
            publishImmediatelyCheckService,
            dataUtils,
            linkedInPublishService,
            builderConstants,
            imageLoaderService,
            mvLocation,
            i18nService,
            'photo',
        );
    }

    public getDoPublish(): MultiImagePublishCallback<CommonPublishData> {
        return this.doPublish.bind(this);
    }

    protected async doPublish(args: MultiImagePublishCallbackArgs<CommonPublishData>): Promise<void> {
        const { commonData, locationGraphqlId, locationId, publishedArtifactGroupId, uploadIds } = args;
        const channelData = {
            comment: commonData.caption || '',
            scheduledTime: this.isToBeScheduled ? commonData.scheduledTime || undefined : undefined,
        };
        const scheduledTime = this.isToBeScheduled ? commonData.scheduledTime || undefined : undefined;
        const canPostToLinkedIn = await this.linkedInPublishService.canPostToLinkedInPageForLocation(locationId);
        if (!canPostToLinkedIn) {
            throw new Error(this.i18nService.text.build.publish.linkedin.accessCheckFailure());
        } else {
            const eventToWaitFor = this.isToBeScheduled
                ? ImagePublishServiceCompletionEvent.PlannerCreated
                : ImagePublishServiceCompletionEvent.Published;
            return this.imagePublishService.publishMultiImage(
                this.ImageProcessingContext,
                locationGraphqlId,
                uploadIds,
                commonData.templateId,
                this.plannerDetails || null,
                channelData,
                async input => this.linkedInPublishService.publishPhoto(input),
                this.contentBuilder.linkedAssetLibraryAsset.map(value => ({
                    layerId: value.layerId,
                    legacyAssetId: value.asset.id,
                })),
                publishedArtifactGroupId,
                scheduledTime,
                eventToWaitFor,
            );
        }
    }
}

angular.module('app').controller(MvContentBuilderPublishToLinkedInCtrl.SID, MvContentBuilderPublishToLinkedInCtrl);
