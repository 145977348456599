import { InstagramStatsDto } from '@deltasierra/shared';
import { ReportTable, StatsEntry, TextAlignClass } from '../reportTable/reportTable';
import { compareNumbers } from '../compare';
import { CreateReportTableOptions } from './common';

export function createInstagramReportTable(options: CreateReportTableOptions) {
    return new ReportTable<StatsEntry<InstagramStatsDto>, InstagramStatsDto>({
        columns: [
            {
                cssClass: TextAlignClass.Left,
                format: options.formatters.formatString,
                getTotal: () => options.i18n.text.report.total(),
                getValue: entry => entry.location.locationTitle,
                label: () => options.i18n.text.report.location(),
            },
            {
                compare: compareNumbers,
                cssClass: TextAlignClass.Right,
                format: options.formatters.formatNumber,
                getTotal: totals => totals.followers.count - totals.followers.new,
                getValue: entry => entry.followers.count - entry.followers.new,
                label: () => options.i18n.text.report.followersFrom(),
            },
            {
                compare: compareNumbers,
                cssClass: TextAlignClass.Right,
                format: options.formatters.formatNumber,
                getTotal: totals => totals.followers.count,
                getValue: entry => entry.followers.count,
                label: () => options.i18n.text.report.followersTo(),
            },
            {
                compare: compareNumbers,
                cssClass: TextAlignClass.Right,
                format: options.formatters.formatNumber,
                getTotal: totals => totals.followers.new,
                getValue: entry => entry.followers.new,
                label: () => options.i18n.text.report.newFollowers(),
            },
            {
                compare: compareNumbers,
                cssClass: TextAlignClass.Right,
                format: options.formatters.formatPercentage,
                getTotal: totals => totals.followers.growth,
                getValue: entry => entry.followers.growth,
                label: () => options.i18n.text.report.followersGrowth(),
            },
            {
                compare: compareNumbers,
                cssClass: TextAlignClass.Right,
                format: options.formatters.formatNumber,
                getTotal: totals => totals.media.new,
                getValue: entry => entry.media.new,
                label: () => options.i18n.text.report.newPosts(),
            },
            {
                compare: compareNumbers,
                cssClass: TextAlignClass.Right,
                format: options.formatters.formatNumber,
                getTotal: totals => totals.media.count,
                getValue: entry => entry.media.count,
                label: () => options.i18n.text.report.totalPosts(),
            },
        ],
    });
}
