/// <reference path="../../../../../typings/browser.d.ts" />
import { Client } from '@deltasierra/shared';
import { FitwareStatsService } from '../../stats/fitwareStatsService';
import { MvClient } from '../../../clients/mvClient';
import { DataUtils } from '../../../common/dataUtils';
import { InteractionUtils } from '../../../common/interactionUtils';
import { MvNotifier } from '../../../common/mvNotifier';
import { $qSID } from '../../../common/angularData';
import { I18nService } from '../../../i18n';
import IQService = angular.IQService;

interface FitwareBusiness {
    BusinessName: string;
    BusinessCode: string;
}

interface FitwareClientConfigProperties {
    model: Client | null;
}

interface FitwareClientConfigScope extends FitwareClientConfigProperties, ng.IScope {
    ctrl: MvFitwareClientConfigCtrl;
}

export class MvFitwareClientConfigCtrl implements FitwareClientConfigProperties {
    public static readonly SID = 'mvFitwareClientConfigCtrl';

    public static readonly $inject: string[] = [
        $qSID,
        InteractionUtils.SID,
        MvNotifier.SID,
        FitwareStatsService.SID,
        MvClient.SID,
        DataUtils.SID,
        I18nService.SID,
    ];

    public model: Client | null = null;

    public loading = {
        canConfigure: false,
        fitwareBusinesses: false,
        updateFitwareBusiness: false,
    };

    public canConfigure = false;

    // Reference data
    public fitwareBusinesses: FitwareBusiness[] | null = null;

    // Form data
    public selectedFitwareBusiness: FitwareBusiness | null = null;

    // eslint-disable-next-line max-params
    public constructor(
        private readonly $q: IQService,
        private readonly interactionUtils: InteractionUtils,
        private readonly mvNotifier: MvNotifier,
        private readonly fitwareStatsService: FitwareStatsService,
        private readonly mvClient: MvClient,
        private readonly dataUtils: DataUtils,
        private readonly i18n: I18nService,
    ) {}

    public updateClient(): void {
        this.init();
    }

    public init(): ng.IPromise<void> | void {
        if (this.model) {
            return this.interactionUtils
                .handleRemoteSimple(
                    this,
                    'check Fitware Config access',
                    'canConfigure', // TODO: translate this
                    this.checkCanConfigure(),
                )
                .then((canConfigure: boolean) => {
                    this.canConfigure = !!canConfigure;
                    if (this.canConfigure) {
                        return this.interactionUtils.handleRemote(
                            this,
                            'retrieve Fitware businesses',
                            'fitwareBusinesses', // TODO: translate this
                            this.fitwareStatsService.getBusinesses(),
                        );
                    } else {
                        return [];
                    }
                })
                .then((businesses: FitwareBusiness[]) => {
                    this.fitwareBusinesses = businesses;
                    this.selectBusiness();
                });
        }
    }

    // eslint-disable-next-line consistent-return
    public async updateFitwareBusiness(): Promise<void> {
        const businessCode = this.selectedFitwareBusiness ? this.selectedFitwareBusiness.BusinessCode : null;
        const businessName = this.selectedFitwareBusiness ? this.selectedFitwareBusiness.BusinessName : null;
        if (this.model) {
            const model = this.model;
            return this.interactionUtils
                .handleRemote(
                    this,
                    'update Fitware customer',
                    'updateFitwareCustomer', // TODO: translate this
                    this.mvClient.updateFitwareBusiness(model.id, businessCode, businessName),
                )
                .then(() => {
                    if (businessCode) {
                        this.mvNotifier.notify(this.i18n.text.integration.fitware.notifyBusinessSet());
                    } else {
                        this.clearSelectedBusiness();
                        this.mvNotifier.notify(this.i18n.text.integration.fitware.notifyBusinessCleared());
                    }
                    model.fitwareBusinessCode = businessCode;
                    model.fitwareBusinessName = businessName;
                });
        }
    }

    private selectBusiness() {
        if (this.model && this.model.fitwareBusinessCode && this.fitwareBusinesses) {
            this.selectedFitwareBusiness = this.dataUtils.findByGetter(
                (business: FitwareBusiness) => business.BusinessCode,
                this.fitwareBusinesses,
                this.model.fitwareBusinessCode,
            );
        } else {
            this.clearSelectedBusiness();
        }
    }

    private clearSelectedBusiness() {
        this.selectedFitwareBusiness = null;
    }

    private checkCanConfigure(): ng.IPromise<boolean> {
        if (this.model) {
            return this.fitwareStatsService.canConfigureClient(this.model.id);
        } else {
            return this.$q.resolve(false);
        }
    }
}
angular.module('app').controller(MvFitwareClientConfigCtrl.SID, MvFitwareClientConfigCtrl);

export const dsFitwareClientConfigConfig: ng.IDirective<FitwareClientConfigScope> = {
    bindToController: true, // TODO: investigate why this isn't working for model
    controller: MvFitwareClientConfigCtrl,
    controllerAs: 'ctrl',
    link: function (scope: FitwareClientConfigScope, element, attrs, ngModelCtrl: ng.INgModelController) {
        ngModelCtrl.$render = function () {
            scope.model = ngModelCtrl.$modelValue;
            scope.ctrl.model = scope.model; // TODO: proper binding
            scope.ctrl.updateClient();
        };
    } as ng.IDirectiveLinkFn<FitwareClientConfigScope>,
    require: 'ngModel',
    restrict: 'E',
    scope: {},
    templateUrl: '/partials/integration/auth/fitware/fitwareClientConfig',
};

export const dsFitwareClientConfigSID = 'dsFitwareClientConfig';

angular.module('app').directive(dsFitwareClientConfigSID, [() => dsFitwareClientConfigConfig]);
