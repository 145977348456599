import {
    DSTable,
    DSTableBody,
    DSTableCell,
    DSTableContainer,
    DSTableHead,
    DSTableRow,
    Translate,
} from '@deltasierra/components';
import { t } from '@deltasierra/shared';
import * as React from 'react';
import { HashtagSetsTableRow } from './HashtagSetsTableRow';

export interface HashtagSetsTableProps {
    children?: React.ReactNode;
}

export function HashtagSetsTable(props: HashtagSetsTableProps): JSX.Element {
    return (
        <DSTableContainer data-cy="hashtag-table">
            <DSTable>
                <DSTableHead>
                    <DSTableRow>
                        <DSTableCell>
                            <Translate keyId="AGENCY.HASHTAG_SETS.TABLE_HEADERS.TITLE" />
                        </DSTableCell>
                        <DSTableCell>
                            <Translate keyId="AGENCY.HASHTAG_SETS.TABLE_HEADERS.DESCRIPTION" />
                        </DSTableCell>
                        <DSTableCell align="right">
                            <Translate keyId="AGENCY.HASHTAG_SETS.TABLE_HEADERS.NUMBER_HASHTAGS" />
                        </DSTableCell>
                        <DSTableCell align="right" aria-label={t('COMMON.ACTIONS')}></DSTableCell>
                    </DSTableRow>
                </DSTableHead>
                <DSTableBody>{props.children}</DSTableBody>
            </DSTable>
        </DSTableContainer>
    );
}
HashtagSetsTable.displayName = 'HashtagSetsTable';
HashtagSetsTable.Row = HashtagSetsTableRow;
