/* eslint-disable jsdoc/require-returns */
/// <reference path="../_references.d.ts" />

export class FormService {
    static SID = 'FormService';

    static readonly $inject: string[] = [];

    constructor() {
        // Do nothing
    }

    /**
     * Sets the form to dirty and returns whether it is valid
     * (based on your angular validation for the form)
     *
     * @param form
     */
    validateForm(form : ng.IFormController) : boolean {
        // If only my gf had method like this
        form.$setDirty();

        for (const key of Object.getOwnPropertyNames(form)) {
            if (form[key] && form[key].$setDirty) {
                form[key].$setDirty();
            }
        }

        return form.$valid;
    }
}

angular.module('app').service(FormService.SID, FormService);
