import { gql } from '@apollo/client';

export const GET_EMAIL_BUILDER_CONFIG = gql`
    query GetEmailBuilderConfig {
        config {
            id
            features {
                builder {
                    builderTemplateDrafts
                }
            }
        }
    }
`;
