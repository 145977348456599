import { FinalizeThumbnailRequest, FinalizeThumbnailResponse,
    InitialUpload,
    InitialUploadResponse,
    UploadApi,
    UploadId,
    UploadThumbnail,
 justBody, justParams } from '@deltasierra/shared';

import { $httpSID } from '../common/angularData';
import { invokeApiRoute } from '../common/httpUtils';
import IPromise = angular.IPromise;

export class UploadApiClient {
    static SID = 'UploadApiClient';

    static readonly $inject : string[] = [
        $httpSID,
    ];

    constructor(protected readonly $http : ng.IHttpService) {

    }

    finalizeThumbnail(data: FinalizeThumbnailRequest) : IPromise<FinalizeThumbnailResponse> {
        return invokeApiRoute(
            this.$http,
            UploadApi.finalizeThumbnail,
            justBody(data),
        );
    }

    initUpload(newUploadData : InitialUpload) : IPromise<InitialUploadResponse> {
        return invokeApiRoute(
            this.$http,
            UploadApi.initUpload,
            justBody(newUploadData),
        );
    }

    uploadSuccess(uploadId: UploadId) {
        return invokeApiRoute(
            this.$http,
            UploadApi.uploadSuccess,
            justParams({
                id: uploadId,
            }),
        );
    }

    uploadFailure(uploadId: UploadId) {
        return invokeApiRoute(
            this.$http,
            UploadApi.uploadFailure,
            justParams({
                id: uploadId,
            }),
        );
    }

    getUploadThumbnail(uploadId: UploadId, key: string): IPromise<UploadThumbnail> {
        return invokeApiRoute(
            this.$http,
            UploadApi.getUploadThumbnail,
            {
                params: {
                    id: uploadId,
                },
                body: undefined,
                query: {
                    key,
                },
            },
        );
    }
}

angular.module('app').service(UploadApiClient.SID, UploadApiClient);
