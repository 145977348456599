/// <reference path="../../../../../typings/browser.d.ts" />
import { TwoWayBinding } from '../../../common/angularData';
import { ProfilePicturePickerController } from './controller';

export const dsProfilePicturePickerSID = 'dsProfilePicturePicker';
export const dsProfilePicturePickerConfig = {
    bindToController: true,
    controller: ProfilePicturePickerController,
    controllerAs: 'ctrl',
    replace: true,
    restrict: 'E',
    scope: {
        user: TwoWayBinding,
    },
    templateUrl: '/partials/account/profile/profilePicturePicker/template',
};

angular.module('app').directive(dsProfilePicturePickerSID, [() => dsProfilePicturePickerConfig]);
