import { ChannelName } from '@deltasierra/shared';
import React from 'react';
import { ChannelDataByName } from '../../channelDataService';
import { MvContentBuilderPublishCtrl } from '../mvContentBuilderPublishCtrl';

export function useChannelDatum<TChannelName extends ChannelName>(
    publishController: MvContentBuilderPublishCtrl,
    channelName: TChannelName,
    propertyGetter: (channelData: ChannelDataByName<TChannelName>) => string | null,
): string | null {
    const getterRef = React.useRef(propertyGetter);
    React.useEffect(() => {
        getterRef.current = propertyGetter;
    }, [propertyGetter]);
    const channelData = React.useMemo(
        () => publishController.contentBuilder.getChannelDatum(channelName, getterRef.current),
        [channelName, publishController.contentBuilder],
    );
    return channelData;
}
