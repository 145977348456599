import { gql } from '@apollo/client';

export const GET_CLUB_READY_LIST_SUMMARY_REQUEST_QUERY = gql`
    query GetClubReadyListSummaryRequestQuery($requestId: ID!) {
        ClubReadyListSummaryRequest(id: $requestId) {
            ... on ClubReadyListSummaryRequest {
                id
                createdAt
                status
                results {
                    edges {
                        node {
                            id
                            location {
                                id
                            }
                            summary {
                                ... on ClubReadyLists {
                                    active {
                                        userCount
                                        suppressedFilteredUserCount
                                        suppressedOptedOutUserCount
                                        suppressedDuplicateUserCount
                                        suppressedInvalidEmailUserCount
                                    }
                                    inactive {
                                        userCount
                                        suppressedFilteredUserCount
                                        suppressedOptedOutUserCount
                                        suppressedDuplicateUserCount
                                        suppressedInvalidEmailUserCount
                                    }
                                    prospects {
                                        userCount
                                        suppressedFilteredUserCount
                                        suppressedOptedOutUserCount
                                        suppressedDuplicateUserCount
                                        suppressedInvalidEmailUserCount
                                        suppressedNotInterestedUserCount
                                    }
                                    cooledOffProspects {
                                        userCount
                                        suppressedOptedOutUserCount
                                        suppressedDuplicateUserCount
                                        suppressedInvalidEmailUserCount
                                    }
                                    custom {
                                        userCount
                                        suppressedOptedOutUserCount
                                        suppressedDuplicateUserCount
                                        suppressedInvalidEmailUserCount
                                        suppressedNotInterestedUserCount
                                    }   
                                }
                                ... on ClubReadyIntegrationListsExceedsMaxLimitError {
                                    code
                                    message
                                }
                            }
                        }
                    }
                }
            }
            ... on Error {
                message
            }
        }
    }
`;
