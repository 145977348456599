import { DSTypography, DSWell, Translate } from '@deltasierra/components';
import {
    FacebookPublishData,
    MAX_FACEBOOK_CAPTION_CHARACTER_LENGTH,
    MAX_FACEBOOK_COMMENT_CHARACTER_LENGTH,
} from '@deltasierra/shared';
import { Formik } from 'formik';
import * as React from 'react';
import { FormButtons } from '../common/FormButton';
import { useTermsAndConditions } from '../common/hooks';
import { TermsAndConditionsPublishMessage } from '../common/termsAndConditions/termsAndConditionsPublishMessage';
import { useChannelDatum } from '../common/useChannelDatum';
import { useRequiredHashtags } from '../hooks';
import { MvContentBuilderPublishCtrl } from '../mvContentBuilderPublishCtrl';
import { buildFacebookPublishData, getCaptionWithTermsAndConditions } from './build-facebook-publish-data';
import { FacebookFormValues } from './facebook-form-values';
import { FacebookFormCaptionSection } from './FacebookFormCaptionSection';
import { FacebookFormDescriptionSection } from './FacebookFormDescriptionSection';
import { FacebookFormHashtagsSection } from './FacebookFormHashtagsSection';
import { FacebookFormScheduleSection } from './FacebookFormScheduleSection';
import { useValidatedFacebookForm } from './useValidatedFacebookForm';

export type FacebookPublishType = 'image' | 'video';

export type FacebookPublishFormProps = {
    locationId: string;
    locationIds?: string[];
    onCancel: () => void;
    onPublish: (data: FacebookPublishData) => void;
    publishController: MvContentBuilderPublishCtrl;
    publishType: FacebookPublishType;
};

export function FacebookPublishForm({
    locationId,
    locationIds: _locationIds,
    onCancel,
    onPublish,
    publishController,
    publishType,
}: FacebookPublishFormProps): JSX.Element {
    const locationIds = _locationIds ? _locationIds : [locationId];
    const [submitted, setSubmitted] = React.useState(false);

    const suggestedCaption = useChannelDatum(publishController, 'facebook', facebookData => facebookData.caption);
    const { loading: requiredHashtagsLoading, requiredHashtags } = useRequiredHashtags(locationId);
    const [termsAndConditionsText, termsAndConditionsUrl] = useTermsAndConditions(publishController);

    const onSubmit = React.useCallback(
        (formValues: FacebookFormValues) => {
            if (submitted || requiredHashtagsLoading) {
                return;
            }
            setSubmitted(true);
            const publishData = buildFacebookPublishData(formValues, {
                requiredHashtags,
                termsAndConditionsText,
                termsAndConditionsUrl,
            });

            onPublish(publishData);
        },
        [
            submitted,
            requiredHashtagsLoading,
            requiredHashtags,
            termsAndConditionsText,
            termsAndConditionsUrl,
            onPublish,
        ],
    );

    const formikProps = useValidatedFacebookForm({
        date: publishController?.plannerDetails?.date,
        requiredHashtags,
        termsAndConditionsText,
        termsAndConditionsUrl,
    });

    return (
        <Formik {...formikProps} validateOnBlur validateOnChange validateOnMount onSubmit={onSubmit}>
            {formik => (
                <>
                    <DSWell>
                        <DSTypography gutterBottom variant="h3">
                            <Translate keyId="BUILD.PUBLISH.FACEBOOK.PUBLISH_TO_FACEBOOK" />
                        </DSTypography>
                        <FacebookFormDescriptionSection locationIds={locationIds} />
                        <DSTypography gutterBottom variant="h4">
                            <Translate keyId="BUILD.PUBLISH.FACEBOOK.HEADERS.POST_DETAILS" />
                        </DSTypography>
                        <FacebookFormCaptionSection formik={formik} suggestedCaption={suggestedCaption} />
                        <TermsAndConditionsPublishMessage
                            text={termsAndConditionsText}
                            url={termsAndConditionsUrl}
                            useNewLabel
                        />
                        <FacebookFormHashtagsSection
                            availableCaptionLength={Math.max(
                                0,
                                MAX_FACEBOOK_CAPTION_CHARACTER_LENGTH -
                                    getCaptionWithTermsAndConditions({
                                        baseCaption: formik.values.caption,
                                        termsAndConditionsText,
                                        termsAndConditionsUrl,
                                    }).length,
                            )}
                            availableFirstCommentLength={MAX_FACEBOOK_COMMENT_CHARACTER_LENGTH}
                            formik={formik}
                            locationId={locationId}
                            locationIds={locationIds}
                            requiredHashtags={requiredHashtags}
                            requiredHashtagsLoading={requiredHashtagsLoading}
                        />
                        <DSTypography gutterBottom variant="h4">
                            <Translate keyId="BUILD.PUBLISH.FACEBOOK.HEADERS.POST_SCHEDULE" />
                        </DSTypography>
                        <FacebookFormScheduleSection
                            formik={formik}
                            locationId={locationId}
                            publishType={publishType}
                        />
                    </DSWell>
                    <FormButtons
                        disabled={!formik.isValid || formik.isSubmitting || requiredHashtagsLoading}
                        isScheduled={!!formik.values.scheduledTime}
                        onCancel={onCancel}
                        onSubmit={formik.handleSubmit}
                    />
                </>
            )}
        </Formik>
    );
}
FacebookPublishForm.displayName = 'FacebookPublishForm';
