import { useMutation } from '@apollo/client';
import {
    DSButton,
    DSDialogActions,
    DSDialogContent,
    DSDialogContentText,
    DSDialogTitle,
    DSTextField,
    DSTypography,
    Translate,
} from '@deltasierra/components';
import { t } from '@deltasierra/shared';
import { FormikErrors, useFormik } from 'formik';
import * as React from 'react';
import { useAngularServiceContext } from '../../../../../../common/componentUtils/angularServiceContexts';
import { ADMIN_SAVE_CLUB_READY_CLIENT_CONFIG_FOR_DIALOG } from './ClubReadyConfigureClientButton.mutation';
import { AdminSaveClubReadyClientConfigForDialog, AdminSaveClubReadyClientConfigForDialogVariables } from './__graphqlTypes/AdminSaveClubReadyClientConfigForDialog';

type ClubReadyClientDialogContentProps = {
    clientTitle: string;
    clubReadyConfId: string;
    initialChainId: string;
    onDone: () => void;
};

export const ClubReadyClientDialogContent: React.FC<ClubReadyClientDialogContentProps> = ({
    clientTitle,
    clubReadyConfId,
    initialChainId,
    onDone,
}) => {
    const notifier = useAngularServiceContext('mvNotifier');

    const [configure, { loading }] = useMutation<AdminSaveClubReadyClientConfigForDialog, AdminSaveClubReadyClientConfigForDialogVariables>(
        ADMIN_SAVE_CLUB_READY_CLIENT_CONFIG_FOR_DIALOG,
    );
    const handleSubmit = async ({ chainId }: { chainId: string }) => {
        const result = await configure({ variables: { id: clubReadyConfId, input: { chainId } } });
        if (
            result.data?.saveClubReadyClientConfiguration.__typename === 'IntegrationConnection' &&
            result.data.saveClubReadyClientConfiguration.configuration.__typename === 'ClubReadyClientConfiguration'
        ) {
            notifier.success(t('INTEGRATION.LOCATION.CLUB_READY.CONFIGURE_SUCCESS'));
        } else if (
            result.data?.saveClubReadyClientConfiguration.__typename === 'ClubReadyClientConfigurationInvalidError'
        ) {
            notifier.unexpectedError(t('ERRORS.BAD_REQUEST_ERRORS.INVALID_ID'));
        } else {
            notifier.unexpectedError(t('ERRORS.GENERIC'));
        }
        onDone();
    };
    const formik = useFormik<{ chainId: string }>({
        initialValues: { chainId: initialChainId },
        onSubmit: handleSubmit,
        validate: val => {
            const errors: FormikErrors<{ chainId: string }> = {};
            if (val.chainId.length === 0) {
                errors.chainId = 'Required';
            }
            return Object.keys(errors).length ? errors : undefined;
        },
    });
    return (
        <form onSubmit={formik.handleSubmit}>
            <DSDialogTitle>
                <Translate
                    keyId="INTEGRATION.CLIENT.CLUB_READY.ENTER_CHAIN_ID_WITH_CLIENT_TITLE"
                    options={{ clientTitle }}
                />
            </DSDialogTitle>
            <DSDialogContent>
                <DSDialogContentText>
                    <Translate keyId="INTEGRATION.CLIENT.CLUB_READY.ENTER_CHAIN_ID_EXPLAIN" />
                </DSDialogContentText>
                <DSTextField
                    autoFocus
                    fullWidth
                    helperText={t('INTEGRATION.CLIENT.CLUB_READY.MULTIPLE_CHAIN_EXPLAIN')}
                    label={t('INTEGRATION.CLIENT.CLUB_READY.CHAIN_ID')}
                    type="text"
                    variant="outlined"
                    {...formik.getFieldProps('chainId')}
                />
                {formik.errors.chainId && (
                    <DSTypography color="error">
                        <Translate keyId="COMMON.REQUIRED_FIELD_MESSAGE" />
                    </DSTypography>
                )}
            </DSDialogContent>
            <DSDialogActions>
                <DSButton color="default" disabled={loading} onClick={onDone}>
                    <Translate keyId="COMMON.CANCEL" />
                </DSButton>
                <DSButton color="primary" disabled={loading || !formik.isValid} type="submit">
                    <Translate keyId="COMMON.SAVE" />
                </DSButton>
            </DSDialogActions>
        </form>
    );
};

ClubReadyClientDialogContent.displayName = 'ClubReadyClientDialogContent';
