/// <reference path="../../../../typings/browser.d.ts" />
import { ExpressionBinding, OptionalOneWayBinding, simpleComponent } from '../../common/angularData';
import { AgencyOptionsPickerController } from './agencyOptionsPickerController';

export const AgencyOptionsPickerSID = 'dsBuilderTemplateGalleryAgencyOptionsPicker';

export const AgencyOptionsPickerConfig = simpleComponent(
    AgencyOptionsPickerController,
    'partials/templateGallery/agencyOptionsPicker/agencyOptionsPickerTemplate',
    {
        change: ExpressionBinding,
        preselectedAgencyOptions: OptionalOneWayBinding,
    },
);

angular.module('app').directive(AgencyOptionsPickerSID, [() => AgencyOptionsPickerConfig]);
