import { FitwareSimpleMemberList, LocationIdHierarchy } from '@deltasierra/shared';

import {
    $qSID,
    $timeoutSID,
    actualComponent,
    ExpressionBinding,
    ExpressionCallback,
    ILifecycleHooks,
    OneWayBinding,
    OptionalOneWayBinding,
} from '../../../../../common/angularData';
import { MultipleLocationActionProcessor } from '../../../../../locations/MultipleLocationActionProcessor';
import { I18nService } from '../../../../../i18n';
import { DataUtils } from '../../../../../common/dataUtils';
import { FitwarePublishService } from '../../../../../integration/publish/fitwarePublishService';

enum TemplateView {
    Loading,
    Failed,
    Loaded,
}

export class MemberListPickerCtrl implements ILifecycleHooks {
    public static readonly SID = 'MemberListPickerCtrl';

    // Inputs
    locations!: LocationIdHierarchy[];

    options!: FitwareSimpleMemberList[];

    onOptionsLoaded!: ExpressionCallback<{ memberLists: FitwareSimpleMemberList[] }>;

    onSelectionChange!: ExpressionCallback<{ memberLists: FitwareSimpleMemberList[] }>;

    preselectedValues: FitwareSimpleMemberList[] | undefined;

    // Other properties
    public readonly TemplateView = TemplateView;

    view = TemplateView.Loading;

    processor?: MultipleLocationActionProcessor;

    selectedMemberLists!: FitwareSimpleMemberList[];

    public static readonly $inject: string[] = [
        $qSID,
        $timeoutSID,
        I18nService.SID,
        DataUtils.SID,
        FitwarePublishService.SID,
    ];

    constructor(
        protected readonly $q: ng.IQService,
        protected readonly $timeout: ng.ITimeoutService,
        protected readonly i18n: I18nService,
        protected readonly dataUtils: DataUtils,
        protected readonly fitwarePublishService: FitwarePublishService,
    ) {}

    $onInit() {
        this.selectedMemberLists = [];
        if (this.options == undefined) {
            this.options = [];
        }

        if (this.options.length === 0) {
            return this.fetchAutomatedEmailMemberLists();
        } else {
            this.view = TemplateView.Loaded;
        }
    }

    protected fetchAutomatedEmailMemberLists() {
        this.view = TemplateView.Loading;
        let memberLists: FitwareSimpleMemberList[] = [];
        const processor = new MultipleLocationActionProcessor(this.$q, this.i18n, this.$timeout, this.locations, {
            action: location =>
                this.fitwarePublishService.getMemberLists(location.locationId).then(fetchedMemberLists => {
                    if (memberLists.length === 0) {
                        memberLists = fetchedMemberLists;
                    } else {
                        // Keep the intersection of member lists, common to all locations.
                        memberLists = this.dataUtils.filterByPredicate(
                            existingMemberList =>
                                fetchedMemberLists.some(memberList => memberList.code === existingMemberList.code),
                            memberLists,
                        );
                    }
                }),
            failure: () => {
                this.view = TemplateView.Failed;
            },
            partialSuccess: () => {
                this.view = TemplateView.Failed;
            },
            success: () => {
                this.options = memberLists;
                this.view = TemplateView.Loaded;
                this.onOptionsLoaded({
                    memberLists,
                });
            },
        });
        this.processor = processor;
        return processor.start();
    }

    onChange(memberLists: FitwareSimpleMemberList[]) {
        this.selectedMemberLists = memberLists;
        this.onSelectionChange({
            memberLists: this.selectedMemberLists,
        });
    }

    getMemberListLabel(entry: FitwareSimpleMemberList): string {
        return entry.name;
    }
}

export const memberListPickerSID = 'memberListPicker';

export const memberListPickerConfig = actualComponent(
    MemberListPickerCtrl,
    '/partials/contentBuilder/email/publish/fitware/memberListPicker/memberListPicker',
    {
        locations: OneWayBinding,
        onOptionsLoaded: ExpressionBinding,
        onSelectionChange: ExpressionBinding,
        options: OneWayBinding,
        preselectedValues: OptionalOneWayBinding,
    },
);

angular.module('app').component(memberListPickerSID, memberListPickerConfig);
