import { DSButton, DSTypography } from '@deltasierra/components';
import * as React from 'react';
import { gql } from '@apollo/client';
import { ScheduledPostRowLocationsButtonFragment } from './__graphqlTypes/ScheduledPostRowLocationsButtonFragment';

const fragments = {
    ScheduledPostRowLocationsButtonFragment: gql`
        fragment ScheduledPostRowLocationsButtonFragment on PostArtifact {
            id
            parent {
                id
                locationCount
            }
        }
    `,
} as const;

export type ScheduledPostRowLocationsButtonProps = {
    post: ScheduledPostRowLocationsButtonFragment;
    toggle: (toggle: 'toggle' | 'toggleOff' | 'toggleOn') => void;
};

export function ScheduledPostRowLocationsButton({ post, toggle }: ScheduledPostRowLocationsButtonProps): JSX.Element {
    return (
        <DSButton color="primary" variant="text" onClick={() => toggle('toggle')}>
            <DSTypography color="primary" variant="body1">
                {post.parent?.locationCount ?? 1}
            </DSTypography>
        </DSButton>
    );
}

ScheduledPostRowLocationsButton.fragments = fragments;
