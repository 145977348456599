import { Roles, User } from '@deltasierra/shared';
import * as React from 'react';
import { useAngularServiceContext } from './componentUtils/angularServiceContexts';
import { useRouter } from './routes';

/**
 * React hook to access the current user.
 *
 * @example
 * const SayHello = () => {
 *     const user = useAuthUser();
 *     return (
 *         <>
 *             { user && 'Hello ${user.firstName}!' }
 *             { !user && 'You are not logged in' }
 *         </>
 *     );
 * }
 * @returns The current user or undefined if the user isn't logged in
 */
export const useAuthUser = (): User | undefined => {
    const mvIdentity = useAngularServiceContext('mvIdentity');
    return mvIdentity.currentUser;
};

/**
 * A hook to easily apply role based authorization to a route. You can configure whether an unauthorized user should be
 * redirected to another URL.
 *
 * @example Redirect unauthorized users
 * const MyComponent = () => {
 *     const hasPermission = useRequireAuth([Roles.builder], '/');
 *     return hasPermission ? 'You\'re logged in and have the builder role' : 'Unauthorized. Redirecting...';
 * };
 * @example Don't redirect unauthorized users
 * const MyComponent = () => {
 *     const hasPermission = useRequireAuth([Roles.builder], false);
 *     return hasPermission ? 'You\'re logged in and have the builder role' : 'Unauthorized';
 * };
 * @param roles - The list of roles to check the current user has
 * @param redirectTo - The URL to redirect unauthorized user to. Setting this to false will stop redirects
 * @returns `true` if the user has is authorized and has the required, `false` otherwise.
 */
export const useRequireAuth = (roles: Roles[] = [], redirectTo: string | false = '/'): boolean => {
    const user = useAuthUser();
    const router = useRouter();
    const hasAccess = !!user && roles.every(role => user.roles.indexOf(role) >= 0);

    React.useEffect(() => {
        if (!hasAccess && redirectTo) {
            router.push(redirectTo);
        }
    }, [router, hasAccess, redirectTo]);

    return hasAccess;
};
