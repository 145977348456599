import { Future, FutureOptions } from './Future';
import IPromise = angular.IPromise;
import ITimeoutService = angular.ITimeoutService;

/**
 * Debounces a function, and provides methods to inspect the status of the debounce/promise. This is useful for exposing
 * in controllers.
 */
export class Debouncer<R, C> extends Future<R, C> {
    private timeoutPromise? : IPromise<any>;

    private running = false;

    constructor(
        private $timeout : ITimeoutService,
        $q: ng.IQService,
        description : string,
        action : (context : C) => IPromise<R>,
        private delay : number,
        options? : FutureOptions,
    ) {
        super($q, description, action, options);
    }

    run(context : C) : IPromise<R> {
        if (!this.running) {
            if (this.timeoutPromise) {
                this.$timeout.cancel(this.timeoutPromise);
            }
            const promise = this.$timeout(() => {
                this.running = true;
                return super.run(context);
            }, this.delay, true);
            void promise.finally(() => {
                this.running = false;
                if (this.timeoutPromise == promise) {
                    this.timeoutPromise = undefined;
                }
            });
            // Note that we store the timeout promise without the "finally()" chain. This is so we can invoke
            // $timeout.cancel(), which doesn't like any promises other than the exact promise returned by $timeout().
            this.timeoutPromise = promise;
            return this.timeoutPromise;
        } else {
            return this.timeoutPromise!;
        }
    }

    cancel() {
        if (this.timeoutPromise) {
            return this.$timeout.cancel(this.timeoutPromise);
        } else {
            return false;
        }
    }

    asFunction() {
        return (context : C) => this.run(context);
    }

    isWaiting() : boolean {
        return this.timeoutPromise != undefined && !this.isRunning();
    }

    isWaitingOrRunning() : boolean {
        return this.isWaiting() || this.isRunning();
    }
}
