/// <reference path="../../../_references.d.ts" />

import { PerformanceSummaryController } from './controller';

export function PerformanceSummaryDirective() : ng.IDirective<ng.IScope> {
    return {
        templateUrl: '/partials/main/dashboard/performanceSummaryDirective/template',
        restrict: 'E',
        scope: {
            location: '=',
        },
        controller: PerformanceSummaryController,
        controllerAs: 'ctrl',
        bindToController: true,
    };
}

export const PerformanceSummaryDirectiveSID = 'dsPerformanceSummary';
