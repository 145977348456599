/// <reference path="../../../typings/browser.d.ts" />
import type { IHttpPromiseCallbackArg, IRootScopeService, IQService } from 'angular';
import { MvNotifier } from '../common/mvNotifier';
import { $qSID, $rootScopeSID } from './angularData';

angular.module('app').factory('serviceMaintenanceInterceptor', [
    $qSID,
    $rootScopeSID,
    MvNotifier.SID,
    ($q: IQService, $rootScope: IRootScopeService, mvNotifier: MvNotifier) => ({
        responseError(response: IHttpPromiseCallbackArg<string>) {
            if (response.status === 503 && (/(maintenance)/i).exec(response.data!)) {
                mvNotifier.disable();
                window.location.reload();
            }
            return $q.reject(response);
        },
    }),
]);

// Generated dummy export
export default {};
