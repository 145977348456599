/// <reference path="../../../typings/browser.d.ts" />
import { MvIdentity } from '../account/mvIdentity';
import { $scopeSID } from '../common/angularData';

/**
 * Assigns identity to the current scope.
 * Can be used in conjunction with "ng-if" to provide behaviour based on the user's auth.
 *
 * @param mvIdentity
 * @returns An angular directive
 */
angular.module('app').directive('dsIdentity', [MvIdentity.SID, function (mvIdentity : MvIdentity) {
    interface DsIdentityScope extends ng.IScope {
        identity : MvIdentity;
    }

    return {
        restrict: 'EAM',
        priority: 700, // Must be higher than ng-if, which is 600
        controller: [
            $scopeSID,
            function ($scope : DsIdentityScope) {
                $scope.identity = mvIdentity;
            },
        ],
    };
}]);
