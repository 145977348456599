/// <reference path="../../../typings/browser.d.ts" />

import {
    BuilderAnimationFrame,
    BuilderDocument,
    BuilderEmailDocument,
    ButtonSection,
    ImageLayer,
    ImageSection,
    Layer,
    LayerType,
    LocationType,
    Section,
    SectionType,
    t,
    TextLayer,
    TextSection,
} from '@deltasierra/shared';

export interface DocumentValidationContext<T> {
    advancedMode: boolean;
    document: T;
}

export interface BuilderDocumentValidationContext extends DocumentValidationContext<BuilderDocument> {
    originalTextLayerText: Map<number, string>;
}

export interface LayerValidationContext extends BuilderDocumentValidationContext {
    layer: Layer;
}

export interface SectionValidationContext extends DocumentValidationContext<BuilderEmailDocument> {
    section: Section;
}

export interface FieldValidationContext {
    fieldPath: string;
}

export interface LayerFieldValidationContext extends FieldValidationContext, LayerValidationContext {}

export interface SectionFieldValidationContext extends FieldValidationContext, SectionValidationContext {}

/**
 * If you add extra severities, make sure you add them to the
 * getSeverityIndex function below too
 */
export enum FieldValidationResultSeverity {
    Info = 'Info',
    Warning = 'Warning',
    Error = 'Error',
}

export function getSeverityIndex(severity: FieldValidationResultSeverity): number {
    switch (severity) {
        case FieldValidationResultSeverity.Info:
            return 0;

        case FieldValidationResultSeverity.Warning:
            return 1;

        case FieldValidationResultSeverity.Error:
            return 2;

        default:
            throw new Error(`Unexpected Severity: ${severity}`);
    }
}

export function getHighestSeverity(severities: FieldValidationResultSeverity[]): FieldValidationResultSeverity {
    let result: FieldValidationResultSeverity | null = null;
    let resultIndex = -1;

    for (const severity of severities) {
        const index = getSeverityIndex(severity);
        if (index > resultIndex) {
            result = severity;
            resultIndex = index;
        }
    }

    if (result) {
        return result;
    } else {
        return FieldValidationResultSeverity.Error;
    }
}

export interface FieldValidationResult {
    severity: FieldValidationResultSeverity;
    message: string;
}

export interface LayerFieldValidationResult extends FieldValidationResult, LayerFieldValidationContext {}

export interface SectionFieldValidationResult extends FieldValidationResult, SectionFieldValidationContext {}

export interface FieldValidator<T, TResult> {
    fieldPath: string;
    validate(context: T): TResult[];
}

export interface LayerFieldValidator extends FieldValidator<LayerFieldValidationContext, LayerFieldValidationResult> {
    layerType: LayerType;
}

export interface SectionFieldValidator
    extends FieldValidator<SectionFieldValidationContext, SectionFieldValidationResult> {
    sectionType: SectionType;
}

interface ValidatorsByFieldPath<T> {
    [fieldPath: string]: T[];
}

interface ValidatorsByType<T> {
    [Type: string]: ValidatorsByFieldPath<T>;
}

interface BuilderDocumentValidators<
    TFieldValidator,
    TFieldValidationContext,
    TFieldValidationResult,
    TLayerValidationContext,
    TDocument,
> {
    add(validator: TFieldValidator): this;

    validateField(context: TFieldValidationContext): TFieldValidationResult[];

    validateLayer(context: TLayerValidationContext): TFieldValidationResult[];

    validateDocument(context: DocumentValidationContext<TDocument>): FieldValidationResult[];
}

export class FieldValidators
    implements
        BuilderDocumentValidators<
            LayerFieldValidator,
            LayerFieldValidationContext,
            FieldValidationResult,
            LayerValidationContext,
            BuilderDocument
        > {
    // Validators is a hash table (indexed by layerType) of hash tables (indexed by fieldPath) of validators
    private layerValidators: ValidatorsByType<LayerFieldValidator> = {};

    public add(validator: LayerFieldValidator): this {
        this.getLayerValidator(validator.layerType, validator.fieldPath).push(validator);
        return this;
    }

    public validateField(context: LayerFieldValidationContext): FieldValidationResult[] {
        const validators = this.getLayerValidator(context.layer.type, context.fieldPath);
        const messages: FieldValidationResult[] = [];

        for (const validator of validators) {
            messages.push(...validator.validate(context));
        }

        return messages;
    }

    public validateLayer(context: LayerValidationContext): FieldValidationResult[] {
        const messages: FieldValidationResult[] = [];
        const typeValidators = this.layerValidators[context.layer.type] || {};
        this.layerValidators[context.layer.type] = typeValidators;
        for (const fieldPath of Object.getOwnPropertyNames(typeValidators)) {
            const validators = typeValidators[fieldPath];

            for (const validator of validators) {
                messages.push(
                    ...validator.validate({
                        ...context,
                        fieldPath,
                    }),
                );
            }
        }

        return messages;
    }

    public validateDocument(context: BuilderDocumentValidationContext): FieldValidationResult[] {
        const messages: FieldValidationResult[] = [];
        for (const layer of context.document.layers) {
            messages.push(
                ...this.validateLayer({
                    ...context,
                    layer,
                    originalTextLayerText: context.originalTextLayerText,
                }),
            );
        }
        return messages;
    }

    protected getLayerValidator(layerType: LayerType, fieldPath: string): LayerFieldValidator[] {
        const layerValidators = this.layerValidators[layerType] || {};
        this.layerValidators[layerType] = layerValidators;
        const fieldValidators = layerValidators[fieldPath] || [];
        layerValidators[fieldPath] = fieldValidators;
        return fieldValidators;
    }
}

export class EmailFieldValidators
    implements
        BuilderDocumentValidators<
            SectionFieldValidator,
            SectionFieldValidationContext,
            FieldValidationResult,
            SectionValidationContext,
            BuilderEmailDocument
        > {
    public validateLayer = this.validateSection.bind(this); // Just to conform to the interface

    // Validators is a hash table (indexed by layerType) of hash tables (indexed by fieldPath) of validators
    private sectionValidators: ValidatorsByType<SectionFieldValidator> = {};

    public add(validator: SectionFieldValidator): this {
        this.getSectionValidator(validator.sectionType, validator.fieldPath).push(validator);
        return this;
    }

    public validateField(context: SectionFieldValidationContext): FieldValidationResult[] {
        const validators = this.getSectionValidator(context.section.type, context.fieldPath);
        const messages: FieldValidationResult[] = [];

        for (const validator of validators) {
            messages.push(...validator.validate(context));
        }

        return messages;
    }

    public validateSection(context: SectionValidationContext): FieldValidationResult[] {
        const messages: FieldValidationResult[] = [];
        const typeValidators = this.sectionValidators[context.section.type] || {};
        this.sectionValidators[context.section.type] = typeValidators;

        for (const fieldPath of Object.getOwnPropertyNames(typeValidators)) {
            const validators = typeValidators[fieldPath];

            for (const validator of validators) {
                messages.push(
                    ...validator.validate({
                        ...context,
                        fieldPath,
                    }),
                );
            }
        }

        return messages;
    }

    public validateDocument(context: DocumentValidationContext<BuilderEmailDocument>): FieldValidationResult[] {
        const messages: FieldValidationResult[] = [];
        for (const section of context.document.sections) {
            messages.push(
                ...this.validateSection({
                    ...context,
                    section,
                }),
            );
        }
        return messages;
    }

    protected getSectionValidator(layerType: SectionType, fieldPath: string): SectionFieldValidator[] {
        const sectionValidators = this.sectionValidators[layerType] || {};
        this.sectionValidators[layerType] = sectionValidators;
        const fieldValidators = sectionValidators[fieldPath] || [];
        sectionValidators[fieldPath] = fieldValidators;
        return fieldValidators;
    }
}

type ValidationMessageOptions = {
    context: LayerFieldValidationContext;
    message: string;
    severity?: FieldValidationResultSeverity;
};

/**
 * Creates and returns an IFieldValidationResult based on your context, message and (optionally) severity
 *
 * @param opts - validation options
 * @returns - ILayerFieldValidationResult
 */
function validationMessage(opts: ValidationMessageOptions): LayerFieldValidationResult {
    return {
        advancedMode: opts.context.advancedMode,
        document: opts.context.document,
        fieldPath: opts.context.fieldPath,
        layer: opts.context.layer,
        message: opts.message,

        originalTextLayerText: opts.context.originalTextLayerText,

        severity: opts.severity || FieldValidationResultSeverity.Error,
    };
}

export class SelectImageValidator implements LayerFieldValidator {
    public layerType = LayerType.image;

    public fieldPath = 'location';

    public validate(context: LayerFieldValidationContext): LayerFieldValidationResult[] {
        const imageLayer = context.layer as ImageLayer;
        const result: LayerFieldValidationResult[] = [];
        const hasAnimation = !!context.document.animation;
        const isVisible = !hasAnimation
            ? imageLayer.visible
            : this.isLayerVisibleInAnimation(context.document.animation!.frames, imageLayer);
        if (
            !context.advancedMode &&
            imageLayer.forceUserToProvideImage &&
            imageLayer.locationType !== LocationType.local &&
            isVisible
        ) {
            result.push(
                validationMessage({
                    context,
                    message: t('BUILD.CUSTOM_IMAGE_REQUIRED'),
                }),
            );
        }
        return result;
    }

    public isLayerVisibleInAnimation(frames: BuilderAnimationFrame[], layer: Layer): boolean {
        return (
            layer.visible ||
            frames.some(frame =>
                frame.deltas.some(
                    delta => delta.layerId === layer.id && delta.path === ('visible' as keyof Layer) && delta.newValue,
                ),
            )
        );
    }
}
export class ForceUserToProvideImageValidator implements LayerFieldValidator {
    public layerType = LayerType.image;

    public fieldPath = 'forceUserToProvideImage';

    public validate(context: LayerFieldValidationContext): LayerFieldValidationResult[] {
        const imageLayer = context.layer as ImageLayer;
        const result: LayerFieldValidationResult[] = [];

        if (context.advancedMode && imageLayer.forceUserToProvideImage) {
            if (!imageLayer.showPlaceholderOverlay) {
                result.push(
                    validationMessage({
                        context,
                        message: 'Show Placeholder Overlay must also be selected to use this option',
                    }),
                );
            }

            if (!this.isLocationEditable(context)) {
                result.push(
                    validationMessage({
                        context,
                        message: t('BUILD.IMAGE_MUST_BE_EDITABLE'),
                    }),
                );
            }
        }

        return result;
    }

    private isLocationEditable(context: LayerFieldValidationContext) {
        return context.document.editableFields.some(
            field => field.layerId === context.layer.id && field.path === 'location',
        );
    }
}

export class TextFieldValidator implements LayerFieldValidator {
    public layerType = LayerType.text;

    public fieldPath = 'text';

    public validate(context: LayerFieldValidationContext): LayerFieldValidationResult[] {
        const result: LayerFieldValidationResult[] = [];
        const textLayer = context.layer as TextLayer;
        const originalText = context.originalTextLayerText.get(textLayer.id);
        if (!context.advancedMode && textLayer.forceUserToProvideText && originalText === textLayer.text) {
            result.push(
                validationMessage({
                    context,
                    message: t('BUILD.CUSTOM_TEXT_REQUIRED'),
                }),
            );
        }
        return result;
    }
}

export class ForceUserToProvideTextValidator implements LayerFieldValidator {
    public layerType = LayerType.text;

    public fieldPath = 'forceUserToProvideText';

    public validate(context: LayerFieldValidationContext): LayerFieldValidationResult[] {
        const textLayer = context.layer as TextLayer;
        const result: LayerFieldValidationResult[] = [];

        if (!this.isLocationEditable(context) && textLayer.forceUserToProvideText && context.advancedMode) {
            result.push(
                validationMessage({
                    context,
                    message: t('BUILD.TEXT_MUST_BE_EDITABLE'),
                }),
            );
        }
        return result;
    }

    private isLocationEditable(context: LayerFieldValidationContext) {
        return context.document.editableFields.some(
            field => field.layerId === context.layer.id && field.path === 'text',
        );
    }
}

// Email builder link validation functions and classes

export function linkHasHttpStart(link: string): boolean {
    return (/^(http[s]?:\/\/|tel:|mailto:).+/g).test(link) || (/^#/g).test(link);
}

export function linkIsEmpty(link: string): boolean {
    return link.trim().length === 0;
}

export function linkContainsWhitespace(link: string): boolean {
    return link.trim().indexOf(' ') >= 0;
}

export function linkHasMergeField(link: string): boolean {
    return link.includes('[[');
}

export class ButtonLinkValidator implements SectionFieldValidator {
    public sectionType = SectionType.button;

    public fieldPath = 'link';

    public validate(context: SectionFieldValidationContext): SectionFieldValidationResult[] {
        const buttonSection = context.section as ButtonSection;
        const result: SectionFieldValidationResult[] = [];

        if (buttonSection.visible) {
            if (
                !linkHasMergeField(buttonSection.link) &&
                (linkIsEmpty(buttonSection.link) ||
                    linkContainsWhitespace(buttonSection.link) ||
                    !linkHasHttpStart(buttonSection.link))
            ) {
                result.push({
                    ...context,
                    message: `${context.section.title}: Link must have a value and start with http://, https://, tel: or mailto:`,
                    severity: FieldValidationResultSeverity.Error,
                });
            }
        }

        return result;
    }
}

export class ImageLinkValidator implements SectionFieldValidator {
    public sectionType = SectionType.image;

    public fieldPath = 'link';

    public validate(context: SectionFieldValidationContext): SectionFieldValidationResult[] {
        const imageSection = context.section as ImageSection;
        const result: SectionFieldValidationResult[] = [];

        if (imageSection.visible && imageSection.isLinkable) {
            if (!linkIsEmpty(imageSection.link)) {
                if (
                    !linkHasMergeField(imageSection.link) &&
                    (linkContainsWhitespace(imageSection.link) || !linkHasHttpStart(imageSection.link))
                ) {
                    result.push({
                        ...context,
                        message: `${context.section.title}: Link must be valid URL and start with http://, https://, tel: or mailto:`,
                        severity: FieldValidationResultSeverity.Error,
                    });
                }
            }
        }

        return result;
    }
}

export class TextLinkValidator implements SectionFieldValidator {
    public sectionType = SectionType.text;

    public fieldPath = 'link';

    public validate(context: SectionFieldValidationContext): SectionFieldValidationResult[] {
        const textSection = context.section as TextSection;
        const result: SectionFieldValidationResult[] = [];

        if (textSection.visible && textSection.html) {
            const parser = new DOMParser();

            const htmlString = textSection.html;
            const sectionDocument = parser.parseFromString(htmlString, 'text/html');
            const nodes: HTMLAnchorElement[] = Array.from(sectionDocument.getElementsByTagName('a'));
            nodes.forEach((node: HTMLAnchorElement, index: number) => {
                const link = node.getAttribute('href') ?? '';
                if (
                    !linkHasMergeField(link) &&
                    (linkIsEmpty(link) || linkContainsWhitespace(link) || !linkHasHttpStart(link))
                ) {
                    result.push({
                        ...context,
                        message: `${context.section.title}: Link number ${
                            index + 1
                        } must have a value and start with http://, https://, tel: or mailto:`,
                        severity: FieldValidationResultSeverity.Error,
                    });
                }
            });
        }

        return result;
    }
}

export function getFieldValidationClassName(messages: FieldValidationResult[]): string {
    if (!messages.length) {
        return '';
    }

    const severity = getHighestSeverity(messages.map(message => message.severity));

    switch (severity) {
        case FieldValidationResultSeverity.Info:
            return '';
        case FieldValidationResultSeverity.Warning:
            return 'has-warning';
        case FieldValidationResultSeverity.Error:
            return 'has-error';
        default:
            return '';
    }
}
