import { t, CreateStripeChargeBody, CurrencyCode, CurrencyNumber, PrintProviderShoppingCartOrderId, StripeChargeDto, StripeConfigurationId } from '@deltasierra/shared';
import * as React from 'react';
import { Elements, StripeProvider } from 'react-stripe-elements';
import { ExpressionBinding, OneWayBinding } from '../../../common/angularData';
import { useAngularServiceContext } from '../../../common/componentUtils/angularServiceContexts';
import { withAngularIntegration } from '../../../common/componentUtils/reactComponentRegistration';
import { useFuture } from '../../../common/futures';
import StripeCheckoutForm from './StripeCheckoutForm';

export interface StripeChargePropsData {
    currency: CurrencyCode;
    description?: string;
    localeCode: string | null;
    locationMapId?: number;
    printProviderShoppingCartOrderId?: PrintProviderShoppingCartOrderId;
    stripeConfigurationId: StripeConfigurationId | null;
    stripePublishableKey: string;
}

interface StripeCheckoutWrapperProps {
    amountInCents: CurrencyNumber;
    createChargeData: StripeChargePropsData;
    disabled: boolean;
    onSuccessfulCharge: (chargeResult: StripeChargeDto) => void;
}

// This component will throw errors regarding unsafe lifecycle methods used in strict mode
// https://github.com/stripe/react-stripe-elements/issues/294
const StripeCheckoutWrapper: React.FunctionComponent<StripeCheckoutWrapperProps> = props => {
    const stripeApiClient = useAngularServiceContext('StripeApiClient');
    const createStripeChargeResult = useFuture(
        async (data: CreateStripeChargeBody) => stripeApiClient.createStripeCharge(data),
        [stripeApiClient],
        { description: t('PAYMENTS.STRIPE.CREATE_STRIPE_CHARGE') },
    );

    const onSuccess = (chargeResult: StripeChargeDto) => {
        props.onSuccessfulCharge(chargeResult);
    };

    return (
        <StripeProvider apiKey={props.createChargeData.stripePublishableKey}>
            <Elements>
                <StripeCheckoutForm
                    amountInCents={props.amountInCents}
                    chargeProcessing={createStripeChargeResult.state.isRunning}
                    chargeResult={createStripeChargeResult.state.isFinished ? createStripeChargeResult.state.value : null}
                    createCharge={async (data: CreateStripeChargeBody) => createStripeChargeResult.invoke(data)}
                    createChargeData={props.createChargeData}
                    disabled={props.disabled}
                    onSuccess={onSuccess}
                />
            </Elements>
        </StripeProvider>
    );
};

const bindings = {
    amountInCents: OneWayBinding,
    createChargeData: OneWayBinding,
    disabled: OneWayBinding,
    onSuccessfulCharge: ExpressionBinding,
};

export default withAngularIntegration(React.memo(StripeCheckoutWrapper), 'stripeCheckoutWrapper', bindings);
