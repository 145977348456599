import { useQuery } from '@apollo/client';
import {
    DSGrid,
    DSIconButton,
    DSLoadMoreButton,
    DSTypography,
    DSWell,
    DSWellHeader,
    DSWellHeaderRightContent,
    DSWellHeaderTitle,
    Translate,
} from '@deltasierra/components';
import { useChecklist } from '@deltasierra/react-hooks';
import RefreshIcon from '@material-ui/icons/Refresh';
import React from 'react';
import styled from 'styled-components';
import { useConnectionFetchMore } from '../../../../../graphql/hooks';
import { relayConnectionToArray } from '../../../../../graphql/utils';
import { BlockedPublishRowAction } from './blocked-publish-row-action';
import { BlockedPublishesActions } from './BlockedPublishesActions';
import { BlockedPublishesTable } from './BlockedPublishesTable';
import { GET_BLOCKED_PUBLISHES_QUERY } from './BlockedPublishesWell.queries';
import { useBlockedPublishesActions } from './use-blocked-publishes-actions';
import { GetBlockedPublishes, GetBlockedPublishesVariables } from './__graphqlTypes/GetBlockedPublishes';

const StyledCenter = styled.div`
    text-align: center;
`;

export function BlockedPublishesWell(): JSX.Element {
    const { data, fetchMore, loading, refetch } = useQuery<GetBlockedPublishes, GetBlockedPublishesVariables>(
        GET_BLOCKED_PUBLISHES_QUERY,
        {
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true,
            variables: {
                first: 2,
            },
        },
    );
    const blockedPublishes = React.useMemo(
        () => relayConnectionToArray(data?.admin.blockedPublishes),
        [data?.admin.blockedPublishes],
    );
    const [handleFetchMore, hasNextPage] = useConnectionFetchMore(data?.admin.blockedPublishes, async after =>
        fetchMore({ variables: { after, first: 20 } }),
    );

    const [selected, setSelected, clearAll] = useChecklist();

    React.useEffect(() => {
        clearAll();
    }, [blockedPublishes, clearAll]);

    const { pushToFailed, pushToSent, running, sendNow } = useBlockedPublishesActions();

    const sendNowSingle = React.useCallback<BlockedPublishRowAction>(
        row => {
            sendNow([row.id]);
        },
        [sendNow],
    );
    const pushToFailedSingle = React.useCallback<BlockedPublishRowAction>(
        row => {
            pushToFailed([row.id]);
        },
        [pushToFailed],
    );
    const pushToSentSingle = React.useCallback<BlockedPublishRowAction>(
        row => {
            pushToSent([row.id]);
        },
        [pushToSent],
    );

    return (
        <DSWell>
            <DSWellHeader>
                <DSWellHeaderTitle>
                    <Translate keyId="ADMIN.SERVICING.BLOCKED_PUBLISHES.TITLE" />
                </DSWellHeaderTitle>
                <DSWellHeaderRightContent>
                    <DSIconButton onClick={() => refetch()}>
                        <RefreshIcon />
                    </DSIconButton>
                </DSWellHeaderRightContent>
            </DSWellHeader>
            <DSTypography gutterBottom>
                <Translate keyId="ADMIN.SERVICING.BLOCKED_PUBLISHES.SUBTITLE" />
            </DSTypography>
            <DSGrid alignItems="center" container direction="row-reverse" spacing={1}>
                <DSGrid item>
                    <BlockedPublishesActions
                        disabled={running}
                        pushToFailed={() => {
                            pushToFailed(selected);
                        }}
                        pushToSent={() => {
                            pushToSent(selected);
                        }}
                        sendNow={() => {
                            sendNow(selected);
                        }}
                    />
                </DSGrid>
                <DSGrid item>
                    <Translate keyId="AGENCY.CLIENT.USERS.COUNT_SELECTED" options={{ count: selected.length }} />
                </DSGrid>
            </DSGrid>
            <BlockedPublishesTable
                disableActions={running}
                loading={loading || running}
                pushToFailed={pushToFailedSingle}
                pushToSent={pushToSentSingle}
                rows={blockedPublishes}
                selected={selected}
                sendNow={sendNowSingle}
                setSelected={setSelected}
            />
            {hasNextPage && (
                <StyledCenter>
                    <DSLoadMoreButton gutterTop loading={loading} onClick={handleFetchMore} />
                </StyledCenter>
            )}
        </DSWell>
    );
}
BlockedPublishesWell.displayName = 'BlockedPublishesWell';
