import { ExternalPrintPackageBannerId, ExternalPrintPackageBanner, GetExternalAppUrlResponse, ClientId, LocationId } from '@deltasierra/shared';
import { IWindowService } from 'angular';
import { actualComponent, ILifecycleHooks, OneWayBinding, ChangesObject, $windowSID } from '../../common/angularData';
import { InteractionUtils } from '../../common/interactionUtils';
import { MvAuth } from '../../account/mvAuth';
import { ExternalPrintPackageApiClient } from './externalPrintPackageApiClient';


export class ExternalPrintPackageBannerPromotion implements ILifecycleHooks {
    // Props
    public readonly clientId!: ClientId;

    public readonly locationId!: LocationId;

    // Futures
    protected readonly fetchExternalPrintPackageBannerForClient = this.interactionUtils.createFuture('fetch external print package banner', (context: { clientId: ClientId }) => this.externalPrintPackageApiClient.getExternalPrintPackageBanners(context.clientId)
            .then((result: ExternalPrintPackageBanner[]) => {
                if (result.length > 0) {
                    this.externalPrintPackageBanner = result[result.length - 1];
                }
            }));

    public readonly fetchExternalAppUrl = this.interactionUtils.createFuture(
        'fetch url for external print package app',
        async (context: { externalPrintPackageBannerId: ExternalPrintPackageBannerId; locationId: LocationId }) => {
            const result: GetExternalAppUrlResponse = await this.externalPrintPackageApiClient.getExternalAppUrl(
                this.clientId,
                context.externalPrintPackageBannerId,
                this.locationId,
            );
            this.$window.open(result.externalAppUrl, '_blank');
        },
    );

    // State
    public dismissed = false;

    public externalPrintPackageBanner: ExternalPrintPackageBanner | null = null;

    static readonly $inject : string[] = [
        $windowSID,
        InteractionUtils.SID,
        MvAuth.SID,
        ExternalPrintPackageApiClient.SID,
    ];

    constructor(
        protected readonly $window: IWindowService,
        protected readonly interactionUtils: InteractionUtils,
        protected readonly mvAuth : MvAuth,
        protected readonly externalPrintPackageApiClient: ExternalPrintPackageApiClient,
    ) {
    }

    $onChanges(changes: ChangesObject<this>) {
        if (changes.clientId && changes.clientId.currentValue) {
            this.externalPrintPackageBanner = null;
            // TODO: This is a design implementation decision - Should the dismissal persist through different clients?
            this.dismissed = false;
            return this.fetchExternalPrintPackageBannerForClient.run({
                clientId: this.clientId,
            });
        }
    }

    getExternalAppUrl() {
        if (this.externalPrintPackageBanner) {
            return this.fetchExternalAppUrl.run({
                externalPrintPackageBannerId: this.externalPrintPackageBanner.id,
                locationId: this.locationId,
            });
        }
    }

    onClickDismiss(): void {
        this.dismissed = true;
    }
}

export const externalPrintPackageBannerPromotionSID = 'externalPrintPackageBannerPromotion';

export const externalPrintPackageBannerPromotionConfig: ng.IComponentOptions = actualComponent(
    ExternalPrintPackageBannerPromotion,
    '/partials/common/externalPrintPackages/externalPrintPackageBannerPromotion',
    {
        clientId: OneWayBinding,
        locationId: OneWayBinding,
    },
);

angular.module('app').component(externalPrintPackageBannerPromotionSID, externalPrintPackageBannerPromotionConfig);
