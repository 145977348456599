import { Omit, LocationId } from '@deltasierra/shared';

import { I18nService } from '../../i18n';
import { ReportTableColumn, StatsEntry, TextAlignClass } from '../reportTable/reportTable';
import { compareDates, compareNumbers, compareStrings } from '../compare';
import { Formatters } from './formatters';

export interface CreateReportTableOptions {
    formatters: Formatters;
    i18n: I18nService;
}

export interface CreateExpandableReportTableOptions {
    getIsLocationExpanded: (locationId: LocationId) => boolean;
    setIsLocationExpanded: (locationId: LocationId, value: boolean) => void;
}

export type UniqueColumnProperties<E extends StatsEntry<T>, T, V> = Omit<ReportTableColumn<E, T, V>,
    'compare' | 'cssClass' | 'format'>;

export function locationColumn<E extends StatsEntry<T>, T>(
    options: CreateReportTableOptions,
    props: Omit<UniqueColumnProperties<E, T, string>, 'getTotal' | 'getValue' | 'label'>,
): ReportTableColumn<E, T, string> {
    return {
        ...props,
        label: () => options.i18n.text.report.location(),
        cssClass: TextAlignClass.Left,
        format: options.formatters.formatString,
        getValue: entry => entry.location.locationTitle,
        getTotal: totals => options.i18n.text.report.total(),
    };
}

export function stringColumn<E extends StatsEntry<T>, T>(
    options: CreateReportTableOptions,
    props: UniqueColumnProperties<E, T, string>): ReportTableColumn<E, T, string> {
    return {
        ...props,
        cssClass: TextAlignClass.Left,
        compare: compareStrings,
        format: options.formatters.formatString,
    };
}

export function dateColumn<E extends StatsEntry<T>, T>(
    options: CreateReportTableOptions,
    props: UniqueColumnProperties<E, T, Date | undefined>): ReportTableColumn<E, T, Date | undefined> {
    return {
        ...props,
        cssClass: TextAlignClass.Center,
        compare: compareDates,
        format: options.formatters.formatDate,
    };
}

export function numberColumn<E extends StatsEntry<T>, T>(
    options: CreateReportTableOptions,
    props: UniqueColumnProperties<E, T, number | undefined>,
): ReportTableColumn<E, T, number | undefined> {
    return {
        ...props,
        cssClass: TextAlignClass.Right,
        compare: compareNumbers,
        format: options.formatters.formatNumber,
    };
}
