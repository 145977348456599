import { AssignedLocation, GoogleMyBusinessAccountResourceId, GoogleMyBusinessLocationResourceId, LinkedInOrganizationUrn, Platform } from '@deltasierra/shared';
import { IScope } from 'angular';

import { noop } from '@deltasierra/object-utilities';

import { MvIdentity } from '../../account/mvIdentity';
import { MvClient } from '../../clients/mvClient';
import { $scopeSID } from '../../common/angularData';
import { InteractionUtils } from '../../common/interactionUtils';
import { I18nService } from '../../i18n';
import { IntroService } from '../../intro/introService';
import { IntroWrapper } from '../../intro/introWrapper';

export class MvAppAuthLocationCtrl {
    public static SID = 'mvAppAuthLocationCtrl';

    public location: AssignedLocation | null = null;

    public introApps: IntroWrapper;

    public availablePlatforms?: Platform[];

    public fetchData = this.interactionUtils.createFuture<void, { location: AssignedLocation }>(
        this.i18n.text.common.fetchData(),
        context => {
            this.location = context.location;

            return this.clientService
                .getPlatforms(this.location.clientId)
                .then(platforms => {
                    this.availablePlatforms = platforms;
                })
                .then(noop);
        },
    );

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public static readonly $inject: string[] = [
        $scopeSID,
        MvIdentity.SID,
        IntroService.SID,
        InteractionUtils.SID,
        MvClient.SID,
        I18nService.SID,
    ];

    // eslint-disable-next-line max-params
    public constructor(
        private readonly $scope: IScope,
        public readonly identity: MvIdentity,
        introService: IntroService,
        private readonly interactionUtils: InteractionUtils,
        private readonly clientService: MvClient,
        private readonly i18n: I18nService,
    ) {
        this.introApps = introService.setUpAndStartIntro('apps', this.$scope);
        this.onGoogleMyBusinessConfigUpdate = this.onGoogleMyBusinessConfigUpdate.bind(this);
        this.onLinkedInConfigUpdate = this.onLinkedInConfigUpdate.bind(this);
    }

    public updateLocation(location: AssignedLocation): ng.IPromise<void> {
        if (location && this.location && location.id === this.location.id) {
            return Promise.resolve();
        }

        return this.fetchData.run({ location });
    }

    public getLocationName(): string {
        return this.location ? this.location.displayName || this.location.title : '';
    }

    public getClientName(): string {
        return this.location ? this.location.client : '';
    }

    public isPlatformAvailable(platformName: string): boolean {
        return !!this.availablePlatforms && this.availablePlatforms.some(platform => platform.name === platformName);
    }

    public onGoogleMyBusinessConfigUpdate(
        accountId: GoogleMyBusinessAccountResourceId,
        businessLocationId: GoogleMyBusinessLocationResourceId,
    ): void {
        if (this.location) {
            this.location.googleMyBusinessAccountName = accountId;
            this.location.googleMyBusinessLocationId = businessLocationId;
        }
    }

    public onLinkedInConfigUpdate(orgUrn: LinkedInOrganizationUrn): void {
        if (this.location) {
            this.location.linkedInOrganizationUrn = orgUrn;
        }
    }
}

angular.module('app').controller(MvAppAuthLocationCtrl.SID, MvAppAuthLocationCtrl);
