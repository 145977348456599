import {
    MAX_FACEBOOK_CAPTION_CHARACTER_LENGTH,
    MAX_FACEBOOK_COMMENT_CHARACTER_LENGTH,
    MAX_FACEBOOK_HASHTAGS_COUNT,
    MAX_FACEBOOK_SCHEDULED_HOURS,
    t,
} from '@deltasierra/shared';

import { FormikConfig, FormikErrors } from 'formik';
import { DateTime, DurationObjectUnits } from 'luxon';
import React from 'react';
import { FormikValidateFunction } from '../../../typings/formik-validate-function';
import { countHashtagsInString } from '../common/count-hashtags-in-string';
import {
    buildCaption,
    BuildFacebookPostAdditionalOptions,
    buildFirstComment,
    buildHashtagString,
    getCaptionWithTermsAndConditions,
} from './build-facebook-publish-data';
import { FacebookFormValues, HashtagInsertType } from './facebook-form-values';

type useValidatedFacebookFormOptions = BuildFacebookPostAdditionalOptions & { date: Date | undefined };

export function useValidatedFacebookForm({
    date,
    requiredHashtags,
    termsAndConditionsText,
    termsAndConditionsUrl,
}: useValidatedFacebookFormOptions): Pick<FormikConfig<FacebookFormValues>, 'initialValues' | 'validate'> {
    const initialValues = {
        caption: '',
        hashtags: [],
        hashtagsInsertType: 'comment' as HashtagInsertType,
        scheduledTime: date || null,
    };

    const validate = React.useCallback<FormikValidateFunction<FacebookFormValues>>(
        // eslint-disable-next-line max-statements
        ({ caption, hashtags, hashtagsInsertType, scheduledTime }) => {
            const errors: FormikErrors<FacebookFormValues> = {};

            const hashtagsString = buildHashtagString(hashtags);
            const hashtagsCount = hashtagsString ? countHashtagsInString(hashtagsString) : 0;

            const captionWithTermsAndConditions = getCaptionWithTermsAndConditions({
                baseCaption: caption,
                termsAndConditionsText,
                termsAndConditionsUrl,
            });

            const finalCaption = buildCaption({
                baseCaption: caption,
                hashtagsInsertType,
                hashtagsString,
                termsAndConditionsText,
                termsAndConditionsUrl,
            });

            const firstComment = buildFirstComment({
                hashtagsInsertType,
                hashtagsString,
            });

            // If we have a large caption we error here
            if (captionWithTermsAndConditions.length > MAX_FACEBOOK_CAPTION_CHARACTER_LENGTH) {
                errors.caption = t('ERRORS.FACEBOOK_ERRORS.MAX_CHARACTER_LIMIT_FOR_CAPTION_REACHED');
            }

            if (
                // If we have a hashtag string
                hashtagsString &&
                hashtagsString.length > 0 &&
                // And the caption is over the limit, it could be because of the hashtags
                ((hashtagsInsertType === 'caption' && finalCaption.length > MAX_FACEBOOK_CAPTION_CHARACTER_LENGTH) ||
                    // OR the comment is over the limit, it could be because of the hashtags
                    (hashtagsInsertType === 'comment' &&
                        firstComment &&
                        firstComment.length > MAX_FACEBOOK_COMMENT_CHARACTER_LENGTH))
            ) {
                errors.hashtags = t('BUILD.PUBLISH.HASHTAGS.ERROR_LIMIT_HASHTAG_LENGTH');
            }

            // If we have too many hashtags because of the caption
            if (
                (hashtagsInsertType === 'caption' &&
                    countHashtagsInString(captionWithTermsAndConditions) + hashtagsCount >
                    MAX_FACEBOOK_HASHTAGS_COUNT) ||
                countHashtagsInString(captionWithTermsAndConditions) > MAX_FACEBOOK_HASHTAGS_COUNT
            ) {
                errors.caption = t('BUILD.PUBLISH.HASHTAGS.ERROR_LIMIT_HASHTAG_COUNT', {
                    count: MAX_FACEBOOK_HASHTAGS_COUNT,
                });
            }

            // If we have too many hashtags
            if (hashtagsCount > MAX_FACEBOOK_HASHTAGS_COUNT) {
                errors.hashtags = t('BUILD.PUBLISH.HASHTAGS.ERROR_LIMIT_HASHTAG_COUNT', {
                    count: MAX_FACEBOOK_HASHTAGS_COUNT,
                });
            }

            // Check Meta scheduling limits and date validity
            if (scheduledTime) {
                const scheduledTimeObject = new Date(scheduledTime);
                const luxonScheduledTime = DateTime.fromJSDate(scheduledTimeObject);
                if (!luxonScheduledTime.isValid) {
                    errors.scheduledTime = 'Invalid date';
                }

                const diff: DurationObjectUnits = luxonScheduledTime.diffNow('hours').toObject();
                const isValidFacebookJobDate = diff.hours ? diff.hours <= MAX_FACEBOOK_SCHEDULED_HOURS : false;

                if (!isValidFacebookJobDate) {
                    errors.scheduledTime = t(
                        'BUILD.PUBLISH.FACEBOOK.SCHEDULED_FACEBOOK_POST_MUST_BE_NO_MORE_THAN_28_DAYS_IN_FUTURE',
                    );
                }

                const luxonCurrentTime = DateTime.now();
                const isPastDate = luxonScheduledTime < luxonCurrentTime;
                if (isPastDate) {
                    errors.scheduledTime = t('BUILD.PUBLISH.INSTAGRAM.SCHEDULED_POST_MUST_BE_AT_LEAST_MINS_IN_FUTURE', {
                        minutes: 15,
                    });
                }
            }

            return errors;
        },
        [termsAndConditionsText, termsAndConditionsUrl],
    );

    return {
        initialValues,
        validate,
    };
}
