/// <reference path="../_references.d.ts" />
import { $filterSID, $qSID, $scopeSID } from '../common/angularData';
import { FormService } from '../common/formService';
import { InteractionUtils } from '../common/interactionUtils';
import { MvNotifier, mvNotifierSID } from '../common/mvNotifier';
import { I18nService } from '../i18n/i18nService';
import {
    MaintenanceNotificationResource,
    MaintenanceNotificationResourceClass,
    SID as MaintenanceNotificationResourceSID,
} from '../main/maintenanceNotificationResource';
import IAngularEvent = angular.IAngularEvent;


interface IMaintenanceWindowForm extends ng.IFormController {
    startDate: ng.INgModelController;
    startTime: ng.INgModelController;
    expectedDowntimeInMinutes: ng.INgModelController;
}

export class ScheduledSiteMaintenanceCtrl {
    public static SID = 'ScheduledSiteMaintenanceCtrl';

    public static readonly $inject: string[] = [
        $qSID,
        $filterSID,
        $scopeSID,
        InteractionUtils.SID,
        MaintenanceNotificationResourceSID,
        FormService.SID,
        mvNotifierSID,
        I18nService.SID,
    ];

    public loading = {
        delete: false,
        get: false,
        set: false,
    };

    public datePickerIsOpen = false;

    public isMaintenanceScheduled = false;

    public getUpdateMode?: () => string;

    public currentNotification?: MaintenanceNotificationResource;

    public newNotification?: MaintenanceNotificationResource;

    public currentMaintenanceWindowFormatted?: string;

    public form!: IMaintenanceWindowForm;

    // eslint-disable-next-line max-params
    public constructor(
        private readonly $q: ng.IQService,
        private readonly $filter: ng.IFilterService,
        private readonly $scope: ng.IScope,
        private readonly interactionUtils: InteractionUtils,
        private readonly maintenanceNotificationResource: MaintenanceNotificationResourceClass,
        private readonly formService: FormService,
        private readonly notifier: MvNotifier,
        private readonly i18n: I18nService,
    ) {
        this.$scope.$watch(
            () => this.newNotification && this.newNotification.startTime && this.newNotification.startTime.getTime(),
            (newValue, oldValue) => {
                if (this.form) {
                    this.validateStartDate();
                    this.validateStartTime();
                }
            },
        );

        void this.refresh();
    }

    public delete(): ng.IPromise<void> {
        if (!(this.currentNotification && this.currentNotification.id)) {
            return this.$q.resolve();
        }

        return this.interactionUtils.handleRemoteSimple(
            this,
            'remove site maintenance window',
            'delete',
            this.currentNotification.$delete().then(() => {
                this.notifier.notify(this.i18n.text.admin.maintenance.notifyMaintenanceRemoved());
                return this.refresh();
            }),
        );
    }

    public getStartDateTimeValidity(): {
        startDate: {
            min: boolean;
            required: boolean;
        };
        startTime: {
            min: boolean;
        };
    } {
        const startTime = this.newNotification && this.newNotification.startTime;
        const now = new Date();

        const result = {
            startDate: {
                min: true,
                required: true,
            },
            startTime: { min: true },
        };

        if (!startTime) {
            result.startDate.required = false;
        } else if (startTime.getTime() < now.getTime()) {
            result.startDate.min =
                startTime.getFullYear() === now.getFullYear() &&
                startTime.getMonth() === now.getMonth() &&
                startTime.getDate() === now.getDate();
            result.startTime.min = false;
        }

        return result;
    }

    public validateStartDate(forceDirty = false): void {
        const validity = this.getStartDateTimeValidity();

        if (forceDirty) {
            this.form.startDate.$setDirty();
        }
        this.form.startDate.$setValidity('required', validity.startDate.required);
        this.form.startDate.$setValidity('min', validity.startDate.min);
    }

    public validateStartTime(forceDirty = false): void {
        const validity = this.getStartDateTimeValidity();

        if (forceDirty) {
            this.form.startTime.$setDirty();
        }
        this.form.startTime.$setValidity('min', validity.startTime.min);
    }

    public update(): ng.IPromise<void> {
        if (!this.newNotification) {
            return this.$q.resolve();
        }

        this.validateStartDate(true);
        this.validateStartTime(true);

        if (!this.formService.validateForm(this.form)) {
            return this.$q.resolve();
        }

        return this.interactionUtils
            .handleRemoteSimple(this, 'update site maintenance window', 'set', this.newNotification.$save())
            .then(() => {
                this.notifier.notify(this.i18n.text.admin.maintenance.notifyMaintenanceUpdated());
                return this.refresh();
            });
    }

    public cancel(): ng.IPromise<void> {
        this.newNotification = undefined;
        return this.refresh();
    }

    public openDatePicker($event: IAngularEvent): void {
        $event.preventDefault();
        if ($event.stopPropagation) {
            $event.stopPropagation();
        }
        this.datePickerIsOpen = true;
    }

    private refresh() {
        return this.interactionUtils
            .handleRemoteSimple<MaintenanceNotificationResource>(
                this,
                'get site maintenance window',
                'get',
                this.maintenanceNotificationResource.get().$promise.catch((err: any) => this.catchNotFound(err)),
            )
            .then(notification => {
                this.isMaintenanceScheduled = !!(notification && notification.id);
                this.getUpdateMode = this.isMaintenanceScheduled
                    ? this.i18n.text.common.update
                    : this.i18n.text.common.add;
                this.currentNotification = notification;

                if (this.isMaintenanceScheduled) {
                    this.newNotification = angular.copy(notification);

                    const startTimeFormatted = this.$filter('date')(notification.startTime, 'd MMM yyyy hh:mm a', '');
                    const downtimeFormatted = notification.expectedDowntimeInMinutes
                        ? `an expected downtime of ${notification.expectedDowntimeInMinutes} minutes`
                        : 'no expected downtime';

                    this.currentMaintenanceWindowFormatted = `Site maintenance is scheduled for ${startTimeFormatted} with ${downtimeFormatted}.`;
                } else {
                    this.newNotification = new this.maintenanceNotificationResource();
                    this.newNotification.startTime = new Date();

                    this.currentMaintenanceWindowFormatted = 'No site maintenance is currently scheduled';
                }
            });
    }

    private catchNotFound(err: any) {
        const errorMessage = (err && err.statusText) || '';
        if ((/Not Found/gi).test(errorMessage)) {
            return this.$q.resolve(null);
        } else {
            return this.$q.reject(err);
        }
    }
}

angular.module('app').controller(ScheduledSiteMaintenanceCtrl.SID, ScheduledSiteMaintenanceCtrl);
