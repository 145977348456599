import { Column } from '@deltasierra/components';
import { AssignedLocation } from '@deltasierra/shared';
import * as React from 'react';
import { LocationPicker } from '../components';
import { PageTitle } from './PageTitle';

export type PageTitleWithLocationPickerProps = {
    children?: React.ReactNode;
    onChange?: (location: AssignedLocation) => void;
    title: React.ReactNode;
};

export function PageTitleWithLocationPicker({
    children,
    onChange,
    title,
}: PageTitleWithLocationPickerProps): JSX.Element {
    return (
        <PageTitle
            renderColumns={
                <Column md={3}>
                    <LocationPicker onChange={(_id, newLocation) => onChange?.(newLocation)} />
                </Column>
            }
            title={title}
        >
            {children}
        </PageTitle>
    );
}
PageTitleWithLocationPicker.displayName = 'PageTitleWithLocationPicker';
