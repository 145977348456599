import {
    LocationId,
    ImageProcessingFailedEvent,
    ImageProcessingFinishedEvent,
    ImageProcessingPlannerCreatedEvent,
    IPublishImageRequest,
    IPublishImageResponse,
    PublishMultiImageRequest,
    GalleryPlannerDetails,
    WorkflowStartResult,
    UploadId,
    AssetIdAndLayerId,
    BuilderTemplateId,
    LegacyAssetIdAndLayerId,
    SocketMessageEvent,
    assertNever,
    PublishedArtifactGroupId,
} from '@deltasierra/shared';
import { SocketService } from '../../sockets/socketService';
import { $qSID } from '../../common/angularData';
import { SentryService } from '../../common/sentryService';
import IPromise = angular.IPromise;
import IQService = angular.IQService;

type PublishCallback<CD> = (locationId: LocationId, request: IPublishImageRequest<CD>) => IPromise<WorkflowStartResult>;

type MultiImagePublishCallback<CD> = (input: PublishMultiImageRequest<CD>) => IPromise<WorkflowStartResult>;

export enum ImagePublishServiceCompletionEvent {
    PlannerCreated = 'plannerCreated',
    Published = 'published',
}

export default class ImagePublishService {
    public static SID = 'ImagePublishService';

    public static readonly $inject: string[] = [$qSID, SocketService.SID, SentryService.SID];

    public constructor(
        private readonly $q: IQService,
        private readonly socketService: SocketService,
        private readonly sentryService: SentryService,
    ) {}

    // eslint-disable-next-line max-params
    public publishPhoto<TChannelData>(
        imageProcessingContext: { isProcessingOnServer: boolean },
        locationId: LocationId,
        uploadId: UploadId,
        templateId: BuilderTemplateId,
        plannerDetails: GalleryPlannerDetails | null,
        channelData: TChannelData,
        publishCallback: PublishCallback<TChannelData>,
        linkedAssetLibraryAssetIds: AssetIdAndLayerId[],
        publishedArtifactGroupId: PublishedArtifactGroupId | null,
        successEvent: ImagePublishServiceCompletionEvent = ImagePublishServiceCompletionEvent.Published,
    ): IPromise<any> {
        const requestBody: IPublishImageRequest<TChannelData> = {
            auditData: {
                builderTemplateId: templateId,
                linkedAssetLibraryAssetIds,
                plannerId: plannerDetails ? plannerDetails.id : undefined,
                publishedArtifactGroupId,
            },
            channelData,
            uploadId,
        };
        imageProcessingContext.isProcessingOnServer = true;

        const workflow = this.startSocketServiceWorkflow(() => publishCallback(locationId, requestBody), successEvent);
        return this.$q.resolve(workflow).finally(() => {
            imageProcessingContext.isProcessingOnServer = false;
        });
    }

    // eslint-disable-next-line max-params
    public publishMultiImage<TChannelData>(
        imageProcessingContext: { isProcessingOnServer: boolean },
        locationId: string,
        uploadIds: string[],
        templateId: BuilderTemplateId,
        plannerDetails: GalleryPlannerDetails | null,
        channelData: TChannelData,
        publishCallback: MultiImagePublishCallback<TChannelData>,
        linkedAssetLibraryAssetIds: LegacyAssetIdAndLayerId[],
        publishedArtifactGroupId: PublishedArtifactGroupId | null,
        scheduledTime?: Date,
        successEvent: ImagePublishServiceCompletionEvent = ImagePublishServiceCompletionEvent.Published,
    ): IPromise<any> {
        const input: PublishMultiImageRequest<TChannelData> = {
            artifactIds: uploadIds,
            auditData: {
                builderTemplateId: templateId,
                deviceId: null,
                linkedAssetLibraryAssetIds,
                plannerId: plannerDetails ? plannerDetails.graphqlId : undefined,
                publishedArtifactGroupId,
            },
            channelData,
            locationId,
            scheduledTime,
        };
        imageProcessingContext.isProcessingOnServer = true;

        const workflow = this.startSocketServiceWorkflow(() => publishCallback(input), successEvent);
        return this.$q.resolve(workflow).finally(() => {
            imageProcessingContext.isProcessingOnServer = false;
        });
    }

    private startSocketServiceWorkflow(
        workflowTrigger: () => IPromise<WorkflowStartResult>,
        successEvent: ImagePublishServiceCompletionEvent,
    ) {
        let successEventMessage: SocketMessageEvent<IPublishImageResponse> | null = null;
        switch (successEvent) {
            case ImagePublishServiceCompletionEvent.Published:
                successEventMessage = ImageProcessingFinishedEvent;
                break;
            case ImagePublishServiceCompletionEvent.PlannerCreated:
                successEventMessage = ImageProcessingPlannerCreatedEvent;
                break;
            default:
                throw assertNever(successEvent);
        }

        return this.socketService.startWorkflow(
            workflowTrigger,
            successEventMessage,
            ImageProcessingFailedEvent,
            undefined,
            () => this.sentryService.captureException('Socket message timeout alert', {}),
        ) as IPromise<IPublishImageResponse>;
    }
}

angular.module('app').service(ImagePublishService.SID, ImagePublishService);
