import { PrintProvider, PrintProviderId, ClientId, LocationId, BuilderTemplateFormatId } from '@deltasierra/shared';


import {
    actualComponent,
    ExpressionBinding,
    ExpressionCallback,
    ILifecycleHooks,
    OneWayBinding,
} from '../../../common/angularData';
import { InteractionUtils } from '../../../common/interactionUtils';
import { PrintProviderApiClient } from '../../../integration/auth/printProviderApiClient';
import { PublishResult } from '../publishResult';
import { MvAuth } from '../../../account/mvAuth';
import { CommonModalService, ModalButtonStyle } from '../../../common/commonModalService/commonModalService';
import { I18nService } from '../../../i18n';
import { PrintPublishData, PublishImageFormat } from './basePublishPrintController';

enum Views {
    SelectProvider,
    NextStep
}

export class PublishPrintProviderSelection implements ILifecycleHooks {
    // Props (inputs)
    publishData!: PrintPublishData;

    onCancel!: ExpressionCallback<{}>;

    onPublishPublished!: ExpressionCallback<{ publishedResult: PublishResult }>;

    onGeneratePrintPdf!: ExpressionCallback<{ exclusiveArtworkReview: boolean | null | undefined }, ng.IPromise<PDFKit.PDFDocument>>;

    onGenerateImageFileName!: ExpressionCallback<{ imageFormat: PublishImageFormat }, string>;

    onOpenPaymentWindow!: ExpressionCallback<{ url: URL }, void>;

    // State
    views = Views;

    view = Views.SelectProvider;

    printProviders: PrintProvider[] = [];

    shouldUseFour51 = false;

    shouldUseStripe = false;

    canContinue = true;

    // Futures
    getPrintProviders = this.interactionUtils.createFuture('Get print providers', () => this.printProviderApiClient.getAvailableLocationPrintProviders(this.publishData.location.id)
            .then(providers => {
                this.printProviders = providers;
                if (this.printProviders.length == 0) {
                    this.publishData.otherSelected = true;
                }
            }));

    getPrintProviderSKUDetails = this.interactionUtils.createFuture('Get print provider SKU details', (context: {
        printProviderId: PrintProviderId;
        builderTemplateFormatId: BuilderTemplateFormatId;
        locationId: LocationId;
        clientId: ClientId;
    }) => this.printProviderApiClient.getPrintProviderSKUGroup(context.printProviderId, context.builderTemplateFormatId, context.locationId)
            .then(result => {
                if ((!!this.publishData.selectedPrintProvider && this.publishData.selectedPrintProvider.id == context.printProviderId) &&
                    result &&
                    result.skuGroup &&
                    result.skuGroup.printProviderSKUs &&
                    result.skuGroup.printProviderSKUs.length > 0
                ) {
                    const sku = result.skuGroup.printProviderSKUs[0];
                    this.shouldUseFour51 = result.skuGroup.paymentGateway === 'four51';
                    this.shouldUseStripe = result.skuGroup.paymentGateway === 'stripe';
                    this.publishData.skuDetails = {
                        id: sku.id,
                        sku: sku.sku,
                        linearPricing: sku.linearPricing,
                        paymentUrl: result.skuGroup.paymentUrl,
                        paymentGateway: result.skuGroup.paymentGateway,
                        priceAmountInCents: sku.priceAmountInCents || 0,
                        priceCurrency: sku.priceCurrency,
                        orderInformation: result.skuGroup.orderInformation,
                    };
                    if (sku.printProviderSKUQuantityOptions && sku.printProviderSKUQuantityOptions.length > 0) {
                        this.publishData.skuDetails.quantityOptions = sku.printProviderSKUQuantityOptions.map(option => option);
                    }
                    this.publishData.hasSkuDetails = true;

                    // Check if the user can actually make use of Four51 publishing.
                    if (this.shouldUseFour51) {
                        return this.mvAuth.isClientAuthorizedAndConfigured('four51', context.clientId)
                            .then((canAccessFour51: boolean) => {
                                this.canContinue = canAccessFour51;
                                if (!canAccessFour51) {
                                    void this.commonModalService.openModal({
                                        title: this.i18n.text.build.four51.noAccessModalTitle(),
                                        message: this.i18n.text.build.four51.noAccessModalMessage(),
                                        buttons: [{
                                            label: this.i18n.text.common.ok(),
                                            value: true,
                                            cssClass: ModalButtonStyle.Primary,
                                        }],
                                    });
                                } else {
                                    this.optionallySkipStep();
                                }
                            });
                    } else {
                        this.optionallySkipStep();
                    }
                } else {
                    this.publishData.skuDetails = null;
                }
            }));

    static readonly $inject = [
        I18nService.SID,
        InteractionUtils.SID,
        CommonModalService.SID,
        MvAuth.SID,
        PrintProviderApiClient.SID,
    ];

    constructor(
        protected readonly i18n: I18nService,
        protected readonly interactionUtils: InteractionUtils,
        protected readonly commonModalService: CommonModalService,
        protected readonly mvAuth: MvAuth,
        protected readonly printProviderApiClient: PrintProviderApiClient,
    ) {
    }

    isProviderSelected(provider: PrintProvider): boolean {
        return !!this.publishData.selectedPrintProvider && this.publishData.selectedPrintProvider.id == provider.id;
    }

    onProviderClick(provider: PrintProvider): ng.IPromise<void> | void {
        const originalPrintProvider = this.publishData.selectedPrintProvider;
        this.publishData.otherSelected = false;
        this.publishData.selectedPrintProvider = provider;

        if (!originalPrintProvider || provider.id !== originalPrintProvider.id) {
            this.resetForNewPrintProviderSelection();

            if (this.publishData.fileFormatChoice.builderTemplateFormatId) {
                return this.getPrintProviderSKUDetails.run({
                    printProviderId: provider.id,
                    builderTemplateFormatId: this.publishData.fileFormatChoice.builderTemplateFormatId,
                    locationId: this.publishData.location.id,
                    clientId: this.publishData.location.clientId,
                });
            }
        }
    }

    onOtherProviderClick(): void {
        this.resetForNewPrintProviderSelection();
        this.publishData.otherSelected = true;
        this.publishData.selectedPrintProvider = null;
    }

    resetForNewPrintProviderSelection(): void {
        this.shouldUseStripe = false;
        this.shouldUseFour51 = false;
        this.publishData.hasSkuDetails = false;
    }

    continueFromPrintProviderView(): void {
        this.view = Views.NextStep;
    }

    publishPublished(publishedResult: PublishResult): void {
        return this.onPublishPublished({ publishedResult });
    }

    openPaymentWindow(url: URL): void {
        return this.onOpenPaymentWindow({ url });
    }

    generatePrintPdf(exclusiveArtworkReview: boolean): ng.IPromise<PDFKit.PDFDocument> {
        return this.onGeneratePrintPdf({ exclusiveArtworkReview });
    }

    generateImageFileName(imageFormat: PublishImageFormat): string {
        return this.onGenerateImageFileName({ imageFormat });
    }

    onClickCancel(): void {
        this.onCancel({});
    }

    $onInit() {
        this.publishData.hasExclusivePrintProvider = !!this.publishData.exclusivePrintProvider;
        if (!this.publishData.exclusivePrintProvider) {
            void this.getPrintProviders.run({});
        } else {
            this.publishData.selectedPrintProvider = this.publishData.exclusivePrintProvider;
            if (this.publishData.fileFormatChoice.builderTemplateFormatId) {
                void this.getPrintProviderSKUDetails.run({
                    printProviderId: this.publishData.selectedPrintProvider.id,
                    builderTemplateFormatId: this.publishData.fileFormatChoice.builderTemplateFormatId,
                    locationId: this.publishData.location.id,
                    clientId: this.publishData.location.clientId,
                });
            }
        }
    }

    private optionallySkipStep() {
        if (this.publishData.hasExclusivePrintProvider) {
            this.continueFromPrintProviderView();
        }
    }
}

export const publishPrintProviderSelectionSID = 'publishPrintProviderSelection';

export const publishPrintProviderSelectionConfig: ng.IComponentOptions = actualComponent(
    PublishPrintProviderSelection,
    '/partials/contentBuilder/publish/print/publishPrintProviderSelection',
    {
        publishData: OneWayBinding,
        onCancel: ExpressionBinding,
        onPublishPublished: ExpressionBinding,
        onGeneratePrintPdf: ExpressionBinding,
        onGenerateImageFileName: ExpressionBinding,
        onOpenPaymentWindow: ExpressionBinding,
    },
);

angular.module('app').component(publishPrintProviderSelectionSID, publishPrintProviderSelectionConfig);
