import { LocationPeriod, PerformanceMetricValues } from '@deltasierra/shared';

import { SegmentKey, TranslateOptions } from '@deltasierra/i18n';

import moment from 'moment-timezone';
import { I18nService } from '../../../../i18n';
import { TranslatableError, isTranslatableError } from '../../../../common/exceptions';
import { $httpSID } from '../../../../common/angularData';

export type MetricRowStatus = 'done' | 'querying' | 'unavailable';

export type DifferenceDirection = 'decrease' | 'increase' | 'none';

export interface MetricValues<TValue> extends PerformanceMetricValues<TValue> {
    difference: TValue;
    differenceDirection: DifferenceDirection;
}

export type MetricColumnType = 'clientAverage' | 'currentMonth' | 'difference' | 'previousMonth';

export interface MetricColumnSettings {
    decimals?: number;
    filter?: string;
}

export type MultipleColumnSettings = {
    [K in MetricColumnType]?: MetricColumnSettings;
} & {
    default: MetricColumnSettings;
};

export interface Metric<TValue> {
    label: string;
    iconUrl: string;
    columnSettings: MultipleColumnSettings;
    values?: MetricValues<TValue> | null | undefined;
    error?: Error | any | string;
}

export class InsufficientDataRecorded extends TranslatableError {
    public static KEY: SegmentKey;

    public constructor() {
        const lastDayOfMonth = moment().endOf('month');
        const dayOfMonthToday = moment();
        const numDaysTillNewMonth = lastDayOfMonth.diff(dayOfMonthToday, 'days') + 1;
        if (numDaysTillNewMonth <= 1) {
            InsufficientDataRecorded.KEY = 'DASHBOARD.PERFORMANCE_SUMMARY.INSUFFICIENT_DATA_RECORDED';
        } else {
            InsufficientDataRecorded.KEY = 'DASHBOARD.PERFORMANCE_SUMMARY.INSUFFICIENT_DATA_RECORDED_plural';
        }
        const translateOptions: TranslateOptions = { numDays: numDaysTillNewMonth };
        super(InsufficientDataRecorded.KEY, translateOptions);
    }
}

export class ServiceNotAuthorised extends TranslatableError {
    public static readonly KEY: SegmentKey = 'DASHBOARD.PERFORMANCE_SUMMARY.SERVICE_NOT_AUTHORISED';

    public constructor() {
        super(ServiceNotAuthorised.KEY);
    }
}

export interface MetricProviderService {
    getMetricRows(params: LocationPeriod): ng.IPromise<Array<Metric<any>>>;
}

export abstract class BaseMetricProviderService implements MetricProviderService {
    public static readonly $inject: string[] = [$httpSID, I18nService.SID];

    public constructor(protected $http: ng.IHttpService, protected i18n: I18nService) {}

    public abstract getMetricRows(params: LocationPeriod): ng.IPromise<Array<Metric<any>>>;

    protected getHttpErrorHandlerForMetrics(...metrics: Array<Metric<any>>) {
        return (res: ng.IHttpPromiseCallbackArg<any>): Array<Metric<any>> => {
            if (
                this.isServiceNotAuthenticatedError(res.data) ||
                this.isServiceConfigurationError(res.data) ||
                this.isServiceDisabledForClientError(res.data)
            ) {
                return [];
            }

            const error = (res.data && res.data.reason) || res.statusText || res.status;

            for (const metric of metrics) {
                metric.error = (error !== -1 && error) || this.i18n.text.common.connectionIssues();
            }

            return metrics;
        };
    }

    private isServiceNotAuthenticatedError(err: { name?: string }): boolean {
        return err && err.name === 'ServiceCredentialsMissingError';
    }

    private isServiceConfigurationError(err: { name?: string }): boolean {
        return err && err.name === 'ServiceConfigurationError';
    }

    private isServiceDisabledForClientError(err: { name?: string }): boolean {
        return err && err.name === 'ServiceDisabledError';
    }
}

export function addDifference<TValue extends number>(values: PerformanceMetricValues<TValue>): MetricValues<TValue> {
    const difference = (values.currentMonth as number) - (values.previousMonth as number);

    return {
        ...values,
        difference: difference as TValue,
        // eslint-disable-next-line no-nested-ternary
        differenceDirection: difference > 0 ? 'increase' : difference < 0 ? 'decrease' : 'none',
    };
}

export function isMetricAvailable(metric: Metric<any>): boolean {
    return !!metric.values;
}

export function isMetricUnavailable(metric: Metric<any>): boolean {
    return !!metric.error;
}

export function hasInsufficientDataRecorded(metric: Metric<any>): boolean {
    return (
        isMetricUnavailable(metric) &&
        isTranslatableError(metric.error) &&
        metric.error.key === InsufficientDataRecorded.KEY
    );
}
