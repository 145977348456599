/// <reference path="../../../typings/browser.d.ts" />
import { noop } from '@deltasierra/object-utilities';
import { Activity, Channel, ClientId, NewChannelData, Untyped } from '@deltasierra/shared';
import IHttpService = angular.IHttpService;
import IPromise = angular.IPromise;
import IHttpPromiseCallbackArg = angular.IHttpPromiseCallbackArg;

function extractData<T>(response: IHttpPromiseCallbackArg<T>): T {
    return response.data!;
}

export class MvActivity {
    static SID = 'mvActivity';

    static readonly $inject: string[] = ['$http'];

    constructor(private $http: IHttpService) {}

    createActivity(activityData: Untyped): IPromise<Activity> {
        return this.$http.post<Activity>('/api/activity/add', activityData).then(extractData);
    }

    addChannel(clientId: ClientId, channelData: NewChannelData): IPromise<Channel> {
        return this.$http
            .post<Channel>('/api/channels', {
                clientId,
                channelData,
            })
            .then(extractData);
    }

    deleteChannel(channelId: number, replacementChannelId?: number): IPromise<any> {
        return this.$http
            .post(`/api/channels/${channelId}/delete`, {
                replacementChannelId: replacementChannelId || null,
            })
            .then(extractData);
    }

    deleteActivity(clientId: Untyped, activityId: Untyped): IPromise<any> {
        return this.$http
            .post('/api/activity/delete', {
                clientId,
                activityId,
            })
            .then(extractData);
    }

    getActivities(clientId: Untyped): IPromise<Activity[]> {
        return this.$http.get<Activity[]>(`/api/clients/${clientId}/activities`).then(extractData);
    }

    updateActivity(clientId: Untyped, activityId: Untyped, activityData: Untyped): IPromise<Activity> {
        return this.$http
            .post<Activity>('/api/activity/update', {
                clientId,
                activityId,
                activityData,
            })
            .then(extractData);
    }

    updateActivityOrder(orderData: number[], clientId: ClientId): ng.IPromise<void> {
        return this.$http
            .post('/api/activity/update-order', {
                orderData,
                clientId,
            })
            .then(noop);
    }
}

angular.module('app').service(MvActivity.SID, MvActivity);
