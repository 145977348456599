import {
    DSButton,
    DSDialog,
    DSDialogActions,
    DSDialogContent,
    DSDialogTitle,
    DSLoaderButton,
    DSPaper,
    DSTypography,
    Loading,
    Tooltip,
    Translate,
} from '@deltasierra/components';
import { t } from '@deltasierra/shared';
import * as React from 'react';
import styled from 'styled-components';
import { Form, Formik } from 'formik';
import { DSCheckboxFormik } from '../../../common';
import { CopyTemplateControlledInput } from './CopyTemplateControlledInput';
import { CopyTemplateClientPicker } from './CopyTemplateClientPicker';
import { useCopyTemplateModal } from './useCopyTemplateModal';
import { SelectAllClientsCheckboxContainer } from './SelectAllClientsCheckboxContainer';

const TitleBox = styled.div`
    display: flex;
`;

export type CopyTemplateModal = {
    builderTemplateId: string;
    onCancel: () => void;
    onSubmit: (clientIds: string[], templateId: string, includeCategories: boolean) => void;
    show: boolean;
};

// eslint-disable-next-line max-lines-per-function
export function CopyTemplateModal({ builderTemplateId, onCancel, onSubmit, show }: CopyTemplateModal): JSX.Element {
    const {
        clientsLoading,
        currentClientLoading,
        handleFetchMore,
        handleSubmit,
        handleValidation,
        hasNextPage,
        initialValues,
        locationLoading,
        searchFilter,
        setSearchFilter,
        targetClientOptions,
    } = useCopyTemplateModal(builderTemplateId, onSubmit);

    const isLoading = clientsLoading || locationLoading || currentClientLoading;

    return (
        <DSDialog maxWidth="md" open={show}>
            <TitleBox>
                <DSDialogTitle>{t('BUILD.TEMPLATE_ADMIN.COPY_TO')}</DSDialogTitle>
            </TitleBox>
            <DSDialogContent>
                <Formik<typeof initialValues>
                    initialValues={initialValues}
                    validate={handleValidation}
                    validateOnMount={false}
                    onSubmit={handleSubmit}
                >
                    {formik => (
                        <>
                            <Form>
                                <CopyTemplateControlledInput initialValue={searchFilter} onChange={setSearchFilter} />
                                {formik.errors.selectedClientIds && (
                                    <DSTypography color="error">{formik.errors.selectedClientIds}</DSTypography>
                                )}
                                {!isLoading && (
                                    <SelectAllClientsCheckboxContainer
                                        optionsLength={targetClientOptions.length}
                                        selected={formik.values.selectedClientIds}
                                        style={{
                                            padding: '4px 8px 0px 8px',
                                        }}
                                        onDeselectAll={() => formik.setFieldValue('selectedClientIds', [])}
                                        onSelectAll={() =>
                                            formik.setFieldValue(
                                                'selectedClientIds',
                                                targetClientOptions.map(client => client.id),
                                            )
                                        }
                                    />
                                )}
                                <DSPaper
                                    elevation={0}
                                    style={{
                                        border: '1px solid #eee',
                                        height: '240px',
                                        overflowY: 'scroll',
                                        padding: '4px 8px 0px 8px',
                                    }}
                                >
                                    {!isLoading && (
                                        <CopyTemplateClientPicker
                                            clients={targetClientOptions}
                                            searchTerm={searchFilter}
                                            selected={formik.values.selectedClientIds}
                                            onChange={value => formik.setFieldValue('selectedClientIds', value)}
                                        />
                                    )}
                                    {isLoading && <Loading />}
                                </DSPaper>
                                <DSCheckboxFormik
                                    formik={formik}
                                    label={
                                        <>
                                            <Translate keyId="BUILD.TEMPLATE_ADMIN.INCLUDE_ASSIGNED_CATEGORIES" />
                                            <Tooltip
                                                tip={
                                                    <Translate keyId="BUILD.TEMPLATE_ADMIN.INCLUDE_ASSIGNED_CATEGORIES_TOOLTIP" />
                                                }
                                            >
                                                <i className={'fa fa-fw fa-question-circle hint-icon'} />
                                            </Tooltip>
                                        </>
                                    }
                                    name="includeCategories"
                                />
                                <DSDialogActions>
                                    {hasNextPage && (
                                        <DSLoaderButton
                                            color="primary"
                                            loading={clientsLoading || locationLoading || currentClientLoading}
                                            style={{ marginRight: 'auto' }}
                                            variant="outlined"
                                            onClick={async () => handleFetchMore()}
                                        >
                                            <Translate keyId="COMMON.LOAD_MORE" />
                                        </DSLoaderButton>
                                    )}
                                    <DSButton onClick={onCancel}>{t('COMMON.CANCEL')}</DSButton>
                                    <DSButton color="primary" disabled={!formik.isValid || isLoading} type="submit">
                                        {t('COMMON.OK')}
                                    </DSButton>
                                </DSDialogActions>
                            </Form>
                        </>
                    )}
                </Formik>
            </DSDialogContent>
        </DSDialog>
    );
}
CopyTemplateModal.displayName = 'CopyTemplateModal';
