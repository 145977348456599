import { BuilderDocumentInspector } from '@deltasierra/shared';
import { InteractionUtils } from '../../../../common/interactionUtils';
import { PrintProviderApiClient } from '../../../../integration/auth/printProviderApiClient';
import {
    $qSID,
    $scopeSID,
    actualComponent,
    ExpressionBinding,
    ILifecycleHooks,
    OneWayBinding,
} from '../../../../common/angularData';
import { BuilderConstants, builderConstantsSID } from '../../../builderConstants';
import { MvIdentity } from '../../../../account/mvIdentity';
import { PdfBuilderService } from '../../../print/pdfBuilderService';
import { ConfirmModal, confirmModalSID } from '../../../../common/confirmModal';
import { FileUtils } from '../../../../common/fileUtils';
import { I18nService } from '../../../../i18n';
import { UploadService } from '../../../../common/uploadService';
import { PrintPublishService } from '../../../../integration/publish/printPublishService';
import { CurrencyService } from '../../../../payments/currencyService';
import { MvLocation } from '../../../../locations/mvLocation';
import { DataUtils } from '../../../../common/dataUtils';
import { MvNotifier } from '../../../../common/mvNotifier';
import { PublishResultOptions } from '../../publishResult';
import {
    BasePublishPrintSKUController,
    BaseSKUPrintPublishViews,
    QuantityOption,
} from '../sku/basePublishPrintSKUController';
import {
    BasePrintPublishViews,
    NextViewMap,
    PrintPublishData,
    SendData,
    SendToPrintContext,
} from '../basePublishPrintController';

const publishPrintStripeNextViewMap: NextViewMap<BasePrintPublishViews | BaseSKUPrintPublishViews> = {
    Review: 'ReviewConfirmation',
    ReviewConfirmation: 'Details',
    Details: 'Summary',
    Summary: 'Publishing',
    Publishing: 'Publishing',
};

export class PublishPrintStripeController extends BasePublishPrintSKUController<BaseSKUPrintPublishViews> implements ILifecycleHooks {
    // Props (inputs)
    publishData!: PrintPublishData;

    // State
    selectedQuantity?: QuantityOption;

    protected readonly inspector = new BuilderDocumentInspector();

    static readonly $inject: string[] = [
        $qSID,
        InteractionUtils.SID,
        DataUtils.SID,
        MvNotifier.SID,
        PrintProviderApiClient.SID,
        builderConstantsSID,
        MvIdentity.SID,
        PdfBuilderService.SID,
        confirmModalSID,
        FileUtils.SID,
        I18nService.SID,
        UploadService.SID,
        PrintPublishService.SID,
        CurrencyService.SID,
        MvLocation.SID,
        $scopeSID,
        // StripeApiClient.SID
    ];

    constructor(
        $q: ng.IQService,
        interactionUtils: InteractionUtils,
        dataUtils: DataUtils,
        mvNotifier: MvNotifier,
        printProviderApiClient: PrintProviderApiClient,
        builderConstants: BuilderConstants,
        identity: MvIdentity,
        pdfBuilderService: PdfBuilderService,
        modal: ConfirmModal,
        fileUtils: FileUtils,
        i18n: I18nService,
        uploadService: UploadService,
        printPublishService: PrintPublishService,
        currencyService: CurrencyService,
        mvLocation: MvLocation,
        protected readonly $scope: ng.IScope,
        // Protected readonly stripeApiClient: StripeApiClient
    ) {
        super(
            $q,
            interactionUtils,
            dataUtils,
            mvNotifier,
            printProviderApiClient,
            builderConstants,
            identity,
            pdfBuilderService,
            modal,
            fileUtils,
            i18n,
            uploadService,
            printPublishService,
            currencyService,
            mvLocation,
            publishPrintStripeNextViewMap,
        );
    }

    /**
     * @override
     */
    $onInit() {
        super.$onInit();

        // Workaround for displaying the quantity label on the summary page, while keeping the actual quantity value on
        //  The requestDetails object.
        this.$scope.$watch(() => this.selectedQuantity, (newValue, oldValue) => {
            if (newValue !== oldValue && !!newValue) {
                this.requestDetails.quantity = newValue.quantity;
            }
        });
    }

    continueToSummary(): void {
        this.setView('Summary');
    }

    protected prepareSendToPrintContext(sendData: SendData<BaseSKUPrintPublishViews>): SendToPrintContext<BaseSKUPrintPublishViews> {
        return {
            ...super.prepareSendToPrintContext(sendData),
            editableTextContent: this.inspector.getAllEditableTextValues(this.publishData.builderDocument, this.publishData.textSubstitutionValues),
            sku: this.skuDetails.sku,
        };
    }

    onSent() {
        const options: PublishResultOptions = {
            extraInfo: this.getExtraPublishInfo(),
            publishType: this.publishTypes.Stripe,
        };
        return super.onSent(options);
    }
}

export const publishPrintStripeControllerSID = 'publishPrintStripeController';

export const publishPrintStripeControllerConfig: ng.IComponentOptions = actualComponent(
    PublishPrintStripeController,
    '/partials/contentBuilder/publish/print/stripe/publishPrintStripe',
    {
        publishData: OneWayBinding,
        generatePrintPdf: ExpressionBinding,
        generateImageFileName: ExpressionBinding,
        onCancel: ExpressionBinding,
        publishPublished: ExpressionBinding,
    },
);

angular.module('app').component(publishPrintStripeControllerSID, publishPrintStripeControllerConfig);
