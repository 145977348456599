import * as React from 'react';
import { Translate } from '@deltasierra/components';
import { TargetClientCheckbox } from './TargetClientCheckbox';

export type SelectAllClientsCheckboxContainerProps = Pick<React.HTMLAttributes<any>, 'style'> & {
    selected: ReadonlyArray<string>;
    optionsLength: number;
    onSelectAll: () => void;
    onDeselectAll: () => void;
};

export const SelectAllClientsCheckboxContainer: React.FC<SelectAllClientsCheckboxContainerProps> = ({
    onDeselectAll,
    onSelectAll,
    optionsLength,
    selected,
    ...props
}) => {
    const isChecked = selected.length === optionsLength && selected.length !==0;

    return (
        <TargetClientCheckbox
            {...props}
            checked={isChecked}
            type="checkbox"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (event.target.checked) {
                    onSelectAll();
                } else {
                    onDeselectAll();
                }
            }}
        >
            <Translate keyId="COMMON.SELECT_ALL" />
        </TargetClientCheckbox>
    );
};
SelectAllClientsCheckboxContainer.displayName = 'SelectAllClientsCheckboxContainer';