/// <reference path="../../../typings/browser.d.ts" />
import { Untyped } from '@deltasierra/shared';

import { ImageLoaderService } from './imageLoaderService';
import { ContentBuilder } from './contentBuilder';
import { FileFormatChoice } from './publish/mvContentBuilderFileFormatCtrl';
import IQService = angular.IQService;

interface GIFOptions {
    workerScript : string;
    workers : number;
    quality : number;
    dither : string;
    repeat : number;
    // ... other properties...
}

interface GIFAddFrameOptions {
    copy : boolean;
    delay : number;
    // ... other properties ...
}

interface GIFLib {
    // eslint-disable-next-line @typescript-eslint/no-misused-new
    new(options : GIFOptions) : GIFLib;
    addFrame(source : any, options : GIFAddFrameOptions) : Untyped;
    on(event : string, handler : (blob : Blob) => void) : Untyped;
    render() : void;
}

declare const GIF : GIFLib;

export class GifWriterService {
    static SID = 'gifWriterService';

    static readonly $inject : string[] = [
        '$q',
        'imageLoaderService',
    ];

    constructor(
        private $q : IQService,
        private imageLoaderService : ImageLoaderService,
    ) {

    }

    writeAnimationToBlob(contentBuilder: ContentBuilder, exportOptions: FileFormatChoice) {
        return this.$q<Blob>((resolve : ng.IQResolveReject<Blob>, reject : ng.IQResolveReject<Blob>) => {
            if (!contentBuilder.document.animation) {
                return reject();
            }
            exportOptions = angular.copy(exportOptions);
            exportOptions.imageFormat = null; // Export to PNG (implicit)

            const numFrames = contentBuilder.document.animation.frames.length;
            let loop = contentBuilder.document.animation.loop;
            if (loop === null) {
                loop = -1;
            }

            const gif = new GIF({
                workerScript: '/vendor/gif.js/dist/gif.worker.js', // Ugh
                workers: 2,
                quality: 10,
                dither: 'FloydSteinberg', // Might need to make this configurable
                repeat: loop,
            });

            for (let i = 0; i < numFrames; i++) {
                contentBuilder.setAnimationFrame(i);
                const canvas = contentBuilder.exportAsCanvas(exportOptions);
                gif.addFrame(canvas, {
                    copy: true,
                    delay: contentBuilder.getCurrentFrame()!.duration,
                });
            }

            gif.on('finished', (blob : Blob) => resolve(blob));

            gif.render();
        });
    }

    writeAnimationToDataUrl(contentBuilder: ContentBuilder, exportOptions: FileFormatChoice) {
        return this.writeAnimationToBlob(contentBuilder, exportOptions)
            .then(blob => this.imageLoaderService.blobToDataUrl(blob));
    }
}

angular.module('app').service(GifWriterService.SID, GifWriterService);
