import { gql } from '@apollo/client';

export const GET_ENABLED_INTEGRATIONS_FOR_CLIENT = gql`
    query GetEnabledIntegrationsForClient($id: ID!) {
        client(id: $id) {
            __typename
            id
            title
            integrationConnection {
                edges {
                    node {
                        id
                        name
                        isEnabled
                        isRequired
                        displayName
                        isReady
                    }
                }
            }
        }
    }
`;