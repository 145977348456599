/// <reference path="../../../../../typings/browser.d.ts" />
import {
    AuthRequestBody,
    Client,
    ClientId,
    GetPlatformsForClientsResult,
    ICreatePrintProviderBody,
    Nullable,
    PrintProviderDtoWithBasicClientInfo,
    PrintProviderId,
    PrintProviderSKUGroup,
    PrintProviderSKUGroupId,
} from '@deltasierra/shared';
import { MvClient } from '../../../clients/mvClient';
import { $routeParamsSID, $routeSID, IRoute } from '../../../common/angularData';
import { DataUtils } from '../../../common/dataUtils';
import { TabNavEntry } from '../../../common/dsTabNav';
import { InteractionUtils } from '../../../common/interactionUtils';
import { MvNotifier } from '../../../common/mvNotifier';
import { I18nService } from '../../../i18n/i18nService';
import { PrintProviderApiClient } from '../../../integration/auth/printProviderApiClient';
import { StripeApiClient } from '../../../integration/stripe/stripeApiClient';
import {
    AgencySKUGroupExpectedRouteParams,
    AgencySKUGroupIds,
    extractIdsFromRouteParams,
    getAgencySKUGroupTabNavEntries,
} from '../common';
import { SKUManagementApiClient } from '../skuManagementApiClient';

interface NewProviderData extends ICreatePrintProviderBody {
    clientId: ClientId;
}

type NewProviderFormData = Nullable<NewProviderData>;

export class SKUGroupPrintProvidersController {
    public static SID = 'SKUGroupPrintProvidersController';

    createNewClientProvider = this.interactionUtils.createFuture(
        'Create print provider',
        (context: { newProviderData: NewProviderData; skuGroupId: PrintProviderSKUGroupId }) => {
            const newProvider = context.newProviderData;
            const payload: ICreatePrintProviderBody = {
                email: newProvider.email,
                businessName: newProvider.businessName,
                address: newProvider.address != '' ? newProvider.address : null,
                phoneNumber: newProvider.phoneNumber != '' ? newProvider.phoneNumber : null,
                printProviderSKUGroupId: context.skuGroupId,
                stripeConfigurationId: null,
            };

            return this.printProviderApiClient.createClientPrintProvider(newProvider.clientId, payload);
        },
    );

    setPrintProviderSKUGroup = this.interactionUtils.createFuture(
        'Update Print Provider',
        (context: { printProviderId: PrintProviderId; skuGroupId: PrintProviderSKUGroupId | null }) =>
            this.printProviderApiClient
                .setPrintProviderSKUGroup(context.printProviderId, context.skuGroupId)
                .then(() => {
                    if (context.skuGroupId) {
                        this.mvNotifier.notify(
                            this.i18n.text.agency.printSkuGroups.printProvider.printProviderAttached(),
                        );
                    } else {
                        this.mvNotifier.notify(
                            this.i18n.text.agency.printSkuGroups.printProvider.printProviderDetached(),
                        );
                    }
                }),
    );

    fetchSKUGroupPrintProvidersAndClients = this.interactionUtils.createFuture(
        'Fetch data',
        async (context: { printProviderSKUGroupId: PrintProviderSKUGroupId }) => {
            const skuGroup = await this.skuManagementApiClient.getSKUGroup(context.printProviderSKUGroupId);
            this.skuGroup = skuGroup;
            const allAgencyPrintProviders = await this.skuManagementApiClient.getPrintProviders();
            this.printProviders = this.dataUtils.filterByPredicate(
                printProvider =>
                    printProvider.printProviderSKUGroupId === context.printProviderSKUGroupId ||
                    !printProvider.printProviderSKUGroupId,
                allAgencyPrintProviders,
            );
            const clients = await this.mvClient.getClients();
            this.clients = this.filterAvailableClients(
                clients,
                allAgencyPrintProviders,
                await this.mvClient.getPlatformsForClients(clients.map(client => client.id)),
                skuGroup,
            );
        },
    );

    sendAuthRequest = this.interactionUtils.createFuture(
        'Send auth request',
        async (context: { authData: AuthRequestBody }) => {
            await this.stripeApiClient.sendStripeAuthRequest(context.authData);
        },
    );

    newProviderFormData?: NewProviderFormData;

    clients: Client[] = [];

    printProviders: PrintProviderDtoWithBasicClientInfo[] = [];

    skuGroup: PrintProviderSKUGroup | null = null;

    tabNavEntries: ReadonlyArray<TabNavEntry> = [];

    tabNavParams: AgencySKUGroupIds;

    static readonly $inject: string[] = [
        $routeSID,
        $routeParamsSID,
        I18nService.SID,
        MvNotifier.SID,
        InteractionUtils.SID,
        MvClient.SID,
        DataUtils.SID,
        SKUManagementApiClient.SID,
        PrintProviderApiClient.SID,
        StripeApiClient.SID,
    ];

    constructor(
        private readonly $route: IRoute,
        $routeParams: AgencySKUGroupExpectedRouteParams,
        private readonly i18n: I18nService,
        private readonly mvNotifier: MvNotifier,
        private readonly interactionUtils: InteractionUtils,
        private readonly mvClient: MvClient,
        private readonly dataUtils: DataUtils,
        private readonly skuManagementApiClient: SKUManagementApiClient,
        private readonly printProviderApiClient: PrintProviderApiClient,
        private readonly stripeApiClient: StripeApiClient,
    ) {
        const ids = extractIdsFromRouteParams($routeParams);
        if (ids.skuGroupId) {
            void this.fetchSKUGroupPrintProvidersAndClients.run({ printProviderSKUGroupId: ids.skuGroupId });
        }
        this.tabNavParams = ids;
        this.tabNavEntries = getAgencySKUGroupTabNavEntries(this.i18n);
    }

    onClickAttachPrintProvider(printProvider: PrintProviderDtoWithBasicClientInfo): ng.IPromise<void> | void {
        const skuGroup = this.skuGroup;
        if (skuGroup) {
            const skuGroupId = skuGroup.id;
            return this.setPrintProviderSKUGroup
                .run({
                    printProviderId: printProvider.id,
                    skuGroupId,
                })
                .then(() => {
                    printProvider.printProviderSKUGroupId = PrintProviderSKUGroupId.from(skuGroupId);
                });
        }
    }

    onClickDetachPrintProvider(printProvider: PrintProviderDtoWithBasicClientInfo): ng.IPromise<void> | void {
        const skuGroupId = null;
        return this.setPrintProviderSKUGroup
            .run({
                printProviderId: printProvider.id,
                skuGroupId,
            })
            .then(() => {
                printProvider.printProviderSKUGroupId = skuGroupId;
            });
    }

    onClickSendAuthRequest(printProvider: PrintProviderDtoWithBasicClientInfo): ng.IPromise<void> | void {
        console.log(printProvider);
        if (!!printProvider && this.skuGroup && this.skuGroup.paymentGateway === 'stripe') {
            const authData = {
                email: printProvider.email,
                printProviderId: printProvider.id,
                agencyId: this.skuGroup.agencyId,
            };
            return this.sendAuthRequest.run({ authData });
        }
    }

    protected hasValidPrintProviderData(data: NewProviderFormData): data is NewProviderData {
        return (
            data.businessName != null &&
            data.businessName != '' &&
            data.email != null &&
            data.email != '' &&
            data.clientId != null
        );
    }

    onSubmitAddPrintProvider() {
        if (this.newProviderFormData && this.skuGroup && this.hasValidPrintProviderData(this.newProviderFormData)) {
            return this.createNewClientProvider
                .run({
                    skuGroupId: this.skuGroup.id,
                    newProviderData: this.newProviderFormData,
                })
                .then(() => {
                    this.mvNotifier.notify(this.i18n.text.agency.printSkuGroups.printProvider.printProviderAdded());
                    this.$route.reload();
                });
        }
    }

    // A stripeConfigurationId will be added when an account has authorized and it will be removed upon deauthorizaiton
    printProviderHasAuthWithStripe(printProvider: PrintProviderDtoWithBasicClientInfo): boolean {
        return !!printProvider.stripeConfigurationId;
    }

    protected filterAvailableClients(
        clients: Client[],
        printProviders: PrintProviderDtoWithBasicClientInfo[],
        platformsForClients: GetPlatformsForClientsResult[],
        skuGroup: PrintProviderSKUGroup,
    ): Client[] {
        const platformLookup = this.dataUtils.groupBy('clientId', platformsForClients);
        return this.dataUtils.filterByPredicate(
            client =>
                !this.isClientLinkedToAPrintProvider(client.id, printProviders) &&
                this.isClientPlatformsEnabled(client.id, platformLookup[client.id] || [], skuGroup),
            clients,
        );
    }

    protected isClientPlatformsEnabled(
        clientId: ClientId,
        platformsForClient: GetPlatformsForClientsResult[],
        skuGroup: PrintProviderSKUGroup,
    ): boolean {
        return (
            this.dataUtils.existsByPredicate(platform => platform.name === 'print', platformsForClient) &&
            (skuGroup.paymentGateway !== 'four51' ||
                this.dataUtils.existsByPredicate(platform => platform.name === 'four51', platformsForClient))
        );
    }

    protected isClientLinkedToAPrintProvider(
        clientId: ClientId,
        printProviders: PrintProviderDtoWithBasicClientInfo[],
    ): boolean {
        return this.dataUtils.existsByPredicate(provider => provider.client.id === clientId, printProviders);
    }
}

angular.module('app').controller(SKUGroupPrintProvidersController.SID, SKUGroupPrintProvidersController);
