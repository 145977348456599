import { useInterval } from '@deltasierra/react-hooks';
import { DSFormControl } from '@deltasierra/components';
import { FormikProps } from 'formik';
import * as React from 'react';
import { SchedulePublishTimePicker } from '../../../../directives/SchedulePublishTimePicker';
import { getMinScheduleDateFromNowForFacebook } from '../../facebook/FacebookFormScheduleSection';
import { FacebookPublishType } from '../../facebook/FacebookPublishForm';
import { getMinScheduleDateFromNowForInstagram } from '../../instagramDirect/InstagramDirectFormScheduleSection';
import { FacebookAndInstagramFormValues } from './facebook-and-instagram-form-values';

function getMinScheduleDate(...params: Parameters<typeof getMinScheduleDateFromNowForFacebook>): Date {
    const instagramDate = getMinScheduleDateFromNowForInstagram();
    const facebookDate = getMinScheduleDateFromNowForFacebook(...params);

    return instagramDate > facebookDate ? instagramDate : facebookDate;
}

export type FacebookAndInstagramFormScheduleSectionProps = {
    formik: FormikProps<FacebookAndInstagramFormValues>;
    multipleLocations: boolean;
    publishType: FacebookPublishType;
    timezone: string;
};

export function FacebookAndInstagramFormScheduleSection({
    formik,
    multipleLocations,
    publishType,
    timezone,
}: FacebookAndInstagramFormScheduleSectionProps): JSX.Element {
    const [minScheduledDate, setMinScheduledDate] = React.useState<Date | null>(
        getMinScheduleDate(),
    );
    const scheduledTime = formik.values.scheduledTime;

    const handleChange = React.useCallback(
        (newValue: Date | null) => formik.setFieldValue('scheduledTime', newValue),
        [formik],
    );

    // Every minute
    useInterval(60 * 1000, () => {
        // Make sure to adjust the minimum scheduled date
        const nextMinScheduledDate = getMinScheduleDate();
        setMinScheduledDate(nextMinScheduledDate);

        if (scheduledTime && scheduledTime < nextMinScheduledDate) {
            // Use this to set the scheduled date
            handleChange(nextMinScheduledDate);
        }
    });

    return (
        <DSFormControl error={!!formik.errors.scheduledTime}>
            <SchedulePublishTimePicker
                error={formik.errors.scheduledTime}
                minDate={minScheduledDate}
                timezone={timezone}
                useNewLabel
                value={scheduledTime}
                onChange={handleChange}
            />
        </DSFormControl>
    );
}

FacebookAndInstagramFormScheduleSection.displayName = 'InstagramDirectFormScheduleSection';
