import { DSFormGroup, DSFormLabel, Loading } from '@deltasierra/components';
import { FormikProps } from 'formik';
import * as React from 'react';
import { Translate } from '../../../../directives/Translate';
import { InstagramDirectFormValues } from '../instagram-direct-form-values';
import { InstagramDirectFormHashtagsSectionInner } from './InstagramDirectFormHashtagsSectionInner';
import { MissingAllRequiredPermissionForFirstCommentMessage } from './MissingAllRequiredPermissionForFirstCommentMessage';
import { MissingSomeRequiredPermissionForFirstCommentMessage } from './MissingSomeRequiredPermissionForFirstCommentMessage';
import { useCheckInstagramFirstCommentPermissions } from './useCheckInstagramFirstCommentPermissions';
import { useIsInstagramFirstCommentHashtagsEnabled } from './useIsInstagramFirstCommentHashtagsEnabled';

export type InstagramDirectFormHashtagsSectionProps = {
    availableCaptionLength: number;
    availableFirstCommentLength: number;
    formik: FormikProps<InstagramDirectFormValues>;
    locationId: string;
    locationIds: string[];
    requiredHashtags: string[];
    requiredHashtagsLoading: boolean;
};

export function InstagramDirectFormHashtagsSection({
    availableCaptionLength,
    availableFirstCommentLength,
    formik,
    locationId,
    locationIds,
    requiredHashtags,
    requiredHashtagsLoading,
}: InstagramDirectFormHashtagsSectionProps): JSX.Element {
    const isFeatureEnabled = useIsInstagramFirstCommentHashtagsEnabled();
    const hasRequiredPermissions = useCheckInstagramFirstCommentPermissions(locationIds);

    if (!isFeatureEnabled) {
        return <></>;
    }

    const availableCharacterLength =
        formik.values.hashtagsInsertType === 'caption' ? availableCaptionLength : availableFirstCommentLength;

    return (
        <DSFormGroup>
            <DSFormLabel>
                <Translate keyId="BUILD.PUBLISH.INSTAGRAM.INPUT_LABELS.HASHTAGS" />
            </DSFormLabel>
            {hasRequiredPermissions.loading ? (
                <Loading inline size="small" />
            ) : (
                <>
                    {!hasRequiredPermissions.hasAtLeastOneWithPermission ? (
                        <MissingAllRequiredPermissionForFirstCommentMessage />
                    ) : (
                        <>
                            <InstagramDirectFormHashtagsSectionInner
                                availableCharacterLength={availableCharacterLength}
                                formik={formik}
                                locationId={locationId}
                                locationIds={locationIds}
                                requiredHashtags={requiredHashtags}
                                requiredHashtagsLoading={requiredHashtagsLoading}
                            />
                            {hasRequiredPermissions.areSomeWithoutPermission && (
                                <MissingSomeRequiredPermissionForFirstCommentMessage />
                            )}
                        </>
                    )}
                </>
            )}
        </DSFormGroup>
    );
}
InstagramDirectFormHashtagsSection.displayName = 'InstagramFirstCommentHashtags';
InstagramDirectFormHashtagsSection.MissingAllRequiredPermissionForFirstCommentMessage =
    MissingAllRequiredPermissionForFirstCommentMessage;
InstagramDirectFormHashtagsSection.MissingSomeRequiredPermissionForFirstCommentMessage =
    MissingSomeRequiredPermissionForFirstCommentMessage;
InstagramDirectFormHashtagsSection.useCheckInstagramFirstCommentPermissions = useCheckInstagramFirstCommentPermissions;
InstagramDirectFormHashtagsSection.useIsInstagramFirstCommentHashtagsEnabled =
    useIsInstagramFirstCommentHashtagsEnabled;
