import * as React from 'react';

export type CurrentClientIdType = string;

export type CurrentClientRouteStateContextType =
    | CurrentClientIdType
    | undefined;

export const CurrentClientRouteStateContext = React.createContext<CurrentClientRouteStateContextType>(undefined);
CurrentClientRouteStateContext.displayName = 'CurrentClientRouteStateContext';
