

import { BuilderTemplateCategoryId, Tag, TemplateGroupItem } from '@deltasierra/shared';
import { $rootScopeSID } from '../../../common/angularData';
import { $modalInstanceSID } from '../../../common/angularUIBootstrapData';
import { InteractionUtils } from '../../../common/interactionUtils';
import { MvNotifier } from '../../../common/mvNotifier';
import { I18nService } from '../../../i18n';
import { ModalInstance } from '../../../typings/angularUIBootstrap/modalService';
import { BuilderTemplateApiClient } from '../../builderTemplateApiClient';
import { templateMetadataUpdatedEvent } from './templateAdminEvents';
import IRootScopeService = angular.IRootScopeService;

export class TemplateAdminCategoriesController {
    public readonly preselectedCategoryIds: BuilderTemplateCategoryId[];

    public selectedCategoryIds: BuilderTemplateCategoryId[];

    public templateTitle: string;

    public tags: Tag[];

    submitTemplateCategories = this.interactionUtils.createFuture(this.i18nService.text.common.save(), () => {
        const metadata = {
            categories: this.selectedCategoryIds,
            tags: this.tags.map(tag => tag.id),
            title: this.templateTitle,
        };

        return this.builderTemplateApiClient
            .updateBuilderTemplateMetadata(this.template.id, metadata)
            .then(updatedTemplate => {
                // Because this is a modal, we need to broadcast the event to everything. Boo!
                templateMetadataUpdatedEvent.broadcast(this.$rootScope, {
                    builderTemplate: updatedTemplate,
                });
            });
    });

    static readonly $inject: string[] = [
        $rootScopeSID,
        I18nService.SID,
        InteractionUtils.SID,
        $modalInstanceSID,
        MvNotifier.SID,
        BuilderTemplateApiClient.SID,
        'template' as keyof TemplateAdminCategoriesController,
    ];


    constructor(
        protected readonly $rootScope: IRootScopeService,
        protected readonly i18nService: I18nService,
        protected readonly interactionUtils: InteractionUtils,
        protected readonly $modalInstance: ModalInstance,
        protected readonly mvNotifier: MvNotifier,
        protected readonly builderTemplateApiClient: BuilderTemplateApiClient,
        public readonly template: TemplateGroupItem,
    ) {
        this.preselectedCategoryIds = (template.categories || []).map(category => category.id);
        this.selectedCategoryIds = [...this.preselectedCategoryIds];
        this.templateTitle = template.title;
        this.tags = [...template.tags || []];
    }

    onSelectedCategoryUpdate(categories: BuilderTemplateCategoryId[]) {
        this.selectedCategoryIds = categories;
    }

    save() {
        return this.submitTemplateCategories.run({}).then(() => {
            this.mvNotifier.success(this.i18nService.text.build.templateAdmin.templateUpdated());
            return this.$modalInstance.close();
        });
    }

    cancel() {
        this.$modalInstance.close();
    }
}
