import { Column, DSTypography, Row } from '@deltasierra/components';
import * as React from 'react';
import styled from 'styled-components';

const StyledTypography = styled(DSTypography)`
    text-transform: capitalize;
`;

export type PageTitleProps = {
    children?: React.ReactNode;
    renderColumns?: React.ReactNode;
    title: React.ReactNode;
};

export function PageTitle({ children, renderColumns, title }: PageTitleProps): JSX.Element {
    return (
        <div className="header">
            <div className="container">
                <Row>
                    <Column md={9}>
                        <StyledTypography component="h2" data-cy="page-title" gutterBottom variant="h2">
                            {title}
                        </StyledTypography>
                    </Column>
                    {renderColumns}
                </Row>
                {children}
            </div>
        </div>
    );
}
PageTitle.displayName = 'PageTitle';
