import { gql } from '@apollo/client';
import {
    DSButton,
    DSDialog,
    DSDialogActions,
    DSDialogContent,
    DSDialogTitle,
    DSGrid,
    Translate,
} from '@deltasierra/components';
import { noop } from '@deltasierra/object-utilities';
import { useHorizontalScroll } from '@deltasierra/react-hooks';
import { t, AssignedLocation, BuilderTemplate } from '@deltasierra/shared';
import * as React from 'react';
import styled from 'styled-components';
import { BuilderTemplateDraftOption } from '../BuilderTemplateDraftOption';
import { BuilderTemplateDraftPreview, SelectedBuilderTemplateDraft } from '../BuilderTemplateDraftPreview';
import { BuilderTemplateDraftLoadModalFragment } from './__graphqlTypes/BuilderTemplateDraftLoadModalFragment';

const fragments = {
    locationDrafts: gql`
        fragment BuilderTemplateDraftLoadModalFragment on LocationDraftConnection {
            edges {
                node {
                    id
                    title
                    document
                    updatedAt
                    compositeImage {
                        id
                        thumb256x256url
                    }
                    ...BuilderTemplateDraftPreviewFragment
                }
            }
        }
        ${BuilderTemplateDraftPreview.fragments.locationDraft}
    `,
};

const StyledDialogContent = styled(DSDialogContent)`
    min-width: 0px;
    width: 100%;
`;

const StyledDialogActions = styled(DSDialogActions)`
    justify-content: flex-start;
    margin-left: 24px;
    margin-right: 24px;
    min-width: 0px;
    width: fit-content;
`;

const StyledOuterGridContainer = styled(DSGrid)`
    overflow-x: scroll;
`;

export type BuilderTemplateDraftLoadModalProps = {
    builderTemplate: BuilderTemplate;
    location: AssignedLocation;
    locationDrafts?: BuilderTemplateDraftLoadModalFragment;
    show: boolean;
    onClose: () => void;
    onDelete: (deletedDraftId: string) => void;
    onDraftSelect?: (selectedDraft: SelectedBuilderTemplateDraft) => void;
    onOriginalTemplateSelect?: () => void;
};

export function BuilderTemplateDraftLoadModal({
    builderTemplate,
    location,
    locationDrafts,
    onClose,
    onDelete,
    onDraftSelect = noop,
    onOriginalTemplateSelect = noop,
    show,
}: BuilderTemplateDraftLoadModalProps): JSX.Element {
    const scrollRef = useHorizontalScroll<HTMLDivElement>();
    return (
        <DSDialog maxWidth="md" open={show} onClose={onClose}>
            <DSDialogTitle>{t('BUILD.LOCATION_DRAFT.LOAD_A_PREVIOUS_TEMPLATE_REVISION')}</DSDialogTitle>
            <StyledDialogContent>
                <StyledOuterGridContainer
                    alignItems="flex-start"
                    container
                    justifyContent="flex-start"
                    ref={scrollRef}
                    wrap="nowrap"
                >
                    <BuilderTemplateDraftOption
                        thumbnail={
                            builderTemplate.compositeImage?.thumb256x256url
                                ? {
                                      title: builderTemplate.compositeImage.filename,
                                      url: `https:${builderTemplate.compositeImage.thumb256x256url}`,
                                  }
                                : undefined
                        }
                        title={t('BUILD.ORIGINAL_TEMPLATE')}
                        updatedAt={builderTemplate.updatedAt ?? builderTemplate.createdAt}
                        onOriginalTemplateSelect={onOriginalTemplateSelect}
                    />
                    {locationDrafts?.edges.map(({ node }) => (
                        <BuilderTemplateDraftPreview
                            builderTemplateId={builderTemplate.id}
                            key={node.id}
                            locationDraft={node}
                            locationId={location.graphqlId}
                            onClick={onDraftSelect}
                            onDelete={onDelete}
                        />
                    ))}
                </StyledOuterGridContainer>
            </StyledDialogContent>
            <StyledDialogActions>
                <DSButton color="primary" variant="outlined" onClick={onClose}>
                    <Translate keyId="COMMON.CANCEL" />
                </DSButton>
            </StyledDialogActions>
        </DSDialog>
    );
}

BuilderTemplateDraftLoadModal.displayName = 'BuilderTemplateDraftLoadModal';
BuilderTemplateDraftLoadModal.fragments = fragments;
