import { SendgridEmailCampaignReport } from '@deltasierra/shared';
import { compareDates, compareStrings } from '../compare';
import { ReportTable, StatsEntry, TextAlignClass } from '../reportTable/reportTable';
import { CreateReportTableOptions, locationColumn, numberColumn } from './common';

export function createSendGridReportTable(
    options: CreateReportTableOptions,
): ReportTable<StatsEntry<SendgridEmailCampaignReport>, SendgridEmailCampaignReport> {
    return new ReportTable<StatsEntry<SendgridEmailCampaignReport>, SendgridEmailCampaignReport>({
        columns: [
            locationColumn(options, {}),
            {
                compare: compareStrings,
                cssClass: TextAlignClass.Left,
                format: options.formatters.formatString,
                getTotal: totals => undefined,
                getValue: entry => entry.title,
                label: () => options.i18n.text.report.campaign(),
            },
            {
                compare: compareDates,
                cssClass: TextAlignClass.Center,
                format: options.formatters.formatDate,
                getTotal: totals => undefined,
                getValue: entry => entry.scheduledTime,
                label: () => options.i18n.text.report.sentDate(),
            },
            numberColumn(options, {
                getTotal: totals => totals.recipientCount,
                getValue: entry => entry.recipientCount,
                label: () => options.i18n.text.report.recipients(),
            }),
            numberColumn(options, {
                getTotal: totals => totals.openCount,
                getValue: entry => entry.openCount,
                label: () => options.i18n.text.report.uniqueOpened(),
            }),
            numberColumn(options, {
                getTotal: totals => totals.clickCount,
                getValue: entry => entry.clickCount,
                label: () => options.i18n.text.report.clicks(),
            }),
            numberColumn(options, {
                getTotal: totals => totals.bounceCount,
                getValue: entry => entry.bounceCount,
                label: () => options.i18n.text.report.bounced(),
            }),
        ],
    });
}
