import { gql } from '@apollo/client';

export const PUBLISH_TO_CLUB_READY_STEP_3_FRAGMENT = gql`
    fragment PublishToClubReadyStep3Fragment on ClubReady {
        id
        clubName
        sender {
            name
            email
        }
    }
`;
