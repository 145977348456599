/// <reference path="../../../typings/browser.d.ts" />

import IInterpolateService = angular.IInterpolateService;
import ILocationService = angular.ILocationService;

export interface TabNavEntry {
    label: () => string;
    path: string;
}

export class TabNavCtrl {
    static SID = 'tabNavCtrl';

    // From directive attributes
    private entries!: TabNavEntry[];

    private readonly params?: { [key: string]: string }; // Technically the value could be "any"

    static readonly $inject: string[] = ['$interpolate', '$location'];

    constructor(private $interpolate: IInterpolateService, private $location: ILocationService) {}

    public $onInit() {
        this.entries = this.rectifyEntries(this.entries);
    }

    public isActive(entry: TabNavEntry): boolean {
        return entry.path === this.$location.path();
    }

    private rectifyEntries(input: TabNavEntry[]): TabNavEntry[] {
        const output = [];
        for (const entry of input) {
            output.push({
                label: entry.label,
                path: this.$interpolate(entry.path)(this.params),
            });
        }
        return output;
    }
}

export const dsTabNavSID = 'dsTabNav';
export const dsTabNavConfig: ng.IDirective<ng.IScope> = {
    restrict: 'E',
    scope: {},
    templateUrl: '/partials/common/dsTabNav',
    controller: TabNavCtrl,
    controllerAs: 'ctrl',
    bindToController: {
        entries: '=',
        params: '=?',
    },
};

angular.module('app').directive(dsTabNavSID, [() => dsTabNavConfig]);
