import { gql } from '@apollo/client';

export const SCHEDULED_PUBLISH_FRAGMENT_FOR_BLOCKED_PUBLISHES_TABLE = gql`
    fragment ScheduledPublishFragmentForBlockedPublishesTable on ScheduledPublish {
        id
        content {
            ... on ScheduledPublishEmailContent {
                id
                subject
                htmlDownloadUrl
                recipientListDownloadUrl
            }
            ... on ScheduledPublishContentError {
                code
                message
            }
        }
        builderTemplate {
            id
            legacyId
        }
        location {
            id
            title
            client {
                id
                title
            }
        }
        debugSchedulingStatusMessage
        debugStatusMessage
        lastUpdatedAt
        schedulingStatus
        schedulingStatusError
        status
        targetScheduledTime
    }
`;
