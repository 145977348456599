import { noop } from '@deltasierra/object-utilities';
import {
    Agency,
    AgencyApi,
    AgencyId,
    AgencySpecialRequestDisclaimer,
    emptyRequest,
    justBody,
    justParams,
    NewAgencyDto,
    paramsAndBody,
    UpdateAgencyDto,
} from '@deltasierra/shared';
import { $httpSID } from '../common/angularData';
import { invokeApiRoute } from '../common/httpUtils';

import IPromise = angular.IPromise;

export class AgencyApiClient {
    static SID = 'AgencyApiClient';

    static readonly $inject: string[] = [$httpSID];

    constructor(protected readonly $http: ng.IHttpService) {}

    createAgency(newAgencyData: NewAgencyDto): IPromise<Agency> {
        return invokeApiRoute(this.$http, AgencyApi.createAgency, justBody(newAgencyData));
    }

    getAgency(agencyId: AgencyId): IPromise<Agency> {
        return invokeApiRoute(
            this.$http,
            AgencyApi.getAgency,
            justParams({
                agencyId,
            }),
        );
    }

    getHasGoogleAdWordsMarketingContact(): IPromise<{ result: boolean }> {
        return invokeApiRoute(this.$http, AgencyApi.getHasGoogleAdWordsMarketingContact, emptyRequest());
    }

    getSpecialRequestDisclaimer(): IPromise<AgencySpecialRequestDisclaimer> {
        return invokeApiRoute(this.$http, AgencyApi.getSpecialRequestDisclaimer, emptyRequest());
    }

    getAgencies(): IPromise<Agency[]> {
        return invokeApiRoute(this.$http, AgencyApi.getAgencies, emptyRequest());
    }

    updateAgency(agencyId: AgencyId, agencyData: UpdateAgencyDto): IPromise<void> {
        return invokeApiRoute(
            this.$http,
            AgencyApi.updateAgency,
            paramsAndBody(
                {
                    agencyId,
                },
                agencyData,
            ),
        ).then(noop);
    }

    deleteAgency(agencyId: AgencyId): IPromise<void> {
        return invokeApiRoute(
            this.$http,
            AgencyApi.deleteAgency,
            justParams({
                agencyId,
            }),
        ).then(noop);
    }
}

angular.module('app').service(AgencyApiClient.SID, AgencyApiClient);
