/// <reference path="../../_references.d.ts" />
import { ReportTableController } from './controller';

export const dsReportTableSID = 'dsReportTable';
export const dsReportTableConfig = {
    templateUrl: '/partials/reports/reportTable/template',
    restrict: 'E',
    replace: true,
    scope: {
        reportTable: '<',
    },
    controller: ReportTableController,
    controllerAs: 'ctrl',
    bindToController: true,
};

angular.module('app').directive(dsReportTableSID, [() => dsReportTableConfig]);
