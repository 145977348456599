/// <reference path="../../../typings/browser.d.ts" />
import {
    FileOrBlob,
    FinalizeThumbnailResponse,
    SanitizedFileName,
    sanitizeFileNameForUpload,
    SUPPORTED_VIDEO_EXTENSIONS,
    Untyped,
    Upload,
    UploadCategory,
    UploadId,
    UploadThumbnail,
} from '@deltasierra/shared';
import * as Sentry from '@sentry/browser';
import { FileUtils } from '../common/fileUtils';
import { ImageLoaderService } from '../contentBuilder/imageLoaderService';
import { I18nService } from '../i18n/i18nService';
import { $qSID } from './angularData';
import { MvNotifier } from './mvNotifier';
import { UploadApiClient } from './uploadApiClient';
import IPromise = angular.IPromise;
import IQService = angular.IQService;
import IDeferred = angular.IDeferred;

const ONE_GB_IN_BYTES = 1024 ** 3;
const MAX_FILE_SIZE_IN_GB = 20;
const MAX_FILE_SIZE_IN_BYTES = MAX_FILE_SIZE_IN_GB * ONE_GB_IN_BYTES;

export class FileTooLargeError extends Error {
    public constructor(file: Blob | File) {
        const fileName = (file as Untyped).name;
        super(
            `File ${
                fileName ? `"${fileName}" ` : ''
            }is too large to upload. The max allowed upload file size is ${MAX_FILE_SIZE_IN_GB} GB`,
        );
    }
}

interface UploadInitData {
    upload: Upload;
    signedUrl: string;
}

export class UploadContext {
    public uploadInProgress = false;

    public currentlyUploading: FileContext[] = [];

    public totalUploads = 0;

    public completedUploads = 0;
}

export class FileContext {
    public progress = 0;

    public promise: IPromise<Upload> | null = null;

    public constructor(public name: string) {}
}

export interface UploadScope {
    uploadContext?: UploadContext;
}

export type UploadMap = { [key: string]: Upload };

export interface UploadOptions {
    suppressNotifications?: boolean;
}

export class UploadService {
    public static readonly SID = 'uploadService';

    public static readonly $inject: string[] = [
        $qSID,
        MvNotifier.SID,
        FileUtils.SID,
        ImageLoaderService.SID,
        I18nService.SID,
        UploadApiClient.SID,
    ];

    public readonly MAX_TRIES = 1;

    public readonly EXTENSION_MAP: { [key: string]: string } = {
        'image/gif': 'gif',
        'image/jpeg': 'jpg',
        'image/jpg': 'jpg',
        'image/png': 'png',
        'text/html': 'html',
        'video/m4v': 'm4v',
        'video/mp4': 'mp4',
        'video/quicktime': 'mov',
    };


    public constructor(
        private readonly $q: IQService,
        private readonly mvNotifier: MvNotifier,
        private readonly fileUtils: FileUtils,
        private readonly imageLoaderService: ImageLoaderService,
        private readonly i18nService: I18nService,
        private readonly uploadApiClient: UploadApiClient,
    ) {}

    public guessExtension(fileBlob: Blob): string {
        let extension = this.EXTENSION_MAP[fileBlob.type];
        if (!extension) {
            extension = 'ext';
        }
        return extension;
    }

    public isSupportedVideo(file: Blob | File): boolean {
        const ext = this.guessExtension(file);
        if (ext === 'ext') {
            return false;
        }

        return this.isSupportedVideoExt(ext);
    }

    public isSupportedVideoExt(ext: string): boolean {
        return SUPPORTED_VIDEO_EXTENSIONS.indexOf(ext) !== -1;
    }

    public checkFileSize(file: FileOrBlob): ng.IPromise<void> {
        if (file.size > MAX_FILE_SIZE_IN_BYTES) {
            return this.$q.reject(new FileTooLargeError(file));
        } else {
            return this.$q.resolve();
        }
    }

    public getUploadThumbnail(uploadId: UploadId, key: string): IPromise<UploadThumbnail> {
        return this.uploadApiClient.getUploadThumbnail(uploadId, key);
    }

    public upload(
        files: FileOrBlob[],
        uploadType: UploadCategory,
        outputArray: Upload[],
        scope: UploadScope,
        options: UploadOptions = {},
    ): Array<IPromise<Upload>> {
        if (!scope.uploadContext) {
            scope.uploadContext = new UploadContext();
        }
        const result = this.performUploadForArray(scope.uploadContext, uploadType, files, outputArray, options);

        void this.$q.all(result).finally(() => {
            scope.uploadContext!.uploadInProgress = scope.uploadContext!.currentlyUploading.length > 0;
        });

        return result;
    }

    public uploadMap(
        fileMap: { [key: string]: FileOrBlob },
        uploadType: UploadCategory,
        scope: UploadScope,
        options: UploadOptions = {},
    ): IPromise<UploadMap> {
        if (!scope.uploadContext) {
            scope.uploadContext = new UploadContext();
        }
        const outputMap = {};
        return this.$q
            .all(this.performUploadForMap(scope.uploadContext, uploadType, fileMap, outputMap, options))
            .then(() => outputMap);
    }

    public uploadThumbnail(
        upload: Upload,
        file: FileOrBlob,
        uploadType: UploadCategory,
        scope: UploadScope,
        options: UploadOptions = {},
    ): IPromise<FinalizeThumbnailResponse> {
        return this.$q
            .all<Upload>(this.upload([file], uploadType, [], scope, options))
            .then(result => this.finalizeThumbnailUpload(upload, result[0]));
    }

    public getUploadGlyph(ext: string): string {
        const extension = ext.toLowerCase();
        if (extension === 'mov' || extension === 'mp4') {
            return 'glyphicon-facetime-video';
        } else if (extension === 'pdf') {
            return 'glyphicon-file';
        } else if (extension === 'doc' || extension === 'docx') {
            return 'glyphicon-file';
        } else {
            return 'glyphicon-paperclip';
        }
    }

    private getImageDimensions(file: FileOrBlob): IPromise<{ width: number; height: number }> {
        return this.imageLoaderService.getFileDimensions(file);
    }

    private removeFileFromUploadContext(fileContext: FileContext, uploadContext: UploadContext): void {
        const position = $.inArray(fileContext, uploadContext.currentlyUploading);
        if (position > -1) {
            uploadContext.currentlyUploading.splice(position, 1);
        }
        if (uploadContext.currentlyUploading.length === 0) {
            uploadContext.uploadInProgress = false;
        }

        uploadContext.completedUploads++;
    }

    private initializeUpload(
        category: UploadCategory,
        file: FileOrBlob,
        fileName: SanitizedFileName,
    ): IPromise<UploadInitData> {
        const payload = {
            category,
            fileName,
            fileType: file.type,
            height: null as number | null,
            size: file.size,
            width: null as number | null,
        };
        const promises = [];
        if (this.fileUtils.isImageMimeType(file.type)) {
            const promise = this.getImageDimensions(file).then(dimensions => {
                payload.width = dimensions.width;
                payload.height = dimensions.height;
            });
            promises.push(promise);
        }
        return this.$q.all(promises).then(() => this.uploadApiClient.initUpload(payload));
    }

    private finalizeThumbnailUpload(upload: Upload, thumbnail: Upload): IPromise<FinalizeThumbnailResponse> {
        const payload = {
            thumbnailId: thumbnail.id,
            uploadId: upload.id,
        };

        return this.uploadApiClient.finalizeThumbnail(payload);
    }

    private tryUpload(
        signedUrl: string,
        file: FileOrBlob,
        filename: SanitizedFileName,
        deferred: IDeferred<string>,
        tries: number,
    ) {
        // TODO: Refactor to use similar logic as in uploadUtils.uploadToS3
        const xhr = new XMLHttpRequest();
        xhr.open('PUT', signedUrl);
        xhr.setRequestHeader('Content-Disposition', `filename="${filename}"`);
        // DS-2224, DS-2469: IE11 needs this header for uploads, but giving an empty value breaks fonts in Chrome.
        if (file.type) {
            xhr.setRequestHeader('Content-Type', file.type);
        }
        const handleResponse = () => {
            if (xhr.status === 200) {
                return deferred.resolve(xhr.responseText);
            } else if (tries < this.MAX_TRIES - 1) {
                const msg = xhr.responseText || xhr.status || 'Upload failed';

                console.log(`Upload failed, retrying: ${msg}`);
                return this.tryUpload(signedUrl, file, filename, deferred, tries + 1);
            } else {
                Sentry.captureException(xhr.responseText);
                const response = xhr.responseText || xhr.status || 'Upload failed';

                console.log(`Upload failed: ${response}`);
                return deferred.reject(response);
            }
        };
        xhr.onload = handleResponse;
        xhr.onerror = handleResponse;

        xhr.upload.onprogress = deferred.notify;
        xhr.send(file);
    }

    private uploadFile(signedUrl: string, file: FileOrBlob, filename: SanitizedFileName): IPromise<string> {
        const deferred = this.$q.defer<string>();
        const tries = 0;
        this.tryUpload(signedUrl, file, filename, deferred, tries);

        return deferred.promise;
    }

    private notifyServerOnSuccess(uploadData: Upload): IPromise<Upload> {
        return this.uploadApiClient.uploadSuccess(uploadData.id);
    }

    private notifyServerOnFailure(uploadData: Upload): IPromise<Upload> {
        return this.uploadApiClient.uploadFailure(uploadData.id);
    }

    private generateFileName(fileBlob: Blob, i: number): string {
        return `File ${i + 1}.${this.guessExtension(fileBlob)}`;
    }


    private processAnUpload(
        uploadContext: UploadContext,
        uploadType: UploadCategory,
        outputArray: Upload[],
        file: FileOrBlob,
        i: number,
        options: UploadOptions,
    ): IPromise<Upload> {
        return this.checkFileSize(file).then(
            () => {
                const fileName = sanitizeFileNameForUpload(file.name ? file.name : this.generateFileName(file, i));

                const fileContext = new FileContext(fileName);
                uploadContext.currentlyUploading.push(fileContext);
                uploadContext.totalUploads++;

                const promise = this.initializeUpload(uploadType, file, fileName).then(initData => {
                    const uploadData = initData.upload;
                    const signedUrl = initData.signedUrl;
                    return this.uploadFile(signedUrl, file, fileName).then(
                        () =>
                            this.notifyServerOnSuccess(uploadData).then(uploadData1 => {
                                this.removeFileFromUploadContext(fileContext, uploadContext);
                                outputArray.push(uploadData1);
                                if (!options.suppressNotifications) {
                                    this.mvNotifier.notify(this.i18nService.text.common.fileUploaded());
                                }
                                return uploadData1;
                            }),
                        err =>
                            this.notifyServerOnFailure(uploadData).then(() => {
                                this.removeFileFromUploadContext(fileContext, uploadContext);
                                throw err;
                            }),
                        evt => {
                            fileContext.progress = Math.floor((evt.loaded / evt.total) * 100);
                        },
                    );
                });
                /* eslint-enable promise/no-nesting */

                fileContext.promise = promise;

                return promise;
            },
            err => {
                uploadContext.uploadInProgress = uploadContext.currentlyUploading.length > 0;
                throw err;
            },
        );
    }

    private performUploadForArray(
        uploadContext: UploadContext,
        uploadType: UploadCategory,
        files: FileOrBlob[],
        outputArray: Upload[],
        options: UploadOptions,
    ): Array<IPromise<Upload>> {
        uploadContext.uploadInProgress = true;
        const promises = [];
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const promise = this.processAnUpload(uploadContext, uploadType, outputArray, file, i, options);
            promises.push(promise);
        }
        return promises;
    }


    private async processAnUploadForMap(
        uploadContext: UploadContext,
        uploadType: UploadCategory,
        dummyOutputArray: Upload[],
        file: FileOrBlob,
        outputMap: UploadMap,
        key: string,
        i: number,
        options: UploadOptions,
    ) {
        const res = await this.processAnUpload(uploadContext, uploadType, dummyOutputArray, file, i, options);
        outputMap[key] = res;
        return res;
    }

    private performUploadForMap(
        uploadContext: UploadContext,
        uploadType: UploadCategory,
        fileMap: { [p: string]: FileOrBlob },
        outputMap: UploadMap,
        options: UploadOptions,
    ) {
        uploadContext.uploadInProgress = true;
        const dummyOutputArray: Upload[] = []; // TODO: remove the need for this
        const promises = [];
        let i = 0;
        for (const key in fileMap) {
            if (Object.prototype.hasOwnProperty.call(fileMap, key)) {
                const file = fileMap[key];
                const promise = this.processAnUploadForMap(
                    uploadContext,
                    uploadType,
                    dummyOutputArray,
                    file,
                    outputMap,
                    key,
                    i,
                    options,
                );
                promises.push(promise);
                i++;
            }
        }
        return promises;
    }
}

angular.module('app').service(UploadService.SID, UploadService);
