import { DSTypography, Translate } from '@deltasierra/components';
import { AssignedLocation, ExportPlatforms, LocationIdHierarchy } from '@deltasierra/shared';
import * as React from 'react';
import { OneWayBinding } from '../../../../common/angularData';
import { withAngularIntegration } from '../../../../common/componentUtils/reactComponentRegistration';
import { useFacebookImagePublishCallback } from '../../facebook/useFacebookImagePublishCallback';
import { useInstagramImagePublishCallback } from '../../instagramDirect/useInstagramImagePublishCallback';
import { MultipleLocationMultiImagePublish } from '../../multipleLocationPublish/MultipleLocationMultiImagePublish';
import { MvContentBuilderPublishCtrl } from '../../mvContentBuilderPublishCtrl';
import { PublishResult } from '../../publishResult';
import { PublishFinishCallback } from '../../types';
import { FacebookAndInstagramData } from './facebook-and-instagram-data';
import { PublishToFacebookAndInstagramDirectForm } from './PublishToFacebookAndInstagramDirectForm';

export type PublishImageToFacebookAndInstagramDirectProps = {
    location: AssignedLocation;
    locations: LocationIdHierarchy[];
    onCancel: () => void;
    onFinish: (data: PublishResult[]) => void;
    publishController: MvContentBuilderPublishCtrl;
};

function PublishImageToFacebookAndInstagramDirect({
    ...props
}: PublishImageToFacebookAndInstagramDirectProps): JSX.Element {
    const { location, locations, onCancel, onFinish, publishController } = props;
    /** Holds overall publish state */
    const [isPublishing, setIsPublishing] = React.useState(false);
    const [isFacebookPublishing, setIsFacebookPublishing] = React.useState(false);
    const [isInstagramPublishing, setIsInstagramPublishing] = React.useState(false);
    const [publishData, setPublishData] = React.useState<FacebookAndInstagramData | null>(null);
    const [publishResults, setPublishResults] = React.useState<PublishResult[]>([]);

    const instagramImagePublishCallback = useInstagramImagePublishCallback(publishController);
    const facebookImagePublishCallback = useFacebookImagePublishCallback(publishController);

    const onPublish = (data: FacebookAndInstagramData) => {
        setPublishData(data);
        setIsPublishing(true);
        setIsFacebookPublishing(true);
        setIsInstagramPublishing(true);
    };

    const instagramFinishCallback: PublishFinishCallback = () => {
        const scheduledTime = (publishData && publishData.instagram.scheduledTime) || null;
        setPublishResults(existing =>
            existing.concat(new PublishResult(ExportPlatforms.InstagramDirect, scheduledTime)),
        );
        setIsInstagramPublishing(false);
    };

    const facebookFinishCallback: PublishFinishCallback = () => {
        const scheduledTime = (publishData && publishData.facebook.scheduledTime) || null;
        setPublishResults(existing => existing.concat(new PublishResult(ExportPlatforms.Facebook, scheduledTime)));
        setIsFacebookPublishing(false);
    };

    React.useEffect(() => {
        if (publishResults.length === 2) {
            onFinish(publishResults);
        }
    }, [publishResults, onFinish]);

    return (
        <>
            {!isPublishing && (
                <PublishToFacebookAndInstagramDirectForm {...props} publishType="image" onPublish={onPublish} />
            )}
            {isPublishing && publishData && (
                <>
                    <DSTypography component="label" variant="h6">
                        {isInstagramPublishing ? (
                            <Translate
                                keyId="BUILD.PUBLISH.MULTIPLE_PLATFORMS.PUBLISHING_TO_PLATFORM"
                                options={{ platform: 'Instagram' }}
                            />
                        ) : (
                            <Translate
                                keyId="BUILD.PUBLISH.MULTIPLE_PLATFORMS.PUBLISHED_TO_PLATFORM"
                                options={{ platform: 'Instagram' }}
                            />
                        )}
                    </DSTypography>
                    {/** Instagram */}
                    <MultipleLocationMultiImagePublish
                        commonData={publishData.instagram}
                        contentBuilder={props.publishController.contentBuilder}
                        locations={props.locations}
                        originalLocation={props.location}
                        publishCtrl={props.publishController}
                        publishMultiImageCallback={instagramImagePublishCallback}
                        onCancel={props.onCancel}
                        onFinish={instagramFinishCallback}
                    />
                    {/** Facebook */}
                    <DSTypography component="label" variant="h6">
                        {isFacebookPublishing ? (
                            <Translate
                                keyId="BUILD.PUBLISH.MULTIPLE_PLATFORMS.PUBLISHING_TO_PLATFORM"
                                options={{ platform: 'Facebook' }}
                            />
                        ) : (
                            <Translate
                                keyId="BUILD.PUBLISH.MULTIPLE_PLATFORMS.PUBLISHED_TO_PLATFORM"
                                options={{ platform: 'Facebook' }}
                            />
                        )}
                    </DSTypography>
                    <MultipleLocationMultiImagePublish
                        commonData={publishData.facebook}
                        contentBuilder={publishController.contentBuilder}
                        locations={locations}
                        originalLocation={location}
                        publishCtrl={publishController}
                        publishMultiImageCallback={facebookImagePublishCallback}
                        onCancel={onCancel}
                        onFinish={facebookFinishCallback}
                    />
                </>
            )}
        </>
    );
}
PublishImageToFacebookAndInstagramDirect.displayName = 'PublishImageToFacebookAndInstagramDirect';

export default withAngularIntegration(
    PublishImageToFacebookAndInstagramDirect,
    'publishImageToFacebookAndInstagramDirect',
    {
        location: OneWayBinding,
        locations: OneWayBinding,
        onCancel: OneWayBinding,
        onFinish: OneWayBinding,
        publishController: OneWayBinding,
    },
);
