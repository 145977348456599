import {
    DSTable,
    DSTableBodyLoadable,
    DSTableCell,
    DSTableContainer,
    DSTableHead,
    DSTableRow,
    Translate,
} from '@deltasierra/components';
import React from 'react';
import { SCHEDULED_PUBLISH_FRAGMENT_FOR_FAILED_PUBLISHES_TABLE } from './FailedPublishesTable.fragments';
import { FailedPublishesTableRow } from './FailedPublishesTableRow';
import { ScheduledPublishFragmentForFailedPublishesTable } from './__graphqlTypes/ScheduledPublishFragmentForFailedPublishesTable';

type Props = {
    loading: boolean;
    rows: ScheduledPublishFragmentForFailedPublishesTable[];
};

export function FailedPublishesTable({ loading, rows }: Props): JSX.Element {
    return (
        <DSTableContainer>
            <DSTable size="small">
                <DSTableHead>
                    <DSTableRow>
                        <DSTableCell>
                            <Translate keyId="ADMIN.SERVICING.FAILED_PUBLISHES.TABLE.HEADERS.FAILED_AT" />
                        </DSTableCell>
                        <DSTableCell>
                            <Translate keyId="COMMON.CLIENT" />
                        </DSTableCell>
                        <DSTableCell>
                            <Translate keyId="COMMON.LOCATION" />
                        </DSTableCell>
                        <DSTableCell>
                            <Translate keyId="ADMIN.SERVICING.FAILED_PUBLISHES.TABLE.HEADERS.SCHEDULED_FOR" />
                        </DSTableCell>
                        <DSTableCell>
                            <Translate keyId="ADMIN.SERVICING.FAILED_PUBLISHES.TABLE.HEADERS.CONTENT" />
                        </DSTableCell>
                    </DSTableRow>
                </DSTableHead>
                <DSTableBodyLoadable disableInteraction={false} loading={loading}>
                    {rows.length > 0
                        ? rows.map(row => <FailedPublishesTableRow key={row.id} row={row} />)
                     : (
                        <DSTableRow>
                            <DSTableCell align="center" colSpan={9}>
                                <Translate keyId="ADMIN.SERVICING.FAILED_PUBLISHES.TABLE.NOT_FOUND_MESSAGE" />
                            </DSTableCell>
                        </DSTableRow>
                    )}
                </DSTableBodyLoadable>
            </DSTable>
        </DSTableContainer>
    );
}
FailedPublishesTable.displayName = 'FailedPublishesTable';
FailedPublishesTable.fragments = {
    SCHEDULED_PUBLISH_FRAGMENT_FOR_FAILED_PUBLISHES_TABLE,
};
