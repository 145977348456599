/// <reference path="../../../typings/browser.d.ts" />
import { ScheduledPost } from '@deltasierra/shared';
import IHttpService = angular.IHttpService;

export class ScheduledPostService {
    static SID = 'scheduledPostService';

    static readonly $inject: string[] = ['$http'];

    constructor(private $http: IHttpService) {}

    getPost(key: string): angular.IHttpPromise<ScheduledPost> {
        return this.$http.get<ScheduledPost>(`/api/scheduledPosts/${key}`);
    }
}

angular.module('app').service(ScheduledPostService.SID, ScheduledPostService);
