import styled from 'styled-components';

export const FlexColumnCenter = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 2em;
`;

export const AssetModalFormElementContainer = styled.div`
    padding: 0px 20px;
`;

export const BigIcon = styled.i`
    font-size: 100px;
    padding-bottom: 10px;
`;
