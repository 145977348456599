import { useDebouncedValue } from '@deltasierra/react-hooks';
import { t } from '@deltasierra/shared';
import * as React from 'react';
import * as Sentry from '@sentry/browser';
import { useAngularServiceContext } from '../componentUtils/angularServiceContexts';

export function useClipboard(notifyOnCopy = true): {
    copied: boolean;
    copyToClipboard: (newText: string, copiedItem: string) => void;
} {
    const notify = useAngularServiceContext('mvNotifier');
    const [copied, setCopied] = React.useState(false);
    const debouncedCopied = useDebouncedValue(copied, 3000);

    React.useEffect(() => {
        if (copied && debouncedCopied === copied) {
            setCopied(false);
        }
    }, [copied, debouncedCopied]);

    const clipboard = navigator.clipboard;
    return {
        copied: copied !== debouncedCopied,
        copyToClipboard: async (newText: string, copiedItem: string) => {
            try {
                // Check if the Clipboard API is available
                if (clipboard) {
                    await clipboard.writeText(newText);
                    if (notifyOnCopy) {
                        notify.success(t('COMMON.COPIED_TO_CLIPBOARD', { item: copiedItem }));
                        setCopied(true);
                    }
                } else {
                    console.error('Failed to copy to clipboard because clipboard API is null or undefined');
                    Sentry.captureException('Failed to copy to clipboard because clipboard API is null or undefined');
                    notify.expectedError(t('COMMON.COPIED_TO_CLIPBOARD_FAILED'));
                }
            } catch (error) {
                console.error(error);
                Sentry.captureException(error);
                notify.unexpectedError(t('COMMON.COPIED_TO_CLIPBOARD_FAILED'));
            }
        },
    };
}
