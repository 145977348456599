import { BuilderEmailDocument, Html, ImageSection, isImageSection } from '@deltasierra/shared';


import {
    $qSID,
    actualComponent,
    ExpressionBinding,
    ExpressionCallback,
    ILifecycleHooks,
    OneWayBinding,
} from '../../../common/angularData';
import {
    CampaignMonitorTemplateParserSID,
    ICampaignMonitorTemplateParser,
    ICampaignMonitorTemplateParserResult,
} from '../campaignMonitorTemplateParser';
import { MvNotifier } from '../../../common/mvNotifier';
import { I18nService } from '../../../i18n';
import { EmailBuilderImageProcessor } from '../emailBuilderImageProcessor';
import IQService = angular.IQService;

export class EmailEditSourceController implements ILifecycleHooks {
    // Inputs
    public readonly builderEmailDocument!: BuilderEmailDocument;

    public readonly htmlDocument!: HTMLDocument;

    public readonly onUpdateHtml!: ExpressionCallback<{ parseResult: ICampaignMonitorTemplateParserResult }>;

    public readonly onCancel!: ExpressionCallback<{}>;

    public html: Html = Html.from('');

    static $inject = [
        $qSID,
        I18nService.SID,
        MvNotifier.SID,
        CampaignMonitorTemplateParserSID,
        EmailBuilderImageProcessor.SID,
    ];

    constructor(
        protected readonly $q : IQService,
        protected readonly i18n : I18nService,
        protected readonly mvNotifier : MvNotifier,
        protected readonly campaignMonitorTemplateParser : ICampaignMonitorTemplateParser,
        protected readonly emailBuilderImageProcessor : EmailBuilderImageProcessor,
    ) {

    }

    $onInit() {
        return this.campaignMonitorTemplateParser.unprocessHtml(this.htmlDocument, this.builderEmailDocument)
            .then(html => this.html = html);
    }

    async onSubmitEditSource() {
        return this.campaignMonitorTemplateParser.processHtml(this.html)
            .then(parseResult => {
                // Loading from a zip does some funky stuff to work out image dimensions, let's partially replicate that here.
                const imageSections = parseResult.sections
                    .filter((section): section is ImageSection => isImageSection(section));
                const promises = [];
                for (const section of imageSections) {
                    const p = this.emailBuilderImageProcessor.updateImageDimensionsIfMissing(section);
                    promises.push(p);
                }
                return this.$q.all(promises)
                    .then(() => this.onUpdateHtml({
                        parseResult,
                    }));
            }).catch((err: any) => {
                this.mvNotifier.unexpectedErrorWithData(
                    this.i18n.text.build.email.failedToParseHtml(),
                    err,
                );
            });
    }

    onClickCancel() {
        this.onCancel({});
    }
}

export const emailEditSourceSID = 'emailEditSource';
export const emailEditSourceConfig = actualComponent(
    EmailEditSourceController,
    '/partials/contentBuilder/email/editSource/emailEditSource',
    {
        builderEmailDocument: OneWayBinding,
        htmlDocument: OneWayBinding,
        onUpdateHtml: ExpressionBinding,
        onCancel: ExpressionBinding,
    },
);

angular.module('app').component(emailEditSourceSID, emailEditSourceConfig);
