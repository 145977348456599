import * as React from 'react';
import styled from 'styled-components';
import { Theme, DSGrid } from '@deltasierra/components';
import { useLocalStorage, useEffectOnUpdateOnly } from '@deltasierra/react-hooks';
import {
    BannerNotification as BannerNotificationDataType,
    BannerNotificationType,
} from '@deltasierra/shared';
import { OneWayBinding } from '../../common/angularData';
import { withAngularIntegration } from '../../common/componentUtils/reactComponentRegistration';

export interface BannerNotificationWrappedProps {
    bannerNotification: BannerNotificationDataType | null;
    preview?: boolean;
}

export interface BannerNotificationProps {
    bannerNotification: BannerNotificationDataType;
    preview: boolean;
}

/* These are colors from the previous Angular banner implementation. We might want to move towards the DS light/dark theme colours */
function getBannerBackgroundColor(type: BannerNotificationType, theme: Theme) {
    switch (type) {
        case 'success':
            return '#dff0d8';
        case 'warning':
            return '#fcf8e3';
        case 'danger':
            return '#f2dede';
        case 'info':
        default:
            return '#d9edf7';
    }
}

function getBannerTextColor(type: BannerNotificationType) {
    switch (type) {
        case 'success':
            return '#3c763d';
        case 'warning':
            return '#8a6d3b';
        case 'danger':
            return '#a94442';
        case 'info':
        default:
            return '#31708f';
    }
}

const BannerContainer = styled(DSGrid)<{ type: BannerNotificationType }>`
    background-color: ${({ theme, type }) => getBannerBackgroundColor(type, theme)};
    color: ${({ type }) => getBannerTextColor(type)};
    display: flex;
    min-height: 50px;
    padding: 15px;
    text-align: center;
    width: 100%;
    a {
        color: ${({ type }) => getBannerTextColor(type)};
        text-decoration: underline;
    }
`;

const DismissButton = styled.button`
    background-color: transparent;
    border: 0;
`;

const NotificationMessageContainer = styled.div``;

export function BannerNotificationWrapped({
    bannerNotification,
    preview = false,
}: BannerNotificationWrappedProps): JSX.Element {
    return bannerNotification ? (
        <BannerNotification bannerNotification={bannerNotification} preview={preview} />
    ) : (
        <></>
    );
}
BannerNotificationWrapped.displayName = 'BannerNotificationWrapped';

export function BannerNotification({ bannerNotification, preview }: BannerNotificationProps): JSX.Element {
    const [hasDismissedBanner, setHasDismissedBanner] = useLocalStorage<boolean>(`banner-${bannerNotification.id}`, {
        initialValue: false,
    });

    // Always show banner preview - used in admin panel
    const [displayBanner, setDisplayBanner] = React.useState(preview ? true : !hasDismissedBanner);

    useEffectOnUpdateOnly(() => {
        if (!displayBanner) {
            setHasDismissedBanner(true);
        }
    }, [displayBanner, setHasDismissedBanner]);

    return displayBanner ? (
        <BannerContainer
            alignItems="center"
            container
            direction="row"
            justifyContent="center"
            type={bannerNotification.type}
        >
            <DSGrid item xs={11}>
                {/** The message field should be properly sanitized on the server-side. See bannerNotificationService.ts */}
                <NotificationMessageContainer dangerouslySetInnerHTML={{ __html: bannerNotification.message }} />
            </DSGrid>
            {bannerNotification.dismissable && (
                <DSGrid item xs={1}>
                    <DismissButton disabled={preview} onClick={() => setDisplayBanner(false)}>
                        <i
                            className="glyphicon glyphicon-remove"
                            style={{ color: getBannerTextColor(bannerNotification.type), cursor: 'pointer' }}
                        />
                    </DismissButton>
                </DSGrid>
            )}
        </BannerContainer>
    ) : (
        <></>
    );
}
BannerNotification.displayName = 'BannerNotification';

export const BannerNotificationAngular = withAngularIntegration(BannerNotificationWrapped, 'bannerNotification', {
    bannerNotification: OneWayBinding,
});
