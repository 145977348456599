import { LocationMapSale } from '@deltasierra/shared';
import moment from 'moment-timezone';
import { InteractionUtils } from '../../common/interactionUtils';
import { I18nService } from '../../i18n/i18nService';
import { Paging } from '../../common/paging';
import { AdminService } from '../adminService';

export class MapsReportController {
    public static SID = 'MapsReportController';

    public mapSales?: LocationMapSale[];

    public mapSalesVisible?: LocationMapSale[];

    public fetchData = this.interactionUtils.createFuture(this.i18n.text.common.fetchData(), () =>
        this.adminService.getMapsReport(),
    );

    public paging: Paging<LocationMapSale> = new Paging<LocationMapSale>({
        initialShowPerPage: 25,
    });

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public static readonly $inject: string[] = [InteractionUtils.SID, AdminService.SID, I18nService.SID];

    public constructor(
        private readonly interactionUtils: InteractionUtils,
        private readonly adminService: AdminService,
        private readonly i18n: I18nService,
    ) {
        void this.loadReport();
    }

    public sortReports(reportA: LocationMapSale, reportB: LocationMapSale): number {
        return moment(reportB.purchasedAt).diff(moment(reportA.purchasedAt));
    }

    public loadReport(): angular.IPromise<void> {
        return this.fetchData.run({}).then((reports: LocationMapSale[]) => {
            this.mapSales = reports.sort(this.sortReports.bind(this));
        });
    }
}

angular.module('app').controller(MapsReportController.SID, MapsReportController);
