import { MvMultipleLocationVideoPublishCtrl } from './videoPublishCtrl';

export const multipleLocationVideoPublishConfig : ng.IDirective<ng.IScope> = {
    restrict: 'E',
    scope: {},
    templateUrl: '/partials/contentBuilder/publish/multipleLocationPublish',
    controller: MvMultipleLocationVideoPublishCtrl,
    controllerAs: 'ctrl',
    bindToController: {
        publishCtrl: '=',
        contentBuilder: '=',
        originalLocation: '=',
        chosenLocations: '=',
        commonData: '=',
        publishCallback: '&',
        finishCallback: '&',
    },
};

export const multipleLocationVideoPublishSID = 'multipleLocationVideoPublish';

angular.module('app').directive(multipleLocationVideoPublishSID, [() => multipleLocationVideoPublishConfig]);
