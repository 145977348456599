import { DSTypography } from '@deltasierra/components';
import { ClubReady, GalleryPlannerDetails } from '@deltasierra/shared';
import * as React from 'react';
import { ExpressionBinding, OneWayBinding } from '../../../../common/angularData';
import { withAngularIntegration } from '../../../../common/componentUtils/reactComponentRegistration';
import { EmailPublishData } from '../../../email/emailPublishData';
import { PublishResult } from '../../../publish/publishResult';
import { MultiLocationLoader } from './components/MultiLocationLoader';
import { useMultiLocationLoader } from './hooks/useMultiLocationLoader';
import { PublishToClubReadySteps } from './PublishToClubReadySteps';

export type PublishToClubReadyProps = {
    plannerDetails: GalleryPlannerDetails | null;
    publishData: EmailPublishData;
    onCancel: () => void;
    onComplete: (publishResult: PublishResult) => void;
};

const PublishToClubReady: React.FC<PublishToClubReadyProps> = ({
    onCancel,
    onComplete,
    plannerDetails,
    publishData,
}) => {
    const { initialLocationProgressList, isPublishing, publish } = useMultiLocationLoader();

    return (
        <>
            {!isPublishing ? (
                <PublishToClubReadySteps
                    publishData={publishData}
                    scheduledDate={plannerDetails?.date}
                    startPublish={publish}
                    onCancel={onCancel}
                />
            ) : (
                <MultiLocationLoader
                    initialLocationProgressList={initialLocationProgressList}
                    submit={() => onComplete(new PublishResult(ClubReady, null))}
                >
                    <DSTypography variant="h1">{'Publishing to ClubReady'}</DSTypography>
                </MultiLocationLoader>
            )}
        </>
    );
};

PublishToClubReady.displayName = 'PublishToClubReady';

export default withAngularIntegration(PublishToClubReady, 'publishToClubReady', {
    onCancel: ExpressionBinding,
    onComplete: ExpressionBinding,
    plannerDetails: OneWayBinding,
    publishData: OneWayBinding,
});
