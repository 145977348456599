import { mergeMap } from 'rxjs/operators';
import { ApolloClient, gql } from '@apollo/client';
import { UploadId } from '@deltasierra/shared';
import { OperatorFunction, pipe } from 'rxjs';
import { UploadSuccess, UploadSuccessVariables } from './__graphqlTypes/UploadSuccess';
import { FileUploadError } from './common';

type ReportUploadSuccessfulContext = {
    client: ApolloClient<unknown>;
};

type ReportUploadSuccessfulInput = {
    file: File;
    legacyUploadId: UploadId;
    uploadId: string;
};

type ReportUploadSuccessfulOutput = ReportUploadSuccessfulInput;

export function reportUploadSuccessfulStep({
    client,
}: ReportUploadSuccessfulContext): OperatorFunction<ReportUploadSuccessfulInput, ReportUploadSuccessfulOutput> {
    return pipe(
        mergeMap(async ({ file, legacyUploadId, uploadId }) => {
            const results = await client.mutate<UploadSuccess, UploadSuccessVariables>({
                mutation: gql`
                    mutation UploadSuccess($uploadId: ID!) {
                        uploadSuccess(id: $uploadId) {
                            ... on Upload {
                                id
                            }
                            ... on UploadNotFoundError {
                                code
                                message
                            }
                        }
                    }
                `,
                variables: {
                    uploadId,
                },
            });

            if (!results.data) {
                throw new FileUploadError({ legacyUploadId, message: 'Missing response data', uploadId });
            } else if (results.data.uploadSuccess.__typename === 'UploadNotFoundError') {
                throw new FileUploadError({ legacyUploadId, message: 'File not found', uploadId });
            }
            return {
                file,
                legacyUploadId,
                uploadId,
            };
        }),
    );
}
