import { $modalInstanceSID, $modalSID } from '../../../../common/angularUIBootstrapData';
import { ModalInstance, ModalService } from '../../../../typings/angularUIBootstrap/modalService';
import { $scopeSID } from '../../../../common/angularData';

const TEN_MINUTES = 10 * 60 * 1000;

export interface PublishImmediatelyModalScope {
    cancel(): void;
    publishImmediately(): void;
}

export class PublishImmediatelyCheckService {
    public static readonly SID = 'publishImmediatelyCheckService';

    public static readonly $inject: string[] = [
        $modalSID,
    ];

    public constructor(private readonly $modal: ModalService) {
    }

    public checkTimeDifferenceAndOptionallyShowConfirmModal(
        scheduledTime: Date,
        scheduleImmediatelyCallback: () => void,
        scheduleCallback: () => void,
        timeCutoffInMilliseconds: number = TEN_MINUTES,
    ): void {
        const now = new Date().valueOf();
        const sTime = scheduledTime.valueOf();
        if (sTime && (sTime < now || sTime - now <= timeCutoffInMilliseconds)) {
            this.$modal.open({
                templateUrl: '/partials/contentBuilder/publish/common/publishImmediatelyCheck/template',
                backdrop: 'static',
                controller: [$scopeSID, $modalInstanceSID, ($scope: PublishImmediatelyModalScope, $modalInstance: ModalInstance) => {
                    $scope.cancel = () => {
                        $modalInstance.dismiss();
                    };

                    $scope.publishImmediately = () => {
                        $modalInstance.dismiss();
                        scheduleImmediatelyCallback();
                    };
                }],
            });
        } else {
            scheduleCallback();
        }
    }
}

angular.module('app').service(PublishImmediatelyCheckService.SID, PublishImmediatelyCheckService);
