import { LocationShippingDetails } from '@deltasierra/shared';
import { $scopeSID } from '../../common/angularData';
import { $modalInstanceSID } from '../../common/angularUIBootstrapData';
import { InteractionUtils } from '../../common/interactionUtils';
import { MvNotifier, mvNotifierSID } from '../../common/mvNotifier';
import { I18nService } from '../../i18n';
import { MvLocation } from '../../locations/mvLocation';
import { ModalInstance } from '../../typings/angularUIBootstrap/modalService';
import IScope = angular.IScope;

export interface LocationShippingDetailsLocals {
    details: () => LocationShippingDetails;
}

export class ShippingDetailsController {
    public static readonly $inject: string[] = [
        $scopeSID,
        $modalInstanceSID,
        mvNotifierSID,
        'details' as keyof LocationShippingDetailsLocals,
        InteractionUtils.SID,
        MvLocation.SID,
        I18nService.SID,
    ];

    public readonly updateShippingDetails = this.interactionUtils.createFuture(
        this.i18n.text.account.locationDetails.updateLocationDetails(),
        () =>
            this.mvLocation
                .updateLocationShippingDetails(this.details.id, {
                    shippingAttention: this.details.shippingAttention || null,
                    shippingCompanyName: this.details.shippingCompanyName || null,
                    shippingLineOne: this.details.shippingLineOne || null,
                    shippingLineThree: this.details.shippingLineThree || null,
                    shippingLineTwo: this.details.shippingLineTwo || null,
                })
                .then((result: LocationShippingDetails) => {
                    this.mvNotifier.success(this.i18n.text.account.locationDetails.shippingDetailsUpdated());
                    const updatedDetails: LocationShippingDetails = {
                        id: result.id,
                        shippingAttention: result.shippingAttention,
                        shippingCompanyName: result.shippingCompanyName,
                        shippingLineOne: result.shippingLineOne,
                        shippingLineThree: result.shippingLineThree,
                        shippingLineTwo: result.shippingLineTwo,
                        title: result.title,
                    };
                    this.$modalInstance.close(updatedDetails);
                }),
    );

    // eslint-disable-next-line max-params
    public constructor(
        private readonly $scope: IScope & { close: () => void },
        private readonly $modalInstance: ModalInstance,
        private readonly mvNotifier: MvNotifier,
        public readonly details: LocationShippingDetails,
        private readonly interactionUtils: InteractionUtils,
        private readonly mvLocation: MvLocation,
        private readonly i18n: I18nService,
    ) {
        this.$scope.close = () => this.close();
    }

    public close(): void {
        this.$modalInstance.dismiss();
    }
}
