import ChannelAdder = require('./activities/channel-adder');
import { AddClientLearnSubSectionCtrl } from './dsAddClientLearnSubSection';
import dsClientNav = require('./dsClientNav');
import mvClient = require('./mvClient');
import { ClientActivitiesController } from './activities';
import mvClientBuilderFontConfigResource = require('./mvClientBuilderFontConfigResource');
import mvClientDetailCtrl = require('./mvClientDetailCtrl');
import mvClientFontsCtrl = require('./mvClientFontsCtrl');
import mvClientListCtrl = require('./mvClientListCtrl');
import mvClientLocationCategoriesCtrl = require('./mvClientLocationCategoriesCtrl');
import mvClientResource = require('./mvClientResource');
import mvClientUserEditCtrl = require('./mvClientUserEditCtrl');
import { ClientLearnMenuCtrl } from './clientLearnMenuCtrl';
import { MvClientUserLocationsCtrl } from './mvClientUserLocationsCtrl';
import { MvClientUsersCtrl } from './mvClientUsersCtrl';
import { FontChooserService } from './fontChooser';
import { ClientExternalLinksController } from './externalLinks/controller';
import { ClientBrandColoursController } from './brandColours/controller';
import * as clientUserBreadcrumbs from './clientUserBreadcrumbs';
import { BuilderTemplateCategoriesController } from './builderTemplateCategories/builderTemplateCategoriesController';
import { LocationCategoryOptionApiClient } from './locationCategoryOptionApiClient';
import { EmailNotificationsForm } from './EmailNotificationsForm';
import ClientUsersPaginatedTable from './clientUsersTable/ClientUsersPaginatedTable';
import { ClientList } from './components/ClientList';
import { AddClientFormWell } from './components/AddClientFormWell';

const LocationCategoryOptionsApiClient = LocationCategoryOptionApiClient;

export {
    AddClientFormWell,
    AddClientLearnSubSectionCtrl,
    BuilderTemplateCategoriesController,
    ChannelAdder,
    ClientActivitiesController,
    ClientBrandColoursController,
    ClientExternalLinksController,
    ClientLearnMenuCtrl,
    ClientList,
    ClientUsersPaginatedTable,
    EmailNotificationsForm,
    FontChooserService,
    LocationCategoryOptionsApiClient,
    MvClientUserLocationsCtrl,
    MvClientUsersCtrl,
    clientUserBreadcrumbs,
    dsClientNav,
    mvClient,
    mvClientBuilderFontConfigResource,
    mvClientDetailCtrl,
    mvClientFontsCtrl,
    mvClientListCtrl,
    mvClientLocationCategoriesCtrl,
    mvClientResource,
    mvClientUserEditCtrl,
};
