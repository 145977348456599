import * as React from 'react';
import { gql } from '@apollo/client';
import { assertNever } from '@deltasierra/shared';
import { translateFileTypeToString, useAssetLibraryContext } from '../../context';
import { useAssetLibrarySettings, useCurrentAssetContext } from '../../contexts';
import { FileTypeIcon } from '../../../common/FileTypeIcon';
import { EditAssetsModal } from '../EditAssetsModal';
import { rectifyS3UrlWithFilename } from '../../../directives/dsDownloadLink';
import { AssetTableViewItemFragment } from './__graphqlTypes/AssetTableViewItemFragment';
import './AssetTableViewItem.scss';

const fragments = {
    AssetTableViewItemFragment: gql`
        fragment AssetTableViewItemFragment on AssetFileOrFolder {
            __typename
            ... on AssetFolder {
                id
                title
                selected @client
                deselected @client
                expires
                created
            }
            ... on AssetFile {
                id
                title
                url
                selected @client
                deselected @client
                expires
                created
                size
                type
                mimeType
                thumbnails {
                    medium {
                        url
                        width
                        height
                    }
                }
                ...EditAssetsModalFragment
            }
        }
        ${EditAssetsModal.fragments.EditAssetsModalFragment}
    `,
};

export type AssetTableViewItemProps = {
    asset: AssetTableViewItemFragment;
    hideButtons?: boolean;
    onClickPreview?: () => void;
};

/* eslint-disable max-lines-per-function */
export const AssetTableViewItem: React.FC<AssetTableViewItemProps> & { fragments: typeof fragments } = ({
    asset,
    hideButtons,
    onClickPreview,
}) => {
    const dateFormatOptions: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'short', year: 'numeric' };
    const [showEditTagModal, setShowEditTagModal] = React.useState(false);
    const { toggleAssetSelected } = useAssetLibraryContext();
    const [, setCurrentCollectionOrAssetId] = useCurrentAssetContext();
    const { removeAnchorOnFolderIcon } = useAssetLibrarySettings();

    const onDoubleClick = () => {
        if (asset.__typename === 'AssetFolder') {
            setCurrentCollectionOrAssetId(asset.id);
        } else if (asset.__typename === 'AssetFile') {
            onClickPreview?.();
        } else {
            throw assertNever(asset);
        }
    };

    const onSingleClick = () => {
        if (asset.__typename === 'AssetFolder') {
            toggleAssetSelected?.({ id: asset.id, type: 'folder' });
        } else {
            toggleAssetSelected?.({ id: asset.id, type: translateFileTypeToString(asset.type) });
        }
    };

    const onClick: React.MouseEventHandler = (event: React.MouseEvent) => {
        switch (event.detail) {
            case 1:
                onSingleClick();
                break;
            case 2:
                onDoubleClick();
                break;
            case 3:
                onDoubleClick();
                break;
            default:
                break;
        }
    };

    let displayStyle = 'normal';
    if (asset.selected) {
        displayStyle = 'selected';
    } else if (asset.deselected) {
        displayStyle = 'deselected';
    }
    return (
        <tr className={`asset-list-item ${displayStyle}`} onClick={onClick}>
            <td className="asset-list-item-clickable" style={{ maxWidth: 50, width: 50 }}>
                {asset.__typename === 'AssetFolder' && <div className="al-folder-thumbnail-icon" />}
                {asset.__typename === 'AssetFile' && asset.thumbnails && (
                    <img
                        height={30}
                        src={asset.thumbnails.medium.url}
                        style={{ height: '30px', objectFit: 'contain', width: '30px' }}
                        width={30}
                    />
                )}
                {asset.__typename === 'AssetFile' && !asset.thumbnails && (
                    <FileTypeIcon alt={asset.title} height={30} mimeType={asset.mimeType} style={{ padding: '5px' }} />
                )}
            </td>
            <td className="asset-list-item-clickable" style={{ maxWidth: 240, padding: 0, width: 240 }}>
                <span className="asset-list-item-name">
                    <span title={asset.title}>{asset.title}</span>
                </span>
            </td>
            <td className="asset-list-item-date asset-list-item-clickable">
                {new Date(asset.created).toLocaleDateString(undefined, dateFormatOptions)}
            </td>
            <td className="asset-list-item-date asset-list-item-clickable">
                {asset.expires ? new Date(asset.expires).toLocaleDateString(undefined, dateFormatOptions) : '-'}
            </td>
            <td className="asset-list-item-fileSize asset-list-item-clickable">
                {asset.__typename === 'AssetFile' && `${Math.round(asset.size / 1000)} kB`}
            </td>
            <td style={{ whiteSpace: 'nowrap' }}>
                {(asset.__typename === 'AssetFile' && (
                    <>
                        {!hideButtons && (
                            <>
                                <i
                                    className="asset-list-item-icon glyphicon glyphicon-edit text-primary"
                                    onClick={() => setShowEditTagModal(true)}
                                />
                                <EditAssetsModal
                                    assets={[asset]}
                                    show={showEditTagModal}
                                    onClose={() => setShowEditTagModal(false)}
                                />
                                <i
                                    className="asset-list-item-icon glyphicon glyphicon-download-alt text-primary"
                                    onClick={() => window.open(rectifyS3UrlWithFilename(asset.url, asset.title))}
                                />
                            </>
                        )}
                        <i
                            className="asset-list-item-icon glyphicon glyphicon-search text-primary"
                            onClick={() => onClickPreview?.()}
                        />
                    </>
                )) ||
                    (asset.__typename === 'AssetFolder' && (
                        <>
                            {removeAnchorOnFolderIcon ? (
                                <i
                                    className="asset-list-item-icon glyphicon glyphicon-folder-open text-primary"
                                    // TODO: write Cypress e2e test
                                    data-cy="open-folder-button"
                                    onClick={() => onDoubleClick()}
                                />
                            ) : (
                                <a
                                    data-cy="open-folder-button"
                                    href={`/assets/view?collection=${asset.id}`}
                                >
                                    <i
                                        className="asset-list-item-icon glyphicon glyphicon-folder-open text-primary"
                                    />
                                </a>
                            )}
                        </>
                    ))}
            </td>
        </tr>
    );
};
/* eslint-enable max-lines-per-function */
AssetTableViewItem.displayName = 'AssetTableViewItem';
AssetTableViewItem.fragments = fragments;
