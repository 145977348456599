import {
    DSListItem,
    DSListItemSecondaryAction,
    DSListItemText,
    DSTextButton,
    Tooltip,
    Translate,
} from '@deltasierra/components';
import CheckIcon from '@material-ui/icons/Check';
import * as React from 'react';
import styled from 'styled-components';
import { HASHTAGS_SELECT_ITEM_FRAGMENT_FOR_HASHTAG_SET } from './HashtagsSelectItem.fragments';
import { HashtagSelectItemFragmentForHashtagSet } from './__graphqlTypes/HashtagSelectItemFragmentForHashtagSet';

const StyledDSListItem: typeof DSListItem = styled(DSListItem)`
    margin-top: ${props => props.theme.spacing(0.5)}px;
    margin-bottom: ${props => props.theme.spacing(0.5)}px;
    padding-top: 0;
    padding-bottom: 0;
`;

const StyledListItemText = styled(DSListItemText)<{ $error?: boolean }>`
    line-height: 24px;
    color: ${props => (props.$error ? props.theme.palette.error.main : props.theme.typography.body1.color ?? 'black')};
    padding-left: ${props => props.theme.spacing(2)}px;
    padding-right: ${props => props.theme.spacing(2)}px;
`;

const StyledListItemSecondaryAction = styled(DSListItemSecondaryAction)`
    color: ${props => props.theme.palette.primary.main};
    &:hover {
        cursor: pointer;
    }
`;

type HashtagsSelectItemTooltipProps = {
    children: React.ReactNode;
    disabled?: boolean;
    existing?: boolean;
};

function HashtagsSelectItemTooltip({ children, disabled, existing }: HashtagsSelectItemTooltipProps): JSX.Element {
    let tip: React.ReactNode = '';
    if (disabled && !existing) {
        tip = <Translate keyId="BUILD.PUBLISH.HASHTAGS.ERROR_LIMIT_HASHTAG_SET" />;
    } else if (existing) {
        tip = <Translate keyId="BUILD.PUBLISH.HASHTAGS.HASHTAG_SET_ALREADY_ADDED" />;
    }
    return (
        <Tooltip tip={tip}>
            <span>{children}</span>
        </Tooltip>
    );
}
HashtagsSelectItemTooltip.displayName = 'HashtagsSelectItemTooltip';

export type HashtagsSelectItemProps = {
    hashtagSet: HashtagSelectItemFragmentForHashtagSet;
    onAdd: (hashtags: string[]) => void;
    disabled?: boolean;
    existing?: boolean;
};

export function HashtagsSelectItem({
    disabled = false,
    existing,
    hashtagSet,
    onAdd,
}: HashtagsSelectItemProps): JSX.Element {
    return (
        <StyledDSListItem button={false} disabled={disabled || existing}>
            <StyledListItemText $error={disabled && !existing}>
                <HashtagsSelectItemTooltip disabled={disabled} existing={existing}>
                    {`${hashtagSet.title} (${hashtagSet.hashtags.length})`}
                </HashtagsSelectItemTooltip>
            </StyledListItemText>
            <StyledListItemSecondaryAction>
                <HashtagsSelectItemTooltip disabled={disabled} existing={existing}>
                    <DSTextButton
                        color="info"
                        disabled={disabled || existing}
                        hideArrow
                        noPadding
                        onClick={() => onAdd(hashtagSet.hashtags.slice())}
                    >
                        {existing ? <CheckIcon /> : <Translate keyId="COMMON.ADD" />}
                    </DSTextButton>
                </HashtagsSelectItemTooltip>
            </StyledListItemSecondaryAction>
        </StyledDSListItem>
    );
}
HashtagsSelectItem.displayName = 'HashtagsSelectItem';
HashtagsSelectItem.fragments = { HASHTAGS_SELECT_ITEM_FRAGMENT_FOR_HASHTAG_SET };
