import { LocationId } from '@deltasierra/shared';
import * as React from 'react';
import { FacebookFormDescriptionSection } from '../../facebook/FacebookFormDescriptionSection';
import { InstagramDirectFormDescriptionSection } from '../../instagramDirect/InstagramDirectFormDescriptionSection';

export type FacebookAndInstagramFormDescriptionSectionProps = {
    locationIds: string[];
    oldLocationIds: LocationId[];
};

export function FacebookAndInstagramFormDescriptionSection({
    locationIds,
    oldLocationIds,
}: FacebookAndInstagramFormDescriptionSectionProps): JSX.Element {
    return (
        <>
            <FacebookFormDescriptionSection locationIds={locationIds} />
            <InstagramDirectFormDescriptionSection oldLocationIds={oldLocationIds} />
        </>
    );
}
FacebookAndInstagramFormDescriptionSection.displayName = 'FacebookAndInstagramFormDescriptionSection';
