import * as React from 'react';
import moment from 'moment-timezone';
import { Translate } from './Translate';

export type DisplayDateTimeInTimezoneProps = {
    timezone: string;
    date: Date | null;
};

export const DisplayDateTimeInTimezone: React.FunctionComponent<DisplayDateTimeInTimezoneProps> = ({
    date,
    timezone,
}) => (
    <p>
        <Translate keyId="BUILD.PUBLISH.RELATIVE_TO_LOCATION" />
        {` ${date ? displayDateAsRelativateToLocation(date, timezone) : '--'}`}
    </p>
);

function displayDateAsRelativateToLocation(date: Date, timezone: string) {
    const formattedDate = moment(date).tz(timezone).format('D MMM YYYY HH:mm:ss');
    return `(${timezone}): ${formattedDate}`;
}
