import {
    AssignedLocation,
    BlobWithData,
    BuilderDocument,
    BuilderTemplateId,
    ExportPlatforms,
    FileOrBlob,
    GalleryPlannerDetails,
    ISendToPrintProviderBody,
    isPrintDocument,
    LocationId,
    Nullable,
    PrintProvider,
    Result,
    resultFailure,
    resultSuccess,
    TextSubstitutionValues,
    Upload,
} from '@deltasierra/shared';
import { IPromise } from 'angular';
import { MvIdentity } from '../../../account/mvIdentity';
import { $qSID, ExpressionCallback } from '../../../common/angularData';
import { ConfirmModal, confirmModalSID } from '../../../common/confirmModal';
import { DataUtils } from '../../../common/dataUtils';
import { FileUtils } from '../../../common/fileUtils';
import { InteractionUtils } from '../../../common/interactionUtils';
import { MvNotifier } from '../../../common/mvNotifier';
import { UploadContext, UploadService } from '../../../common/uploadService';
import { I18nService } from '../../../i18n';
import { PrintProviderApiClient } from '../../../integration/auth/printProviderApiClient';
import { PrintPublishService } from '../../../integration/publish/printPublishService';
import { MvLocation } from '../../../locations/mvLocation';
import { CurrencyService } from '../../../payments/currencyService';
import { BuilderConstants, builderConstantsSID } from '../../builderConstants';
import { PdfBuilderService } from '../../print/pdfBuilderService';
import { FileFormatChoice } from '../mvContentBuilderFileFormatCtrl';
import { PublishResult, PublishResultOptions } from '../publishResult';
import { SKUDetails } from './sku/basePublishPrintSKUController';

export type BasePrintPublishViews = 'Publishing' | 'Review' | 'ReviewConfirmation';

export interface PublishImageFormat {
    name: string;
    fileExtension: string;
}

export interface PublishPrintRequestDetails {
    quantity: number;
    instructions: string | null;
    replyEmail: string;
    shippingAttention: string | null;
    shippingCompanyName: string | null;
    shippingLineOne: string | null;
    shippingLineTwo: string | null;
    shippingLineThree: string | null;
}

export interface GeneratedArtwork {
    blob: FileOrBlob;
    url: string;
}

export interface SendToPrintContext<TViews> {
    didReviewArtwork: boolean;
    documentPagesAmount: number;
    editableTextContent: string[];
    formatHeight: number;
    formatWidth: number;
    locationId: LocationId;
    plannerId: number | undefined;
    prePublishView: BasePrintPublishViews | TViews;
    requestDetails: PublishPrintRequestDetails;
    sku?: string | undefined;
    templateId: BuilderTemplateId;
}

export interface OtherPrintProvider {
    businessName: string;
    email: string;
    address?: string;
    phoneNumber?: string;
}

export interface PrintPublishData {
    fileFormatChoice: FileFormatChoice;
    location: AssignedLocation;
    plannerDetails?: GalleryPlannerDetails;
    templateId: BuilderTemplateId;
    builderDocument: BuilderDocument;
    textSubstitutionValues: TextSubstitutionValues;

    // Optionals for sub components
    exclusivePrintProvider?: PrintProvider | null;
    otherSelected?: boolean | null;
    otherPrintProvider?: OtherPrintProvider | null;
    hasSkuDetails?: boolean | null;
    hasExclusivePrintProvider?: boolean; // This will get set in $onInit
    saveOtherAsNew?: boolean;
    skuDetails?: SKUDetails | null;
    selectedPrintProvider?: PrintProvider | null;
}

export interface SendData<TViews> {
    pagesAmount: number;
    prePublishView: BasePrintPublishViews | TViews;
    requestDetails: PublishPrintRequestDetails;
}

export type NextViewMap<TViews extends string> = {
    [K in BasePrintPublishViews | TViews]: BasePrintPublishViews | TViews;
};

export abstract class BasePublishPrintController<TViews extends string> {
    public static readonly $inject: string[] = [
        $qSID,
        InteractionUtils.SID,
        DataUtils.SID,
        MvNotifier.SID,
        PrintProviderApiClient.SID,
        builderConstantsSID,
        MvIdentity.SID,
        PdfBuilderService.SID,
        confirmModalSID,
        FileUtils.SID,
        I18nService.SID,
        UploadService.SID,
        PrintPublishService.SID,
        CurrencyService.SID,
        MvLocation.SID,
    ];

    // Props (inputs)
    public publishData!: PrintPublishData;

    public generatePrintPdf!: ExpressionCallback<
        { exclusiveArtworkReview: boolean | null | undefined },
        ng.IPromise<PDFKit.PDFDocument>
    >;

    public generateImageFileName!: ExpressionCallback<{ imageFormat: PublishImageFormat }, string>;

    public onCancel!: ExpressionCallback<Record<string, unknown>>;

    public publishPublished!: ExpressionCallback<{ publishedResult: PublishResult }>;

    // State
    public view: BasePrintPublishViews | TViews = 'Review';

    public requestDetails!: Nullable<PublishPrintRequestDetails>;

    public saveOtherAsNew = false;

    public changeInShippingAddress = false;

    public didReviewArtwork = false;

    public generatedArtwork?: GeneratedArtwork;

    public uploadContext: UploadContext | undefined = undefined;

    // Futures
    public sendToPrint = this.interactionUtils.createFuture(
        'Send to print',
        async (context: SendToPrintContext<TViews>) => {
            try {
                await this.updateShippingAddress();
                const artwork = await this.getArtwork();
                const upload = await this.uploadArtwork(artwork);
                const provider = await this.getSelectedPrintProviderOrCreate();
                return await this.invokeServerSend(context, upload, provider);
            } catch (err) {
                // If an error occurs, go back to the form to allow the user to try again.
                this.returnToPrePublishView(context.prePublishView);
                throw err;
            }
        },
    );

    public createSpecifiedOtherProvider = this.interactionUtils.createFuture(
        'Create other provider',
        (context: { locationId: LocationId; otherPrintProvider: OtherPrintProvider; saveOtherAsNew: boolean }) => {
            const payload = {
                address: context.otherPrintProvider.address !== '' ? context.otherPrintProvider.address || null : null,
                businessName: context.otherPrintProvider.businessName,
                email: context.otherPrintProvider.email,
                phoneNumber:
                    context.otherPrintProvider.phoneNumber !== ''
                        ? context.otherPrintProvider.phoneNumber || null
                        : null,
            };
            if (context.saveOtherAsNew) {
                return this.printProviderApiClient.createLocationPrintProvider(context.locationId, payload);
            }
            return this.printProviderApiClient.createOnceOffPrintProvider(payload);
        },
    );

    // eslint-disable-next-line max-params
    public constructor(
        protected readonly $q: ng.IQService,
        protected readonly interactionUtils: InteractionUtils,
        protected readonly dataUtils: DataUtils,
        protected readonly mvNotifier: MvNotifier,
        protected readonly printProviderApiClient: PrintProviderApiClient,
        protected readonly builderConstants: BuilderConstants,
        protected readonly identity: MvIdentity,
        protected readonly pdfBuilderService: PdfBuilderService,
        protected readonly modal: ConfirmModal,
        protected readonly fileUtils: FileUtils,
        protected readonly i18n: I18nService,
        protected readonly uploadService: UploadService,
        protected readonly printPublishService: PrintPublishService,
        protected readonly currencyService: CurrencyService,
        protected readonly mvLocation: MvLocation,
        protected readonly nextViewMap: NextViewMap<BasePrintPublishViews | TViews>,
        protected readonly isCreatePrintProviderAllowed: boolean,
    ) {}

    public $onInit(): void {
        this.initialiseRequestDetails();
    }

    public async reviewArtwork(): Promise<void> {
        this.didReviewArtwork = true;
        await this.generatePdf(); // Always re-generate the artwork
        await this.downloadArtwork();
        return this.nextView();
    }

    public async secondaryReview(): Promise<void> {
        await this.generatePdf();
        await this.downloadArtwork();
        this.didReviewArtwork = true;
    }

    public async updateShippingAddress(): Promise<void> {
        if (this.changeInShippingAddress) {
            return this.mvLocation
                .updateShippingDetails(this.publishData.location.id, {
                    shippingAttention: this.requestDetails.shippingAttention || null,
                    shippingCompanyName: this.requestDetails.shippingCompanyName || null,
                    shippingLineOne: this.requestDetails.shippingLineOne || null,
                    shippingLineThree: this.requestDetails.shippingLineThree || null,
                    shippingLineTwo: this.requestDetails.shippingLineTwo || null,
                })
                .then(() => {
                    // Update the local Location data
                    this.publishData.location.shippingLineOne = this.requestDetails.shippingLineOne;
                    this.publishData.location.shippingLineTwo = this.requestDetails.shippingLineTwo;
                    this.publishData.location.shippingLineThree = this.requestDetails.shippingLineThree;
                });
        }
        return Promise.resolve();
    }

    public async onClickSendArtwork(): Promise<void> {
        const result = this.prepareToSend();
        if (result.success) {
            const sendData = result.value;
            this.preparePlatformData(sendData.requestDetails);
            await this.startSendProcess(sendData);
            return this.onSent();
        }
        return Promise.resolve();
    }

    public returnToPrePublishView(prePublishView: BasePrintPublishViews | TViews): void {
        if (this.view === 'Publishing') {
            this.setView(prePublishView);
        }
    }

    public getDocumentPagesAmount(): Result<Error, number> {
        if (isPrintDocument(this.publishData.builderDocument)) {
            return resultSuccess(this.publishData.builderDocument.pages.length);
        } else {
            return resultFailure(new Error(this.i18n.text.errors.badRequestErrors.invalidDocument()));
        }
    }

    public nextView(): void {
        this.setView(this.nextViewMap[this.view]);
    }

    public goBackFromDetails(): void {
        this.setView('Review');
    }

    public onClickCancel(): void {
        this.onCancel({});
    }

    protected initialiseRequestDetails(): void {
        this.requestDetails = {
            instructions: null,
            quantity: null,
            replyEmail: this.identity.currentUser!.username,
            shippingAttention: this.publishData.location.shippingAttention,
            shippingCompanyName: this.publishData.location.shippingCompanyName,
            shippingLineOne: this.publishData.location.shippingLineOne,
            shippingLineThree: this.publishData.location.shippingLineThree,
            shippingLineTwo: this.publishData.location.shippingLineTwo,
        };
    }

    protected getSelectedPrintProviderOrCreate(): IPromise<PrintProvider> {
        if (
            this.isCreatePrintProviderAllowed &&
            this.publishData.otherSelected &&
            this.publishData.otherPrintProvider
        ) {
            return this.createSpecifiedOtherProvider.run({
                locationId: this.publishData.location.id,
                otherPrintProvider: this.publishData.otherPrintProvider,
                saveOtherAsNew: this.saveOtherAsNew,
            });
        } else if (this.publishData.selectedPrintProvider) {
            return this.$q.resolve(this.publishData.selectedPrintProvider);
        } else {
            return this.$q.reject() as IPromise<PrintProvider>;
        }
    }

    protected isExclusiveArtworkReview(): boolean {
        return false;
    }

    protected async generatePdf(): Promise<GeneratedArtwork> {
        return this.generatePrintPdf({
            exclusiveArtworkReview: this.isExclusiveArtworkReview(),
        })
            .then(pdf => this.pdfBuilderService.pdfToBlob(pdf))
            .then((blobStream: BlobStream.IBlobStream) => {
                const now = new Date();
                const blob: BlobWithData = blobStream.toBlob('application/pdf');
                blob.lastModified = now.getTime();
                blob.name = this.getPdfName();
                const generatedArtwork = {
                    blob,
                    url: URL.createObjectURL(blob),
                };
                this.generatedArtwork = generatedArtwork;
                return generatedArtwork;
            });
    }

    protected getArtwork(): angular.IPromise<GeneratedArtwork> {
        if (this.generatedArtwork) {
            return this.$q.resolve(this.generatedArtwork);
        }

        return this.generatePdf();
    }

    protected getPdfName(): string {
        const fileType = { imageFormat: { fileExtension: 'pdf', name: 'pdf' } };
        return this.generateImageFileName(fileType);
    }

    protected downloadArtwork(): angular.IPromise<void> {
        return this.getArtwork().then(artwork => this.fileUtils.downloadFile(artwork.url, this.getPdfName()));
    }

    protected uploadArtwork(artwork: GeneratedArtwork): angular.IPromise<Upload> {
        return this.uploadService.upload([artwork.blob], 'publishedArtifacts/print', [], this)[0];
    }

    protected requestDetailsHasAllRequiredFields(
        requestDetails: Nullable<PublishPrintRequestDetails>,
    ): requestDetails is PublishPrintRequestDetails {
        return !!(requestDetails.quantity && requestDetails.instructions && requestDetails.replyEmail);
    }

    protected prepareToSend(): Result<Error | string, SendData<TViews>> {
        const requestDetails = {
            ...this.requestDetails, // Clone this so we don't change it accidentally
        };
        if (!this.requestDetailsHasAllRequiredFields(requestDetails)) {
            const msg = this.i18n.text.build.publish.print.requestDetailsIsMissingData();
            this.mvNotifier.expectedError(msg);
            return resultFailure(msg);
        }

        const prePublishView = this.view;
        const pagesAmountResult = this.getDocumentPagesAmount();
        switch (pagesAmountResult.success) {
            case true:
                break;
            case false:
            default:
                // If an error occurs, go back to the form to allow the user to try again.
                this.returnToPrePublishView(prePublishView);
                // eslint-disable-next-line no-case-declarations
                const err = pagesAmountResult.failure;
                this.mvNotifier.unexpectedError(err);
                return resultFailure(err);
        }
        const pagesAmount = pagesAmountResult.value;
        this.setView('Publishing');
        return resultSuccess({
            pagesAmount,
            prePublishView,
            requestDetails,
        });
    }

    protected prepareSendToPrintContext(sendData: SendData<TViews>): SendToPrintContext<TViews> {
        return {
            didReviewArtwork: this.didReviewArtwork,
            documentPagesAmount: sendData.pagesAmount,
            editableTextContent: [] as string[],
            formatHeight: this.publishData.fileFormatChoice.height,
            formatWidth: this.publishData.fileFormatChoice.width,
            locationId: this.publishData.location.id,
            plannerId: this.publishData.plannerDetails ? this.publishData.plannerDetails.id : undefined,
            prePublishView: sendData.prePublishView,
            requestDetails: sendData.requestDetails,
            templateId: this.publishData.templateId,
        };
    }

    protected startSendProcess(sendData: SendData<TViews>): IPromise<void> {
        return this.sendToPrint.run(this.prepareSendToPrintContext(sendData));
    }

    protected invokeServerSend(
        context: SendToPrintContext<TViews>,
        upload: Upload,
        provider: PrintProvider,
    ): IPromise<void> {
        const payload: ISendToPrintProviderBody = {
            builderTemplateId: context.templateId,
            documentPagesAmount: context.documentPagesAmount,
            editableTextContent: context.editableTextContent,
            formatHeight: context.formatHeight,
            formatWidth: context.formatWidth,
            plannerId: context.plannerId || null,
            printProviderId: provider.id,
            reviewedArtwork: context.didReviewArtwork,
            uploadId: upload.id,
            ...context.requestDetails,
        };

        return this.printPublishService.sendToPrintProvider(context.locationId, payload);
    }

    protected onSent(options?: PublishResultOptions): void {
        const publishedResult = new PublishResult(ExportPlatforms.Print, null, options);
        this.publishPublished({
            publishedResult,
        });
    }

    protected setView(view: BasePrintPublishViews | TViews): void {
        this.view = view;
    }

    public abstract skipArtworkReview(): void;

    protected abstract preparePlatformData(requestDetails: PublishPrintRequestDetails): void;
}
