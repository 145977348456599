/// <reference path="../../../typings/browser.d.ts" />
import { ClientId } from '@deltasierra/shared';
import { MvClientResource, mvClientResourceSID } from './mvClientResource';
import ILocationService = angular.ILocationService;
import IDirectiveLinkFn = angular.IDirectiveLinkFn;

export const dsClientNavSID = 'dsClientNav';

export const dsClientNavConfig = {
    restrict: 'E',
    scope: {
        client: '=?',
        clientId: '=?',
    },
};

interface ClientNavScope extends ng.IScope {
    client: any;
    clientId: ClientId | string;
    clientGraphqlId: string;
    client_id: ClientId | string;
    isActive: (route: string) => boolean;
}

angular.module('app').directive(dsClientNavSID, [
    '$location',
    mvClientResourceSID,
    function ($location: ILocationService, mvClientResource: MvClientResource): ng.IDirective<ClientNavScope> {
        return {
            ...dsClientNavConfig,
            templateUrl: '/partials/clients/client-nav',
            link: function (scope: ClientNavScope): void {
                if (!scope.client) {
                    scope.client = mvClientResource.get({ id: scope.clientId });
                }
                scope.client_id = scope.clientId; // Dumb hack so I don't have to refactor everything that uses client-nav directly

                scope.clientGraphqlId = scope.client.graphqlId;

                scope.isActive = function (route): boolean {
                    return route === $location.path();
                };
            } as IDirectiveLinkFn<ClientNavScope>,
        };
    },
]);
