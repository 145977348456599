import { ErrorMessage, Loader } from '@deltasierra/components';
import * as React from 'react';
import { ConfigType, useConfig } from './useConfig';

export type ConfigContextProps = {
    children?: React.ReactNode;
};

export const ConfigContext = React.createContext<ConfigType | null>(null);
ConfigContext.displayName = 'ConfigContext';

export const ConfigLoader: React.FC<ConfigContextProps> = ({ children }) => {
    const [data, loading, { error }] = useConfig();

    return (
        <Loader loading={loading}>
            {data && <ConfigContext.Provider value={data}>{children}</ConfigContext.Provider>}
            {error && <ErrorMessage error={error} />}
        </Loader>
    );
};
ConfigLoader.displayName = 'ConfigLoader';
