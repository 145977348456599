/* eslint-disable jsdoc/require-returns */
/// <reference path="../../../typings/browser.d.ts" />
import { Untyped } from '@deltasierra/shared';
import * as linq from 'linq';
import { MvNotifier } from '../common/mvNotifier';
import { I18nService } from '../i18n';
import { Future, FutureOptions } from './Future';

import IHttpPromiseCallbackArg = angular.IHttpPromiseCallbackArg;
import IPromise = angular.IPromise;
import IHttpPromise = angular.IHttpPromise;

// This would require updating all invocation sites... blech
/* Export interface HasLoading {
    loading : { [key : string] : boolean };
}*/
export interface HasLoading {
    loading: Object; // Not great, can't enforce boolean values
}

export interface HasResourcePromise<T> {
    $promise: IPromise<T>;
}

export class HttpFuture<R, C> extends Future<R, C, IHttpPromiseCallbackArg<R>> {
    public run(context: C): IPromise<R> {
        return super.run(context).then(result => (result as IHttpPromiseCallbackArg<R>).data!) as IPromise<R>;
    }
}

export class InteractionUtils {
    public static SID = 'interactionUtils';

    static readonly $inject: string[] = ['$q', MvNotifier.SID, I18nService.SID];

    constructor(private $q: Untyped, private mvNotifier: MvNotifier, private i18n: I18nService) {}

    wrapNotifyError(container: HasLoading, loadingPropertyName: string, description: string) {
        return (data: Untyped) => {
            this.notifyError(description, data);
            (container.loading as Untyped)[loadingPropertyName] = false;
            return this.$q.reject(data);
        };
    }

    getData<T>(res: IHttpPromiseCallbackArg<T>) {
        return res.data;
    }

    wrapDeactivateLoadingFlag(container: HasLoading, loadingPropertyName: string) {
        return function (result: Untyped) {
            (container.loading as Untyped)[loadingPropertyName] = false;
            return result;
        };
    }

    /**
     * @param container
     * @param description
     * @param propertyName
     * @param promise
     * @deprecated Use Futures instead
     * @returns
     */
    handleRemote<T>(
        container: HasLoading,
        description: string,
        propertyName: string,
        promise: ng.IPromise<ng.IHttpPromiseCallbackArg<T>>,
    ): ng.IPromise<T> {
        (container.loading as Untyped)[propertyName] = true;
        return promise
            .then(this.getData.bind(this))
            .then(this.wrapDeactivateLoadingFlag(container, propertyName))
            .catch(this.wrapNotifyError(container, propertyName, description));
    }

    /**
     * @param container
     * @param description
     * @param propertyName
     * @param promise
     * @deprecated Use Futures instead
     */
    handleRemoteSimple<T>(
        container: HasLoading,
        description: string,
        propertyName: string,
        promise: ng.IPromise<T>,
    ): ng.IPromise<T> {
        (container.loading as Untyped)[propertyName] = true;
        return promise
            .then(this.wrapDeactivateLoadingFlag(container, propertyName))
            .catch(this.wrapNotifyError(container, propertyName, description));
    }

    /**
     * @param container
     * @param description
     * @param propertyName
     * @param resource
     * @deprecated Use Futures instead. Also, replace resources with ApiClient services.
     */
    handleResource<T>(
        container: HasLoading,
        description: string,
        propertyName: string,
        resource: HasResourcePromise<T>,
    ): ng.IPromise<any> {
        (container.loading as Untyped)[propertyName] = true;
        return resource.$promise
            .then(this.wrapDeactivateLoadingFlag(container, propertyName))
            .catch(this.wrapNotifyError(container, propertyName, description));
    }

    createFuture<R, C>(
        description: string | (() => string),
        action: (context: C) => IPromise<R>,
        options?: FutureOptions,
    ) {
        if (!options) {
            options = {};
        }
        if (!options.onError) {
            options.onError = (err: Error) => {
                this.notifyError(description, err);
                return this.$q.reject(err);
            };
        }
        return new Future<R, C>(this.$q, description, action, options);
    }

    createHttpFuture<R, C>(
        description: string | (() => string),
        action: (context: C) => IHttpPromise<R>,
        options?: FutureOptions,
    ) {
        if (!options) {
            options = {};
        }
        if (!options.onError) {
            options.onError = (err: Error) => {
                this.notifyError(description, err);
                return this.$q.reject(err);
            };
        }
        return new HttpFuture<R, C>(this.$q, description, action, options);
    }

    createIsLoading(future: Future<any, any>, ...rest: Array<Future<any, any>>): () => boolean {
        let futures = [future];
        if (rest && rest.length > 0) {
            futures = futures.concat(rest);
        }
        return () => linq.from(futures).any(future1 => future1.isRunning());
    }

    private notifyError(description: string | (() => string), error: Error) {
        this.mvNotifier.unexpectedErrorWithData(
            this.i18n.text.common.failedTo({
                description: typeof description === 'function' ? description() : description,
            }),
            error,
        );
    }
}

angular.module('app').service(InteractionUtils.SID, InteractionUtils);
