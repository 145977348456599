import {
    translate,
    TranslateOptions,
    GetSegmentKeyFunction,
    SegmentKey,
    makeTextService,
    TextTranslators,
} from '@deltasierra/i18n';
import {
    FormatCurrencyOptions,
    FormatDateOptions,
    FormatNumberOptions,
    I18nService as I18nServiceInterface,
    Locale,
    MonthOneBased,
    Untyped,
} from '@deltasierra/shared';
import i18next from 'i18next';
import { $filterSID, $qSID, $rootScopeSID } from '../common/angularData';
import { MoneyFilterOptions } from '../filters/money';
import { LOCALES } from './config';

export type SegmentKeysSubset = {
    [name: string]: GetSegmentKeyFunction | SegmentKey;
};

export type TranslatedValues<TSegmentKeys extends SegmentKeysSubset> = {
    [p in keyof TSegmentKeys]: string;
};

type MonthYearFormatType = 'long' | 'short';

const FORMATS = Object.freeze<{ [key: string]: { [K in MonthYearFormatType]: string } }>({
    MONTH_YEAR: {
        long: 'MMMM yyyy',
        short: 'MMM yyyy',
    },
});

interface BootstrappableScope {
    i18n?: TextTranslators;
    translate?: (key: SegmentKey, options: TranslateOptions) => string;
}

export class I18nService implements I18nServiceInterface {
    public static readonly SID = 'I18nService';

    public static readonly $inject: string[] = [$qSID, $filterSID];

    public text = makeTextService();

    private readonly $filterNumber: ng.IFilterNumber;

    private readonly $filterDate: ng.IFilterDate;

    private readonly filterMoney: (value: number, options?: MoneyFilterOptions) => string;

    public constructor(private $q: ng.IQService, $filter: ng.IFilterService) {
        this.$filterDate = $filter('date');
        this.$filterNumber = $filter('number');
        this.filterMoney = $filter('money')!;
    }

    public getLocales(): Locale[] {
        return LOCALES.map(locale => ({ ...locale }));
    }

    public getLocaleCode(): string {
        return i18next.language;
    }

    public setLocaleCode(localeCode: string): ng.IPromise<void> {
        return this.$q<void>((resolve: Untyped, reject: Untyped) => {
            i18next.changeLanguage(localeCode, err => {
                if (err) {
                    // eslint-disable-next-line no-console
                    console.error(`Error switching locale to '${localeCode}': `, err.message || err);
                    return reject(err);
                }

                location.reload();

                return resolve();
            });
        });
    }

    public translate(key: GetSegmentKeyFunction | SegmentKey, options?: TranslateOptions): string {
        return translate(key, options);
    }

    public bootstrapScope(scope: BootstrappableScope): void {
        scope.i18n = this.text;
        scope.translate = (key: Untyped, options: Untyped) => this.translate(key, options);
    }

    public formatNumber(value: number, options?: FormatNumberOptions): string {
        return this.$filterNumber(value, options ? options.decimalPoints : undefined);
    }

    public formatCurrency(value: number, options?: FormatCurrencyOptions): string {
        return this.filterMoney(value, options);
    }

    public formatDate(value: Date, format: string, timezone: number | string, options?: FormatDateOptions): string {
        return this.$filterDate(value, format, typeof timezone === 'number' ? String(timezone) : timezone);
    }

    public formatMonthYear(date: Date, formatType: MonthYearFormatType): string;

    public formatMonthYear(month: MonthOneBased, year: number, formatType: MonthYearFormatType): string;

    public formatMonthYear(...args: any[]): string {
        let date: Date;
        let formatType: MonthYearFormatType;

        if (args[0] instanceof Date) {
            [date, formatType] = args;
        } else {
            let month: number;
            let year: number;
            [month, year, formatType] = args;
            date = new Date(year, month - 1, 1);
        }

        const format = FORMATS.MONTH_YEAR[formatType];
        return this.$filterDate(date, format);
    }

    public getLocaleCompare(): (x: string, y: string) => number {
        const collator = Intl.Collator(this.getLocaleCode());
        return collator.compare.bind(collator);
    }
}

angular
    .module('app')
    .service(I18nService.SID, I18nService)
    .run([
        $rootScopeSID,
        I18nService.SID,
        ($rootScope: BootstrappableScope, i18n: I18nService) => {
            i18n.bootstrapScope($rootScope);
        },
    ]);
