/* eslint-disable jsdoc/require-returns */
/// <reference path="../../../typings/browser.d.ts" />
import IScope = angular.IScope;
import IAngularEvent = angular.IAngularEvent;

/**
 * Wrapper around AngularJS events, to provide some type safety.
 */
export class AngularEventDefinition<TData> {
    constructor(
        public readonly name : string,
        public readonly description : string,
    ) {

    }

    /**
     * Returns a deregistration function.
     *
     * @param scope
     * @param listener
     */
    on(scope : IScope, listener : (event : IAngularEvent, data : TData) => any) : Function {
        return scope.$on(this.name, listener);
    }

    /**
     * Propagates upwards
     *
     * @param scope
     * @param data
     */
    emit(scope : IScope, data : TData) {
        return scope.$emit(this.name, data);
    }

    /**
     * Propagates downwards
     *
     * @param scope
     * @param data
     */
    broadcast(scope : IScope, data : TData) {
        return scope.$broadcast(this.name, data);
    }
}
