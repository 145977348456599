import { justBody, SanitizedUser, t, UserId, UserInviteApi } from '@deltasierra/shared';
import * as React from 'react';
import { BootstrapSize, Button, ButtonProps } from '../Button';
import { useAngularServiceContext } from '../componentUtils/angularServiceContexts';
import { useFuture } from '../futures';
import { invokeApiRoute } from '../httpUtils';

import { Translate } from '../../directives/Translate';

export type UserInviteStatusButtonProps = Pick<ButtonProps, 'block'> & {
    className?: string;
    user: Pick<SanitizedUser, 'hasAcceptedInvite' | 'id'>;
    /**
     * The size of the button
     *
     * @default 'xs'
     */
    size?: BootstrapSize;
};

const UserInviteStatusButton: React.FunctionComponent<UserInviteStatusButtonProps> = ({
    block = false,
    className,
    size = 'xs',
    user,
}) => {
    const { isSending, resend } = useResendInvite(user.id);
    if (isSending) {
        return <InviteSendingButton block={block} className={className} size={size} />;
    } else if (!user.hasAcceptedInvite) {
        return <ResendInviteButton block={block} className={className} size={size} onClick={() => resend()} />;
    }
    return <></>;
};

export default UserInviteStatusButton;

const ResendInviteButton: React.FunctionComponent<
    Pick<ButtonProps, 'block' | 'size'> & { className?: string; onClick: () => void }
> = ({ block, className, onClick, size }) => (
    <Button block={block} className={className} size={size} theme="info" onClick={onClick}>
        <i className="fas fa-envelope icon-space" />
        <span>
            <Translate keyId="PROFILE.RESEND_INVITE" />
        </span>
    </Button>
);

const InviteSendingButton: React.FunctionComponent<Pick<ButtonProps, 'block' | 'size'> & { className?: string }> = ({
    block,
    className,
    size,
}) => (
    <Button block={block} className={className} disabled size={size} theme="info">
        <span>
            <Translate keyId="PROFILE.SENDING_INVITE" />
        </span>
    </Button>
);

function useResendInvite(userId: UserId): { isSending: boolean; resend: () => void } {
    const $http = useAngularServiceContext('$http');
    const notifier = useAngularServiceContext('mvNotifier');
    const response = useFuture(
        async () => {
            await invokeApiRoute($http, UserInviteApi.resendInvite, justBody({ userId }));
            notifier.notify(t('PROFILE.INVITE_SENT'));
        },
        [$http, userId, notifier],
        { description: 'resend invite' },
    );
    return {
        isSending: response.state.isRunning,
        resend: response.invoke,
    };
}
