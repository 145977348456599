/// <reference path="../../../../typings/browser.d.ts" />
import { URLBuilder } from '@deltasierra/shared';
import { OneWayBinding, StringBinding } from '../../common/angularData';
import { FileUtils } from '../../common/fileUtils';
import IDirective = angular.IDirective;

export function rectifyS3UrlWithFilename(url: string, filename?: string): string {
    const urlBuilder = new URLBuilder(url);

    // Add a download string to external URLs, but not to blobs.
    if (url.indexOf('//') === 0 || url.indexOf('https://') === 0) {
        let contentDisposition = 'attachment;';
        if (filename) {
            // DS-6719 - Get UTF-8 encoding compliant version of certain characters if they exist in filename
            /* DS-8523 - Encode twice, as it appears AWS decodes prior to sending a response, resulting in
             * raw UTF-8 characters appearing in the response Content-Disposition header and causing an error.
             */
            const encodedFilename = encodeURIComponent(encodeURIComponent(filename));
            contentDisposition = `${contentDisposition} filename*=UTF-8''${encodedFilename}`;
        }
        urlBuilder.add('response-content-disposition', contentDisposition);
    }
    return urlBuilder.build();
}

class DsDownloadLinkController {
    public static SID = 'DsDownloadLinkController';

    public static readonly $inject: string[] = [FileUtils.SID];

    // Directive bindings
    protected linkClass!: string;

    protected url!: string;

    protected fileName!: string;

    // Other properties
    protected newTab = !this.fileUtils.isDownloadAttributeSupported();

    public constructor(protected readonly fileUtils: FileUtils) {}

    public $onInit(): void {
        this.url = rectifyS3UrlWithFilename(this.url, this.fileName);
    }
}

export const dsDownloadLinkSID = 'dsDownloadLink';
export const dsDownloadLinkConfig: IDirective<ng.IScope> = {
    bindToController: true,
    controller: DsDownloadLinkController,
    controllerAs: 'ctrl',
    restrict: 'E',
    scope: {
        fileName: OneWayBinding,
        linkClass: StringBinding,
        url: OneWayBinding,
    },
    templateUrl: '/partials/directives/dsDownloadLink/template',
    transclude: true,
};

angular.module('app').directive(dsDownloadLinkSID, [() => dsDownloadLinkConfig]);
