import * as React from 'react';
import { t } from '@deltasierra/shared';
import { UploadAssetFilesModal } from './UploadAssetFilesModal';

export type UploadFilesButtonProps = {
    folderOrCollectionId: string;
};

export const UploadAssetFilesButton: React.FC<UploadFilesButtonProps> = ({ folderOrCollectionId }) => {
    const [show, setShow] = React.useState(false);
    return (
        <>
            <button className="btn btn-sm btn-link" data-cy="upload-asset-button" type="button" onClick={() => setShow(true)}>
                <i className="fas fa-upload text-muted" title={t('COMMON.UPLOAD_FILES')} />
            </button>
            <UploadAssetFilesModal folderOrCollectionId={folderOrCollectionId} show={show} onClose={() => setShow(false)} />
        </>
    );
};
UploadAssetFilesButton.displayName = 'UploadAssetFilesButton';
