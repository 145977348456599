/// <reference path="../../../../typings/browser.d.ts" />
import {
    emptyRequest,
    GetAutomatedEmailResponse,
    GetSimpleAutomatedEmailTemplatesResponse,
    HasBuilderTemplateFormatId,
    justParams,
    LocationId,
    MemberEngagerApi,
} from '@deltasierra/shared';
import { $httpSID, $qSID } from '../../common/angularData';
import { invokeApiRoute } from '../../common/httpUtils';

export class MemberEngagerApiClient {
    static SID = 'MemberEngagerApiClient';

    static $inject = [$httpSID, $qSID];

    constructor(protected readonly $http: ng.IHttpService, protected readonly $q: ng.IQService) {}

    getFitwareAutomatedEmails(locationId: LocationId): ng.IPromise<GetSimpleAutomatedEmailTemplatesResponse[]> {
        return invokeApiRoute(
            this.$http,
            MemberEngagerApi.getFitwareAutomatedEmails,
            justParams({
                locationId,
            }),
        );
    }

    getFitwareDirectEmailFormat(): ng.IPromise<HasBuilderTemplateFormatId> {
        return invokeApiRoute(this.$http, MemberEngagerApi.getFitwareDirectEmailFormat, emptyRequest());
    }

    getAutomatedEmail(locationId: LocationId, automatedEmailId: number): ng.IPromise<GetAutomatedEmailResponse> {
        return invokeApiRoute(
            this.$http,
            MemberEngagerApi.getAutomatedEmail,
            justParams({
                locationId,
                automatedEmailId,
            }),
        );
    }
}

angular.module('app').service(MemberEngagerApiClient.SID, MemberEngagerApiClient);
