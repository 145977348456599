import { Observable, of, Subject } from 'rxjs';
import { assertDefined, type UploadCategory } from '@deltasierra/shared';
import { type ApolloClient, gql } from '@apollo/client';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { UploadFailure, UploadFailureVariables } from './__graphqlTypes/UploadFailure';
import { FileUploadStatus, isFileUploadError } from './common';
import { InitializeUploadInput, initializeUploadStep } from './initialize-upload-step';
import { uploadFileToS3Step } from './upload-file-to-s3-step';
import { reportUploadSuccessfulStep } from './report-upload-successful-step';

export type UploadFileOptions = {
    file: File;
    category: UploadCategory;
    client: ApolloClient<unknown>;
    cancellation$?: Observable<unknown>;
};

export function uploadFile(options: UploadFileOptions): Observable<FileUploadStatus> {
    const cancellation$ = options.cancellation$ ?? new Subject<unknown>();
    const progress$ = new Subject<FileUploadStatus>();

    const input: InitializeUploadInput = {
        category: options.category,
        file: options.file,
    };

    of(input)
        .pipe(
            tap(() => progress$.next({ status: 'starting' })),
            initializeUploadStep({ client: options.client }),
            tap(value =>
                progress$.next({
                    legacyUploadId: value.legacyUploadId,
                    status: 'started',
                    uploadId: value.uploadId,
                }),
            ),
            uploadFileToS3Step({ cancellation$, progress$ }),
            tap(() => progress$.next({ status: 'finalizing' })),
            reportUploadSuccessfulStep({ client: options.client }),
            takeUntil(cancellation$),
            catchError(async (error: unknown) => {
                if (isFileUploadError(error) && error.uploadId) {
                    await reportUploadFailed(error.uploadId, options.client);
                }
                throw error;
            }),
        )
        .subscribe({
            complete: () => progress$.complete(),
        });

    return progress$;
}

export async function simpleUploadFile(options: UploadFileOptions): Promise<{ id: string }> {
    return new Promise<{ id: string }>((resolve, reject) => {
        let uploadId: string;
        uploadFile(options).subscribe({
            complete: () => {
                assertDefined(uploadId);
                resolve({ id: uploadId });
            },
            error: reject,
            next: event => {
                if (event.status === 'started') {
                    uploadId = event.uploadId;
                }
            },
        });
    });
}

async function reportUploadFailed(uploadId: string, client: ApolloClient<unknown>): Promise<void> {
    const results = await client.mutate<UploadFailure, UploadFailureVariables>({
        mutation: gql`
            mutation UploadFailure($uploadId: ID!) {
                uploadFailure(id: $uploadId) {
                    ... on Upload {
                        id
                    }
                    ... on UploadNotFoundError {
                        code
                        message
                    }
                }
            }
        `,
        variables: {
            uploadId,
        },
    });

    if (!results.data) {
        throw new Error('Missing data');
    } else if (results.data.uploadFailure.__typename === 'UploadNotFoundError') {
        throw new Error('Upload not found');
    }
}
