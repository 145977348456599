import { gql } from '@apollo/client';

export const GENERATE_CSV_FOR_SUPPRESSED_EMAILS_MUTATION = gql`
    mutation GenerateCSVForSuppressedEmailsMutation(
        $locationIds: [ID!]!
        $active: ClubReadyIntegrationListsActive!
        $inactive: ClubReadyIntegrationListsInactive!
        $prospects: ClubReadyIntegrationListsProspects!
        $cooledOffProspects: ClubReadyIntegrationListsCooledOffProspects!
        $custom: ClubReadyIntegrationListsCustom!
    ) {
        generateCSVForSuppressedEmails(
            locationIds: $locationIds
            active: $active
            inactive: $inactive
            prospects: $prospects
            cooledOffProspects: $cooledOffProspects
            custom: $custom
        ) {
            ... on GenerateCSVForSuppressedEmailsSuccess {
                request {
                    id
                }
            }
            ... on GenerateCSVForSuppressedEmailsError {
                code
                message
            }
        }
    }
`;
