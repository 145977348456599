import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { NotifierDispatchNotificationContext, NotifierDispatchNotificationContextType } from '../../contexts';
import { NotifierContainer, ActiveNotification } from '../../NotifierContainer';

export type NotifierProviderProps = {
    children: React.ReactNode;
};

/**
 * This component registers the dispatch notification event.
 * It will handle registering the notification component as well as any props to pass to it
 *
 * @param props - The props
 * @param props.children - The items to wrap with the provider
 * @returns The jsx element
 */
export function NotifierDispatchNotificationProvider({ children }: NotifierProviderProps): JSX.Element {
    const [notifications, setNotifications] = React.useState<ActiveNotification[]>([]);
    const [fadingIds, setFadingIds] = React.useState<string[]>([]);

    // This function starts the removal of a notification from the list
    const startDismissNotification = React.useCallback((id: string) => {
        setFadingIds(ids => [...ids, id]);
    }, []);

    // This function finalises the removal of the notification
    const removeNotification = React.useCallback((id: string) => {
        setNotifications(old => [...old].filter(not => not.id !== id));
        setFadingIds(ids => ids.filter(oldId => oldId !== id));
    }, []);

    // This is the dispatch notification handler for the context
    const dispatchNotification: Exclude<NotifierDispatchNotificationContextType, undefined> = React.useCallback(
        (component, options) => {
            const { dismissable = true, extendTimeoutMs = 1_000, timeoutMs = 5_000 } = options ?? {};

            const newId = uuidv4();
            const dismiss = () => {
                startDismissNotification(newId);
            };
            const newNotification: ActiveNotification = {
                component,
                id: newId,
                options: {
                    dismissable,
                    extendTimeoutMs,
                    timeoutMs,
                },
                props: {
                    dismiss,
                    extendTimeoutMs,
                    isTransitioningOut: false,
                    onClickDismiss: dismissable ? dismiss : undefined,
                    onTransitionOut: () => removeNotification(newId),
                    timeoutMs,
                },
            };

            // Add the new notification to the list
            setNotifications(old => [...old, newNotification]);
        },
        [removeNotification, startDismissNotification],
    );

    // This is a helper function to update any props that change in the components after the initial add
    const notificationsWithUpdatedProps = React.useMemo(
        () =>
            notifications.map(notification => ({
                ...notification,
                props: {
                    ...notification.props,
                    isTransitioningOut: fadingIds.includes(notification.id),
                },
            })),
        [fadingIds, notifications],
    );

    return (
        <NotifierDispatchNotificationContext.Provider value={dispatchNotification}>
            {children}
            <NotifierContainer notifications={notificationsWithUpdatedProps} />
        </NotifierDispatchNotificationContext.Provider>
    );
}
NotifierDispatchNotificationProvider.displayName = 'NotifierDispatchNotificationProvider';
