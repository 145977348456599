import { gql } from '@apollo/client';
import { Loading } from '@deltasierra/components';
import * as React from 'react';
import { useCurrentAssetContext } from '../../contexts';
import { ClientBinAssets, ClientExpiredAssets, LocationBinAssets, LocationExpiredAssets } from '../RecyledAssets';
import { CollectionAssets } from './CollectionAssets';
import { LocationFragmentForAssetIdSwitch } from './__graphqlTypes/LocationFragmentForAssetIdSwitch';

const fragments = {
    LOCATION_FRAGMENT_FOR_ASSET_ID_SWITCH: gql`
        fragment LocationFragmentForAssetIdSwitch on Location {
            id
            ...LocationFragmentForCollectionAssets
        }
        ${CollectionAssets.fragments.LOCATION_FRAGMENT_FOR_COLLECTION_ASSETS}
    `,
};

export interface AssetIdSwitchProps {
    enableLightboxPreview: boolean;
    location: LocationFragmentForAssetIdSwitch;
}

export function AssetIdSwitch({ enableLightboxPreview, location }: AssetIdSwitchProps): JSX.Element {
    const [currentAsset] = useCurrentAssetContext();
    if (currentAsset === 'client-expired') {
        return <ClientExpiredAssets locationId={location.id} />;
    } else if (currentAsset === 'client-bin') {
        return <ClientBinAssets locationId={location.id} />;
    } else if (currentAsset === 'location-expired') {
        return <LocationExpiredAssets locationId={location.id} />;
    } else if (currentAsset === 'location-bin') {
        return <LocationBinAssets locationId={location.id} />;
    } else if (currentAsset) {
        return (
            <CollectionAssets
                currentAsset={currentAsset}
                enableLightboxPreview={enableLightboxPreview}
                location={location}
            />
        );
    } else {
        return <Loading />;
    }
}
AssetIdSwitch.displayName = 'AssetIdSwitch';
AssetIdSwitch.fragments = fragments;
