import { gql } from '@apollo/client';

export const LCO_FRAGMENT_FOR_LOCATION_GROUP_THUMBNAIL = gql`
    fragment LCOFragmentForLocationGroupThumbnail on LocationCategoryOption {
        id
        label
        builderTemplateCategories {
            id
            legacyId
        }
        logo {
            id
            url
        }
    }
`;
