import { useQuery } from '@apollo/client';
import {
    DSDialog,
    DSLoaderButton,
    HelpPopper,
    Translate,
} from '@deltasierra/components';
import { useToggle } from '@deltasierra/react-hooks';
import * as React from 'react';
import { ClubReadyClientDialogContent } from './ClubReadyClientDialogContent';
import { GET_ADMIN_CLUBREADY_INTEGRATION_FOR_CLIENT_DIALOG } from './ClubReadyConfigureClientButton.queries';
import { GetAdminClubReadyIntegrationForClientDialog, GetAdminClubReadyIntegrationForClientDialogVariables } from './__graphqlTypes/GetAdminClubReadyIntegrationForClientDialog';

export type ClubReadyConfigureClientButtonProps = {
    clientTitle: string;
    integrationId: string;
};

export const ClubReadyConfigureClientButton: React.FC<ClubReadyConfigureClientButtonProps> = ({
    clientTitle,
    integrationId,
}) => {
    const [isConfigDialogOpen, toggleConfigDialog] = useToggle();

    const { data, loading } = useQuery<GetAdminClubReadyIntegrationForClientDialog, GetAdminClubReadyIntegrationForClientDialogVariables>(
        GET_ADMIN_CLUBREADY_INTEGRATION_FOR_CLIENT_DIALOG,
        {
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true,
            variables: { id: integrationId },
        },
    );

    const chainId =
        data?.integration.__typename === 'Integration' &&
        data.integration.connection.__typename === 'IntegrationConnection' &&
        data.integration.connection.configuration.__typename === 'ClubReadyClientConfiguration'
            ? data?.integration?.connection?.configuration?.chainId ?? ''
            : '';

    const isClientConfigurable = data?.integration.__typename === 'Integration' &&
        data?.integration.connection.__typename === 'IntegrationConnection' &&
        data.integration.connection.configuration.__typename === 'ClubReadyClientConfiguration';

    return (
        <>
            <DSLoaderButton
                color="primary"
                disabled={loading || !isClientConfigurable}
                loading={loading}
                size="small"
                onClick={() => toggleConfigDialog('toggleOn')}
            >
                <Translate keyId="INTEGRATION.CONFIGURE_INTEGRATION" options={{ integration: 'ClubReady' }} />
            </DSLoaderButton>
            {!loading && !isClientConfigurable && (
                <HelpPopper margin="left" popperIcon="none">
                    <Translate keyId="ADMIN.CLUBREADY_CONNECT.CLUBREADY_DISABLED_FOR_CLIENT_HELP_TEXT"/>
                </HelpPopper>
            )}
            <DSDialog open={isConfigDialogOpen && isClientConfigurable}>
                {isConfigDialogOpen && isClientConfigurable && (
                        <ClubReadyClientDialogContent
                            clientTitle={clientTitle}
                            clubReadyConfId={data.integration.connection.configuration.id}
                            initialChainId={chainId}
                            onDone={() => toggleConfigDialog('toggleOff')}
                        />
                    )}
            </DSDialog>
        </>
    );
};

ClubReadyConfigureClientButton.displayName = 'ClubReadyConfigureClientButton';
