import * as React from 'react';
import styled from 'styled-components';
import { DSGrid, DSTypography, Image, DSIconButton, Loading } from '@deltasierra/components';
import { getDifferenceWithGreatestUnitOfTime } from '@deltasierra/dates';
import { translateTimeUnitAgo } from '@deltasierra/translate-utilities';
import ClearIcon from '@material-ui/icons/Clear';
import { useUploadThumbnailPoll } from '../../../../common/hooks/useUploadThumbnailPoll';

const StyledGridContainer = styled(DSGrid)`
    border-radius: 4px;
    border: 2px solid black;
    max-height: 400px;
    width: 250px;
`;

const StyledThumbnail = styled(Image)`
    max-width: 100%;
    height: auto;
    margin: 10px;
`;

const StyledDraftItemText = styled(DSTypography).attrs({ component: 'h5', variant: 'h5' })`
    margin: 10px 10px 5px 10px;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const StyledDraftItemDateText = styled(DSTypography).attrs({ component: 'div', variant: 'body1' })`
    margin: 0px 10px 20px 10px;
`;

const StyledIconButton = styled(DSIconButton)`
    color: red;

    margin-left: auto;
    margin-right: 2px;
`;

const StyledClickableGridItem = styled(DSGrid)`
    &:hover,
    &:focus-within {
        transform: translate(0px, -4px);
        box-shadow: 0px 3px 0px rgba(0, 0, 0, 0.1);
    }

    cursor: pointer;
    margin: 8px;
    transition-duration: 0.25s;
    transition-property: transform, box-shadow;
`;

export type BuilderTemplateDraftOptionProps = {
    title: string;
    updatedAt: Date;
    thumbnail?: { url: string; title: string };
    thumbnailUploadId?: string;
    deleteDisabled?: boolean;
    onDraftSelect?: () => void;
    onOriginalTemplateSelect?: () => void;
    onClickRemove?: () => void;
};

export function BuilderTemplateDraftOption({
    title,
    thumbnail,
    thumbnailUploadId,
    updatedAt,
    deleteDisabled = false,
    onClickRemove,
    onDraftSelect,
    onOriginalTemplateSelect,
}: BuilderTemplateDraftOptionProps): JSX.Element {
    const currentDate = new Date();
    const dateDiff = getDifferenceWithGreatestUnitOfTime(updatedAt, currentDate);

    const [placeholder, setPlaceholder] = React.useState(<Loading />);

    const { thumbnail: thumbnailPoll, loading } = useUploadThumbnailPoll(thumbnailUploadId);

    React.useEffect(() => {
        if (thumbnailPoll && !thumbnailPoll.thumbnailUrl && !loading) {
            setPlaceholder(<StyledThumbnail src={'/img/thumbnail-other.svg'} title={'fallback'} />);
        }
    }, [loading, thumbnailPoll]);

    const handleSelect = () => {
        if (deleteDisabled) {
            return;
        }
        if (onOriginalTemplateSelect) {
            onOriginalTemplateSelect();
        } else if (onDraftSelect) {
            onDraftSelect();
        }
    };

    const handleRemove = (event: React.MouseEvent) => {
        event.stopPropagation();
        event.preventDefault();
        onClickRemove?.();
    };

    return (
        <StyledClickableGridItem item onClick={handleSelect}>
            <StyledGridContainer alignItems="flex-start" container justifyContent="flex-start">
                <DSGrid item xs={10}>
                    {(() => {
                        if (thumbnail) {
                            return <StyledThumbnail src={thumbnail.url} title={thumbnail.title} />;
                        } else if (thumbnailUploadId && thumbnailPoll && thumbnailPoll.thumbnailUrl) {
                            return (
                                <StyledThumbnail
                                    src={`https://${thumbnailPoll.thumbnailUrl}`}
                                    title={thumbnailPoll.filename}
                                />
                            );
                        }
                        return placeholder;
                    })()}
                </DSGrid>
                {onClickRemove && (
                    <StyledIconButton
                        data-cy="delete"
                        disabled={deleteDisabled}
                        role="button"
                        size="small"
                        onClick={handleRemove}
                    >
                        <ClearIcon />
                    </StyledIconButton>
                )}
                <DSGrid item xs={12}>
                    <StyledDraftItemText>{title}</StyledDraftItemText>
                </DSGrid>
                <DSGrid item xs={12}>
                    <StyledDraftItemDateText>
                        {`Last updated: ${dateDiff.timeDifference} ${translateTimeUnitAgo(
                            dateDiff.unit,
                            dateDiff.timeDifference > 1,
                        )}`}
                    </StyledDraftItemDateText>
                </DSGrid>
            </StyledGridContainer>
        </StyledClickableGridItem>
    );
}

BuilderTemplateDraftOption.displayName = 'BuilderTemplateDraftOption';
