/* eslint-disable max-lines-per-function */
import { useEffectOnUpdateOnly } from '@deltasierra/react-hooks';
import { UploadCategory } from '@deltasierra/shared';
import { stripUndefined } from '@deltasierra/object-utilities';
import TextAlign from '@tiptap/extension-text-align';
import Link from '@tiptap/extension-link';
import { EditorOptions, Extensions, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import * as React from 'react';
import { UploadedImageNode, UploadFileNode } from './nodes';

export type UseTipTapEditorOptions = {
    defaultUploadCategory?: UploadCategory;
    value?: string | null;
    imageClassName?: string;
    onChange?: (newValue: string) => void;
    forceUpdates?: boolean;
};
export type UseTipTapEditorReturnType = ReturnType<typeof useEditor>;

export function useTipTapEditor(
    { defaultUploadCategory = 'emailResources', forceUpdates, imageClassName, onChange, value }: UseTipTapEditorOptions,
    tipTapOptions?: Partial<EditorOptions>,
    deps?: React.DependencyList,
): UseTipTapEditorReturnType {
    // This is the value we supply to the useEditor function
    const [editorValue, setEditorValue] = React.useState(value);
    // This is the value updated by the editor
    const [, setActualEditorValue] = React.useState(value);

    // This will update the editor value anytime the input value changes
    useEffectOnUpdateOnly(() => {
        // Reset the actual editors value
        setActualEditorValue(_actualEditorValue => {
            // If the value has changed, and it does not match what the editor already has
            if (value === _actualEditorValue && !forceUpdates) {
                return _actualEditorValue;
            }
            // Force update the editor
            setEditorValue(value);
            return value;
        });
    }, [value, forceUpdates]);

    const {
        autofocus,
        editable,
        editorProps,
        element,
        enableCoreExtensions,
        enableInputRules,
        enablePasteRules,
        extensions: additionalExtensions,
        injectCSS,
        onBeforeCreate,
        onBlur,
        onCreate,
        onDestroy,
        onFocus,
        onSelectionUpdate,
        onTransaction,
        onUpdate,
        parseOptions,
    } = tipTapOptions ?? {};
    const extensions: Extensions = React.useMemo(
        () => [
            StarterKit,
            TextAlign.configure({
                types: ['heading', 'paragraph', 'upload-file', 'uploaded-image'],
            }),
            UploadFileNode.configure({
                defaultUploadCategory,
                imageClassName,
            }),
            UploadedImageNode.configure({
                imageClassName,
            }),
            Link,
            ...additionalExtensions ?? [],
        ],
        [additionalExtensions, defaultUploadCategory, imageClassName],
    );

    const mergedTipTapOptions: Partial<EditorOptions> = React.useMemo(
        () =>
            stripUndefined({
                autofocus,
                content: editorValue,
                editable,
                editorProps,
                element,
                enableCoreExtensions,
                enableInputRules,
                enablePasteRules,
                extensions,
                injectCSS,
                onBeforeCreate,
                onBlur,
                onCreate,
                onDestroy,
                onFocus,
                onSelectionUpdate,
                onTransaction,
                onUpdate: props => {
                    onUpdate?.(props);

                    const ed = props.editor;

                    // Only allow left aligned lists. because of a bug
                    if (
                        (ed.isActive('bulletList') || ed.isActive('orderedList')) &&
                        ed.isActive({ textAlign: 'center' })
                    ) {
                        ed.chain().setTextAlign('left').run();
                    }
                    const newValue = ed.getHTML();
                    // This needs to be first
                    setActualEditorValue(newValue);
                    // And this second
                    onChange?.(newValue);
                },

                parseOptions,
            }),
        [
            autofocus,
            editorValue,
            editable,
            editorProps,
            element,
            enableCoreExtensions,
            enableInputRules,
            enablePasteRules,
            extensions,
            injectCSS,
            onBeforeCreate,
            onBlur,
            onCreate,
            onDestroy,
            onFocus,
            onSelectionUpdate,
            onTransaction,
            parseOptions,
            onUpdate,
            onChange,
        ],
    );
    return useEditor(mergedTipTapOptions, [mergedTipTapOptions, ...deps ?? []]);
}
