import { ExternalFrontendRouter } from '@deltasierra/frontend/routing/core';
import React from 'react';
import { useConfig } from '../config';

export function useAppFrontendRouter(): ExternalFrontendRouter | null {
    const [config] = useConfig();

    const router = React.useMemo(() => {
        if (!config) {
            return null;
        }
        return new ExternalFrontendRouter(config.appFrontendUrl);
    }, [config]);

    return router;
}
