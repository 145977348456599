import { gql, useMutation } from '@apollo/client';
import { DependencyList, useEffect, useMemo, useRef, useState } from 'react';
import {
    MoveAssetsMutationForMoveFolderDialog,
    MoveAssetsMutationForMoveFolderDialogVariables,
} from './__graphqlTypes/MoveAssetsMutationForMoveFolderDialog';
import { AssetMoverState } from './asset-mover-state';
import { AssetMoverBatchProcessor, AssetMoverOptions } from './asset-mover-batch-processor';
import { MoveAssetOption } from './move-asset-option';

const MOVE_ASSETS_MUTATION_FOR_MOVE_FOLDER_DIALOG = gql`
    mutation MoveAssetsMutationForMoveFolderDialog($input: EditAssetsBulkInput!) {
        editAssetsBulk(input: $input) {
            __typename
            ... on EditAssetsBulkPayload {
                ids
            }
        }
    }
`;

function useAssetMoverInstance(options: AssetMoverOptions): AssetMoverBatchProcessor {
    // This function allows us to provide a single instances of this asset mover to the component
    const assetMoverRef = useRef<AssetMoverBatchProcessor | null>();
    if (!assetMoverRef.current) {
        assetMoverRef.current = new AssetMoverBatchProcessor(options);
    }
    return assetMoverRef.current;
}

function useAssetMover(
    executeMove: AssetMoverOptions['executeMove'],
    deps: DependencyList,
): {
    moveAssets: (idsToMove: ReadonlyArray<MoveAssetOption>) => Promise<void>;
    state: AssetMoverState;
} {
    // This function handles wrapping the asset mover so we can update the react state
    const [state, setState] = useState<AssetMoverState>({
        assetsLeftToMove: 0,
    });
    const options = useMemo<AssetMoverOptions>(
        () => ({
            executeMove,
            onStateChange: setState,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [setState, ...deps],
    );

    const assetMover = useAssetMoverInstance(options);

    useEffect(() => {
        assetMover.syncOptions(options);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options]);

    return {
        moveAssets: (...args) => assetMover.moveAssets(...args),
        state,
    };
}

type UseMoveAssetsOptions = {
    refetchQueries?: string[];
};

export function useMoveAssets({ refetchQueries }: UseMoveAssetsOptions): {
    assetsLeftToMove: number;
    isMoving: boolean;
    moveAssets: (ids: MoveAssetOption[]) => Promise<void>;
} {
    const [move] = useMutation<MoveAssetsMutationForMoveFolderDialog, MoveAssetsMutationForMoveFolderDialogVariables>(
        MOVE_ASSETS_MUTATION_FOR_MOVE_FOLDER_DIALOG,
    );

    const { moveAssets, state } = useAssetMover(
        async (idsToMove: ReadonlyArray<MoveAssetOption>, isLastMove) => {
            await move({
                ...isLastMove
                    ? {
                          awaitRefetchQueries: true,
                          refetchQueries,
                      }
                    : {},
                variables: {
                    input: {
                        assets: idsToMove.map(({ assetId, targetCollectionOrFolderId }) => ({
                            folderOrCollectionId: targetCollectionOrFolderId,
                            id: assetId,
                        })),
                    },
                },
            });
        },
        [refetchQueries],
    );

    return {
        assetsLeftToMove: state.assetsLeftToMove,
        isMoving: state.assetsLeftToMove > 0,
        moveAssets,
    };
}
