import { gql } from '@apollo/client';

export const GENERATE_CLUB_READY_LIST_SUMMARY_MUTATION = gql`
    mutation GenerateClubReadyListSummaryMutation(
        $locationIds: [ID!]!, 
        $active: ClubReadyIntegrationListsActive!,
        $inactive: ClubReadyIntegrationListsInactive!,
        $prospects: ClubReadyIntegrationListsProspects!,
        $cooledOffProspects: ClubReadyIntegrationListsCooledOffProspects!,
        $custom: ClubReadyIntegrationListsCustom!
    ) {
        generateClubReadyListSummary(
            locationIds: $locationIds
            active: $active
            inactive: $inactive
            prospects: $prospects
            cooledOffProspects: $cooledOffProspects
            custom: $custom
        ) {
            ... on GenerateClubReadyListSummarySuccess {
                request {
                    id
                }
            }
            ... on GenerateClubReadyListSummaryError {
                code
                message
            }
        }
    }
`;
