/// <reference path="../../../typings/browser.d.ts" />
import { BuilderTemplateFormat } from '@deltasierra/shared';
import { $resourceSID } from '../common/angularData';
import IResourceService = angular.resource.IResourceService;

export const mvBuilderTemplateFormatResourceSID = 'mvBuilderTemplateFormatResource';

export interface BuilderTemplateFormatResource extends ng.resource.IResource<BuilderTemplateFormatResource>, BuilderTemplateFormat {

}

export interface MvBuilderTemplateFormatResource extends ng.resource.IResourceClass<BuilderTemplateFormatResource> {

}

angular.module('app').factory(mvBuilderTemplateFormatResourceSID, [
    $resourceSID,
    function ($resource : IResourceService): MvBuilderTemplateFormatResource {
    const resource: MvBuilderTemplateFormatResource = $resource<BuilderTemplateFormatResource>(
        '/api/builderTemplateFormats',
    );

    return resource;
},
]);
