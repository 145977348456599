import * as React from 'react';
import {
    DSDialog,
    DSDialogTitle,
    DSDialogContent,
    DSDialogActions,
    DSAlert,
    DSButton,
    Translate,
} from '@deltasierra/components';
import { ImageSection } from '@deltasierra/shared';
import styled from 'styled-components';
import { withAngularIntegration } from '../../../../common/componentUtils/reactComponentRegistration';
import { OneWayBinding } from '../../../../common/angularData';

export interface ImageSelectConfirmationModalProps {
    file: File | null;
    open: boolean;
    section: ImageSection;
    source: string | null;
    onContinue: (file: File, section: ImageSection) => void;
    openImageCropper: (file: File, section: ImageSection) => void;
}

const DisplayedImage = styled.img`
    align-self: center;
    max-width: 50%;
    max-height: 50%;
    object-fit: contain;
`;

const MessagePrompt = styled.span`
    margin-top: ${({ theme }) => theme.spacing(2)}px;
    margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`;

// 1 MB
const FILE_SIZE_WARNING_THRESHOLD = 1000000;

export function ImageSelectConfirmationModal({
    file,
    onContinue,
    open,
    openImageCropper,
    section,
    source,
}: ImageSelectConfirmationModalProps): JSX.Element {
    if (!file) {
        return <></>;
    }
    return (
        <DSDialog open={open}>
            <DSDialogTitle>
                <Translate keyId="BUILD.IMAGE_SELECTED" />
            </DSDialogTitle>
            <DSDialogContent style={{ display: 'flex', flexDirection: 'column' }}>
                {source && <DisplayedImage src={source} />}
                <MessagePrompt>
                    <Translate keyId="BUILD.IMAGE_CROPPER_PROMPT_MESSAGE" />
                </MessagePrompt>
                {file.size > FILE_SIZE_WARNING_THRESHOLD && (
                    <DSAlert severity="warning">
                        <Translate keyId="BUILD.EMAIL.IMAGE_EXCEEDS_RECOMMENDED_SIZE_WARNING" />
                    </DSAlert>
                )}
            </DSDialogContent>
            <DSDialogActions>
                <DSButton data-cy="crop-image-button" onClick={() => openImageCropper(file, section)}>
                    <Translate keyId="BUILD.IMAGE_CROPPER.CROP_IMAGE" />
                </DSButton>
                <DSButton
                    color="primary"
                    data-cy="continue-without-crop-button"
                    onClick={() => onContinue(file, section)}
                >
                    <Translate keyId="COMMON.CONTINUE" />
                </DSButton>
            </DSDialogActions>
        </DSDialog>
    );
}
ImageSelectConfirmationModal.displayName = 'ImageSelectConfirmationModal';

export const ImageSelectConfirmationModalAngular = withAngularIntegration(
    ImageSelectConfirmationModal,
    'imageSelectConfirmationModal',
    {
        file: OneWayBinding,
        onContinue: OneWayBinding,
        open: OneWayBinding,
        openImageCropper: OneWayBinding,
        section: OneWayBinding,
        source: OneWayBinding,
    },
);
