/// <reference path="../../../../typings/browser.d.ts" />
import * as linq from 'linq';
import { GET_NOTIFICATIONS_LIMIT, InAppNotification, inAppNotificationSocketEvent } from '@deltasierra/shared';
import { InteractionUtils } from '../../common/interactionUtils';
import { InAppNotificationApiClient } from '../inAppNotificationApiClient';
import { I18nService } from '../../i18n/i18nService';
import { BaseInAppNotificationsController } from '../baseInAppNotificationController';
import { $rootScopeSID, $scopeSID } from '../../common/angularData';
import { MvIdentity } from '../../account/mvIdentity';
import { SocketService } from '../../sockets/socketService';
import IPromise = angular.IPromise;
import IRootScopeService = angular.IRootScopeService;
import IScope = angular.IScope;

export class InAppNotificationsListController extends BaseInAppNotificationsController {
    static SID = 'inAppNotificationsListController';

    offset : {
        previousId: number;
        previousDate: Date;
    } | undefined = undefined;

    allLoaded = false;

    readonly fetchNotifications = this.interactionUtils.createFuture('fetch notifications',
        () => this.inAppNotificationApiClient.getNotifications(this.offset)
                .then((notifications : InAppNotification[]) => {
                    this.notifications.push(...notifications);
                    this.notifications.forEach(notification => {
                        if (notification.readAt === null) {
                            this.unreadNotificationsCount++;
                        }
                    });
                    if (notifications.length == GET_NOTIFICATIONS_LIMIT) {
                        const earliestNotification = notifications[notifications.length - 1];
                        this.offset = {
                            previousId: earliestNotification.id,
                            previousDate: earliestNotification.createdAt,
                        };
                    } else {
                        this.allLoaded = true;
                    }
                }));

    // Tslint:disable-next-line:member-ordering
    static readonly $inject : string[] = [
        $rootScopeSID,
        $scopeSID,
        I18nService.SID,
        InteractionUtils.SID,
        InAppNotificationApiClient.SID,
        MvIdentity.SID,
        SocketService.SID,
    ];

    constructor(
        $rootScope : IRootScopeService,
        $scope : IScope,
        i18nService : I18nService,
        interactionUtils : InteractionUtils,
        inAppNotificationApiClient : InAppNotificationApiClient,
        mvIdentity: MvIdentity,
        protected readonly socketService: SocketService,
    ) {
        super($rootScope, $scope, i18nService, interactionUtils, inAppNotificationApiClient, mvIdentity);
        void this.onInit(); // This is called automatically for components, but not directive controllers... how annoying!
    }

    onInit() {
        this.listenToMarkedAsReadEvent((event, data) => this.handleMarkedAsRead(data));
        this.listenToMarkedAllAsReadEvent(event => this.handleMarkedAllAsRead());
        return this.fetchNotifications.run({})
            .then(() => {
                this.socketService.on<InAppNotification>(inAppNotificationSocketEvent, this.socketHandler);
            });
    }

    $onDestroy() {
        this.socketService.off<InAppNotification>(inAppNotificationSocketEvent, this.socketHandler);
    }

    protected handleMarkedAsRead(notification : InAppNotification) : IPromise<void> | void {
        const existingNotification = linq.from(this.notifications)
            .firstOrDefault(n => n.id == notification.id);
        if (existingNotification) {
            existingNotification.readAt = new Date(); // This date is not entirely accurate, but it doesn't really matter here
            this.unreadNotificationsCount--;
        }
    }

    protected handleMarkedAllAsRead() : void {
        this.notifications.forEach(notification => {
            notification.readAt = new Date();
        });
        this.unreadNotificationsCount = 0;
    }

    loadMore() {
        return this.fetchNotifications.run({});
    }

    markAsRead(notification : InAppNotification) : IPromise<any> | void {
        if (!notification.readAt) {
            return this.submitMarkAsRead.run({ notification });
        }
    }

    markAsClicked(notification : InAppNotification) : IPromise<any> | void {
        if (!notification.clickedAt) {
            return this.submitMarkAsClicked.run({ notification });
        }
    }

    onClickNotification(notification: InAppNotification) : IPromise<void> | void {
        this.markAsRead(notification);
        if (notification.type !== 'Agency.CustomMessage') {
            this.markAsClicked(notification);
        }
    }
}

angular.module('app').controller(InAppNotificationsListController.SID, InAppNotificationsListController);
