/// <reference path="../../../typings/browser.d.ts" />
import {
    assertNever,
    BuilderDocument,
    ChannelDataValues,
    ChannelName,
    FacebookChannelData,
    InstagramChannelData,
    LinkedInChannelData,
    t,
    TermsAndConditionsChannelData,
    TextSubstitutionValues,
    TwitterChannelData,
} from '@deltasierra/shared';
import { TextSubstitutionService } from './common/textSubstitutionService';

export interface ChannelDataField<T> {
    label: string;
    path: keyof T;
    fieldType?: string;
}

export interface ChannelDataConfig<T> {
    label: string;
    channel: ChannelName;
    fields: Array<ChannelDataField<T>>;
}

interface ChannelDataConfigMap {
    facebook: ChannelDataConfig<FacebookChannelDataClass>;
    instagram: ChannelDataConfig<InstagramChannelDataClass>;
    linkedIn: ChannelDataConfig<LinkedInChannelDataClass>;
    twitter: ChannelDataConfig<TwitterChannelDataClass>;
    termsAndConditions: ChannelDataConfig<TermsAndConditionsChannelDataClass>;
}

export type ChannelDataByName<TChannelName> = TChannelName extends 'facebook'
    ? FacebookChannelData
    : TChannelName extends 'instagram'
    ? InstagramChannelData
    : TChannelName extends 'linkedIn'
    ? LinkedInChannelData
    : TChannelName extends 'twitter'
    ? TwitterChannelData
    : TChannelName extends 'termsAndConditions'
    ? TermsAndConditionsChannelData
    : never;

export class FacebookChannelDataClass implements FacebookChannelData {
    public caption: string | null = null;
}
export class InstagramChannelDataClass implements InstagramChannelData {
    public caption: string | null = null;
}
export class LinkedInChannelDataClass implements LinkedInChannelData {
    public caption: string | null = null;
}
export class TwitterChannelDataClass implements TwitterChannelData {
    public status: string | null = null;
}

export class TermsAndConditionsChannelDataClass implements TermsAndConditionsChannelData {
    public text: string | null = null;

    public url: string | null = null;
}

export type ChannelDataClass =
    | FacebookChannelDataClass
    | InstagramChannelDataClass
    | LinkedInChannelDataClass
    | TermsAndConditionsChannelData
    | TwitterChannelDataClass;

export default class ChannelDataService {
    public static SID = 'channelDataService';

    public static readonly $inject: string[] = [TextSubstitutionService.SID];

    private readonly channelDataConfigMap: ChannelDataConfigMap = Object.freeze<ChannelDataConfigMap>({
        facebook: {
            channel: 'facebook',
            fields: [
                {
                    fieldType: '',
                    label: 'Caption',
                    path: 'caption',
                },
            ],
            label: 'Facebook',
        },
        instagram: {
            channel: 'instagram',
            fields: [
                {
                    fieldType: '',
                    label: 'Caption',
                    path: 'caption',
                },
            ],
            label: 'Instagram',
        },
        linkedIn: {
            channel: 'linkedIn',
            fields: [
                {
                    fieldType: '',
                    label: 'Caption',
                    path: 'caption',
                },
            ],
            label: 'LinkedIn',
        },
        termsAndConditions: {
            channel: 'termsAndConditions',
            fields: [
                {
                    label: t('COMMON.TEXT'),
                    path: 'text',
                },
                {
                    label: t('COMMON.URL'),
                    path: 'url',
                },
            ],
            label: t('COMMON.TERMS_AND_CONDITIONS_WITH_SYMBOL'),
        },
        twitter: {
            channel: 'twitter',
            fields: [
                {
                    fieldType: '',
                    label: 'Status',
                    path: 'status',
                },
            ],
            label: 'Twitter',
        },
    });


    public readonly channelDataConfig = [
        this.channelDataConfigMap.facebook,
        this.channelDataConfigMap.instagram,
        this.channelDataConfigMap.linkedIn,
        this.channelDataConfigMap.twitter,
        this.channelDataConfigMap.termsAndConditions,
    ];
    /* eslint-enable @typescript-eslint/no-invalid-this */

    private readonly channelDataMap = Object.freeze({
        facebook: FacebookChannelDataClass,
        instagram: InstagramChannelDataClass,
        linkedIn: LinkedInChannelDataClass,
        termsAndConditions: TermsAndConditionsChannelDataClass,
        twitter: TwitterChannelDataClass,
    });

    public constructor(protected textSubstitutionService: TextSubstitutionService) {}

    public createChannelDatum(channelName: ChannelName): ChannelDataClass {
        const clz = this.channelDataMap[channelName];
        if (!clz) {
            throw new Error(`Unrecognised channel name: ${channelName}`);
        }
        return new clz();
    }

    public getChannelDatum<TChannelName extends keyof ChannelDataValues>(
        textSubstitutionValues: TextSubstitutionValues,
        document: BuilderDocument,
        channelName: TChannelName,
        propertyGetter: (channelData: ChannelDataByName<TChannelName>) => string | null,
    ): string | null {
        if (!document.channelData || !document.channelData[channelName]) {
            return null;
        } else {
            let channelData: ChannelDataClass | undefined;
            const channelName1: keyof ChannelDataValues = channelName;
            switch (channelName1) {
                case 'facebook':
                    channelData = document.channelData.facebook;
                    break;
                case 'instagram':
                    channelData = document.channelData.instagram;
                    break;
                case 'linkedIn':
                    channelData = document.channelData.linkedIn;
                    break;
                case 'termsAndConditions':
                    channelData = document.channelData.termsAndConditions;
                    break;
                case 'twitter':
                    channelData = document.channelData.twitter;
                    break;
                default:
                    throw assertNever(channelName1);
            }
            if (!channelData) {
                return null;
            } else {
                const value = propertyGetter(channelData as ChannelDataByName<TChannelName>);
                return this.textSubstitutionService.substitute(textSubstitutionValues, value);
            }
        }
    }
}

angular.module('app').service(ChannelDataService.SID, ChannelDataService);
