/// <reference path="../../../../typings/browser.d.ts" />
import { BillingDetailsUpdateData, BillingDetailsUpdateInviteData } from '@deltasierra/shared';
import { InteractionUtils } from '../../common/interactionUtils';
import { BillingDetailsUpdateService } from './billingDetailsUpdateService';
import ILocationService = angular.ILocationService;
import IWindowService = angular.IWindowService;
import IQService = angular.IQService;

export class MvBillingDetailsUpdateCtrl {
    public static SID = 'mvBillingDetailsUpdateCtrl';

    token: string | null = null;

    inviteData: BillingDetailsUpdateInviteData | undefined;

    billingDetailsUpdateData?: BillingDetailsUpdateData;

    loading = {
        redirect: false,
    };

    fetchInviteData = this.interactionUtils.createHttpFuture(
        'initialize billing details update',
        () => this.billingDetailsUpdateService.getInviteData(this.token!),
        {
            onError: err => {
                console.log(err); // Swallow error
                return this.$q.reject(err);
            },
        },
    );

    submitBillingDetailsUpdate = this.interactionUtils.createHttpFuture('submit billing details update', () =>
        this.billingDetailsUpdateService.submitBillingDetailsUpdate(this.token!, this.billingDetailsUpdateData!),
    );

    static readonly $inject: string[] = [
        '$location',
        '$window',
        '$q',
        InteractionUtils.SID,
        BillingDetailsUpdateService.SID,
    ];

    constructor(
        private $location: ILocationService,
        private $window: IWindowService,
        private $q: IQService,
        private interactionUtils: InteractionUtils,
        private billingDetailsUpdateService: BillingDetailsUpdateService,
    ) {
        this.token = this.getToken();
        if (this.token) {
            void this.getInviteData();
        }
    }

    private getToken() {
        const params = this.$location.search();
        if (params.token) {
            return params.token;
        }
        return null;
    }

    private getInviteData() {
        return this.fetchInviteData
            .run({})
            .catch(err => undefined)
            .then((inviteData: BillingDetailsUpdateInviteData | undefined) => {
                this.inviteData = inviteData;
                if (inviteData) {
                    this.mapInviteDataToUpdateData(inviteData);
                }
            });
    }

    private mapInviteDataToUpdateData(inviteData: BillingDetailsUpdateInviteData) {
        this.billingDetailsUpdateData = {
            country: inviteData.country,
            email: inviteData.username,
            firstName: inviteData.firstName,
            lastName: inviteData.lastName,
            title: inviteData.title,
            token: this.token!,
        };
    }

    public submit() {
        return this.submitBillingDetailsUpdate.run({}).then((result: { SharedPaymentUrl: string }) => {
            this.loading.redirect = true;
            this.$window.location.assign(result.SharedPaymentUrl);
        });
    }
}

angular.module('app').controller(MvBillingDetailsUpdateCtrl.SID, MvBillingDetailsUpdateCtrl);
