import * as React from 'react';
import { NotifierOptions } from '../notifier';
import { NotifierNotificationComponentType } from '../notifier-notification';

export type NotifierDispatchNotificationContextType =
    | ((component: NotifierNotificationComponentType, options?: NotifierOptions) => void)
    | undefined;

export const NotifierDispatchNotificationContext =
    React.createContext<NotifierDispatchNotificationContextType>(undefined);
NotifierDispatchNotificationContext.displayName = 'NotifierDispatchNotificationContext';
