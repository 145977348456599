/// <reference path="../../../typings/browser.d.ts" />
import {
    ClientId,
    CreateLocationCategoryOptionBody,
    justParams,
    LocationCategoryGroupId,
    LocationCategoryOption,
    LocationCategoryOptionApi,
    LocationCategoryOptionId,
    paramsAndBody,
} from '@deltasierra/shared';
import { $httpSID } from '../common/angularData';
import { invokeApiRoute } from '../common/httpUtils';

export class LocationCategoryOptionApiClient {
    static readonly SID: string = 'locationCategoryOptionApiClient';

    static readonly $inject: string[] = [$httpSID];

    constructor(protected readonly $http: ng.IHttpService) {}

    createLocationCategoryOption(
        clientId: ClientId,
        groupId: LocationCategoryGroupId,
        body: CreateLocationCategoryOptionBody,
    ): ng.IPromise<LocationCategoryOption> {
        return invokeApiRoute(
            this.$http,
            LocationCategoryOptionApi.createLocationCategoryOption,
            paramsAndBody(
                {
                    clientId,
                    groupId,
                },
                body,
            ),
        );
    }

    deleteLocationCategoryOption(
        clientId: ClientId,
        groupId: LocationCategoryGroupId,
        optionId: LocationCategoryOptionId,
    ) {
        return invokeApiRoute(
            this.$http,
            LocationCategoryOptionApi.deleteLocationCategoryOption,
            justParams({
                clientId,
                groupId,
                optionId,
            }),
        );
    }
}

angular.module('app').service(LocationCategoryOptionApiClient.SID, LocationCategoryOptionApiClient);
