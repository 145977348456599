import { justParams, paramsAndBody, PrintProviderShoppingCartOrdersApi, PrintProviderShoppingCartOrderId, ShoppingCartOrderWithItemCount, OrderStatus, PrintProviderShoppingCartItemId, PrintProviderShoppingCartItemsApi } from '@deltasierra/shared';


import { MvIdentity } from './../account/mvIdentity';
import { $httpSID } from './../common/angularData';
import { invokeApiRoute } from './../common/httpUtils';

export class ShoppingCartApiClient {
    static SID = 'ShoppingCartApiClient';

    static readonly $inject: string[] = [
        $httpSID,
        MvIdentity.SID,
    ];

    constructor(protected readonly $http: ng.IHttpService,
                protected readonly mvIdentity: MvIdentity) {
    }

    deleteShoppingCartItem(itemId: PrintProviderShoppingCartItemId, orderId: PrintProviderShoppingCartOrderId): ng.IPromise<void> {
        return invokeApiRoute(this.$http, PrintProviderShoppingCartItemsApi.deleteItem, justParams({ itemId, orderId }));
    }

    getOrdersWithItemCount(status: OrderStatus): ng.IPromise<ShoppingCartOrderWithItemCount[]> {
        return invokeApiRoute(this.$http, PrintProviderShoppingCartOrdersApi.getOrdersWithItemCount, justParams({ status }));
    }

    getOrder(orderId: PrintProviderShoppingCartOrderId) {
        return invokeApiRoute(this.$http, PrintProviderShoppingCartOrdersApi.getOrder, justParams({ orderId }));
    }

    getShoppingCartOrders(status: OrderStatus): ng.IPromise<PrintProviderShoppingCartOrderId[]> {
        return invokeApiRoute(this.$http, PrintProviderShoppingCartOrdersApi.getOrders, justParams({ status }));
    }

    updateItemQuantity(orderId: PrintProviderShoppingCartOrderId, itemId: PrintProviderShoppingCartItemId, quantity: number): ng.IPromise<void> {
        return invokeApiRoute(this.$http, PrintProviderShoppingCartItemsApi.updateItem, paramsAndBody({ orderId, itemId }, { quantity }));
    }
}

angular.module('app').service(ShoppingCartApiClient.SID, ShoppingCartApiClient);
