import { ButtonSection } from '@deltasierra/shared';

export function updateButtonSectionMsoHtmlLink(section: ButtonSection): ButtonSection {
    const { link, msoHtml } = section;
    if (msoHtml) {
        // Captures all href tags, like so: href=\"https://blah.com\"
        const hrefRegex = /href=(\S+)/;
        return {
            ...section,
            msoHtml: msoHtml.replace(hrefRegex, `href="${link}"`),
        };
    }
    return section;
}
