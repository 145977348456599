import {
    addTermsAndConditionsToCaption,
    AssignedLocation,
    BuilderTemplateId,
    calculateTwitterMaxCharacterLimit,
    ExportPlatforms,
    GalleryPlannerDetails,
    getDefaultScheduledTime,
    LocationIdHierarchy,
    MAX_TWEET_CHARACTER_LENGTH,
    TermsAndConditionsChannelData,
    TwitterChannelData,
} from '@deltasierra/shared';
import type { IScope } from 'angular';
import { $scopeSID } from '../../../common/angularData';
import { I18nService } from '../../../i18n';
import { TwitterPublishService } from '../../../integration/publish/twitterPublishService';
import { BuilderConstants, builderConstantsSID } from '../../builderConstants';
import { ContentBuilder } from '../../contentBuilder';
import { PublishImmediatelyCheckService } from '../common/publishImmediatelyCheck/service';
import ImagePublishService, { ImagePublishServiceCompletionEvent } from '../ImagePublishService';
import { MvContentBuilderPublishCtrl } from '../mvContentBuilderPublishCtrl';
import { PublishResult } from '../publishResult';
import {
    MultiImagePublishCallback,
    MultiImagePublishCallbackArgs,
    MultiImagePublishFlowCallbacks,
    PublishFinishCallback,
} from '../types';

interface MvContentBuilderPublishToTwitterCtrlScope extends IScope {
    ctrl: MvContentBuilderPublishCtrl;
}

const VIEWS = {
    PUBLISH_ERRORS: 'PUBLISH_ERRORS',
    PUBLISH_FORM: 'PUBLISH_FORM',
    PUBLISH_PROGRESS: 'PUBLISH_PROGRESS',
};

class CommonPublishData {
    public constructor(
        public status: string | null,
        public scheduledTime: Date | null,
        public templateId: BuilderTemplateId,
        public plannerId: number | null,
    ) {}
}

export class MvContentBuilderPublishToTwitterCtrl implements MultiImagePublishFlowCallbacks<CommonPublishData> {
    public static SID = 'mvContentBuilderPublishToTwitterCtrl';

    public ImageProcessingContext = {
        isProcessingOnServer: false,
    };

    public VIEWS = VIEWS;

    public view = VIEWS.PUBLISH_FORM;

    public publishCtrl!: MvContentBuilderPublishCtrl;

    public contentBuilder!: ContentBuilder;

    public templateId!: BuilderTemplateId;

    public location!: AssignedLocation;

    public chosenLocations!: LocationIdHierarchy[];

    public plannerDetails?: GalleryPlannerDetails | null = null;

    public isPublishing = false;

    public status!: string | null;

    public statusLabel: string = this.i18n.text.build.publish.twitter.tweet();

    public maxStatusLength: number = MAX_TWEET_CHARACTER_LENGTH;

    public isValidStatusLength = false;

    public termsAndConditionsText: string | null = null;

    public termsAndConditionsUrl: string | null = null;

    public isToBeScheduled = false;

    public scheduledTime: Date | null = null;

    public minDate = new Date();

    public commonData: CommonPublishData | null = null;

    public static readonly $inject: string[] = [
        $scopeSID,
        TwitterPublishService.SID,
        builderConstantsSID,
        ImagePublishService.SID,
        I18nService.SID,
        PublishImmediatelyCheckService.SID,
    ];

    public constructor(
        private $scope: MvContentBuilderPublishToTwitterCtrlScope,
        private twitterPublishService: TwitterPublishService,
        private builderConstants: BuilderConstants,
        private imagePublishService: ImagePublishService,
        private i18n: I18nService,
        private publishImmediatelyCheckService: PublishImmediatelyCheckService,
    ) {
        this.statusCharacterCounter = this.statusCharacterCounter.bind(this);
    }

    public $onInit(): void {
        this.populateInheritedProperties();
        if (this.plannerDetails?.date) {
            this.isToBeScheduled = true;
            this.scheduledTime = this.plannerDetails.date;
        }
        this.status = this.contentBuilder.getChannelDatum('twitter', (data: TwitterChannelData) => data.status);
        this.termsAndConditionsText = this.contentBuilder.getChannelDatum(
            'termsAndConditions',
            (data: TermsAndConditionsChannelData) => data.text,
        );
        this.termsAndConditionsUrl = this.contentBuilder.getChannelDatum(
            'termsAndConditions',
            (data: TermsAndConditionsChannelData) => data.url,
        );
        this.maxStatusLength = calculateTwitterMaxCharacterLimit(
            this.termsAndConditionsText,
            this.termsAndConditionsUrl,
            MAX_TWEET_CHARACTER_LENGTH,
        );
        this.checkIfValidStatusLength();
        this.scheduledTime = getDefaultScheduledTime(15, this.plannerDetails, 19);

        this.$scope.$watch(
            () => this.status,
            () => this.checkIfValidStatusLength(),
        );
    }

    public cancel(): void {
        this.$scope.$emit(this.builderConstants.EVENTS.PUBLISH_CANCEL);
    }

    public finish(): void {
        const publishResult = new PublishResult(
            ExportPlatforms.Twitter,
            this.isToBeScheduled ? this.scheduledTime : null,
        );
        this.$scope.$emit(this.builderConstants.EVENTS.PUBLISH_PUBLISHED, publishResult);
    }

    public getDoFinish(): PublishFinishCallback {
        return this.finish.bind(this);
    }

    public onClickPublish(): void {
        if (this.isToBeScheduled) {
            this.publishImmediatelyCheckService.checkTimeDifferenceAndOptionallyShowConfirmModal(
                this.scheduledTime!,
                () => {
                    this.scheduledTime = null;
                    this.isToBeScheduled = false;
                    this.publish();
                },
                () => this.publish(),
            );
        } else {
            this.publish();
        }
    }

    public publish(): void {
        const optionalPlannerId = this.plannerDetails ? this.plannerDetails.id : null;
        if (this.termsAndConditionsText || this.termsAndConditionsUrl) {
            this.status = addTermsAndConditionsToCaption(
                this.status,
                this.termsAndConditionsText,
                this.termsAndConditionsUrl,
            );
        }
        this.commonData = new CommonPublishData(this.status, this.scheduledTime, this.templateId, optionalPlannerId);
        this.isPublishing = true;
    }

    public getDoPublish(): MultiImagePublishCallback<CommonPublishData> {
        return this.doPublish.bind(this);
    }

    public statusCharacterCounter(text: string): number {
        return text.length;
    }

    private populateInheritedProperties() {
        this.publishCtrl = this.$scope.ctrl;
        this.contentBuilder = this.publishCtrl.contentBuilder;
        this.templateId = this.publishCtrl.templateId;
        this.location = this.publishCtrl.location;
        this.chosenLocations = this.publishCtrl.locations;
        this.plannerDetails = this.publishCtrl.plannerDetails;
    }

    private async doPublish(args: MultiImagePublishCallbackArgs<CommonPublishData>): Promise<void> {
        const { commonData, locationGraphqlId, locationId, publishedArtifactGroupId, uploadIds } = args;
        const scheduledTime = this.isToBeScheduled ? commonData.scheduledTime || undefined : undefined;
        const channelData = {
            scheduledTime,
            status: commonData.status || null,
        };
        const canPostToTwitter = await this.twitterPublishService.canPostToTwitterPageForLocation(locationId);
        if (!canPostToTwitter) {
            throw new Error(this.i18n.text.build.publish.twitter.accessCheckFailure());
        } else {
            const eventToWaitFor = this.isToBeScheduled
                ? ImagePublishServiceCompletionEvent.PlannerCreated
                : ImagePublishServiceCompletionEvent.Published;
            return this.imagePublishService.publishMultiImage(
                this.ImageProcessingContext,
                locationGraphqlId,
                uploadIds,
                commonData.templateId,
                this.plannerDetails || null,
                channelData,
                async input => this.twitterPublishService.publishPhoto(input),
                this.publishCtrl.contentBuilder.linkedAssetLibraryAsset.map(value => ({
                    layerId: value.layerId,
                    legacyAssetId: value.asset.id,
                })),
                publishedArtifactGroupId,
                scheduledTime,
                eventToWaitFor,
            );
        }
    }

    private checkIfValidStatusLength(): void {
        const characterCount = this.statusCharacterCounter(this.status || '');
        this.isValidStatusLength = characterCount <= this.maxStatusLength;
    }
}

angular.module('app').controller(MvContentBuilderPublishToTwitterCtrl.SID, MvContentBuilderPublishToTwitterCtrl);
