/// <reference path="../../../typings/browser.d.ts" />
import { ILocationService, IPromise, IRootScopeService } from 'angular';
import { IntroService } from '../intro/introService';
import { $locationSID, $rootScopeSID } from '../common/angularData';
import { UserService } from './userService';
import { MvIdentity } from './mvIdentity';

export class IntroStatusController {
    public static readonly SID = 'mvIntroStatusCtrl';

    public static readonly $inject: string[] = [$rootScopeSID, $locationSID, MvIdentity.SID, IntroService.SID, UserService.SID];

    public showTourStatus = false;

    public constructor(
        private readonly $rootScope: IRootScopeService,
        private readonly $location: ILocationService,
        private readonly mvIdentity: MvIdentity,
        private readonly introService: IntroService,
        private readonly userService: UserService,
    ) {
        this.$rootScope.$on('$routeChangeSuccess', this.updateStatus.bind(this));
    }

    public updateStatus(): void {
        this.showTourStatus =
            !this.mvIdentity.isConnectToAccess() &&
            !!this.mvIdentity.isGuidedTourEnabled() &&
            !this.introService.isAnyIntroActive() &&
            this.$location.path() !== '/intro';
    }

    public returnToTour(): IPromise<any> {
        return this.userService
            .updateGuidedTour({
                guidedTourEnabled: true,
                guidedTourPostponed: false,
            })
            .then(() => {
                this.mvIdentity.setGuidedTourPostponed(false);
                this.$location.url('/intro');
            });
    }
}

angular.module('app').controller(IntroStatusController.SID, IntroStatusController);
