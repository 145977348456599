import { gql } from '@apollo/client';

export const GET_IS_FACEBOOK_FIRST_COMMENT_HASHTAGS_ENABLED = gql`
    query GetIsFacebookFirstCommentHashtagsEnabled {
        config {
            id
            features {
                publishing {
                    facebookFirstCommentHashtags
                }
            }
        }
    }
`;
