import {
    AgencyId,
    ICreateOrUpdatePrintProviderSKUBody,
    ICreatePrintProviderSKUGroupBody,
    IUpdatePrintProviderSKUGroupBody,
    justParams,
    paramsAndBody,
    PrintProviderDtoWithBasicClientInfo,
    PrintProviderSKUGroupId,
    PrintProviderSKUGroupsApi,
    PrintProviderSKUId,
    PrintProviderSKUsApi,
} from '@deltasierra/shared';
import { MvIdentity } from '../../account/mvIdentity';
import { $httpSID } from '../../common/angularData';
import { invokeApiRoute } from '../../common/httpUtils';

export class SKUManagementApiClient {
    static SID = 'SKUManagementApiClient';

    static readonly $inject: string[] = [$httpSID, MvIdentity.SID];

    constructor(protected readonly $http: ng.IHttpService, protected readonly mvIdentity: MvIdentity) {}

    createSKU(newSKUData: ICreateOrUpdatePrintProviderSKUBody) {
        const agencyId: AgencyId = this.mvIdentity.currentUser!.agencyId;
        return invokeApiRoute(
            this.$http,
            PrintProviderSKUsApi.createSKU,
            paramsAndBody(
                {
                    agencyId,
                },
                newSKUData,
            ),
        );
    }

    deleteSKU(printProviderSKUId: PrintProviderSKUId) {
        const agencyId: AgencyId = this.mvIdentity.currentUser!.agencyId;
        return invokeApiRoute(this.$http, PrintProviderSKUsApi.deleteSKU, justParams({ agencyId, printProviderSKUId }));
    }

    getSKU(printProviderSKUId: PrintProviderSKUId) {
        const agencyId: AgencyId = this.mvIdentity.currentUser!.agencyId;
        return invokeApiRoute(this.$http, PrintProviderSKUsApi.getSKU, justParams({ agencyId, printProviderSKUId }));
    }

    updateSKU(printProviderSKUId: PrintProviderSKUId, updatedSKUData: ICreateOrUpdatePrintProviderSKUBody) {
        const agencyId: AgencyId = this.mvIdentity.currentUser!.agencyId;
        return invokeApiRoute(
            this.$http,
            PrintProviderSKUsApi.updateSKU,
            paramsAndBody(
                {
                    agencyId,
                    printProviderSKUId,
                },
                updatedSKUData,
            ),
        );
    }

    getSKUQuantityOptions(printProviderSKUId: PrintProviderSKUId) {
        const agencyId: AgencyId = this.mvIdentity.currentUser!.agencyId;
        return invokeApiRoute(
            this.$http,
            PrintProviderSKUsApi.getSKUQuantityOptions,
            justParams({ agencyId, printProviderSKUId }),
        );
    }

    createSKUGroup(newPrintProviderSKUGroupData: ICreatePrintProviderSKUGroupBody) {
        const agencyId: AgencyId = this.mvIdentity.currentUser!.agencyId;
        return invokeApiRoute(
            this.$http,
            PrintProviderSKUGroupsApi.createSKUGroup,
            paramsAndBody(
                {
                    agencyId,
                },
                newPrintProviderSKUGroupData,
            ),
        );
    }

    deleteSKUGroup(printProviderSKUGroupId: PrintProviderSKUGroupId) {
        const agencyId: AgencyId = this.mvIdentity.currentUser!.agencyId;
        return invokeApiRoute(
            this.$http,
            PrintProviderSKUGroupsApi.deleteSKUGroup,
            justParams({ agencyId, printProviderSKUGroupId }),
        );
    }

    getPrintProviders(): ng.IPromise<PrintProviderDtoWithBasicClientInfo[]> {
        const agencyId: AgencyId = this.mvIdentity.currentUser!.agencyId;
        return invokeApiRoute(this.$http, PrintProviderSKUGroupsApi.getPrintProviders, justParams({ agencyId }));
    }

    getSKUGroup(printProviderSKUGroupId: PrintProviderSKUGroupId) {
        const agencyId: AgencyId = this.mvIdentity.currentUser!.agencyId;
        return invokeApiRoute(
            this.$http,
            PrintProviderSKUGroupsApi.getSKUGroup,
            justParams({ agencyId, printProviderSKUGroupId }),
        );
    }

    getSKUGroupName(printProviderSKUGroupId: PrintProviderSKUGroupId): ng.IPromise<{ name: string }> {
        const agencyId: AgencyId = this.mvIdentity.currentUser!.agencyId;
        return invokeApiRoute(
            this.$http,
            PrintProviderSKUGroupsApi.getSKUGroupName,
            justParams({ agencyId, printProviderSKUGroupId }),
        );
    }

    getSKUGroups() {
        const agencyId: AgencyId = this.mvIdentity.currentUser!.agencyId;
        return invokeApiRoute(this.$http, PrintProviderSKUGroupsApi.getSKUGroups, justParams({ agencyId }));
    }

    getSKUGroupSKUs(printProviderSKUGroupId: PrintProviderSKUGroupId) {
        const agencyId: AgencyId = this.mvIdentity.currentUser!.agencyId;
        return invokeApiRoute(
            this.$http,
            PrintProviderSKUGroupsApi.getSKUGroupSKUs,
            justParams({ agencyId, printProviderSKUGroupId }),
        );
    }

    getMappedSKUFormatOptions(printProviderSKUGroupId: PrintProviderSKUGroupId) {
        const agencyId: AgencyId = this.mvIdentity.currentUser!.agencyId;
        return invokeApiRoute(
            this.$http,
            PrintProviderSKUGroupsApi.getMappedSKUFormatOptions,
            justParams({ agencyId, printProviderSKUGroupId }),
        );
    }

    updateSKUGroup(
        printProviderSKUGroupId: PrintProviderSKUGroupId,
        updatedSKUGroupData: IUpdatePrintProviderSKUGroupBody,
    ) {
        const agencyId: AgencyId = this.mvIdentity.currentUser!.agencyId;
        return invokeApiRoute(
            this.$http,
            PrintProviderSKUGroupsApi.updateSKUGroup,
            paramsAndBody(
                {
                    agencyId,
                    printProviderSKUGroupId,
                },
                updatedSKUGroupData,
            ),
        );
    }
}

angular.module('app').service(SKUManagementApiClient.SID, SKUManagementApiClient);
