/// <reference path="../../../../typings/browser.d.ts" />
import { LocationIdHierarchy, Nullable, ReportSummaryEditDto, Untyped } from '@deltasierra/shared';
import { $kookiesSID, $locationSID, $routeParamsSID, IKookies, IRouteParams } from '../../common/angularData';
import { DataUtils } from '../../common/dataUtils';
import { MvNotifier } from '../../common/mvNotifier';
import { I18nService } from '../../i18n';
import { mvReportSummaryResourceSID } from './mvReportSummaryResource';
import ILocationService = angular.ILocationService;
import IAngularEvent = angular.IAngularEvent;

export class MvReportSummaryEditCtrl {
    public static readonly $inject: string[] = [
        $locationSID,
        $routeParamsSID,
        $kookiesSID,
        MvNotifier.SID,
        DataUtils.SID,
        I18nService.SID,
        mvReportSummaryResourceSID,
    ];

    public static SID = 'mvReportSummaryEditCtrl';

    public locations: LocationIdHierarchy[] = [];

    public reportSummary!: Nullable<ReportSummaryEditDto>;

    public datePickerIsOpen = {
        endDate: false,
        startDate: false,
    };

    public loading = {
        reportSummary: false,
    };


    public constructor(
        private readonly $location: ILocationService,
        $routeParams: IRouteParams,
        private readonly $kookies: IKookies,
        private readonly mvNotifier: MvNotifier,
        private readonly dataUtils: DataUtils,
        private readonly i18n: I18nService,
        private readonly mvReportSummaryResource: Untyped,
    ) {
        this.createPlaceholderModel();
        if ($routeParams.reportSummaryId) {
            this.loadReportSummary(parseInt($routeParams.reportSummaryId, 10));
        } else {
            this.createPlaceholderModel();
            this.setDefaultDateRange();
            this.setDefaultLocation();
        }
    }

    public updateSelectedLocations(locations: LocationIdHierarchy[]): void {
        this.locations = locations;
        const ids = this.dataUtils.pluckByGetter(location => location.locationId, locations);
        this.reportSummary.locationIds = ids;
    }

    public openDatePicker(
        $event: IAngularEvent,
        datePickerName: keyof MvReportSummaryEditCtrl['datePickerIsOpen'],
    ): void {
        $event.preventDefault();
        if ($event.stopPropagation) {
            $event.stopPropagation();
        }
        this.datePickerIsOpen[datePickerName] = true;
    }

    public saveReportSummary(): void {
        const startDate = this.reportSummary.startDate;
        const endDate = this.reportSummary.endDate;
        if (!startDate || isNaN(startDate.getTime())) {
            this.mvNotifier.expectedError(this.i18n.text.report.invalidStartDate());
            return;
        }
        if (!endDate || isNaN(endDate.getTime())) {
            this.mvNotifier.expectedError(this.i18n.text.report.invalidEndDate());
            return;
        }
        if (endDate < startDate) {
            this.mvNotifier.expectedError(this.i18n.text.report.reportSummaryText.invalidDateRange());
            return;
        }
        const params = {};
        if (this.reportSummary.id) {
            (params as Untyped).id = this.reportSummary.id;
        }
        this.mvReportSummaryResource.save(
            params,
            this.reportSummary,
            () => {
                this.mvNotifier.notify(this.i18n.text.report.reportSummaryText.saved());
                this.$location.path('/reports');
            },
            (err: Untyped) => {
                this.mvNotifier.unexpectedErrorWithData('Failed to save report summary', err);
            },
        );
    }

    public deleteReportSummary(): void {
        const params = {};
        if (this.reportSummary.id) {
            (params as Untyped).id = this.reportSummary.id;
        }
        this.mvReportSummaryResource.delete(
            params,
            () => {
                this.mvNotifier.notify(this.i18n.text.report.reportSummaryText.deleted());
                this.$location.path('/reports');
            },
            (err: Untyped) => {
                this.mvNotifier.unexpectedErrorWithData('Failed to delete report summary', err);
            },
        );
    }

    public cancel(): void {
        this.$location.path('/reports');
    }

    private loadReportSummary(reportSummaryId: number): void {
        this.mvReportSummaryResource.get(
            {
                id: reportSummaryId,
            },
            (reportSummary: Nullable<ReportSummaryEditDto>) => {
                this.reportSummary = reportSummary;
            },
            (err: Untyped) => {
                this.mvNotifier.unexpectedErrorWithData('Failed to retrieve report summary', err);
            },
        );
    }

    private createPlaceholderModel(): void {
        this.reportSummary = {
            endDate: null,
            id: null,
            locationIds: [],
            startDate: null,
            text: null,
            title: null,
        };
    }

    private setDefaultDateRange(): void {
        const lastMonth = new Date();
        lastMonth.setDate(0);
        const startDate = new Date(lastMonth.getFullYear(), lastMonth.getMonth());
        const endDate = new Date(lastMonth.getFullYear(), lastMonth.getMonth() + 1);
        endDate.setSeconds(-1);
        this.reportSummary.startDate = startDate;
        this.reportSummary.endDate = endDate;
    }

    private setDefaultLocation(): void {
        const currentLocationId = this.$kookies.get('plannerLocation', Number);
        if (currentLocationId) {
            this.reportSummary.locationIds!.push(currentLocationId);
        }
    }
}

angular.module('app').controller(MvReportSummaryEditCtrl.SID, MvReportSummaryEditCtrl);
