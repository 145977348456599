import * as React from 'react';
import { getService, InjectableServices } from '../angularData';
import { MvNotifier } from '../mvNotifier';

type InitializedServiceContextsStore = {
    [K in keyof Partial<InjectableServices>]: React.Context<InjectableServices[K]>;
};

/**
 * This should store all initialised service contexts. Feel free to manually specify any.
 */
const initialisedServiceContexts: InitializedServiceContextsStore = {
    [MvNotifier.SID]: React.createContext(new MvNotifier()),
};

/**
 * Utility function for converting angular services into react contexts.
 *
 * @see {@link AngularInjectedServices} for a list of supported services. You may need to add any missing services.
 * @param sid - The ID of the service to retrieve
 * @returns The service matching the given service ID
 */
export function getAngularServiceContext<TServiceId extends keyof InjectableServices>(
    sid: TServiceId,
): React.Context<InjectableServices[TServiceId]> {
    if (!(sid in initialisedServiceContexts)) {
        const context = React.createContext(getService(sid));
        // TODO: Fix this type coercion
        initialisedServiceContexts[sid] = context as any;
    }
    // TODO: Fix this type coercion
    return initialisedServiceContexts[sid] as any;
}

/**
 * Override the default angular service resolution with the provided service context.
 *
 * @see {@link AngularInjectedServices} for a list of supported services. You may need to add any missing services.
 * @param sid - The service ID of the service to register
 * @param context - The React context to register
 */
export function registerAngularServiceContext<TServiceId extends keyof InjectableServices>(
    sid: TServiceId,
    context: React.Context<InjectableServices[TServiceId]>,
): void {
    initialisedServiceContexts[sid] = context as any;
}

/**
 * A wrapper for React.useContext to allow loading of AngularJS services.
 *
 * @see {@link AngularInjectedServices} for a list of supported services. You may need to add any missing services.
 * @param sid - The SID for the service to load
 * @returns The React context for the given service
 */
export function useAngularServiceContext<TServiceId extends keyof InjectableServices>(
    sid: TServiceId,
): InjectableServices[TServiceId] {
    return React.useContext(getAngularServiceContext(sid));
}
