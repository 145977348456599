/// <reference path="../../_references.d.ts" />
import { ScheduleTimePickerController } from './controller';

export const dsScheduledTimePickerConfig: ng.IDirective<ng.IScope> = {
    require: 'ngModel',
    templateUrl: '/partials/directives/scheduledTimePicker/template',
    restrict: 'E',
    replace: true,
    scope: {
        scheduledTime: '=',
        isScheduled: '=',
        timezone: '=',
        minDate: '=?',
    },
    controller: ScheduleTimePickerController,
    controllerAs: 'ctrl',
    bindToController: true,
};
export const dsScheduledTimePickerSID = 'dsScheduledTimePicker';

angular.module('app').directive(dsScheduledTimePickerSID, [() => dsScheduledTimePickerConfig]);
