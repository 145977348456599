import * as React from 'react';
import { assertNever } from '@deltasierra/shared';
import { createFailedState, createFinishedState, createLoadingState, createPendingState, FutureState } from './types';

export type FutureStateAction<TValue, TError = any> =
    { type: 'FAILED'; payload: TError } | { type: 'FINISHED'; payload: TValue } | { type: 'RESET' } | { type: 'START' };

/**
 * A wrapped for tracking the error, result, and state of an async operation. This has the advantage of setting each state item
 * at once which should reduce re-renders.
 *
 * @example
 * const MyComponent = () => {
 *     const [state, dispatch] = useFutureStateReducer<string>();
 *     React.useEffect(() => {
 *         if (state.isLoading) {
 *             fetchData()
 *                 .then(data => dispatch({ payload: data, type: 'FINISHED' })
 *                 .catch(error => dispatch({ payload: error, type: 'FAILED' });
 *         }
 *     }, [dispatch, state]);
 *     return (
 *         <>
 *             {state.isPending && <button onClick={() => dispatch({ type: 'START' })}>Load</button>}
 *             {state.isLoading && <span>Loading...</span>}
 *             {state.isFinished && <span>state.value</span>}
 *             {state.isFailed && <span>Error!</span>}
 *         </>
 *     )
 * };
 * @param initialState - Any default values for the state
 * @returns - The state and a reducer to update the state
 * @see createFutureStateReducer
 */
export function useFutureStateReducer<TValue, TError = any>(
    initialState: FutureState<TValue, TError> = createPendingState<TValue, TError>(),
): [FutureState<TValue, TError>, React.Dispatch<FutureStateAction<TValue, TError>>] {
    type TState = FutureState<TValue, TError>;
    type TAction = FutureStateAction<TValue, TError>;
    return React.useReducer((state: TState, action: TAction): TState => {
        switch (action.type) {
            case 'START':
                if (state.isLoading) {
                    // Return state if 'START' action is dispatched while state is currently loading to prevent extra rerenders.
                    return state;
                } else {
                    return createLoadingState(state);
                }
            case 'FINISHED':
                return createFinishedState(action.payload, state);
            case 'FAILED':
                return createFailedState(action.payload, state);
            case 'RESET':
                return createPendingState(state);
            default:
                throw assertNever(action);
        }
    }, initialState);
}
