import * as React from 'react';
import { Translate } from '@deltasierra/components';
import { OneWayBinding, OptionalExpressionBinding, OptionalOneWayBinding } from '../../../common/angularData';
import { withAngularIntegration } from '../../../common/componentUtils/reactComponentRegistration';
import { DeleteTemplateConfirmationModal } from './DeleteTemplateConfirmationModal';

export type DeleteTemplateButtonProps = {
    builderTemplateId: string;
    onClick?: () => void;
    disabled?: boolean;
};

export function DeleteTemplateButton({ builderTemplateId, disabled, onClick }: DeleteTemplateButtonProps): JSX.Element {
    const [showModal, setShowModal] = React.useState(false);

    const handleClickDelete: React.MouseEventHandler = () => {
        setShowModal(true);
    };

    const handleConfirmDelete = () => {
        setShowModal(false);
        onClick?.();
    };

    const handleCancel = () => {
        setShowModal(false);
    };

    return (
        <>
            <button
                className="btn btn-danger form-control"
                disabled={disabled}
                type="button"
                onClick={handleClickDelete}
            >
                <Translate keyId="COMMON.DELETE" />
            </button>
            <DeleteTemplateConfirmationModal
                builderTemplateId={builderTemplateId}
                show={showModal}
                onCancel={handleCancel}
                onConfirm={handleConfirmDelete}
            />
        </>
    );
}

export default withAngularIntegration(DeleteTemplateButton, 'deleteTemplateButton', {
    builderTemplateId: OneWayBinding,
    disabled: OptionalOneWayBinding,
    onClick: OptionalExpressionBinding,
});
