import * as React from 'react';
import { DSCardMedia, Tooltip } from '@deltasierra/components';
import { gql } from '@apollo/client';
import styled from 'styled-components';
import { t } from '@deltasierra/shared';
import { PlatformLogo } from '../../../platforms/components/PlatformLogo';
import { SubChannel } from '../../../../../__graphqlTypes/globalTypes';
import PhoneIcon from '../../../../img/phoneframe-white-background.svg';
import { PlatformIconAndTemplateThumbnailFragment } from './__graphqlTypes/PlatformIconAndTemplateThumbnailFragment';

const IconWrapper = styled.div`
    position: relative;
    margin-right: 16px;
    width: 26px;
    height: 26px;
`;

const IconAbsoluteWrapper = styled.div`
    position: absolute;
`;

const PlayButtonOverlay = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 28px;
`;

const fragments = {
    PlatformIconAndTemplateThumbnailFragment: gql`
        fragment PlatformIconAndTemplateThumbnailFragment on PostArtifact {
            id
            type
            subChannel
            platform {
                id
                name
            }
            thumbnails {
                medium {
                    url
                }
            }
        }
    `,
};

const StyledMobileAppSubChannelWrapper = styled.div`
    position: absolute;
    top: -20px;
    right: -2px;
`;

export type PlatformIconAndTemplateThumbnailProps = {
    post: PlatformIconAndTemplateThumbnailFragment;
    useLink?: boolean;
};

export function PlatformIconAndTemplateThumbnail({
    post,
    useLink,
}: PlatformIconAndTemplateThumbnailProps): JSX.Element {
    const iconTip = post.subChannel === SubChannel.MOBILE_APP ? t('SCHEDULE.SCHEDULED_WITH_MOBILE_APP') : false;
    return (
        <div style={{ alignItems: 'center', display: 'flex' }}>
            <Tooltip tip={iconTip}>
                <IconWrapper>
                    {post.subChannel === SubChannel.MOBILE_APP && (
                        <StyledMobileAppSubChannelWrapper>
                            <span>
                                <img src={PhoneIcon} width={31} />
                            </span>
                        </StyledMobileAppSubChannelWrapper>
                    )}
                    <IconAbsoluteWrapper>
                        <PlatformLogo name={post.platform.name} size={26} />
                    </IconAbsoluteWrapper>
                </IconWrapper>
            </Tooltip>

            {useLink ? (
                <a href="#">
                    <TemplateThumbnailForScheduled post={post} />
                </a>
            ) : (
                <TemplateThumbnailForScheduled post={post} />
            )}
        </div>
    );
}
PlatformIconAndTemplateThumbnail.displayName = 'PlatformIconAndTemplateThumbnail';
PlatformIconAndTemplateThumbnail.fragments = fragments;

function TemplateThumbnailForScheduled({ post }: { post: PlatformIconAndTemplateThumbnailFragment }): JSX.Element {
    return (
        <div style={{ display: 'flex', height: '120px', position: 'relative', width: '120px' }}>
            <DSCardMedia
                component="img"
                src={post.thumbnails?.medium.url ?? ''}
                style={{ display: 'block', margin: 'auto', maxHeight: '100%', maxWidth: '100%' }}
            />
            {post.type === 'video' && (
                <PlayButtonOverlay>
                    <i className="fas fa-play" />
                </PlayButtonOverlay>
            )}
        </div>
    );
}
