/// <reference path="../_references.d.ts" />
import {
    assertNever,
    CurrencyDefinitions,
    MoneyFilter,
    MoneyFilterFormat,
    MoneyFilterOptions,
} from '@deltasierra/shared';
export type { MoneyFilterOptions, MoneyFilter, MoneyFilterFormat };

export const moneyFilterSID = 'money';

angular.module('app').filter(moneyFilterSID, [
    '$filter',
    'currencyDefinitions',
    ($filter: ng.IFilterService, currencies: CurrencyDefinitions) => {
        const formatNumber = $filter('number');

        const DEFAULT_FORMAT = 'symbol';
        const DEFAULT_FRACTION_SIZE = 2;

        function formatMoney(value: number, options?: MoneyFilterOptions): string {
            const currencyCode = options && options.code;
            const format = (options && options.format) || DEFAULT_FORMAT;
            const fractionSize = (options && options.fractionSize) || DEFAULT_FRACTION_SIZE;

            const currencyDefinition = !!currencyCode && currencies[currencyCode];

            const sign = value < 0 ? '-' : '';
            const formattedAmount = formatNumber(Math.abs(value), fractionSize);

            // If the currency definition is undefined, we can't display currencies properly, so default to showing the number format.
            if (!currencyDefinition) {
                if (format == 'code' && currencyCode) {
                    return `${sign + formattedAmount} ${currencyCode.toUpperCase()}`;
                } else {
                    return sign + formattedAmount;
                }
            }

            switch (format) {
                case 'number':
                    return sign + formattedAmount;

                case 'symbol':
                    return (
                        sign +
                        currencyDefinition.symbol.template
                            .replace('1', formattedAmount)
                            .replace('$', currencyDefinition.symbol.grapheme || '')
                    );

                case 'uniqueSymbol':
                    return (
                        sign +
                        currencyDefinition.uniqSymbol.template
                            .replace('1', formattedAmount)
                            .replace('$', currencyDefinition.uniqSymbol.grapheme || '')
                    );

                case 'code':
                    return `${sign + formattedAmount} ${currencyCode!.toUpperCase()}`;

                default:
                    throw assertNever(format);
            }
        }

        return formatMoney;
    },
]);
