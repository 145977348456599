import campaignMonitorPublishService = require('./campaignMonitorPublishService');
import facebookPublishService = require('./facebookPublishService');
import localPublishService = require('./localPublishService');
import twitterPublishService = require('./twitterPublishService');
import instagramPublishService = require('./instagramPublishService');

export {
    campaignMonitorPublishService,
    facebookPublishService,
    localPublishService,
    twitterPublishService,
    instagramPublishService,
};
