/// <reference path="../../../../typings/browser.d.ts" />
import {
    AssignedLocation,
    ClientId,
    Currency,
    getDaysSincePrevious,
    getDaysUntilNext,
    isRecurringPaymentDeletable,
    isRecurringPaymentEditable,
    isRecurringPaymentNextDateInactive,
    LocationId,
    RecurringPaymentListDto,
    Untyped,
} from '@deltasierra/shared';
import * as linq from 'linq';
import moment from 'moment-timezone';
import { InteractionUtils } from '../../common/interactionUtils';
import { MvNotifier } from '../../common/mvNotifier';
import { I18nService } from '../../i18n';
import { MvLocation } from '../../locations/mvLocation';
import { CurrencyService } from '../../payments/currencyService';
import { LocationSubscription } from './models';

import ILocationService = angular.ILocationService;
import IQService = angular.IQService;
import IPromise = angular.IPromise;

export class MvLocationSubscriptionDetailCtrl {
    public static SID = 'mvLocationSubscriptionDetailCtrl';

    public static readonly $inject: string[] = [
        '$q',
        '$routeParams',
        '$route',
        '$location',
        'mvNotifier',
        MvLocation.SID,
        InteractionUtils.SID,
        CurrencyService.SID,
        I18nService.SID,
    ];

    public CURRENCIES!: Currency[] | ReadonlyArray<Currency>;

    public clientId!: ClientId;

    public locationId!: LocationId;

    public location?: AssignedLocation;

    public locationSubscription?: LocationSubscription;

    public recurringPayments?: RecurringPaymentListDto[];

    public nextRecurringPayment?: RecurringPaymentListDto;

    public fetchLocation = this.interactionUtils.createFuture('retrieve location', () =>
        this.mvLocation.getAssignedLocation(this.locationId).then((location: AssignedLocation) => {
            this.location = location;
        }),
    );

    public fetchLocationSubscription = this.interactionUtils.createFuture('retrieve location subscription', () =>
        this.mvLocation.getSubscription(this.locationId).then((locationSubscription: LocationSubscription) => {
            this.locationSubscription = locationSubscription;
        }),
    );

    public fetchRecurringPayments = this.interactionUtils.createFuture('retrieve location recurring payments', () =>
        this.mvLocation.getRecurringPayments(this.locationId).then(recurringPayments => {
            this.recurringPayments = recurringPayments;
        }),
    );

    public submitDeleteRecurringPayment = this.interactionUtils.createFuture(
        'delete recurring payment',
        (context: { recurringPaymentId: number }) =>
            this.mvLocation.deleteRecurringPayment(this.locationId, context.recurringPaymentId),
    );

    public submitUpdateLocationSubscription = this.interactionUtils.createHttpFuture(
        'update location subscription',
        () => this.mvLocation.updateSubscription(this.locationId, this.locationSubscription!),
    );

    public isRecurringPaymentDeletable = isRecurringPaymentDeletable;

    public isRecurringPaymentEditable = isRecurringPaymentEditable;


    public constructor(
        private $q: IQService,
        private $routeParams: Untyped,
        private $route: Untyped,
        private $location: ILocationService,
        private mvNotifier: MvNotifier,
        private mvLocation: MvLocation,
        private interactionUtils: InteractionUtils,
        private currencyService: CurrencyService,
        private i18n: I18nService,
    ) {
        void this.onInit();
    }

    public onInit(): IPromise<undefined | void> {
        this.CURRENCIES = this.currencyService.CURRENCIES;
        this.clientId = ClientId.from(parseInt(this.$routeParams.client_id, 10));
        this.locationId = LocationId.from(parseInt(this.$routeParams.location_id, 10));
        return this.$q.all([this.fetchLocation.run({}), this.fetchRecurringPayments.run({})]).then(() => {
            if (this.recurringPayments!.length === 0) {
                return this.fetchLocationSubscription.run({});
            } else {
                this.findNextRecurringPayment();
                // eslint-disable-next-line promise/no-return-wrap
                return Promise.resolve();
            }
        });
    }

    public getDaysSincePrevious(rp: RecurringPaymentListDto): number {
        return getDaysSincePrevious(rp, this.recurringPayments!);
    }

    public getDaysUntilNext(rp: RecurringPaymentListDto): number {
        return getDaysUntilNext(rp, this.recurringPayments!);
    }

    public updateSubscription(): IPromise<void> {
        return this.submitUpdateLocationSubscription.run({}).then(() => {
            this.mvNotifier.notify(this.i18n.text.admin.subscriptions.notifySubscriptionUpdated());
            this.$location.url(`/admin/subscriptions/clients/${this.location!.clientId}/locations`);
        });
    }

    public getAmount(recurringPayment: RecurringPaymentListDto): number {
        return this.currencyService.getLargestUnit(recurringPayment.currency, recurringPayment.amountInCents);
    }

    public getNextAmount(recurringPayment: RecurringPaymentListDto): number {
        return this.currencyService.getLargestUnit(recurringPayment.currency, recurringPayment.nextAmountInCents);
    }

    public isNextRecurringPayment(recurringPayment: RecurringPaymentListDto): boolean {
        return recurringPayment == this.nextRecurringPayment;
    }

    public addNewRecurringPayment(): void {
        this.$location.url(
            `/admin/subscriptions/clients/${this.location!.clientId}/locations/${
                this.locationId
            }/recurringPayments/add`,
        );
    }

    public deleteRecurringPayment(recurringPayment: RecurringPaymentListDto): IPromise<any> {
        return this.submitDeleteRecurringPayment.run({ recurringPaymentId: recurringPayment.id }).then(() => {
            this.mvNotifier.notify(this.i18n.text.admin.subscriptions.location.notifyRecurringPaymentDeleted());
            this.$route.reload();
        });
    }

    public isRecurringPaymentInactive(recurringPayment: RecurringPaymentListDto): boolean {
        return (
            !this.isRecurringPaymentEditable(recurringPayment) || isRecurringPaymentNextDateInactive(recurringPayment)
        );
    }

    protected findNextRecurringPayment(): void {
        this.nextRecurringPayment = linq.from(this.recurringPayments!).lastOrDefault(
            rp =>
                moment().isBefore(rp.nextDate) && // Exclude things whose end date is on or before the next date
                (!rp.endDate || moment(rp.nextDate).isBefore(rp.endDate)),
            undefined,
        );
    }
}

angular.module('app').controller(MvLocationSubscriptionDetailCtrl.SID, MvLocationSubscriptionDetailCtrl);
