import * as React from 'react';
import styled from 'styled-components';
import { CreateFolderButton } from '../CreateFolderButton';
import { UploadAssetFilesButton } from '../UploadAssetFilesButton';
import { EditCollectionButton } from '../EditCollectionButton';
import { EditFolderButton } from '../EditFolderButton';
import { useCurrentAssetContext } from '../../contexts';

export const Spacer = styled.div`
    margin-left: 8px;
`;

export interface UpdateButtonsProps {
    backId: string | null;
    collectionId: string;
    currentAssetId: string;
    currentAssetTitle: string;
}

/**
 * UpdateButtons
 * This component houses a few buttons for editing and navigating particular assets
 * Right now it doesn't check any permissions, that is handled upstream.  This might change.
 * It does conditionally render the `up folder` button on whether there is a folder to up.
 *
 * @param props - UpdateButtonsProps
 * @param props.backId - The parent of the current asset (used for clicking the `up folder` button)
 * @param props.collectionId - The id of the rootmost parent asset (collection).  Used for the edit collection modal
 * @param props.currentAssetId - The id of the currently selected folder or collection
 * @returns UpdateButtons
 */
export function UpdateButtons(props: UpdateButtonsProps): JSX.Element {
    const { backId, collectionId, currentAssetId, currentAssetTitle } = props;
    return (
        <>
            <Spacer>
                {!backId ? (
                    <EditCollectionButton collectionId={collectionId} />
                ) : (
                    <EditFolderButton
                        assetOrCollectionId={currentAssetId}
                        backId={backId}
                        currentAssetTitle={currentAssetTitle}
                    />
                )}
            </Spacer>
            <Spacer>
                <UploadAssetFilesButton folderOrCollectionId={currentAssetId} />
            </Spacer>
            <Spacer>
                <CreateFolderButton assetOrCollectionId={currentAssetId} />
            </Spacer>
        </>
    );
}
UpdateButtons.displayName = 'UpdateButtons';

interface BackButtonProps {
    backId: string;
}

export function BackButton(props: BackButtonProps): JSX.Element {
    const [, setCurrentAsset] = useCurrentAssetContext();
    return (
        <button
            className="btn btn-sm btn-link"
            data-cy="back-button"
            style={{ padding: 12 }}
            onClick={() => setCurrentAsset(props.backId)}
        >
            <i className="fas fa-level-up-alt text-muted" title="Back" />
        </button>
    );
}
BackButton.displayName = 'BackButton';
