/// <reference path="../../../typings/browser.d.ts" />

import { getData } from '../common/httpUtils';

export interface BasePaymentData {
    email?: string;
    businessName?: string;
    phoneNumber?: string;
    currency?: string;
    amount?: number;
    invoiceDescription: string;
    r?: string; // Redirect
}

export interface PaymentData extends BasePaymentData {
    amount: number;
    currency: string;
    specialRequestId?: number;
}

export interface MapPaymentData extends BasePaymentData {
    locationMapId: number;
    serviceCostId: number;
}

export interface StartPaymentResponse {
    SharedPaymentUrl: string;
}

export class PaymentService {
    public static SID = 'mvPaymentService';

    public static readonly $inject: string[] = ['$http'];

    public constructor(private $http: ng.IHttpService) { }

    public startPayment(paymentData: PaymentData): ng.IPromise<StartPaymentResponse> {
        return this.$http.post<StartPaymentResponse>('/api/payments', paymentData).then(getData);
    }
}

angular.module('app').service(PaymentService.SID, PaymentService);
