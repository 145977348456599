// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../static/img/loading.gif", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../../../static/img/loading-small.gif", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading{background:rgba(0,0,0,0) url(${___CSS_LOADER_URL_REPLACEMENT_0___}) center center no-repeat;width:128px;height:128px;margin:0 auto}.loading-small{display:inline-block;background:rgba(0,0,0,0) url(${___CSS_LOADER_URL_REPLACEMENT_1___}) center center no-repeat;width:32px;height:32px;vertical-align:bottom}`, "",{"version":3,"sources":["webpack://./../../dist/libraries/components/src/Generic/Loaders/Loading/Loading.scss"],"names":[],"mappings":"AAAA,SACI,wFAAA,CACA,WAAA,CACA,YAAA,CACA,aAAA,CAGJ,eACI,oBAAA,CACA,wFAAA,CACA,UAAA,CACA,WAAA,CACA,qBAAA","sourcesContent":[".loading {\n    background: transparent url('../../../../static/img/loading.gif') center center no-repeat;\n    width: 128px;\n    height: 128px;\n    margin: 0 auto;\n}\n\n.loading-small {\n    display: inline-block;\n    background: transparent url('../../../../static/img/loading-small.gif') center center no-repeat;\n    width: 32px;\n    height: 32px;\n    vertical-align: bottom;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
