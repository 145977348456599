import { useControlledSearchInput } from '@deltasierra/react-hooks';
import { BuilderTemplateId } from '@deltasierra/shared';
import { isNullOrUndefined } from '@deltasierra/type-utilities';
import * as React from 'react';

import { useValidateTemplateId } from '../../../campaign/components/CampaignEventForm/TemplateOrCategoryPicker/TemplateSelect/extract-id-from-template-url';
import { ValidationState } from '../../../campaign/components/CampaignEventForm/TemplateOrCategoryPicker/values/validation-state.enum';

function getInitialUrl(id: number | null) {
    if (isNullOrUndefined(id)) {
        return '';
    }
    return `${window.location.origin}/contentBuilder?template=${id}`;
}

export function useTemplateSelect(
    initialId: BuilderTemplateId | null,
    onChange?: (value: BuilderTemplateId | null) => void,
): [
    inputProps: { value: string; onChange: React.ChangeEventHandler<HTMLInputElement> },
    state: ValidationState,
    thumbnail?: string,
] {
    // Whether the hook is validating, valid, or invalid
    const [validation, setValidation] = React.useState(ValidationState.None);
    const [thumbnail, setThumbnail] = React.useState<string | undefined>(undefined);
    const onChangeRef = React.useRef(onChange);

    const validate = useValidateTemplateId();

    // Runs validation and sets state
    const handleValidateTemplate = React.useCallback(
        async (url: string) => {
            if (url === '') {
                setValidation(ValidationState.None);
                setThumbnail(undefined);
                onChangeRef.current?.(null);
                return;
            }
            const result = await validate(url);
            if (result.success) {
                setValidation(ValidationState.Valid);
                setThumbnail(result.value.thumbnail);
                onChangeRef.current?.(BuilderTemplateId.from(result.value.id));
            } else {
                // Template doesn't exist or url was invalid (or something wlse went wrong ie network)
                setValidation(ValidationState.Invalid);
                setThumbnail(undefined);
                onChangeRef.current?.(null);
            }
        },
        [validate],
    );

    // Input props (with validation) fro the text field
    const { inputProps, searchTerm } = useControlledSearchInput({
        initialValue: getInitialUrl(initialId),
        interval: 300,
    });

    React.useEffect(() => {
        void handleValidateTemplate(searchTerm);
    }, [handleValidateTemplate, searchTerm]);

    return [inputProps, validation, thumbnail];
}
