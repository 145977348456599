import { LocaleMenuController } from './localeMenuController';

export function LocaleMenuDirective() {
    return {
        restrict: 'E',
        replace: true,
        scope: {},
        templateUrl: '/partials/account/localeMenu/template',
        controller: LocaleMenuController,
        controllerAs: 'ctrl',
    };
}

angular.module('app').directive('dsLocaleMenu', [LocaleMenuDirective]);
