/// <reference path="../../../typings/browser.d.ts" />
import { ClientId, LocationId, LocationIdHierarchy, Untyped, User, UserId } from '@deltasierra/shared';
import { $routeParamsSID, $routeSID } from '../common/angularData';
import { DataUtils } from '../common/dataUtils';
import { TabNavEntry } from '../common/dsTabNav';
import { InteractionUtils } from '../common/interactionUtils';
import { MvNotifier } from '../common/mvNotifier';
import { I18nService } from '../i18n/i18nService';
import { LocationUserApiClient } from '../locations/locationUserApiClient';
import { MvLocation } from '../locations/mvLocation';
import { MvClient } from './mvClient';
import {
    ClientUserExpectedRouteParams,
    ClientUserIds,
    extractIdsFromRouteParams,
    getTabNavEntries,
} from './mvClientUserEditCtrl';

interface LocationEntry extends LocationIdHierarchy {
    selected?: boolean;
}

export class MvClientUserLocationsCtrl {
    public static SID = 'mvClientUserLocationsCtrl';

    clientId?: ClientId;

    locationId?: LocationId;

    userId?: UserId;

    user?: User;

    getUser = this.interactionUtils.createFuture<User, { userId: UserId }>('retrieve user', context =>
        this.mvClient.getUser(context.userId),
    );

    locations?: LocationIdHierarchy[];

    getLocations = this.interactionUtils.createFuture<LocationIdHierarchy[], { userId: UserId }>(
        'retrieve assigned locations',
        context => this.mvLocation.getAssignedLocationsForUser(context.userId),
    );

    saveLocations = this.interactionUtils.createFuture<void, { locationIds: LocationId[]; userId: UserId }>(
        'assign locations for user',
        context => this.locationUserApiClient.setUserLocations(context.userId, context.locationIds),
    );

    tabNavEntries: ReadonlyArray<TabNavEntry> | TabNavEntry[] = [];

    tabNavParams: ClientUserIds;

    static readonly $inject: string[] = [
        $routeSID,
        $routeParamsSID,
        InteractionUtils.SID,
        DataUtils.SID,
        MvNotifier.SID,
        MvClient.SID,
        MvLocation.SID,
        LocationUserApiClient.SID,
        I18nService.SID,
    ];

    constructor(
        private $route: Untyped,
        $routeParams: ClientUserExpectedRouteParams,
        private interactionUtils: InteractionUtils,
        private dataUtils: DataUtils,
        private mvNotifier: MvNotifier,
        private mvClient: MvClient,
        private mvLocation: MvLocation,
        private locationUserApiClient: LocationUserApiClient,
        private i18n: I18nService,
    ) {
        const ids = extractIdsFromRouteParams($routeParams);
        this.userId = ids.userId;
        this.tabNavParams = ids;
        this.tabNavEntries = getTabNavEntries(this.i18n, ids);
        void this.getUser
            .run({
                userId: this.userId!,
            })
            .then(user => this.user = user);
        void this.getLocations
            .run({
                userId: this.userId!,
            })
            .then((locations: LocationIdHierarchy[]) => this.locations = locations);
    }

    updateSelectedLocations(locations: LocationIdHierarchy[]) {
        this.locations = locations;
    }

    clickSave() {
        const locationIds = this.locations ? this.locations.map(location => location.locationId) : [];
        return this.saveLocations.run({ locationIds, userId: this.userId! }).then(() => {
            this.mvNotifier.notify(this.i18n.text.agency.client.location.users.locationsUpdated());
            this.$route.reload();
        });
    }

    clickRemove(location: LocationIdHierarchy) {
        (location as LocationEntry).selected = false;
        this.dataUtils.removeObject(location, this.locations!);
    }
}

angular.module('app').controller(MvClientUserLocationsCtrl.SID, MvClientUserLocationsCtrl);
