import React from 'react';
import { t } from '@deltasierra/shared';
import { FormikProps } from 'formik';
import { Translate } from '@deltasierra/components';
import { StyledDSTextField, StyledDSAlert } from '../../../../common';
import { Textarea } from '../../../../../../../../../common/form/Textarea';
import { PublishToClubReadyStep1FormValues } from '../../../types/publish-to-club-ready-step-1-form-values';

type ClubReadyCustomEmailListOptionProps = {
    formik: FormikProps<PublishToClubReadyStep1FormValues>;
};

export function ClubReadyCustomEmailListOption({ formik }: ClubReadyCustomEmailListOptionProps): JSX.Element {
    return (
        <>
            <StyledDSTextField
                error={!!formik.errors.listDescription}
                helperText={formik.errors.listDescription}
                placeholder={t('BUILD.PUBLISH.CLUB_READY.CUSTOM_LIST_DESCRIPTION_PLACEHOLDER')}
                value={formik.values.listDescription ?? ''}
                variant="outlined"
                onChange={event => formik.setFieldValue('listDescription', event.target.value)}
            />
            <Textarea
                initialValue={formik.values.emailList}
                label={t('BUILD.PUBLISH.CLUB_READY.PASTE_EMAIL_ADDRESSES_BELOW')}
                rows={4}
                onChange={value => {
                    formik.setFieldValue('emailList', value);
                }}
            />
            {formik.errors.emailList && <StyledDSAlert severity="error">{formik.errors.emailList}</StyledDSAlert>}
            <StyledDSAlert severity="warning">
                <Translate keyId="BUILD.PUBLISH.CLUB_READY.CUSTOM_LIST_OF_EMAIL_ADDRESSES_WARNING" />
            </StyledDSAlert>
        </>
    );
}
ClubReadyCustomEmailListOption.displayName = 'ClubReadyCustomEmailListOption';
