import * as React from 'react';
import { DSWell, Loading } from '@deltasierra/components';
import { gql, useQuery } from '@apollo/client';
import { t } from '@deltasierra/shared';
import { selectedAssets } from '../../../graphql';
import { SortAssetsInput } from '../../../../../__graphqlTypes/globalTypes';
import { GeneralErrorMessage } from '../CollectionErrorComponents';
import { SearchBarWithButton } from '../SearchBar/SearchBarWithButton';
import { CollectionAssetsWithData, ViewOption } from './CollectionAssetsWithData';
import { CollectionAssetsData } from './CollectionAssetsData';
import {
    GetCollectionForCollectionAssets,
    GetCollectionForCollectionAssetsVariables,
} from './__graphqlTypes/GetCollectionForCollectionAssets';
import { LocationFragmentForCollectionAssets } from './__graphqlTypes/LocationFragmentForCollectionAssets';

const GET_COLLECTION = gql`
    query GetCollectionForCollectionAssets($id: ID!, $search: String, $sort: SortAssetsInput) {
        collectionOrAsset(id: $id) {
            __typename
            ... on AssetFolder {
                id
                ...FolderForAssetList
                assets(filter: { search: $search }, sort: $sort) {
                    ...AssetConnectionForAssetList
                }
            }
            ... on Collection {
                id
                ...CollectionForAssetList
                assets(filter: { search: $search }, sort: $sort) {
                    ...AssetConnectionForAssetList
                }
                assetCount
            }
        }
    }
    ${CollectionAssetsWithData.fragments.ASSET_CONNECTION_FOR_ASSET_LIST}
    ${CollectionAssetsWithData.fragments.COLLECTION_FOR_ASSET_LIST}
    ${CollectionAssetsWithData.fragments.FOLDER_FOR_ASSET_LIST}
`;

const fragments = {
    LOCATION_FRAGMENT_FOR_COLLECTION_ASSETS: gql`
        fragment LocationFragmentForCollectionAssets on Location {
            id
            title
            client {
                id
                title
            }
        }
    `,
};

export interface CollectionAssetsProps {
    currentAsset: string;
    enableLightboxPreview: boolean;
    location: LocationFragmentForCollectionAssets;
}

/**
 * CollectionAssets
 * This is the main display container for the asset library, and where we actually query for the asset connection.
 * It is provided a folder or collection id, and queries and displays all children assets of that folder or collection
 * Alternatively, if a search term is provided...
 * It will search and filter all assets and sub folders for a text match
 *
 * This is split into a parent container that mostly provides a shell for the graphql query, handling loading, etc...
 * And a child component `CollectionAssetsWithData` that assumes that the data exists and should be displayed
 * This minimizes conditionals and confusing logic within the component
 *
 * @param props - CollectionAssetsProps
 * @param props.enableLightboxPreview - Should users be allowed to view files in a lightbox modal
 * @param props.currentAsset - The current asset as a prop to ensure that it is defined and to allow flexibility
 * @param props.location - The current location and its associated data
 * @returns CollectionAssets
 */
export function CollectionAssets({
    currentAsset,
    enableLightboxPreview,
    location,
}: CollectionAssetsProps): JSX.Element {
    const [searchTerm, setSearchTerm] = React.useState('');
    const [sort, setSort] = React.useState<SortAssetsInput | null>(null);

    const [viewOption, setViewOption] = React.useState<ViewOption>('thumbnail');

    React.useEffect(() => {
        selectedAssets([]);
    }, [searchTerm]);

    const { data, error, loading } = useQuery<
        GetCollectionForCollectionAssets,
        GetCollectionForCollectionAssetsVariables
    >(GET_COLLECTION, {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first',
        notifyOnNetworkStatusChange: true,
        variables: {
            id: currentAsset,
            search: searchTerm,
            sort,
        },
    });

    const searchBarPlaceholder =
        location.title && location.client.title
            ? `${t('COMMON.SEARCH_ASSETS')} - ${location.client.title} - ${location.title}`
            : '';

    return (
        <>
            <SearchBarWithButton
                initialValue={searchTerm}
                placeholder={searchBarPlaceholder}
                onSearch={setSearchTerm}
            />
            <DSWell style={{ position: 'relative' }}>
                {!!data && (
                    <CollectionAssetsData
                        collectionData={data}
                        enableLightboxPreview={enableLightboxPreview}
                        setSort={setSort}
                        setViewOption={setViewOption}
                        sort={sort}
                        viewOption={viewOption}
                    />
                )}
                {loading && <Loading />}
                {error && <GeneralErrorMessage />}
            </DSWell>
        </>
    );
}
CollectionAssets.displayName = 'CollectionAssets';
CollectionAssets.fragments = fragments;
