/// <reference path="../../../typings/browser.d.ts" />
import { AgencyUserDto, coerceDate, Untyped } from '@deltasierra/shared';
import { MvIdentity } from '../account/mvIdentity';
import { AgencyUserApiClient } from '../agencies/agencyUserApiClient';
import { MvClientResource, mvClientResourceSID } from '../clients/mvClientResource';
import { $locationSID, $qSID, $routeParamsSID, $scopeSID, IKookies } from '../common/angularData';
import { $modalSID } from '../common/angularUIBootstrapData';
import { DataUtils } from '../common/dataUtils';
import { InteractionUtils } from '../common/interactionUtils';
import { MvNotifier } from '../common/mvNotifier';
import { UploadService } from '../common/uploadService';
import { I18nService } from '../i18n/i18nService';
import { MvLocation } from '../locations/mvLocation';
import { CurrencyService } from '../payments/currencyService';
import { ModalService } from '../typings/angularUIBootstrap/modalService';
import { BaseSpecialRequestCtrl } from './BaseSpecialRequestCtrl';
import IScope = angular.IScope;
import ILocationService = angular.ILocationService;
import IQService = angular.IQService;

export class MvSpecialRequestDetailsCtrl extends BaseSpecialRequestCtrl {
    public static readonly SID = 'mvSpecialRequestDetailsCtrl';

    public static readonly $inject: string[] = [
        $scopeSID,
        $locationSID,
        $routeParamsSID,
        $qSID,
        '$kookies',
        $modalSID,
        UploadService.SID,
        MvNotifier.SID,
        MvIdentity.SID,
        MvLocation.SID,
        'mvSpecialRequestResource',
        mvClientResourceSID,
        'confirmModal',
        DataUtils.SID,
        InteractionUtils.SID,
        CurrencyService.SID,
        I18nService.SID,
        AgencyUserApiClient.SID,
    ];

    public loading = {
        agencyManagers: false,
        currentSpecialRequest: false,
        deliverables: false,
        saveSpecialRequest: false,
    };

    public agencyManagers: AgencyUserDto[] | null = null;

    public displayAssignedToDropdown = false;


    public constructor(
        $scope: IScope,
        $location: ILocationService,
        protected $routeParams: Untyped,
        $q: IQService,
        $kookies: IKookies,
        $modal: ModalService,
        uploadService: UploadService,
        mvNotifier: MvNotifier,
        mvIdentity: MvIdentity,
        mvLocation: MvLocation,
        mvSpecialRequestResource: Untyped,
        mvClientResource: MvClientResource,
        confirmModal: Untyped,
        dataUtils: DataUtils,
        protected interactionUtils: InteractionUtils,
        currencyService: CurrencyService,
        i18n: I18nService,
        protected agencyUserApiClient: AgencyUserApiClient,
    ) {
        super(
            $scope,
            $location,
            $q,
            $kookies,
            $modal,
            uploadService,
            mvNotifier,
            mvIdentity,
            mvLocation,
            mvSpecialRequestResource,
            mvClientResource,
            confirmModal,
            dataUtils,
            currencyService,
            i18n,
        );
        const locationPromise = this.getLocations();
        void this.getStatuses();
        const specialRequestId = $routeParams.specialRequestId;
        const specialRequestPromise = this.getSpecialRequestDetails(specialRequestId, locationPromise);
        this.initAgencyManagers();

        void $q.all([locationPromise, specialRequestPromise]).then(() => {
            if (this.currentSpecialRequest) {
                return this.getClientForLocation(this.currentSpecialRequest.locationId);
            }
        });
    }

    public canSeeAssignedTo(): boolean {
        return this.mvIdentity.isManager();
    }

    public cancelSpecialRequest(): void {
        this.$location.path('/specialRequests');
    }

    public saveSpecialRequest(): ng.IPromise<unknown> {
        return this.interactionUtils
            .handleResource(
                this,
                this.i18n.text.requests.saveSpecialRequest(),
                'saveSpecialRequest',
                super.saveSpecialRequest(),
            )
            .then(data => this.handleSaveResponse(data));
    }

    public onAgencyManagerSelected(agencyManager: AgencyUserDto | undefined): void {
        if (this.currentSpecialRequest) {
            this.currentSpecialRequest.assignedToId = agencyManager ? agencyManager.id : null;
        }
    }

    private getSpecialRequestDetails(specialRequestId: Untyped, locationPromise: Untyped) {
        this.currentSpecialRequest = null;
        this.loading.currentSpecialRequest = true;
        return this.interactionUtils
            .handleResource(
                this,
                this.i18n.text.requests.retrieveSpecialRequest(),
                'currentSpecialRequest',
                this.mvSpecialRequestResource.get({ id: specialRequestId }),
            )
            .then(specialRequest => {
                specialRequest.dateRequiredBy = coerceDate(specialRequest.dateRequiredBy);
                this.currentSpecialRequest = specialRequest;
            });
    }

    private handleSaveResponse(data: Untyped) {
        this.currentSpecialRequest = data;
        if (this.currentSpecialRequest) {
            this.rectifySpecialRequestDate(this.currentSpecialRequest);
        }
    }

    private initAgencyManagers() {
        if (this.canSeeAssignedTo()) {
            this.displayAssignedToDropdown = true;
            this.loading.agencyManagers = true;
            this.agencyUserApiClient
                .getManagers()
                .then(data => {
                    this.agencyManagers = data;
                })
                .finally(() => {
                    this.loading.agencyManagers = false;
                })
                .catch(data => {
                    this.mvNotifier.unexpectedErrorWithData(this.i18n.text.requests.loadManagersFailed(), data);
                });
        }
    }
}

angular.module('app').controller(MvSpecialRequestDetailsCtrl.SID, MvSpecialRequestDetailsCtrl);
