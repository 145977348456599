import { DSAlert, DSCheckbox, DSTableCell, DSTableRow, RelativeTimestamp, Timestamp } from '@deltasierra/components';
import { useChecklistSetChecked } from '@deltasierra/react-hooks';
import React from 'react';
import { withStyles } from '@material-ui/core';
import { BlockedPublishRowAction } from './blocked-publish-row-action';
import { BlockedPublishesActions } from './BlockedPublishesActions';
import { BlockedPublishesContentCell } from './BlockedPublishesContentCell';
import { ScheduledPublishFragmentForBlockedPublishesTable } from './__graphqlTypes/ScheduledPublishFragmentForBlockedPublishesTable';

const StyledErrorMessage = withStyles({
    message: {
        fontSize: '10px',
        padding: '4px 0',
    },
})(DSAlert);

type BlockedPublishesTableRowProps = {
    checked: boolean;
    disableActions: boolean;
    pushToFailed: BlockedPublishRowAction;
    pushToSent: BlockedPublishRowAction;
    row: ScheduledPublishFragmentForBlockedPublishesTable;
    sendNow: BlockedPublishRowAction;
    setChecked: useChecklistSetChecked;
};

export function BlockedPublishesTableRow({
    checked,
    disableActions,
    pushToFailed,
    pushToSent,
    row,
    sendNow,
    setChecked,
}: BlockedPublishesTableRowProps): JSX.Element {
    return (
        <>
            <DSTableRow>
                <DSTableCell width={90}>
                    <DSCheckbox checked={checked} onChange={event => setChecked(event.target.checked, row.id)} />
                </DSTableCell>
                <DSTableCell>
                    <Timestamp date={row.lastUpdatedAt} format="long" />
                    <br />
                    {'('}
                    <RelativeTimestamp autoUpdate date={row.lastUpdatedAt} />
                    {')'}
                </DSTableCell>
                <DSTableCell>{row.location.client.title}</DSTableCell>
                <DSTableCell>{row.location.title}</DSTableCell>
                <DSTableCell>
                    <Timestamp date={row.targetScheduledTime} format="long" />
                    <br />
                    {'('}
                    <RelativeTimestamp autoUpdate date={row.targetScheduledTime} />
                    {')'}
                </DSTableCell>
                <BlockedPublishesContentCell row={row} />
                <DSTableCell>
                    <BlockedPublishesActions
                        disabled={disableActions}
                        pushToFailed={() => pushToFailed(row)}
                        pushToSent={() => pushToSent(row)}
                        sendNow={() => sendNow(row)}
                    />
                </DSTableCell>
            </DSTableRow>
            <DSTableRow>
                <DSTableCell colSpan={9}>
                    <StyledErrorMessage severity="error">{row.debugStatusMessage}</StyledErrorMessage>
                </DSTableCell>
            </DSTableRow>
        </>
    );
}
BlockedPublishesTableRow.displayName = 'BlockedPublishesTableRow';
