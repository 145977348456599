/* eslint-disable camelcase */
import { useQuery } from '@apollo/client';
import { relayConnectionToArray } from '../../../../graphql/utils';
import { GET_CLIENT_AND_LOCATION_HASHTAGS_QUERY } from './useGetHashtagSets.queries';
import {
    GetClientAndLocationHashtagsQuery,
    GetClientAndLocationHashtagsQueryVariables,
    GetClientAndLocationHashtagsQuery_location_client_hashtagSetsConnection_edges_node,
    GetClientAndLocationHashtagsQuery_location_hashtagSetsConnection_edges_node,
} from './__graphqlTypes/GetClientAndLocationHashtagsQuery';

export type ClientHashtagSet = GetClientAndLocationHashtagsQuery_location_client_hashtagSetsConnection_edges_node;
export type LocationHashtagSet = GetClientAndLocationHashtagsQuery_location_hashtagSetsConnection_edges_node;

export function useGetHashtagSets(locationId: string): {
    clientHashtags: ClientHashtagSet[];
    loading: boolean;
    locationHashtags: LocationHashtagSet[];
    refetch: () => void;
} {
    const { data, loading, refetch } = useQuery<
        GetClientAndLocationHashtagsQuery,
        GetClientAndLocationHashtagsQueryVariables
    >(GET_CLIENT_AND_LOCATION_HASHTAGS_QUERY, {
        nextFetchPolicy: 'cache-and-network',
        notifyOnNetworkStatusChange: true,
        variables: { locationId },
    });

    const locationHashtags = relayConnectionToArray(data?.location?.hashtagSetsConnection);
    const clientHashtags = relayConnectionToArray(data?.location?.client?.hashtagSetsConnection);
    return { clientHashtags, loading, locationHashtags, refetch };
}
