/// <reference path="../../../typings/browser.d.ts" />
import { Untyped } from '@deltasierra/shared';

export type ToggleSelectedForList<T> = (object : T) => void;

export type IsSelectedForList<T> = (object : T) => boolean | void;

export interface ListSelectionService {
    applyListSelectionMixins(scope : Untyped, name : string, idProperty : string, fnGetList : () => any[]): void;
}

export const listSelectionServiceSID = 'listSelectionService';

angular.module('app').service(listSelectionServiceSID, [function (): ListSelectionService {
    function applyListSelectionMixins(scope : Untyped, name : string, idProperty : string, fnGetList : () => any[]) {
        scope[`toggleSelectedFor${name}`] = function (object : Untyped) {
            const list = fnGetList();
            if (!list) {
                return;
            }
            for (let i = list.length - 1; i >= 0; i--) {
                const element = list[i];
                if (element[idProperty] == object[idProperty]) {
                    list.splice(i, 1);
                    return;
                }
            }
            list.push(object);
        };

        scope[`isSelectedFor${name}`] = function (object : Untyped) {
            const list = fnGetList();
            if (!list) {
                return;
            }
            for (const element of list) {
                if (element[idProperty] == object[idProperty]) {
                    return true;
                }
            }
            return false;
        };
    }

    return {
        applyListSelectionMixins,
    };
}]);
