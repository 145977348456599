import { BannerNotificationsSocketService } from './bannerNotificationsSocketService';
import { NotificationsSocketService } from './notificationsSocketService';
import { SocketService } from './socketService';

/**
 * Simply an umbrella service that ensures all the services that respond to socket events get imported and instantiated
 */
export class SocketServicesContainer {
    public static SID = 'SocketServicesContainer';

    public static readonly $inject: string[] = [NotificationsSocketService.SID, BannerNotificationsSocketService.SID];

    public constructor(
        private readonly notifications: NotificationsSocketService,
        private readonly bannerNotificationSocketService: BannerNotificationsSocketService,
    ) {}

    /**
     * Why do we call this in a function and in controllers we import SocketService directly?
     * Well, whatever we register here, will be registered as soon as the socket is connected.
     * !!!Before it recieves any data!!!
     * This means if data is sent as soon as you hit the backend, it will pick up on it. This is good
     * for global broadcasted events, but not so much events in a step by step process, for that,
     * you can register it before you expect a value returned, and then, only once the value is asked for
     * you will handle the response.
     *
     * @param socketService - The socket service instance
     */
    public onSocketConnected(socketService: SocketService): void {
        this.notifications.registerSocketListeners(socketService);
        this.bannerNotificationSocketService.registerSocketListeners(socketService);
    }
}

angular.module('app').service(SocketServicesContainer.SID, SocketServicesContainer);
