/* eslint-disable max-lines */
/* eslint-disable max-statements, max-lines-per-function, complexity */
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { filterUniqueBy } from '@deltasierra/array-utilities';
import {
    AssetShareTarget,
    AssetShareTargetGroup,
    ClientPicker,
    DSButton,
    DSDialog,
    DSDialogActions,
    DSDialogContent,
    DSDialogTitle,
    DSFormControl,
    DSFormControlLabel,
    DSGrid,
    DSLoadMoreButton,
    DSPaper,
    DSRadio,
    DSRadioGroup,
    DSTextField,
    DSTypography,
    ErrorMessage,
    Loading,
    LocationPicker,
    SelectedClientsOrLocations,
    Translate,
} from '@deltasierra/components';
import { useControlledSearchInput } from '@deltasierra/react-hooks';
import { DEFAULT_CLIENT_COLLECTION_LIMIT, DEFAULT_LOCATION_COLLECTION_LIMIT, t } from '@deltasierra/shared';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import * as React from 'react';
import { CreateCollectionInput } from '../../../../../__graphqlTypes/globalTypes';
import { useAngularServiceContext } from '../../../common/componentUtils/angularServiceContexts';
import { useConnectionFetchMore } from '../../../graphql/hooks';
import { relayConnectionToArray } from '../../../graphql/utils';
import { useCurrentAssetContext } from '../../contexts';
import { InitialValues, useHandleValidation } from './useHandleValidation';
import { ClientFragmentForCreateCollectionModal } from './__graphqlTypes/ClientFragmentForCreateCollectionModal';
import {
    CreateCollectionForCreateCollectionModal,
    CreateCollectionForCreateCollectionModalVariables,
} from './__graphqlTypes/CreateCollectionForCreateCollectionModal';
import {
    GetClientsForCreateCollectionModal,
    GetClientsForCreateCollectionModalVariables,
} from './__graphqlTypes/GetClientsForCreateCollectionModal';
import {
    GetCurrentClientForCreateCollectionModal,
    GetCurrentClientForCreateCollectionModalVariables,
} from './__graphqlTypes/GetCurrentClientForCreateCollectionModal';
import {
    GetLocationsForCreateCollectionModal,
    GetLocationsForCreateCollectionModal_me_locations_edges_node,
} from './__graphqlTypes/GetLocationsForCreateCollectionModal';
import { NewCollection } from './__graphqlTypes/NewCollection';

const CLIENT_FRAGMENT_FOR_CREATE_COLLECTION_MODAL = gql`
    fragment ClientFragmentForCreateCollectionModal on Client {
        id
        title
        legacyId
        collectionLimits {
            current
            max
        }
    }
`;

const GET_CLIENTS_FOR_CREATE_COLLECTION_MODAL_QUERY = gql`
    query GetClientsForCreateCollectionModal($after: String, $first: Int, $title: String) {
        me {
            id
            agency {
                id
                clients(after: $after, first: $first, filter: { title: $title }) {
                    edges {
                        node {
                            id
                            ...ClientFragmentForCreateCollectionModal
                        }
                    }
                    pageInfo {
                        hasNextPage
                        endCursor
                    }
                }
            }
        }
    }
    ${CLIENT_FRAGMENT_FOR_CREATE_COLLECTION_MODAL}
`;

const GET_CURRENT_CLIENT_FOR_CREATE_COLLECTION_MODAL_QUERY = gql`
    query GetCurrentClientForCreateCollectionModal($id: ID!) {
        client(id: $id) {
            id
            ...ClientFragmentForCreateCollectionModal
        }
    }
    ${CLIENT_FRAGMENT_FOR_CREATE_COLLECTION_MODAL}
`;

const GET_LOCATIONS_FOR_CREATE_COLLECTION_MODAL_QUERY = gql`
    query GetLocationsForCreateCollectionModal($locationId: ID!, $hasLocation: Boolean! = false) {
        me {
            id
            locations(first: 10000) {
                edges {
                    node {
                        id
                        title
                        client {
                            id
                        }
                        collectionLimits {
                            current
                            max
                        }
                    }
                }
            }
        }
        hasCurrentLocation @client @export(as: "hasLocation")
        currentLocationId @client @export(as: "locationId")
        location(id: $locationId) @include(if: $hasLocation) {
            id
            canCreateCollection
            client {
                canCreateCollection
                id
            }
        }
    }
`;

const CREATE_COLLECTION_MUTATION = gql`
    mutation CreateCollectionForCreateCollectionModal($input: CreateCollectionInput!) {
        createCollection(input: $input) {
            __typename
            ... on Collection {
                id
                title
                sharedWith {
                    __typename
                }
            }
        }
    }
`;

export type CreateCollectionModalProps = {
    show: boolean;
    onClose: () => void;
};

export const CreateCollectionModal: React.FC<CreateCollectionModalProps> = ({ onClose, show }) => (
    <DSDialog fullWidth maxWidth="md" open={show} onClose={onClose}>
        <DSDialogTitle>{t('COMMON.NEW_COLLECTION')}</DSDialogTitle>
        {show && <CreateCollectionModalContent onClose={onClose} />}
    </DSDialog>
);
CreateCollectionModal.displayName = 'CreateCollectionModal';

type CreateCollectionModalContentProps = {
    onClose: () => void;
};

// OptTitleField - an 'optimized' form field that debounces sending the change event to formik
// Explain: No one likes this hack less than me, but for now, the rerenders formik was causing when you type
// A title into the text field was slowing down the UI.  We really cannot slow down typing because it is
// Very obvious.  This hack is to debounce sending the title to formik using a 'search bar'.
// TODO: embiggen the performance on this page
type OptTitleProps = { error?: boolean; helperText?: string; onChange: (val: string) => void };

const OptTitleField = React.forwardRef<HTMLInputElement, OptTitleProps>((props, ref) => {
    const { inputProps } = useControlledSearchInput({ initialValue: '', onSearchTermValueChange: props.onChange });

    return (
        <DSTextField
            autoFocus
            data-cy="collection-title-input"
            error={props.error}
            fullWidth
            helperText={props.helperText}
            inputRef={ref}
            placeholder="Name"
            variant="outlined"
            {...inputProps}
        />
    );
});
OptTitleField.displayName = 'OptTitleField';

const CreateCollectionModalContent: React.FC<CreateCollectionModalContentProps> = ({ onClose }) => {
    const notifier = useAngularServiceContext('mvNotifier');

    const [searchFilter, setSearchFilter] = React.useState<string | undefined>('');

    const [fetchCurrentClient, { data: currentClientData, loading: currentClientLoading }] = useLazyQuery<
        GetCurrentClientForCreateCollectionModal,
        GetCurrentClientForCreateCollectionModalVariables
    >(GET_CURRENT_CLIENT_FOR_CREATE_COLLECTION_MODAL_QUERY, {
        notifyOnNetworkStatusChange: true,
    });

    const [fetchClients, { data: clientsData, fetchMore, loading: clientsLoading }] = useLazyQuery<
        GetClientsForCreateCollectionModal,
        GetClientsForCreateCollectionModalVariables
    >(GET_CLIENTS_FOR_CREATE_COLLECTION_MODAL_QUERY, {
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-and-network',
        notifyOnNetworkStatusChange: true,
        variables: {
            first: 100,
            title: searchFilter,
        },
    });

    const { data, loading: locationsLoading } = useQuery<GetLocationsForCreateCollectionModal>(
        GET_LOCATIONS_FOR_CREATE_COLLECTION_MODAL_QUERY,
    );

    const [handleFetchMore, hasNextPage] = useConnectionFetchMore(
        clientsData?.me.agency.clients,
        async (after, first = 100) => fetchMore?.({ variables: { after, first } }),
    );

    const [, setCurrentAsset] = useCurrentAssetContext();

    const currentLocationId = data?.location?.id ?? null;
    const currentClientId = data?.location?.client.id ?? null;
    const canCreateLocationAndClientCollection =
        data?.location?.canCreateCollection && data?.location?.client.canCreateCollection;

    const currentLocation = React.useMemo(
        () => relayConnectionToArray(data?.me.locations).find(location => location.id === currentLocationId),
        [data?.me.locations, currentLocationId],
    );

    React.useEffect(() => {
        if (canCreateLocationAndClientCollection) {
            void fetchClients();
        }
    }, [fetchClients, canCreateLocationAndClientCollection]);

    React.useEffect(() => {
        if (currentClientId) {
            void fetchCurrentClient({ variables: { id: currentClientId } });
        }
    }, [currentClientId, fetchCurrentClient]);

    const [createCollection] = useMutation<
        CreateCollectionForCreateCollectionModal,
        CreateCollectionForCreateCollectionModalVariables
    >(CREATE_COLLECTION_MUTATION, {
        // This is pretty intense logic for a component...\
        // Basically updating the cache manually so we don't need to requery for collections
        update(cache, { data: createCollectionData }) {
            if (createCollectionData?.createCollection.__typename === 'Collection') {
                const clientOrCollection = { __typename: 'Location', id: currentLocationId };
                if (createCollectionData.createCollection.sharedWith.__typename === 'SharedWithClients') {
                    cache.modify<{ clientCollections: any }>({
                        fields: {
                            clientCollections: (
                                existingRef: { edges: [{ node: { id: string; title: string } }] },
                                { readField },
                            ): any => {
                                if (createCollectionData.createCollection.__typename === 'Collection') {
                                    const newCollectionRef = cache.writeFragment<NewCollection>({
                                        data: createCollectionData.createCollection,
                                        fragment: gql`
                                            fragment NewCollection on Collection {
                                                id
                                                title
                                            }
                                        `,
                                    });
                                    if (
                                        existingRef.edges
                                            .map(({ node }) => node)
                                            .some(
                                                (ref: { id: string }) =>
                                                    createCollectionData.createCollection.__typename === 'Collection' &&
                                                    readField('id', ref) === createCollectionData.createCollection.id,
                                            )
                                    ) {
                                        return existingRef;
                                    }
                                    return {
                                        ...existingRef,
                                        edges: [...existingRef.edges, { node: newCollectionRef }],
                                    };
                                }
                                return existingRef;
                            },
                        },
                        id: cache.identify(clientOrCollection),
                    });
                } else {
                    cache.modify<{ collections: any }>({
                        fields: {
                            collections(
                                existingRef: { edges: [{ node: { id: string; title: string } }] },
                                { readField },
                            ): any {
                                if (createCollectionData.createCollection.__typename === 'Collection') {
                                    const newCollectionRef = cache.writeFragment<NewCollection>({
                                        data: createCollectionData.createCollection,
                                        fragment: gql`
                                            fragment NewCollection on Collection {
                                                id
                                                title
                                            }
                                        `,
                                    });
                                    if (
                                        existingRef.edges
                                            .map(({ node }) => node)
                                            .some(
                                                (ref: { id: string }) =>
                                                    createCollectionData.createCollection.__typename === 'Collection' &&
                                                    readField('id', ref) === createCollectionData.createCollection.id,
                                            )
                                    ) {
                                        return existingRef;
                                    }
                                    return {
                                        ...existingRef,
                                        edges: [...existingRef.edges, { node: newCollectionRef }],
                                    };
                                }
                                return existingRef;
                            },
                        },
                        id: cache.identify(clientOrCollection),
                    });
                }
            }
        },
    });

    const clients: Array<AssetShareTarget & AssetShareTargetGroup> = React.useMemo(
        () =>
            [
                ...currentClientData?.client ? [currentClientData.client] : [],
                ...relayConnectionToArray(clientsData?.me.agency.clients),
            ]
                .filter(filterUniqueBy(client => client.id))
                .map((client): AssetShareTarget & AssetShareTargetGroup => ({
                    currentCollections: client.collectionLimits.current,
                    id: client.id,
                    items: relayConnectionToArray(data?.me.locations)
                        .filter(location => location.client.id === client.id)
                        .map(
                            (location): AssetShareTarget => ({
                                currentCollections: location.collectionLimits.current,
                                id: location.id,
                                maxCollections: location.collectionLimits.max,
                                title: location.title,
                            }),
                        ),
                    maxCollections: client.collectionLimits.max,
                    title: client.title,
                })),
        [clientsData?.me.agency.clients, currentClientData, data?.me.locations],
    );

    // This is hacky but it solves having to do another query.
    // It should be refactored in the future along with the rest of this component
    const [cachedClients, setCachedClients] = React.useState<ClientFragmentForCreateCollectionModal[]>([]);
    React.useEffect(() => {
        setCachedClients(existing => {
            const newArr = [...existing];
            const toPush = relayConnectionToArray(clientsData?.me.agency.clients).filter(
                client => !newArr.some(exist => exist.id === client.id),
            );
            newArr.push(...toPush);
            return newArr;
        });
    }, [clientsData?.me.agency.clients]);

    const [cachedLocations, setCachedLocations] = React.useState<
        // eslint-disable-next-line camelcase
        GetLocationsForCreateCollectionModal_me_locations_edges_node[]
    >([]);
    React.useEffect(() => {
        setCachedLocations(existing => {
            const newArr = [...existing];
            const toPush = relayConnectionToArray(data?.me.locations).filter(
                location => !newArr.some(exist => exist.id === location.id),
            );
            newArr.push(...toPush);
            return newArr;
        });
    }, [data?.me.locations]);

    const initialValues: InitialValues = React.useMemo(
        () => ({
            currentClient: {
                currentNumCollections: 0,
                maxNumCollections: DEFAULT_CLIENT_COLLECTION_LIMIT,
                title: '',
            },
            currentLocation: {
                currentNumCollections: 0,
                maxNumCollections: DEFAULT_LOCATION_COLLECTION_LIMIT,
                title: '',
            },
            modelType: canCreateLocationAndClientCollection ? ('client' as const) : 'location' as const,
            selectedClientIds: currentClientId ? [currentClientId] : [],
            selectedLocationIds: currentLocationId ? [currentLocationId] : [],
            title: '',
        }),
        [canCreateLocationAndClientCollection, currentClientId, currentLocationId],
    );

    React.useEffect(() => {
        if (currentClientData?.client) {
            initialValues.currentClient.title = currentClientData.client.title;
            if (currentClientData.client.collectionLimits) {
                initialValues.currentClient.currentNumCollections = currentClientData.client.collectionLimits.current;
                initialValues.currentClient.maxNumCollections = currentClientData.client.collectionLimits.max;
            }
        }
    }, [currentClientData?.client, initialValues]);

    React.useEffect(() => {
        if (currentLocation) {
            initialValues.currentLocation.title = currentLocation.title;
            if (data?.me?.locations && currentLocationId) {
                initialValues.currentLocation.currentNumCollections = currentLocation.collectionLimits.current;
                initialValues.currentLocation.maxNumCollections = currentLocation.collectionLimits.max;
            }
        }
    }, [data?.me.locations, currentLocation, currentLocationId, initialValues]);

    const handleSubmit = async (values: typeof initialValues, formikHelpers: FormikHelpers<typeof initialValues>) => {
        formikHelpers.setSubmitting(true);
        const input: CreateCollectionInput = {
            permissionLevel: values.modelType,
            sharedWithIds: values.modelType === 'client' ? values.selectedClientIds : values.selectedLocationIds,
            title: values.title.trim(),
        };

        const { data: createCollectionResponse } = await createCollection({
            variables: {
                input,
            },
        });
        if (createCollectionResponse?.createCollection.__typename === 'Collection') {
            setCurrentAsset(createCollectionResponse.createCollection.id);
        } else {
            notifier.unexpectedError(t('COMMON.FAILED_TO', { description: t('COMMON.OP_CREATE_COLLECTION') }));
            formikHelpers.setSubmitting(false);
        }

        onClose();
        return Promise.resolve();
    };

    const onTitleFieldChange = React.useCallback((value: string, formik: FormikProps<typeof initialValues>) => {
        formik.setFieldValue('title', value);
        formik.setTouched({ title: true });
    }, []);

    const handleValidation = useHandleValidation();

    // This prevents a formik issue, it has to be here
    if (!currentLocationId || !currentClientId) {
        return <Loading />;
    }

    return (
        <Formik<typeof initialValues>
            initialValues={initialValues}
            validate={handleValidation}
            validateOnMount
            onSubmit={handleSubmit}
        >
            {formik => (
                <>
                    <Form>
                        <DSDialogContent>
                            <DSGrid container direction="column">
                                <DSGrid
                                    alignItems="center"
                                    container
                                    direction="row"
                                    item
                                    style={{ margin: '4px 0px' }}
                                >
                                    <DSGrid item justifyContent="flex-end" md={2} sm={3} xs={12}>
                                        <DSTypography component="p" variant="h6">
                                            {t('COMMON.NAME')}
                                        </DSTypography>
                                    </DSGrid>
                                    <DSGrid item md={10} sm={9} xs={12}>
                                        <OptTitleField
                                            error={formik.touched.title && !!formik.errors.title}
                                            helperText={formik.touched.title ? formik.errors.title : ''}
                                            onChange={value => onTitleFieldChange(value, formik)}
                                        />
                                    </DSGrid>
                                </DSGrid>
                                {canCreateLocationAndClientCollection && (
                                    <DSGrid
                                        alignItems="center"
                                        container
                                        direction="row"
                                        item
                                        style={{ margin: '4px 0px' }}
                                    >
                                        <DSGrid item justifyContent="flex-end" md={2} sm={3} xs={12}>
                                            <DSTypography component="p" variant="h6">
                                                <Translate keyId="ASSET_LIBRARY.SHARED_WITH" />
                                            </DSTypography>
                                        </DSGrid>
                                        <DSGrid item md={10} sm={9} xs={12}>
                                            <DSFormControl>
                                                <DSRadioGroup
                                                    row
                                                    value={formik.values.modelType}
                                                    onChange={value =>
                                                        formik.setFieldValue('modelType', value.target.value)
                                                    }
                                                >
                                                    <DSFormControlLabel
                                                        control={<DSRadio />}
                                                        label={t('COMMON.CLIENTS')}
                                                        value="client"
                                                    />
                                                    <DSFormControlLabel
                                                        control={<DSRadio />}
                                                        data-cy="location-radio-button"
                                                        label={t('COMMON.LOCATIONS')}
                                                        value="location"
                                                    />
                                                </DSRadioGroup>
                                            </DSFormControl>
                                        </DSGrid>
                                    </DSGrid>
                                )}
                                <DSGrid container direction="row" item style={{ margin: '4px 0px' }}>
                                    <DSGrid
                                        item
                                        justifyContent="flex-end"
                                        md={2}
                                        sm={3}
                                        style={{ margin: '14px 0px' }}
                                        xs={12}
                                    >
                                        <DSTypography component="p" variant="h6">
                                            {formik.values.modelType === 'client'
                                                ? t('COMMON.CLIENTS')
                                                : t('COMMON.LOCATIONS')}
                                        </DSTypography>
                                    </DSGrid>
                                    <DSGrid item md={5} sm={5} xs={12}>
                                        <DSPaper
                                            elevation={0}
                                            style={{
                                                border: '1px solid #eee',
                                                height: '240px',
                                                overflowY: 'scroll',
                                                padding: '4px 8px 0px 8px',
                                            }}
                                        >
                                            {formik.values.modelType === 'client' ? (
                                                <>
                                                    <ClientPicker
                                                        clients={clients}
                                                        disabled={[currentClientId]}
                                                        selected={formik.values.selectedClientIds}
                                                        onChange={value =>
                                                            formik.setFieldValue('selectedClientIds', value)
                                                        }
                                                        onSearchTermChange={setSearchFilter}
                                                    />
                                                    {(clientsLoading || currentClientLoading) && <Loading />}
                                                    {hasNextPage && (
                                                        <DSLoadMoreButton
                                                            gutterTop
                                                            loading={clientsLoading}
                                                            onClick={async () => handleFetchMore()}
                                                        />
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    <LocationPicker
                                                        clients={clients}
                                                        disabled={[currentLocationId]}
                                                        selected={formik.values.selectedLocationIds}
                                                        onChange={value =>
                                                            formik.setFieldValue('selectedLocationIds', value)
                                                        }
                                                    />
                                                    {(clientsLoading || currentClientLoading || locationsLoading) && (
                                                        <Loading />
                                                    )}
                                                    {hasNextPage && (
                                                        <DSLoadMoreButton
                                                            gutterTop
                                                            loading={
                                                                clientsLoading ||
                                                                currentClientLoading ||
                                                                locationsLoading
                                                            }
                                                            onClick={async () => handleFetchMore(100000)}
                                                        />
                                                    )}
                                                </>
                                            )}
                                        </DSPaper>
                                    </DSGrid>
                                    <DSGrid item md={5} sm={5} xs={12}>
                                        <DSPaper
                                            elevation={0}
                                            style={{
                                                height: '240px',
                                                overflowY: 'scroll',
                                                padding: '4px 8px 0px 8px',
                                            }}
                                        >
                                            <SelectedClientsOrLocations
                                                items={
                                                    formik.values.modelType === 'client'
                                                        ? cachedClients.filter(
                                                              client =>
                                                                  formik.values.selectedClientIds.indexOf(client.id) >=
                                                                  0,
                                                          )
                                                        : cachedLocations.filter(
                                                              item =>
                                                                  formik.values.selectedLocationIds.indexOf(item.id) >=
                                                                  0,
                                                          )
                                                }
                                                title={
                                                    formik.values.modelType === 'client'
                                                        ? t('ASSET_LIBRARY.SELECTED_CLIENTS')
                                                        : t('ASSET_LIBRARY.SELECTED_LOCATIONS')
                                                }
                                            />
                                            {(clientsLoading || locationsLoading || currentClientLoading) && (
                                                <Loading />
                                            )}
                                        </DSPaper>
                                    </DSGrid>
                                </DSGrid>
                            </DSGrid>
                            {formik.errors?.currentClient?.currentNumCollections && (
                                <ErrorMessage message={formik.errors.currentClient.currentNumCollections} />
                            )}
                            {formik.errors?.currentLocation?.currentNumCollections && (
                                <ErrorMessage message={formik.errors.currentLocation.currentNumCollections} />
                            )}
                        </DSDialogContent>
                        <DSDialogActions>
                            <DSButton
                                color="default"
                                disabled={formik.isSubmitting}
                                variant="outlined"
                                onClick={onClose}
                            >
                                {t('COMMON.CANCEL')}
                            </DSButton>
                            <DSButton
                                color="success"
                                data-cy="create-collection-button"
                                disabled={!formik.isValid || formik.isSubmitting}
                                type="submit"
                                variant="contained"
                            >
                                {t('COMMON.CREATE')}
                            </DSButton>
                        </DSDialogActions>
                    </Form>
                </>
            )}
        </Formik>
    );
};
CreateCollectionModalContent.displayName = 'CreateCollectionModalContent';
