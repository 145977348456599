import * as React from 'react';
import { DSButton, DSTypography, Translate } from '@deltasierra/components';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useRouter } from '../../../common/routes';
import { FlexColumnCenter, BigIcon } from '../styled';

export const CollectionNotFound = (): JSX.Element => {
    const { navigate } = useRouter();
    return (
        <FlexColumnCenter>
            <BigIcon className="far fa-exclamation-circle text-danger" />
            <DSTypography component="h2" variant="h2">
                <Translate keyId="COMMON.NO_ASSETS_FOUND" />
            </DSTypography>
            <DSTypography align="center" component="h4" variant="h4">
                <Translate keyId="COMMON.CANNOT_FIND_ASSET" />
            </DSTypography>
            <DSButton size="small" startIcon={<RefreshIcon />} onClick={() => navigate('/assets')}>
                <Translate keyId="COMMON.REFRESH" />
            </DSButton>
        </FlexColumnCenter>
    );
};

CollectionNotFound.displayName = 'CollectionNotFound';
