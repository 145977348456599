import { DSTypography, DSWell, Translate } from '@deltasierra/components';
import {
    pluck,
    AssignedLocation,
    InstagramStoryDirectPublishFormData,
    LocationIdHierarchy,
} from '@deltasierra/shared';
import { Formik } from 'formik';
import * as React from 'react';
import { FormButtons } from '../common/FormButton';
import { MvContentBuilderPublishCtrl } from '../mvContentBuilderPublishCtrl';
import { InstagramStoryDirectFormValues } from './instagram-story-direct-form-values';
import { InstagramStoryDirectFormScheduleSection } from './InstagramStoryDirectFormScheduleSection';
import { useValidatedInstagramStoryDirectForm } from './useValidatedInstagramStoryDirectForm';

export type PublishToInstagramStoryDirectFormProps = {
    location: AssignedLocation;
    locations: LocationIdHierarchy[];
    publishController: MvContentBuilderPublishCtrl;
    onCancel: () => void;
    onPublish: (data: InstagramStoryDirectPublishFormData) => void;
};

export function PublishToInstagramStoryDirectForm({
    location,
    locations,
    onCancel,
    onPublish,
    publishController,
}: PublishToInstagramStoryDirectFormProps): JSX.Element {
    const graphqlLocationIds = React.useMemo(() => pluck('locationGraphqlId', locations), [locations]);

    const [submitted, setSubmitted] = React.useState(false);

    const onSubmit = React.useCallback(
        (formValues: InstagramStoryDirectFormValues) => {
            if (submitted) {
                return;
            }
            setSubmitted(true);

            onPublish(formValues);
        },
        [
            submitted,
            onPublish,
        ],
    );

    const formikProps = useValidatedInstagramStoryDirectForm({
        date: publishController?.plannerDetails?.date,
    });

    return (
        <Formik {...formikProps} validateOnBlur validateOnChange validateOnMount onSubmit={onSubmit}>
            {formik => (
                <>
                    <DSWell>
                        <DSTypography gutterBottom variant="h3">
                            <Translate keyId="BUILD.PUBLISH.INSTAGRAM.PUBLISH_STORY_TO_INSTAGRAM" />
                        </DSTypography>
                        <DSTypography gutterBottom variant="h4">
                            <Translate keyId="BUILD.PUBLISH.INSTAGRAM.HEADERS.POST_SCHEDULE" />
                        </DSTypography>
                        <InstagramStoryDirectFormScheduleSection
                            formik={formik}
                            locationIds={graphqlLocationIds}
                            timezone={location.timezone}
                        />
                    </DSWell>
                    <FormButtons
                        disabled={!formik.isValid || formik.isSubmitting}
                        isScheduled={!!formik.values.scheduledTime}
                        onCancel={onCancel}
                        onSubmit={formik.handleSubmit}
                    />
                </>
            )}
        </Formik>
    );
}

PublishToInstagramStoryDirectForm.displayName = 'PublishToInstagramStoryDirectForm';
