/// <reference path="../../../../typings/browser.d.ts" />
import { BillingDetailsUpdateData, BillingDetailsUpdateInviteData, LocationId } from '@deltasierra/shared';
import IHttpService = angular.IHttpService;
import IHttpPromise = angular.IHttpPromise;

export class BillingDetailsUpdateService {
    public static SID = 'billingDetailsUpdateService';

    static readonly $inject: string[] = ['$http'];

    constructor(private $http: IHttpService) {}

    sendInvite(locationId: LocationId, email: string): IHttpPromise<any> {
        return this.$http.post('/api/billingDetailsUpdate/invite', {
            locationId,
            email,
        });
    }

    getInviteData(token: string): IHttpPromise<BillingDetailsUpdateInviteData> {
        return this.$http.get(`/api/billingDetailsUpdate/${token}`);
    }

    submitBillingDetailsUpdate(
        token: string,
        billingDetailsUpdateData: BillingDetailsUpdateData,
    ): IHttpPromise<{ SharedPaymentUrl: string }> {
        return this.$http.post<{ SharedPaymentUrl: string }>(
            `/api/billingDetailsUpdate/${token}`,
            billingDetailsUpdateData,
        );
    }

    finaliseBillingDetailsUpdate(accessCode: string): IHttpPromise<any> {
        return this.$http.post('/api/billingDetailsUpdate/finalise', {
            accessCode,
        });
    }
}

angular.module('app').service(BillingDetailsUpdateService.SID, BillingDetailsUpdateService);
