import {
    DSCheckbox,
    DSTable,
    DSTableBodyLoadable,
    DSTableCell,
    DSTableContainer,
    DSTableHead,
    DSTableRow,
    Tooltip,
    Translate,
} from '@deltasierra/components';
import { useChecklistChecklist, useChecklistSetChecked } from '@deltasierra/react-hooks';
import React from 'react';
import { BlockedPublishRowAction } from './blocked-publish-row-action';
import { SCHEDULED_PUBLISH_FRAGMENT_FOR_BLOCKED_PUBLISHES_TABLE } from './BlockedPublishesTable.fragments';
import { BlockedPublishesTableRow } from './BlockedPublishesTableRow';
import { ScheduledPublishFragmentForBlockedPublishesTable } from './__graphqlTypes/ScheduledPublishFragmentForBlockedPublishesTable';

type BlockedPublishesTableProps = {
    disableActions: boolean;
    loading: boolean;
    pushToFailed: BlockedPublishRowAction;
    pushToSent: BlockedPublishRowAction;
    rows: ScheduledPublishFragmentForBlockedPublishesTable[];
    selected: useChecklistChecklist;
    sendNow: BlockedPublishRowAction;
    setSelected: useChecklistSetChecked;
};

export function BlockedPublishesTable({
    disableActions,
    loading,
    pushToFailed,
    pushToSent,
    rows,
    selected,
    sendNow,
    setSelected,
}: BlockedPublishesTableProps): JSX.Element {
    return (
        <DSTableContainer>
            <DSTable size="small">
                <DSTableHead>
                    <DSTableRow>
                        <DSTableCell width={90}>
                            <Tooltip tip={<Translate keyId="COMMON.TOOLTIPS.SELECT_ALL" />}>
                                <DSCheckbox
                                    checked={selected.length === rows.length && selected.length !== 0}
                                    onChange={event =>
                                        setSelected(
                                            event.target.checked,
                                            rows.map(row => row.id),
                                        )
                                    }
                                />
                            </Tooltip>
                        </DSTableCell>
                        <DSTableCell>
                            <Translate keyId="ADMIN.SERVICING.BLOCKED_PUBLISHES.TABLE.HEADERS.UPDATED_AT" />
                        </DSTableCell>
                        <DSTableCell>
                            <Translate keyId="COMMON.CLIENT" />
                        </DSTableCell>
                        <DSTableCell>
                            <Translate keyId="COMMON.LOCATION" />
                        </DSTableCell>
                        <DSTableCell>
                            <Translate keyId="ADMIN.SERVICING.BLOCKED_PUBLISHES.TABLE.HEADERS.SCHEDULED_FOR" />
                        </DSTableCell>
                        <DSTableCell>
                            <Translate keyId="ADMIN.SERVICING.BLOCKED_PUBLISHES.TABLE.HEADERS.CONTENT" />
                        </DSTableCell>
                        <DSTableCell>
                            <Translate keyId="COMMON.ACTIONS" />
                        </DSTableCell>
                    </DSTableRow>
                </DSTableHead>
                <DSTableBodyLoadable disableInteraction={false} loading={loading}>
                    {rows.length > 0
                        ? rows.map(row => (
                            <BlockedPublishesTableRow
                                checked={selected.includes(row.id)}
                                disableActions={disableActions}
                                key={row.id}
                                pushToFailed={pushToFailed}
                                pushToSent={pushToSent}
                                row={row}
                                sendNow={sendNow}
                                setChecked={setSelected}
                            />
                        ))
                     : (
                        <DSTableRow>
                            <DSTableCell align="center" colSpan={9}>
                                <Translate keyId="ADMIN.SERVICING.BLOCKED_PUBLISHES.TABLE.NOT_FOUND_MESSAGE" />
                            </DSTableCell>
                        </DSTableRow>
                    )}
                </DSTableBodyLoadable>
            </DSTable>
        </DSTableContainer>
    );
}
BlockedPublishesTable.displayName = 'BlockedPublishesTable';
BlockedPublishesTable.fragments = {
    SCHEDULED_PUBLISH_FRAGMENT_FOR_BLOCKED_PUBLISHES_TABLE,
};
