export class ErrorsListController {
    // Props
    public errors!: string[];

    public showErrors?: boolean;

    static $inject: string[] = [];

    constructor() {
        //
    }

    public $onInit(): void {
        this.showErrors = !!this.showErrors;
    }

    toggleErrors() {
        this.showErrors = !this.showErrors;
    }
}
