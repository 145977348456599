import { Observable, OperatorFunction, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AnyError } from '@deltasierra/shared';

export const errors$ = new Subject<AnyError>();

/**
 * A wrapper for the RxJS catchError operator that also sends 'puts' the error onto the `errors$` observable.
 *
 * @example
 * of(1,2,3,4,5,6).pipe(
 *     mergeMap(num => {
 *         if (num === 6) {
 *             throw new Error('The value of 6 is too damn high');
 *         } else {
 *             return of(num.toString());
 *         }
 *     }),
 *     catchErrorWithNotification(error => of('Some error occurred: ' + error.toString()),
 *  ).subscribe(console.log)
 * @param fn - The error handling function
 * @returns An observable operator
 */
// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
export const catchErrorWithNotification = <TInput extends {}, TResult extends {}, TError extends any = AnyError>(
    fn: (error: TError) => Observable<TResult>,
): OperatorFunction<TInput, TInput | TResult> =>
    catchError((error: TError, obs$: Observable<TInput>) => {
        errors$.next(error);
        return fn(error);
    });
