import { DSTypography, FormGroup } from '@deltasierra/components';
import { maxDate, t } from '@deltasierra/shared';
import * as React from 'react';
import { DateTimePicker, DateTimePickerProps } from '../common/form/dates/DateTimePicker';
import { Toggle } from '../common/form/Toggle';

export type SchedulePublishTimePickerProps = DateTimePickerProps & {
    disabled?: boolean;
    minDate?: Date | null;
    onChange: (newValue: Date | null) => void;
    timezone: string;
    useNewLabel?: boolean;
    value: Date | null;
    error?: string;
};

export const SchedulePublishTimePicker: React.FunctionComponent<SchedulePublishTimePickerProps> = ({
    disabled,
    error,
    minDate = new Date(),
    onChange,
    timezone,
    useNewLabel,
    value,
    ...props
}) => {
    const [checked, setChecked] = React.useState(!!value);
    const onSetChecked = (newCheckedValue: boolean) => {
        setChecked(newCheckedValue);
        if (!newCheckedValue) {
            onChange(null);
        } else {
            onChange(value ? maxDate(value, minDate || new Date()) : minDate);
        }
    };

    return (
        <FormGroup fullWidth label={!useNewLabel ? t('COMMON.SCHEDULE') : undefined}>
            {error && <DSTypography color="error">{error}</DSTypography>}
            <Toggle
                disabled={disabled}
                label={{ keyId: 'BUILD.PUBLISH.SCHEDULE_FOR_LATER' }}
                value={checked}
                onChange={onSetChecked}
            />
            {checked && (
                <DateTimePicker
                    min={minDate}
                    showClarificationInTimezone={timezone}
                    value={value}
                    onChange={onChange}
                    {...props}
                />
            )}
        </FormGroup>
    );
};

SchedulePublishTimePicker.displayName = 'SchedulePublishTimePicker';
