import { AssignedLocation, LocationId } from '@deltasierra/shared';
import {
    $routeSID,
    actualComponent,
    ChangesObject,
    ILifecycleHooks,
    IRoute,
    OneWayBinding,
} from '../../../common/angularData';
import { InteractionUtils } from '../../../common/interactionUtils';
import { MvLocation } from '../../../locations/mvLocation';
import { MvNotifier } from '../../../common/mvNotifier';
import { I18nService } from '../../../i18n';
import { MvAuth } from '../../../account/mvAuth';

export class Four51LocationAuth implements ILifecycleHooks {

    // Props
    public location!: AssignedLocation;

    // State
    public accountDescription?: string;

    public isNewCredentialsFormVisible = false;

    public newUsername?: string;

    public newPassword?: string;

    // Futures
    public readonly fetchFour51AccountDescription = this.interactionUtils.createFuture('fetch Four51 account description', (context: {
        locationId: LocationId;
    }) => this.mvAuth.getServiceAccessInfo('four51', 'location', context.locationId)
            .then(result => {
                if (result.result) {
                    this.accountDescription = result.serviceAccountName || undefined;
                }
            }));

    public readonly updateFour51Credentials = this.interactionUtils.createFuture('update Four51 credentials', (context: {
        locationId: LocationId;
        username: string;
        password: string;
    }) => this.mvLocation.updateFour51Credentials(
            context.locationId,
            {
                username: context.username,
                password: context.password,
            },
        ));

    public readonly deleteFour51Credentials = this.interactionUtils.createFuture('delete Four51 credentials', (context: {
        locationId: LocationId;
    }) => this.mvLocation.deleteFour51Credentials(context.locationId));

    static $inject = [
        $routeSID,
        InteractionUtils.SID,
        MvNotifier.SID,
        I18nService.SID,
        MvLocation.SID,
        MvAuth.SID,
    ];

    constructor(
        protected readonly $route: IRoute,
        protected readonly interactionUtils: InteractionUtils,
        protected readonly mvNotifier: MvNotifier,
        protected readonly i18n: I18nService,
        protected readonly mvLocation: MvLocation,
        protected readonly mvAuth: MvAuth,
    ) {

    }

    $onChanges(changesObj: ChangesObject<this>) {
        if (changesObj.location &&
            (
                changesObj.location.isFirstChange() ||
                changesObj.location.previousValue !== changesObj.location.currentValue
            )
        ) {
            return this.fetchFour51AccountDescription.run({
                locationId: this.location.id,
            });
        }
    }

    onClickShowNewCredentialsForm(): void {
        this.isNewCredentialsFormVisible = true;
    }

    onClickDeleteCredentialsForm() {
        return this.deleteFour51Credentials.run({
            locationId: this.location.id,
        }).then(() => {
            this.mvNotifier.success(this.i18n.text.apps.four51.credentialsDeleted());
            this.$route.reload();
        });
    }

    onSubmitUpdateCredentials() {
        if (!this.newUsername || !this.newPassword) {
            this.mvNotifier.expectedError(this.i18n.text.apps.four51.invalidNewCredentials());
            return;
        }
        return this.updateFour51Credentials.run({
            locationId: this.location.id,
            username: this.newUsername,
            password: this.newPassword,
        }).then(() => {
            this.mvNotifier.success(this.i18n.text.apps.four51.credentialsUpdated());
            this.$route.reload();
        });
    }

    onClickCancel(): void {
        this.isNewCredentialsFormVisible = false;
    }
}

export const four51LocationAuthSID = 'fourfiftyoneLocationAuth';
export const four51LocationAuthConfig = actualComponent(
    Four51LocationAuth,
    '/partials/integration/auth/four51/four51LocationAuth',
    {
        location: OneWayBinding,
    },
);

angular.module('app').component(four51LocationAuthSID, four51LocationAuthConfig);
