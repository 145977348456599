import * as React from 'react';
import { useRouter } from '../../../routes';
import { ClientPicker } from '../ClientPicker';
import { CurrentClientIdType, CurrentClientRouteDispatchContext, CurrentClientRouteStateContext } from '../contexts';

export type CurrentClientRouteContextProviderProps = {
    children: React.ReactNode;
    value: Parameters<typeof CurrentClientRouteStateContext.Provider>[0]['value'];
} & (
    | {
          onChange: Parameters<typeof CurrentClientRouteDispatchContext.Provider>[0]['value'];
      }
    | {
          onUpdateUrl: (newClientId: CurrentClientIdType) => string;
      }
);

export function CurrentClientRouteContextProvider({
    children,
    value,
    ...props
}: CurrentClientRouteContextProviderProps): JSX.Element {
    const router = useRouter();
    const [currentClientIdFromContext, updateCurrentClient] = ClientPicker.useCurrentClient();

    React.useEffect(() => {
        if (value && value !== currentClientIdFromContext) {
            updateCurrentClient(value);
        }
    }, [currentClientIdFromContext, updateCurrentClient, value]);

    return (
        <CurrentClientRouteStateContext.Provider value={value}>
            <CurrentClientRouteDispatchContext.Provider
                value={
                    'onChange' in props
                        ? props.onChange
                        : newClientId => {
                              if (newClientId) {
                                  router.push(props.onUpdateUrl(newClientId));
                              }
                          }
                }
            >
                {children}
            </CurrentClientRouteDispatchContext.Provider>
        </CurrentClientRouteStateContext.Provider>
    );
}
CurrentClientRouteContextProvider.displayName = 'CurrentClientRouteContextProvider';
