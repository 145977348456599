import { DSButton, DSTypography, SvgLoading, Translate } from '@deltasierra/components';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import * as React from 'react';
import { useExportReportButton } from './use-export-report-button';
import { StyledExportingMessage, StyledWrapper } from './styled';
import {
    UseExportReportButtonQuery,
    UseExportReportButtonInputs,
    UseExportReportButtonMutationVariables,
} from './types';
export function ExportReportButton({
    mutation,
    mutationVariables,
    pollerFn,
    query,
    title = 'REPORT.POPULAR_BUILDER_TEMPLATE_DETAILS.BUTTONS.EXPORT_CSV',
    successTitle = 'REPORT.POPULAR_BUILDER_TEMPLATE_DETAILS.BUTTONS.DOWNLOAD_CSV',
}: UseExportReportButtonInputs<UseExportReportButtonMutationVariables, UseExportReportButtonQuery>): JSX.Element {
    const { downloadLinkRef, loading, onClickButton, state } = useExportReportButton({
        mutation,
        mutationVariables,
        pollerFn,
        query,
    });
    return (
        <>
            {state.name !== 'success' ? (
                <StyledWrapper>
                    <DSButton
                        color="primary"
                        disabled={loading}
                        endIcon={!loading && <SaveAltIcon />}
                        size="small"
                        onClick={onClickButton}
                    >
                        {loading ? (
                            <>
                                <StyledExportingMessage variant="body2">
                                    <Translate keyId="REPORT.POPULAR_BUILDER_TEMPLATE_DETAILS.EXPORTING_PLEASE_WAIT" />
                                </StyledExportingMessage>
                                <SvgLoading data-testid="loading" />
                            </>
                        ) : (
                            <Translate keyId={title} />
                        )}
                    </DSButton>
                    {state.name === 'error' && (
                        <DSTypography color="error" variant="body2">
                            <Translate keyId="REPORT.POPULAR_BUILDER_TEMPLATE_DETAILS.ERROR_EXPORTING_CSV" />
                        </DSTypography>
                    )}
                </StyledWrapper>
            ) : (
                <StyledWrapper>
                    <DSButton
                        color="success"
                        download
                        endIcon={<CloudDownloadIcon />}
                        href={state.downloadUrl}
                        innerRef={downloadLinkRef}
                        size="small"
                        variant="text"
                    >
                        <Translate keyId={successTitle} />
                    </DSButton>
                </StyledWrapper>
            )}
        </>
    );
}
ExportReportButton.displayName = 'ExportReportButton';
