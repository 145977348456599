import { TimeUnit, timeUnitToString } from '@deltasierra/dates';
import { translate } from '@deltasierra/i18n';

type TimeUnitAgoTranslationMapKeys =
    | 'day'
    | 'days'
    | 'hour'
    | 'hours'
    | 'minute'
    | 'minutes'
    | 'month'
    | 'months'
    | 'second'
    | 'seconds'
    | 'week'
    | 'weeks'
    | 'year'
    | 'years';

export const TIME_UNIT_AGO_TRANSLATION_MAP: Record<TimeUnitAgoTranslationMapKeys, () => string> = {
    day: () => translate('BUILD.TIME_UNITS_AGO.DAY_AGO'),
    days: () => translate('BUILD.TIME_UNITS_AGO.DAY_AGO_plural'),
    hour: () => translate('BUILD.TIME_UNITS_AGO.HOUR_AGO'),
    hours: () => translate('BUILD.TIME_UNITS_AGO.HOUR_AGO_plural'),
    minute: () => translate('BUILD.TIME_UNITS_AGO.MINUTE_AGO'),
    minutes: () => translate('BUILD.TIME_UNITS_AGO.MINUTE_AGO_plural'),
    month: () => translate('BUILD.TIME_UNITS_AGO.MONTH_AGO'),
    months: () => translate('BUILD.TIME_UNITS_AGO.MONTH_AGO_plural'),
    second: () => translate('BUILD.TIME_UNITS_AGO.SECOND_AGO'),
    seconds: () => translate('BUILD.TIME_UNITS_AGO.SECOND_AGO_plural'),
    week: () => translate('BUILD.TIME_UNITS_AGO.WEEK_AGO'),
    weeks: () => translate('BUILD.TIME_UNITS_AGO.WEEK_AGO_plural'),
    year: () => translate('BUILD.TIME_UNITS_AGO.YEAR_AGO'),
    years: () => translate('BUILD.TIME_UNITS_AGO.YEAR_AGO_plural'),
};

/**
 * This function provides translation with plurals for a provided TimeUnit.
 * This can only be used on the front-end.
 *
 * @param timeUnit - the TimeUnit to retrieve translation for
 * @param plural  - flag for whether the plural form should be used
 * @returns - the translated string or empty string if no mapping is found
 */
export function translateTimeUnitAgo(timeUnit: TimeUnit, plural = true): string {
    const key = timeUnitToString(timeUnit, plural);
    const translateFn: (() => string) | undefined = (TIME_UNIT_AGO_TRANSLATION_MAP as any)[key];
    return translateFn ? translateFn() : '';
}
