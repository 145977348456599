import { HelpPopper, Loading, Translate } from '@deltasierra/components';
import { assertNever } from '@deltasierra/shared';
import Check from '@material-ui/icons/Check';
import ReportProblem from '@material-ui/icons/ReportProblem';
import * as React from 'react';
import styled from 'styled-components';
import { ValidationState } from '../values/validation-state.enum';

const StyledCheck = styled(Check)`
    color: ${({ theme }) => theme.palette.success.main};
`;

const StyledClose = styled(ReportProblem)`
    color: ${({ theme }) => theme.palette.error.main};
`;

export interface EndAdornmentProps {
    state: ValidationState;
}

export function EndAdornment(props: EndAdornmentProps): JSX.Element {
    switch (props.state) {
        case ValidationState.Invalid:
        case ValidationState.None:
            return (
                <>
                    <HelpPopper icon={() => <StyledClose />} placement="top" popperIcon={() => <></>}>
                        <p style={{ fontSize: '1rem', fontWeight: 500, margin: 0 }}>
                            <Translate keyId="PLAN.ASSOCIATED_BUILDER_RESOURCES.INVALID_SINGLE_TEMPLATE_EXPLANATION" />
                        </p>
                    </HelpPopper>
                </>
            );
        case ValidationState.Valid:
            return <StyledCheck />;
        case ValidationState.Validating:
            return <Loading size="small" />;
        default:
            return assertNever(props.state);
    }
}
EndAdornment.displayName = 'EndAdornment';
