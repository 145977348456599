import * as React from 'react';
import { useField } from 'formik';
import { FormGroup, FormGroupProps } from '@deltasierra/components';
import { RequiredFieldMarker } from './helpers';

export const ValidateableFieldContext = React.createContext<string | null>(null);

export type ValidateableFormGroupProps = FormGroupProps & {
    name: string;
    required?: boolean;
};

export const ValidateableFormGroup: React.FC<ValidateableFormGroupProps> = ({
    children,
    label,
    name,
    required,
    ...props
}) => {
    const [, meta] = useField(name);
    const showError = meta.error && meta.touched;
    // If the field is required we should append the required field marker to the label
    const labelWithRequired = required ? (
        <>
            {label} <RequiredFieldMarker />
        </>
    ) : undefined;
    return (
        <ValidateableFieldContext.Provider value={name}>
            <FormGroup className={showError ? 'has-error' : ''} label={labelWithRequired ?? label} {...props}>
                {children}
                {showError && <div className="help-block">{meta.error}</div>}
            </FormGroup>
        </ValidateableFieldContext.Provider>
    );
};

ValidateableFormGroup.displayName = 'ValidateableFormGroup';
