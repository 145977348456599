import {
    AssignedLocation,
    ExportPlatforms,
    FacebookPublishData,
    ISize,
    LocationIdHierarchy,
    PreparedVideoRequestData,
} from '@deltasierra/shared';
import * as React from 'react';
import { usePrepareVideo, VideoUploadProgress } from '../instagramDirect/VideoUploadProgress';
import { MultipleLocationVideoPublish } from '../multipleLocationPublish/MultipleLocationVideoPublish';
import { MvContentBuilderPublishCtrl } from '../mvContentBuilderPublishCtrl';
import { PublishResult } from '../publishResult';
import { PublishFinishCallback } from '../types';
import { FacebookPublishForm } from './FacebookPublishForm';
import { useFacebookVideoPublishCallback } from './useFacebookVideoPublishCallback';

export type PublishVideoToFacebookStepProps = {
    location: AssignedLocation;
    locations: LocationIdHierarchy[];
    onCancel: () => void;
    onFinish: (data: PublishResult) => void;
    publishController: MvContentBuilderPublishCtrl;
};

export function PublishVideoToFacebookStep({
    location,
    locations,
    onCancel,
    onFinish,
    publishController,
}: PublishVideoToFacebookStepProps): JSX.Element {
    const [isPublishing, setIsPublishing] = React.useState(false);
    const [publishData, setPublishData] = React.useState<FacebookPublishData | null>(null);
    const [videoData, setVideoData] = React.useState<PreparedVideoRequestData | null>(null);
    const [outputDimensions, setoutputDimensions] = React.useState<ISize>({
        height: publishController.contentBuilder.document.dimensions.height,
        width: publishController.contentBuilder.document.dimensions.width,
    });

    const [prepareVideo, videoProcessingContext] = usePrepareVideo();

    const videoPublishCallback = useFacebookVideoPublishCallback(publishController, videoData);

    const isUploading = videoProcessingContext.uploadContext.uploadInProgress;

    const onPublish = async (data: FacebookPublishData) => {
        setIsPublishing(true);
        setPublishData(data);

        if (publishController.fileFormatChoice) {
            setoutputDimensions({
                height: publishController.fileFormatChoice.height,
                width: publishController.fileFormatChoice.width,
            });
        }

        const preparedVideoData = await prepareVideo(
            publishController.contentBuilder.document,
            outputDimensions,
            publishController,
        );
        setVideoData(preparedVideoData);
    };

    const finishCallback: PublishFinishCallback = () => {
        const scheduledTime = (publishData && publishData.scheduledTime) || null;
        onFinish(new PublishResult(ExportPlatforms.Facebook, scheduledTime));
    };

    return (
        <>
            {!isPublishing && !isUploading && !videoData && (
                <FacebookPublishForm
                    locationId={location.graphqlId}
                    locationIds={locations.map(loc => loc.locationGraphqlId)}
                    publishController={publishController}
                    publishType="video"
                    onCancel={onCancel}
                    onPublish={onPublish}
                />
            )}
            {isUploading && <VideoUploadProgress uploadContext={videoProcessingContext.uploadContext} />}
            {isPublishing && !isUploading && videoData && publishData && (
                <MultipleLocationVideoPublish
                    commonData={publishData}
                    contentBuilder={publishController.contentBuilder}
                    locations={locations}
                    originalLocation={location}
                    publishCallback={videoPublishCallback}
                    publishCtrl={publishController}
                    onCancel={onCancel}
                    onFinish={finishCallback}
                />
            )}
        </>
    );
}
PublishVideoToFacebookStep.displayName = 'PublishVideoToFacebookStep';
