/// <reference path="../../../../typings/browser.d.ts" />
import {
    BannerNotification,
    BannerNotificationApi,
    BannerNotificationType,
    emptyRequest,
    justBody,
} from '@deltasierra/shared';
import { invokeApiRoute } from '../../common/httpUtils';

export default class BannerNotificationService {
    static SID = 'BannerNotificationService';

    static readonly $inject: string[] = ['$http'];

    constructor(private $http: angular.IHttpService) {}

    createBannerNotification(
        message: string,
        type: BannerNotificationType,
        isDismissable: boolean,
    ): ng.IPromise<BannerNotification> {
        return invokeApiRoute(
            this.$http,
            BannerNotificationApi.createBannerNotification,
            justBody({
                message,
                type,
                dismissable: isDismissable,
            }),
        );
    }

    deleteCurrentBannerNotification(): ng.IPromise<void> {
        return invokeApiRoute(this.$http, BannerNotificationApi.deleteCurrentBannerNotification, emptyRequest());
    }

    getCurrentBannerNotification(): ng.IPromise<BannerNotification> {
        return invokeApiRoute(this.$http, BannerNotificationApi.getCurrentBannerNotification, emptyRequest());
    }
}

angular.module('app').service(BannerNotificationService.SID, BannerNotificationService);
