import { Untyped, ExternalAuthApp, ExternalAuthToken } from '@deltasierra/shared';

import { clone } from '@deltasierra/object-utilities';
import { InteractionUtils } from '../../../common/interactionUtils';
import { I18nService } from '../../../i18n/i18nService';
import { ExternalAuthService } from '../../externalAuthService';
import { ModalService } from '../../../typings/angularUIBootstrap/modalService';

import { AuthAppDetailsController, ExternalAuthAppDetailsLocals } from '../authAppDetailsController';
import { $locationSID, $qSID, $routeParamsSID } from '../../../common/angularData';
import { $modalSID } from '../../../common/angularUIBootstrapData';

export class ExternalAuthAppDetailsController {
    static SID = 'ExternalAuthAppDetailsController';

    fetchConnections = this.interactionUtils.createFuture(this.i18n.text.common.fetchData(), () => this.externalAuthService.getExternalAuthAppConnections(this.$routeParams.id)
            .then(connections => this.connections = connections));

    fetchAuthAppDetails = this.interactionUtils.createFuture(this.i18n.text.common.fetchData(), () => this.externalAuthService.getExternalAuthApp(this.$routeParams.id)
            .then(app => this.externalAuthApp = app));

    fetchData = this.interactionUtils.createFuture(this.i18n.text.common.fetchData(), () => this.$q.all([this.fetchAuthAppDetails.run({}), this.fetchConnections.run({})]));

    deleteApp = this.interactionUtils.createFuture(this.i18n.text.admin.externalAuthApps.deleteApp(), () => this.externalAuthService.deleteExternalAuthApp(this.$routeParams.id).then(app => {
            this.$location.path('/admin/external-authentication-apps');
        }));

    deleteConnection = this.interactionUtils.createFuture(this.i18n.text.admin.externalAuthApps.deleteConnection(), (context: ExternalAuthToken) => this.externalAuthService.deleteExternalAuthAppConnection(context)
            .then(() => this.connections = this.connections.filter(x => x.id != context.id)));

    externalAuthApp: ExternalAuthApp | null = null;

    connections: ExternalAuthToken[] = [];

    static readonly $inject : string[] = [
        $qSID,
        $modalSID,
        $routeParamsSID,
        $locationSID,
        InteractionUtils.SID,
        I18nService.SID,
        ExternalAuthService.SID,
    ];

    constructor(
        private readonly $q: ng.IQService,
        private readonly $modal: ModalService,
        private readonly $routeParams : Untyped,
        private readonly $location: ng.ILocationService,
        private readonly interactionUtils: InteractionUtils,
        private readonly i18n: I18nService,
        private readonly externalAuthService: ExternalAuthService,
    ) {
        void this.fetchData.run({});
    }

    openUpdateAppModal() {
        const modalInstance = this.$modal.open({
            templateUrl: '/partials/admin/externalAuthApps/createEditExternalAuthApp',
            controller: AuthAppDetailsController,
            controllerAs: 'ctrl',
            resolve: <ExternalAuthAppDetailsLocals>{
                appToUpdate: () => clone(this.externalAuthApp),
            },
        });

        return modalInstance.result.then(value => this.fetchData.run({}));
    }

    editDetails() {
        return this.openUpdateAppModal();
    }
}

angular.module('app').controller(ExternalAuthAppDetailsController.SID, ExternalAuthAppDetailsController);
