/// <reference path="../../../typings/browser.d.ts" />
import { Location, Planner } from '@deltasierra/shared';
import { IntroService } from '../intro/introService';

export class UpcomingPlannerEventsBarCtrl {
    static SID = 'UpcomingPlannerEventsBarCtrl';

    // From directive attributes
    public location!: Location;

    numPlanners = 0;

    isExpanded = false;

    static readonly $inject: string[] = [IntroService.SID];

    constructor(private introService: IntroService) {}

    toggleExpanded() {
        this.isExpanded = !this.isExpanded;
    }

    onPlannersChange(planners: Planner[]): void {
        if (this.introService.isIntroActive('build')) {
            this.numPlanners = 3;
        } else {
            this.numPlanners = planners ? planners.length : 0;
        }
    }
}

export const dsUpcomingPlannerEventsBarSID = 'dsUpcomingPlannerEventsBar';
export const dsUpcomingPlannerEventsBarConfig: ng.IDirective<ng.IScope> = {
    restrict: 'E',
    scope: {},
    templateUrl: '/partials/contentBuilder/upcomingPlannerEventsBar',
    controller: UpcomingPlannerEventsBarCtrl,
    controllerAs: 'ctrl',
    bindToController: {
        location: '=',
    },
};

angular.module('app').controller(UpcomingPlannerEventsBarCtrl.SID, UpcomingPlannerEventsBarCtrl);
angular.module('app').directive(dsUpcomingPlannerEventsBarSID, [() => dsUpcomingPlannerEventsBarConfig]);
