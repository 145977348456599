import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { BrowserRouter } from 'react-router-dom';
import {
    type AssetForPicker,
    DSAlert,
    DSButton,
    DSDialog,
    DSDialogActions,
    DSDialogContent,
    DSDialogTitle,
    Loading,
    Translate,
} from '@deltasierra/components';
import { AssetId, AssignedLocation, t } from '@deltasierra/shared';
import * as React from 'react';
import styled from 'styled-components';
import { DateTime } from 'luxon';
import { ExpressionBinding, OneWayBinding, OptionalOneWayBinding } from '../common/angularData';
import { withAngularIntegration } from '../common/componentUtils/reactComponentRegistration';
import { selectedAssets } from '../graphql';
import { parseAndValidateUniversalNodeId } from '../graphql/utils';
import { AssetLibraryWithLocation } from './components';
import { AssetLibraryActions } from './context';
import { AssetLibrarySettingsContext, CurrentAssetContext } from './contexts';
import { GetAssetForModal, GetAssetForModalVariables } from './__graphqlTypes/GetAssetForModal';
import {
    GetCurrentLocationForAssetLibraryModal,
    GetCurrentLocationForAssetLibraryModalVariables,
} from './__graphqlTypes/GetCurrentLocationForAssetLibraryModal';
import { TrackAssetUsage, TrackAssetUsageVariables } from './__graphqlTypes/TrackAssetUsage';

export type AssetLibraryModalProps = {
    mediaFilter?: Array<'document' | 'image' | 'video'>;
    onAssetChosen: (asset: AssetForPicker) => void;
    onCancel: () => void;
    show: boolean;
    location: AssignedLocation;
    suggestedCollectionId?: string;
};

const GET_CURRENT_LOCATION_FOR_ASSET_LIBRARY_MODAL = gql`
    query GetCurrentLocationForAssetLibraryModal($locationId: ID!) {
        location(id: $locationId) {
            id
            ...LocationFragmentForAssetLibraryWithLocation
        }
    }
    ${AssetLibraryWithLocation.fragments.LOCATION_FRAGMENT_FOR_ASSET_LIBRARY_WITH_LOCATION}
`;

const GET_ASSET_FOR_MODAL = gql`
    query GetAssetForModal($id: ID!) {
        asset(id: $id) {
            __typename
            ... on AssetFile {
                id
                mimeType
                url
            }
        }
    }
`;

export const TRACK_ASSET_USAGE_MUTATION = gql`
    mutation TrackAssetUsage($input: TrackAssetLibraryEventInput!) {
        trackAssetUsage(input: $input) {
            ... on TrackAssetLibraryEventSuccess {
                success
            }
            ... on TrackAssetLibraryEventError {
                message
            }
        }
    }
`;

const TitleBox = styled.div`
    display: flex;
`;
// eslint-disable-next-line max-lines-per-function
export function AssetLibraryModal({
    location,
    mediaFilter,
    onAssetChosen,
    onCancel,
    show,
    suggestedCollectionId,
}: AssetLibraryModalProps): JSX.Element {
    const { data: locationData } = useQuery<
        GetCurrentLocationForAssetLibraryModal,
        GetCurrentLocationForAssetLibraryModalVariables
    >(GET_CURRENT_LOCATION_FOR_ASSET_LIBRARY_MODAL, {
        variables: { locationId: location.graphqlId },
    });

    const [getAsset, { called, data: assetData, loading }] = useLazyQuery<GetAssetForModal, GetAssetForModalVariables>(
        GET_ASSET_FOR_MODAL,
    );

    const [trackAssetUsage] = useMutation<
        TrackAssetUsage,
        TrackAssetUsageVariables
    >(TRACK_ASSET_USAGE_MUTATION);

    const [currentAsset, setCurrentAsset] = React.useState<string | undefined>(undefined);

    const [unselected, setUnselected] = React.useState(true);

    const actions = React.useMemo<AssetLibraryActions>(
        (): AssetLibraryActions => ({
            toggleAllAssetsSelected: () => undefined,
            toggleAssetSelected: asset => {
                const selected = selectedAssets();
                if (selected.find(({ id }) => asset.id === id)) {
                    setUnselected(true);
                    selectedAssets([]);
                } else if (asset.type !== 'folder') {
                    selectedAssets([asset]);
                    setUnselected(false);
                    void getAsset({ variables: { id: asset.id } });
                }
            },
        }),
        [getAsset],
    );

    const handleSubmit = () => {
        if (!!assetData && assetData.asset.__typename === 'AssetFile') {
            const id = parseAndValidateUniversalNodeId<AssetId>(assetData.asset.id, 'asset');
            if (id !== null) {
                selectedAssets([]);
                setUnselected(true);
                onAssetChosen({
                    id,
                    mimeType: assetData.asset.mimeType,
                    url: assetData.asset.url,
                });
                void trackAssetUsage({
                    variables: {
                        input: {
                            browser: window.navigator.userAgent,
                            key: assetData.asset.id,
                            timestamp: DateTime.utc().toString(),
                            timezone: DateTime.now().zoneName,
                        },
                    },
                });
            }
        }
    };

    const handleCancel = () => {
        selectedAssets([]);
        setUnselected(true);
        onCancel();
    };

    return (
        <BrowserRouter>
            <DSDialog
                PaperProps={{ style: { height: 'calc(100% - 128px)' } }}
                disableEnforceFocus // Fixes https://github.com/mui/material-ui/issues/16518#issuecomment-625218550
                fullWidth
                maxWidth="xl"
                open={show}
                style={{ zIndex: 1030 }} // Fixes https://digitalstack.atlassian.net/browse/DSL-1320
            >
                <TitleBox>
                    <DSDialogTitle>{t('COMMON.ASSET_LIBRARY')}</DSDialogTitle>
                    <DSAlert severity="info" style={{ height: '40px', marginLeft: '4rem', marginTop: '1rem' }}>
                        <Translate keyId="ASSET_LIBRARY.EXPLAIN" />
                    </DSAlert>
                </TitleBox>
                <DSDialogContent>
                    <AssetLibrarySettingsContext.Provider value={{
                        hideBin: true,
                        hideOperations: true,
                        mediaFilter,
                        removeAnchorOnFolderIcon: true,
                    }}>
                        <CurrentAssetContext.Provider value={[currentAsset, setCurrentAsset]}>
                            {!locationData?.location && <Loading />}
                            {!!locationData?.location && (
                                <AssetLibraryWithLocation
                                    actions={actions}
                                    enableLightboxPreview={true}
                                    initialAssetId={suggestedCollectionId}
                                    location={locationData.location}
                                />
                            )}
                        </CurrentAssetContext.Provider>
                    </AssetLibrarySettingsContext.Provider>
                </DSDialogContent>
                <DSDialogActions>
                    <DSButton onClick={handleCancel}>{t('COMMON.CANCEL')}</DSButton>
                    <DSButton
                        color="primary"
                        disabled={loading || !called || !assetData || unselected}
                        onClick={handleSubmit}
                    >
                        {t('COMMON.OK')}
                    </DSButton>
                </DSDialogActions>
            </DSDialog>
        </BrowserRouter>
    );
}
AssetLibraryModal.displayName = 'AssetLibraryModal';

export default withAngularIntegration(AssetLibraryModal, 'assetLibraryModal', {
    location: OneWayBinding,
    mediaFilter: OptionalOneWayBinding,
    onAssetChosen: ExpressionBinding,
    onCancel: ExpressionBinding,
    show: OneWayBinding,
    suggestedCollectionId: OneWayBinding,
});
