/// <reference path="../../../typings/browser.d.ts" />
import { Nullable, TryThisSection, TryThisSubSection } from '@deltasierra/shared';
import { DataUtils } from '../common/dataUtils';

export class AddClientLearnSubSectionCtrl {
    static SID = 'AddClientLearnSubSection';

    // From directive attributes
    section!: TryThisSection;

    newSubSection?: Nullable<TryThisSubSection> | null;

    public loading = {};

    static readonly $inject: string[] = [DataUtils.SID];

    constructor(private dataUtils: DataUtils) {}

    startNewSubSection() {
        this.initSubSection();
    }

    cancel() {
        this.newSubSection = null;
    }

    isDuplicateSubSection(): boolean {
        const existingObject = this.dataUtils.findByPredicate(
            subSection => subSection.title == this.newSubSection!.title,
            this.section.subSections!,
        );
        return !!existingObject;
    }

    addNewSubSection() {
        const order = this.section.subSections!.length;
        this.newSubSection!.order = order;
        this.section.subSections!.push(this.newSubSection as TryThisSubSection);
        this.newSubSection = null;
    }

    private initSubSection() {
        this.newSubSection = {
            id: null,
            tryThisSectionId: null,
            title: null,
            tag: null,
            order: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            createdById: null,
            updatedById: null,
            deletedById: null,
        };
    }
}

function createDirective(): ng.IDirective<ng.IScope> {
    return {
        restrict: 'E',
        scope: {},
        templateUrl: '/partials/clients/addClientLearnSubSection',
        controller: AddClientLearnSubSectionCtrl,
        controllerAs: 'ctrl',
        bindToController: {
            section: '=',
        },
    };
}

const DIRECTIVE_SID = 'dsAddClientLearnSubSection';

angular.module('app').controller(AddClientLearnSubSectionCtrl.SID, AddClientLearnSubSectionCtrl);
angular.module('app').directive(DIRECTIVE_SID, [createDirective]);
