/// <reference path="../../../../typings/browser.d.ts" />
import {
    AssignedLocation,
    Currency,
    getDaysSincePrevious,
    getDaysUntilNext,
    isRecurringPaymentNextDateInactive,
    LocationId,
    Nullable,
    RecurringPaymentDetailDto,
    RecurringPaymentListDto,
    Untyped,
    validateNewRecurringPayment,
    validateUpdateExistingActiveRecurringPayment,
    validateUpdateExistingFutureRecurringPayment,
} from '@deltasierra/shared';
import moment from 'moment-timezone';
import { ConfirmModal, confirmModalSID } from '../../common/confirmModal';
import { InteractionUtils } from '../../common/interactionUtils';
import { MvNotifier } from '../../common/mvNotifier';
import { I18nService } from '../../i18n';
import { MvLocation } from '../../locations/mvLocation';
import { CurrencyService } from '../../payments/currencyService';
import { ModalInstance } from '../../typings/angularUIBootstrap/modalService';
import ILocationService = angular.ILocationService;
import IQService = angular.IQService;

interface DatePickerEntry {
    open: boolean;
    minDate: Date | null;
}

export class RecurringPaymentDetailCtrl {
    public static SID = 'recurringPaymentDetailCtrl';

    public static readonly $inject: string[] = [
        '$q',
        '$location',
        '$routeParams',
        confirmModalSID,
        MvNotifier.SID,
        InteractionUtils.SID,
        CurrencyService.SID,
        MvLocation.SID,
        I18nService.SID,
    ];

    public readonly CURRENCIES: ReadonlyArray<Currency>;

    public locationId: LocationId;

    public recurringPaymentId?: number;

    public location?: AssignedLocation;

    public recurringPayment?: Nullable<RecurringPaymentDetailDto>;

    public recurringPayments?: RecurringPaymentListDto[];

    public isCurrentlyActive?: boolean;

    public datePickers: {
        startDate: DatePickerEntry;
        endDate: DatePickerEntry;
        nextDate: DatePickerEntry;
    } = {
        endDate: {
            minDate: null,
            open: false,
        },
        nextDate: {
            minDate: null,
            open: false,
        },
        startDate: {
            minDate: null,
            open: false,
        },
    };

    public fetchLocation = this.interactionUtils.createFuture('retrieve location', () =>
        this.mvLocation.getAssignedLocation(this.locationId).then(location => this.location = location),
    );

    public fetchRecurringPayment = this.interactionUtils.createFuture('retrieve recurring payment', () =>
        this.mvLocation
            .getRecurringPayment(this.locationId, this.recurringPaymentId!)
            .then(recurringPayment => {
                this.recurringPayment = recurringPayment;
            })
            .then(() => this.mvLocation.getCurrentlyActiveRecurringPayment(this.locationId))
            .then(activeRecurringPayment => {
                this.isCurrentlyActive =
                    !!activeRecurringPayment && activeRecurringPayment.id == this.recurringPayment!.id;
            }),
    );

    public fetchRecurringPayments = this.interactionUtils.createFuture('retrieve location recurring payments', () =>
        this.mvLocation.getRecurringPayments(this.locationId).then(recurringPayments => {
            this.recurringPayments = recurringPayments;
        }),
    );

    public submitSaveRecurringPayment = this.interactionUtils.createFuture('save recurring payment', () =>
        this.mvLocation.saveRecurringPayment(this.locationId, this.recurringPayment as RecurringPaymentDetailDto),
    );


    public constructor(
        protected $q: IQService,
        protected $location: ILocationService,
        protected $routeParams: Untyped,
        protected confirmModal: ConfirmModal,
        protected mvNotifier: MvNotifier,
        protected interactionUtils: InteractionUtils,
        protected currencyService: CurrencyService,
        protected mvLocation: MvLocation,
        private i18n: I18nService,
    ) {
        this.CURRENCIES = this.currencyService.CURRENCIES;
        this.locationId = $routeParams.location_id;
        this.recurringPaymentId = $routeParams.recurring_payment_id;
        void this.onInit();
    }

    public updateNextDateMinDate(): void {
        this.datePickers.nextDate.minDate = this.recurringPayment!.startDate;
    }

    public getAmount(recurringPayment: RecurringPaymentDetailDto): number {
        return this.currencyService.getLargestUnit(recurringPayment.currency, recurringPayment.amountInCents);
    }

    public getNextAmount(recurringPayment: RecurringPaymentDetailDto): number {
        return this.currencyService.getLargestUnit(recurringPayment.currency, recurringPayment.nextAmountInCents);
    }

    public toggleDatePicker($event: Event, name: 'endDate' | 'nextDate' | 'startDate'): void {
        $event.preventDefault();
        $event.stopPropagation();
        for (const key of Object.getOwnPropertyNames(this.datePickers)) {
            const datePicker = (this.datePickers as Untyped)[key];
            if (key === name) {
                datePicker.open = !datePicker.open;
            } else {
                datePicker.open = false;
            }
        }
    }

    public isNew(): boolean {
        return !this.recurringPaymentId;
    }

    public clickSaveRecurringPayment(): ModalInstance | ng.IPromise<void> | void {
        // It's not nice to perform validation here, but the date picker doesn't validate manual text input against
        // The min-date attribute. To fix this we'd need to write our own directive with a custom validator.
        // Plus we have some cross-field validations to perform.
        let validationMessages;
        if (this.isNew()) {
            validationMessages = validateNewRecurringPayment(this.recurringPayment as RecurringPaymentDetailDto);
        } else if (this.isCurrentlyActive) {
            validationMessages = validateUpdateExistingActiveRecurringPayment(
                this.recurringPayment as RecurringPaymentDetailDto,
            );
        } else {
            validationMessages = validateUpdateExistingFutureRecurringPayment(
                this.recurringPayment as RecurringPaymentDetailDto,
            );
        }
        if (validationMessages && validationMessages.length > 0) {
            for (const message of validationMessages) {
                this.mvNotifier.expectedError(message);
            }
            return;
        }

        const daysSincePrevious = this.getDaysSincePrevious();
        const daysUntilNext = this.getDaysUntilNext();
        if (daysSincePrevious !== 0 || daysUntilNext !== 0) {
            const messages = [];
            if (daysSincePrevious !== 0) {
                messages.push(`${daysSincePrevious} days before this recurring payment`);
            }
            if (daysUntilNext !== 0) {
                messages.push(`${daysUntilNext} days after this recurring payment`);
            }
            const gapMessage = messages.join(', and ');

            return this.confirmModal.open(
                'Payment gap',
                `There is a gap of ${gapMessage}, during which time no payments will occur. Do you want to proceed?`,
                () => this.performSave(),
                () => {
                    //
                },
            );
        } else {

            return this.performSave();
        }
    }

    public nextDateIsAfterEndDate(): boolean {
        return (
            !!this.recurringPayment &&
            isRecurringPaymentNextDateInactive(this.recurringPayment as RecurringPaymentDetailDto)
        );
    }

    public getDaysSincePrevious(): number {
        const rp = this.recurringPayment;
        const recurringPayments = this.getRecurringPaymentsToAnalyse();
        return getDaysSincePrevious(rp as RecurringPaymentDetailDto, recurringPayments);
    }

    public getDaysUntilNext(): number {
        const rp = this.recurringPayment;
        const recurringPayments = this.getRecurringPaymentsToAnalyse();
        return getDaysUntilNext(rp as RecurringPaymentDetailDto, recurringPayments);
    }

    protected async onInit(): Promise<void> {
        return this.$q
            .all([this.fetchLocation.run({}), this.fetchRecurringPayments.run({})])
            .then(() => {
                if (this.recurringPaymentId) {
                    return this.fetchRecurringPayment.run({});
                } else {
                    this.recurringPayment = {
                        amountInCents: null,
                        currency: null,
                        endDate: null,
                        id: null,
                        nextAmountInCents: null,
                        nextDate: null,
                        startDate: null,
                    };
                }
            })
            .then(() => this.initDatePickers());
    }

    protected initDatePickers(): void {
        this.datePickers.startDate.minDate = moment().startOf('day').add(1, 'days').toDate();
        this.datePickers.endDate.minDate = this.isNew()
            ? moment().startOf('day').add(2, 'days').toDate()
            : moment().startOf('day').add(1, 'days').toDate();
        if (this.isNew()) {
            this.datePickers.nextDate.minDate = this.datePickers.startDate.minDate;
        } else if (this.recurringPayment!.startDate && moment().isAfter(this.recurringPayment!.startDate)) {
            this.datePickers.nextDate.minDate = moment().startOf('day').add(1, 'days').toDate();
        } else {
            this.datePickers.nextDate.minDate = this.recurringPayment!.startDate;
        }
    }

    private performSave() {
        return this.submitSaveRecurringPayment.run({}).then(() => {
            this.mvNotifier.notify(this.i18n.text.admin.subscriptions.notifyRecurringPaymentSaved());
            this.$location.path(
                `/admin/subscriptions/clients/${this.location!.clientId}/locations/${this.location!.id}`,
            );
        });
    }

    private getRecurringPaymentsToAnalyse() {
        const rps = this.recurringPayments ? this.recurringPayments.slice() : [];
        if (this.recurringPayment!.id) {
            for (let i = 0; i < rps.length; i++) {
                const rp = rps[i];
                if (rp.id === this.recurringPayment!.id) {
                    rps[i] = this.recurringPayment as RecurringPaymentListDto;
                    break;
                }
            }
        } else {
            rps.push(this.recurringPayment as RecurringPaymentListDto);
        }
        rps.sort((paymentA, paymentB) => paymentB.startDate.getTime() - paymentA.startDate.getTime());
        return rps;
    }
}

angular.module('app').controller(RecurringPaymentDetailCtrl.SID, RecurringPaymentDetailCtrl);
