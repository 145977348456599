import { AssignedLocation, LocationIdHierarchy } from '@deltasierra/shared';
import { useAngularComponentRenderer, useAngularScope } from '../../../common/componentUtils/angularRendering';
import { useAngularServiceContext } from '../../../common/componentUtils/angularServiceContexts';
import { ContentBuilder } from '../../contentBuilder';
import { CommonDataType } from '../common/common-data-type';
import { MvContentBuilderPublishCtrl } from '../mvContentBuilderPublishCtrl';
import { PublishCallback, PublishFinishCallback } from '../types';
import { multipleLocationPublishConfig } from './imageDirective';
import { MvMultipleLocationPublishCtrl } from './imagePublishCtrl';

export type MultipleLocationPublishProps<CommonData extends CommonDataType> = {
    commonData: CommonData;
    contentBuilder: ContentBuilder;
    locations: LocationIdHierarchy[];
    onCancel: () => void;
    onFinish: PublishFinishCallback;
    originalLocation: AssignedLocation;
    publishCallback: PublishCallback<CommonData>;
    publishCtrl: MvContentBuilderPublishCtrl;
};

export function MultipleLocationPublish<CommonData extends CommonDataType>(
    props: MultipleLocationPublishProps<CommonData>,
): JSX.Element {
    const builderConstants = useAngularServiceContext('builderConstants');
    const scope = useAngularScope<{
        [K in keyof typeof multipleLocationPublishConfig.bindToController]: MvMultipleLocationPublishCtrl[K];
    }>(
        {
            chosenLocations: props.locations,
            commonData: props.commonData,
            contentBuilder: props.contentBuilder,
            finishCallback: props.onFinish as any,
            originalLocation: props.originalLocation,
            publishCallback: props.publishCallback as any,
            publishCtrl: props.publishCtrl,
        },
        {},
        {
            [builderConstants.EVENTS.PUBLISH_CANCEL]: props.onCancel,
        },
    );
    return useAngularComponentRenderer(
        '<multiple-location-publish' +
            ' publish-ctrl="publishCtrl"' +
            ' content-builder="contentBuilder"' +
            ' original-location="originalLocation"' +
            ' chosen-locations="chosenLocations"' +
            ' common-data="commonData"' +
            ' publish-callback="publishCallback"' +
            ' finish-callback="finishCallback"' +
            '></multiple-location-publish>',
        scope,
    );
}
MultipleLocationPublish.displayName = 'MultipleLocationPublish';
