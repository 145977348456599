import * as React from 'react';
import { Translate } from '@deltasierra/components';
import { OneWayBinding, OptionalExpressionBinding, OptionalOneWayBinding } from '../../../common/angularData';
import { withAngularIntegration } from '../../../common/componentUtils/reactComponentRegistration';
import { useLazyLocationDraftsCount, LocationDraftLocation } from '../../hooks/use-location-drafts-count';
import { useAngularServiceContext } from '../../../common/componentUtils/angularServiceContexts';
import { UpdateTemplateConfirmationModal } from './UpdateTemplateConfirmationModal';

export type SaveTemplateButtonProps = {
    builderTemplateId: string;
    onClick?: (deleteLocationDrafts: boolean) => void;
    disabled?: boolean;
};

export function SaveTemplateButton({ builderTemplateId, disabled, onClick }: SaveTemplateButtonProps): JSX.Element {
    const [showModal, setShowModal] = React.useState(false);
    const [locationDraftsCount, setLocationDraftsCount] = React.useState(0);
    const [locationDraftsLocationList, setLocationDraftsLocationList] = React.useState<LocationDraftLocation[]>([]);
    const [getLocationDraftsCount] = useLazyLocationDraftsCount(builderTemplateId);
    const sentryService = useAngularServiceContext('SentryService');

    const handleConfirm = (deleteLocationDrafts: boolean) => {
        setShowModal(false);
        onClick?.(deleteLocationDrafts);
    };

    const handleCancel = () => {
        setShowModal(false);
    };

    const handleClickSave = () => {
        getLocationDraftsCount()
            .then(({ count, locations }) => {
                setLocationDraftsLocationList(locations);
                setLocationDraftsCount(count);
                if (count > 0) {
                    setShowModal(true);
                } else {
                    onClick?.(false);
                }
            })
            .catch((error: unknown) => {
                sentryService.captureException('Failed to get location drafts count', error);
                onClick?.(false);
            });
    };

    return (
        <>
            <button
                className="btn btn-primary form-control"
                disabled={disabled}
                type="button"
                onClick={handleClickSave}
            >
                <Translate keyId="COMMON.SAVE" />
            </button>
            <UpdateTemplateConfirmationModal
                locationDraftsCount={locationDraftsCount}
                locationDraftsLocationList={locationDraftsLocationList}
                show={showModal}
                onCancel={handleCancel}
                onConfirm={handleConfirm}
            />
        </>
    );
}

SaveTemplateButton.displayName = 'SaveTemplateButton';

export default withAngularIntegration(SaveTemplateButton, 'saveTemplateButton', {
    builderTemplateId: OneWayBinding,
    disabled: OptionalOneWayBinding,
    onClick: OptionalExpressionBinding,
});
