import { gql, useLazyQuery } from '@apollo/client';
import { Dropdown } from '@deltasierra/components';
import { noop } from '@deltasierra/object-utilities';
import { t, AssignedLocation } from '@deltasierra/shared';
import * as React from 'react';
import { OneWayBinding } from '../../../common/angularData';
import { withAngularIntegration } from '../../../common/componentUtils/reactComponentRegistration';
import { relayConnectionToArray } from '../../../graphql/utils';
import {
    GetCollectionsForPlannerPage,
    GetCollectionsForPlannerPageVariables,
} from './__graphqlTypes/GetCollectionsForPlannerPage';

const GET_COLLECTIONS_FOR_PLANNER_PAGE = gql`
    query GetCollectionsForPlannerPage($id: ID!) {
        location(id: $id) {
            id
            clientCollections {
                edges {
                    node {
                        id
                        legacyId
                        title
                    }
                }
            }
        }
    }
`;

export interface PlannerCollectionDropdownProps {
    location?: AssignedLocation;
    collectionId: number | null;
    onChange?: (collectionId: number | null) => void;
}

export function PlannerCollectionDropdown({
    collectionId = null,
    location,
    onChange = noop,
}: PlannerCollectionDropdownProps): JSX.Element {
    const [getCollections, { data }] = useLazyQuery<
        GetCollectionsForPlannerPage,
        GetCollectionsForPlannerPageVariables
    >(GET_COLLECTIONS_FOR_PLANNER_PAGE);
    const [selectedCollection, setSelectedCollection] = React.useState<{
        legacyId: number | null;
        title: string;
    } | null>(null);

    const [filter, setFilter] = React.useState('');

    const dropdownItems: Array<{ legacyId: number; title: string }> = React.useMemo(
        () =>
            relayConnectionToArray(data?.location?.clientCollections)
                .map(({ legacyId, title }) => ({ legacyId, title }))
                .filter(({ title }) => title.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) > -1),
        [data?.location, filter],
    );

    const nullOption = { legacyId: null, title: 'None' };

    React.useEffect(() => {
        if (location) {
            void getCollections({ variables: { id: location.graphqlId } });
        }
    }, [location, getCollections]);

    React.useEffect(() => {
        // Setting selected collection state based on existing collection id
        if (collectionId && dropdownItems.length > 0) {
            const preselectedCollection = dropdownItems.find(item => item.legacyId === collectionId);
            setSelectedCollection(preselectedCollection ? preselectedCollection : null);
        }
    }, [collectionId, dropdownItems]);

    return (
        <Dropdown
            filterable
            getOptionTemplate={item => <p>{item.title}</p>}
            getSelectedTemplate={item => <>{item.title}</>}
            getValue={value => value.title}
            items={[nullOption, ...dropdownItems]}
            menuStyle={{ maxHeight: '300px', overflowY: 'auto', width: '100%' }}
            selectText={t('PLAN.ASSOCIATED_BUILDER_RESOURCES.SELECT_A_COLLECTION')}
            value={selectedCollection}
            onFilterChange={setFilter}
            onSelect={value => {
                onChange(value.legacyId);
                setSelectedCollection(value);
            }}
        />
    );
}

PlannerCollectionDropdown.displayName = 'PlannerCollectionDropdown';

export const PlannerCollectionDropdownWithAngular = withAngularIntegration(
    PlannerCollectionDropdown,
    'plannerCollectionDropdown',
    {
        collectionId: OneWayBinding,
        location: OneWayBinding,
        onChange: OneWayBinding,
    },
);
