/// <reference path="../../../typings/browser.d.ts" />
import moment from 'moment-timezone';
import {
    ClientId,
    LayerType,
    ImageLayer,
    LocationType,
    BuilderDocumentFormat,
    CopyrightStatus,
    UploadId,
    getFontMetricsForArial,
    TextFit,
    TextLayer,
    UserId,
    BuilderTemplateId,
    ContentBuilderTemplateWithBasicAssociations,
    LocationId,
    Planner,
    Channel,
    ChannelId,
    Activity,
    ActivityId,
    ProtocolRelativeUrl,
    BuilderTemplateFormatId,
    PlatformId,
} from '@deltasierra/shared';
import { I18nService } from '../i18n';
import { convertIdToUniversalNodeId } from '../graphql/utils';

const DUMMY_ACTIVITY_ID = ActivityId.from(123);
const DUMMY_CHANNEL_ID = ChannelId.from(234);
const DUMMY_CLIENT_ID = ClientId.from(1);
const DUMMY_PLATFORM_ID = PlatformId.from(345);

export class IntroDataService {
    public static readonly SID = 'IntroDataService';

    public readonly dummyChannel: Channel = {
        activityId: DUMMY_ACTIVITY_ID,
        createdAt: new Date(),
        createdById: null,
        deletedAt: null,
        deletedById: null,
        id: DUMMY_CHANNEL_ID,
        isSelfService: true,
        isSupported: false,
        label: this.i18nService.text.plan.introDummyChannel(),
        platformId: DUMMY_PLATFORM_ID,
        updatedAt: new Date(),
        updatedById: null,
    };

    public readonly dummyActivity: Activity = {
        channels: [this.dummyChannel],
        clientId: DUMMY_CLIENT_ID,
        createdAt: new Date(),
        createdById: null,
        deletedAt: null,
        deletedById: null,
        id: DUMMY_ACTIVITY_ID,
        label: this.i18nService.text.plan.introDummyActivity(),
        order: 0,
        updatedAt: new Date(),
        updatedById: null,
    };

    private readonly exampleTemplate: ContentBuilderTemplateWithBasicAssociations = {
        buildableTemplateId: null,
        categories: [],
        clientId: ClientId.from(1),
        compositeImage: {
            builderTemplateId: null,
            category: 'builderTemplateImage',
            commentId: null,
            contentType: 'image/png',
            copyrightStatus: CopyrightStatus.Unknown,
            createdAt: new Date('2017-01-02T02:38:40.426Z'),
            createdById: UserId.from(10),
            deletedAt: null,
            deletedById: null,
            ext: 'png',
            filename: 'File 1.png',
            graphqlId: convertIdToUniversalNodeId('upload', 159),
            height: 1080,
            id: 159 as UploadId,
            isImage: true,
            key: 'builderTemplate/227b88dd-d523-4f31-b953-11a5a858df4e.png',
            locationDraftId: null,
            multiImagePublishedArtifactId: null,
            plannerId: null,
            reportId: null,
            savedEmailPartId: null,
            size: 129340,
            status: 'uploaded',
            thumb256x256key: 'builderTemplate/256x256/5495fc4b-5db2-4b03-934d-3456f74d712f.png',
            // Wait, this isn't protocol relative! It's a big fat phony!
            thumb256x256url: ProtocolRelativeUrl.from('img/intro-template-thumb.png'),
            tryThisId: null,
            updatedAt: new Date('2017-01-02T02:38:44.578Z'),
            updatedById: UserId.from(10),
            url: ProtocolRelativeUrl.from(
                '//content-dev.digitalstack.io/builderTemplate/227b88dd-d523-4f31-b953-11a5a858df4e.png',
            ),
            width: 1080,
        },
        compositeImageId: 159 as UploadId,
        createdAt: new Date('2017-01-02T02:38:46.089Z'),
        createdById: UserId.from(10),
        currentRevisionId: null,
        deletedAt: null,
        deletedById: null,
        document: {
            animation: null,
            background: 'rgb(91, 200, 242)',
            bleed: {
                amount: null,
                units: null,
            },
            channelData: {},
            clipTextLayers: false,
            dimensions: {
                height: 1080,
                unit: 'px',
                width: 1080,
            },
            editableFields: [
                {
                    control: 'textarea',
                    label: 'Text',
                    layerId: 2,
                    path: 'text',
                },
            ],
            format: BuilderDocumentFormat.content,
            formatVersion: '1',
            instructions: null,
            layers: [
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                {
                    blendMode: undefined,
                    effects: [],
                    fill: 'rgb(0, 0, 0)',
                    forceUserToProvideImage: false,
                    height: 311,
                    id: 1,
                    location: 'img/intro-template.png',
                    locationType: LocationType.external,
                    lockAspectRatio: false,
                    opacity: 1,
                    originalHeight: 311,
                    originalWidth: 1080,
                    position: {
                        x: 0,
                        y: 254,
                    },
                    rotation: 0,
                    scale: 1,
                    selected: false,
                    showPlaceholderOverlay: false,
                    skewX: 0,
                    skewY: 0,
                    stroke: 'rgb(0, 0, 0)',
                    strokeWidth: 0,
                    title: 'Background image',
                    type: LayerType.image,
                    visible: true,
                    width: 1080,
                } as ImageLayer,
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                {
                    blendMode: undefined,
                    clip: false,
                    direction: 'inherit',
                    effects: [],
                    fill: 'rgb(255, 255, 255)',
                    fontFamily: 'Arial',
                    fontMetrics: getFontMetricsForArial(),
                    fontSize: 120,
                    fontSizeUnit: 'pt',
                    forceUserToProvideText: false,
                    height: 463.66037735849056,
                    id: 2,
                    letterSpacing: 0,
                    lineHeight: 1.2,
                    lockAspectRatio: false,
                    lowercase: false,
                    maxLength: 50,
                    opacity: 1,
                    position: {
                        x: 0,
                        y: -187,
                    },
                    rotation: 0,
                    scale: 1,
                    selected: false,
                    skewX: 0,
                    skewY: 0,
                    stroke: 'rgb(0, 0, 0)',
                    strokeWidth: 0,
                    text: 'Your message here',
                    textAlign: 'center',
                    textBaseline: 'top',
                    textFit: TextFit.wordWrap,
                    textVerticalAlign: 'center',
                    title: 'Custom message',
                    type: LayerType.text,
                    uppercase: true,
                    visible: true,
                    width: 969.9622641509434,
                } as TextLayer,
            ],
            multiImage: null,
            pages: [],
            textSubstitutionFields: [
                {
                    displayName: 'Location',
                    key: 'location',
                },
            ],
            title: 'Example template',
            useOldTextRenderingLineHeightFix: false,
            useTextRenderingLineHeightFix: true,
        },
        documentFormat: BuilderDocumentFormat.content,
        documentFormatVersion: '1',
        editableImage: false,
        editableText: false,
        editableVideo: false,
        formats: [
            {
                animatable: false,
                canPublishTo: 'instagram',
                groupLabel: 'Instagram',
                height: 612,
                id: BuilderTemplateFormatId.from(5),
                label: 'Instagram',
                platform: {
                    label: 'Instagram',
                    name: 'instagram',
                },
                platformId: 1, // TODO: fix this
                type: 'image',
                width: 612,
            },
        ],
        graphqlId: 'dummy-id',
        id: 1 as BuilderTemplateId,
        isDraft: false,
        isMultiImage: false,
        mobile: false,
        tags: [],
        title: this.i18nService.text.intro.exampleTemplateTitle(),
        updatedAt: new Date('2017-01-02T02:38:46.122Z'),
        updatedById: UserId.from(10),
    };

    private readonly exampleGraphqlTemplate = {
        ...this.exampleTemplate,
        compositeImage: {
            thumb256x256url: this.exampleTemplate.compositeImage!.thumb256x256url!,
        },
    };

    private readonly examplePlanner: Planner = {
        activityId: DUMMY_ACTIVITY_ID,
        assignedToId: null,
        builderTemplateCategoryId: null,
        campaignRolloutId: null,
        channels: [this.dummyChannel],
        clientId: DUMMY_CLIENT_ID,
        collectionId: null,
        contentSupplied: true,
        createdAt: moment('2017-04-12T05:28:52.526Z').toDate(),
        createdById: null,
        date: moment().add(1, 'month').set('date', 11).toDate(),
        deletedAt: null,
        deletedById: null,
        deviceId: null,
        expectedLeads: null,
        graphqlId: convertIdToUniversalNodeId('plannerEvent', 1),
        id: 1,
        instructions: null,
        isArtifactRequired: true,
        isSupported: false,
        locationId: LocationId.from(7),
        maxSpend: null,
        postedBy: 'Manager',
        recurringPlannerId: null,
        status: 'planned',
        text: null,
        title: this.i18nService.text.intro.examplePlannerTitle(),
        updatedAt: moment('2017-04-12T05:28:52.526Z').toDate(),
        updatedById: null,
    };

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public static readonly $inject: string[] = [I18nService.SID];

    public constructor(private readonly i18nService: I18nService) {}

    public getExampleTemplate(): ContentBuilderTemplateWithBasicAssociations {
        return this.exampleTemplate;
    }

    public getExampleGraphqlTemplate(): Omit<
        ContentBuilderTemplateWithBasicAssociations,
        'compositeImage' | 'compositeImageId'
    > & { compositeImage: { thumb256x256url: ProtocolRelativeUrl } } {
        return this.exampleGraphqlTemplate;
    }

    public getExamplePlanner(): Planner {
        return this.examplePlanner;
    }
}

angular.module('app').service(IntroDataService.SID, IntroDataService);
