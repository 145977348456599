import { BuilderTemplateCategoryId, BuilderTemplateCategorySimpleDto, ClientId } from '@deltasierra/shared';
import { BuilderTemplateCategoryApiClient } from '../../../clients/builderTemplateCategories/builderTemplateCategoryApiClient';
import {
    $scopeSID,
    ExpressionBinding,
    ExpressionCallback,
    ILifecycleHooks,
    OptionalTwoWayBinding,
    simpleComponent,
    TwoWayBinding,
} from '../../../common/angularData';
import { InteractionUtils } from '../../../common/interactionUtils';
import { I18nService } from '../../../i18n';
import {
    AngularjsDropdownMultiselectEvents,
    AngularjsDropdownMultiselectExtraSettings,
    AngularjsDropdownMultiselectTranslationTexts,
} from '../../../typings/angularjsDropdownMultiselect/angularjsDropdownMultiselect';

import IScope = angular.IScope;

export class BuilderTemplateCategoryPickerController implements ILifecycleHooks {
    static SID = 'BuilderTemplateCategoryPickerController';

    clientId!: ClientId;

    change!: ExpressionCallback<{ categories: BuilderTemplateCategoryId[] }>;

    preselectedCategories?: BuilderTemplateCategoryId[];

    options: BuilderTemplateCategorySimpleDto[] = [];

    selectedOptions: BuilderTemplateCategorySimpleDto[] = [];

    multiselectSettings: AngularjsDropdownMultiselectExtraSettings = {
        checkBoxes: true,
        displayProp: 'title' as keyof BuilderTemplateCategorySimpleDto,
        showCheckAll: false,
        showUncheckAll: true,
        enableSearch: true,
        searchField: 'title' as keyof BuilderTemplateCategorySimpleDto,
        scrollable: true,
        smartButtonMaxItems: 1,
        keyboardControls: true,
    };

    multiselectEvents: AngularjsDropdownMultiselectEvents = {
        onSelectionChanged: () => this.onSelectionChange(),
    };

    multiselectTexts: AngularjsDropdownMultiselectTranslationTexts = {
        buttonDefaultText: this.i18nService.text.common.categories(),
        dynamicButtonTextSuffix: this.i18nService.text.common.categories(),
        searchPlaceholder: this.i18nService.text.common.search(),
        uncheckAll: this.i18nService.text.common.none(),
    };

    fetchCategories = this.interactionUtils.createFuture(this.i18nService.text.common.fetchData(), () =>
        this.builderTemplateCategoryApiClient
            .getAllBuilderTemplateCategoriesForClient(this.clientId)
            .then(categories => {
                const localeCompare = this.i18nService.getLocaleCompare();
                this.options = categories.sort((a, b) => localeCompare(a.title, b.title));
                this.preselectOptions();
            }),
    );

    static readonly $inject: string[] = [
        $scopeSID,
        I18nService.SID,
        InteractionUtils.SID,
        BuilderTemplateCategoryApiClient.SID,
    ];

    constructor(
        protected readonly $scope: IScope,
        protected readonly i18nService: I18nService,
        protected readonly interactionUtils: InteractionUtils,
        protected readonly builderTemplateCategoryApiClient: BuilderTemplateCategoryApiClient,
    ) {}

    $onInit() {
        this.initWatchers();
        if (this.clientId) {
            return this.fetchCategories.run({});
        }
    }

    protected initWatchers() {
        this.$scope.$watch(
            () => this.clientId,
            (newValue, oldValue) => newValue != oldValue && newValue != undefined && this.fetchCategories.run({}),
        );
        this.$scope.$watch(
            () => this.preselectedCategories,
            (newValue, oldValue) => newValue != oldValue && newValue != undefined && this.preselectOptions(),
        );
    }

    protected preselectOptions() {
        const selectedCategories = [];
        if (this.preselectedCategories && this.preselectedCategories.length > 0) {
            for (const option of this.options) {
                if (this.preselectedCategories.indexOf(option.id) > -1) {
                    selectedCategories.push(option);
                }
            }
        }
        this.selectedOptions = selectedCategories;
    }

    onSelectionChange() {
        this.change({
            categories: this.selectedOptions.map(category => category.id),
        });
    }
}

export const BuilderTemplateCategoryPickerSID = 'builderTemplateCategoryPicker';
export const BuilderTemplateCategoryPickerConfig = simpleComponent(
    BuilderTemplateCategoryPickerController,
    'partials/contentBuilder/common/builderTemplateCategoryPicker/builderTemplateCategoryPicker.jade',
    {
        clientId: TwoWayBinding,
        change: ExpressionBinding,
        preselectedCategories: OptionalTwoWayBinding,
    },
);

angular.module('app').directive(BuilderTemplateCategoryPickerSID, [() => BuilderTemplateCategoryPickerConfig]);
