import * as React from 'react';
import { t } from '@deltasierra/shared';
import { DSButton, DSDropdown, DSMenuItem, DSMenuList, Translate } from '@deltasierra/components';
import { gql, useMutation } from '@apollo/client';
import styled from 'styled-components';
import { selectedAssets } from '../../../graphql';
import { EditAssetsModal } from '../EditAssetsModal';
import {
    AssetPermission,
    EditAssetsBulkInput,
    RecycleAssetsInput,
    RestoreAssetsInput,
} from '../../../../../__graphqlTypes/globalTypes';
import {
    AssetOperationsDropdownFragment,
    AssetOperationsDropdownFragment_AssetFile,
} from './__graphqlTypes/AssetOperationsDropdownFragment';
import { RecycleAssetsForDropdown, RecycleAssetsForDropdownVariables } from './__graphqlTypes/RecycleAssetsForDropdown';
import { RestoreAssets, RestoreAssetsVariables } from './__graphqlTypes/RestoreAssets';
import { AssetOperationsDropdownAssetFolderFragment } from './__graphqlTypes/AssetOperationsDropdownAssetFolderFragment';
import { AssetOperationsDropdownCollectionFragment } from './__graphqlTypes/AssetOperationsDropdownCollectionFragment';
import { SetExpiryNull, SetExpiryNullVariables } from './__graphqlTypes/SetExpiryNull';
import { MoveFolderDialog } from './MoveFolderDialog';

const RECYCLE_ASSETS_MUTATION = gql`
    mutation RecycleAssetsForDropdown($input: RecycleAssetsInput!) {
        recycleAssets(input: $input) {
            __typename
            ... on RecycledAssetsNode {
                ids
            }
        }
    }
`;

const RESTORE_ASSETS_MUTATION = gql`
    mutation RestoreAssets($input: RestoreAssetsInput!) {
        restoreAssets(input: $input) {
            __typename
            ... on RestoredAssetsNode {
                ids
            }
        }
    }
`;

const SET_EXPIRY_NULL = gql`
    mutation SetExpiryNull($input: EditAssetsBulkInput!) {
        editAssetsBulk(input: $input) {
            __typename
            ... on EditAssetsBulkPayload {
                assets {
                    edges {
                        node {
                            __typename
                            ... on AssetFile {
                                id
                                expires
                            }
                        }
                    }
                }
            }
        }
    }
`;

const fragments = {
    AssetOperationsDropdownAssetFolderFragment: gql`
        fragment AssetOperationsDropdownAssetFolderFragment on AssetFolder {
            id
            collection {
                id
                permissions
            }
        }
    `,
    AssetOperationsDropdownCollectionFragment: gql`
        fragment AssetOperationsDropdownCollectionFragment on Collection {
            id
            permissions
        }
    `,
    AssetOperationsDropdownFragment: gql`
        fragment AssetOperationsDropdownFragment on AssetFileOrFolder {
            __typename
            ... on AssetFile {
                id
                selected @client
                ...EditAssetsModalFragment
            }
            ... on AssetFolder {
                id
                selected @client
            }
        }
        ${EditAssetsModal.fragments.EditAssetsModalFragment}
    `,
};

function getPermissions(currentAsset: AssetOperationsDropdownProps['currentAsset']) {
    if (!currentAsset) {
        return [];
    } else if (currentAsset.__typename === 'AssetFolder') {
        return currentAsset.collection.permissions;
    } else {
        return currentAsset.permissions;
    }
}

export interface AssetOperationsDropdownProps {
    currentAsset: AssetOperationsDropdownAssetFolderFragment | AssetOperationsDropdownCollectionFragment | null;
    assets: ReadonlyArray<AssetOperationsDropdownFragment>;
    expired?: boolean;
    recycled?: boolean;
}

/**
 * AssetOperationsDropdown
 * A dropdown that displays a dynamic list of options given a provided list of assets
 * These options can be: recycle, restore, set expiry, edit tags, move folder
 *
 * @param props - AssetOperationsDropdownProps
 * @param props.currentAsset - Folder or Collection fragment provided by component (or null for some reason)
 * @param props.assets - List of assets that any action will affect
 * @returns AssetOperationsDropdown
 */

export function AssetOperationsDropdown(props: AssetOperationsDropdownProps): JSX.Element {
    const { assets, currentAsset, expired, recycled } = props;
    const [recycleAssets] = useMutation<RecycleAssetsForDropdown, RecycleAssetsForDropdownVariables>(
        RECYCLE_ASSETS_MUTATION,
        {
            awaitRefetchQueries: true,
            refetchQueries: ['GetLocationBinAssets', 'GetClientBinAssets', 'GetCollectionForCollectionAssets'],
        },
    );
    const [restoreAssets] = useMutation<RestoreAssets, RestoreAssetsVariables>(RESTORE_ASSETS_MUTATION, {
        awaitRefetchQueries: true,
        refetchQueries: ['GetLocationBinAssets', 'GetClientBinAssets', 'GetCollectionForCollectionAssets'],
    });
    const [setExpiryNull] = useMutation<SetExpiryNull, SetExpiryNullVariables>(SET_EXPIRY_NULL, {
        awaitRefetchQueries: true,
        refetchQueries: ['GetLocationExpiredAssets', 'GetClientExpiredAssets'],
    });
    const [showEditAssets, setShowEditAssets] = React.useState(false);
    const [showMoveModal, setShowMoveModal] = React.useState(false);
    const selected = assets.filter(asset => asset.selected);
    const selectedFiles = selected.filter(
        // eslint-disable-next-line camelcase
        (asset): asset is AssetOperationsDropdownFragment_AssetFile => asset.__typename === 'AssetFile',
    );
    const areAllAssetFiles = selectedFiles.length === selected.length;

    const handleClickRecycle = async () => {
        const input: RecycleAssetsInput = {
            ids: selected.map(({ id }) => id),
        };
        selectedAssets([]);
        await recycleAssets({
            variables: { input },
        });
    };

    const handleClickRestore = async () => {
        const input: RestoreAssetsInput = {
            ids: selected.map(({ id }) => id),
        };
        selectedAssets([]);
        await restoreAssets({ variables: { input } });
    };

    const handleSetExpiryNull = async () => {
        const input: EditAssetsBulkInput = {
            assets: selected.map(({ id }) => ({ expires: null, id })),
        };
        selectedAssets([]);
        await setExpiryNull({ variables: { input } });
    };

    const permissions = getPermissions(currentAsset);
    const showActions = permissions.indexOf(AssetPermission.UPDATE) >= 0 || recycled || expired;

    return (
        <>
            {showActions && selected.length > 0 && (
                <FlexRight>
                    <SelectedSpan>{`${selected.length} ${t('COMMON.SELECTED')}`}</SelectedSpan>
                    <DSDropdown
                        render={toggle => (
                            <DSButton
                                color="primary"
                                data-cy="asset-operations-button"
                                endIcon={<span className="caret caret-right-align" />}
                                style={{ padding: '6px 25px' }}
                                variant="contained"
                                onClick={() => toggle('toggleOn')}
                            >
                                {t('COMMON.ACTIONS')}
                            </DSButton>
                        )}
                    >
                        {requestClose => (
                            <DSMenuList>
                                {areAllAssetFiles && !recycled && !expired && (
                                    <DSMenuItem
                                        onClick={() => {
                                            requestClose();
                                            setShowEditAssets(true);
                                        }}
                                    >{`${t('COMMON.EDIT_ASSETS')}...`}</DSMenuItem>
                                )}
                                {!recycled && !expired && (
                                    <>
                                        <DSMenuItem
                                            data-cy="move-asset-to-folder-button"
                                            onClick={() => {
                                                requestClose();
                                                setShowMoveModal(true);
                                            }}
                                        >{`${t('COMMON.MOVE_TO_FOLDER')}...`}</DSMenuItem>
                                        <DSMenuItem
                                            data-cy="bin-asset-button"
                                            onClick={async () => {
                                                requestClose();
                                                await handleClickRecycle();
                                            }}
                                        >
                                            {t('ASSET_LIBRARY.BIN')}
                                        </DSMenuItem>
                                    </>
                                )}
                                {recycled && (
                                    <DSMenuItem
                                        onClick={async () => {
                                            requestClose();
                                            await handleClickRestore();
                                        }}
                                    >
                                        <Translate keyId="ASSET_LIBRARY.RESTORE" />
                                    </DSMenuItem>
                                )}
                                {expired && (
                                    <DSMenuItem
                                        // Icon="clock"
                                        onClick={async () => {
                                            requestClose();
                                            await handleSetExpiryNull();
                                        }}
                                    >
                                        {t('ASSET_LIBRARY.REMOVE_EXPIRATION_DATE')}
                                    </DSMenuItem>
                                )}
                            </DSMenuList>
                        )}
                    </DSDropdown>
                </FlexRight>
            )}
            {!recycled && !expired && (
                <EditAssetsModal assets={selectedFiles} show={showEditAssets} onClose={() => setShowEditAssets(false)} />
            )}
            {!!currentAsset && (
                <MoveFolderDialog
                    assetIdsToMove={selected.map(({ id }) => id)}
                    initialCollectionOrFolderId={currentAsset.id}
                    isOpen={showMoveModal}
                    onRequestClose={() => {
                        selectedAssets([]);
                        setShowMoveModal(false);
                    }}
                />
            )}
        </>
    );
}
AssetOperationsDropdown.displayName = 'AssetOperationsDropdown';
AssetOperationsDropdown.fragments = fragments;

const FlexRight = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-grow: 2;
    flex-wrap: nowrap;
    justify-content: end;
`;

const SelectedSpan = styled.span`
    margin-left: auto;
    margin-right: 15px;
`;
