import moment from 'moment-timezone';
import { Clock, AssignedLocation, Client, getPeriodFromDateUTC, LocationPeriod, MonthOneBased, Roles } from '@deltasierra/shared';


import type { IPromise } from 'angular';
import { InteractionUtils } from '../../../common/interactionUtils';
import { I18nService } from '../../../i18n';
import { ClientResource, MvClientResource, mvClientResourceSID } from '../../../clients/mvClientResource';
import { isTranslatableError } from '../../../common/exceptions';
import { $filterSID, $qSID, $rootScopeSID, $timeoutSID } from '../../../common/angularData';
import { MvIdentity } from '../../../account/mvIdentity';
import { DataUtils } from '../../../common/dataUtils';
import {
    hasInsufficientDataRecorded,
    isMetricAvailable,
    isMetricUnavailable,
    Metric,
    MetricColumnSettings,
    MetricColumnType,
} from './metricProviders/common';
import { PerformanceSummaryService } from './service';

interface Period {
    month: MonthOneBased;
    year: number;
    labelLong: string;
    labelShort: string;
}

type VisibleMetric = Metric<any> & {
    isVisible?: boolean;
};

export class PerformanceSummaryController {
    public static readonly SID = 'PerformanceSummaryController';

    public static readonly METRIC_FADE_IN_DELAY = 100;

    public formatDate!: ng.IFilterDate;

    public location!: AssignedLocation;

    public client?: Client | null;

    public currentMonth!: Period;

    public previousMonth!: Period;

    public metrics: VisibleMetric[] = [];

    public unavailableMetricsErrorMessages: string[] = [];

    public fetchedForLocationId?: number | null;

    public fetchData = this.interactionUtils.createFuture<void, unknown>(
        this.i18n.text.dashboard.performanceSummary.fetchPerformanceMetrics(),
        () => {
            this.metrics = [];
            if (!this.isUserPermittedToSeeSection()) {
                return this.$q.resolve();
            }

            const params: LocationPeriod = {
                locationId: this.location && this.location.id,
                month: this.currentMonth.month,
                year: this.currentMonth.year,
            };
            return this.$q
                .resolve(this.location)
                .then(location => (location ? this.clientResource.get({ id: location.clientId }) : null))
                .then((client: ClientResource | null): IPromise<Array<Metric<any>> | void> => {
                    this.client = client;

                    if (!params.locationId) {
                        return this.$q.resolve();
                    }

                    return this.performanceSummaryService.getMetricRows(params);
                })
                .then(metrics =>
                    // eslint-disable-next-line consistent-return
                    this.$q<void>(resolve => {
                        // eslint-disable-next-line no-param-reassign
                        metrics ||= [];
                        const resultMetrics = this.dataUtils.groupByGetter(
                            metric =>
                                isMetricUnavailable(metric) && !hasInsufficientDataRecorded(metric)
                                    ? 'errors'
                                    : 'success',
                            metrics,
                        );

                        const { errors: erroredMetrics = [], success: metricsToDisplay = [] } = resultMetrics;
                        this.unavailableMetricsErrorMessages = erroredMetrics.map(
                            metric => `${metric.label} - ${this.getMetricErrorMessage(metric)}`,
                        );

                        if (!metricsToDisplay.length) {
                            return resolve();
                        }

                        // Display metrics with a slight delay between each one's reveal
                        metricsToDisplay.forEach((metric: VisibleMetric, index) => {
                            metric.isVisible = false;
                            this.metrics.push(metric);
                            this.fetchedForLocationId = params.locationId;

                            // eslint-disable-next-line consistent-return
                            void this.$timeout(() => {
                                metric.isVisible = true;
                                if (index === metricsToDisplay.length - 1) {
                                    return resolve();
                                }
                            }, PerformanceSummaryController.METRIC_FADE_IN_DELAY * index);
                        });
                    }),
                )
                .finally(() => {
                    // Say we fetched the data for the location (or tried) in the case where it may have rejected
                    this.fetchedForLocationId = this.location && this.location.id;
                });
        },
    );

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public static readonly $inject = [
        $rootScopeSID,
        $filterSID,
        $qSID,
        $timeoutSID,
        InteractionUtils.SID,
        I18nService.SID,
        mvClientResourceSID,
        PerformanceSummaryService.SID,
        MvIdentity.SID,
        DataUtils.SID,
    ];

    // eslint-disable-next-line max-params
    public constructor(
        private $rootScope: ng.IRootScopeService,
        private $filter: ng.IFilterService,
        private $q: ng.IQService,
        private $timeout: ng.ITimeoutService,
        private interactionUtils: InteractionUtils,
        private i18n: I18nService,
        private clientResource: MvClientResource,
        private performanceSummaryService: PerformanceSummaryService,
        private identity: MvIdentity,
        private dataUtils: DataUtils,
    ) {}

    public $onInit(): void {
        this.formatDate = this.$filter('date');

        const today = new Clock().today();

        this.currentMonth = this.getPeriod(moment(today).utc().startOf('month').add(-1, 'month').toDate());
        this.previousMonth = this.getPeriod(moment(today).utc().startOf('month').add(-2, 'month').toDate());

        this.$rootScope.$watch(
            () => this.location,
            () => this.fetchData.run({}),
            true,
        );
    }

    public getMetricErrorMessage(metric: Metric<any>): string {
        if (isTranslatableError(metric.error)) {
            return this.i18n.text(metric.error.key, metric.error.translateOptions);
        } else {
            return metric.error.message || metric.error;
        }
    }

    public isMetricAvailable(metric: Metric<any>): boolean {
        return isMetricAvailable(metric);
    }

    public getMetricClasses(metric: VisibleMetric): string[] {
        const result: string[] = [];

        result.push(
            isMetricAvailable(metric)
                ? `metric-difference-${metric.values!.differenceDirection}`
                : 'metric-unavailable',
        );

        if (metric.isVisible) {
            result.push('metric-visible');
        }

        return result;
    }

    public isUserPermittedToSeeSection(): boolean {
        return this.identity.hasRole(Roles.manager) || this.identity.hasRole(Roles.analyst);
    }

    public getConnectAppsMessageHtml(): string {
        return this.i18n.text.dashboard.performanceSummary.connectAppsToSeePerformance({ url: '/apps' });
    }

    public hasFetchedForCurrentLocation(): boolean {
        return !!this.fetchedForLocationId && this.fetchedForLocationId === this.location.id;
    }

    public getMetricColumnSettings(metric: Metric<any>, column: MetricColumnType): MetricColumnSettings {
        const defaultSettingsForAllMetrics = {
            decimals: 0,
            filter: '',
        };
        const specificColumnSettings = metric.columnSettings[column];
        const defaultColumnSettings = metric.columnSettings.default;
        const result = {
            ...defaultSettingsForAllMetrics,
            ...defaultColumnSettings,
            ...specificColumnSettings,
        };
        return result;
    }

    private getPeriod(date: Date): Period {
        const { month, year } = getPeriodFromDateUTC(date);

        return {
            labelLong: this.i18n.formatMonthYear(month, year, 'long'),
            labelShort: this.i18n.formatMonthYear(month, year, 'short'),
            month,
            year,
        };
    }
}
