import { ClientId, LocationId,
    Roles,
    UpdateClientUserDto,
    User,
    UserId,
    CONNECT_TO_ACCESS_EXCLUSIVE_ROLES } from '@deltasierra/shared';


import { TabNavEntry } from '../common/dsTabNav';
import { MvNotifier } from '../common/mvNotifier';
import { InteractionUtils } from '../common/interactionUtils';
import { I18nService } from '../i18n';
import { $routeParamsSID, $routeSID, $windowSID, IRoute } from '../common/angularData';
import { MvClient } from './mvClient';
import IWindowService = angular.IWindowService;

export function getClientLocationUserTabNavEntries(i18n: I18nService): ReadonlyArray<TabNavEntry> {
    return Object.freeze([
        {
            label: () => i18n.text.common.details(),
            path: '/clients/{{ clientId }}/locations/{{ locationId }}/users/{{ userId }}/edit',
        },
        {
            label: () => i18n.text.common.locations(),
            path: '/clients/{{ clientId }}/locations/{{ locationId }}/users/{{ userId }}/locations',
        },
    ]);
}

export function getClientUserTabNavEntries(i18n: I18nService): ReadonlyArray<TabNavEntry> {
    return Object.freeze([
        {
            label: () => i18n.text.common.details(),
            path: '/clients/{{ clientId }}/users/{{ userId }}/edit',
        },
        {
            label: () => i18n.text.common.locations(),
            path: '/clients/{{ clientId }}/users/{{ userId }}/locations',
        },
    ]);
}

export function getAgencyClientUserTabNavEntries(i18n: I18nService): ReadonlyArray<TabNavEntry> {
    return Object.freeze([
        {
            label: () => i18n.text.common.details(),
            path: '/agency/clientUsers/{{ userId }}/edit',
        },
        {
            label: () => i18n.text.common.locations(),
            path: '/agency/clientUsers/{{ userId }}/locations',
        },
    ]);
}

export function getTabNavEntries(i18n: I18nService, ids: ClientUserIds): ReadonlyArray<TabNavEntry> {
    if (ids.locationId) {
        return getClientLocationUserTabNavEntries(i18n);
    } else if (ids.clientId) {
        return getClientUserTabNavEntries(i18n);
    } else {
        return getAgencyClientUserTabNavEntries(i18n);
    }
}

export interface ClientUserExpectedRouteParams {
    clientId?: string;
    locationId?: string;
    userId?: string;
}

export interface ClientUserIds {
    clientId?: ClientId;
    locationId?: LocationId;
    userId?: UserId;
}

export function extractIdsFromRouteParams($routeParams: ClientUserExpectedRouteParams): ClientUserIds {
    let clientId;
    if ($routeParams.clientId) {
        clientId = ClientId.from(parseInt($routeParams.clientId, 10));
    }
    let locationId;
    if ($routeParams.locationId) {
        locationId = LocationId.from(parseInt($routeParams.locationId, 10));
    }
    let userId;
    if ($routeParams.userId) {
        userId = UserId.from(parseInt($routeParams.userId, 10));
    }
    return {
        clientId,
        locationId,
        userId,
    };
}

export class MvClientUserEditCtrl {
    public static SID = 'mvClientUserEditCtrl';

    public static readonly $inject: string[] = [
        $routeParamsSID,
        $windowSID,
        $routeSID,
        MvClient.SID,
        MvNotifier.SID,
        InteractionUtils.SID,
        I18nService.SID,
    ];

    public roles: Array<{ label: () => string; selected: boolean; value: Roles }> = [
        {
            label: (): string => this.i18n.text.agency.client.location.users.userEdit.analyst(),
            selected: true,
            value: Roles.analyst,
        },
        {
            label: (): string => this.i18n.text.agency.client.location.users.userEdit.builder(),
            selected: true,
            value: Roles.builder,
        },
        {
            label: (): string => this.i18n.text.agency.client.location.users.userEdit.specialRequester(),
            selected: true,
            value: Roles.specialRequester,
        },
        {
            label: (): string => this.i18n.text.agency.client.location.users.userEdit.connectToAccess(),
            selected: true,
            value: Roles.connectToAccess,
        },
        {
            label: (): string => this.i18n.text.agency.client.location.users.userEdit.publisher(),
            selected: true,
            value: Roles.publisher,
        },
        {
            label: (): string => this.i18n.text.agency.client.location.users.userEdit.planner(),
            selected: true,
            value: Roles.planner,
        },
    ];

    public clientId?: ClientId;

    public locationId?: LocationId;

    public userId?: UserId;

    public tabNavEntries: ReadonlyArray<TabNavEntry> | TabNavEntry[] = [];

    public tabNavParams: ClientUserIds;

    public user?: Pick<User, keyof UpdateClientUserDto>;

    public readonly getUser = this.interactionUtils.createFuture<void, { id: UserId }>(
        'retrieve user',
        async ({ id }) => {
            this.user = await this.mvClient.getUser(id);
            this.prepareRolesForDisplay();
        },
    );

    public readonly updateUser = this.interactionUtils.createFuture('update user', () =>
        this.mvClient.updateUser(this.userId!, this.user!),
    );

    public readonly deleteUser = this.interactionUtils.createFuture('delete user', () =>
        this.mvClient.deleteClientUser(this.userId!).then(() => this.$window.history.back()),
    );

    // eslint-disable-next-line max-params
    public constructor(
        $routeParams: ClientUserExpectedRouteParams,
        private readonly $window: IWindowService,
        private readonly $route: IRoute,
        private readonly mvClient: MvClient,
        private readonly mvNotifier: MvNotifier,
        private readonly interactionUtils: InteractionUtils,
        private readonly i18n: I18nService,
    ) {
        const ids = extractIdsFromRouteParams($routeParams);
        this.userId = ids.userId;
        this.clientId = ids.clientId;
        this.locationId = ids.locationId;
        this.tabNavParams = ids;
        this.tabNavEntries = getTabNavEntries(this.i18n, ids);
        this.getUser.run({ id: this.userId! });
        this.onChangeTimezone = this.onChangeTimezone.bind(this);
    }

    public async update(): Promise<void> {
        this.updateUserRoles();
        await this.updateUser.run({});
        this.mvNotifier.notify(
            this.i18n.text.agency.client.location.users.userEdit.userUpdated({
                firstName: this.user!.firstName,
                lastName: this.user!.lastName,
            }),
        );
        this.$route.reload();
    }

    public cancel(): void {
        this.$window.history.back();
    }

    public onChangeTimezone(timezone: string): void {
        this.user!.timezone = timezone;
    }

    public isRoleDisabled(role: Roles): boolean {
        const selectedRoles = this.roles.filter(rle => rle.selected).map(rle => rle.value);
        if (CONNECT_TO_ACCESS_EXCLUSIVE_ROLES.has(role) && selectedRoles.includes(Roles.connectToAccess)) {
            return true;
        } else if (
            role === Roles.connectToAccess &&
            selectedRoles.some(selectedRole => CONNECT_TO_ACCESS_EXCLUSIVE_ROLES.has(selectedRole))
        ) {
            return true;
        }
        return false;
    }

    private updateUserRoles() {
        for (const role of this.roles) {
            const index = this.user!.roles.indexOf(role.value);
            if (role.selected && index === -1) {
                this.user!.roles.push(role.value);
            } else if (!role.selected && index > -1) {
                this.user!.roles.splice(index, 1);
            }
        }
    }

    private prepareRolesForDisplay() {
        for (const role of this.roles) {
            role.selected = this.user!.roles.indexOf(role.value) > -1;
        }
    }
}

angular.module('app').controller(MvClientUserEditCtrl.SID, MvClientUserEditCtrl);
