/// <reference path="../../../typings/browser.d.ts" />
import { LocationCategoryGroup, ClientId } from '@deltasierra/shared';
import { $resourceSID } from '../common/angularData';

import IResourceService = angular.resource.IResourceService;

export const mvLocationCategoryOptionsResourceSID = 'mvLocationCategoryOptionsResource';

export interface LocationCategoryOptionsResource
    extends ng.resource.IResource<LocationCategoryGroup>,
        LocationCategoryGroup {}

export interface MvLocationCategoryOptionsResource extends ng.resource.IResourceClass<LocationCategoryGroup> {
    queryWithOptions(params: { clientId: ClientId }): LocationCategoryOptionsResource[];
    queryAssigned(): LocationCategoryOptionsResource[];
}

angular.module('app').factory(mvLocationCategoryOptionsResourceSID, [
    $resourceSID,
    function ($resource: IResourceService): MvLocationCategoryOptionsResource {
        const LocationResource = $resource<LocationCategoryOptionsResource>(
            '/api/clients/:clientId/locationCategories',
            {
                clientId: '@clientId',
            },
            {
                queryGroups: {
                    url: '/api/locationCategories',
                    method: 'GET',
                    isArray: true,
                    params: {},
                },
                queryAssigned: {
                    url: '/api/locationCategories/assigned',
                    method: 'GET',
                    isArray: true,
                    params: {},
                },
                queryWithOptions: {
                    url: '/api/clients/:clientId/locationCategoriesWithOptions',
                    method: 'GET',
                    isArray: true,
                    params: {
                        clientId: '@clientId',
                    },
                },
            },
        );

        return LocationResource as any as MvLocationCategoryOptionsResource; // Hack
    },
]);
