import { gql, useLazyQuery, useQuery } from '@apollo/client';
import {
    GetBuilderTemplateLocationDraftsCountForHookQuery,
    GetBuilderTemplateLocationDraftsCountForHookQueryVariables,
} from './__graphqlTypes/GetBuilderTemplateLocationDraftsCountForHookQuery';

const GET_BUILDER_TEMPLATE_LOCATION_DRAFTS_COUNT_FOR_HOOK_QUERY = gql`
    query GetBuilderTemplateLocationDraftsCountForHookQuery($builderTemplateId: ID!) {
        builderTemplate(id: $builderTemplateId) {
            ... on BuilderTemplate {
                id
                dependentLocationDraftsCount
                dependentLocationDraftsLocations {
                    edges {
                        node {
                            id
                            title
                        }
                    }
                }
            }
        }
    }
`;

export type LocationDraftsCountResult = [loading: false, count: number];
export type LocationDraftsCountLoadingResult = [loading: true, count: undefined];

export function useLocationDraftsCount(
    builderTemplateId: string,
): LocationDraftsCountLoadingResult | LocationDraftsCountResult {
    const { data, loading } = useQuery<
        GetBuilderTemplateLocationDraftsCountForHookQuery,
        GetBuilderTemplateLocationDraftsCountForHookQueryVariables
    >(GET_BUILDER_TEMPLATE_LOCATION_DRAFTS_COUNT_FOR_HOOK_QUERY, {
        fetchPolicy: 'network-only',
        variables: {
            builderTemplateId,
        },
    });

    if (loading || !data || data.builderTemplate.__typename !== 'BuilderTemplate') {
        return [true, undefined];
    } else {
        return [false, data.builderTemplate.dependentLocationDraftsCount];
    }
}

export type LocationDraftLocation = {
    id: string,
    title: string,
}

export type LazyLocationDraftsInfoResult = {
    count: number,
    locations: LocationDraftLocation[],
}

export function useLazyLocationDraftsCount(builderTemplateId: string): [() => Promise<LazyLocationDraftsInfoResult>] {
    const [lazyLoad] = useLazyQuery<
        GetBuilderTemplateLocationDraftsCountForHookQuery,
        GetBuilderTemplateLocationDraftsCountForHookQueryVariables
    >(GET_BUILDER_TEMPLATE_LOCATION_DRAFTS_COUNT_FOR_HOOK_QUERY, {
        fetchPolicy: 'network-only',
        variables: {
            builderTemplateId,
        },
    });
    return [
        async () => {
            const results = await lazyLoad();

            if (results.data?.builderTemplate.__typename === 'BuilderTemplate') {
                const { dependentLocationDraftsCount, dependentLocationDraftsLocations } = results.data.builderTemplate;
                const locationList = dependentLocationDraftsLocations.edges.map(edge => edge.node);

                return {
                    count: dependentLocationDraftsCount,
                    locations: locationList,
                };
            }
            return {
                count: 0,
                locations: [],
            };
        },
    ];
}
