import * as React from 'react';
import { NotifierInstanceContext } from '../contexts';
import { Notifier } from '../notifier';

/**
 * Gets the notifier from the context
 *
 * @example
 * const notifier = useNotifier();
 * notifier.success('Success message');
 * notifier.unexpectedError('Error message', new Error());
 * @returns The notifier with its functions
 */
export function useNotifier(): Notifier {
    const notifier = React.useContext(NotifierInstanceContext);
    if (!notifier) {
        throw new Error('The <NotifierProvider /> component needs to be added above this component the component tree');
    }
    return notifier;
}
