/// <reference path="../../../typings/browser.d.ts" />
import { $resourceSID } from '../common/angularData';
import IResourceService = angular.resource.IResourceService;

export const mvClientBuilderFontConfigResourceSID = 'mvClientBuilderFontConfigResource';

angular.module('app').factory(mvClientBuilderFontConfigResourceSID, [
    $resourceSID,
    function ($resource : IResourceService) {
    const ClientBuilderFontConfigResource = $resource('/api/clients/:clientId/fonts', // Tslint:disable-line:variable-name
        { clientId: '@clientId' });

    return ClientBuilderFontConfigResource;
},
]);
