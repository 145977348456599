import { PrintFooter, Translate } from '@deltasierra/components';
import moment from 'moment';
import * as React from 'react';

export const PrintPageFooter: React.FunctionComponent = () => (
    <PrintFooter>
        <p>
            <Translate
                keyId="REPORT.GENERATED_REPORT_PRINT_FOOTER"
                options={{ formattedDate: moment().format('DD-MM-YYYY hh:mm:ss A') }}
            />
        </p>
    </PrintFooter>
);
PrintPageFooter.displayName = 'PrintPageFooter';
