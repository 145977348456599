import { gql, useQuery } from '@apollo/client';
import { Loading } from '@deltasierra/components';
import { t } from '@deltasierra/shared';
import * as React from 'react';
import { RecycledAssets } from './RecycledAssets';
import { GetLocationBinAssets, GetLocationBinAssetsVariables } from './__graphqlTypes/GetLocationBinAssets';

const GET_LOCATION_BIN_ASSETS = gql`
    query GetLocationBinAssets($locationId: ID!) {
        location(id: $locationId) {
            id
            recycled {
                ...AssetConnectionForRecycledAssets
            }
        }
    }
    ${RecycledAssets.fragments.ASSET_CONNECTION_FOR_RECYCLED_ASSETS}
`;

export interface LocationBinAssetsProps {
    locationId: string;
}

export function LocationBinAssets(props: LocationBinAssetsProps): JSX.Element {
    const { data, loading } = useQuery<GetLocationBinAssets, GetLocationBinAssetsVariables>(GET_LOCATION_BIN_ASSETS, {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first',
        notifyOnNetworkStatusChange: true,
        variables: { locationId: props.locationId },
    });
    if (!data?.location || loading) {
        return <Loading />;
    } else {
        return <RecycledAssets assetConnection={data.location.recycled} headerText={t('ASSET_LIBRARY.BIN_ASSETS')} />;
    }
}
LocationBinAssets.displayName = 'LocationBinAssets';
