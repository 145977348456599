import * as React from 'react';

export type CurrentLocationIdType = string;

export type CurrentLocationRouteStateContextType =
    | CurrentLocationIdType
    | undefined;

export const CurrentLocationRouteStateContext = React.createContext<CurrentLocationRouteStateContextType>(undefined);
CurrentLocationRouteStateContext.displayName = 'CurrentLocationRouteStateContext';
