import { DSList, DSListSubheader } from '@deltasierra/components';
import { ChecklistItemStateHandler, ChecklistMap } from '@deltasierra/react-hooks';
import * as React from 'react';
import {
    CLIENT_FRAGMENT_FOR_LOCATIONS_CHECKLIST,
    LOCATION_FRAGMENT_FOR_LOCATIONS_CHECKLIST,
} from './LocationsChecklist.fragments';
import { MemoizedLocationsChecklistItemClient } from './LocationsChecklistItemClient';
import { MemoizedLocationsChecklistItemLocation } from './LocationsChecklistItemLocation';
import { ClientFragmentForLocationsChecklist } from './__graphqlTypes/ClientFragmentForLocationsChecklist';
import { LocationFragmentForLocationsChecklist } from './__graphqlTypes/LocationFragmentForLocationsChecklist';

function isClientEntry(obj: any): obj is { client: ClientFragmentForLocationsChecklist } {
    return (
        !!obj.client &&
        (obj.client as ClientFragmentForLocationsChecklist).__typename === 'Client' &&
        !!(obj.client as ClientFragmentForLocationsChecklist).id &&
        !!(obj.client as ClientFragmentForLocationsChecklist).title
    );
}

export type LocationsChecklistProps = {
    checklist: ChecklistMap;
    clientsAndLocations: Array<[ClientFragmentForLocationsChecklist, LocationFragmentForLocationsChecklist[]]>;
    indeterminatableLocations?: string[];
    onChecked: ChecklistItemStateHandler;
    title?: React.ReactNode;
};

export function LocationsChecklist({
    checklist,
    clientsAndLocations,
    indeterminatableLocations,
    onChecked,
    title,
}: LocationsChecklistProps): JSX.Element {
    const clientsAndLocationsSingleList = React.useMemo(
        () =>
            clientsAndLocations
                .map(([client, locations]) => [{ client, locations }, locations.map(location => ({ location }))])
                .flat(2),
        [clientsAndLocations],
    );

    const clientChecklist = React.useMemo(
        () =>
            new Map(
                clientsAndLocations.map(([client, locations]) => {
                    const checkedLocations = locations.filter(loc => checklist.isChecked(loc.id));

                    return [
                        client.id,
                        {
                            checked: checkedLocations.length === locations.length,
                            checkedCount: checkedLocations.length,
                            indeterminate: checkedLocations.length > 0 && checkedLocations.length !== locations.length,
                            locationCount: locations.length,
                        },
                    ];
                }),
            ),
        [clientsAndLocations, checklist],
    );

    const childList = React.useMemo(
        () =>
            clientsAndLocationsSingleList.map((clientOrLocation, index) =>
                isClientEntry(clientOrLocation) ? (
                    <MemoizedLocationsChecklistItemClient
                        checked={clientChecklist.get(clientOrLocation.client.id)?.checked ?? false}
                        client={clientOrLocation.client}
                        indeterminate={clientChecklist.get(clientOrLocation.client.id)?.indeterminate ?? false}
                        key={index}
                        locationCheckedCount={clientChecklist.get(clientOrLocation.client.id)?.checkedCount ?? 0}
                        locations={clientOrLocation.locations}
                        onChecked={onChecked}
                    />
                ) : (
                    <MemoizedLocationsChecklistItemLocation
                        checked={checklist.isChecked(clientOrLocation.location.id)}
                        indeterminatable={indeterminatableLocations?.includes(clientOrLocation.location.id) ?? false}
                        indeterminate={checklist.isIndeterminate(clientOrLocation.location.id)}
                        key={index}
                        location={clientOrLocation.location}
                        onChecked={onChecked}
                    />
                ),
            ),
        [checklist, clientChecklist, clientsAndLocationsSingleList, indeterminatableLocations, onChecked],
    );

    return (
        <>
            <DSList
                component="div"
                data-cy="available-locations-list"
                subheader={
                    title ? (
                        <DSListSubheader component="div" disableSticky>
                            {title}
                        </DSListSubheader>
                    ) : undefined
                }
            >
                {childList}
            </DSList>
        </>
    );
}
LocationsChecklist.displayName = 'LocationsChecklist';
LocationsChecklist.fragments = {
    CLIENT_FRAGMENT_FOR_LOCATIONS_CHECKLIST,
    LOCATION_FRAGMENT_FOR_LOCATIONS_CHECKLIST,
};
