import { useLazyQuery } from '@apollo/client';
import { DocumentNode } from 'graphql/language/ast';
import React from 'react';
import {
    UseExportReportButtonState,
    UseExportReportButtonStateReducerDispatch,
} from './use-export-report-button-state-reducer';
import { UseExportReportButtonQuery, UseExportReportButtonQueryVariables, PollerFunction } from './types';

// Poll every 5 seconds
const DEFAULT_POLL_INTERVAL = 5_000;

export function useGenerateReportRequestPoller(
    state: UseExportReportButtonState,
    dispatch: UseExportReportButtonStateReducerDispatch,
    pollerFn: PollerFunction<UseExportReportButtonQuery>,
    query: DocumentNode,
    pollIntervalMs = DEFAULT_POLL_INTERVAL,
): {
    poll: (reportRequestId: string) => void;
    pollCount: number;
    pollIntervalMs: number;
    reset: () => void;
} {
    // This can track the amount of times we've polled so far
    // We dont need to track it, but its useful for updating state to trigger timeouts
    const [pollCount, setPollCount] = React.useState(0);

    const pollAgain = React.useCallback(() => {
        setPollCount(pCount => pCount + 1);
    }, []);

    const [getReportRequestQuery] = useLazyQuery<UseExportReportButtonQuery, UseExportReportButtonQueryVariables>(
        query,
        {
            errorPolicy: 'all',
            fetchPolicy: 'no-cache',
            notifyOnNetworkStatusChange: true,
            onCompleted: data => {
                if (state.name === 'polling' && data) {
                    const dispatchPayload = pollerFn(data);
                    dispatch(dispatchPayload);
                    if (dispatchPayload.type === 'complete') {
                        return;
                    }
                }
                pollAgain();
            },
            onError: err => {
                if (!err.networkError) {
                    // Only if the error is not a network error should we show the user a message
                    // This means timeouts will be swallowed in the polling
                    dispatch({
                        error: err,
                        type: 'error',
                    });
                }
                pollAgain();
            },
        },
    );

    const poll = React.useCallback(
        reportRequestId => {
            void getReportRequestQuery({
                variables: {
                    reportRequestId,
                },
            });
        },
        [getReportRequestQuery],
    );

    const reset = React.useCallback(() => {
        setPollCount(0);
    }, []);

    return {
        poll,
        pollCount,
        pollIntervalMs,
        reset,
    };
}
