/* eslint-disable max-statements */
import * as React from 'react';
import { useMutation } from '@apollo/client';
import { isImageMimeType, UploadCategory, t } from '@deltasierra/shared';

import { useAngularServiceContext } from '../../componentUtils/angularServiceContexts';
import { uploadToS3 } from '../../uploadUtils';
import { getImage } from './utils/getImage';
import {
    INITIALIZE_UPLOAD_FOR_USE_UPLOAD_FILE,
    UPLOAD_FAILURE_FOR_USE_UPLOAD_FILE,
    UPLOAD_SUCCESS_FOR_USE_UPLOAD_FILE,
} from './useUploadFiles.mutations';
import {
    InitializeUploadForUseUploadFile,
    InitializeUploadForUseUploadFileVariables,
} from './__graphqlTypes/InitializeUploadForUseUploadFile';
import {
    UploadSuccessForUseUploadFile,
    UploadSuccessForUseUploadFileVariables,
} from './__graphqlTypes/UploadSuccessForUseUploadFile';
import {
    UploadFailureForUseUploadFile,
    UploadFailureForUseUploadFileVariables,
} from './__graphqlTypes/UploadFailureForUseUploadFile';

export type UseUploadFileInitializedFile = {
    id: string;
    filename: string;
    getObjectUrl: () => string;
    image?: { width: number; height: number };
};
export type UseUploadFileUploadedFile = UseUploadFileInitializedFile & { url: string | null };

export type UseUploadFileOptions = {
    onUploadInitialized?: (upload: UseUploadFileInitializedFile) => void;
    onUploadSuccess?: (upload: UseUploadFileUploadedFile) => void;
};

export type UploadFilesFunctionType = {
    (file: File, options?: UseUploadFileOptions): Promise<UseUploadFileUploadedFile>;
    (files: File[], options?: UseUploadFileOptions): Promise<UseUploadFileUploadedFile[]>;
};


export function useUploadFiles(category: UploadCategory): [
    uploadFiles: UploadFilesFunctionType,
    uploadData: {
        hasTriedUpload: boolean;
        lastUploadedFile?: UseUploadFileUploadedFile;
        loading: boolean;
        uploadedFiles?: UseUploadFileUploadedFile[];
    },
] {
    const [initializeUpload] = useMutation<InitializeUploadForUseUploadFile, InitializeUploadForUseUploadFileVariables>(
        INITIALIZE_UPLOAD_FOR_USE_UPLOAD_FILE,
    );
    const [uploadSuccess] = useMutation<UploadSuccessForUseUploadFile, UploadSuccessForUseUploadFileVariables>(
        UPLOAD_SUCCESS_FOR_USE_UPLOAD_FILE,
    );
    const [uploadFailure] = useMutation<UploadFailureForUseUploadFile, UploadFailureForUseUploadFileVariables>(
        UPLOAD_FAILURE_FOR_USE_UPLOAD_FILE,
    );
    const [hasTriedUpload, setHasTriedUpload] = React.useState(false);

    const [loading, setLoading] = React.useState(false);
    const [uploadedFiles, setUploadedFiles] = React.useState<UseUploadFileUploadedFile[]>();
    const lastUploadedFile = React.useMemo(
        () => (uploadedFiles && uploadedFiles.length > 0 ? uploadedFiles[uploadedFiles.length - 1] : undefined),
        [uploadedFiles],
    );

    const notifier = useAngularServiceContext('mvNotifier');

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const uploadFiles = React.useCallback<UploadFilesFunctionType>(
        (async (files, options) => {
            const { onUploadInitialized, onUploadSuccess } = options ?? {};

            if (!hasTriedUpload) {
                setHasTriedUpload(true);
            }

            setLoading(true);
            const filesArray = Array.isArray(files) ? files : [files];
            const filesResult = await Promise.all(
                filesArray.map(async file => {
                    let imageData: UseUploadFileInitializedFile['image'];
                    if (isImageMimeType(file.type)) {
                        const image = await getImage(file);

                        imageData = {
                            height: image.height,
                            width: image.width,
                        };
                    }

                    const result = await initializeUpload({
                        variables: {
                            input: {
                                category,
                                fileName: file.name,
                                fileType: file.type,
                                height: imageData?.height,
                                width: imageData?.width,
                            },
                        },
                    });
                    if (!result.data?.uploadInitialize) {
                        notifier.unexpectedError(t('PLAN.CAMPAIGNS.ERRORS.UPLOAD_FAILED'));
                        throw new Error(t('PLAN.CAMPAIGNS.ERRORS.UPLOAD_FAILED'));
                    }
                    try {
                        const initializedUpload = result.data.uploadInitialize.upload;

                        const initializedUploadResponse: UseUploadFileInitializedFile = {
                            filename: initializedUpload.filename,
                            getObjectUrl: () => URL.createObjectURL(file),
                            id: initializedUpload.id,
                            image: imageData,
                        };

                        // Run the callback to say we are initialized
                        onUploadInitialized?.(initializedUploadResponse);

                        // Upload it to S3
                        await uploadToS3(result.data.uploadInitialize.signedUrl, file);

                        // Run the success callback to save the upload
                        const successReponse = await uploadSuccess({
                            variables: { id: result.data.uploadInitialize.upload.id },
                        });

                        if (successReponse.data?.uploadSuccess.__typename !== 'Upload') {
                            throw new Error('Could not complete the upload');
                        }

                        const uploadedFile: UseUploadFileUploadedFile = {
                            ...initializedUploadResponse,
                            url: successReponse.data.uploadSuccess.url,
                        };

                        // Run the callback to say we have succeeded
                        onUploadSuccess?.(uploadedFile);

                        return uploadedFile;
                    } catch (error) {
                        await uploadFailure({
                            variables: { failedUploadId: result.data.uploadInitialize.upload.id },
                        });
                        throw error;
                    }
                }),
            );
            setUploadedFiles(oldValue => [...oldValue ?? [], ...filesResult]);
            setLoading(() => false);

            return Array.isArray(files) ? filesResult : filesResult[0];
        }) as UploadFilesFunctionType,
        [category, initializeUpload, notifier, hasTriedUpload, uploadFailure, uploadSuccess],
    );
    return [
        uploadFiles,
        {
            hasTriedUpload,
            lastUploadedFile,
            loading,
            uploadedFiles,
        },
    ];
}
