import * as React from 'react';
import { gql, useQuery } from '@apollo/client';
import { createWizardStep, Loading, Wizard } from '@deltasierra/components';
import { ExportPlatforms } from '@deltasierra/shared';
import { OneWayBinding, ExpressionBinding } from '../../../../common/angularData';
import { withAngularIntegration } from '../../../../common/componentUtils/reactComponentRegistration';
import { Translate } from '../../../../directives/Translate';
import { EmailPublishData } from '../../emailPublishData';
import { PublishResult } from '../../../publish/publishResult';
import { PublishToMailchimpStep1, PublishToMailchimpStep1Data, PublishToMailchimpStep2, PublishToMailchimpStep2Data } from './components';
import { GetMailchimpForLocation, GetMailchimpForLocationVariables } from './__graphqlTypes/GetMailchimpForLocation';
import { PublishToMailchimpStep3, PublishToMailchimpStep3Data } from './components/PublishToMailchimpStep3';
import { PublishToMailchimpStep4, PublishToMailchimpStep4Data } from './components/PublishToMailchimpStep4';

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const GET_MAILCHIMP_FOR_LOCATION = gql`
    query GetMailchimpForLocation($locationId: ID!) {
        location(id: $locationId) {
            id
            mailchimp {
                ... on Mailchimp {
                    id
                }
            }
        }
    }
`;

export type PublishToMailchimpProps = {
    publishData: EmailPublishData;
    onCancel: () => void;
    onComplete: (publishResult: PublishResult) => void;
};

export const PublishToMailchimp: React.FC<PublishToMailchimpProps> = ({ publishData, onCancel, onComplete }) => {
    const { data, loading } = useQuery<GetMailchimpForLocation, GetMailchimpForLocationVariables>(GET_MAILCHIMP_FOR_LOCATION, {
        variables: {
            locationId: publishData.location.graphqlId,
        },
    });

    const [step1Data, updateStep1] = React.useState<PublishToMailchimpStep1Data>();
    const [step2Data, updateStep2] = React.useState<PublishToMailchimpStep2Data>();
    const [step3Data, updateStep3] = React.useState<PublishToMailchimpStep3Data>();

    if (loading) {
        return <Loading />;
    }

    if (!data || data.location?.mailchimp.__typename !== 'Mailchimp') {
        return <>{'There was an error'}</>;
    }

    const mailchimpId = data.location.mailchimp.id;
    return (
        <>
            <h3>
                <Translate keyId="BUILD.PUBLISH.MAILCHIMP.PUBLISH_TO_MAILCHIMP" />
            </h3>
            <Wizard
                steps={[
                    createWizardStep(
                        wizardStepData => <PublishToMailchimpStep1 initialData={step1Data} mailchimpId={mailchimpId} {...wizardStepData} />,
                        updateStep1,
                    ),
                    createWizardStep(
                        wizardStepData =>
                            step1Data ? (
                                <PublishToMailchimpStep2
                                    mailchimpId={mailchimpId}
                                    publishData={publishData}
                                    step1Data={step1Data}
                                    step2Data={step2Data}
                                    {...wizardStepData}
                                />
                            ) : (
                                <></>
                            ),
                        updateStep2,
                    ),
                    createWizardStep(
                        wizardStepData =>
                            step2Data ? (
                                <PublishToMailchimpStep3
                                    mailchimpId={mailchimpId}
                                    step2Data={step2Data}
                                    step3Data={step3Data}
                                    {...wizardStepData}
                                />
                            ) : (
                                <></>
                            ),
                        updateStep3,
                    ),
                    createWizardStep(
                        wizardStepData =>
                            step2Data ? (
                                <PublishToMailchimpStep4
                                    location={publishData.location}
                                    mailchimpId={mailchimpId}
                                    step2Data={step2Data}
                                    {...wizardStepData}
                                />
                            ) : (
                                <></>
                            ),
                        ({ formValues: { scheduleDate } }: PublishToMailchimpStep4Data) => {
                            onComplete(new PublishResult(ExportPlatforms.Mailchimp, scheduleDate ?? null));
                        },
                    ),
                ]}
                onCancel={onCancel}
            />
        </>
    );
};

export default withAngularIntegration(PublishToMailchimp, 'publishToMailchimp', {
    onCancel: ExpressionBinding,
    onComplete: ExpressionBinding,
    publishData: OneWayBinding,
});
