/* eslint-disable max-lines-per-function */
import { QueryLazyOptions, useLazyQuery } from '@apollo/client';
import * as React from 'react';
import { usePoller } from '@deltasierra/react-hooks';
import { ScheduledPublishesSchedulingStatus } from '../../../../../../../__graphqlTypes/globalTypes';
import { MultiLocationLocationLoaderData } from '../hooks/useMultiLocationLoader';
import { USE_SCHEDULED_PUBLISH_POLLER_QUERY } from './useScheduledPublishPoller.queries';
import {
    UseScheduledPublishPollerQuery,
    UseScheduledPublishPollerQueryVariables,
} from './__graphqlTypes/UseScheduledPublishPollerQuery';

export type UseScheduledPublishPollerOptions = {
    initialLocationProgressList: MultiLocationLocationLoaderData[];
    queryOptions: QueryLazyOptions<UseScheduledPublishPollerQueryVariables> | undefined;
};

export function useScheduledPublishPoller({
    initialLocationProgressList,
    queryOptions,
}: UseScheduledPublishPollerOptions): [
    startPolling: () => void,
    options: {
        locationsProgressList: MultiLocationLocationLoaderData[];
        isComplete: boolean;
        progressLabel: string;
        progress: number;
        context?: 'error' | 'success' | 'warning';
    },
] {
    const [locationsProgressList, setLocationsProgressList] = React.useState(initialLocationProgressList);

    const [runQuery, { data, error, loading }] = useLazyQuery<
        UseScheduledPublishPollerQuery,
        UseScheduledPublishPollerQueryVariables
    >(USE_SCHEDULED_PUBLISH_POLLER_QUERY, {
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
    });

    const { onError, onSuccess, startPolling, stopPolling } = usePoller(() => {
        runQuery(queryOptions);
    }, 10_000);

    React.useEffect(() => {
        if (loading) {
            return;
        }
        if (error) {
            onError();
        } else {
            onSuccess();
        }
    }, [error, loading, onError, onSuccess]);

    const isComplete = React.useMemo(
        () =>
            data?.scheduledPublishes
                ? data?.scheduledPublishes.every(
                      scheduledPublish =>
                          scheduledPublish.schedulingStatus !== ScheduledPublishesSchedulingStatus.pending,
                  )
                : false,
        [data?.scheduledPublishes],
    );

    const progressLabel = React.useMemo(
        () =>
            data?.scheduledPublishes
                ? `${
                      data?.scheduledPublishes.filter(
                          scheduledPublish =>
                              scheduledPublish.schedulingStatus !== ScheduledPublishesSchedulingStatus.pending,
                      ).length
                  } / ${data?.scheduledPublishes.length}`
                : '',
        [data?.scheduledPublishes],
    );

    const progress = React.useMemo(
        () =>
            data?.scheduledPublishes
                ? (data?.scheduledPublishes.filter(
                      scheduledPublish =>
                          scheduledPublish.schedulingStatus !== ScheduledPublishesSchedulingStatus.pending,
                  ).length /
                      data?.scheduledPublishes.length) *
                  100
                : 0,
        [data?.scheduledPublishes],
    );

    const context = React.useMemo(() => {
        if (data?.scheduledPublishes) {
            const isAnyLocationFailed = data?.scheduledPublishes.some(
                scheduledPublish => scheduledPublish.schedulingStatus === ScheduledPublishesSchedulingStatus.failed,
            );
            return isAnyLocationFailed ? 'warning' : ScheduledPublishesSchedulingStatus.success;
        }
        return undefined;
    }, [data?.scheduledPublishes]);

    React.useEffect(() => {
        const pendingLocationCount = data?.scheduledPublishes.map(
            locationsProgress => locationsProgress.schedulingStatus === ScheduledPublishesSchedulingStatus.pending,
        ).length;
        if (pendingLocationCount !== 0) {
            setLocationsProgressList(prevData => {
                const newData = prevData.map(locationData => {
                    if (locationData.scheduledPublish === null) {
                        return locationData;
                    }
                    const polledData = data?.scheduledPublishes.find(
                        item => item.id === locationData.scheduledPublish?.id,
                    );
                    if (polledData && locationData.scheduledPublish.schedulingStatus !== polledData.schedulingStatus) {
                        return {
                            ...locationData,
                            scheduledPublish: polledData,
                        };
                    } else {
                        return locationData;
                    }
                });
                return newData;
            });
        }
    }, [data?.scheduledPublishes]);

    React.useEffect(() => {
        if (isComplete) {
            stopPolling();
        }
    }, [isComplete, stopPolling]);

    return [startPolling, { context, isComplete, locationsProgressList, progress, progressLabel }];
}
