import { DSTableCell, DSTableCellProps, DSTableRow } from '@deltasierra/components';
import * as React from 'react';
import styled, { css } from 'styled-components';
import { gql } from '@apollo/client';
import { ScheduledPostDateDisplay } from '../ScheduledPostDateDisplay';
import { PostArtifactState } from '../../../../../__graphqlTypes/globalTypes';
import { MoreLocationsButton } from '../MoreLocationsButton/MoreLocationsButton';
import { ScheduledContextMenu } from '../ScheduledContextMenu/ScheduledContextMenu';
import { ScheduledPostsRowFragment } from './__graphqlTypes/ScheduledPostsRowFragment';
import { ScheduledPostRowLocationsButton } from './ScheduledPostRowLocationsButton';
import { TemplateCaptionForScheduled } from './TemplateCaptionForScheduled';
import { PlatformIconAndTemplateThumbnail } from './PlatformIconAndTemplateThumbnail';

export type ScheduledPostRowProps = {
    style?: React.CSSProperties;
    className?: string;
    post: ScheduledPostsRowFragment;
    hideActions?: boolean;
    hideLocationCount?: boolean;
    disableReschedule?: boolean;
    disableLinkToPost?: boolean;
    onReschedule?: () => void;
};

const fragments = {
    post: gql`
        fragment ScheduledPostsRowFragment on PostArtifact {
            __typename
            id
            status {
                state
            }
            meta {
                ... on InstagramPostArtifactMeta {
                    isStory
                }
            }
            ...ScheduledPostRowLocationsButtonFragment
            ...TemplateCaptionForScheduledFragment
            ...ScheduledPostDateDisplayFragment
            ...PlatformIconAndTemplateThumbnailFragment
        }
        ${ScheduledPostRowLocationsButton.fragments.ScheduledPostRowLocationsButtonFragment}
        ${TemplateCaptionForScheduled.fragments.TemplateCaptionForScheduledFragment}
        ${ScheduledPostDateDisplay.fragments.ScheduledPostDateDisplayFragment}
        ${PlatformIconAndTemplateThumbnail.fragments.PlatformIconAndTemplateThumbnailFragment}
    `,
};

type ScheduledPostRowCellProps = DSTableCellProps & { inactive?: boolean };
const ScheduledPostRowCell = styled(({ inactive, ...rest }: ScheduledPostRowCellProps) => <DSTableCell {...rest} />)`
    border: none;
    ${props =>
        props.inactive &&
        css`
            opacity: 0.5;
        `}
`;

export function ScheduledPostRow({
    style,
    className,
    disableLinkToPost,
    disableReschedule,
    hideActions,
    hideLocationCount,
    post,
    onReschedule = () => null,
}: ScheduledPostRowProps): JSX.Element {
    /*
        Mark post row as inactive if post is not in scheduled state or scheduled time is less than the current time,
        meaning the post has already gone out or been deleted.
    */
    const inactive =
        post.status.state !== PostArtifactState.SCHEDULED ||
        (!!post.timestamps.scheduled && new Date(post.timestamps.scheduled) <= new Date());

    return (
        <DSTableRow className={className} style={{ border: 'none', ...style }}>
            <ScheduledPostRowCell inactive={inactive}>
                <PlatformIconAndTemplateThumbnail post={post} useLink={!disableLinkToPost} />
            </ScheduledPostRowCell>
            <ScheduledPostRowCell inactive={inactive}>
                <TemplateCaptionForScheduled post={post} />
            </ScheduledPostRowCell>
            {!hideLocationCount && (
                <ScheduledPostRowCell align="right" inactive={inactive}>
                    <MoreLocationsButton
                        postArtifactId={post.id}
                        render={params => <ScheduledPostRowLocationsButton post={post} toggle={params.toggle} />}
                    />
                </ScheduledPostRowCell>
            )}
            <ScheduledPostRowCell align="center" inactive={inactive}>
                <ScheduledPostDateDisplay post={post} />
            </ScheduledPostRowCell>
            {!hideActions && (
                <ScheduledPostRowCell align="right">
                    <ScheduledContextMenu post={post} />
                </ScheduledPostRowCell>
            )}
        </DSTableRow>
    );
}
ScheduledPostRow.fragments = fragments;
ScheduledPostRow.displayName = 'ScheduledPostRow';
