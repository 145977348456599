import * as React from 'react';

export type Option<T extends string | { toString(): string }> = {
    label: string;
    value: T;
};

export type OptionsList<T extends string | { toString(): string }> = Array<Option<T> | T>;

function isOption<T extends string | { toString(): string }>(option: Option<T> | T): option is Option<T> {
    return typeof option !== 'string';
}

export function useCleanedOptionsAndStoredTypes<T extends {}>(options: Array<Option<T> | T>) {
    return React.useMemo((): [Array<Option<T>>, { [key: string]: T }] => {
        const cleanedOptions = options.map<Option<T>>(option =>
            isOption<T>(option) ? option : { label: option.toString(), value: option },
        );
        const typedValueMap: { [key: string]: T } = cleanedOptions.reduce(
            (carry, current) => ({ ...carry, [current.value.toString()]: current.value }),
            {},
        );
        return [cleanedOptions, typedValueMap];
    }, [options]);
}
