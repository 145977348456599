
import { AgencyContactDetails, replaceEmptyStringsWithNulls } from '@deltasierra/shared';
import { AgencyService } from '../../agencies/service';
import { InteractionUtils } from '../../common/interactionUtils';
import { MvNotifier } from '../../common/mvNotifier';
import { I18nService } from '../../i18n/i18nService';
import { AgencyNotificationsService } from '../notifications/agencyNotificationsService';
import { agencyTabNavEntries } from '../tabNavEntries';

export class AgencyContactDetailsController {
    public static SID = 'AgencyContactDetailsController';

    public static readonly $inject: string[] = [
        InteractionUtils.SID,
        I18nService.SID,
        MvNotifier.SID,
        AgencyService.SID,
        AgencyNotificationsService.SID,
    ];

    public tabNavEntries = agencyTabNavEntries;

    public contactDetails?: AgencyContactDetails;

    public fetchData = this.interactionUtils.createFuture(this.i18n.text.common.fetchData(), () =>
        this.agencyService.getContactDetails().then(contacts => {
            this.contactDetails = contacts;
        }),
    );

    public update = this.interactionUtils.createFuture(this.i18n.text.agency.contactDetails.updateContacts(), () => {
        replaceEmptyStringsWithNulls(this.contactDetails!);
        return this.agencyService
            .updateContactDetails(this.contactDetails!)
            .then(() => this.notifier.notify(this.i18n.text.common.changesSaved(), 'success'));
    });

    public constructor(
        private interactionUtils: InteractionUtils,
        private i18n: I18nService,
        private notifier: MvNotifier,
        private agencyService: AgencyService,
    ) {
        void this.fetchData.run({});
    }
}

angular.module('app').controller(AgencyContactDetailsController.SID, AgencyContactDetailsController);
