import { LocationId, ClientId } from '@deltasierra/shared';
import { $locationSID } from '../common/angularData';
import IDirective = angular.IDirective;
import ILocationService = angular.ILocationService;

export const locationNavSID = 'locationNav';

export const locationNavConfig = {
    restrict: 'E',
    templateUrl: '/partials/locations/location-nav',
    scope: {
        client: '=',
        location: '=',
    },
};

export interface ILocationNavScope extends ng.IScope {
    client: { id: ClientId; title: string };
    location: { id: LocationId; title: string };
    isActive: (route: string) => boolean;
    items: Array<{ title: string; url: string }>;
}

angular.module('app').directive(locationNavSID, [
    $locationSID,
    function ($location: ILocationService): IDirective<ILocationNavScope> {
        return {
            ...locationNavConfig,
            link(scope: ILocationNavScope, element, attrs) {
                scope.isActive = (route: string) => route === $location.path();

                scope.$watchGroup(['client.id', 'location.id'], ([clientId, locationId]) => {
                    const baseUrl = `/clients/${clientId}/locations/${locationId}`;

                    scope.items = [
                        {
                            title: 'Users',
                            url: `${baseUrl}/users`,
                        },
                        {
                            title: 'Categories',
                            url: `${baseUrl}/categories`,
                        },
                        {
                            title: 'Location',
                            url: baseUrl,
                        },
                        {
                            title: 'Platforms',
                            url: `${baseUrl}/platforms`,
                        },
                    ];
                });
            },
        };
    },
]);
