/// <reference path="../../../../typings/browser.d.ts" />
import { ISendToPrintProviderBody, LocationId, paramsAndBody, PublishPrintApi } from '@deltasierra/shared';
import { $httpSID } from '../../common/angularData';
import { invokeApiRoute } from '../../common/httpUtils';

export class PrintPublishService {
    static SID = 'printPublishService';

    static readonly $inject: string[] = [$httpSID];

    constructor(private $http: ng.IHttpService) {}

    sendToPrintProvider(locationId: LocationId, data: ISendToPrintProviderBody) {
        return invokeApiRoute(
            this.$http,
            PublishPrintApi.sendToPrintProvider,
            paramsAndBody(
                {
                    locationId,
                },
                data,
            ),
        );
    }
}

angular.module('app').service(PrintPublishService.SID, PrintPublishService);
