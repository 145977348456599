import { t, Omit, PropertyPathAccessor } from '@deltasierra/shared';
import * as Formik from 'formik';
import * as React from 'react';
import { Translate, TranslateProps } from '../../directives/Translate';
import { FormDirectionContext } from './Form';

export type FieldIntegrationProps<T> = {
    field: Omit<PublicFormikProps<T>, 'name'> & {
        error?: string;
        formik: Formik.FieldProps<T>;
        handleBlurEvent(event: React.FocusEvent<any>): void;
        handleChangeEvent(event: React.ChangeEvent<any>): void;
        name: string;
        onBlur(): void;
        onChange(value: T): void;
        value: T;
    };
};

export type PublicFormikProps<T> = {
    disabled?: boolean;
    label: string;
    name: PropertyPathAccessor<any> | string;
    required?: boolean;
};

function defaultValidator<TValue>(value: TValue, props: PublicFormikProps<TValue>): string | undefined {
    return props.required && !value ? t('COMMON.REQUIRED_FIELD_MESSAGE') : undefined;
}

/**
 * Integrate a custom input component with forms/Formik. This is mainly a wrapper for the Formik.Field component.
 *
 * @example
 * type TextFieldProps<TValue> = {
 *     type: 'email' | 'phone' | 'text';
 *     field: FieldIntegrationProps<TValue>;
 * };
 * function TextField<TValue extends string | number>({ type, field }: TextFieldProps<TValue>): React.ReactElement {
 *     return (
 *         <input type={type} value={field.value} onChange={field.handleChangeEvent} name={field.name} />
 *     );
 * }
 * const validation = (value: string) => value.indexOf('s') ? 'Value must not contain "s"' : undefined;
 * const TextInputField = withFormField(validation)(TextField);
 * @param [validator] - An optional validation callback for field level validation
 * @returns A function to wrap a component with validation and Formik integration
 * @see {@link https://jaredpalmer.com/formik/docs/overview Formik docs}
 * @see {@link https://jaredpalmer.com/formik/docs/api/field Formik.Field docs}
 */
export function withFormField<TValue>(validator?: (value: TValue) => string | undefined) {
    return function wrapComponent<TProps extends FieldIntegrationProps<TValue>>(
        Component: React.ComponentType<TProps>,
    ): React.ComponentType<PublicFormikProps<TValue> & Omit<TProps, keyof FieldIntegrationProps<TValue>>> {
        const WrappingComponent: React.FunctionComponent<PublicFormikProps<TValue> & Omit<TProps, keyof FieldIntegrationProps<TValue>>> = (
            props: PublicFormikProps<TValue> & Omit<TProps, keyof FieldIntegrationProps<TValue>>,
        ) => {
            const validatorOrDefault = validator || defaultValidator;
            const name = typeof props.name === 'string' ? props.name : props.name.__path;
            return (
                <Formik.Field name={name} validate={(value: TValue) => validatorOrDefault(value, props)}>
                    {({ field, form }: Formik.FieldProps<TValue>) => {
                        const { label, required, disabled, ...rest } = props;
                        const passThroughProps = ({
                            ...rest,
                            field: {
                                disabled: disabled || form.isSubmitting,
                                error: Formik.getIn(form.touched, field.name) ? Formik.getIn(form.errors, field.name) : undefined,
                                formik: {
                                    field,
                                    form,
                                },
                                handleBlurEvent: field.onBlur,
                                handleChangeEvent: field.onChange,
                                label,
                                name: field.name,
                                onBlur: () => {
                                    form.setFieldTouched(field.name);
                                    form.handleBlur(field.name);
                                },
                                onChange: (value: TValue) => {
                                    form.setFieldValue(field.name, value);
                                    form.handleChange(field.name);
                                },
                                required,
                                value: field.value,
                            },
                        } as unknown) as TProps;
                        return (
                            <FormGroupWithLabel error={passThroughProps.field.error} label={props.label} required={props.required}>
                                <Component {...passThroughProps} />
                            </FormGroupWithLabel>
                        );
                    }}
                </Formik.Field>
            );
        };
        WrappingComponent.displayName = `withFormField(${ Component.displayName || (Component as any).name })`;
        return WrappingComponent;
    };
}

export type FormGroupWithLabelProps = {
    children: React.ReactElement;
    error?: string;
    label: string;
    required?: boolean;
};

export const FormGroupWithLabel: React.FunctionComponent<FormGroupWithLabelProps> = React.memo(({ children, error, label, required }) => {
    const direction = React.useContext(FormDirectionContext);
    return (
        <div className={`form-group ${ error ? 'has-error' : '' }`}>
            <label className={`control-label text-nowrap ${ direction === 'horizontal' ? 'col-md-2' : '' }`}>
                {label} {required && <RequiredFieldMarker />}
            </label>
            <div className={direction === 'horizontal' ? 'col-md-10' : ''}>{children}</div>
            {error && <div className={`help-block ${ direction === 'horizontal' ? 'col-md-offset-2 col-md-10' : '' }`}>{error}</div>}
        </div>
    );
});
FormGroupWithLabel.displayName = 'FormGroupWithLabel';

export type FormFieldProps = {
    label: TranslateProps;
};

/**
 * Create a HOC to wrap a component with common form elements and properties.
 *
 * @example
 * // Add a label to an input component
 * const Input = () => <input type="text" />;
 * const InputWithLabel = withFormField()(Input);
 * <InputWithLabel label="Hello!" />
 * @returns Function - A function to wrap a React element and apply form elements and properties
 */
export function withFormGroupAndLabel() {
    return function wrapComponent<T>(Component: React.FunctionComponent<T>): React.ComponentType<T & FormFieldProps> {
        const WrappingComponent: React.FunctionComponent<T & FormFieldProps> = props => (
            <FormGroupWithLabel label={t(props.label.keyId, props.label.options)}>
                <Component {...props} />
            </FormGroupWithLabel>
        );

        WrappingComponent.displayName = `withFormField(${ Component.displayName })`;
        return WrappingComponent;
    };
}

export const RequiredFieldHelp: React.FunctionComponent = () => (
    <p>
        <span className="space-right">
            <RequiredFieldMarker />
        </span>
        <Translate keyId="COMMON.REQUIRED_FIELD_HELP" />
    </p>
);

const fieldIndication = '*';
export const RequiredFieldMarker: React.FunctionComponent = () => <span className="required">{fieldIndication}</span>;
RequiredFieldMarker.displayName = 'RequiredFieldMarker';
