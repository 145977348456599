import * as React from 'react';
import AttachFile from '@material-ui/icons/AttachFile';
import { DSButton, Translate } from '@deltasierra/components';
import styled from 'styled-components';

// This style needs increased specificity to compete with bootstrap
const HiddenInput = styled.input.attrs({
    accept: '*',
    multiple: true,
    type: 'file',
})`
    &&& {
        display: none;
    }
`;

const WrapperLabel = styled.label`
    width: 100%;
`;

export type UploadButtonProps = {
    disabled?: boolean;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
};

export function UploadButton({ disabled, onChange }: UploadButtonProps): JSX.Element {
    const handleChange: React.ChangeEventHandler<HTMLInputElement> = React.useCallback(
        ev => {
            onChange?.(ev);
            ev.target.value = '';
        },
        [onChange],
    );

    return (
        <>
            <HiddenInput disabled={disabled} id="upload-input" onChange={handleChange} />
            <WrapperLabel htmlFor="upload-input">
                <DSButton
                    color="primary"
                    component="span"
                    disabled={disabled}
                    fullWidth
                    startIcon={<AttachFile aria-hidden />}
                    variant="contained"
                >
                    <Translate keyId="COMMON.UPLOAD_A_FILE" />
                </DSButton>
            </WrapperLabel>
        </>
    );
}
UploadButton.displayName = 'UploadButton';
