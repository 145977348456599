/// <reference path="../../../../typings/browser.d.ts" />
import { AssignedLocation } from '@deltasierra/shared';
import { MvIdentity } from '../../account/mvIdentity';
import { $locationSID } from '../../common/angularData';
import { MvNotifier } from '../../common/mvNotifier';
import { I18nService } from '../../i18n';
export class DashboardController {
    public static readonly SID = 'dashboardController';

    public location?: AssignedLocation | null;

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public static readonly $inject: string[] = [$locationSID, MvNotifier.SID, MvIdentity.SID, I18nService.SID];

    public constructor(
        $location: ng.ILocationService,
        private readonly notifier: MvNotifier,
        public readonly identity: MvIdentity,
        private readonly i18n: I18nService,
    ) {
        if (this.identity.isAuthenticated()) {
            if (this.identity.isConnectToAccess()) {
                $location.url('/connect');
            } else if (this.identity.isGuidedTourEnabled()) {
                if (this.identity.isGuidedTourPostponed()) {
                    $location.url('/');
                } else {
                    $location.url('/intro');
                }
            } else {
                this.location = null;
            }
        } else {
            $location.url('/login');
        }
    }

    public updateLocation(location: AssignedLocation): void {
        this.location = location;

        if (!this.location) {
            this.notifier.unexpectedError(this.i18n.text.dashboard.failedToSetYourLocation());
        }
    }
}

angular.module('app').controller(DashboardController.SID, DashboardController);
