/// <reference path="../../../typings/browser.d.ts" />
import { MaintenanceNotification } from '@deltasierra/shared';
import moment from 'moment';
import { $resourceSID } from '../common/angularData';

export const SID = 'maintenanceNotificationResource';

export interface MaintenanceNotificationResource
    extends ng.resource.IResource<MaintenanceNotificationResource>,
        MaintenanceNotification {}

export interface MaintenanceNotificationResourceClass
    extends ng.resource.IResourceClass<MaintenanceNotificationResource> {
    upcoming(): MaintenanceNotificationResource;
}

const factory = ($resource: ng.resource.IResourceService): MaintenanceNotificationResourceClass =>
    $resource<unknown, MaintenanceNotificationResourceClass>(
        '/api/maintenanceNotifications',
        {},
        {
            delete: {
                isArray: false,
                method: 'DELETE',
                url: '/api/maintenanceNotifications',
            },
            get: {
                interceptor: {
                    response(response: any) {
                        const instance = response.resource;

                        instance.startTime = moment(instance.startTime).toDate();

                        return instance;
                    },
                },
                isArray: false,
                url: '/api/maintenanceNotifications',
            },
            save: {
                isArray: false,
                method: 'POST',
                url: '/api/maintenanceNotifications',
            },
            upcoming: {
                isArray: false,
                url: '/api/maintenanceNotifications/upcoming',
            },
        },
    );

angular.module('app').factory(SID, [$resourceSID, factory]);
