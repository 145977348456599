import * as React from 'react';
import { CurrentClientIdType, CurrentClientRouteStateContext } from '../contexts';
import { useCurrentClientLocalStorageState } from './useCurrentClientStorageState';

/**
 * Use the current client from the combined contexts
 *
 * @param [useLocalStorageAsFallback=true] - Whether to include the local storage as a fallback?
 * @returns The current client
 */
export function useCurrentClientState(useLocalStorageAsFallback = true): CurrentClientIdType | undefined {
    const currentClientFromLocalStorage = useCurrentClientLocalStorageState();
    const currentClientFromContext = React.useContext(CurrentClientRouteStateContext);
    return useLocalStorageAsFallback
        ? currentClientFromContext ?? currentClientFromLocalStorage
        : currentClientFromContext;
}
