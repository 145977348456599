import * as React from 'react';
import { NotifierDispatchNotificationProvider } from '../NotifierDispatchNotificationProvider';
import { NotifierInstanceProvider } from '../NotifierInstanceProvider';
import { NotifierThemeProvider } from '../NotifierThemeProvider';

export type NotifierProviderProps = {
    children: React.ReactNode;
};

export function NotifierProvider({ children }: NotifierProviderProps): JSX.Element {
    return (
        <NotifierThemeProvider>
            <NotifierDispatchNotificationProvider>
                <NotifierInstanceProvider>{children}</NotifierInstanceProvider>
            </NotifierDispatchNotificationProvider>
        </NotifierThemeProvider>
    );
}
NotifierProvider.displayName = 'NotifierProvider';
