/// <reference path="../../../typings/browser.d.ts" />
import * as Sentry from '@sentry/browser';
import { $qSID } from './angularData';
import { TranslatableError } from './exceptions';
import IHttpPromiseCallbackArg = angular.IHttpPromiseCallbackArg;

export const networkDropoutInterceptorSID = 'networkDropoutInterceptor';
const HTTP_STATUS_NO_NETWORK_CONNECTION = -1;

export class NoNetworkConnectionError extends TranslatableError {
    public constructor() {
        super('ERRORS.NO_NETWORK_CONNECTION_ERROR', undefined, { bypassLogging: true });
    }
}

angular.module('app').factory(networkDropoutInterceptorSID, [
    $qSID,
    function networkDropoutInterceptorFactory($q: ng.IQService) {
        return {
            responseError(response: IHttpPromiseCallbackArg<any>) {
                if (response.status === HTTP_STATUS_NO_NETWORK_CONNECTION) {
                    const error = new NoNetworkConnectionError();
                    Sentry.withScope(scope => {
                        scope.setExtra('response', response);
                        Sentry.captureException(error);
                    });
                    return $q.reject(error);
                }
                return $q.reject(response);
            },
        };
    },
]);
