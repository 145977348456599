import { gql } from '@apollo/client';

export const GET_FLAG_FOR_LOCATION_GROUPS_DASHBOARD_WELL = gql`
    query GetFlagForLocationGroupsDashboardWell($locationId: ID!) {
        location(id: $locationId) {
            id
            client {
                id
                showGroupsOnDashboard
            }
        }
    }
`;
