/// <reference path="../../../../typings/browser.d.ts" />

import { ISize } from '@deltasierra/shared';
import { $rootScopeSID } from '../../common/angularData';
import { $modalSID } from '../angularUIBootstrapData';
import { ModalService } from '../../typings/angularUIBootstrap/modalService';
import { SID as ControllerSID, MvCropperCtrlScope } from './controller';

export class ImageCropperService {
    public static readonly $inject: string[] = [$rootScopeSID, $modalSID];

    public static SID = 'ImageCropperService';

    public constructor(private $rootScope: ng.IRootScopeService, private $modal: ModalService) {}


    public showCropperModal(
        file: File,
        lockAspectRatioToDimensions: ISize,
        storeImageAtTargetDimensions?: boolean,
        strict?: boolean,
    ): ng.IPromise<File> {
        const newScope: MvCropperCtrlScope = this.$rootScope.$new() as MvCropperCtrlScope;
        newScope.inputFile = file;
        newScope.targetDimensions = lockAspectRatioToDimensions;
        newScope.strict = strict ?? true;

        if (storeImageAtTargetDimensions) {
            newScope.storeImageAtTargetDimensions = true;
        }

        const modalInstance = this.$modal.open({
            backdrop: 'static',
            controller: ControllerSID,
            scope: newScope,
            templateUrl: '/partials/common/imageCropper/template',
        });

        return modalInstance.result as ng.IPromise<File>;
    }
}

angular.module('app').service(ImageCropperService.SID, ImageCropperService);
