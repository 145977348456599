import { gql } from '@apollo/client';

export const PlannerCategoryFragment = gql`
    fragment PlannerCategoryFragment on BuilderTemplateCategory {
        id
        title
        order
        legacyId
        startDate
        endDate
    }
`;
