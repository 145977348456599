import * as React from 'react';
import { FormikHelpers } from 'formik';
import {
    t,
    AssignedLocation,
    Client,
    InviteClientUserDto,
    Roles,
    SanitizedUser,
    CONNECT_TO_ACCESS_EXCLUSIVE_ROLES,
} from '@deltasierra/shared';
import { ExpressionBinding, OneWayBinding } from '../angularData';
import { useAngularServiceContext } from '../componentUtils/angularServiceContexts';
import { withAngularIntegration } from '../componentUtils/reactComponentRegistration';
import { CheckboxesFieldInput, CheckboxToggleFieldInput } from '../form/Checkboxes';
import UserInviteForm from './UserInviteForm';

export type UserInviteFormProps = {
    client: Client;
    location: AssignedLocation;
    onCreate: (newUser: SanitizedUser) => void;
};

const ClientUserInviteForm: React.FunctionComponent<UserInviteFormProps> = ({ client, location, onCreate }) => {
    const locationApiClient = useAngularServiceContext('LocationUserApiClient');
    const notifier = useAngularServiceContext('mvNotifier');
    const identity = useAngularServiceContext('mvIdentity');
    const roleOptions = useRoleOptions();
    const initialValues: InviteClientUserDto = {
        guidedTourEnabled: true,
        localeCode: identity.currentUser!.localeCode,
        roles: [Roles.builder, Roles.analyst, Roles.specialRequester, Roles.publisher, Roles.planner],
        timezone: location.timezone,
        username: '',
    };

    const onSubmit = async (values: InviteClientUserDto, actions: FormikHelpers<InviteClientUserDto>) => {
        const newUser = await locationApiClient.inviteClientUser(client.id, location.id, values);
        // Force the locale code because i18next seems to use the locale code of the invited user...
        notifier.notify(
            t('AGENCY.CLIENT.LOCATION.USERS.USER_ADDED', { ...values, localeCode: identity.currentUser!.localeCode }),
        );
        actions.resetForm();
        onCreate(newUser);
    };

    const isRoleDisabled = React.useCallback((selectedRoles: Roles[], role: Roles) => {
        if (CONNECT_TO_ACCESS_EXCLUSIVE_ROLES.has(role) && selectedRoles.includes(Roles.connectToAccess)) {
            return true;
        } else if (
            role === Roles.connectToAccess &&
            selectedRoles.some(selectedRole => CONNECT_TO_ACCESS_EXCLUSIVE_ROLES.has(selectedRole))
        ) {
            return true;
        }
        return false;
    }, []);

    return (
        <UserInviteForm
            initialValues={initialValues}
            title={t('AGENCY.CLIENT.LOCATION.USERS.ADD_NEW_USER_TO', {
                client: client.title,
                location: location.title,
            })}
            onSubmit={onSubmit}
        >
            {(props, inputNames) => (
                <>
                    <CheckboxesFieldInput
                        dataCy="user-roles-checkbox"
                        label={t('AGENCY.CLIENT.LOCATION.USERS.ROLES')}
                        name={inputNames.roles}
                        options={roleOptions}
                        valueDisabled={role => isRoleDisabled(props.values.roles, role as Roles)}
                    />
                    <CheckboxToggleFieldInput label={t('PROFILE.GUIDED_TOUR')} name={inputNames.guidedTourEnabled} />
                </>
            )}
        </UserInviteForm>
    );
};
ClientUserInviteForm.displayName = 'ClientUserInviteForm';

function useRoleOptions() {
    const i18n = useAngularServiceContext('I18nService');
    return React.useMemo(
        () => [
            {
                label: i18n.text.agency.client.location.users.userEdit.analyst(),
                value: Roles.analyst,
            },
            {
                label: i18n.text.agency.client.location.users.userEdit.builder(),
                value: Roles.builder,
            },
            {
                label: i18n.text.agency.client.location.users.userEdit.specialRequester(),
                value: Roles.specialRequester,
            },
            {
                label: i18n.text.agency.client.location.users.userEdit.publisher(),
                value: Roles.publisher,
            },
            {
                label: i18n.text.agency.client.location.users.userEdit.planner(),
                value: Roles.planner,
            },
            {
                label: i18n.text.agency.client.location.users.userEdit.connectToAccess(),
                value: Roles.connectToAccess,
            },
        ],
        [i18n],
    );
}

export default withAngularIntegration(ClientUserInviteForm, 'clientUserInviteForm', {
    client: OneWayBinding,
    location: OneWayBinding,
    onCreate: ExpressionBinding,
});
