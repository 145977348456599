import * as React from 'react';
import { t } from '@deltasierra/shared';
import { EditCollectionModal } from './EditCollectionModal';

export type EditCollectionButtonProps = {
    collectionId: string;
};

export const EditCollectionButton: React.FC<EditCollectionButtonProps> = ({ collectionId }) => {
    const [show, setShow] = React.useState(false);
    return (
        <>
            <button className="btn btn-sm btn-link" type="button" onClick={() => setShow(true)}>
                <i className="fas fa-cog text-muted" title={t('COMMON.SETTINGS')} />
            </button>
            <EditCollectionModal collectionId={collectionId} show={show} onClose={() => setShow(false)} />
        </>
    );
};
EditCollectionButton.displayName = 'EditCollectionButton';
