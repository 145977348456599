
/// <reference path="../../../typings/browser.d.ts" />
import { Client, ClientId } from '@deltasierra/shared';
import { InteractionUtils } from '../common/interactionUtils';
import { $routeParamsSID, IRouteParams } from '../common/angularData';
import { MvClient } from './mvClient';

export class MvClientUsersCtrl {
    public static SID = 'mvClientUsersCtrl';

    public static readonly $inject: string[] = [$routeParamsSID, InteractionUtils.SID, MvClient.SID];

    public clientId: ClientId;

    public client: Client | null = null;

    public readonly getClientById = this.interactionUtils.createFuture<any, { clientId: ClientId }>(
        'get client by ID',
        (context: { clientId: ClientId }) => this.mvClient.getClientById(context.clientId),
    );

    public constructor(
        $routeParams: IRouteParams,
        private readonly interactionUtils: InteractionUtils,
        private readonly mvClient: MvClient,
    ) {
        this.clientId = ClientId.from(parseInt($routeParams.clientId, 10));
        void this.getClientById.run({ clientId: this.clientId }).then((client: Client) => {
            this.client = client;
        });
    }
}

angular.module('app').controller(MvClientUsersCtrl.SID, MvClientUsersCtrl);
