import { isNotNullOrUndefined } from '@deltasierra/type-utilities';
import { UploadCategory } from '@deltasierra/shared';
import { Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';
import { Plugin, PluginKey } from 'prosemirror-state';
import { UploadFileNodeAttributes } from './upload-file-node-attributes';
import { UploadFileNodeOptions } from './upload-file-node-options';
import { UploadFileNodeView } from './UploadFileNodeView';

type InsertUploadFileNodeOptions = {
    file: File;
    insertPos?: number;
    src?: string;
    uploadCategory: UploadCategory;
};

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        insertUploadFileNode: {
            /**
             * Add a node to show the loading of an uploading file
             */
            insertUploadFileNode: (options: InsertUploadFileNodeOptions) => ReturnType;
        };
        insertUploadFileNodes: {
            insertUploadFileNodes: (options: InsertUploadFileNodeOptions[]) => ReturnType;
        };
    }
}

export const UploadFileNode = Node.create<UploadFileNodeOptions>({
    addAttributes(): TypedTipTapAttributes<UploadFileNodeAttributes> {
        return {
            file: {
                default: null,
            },
            src: {
                default: undefined,
            },
            uploadCategory: {
                default: null,
            },
        };
    },
    addCommands() {
        return {
            insertUploadFileNode:
                ({ file, insertPos, src, uploadCategory }) =>
                props => {
                    const pos = props.editor.state.selection.anchor;
                    return props.commands.insertContentAt(insertPos ?? pos, {
                        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                        attrs: {
                            file,
                            src,
                            uploadCategory,
                        } as UploadFileNodeAttributes,
                        type: this.name,
                    });
                },
            insertUploadFileNodes: fileOptions => props => {
                const pos = props.editor.state.selection.anchor;

                let chain = props.chain();
                for (const fileOption of fileOptions) {
                    const { file, insertPos, src, uploadCategory } = fileOption;
                    chain = chain.insertContentAt(insertPos ?? pos, {
                        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                        attrs: {
                            file,
                            src,
                            uploadCategory,
                        } as UploadFileNodeAttributes,
                        type: this.name,
                    });
                }
                return chain.run();
            },
        };
    },
    addNodeView() {
        return ReactNodeViewRenderer(UploadFileNodeView);
    },
    addProseMirrorPlugins(this) {
        const editor = this.editor;
        const options = this.options;

        const insertFileNodes = (files: File[], position: number) => {
            const mappedOptions: InsertUploadFileNodeOptions[] = files
                .filter(file => file.type && file.type.startsWith('image/'))
                .map(file => ({
                    file,
                    insertPos: position,
                    uploadCategory: options.defaultUploadCategory,
                }));
            return editor.commands.insertUploadFileNodes(mappedOptions);
        };

        const handleDOMEventsCorrectlyTyped: {
            [event in keyof HTMLElementEventMap]?: (
                view: Parameters<Exclude<Exclude<Plugin['props']['handleDOMEvents'], undefined>[string], undefined>>[0],
                event: HTMLElementEventMap[event],
            ) => boolean | void;
        } = {
            drop: (view, event: HTMLElementEventMap['drop']) => {
                if (!event.dataTransfer?.files?.length || !(event.dataTransfer.files.length > 0)) {
                    return false;
                }

                event.preventDefault();

                const files = Array.from(event.dataTransfer.files);
                const coordinates = view.posAtCoords({ left: event.clientX, top: event.clientY });

                insertFileNodes(files, coordinates?.pos ?? 0);

                return true;
            },
            paste(view, event: HTMLElementEventMap['paste']) {
                if (!event.clipboardData?.items) {
                    return false;
                }

                const files = Array.from(event.clipboardData.items)
                    .map(transferItem => transferItem.getAsFile())
                    .filter(isNotNullOrUndefined);

                insertFileNodes(files, view.state.selection.anchor);

                return true;
            },
        };

        // This fixes the broken typing inside the `prosemirror-view` package
        const handleDOMEvents: Plugin['props']['handleDOMEvents'] =
            handleDOMEventsCorrectlyTyped as unknown as Plugin['props']['handleDOMEvents'];

        return [
            new Plugin({
                key: new PluginKey('dom-events-correctly-typed'),
                props: {
                    handleDOMEvents,
                },
            }),
        ];
    },
    atom: true,
    draggable: true,
    group: 'block',
    name: 'upload-file',
    renderHTML() {
        // You cannot render this from html, if you do its a blank div
        return ['div'];
    },
    selectable: true,
});
