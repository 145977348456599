import * as React from 'react';
import { useLocalStorage } from '@deltasierra/react-hooks';
import { useRouter } from '../../../routes';

export function useLocationCategoryFromQueryParams(): [
    selectedCategory: string | null,
    setSelectedCategory: (val: string | null) => void,
] {
    const [fromUrl, setFromUrl] = useSelectedCategoryFromUrl();
    const [fromLocalStorage, setLocalStorageValue] = useLocalStorage<string | null>(
        'brands.engagement.selectedCategory',
    );
    const [value, setValue] = React.useState<string | null>(() => fromUrl ?? fromLocalStorage ?? null);

    React.useEffect(() => {
        if (value !== fromUrl) {
            setFromUrl(value);
        }
        if (value !== fromLocalStorage) {
            setLocalStorageValue(value);
        }
    });

    return [value, setValue];
}

function useSelectedCategoryFromUrl() {
    const router = useRouter<Record<string, never>, { selectedCategory?: string }>();
    const currentValue = router.query.get('selectedCategory') ?? null;

    const setValue = React.useCallback(
        (value: string | null | undefined): void => {
            if (!value) {
                router.query.remove('selectedCategory');
            } else {
                router.query.set('selectedCategory', value);
            }
        },
        [router.query],
    );
    return [currentValue, setValue] as const;
}
