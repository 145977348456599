// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../static/img/loading.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.svg-loading{background:rgba(0,0,0,0) url(${___CSS_LOADER_URL_REPLACEMENT_0___}) center center no-repeat;width:1.7em;height:1.7em;margin:0 auto;animation:rotation 2s infinite ease-in-out;background-size:cover}.svg-loading.svg-loading-inline{display:inline-block}.svg-loading.svg-loading-small{display:inline-block;width:32px;height:32px;vertical-align:bottom}.svg-loading.svg-loading-large{display:inline-block;width:64px;height:64px;vertical-align:bottom}@keyframes rotation{0%{transform:rotate(0)}100%{transform:rotate(360deg)}}`, "",{"version":3,"sources":["webpack://./../../dist/libraries/components/src/Generic/Loaders/SvgLoading/SvgLoading.scss"],"names":[],"mappings":"AAAA,aACI,wFAAA,CACA,WAAA,CACA,YAAA,CACA,aAAA,CACA,0CAAA,CACA,qBAAA,CACA,gCACI,oBAAA,CAEJ,+BACI,oBAAA,CACA,UAAA,CACA,WAAA,CACA,qBAAA,CAEJ,+BACI,oBAAA,CACA,UAAA,CACA,WAAA,CACA,qBAAA,CAIR,oBACI,GACI,mBAAA,CAEJ,KACI,wBAAA,CAAA","sourcesContent":[".svg-loading {\n    background: transparent url('../../../../static/img/loading.svg') center center no-repeat;\n    width: 1.7em;\n    height: 1.7em;\n    margin: 0 auto;\n    animation: rotation 2s infinite ease-in-out;\n    background-size: cover;\n    &.svg-loading-inline {\n        display: inline-block;\n    }\n    &.svg-loading-small {\n        display: inline-block;\n        width: 32px;\n        height: 32px;\n        vertical-align: bottom;\n    }\n    &.svg-loading-large {\n        display: inline-block;\n        width: 64px;\n        height: 64px;\n        vertical-align: bottom;\n    }\n}\n\n@keyframes rotation {\n    0% {\n        transform: rotate(0);\n    }\n    100% {\n        transform: rotate(360deg);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
