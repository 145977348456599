/// <reference path="../../../../typings/browser.d.ts" />
import { ReportableService } from '@deltasierra/integrations/integration-types';
import { $httpSID } from '../../common/angularData';
import { DataUtils } from '../../common/dataUtils';
import { BaseLocationStatsService } from './common';
import IHttpService = angular.IHttpService;

export class CampaignMonitorStatsService extends BaseLocationStatsService<CampaignMonitorStats, CampaignMonitorStatsEntry> {
    public static SID = 'campaignMonitorStatsService';

    public static readonly $inject: string[] = [$httpSID, DataUtils.SID];

    public serviceName: ReportableService = 'campaignMonitor';

    public displayName = 'Campaign Monitor';

    public constructor($http: IHttpService) {
        super($http);
    }

    public combineStats(stats: CampaignMonitorStatsEntry[]): CampaignMonitorStatsEntry {
        const initial: Summary = {
            Bounced: 0,
            Clicks: 0,
            Forwards: 0,
            Likes: 0,
            Mentions: 0,
            Recipients: 0,
            SpamComplaints: 0,
            TotalOpened: 0,
            UniqueOpened: 0,
            Unsubscribed: 0,
            WebVersionTextURL: null,
            WebVersionURL: null,
            WorldviewURL: null,
        };
        const dummy: CampaignMonitorStatsEntry = {
            campaign: null,
            client: null,
            summary: initial,
        };
        return stats.reduce(this.reduceStats.bind(this), dummy);
    }

    private reduceStats(
        previousValue: CampaignMonitorStatsEntry,
        currentValue: CampaignMonitorStatsEntry,
        currentIndex: number,
        array: CampaignMonitorStatsEntry[],
    ): CampaignMonitorStatsEntry {
        return {
            campaign: null,
            client: null,
            summary: {
                Bounced: previousValue.summary.Bounced + currentValue.summary.Bounced,
                Clicks: previousValue.summary.Clicks + currentValue.summary.Clicks,
                Forwards: previousValue.summary.Forwards + currentValue.summary.Forwards,
                Likes: previousValue.summary.Likes + currentValue.summary.Likes,
                Mentions: previousValue.summary.Mentions + currentValue.summary.Mentions,
                Recipients: previousValue.summary.Recipients + currentValue.summary.Recipients,
                SpamComplaints: previousValue.summary.SpamComplaints + currentValue.summary.SpamComplaints,
                TotalOpened: previousValue.summary.TotalOpened + currentValue.summary.TotalOpened,
                UniqueOpened: previousValue.summary.UniqueOpened + currentValue.summary.UniqueOpened,
                Unsubscribed: previousValue.summary.Unsubscribed + currentValue.summary.Unsubscribed,
                WebVersionTextURL: null,
                WebVersionURL: null,
                WorldviewURL: null,
            },
        };
    }
}

export interface Campaign {
    CampaignID: string;
    FromEmail: string;
    FromName: string;
    Name: string;
    ReplyTo: string;
    SentDate: string;
    Subject: string;
    TotalRecipients: number;
    WebVersionTextURL: string;
    WebVersionURL: string;
}

export interface Client {
    clientId: string;
    name: string;
}

export interface Summary {
    Bounced: number;
    Clicks: number;
    Forwards: number;
    Likes: number;
    Mentions: number;
    Recipients: number;
    SpamComplaints: number;
    TotalOpened: number;
    UniqueOpened: number;
    Unsubscribed: number;
    WebVersionTextURL: string | null;
    WebVersionURL: string | null;
    WorldviewURL: string | null;
}

export interface CampaignMonitorStatsEntry {
    campaign: Campaign | null;
    client: Client | null;
    summary: Summary;
}

export type CampaignMonitorStats = CampaignMonitorStatsEntry[];

angular.module('app').service('campaignMonitorStatsService', CampaignMonitorStatsService);
