import { AssignedLocation, MapGeneratedSocketMessageEvent } from '@deltasierra/shared';

import { InteractionUtils } from '../common/interactionUtils';
import { MvLocation } from '../locations/mvLocation';
import { I18nService } from '../i18n/i18nService';
import { UploadContext } from '../common/uploadService';
import { SocketService } from '../sockets/socketService';
import { $scopeSID } from '../common/angularData';
import IScope = angular.IScope;

export class LocationDetailsController {
    public static readonly $inject: string[] = [
        InteractionUtils.SID,
        MvLocation.SID,
        I18nService.SID,
        SocketService.SID,
        $scopeSID,
    ];

    public static SID = 'LocationDetailsController';

    public location?: AssignedLocation;

    public getLocationDetails = this.interactionUtils.createFuture(
        this.i18n.text.account.locationDetails.getLocationDetails(),
        context =>
            this.mvLocation.getAssignedLocation(this.location!.id).then(location => {
                this.location = location;
            }),
    );

    public uploadContext?: UploadContext;

    public constructor(
        private interactionUtils: InteractionUtils,
        private mvLocation: MvLocation,
        private i18n: I18nService,
        private socketService: SocketService,
        private readonly $scope: IScope,
    ) {
        this.socketService.on(MapGeneratedSocketMessageEvent, this.socketHandler);
        this.$scope.$on('$destroy', () => {
            this.socketService.off(MapGeneratedSocketMessageEvent, this.socketHandler);
        });
    }

    public changeLocation(location: AssignedLocation): void {
        this.location = location;
    }

    protected readonly socketHandler = (): ng.IPromise<void> => this.getLocationDetails.run({});
}

angular.module('app').controller(LocationDetailsController.SID, LocationDetailsController);
