import { Untyped } from '@deltasierra/shared';
import { ITimeoutService, IScope } from 'angular';
import { $timeoutSID } from '../common/angularData';

// IntroOptions = any;

export interface IntroJsIntroItem {
    element : HTMLElement;
    intro : string;
    step : number;
}

// Gleaned from console log. TODO: update types based on the documentation.
export interface IntroJs {
    _currentStep : number;
    _direction : 'backward' | 'forward';
    _introChangeCallback : (targetElement : HTMLElement) => void;
    _introCompleteCallback : () => void;
    _introExitCallback : () => void;
    _introItems : IntroJsIntroItem[];
    _lastShowElementTimer : number;
    _onKeyDown : (b : Event) => void;
    _onResize : (a : Event) => void;
    _options : IntroOptions;
    _targetElement : HTMLElement;

    addHints() : Untyped;
    clone() : Untyped;
    exit() : Untyped;
    goToStep(a : Untyped) : Untyped;
    hideHint(a : Untyped) : Untyped;
    hideHints() : Untyped;
    nextStep() : Untyped;
    onafterchange(a : Untyped) : Untyped;
    onbeforechange(a : Untyped) : Untyped;
    onchange(a : Untyped) : Untyped;
    oncomplete(a : Untyped) : Untyped;
    onexit(a : Untyped) : Untyped;
    onhintclick(a : Untyped) : Untyped;
    onhintclose(a : Untyped) : Untyped;
    onhintsadded(a : Untyped) : Untyped;
    previousStep() : Untyped;
    refresh() : Untyped;
    setOption(a : Untyped, b : Untyped) : Untyped;
    setOptions(a : Untyped) : Untyped;
    start() : Untyped;
}

export interface IntroStep {
    element?: string;
    intro: string;
    position?: string;
}

export interface IntroOptions {
    steps: IntroStep[];
    nextLabel?: string;
    prevLabel?: string;
    skipLabel?: string;
    doneLabel?: string;
    hidePrev?: boolean;
    hideNext?: boolean;
    tooltipPosition?: string;
    tooltipClass?: string;
    highlightClass?: string;
    exitOnEsc?: boolean;
    exitOnOverlayClick?: boolean;
    showStepNumbers?: boolean;
    keyboardNavigation?: boolean;
    showButtons?: boolean;
    showBullets?: boolean;
    showProgress?: boolean;
    scrollToElement?: boolean;
    overlayOpacity?: number;
    disableInteraction?: boolean;
}

interface IntroWrapperOptions {
    startMethod?: Function;
    // ExitMethod?: Function;
    // NextMethod?: Function;
    // PreviousMethod?: Function;
    // RefreshMethod?: Function;
    options: IntroOptions;
    onComplete?: Function;
    onExit?: Function;
    onChange?: (element: HTMLElement, scope: IScope) => void;
    onBeforeChange?: Function;
    onAfterChange?: Function;
    autoStart?: boolean;
    autoRefresh?: boolean;
}

class IntroJsNotAvailable extends Error {
    name = 'IntroJsNotAvailable';

    constructor() {
        super('Intro.js is not available. Make sure it is properly loaded.');
    }
}

declare const introJs: any; // TODO: proper types

export class IntroWrapper {
    intro? : IntroJs;

    refreshWatch? : Function;

    navigationWatch? : Function;

    autoStartWatch = this.scope.$watch('autoStart', () => {
        let p;
        if (this.options.autoStart) {
            p = this.$timeout(() => {
                if (this.options.startMethod) {
                    this.options.startMethod();
                }
            });
        }
        this.autoStartWatch();
        return p;
    });

    constructor(
        private $timeout: ITimeoutService,
        private scope: IScope,
        private options: IntroWrapperOptions) {
    }

    onElementsLoaded(callBack: () => void, extraElements?: string[], maxWaitTime = 20000) {
        if (this.options && this.options.options && this.options.options.steps) {
            const steps = this.options.options.steps;

            const elements = steps.filter((x): x is IntroStep & { element: string } => x.element !== null && x.element !== undefined && typeof x.element === 'string')
                .map(x => x.element);

            this.checkElements(elements.concat(extraElements || []), callBack, maxWaitTime);
        } else {
            callBack();
        }
    }

    private checkElements(elements: string[], callBack: () => void, maxWait: number): ng.IPromise<void> | void {
        const allExist = elements.every(x => document.querySelector(x) != null);
        // All of the elements have loaded now OR we've waited long enough
        if (allExist || maxWait <= 0) {
            callBack();
            return;
        }

        const waitTimeout = 100;
        maxWait -= waitTimeout;

        return this.$timeout(waitTimeout)
            .then(() => this.checkElements(elements, callBack, maxWait));
    }

    protected createIntro(step? : number | string): IntroJs {
        if (typeof step === 'string') {
            return introJs(step);
        } else {
            return introJs();
        }
    }

    doStep(step? : number | string) {
        this.navigationWatch = this.scope.$on('$locationChangeStart', () => {
            if (this.intro) {
                this.intro.exit();
            }
        });

        const intro: IntroJs = this.createIntro(step);
        this.intro = intro;

        this.intro.setOptions(this.options.options);

        if (this.options.autoRefresh) {
            this.refreshWatch = this.scope.$watch(() => {
                intro.refresh();
            });
        }
        this.bindEventCallbacks(intro);

        if (typeof step === 'number') {
            this.intro.goToStep(step).start();
        } else {
            this.intro.start();
        }

        this.scope.$on('$locationChangeSuccess', () => {
            if (typeof this.intro !== 'undefined') {
                intro.exit();
            }
        });

        this.scope.$on('$destroy', () => {
            this.clearWatches();
        });
    }

    protected bindEventCallbacks(intro: IntroJs) {
        // Tslint:disable-next-line:no-this-assignment
        const self = this;

        if (this.options.onComplete) {
            const onComplete = this.options.onComplete;
            intro.oncomplete(() => {
                onComplete(this.scope);
                void this.$timeout(() => {
 this.scope.$digest();
});
                this.clearWatches();
            });
        }

        if (this.options.onExit) {
            const onExit = this.options.onExit;
            intro.onexit(function (this: IntroJs) {
                onExit.call(this, self.scope);
                void self.$timeout(() => {
 self.scope.$digest();
});
                self.clearWatches();
            });
        }

        if (this.options.onChange) {
            const onChange = this.options.onChange;
            intro.onchange(function (this: IntroJs, targetElement : Untyped) {
                onChange.call(this, targetElement, self.scope);
                void self.$timeout(() => {
 self.scope.$digest();
});
            });
        }

        if (this.options.onBeforeChange) {
            const onBeforeChange = this.options.onBeforeChange;
            intro.onbeforechange(function (this: IntroJs, targetElement : Untyped) {
                onBeforeChange.call(this, targetElement, self.scope);
                void self.$timeout(() => {
 self.scope.$digest();
});
            });
        }

        if (this.options.onAfterChange) {
            const onAfterChange = this.options.onAfterChange;
            intro.onafterchange(function (this: IntroJs, targetElement : Untyped) {
                onAfterChange.call(this, targetElement, self.scope);
                void self.$timeout(() => {
 self.scope.$digest();
});
            });
        }
    }

    nextStep() {
        if (this.intro) {
            this.intro.nextStep();
        }
    }

    previousStep() {
        if (this.intro) {
            this.intro.previousStep();
        }
    }

    exit(callback? : Function) {
        if (this.intro) {
            this.intro.exit();
        }
        if (typeof callback === 'function') {
            callback();
        }
    }

    refresh() {
        if (this.intro) {
            this.intro.refresh();
        }
    }

    clearWatches() {
        if (this.navigationWatch) {
            this.navigationWatch();
        }
        if (this.refreshWatch) {
            this.refreshWatch();
        }
    }

    getCurrentStep() : number | undefined {
        if (this.intro) {
            return this.intro._currentStep;
        }
    }

    getDirection() : 'backward' | 'forward' | undefined {
        if (this.intro) {
            return this.intro._direction;
        }
    }
}

export class IntroWrapperFactory {
    static SID = 'introWrapperFactory';

    static readonly $inject : string[] = [
        $timeoutSID,
    ];

    constructor(
        private $timeout: ITimeoutService,
    ) {
        if (typeof introJs !== 'function') {
            throw new IntroJsNotAvailable();
        }
    }

    create(scope: IScope, options: IntroWrapperOptions) {
        return new IntroWrapper(this.$timeout, scope, options);
    }
}

angular.module('app').service(IntroWrapperFactory.SID, IntroWrapperFactory);
