import { TimeUnit } from './getDifferenceWithGreatestUnitOfTime';

/**
 * @param timeUnit - unit of time to convert to string
 * @param plural - whether the unit string should be plural
 * @returns - time unit string
 */
export function timeUnitToString(timeUnit: TimeUnit, plural = true): string {
    return plural ? timeUnit : timeUnit.slice(0, timeUnit.length - 1);
}
