import { LocationId, ClientId, ShoppingCartData, ServerRouteUrl } from '@deltasierra/shared';


import { InteractionUtils } from '../../common/interactionUtils';
import { MvAuth } from '../../account/mvAuth';

import { actualComponent, ChangesObject, ILifecycleHooks, OneWayBinding } from '../../common/angularData';
import { Four51ApiClient } from './four51ApiClient';

export class Four51ShoppingCart implements ILifecycleHooks {
    // Props
    public readonly locationId!: LocationId;

    public readonly clientId!: ClientId;

    // State
    public canAccessFour51 = false;

    public shoppingCart: ShoppingCartData | null = null;

    // Futures
    protected readonly fetchFour51Access = this.interactionUtils.createFuture('fetch Four51 access', (context: { clientId: ClientId }) => this.mvAuth.isClientAuthorizedAndConfigured('four51', context.clientId)
            .then((result: boolean) => {
                this.canAccessFour51 = result;
            }));

    public readonly fetchShoppingCart = this.interactionUtils.createFuture('fetch shopping cart', (context: { locationId: LocationId }) => this.four51ApiClient.getShoppingCart(context.locationId)
            .then(result => {
                if (result.shoppingCart) {
                    this.shoppingCart = result.shoppingCart;
                }
            }));

    static readonly $inject : string[] = [
        InteractionUtils.SID,
        MvAuth.SID,
        Four51ApiClient.SID,
    ];

    constructor(
        protected readonly interactionUtils: InteractionUtils,
        protected readonly mvAuth : MvAuth,
        protected readonly four51ApiClient: Four51ApiClient,
    ) {
    }

    $onChanges(changes: ChangesObject<this>) {
        if (changes.locationId && changes.locationId.currentValue) {
            this.shoppingCart = null;
            return this.fetchFour51Access.run({
                clientId: this.clientId,
            }).then(() => {
                if (this.canAccessFour51) {
                    return this.fetchShoppingCart.run({
                        locationId: this.locationId,
                    });
                }
            });
        }
    }

    getShoppingCartUrl(): ServerRouteUrl {
        return this.four51ApiClient.getLogonToFour51Url(this.locationId);
    }
}

export const four51ShoppingCartSID = 'fourfiftyoneShoppingCart';

export const four51ShoppingCartConfig: ng.IComponentOptions = actualComponent(
    Four51ShoppingCart,
    '/partials/contentBuilder/four51/four51ShoppingCart',
    {
        locationId: OneWayBinding,
        clientId: OneWayBinding,
    },
);

angular.module('app').component(four51ShoppingCartSID, four51ShoppingCartConfig);
