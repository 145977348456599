/// <reference path="../../../typings/browser.d.ts" />
import { Untyped } from '@deltasierra/shared';

import { MvIdentity } from '../account/mvIdentity';
import { InteractionUtils } from '../common/interactionUtils';
import { DataUtils } from '../common/dataUtils';
import { IntroWrapper } from '../intro/introWrapper';
import { I18nService } from '../i18n/i18nService';
import { IntroService } from './../intro/introService';
import { TryThisVoteable } from './models';
import { MvTryThis } from './mvTryThis';
import { BaseTryThisCtrl } from './common';
import IScope = angular.IScope;
import ILocationService = angular.ILocationService;
import IPromise = angular.IPromise;
import ITimeoutService = angular.ITimeoutService;
import IQService = angular.IQService;

const CHUNK_SIZE = 3;

export class MvTryThisCtrl extends BaseTryThisCtrl {
    static SID = 'mvTryThisCtrl';

    private readonly searchedText : string | null = null;

    loading = {
        trythises: false,
        more: false,
        vote: false,
    };

    showing = 0;

    loadAmount = 10;

    introLearn : IntroWrapper;

    static readonly $inject : string[] = [
        '$q',
        '$scope',
        '$timeout',
        '$location',
        'Slug',
        MvIdentity.SID,
        MvTryThis.SID,
        InteractionUtils.SID,
        DataUtils.SID,
        IntroService.SID,
        I18nService.SID,
    ];

    constructor(
        $q : IQService,
        $scope : IScope,
        $timeout : ITimeoutService,
        $location : ILocationService,
        Slug : Untyped,
        identity : MvIdentity,
        mvTryThis : MvTryThis,
        interactionUtils : InteractionUtils,
        dataUtils : DataUtils,
        introService : IntroService,
        public i18nService : I18nService,
    ) {
        super($q, $scope, $timeout, $location, Slug, identity, mvTryThis, interactionUtils, dataUtils, i18nService);
        const queryParams = $location.search();
        if (queryParams.q) {
            this.searchedText = queryParams.q;
            this.searchText = this.searchedText;
        }

        // We wait for the location picker as it isn't a static element and allows the page to load more before starting the intro
        this.introLearn = introService.setUpAndStartIntro('learn', this.$scope, ['.location-picker.btn-group']);
    }

    protected loadTryThisData() : IPromise<any> {
        return this.loadMore();
    }

    private rectifyItems(ts : TryThisVoteable[]) {
        for (const tryThis of ts) {
            if ((tryThis.helpful && tryThis.helpful.indexOf(this.identity.currentUser!.id) > -1) ||
                (tryThis.unhelpful && tryThis.unhelpful.indexOf(this.identity.currentUser!.id) > -1)
            ) {
                tryThis.voted = true;
            }
        }
    }

    loadMore() : IPromise<void> {
        if (this.location) {
            return this.interactionUtils.handleRemoteSimple(this, 'retrieve more learn articles', 'more',
                this.mvTryThis.getTryThese(this.location.clientId, this.loadAmount, this.showing, this.searchedText || undefined),
            ).then((items : TryThisVoteable[]) => {
                this.showing += this.loadAmount;
                this.rectifyItems(items);
                if (items.length < this.loadAmount) {
                    this.allLoaded = true;
                }
                if (this.trythis == null && items.length > 0) {
                    this.trythis = items.splice(0, 1)[0];
                }
                this.trythises = this.trythises.concat(items);
                this.chunkedTryThese = this.dataUtils.chunk(CHUNK_SIZE, this.trythises);
            });
        } else {
            return this.$q.resolve();
        }
    }

    protected reset() {
        super.reset();
        this.trythis = null;
        this.showing = 0;
    }
}

angular.module('app').controller(MvTryThisCtrl.SID, MvTryThisCtrl);
