import { DSClickAwayListener, DSGrow, DSPaper, DSPopper } from '@deltasierra/components';
import * as React from 'react';
import styled from 'styled-components';
import { HashtagsSelectList } from './HashtagsSelectList';

const StyledContainer = styled(DSPaper)<{ $width?: number }>`
    ${props => props.$width && `width: ${props.$width}px;`}
    box-shadow: ${props => props.theme.shadows[2]};
`;

// Use this to remove the annoying flippy dropdown
const popperModifiers = { flip: { enabled: false } };

export type HashtagSetsDropdownProps = {
    disabledCheck?: (hashtags: ReadonlyArray<string>) => boolean;
    existingHashtags?: string[];
    locationId: string;
    onAdd: (hashtags: string[]) => void;
    onClickAway?: () => void;
    open: boolean;
    popperAnchorEl: HTMLElement | null;
};
export function HashtagSetsDropdown({
    disabledCheck,
    existingHashtags,
    locationId,
    onAdd,
    onClickAway,
    open,
    popperAnchorEl,
}: HashtagSetsDropdownProps): JSX.Element {
    const popperWidth = popperAnchorEl?.clientWidth;

    const handleClickAway = React.useCallback(
        (event: React.MouseEvent<Document, MouseEvent>) => {
            if (event.target instanceof Node && popperAnchorEl?.contains(event.target)) {
                return;
            }
            onClickAway?.();
        },
        [popperAnchorEl, onClickAway],
    );

    return (
        <DSPopper anchorEl={popperAnchorEl} modifiers={popperModifiers} open={open} placement="bottom" transition>
            {({ TransitionProps }) => (
                <DSGrow {...TransitionProps}>
                    <StyledContainer $width={popperWidth}>
                        <DSClickAwayListener onClickAway={handleClickAway}>
                            <div>
                                <HashtagsSelectList
                                    disabledCheck={disabledCheck}
                                    existingHashtags={existingHashtags}
                                    isShowing={open}
                                    locationId={locationId}
                                    onAdd={onAdd}
                                />
                            </div>
                        </DSClickAwayListener>
                    </StyledContainer>
                </DSGrow>
            )}
        </DSPopper>
    );
}
HashtagSetsDropdown.displayName = 'HashtagsSelect';
