import { BuilderTemplateCategoryAdminCreateUpdateDto,
    BuilderTemplateCategoryAdminDto,
    BuilderTemplateCategoryApi,
    BuilderTemplateCategoryId,
    BuilderTemplateCategorySimpleDto,
 justParams, justQuery, paramsAndBody, ClientId } from '@deltasierra/shared';


import { invokeApiRoute } from '../../common/httpUtils';
import { $httpSID } from '../../common/angularData';
import IHttpService = angular.IHttpService;
import IPromise = angular.IPromise;

export class BuilderTemplateCategoryApiClient {
    static SID = 'BuilderTemplateCategoryApiClient';

    static readonly $inject : string[] = [
        $httpSID,
    ];

    constructor(
        protected readonly $http : IHttpService,
    ) {

    }

    getActiveBuilderTemplateCategories(clientIds? : ClientId[]) : IPromise<BuilderTemplateCategorySimpleDto[]> {
        return invokeApiRoute(
            this.$http,
            BuilderTemplateCategoryApi.getAllActiveBuilderTemplateCategories,
            justQuery(clientIds !== undefined
                ? {
                    clientIds,
                }
                : {},
            ),
        );
    }

    getAllBuilderTemplateCategoriesForClient(clientId : ClientId) : IPromise<BuilderTemplateCategoryAdminDto[]> {
        return invokeApiRoute(
            this.$http,
            BuilderTemplateCategoryApi.getAllBuilderTemplateCategories,
            justParams({
                clientId,
            }),
        );
    }

    createBuilderTemplateCategory(clientId : ClientId, data : BuilderTemplateCategoryAdminCreateUpdateDto) : IPromise<BuilderTemplateCategoryAdminDto> {
        return invokeApiRoute(
            this.$http,
            BuilderTemplateCategoryApi.createBuilderTemplateCategory,
            paramsAndBody(
                {
                    clientId,
                },
                data,
            ),
        );
    }

    updateBuilderTemplateCategory(clientId : ClientId, builderTemplateCategoryId : BuilderTemplateCategoryId, data : BuilderTemplateCategoryAdminCreateUpdateDto) : IPromise<BuilderTemplateCategoryAdminDto> {
        return invokeApiRoute(
            this.$http,
            BuilderTemplateCategoryApi.updateBuilderTemplateCategory,
            paramsAndBody(
                {
                    clientId,
                    builderTemplateCategoryId,
                },
                data,
            ),
        );
    }

    deleteBuilderTemplateCategory(clientId : ClientId, builderTemplateCategoryId : BuilderTemplateCategoryId) {
        return invokeApiRoute(
            this.$http,
            BuilderTemplateCategoryApi.deleteBuilderTemplateCategory,
            justParams(
                {
                    clientId,
                    builderTemplateCategoryId,
                },
            ),
        );
    }

    reorderBuilderTemplateCategories(clientId : ClientId, categoriesOrder : BuilderTemplateCategoryId[]) {
        return invokeApiRoute(
            this.$http,
            BuilderTemplateCategoryApi.reorderBuilderTemplateCategories,
            paramsAndBody(
                {
                    clientId,
                },
                {
                    categoriesOrder,
                },
            ),
        );
    }
}

angular.module('app').service(BuilderTemplateCategoryApiClient.SID, BuilderTemplateCategoryApiClient);
