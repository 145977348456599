/// <reference path="../../../typings/browser.d.ts" />
import { AnyError } from '@deltasierra/shared';
import IPromise = angular.IPromise;

export interface FutureOptions {
    onError?: (err: AnyError) => any;
}

export enum FutureStatus {
    Pending = 'Pending',
    Running = 'Running',
    Finished = 'Finished', // Aka Succeeded
    Failed = 'Failed',
}

/**
 * A future is a stateful promise that must be invoked _after_ it's instantiated, by calling its "run()" method.
 * Its main use is to expose the state of an async task to the UI, e.g. for showing loading spinners, disabling buttons,
 *  etc.
 * The run method accepts a context object. This should contain all the data required by the action function. Avoid
 *  using "this" in your action function, as it can lead to bugs. For example:
 *  - User enters a "title" value of "foo"
 *  - User clicks submit
 *  - Action function sends "this.title" to the server
 *  - User changes the "title" to "bar" while waiting for the server response
 *  - Action function displays "bar has been updated" to the user - this should be "foo has been updated"
 * A future should never be called multiple times in parallel.
 */
export class Future<R, C, RI = R> {
    private readonly description: () => string;

    status: FutureStatus = FutureStatus.Pending;

    onError?: (err: AnyError) => any;

    promise?: IPromise<R>;

    constructor(
        private $q: ng.IQService,
        description: string | (() => string),
        private action: (context: C) => IPromise<RI>,
        options?: FutureOptions,
    ) {
        this.description = typeof description === 'string' ? () => description : description;

        if (options) {
            this.parseOptions(options);
        }
    }

    protected parseOptions(options: FutureOptions): void {
        this.onError = options.onError;
    }

    run(context: C): IPromise<R> {
        this.status = FutureStatus.Running;
        this.promise = this.$q
            .resolve()
            .then(() => this.action(context))
            .then((result: RI) => {
                this.status = FutureStatus.Finished;
                this.promise = undefined;
                return result as unknown as R;
            })
            .catch((err: AnyError) => {
                this.status = FutureStatus.Failed;
                this.promise = undefined;
                if (this.onError) {
                    return this.onError(err);
                } else {
                    return this.defaultOnError(err);
                }
            });
        return this.promise;
    }

    protected defaultOnError(err: AnyError) {
        console.log(`Failed to ${this.description()}`);
        throw err;
    }

    reset() {
        this.status = FutureStatus.Pending;
    }

    isPending() {
        return this.status == FutureStatus.Pending;
    }

    isRunning() {
        return this.status == FutureStatus.Running;
    }

    isFinished() {
        return this.status == FutureStatus.Finished;
    }

    isFailed() {
        return this.status == FutureStatus.Failed;
    }

    isFinishedOrFailed() {
        return [FutureStatus.Finished, FutureStatus.Failed].indexOf(this.status) > -1;
    }
}
