/// <reference path="../../../_references.d.ts" />
import { $modalInstanceSID } from '../../../common/angularUIBootstrapData';
import { ModalInstance } from '../../../typings/angularUIBootstrap/modalService';

export interface DuplicateToPagePromptOptions {
    pageIndex : number;
    pageCount : number;
}

export class DuplicateToPagePromptController {
    static SID = 'DuplicateToPagePromptController';

    static readonly $inject : string[] = [
        $modalInstanceSID,
        'options',
    ];

    public pageNumber : number;

    public pageCount : number;

    constructor(
        public $modalInstance : ModalInstance,
        options : DuplicateToPagePromptOptions,
    ) {
        this.pageNumber = (options.pageIndex || 0) + 1;
        this.pageCount = options.pageCount;
    }

    accept() {
        this.$modalInstance.close(this.pageNumber - 1);
    }

    cancel() {
        this.$modalInstance.dismiss();
    }
}

angular.module('app').controller(DuplicateToPagePromptController.SID, DuplicateToPagePromptController);
