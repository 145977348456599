/* eslint-disable sort-keys */
/// <reference path="../../../typings/browser.d.ts" />
import moment from 'moment-timezone';
import { linkToGallery, linkToTemplate, linkToPlannerCalendar, linkToPlannerEvent, ClientId, LocationId, BuilderDocumentFormat, INTRO_CSS_SELECTORS, isNotNullOrUndefined } from '@deltasierra/shared';


import { MvIdentity } from '../account/mvIdentity';
import { I18nService } from '../i18n/i18nService';
import { IntroOptions } from './introWrapper';
import { Intro } from './introService';

/**
 * Remember to update the E2E tests with any new intros, steps, pages, CSS selectors, etc.
 */
export class IntroDefinitionService {
    public static SID = 'IntroDefinitionService';

    public readonly videoIntro: Intro = {
        name: 'video',
        title: this.i18nService.text.intro.video.title(),
        pages: [
            {
                options: {
                    steps: [],
                },
                route: '/intro',
            },
        ],
        completionPage: null,
        cannotBeRestarted: true,
    };

    public readonly appsIntro: Intro = {
        name: 'apps',
        title: this.i18nService.text.intro.apps.title(),
        pages: [
            {
                options: this.createOptions({
                    steps: [
                        {
                            element: INTRO_CSS_SELECTORS.navbar.profileDropdownToggle,
                            intro: this.i18nService.text.intro.apps.page.one.step.one(),
                        },
                        {
                            element: INTRO_CSS_SELECTORS.navbar.profileDropdownToggle,
                            intro: this.i18nService.text.intro.apps.page.two.step.one(),
                        },
                        {
                            element: INTRO_CSS_SELECTORS.navbar.profileDropdownToggle,
                            intro: this.i18nService.text.intro.apps.page.two.step.two(),
                        },
                    ],
                    highlightClass: 'intro-highlight-hide',
                    doneLabel: this.i18nService.text.intro.apps.page.two.doneLabel(),
                }),
                route: '/',
            },
        ],
        completionPage: {
            heading: this.i18nService.text.intro.apps.complete.heading(),
            subheading: this.i18nService.text.intro.apps.complete.subheading(),
            tip: this.i18nService.text.intro.apps.complete.tip(),
        },
    };

    public readonly planIntro: Intro = {
        name: 'plan',
        title: this.i18nService.text.intro.plan.title(),
        pages: [
            {
                options: this.createOptions({
                    steps: [
                        {
                            element: INTRO_CSS_SELECTORS.navbar.plan,
                            intro: this.i18nService.text.intro.plan.page.one.step.one(),
                        },
                    ],
                    highlightClass: 'intro-highlight-hide',
                    doneLabel: this.i18nService.text.intro.plan.page.one.doneLabel(),
                }),
                route: '/',
            },
            {
                options: this.createOptions({
                    steps: [
                        { intro: this.i18nService.text.intro.plan.page.two.step.one() },
                        {
                            element: INTRO_CSS_SELECTORS.plan.calendar.calendarLegend,
                            intro: this.i18nService.text.intro.plan.page.two.step.two(),
                        },
                        {
                            element: INTRO_CSS_SELECTORS.plan.calendar.legendEntryCalDefault,
                            intro: this.i18nService.text.intro.plan.page.two.step.three(),
                        },
                        {
                            element: INTRO_CSS_SELECTORS.plan.calendar.legendEntryCalDanger,
                            intro: this.i18nService.text.intro.plan.page.two.step.four(),
                        },
                        {
                            element: INTRO_CSS_SELECTORS.plan.calendar.legendEntryCalWarning,
                            intro: this.i18nService.text.intro.plan.page.two.step.five(),
                        },
                        {
                            element: INTRO_CSS_SELECTORS.plan.calendar.legendEntryCalSuccess,
                            intro: this.i18nService.text.intro.plan.page.two.step.six(),
                        },
                        {
                            element: INTRO_CSS_SELECTORS.plan.calendar.legendEntryCalSupported,
                            intro: this.i18nService.text.intro.plan.page.two.step.seven(),
                        },
                        {
                            position: 'top',
                            element: INTRO_CSS_SELECTORS.plan.calendar.dateCell,
                            intro: this.i18nService.text.intro.plan.page.two.step.eight(),
                        },
                    ],
                    disableInteraction: false,
                    doneLabel: this.i18nService.text.intro.plan.page.two.doneLabel(),
                }),
                route: linkToPlannerCalendar(this.getNextMonthDateAsString()),
            },
            {
                options: this.createOptions({
                    steps: [
                        {
                            element: INTRO_CSS_SELECTORS.plan.plannerEvent.title,
                            intro: this.i18nService.text.intro.plan.page.three.step.one(),
                        },
                        {
                            element: INTRO_CSS_SELECTORS.plan.plannerEvent.activity,
                            intro: this.i18nService.text.intro.plan.page.three.step.two(),
                        },
                        {
                            element: INTRO_CSS_SELECTORS.plan.plannerEvent.text,
                            intro: this.i18nService.text.intro.plan.page.three.step.three(),
                        },
                        {
                            element: INTRO_CSS_SELECTORS.plan.plannerEvent.upload,
                            intro: this.i18nService.text.intro.plan.page.three.step.four(),
                        },
                        {
                            element: INTRO_CSS_SELECTORS.plan.plannerEvent.buttonContentRequired,
                            intro: this.i18nService.text.intro.plan.page.three.step.five(),
                        },
                        {
                            element: INTRO_CSS_SELECTORS.plan.plannerEvent.buttonBuildLater,
                            intro: this.i18nService.text.intro.plan.page.three.step.six(),
                        },
                        {
                            element: INTRO_CSS_SELECTORS.plan.plannerEvent.buttonBuildNow,
                            intro: this.i18nService.text.intro.plan.page.three.step.seven(),
                        },
                    ],
                    doneLabel: this.i18nService.text.intro.plan.page.three.doneLabel(),
                }),
                route: linkToPlannerEvent(
                    ClientId.from(321),
                    LocationId.from(654),
                    this.getNextMonthDateAsString(),
                    987,
                ),
            },
        ],
        completionPage: {
            heading: this.i18nService.text.intro.plan.complete.heading(),
            subheading: this.i18nService.text.intro.plan.complete.subheading(),
            tip: this.i18nService.text.intro.plan.complete.tip(),
        },
    };

    public readonly buildIntro: Intro = {
        name: 'build',
        title: this.i18nService.text.intro.build.title(),
        pages: [
            {
                options: this.createOptions({
                    steps: [
                        {
                            element: INTRO_CSS_SELECTORS.navbar.build,
                            intro: this.i18nService.text.intro.build.page.one.step.one(),
                        },
                        {
                            element: INTRO_CSS_SELECTORS.navbar.plan,
                            intro: this.i18nService.text.intro.build.page.one.step.two(),
                        },
                    ],
                    highlightClass: 'intro-highlight-hide',
                    doneLabel: this.i18nService.text.intro.build.page.one.doneLabel(),
                }),
                route: '/',
            },
            {
                options: this.createOptions({
                    steps: [
                        { intro: this.i18nService.text.intro.build.page.two.step.one() },
                        {
                            position: 'top',
                            element: INTRO_CSS_SELECTORS.plan.calendar.dateCell,
                            intro: this.i18nService.text.intro.build.page.two.step.two(),
                        },
                    ],
                    disableInteraction: false,
                    doneLabel: this.i18nService.text.intro.build.page.two.doneLabel(),
                }),
                route: linkToPlannerCalendar(this.getNextMonthDateAsString()),
            },
            {
                options: this.createOptions({
                    steps: [
                        {
                            element: INTRO_CSS_SELECTORS.plan.plannerEvent.buttonBuildNow,
                            intro: this.i18nService.text.intro.build.page.three.step.one(),
                        },
                    ],
                    disableInteraction: true,
                    doneLabel: this.i18nService.text.intro.build.page.three.doneLabel(),
                }),
                route: linkToPlannerEvent(
                    ClientId.from(123),
                    LocationId.from(456),
                    this.getNextMonthDateAsString(),
                    789,
                ),
            },
            {
                options: this.createOptions({
                    steps: [
                        { intro: this.i18nService.text.intro.build.page.four.step.one() },
                        {
                            element: INTRO_CSS_SELECTORS.build.gallery.plannersReadyToBuildBar,
                            intro: this.i18nService.text.intro.build.page.four.step.two(),
                        },
                        {
                            element: INTRO_CSS_SELECTORS.build.gallery.templateThumbnail,
                            intro: this.i18nService.text.intro.build.page.four.step.three(),
                        },
                    ],
                    disableInteraction: false,
                    doneLabel: this.i18nService.text.intro.build.page.four.doneLabel(),
                }),
                route: linkToGallery(),
            },
            {
                options: this.createOptions({
                    steps: [
                        {
                            element: INTRO_CSS_SELECTORS.build.contentBuilder.textarea,
                            intro: this.i18nService.text.intro.build.page.five.step.one(),
                        },
                        {
                            element: INTRO_CSS_SELECTORS.build.contentBuilder.buttonSchedule,
                            intro: this.i18nService.text.intro.build.page.five.step.two(),
                        },
                        {
                            position: 'left',
                            intro: this.i18nService.text.intro.build.page.five.step.three(),
                        },
                    ],
                    disableInteraction: false,
                    doneLabel: this.i18nService.text.intro.build.page.five.doneLabel(),
                }),
                route: linkToTemplate(BuilderDocumentFormat.content, 1),
            },
        ],
        completionPage: {
            heading: this.i18nService.text.intro.build.complete.heading(),
            subheading: this.i18nService.text.intro.build.complete.subheading(),
            tip: this.i18nService.text.intro.build.complete.tip(),
        },
        accessCheck: () => this.mvIdentity.isAdmin() || this.mvIdentity.isManager() || this.mvIdentity.isBuilder(),
    };

    public readonly reportIntro: Intro = {
        name: 'report',
        title: this.i18nService.text.intro.report.title(),
        pages: [
            {
                options: this.createOptions({
                    steps: [
                        {
                            element: INTRO_CSS_SELECTORS.navbar.report,
                            intro: this.i18nService.text.intro.report.page.one.step.one(),
                        },
                    ],
                    highlightClass: 'intro-highlight-hide',
                    doneLabel: this.i18nService.text.intro.report.page.one.doneLabel(),
                }),
                route: '/',
            },
            {
                options: this.createOptions({
                    steps: [
                        {
                            position: 'top',
                            element: INTRO_CSS_SELECTORS.report.startDate,
                            intro: this.i18nService.text.intro.report.page.two.step.one(),
                        },
                        {
                            position: 'top',
                            element: INTRO_CSS_SELECTORS.report.endDate,
                            intro: this.i18nService.text.intro.report.page.two.step.two(),
                        },
                        {
                            element: INTRO_CSS_SELECTORS.report.buttonSearch,
                            intro: this.i18nService.text.intro.report.page.two.step.three(),
                        },
                        { intro: this.i18nService.text.intro.report.page.two.step.four() },
                    ],
                    disableInteraction: false,
                    doneLabel: this.i18nService.text.intro.report.page.two.doneLabel(),
                }),
                route: '/reports',
            },
        ],
        completionPage: {
            heading: this.i18nService.text.intro.report.complete.heading(),
            subheading: this.i18nService.text.intro.report.complete.subheading(),
            tip: this.i18nService.text.intro.report.complete.tip(),
        },
        accessCheck: () => this.mvIdentity.isAdmin() || this.mvIdentity.isManager() || this.mvIdentity.isAnalyst(),
    };

    public readonly learnIntro: Intro = {
        name: 'learn',
        title: this.i18nService.text.intro.learn.title(),
        pages: [
            {
                options: this.createOptions({
                    steps: [
                        {
                            element: INTRO_CSS_SELECTORS.navbar.learn,
                            intro: this.i18nService.text.intro.learn.page.one.step.one(),
                        },
                    ],
                    highlightClass: 'intro-highlight-hide',
                    doneLabel: this.i18nService.text.intro.learn.page.one.doneLabel(),
                }),
                route: '/',
            },
            {
                options: this.createOptions({
                    steps: [
                        {
                            element: INTRO_CSS_SELECTORS.learn.pageTitle,
                            intro: this.i18nService.text.intro.learn.page.two.step.one(),
                        },
                        {
                            element: INTRO_CSS_SELECTORS.learn.searchText,
                            intro: this.i18nService.text.intro.learn.page.two.step.two(),
                        },
                    ],
                    highlightClass: 'intro-highlight-hide',
                    doneLabel: this.i18nService.text.intro.learn.page.two.doneLabel(),
                }),
                route: '/trythis',
            },
        ],
        completionPage: {
            heading: this.i18nService.text.intro.learn.complete.heading(),
            subheading: this.i18nService.text.intro.learn.complete.subheading(),
            tip: this.i18nService.text.intro.learn.complete.tip(),
        },
    };

    private readonly DEFAULT_OPTIONS: Readonly<IntroOptions> = Object.freeze({
        disableInteraction: true,
        doneLabel: this.i18nService.text.intro.doneLabel(),
        exitOnOverlayClick: false,
        keyboardNavigation: false,
        overlayOpacity: 0.2,
        showStepNumbers: false,
        skipLabel: this.i18nService.text.intro.skipLabel(),
        steps: [],
    });

    private readonly intros: Intro[] = [
        this.videoIntro,
        this.appsIntro,
        this.planIntro,
        this.buildIntro,
        this.reportIntro,
        this.learnIntro,
    ];

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public static readonly $inject: string[] = [I18nService.SID, MvIdentity.SID];

    public constructor(private i18nService: I18nService, private mvIdentity: MvIdentity) {}

    public getIntros(): Intro[] {
        return this.intros;
    }

    private getNextMonthDateAsString(): string {
        return moment().startOf('month').add(1, 'months').format('YYYY-MM-DD');
    }

    private createOptions(options: IntroOptions): IntroOptions {
        return {
            ...this.DEFAULT_OPTIONS,
            ...options,
            showBullets: isNotNullOrUndefined(options.showBullets)
                ? options.showBullets
                : options && options.steps && options.steps.length > 1,
        };
    }
}

angular.module('app').service(IntroDefinitionService.SID, IntroDefinitionService);
