import { DSFormGroup, DSFormLabel, Loading, Translate } from '@deltasierra/components';
import { FormikProps } from 'formik';
import * as React from 'react';
import { FacebookFormHashtagsSection } from '../../../facebook/FacebookFormHashtagsSection';
import { InstagramDirectFormHashtagsSection } from '../../../instagramDirect/InstagramDirectFormHashtagsSection';
import { FacebookAndInstagramFormValues } from '../facebook-and-instagram-form-values';
import { FacebookAndInstagramHashtagsSectionInner } from './FacebookAndInstagramHashtagsSectionInner';

export type FacebookAndInstagramHashtagsSectionProps = {
    availableCaptionLength: number;
    availableFirstCommentLength: number;
    formik: FormikProps<FacebookAndInstagramFormValues>;
    locationId: string;
    locationIds: string[];
    requiredHashtags: string[];
    requiredHashtagsLoading: boolean;
};

export function FacebookAndInstagramHashtagsSection({
    availableCaptionLength,
    availableFirstCommentLength,
    formik,
    locationId,
    locationIds,
    requiredHashtags,
    requiredHashtagsLoading,
}: FacebookAndInstagramHashtagsSectionProps): JSX.Element {
    const isFeatureEnabledForFacebook = FacebookFormHashtagsSection.useIsFacebookFirstCommentHashtagsEnabled();
    const isFeatureEnabledForInstagram = InstagramDirectFormHashtagsSection.useIsInstagramFirstCommentHashtagsEnabled();

    const hasRequiredPermissionsForFacebook =
        FacebookFormHashtagsSection.useCheckFacebookFirstCommentPermissions(locationIds);
    const hasRequiredPermissionsForInstagram =
        InstagramDirectFormHashtagsSection.useCheckInstagramFirstCommentPermissions(locationIds);

    if (!(isFeatureEnabledForFacebook && isFeatureEnabledForInstagram)) {
        return <></>;
    }

    const availableCharacterLength =
        formik.values.hashtagsInsertType === 'caption' ? availableCaptionLength : availableFirstCommentLength;

    return (
        <DSFormGroup>
            <DSFormLabel>
                <Translate keyId="BUILD.PUBLISH.MULTIPLE_PLATFORMS.INPUT_LABELS.HASHTAGS" />
            </DSFormLabel>
            {hasRequiredPermissionsForFacebook.loading || hasRequiredPermissionsForInstagram.loading ? (
                <Loading inline size="small" />
            ) : (
                <>
                    {!hasRequiredPermissionsForFacebook.hasAtLeastOneWithPermission &&
                    !hasRequiredPermissionsForInstagram.hasAtLeastOneWithPermission ? (
                        <>
                            <FacebookFormHashtagsSection.MissingAllRequiredPermissionForFirstCommentMessage />
                            <InstagramDirectFormHashtagsSection.MissingAllRequiredPermissionForFirstCommentMessage />
                        </>
                    ) : (
                        <>
                            <FacebookAndInstagramHashtagsSectionInner
                                availableCharacterLength={availableCharacterLength}
                                formik={formik}
                                locationId={locationId}
                                locationIds={locationIds}
                                requiredHashtags={requiredHashtags}
                                requiredHashtagsLoading={requiredHashtagsLoading}
                            />
                            {hasRequiredPermissionsForFacebook.areSomeWithoutPermission && (
                                <FacebookFormHashtagsSection.MissingSomeRequiredPermissionForFirstCommentMessage />
                            )}
                            {hasRequiredPermissionsForInstagram.areSomeWithoutPermission && (
                                <InstagramDirectFormHashtagsSection.MissingSomeRequiredPermissionForFirstCommentMessage />
                            )}
                        </>
                    )}
                </>
            )}
        </DSFormGroup>
    );
}
FacebookAndInstagramHashtagsSection.displayName = 'FacebookAndInstagramHashtagsSection';
