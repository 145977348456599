/// <reference path="../../../../typings/browser.d.ts" />
import { ShoppingCartBadgerController } from './controller';

const shoppingCartBadgerDirectiveName = 'dsShoppingCartBadger';

function ShoppingCartBadgerDirective(): ng.IDirective<ng.IScope> {
    return {
        templateUrl: '/partials/shoppingCart/shoppingCartBadger/template',
        controller: ShoppingCartBadgerController,
        controllerAs: 'ctrl',
        bindToController: true,
        scope: {},
        replace: true,
        restrict: 'E',
    };
}

angular.module('app').directive(shoppingCartBadgerDirectiveName, [ShoppingCartBadgerDirective]);

