/// <reference path="../../../../../typings/browser.d.ts" />
import { SectionType, TextSection as ITextSection } from '@deltasierra/shared';
import { Section } from './section';

export class TextSection extends Section implements ITextSection {
    type!: SectionType.text;

    html = '';

    public constructor(id: number) {
        super(id, SectionType.text);
    }
}
