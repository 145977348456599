import { InAppNotification, inAppNotificationSocketEvent } from '@deltasierra/shared';
import { InAppNotificationApiClient } from '../inAppNotificationApiClient';
import { InteractionUtils } from '../../common/interactionUtils';
import { SocketService } from '../../sockets/socketService';
import {
    $locationSID,
    $rootScopeSID,
    $scopeSID,
    $timeoutSID,
    $windowSID,
    ILifecycleHooks,
} from '../../common/angularData';
import { I18nService } from '../../i18n/i18nService';
import { BaseInAppNotificationsController } from '../baseInAppNotificationController';
import { DataUtils } from '../../common/dataUtils';
import { BrowserNotificationService } from '../browserNotificationService';
import { MvIdentity } from '../../account/mvIdentity';
import IScope = angular.IScope;
import IWindowService = angular.IWindowService;
import IPromise = angular.IPromise;
import IRootScopeService = angular.IRootScopeService;
import ITimeoutService = angular.ITimeoutService;
import ILocationService = angular.ILocationService;
import IAngularEvent = angular.IAngularEvent;

const REMOVE_ALERT_DELAY = 15000;

interface AlertInAppNotification extends InAppNotification {
    removeAlertTimeoutPromise? : IPromise<void>;
}

export class InAppNotificationsAlertController extends BaseInAppNotificationsController implements ILifecycleHooks {
    public static SID = 'InAppNotificationsAlertController';

    notifications : AlertInAppNotification[] = [];

    protected readonly socketHandler = (notification : InAppNotification) => {
        this.$scope.$apply(() => {
            this.showAlert(notification);
        });
    };

    // Tslint:disable-next-line:member-ordering
    static readonly $inject : string[] = [
        $windowSID,
        $locationSID,
        $rootScopeSID,
        $scopeSID,
        $timeoutSID,
        I18nService.SID,
        InteractionUtils.SID,
        DataUtils.SID,
        SocketService.SID,
        InAppNotificationApiClient.SID,
        BrowserNotificationService.SID,
        MvIdentity.SID,
    ];

    constructor(
        protected readonly $window : IWindowService,
        protected readonly $location : ILocationService,
        $rootScope : IRootScopeService,
        $scope : IScope,
        protected readonly $timeout : ITimeoutService,
        i18nService : I18nService,
        interactionUtils : InteractionUtils,
        protected readonly dataUtils : DataUtils,
        protected readonly socketService : SocketService,
        inAppNotificationApiClient : InAppNotificationApiClient,
        protected readonly browserNotificationService : BrowserNotificationService,
        protected readonly mvIdentity: MvIdentity,
    ) {
        super($rootScope, $scope, i18nService, interactionUtils, inAppNotificationApiClient, mvIdentity);
    }

    $onInit() {
        this.listenToMarkedAsReadEvent((event, notification) => this.removeRealtimeNotification(notification.id));
        this.socketService.on<InAppNotification>(inAppNotificationSocketEvent, this.socketHandler);
    }

    $onDestroy() {
        this.socketService.off<InAppNotification>(inAppNotificationSocketEvent, this.socketHandler);
    }

    protected showAlert(notification : AlertInAppNotification) : void {
        this.notifications.push(notification);
        this.scheduleRemoval(notification);
        if (!document.hasFocus()) {
            this.browserNotificationService.notify('Digital Stack', {
                body: this.translateMessage(notification),
                icon: '/img/logo-small.png',
                tag: `in-app-notification-${notification.id}`,
                clickHandler: (event, browserNotification) => this.$scope.$apply(() => this.clickBrowserNotification(notification)),
            });
        }
    }

    protected clickBrowserNotification(notification : InAppNotification) {
        this.markAsRead(notification);
        if (notification.link) {
            this.markAsClicked(notification);
            if (notification.link.lastIndexOf('http', 0) === 0) {
                this.$window.open(notification.link);
            } else {
                this.$location.url(notification.link);
            }
        }
    }

    protected scheduleRemoval(notification : AlertInAppNotification) : void {
        notification.removeAlertTimeoutPromise = this.$timeout(() => {
            this.removeRealtimeNotification(notification.id);
        }, REMOVE_ALERT_DELAY);
    }

    removeRealtimeNotification(notificationId : number) : void {
        const index = this.dataUtils.indexOfBy('id', this.notifications, notificationId);
        if (index > -1) {
            this.notifications.splice(index, 1);
        }
    }

    clickMarkAsRead(event : IAngularEvent, notification : InAppNotification) : void {
        event.preventDefault();
        this.removeRealtimeNotification(notification.id);
        this.markAsRead(notification);
    }

    clickDismiss(event : IAngularEvent, notification : InAppNotification) : void {
        event.preventDefault();
        this.removeRealtimeNotification(notification.id);
    }

    onClickNotification(notification : InAppNotification) {
        this.removeRealtimeNotification(notification.id);
        this.markAsRead(notification);
        if (notification.type !== 'Agency.CustomMessage') {
            this.markAsClicked(notification);
        }
    }

    handleMouseEnter(notification : AlertInAppNotification) : void {
        if (notification.removeAlertTimeoutPromise) {
            this.$timeout.cancel(notification.removeAlertTimeoutPromise);
            notification.removeAlertTimeoutPromise = undefined;
        }
    }

    handleMouseLeave(notification : AlertInAppNotification) : void {
        if (!notification.removeAlertTimeoutPromise) {
            this.scheduleRemoval(notification);
        }
    }
}
