import * as React from 'react';
import Modal, { ICustomModalStyle } from '@bdenzer/react-modal';

interface ModalState {
    shouldShowModal: boolean;
}

interface ModalProps {
    buttonDisabled: boolean;
    buttonText: string;
    children?: React.ReactNode;
    modalTitle: string;
}

export class BasicModal extends React.Component<ModalProps, ModalState> {
    constructor(props: ModalProps) {
        super(props);
        this.state = {
            shouldShowModal: false,
        };
        this.closeModal = this.closeModal.bind(this);
        this.openModal = this.openModal.bind(this);
    }

    public render(): JSX.Element {
        // TODO: May want to style these all via stylus instead
        // https://github.com/ben-denzer/react-modal#example---adding-custom-styles-in-typescript
        const modalStyle: ICustomModalStyle = {
            animationTime: 200,
            closeButtonText: {
                color: '#aaa',
                fontWeight: 500,
                fontSize: '17px',
            },
            closeButtonHover: {
                color: '#aaa',
            },
            modalBackground: {
                transition: 'none',
            },
            modalInner: {
                maxWidth: '600px',
                borderRadius: '6px',
            },
            modalHeader: {
                borderTopLeftRadius: '6px',
                borderTopRightRadius: '6px',
                borderBottom: '1px solid #e5e5e5',
                fontSize: '24px',
                marginBottom: '10px',
                fontWeight: 300,
                backgroundColor: '#fff',
            },
            modalTitle: {
                color: '#b82f81',
            },
        };
        return (
            <div>
                <Modal
                    closeModal={() => this.closeModal()}
                    customStyle={modalStyle}
                    shouldShowModal={this.state.shouldShowModal}
                    title={this.props.modalTitle}
                >
                    {this.props.children}
                </Modal>
                <button className="btn btn-success" disabled={this.props.buttonDisabled} onClick={() => this.openModal()}>
                    {this.props.buttonText}
                </button>
            </div>
        );
    }

    private closeModal(): void {
        this.setState({ shouldShowModal: false });
    }

    private openModal(): void {
        this.setState({ shouldShowModal: true });
    }
}

export default BasicModal;
