function efp(x: number, y: number) {
    return document.elementFromPoint(x, y);
}

/**
 * Check if the given element is within view of the browser window.
 *
 * @see Taken from https://stackoverflow.com/a/15203639
 * @param el - The element to check
 * @returns Whether or not the element is viewable
 */
export function isElementVisible(el: Element) {
    // TODO: Improve this by using the Intersection Observer API
    // @see https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
    const doc = document;
    const rect = el.getBoundingClientRect();
    const vWidth = window.innerWidth || doc.documentElement.clientWidth;
    const vHeight = window.innerHeight || doc.documentElement.clientHeight;

    // Return false if it's not in the viewport
    if (rect.right < 0 || rect.bottom < 0 || rect.left > vWidth || rect.top > vHeight) {
        return false;
    }

    // Return true if any of its four corners or the center point are visible
    return (
        el.contains(efp(rect.left, rect.top)) ||
        el.contains(efp(rect.right, rect.top)) ||
        el.contains(efp(rect.right, rect.bottom)) ||
        el.contains(efp(rect.left, rect.bottom)) ||
        el.contains(efp(rect.right - rect.width / 2, rect.bottom - rect.height / 2))
    );
}
