/// <reference path="../../_references.d.ts" />

import { OptionalStringBinding, StringBinding } from '../angularData';

export function HintDirective(): ng.IDirective<ng.IScope> {
    return {
        link: (scope, element) => {
            // Angular-UI/bootstrap fails to correctly the position the popover when it appears near the edge of the page
            // So we'll try to reposition it

            const getBounds = (el: ng.IAugmentedJQuery) => {
                const offset = el.offset()!;
                return {
                    height: el.outerHeight(),
                    width: el.outerWidth(),
                    x: offset.left,
                    y: offset.top,
                };
            };

            const repositionHint = (): void => {
                const hintElement = angular.element('body .popover:last-of-type');
                if (!hintElement.length) {
                    setTimeout(() => repositionHint(), 100);
                    return;
                }

                const hintBounds = getBounds(hintElement);
                const elementBounds = getBounds(element);

                // Dirty hack, the position calculation is not correct, not accounting for something
                // Just doing this hard-coded adjustment for now (yuck)
                const ADDITIONAL_HORIZONTAL_OFFSET = 3; // This might be to do with box shadow or something? Hmm not sure...
                const VERTICAL_SPACING = 8;

                hintElement.offset({
                    left: Math.round(elementBounds.x + elementBounds.width / 2 - hintBounds.width / 2) + ADDITIONAL_HORIZONTAL_OFFSET,
                    top: Math.round(elementBounds.y - hintBounds.height) - VERTICAL_SPACING,
                });
            };

            const mouseEnter = () => {
                // Hacky, don't know when to reposition exactly, probably could work it out with enough time...
                setTimeout(() => {
                    repositionHint();
                }, 10);

                setTimeout(() => {
                    repositionHint();
                }, 200);
            };

            const MOUSE_ENTER_EVENT = 'mouseenter';

            element.on(MOUSE_ENTER_EVENT, mouseEnter);

            scope.$on('$destroy', () => {
                element.off(MOUSE_ENTER_EVENT, mouseEnter);
            });
        },
        restrict: 'E',
        scope: {
            hintText: StringBinding,
            hintTitle: OptionalStringBinding,
        },
        templateUrl: '/partials/common/hint/template',
    };
}

export const dsHintSID = 'dsHint';

angular.module('app').directive(dsHintSID, [HintDirective]);
