/// <reference path="../../../../typings/browser.d.ts" />
import { InAppNotificationsAlertController } from './controller';

const inAppNotificationsAlertDirectiveName = 'dsInAppNotificationsAlert';

function InAppNotificationsAlertDirective(): ng.IDirective<ng.IScope> {
    return {
        templateUrl: '/partials/inAppNotifications/alert/template',
        controller: InAppNotificationsAlertController,
        controllerAs: 'ctrl',
        bindToController: true,
        scope: {},
        replace: false,
        restrict: 'E',
    };
}

angular.module('app').directive(inAppNotificationsAlertDirectiveName, [InAppNotificationsAlertDirective]);
