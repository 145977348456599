import JSZip from 'jszip';
import { Untyped } from '@deltasierra/shared';
import { fileReaderServiceSID, IFileReaderService } from './fileReaderService';

export class ZipService {
    public static SID = 'zipService';

    public static $inject = [fileReaderServiceSID];

    public constructor(private fileReaderService: IFileReaderService) {}

    public async readZip(file: File): Promise<JSZip> {
        return this.fileReaderService
            .readFileAsArrayBuffer(file)
            .then(async (arrayBuffer: Untyped) => JSZip.loadAsync(arrayBuffer));
    }
}

angular.module('app').service(ZipService.SID, ZipService);
