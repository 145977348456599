import * as React from 'react';
import ThumbnailAiSvg from '../../../img/thumbnail-ai.svg';
import ThumbnailAviSvg from '../../../img/thumbnail-avi.svg';
import ThumbnailCsvSvg from '../../../img/thumbnail-csv.svg';
import ThumbnailDocSvg from '../../../img/thumbnail-doc.svg';
import ThumbnailEpsSvg from '../../../img/thumbnail-eps.svg';
import ThumbnailGifSvg from '../../../img/thumbnail-gif.svg';
import ThumbnailHtmlSvg from '../../../img/thumbnail-html.svg';
import ThumbnailJpgSvg from '../../../img/thumbnail-jpg.svg';
import ThumbnailMp3Svg from '../../../img/thumbnail-mp3.svg';
import ThumbnailOtherSvg from '../../../img/thumbnail-other.svg';
import ThumbnailPdfSvg from '../../../img/thumbnail-pdf.svg';
import ThumbnailPngSvg from '../../../img/thumbnail-png.svg';
import ThumbnailPptSvg from '../../../img/thumbnail-ppt.svg';
import ThumbnailPsdSvg from '../../../img/thumbnail-psd.svg';
import ThumbnailTxtSvg from '../../../img/thumbnail-txt.svg';
import ThumbnailWavSvg from '../../../img/thumbnail-wav.svg';
import ThumbnailXlsSvg from '../../../img/thumbnail-xls.svg';
import ThumbnailZipSvg from '../../../img/thumbnail-zip.svg';

const THUMBNAILS: { [key: string]: string } = {
    'application/illustrator': ThumbnailAiSvg,
    'application/msword': ThumbnailDocSvg,
    'application/pdf': ThumbnailPdfSvg,
    'application/postscript': ThumbnailEpsSvg,
    'application/vnd.ms-excel': ThumbnailXlsSvg,
    'application/vnd.ms-powerpoint': ThumbnailPptSvg,
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': ThumbnailPptSvg,
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ThumbnailXlsSvg,
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ThumbnailDocSvg,
    'application/zip': ThumbnailZipSvg,
    'audio/mpeg3': ThumbnailMp3Svg,
    'audio/wav': ThumbnailWavSvg,
    'image/gif': ThumbnailGifSvg,
    'image/jpeg': ThumbnailJpgSvg,
    'image/png': ThumbnailPngSvg,
    'image/vnd.adobe.photoshop': ThumbnailPsdSvg,
    'text/csv': ThumbnailCsvSvg,
    'text/html': ThumbnailHtmlSvg,
    'text/plain': ThumbnailTxtSvg,
    'video/avi': ThumbnailAviSvg,
};

export const getFileTypeIconImgPath = (mimeType: string): string => mimeType in THUMBNAILS ? THUMBNAILS[mimeType] : ThumbnailOtherSvg;

export type FileTypeIconProps = Exclude<React.ImgHTMLAttributes<any>, 'src'> & {
    mimeType: string;
};

export const FileTypeIcon: React.FunctionComponent<FileTypeIconProps> = ({ mimeType, ...props }) => (
    <img {...props} src={getFileTypeIconImgPath(mimeType)} />
);
