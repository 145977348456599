import * as React from 'react';
import { ThemeProvider } from '@deltasierra/components';
import { withAngularIntegration } from '../../../../common/componentUtils/reactComponentRegistration';
import { BlockedPublishesWell } from './BlockedPublishesWell';
import { FailedPublishesWell } from './FailedPublishesWell';

function AdminServicingPage(): JSX.Element {
    return (
        <ThemeProvider>
            <BlockedPublishesWell />
            <FailedPublishesWell />
        </ThemeProvider>
    );
}
AdminServicingPage.displayName = 'AdminServicingPage';

export const AdminServicingPageAngular = withAngularIntegration(AdminServicingPage, 'adminServicingPage', {});
