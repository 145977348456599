/// <reference path="../../../../../typings/browser.d.ts" />
import {
    AssignedLocation,
    BuilderTemplateId,
    GalleryPlannerDetails,
    IProcessVideoProgress,
    IPublishVideoRequest,
    ISize,
    JobFailure,
    Result,
    resultFailure,
    resultSuccess,
    ServerError,
    Untyped,
    WorkflowStartResult,
} from '@deltasierra/shared';
import type { IHttpPromiseCallbackArg, IPromise, IQService } from 'angular';
import { InteractionUtils } from '../../../common/interactionUtils';
import { VideoPublishService } from '../VideoPublishService';
import { $qSID, $scopeSID, $timeoutSID, ILifecycleHooks } from '../../../common/angularData';
import { baseVideoPublishErrorHandler } from '../common/video';
import { I18nService } from '../../../i18n/i18nService';
import { UploadContext } from '../../../common/uploadService';
import { ContentBuilder } from '../../contentBuilder';
import { MvNotifier } from '../../../common/mvNotifier';

export type PublishVideoResult = Result<Error | JobFailure | string | undefined, { outputVideoUrl: string }>;
export type VideoFinishCallback = (result: PublishVideoResult) => void;

interface VideoProcessingContext {
    fileName: string | null;
    isProcessingOnServer: boolean;
    timeMark: number;
    uploadContext: UploadContext;
    videoDuration: number;
}

export interface PublishVideoControllerScope extends angular.IScope {
    videoProcessingContext: VideoProcessingContext;
}

export enum PublishStatus {
    pending = 'pending',
    publishing = 'publishing',
    finished = 'finished',
    errored = 'errored',
}

export class PublishVideoController implements ILifecycleHooks {
    public static SID = 'PublishVideoController';

    public contentBuilder!: ContentBuilder;

    public templateId!: BuilderTemplateId;

    public location!: AssignedLocation;

    public plannerDetails!: GalleryPlannerDetails;

    public channelData: Untyped;

    public onPublishVideo!: (locals: { request: IPublishVideoRequest<any> }) => IPromise<WorkflowStartResult>;

    public onFinish!: (locals: { result: PublishVideoResult }) => void;

    public outputDimensions!: ISize;

    public status: PublishStatus = PublishStatus.pending;

    public isPublishing = false;

    public outputVideoUrl?: string;

    public jobError: Error | JobFailure | string | undefined;

    public submitPublish = this.interactionUtils.createFuture<void, Record<string, unknown>>(
        this.i18nService.text.build.publish.publishVideoAction(),
        (context): IPromise<void> => this.doPublish(),
    );

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public static readonly $inject: string[] = [
        $qSID,
        MvNotifier.SID,
        InteractionUtils.SID,
        VideoPublishService.SID,
        I18nService.SID,
        $scopeSID,
        $timeoutSID,
    ];

    // eslint-disable-next-line max-params
    public constructor(
        protected $q: IQService,
        public mvNotifier: MvNotifier,
        protected interactionUtils: InteractionUtils,
        protected videoPublishService: VideoPublishService,
        public i18nService: I18nService,
        private readonly $scope: PublishVideoControllerScope,
        private readonly $timeout: angular.ITimeoutService,
    ) {
        this.$scope.videoProcessingContext = {
            fileName: null,
            isProcessingOnServer: false,
            timeMark: 0,
            uploadContext: new UploadContext(),
            videoDuration: 0,
        };
    }

    public $onInit(): IPromise<void> {
        return this.publish();
    }

    public publish(): IPromise<void> {
        this.jobError = undefined;
        return this.submitPublish.run({});
    }

    public cancel(): void {
        this.onFinish({ result: resultFailure(this.jobError) });
    }

    public clickPublish(): angular.IPromise<void> {
        return this.publish();
    }

    public isRenderingVideo(): boolean {
        return (
            this.$scope.videoProcessingContext.isProcessingOnServer &&
            this.$scope.videoProcessingContext.timeMark > 0 &&
            this.$scope.videoProcessingContext.timeMark < this.$scope.videoProcessingContext.videoDuration
        );
    }

    public isFinalizingVideo(): boolean {
        return (
            this.$scope.videoProcessingContext.isProcessingOnServer &&
            this.$scope.videoProcessingContext.timeMark > 0 &&
            this.$scope.videoProcessingContext.timeMark >= this.$scope.videoProcessingContext.videoDuration
        );
    }

    protected doPublish(): angular.IPromise<void> {
        this.status = PublishStatus.publishing;
        return this.$q
            .resolve(
                this.videoPublishService.publishVideoWithListeners(
                    this.contentBuilder.document,
                    this.outputDimensions,
                    {
                        contentBuilder: this.contentBuilder,
                        ctrl: this.$scope.videoProcessingContext,
                        fileCache: this.contentBuilder.fileCache,
                    },
                    {
                        onProcessingStateChange: (isProcessingOnServer: boolean) => {
                            void this.$timeout(() => {
                                this.$scope.videoProcessingContext.isProcessingOnServer = isProcessingOnServer;
                                this.$scope.$apply();
                            });
                        },
                        onProgress: (result: IProcessVideoProgress) => {
                            void this.$timeout(() => {
                                this.$scope.videoProcessingContext.timeMark = result.timeMark;
                                this.$scope.videoProcessingContext.videoDuration = result.duration;
                                this.$scope.$apply();
                            });
                        },
                    },
                    this.templateId,
                    this.plannerDetails ? this.plannerDetails.id : null,
                    this.channelData,
                    (request: IPublishVideoRequest<any>) =>
                        this.onPublishVideo({
                            request,
                        }),
                    this.contentBuilder.linkedAssetLibraryAsset.map(value => ({
                        assetId: value.asset.id,
                        layerId: value.layerId,
                    })),
                ),
            )
            .then(
                videoUrl => {
                    this.status = PublishStatus.finished;
                    this.outputVideoUrl = videoUrl;
                    this.onFinish({ result: resultSuccess({ outputVideoUrl: this.outputVideoUrl }) });
                },
                (result: IHttpPromiseCallbackArg<ServerError> | JobFailure | any) => {
                    this.status = PublishStatus.errored;
                    this.jobError = baseVideoPublishErrorHandler(this, result);
                },
            );
    }
}

angular.module('app').controller(PublishVideoController.SID, PublishVideoController);
