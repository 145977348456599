import { gql, useLazyQuery } from '@apollo/client';
import { useToggle } from '@deltasierra/react-hooks';
import { t } from '@deltasierra/shared';
import * as React from 'react';
import { useCurrentAssetContext } from '../../contexts';
import { CollectionList } from '../CollectionList';
import { SearchBar } from '../SearchBar';
import { GetBinExpiredAssets, GetBinExpiredAssetsVariables } from './__graphqlTypes/GetBinExpiredAssets';
import { LocationFragmentForCollectionListSection } from './__graphqlTypes/LocationFragmentForCollectionListSection';

const fragments = {
    LOCATION_FRAGMENT_FOR_COLLECTION_LIST_SECTION: gql`
        fragment LocationFragmentForCollectionListSection on Location {
            id
            title
            collections {
                ...CollectionConnectionForCollectionList
            }
            client {
                id
                title
            }
            clientCollections {
                ...CollectionConnectionForCollectionList
            }
        }
        ${CollectionList.fragments.COLLECTION_CONNECTION_FOR_COLLECTION_LIST}
    `,
};

// This is really just organizational
const BIN_COUNT_FRAGMENT = gql`
    fragment BinCountFragment on AssetConnection {
        edges {
            node {
                __typename
                ... on AssetFolder {
                    id
                }
                ... on AssetFile {
                    id
                }
            }
        }
    }
`;

// Since depending on how many assets there are, this can be a very resource heavy query, we give it its own...
// Separate lazy query, to be called if the user checks the bin
const GET_BIN_EXPIRED_ASSETS = gql`
    query GetBinExpiredAssets($locationId: ID!) {
        location(id: $locationId) {
            id
            client {
                id
                expired {
                    ...BinCountFragment
                }
                recycled {
                    ...BinCountFragment
                }
            }
            expired {
                ...BinCountFragment
            }
            recycled {
                ...BinCountFragment
            }
        }
    }
    ${BIN_COUNT_FRAGMENT}
`;

export interface CollectionListSectionProps {
    location: LocationFragmentForCollectionListSection;
}

export function CollectionListSection(props: CollectionListSectionProps): JSX.Element {
    const [getBinCount, { data: binData }] = useLazyQuery<GetBinExpiredAssets, GetBinExpiredAssetsVariables>(
        GET_BIN_EXPIRED_ASSETS,
    );
    const [currentAsset, setCurrentAsset] = useCurrentAssetContext();
    const [collectionSearchTerm, setCollectionSearchTerm] = React.useState('');
    const [isClientBinOpen, toggleIsClientBinOpen] = useToggle();
    const [isLocBinOpen, toggleIsLocBinOpen] = useToggle();

    return (
        <>
            <SearchBar
                initialValue=""
                placeholder={t('ASSET_LIBRARY.SEARCH_COLLECTIONS')}
                onSearchChange={setCollectionSearchTerm}
            />
            <CollectionList
                binData={{
                    count: binData?.location?.client.recycled.edges.length ?? 0,
                    id: 'client-bin',
                    title: t('ASSET_LIBRARY.BIN'),
                }}
                collections={props.location.clientCollections}
                currentId={currentAsset}
                dataCy="client-collection-list"
                expiredData={{
                    count: binData?.location?.client.expired.edges.length ?? 0,
                    id: 'client-expired',
                    title: t('COMMON.EXPIRED'),
                }}
                headerText={props.location.client.title}
                isBinSectionOpen={isClientBinOpen}
                searchTerm={collectionSearchTerm}
                onClick={setCurrentAsset}
                onRequestBinSection={() => {
                    if (!binData) {
                        getBinCount({ variables: { locationId: props.location.id } });
                    }
                    toggleIsClientBinOpen('toggle');
                }}
            />
            <CollectionList
                binData={{
                    count: binData?.location?.recycled.edges.length ?? 0,
                    id: 'location-bin',
                    title: t('ASSET_LIBRARY.BIN'),
                }}
                collections={props.location.collections}
                currentId={currentAsset}
                dataCy="location-collection-list"
                expiredData={{
                    count: binData?.location?.expired.edges.length ?? 0,
                    id: 'location-expired',
                    title: t('COMMON.EXPIRED'),
                }}
                headerText={props.location.title}
                isBinSectionOpen={isLocBinOpen}
                searchTerm={collectionSearchTerm}
                onClick={setCurrentAsset}
                onRequestBinSection={() => {
                    if (!binData) {
                        getBinCount({ variables: { locationId: props.location.id } });
                    }
                    toggleIsLocBinOpen('toggle');
                }}
            />
        </>
    );
}
CollectionListSection.displayName = 'CollectionListSection';
CollectionListSection.fragments = fragments;
