import { Upload, ISize, BlobWithData, BuilderTemplateType, LocationIdHierarchy, UploadId } from '@deltasierra/shared';


import { MvNotifier } from '../../../common/mvNotifier';
import { I18nService } from '../../../i18n/i18nService';
import { ImageLoaderService } from '../../imageLoaderService';
import { VideoPublishService } from '../VideoPublishService';
import { BuilderConstants, builderConstantsSID } from '../../builderConstants';
import { $qSID, $scopeSID, $timeoutSID } from '../../../common/angularData';
import { MvLocation } from '../../../locations/mvLocation';
import { GraphqlService } from '../../../graphql/GraphqlService';
import { BaseContentBuilderMultipleLocationPublishCtrl } from './BaseContentBuilderMultipleLocationPublishCtrl';
import IPromise = angular.IPromise;
import IQService = angular.IQService;
import IScope = angular.IScope;

export class MvMultipleLocationVideoPublishCtrl extends BaseContentBuilderMultipleLocationPublishCtrl {
    public static SID = 'MvMultipleLocationVideoPublishCtrl';

    public static readonly $inject: string[] = [
        $scopeSID,
        $qSID,
        $timeoutSID,
        MvNotifier.SID,
        ImageLoaderService.SID,
        builderConstantsSID,
        I18nService.SID,
        MvLocation.SID,
        VideoPublishService.SID,
        GraphqlService.SID,
    ];

    public outputDimensions: ISize;

    // eslint-disable-next-line max-params
    public constructor(
        $scope: IScope,
        $q: IQService,
        $timeout: ng.ITimeoutService,
        mvNotifier: MvNotifier,
        imageLoaderService: ImageLoaderService,
        builderConstants: BuilderConstants,
        i18n: I18nService,
        mvLocation: MvLocation,
        private videoPublishService: VideoPublishService,
        graphqlService: GraphqlService,
    ) {
        super($scope, $q, $timeout, mvNotifier, imageLoaderService, builderConstants, i18n, mvLocation, graphqlService);

        if (this.publishCtrl && this.publishCtrl.fileFormatChoice) {
            this.outputDimensions = {
                height: this.publishCtrl.fileFormatChoice.height,
                width: this.publishCtrl.fileFormatChoice.width,
            };
        } else {
            // Ehhh, this shouldn't happen, but let's be safe.
            this.outputDimensions = {
                height: this.publishCtrl.contentBuilder.document.dimensions.height,
                width: this.publishCtrl.contentBuilder.document.dimensions.width,
            };
        }

        this.start();
    }

    public uploadArtifact(): IPromise<Upload> {
        return this.videoPublishService
            .generateVideoOverlayImage(this.publishCtrl.contentBuilder, this.outputDimensions)
            .then((imageBlob: BlobWithData) => {
                // Safari does not support the File constructor
                // Const file = new File([blob], this.publishCtrl.generateImageFileName(exportData.imageFormat), { type: blob.type });
                const now = new Date();
                imageBlob.lastModified = now.getTime();
                imageBlob.name = this.publishCtrl.generateImageFileName();
                return this.videoPublishService.uploadOverlayImage(imageBlob, this);
            });
    }

    protected async uploadArtifactOrUseExisting(
        uploadId: UploadId | null,
        locationIdHierarchy: LocationIdHierarchy,
    ): Promise<UploadId> {
        if (!uploadId || this.hasSubstitutionContent()) {
            const location = await this.mvLocation.getAssignedLocation(locationIdHierarchy.locationId);
            await this.updateSubstitutionValuesForLocation(location, BuilderTemplateType.video);
            const { id }: Upload = await this.uploadArtifact();
            return id;
        } else {
            return this.$q.when(uploadId);
        }
    }
}

angular.module('app').controller(MvMultipleLocationVideoPublishCtrl.SID, MvMultipleLocationVideoPublishCtrl);
