import * as React from 'react';
import { useLazyQuery } from '@apollo/client';
import {
    GetUploadForUploadThumbnailPoll,
    GetUploadForUploadThumbnailPollVariables,
} from './__graphqlTypes/GetUploadForUploadThumbnailPoll';
import { GET_UPLOAD_FOR_UPLOAD_THUMBNAIL_POLL } from './upload.query';

export type ThumbnailUpload = {
    id: string;
    filename: string;
    thumbnailUrl: string | null;
    url: string | null;
    contentType: string;
};

export const useUploadThumbnailPoll = (
    uploadId?: string,
    pollOptions: { maxRetries: number; pollInterval: number } = { maxRetries: 5, pollInterval: 3000 },
): { thumbnail: ThumbnailUpload | undefined; loading: boolean } => {
    const [tries, setTries] = React.useState(0);
    const [getUpload, { data, loading, startPolling, stopPolling }] = useLazyQuery<
        GetUploadForUploadThumbnailPoll,
        GetUploadForUploadThumbnailPollVariables
    >(GET_UPLOAD_FOR_UPLOAD_THUMBNAIL_POLL, {
        fetchPolicy: 'network-only',
        // This is required for the `onCompleted` callback to be called even if data stays the same D:
        notifyOnNetworkStatusChange: true,
        onCompleted({ upload }) {
            setTries(prev => prev + 1);
        },
    });

    React.useEffect(() => {
        if (uploadId) {
            getUpload({ variables: { id: uploadId } });
        }
    }, [getUpload, uploadId]);

    React.useEffect(() => {
        if (data?.upload.__typename === 'Upload') {
            if (!data.upload.thumb256x256url && tries < pollOptions.maxRetries) {
                startPolling(pollOptions.pollInterval);
            } else {
                stopPolling();
            }
        }
    }, [data, pollOptions.maxRetries, pollOptions.pollInterval, startPolling, stopPolling, tries]);

    const thumbnail = React.useMemo(
        () =>
            data?.upload.__typename === 'Upload'
                ? {
                      ...data.upload,
                      thumbnailUrl: data.upload.thumb256x256url,
                  }
                : undefined,
        [data?.upload],
    );

    return {
        loading,
        thumbnail,
    };
};
