import {
    DSCheckbox,
    DSListItem,
    DSListItemIcon,
    DSListItemProps,
    DSListItemSecondaryAction,
    DSListItemText,
} from '@deltasierra/components';
import * as React from 'react';
import styled from 'styled-components';

const StyledNestedDSListItem: React.FC<DSListItemProps & { $depth: number }> = styled(DSListItem)<{ $depth: number }>`
    ${props =>
        props.$depth > 0
            ? `
                padding-left: ${props.theme.spacing(4) * props.$depth}px;
            `
            : ''}
` as React.FC<DSListItemProps & { $depth: number }>;

const StyledDSListItemIcon = styled(DSListItemIcon)`
    padding: 0px;
`;

export type LocationsChecklistItemProps = {
    checkboxOnly?: boolean;
    checked: boolean;
    chip?: React.ReactNode;
    depth?: number;
    indeterminate: boolean;
    onClick?: () => void;
    title: React.ReactNode;
    type: 'primary' | 'secondary';
};

export function LocationsChecklistItem({
    checkboxOnly = false,
    checked,
    chip,
    depth = 0,
    indeterminate,
    onClick,
    title,
    type = 'primary',
}: LocationsChecklistItemProps): JSX.Element {
    return (
        <StyledNestedDSListItem $depth={depth} button={!checkboxOnly} onClick={checkboxOnly ? undefined : onClick}>
            <StyledDSListItemIcon>
                <DSCheckbox
                    checked={checked}
                    edge="start"
                    indeterminate={indeterminate}
                    size="small"
                    onClick={checkboxOnly ? onClick : undefined}
                />
            </StyledDSListItemIcon>
            <DSListItemText
                primary={type === 'primary' ? title : undefined}
                secondary={type === 'secondary' ? title : undefined}
            />
            {chip && <DSListItemSecondaryAction>{chip}</DSListItemSecondaryAction>}
        </StyledNestedDSListItem>
    );
}
LocationsChecklistItem.displayName = 'LocationsChecklistItem';
