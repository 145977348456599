/// <reference path="../../../typings/browser.d.ts" />
import { AssignedLocation, coerceDate, LocationId, Planner } from '@deltasierra/shared';
import { InteractionUtils } from '../common/interactionUtils';
import { I18nService } from '../i18n/i18nService';
import { IntroDataService } from '../intro/introDataService';
import { IntroService } from '../intro/introService';
import { MvPlanner } from '../planner/mvPlanner';
import { PlannerUIService } from '../planner/plannerUIService';
import IHttpPromise = angular.IHttpPromise;
import IScope = angular.IScope;

const MONTH_RANGE = 2;
const MS_PER_DAY = 1000 * 60 * 60 * 24;

enum UpcomingPlannerType {
    REQUIRING_ARTWORK = 'requiringArtwork' as any,
    PLANNER = 'planned' as any,
    READY_TO_BUILD = 'readyToBuild' as any,
}

interface UpcomingPlannerEntry {
    iconClasses: string;
    dueText: string;
    dueClass: string;
    title: string;
    url: string;
}

export class UpcomingPlannersCtrl {
    static SID = 'UpcomingPlannersCtrl';

    // From directive
    location!: AssignedLocation;

    type!: UpcomingPlannerType;

    change!: (locals: { planners: Planner[] }) => any;

    loading = {
        upcomingPlanners: false,
    };

    plannerEntries: UpcomingPlannerEntry[] | null = null;

    static readonly $inject: string[] = [
        '$scope',
        MvPlanner.SID,
        PlannerUIService.SID,
        InteractionUtils.SID,
        I18nService.SID,
        IntroService.SID,
        IntroDataService.SID,
    ];

    constructor(
        $scope: IScope,
        private readonly mvPlanner: MvPlanner,
        private readonly plannerUIService: PlannerUIService,
        private readonly interactionUtils: InteractionUtils,
        private readonly i18n: I18nService,
        private readonly introService: IntroService,
        private readonly introDataService: IntroDataService,
    ) {
        $scope.$watch(
            () => this.location,
            () => this.retrievePlanners(),
        );
    }

    private getToday(): Date {
        let today = new Date();
        today = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate()));
        return today;
    }

    private constructUrl(planner: Planner): string {
        // If (scope.type == TYPES.REQUIRING_ARTWORK) {
        //     Return '/builderTemplateGallery?planner=' + planner.id;
        // } else {
        return this.plannerUIService.getPlannerUrl(planner);
        // }
    }

    private getIconClasses(): string {
        switch (this.type) {
            case UpcomingPlannerType.REQUIRING_ARTWORK:
            case UpcomingPlannerType.READY_TO_BUILD:
                return 'fa fa-paint-brush';
            default:
                return 'fa fa-calendar';
        }
    }

    private mapPlanners(planners: Planner[]): UpcomingPlannerEntry[] {
        const entries = [];
        for (const planner of planners) {
            const entry = {
                iconClasses: this.getIconClasses(),
                dueText: this.getDueText(planner),
                dueClass: this.getDueClass(planner),
                title: planner.title,
                url: this.constructUrl(planner),
            };
            entries.push(entry);
        }
        return entries;
    }

    private getData(locationId: LocationId, dateFrom: Date, dateTo: Date): IHttpPromise<Planner[]> {
        switch (this.type) {
            case UpcomingPlannerType.REQUIRING_ARTWORK:
                return this.mvPlanner.getUpcomingRequiringArtwork(locationId, dateFrom, dateTo);
            case UpcomingPlannerType.READY_TO_BUILD:
                return this.mvPlanner.getUpcomingReadyToBuild(locationId, dateFrom, dateTo);
            default:
                return this.mvPlanner.getUpcomingPlanned(locationId, dateFrom, dateTo);
        }
    }

    private retrievePlanners(): ng.IPromise<void> | void {
        if (this.introService.isIntroActive('build')) {
            const introPlanner = [this.introDataService.getExamplePlanner()];
            this.plannerEntries = this.mapPlanners(introPlanner);
            this.change({ planners: introPlanner });
        } else if (this.location) {
            const locationId = this.location.id;
            const dateFrom = this.getToday();
            const dateTo = new Date(dateFrom.getTime());
            dateTo.setUTCMonth(dateTo.getUTCMonth() + MONTH_RANGE);
            return this.interactionUtils
                .handleRemote(
                    this,
                    'retrieve upcoming planner events',
                    'upcomingPlanners',
                    this.getData(locationId, dateFrom, dateTo),
                )
                .then(planners => {
                    this.plannerEntries = this.mapPlanners(planners);
                    this.change({ planners });
                });
        }
    }

    /**
     * Derived from http://stackoverflow.com/a/15289883
     *
     * @param date
     * @returns {number}
     */
    private getDaysDifference(date: Date): number {
        const today = this.getToday();
        date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
        return Math.floor((date.valueOf() - today.valueOf()) / MS_PER_DAY);
    }

    private getDueText(planner: Planner): string {
        if (!planner) {
            return '';
        }
        const days = this.getDaysDifference(coerceDate(planner.date));
        const weeks = Math.floor(days / 7);
        let result: string;
        switch (this.type) {
            case UpcomingPlannerType.REQUIRING_ARTWORK:
            case UpcomingPlannerType.READY_TO_BUILD:
                if (days == 0) {
                    result = `${this.i18n.text.dashboard.dueToday()}!`;
                } else if (days == 1) {
                    result = this.i18n.text.dashboard.dueTomorrow();
                } else if (weeks > 0) {
                    result = this.i18n.text.dashboard.dueInNWeeks({ count: weeks });
                } else {
                    result = this.i18n.text.dashboard.dueInNDays({ count: days });
                }
                break;
            default:
                if (days == 0) {
                    result = this.i18n.text.common.today();
                } else if (days == 1) {
                    result = this.i18n.text.common.tomorrow();
                } else if (weeks > 0) {
                    result = this.i18n.text.dashboard.inNWeeks({ count: weeks });
                } else {
                    result = this.i18n.text.dashboard.dueInNDays({ count: days });
                }
                break;
        }
        return result;
    }

    private getDueClass(planner: Planner): string {
        if (!planner) {
            return '';
        }
        if (planner.isArtifactRequired && !planner.contentSupplied) {
            return 'text-calendar-required';
        } else if (planner.isArtifactRequired && planner.contentSupplied) {
            return 'text-calendar-ready';
        } else {
            return 'text-calendar-general';
        }
    }
}

angular.module('app').controller(UpcomingPlannersCtrl.SID, UpcomingPlannersCtrl);

angular.module('app').directive('upcomingPlanners', [
    function () {
        return {
            restrict: 'E',
            replace: true,
            scope: {},
            templateUrl: '/partials/main/upcomingPlanners',
            controller: UpcomingPlannersCtrl,
            controllerAs: 'ctrl',
            bindToController: {
                location: '=',
                type: '@',
                change: '&',
            },
        };
    },
]);
