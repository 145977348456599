import { gql, useMutation } from '@apollo/client';
import {
    DSButton,
    DSConfirmButton,
    DSTable,
    DSTableBody,
    DSTableBodyLoadable,
    DSTableCell,
    DSTableContainer,
    DSTableHead,
    DSTableRow,
    DSTextField,
} from '@deltasierra/components';
import { useControlledInput, useDebouncedValue } from '@deltasierra/react-hooks';
import { t } from '@deltasierra/shared';
import * as React from 'react';
import styled from 'styled-components';
import { AssociateBrandButton } from '../AssociateBrandButton';
import { AssociateBrandDialog } from '../AssociateBrandButton/AssociateBrandDialog';
import { BrandButton } from '../BrandButton';
import { BrandDialog } from '../BrandButton/BrandDialog';
import { BrandTableFragment } from './__graphqlTypes/BrandTableFragment';
import { DeleteBrandsForTable, DeleteBrandsForTableVariables } from './__graphqlTypes/DeleteBrandsForTable';

const fragments = {
    BrandTableFragment: gql`
        fragment BrandTableFragment on Brand {
            __typename
            id
            title
            url
            ...BrandForDialog
            ...BrandForAssociateDialog
            clients {
                edges {
                    node {
                        __typename
                        id
                        title
                    }
                }
            }
        }

        ${BrandDialog.fragments.BrandDialogFragment}
        ${AssociateBrandDialog.fragments.AssociateBrandDialogFragment}
    `,
};

const DELETE_BRANDS_FOR_TABLE = gql`
    mutation DeleteBrandsForTable($brandId: ID!) {
        deleteBrand(input: { brandId: $brandId }) {
            __typename
            ... on DeleteBrandPayload {
                brandId
            }
        }
    }
`;

const Flexor = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const LogoWrapper = styled.div`
    height: 64px;
    width: 64px;
    margin-right: 12px;
`;

const ButtonWrapper = styled.div`
    margin-left: 8px;
    margin-right: 8px;
`;

export type BrandTableProps = {
    brands: BrandTableFragment[];
    loading?: boolean;
};

export const BrandTable: React.FC<BrandTableProps> & { fragments: typeof fragments } = ({ brands, loading }) => {
    const [deleteBrand] = useMutation<DeleteBrandsForTable, DeleteBrandsForTableVariables>(DELETE_BRANDS_FOR_TABLE, {
        refetchQueries: ['GetBrandsForTable'],
    });
    const [search, , searchProps] = useControlledInput();
    const debouncedSearch = useDebouncedValue(search);

    // Front end searching doesn't seem bad now, if there starts to be performance issues, however, this is a good place to look
    // Minor optimization until then by memoizing the brands list and only refreshing on a debounced filter value
    const filteredBrands = brands.filter(
        brand =>
            brand.title.toLowerCase().indexOf(debouncedSearch.toLowerCase()) > -1 ||
            brand.clients.edges
                .map(({ node }) => node)
                .some(client => client.title.toLowerCase().indexOf(debouncedSearch.toLowerCase()) > -1),
    );

    return (
        <DSTableContainer>
            <DSTable>
                <DSTableHead>
                    <DSTableRow>
                        <DSTableCell>{t('ADMIN.BRANDS.BRAND')}</DSTableCell>
                        <DSTableCell align="right">{t('COMMON.CLIENTS')}</DSTableCell>
                        <DSTableCell>{''}</DSTableCell>
                    </DSTableRow>
                </DSTableHead>
                <DSTableBody>
                    <DSTableRow>
                        <DSTableCell colSpan={3}>
                            <DSTextField label={t('ADMIN.BRANDS.FILTER_BRANDS')} variant="outlined" {...searchProps} />
                        </DSTableCell>
                    </DSTableRow>
                </DSTableBody>
                <DSTableBodyLoadable loading={loading} minHeight="50px">
                    {filteredBrands.map(brand => (
                        <DSTableRow key={brand.id}>
                            <DSTableCell component="th" scope="row" style={{ width: '100%' }}>
                                <Flexor>
                                    <LogoWrapper>
                                        {brand.url && (
                                            <img
                                                height="56px"
                                                src={brand.url ?? ''}
                                                style={{ objectFit: 'contain' }}
                                                width="64px"
                                            />
                                        )}
                                    </LogoWrapper>
                                    {brand.title}
                                </Flexor>
                            </DSTableCell>
                            <DSTableCell align="right">{brand.clients.edges.length}</DSTableCell>
                            <DSTableCell align="right">
                                <Flexor>
                                    <ButtonWrapper>
                                        <AssociateBrandButton brand={brand} />
                                    </ButtonWrapper>

                                    <ButtonWrapper>
                                        <BrandButton
                                            brand={brand}
                                            render={({ toggle }) => (
                                                <DSButton
                                                    color="primary"
                                                    style={{ paddingLeft: '12px', paddingRight: '12px' }}
                                                    variant="text"
                                                    onClick={() => toggle('toggleOn')}
                                                >
                                                    <i className="fas fa-edit" />
                                                </DSButton>
                                            )}
                                        />
                                    </ButtonWrapper>
                                    <ButtonWrapper>
                                        <DSConfirmButton
                                            color="error"
                                            message={t('ADMIN.BRANDS.THIS_WILL_PERMANENTLY_DELETE', {
                                                brand: brand.title,
                                            })}
                                            style={{ paddingLeft: '12px', paddingRight: '12px' }}
                                            variant="text"
                                            onConfirm={async () =>
                                                deleteBrand({
                                                    refetchQueries: ['GetBrandsForAdminWell'],
                                                    variables: { brandId: brand.id },
                                                })
                                            }
                                        >
                                            <i className="fas fa-trash-alt" />
                                        </DSConfirmButton>
                                    </ButtonWrapper>
                                </Flexor>
                            </DSTableCell>
                        </DSTableRow>
                    ))}
                </DSTableBodyLoadable>
            </DSTable>
        </DSTableContainer>
    );
};
BrandTable.displayName = 'BrandTable';
BrandTable.fragments = fragments;
