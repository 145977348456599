import { Roles } from '@deltasierra/shared';
import { Loading, Translate } from '@deltasierra/components';
import * as React from 'react';
import { OneWayBinding, OptionalOneWayBinding } from '../../../common/angularData';
import { withAngularIntegration } from '../../../common/componentUtils/reactComponentRegistration';
import { useAuthUser } from '../../../common/auth';
import { CopyTemplateModal } from './CopyTemplateModal';
import { useCopyTemplate } from './useCopyTemplate';

export type CopyTemplateButtonProps = {
    builderTemplateId: string;
    disabled?: boolean;
};

export function CopyTemplateButton({ builderTemplateId, disabled }: CopyTemplateButtonProps): JSX.Element {
    const { handleCancel, handleClickCopy, handleCopyMutation, loading, showModal } = useCopyTemplate();

    const user = useAuthUser();
    const isAuthorised = user?.roles.includes(Roles.manager);

    return (
        <>
            {isAuthorised && (
                <>
                    <button
                        className="btn btn-primary form-control"
                        disabled={disabled}
                        type="button"
                        onClick={handleClickCopy}
                    >
                        <Translate keyId="COMMON.COPY" />
                    </button>
                    {loading && <Loading />}
                    <CopyTemplateModal
                        builderTemplateId={builderTemplateId}
                        show={showModal}
                        onCancel={handleCancel}
                        onSubmit={handleCopyMutation}
                    />
                </>
            )}
        </>
    );
}
CopyTemplateButton.displayName = 'CopyTemplateButton';

export default withAngularIntegration(CopyTemplateButton, 'copyTemplateButton', {
    builderTemplateId: OneWayBinding,
    disabled: OptionalOneWayBinding,
});
