/// <reference path="../../../typings/browser.d.ts" />
export const builderConstantsSID = 'builderConstants';
export interface BuilderConstants {
    EVENTS: {
        RENDER_AND_RESET_ZOOM: string;
        SHOW_FILE_FORMAT_OPTIONS: string;
        FILE_FORMAT_CHOSEN: string;
        PUBLISH_CANCEL: string;
        PUBLISH_PUBLISHED: string;
        PUBLISH_FINISH: string;
        UPDATE_DOCUMENT_FROM_EDITOR: string;
    };
}

const BuilderConstants: BuilderConstants = {
    EVENTS: {
        FILE_FORMAT_CHOSEN: 'builderFileFormatChosen',
        PUBLISH_CANCEL: 'builderPublishCancel',
        PUBLISH_FINISH: 'builderPublishFinish',
        PUBLISH_PUBLISHED: 'builderPublishPublished',
        RENDER_AND_RESET_ZOOM: 'builderRenderAndResetZoom',
        SHOW_FILE_FORMAT_OPTIONS: 'builderShowFileFormatOptions',
        UPDATE_DOCUMENT_FROM_EDITOR: 'updateDocumentFromEditor',
    },
};
angular.module('app').constant(builderConstantsSID, BuilderConstants);
