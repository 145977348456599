import { ISize, ImageSection, isImageSection } from '@deltasierra/shared';

import { IQResolveReject } from 'angular';
import { I18nService } from '../../i18n';
import { $qSID } from '../../common/angularData';
import { IZipFileEntryExtended, IZipFileValidationContext } from './emailBuilderZip';
import { EmailBuilder } from './emailBuilder';

export class EmailBuilderImageProcessor {
    public static readonly SID: string = 'EmailBuilderImageProcessor';

    public static readonly $inject: string[] = [
        $qSID,
        I18nService.SID,
    ];

    public constructor(
        private readonly $q : angular.IQService,
        private readonly i18n: I18nService,
    ) {
    }

    public updateImageDimensionsIfMissing(section: ImageSection) : ng.IPromise<void> {
        // Since the images are loaded locally (not from an external service)
        // We can have a relatively short timeout
        const timeoutDuration = 2000;

        // If all the sections that point to this image already have a width and height then
        // We don't need to work it and can exit early
        if (!!section.width && !!section.height) {
            return this.$q.resolve();
        }

        return this.getImageDimensions(section.location!, { timeoutDuration }).then(size => {
            section.width = size.width;
            section.height = size.height;
        });
    }

    public async processImages(context : IZipFileValidationContext, emailBuilder: EmailBuilder) : Promise<IZipFileValidationContext> {
        const promises = context.images.map(async image => {
            const blob = await this.zipFileEntryExtendedToBlob(image);
            if (blob) {
                const file = this.blobToFile(blob, image.name + image.ext);
                // Find the section(s) that points to this image
                const matchingImageSections = context.parsedTemplate.sections.filter(
                    section => isImageSection(section) && section.originalLocation === image.fileName,
                ) as ImageSection[];
                const taskPromises = matchingImageSections.map(section => {
                    emailBuilder.setImageLocationFromFile(section, file);

                    // We may not have been able to determine the img element sizes by parsing the html alone,
                    // In which case, we have a 2nd shot at it here now the image has been loaded
                    return this.updateImageDimensionsIfMissing(section);
                });
                await Promise.all(taskPromises);
            }
        });

        await Promise.all(promises);
        return context;
    }

    private async zipFileEntryExtendedToBlob(entry : IZipFileEntryExtended) : Promise<Blob | null> {
        if (!entry) {
            return null;
        }

        const data = await entry.file.async('arraybuffer');
        const blob = new Blob([data], { type: entry.mimeType });
        return blob;
    }

    private blobToFile(theBlob : Blob, fileName : string) : File {
        // A Blob is almost a File - it's just missing the two properties below which we will add
        const result : File = angular.extend(theBlob, {
            lastModified: new Date().valueOf(),
            name: fileName,
        });

        return result;
    }

    private getImageDimensions(
        url : string,
        options? : { timeoutDuration? : number },
    ) : angular.IPromise<{ width : number; height : number }> {
        const defaultTimeoutDuration = 30000;
        const timeoutDuration = (options && options.timeoutDuration) || defaultTimeoutDuration;

        return this.$q((resolve : IQResolveReject<ISize>, reject : IQResolveReject<Error>) => {
            const $image = $('<img style="display: none !important; border: 0 !important; padding: 0 !important; margin: 0 !important; width: auto !important; height: auto !important;" />');
            $image.attr('src', url);

            // Seem to need to add image to DOM (kind of surprised by this actually?)
            // We've set it to hidden so it won't be visible or effect layout and
            // Shouldn't be affected by pre-existing css that could alter it's dimensions
            $('body').append($image);

            let loaded = false;

            const timeoutHandle = setTimeout(() => {
                    // Remove our onLoad event handler
                    $image.off();
                    reject(new Error(this.i18n.text.build.email.imageTimeout()));
                },
                timeoutDuration);

            $image.one('load', () => {
                loaded = true;

                // Remove timeout handler
                clearTimeout(timeoutHandle);

                const size = {
                    height: $image.height(),
                    width: $image.width(),
                };

                $image.remove();

                resolve(size);
            }).each(function load(this: HTMLImageElement) {
                if (!loaded) {
                    // This will ensure the load event fires
                    // Even if the image was already in the
                    // Browser's cache
                    if (this.complete) {
                        $(this).load();
                    }
                }
            });
        });
    }
}

angular.module('app').service(EmailBuilderImageProcessor.SID, EmailBuilderImageProcessor);
