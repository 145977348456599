import {
    DSCheckbox,
    DSCheckboxProps,
    DSFormControl,
    DSFormControlLabel,
    DSFormHelperText,
} from '@deltasierra/components';
import { KeysOfTypeLoose } from '@deltasierra/type-utilities';
import { FormikProps, useField } from 'formik';
import React from 'react';

type DSCheckboxFormikKeys<T> = Exclude<KeysOfTypeLoose<T, boolean>, undefined>;

export type DSCheckboxFormikProps<T> = Omit<DSCheckboxProps, 'checked' | 'name' | 'onBlur' | 'onChange' | 'value'> & {
    error?: boolean;
    formik?: FormikProps<T>;
    helperText?: JSX.Element;
    label?: JSX.Element;
    name: DSCheckboxFormikKeys<T>;
};

export function DSCheckboxFormik<T>({
    /**
     * We pass formik through for type safety only
     */
    error,
    formik: _formik,
    helperText: _helperText,
    label,
    name,
    ...props
}: DSCheckboxFormikProps<T>): JSX.Element {
    const [field, meta] = useField({ name: name as string, type: 'checkbox' });

    const showError = !!meta.error && meta.touched;

    const helperText = (showError ? meta.error : undefined) ?? _helperText;

    return (
        <DSFormControl error={showError || error}>
            <DSFormControlLabel
                control={
                    <DSCheckbox
                        {...props}
                        checked={field.checked}
                        name={field.name}
                        onBlur={field.onBlur}
                        onChange={field.onChange}
                    />
                }
                label={label}
            />
            {helperText && <DSFormHelperText>{helperText}</DSFormHelperText>}
        </DSFormControl>
    );
}
DSCheckboxFormik.displayName = 'DSCheckboxFormik';
