import {
    DSAlert,
    DSFormControlLabel,
    DSFormGroup,
    DSTextField,
    DSTypography,
    DSRadioGroup,
} from '@deltasierra/components';
import { Form } from 'formik';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core';

export const StyledForm = styled(Form)`
    display: flex;
    flex-direction: column;
`;

export const StyledDSFormControlLabel = styled(DSFormControlLabel)`
    margin-bottom: 0px;
    width: fit-content;
`;

export const StyledTextBox = styled.div`
    align-items: center;
    background: #fff;
    border-color: #ccc;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    color: darkgrey;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const StyledTextBoxContent = styled.div`
    padding: 6px 12px 6px 12px;
`;

export const StyledTextBoxEnd = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    padding: 6px;
`;

export const StyledMainTitle = styled(DSTypography)`
    margin-bottom: 15px;
`;

export const FormButtonGroup = styled(DSFormGroup)`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0px;
    margin-top: auto;
`;

export const StyledLabel = styled(DSTypography)`
    margin-bottom: 5px;
`;

export const StyledClubReadyRadioGroup = styled(DSRadioGroup)`
    margin-bottom: 0px;
    margin-left: 25px;
`;

export const StyledDSFormGroup = styled(DSFormGroup)`
    margin-bottom: 25px;
`;

export const StyledList = styled.ul`
    margin-bottom: 0px;
    padding-left: 20px;
`;

export const StyledListItem = styled.li`
    padding: 3px 0px 3px 0px;
`;

export const StyledAnchor = styled.a`
    margin-left: 5px;
`;

export const StyledDSTextField = withStyles({
    root: {
        '& [class*=MuiInputBase-root]': {
            borderRadius: '4px',
            backgroundColor: 'white',
            overflow: 'hidden',
        },
    }
})(DSTextField);

export const StyledListOptionBaseContainer = styled.div`
    padding-left: 10px;
`;

export const StyledDSAlert = withStyles({
    root: {
        marginBottom: '8px',
        boxShadow: 'none',
        borderRadius: 0,
        border: '1px solid #ccc'
    },
    message: {
        fontSize: '1rem',
    },
})(DSAlert);
