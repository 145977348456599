import { DSAlert } from '@deltasierra/components';
import * as React from 'react';
import { Translate } from '../../../../directives/Translate';

export function MissingSomeRequiredPermissionForFirstCommentMessage(): JSX.Element {
    return (
        <DSAlert severity="warning">
            <Translate keyId="ERRORS.FACEBOOK_ERRORS.SOME_LOCATIONS_ARE_MISSING_FIRST_COMMENT_HASHTAGS_PERMISSION" />
        </DSAlert>
    );
}
MissingSomeRequiredPermissionForFirstCommentMessage.displayName = 'MissingSomeRequiredPermissionForFirstCommentMessage';
