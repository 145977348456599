import { AssignedLocation, ImageSubstitutionType, AutomaticTextPlaceholders, BuilderTemplateType } from '@deltasierra/shared';


import { IPromise, IQService, IScope } from 'angular';
import { MvContentBuilderPublishCtrl } from '../mvContentBuilderPublishCtrl';
import { ContentBuilder } from '../../contentBuilder';
import { MvNotifier } from '../../../common/mvNotifier';
import { I18nService } from '../../../i18n';
import { ImageLoaderService } from '../../imageLoaderService';
import { BuilderConstants } from '../../builderConstants';
import { MvLocation } from '../../../locations/mvLocation';
import { GraphqlService } from '../../../graphql/GraphqlService';
import { BaseMultipleLocationPublishCtrl } from './BaseMultipleLocationPublishCtrl';

export abstract class BaseContentBuilderMultipleLocationPublishCtrl extends BaseMultipleLocationPublishCtrl {
    public publishCtrl!: MvContentBuilderPublishCtrl;

    public contentBuilder!: ContentBuilder;

    // eslint-disable-next-line max-params
    public constructor(
        $scope: IScope,
        $q: IQService,
        $timeout: ng.ITimeoutService,
        mvNotifier: MvNotifier,
        imageLoaderService: ImageLoaderService,
        builderConstants: BuilderConstants,
        i18n: I18nService,
        mvLocation: MvLocation,
        graphqlService: GraphqlService,
    ) {
        super($scope, $q, $timeout, mvNotifier, builderConstants, i18n, mvLocation, graphqlService);
    }

    protected hasTextSubstitutionContent(...keys: AutomaticTextPlaceholders[]): boolean {
        return this.contentBuilder.hasTextSubstitutionContent(...keys);
    }

    protected setTextSubstitution(key: string, value: string): void {
        this.contentBuilder.textSubstitutionValues[key] = value;
    }

    protected updateSubstitutionValuesForLocation(location: AssignedLocation, templateType?: BuilderTemplateType): IPromise<void> {
        return this.updateTextSubstitutionValuesForLocation(location, templateType)
            .then(() => this.contentBuilder.updateImageSubstitions(location))
            .then(details => {
                if (details.failedSubstitutions && details.failedSubstitutions.length > 0) {
                    const locationName = location.title;
                    const reason = details.failedSubstitutions
                        .map(type => {
                            switch (type) {
                                case ImageSubstitutionType.LocationLogo:
                                    return `${this.i18n.text.build.locationLogoNotSet({ location: locationName })}\n`;
                                case ImageSubstitutionType.LocationMap:
                                    return `${this.i18n.text.build.locationMapNotCreated({ location: locationName })}\n`;
                                default:
                                    return '';
                            }
                        })
                        .join('\n');

                    throw new Error(reason);
                }
            })
            .then(() => {
                this.publishCtrl.renderToPublishCanvas();
            });
    }

    protected hasSubstitutionContent(): boolean {
        return this.contentBuilder.hasSubstitutionContent();
    }
}
