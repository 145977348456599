import { DSFormGroup, DSFormLabel, Translate } from '@deltasierra/components';
import { t } from '@deltasierra/shared';
import { FormikProps } from 'formik';
import * as React from 'react';
import { Textarea } from '../../../../common/form/Textarea';
import { FacebookAndInstagramFormValues } from './facebook-and-instagram-form-values';


export type FacebookAndInstagramFormCaptionSectionProps = {
    formik: FormikProps<FacebookAndInstagramFormValues>;
};

export function FacebookAndInstagramFormCaptionSection({
    formik,
}: FacebookAndInstagramFormCaptionSectionProps): JSX.Element {
    const handleChange = React.useCallback(newValue => {
        formik.setFieldValue('caption', newValue);
    }, [formik]);
    return (
        <>
            <DSFormGroup>
                <DSFormLabel>
                    <Translate keyId="BUILD.PUBLISH.INSTAGRAM.CAPTION" />
                </DSFormLabel>
                <Textarea
                    enableEmojis
                    helpText={t('BUILD.PUBLISH.INSTAGRAM.CAPTION_HELP_TEXT')}
                    initialValue={formik.values.caption}
                    invalidHelpText={formik.errors.caption}
                    isValid={!formik.errors.caption}
                    rows={5}
                    onChange={handleChange}
                />
            </DSFormGroup>
        </>
    );
}

FacebookAndInstagramFormCaptionSection.displayName = 'FacebookAndInstagramFormCaptionSection';
