import { LocationPeriod, TwitterPerformanceStats, HttpResponseCode } from '@deltasierra/shared';


import { addDifference, BaseMetricProviderService, Metric, InsufficientDataRecorded } from './common';

export class TwitterMetricProviderService extends BaseMetricProviderService {
    static SID = 'TwitterMetricProviderService';

    getMetricRows(params: LocationPeriod): ng.IPromise<Array<Metric<any>>> {
        const favourites: Metric<number> = {
            label: this.i18n.text.dashboard.performanceSummary.metrics.twitterLikes(),
            iconUrl: '/img/dashboard/icon-twitter.png',
            columnSettings: {
                default: {
                    decimals: 0,
                    filter: 'number',
                },
            },
        };

        return this.$http
            .get<TwitterPerformanceStats>(`/api/performance/twitter/${params.locationId}/${params.year}/${params.month}`)
            .then(res => {
                if (res.status == HttpResponseCode.NoContent) {
                    favourites.error = new InsufficientDataRecorded();
                } else {
                    favourites.values = addDifference(res.data!.favourites);
                }

                return [favourites];
            }, this.getHttpErrorHandlerForMetrics(favourites));
    }
}

angular.module('app').service(TwitterMetricProviderService.SID, TwitterMetricProviderService);
