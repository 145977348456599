/// <reference path="../../../../typings/browser.d.ts" />
import { actualComponent, OneWayBinding, OptionalTwoWayBinding } from '../../common/angularData';
import { ErrorsListController } from './controller';

export const errorsListSID = 'errorsList';
export const errorsListConfig = actualComponent(
    ErrorsListController,
    '/partials/directives/errorsList/template',
    {
        errors: OneWayBinding,
        showErrors: OptionalTwoWayBinding,
    },
);

angular.module('app').component(errorsListSID, errorsListConfig);
