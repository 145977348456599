/* eslint-disable camelcase */
import * as React from 'react';
import { gql, QueryLazyOptions, useLazyQuery } from '@apollo/client';
import { JobState } from '../../../../../__graphqlTypes/globalTypes';
import { relayConnectionToArray } from '../../../graphql/utils';
import {
    GetScheduledPostStatus,
    GetScheduledPostStatusVariables,
    GetScheduledPostStatus_job,
} from './__graphqlTypes/GetScheduledPostStatus';

const GET_SCHEDULED_POST_STATUS_QUERY = gql`
    query GetScheduledPostStatus($id: ID!) {
        job(id: $id) {
            __typename
            id
            status {
                message
                state
            }
            ... on JobWithResults {
                results {
                    edges {
                        node {
                            id
                            status
                            reason
                            info
                            errorMessage
                        }
                    }
                }
            }
        }
    }
`;

export interface UseWorkflowArgs {
    pollInterval?: number;
    onComplete?: (job: GetScheduledPostStatus_job) => void;
    onError?: (userFacingMessage?: string | null) => void;
}

export function useWorkflow({
    onComplete,
    onError,
    pollInterval = 2000,
}: UseWorkflowArgs): [
    (options?: QueryLazyOptions<GetScheduledPostStatusVariables> | undefined) => void,
    { isRunning: boolean },
] {
    const [getStatus, { called, data, loading, stopPolling }] = useLazyQuery<
        GetScheduledPostStatus,
        GetScheduledPostStatusVariables
    >(GET_SCHEDULED_POST_STATUS_QUERY, {
        fetchPolicy: 'no-cache',
        pollInterval,
    });

    const isRunning = React.useMemo(
        () => loading || data?.job?.status?.state === JobState.RUNNING,
        [data?.job?.status?.state, loading],
    );

    const onCompleteRef = React.useRef(onComplete);
    React.useEffect(() => {
        onCompleteRef.current = onComplete;
    }, [onComplete]);

    const onErrorRef = React.useRef(onError);
    React.useEffect(() => {
        onErrorRef.current = onError;
    }, [onError]);

    React.useEffect(() => {
        const jobStatusSuccess = data?.job?.status.state === JobState.SUCCESS;
        const allJobResultsSuccessfulCheck = data?.job?.results.edges
            ? data?.job?.results.edges.every(({ node }) => node.status === 'success')
            : true;
        if (jobStatusSuccess && allJobResultsSuccessfulCheck) {
            stopPolling();
            onCompleteRef.current?.(data.job);
        } else if (
            called &&
            !loading &&
            data?.job?.status.state !== JobState.RUNNING &&
            (!jobStatusSuccess || !allJobResultsSuccessfulCheck)
        ) {
            // Take the first user facing message that we find
            const userFacingMessage = relayConnectionToArray(data?.job?.results).find(
                node => !!node.errorMessage,
            )?.errorMessage;
            onErrorRef.current?.(userFacingMessage);
        }
    }, [called, data, loading, stopPolling]);

    return [getStatus, { isRunning }];
}
