import { DSTextField } from '@deltasierra/components';
import * as React from 'react';
import { Button } from '@material-ui/core';
import styled from 'styled-components';
import { t } from '@deltasierra/shared';

const SearchBarContainer = styled.div`
    display: flex;
    align-items: baseline;
`;

const SearchButton = styled(Button)`
    margin-left: 6px;
    line-height: 1.2rem;
`;

export type SearchBarWithButtonProps = {
    initialValue?: string;
    placeholder?: string;
    onSearch: (searchTerm: string) => void;
};

/**
 * SearchBarWithButton
 * A search bar component with a search button.
 * It allows users to enter a search term and trigger a search by clicking the button or pressing Enter.
 *
 * @param props - Props object sent to the search bar with button
 * @param props.initialValue - The initial value of the search input
 * @param props.placeholder - The placeholder text for the search input
 * @param props.onSearch - The callback function to be called when the search button is clicked or Enter is pressed
 *
 * @returns SearchBarWithButton
 */
export function SearchBarWithButton(props: SearchBarWithButtonProps): JSX.Element {
    const { initialValue, onSearch, placeholder } = props;
    const [searchTerm, setSearchTerm] = React.useState(initialValue ?? '');

    const handleSearch = () => {
        onSearch(searchTerm);
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };

    return (
        <SearchBarContainer>
            <DSTextField
                InputProps={{ style: { backgroundColor: 'white', marginBottom: '12px' } }}
                aria-label={placeholder}
                data-cy={'search-assets-input'}
                fullWidth
                placeholder={placeholder}
                value={searchTerm}
                variant="outlined"
                onChange={event => setSearchTerm(event.target.value)}
                onKeyPress={handleKeyPress}
            />
            <SearchButton color="primary" data-cy={'search-assets-button'} variant="contained" onClick={handleSearch}>
                {t('COMMON.SEARCH')}
            </SearchButton>
        </SearchBarContainer>
    );
}

SearchBarWithButton.displayName = 'SearchBarWithButton';
