import * as React from 'react';
import styled from 'styled-components';
import { DSTableContainer, DSTable, DSTableHead, DSTableBodyLoadable, DSTableCell, DSTableRow, DSTypography, DSTableBody, DSTextField, Translate } from '@deltasierra/components';
import { useControlledInput, useDebouncedValue } from '@deltasierra/react-hooks';
import { CLUBREADY_CHAIN_ID_CONFIGURATION_TABLE_FRAGMENT } from './ClubReadyChainIdConfigurationTable.fragment';
import { ClubReadyChainIdConfigurationTableFragment } from './__graphqlTypes/ClubReadyChainIdConfigurationTableFragment';
import { ClubReadyConfigureClientButton } from './ClubReadyConfigureClientButton';

const StyledTableRow = styled(DSTableRow)`
    border-bottom: 1px solid #dde3eb;
`;

export type ClubReadyChainIdConfigurationTableProps = {
    clients: ClubReadyChainIdConfigurationTableFragment[],
    onClientSearch: (clientTitle: string | null) => void,
    isLoading?: boolean,
}

export function ClubReadyChainIdConfigurationTable(
    { clients, isLoading, onClientSearch }: ClubReadyChainIdConfigurationTableProps,
): JSX.Element {
    const [search, , searchProps] = useControlledInput();
    const debouncedSearch = useDebouncedValue(search);

    React.useEffect(() => {
        if (debouncedSearch !== '') {
            onClientSearch(debouncedSearch);
        } else {
            onClientSearch(null);
        }
    }, [debouncedSearch, onClientSearch]);

    const clientClubReadyIntegrations = React.useMemo(() => clients.map(client => {
        const integrationClubReadyNode = client.integrationConnection.edges
            .find(connection => connection.node.name === 'clubReady')?.node;

        return {
            clientId: client.id,
            integrationId: integrationClubReadyNode?.id,
            clientTitle: client.title,
        };
    }), [clients]);

    return (
        <DSTableContainer>
            <DSTable>
                <DSTableHead>
                    <DSTableRow>
                        <DSTableCell>
                            <Translate keyId="ADMIN.CLUBREADY_CONNECT.CLIENT_TITLE"/>
                        </DSTableCell>
                        <DSTableCell>
                            <Translate keyId="ADMIN.CLUBREADY_CONNECT.ACTION"/>
                        </DSTableCell>
                    </DSTableRow>
                </DSTableHead>
                <DSTableBody>
                    <DSTableRow>
                        <DSTableCell>
                            <DSTextField
                                label={<Translate keyId="ADMIN.CLUBREADY_CONNECT.FILTER_CLIENTS"/>}
                                variant="outlined"
                                {...searchProps}
                            />
                        </DSTableCell>
                    </DSTableRow>
                </DSTableBody>
                <DSTableBodyLoadable loading={isLoading}>
                    {clientClubReadyIntegrations.map(integration => (
                        <StyledTableRow key={integration.clientId}>
                            <DSTableCell>
                                {integration.clientTitle}
                            </DSTableCell>
                            <DSTableCell align="left">
                                <>
                                    {integration.integrationId && (
                                        <ClubReadyConfigureClientButton
                                            clientTitle={integration.clientTitle}
                                            integrationId={integration.integrationId}
                                        />
                                    )}
                                    {!integration.integrationId && (
                                        <DSTypography>
                                            <Translate keyId="ADMIN.CLUBREADY_CONNECT.NO_INTEGRATION_FOUND_FOR_CLIENT"/>
                                        </DSTypography>
                                    )}
                                </>
                            </DSTableCell>
                        </StyledTableRow>
                    ))}
                </DSTableBodyLoadable>
            </DSTable>
        </DSTableContainer>
    );
}

ClubReadyChainIdConfigurationTable.displayName = 'ClubReadyChainIdConfigurationTable';

ClubReadyChainIdConfigurationTable.fragments = {
    CLUBREADY_CHAIN_ID_CONFIGURATION_TABLE_FRAGMENT,
};
