/// <reference path="../../../typings/browser.d.ts" />
import { isNullOrUndefined, Untyped } from '@deltasierra/shared';

export interface IScrollToOptions {
    duration: number;
}

export class NavUtils {
    public static SID = 'navUtils';

    public static readonly $inject: string[] = ['$location'];

    public constructor(private $location: angular.ILocationService) {}

    public isActive(route: string) {
        return route === this.$location.path();
    }

    public scrollTo(target: JQuery | number | string): Untyped;

    public scrollTo(target: JQuery | number | string, duration: number): Untyped;

    public scrollTo(target: JQuery | number | string, options: IScrollToOptions): Untyped;

    public scrollTo(target: JQuery | number | string, options?: any) {
        const defaultDuration = 1000;

        // Get duration depending on whether it's passed as number or inside options
        let duration = typeof options === 'object' ? options && options.duration : parseInt(options, 10);

        // Use default duration if invalid/omitted
        duration = isNullOrUndefined(duration) || isNaN(duration) ? defaultDuration : duration;

        angular.element('html, body').animate(
            {
                scrollTop: angular.element(target).offset()!.top,
            },
            duration,
        );
    }
}

angular.module('app').service(NavUtils.SID, NavUtils);
