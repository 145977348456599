import { ActionButton, DSMenuItem, Translate } from '@deltasierra/components';
import React from 'react';

type BlockedPublishesActionsProps = {
    disabled: boolean;
    pushToFailed: () => void;
    pushToSent: () => void;
    sendNow: () => void;
};

export function BlockedPublishesActions({
    disabled,
    pushToFailed,
    pushToSent,
    sendNow,
}: BlockedPublishesActionsProps): JSX.Element {
    return (
        <ActionButton>
            <DSMenuItem disabled={disabled} onClick={pushToFailed}>
                <Translate keyId="ADMIN.SERVICING.BLOCKED_PUBLISHES.TABLE.ACTIONS.MARK_FAILED" />
            </DSMenuItem>
            <DSMenuItem disabled={disabled} onClick={pushToSent}>
                <Translate keyId="ADMIN.SERVICING.BLOCKED_PUBLISHES.TABLE.ACTIONS.MARK_SUCCESSFUL" />
            </DSMenuItem>
            <DSMenuItem disabled={disabled} onClick={sendNow}>
                <Translate keyId="ADMIN.SERVICING.BLOCKED_PUBLISHES.TABLE.ACTIONS.RETRY_NOW" />
            </DSMenuItem>
        </ActionButton>
    );
}
BlockedPublishesActions.displayName = 'BlockedPublishesActions';
