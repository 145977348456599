import * as React from 'react';
import styled from 'styled-components';
import { DSTypography } from '@deltasierra/components';
import { gql } from '@apollo/client';
import { TemplateCaptionForScheduledFragment } from './__graphqlTypes/TemplateCaptionForScheduledFragment';

const CaptionWrapper = styled.div`
    height: 120px;
    max-width: 18vw;
    overflow-wrap: break-word;
    overflow-y: hidden;
    position: relative;
    white-space: pre-wrap;
`;

const CaptionFade = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 40px;
    background-image: linear-gradient(to bottom, transparent, white);
`;

const fragments = {
    TemplateCaptionForScheduledFragment: gql`
        fragment TemplateCaptionForScheduledFragment on PostArtifact {
            id
            content {
                caption
            }
        }
    `,
};

export type TemplateCaptionForScheduledProps = {
    post: TemplateCaptionForScheduledFragment;
};

export function TemplateCaptionForScheduled({ post }: TemplateCaptionForScheduledProps): JSX.Element {
    return (
        <CaptionWrapper>
            <DSTypography color="textSecondary" component="p" variant="caption">
                {post.content.caption}
            </DSTypography>
            <CaptionFade />
        </CaptionWrapper>
    );
}

TemplateCaptionForScheduled.fragments = fragments;
