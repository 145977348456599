import { Dropdown } from '@deltasierra/components';
import { noop } from '@deltasierra/object-utilities';
import * as React from 'react';
import styled from 'styled-components';
import { OneWayBinding, OptionalExpressionBinding } from '../../../common/angularData';
import { withAngularIntegration } from '../../../common/componentUtils/reactComponentRegistration';
import { useGetMonths } from './hooks';
import { PlannerMonthPickerDate } from './PlannerMonthPickerDate';
import { defaultColor, white } from './utils';

export type PlannerMonthPickerProps = {
    month: string;
    onChange?: (month: string) => void;
};

export const PlannerMonthPicker: React.FC<PlannerMonthPickerProps> = ({ month, onChange = noop, ...rest }) => {
    const [monthObject, monthObjects] = useGetMonths(month);

    return (
        <Dropdown
            getOptionTemplate={mnth => <PlannerMonthPickerListItem>{mnth.formattedDate}</PlannerMonthPickerListItem>}
            getValue={mnth => mnth.date}
            items={monthObjects}
            menuStyle={{ marginTop: '-34px', width: '100%' }}
            render={({ requestToggle }) => (
                <PlannerMonthPickerDate month={monthObject.formattedDate} onClick={requestToggle} />
            )}
            value={monthObject}
            onSelect={mnth => onChange(mnth.date)}
        />
    );
};
PlannerMonthPicker.displayName = 'PlannerMonthPicker';

const PlannerMonthPickerListItem = styled.div`
    background-color: ${white}
    color: ${defaultColor}
    &:hover {
        background-color: ${defaultColor};
        color: ${white};
    }
`;

export const PlannerMonthPickerWithAngular = withAngularIntegration(PlannerMonthPicker, 'plannerMonthPicker', {
    month: OneWayBinding,
    onChange: OptionalExpressionBinding,
});
