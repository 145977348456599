import { LocationIdHierarchy } from '@deltasierra/shared';
import { compareStrings } from '../compare';

export type StatsEntry<T> = T & {
    location: LocationIdHierarchy;
};

export interface PlatformStats<T> {
    entries: Array<StatsEntry<T>>;
    totals: T;
}

export type ReportTableEntry<E> = E & {
    reportTableEntryDepth?: number;
    rowIsExpanded?: boolean;
};

export enum SortOrder {
    Desc = -1,
    Asc = 1,
}

export enum TextAlignClass {
    Left = 'text-left',
    Center = 'text-center',
    Right = 'text-right',
}

export interface ReportTableColumn<E extends StatsEntry<T>, T, V> {
    label: () => string;
    cssClass: TextAlignClass;
    format: (value: V, entry: E | T) => string;
    getValue: (entry: E) => V;
    getTotal: (totalEntry: T) => V | null | undefined;
    compare?: (a: V, b: V) => number;
    width?: string;
    wrapHeader?: boolean;
    getCellClickHandler?: (entry?: E) => (entry?: E) => void;
    getHeaderHint?: () => string;
    getIsVisible?: (reportTable?: ReportTable<E, T>) => boolean;
    getColSpan?: (entry: E) => number;
    popoverMessage?: () => string;
    showPopover?: (entry: E) => boolean;
}

export interface ReportTableTreeOptions<E> {
    getChildEntries: (entry: E) => E[];
    getIsEntryExpanded?: (entry: E) => boolean;
    setIsEntryExpanded?: (entry: E, value: boolean) => void;
    canExpandOrCollapseEntry: (entry: E) => boolean;
}

export interface RowFooterOptions<E> {
    getTemplateUrl: (entry: E) => string | null;
    getOptions?: (entry: E) => any;
    getIsHiddenWhenCollapsed?: (entry: E) => boolean;
}

export interface ReportTableColumnSortOptions {
    columnIndex: number;
    order: SortOrder;
}

export interface ReportTableTotalOptions {
    isEnabled: boolean;
}

export interface ReportTableOptions<E extends StatsEntry<T>, T> {
    columns: Array<ReportTableColumn<E, T, any>>;
    sortedByColumn?: ReportTableColumnSortOptions;
    tree?: ReportTableTreeOptions<E>;
    rowFooter?: RowFooterOptions<E>;
    totals?: ReportTableTotalOptions;
}

export function isReportTableATree(reportTable: ReportTable<any, any>): boolean {
    return !!reportTable.options.tree;
}

export class ReportTableData<E extends ReportTableEntry<T> & StatsEntry<T>, T> {
    public entries: E[] | null = null;

    public totals: T | null = null;

    public reportPeriod = 0;

    private readonly isATree: boolean;

    public constructor(private options: ReportTableOptions<E, T>) {
        this.isATree = !!options.tree;
    }

    public canExpandOrCollapseEntry(entry: E): boolean {
        const isToggleDisallowedByOverride =
            this.isATree &&
            this.options.tree &&
            this.options.tree.canExpandOrCollapseEntry &&
            !this.options.tree.canExpandOrCollapseEntry(entry);

        if (isToggleDisallowedByOverride) {
            return false;
        }

        if (this.isATree && this.options.tree && this.options.tree.getChildEntries(entry).length > 0) {
            return true;
        }

        if (
            this.options.rowFooter &&
            this.options.rowFooter.getTemplateUrl(entry) &&
            this.options.rowFooter.getIsHiddenWhenCollapsed &&
            this.options.rowFooter.getIsHiddenWhenCollapsed(entry)
        ) {
            return true;
        }

        return false;
    }

    public getChildEntries(entry: E): E[] {
        return (this.options.tree && this.options.tree.getChildEntries(entry)) || ReportTable.EMPTY_ENTRY_ARRAY;
    }

    public getEntries(): E[] {
        return this.entries || ReportTable.EMPTY_ENTRY_ARRAY;
    }

    public getEntryDepth(entry: E): number {
        return this.isATree ? entry.reportTableEntryDepth || 0 : 0;
    }

    public getIsEntryExpanded(entry: E): boolean {
        if (!this.isATree) {
            return false;
        }

        if (this.options.tree && this.options.tree.getIsEntryExpanded) {
            return this.options.tree.getIsEntryExpanded(entry);
        } else {
            return !!entry.rowIsExpanded;
        }
    }

    public getSortedByColumn(): ReportTableColumnSortOptions | undefined {
        return this.options.sortedByColumn;
    }

    public getTotals(): T | null {
        return this.totals;
    }

    public setIsEntryExpanded(entry: E, isExpanded: boolean): void {
        if (this.isATree && this.options.tree) {
            if (this.options.tree.setIsEntryExpanded) {
                this.options.tree.setIsEntryExpanded(entry, isExpanded);
            } else {
                entry.rowIsExpanded = isExpanded;
            }
        }
    }

    public update(entries: E[], totals: T, period: number): void {
        this.entries = entries;
        this.totals = totals;
        this.reportPeriod = period;
        this.sortEntries(this.entries);
    }

    public sortByColumn(sortOptions: ReportTableColumnSortOptions): void {
        this.options.sortedByColumn = sortOptions;
        this.sortEntries(this.entries || []);
    }

    private compareEntries(entry1: E, entry2: E): number {
        const sortedByColumnIndex = this.options.sortedByColumn ? this.options.sortedByColumn.columnIndex : 0;
        const sortedByOrder = this.options.sortedByColumn ? this.options.sortedByColumn.order : SortOrder.Asc;
        const sortedByColumn = this.options.columns[sortedByColumnIndex];
        type ValueType = ReturnType<typeof sortedByColumn['getValue']>;
        const compare = sortedByColumn.compare
            ? (value1: ValueType, value2: ValueType) => sortedByColumn.compare!(value1, value2)
            : (value1: ValueType, value2: ValueType) => compareStrings(value1, value2);

        return compare(sortedByColumn.getValue(entry1), sortedByColumn.getValue(entry2)) * sortedByOrder;
    }

    private sortEntries(entries: E[]) {
        entries.sort((one, two) => this.compareEntries(one, two));

        for (const entry of entries) {
            const childEntries = this.getChildEntries(entry);
            this.sortEntries(childEntries);
        }
    }
}

export class ReportTable<E extends StatsEntry<T>, T> {
    // Have one reference for empty rows so that we can use to return empty arrays
    // Without returning a new empty array each time which angular watches don't like
    public static readonly EMPTY_ENTRY_ARRAY: any[] = [];

    public readonly data: ReportTableData<E, T>;

    public readonly options: ReportTableOptions<E, T>;

    public constructor(options: ReportTableOptions<E, T>) {
        if (options.columns.length === 0) {
            throw new Error('A report table cannot be initialised with zero columns.');
        }

        // If no default sort specified then sort by first column ascending
        options.sortedByColumn ||= {
            columnIndex: 0,
            order: SortOrder.Asc,
        };

        this.options = options;
        this.data = new ReportTableData(this.options);
    }
}
