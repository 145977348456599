import { LocationShippingDetails } from '@deltasierra/shared';
import { clone } from '@deltasierra/object-utilities';
import { ExpressionCallback } from '../../common/angularData';
import { ModalService } from '../../typings/angularUIBootstrap/modalService';
import { $modalSID } from '../../common/angularUIBootstrapData';
import { ShippingDetailsController, LocationShippingDetailsLocals } from './shippingDetailsController';

export class ShippingAddressEditorController {
    // Props
    public locationShippingDetails!: LocationShippingDetails;

    public onChange!: ExpressionCallback<{ locationShippingDetails: LocationShippingDetails }>;

    public editingRequired!: boolean;

    static readonly $inject: string[] = [
        $modalSID,
    ];

    constructor(
        private readonly $modal: ModalService,
    ) {
    }

    openEditModal(details: LocationShippingDetails) {
        const modalInstance = this.$modal.open({
            templateUrl: '/partials/directives/shippingAddressEditor/editShippingDetails',
            controller: ShippingDetailsController,
            controllerAs: 'ctrl',
            resolve: <LocationShippingDetailsLocals>{
                details: () => clone(details),
            },
        });

        return modalInstance.result.then((value: LocationShippingDetails) => this.onChange({ locationShippingDetails: value }));
    }

}
