/// <reference path="../../../../../typings/browser.d.ts" />
import {
    Currency,
    IUpdatePrintProviderSKUGroupBody,
    PrintProviderSKUGroup,
    PrintProviderSKUGroupId,
} from '@deltasierra/shared';
import { $locationSID, $routeParamsSID } from '../../../common/angularData';
import { currencyPattern } from '../../../common/currencyInput';
import { TabNavEntry } from '../../../common/dsTabNav';
import { InteractionUtils } from '../../../common/interactionUtils';
import { MvNotifier } from '../../../common/mvNotifier';
import { I18nService } from '../../../i18n/i18nService';
import { CurrencyService } from '../../../payments/currencyService';
import {
    AgencySKUGroupExpectedRouteParams,
    AgencySKUGroupIds,
    extractIdsFromRouteParams,
    getAgencySKUGroupTabNavEntries,
} from '../common';
import { paymentGatewayOptions } from '../controller';
import { SKUManagementApiClient } from '../skuManagementApiClient';

export class SKUGroupDetailsController {
    public static SID = 'SKUGroupDetailsController';

    fetchSKUGroup = this.interactionUtils.createFuture(
        'Get Product',
        (context: { printProviderSKUGroupId: PrintProviderSKUGroupId }) =>
            this.skuManagementApiClient.getSKUGroup(context.printProviderSKUGroupId).then(skuGroup => {
                this.skuGroup = skuGroup;
            }),
    );

    updateSKUGroup = this.interactionUtils.createFuture(
        'Update Product',
        (context: {
            printProviderSKUGroupId: PrintProviderSKUGroupId;
            skuGroupData: IUpdatePrintProviderSKUGroupBody;
        }) =>
            this.skuManagementApiClient
                .updateSKUGroup(context.printProviderSKUGroupId, context.skuGroupData)
                .then(() => {
                    this.mvNotifier.notify(this.i18n.text.agency.printSkuGroups.skuGroups.skuGroupUpdated());
                }),
    );

    deleteSKUGroup = this.interactionUtils.createFuture(
        'Delete Product',
        (context: { printProviderSKUGroupId: PrintProviderSKUGroupId }) =>
            this.skuManagementApiClient.deleteSKUGroup(context.printProviderSKUGroupId).then(() => {
                this.mvNotifier.notify(this.i18n.text.agency.printSkuGroups.skuGroups.skuGroupDeleted());
                this.$location.path('/agency/printProviderSKUGroups');
            }),
    );

    skuGroup: PrintProviderSKUGroup | null = null;

    tabNavEntries: ReadonlyArray<TabNavEntry> = [];

    tabNavParams: AgencySKUGroupIds;

    paymentGatewayOptions = paymentGatewayOptions;

    // Form data
    currencies: Currency[] | ReadonlyArray<Currency>;

    currencyPattern = currencyPattern;

    static readonly $inject: string[] = [
        $routeParamsSID,
        $locationSID,
        I18nService.SID,
        MvNotifier.SID,
        InteractionUtils.SID,
        SKUManagementApiClient.SID,
        CurrencyService.SID,
    ];

    constructor(
        $routeParams: AgencySKUGroupExpectedRouteParams,
        private readonly $location: ng.ILocationService,
        private readonly i18n: I18nService,
        private readonly mvNotifier: MvNotifier,
        private readonly interactionUtils: InteractionUtils,
        private readonly skuManagementApiClient: SKUManagementApiClient,
        currencyService: CurrencyService,
    ) {
        this.currencies = currencyService.CURRENCIES;
        const ids = extractIdsFromRouteParams($routeParams);
        if (ids.skuGroupId) {
            void this.fetchSKUGroup.run({ printProviderSKUGroupId: ids.skuGroupId });
        }
        this.tabNavParams = ids;
        this.tabNavEntries = getAgencySKUGroupTabNavEntries(this.i18n);
    }

    onClickDelete(): void {
        void this.deleteSKUGroup.run({ printProviderSKUGroupId: PrintProviderSKUGroupId.from(this.skuGroup!.id) });
    }

    onClickUpdate(): void {
        if (this.skuGroup) {
            if (!this.skuGroup.paymentUrl || this.skuGroup.paymentUrl === '') {
                this.skuGroup.paymentUrl = null;
            }
            if (!this.skuGroup.orderInformation || this.skuGroup.orderInformation === '') {
                this.skuGroup.orderInformation = null;
            }
            void this.updateSKUGroup.run({
                printProviderSKUGroupId: PrintProviderSKUGroupId.from(this.skuGroup.id),
                skuGroupData: this.skuGroup,
            });
        }
    }
}

angular.module('app').controller(SKUGroupDetailsController.SID, SKUGroupDetailsController);
