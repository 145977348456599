import * as React from 'react';
import styled from 'styled-components';
import { DSFormLabel, Translate, Well } from '@deltasierra/components';
import { t } from '@deltasierra/shared';
import { withAngularIntegration } from '../../../../common/componentUtils/reactComponentRegistration';
import { OneWayBinding } from '../../../../common/angularData';

const StyledWell = styled(Well)`
    background-color: #f5f5f5;
    border-radius: 3px;
    border: 1px solid lightgrey;
    box-shadow: none;
    color: #737373;
    margin-bottom: 0px;
    padding: 9px;
`;

export type TermsAndConditionsPublishMessageProps = {
    text: string | null;
    url: string | null;
    useNewLabel?: boolean;
};

export const TermsAndConditionsPublishMessage: React.FC<TermsAndConditionsPublishMessageProps> = ({
    text,
    url,
    useNewLabel,
}) => (
    <>
        {(text || url) && (
            <div className="form-group">
                {useNewLabel ? (
                    <DSFormLabel>
                        <Translate keyId="COMMON.TERMS_AND_CONDITIONS_WITH_SYMBOL" />
                    </DSFormLabel>
                ) : (
                    <label>
                        <Translate keyId="COMMON.TERMS_AND_CONDITIONS_WITH_SYMBOL" />
                    </label>
                )}
                <StyledWell>
                    {text && <span>{`${text} `}</span>}
                    {url && (
                        <a
                            href={url.startsWith('http://') || url.startsWith('https://') ? url : `http://${url}`}
                            rel="noreferrer noopener"
                            target="_blank"
                        >
                            {url}
                        </a>
                    )}
                </StyledWell>
                <p className="help-block">
                    <em>{t('BUILD.PUBLISH.TERMS_AND_CONDITIONS_HELP_TEXT')}</em>
                </p>
            </div>
        )}
    </>
);

TermsAndConditionsPublishMessage.displayName = 'TermsAndConditionsPublishMessage';

export default withAngularIntegration(TermsAndConditionsPublishMessage, 'termsAndConditionsPublishMessage', {
    text: OneWayBinding,
    url: OneWayBinding,
});
