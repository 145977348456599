angular.module('app', [
    'ngSentry',
    'ngResource',
    'ngRoute',
    'ngSanitize',
    'ngKookies',
    'ui',
    'ui.checkbox',
    'ui.bootstrap',
    'ui.directives',
    'ngDragDrop',
    'angularFileUpload',
    'frapontillo.bootstrap-switch',
    'slugifier',
    'chart.js',
    'ngCropper',
    'textAngular',
    'angular.filter',
    'jm.i18next',
    'countUpModule',
    'ngAnimate',
    'google.places',
    'ui.toggle',
    'angularjs-dropdown-multiselect',
]);

export default {};
