/// <reference path="../../../typings/browser.d.ts" />
import { ClientId, Nullable, TryThisSection, TryThisSubSection } from '@deltasierra/shared';
import { $routeParamsSID, $scopeSID } from '../common/angularData';
import { DataUtils } from '../common/dataUtils';
import { DirtyStateChecker, DirtyStateCheckerFactory } from '../common/dirtyStateChecker';
import { InteractionUtils } from '../common/interactionUtils';
import { MvNotifier } from '../common/mvNotifier';
import { I18nService } from '../i18n/i18nService';
import { MvTryThis } from '../trythis/mvTryThis';
import IPromise = angular.IPromise;
import IScope = angular.IScope;

export class ClientLearnMenuCtrl {
    public static SID = 'ClientLearnMenuCtrl';

    clientId: ClientId;

    sections?: Array<Nullable<TryThisSection>>;

    newSectionTitle: string | null = null;

    loading = {
        sections: false,
        saveLearnMenu: false,
    };

    deletedSubSections: number[] = [];

    deletedSections: number[] = [];

    dirtyStateChecker: DirtyStateChecker;

    static readonly $inject: string[] = [
        $scopeSID,
        $routeParamsSID,
        MvNotifier.SID,
        InteractionUtils.SID,
        DataUtils.SID,
        DirtyStateCheckerFactory.SID,
        MvTryThis.SID,
        I18nService.SID,
    ];

    constructor(
        $scope: IScope,
        $routeParams: { [key: string]: string },
        private readonly mvNotifier: MvNotifier,
        private readonly interactionUtils: InteractionUtils,
        private readonly dataUtils: DataUtils,
        private readonly dirtyStateCheckerFactory: DirtyStateCheckerFactory,
        private readonly mvTryThis: MvTryThis,
        private readonly i18n: I18nService,
    ) {
        this.dirtyStateChecker = this.dirtyStateCheckerFactory.createChecker($scope, [
            {
                watcher: () => this.sections,
                deep: true,
            },
        ]);
        this.clientId = ClientId.from(parseInt($routeParams.id, 10));
        void this.loadTryThisSections();
    }

    private loadTryThisSections(): IPromise<any> {
        return this.interactionUtils
            .handleRemote(
                this,
                'retrieve learn menu sections',
                'sections',
                this.mvTryThis.getTryThisSections(this.clientId),
            )
            .then((sections: TryThisSection[]) => {
                this.sections = sections;
                this.dirtyStateChecker.startWatching();
            });
    }

    save() {
        return this.interactionUtils
            .handleRemote(
                this,
                'save learn menu',
                'saveLearnMenu',
                this.mvTryThis.saveTryThisSections(
                    this.clientId,
                    this.sections as TryThisSection[],
                    this.deletedSections,
                    this.deletedSubSections,
                ),
            )
            .then((sections: TryThisSection[]) => {
                this.mvNotifier.notify(this.i18n.text.agency.client.location.learnMenu.learnMenuSaved());
                this.dirtyStateChecker.reset();
                this.sections = sections;
                this.deletedSections.length = 0;
                this.deletedSubSections.length = 0;
                this.dirtyStateChecker.startWatching();
            });
    }

    deleteSection(section: TryThisSection) {
        this.dataUtils.removeObject(section, this.sections!);
        if (section.id) {
            this.deletedSections.push(section.id);
        }
    }

    deleteSubSection(subSection: TryThisSubSection, section: TryThisSection) {
        this.dataUtils.removeObject(subSection, section.subSections || []);
        if (subSection.id) {
            this.deletedSubSections.push(subSection.id);
        }
    }

    moveSectionUp(section: TryThisSection) {
        const wasMoved = this.dataUtils.moveUp(section, this.sections!);
        if (wasMoved) {
            this.dataUtils.rectifyOrder(this.sections as TryThisSection[]);
        }
    }

    moveSectionDown(section: TryThisSection) {
        const wasMoved = this.dataUtils.moveDown(section, this.sections!);
        if (wasMoved) {
            this.dataUtils.rectifyOrder(this.sections as TryThisSection[]);
        }
    }

    moveSubSectionUp(subSection: TryThisSubSection, section: TryThisSection) {
        const wasMoved = this.dataUtils.moveUp(subSection, section.subSections || []);
        if (wasMoved) {
            this.dataUtils.rectifyOrder(section.subSections || []);
        }
    }

    moveSubSectionDown(subSection: TryThisSubSection, section: TryThisSection) {
        const wasMoved = this.dataUtils.moveDown(subSection, section.subSections || []);
        if (wasMoved) {
            this.dataUtils.rectifyOrder(section.subSections || []);
        }
    }

    addNewSection() {
        const order = this.sections!.length;
        const newSection: Nullable<TryThisSection> = {
            title: this.newSectionTitle,
            clientId: this.clientId,
            order,
            id: null,
            subSections: [],
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            createdById: null,
            updatedById: null,
            deletedById: null,
        };
        this.sections!.push(newSection);
        this.newSectionTitle = null;
    }
}

angular.module('app').controller(ClientLearnMenuCtrl.SID, ClientLearnMenuCtrl);
