import { DSFormControl, DSFormHelperText, DSFormLabel, DSRadioGroup, DSRadioGroupProps } from '@deltasierra/components';
import { KeysOfType, KeysOfTypeLoose } from '@deltasierra/type-utilities';
import { FormikProps, useField } from 'formik';
import React from 'react';

type DSRadioGroupFormikKeys<T> = Exclude<
    KeysOfType<T, number> | KeysOfType<T, string> | KeysOfTypeLoose<T, number> | KeysOfTypeLoose<T, string>,
    undefined
>;

export type DSRadioGroupFormikProps<T> = Omit<DSRadioGroupProps, 'name' | 'onBlur' | 'onChange' | 'value'> & {
    error?: boolean;
    formik?: FormikProps<T>;
    helperText?: JSX.Element;
    label?: JSX.Element;
    name: DSRadioGroupFormikKeys<T>;
};

export function DSRadioGroupFormik<T>({
    /**
     * We pass formik through for type safety only
     */
    error,
    formik,
    helperText: _helperText,
    label,
    name,
    ...props
}: DSRadioGroupFormikProps<T>): JSX.Element {
    const [field, meta] = useField({
        name: name as string,
        type: 'radio',
        value: formik?.values[name] as number | string | undefined,
    });

    const showError = !!meta.error && meta.touched;

    const helperText = (showError ? meta.error : undefined) ?? _helperText;

    return (
        <DSFormControl error={showError || error}>
            {label && <DSFormLabel>{label}</DSFormLabel>}
            <DSRadioGroup
                {...props}
                name={field.name}
                value={field.value}
                onBlur={field.onBlur}
                onChange={field.onChange}
            />
            {helperText && <DSFormHelperText>{helperText}</DSFormHelperText>}
        </DSFormControl>
    );
}
DSRadioGroupFormik.displayName = 'DSRadioGroupFormik';
