import { gql } from '@apollo/client';

export const GET_CLUB_READY_LOCATIONS_VERIFIED_SENDER = gql`
    query GetClubReadyLocationsVerifiedSender($locationIds: [ID!]!) {
        locations(ids: $locationIds) {
            edges {
                node {
                    id
                    clubReady {
                        ... on ClubReady {
                            id
                            sender {
                                name
                                email
                                isVerified
                            }
                        }
                    }
                }
            }
        }
    }
`;
