import { CurrencyCode, CurrencyNumber, LocationId } from '@deltasierra/shared';

import { $modalInstanceSID } from '../../common/angularUIBootstrapData';
import { ModalInstance } from '../../typings/angularUIBootstrap/modalService';
import { I18nService } from '../../i18n';
import { AgencyService } from '../../agencies/service';
import { MvNotifier } from '../../common/mvNotifier';
import { InteractionUtils } from '../../common/interactionUtils';

export interface GoogleAdWordsSpendAdjustmentRequestOptions {
    location: {
        id: LocationId;
        title: string;
    };
    currencyCode: CurrencyCode;
    adjustedSpendPerDay: CurrencyNumber;
    recommendedSpendPerDay: CurrencyNumber;
}

export class GoogleAdWordsSpendAdjustmentRequestController {
    additionalNotes?: string;

    sendRequest = this.interactionUtils.createFuture(this.i18n.text.report.googleAdwordsSpendAdjustmentRequest.sendRequest(), () => this.agencyService
            .requestGoogleAdWordsSpendAdjustment(
                this.options.location.id,
                this.options.adjustedSpendPerDay,
                this.options.recommendedSpendPerDay,
                this.options.currencyCode,
                this.additionalNotes,
            )
            .then(() => {
                this.$modalInstance.close();
                this.notifier.notify(this.i18n.text.report.googleAdwordsSpendAdjustmentRequest.requestHasBeenSent());
            }, err => {
                this.notifier.unexpectedErrorWithData(this.i18n.text.report.googleAdwordsSpendAdjustmentRequest.failedToRequestSpendAdjustment(), err);
            }));

    static readonly $inject = [
        $modalInstanceSID,
        I18nService.SID,
        'options',
        AgencyService.SID,
        MvNotifier.SID,
        InteractionUtils.SID,
    ];

    constructor(
        private readonly $modalInstance: ModalInstance,
        private readonly i18n: I18nService,
        public readonly options: GoogleAdWordsSpendAdjustmentRequestOptions,
        private readonly agencyService: AgencyService,
        private readonly notifier: MvNotifier,
        private readonly interactionUtils: InteractionUtils,
    ) {
    }

    cancel() {
        this.$modalInstance.dismiss();
    }

    formatCurrency(value: number): string {
        return this.i18n.formatCurrency(value, {
            format: 'code',
            code: this.options.currencyCode,
        });
    }

    getMessageToAgency() {
        const adjustedSpendPerDayFormatted = this.i18n.formatCurrency(this.options.adjustedSpendPerDay, {
            format: 'code',
            code: this.options.currencyCode,
        });

        const recommendedSpendPerDayFormatted = this.i18n.formatCurrency(this.options.recommendedSpendPerDay, {
            format: 'code',
            code: this.options.currencyCode,
        });

        let result = this.i18n.text.report.googleAdwordsSpendAdjustmentRequest.increaseMyGoogleAdwordsBudget({
            adjustedSpendPerDay: adjustedSpendPerDayFormatted,
            location: this.options.location.title,
        });

        if (this.options.adjustedSpendPerDay > this.options.recommendedSpendPerDay) {
            result += `\n\n${this.i18n.text.report.googleAdwordsSpendAdjustmentRequest.extraStepsMayBeRequired({
                recommendedSpendPerDay: recommendedSpendPerDayFormatted,
            })}`;
        }

        return result;
    }
}
