import * as React from 'react';
import { t } from '@deltasierra/shared';
import { useAngularServiceContext } from '../../../../../common/componentUtils/angularServiceContexts';

export interface InitialLocationData {
    id: string;
    title: string;
}

export type ScheduledPublish = {
    id: string;
    schedulingStatus: string;
};
export interface PublishAndReturnExecution {
    scheduledPublish: ScheduledPublish;
    locationId: string;
}
export interface MultiLocationLocationLoaderData {
    location: InitialLocationData;
    scheduledPublish: ScheduledPublish | null;
}

export type MultiLocationLoaderPublishFunc = (
    initialLocations: InitialLocationData[],
    mutationFunc: () => Promise<PublishAndReturnExecution[]>,
) => void;

export function useMultiLocationLoader(): {
    initialLocationProgressList: MultiLocationLocationLoaderData[];
    isPublishing: boolean;
    publish: MultiLocationLoaderPublishFunc;
} {
    const notifier = useAngularServiceContext('mvNotifier');
    const sentryService = useAngularServiceContext('SentryService');
    const [isPublishing, setIsPublishing] = React.useState(false);

    const [initialLocationProgressList, setInitialLocationsProgressList] = React.useState<
        MultiLocationLocationLoaderData[]
    >([]);

    const publishCallback: MultiLocationLoaderPublishFunc = async (initialLocations, mutationFunc) => {
        try {
            const jobList: PublishAndReturnExecution[] = await mutationFunc();
            setInitialLocationsProgressList(
                initialLocations.map(inLoc => ({
                    location: inLoc,
                    scheduledPublish: jobList.find(job => job.locationId === inLoc.id)?.scheduledPublish ?? null,
                })),
            );
            setIsPublishing(true);
        } catch (error) {
            sentryService.captureException(t('ERRORS.PUBLISH.ERRORS.GENERIC_ERROR_MESSAGE'), error);
            notifier.unexpectedError(t('ERRORS.PUBLISH.ERRORS.GENERIC_ERROR_MESSAGE'));
        }
    };

    return {
        initialLocationProgressList,
        isPublishing,
        publish: publishCallback,
    };
}
