import { ChannelAdderController } from './controller';

function ChannelAdderDirective() : ng.IDirective<ng.IScope> {
    return {
        restrict: 'E',
        scope: {},
        templateUrl: '/partials/clients/activities/channel-adder/template',
        controller: ChannelAdderController,
        controllerAs: 'ctrl',
        bindToController: {
            buildingPlatforms: '=',
            activity: '<',
        },
    };
}

export const SID = 'dsChannelAdder';

angular.module('app').directive(SID, [ChannelAdderDirective]);
