/// <reference path="../../../../typings/browser.d.ts" />
import { $resourceSID } from '../../common/angularData';
import IResourceService = angular.resource.IResourceService;
export const mvReportSummaryResourceSID = 'mvReportSummaryResource';

angular.module('app').factory(mvReportSummaryResourceSID, [
    $resourceSID,
    function ($resource : IResourceService) {
    const ReportSummaryResource = $resource(
        '/api/reports/summaries/:id',
    );

    return ReportSummaryResource;
},
]);
