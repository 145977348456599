import { DSGrid, DSIconButton } from '@deltasierra/components';
import Trash from '@material-ui/icons/Delete';
import * as React from 'react';

export function Grid(props: { children: React.ReactNode; onBack?: () => void }): JSX.Element {
    return (
        <DSGrid alignItems="flex-start" container direction="row">
            <DSGrid alignItems="center" container item spacing={1} xs>
                {props.children}
            </DSGrid>
            <DSGrid item>
                <DSIconButton color="primary" onClick={props.onBack}>
                    <Trash />
                </DSIconButton>
            </DSGrid>
        </DSGrid>
    );
}
Grid.displayName = 'Grid';
