// Must be an angular issue, make sure global is set
(window as any).global = window;

import '../css/index.styl';
import './app';

import './account';
import './activities';
import './admin';
import './agency';
import './agencies';
import './brands';
import './channels';
import './clients';
import './comments';
import './common';
import './contentBuilder';
import './directives';
import './filters';
import './i18n';
import './inAppNotifications';
import './integration';
import './locations';
import './main';
import './overview';
import './payments';
import './planner';
import './platforms';
import './reports';
import './shoppingCart';
import './specialRequests';
import './tags';
import './templateGallery';
import './trythis';
import './sockets';
import './scheduledPosts';
import './DsReactApp';
import './assetLibrary/AssetLibraryModal';

// Required for angular integrations
import './domain';
