import * as React from 'react';
import moment from 'moment';
import { useAngularServiceContext } from '../../../common/componentUtils/angularServiceContexts';

export const formatDate = (date: Date | string, format?: string): string => moment(date).format(format ?? 'MMMM YYYY');

export const formatDateWithIntl = (date: Date, localeCode = 'default'): string =>
    new Intl.DateTimeFormat(localeCode, { month: 'long', year: 'numeric' }).format(date);

export type DatePickerItem = {
    formattedDate: string;
    date: string;
};

export const useGetMonths = (date: string | undefined, monthRange = 6): [DatePickerItem, DatePickerItem[]] => {
    const identityService = useAngularServiceContext('mvIdentity');
    const userLocale = React.useMemo(
        () => identityService.currentUser?.localeCode,
        [identityService.currentUser?.localeCode],
    );

    const months = React.useMemo(() => {
        const rawMonths = [];
        let dateCounter = date ? new Date(date) : new Date();
        dateCounter = new Date(Date.UTC(dateCounter.getFullYear(), dateCounter.getMonth(), 1));
        const startMonth = dateCounter.getUTCMonth() - monthRange;
        dateCounter.setUTCMonth(startMonth);
        for (let i = 0; i < monthRange * 2; i++) {
            dateCounter.setUTCMonth(dateCounter.getUTCMonth() + 1);
            rawMonths.push({
                date: formatDate(dateCounter, 'YYYY-MM-DD'),
                formattedDate: formatDateWithIntl(dateCounter, userLocale),
            });
        }
        return rawMonths;
    }, [date, monthRange, userLocale]);

    const month = React.useMemo(() => {
        const rawDate = date ?? formatDate(moment().add(1, 'months').utc().toDate(), 'YYYY-MM-DD');
        return { date: rawDate, formattedDate: formatDateWithIntl(moment(rawDate).toDate(), userLocale) };
    }, [date, userLocale]);

    return [month, months];
};
