/// <reference path="../../../typings/browser.d.ts" />
import { $parseSID, RestrictToAttribute } from '../common/angularData';
import IAugmentedJQuery = angular.IAugmentedJQuery;
import IScope = angular.IScope;
import IAttributes = angular.IAttributes;
import IParseService = angular.IParseService;

export const scrolledToLeftEdgeSID = 'scrolledToLeftEdge';

export const scrolledToLeftEdgeConfig = {
    restrict: RestrictToAttribute,
};

const THRESHOLD_PIXELS = 4;

angular.module('app').directive(scrolledToLeftEdgeSID, [
    $parseSID,
    function (
    $parse : IParseService,
) {

    return {
        ...scrolledToLeftEdgeConfig,
        link(scope : IScope, element : IAugmentedJQuery, attributes : IAttributes) {
            const callback = $parse(attributes.scrolledToLeftEdge);
            function update() {
                const domElement = element[0];
                const leftEdge = domElement.scrollLeft;
                return callback(scope, {
                    scrolledToLeftEdge: leftEdge <= 0 + THRESHOLD_PIXELS,
                });
            }

            scope.$watch(() => element.get(0).scrollWidth, update);
            angular.element(element).bind('scroll', () => scope.$apply(update));
        },
    };
},
]);
