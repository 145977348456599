import * as React from 'react';
import { noop } from '@deltasierra/object-utilities';
import { t } from '@deltasierra/shared';
import { DropdownMenu, DropdownMenuItem } from '@deltasierra/components';

export type AssetViewStyleOptions = 'list' | 'thumbnail';

export type ThumbnailViewToggleProps = {
    value: AssetViewStyleOptions;
    onChange?: (value: AssetViewStyleOptions) => void;
};

export const ThumbnailViewToggle: React.FunctionComponent<ThumbnailViewToggleProps> = ({ onChange = noop, value }) => (
    <DropdownMenu
        buttonDisplayType="icon"
        icon={`glyphicon ${value === 'thumbnail' ? 'glyphicon-th-large' : 'glyphicon-th-list'}`}
    >
        <DropdownMenuItem
            icon={'glyphicon glyphicon-th-large'}
            name={t('ASSET_LIBRARY.THUMBNAIL')}
            selected={value === 'thumbnail'}
            onClick={() => onChange('thumbnail')}
        />
        <DropdownMenuItem
            icon={'glyphicon glyphicon-th-list'}
            name={t('ASSET_LIBRARY.LIST')}
            selected={value === 'list'}
            onClick={() => onChange('list')}
        />
    </DropdownMenu>
);
ThumbnailViewToggle.displayName = 'ThumbnailViewToggle';
