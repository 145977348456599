/// <reference path="../_references.d.ts" />
import { BannerNotification, bannerNotificationSocketEvent } from '@deltasierra/shared';
import { $timeoutSID } from '../common/angularData';
import { SocketService } from './socketService';

export class BannerNotificationsSocketService {
    public static SID = 'BannerNotificationsSocketService';

    public static readonly $inject: string[] = [$timeoutSID];

    public currentBannerNotification: BannerNotification | null = null;

    public constructor(private readonly $timeout: ng.ITimeoutService) {}

    public registerSocketListeners(socketService: SocketService): void {
        // Listen For this event
        socketService.on(bannerNotificationSocketEvent, data => this.handleSocket(data));
    }

    private handleSocket(data: { notification: BannerNotification | null }) {
        // Execute withing the $timeout service to force a re-render
        this.$timeout(() => {
            this.currentBannerNotification = data.notification;
        });
    }
}

angular.module('app').service(BannerNotificationsSocketService.SID, BannerNotificationsSocketService);
