export const RootUrlMap = {
    auth: buildMappedUrl('auth'),
    brand: buildMappedUrl('brand'),
    builderTemplateGallery: buildMappedUrl('builderTemplateGallery'),
    reports: buildMappedUrl('reports'),
} as const;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type NotVariableStringUrl<T> = T extends `:${infer U}` ? never : T;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type VariableOnlyStringUrl<T> = T extends `:${infer U}` ? T : never;

export function buildMappedUrl<PrevRouterUrlStr extends string>(
    previousRoute: PrevRouterUrlStr,
): MappedUrl<PrevRouterUrlStr, PrevRouterUrlStr, []>;
export function buildMappedUrl<
    PrevRouterUrlStr extends string,
    PrevUrlStr extends string,
    PrevOptions extends any[],
    NextRouterUrlStr extends string,
>(
    previousRoute: MappedUrl<PrevRouterUrlStr, PrevUrlStr, PrevOptions>,
    nextRoute: NotVariableStringUrl<NextRouterUrlStr>,
): MappedUrl<`${NextRouterUrlStr}`, `${PrevUrlStr}/${NextRouterUrlStr}`, PrevOptions>;
export function buildMappedUrl<
    PrevRouterUrlStr extends string,
    PrevUrlStr extends string,
    PrevOptions extends any[],
    NextRouterUrlStr extends string,
    NextUrlStr extends string,
    NextOptionsType,
>(
    previousRoute: MappedUrl<PrevRouterUrlStr, PrevUrlStr, PrevOptions>,
    nextRouterUrl: VariableOnlyStringUrl<NextRouterUrlStr>,
    nextRoute: (options: NextOptionsType) => NextUrlStr,
): MappedUrl<
    `${NextRouterUrlStr}`,
    `${PrevUrlStr}/${NextUrlStr}`,
    [...PrevOptions, NextOptionsType]
>;
export function buildMappedUrl<
    PrevRouterUrlStr extends string,
    PrevUrlStr extends string,
    PrevOptions extends any[],
    NextRouterUrlStr extends string,
    NextUrlStr extends string,
    NextOptionsType,
>(
    previousRoute: MappedUrl<PrevRouterUrlStr, PrevUrlStr, PrevOptions> | PrevRouterUrlStr,
    nextRouterUrl?: NotVariableStringUrl<NextRouterUrlStr> | VariableOnlyStringUrl<NextRouterUrlStr>,
    nextRoute?: (options: NextOptionsType) => NextUrlStr,
):
    | MappedUrl<
          `${NextRouterUrlStr}`,
          `${PrevUrlStr}/${NextUrlStr}`,
          [...PrevOptions, NextOptionsType]
      >
    | MappedUrl<`${NextRouterUrlStr}`, `${PrevUrlStr}/${NextRouterUrlStr}`, PrevOptions>
    | MappedUrl<PrevRouterUrlStr, PrevRouterUrlStr, []> {
    if (typeof previousRoute === 'string') {
        return {
            routerUrl: previousRoute,
            url: () => previousRoute,
        };
    }
    if (nextRouterUrl === undefined) {
        throw new Error('There was an error building the url map');
    }

    const routerUrl = `${nextRouterUrl}` as const;

    if (!nextRoute) {
        return {
            routerUrl,
            url: (...options: PrevOptions) => `${previousRoute.url(...options)}/${nextRouterUrl}` as const,
        };
    }

    return {
        routerUrl,
        url: (...options: [...PrevOptions, NextOptionsType]) => {
            const last: NextOptionsType = options.pop() as NextOptionsType;
            return `${previousRoute.url(...(options as unknown as PrevOptions))}/${nextRoute(last)}` as const;
        },
    };
}

export interface MappedUrl<RouterUrlStr extends string, UrlStr extends string, Options extends any[]> {
    url: (...options: [...Options]) => UrlStr;
    routerUrl: RouterUrlStr;
}
