import * as React from 'react';

export type TargetClientCheckbox = React.InputHTMLAttributes<HTMLInputElement> & {
    indeterminate?: boolean;
    children?: React.ReactNode;
};

export const TargetClientCheckbox: React.FC<TargetClientCheckbox> = ({ children, indeterminate = false, style, type, ...props }) => {
    const ref = React.useRef<HTMLInputElement>(null);

    React.useEffect(() => {
        if (ref.current) {
            ref.current.indeterminate = indeterminate;
        }
    });

    return (
        <div className="checkbox" style={style}>
            <label style={{
                alignItems: 'center',
                display: 'flex',
                gap: '10px',
                height: '40px',
            }}>
                <input ref={ref} style={{ accentColor: '#6b30fa' }} {...props} type={type ?? 'checkbox'} />
                {children}
            </label>
        </div>
    );
};
TargetClientCheckbox.displayName = 'TargetClientCheckbox';