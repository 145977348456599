export type ProgressType = 'danger' | 'default' | 'success' | 'warning';

export interface IProgressModalProgress {
    value : number;
    max : number;
    title? : string;
    status? : string;
    type? : ProgressType;
}

export class ProgressModalController {
    static SID = 'ProgressModalController';

    static readonly $inject : string[] = [
        'progress',
    ];

    constructor(
        public readonly progress : IProgressModalProgress,
    ) {
    }
}

angular.module('app').controller(ProgressModalController.SID, ProgressModalController);
