import * as React from 'react';
import { DSIcon, DSListItem, DSListItemIcon, DSListItemText, DSTypography, Icon } from '@deltasierra/components';

export interface GenericListItemProps {
    count?: number;
    dataCy?: string;
    icon: 'clock' | 'document' | 'folder-thin' | 'image' | 'trash' | 'video';
    isSelected?: boolean;
    text: string;
    onClick?: () => void;
}

export function GenericListItem({ dataCy, ...props }: GenericListItemProps): JSX.Element {
    return (
        <DSListItem data-cy={dataCy} dense onClick={() => props.onClick?.()}>
            <DSListItemIcon>
                <DSIcon
                    color={props.isSelected ? 'error' : 'primary'}
                    fontSize="small"
                    style={{ height: '1.25em', overflow: 'visible' }}
                >
                    <Icon icon={props.icon} style={{ fontSize: '1.4rem' }} />
                </DSIcon>
            </DSListItemIcon>
            <DSListItemText>
                <DSTypography color="textPrimary">{props.text}</DSTypography>
            </DSListItemText>
            <div>{props.count ?? 0}</div>
        </DSListItem>
    );
}
GenericListItem.displayName = 'GenericListItem';
