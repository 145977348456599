import { useQuery } from '@apollo/client';
import { GET_FLAG_FOR_LOCATION_GROUPS_DASHBOARD_WELL } from './LocationGroupsDashboard.queries';
import {
    GetFlagForLocationGroupsDashboardWell,
    GetFlagForLocationGroupsDashboardWellVariables,
} from './__graphqlTypes/GetFlagForLocationGroupsDashboardWell';

export function useShouldShowLocationGroupsDashboardWell(locationId: string): {
    loading: boolean;
    shouldShowGroups: boolean;
} {
    const { data, loading } = useQuery<
        GetFlagForLocationGroupsDashboardWell,
        GetFlagForLocationGroupsDashboardWellVariables
    >(GET_FLAG_FOR_LOCATION_GROUPS_DASHBOARD_WELL, {
        variables: {
            locationId,
        },
    });

    return {
        loading,
        shouldShowGroups: data?.location?.client.showGroupsOnDashboard ?? false,
    };
}
