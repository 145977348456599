import { BannerNotification, withId } from '@deltasierra/shared';

import { MvNotifier } from '../../common/mvNotifier';
import { InteractionUtils } from '../../common/interactionUtils';
import { I18nService } from '../../i18n/i18nService';
import BannerNotificationService from './service';

type LocalBannerNotification = BannerNotification | null;

export default class BannerNotificationController {
    public static SID = 'BannerNotificationController';

    public static readonly $inject: string[] = [
        MvNotifier.SID,
        InteractionUtils.SID,
        BannerNotificationService.SID,
        I18nService.SID,
    ];

    public createBannerNotification = this.interactionUtils.createFuture(
        this.i18n.text.admin.banner.createBannerNotification,
        context =>
            this.bannerNotificationService.createBannerNotification(
                this.inputBanner.message,
                this.inputBanner.type,
                this.inputBanner.dismissable,
            ),
    );

    public deleteCurrentBannerNotification = this.interactionUtils.createFuture(
        this.i18n.text.admin.banner.deleteCurrentBannerNotification,
        context => this.bannerNotificationService.deleteCurrentBannerNotification(),
    );

    public getCurrentBannerNotification = this.interactionUtils.createFuture(
        this.i18n.text.admin.banner.getCurrentBannerNotification,
        context => this.bannerNotificationService.getCurrentBannerNotification(),
    );

    public notificationTypes = [
        { label: 'Success', value: 'success' },
        { label: 'Info', value: 'info' },
        { label: 'Warning', value: 'warning' },
        { label: 'Danger', value: 'danger' },
    ];

    public inputBanner: BannerNotification = withId({
        dismissable: false,
        message: '',
        type: 'info',
    });

    public currentBannerNotification: LocalBannerNotification | null = null;

    public constructor(
        private notifier: MvNotifier,
        private interactionUtils: InteractionUtils,
        private bannerNotificationService: BannerNotificationService,
        private i18n: I18nService,
    ) {
        this.setBannerNotification = this.setBannerNotification.bind(this);
        void this.getCurrentBannerNotification.run({}).then((currentBanner: LocalBannerNotification) => {
            this.currentBannerNotification = currentBanner || null;
        });
    }

    public getModel(): LocalBannerNotification {
        return this.currentBannerNotification ? this.currentBannerNotification : this.inputBanner;
    }

    public isDisabled(): boolean {
        return this.currentBannerNotification !== null;
    }

    public createBanner(): ng.IPromise<void> {
        return this.createBannerNotification.run({}).then((value: BannerNotification) => {
            this.currentBannerNotification = value;
            this.notifier.notify(this.i18n.text.admin.banner.notifyCreated());
        });
    }

    public deleteBanner(): ng.IPromise<void> {
        return this.deleteCurrentBannerNotification.run({}).then(() => {
            this.currentBannerNotification = null;
            this.inputBanner = withId({
                dismissable: false,
                message: '',
                type: 'info',
            });
            this.notifier.notify(this.i18n.text.admin.banner.notifyDeleted());
        });
    }

    public toggleDismissable(): void {
        this.inputBanner.dismissable = !this.inputBanner.dismissable;
    }

    public setBannerNotification(banner: BannerNotification | null): void {
        this.currentBannerNotification = banner;
    }
}

angular.module('app').controller(BannerNotificationController.SID, BannerNotificationController);
