/// <reference path="../../../typings/browser.d.ts" />
import { Activity, Channel, Client, ClientId } from '@deltasierra/shared';
import { MvClientResource, mvClientResourceSID } from '../clients/mvClientResource';
import { InteractionUtils } from '../common/interactionUtils';

class ChannelPickerEntry {
    constructor(
        public label: string,
        public value: Channel,
        public isSupported: boolean,
        public isSelfService: boolean,
    ) {}
}

export class ChannelPickerCtrl {
    static SID = 'channelPickerCtrl';

    // From directive attributes
    private change!: (locals: { channel: Channel | null }) => any;

    private clientId!: ClientId;

    private preselectedChannel!: Channel;

    private activities!: Activity[];

    public allowNone?: boolean;

    private excludeChannelId?: number;

    public search = {
        // Tslint:disable-line:no-unused-variable
        label: '',
    };

    private entries?: ChannelPickerEntry[];

    private selectedEntry?: ChannelPickerEntry;

    public loading = {
        // Tslint:disable-line:no-unused-variable
        client: false,
    };

    static readonly $inject: string[] = [mvClientResourceSID, InteractionUtils.SID];

    constructor(
        private readonly mvClientResource: MvClientResource,
        private readonly interactionUtils: InteractionUtils,
    ) {}

    public $onInit(): void {
        // Get all channels/activities for the client.
        // Convert channels to options.
        // Filter by criteria
        if (this.activities) {
            this.initActivities(this.activities);
        } else if (this.clientId) {
            void this.interactionUtils
                .handleResource(this, 'retrieve client', 'client', this.mvClientResource.get({ id: this.clientId }))
                .then((client: Client) => {
                    this.initActivities(client.activities || []);
                });
        }
    }

    private initActivities(activities: Activity[]) {
        this.entries = this.mapToEntries(activities);
        this.selectEntryFromChannelId();
    }

    private mapToEntries(activities: Activity[]): ChannelPickerEntry[] {
        const entries = [];
        for (const activity of activities) {
            if (!activity.deletedAt) {
                for (const channel of activity.channels || []) {
                    if (!channel.deletedAt && (!this.excludeChannelId || channel.id != this.excludeChannelId)) {
                        const entry = new ChannelPickerEntry(
                            `${activity.label} - ${channel.label}`,
                            channel,
                            channel.isSupported,
                            channel.isSelfService || channel.platformId != null,
                        );
                        entries.push(entry);
                    }
                }
            }
        }
        return entries;
    }

    private selectEntryFromChannelId() {
        if (this.preselectedChannel) {
            for (const entry of this.entries!) {
                if (entry.value.id == this.preselectedChannel.id) {
                    this.select(entry);
                    break;
                }
            }
        }
    }

    select(entry: ChannelPickerEntry) {
        this.selectedEntry = entry;
        if (this.selectedEntry && this.selectedEntry.value) {
            this.change({ channel: this.selectedEntry.value });
        }
    }

    selectNone() {
        this.selectedEntry = undefined;
        this.change({ channel: null });
    }
}

export const dsChannelPickerConfig: ng.IDirective<ng.IScope> = {
    restrict: 'E',
    scope: {},
    templateUrl: '/partials/channels/channelPicker',
    controller: ChannelPickerCtrl,
    controllerAs: 'ctrl',
    bindToController: {
        activities: '=',
        allowNone: '=?',
        change: '&',
        clientId: '=',
        excludeChannelId: '=?',
        preselectedChannel: '=',
    },
};

export const dsChannelPickerSID = 'dsChannelPicker';

angular.module('app').controller(ChannelPickerCtrl.SID, ChannelPickerCtrl);
angular.module('app').directive(dsChannelPickerSID, [() => dsChannelPickerConfig]);
