import { gql, useQuery } from '@apollo/client';
import { GetIsClubReadyPublishingDisabled } from './__graphqlTypes/GetIsClubReadyPublishingDisabled';

export const GET_IS_CLUB_READY_PUBLISHING_DISABLED = gql`
    query GetIsClubReadyPublishingDisabled {
        config {
            id
            clubReadyConfig {
                disableClubReadyPublishing
            }
        }
    }
`;

export function useIsClubReadyPublishingDisabled(): boolean {
    const { data, loading } = useQuery<GetIsClubReadyPublishingDisabled>(GET_IS_CLUB_READY_PUBLISHING_DISABLED);
    return loading || !!data?.config.clubReadyConfig.disableClubReadyPublishing;
}
