export const getImage = async (blah: File): Promise<HTMLImageElement> =>
    new Promise<HTMLImageElement>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            const image = new Image();
            image.src = reader.result as string;
            image.onload = () => {
                resolve(image);
            };
            image.onerror = reject;
        };
        reader.onerror = reject;
        reader.readAsDataURL(blah);
    });
