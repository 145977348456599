/// <reference path="../../../../typings/browser.d.ts" />
import { $compileSID, $interpolateSID } from '../../common/angularData';
import IDirective = angular.IDirective;
import ICompileService = angular.ICompileService;
import IInterpolateService = angular.IInterpolateService;

interface MsoCommentScope extends ng.IScope {
    sectionId: number;
    getSectionById: (sectionId: number) => any;
}

angular.module('app').directive('msoComment', [
    $compileSID,
    $interpolateSID,
    function ($compile: ICompileService, $interpolate: IInterpolateService): IDirective<MsoCommentScope> {
        return {
            restrict: 'A',
            scope: true,
            link(scope: MsoCommentScope, element, attrs) {
                scope.sectionId = parseInt(attrs.sectionId, 10);

                function renderHtml() {
                    const section = scope.getSectionById(scope.sectionId);
                    const render = $interpolate(section.msoHtml);
                    const newHtml = render(scope);
                    element.empty();
                    element.append(`<!--[if mso]>${newHtml}<![endif]-->`);
                }

                scope.$watch(() => scope.getSectionById(scope.sectionId), renderHtml, true);
            },
        };
    },
]);
