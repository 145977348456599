import { gql } from '@apollo/client';

export const CLIENT_FRAGMENT_FOR_CLIENT_PICKER = gql`
    fragment ClientFragmentForClientPicker on Client {
        id
        title
    }
`;

export const CLIENT_PICKER_GET_SELECTED_CLIENT_QUERY = gql`
    query ClientPickerGetSelectedClientQuery($clientId: ID!) {
        client(id: $clientId) {
            id
            ...ClientFragmentForClientPicker
        }
    }
    ${CLIENT_FRAGMENT_FOR_CLIENT_PICKER}
`;

export const CLIENT_PICKER_GET_USER_CLIENTS_QUERY = gql`
    query ClientPickerGetUserClientsQuery($after: String, $title: String) {
        me {
            id
            agency {
                id
                clients(after: $after, first: 100, filter: { title: $title }) {
                    edges {
                        node {
                            id
                            ...ClientFragmentForClientPicker
                        }
                    }
                    pageInfo {
                        endCursor
                        hasNextPage
                    }
                }
            }
        }
    }
    ${CLIENT_FRAGMENT_FOR_CLIENT_PICKER}
`;
