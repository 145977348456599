import { IFilterService } from 'angular';
import { MoneyFilter, MoneyFilterOptions } from '../../filters/money';

export class Formatters {
    private readonly filterNumber = this.$filter('number');

    private readonly filterDate = this.$filter('date');

    private readonly filterMoney = this.$filter('money') as MoneyFilter;

    private readonly PERCENTAGE_PRECISION = 2;

    readonly formatString = (value? : string) : string => value || '';

    readonly formatNumber = (value? : number) : string => value != undefined ? this.filterNumber(value) : '';

    readonly formatDate = (value? : Date) => value ? this.filterDate(value, 'd MMM yyyy') : '';

    readonly formatPercentage = (value? : number) : string => value != undefined ? `${this.filterNumber(value, this.PERCENTAGE_PRECISION)}%` : '';

    readonly formatPercentageFloat = (value?: number): string => value != undefined ? this.formatPercentage(value * 100) : '';

    readonly formatCurrency = (value: number | undefined, options: MoneyFilterOptions) => value != undefined ? this.filterMoney(value, options) : '';

    constructor(private $filter: IFilterService) {
    }
}
