/// <reference path="../../../typings/browser.d.ts" />
import { LocationIdHierarchy, Untyped } from '@deltasierra/shared';
import { $qSID, $timeoutSID } from '../common/angularData';
import { $modalInstanceSID } from '../common/angularUIBootstrapData';
import { MvNotifier } from '../common/mvNotifier';
import { UploadContext } from '../common/uploadService';
import { I18nService } from '../i18n/i18nService';
import { MultipleLocationActionProcessor } from '../locations/MultipleLocationActionProcessor';
import { ModalInstance } from '../typings/angularUIBootstrap/modalService';
import { MvPlanner } from './mvPlanner';
import IQService = angular.IQService;

export class MvMultiplePlannerSaveCtrl {
    public static SID = 'mvMultiplePlannerSaveCtrl';

    public static readonly $inject: string[] = [
        $qSID,
        $timeoutSID,
        $modalInstanceSID,
        MvNotifier.SID,
        MvPlanner.SID,
        I18nService.SID,
        'currentLocationId',
        'locations',
        'payloadData',
    ];

    public currentLocationPlannerId?: number;

    public uploadContext: UploadContext = new UploadContext();

    public processor: MultipleLocationActionProcessor;

    public constructor(
        private readonly $q: IQService,
        private readonly $timeout: ng.ITimeoutService,
        private readonly $modalInstance: ModalInstance,
        private readonly mvNotifier: MvNotifier,
        private readonly mvPlanner: MvPlanner,
        private readonly i18n: I18nService,
        private readonly currentLocationId: number,
        private readonly locations: LocationIdHierarchy[],
        private readonly payloadData: Untyped, // TODO: type
    ) {
        this.locations.sort((a, b) => a.locationTitle.localeCompare(b.locationTitle));
        this.processor = new MultipleLocationActionProcessor(this.$q, this.i18n, this.$timeout, this.locations, {
            action: this.onAction.bind(this),
            failure: this.onFailure.bind(this),
            partialSuccess: this.onPartialSuccess.bind(this),
            success: this.onSuccess.bind(this),
        });
        void this.processor.start();
    }

    public onAction(location: LocationIdHierarchy) {
        const payloadData = angular.copy(this.payloadData);
        payloadData.planner.locationId = location.locationId;
        return this.mvPlanner.createPlanner(payloadData).then(planner => {
            if (this.currentLocationId == planner.locationId) {
                this.currentLocationPlannerId = planner.id;
            }
            return planner;
        });
    }

    public onSuccess() {
        this.mvNotifier.notify(this.i18n.text.plan.notifyPlannerCreated({ planner: this.payloadData.planner.title }));
        this.$modalInstance.close(this.currentLocationPlannerId);
    }

    public onPartialSuccess() {
        this.mvNotifier.notify(
            this.i18n.text.plan.notifyPlannerCreatedPartial({ planner: this.payloadData.planner.title }),
        );
        this.$modalInstance.close(this.currentLocationPlannerId); // Could be null
    }

    public onFailure() {
        this.mvNotifier.unexpectedError(this.i18n.text.plan.notifyPlannerCreateError());
        this.$modalInstance.dismiss(this.$q.reject());
    }
}

angular.module('app').controller(MvMultiplePlannerSaveCtrl.SID, MvMultiplePlannerSaveCtrl);
