/* eslint-disable max-lines-per-function */
import {
    DSButton,
    DSFormControl,
    DSFormControlLabel,
    DSFormLabel,
    DSMenuItem,
    DSRadio,
    DSRadioGroup,
    DSSelect,
    DSTypography,
    Translate,
} from '@deltasierra/components';
import {
    emptyRequest,
    justBody,
    BannerNotification as BannerNotificationData,
    BannerNotificationApi,
    BannerNotificationType,
} from '@deltasierra/shared';
import * as React from 'react';
import styled from 'styled-components';
import { OneWayBinding } from '../../../common/angularData';
import { TipTapEditor } from '../../../common/components';
import { useAngularServiceContext } from '../../../common/componentUtils/angularServiceContexts';
import { withAngularIntegration } from '../../../common/componentUtils/reactComponentRegistration';
import { invokeApiRoute } from '../../../common/httpUtils';
import { BannerNotification } from '../../../main/bannerNotificationDisplay/BannerNotification';

const RightAlignedButton = styled(DSButton)`
    align-self: flex-end;
    width: 20%;
`;

const SectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: ${props => props.theme.spacing(3)}px;
`;

export interface EditBannerNotificationProps {
    currentBannerNotification: BannerNotificationData | null;
    setBannerNotification: (banner: BannerNotificationData | null) => void;
}

const notificationTypes = ['success', 'info', 'warning', 'danger'];

export function EditBannerNotification({
    currentBannerNotification,
    setBannerNotification,
}: EditBannerNotificationProps): JSX.Element {
    const [bannerNotificationHtml, setBannerNotificationHtml] = React.useState(
        currentBannerNotification?.message || '',
    );
    const [dismissable, setDismissable] = React.useState(!!currentBannerNotification?.dismissable);
    const [notificationType, setNotificationType] = React.useState<BannerNotificationType>(
        currentBannerNotification?.type || 'info',
    );
    const httpService = useAngularServiceContext('$http');
    const editor = TipTapEditor.useTipTapEditor(
        {
            forceUpdates: currentBannerNotification !== null, // Updates editor content on successful create/delete
            onChange: setBannerNotificationHtml,
            value: bannerNotificationHtml,
        },
        {
            autofocus: false,
            editable: !currentBannerNotification,
        },
    );

    const createBannerNotification = async () =>
        invokeApiRoute(
            httpService,
            BannerNotificationApi.createBannerNotification,
            justBody({
                dismissable,
                message: editor?.getHTML() || '',
                type: notificationType,
            }),
        ).then(response => setBannerNotification(response));

    const deleteBannerNotification = async () =>
        invokeApiRoute(httpService, BannerNotificationApi.deleteCurrentBannerNotification, emptyRequest()).then(() => {
            setBannerNotification(null);
            setBannerNotificationHtml('');
        });

    return (
        <>
            <DSFormControl>
                <DSTypography component="h2" variant="h2">
                    <Translate keyId="ADMIN.BANNER.BANNER_NOTIFICATION" />
                </DSTypography>
                <SectionContainer>
                    <DSFormLabel>
                        <Translate keyId="ADMIN.BANNER.BANNER_MESSAGE" />
                    </DSFormLabel>
                    <TipTapEditor
                        editor={editor}
                        minHeight={200}
                        padBottom={55}
                        padTop={TipTapEditor.TipTapEditorMenu.padTop}
                    >
                        <TipTapEditor.TipTapEditorMenu editor={editor} />
                    </TipTapEditor>
                    <DSTypography style={{ fontStyle: 'italic' }}>
                        <Translate keyId="ADMIN.BANNER.TIP" />
                    </DSTypography>
                </SectionContainer>
                <SectionContainer>
                    <DSFormLabel>
                        <Translate keyId="ADMIN.BANNER.NOTIFICATION_TYPE" />
                    </DSFormLabel>
                    <DSSelect
                        disabled={currentBannerNotification !== null}
                        fullWidth={false}
                        style={{ width: '20%' }}
                        value={notificationType}
                        onChange={event => setNotificationType(event.target.value as BannerNotificationType)}
                    >
                        {notificationTypes.map(type => (
                            <DSMenuItem key={type} value={type}>
                                <DSTypography style={{ textTransform: 'capitalize' }}>{type}</DSTypography>
                            </DSMenuItem>
                        ))}
                    </DSSelect>
                </SectionContainer>
                <SectionContainer>
                    <DSFormLabel>
                        <Translate keyId="ADMIN.BANNER.DISMISSABLE" />
                    </DSFormLabel>
                    <DSRadioGroup
                        row
                        value={dismissable}
                        onChange={event => setDismissable(event.target.value === 'true')}
                    >
                        <DSFormControlLabel
                            control={<DSRadio />}
                            disabled={currentBannerNotification !== null}
                            label={<Translate keyId="COMMON.NO" />}
                            value={false}
                        />
                        <DSFormControlLabel
                            control={<DSRadio />}
                            disabled={currentBannerNotification !== null}
                            label={<Translate keyId="COMMON.YES" />}
                            value={true}
                        />
                    </DSRadioGroup>
                </SectionContainer>
                {currentBannerNotification !== null ? (
                    <RightAlignedButton color="error" onClick={deleteBannerNotification}>
                        <Translate keyId="COMMON.DELETE" />
                    </RightAlignedButton>
                ) : (
                    <RightAlignedButton color="primary" disabled={editor?.isEmpty} onClick={createBannerNotification}>
                        <Translate keyId="COMMON.ADD" />
                    </RightAlignedButton>
                )}
            </DSFormControl>
            {currentBannerNotification === null && !editor?.isEmpty && (
                <SectionContainer>
                    <BannerNotification
                        bannerNotification={{
                            dismissable,
                            id: 0,
                            message: bannerNotificationHtml,
                            type: notificationType,
                        }}
                        preview
                    />
                </SectionContainer>
            )}
        </>
    );
}
EditBannerNotification.displayName = 'EditBannerNotification';

export const EditBannerNotificationAngular = withAngularIntegration(EditBannerNotification, 'editBannerNotification', {
    currentBannerNotification: OneWayBinding,
    setBannerNotification: OneWayBinding,
});
