import { DSTypography } from '@deltasierra/components';
import styled from 'styled-components';

export const StyledExportingMessage = styled(DSTypography)`
    margin-right: ${props => props.theme.spacing(1)}px;
    color: white;
`;

export const StyledWrapper = styled.div`
    text-align: right;
`;
