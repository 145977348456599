import { DSTextField, Translate } from '@deltasierra/components';
import { useControlledSearchInput } from '@deltasierra/react-hooks';
import * as React from 'react';

export type CopyTemplateControlledInputProps = {
    initialValue: string;
    onChange: (value: string) => void;
};

export const CopyTemplateControlledInput: React.FC<CopyTemplateControlledInputProps> = ({
    initialValue,
    onChange,
}) => {
    const { inputProps } = useControlledSearchInput({
        initialValue,
        onSearchTermValueChange: onChange,
    });
    return (
        <DSTextField
            label={<Translate keyId="ADMIN.BRANDS.FILTER_CLIENTS" />}
            variant="outlined"
            {...inputProps}
            style={{ width: '100%' }}
        />
    );
}
CopyTemplateControlledInput.displayName = 'CopyTemplateControlledInput';