import { gql, QueryResult, useQuery } from '@apollo/client';
import { ClientUsersFilterInput } from '../../../../__graphqlTypes/globalTypes';
import { GetClientUsers, GetClientUsersVariables } from './__graphqlTypes/GetClientUsers';

export const CLIENT_USERS_QUERY = gql`
    query GetClientUsers($cursor: String, $first: Int, $clientId: ID!, $filter: ClientUsersFilterInput) {
        client(id: $clientId) {
            id
            legacyId
            users(after: $cursor, first: $first, filter: $filter) {
                edges {
                    cursor
                    node {
                        id
                        legacyId
                        firstName
                        lastName
                        username
                        hasAcceptedInvite
                    }
                }
                pageInfo {
                    endCursor
                    hasNextPage
                    startCursor
                }
            }
        }
    }
`;

export const useGetClientUsers = (
    clientId: string,
    filter: ClientUsersFilterInput,
    first = 25,
): [QueryResult<GetClientUsers, GetClientUsersVariables>, () => void, boolean] => {
    const result = useQuery<GetClientUsers, GetClientUsersVariables>(CLIENT_USERS_QUERY, {
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
        notifyOnNetworkStatusChange: true,
        variables: { clientId, filter, first },
    });

    const fetchMore = () => {
        if (result.data?.client?.users.pageInfo.hasNextPage) {
            void result.fetchMore({
                variables: {
                    cursor: result.data?.client.users.pageInfo.endCursor,
                    filter,
                    first,
                },
            });
        }
    };

    return [result, fetchMore, result.data?.client?.users.pageInfo.hasNextPage || false];
};
