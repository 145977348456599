import { DSAlert } from '@deltasierra/components';
import * as React from 'react';
import { Translate } from '../../../../directives/Translate';

export function MissingAllRequiredPermissionForFirstCommentMessage(): JSX.Element {
    return (
        <DSAlert severity="warning">
            <Translate keyId="ERRORS.INSTAGRAM_ERRORS.MISSING_FIRST_COMMENT_HASHTAGS_PERMISSION" />
        </DSAlert>
    );
}
MissingAllRequiredPermissionForFirstCommentMessage.displayName = 'MissingAllRequiredPermissionForFirstCommentMessage';
