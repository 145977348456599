import { Translate } from '@deltasierra/components';
import * as React from 'react';
import styled from 'styled-components';
import { NotifierToast, NotifierToastProps } from './NotifierToast';

const StyledSubTextWrapper = styled.div`
    height: 8px;
`;
const StyledSubText = styled.div`
    font-size: 70%;
    color: ${props => props.theme.palette.grey[400]};
`;

export type NotifierToastErrorProps = Omit<NotifierToastProps, 'type'> & {
    errorId?: string;
};

export function NotifierToastError({ children, errorId, ...props }: NotifierToastErrorProps): JSX.Element {
    return (
        <NotifierToast dataCy="error-notifier-toast" type="error" {...props}>
            {children}
            {errorId && (
                <StyledSubTextWrapper>
                    <StyledSubText>
                        <Translate keyId="ERRORS.EXCEPTION_ID" options={{ id: errorId }} />
                    </StyledSubText>
                </StyledSubTextWrapper>
            )}
        </NotifierToast>
    );
}
NotifierToastError.displayName = 'NotifierToastError';
