/// <reference path="../../../../typings/browser.d.ts" />
import { Client, ClientId, Currency } from '@deltasierra/shared';
import { MvClient } from '../../clients/mvClient';
import { MvClientResource, mvClientResourceSID } from '../../clients/mvClientResource';
import { $locationSID, $routeParamsSID, IRouteParams } from '../../common/angularData';
import { InteractionUtils } from '../../common/interactionUtils';
import { MvNotifier } from '../../common/mvNotifier';
import { I18nService } from '../../i18n';
import { CurrencyService } from '../../payments/currencyService';
import { ClientSubscription } from './models';
import ILocationService = angular.ILocationService;

export class MvClientSubscriptionDetailCtrl {
    public static SID = 'mvClientSubscriptionDetailCtrl';

    public static readonly $inject: string[] = [
        $routeParamsSID,
        $locationSID,
        MvNotifier.SID,
        MvClient.SID,
        mvClientResourceSID,
        InteractionUtils.SID,
        CurrencyService.SID,
        I18nService.SID,
    ];

    public CURRENCIES: Currency[] | ReadonlyArray<Currency>;

    public clientId: ClientId;

    public client?: Client;

    public clientSubscription?: ClientSubscription;

    public loading = {
        clientSubscription: false,
        updateClientSubscription: false,
    };


    public constructor(
        $routeParams: IRouteParams,
        private readonly $location: ILocationService,
        private readonly mvNotifier: MvNotifier,
        private readonly mvClient: MvClient,
        private readonly mvClientResource: MvClientResource,
        private readonly interactionUtils: InteractionUtils,
        currencyService: CurrencyService,
        private readonly i18n: I18nService,
    ) {
        this.CURRENCIES = currencyService.CURRENCIES;
        this.clientId = ClientId.from(parseInt($routeParams.client_id, 10));
        void this.interactionUtils
            .handleResource(this, 'retrieve client', 'client', this.mvClientResource.get({ id: this.clientId }))
            .then((client: Client) => {
                this.client = client;
            });
        void this.interactionUtils
            .handleRemote(
                this,
                'retrieve client subscription',
                'clientSubscription',
                this.mvClient.getSubscription(this.clientId),
            )
            .then((clientSubscription: ClientSubscription) => {
                this.clientSubscription = clientSubscription;
            });
    }

    public async updateSubscription(): Promise<void> {
        return this.interactionUtils
            .handleRemote(
                this,
                'update client subscription',
                'updateClientSubscription',
                this.mvClient.updateSubscription(this.clientId, this.clientSubscription!),
            )
            .then(() => {
                this.mvNotifier.notify(this.i18n.text.admin.subscriptions.defaultSubscriptionUpdated());
                this.$location.url('/admin/subscriptions');
            });
    }
}

angular.module('app').controller(MvClientSubscriptionDetailCtrl.SID, MvClientSubscriptionDetailCtrl);
