import { useLocalStorage } from '@deltasierra/react-hooks';
import * as React from 'react';
import {
    CurrentClientIdType,
    CurrentClientStorageDispatchContext,
    CurrentClientStorageDispatchContextType,
} from '../contexts';
import { CurrentClientContextLocalStorageKey } from './useCurrentClientStorageState';

/**
 * Use the current client from the local storage. You probably don't need to use.
 * Use `useCurrentClient` instead.
 *
 * @returns The local storage actions for the current client key
 */
export function useCurrentClientLocalStorageDispatch(): Exclude<CurrentClientStorageDispatchContextType, undefined> {
    const dispatchToContext = React.useContext(CurrentClientStorageDispatchContext);
    const [, dispatchToLocalStorage] = useLocalStorage<CurrentClientIdType>(CurrentClientContextLocalStorageKey);
    return newValue => {
        dispatchToLocalStorage(newValue ?? null);
        dispatchToContext?.(newValue);
    };
}
