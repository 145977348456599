import { Untyped, IPublishVideoToLocalRequest, AssignedLocation, GalleryPlannerDetails, ExportPlatforms, PublishVideoCallback, ISize } from '@deltasierra/shared';


import { InteractionUtils } from '../../../common/interactionUtils';
import { VideoPublishService } from '../VideoPublishService';
import { MvNotifier } from '../../../common/mvNotifier';
import { LocalPublishService } from '../../../integration/publish/localPublishService';
import { MvContentBuilderPublishCtrl } from '../mvContentBuilderPublishCtrl';

import { ContentBuilder } from '../../contentBuilder';
import { PublishResult } from '../publishResult';
import { getFormatByName } from '../../imageFormats';
import { I18nService } from '../../../i18n/i18nService';
import { VideoFinishCallback, PublishVideoResult } from '../publishVideoDirective/controller';
import { $scopeSID, $qSID } from '../../../common/angularData';
import IScope = angular.IScope;
import IQService = angular.IQService;


interface PublishToLocalCtrlScope extends IScope {
    ctrl: MvContentBuilderPublishCtrl;
}

interface IMvContentBuilderPublishToLocalCtrl {
    onPublishVideo: PublishVideoCallback<IPublishVideoToLocalRequest>;
    onFinishVideoPublish: VideoFinishCallback;
}

export class MvContentBuilderPublishVideoToLocalCtrl implements IMvContentBuilderPublishToLocalCtrl {
    static SID = 'mvContentBuilderPublishVideoToLocalCtrl';

    isPublishing = false;

    contentBuilder!: ContentBuilder;

    templateId!: number;

    location!: AssignedLocation;

    plannerDetails?: GalleryPlannerDetails | null = null;

    publishCtrl!: MvContentBuilderPublishCtrl;

    channelData = {};

    outputDimensions : ISize;

    outputVideoUrl?: string;

    fileName: string;

    static readonly $inject : string[] = [
        $scopeSID,
        $qSID,
        'builderConstants',
        MvNotifier.SID,
        InteractionUtils.SID,
        VideoPublishService.SID,
        LocalPublishService.SID,
        I18nService.SID,
    ];

    constructor(
        protected $scope: PublishToLocalCtrlScope,
        protected $q: IQService,
        protected builderConstants: Untyped,
        public mvNotifier: MvNotifier,
        protected interactionUtils: InteractionUtils,
        protected videoPublishService: VideoPublishService,
        protected localPublishService: LocalPublishService,
        public i18nService: I18nService,
    ) {
        this.populateInheritedProperties();
        this.fileName = this.$scope.ctrl.generateImageFileName(getFormatByName('MP4'));

        if (this.publishCtrl && this.publishCtrl.fileFormatChoice) {
            this.outputDimensions = {
                width: this.publishCtrl.fileFormatChoice.width,
                height: this.publishCtrl.fileFormatChoice.height,
            };
        } else {
            // Ehhh, this shouldn't happen, but let's be safe.
            this.outputDimensions = {
                width: this.publishCtrl.contentBuilder.document.dimensions.width,
                height: this.publishCtrl.contentBuilder.document.dimensions.height,
            };
        }
        this.publish();
    }

    private populateInheritedProperties() {
        this.publishCtrl = this.$scope.ctrl;
        this.contentBuilder = this.publishCtrl.contentBuilder;
        this.templateId = this.publishCtrl.templateId;
        this.location = this.publishCtrl.location;
        this.plannerDetails = this.publishCtrl.plannerDetails;
    }

    publish(): void {
        this.isPublishing = true;
    }

    onPublishVideo(request: IPublishVideoToLocalRequest) {
        return this.localPublishService.publishVideo(this.location.id, request);
    }

    onFinishVideoPublish(result: PublishVideoResult) {
        this.isPublishing = false;
        switch (result.success) {
            case true:
                this.outputVideoUrl = result.value.outputVideoUrl;
                break;
            case false:
            default:
                this.cancel();
                break;
        }
    }

    cancel() {
        this.$scope.$emit(this.builderConstants.EVENTS.PUBLISH_CANCEL);
    }

    finish() {
        this.$scope.$emit(this.builderConstants.EVENTS.PUBLISH_PUBLISHED, new PublishResult(ExportPlatforms.Local, null));
    }
}

angular.module('app').controller(MvContentBuilderPublishVideoToLocalCtrl.SID, MvContentBuilderPublishVideoToLocalCtrl);
