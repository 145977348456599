import * as React from 'react';
import { SortAssetsInput } from '../../../../../__graphqlTypes/globalTypes';
import { CollectionNotFound, GeneralErrorMessage } from '../CollectionErrorComponents';
import { CollectionAssetsWithData, ViewOption } from './CollectionAssetsWithData';
import { GetCollectionForCollectionAssets } from './__graphqlTypes/GetCollectionForCollectionAssets';

export type CollectionAssetsDataProps = {
    collectionData: GetCollectionForCollectionAssets;
    enableLightboxPreview: boolean;
    setSort: (sort: SortAssetsInput | null) => void;
    setViewOption: (option: ViewOption) => void;
    sort: SortAssetsInput | null;
    viewOption: ViewOption;
};

export function CollectionAssetsData({
    collectionData,
    enableLightboxPreview,
    setSort,
    setViewOption,
    sort,
    viewOption,
}: CollectionAssetsDataProps): JSX.Element {
    switch (collectionData.collectionOrAsset.__typename) {
        case 'AssetFolder':
        case 'Collection':
            return (
                <CollectionAssetsWithData
                    assetConnection={collectionData.collectionOrAsset.assets}
                    currentAsset={collectionData.collectionOrAsset}
                    enableLightboxPreview={enableLightboxPreview}
                    setSort={setSort}
                    setViewOption={setViewOption}
                    sort={sort}
                    viewOption={viewOption}
                />
            );
        case 'CollectionOrAssetNotFoundError':
        case 'CollectionOrAssetPermissionError':
            return <CollectionNotFound />;
        default:
            return <GeneralErrorMessage />;
    }
}

CollectionAssetsData.displayName = 'CollectionAssetsData';
