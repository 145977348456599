import { ConfirmButton } from '@deltasierra/components';

import { withAngularIntegration } from '../../componentUtils/reactComponentRegistration';
import { OneWayBinding, OptionalOneWayBinding, StringBinding, OptionalStringBinding } from '../../angularData';

export const AngularReactConfirmButton = withAngularIntegration(ConfirmButton, 'reactConfirmButton', {
    cancelText: StringBinding,
    confirmText: StringBinding,
    confirmationText: OptionalStringBinding,
    disabled: OptionalOneWayBinding,
    label: StringBinding,
    message: StringBinding,
    onConfirm: OneWayBinding,
    theme: StringBinding,
    title: StringBinding,
});
