import { DSTypography, DSWell, Translate } from '@deltasierra/components';
import {
    pluck,
    AssignedLocation,
    InstagramDirectPublishFormData,
    LocationIdHierarchy,
    MAX_INSTAGRAM_CAPTION_CHARACTER_LENGTH,
} from '@deltasierra/shared';
import { Formik } from 'formik';
import * as React from 'react';
import { FormButtons } from '../common/FormButton';
import { useTermsAndConditions } from '../common/hooks';
import { TermsAndConditionsPublishMessage } from '../common/termsAndConditions/termsAndConditionsPublishMessage';
import { useChannelDatum } from '../common/useChannelDatum';
import { useRequiredHashtags } from '../hooks';
import { MvContentBuilderPublishCtrl } from '../mvContentBuilderPublishCtrl';
import {
    buildInstagramDirectPublishData,
    getCaptionWithTermsAndConditions,
} from './build-instagram-direct-publish-data';
import { InstagramDirectFormValues } from './instagram-direct-form-values';
import { InstagramDirectFormCaptionSection } from './InstagramDirectFormCaptionSection';
import { InstagramDirectFormDescriptionSection } from './InstagramDirectFormDescriptionSection';
import { InstagramDirectFormHashtagsSection } from './InstagramDirectFormHashtagsSection';
import { InstagramDirectFormScheduleSection } from './InstagramDirectFormScheduleSection';
import { useValidatedInstagramDirectForm } from './useValidatedInstagramDirectForm';

export type PublishToInstagramDirectFormProps = {
    location: AssignedLocation;
    locations: LocationIdHierarchy[];
    publishController: MvContentBuilderPublishCtrl;
    onCancel: () => void;
    onPublish: (data: InstagramDirectPublishFormData) => void;
};

export function PublishToInstagramDirectForm({
    location,
    locations,
    onCancel,
    onPublish,
    publishController,
}: PublishToInstagramDirectFormProps): JSX.Element {
    const oldLocationIds = React.useMemo(() => pluck('locationId', locations), [locations]);
    const graphqlLocationIds = React.useMemo(() => pluck('locationGraphqlId', locations), [locations]);

    const [submitted, setSubmitted] = React.useState(false);

    const suggestedCaption = useChannelDatum(publishController, 'instagram', instagramData => instagramData.caption);
    const headerTranslationKey = publishController.isExportingVideo()
        ? 'BUILD.PUBLISH.INSTAGRAM.PUBLISH_VIDEO_TO_INSTAGRAM'
        : 'BUILD.PUBLISH.INSTAGRAM.PUBLISH_IMAGE_TO_INSTAGRAM';

    const { loading: requiredHashtagsLoading, requiredHashtags } = useRequiredHashtags(location.graphqlId);
    const [termsAndConditionsText, termsAndConditionsUrl] = useTermsAndConditions(publishController);

    const onSubmit = React.useCallback(
        (formValues: InstagramDirectFormValues) => {
            if (submitted || requiredHashtagsLoading) {
                return;
            }
            setSubmitted(true);

            const publishData = buildInstagramDirectPublishData(formValues, {
                requiredHashtags,
                termsAndConditionsText,
                termsAndConditionsUrl,
            });

            onPublish(publishData);
        },
        [
            submitted,
            requiredHashtagsLoading,
            termsAndConditionsText,
            termsAndConditionsUrl,
            requiredHashtags,
            onPublish,
        ],
    );

    const formikProps = useValidatedInstagramDirectForm({
        date: publishController?.plannerDetails?.date,
        requiredHashtags,
        termsAndConditionsText,
        termsAndConditionsUrl,
    });

    return (
        <Formik {...formikProps} validateOnBlur validateOnChange validateOnMount onSubmit={onSubmit}>
            {formik => (
                <>
                    <DSWell>
                        <DSTypography gutterBottom variant="h3">
                            <Translate keyId={headerTranslationKey} />
                        </DSTypography>
                        <InstagramDirectFormDescriptionSection oldLocationIds={oldLocationIds} />
                        <DSTypography gutterBottom variant="h4">
                            <Translate keyId="BUILD.PUBLISH.INSTAGRAM.HEADERS.POST_DETAILS" />
                        </DSTypography>
                        <InstagramDirectFormCaptionSection formik={formik} suggestedCaption={suggestedCaption} />
                        <TermsAndConditionsPublishMessage
                            text={termsAndConditionsText}
                            url={termsAndConditionsUrl}
                            useNewLabel
                        />
                        <InstagramDirectFormHashtagsSection
                            availableCaptionLength={Math.max(
                                0,
                                MAX_INSTAGRAM_CAPTION_CHARACTER_LENGTH -
                                    getCaptionWithTermsAndConditions({
                                        baseCaption: formik.values.caption,
                                        termsAndConditionsText,
                                        termsAndConditionsUrl,
                                    }).length,
                            )}
                            availableFirstCommentLength={MAX_INSTAGRAM_CAPTION_CHARACTER_LENGTH}
                            formik={formik}
                            locationId={location.graphqlId}
                            locationIds={graphqlLocationIds}
                            requiredHashtags={requiredHashtags}
                            requiredHashtagsLoading={requiredHashtagsLoading}
                        />
                        <DSTypography gutterBottom variant="h4">
                            <Translate keyId="BUILD.PUBLISH.INSTAGRAM.HEADERS.POST_SCHEDULE" />
                        </DSTypography>
                        <InstagramDirectFormScheduleSection
                            formik={formik}
                            locationIds={graphqlLocationIds}
                            timezone={location.timezone}
                        />
                    </DSWell>
                    <FormButtons
                        disabled={!formik.isValid || formik.isSubmitting || requiredHashtagsLoading}
                        isScheduled={!!formik.values.scheduledTime}
                        onCancel={onCancel}
                        onSubmit={formik.handleSubmit}
                    />
                </>
            )}
        </Formik>
    );
}

PublishToInstagramDirectForm.displayName = 'PublishToInstagramDirectForm';
