import { DSAlert, DSAlertProps, DSAlertTitle, DSFade, DSIconButton } from '@deltasierra/components';
import { assertNever } from '@deltasierra/shared';
import * as React from 'react';
import styled from 'styled-components';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import { NotifierNotificationProps } from '../notifier-notification';
import { useDismissTimeout } from './useDismissTimeout';

const StyledAlert = styled(DSAlert)<{ $clickable: boolean }>`
    ${props => props.$clickable && 'cursor: pointer;'}
`;

type NotifierToastType = 'error' | 'success';

function getColorFromType(type: NotifierToastType): DSAlertProps['color'] {
    if (type === 'success') {
        return 'success';
    } else if (type === 'error') {
        return 'error';
    } else {
        return assertNever(type);
    }
}

export type NotifierToastProps = NotifierNotificationProps & {
    children: React.ReactNode;
    title?: string;
    type: NotifierToastType;
    dataCy?: string;
};

/**
 * A notifier component for displaying success/error Alert
 *
 * @param props - NotifierToastProps
 * @param props.children - child component within the Alert
 * @param props.defaultClickAsDismiss - whether onClick should be defaulted to be the same as onClickDismiss
 * @param props.dismiss - callback on dismissal of notification
 * @param props.extendTimeoutMs - length of time (ms) to extend the alert once alert is hovered over
 * @param props.isTransitioningOut - whether the alert is transitioning out (i.e. fade transition)
 * @param props.onClick - callback on alert click
 * @param props.onClickDismiss - callback on alert close/dismiss click
 * @param props.onTransitionOut - callback on alert transitioning out
 * @param props.timeoutMs - length of time (ms) the alert should be shown for
 * @param props.title - alert title
 * @param props.transitionTimeMs - length of time(ms) for transition
 * @param props.type - the type of alert (i.e. success/error)
 * @param props.dataCy - Cypress data ID
 * @returns The jsx element
 */
export function NotifierToast({
    children,
    dataCy,
    defaultClickAsDismiss = true,
    dismiss,
    extendTimeoutMs,
    isTransitioningOut,
    onClick,
    onClickDismiss,
    onTransitionOut,
    timeoutMs,
    title,
    transitionTimeMs = 500,
    type,
}: NotifierToastProps): JSX.Element {
    const { handleMouseEnter, handleMouseLeave } = useDismissTimeout({ dismiss, extendTimeoutMs, timeoutMs });

    const handleClick = onClick ?? (defaultClickAsDismiss ? onClickDismiss : undefined);

    return (
        <DSFade
            in={!isTransitioningOut}
            mountOnEnter
            timeout={transitionTimeMs}
            unmountOnExit
            onExited={onTransitionOut}
        >
            <StyledAlert
                $clickable={!!handleClick}
                action={
                    onClickDismiss && (
                        <DSIconButton data-testid="notifier-toast-close" size="small" onClick={onClickDismiss}>
                            <CloseIcon />
                        </DSIconButton>
                    )
                }
                color={getColorFromType(type)}
                data-cy={dataCy}
                iconMapping={{
                    error: <WarningIcon />,
                    info: <InfoIcon />,
                    success: <CheckCircleIcon />,
                    warning: <WarningIcon />,
                }}
                severity={type}
                variant="outlined"
                onClick={handleClick}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                {title && <DSAlertTitle>{title}</DSAlertTitle>}
                {children}
            </StyledAlert>
        </DSFade>
    );
}
NotifierToast.displayName = 'NotifierToast';
