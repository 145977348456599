import moment from 'moment-timezone';

// It is important to maintain the time units in decending order (largest time unit to smallest time unit).
const timeUnitArray = ['years', 'months', 'weeks', 'days', 'hours', 'minutes', 'seconds'] as const;
export type TimeUnit = typeof timeUnitArray[number];

/**
 * @param from - starting Date
 * @param to - ending Date
 * @returns - object containing the time difference and time unit
 */
export function getDifferenceWithGreatestUnitOfTime(from: Date, to: Date): { timeDifference: number; unit: TimeUnit } {
    const fromDate = moment(from);
    const toDate = moment(to);
    for (const timeUnit of timeUnitArray) {
        const timeDiff = toDate.diff(fromDate, timeUnit);
        if (timeDiff > 0) {
            return { timeDifference: timeDiff, unit: timeUnit };
        }
    }
    return { timeDifference: 0, unit: 'seconds' };
}
