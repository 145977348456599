import { DSGrid, DSTypography, Loading, Translate } from '@deltasierra/components';
import React from 'react';
import { LocationGroupThumbnail } from './LocationGroupThumbnail';
import { useLocationGroups } from './useLocationGroups';

export type LocationGroupsThumbnailListProps = {
    locationId: string;
};

export function LocationGroupsThumbnailList({ locationId }: LocationGroupsThumbnailListProps): JSX.Element {
    const { groups, loading } = useLocationGroups({ locationId });
    return (
        <>
            {loading && <Loading data-testid="loading" />}
            {groups && (
                <>
                    {groups.length > 0 ? (
                        <DSGrid alignItems="center" container spacing={3}>
                            {groups.map(group => (
                                <DSGrid item key={group.id} md={3} role="listitem" sm={6} xs={12}>
                                    <LocationGroupThumbnail locationCategoryOption={group} />
                                </DSGrid>
                            ))}
                        </DSGrid>
                    ) : (
                        <DSTypography>
                            <Translate keyId="AGENCY.CLIENT.LOCATIONS.NO_LOCATION_GROUPS" />
                        </DSTypography>
                    )}
                </>
            )}
        </>
    );
}
LocationGroupsThumbnailList.displayName = 'LocationGroupsThumbnailList';
