import {
    BuilderTemplateFormat,
    BuilderTemplateFormatId,
    formatSizeOption,
    GetMappedSKUFormatOptionsResponse,
    PrintProviderSKUGroupId,
    PrintProviderSKUId,
} from '@deltasierra/shared';
import { MvIdentity } from '../../../../../account/mvIdentity';
import {
    $scopeSID,
    actualComponent,
    ExpressionBinding,
    ExpressionCallback,
    ILifecycleHooks,
    OneWayBinding,
    OptionalOneWayBinding,
} from '../../../../../common/angularData';
import { DataUtils } from '../../../../../common/dataUtils';
import { InteractionUtils } from '../../../../../common/interactionUtils';
import {
    MvBuilderTemplateFormatResource,
    mvBuilderTemplateFormatResourceSID,
} from '../../../../../contentBuilder/mvBuilderTemplateFormatResource';
import { I18nService } from '../../../../../i18n';
import {
    AngularjsDropdownMultiselectEvents,
    AngularjsDropdownMultiselectExtraSettings,
    AngularjsDropdownMultiselectTranslationTexts,
} from '../../../../../typings/angularjsDropdownMultiselect/angularjsDropdownMultiselect';
import { SKUManagementApiClient } from '../../../skuManagementApiClient';
import IScope = angular.IScope;

interface BuilderTemplateFormatOption extends BuilderTemplateFormat {
    disabled?: boolean;
}

export class BuilderTemplateFormatToSKUPickerCtrl implements ILifecycleHooks {
    // Props (inputs)
    skuGroupId!: PrintProviderSKUGroupId;

    skuId?: PrintProviderSKUId;

    change!: ExpressionCallback<{ formats: BuilderTemplateFormatId[] }>;

    // State
    options: BuilderTemplateFormatOption[] = [];

    selectedOptions: BuilderTemplateFormat[] = [];

    multiselectSettings: AngularjsDropdownMultiselectExtraSettings = {
        checkBoxes: true,
        displayProp: 'label' as keyof BuilderTemplateFormat,
        showCheckAll: false,
        showUncheckAll: true,
        enableSearch: true,
        searchField: 'label' as keyof BuilderTemplateFormat,
        scrollable: true,
        smartButtonMaxItems: 4,
        keyboardControls: true,
    };

    multiselectEvents: AngularjsDropdownMultiselectEvents = {
        onSelectionChanged: () => this.onSelectionChange(),
    };

    multiselectTexts: AngularjsDropdownMultiselectTranslationTexts = {
        buttonDefaultText: this.i18nService.text.common.formats(),
        dynamicButtonTextSuffix: this.i18nService.text.common.formats(),
        searchPlaceholder: this.i18nService.text.common.search(),
        uncheckAll: this.i18nService.text.common.none(),
    };

    // Futures
    fetchFormats = this.interactionUtils.createFuture(this.i18nService.text.common.fetchData(), () =>
        this.mvBuilderTemplateFormatResource
            .query({
                type: 'print',
            })
            .$promise.then(async options => {
                // Fetch unavailable format ids
                if (!this.skuGroupId) {
                    return;
                }

                return this.skuManagementApiClient.getMappedSKUFormatOptions(this.skuGroupId).then(mappedOptions => {
                    this.options = options;
                    this.options.forEach(option => {
                        option.label = this.getFormatLabel(option);
                        option.disabled = false;
                    });
                    this.setAvailableOptions(mappedOptions);
                    this.preselectOptions(mappedOptions);
                });
            }),
    );

    static readonly $inject: string[] = [
        $scopeSID,
        I18nService.SID,
        InteractionUtils.SID,
        mvBuilderTemplateFormatResourceSID,
        DataUtils.SID,
        SKUManagementApiClient.SID,
        MvIdentity.SID,
    ];

    constructor(
        private readonly $scope: IScope,
        private readonly i18nService: I18nService,
        private readonly interactionUtils: InteractionUtils,
        private readonly mvBuilderTemplateFormatResource: MvBuilderTemplateFormatResource,
        private readonly dataUtils: DataUtils,
        private readonly skuManagementApiClient: SKUManagementApiClient,
        private readonly identity: MvIdentity,
    ) {}

    $onInit() {
        this.initWatchers();
        return this.fetchFormats.run({});
    }

    // Should this be in a common file?
    getFormatLabel(format: BuilderTemplateFormat): string {
        if (!this.identity.currentUser) {
            return '';
        }
        const units = this.identity.currentUser.printUnits;
        return format.type !== 'email'
            ? formatSizeOption(format, { isPrintDocument: format.type === 'print', userPreferencePrintUnits: units })
            : format.label;
    }

    setAvailableOptions(mappedOptions: GetMappedSKUFormatOptionsResponse[]): void {
        mappedOptions.forEach(mappedOption => {
            const index = this.dataUtils.indexOfBy('id', this.options, mappedOption.builderTemplateFormatId);
            if (index > -1 && mappedOption.printProviderSKUId != this.skuId) {
                // Rather disable the option than hide it completely
                this.options[index].disabled = true;
            }
        });
    }

    initWatchers() {
        this.$scope.$watch(
            () => this.skuId,
            (newValue, oldValue) => newValue != oldValue && newValue != undefined && this.fetchFormats.run({}),
        );
    }

    preselectOptions(mappedOptions: GetMappedSKUFormatOptionsResponse[]) {
        const selectedFormatOptions: BuilderTemplateFormat[] = [];
        const preSelectedOptions: BuilderTemplateFormatId[] = [];

        mappedOptions.forEach(mappedOption => {
            if (mappedOption.printProviderSKUId == this.skuId) {
                preSelectedOptions.push(mappedOption.builderTemplateFormatId);
            }
        });

        if (preSelectedOptions && preSelectedOptions.length > 0) {
            for (const option of this.options) {
                if (preSelectedOptions.indexOf(BuilderTemplateFormatId.from(option.id)) > -1) {
                    selectedFormatOptions.push(option);
                }
            }
        }
        this.selectedOptions = selectedFormatOptions;
        this.onSelectionChange(); // Do an initial callback, so that parent components get the initially selected formats.
    }

    onSelectionChange() {
        this.change({
            formats: this.selectedOptions.map(format => BuilderTemplateFormatId.from(format.id)),
        });
    }
}

// Use title case for Sku to avoid issues with unrecognized HTML tag
export const builderTemplateFormatToSKUPickerSID = 'builderTemplateFormatToSkuPicker';
export const builderTemplateFormatToSKUPickerConfig: ng.IComponentOptions = actualComponent(
    BuilderTemplateFormatToSKUPickerCtrl,
    '/partials/agency/printProviderSKUGroups/skus/skuDetails/builderTemplateFormatToSKUPicker/builderTemplateFormatToSKUPicker',
    {
        skuGroupId: OneWayBinding,
        skuId: OptionalOneWayBinding,
        change: ExpressionBinding,
    },
);

angular.module('app').component(builderTemplateFormatToSKUPickerSID, builderTemplateFormatToSKUPickerConfig);
