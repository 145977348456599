/* eslint-disable max-statements */
/// <reference path="../../../typings/browser.d.ts" />
import { BuilderFontConfig, BuilderFontCustomDto, Client, Omit, Untyped, Upload } from '@deltasierra/shared';
import { $locationSID, $qSID, $routeParamsSID, $routeSID, $scopeSID } from '../common/angularData';
import { MvNotifier } from '../common/mvNotifier';
import { UploadContext, UploadService } from '../common/uploadService';
import { I18nService } from '../i18n';
import { FontChooserService } from './fontChooser';
import { MvClientResource, mvClientResourceSID } from './mvClientResource';
import IRouteParams = angular.IRouteParams;
import ILocationService = angular.ILocationService;
import IQService = angular.IQService;
import IResource = angular.resource.IResource;
import IPromise = angular.IPromise;

type BuilderFontConfigAdminDto = BuilderFontConfig & {
    custom: Array<Omit<BuilderFontCustomDto, 'clientId' | 'id' | 'metrics' | 'upload' | 'uploadId'>>;
};

interface MvClientFontsCtrlScope {
    client_id: string;
    client: Client;
    builderFontConfig: (BuilderFontConfigAdminDto & IResource<BuilderFontConfigAdminDto>) | null;
    loading: boolean;
    fontUploads: Upload[] | null;
    customAdvancedToggles: { [key: number]: boolean };
    currentCustomFont: BuilderFontCustomDto | null;
    uploadContext?: UploadContext;
    isSaving: boolean;

    isActive(route: string): boolean;
    addCustom(): void;
    saveBuilderFontConfig(): void;
    hasFonts(): boolean;
    removeFont(fontArray: Untyped[], index: number): void;
    selectFont(customFontObject: BuilderFontCustomDto): IPromise<void>;
    uploadFont(customFontObject: BuilderFontCustomDto, fontFile: File): IPromise<void>;
    getUploadGlyph(ext: string): string;
    toggleCustomAdvanced(customFontConfig: BuilderFontCustomDto): void;
    isCustomAdvancedVisible(customFontConfig: BuilderFontCustomDto): boolean;
    hasCustomFontWithoutAnUpload(): boolean;
}

angular.module('app').controller('mvClientFontsCtrl', [
    $scopeSID,
    $routeParamsSID,
    $routeSID,
    $locationSID,
    $qSID,
    MvNotifier.SID,
    'mvClientBuilderFontConfigResource', // TODO: SID
    mvClientResourceSID,
    UploadService.SID,
    FontChooserService.SID,
    I18nService.SID,

    function mvClientFontsCtrl(
        $scope: MvClientFontsCtrlScope,
        $routeParams: IRouteParams,
        $route: Untyped,
        $location: ILocationService,
        $q: IQService,
        mvNotifier: MvNotifier,
        mvClientBuilderFontConfigResource: Untyped, // TODO: type
        mvClientResource: MvClientResource,
        uploadService: UploadService,
        fontChooserService: FontChooserService,
        i18nService: I18nService,
    ) {
        $scope.client_id = $routeParams.id;
        $scope.client = mvClientResource.get({ id: $routeParams.id });
        $scope.builderFontConfig = null;
        $scope.loading = false;
        $scope.fontUploads = null;
        $scope.customAdvancedToggles = {};
        $scope.currentCustomFont = null;
        $scope.isSaving = false;

        function initPage() {
            $scope.loading = true;
            mvClientBuilderFontConfigResource
                .get({ clientId: $scope.client_id })
                .$promise.then((builderFontConfig: Untyped) => {
                    $scope.builderFontConfig = builderFontConfig;
                    $scope.loading = false;
                })
                .catch((data: Untyped) => {
                    mvNotifier.unexpectedErrorWithData('Failed to retrieve font config data', data);
                    $scope.loading = false;
                });
        }

        $scope.isActive = (route: string) => route === $location.path();

        $scope.addCustom = () => {
            $scope.builderFontConfig!.custom.push({
                family: '',
                stretch: 'normal',
                style: 'normal',
                weight: 'normal',
                // UploadId: null
            });
        };

        $scope.saveBuilderFontConfig = () => {
            $scope.isSaving = true;
            $scope
                .builderFontConfig!.$save({ clientId: $scope.client_id })
                .then(() => {
                    mvNotifier.notify(i18nService.text.agency.client.location.fonts.fontsSaved());
                    $route.reload();
                })
                .catch((data: Untyped) => {
                    mvNotifier.unexpectedErrorWithData('Failed to save font config', data);
                });
        };

        $scope.hasFonts = () => {
            if (!$scope.builderFontConfig) {
                return false;
            }
            return $scope.builderFontConfig.custom.length > 0;
        };

        $scope.removeFont = (fontArray: Untyped[], index: number) => {
            fontArray.splice(index, 1);
        };

        $scope.selectFont = (customFontObject: BuilderFontCustomDto) =>
            fontChooserService.showModal().then(fontFile => $scope.uploadFont(customFontObject, fontFile));

        $scope.uploadFont = async (customFontObject: BuilderFontCustomDto, fontFile: File) => {
            const fontUploads: Upload[] = [];
            $scope.fontUploads = fontUploads;
            $scope.currentCustomFont = customFontObject;
            const imageUploadPromises = uploadService.upload([fontFile], 'font', fontUploads, $scope);
            return $q
                .all(imageUploadPromises)
                .then(() => {
                    customFontObject.upload = fontUploads[0];
                    // TODO: delete the old upload properly, instead of leaving it orphaned
                    customFontObject.uploadId = customFontObject.upload.id; // Replace any existing font.
                    $scope.fontUploads = null;
                    $scope.currentCustomFont = null;
                })
                .catch((err: any) => {
                    $scope.fontUploads = null;
                    $scope.currentCustomFont = null;
                    mvNotifier.unexpectedErrorWithData('Failed to upload font', err);
                });
        };

        $scope.getUploadGlyph = (ext: string) => uploadService.getUploadGlyph(ext);

        $scope.toggleCustomAdvanced = (customFontConfig: BuilderFontCustomDto) => {
            const index = $scope.builderFontConfig!.custom.indexOf(customFontConfig);
            // Assume index should never be -1
            $scope.customAdvancedToggles[index] = !$scope.customAdvancedToggles[index];
        };

        $scope.isCustomAdvancedVisible = (customFontConfig: BuilderFontCustomDto) => {
            const index = $scope.builderFontConfig!.custom.indexOf(customFontConfig);
            return !!$scope.customAdvancedToggles[index];
        };

        $scope.hasCustomFontWithoutAnUpload = () =>
            !!$scope.builderFontConfig && $scope.builderFontConfig.custom.some(bfc => !bfc.upload);

        initPage();
    },
]);
