import * as linq from 'linq';
import {
    GetUnreadNotificationsResponse, InAppNotification,
    inAppNotificationSocketEvent, UNREAD_NOTIFICATIONS_LIMIT,
} from '@deltasierra/shared';
import { InAppNotificationApiClient } from '../inAppNotificationApiClient';
import { InteractionUtils } from '../../common/interactionUtils';
import { SocketService } from '../../sockets/socketService';
import { $locationSID, $rootScopeSID, $scopeSID, $windowSID, ILifecycleHooks } from '../../common/angularData';
import { I18nService } from '../../i18n/i18nService';
import { BaseInAppNotificationsController } from '../baseInAppNotificationController';
import { MvIdentity } from '../../account/mvIdentity';
import IScope = angular.IScope;
import IWindowService = angular.IWindowService;
import IPromise = angular.IPromise;
import IRootScopeService = angular.IRootScopeService;
import ILocationService = angular.ILocationService;
import IAngularEvent = angular.IAngularEvent;

const REDIRECT_ON_CLICK_WIDTH_THRESHOLD = 480;

export class NotificationsBadgerController extends BaseInAppNotificationsController implements ILifecycleHooks {
    public static SID = 'NotificationsBadgerController';

    isOpen = false;

    readonly fetchLatestUnread = this.interactionUtils.createFuture('fetch latest unread notifications',
        () => this.inAppNotificationApiClient.getUnread()
                .then((response : GetUnreadNotificationsResponse) => {
                    this.notifications = response.recent;
                    this.unreadNotificationsCount = response.count;
                }).catch(() => {
                    // Swallow any errors, because most of the logged errors are spurious.
                }));

    protected readonly windowClickHandler = (event : IAngularEvent | MouseEvent) => {
        this.$scope.$apply(() => {
            // I've had to use some jQuery to work out if we've clicked outside of the notification badger.
            // There may be another way to do this through the directive, but... this works.
            if ((event as MouseEvent).target && $((event as MouseEvent).target!).closest('#notificationsBadger').length == 0) {
                if (this.isOpen) {
                    this.toggleDropdown();
                }
                this.$window.removeEventListener('mouseup', this.windowClickHandler);
            }
        });
    };

    // Tslint:disable-next-line:member-ordering
    static readonly $inject : string[] = [
        $windowSID,
        $rootScopeSID,
        $scopeSID,
        $locationSID,
        I18nService.SID,
        InteractionUtils.SID,
        SocketService.SID,
        InAppNotificationApiClient.SID,
        MvIdentity.SID,
    ];

    constructor(
        protected readonly $window : IWindowService,
        $rootScope : IRootScopeService,
        $scope : IScope,
        protected readonly $location : ILocationService,
        i18nService : I18nService,
        interactionUtils : InteractionUtils,
        protected readonly socketService : SocketService,
        inAppNotificationApiClient : InAppNotificationApiClient,
        protected readonly mvIdentity: MvIdentity,
    ) {
        super($rootScope, $scope, i18nService, interactionUtils, inAppNotificationApiClient, mvIdentity);
    }

    $onInit() {
        this.listenToMarkedAsReadEvent((event, data) => this.handleMarkedAsRead(data));
        this.listenToMarkedAllAsReadEvent(event => this.handleMarkedAllAsRead());
        return this.fetchLatestUnread.run({})
            .then(() => {
                this.socketService.on<InAppNotification>(inAppNotificationSocketEvent, this.socketHandler);
            });
    }

    $onDestroy() {
        this.$window.removeEventListener('mouseup', this.windowClickHandler);
        this.socketService.off<InAppNotification>(inAppNotificationSocketEvent, this.socketHandler);
    }

    protected handleMarkedAsRead(notification : InAppNotification) : IPromise<void> | void {
        const existingNotification = linq.from(this.notifications)
            .firstOrDefault(n => n.id == notification.id);
        if (existingNotification) {
            existingNotification.readAt = new Date(); // This date is not entirely accurate, but it doesn't really matter here
            this.notifications.splice(this.notifications.indexOf(existingNotification), 1);
            this.unreadNotificationsCount--;
            if (this.notifications.length < UNREAD_NOTIFICATIONS_LIMIT && this.unreadNotificationsCount + 1 > UNREAD_NOTIFICATIONS_LIMIT) {
                return this.fetchLatestUnread.run({});
            }
        }
    }

    protected handleMarkedAllAsRead() : IPromise<void> | void {
        this.notifications.length = 0;
        this.unreadNotificationsCount = 0;
        return this.fetchLatestUnread.run({});
    }

    protected getUnreadNotificationsLimit() : number {
        return UNREAD_NOTIFICATIONS_LIMIT;
    }

    toggleDropdown(): void {
        this.isOpen = !this.isOpen;
        if (this.isOpen) {
            // We listen to mouseup, because the bootstrap dropdown seems to interfere with a normal "click" handler.
            // (i.e. clicking on the profile dropdown menu to open it, will not close the notifications drawer.)
            this.$window.addEventListener('mouseup', this.windowClickHandler);
        }
    }

    clickToggleDropdown(): void {
        if (this.$window.innerWidth <= REDIRECT_ON_CLICK_WIDTH_THRESHOLD) {
            this.isOpen = false;
            this.$window.removeEventListener('mouseup', this.windowClickHandler);
            this.$location.url('/notifications');
        } else {
            return this.toggleDropdown();
        }
    }

    onClickNotification(notification: InAppNotification): IPromise<any> | void {
        if (notification.type !== 'Agency.CustomMessage') {
            return this.markAsClicked(notification);
        }
    }
}
