/// <reference path="../../../../../typings/browser.d.ts" />
import { AssignedLocation, ClientId } from '@deltasierra/shared';
import { $qSID } from '../../../common/angularData';
import { DataUtils } from '../../../common/dataUtils';
import { InteractionUtils } from '../../../common/interactionUtils';
import { MvNotifier } from '../../../common/mvNotifier';
import { I18nService } from '../../../i18n';
import { MvLocation } from '../../../locations/mvLocation';
import { FitwareClub, FitwareStatsService } from '../../stats/fitwareStatsService';
import IQService = angular.IQService;

interface FitwareLocationConfigProperties {
    model: AssignedLocation | null;
}

interface FitwareLocationConfigScope extends FitwareLocationConfigProperties, ng.IScope {
    ctrl: MvFitwareLocationConfigCtrl;
}

export class MvFitwareLocationConfigCtrl implements FitwareLocationConfigProperties {
    public static readonly SID = 'mvFitwareLocationConfigCtrl';

    public static readonly $inject: string[] = [
        $qSID,
        InteractionUtils.SID,
        MvNotifier.SID,
        FitwareStatsService.SID,
        MvLocation.SID,
        DataUtils.SID,
        I18nService.SID,
    ];

    public model: AssignedLocation | null = null;

    public loading = {
        canConfigure: false,
        fitwareClubs: false,
        updateFitwareClub: false,
    };

    public canConfigure = false;

    public selectedFitwareClub: FitwareClub | null = null;

    public fitwareClubs?: FitwareClub[];

    private clientId: ClientId | null = null;


    public constructor(
        private readonly $q: IQService,
        private readonly interactionUtils: InteractionUtils,
        private readonly mvNotifier: MvNotifier,
        private readonly fitwareStatsService: FitwareStatsService,
        private readonly mvLocation: MvLocation,
        private readonly dataUtils: DataUtils,
        private readonly i18n: I18nService,
    ) {}

    public updateLocation(): void {
        this.init();
    }


    public init(): any {
        if (this.model) {
            if (this.model.clientId != this.clientId) {
                this.clientId = this.model.clientId;
                // Get the clubs for this client
                return this.getAndSetCanConfigure().then(this.getAndSetFitwareClubs.bind(this));
            } else {
                this.selectFitwareClub();
            }
        }
    }

    public async updateFitwareClub(): Promise<void> {
        const club = this.selectedFitwareClub;
        if (this.model) {
            const model = this.model;
            return this.interactionUtils
                .handleRemoteSimple(
                    this,
                    'Update Fitware club',
                    'updateFitwareClub', // TODO: translate this
                    this.mvLocation.updateFitwareClub(model.id, club),
                )
                .then(() => {
                    this.mvNotifier.notify(this.i18n.text.integration.fitware.notifyClubUpdated());
                    this.selectedFitwareClub = club;
                    model.fitwareClubNumber = club ? String(club.ClubNumber) : null;
                    model.fitwareClubName = club ? club.ClubName : null;
                });
        }
    }

    private getAndSetCanConfigure(): ng.IPromise<void> {
        if (this.model) {
            return this.fitwareStatsService.canConfigure(this.model.id).then((canConfigure: boolean) => {
                this.canConfigure = canConfigure;
            });
        } else {
            this.canConfigure = false;
            return this.$q.resolve();
        }
    }


    private async getAndSetFitwareClubs(): Promise<void> {
        if (this.canConfigure && this.clientId) {
            return this.interactionUtils
                .handleRemote(
                    this,
                    'retrieve Fitware clubs',
                    'fitwareClubs', // TODO: translate this
                    this.fitwareStatsService.getClubs(this.clientId),
                )
                .then((fitwareClubs: FitwareClub[]) => {
                    this.fitwareClubs = fitwareClubs;
                    this.selectFitwareClub();
                });
        }
    }

    private selectFitwareClub() {
        if (this.fitwareClubs && this.model && this.model.fitwareClubNumber) {
            const club = this.dataUtils.findBy('ClubNumber', this.fitwareClubs, this.model.fitwareClubNumber);
            this.selectedFitwareClub = club;
        } else {
            this.selectedFitwareClub = null;
        }
    }
}
angular.module('app').controller(MvFitwareLocationConfigCtrl.SID, MvFitwareLocationConfigCtrl);

export const dsFitwareLocationConfigConfig: ng.IDirective<FitwareLocationConfigScope> = {
    bindToController: true, // TODO: investigate why this isn't working for model
    controller: MvFitwareLocationConfigCtrl,
    controllerAs: 'ctrl',
    link(scope: FitwareLocationConfigScope, element, attrs, ngModelCtrl: ng.INgModelController) {
        ngModelCtrl.$render = () => {
            scope.model = ngModelCtrl.$modelValue;
            scope.ctrl.model = scope.model; // TODO: proper binding
            scope.ctrl.updateLocation();
        };
    },
    require: 'ngModel',
    restrict: 'E',
    scope: {},
    templateUrl: '/partials/integration/auth/fitware/fitwareLocationConfig',
};

export const dsFitwareLocationConfigSID = 'dsFitwareLocationConfig';

angular.module('app').directive(dsFitwareLocationConfigSID, [() => dsFitwareLocationConfigConfig]);
