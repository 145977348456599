import * as React from 'react';
import { gql } from '@apollo/client';
import { AssetThumbnailContent, AssetThumbnailTitle } from '@deltasierra/components';
import { FileTypeIcon } from '../../../../common/FileTypeIcon';
import { AssetFileThumbnailContentFragment } from './__graphqlTypes/AssetFileThumbnailContentFragment';

const fragments = {
    AssetFileThumbnailContentFragment: gql`
        fragment AssetFileThumbnailContentFragment on AssetFile {
            id
            title
            mimeType
            size
            thumbnails {
                medium {
                    url
                }
            }
        }
    `,
};

export type AssetFileThumbnailContentProps = {
    asset: AssetFileThumbnailContentFragment;
    height: number;
};

export const AssetFileThumbnailContent: React.FC<AssetFileThumbnailContentProps> & { fragments: typeof fragments } = ({
    asset,
    height,
}) => (
    <>
        {asset.thumbnails ? (
            <>
                <AssetThumbnailTitle>{asset.title}</AssetThumbnailTitle>
                <AssetThumbnailContent>
                    <img alt={asset.title} height={height} src={asset.thumbnails.medium.url} />
                </AssetThumbnailContent>
            </>
        ) : (
            <AssetThumbnailContent>
                <div
                    style={{
                        overflow: 'hidden',
                        padding: '5px',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        width: '100%',
                    }}
                >
                    {asset.title}
                </div>
                <FileTypeIcon alt={asset.title} height={height} mimeType={asset.mimeType} style={{ padding: '5px' }} />
                <div style={{ padding: '5px' }}>{`${Math.round(asset.size / 1000)} kB`}</div>
            </AssetThumbnailContent>
        )}
    </>
);
AssetFileThumbnailContent.displayName = 'AssetFileThumbnailContent';
AssetFileThumbnailContent.fragments = fragments;
