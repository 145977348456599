import * as React from 'react';
import { useSafeRedirect } from '../../../hooks';
import {
    CurrentLocationIdType,
    CurrentLocationRouteDispatchContext,
    CurrentLocationRouteStateContext,
} from '../contexts';

export type CurrentLocationRouteContextProviderProps = {
    children: React.ReactNode;
    value: Parameters<typeof CurrentLocationRouteStateContext.Provider>[0]['value'];
} & (
    | {
          onChange: Parameters<typeof CurrentLocationRouteDispatchContext.Provider>[0]['value'];
      }
    | {
          onUpdateUrl: (newLocationId: CurrentLocationIdType) => string;
      }
);

export function CurrentLocationRouteContextProvider({
    children,
    value,
    ...props
}: CurrentLocationRouteContextProviderProps): JSX.Element {
    const redirect = useSafeRedirect();

    return (
        <CurrentLocationRouteStateContext.Provider value={value}>
            <CurrentLocationRouteDispatchContext.Provider
                value={
                    'onChange' in props
                        ? props.onChange
                        : newLocationId => {
                              if (newLocationId) {
                                  redirect(props.onUpdateUrl(newLocationId));
                              }
                          }
                }
            >
                {children}
            </CurrentLocationRouteDispatchContext.Provider>
        </CurrentLocationRouteStateContext.Provider>
    );
}
CurrentLocationRouteContextProvider.displayName = 'CurrentLocationRouteContextProvider';
