/// <reference path="../_references.d.ts" />

import { ProgressModalController, IProgressModalProgress, ProgressType } from './progressModalController';

interface IProgressModalServiceOptions {
    value? : number;
    max : number;
    title? : string;
    status? : string;
    type? : ProgressType;
    animated? : boolean;
}

class ProgressModalServiceResult implements IProgressModalProgress {
    constructor(public value : number, public max : number, public status : string | undefined, public title : string | undefined, public type : ProgressType | undefined, public close : () => void) {
    }

    increment() {
        this.value = Math.min(this.value + 1, this.max);
    }

    update(values : { value : number; max : number; status? : string }) {
        this.value = values.value;
        this.max = values.max;
        if (values.status) {
            this.status = values.status;
        }
    }
}

export class ProgressModalService {
    static SID = 'ProgressModalService';

    static readonly $inject : string[] = ['$modal'];

    constructor(private $modal : any) {
    }

    public showModal(options : IProgressModalServiceOptions) {
        const result = new ProgressModalServiceResult(options.value || 0, options.max, options.status, options.title, options.type, closeModal);

        const modalInstance = this.$modal.open({
            animation: options.animated === undefined ? true : options.animated,
            backdrop: 'static',
            keyboard: false,
            templateUrl: 'partials/common/progressModal',
            controller: ProgressModalController,
            controllerAs: 'ctrl',
            resolve: { progress() {
 return result;
} },
        });

        function closeModal() {
            modalInstance.close();
        }

        return result;
    }
}

angular.module('app').service(ProgressModalService.SID, ProgressModalService);
