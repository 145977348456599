import {
    MAX_FACEBOOK_CAPTION_CHARACTER_LENGTH,
    MAX_FACEBOOK_COMMENT_CHARACTER_LENGTH,
    MAX_FACEBOOK_HASHTAGS_COUNT,
    MAX_FACEBOOK_SCHEDULED_HOURS,
    MAX_INSTAGRAM_CAPTION_CHARACTER_LENGTH,
    MAX_INSTAGRAM_HASHTAGS_COUNT,
    t,
} from '@deltasierra/shared';
import { FormikConfig, FormikErrors } from 'formik';
import { DateTime, DurationObjectUnits } from 'luxon';
import React from 'react';
import { FormikValidateFunction } from '../../../../typings/formik-validate-function';
import { countHashtagsInString } from '../../common/count-hashtags-in-string';
import {
    buildCaption,
    BuildFacebookAndInstagramPublishDataOptions,
    buildFirstComment,
    buildHashtagString,
    getCaptionWithTermsAndConditions,
} from './build-facebook-and-instagram-publish-data';
import { FacebookAndInstagramFormValues, HashtagInsertType } from './facebook-and-instagram-form-values';

type useValidatedFacebookAndInstagramFormOptions = BuildFacebookAndInstagramPublishDataOptions & {
    date: Date | undefined;
};

export function useValidatedFacebookAndInstagramForm({
    date,
    requiredHashtags,
    termsAndConditionsText,
    termsAndConditionsUrl,
}: useValidatedFacebookAndInstagramFormOptions): Pick<
    FormikConfig<FacebookAndInstagramFormValues>,
    'initialValues' | 'validate'
> {
    const initialValues = {
        caption: '',
        hashtags: [],
        hashtagsInsertType: 'comment' as HashtagInsertType,
        scheduledTime: date || null,
    };

    const validate = React.useCallback<FormikValidateFunction<FacebookAndInstagramFormValues>>(
        // eslint-disable-next-line max-statements
        ({ caption, hashtags, hashtagsInsertType, scheduledTime }) => {
            const errors: FormikErrors<FacebookAndInstagramFormValues> = {};

            const hashtagsString = buildHashtagString(hashtags);
            const hashtagsCount = hashtagsString ? countHashtagsInString(hashtagsString) : 0;

            const captionWithTermsAndConditions = getCaptionWithTermsAndConditions({
                baseCaption: caption,
                termsAndConditionsText,
                termsAndConditionsUrl,
            });

            const finalCaption = buildCaption({
                baseCaption: caption,
                hashtagsInsertType,
                hashtagsString,
                termsAndConditionsText,
                termsAndConditionsUrl,
            });

            const firstComment = buildFirstComment({
                hashtagsInsertType,
                hashtagsString,
            });

            const maxCaptionLength = Math.min(
                MAX_INSTAGRAM_CAPTION_CHARACTER_LENGTH,
                MAX_FACEBOOK_CAPTION_CHARACTER_LENGTH,
            );

            const maxCommentLength = Math.min(
                MAX_INSTAGRAM_CAPTION_CHARACTER_LENGTH,
                MAX_FACEBOOK_COMMENT_CHARACTER_LENGTH,
            );

            const maxTags = Math.min(MAX_FACEBOOK_HASHTAGS_COUNT, MAX_INSTAGRAM_HASHTAGS_COUNT);

            // If we have a large caption we error here
            if (captionWithTermsAndConditions.length > maxCaptionLength) {
                errors.caption = t('ERRORS.INSTAGRAM_ERRORS.MAX_CHARACTER_LIMIT_FOR_CAPTION_REACHED');
            }

            if (
                // If we have a hashtag string
                hashtagsString &&
                hashtagsString.length > 0 &&
                // And the caption is over the limit, it could be because of the hashtags
                ((hashtagsInsertType === 'caption' && finalCaption.length > maxCaptionLength) ||
                    // OR the comment is over the limit, it could be because of the hashtags
                    (hashtagsInsertType === 'comment' && firstComment && firstComment.length > maxCommentLength))
            ) {
                errors.hashtags = t('BUILD.PUBLISH.HASHTAGS.ERROR_LIMIT_HASHTAG_LENGTH');
            }

            // If we have too many hashtags because of the caption
            if (
                (hashtagsInsertType === 'caption' &&
                    countHashtagsInString(captionWithTermsAndConditions) + hashtagsCount > maxTags) ||
                countHashtagsInString(captionWithTermsAndConditions) > maxTags
            ) {
                errors.caption = t('BUILD.PUBLISH.HASHTAGS.ERROR_LIMIT_HASHTAG_COUNT', {
                    count: maxTags,
                });
            }

            // If we have too many hashtags
            if (hashtagsCount > maxTags) {
                errors.hashtags = t('BUILD.PUBLISH.HASHTAGS.ERROR_LIMIT_HASHTAG_COUNT', {
                    count: maxTags,
                });
            }

            // Check Meta scheduling limits
            if (scheduledTime) {
                const scheduledTimeObject = new Date(scheduledTime);
                const luxonScheduledTime = DateTime.fromJSDate(scheduledTimeObject);
                if (!luxonScheduledTime.isValid) {
                    errors.scheduledTime = 'Invalid date';
                }

                const diff: DurationObjectUnits = luxonScheduledTime.diffNow('hours').toObject();
                // Using the maximum limit of 28 days when scheduling a dual Facebook and Instagram post
                const isValidFacebookJobDate = diff.hours ? diff.hours <= MAX_FACEBOOK_SCHEDULED_HOURS : false;

                if (!isValidFacebookJobDate) {
                    errors.scheduledTime = t(
                        'BUILD.PUBLISH.FACEBOOK.SCHEDULED_FACEBOOK_POST_MUST_BE_NO_MORE_THAN_28_DAYS_IN_FUTURE',
                    );
                }

                const luxonCurrentTime = DateTime.now();
                const isPastDate = luxonScheduledTime < luxonCurrentTime;
                if (isPastDate) {
                    errors.scheduledTime = t('BUILD.PUBLISH.INSTAGRAM.SCHEDULED_POST_MUST_BE_AT_LEAST_MINS_IN_FUTURE', {
                        minutes: 15,
                    });
                }
            }

            return errors;
        },
        [termsAndConditionsText, termsAndConditionsUrl],
    );

    return {
        initialValues,
        validate,
    };
}
