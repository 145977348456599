import * as React from 'react';
import { ApolloError, useMutation, useLazyQuery } from '@apollo/client';
import { ClubReadyListSummaryRequestStatus } from '../../../../../../../../../../../__graphqlTypes/globalTypes';
import { PublishToClubReadyStep1Data } from '../../../../PublishToClubReadyStep1/types/publish-to-club-ready-step-1-data';
import {
    GenerateClubReadyListSummaryMutation,
    GenerateClubReadyListSummaryMutationVariables,
} from './__graphqlTypes/GenerateClubReadyListSummaryMutation';
import {
    GetClubReadyListSummaryRequestQuery,
    GetClubReadyListSummaryRequestQueryVariables,
} from './__graphqlTypes/GetClubReadyListSummaryRequestQuery';
import { GENERATE_CLUB_READY_LIST_SUMMARY_MUTATION } from './use-club-ready-list-counts.mutation';
import { GET_CLUB_READY_LIST_SUMMARY_REQUEST_QUERY } from './use-club-ready-list-counts.queries';
import { getUserCountQueryVariables, getUserCountTotals, ClubReadyListTotals } from './utils';

const DATA_POLLING_INTERVAL = 3000;

type useClubReadyListCountsReturn = {
    error: ApolloError | undefined;
    loading: boolean;
    refetch: () => void;
    userCountListTotals: ClubReadyListTotals | undefined;
    maxUserCountLimitReached: boolean;
};

export function useClubReadyListCounts(
    locationIds: string[],
    step1Data: PublishToClubReadyStep1Data,
): useClubReadyListCountsReturn {
    const [isPolling, setIsPolling] = React.useState(false);

    const mutationVariables: GenerateClubReadyListSummaryMutationVariables = React.useMemo(
        () => getUserCountQueryVariables(locationIds, step1Data),
        [step1Data, locationIds],
    );

    const [
        generateClubReadyListSummary,
        {
            called: calledId,
            data: dataId,
            error: errorId,
            loading: loadingId,
        },
    ] = useMutation<
        GenerateClubReadyListSummaryMutation,
        GenerateClubReadyListSummaryMutationVariables
    >(GENERATE_CLUB_READY_LIST_SUMMARY_MUTATION, {
        fetchPolicy: 'network-only',
        variables: mutationVariables,
    });

    const requestId = React.useMemo(() =>
        dataId?.generateClubReadyListSummary.__typename === 'GenerateClubReadyListSummarySuccess'
            ? dataId.generateClubReadyListSummary.request.id
            : undefined, [dataId]);

    const [
        getClubReadyListSummaryRequest,
        {
            data: dataSummary,
            error: errorSummary,
            loading: loadingSummary,
            refetch,
            startPolling,
            stopPolling,
        },
    ] = useLazyQuery<
        GetClubReadyListSummaryRequestQuery,
        GetClubReadyListSummaryRequestQueryVariables
    >(
        GET_CLUB_READY_LIST_SUMMARY_REQUEST_QUERY,
        { fetchPolicy: 'network-only' },
    );

    React.useEffect(() => {
        if (!calledId && !requestId && generateClubReadyListSummary) {
            generateClubReadyListSummary();
        } else if (requestId && getClubReadyListSummaryRequest) {
            getClubReadyListSummaryRequest({ variables: { requestId } });
        }
    }, [calledId, requestId, generateClubReadyListSummary, getClubReadyListSummaryRequest]);

    const {
        results,
        status,
    } = React.useMemo(() => dataSummary?.ClubReadyListSummaryRequest.__typename === 'ClubReadyListSummaryRequest'
        ? dataSummary?.ClubReadyListSummaryRequest
        : {
            results: undefined,
            status: undefined,
        }, [dataSummary]);

    React.useEffect(() => {
        if (status) {
            const { requested, running } = ClubReadyListSummaryRequestStatus;
            if (status === requested || status === running) {
                startPolling(DATA_POLLING_INTERVAL);
                setIsPolling(true);
            } else {
                stopPolling();
                setIsPolling(false);
            }
        }
    }, [status, startPolling, stopPolling]);

    const userCountListTotals: ClubReadyListTotals | undefined = React.useMemo(() => results
            ? getUserCountTotals(results)
            : undefined, [results]);

    const maxUserCountLimitReached = React.useMemo(() => {
        if (results && results.edges && results.edges.length > 0) {
            return results.edges
                .some(edge =>
                    edge.node.__typename === 'ClubReadyListSummaryResult' &&
                    edge.node.summary.__typename === 'ClubReadyIntegrationListsExceedsMaxLimitError',
                );
        }
        return false;
    }, [results]);

    return {
        error: errorId || errorSummary,
        loading: loadingId || loadingSummary || isPolling,
        maxUserCountLimitReached,
        refetch: () => requestId
            ? refetch({ requestId })
            : generateClubReadyListSummary(),
        userCountListTotals,
    };
}
