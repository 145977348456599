import * as React from 'react';
import { Field, FieldProps, getIn } from 'formik';
import { noop } from '@deltasierra/object-utilities';
import { Translate, TranslateProps } from '../../directives/Translate';
import { Loading } from '../Loading';
import { RequiredFieldMarker } from './helpers';
import { OptionsList, useCleanedOptionsAndStoredTypes } from './common';

export type StandaloneSelectProps<T extends string | { toString(): string }> = {
    disabled?: boolean;
    label: TranslateProps;
    loading?: boolean;
    onChange?: (value: T) => void;
    options: OptionsList<T>;
    value: T | null;
    direction?: 'horizontal' | 'vertical';
    dataCy?: string;
};

export function StandaloneSelect<T extends string | { toString(): string }>({
    disabled,
    label,
    loading,
    onChange = noop,
    options,
    value,
    direction = 'vertical',
    dataCy,
}: StandaloneSelectProps<T>): React.ReactElement {
    const [cleanedOptions, typedValueMap] = useCleanedOptionsAndStoredTypes(options);
    return (
        <div className="form-group">
            <label className={`control-label${direction === 'horizontal' ? ' col-sm-3' : ''}`}>
                <Translate {...label} />
            </label>
            <div className={direction === 'horizontal' ? 'col-sm-9' : ''}>
                {!loading && (
                    <select
                        className="form-control"
                        data-cy={dataCy}
                        disabled={disabled}
                        value={(value || '').toString()}
                        onChange={event => onChange(typedValueMap[event.target.value]!)}
                    >
                        {cleanedOptions.map(opt => (
                            <option key={opt.value.toString()} value={opt.value.toString()}>
                                {opt.label}
                            </option>
                        ))}
                    </select>
                )}
                {loading && <Loading size="small" />}
            </div>
        </div>
    );
}
StandaloneSelect.displayName = 'StandaloneSelect';

export type SelectProps<T extends string | { toString(): string }> = {
    disabled?: boolean;
    label: string;
    name: string;
    options: OptionsList<T>;
    required?: boolean;
};

export default function Select<T extends string | { toString(): string }>({
    disabled,
    label,
    name,
    options,
    required,
    ...props
}: SelectProps<T>): React.ReactElement {
    const [cleanedOptions, typedValueMap] = useCleanedOptionsAndStoredTypes(options);
    return (
        <Field name={name.toString()}>
            {({ field, form }: FieldProps<T>) => {
                const error = getIn(form.touched, field.name) ? getIn(form.errors, field.name) : '';
                return (
                    <div className="form-group">
                        <label className="control-label col-md-2 text-nowrap">
                            {label} {required && <RequiredFieldMarker />}
                        </label>
                        <div className="col-md-10">
                            <select
                                {...props}
                                className="form-control"
                                disabled={disabled}
                                name={field.name}
                                value={field.value as string[] | number | string | undefined}
                                onBlur={field.onBlur}
                                onChange={event => form.setFieldValue(field.name, typedValueMap[event.target.value]!)}
                            >
                                {cleanedOptions.map(opt => (
                                    <option key={opt.value.toString()} value={opt.value.toString()}>
                                        {opt.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                        {error && <div className="help-block col-md-offset-2 col-md-10">{error}</div>}
                    </div>
                );
            }}
        </Field>
    );
}
