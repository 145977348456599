import * as React from 'react';
import { Formik, FormikProps, FormikValues } from 'formik';
import { t } from '@deltasierra/shared';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useAngularServiceContext } from '../../common/componentUtils/angularServiceContexts';
import { GetLocationForLocationDetailsModal } from './__graphqlTypes/GetLocationForLocationDetailsModal';
import {
    UpdateLocationDetailsForLocationDetailsModal,
    UpdateLocationDetailsForLocationDetailsModalVariables,
} from './__graphqlTypes/UpdateLocationDetailsForLocationDetailsModal';

export type LocationDetailsFormikWrapperProps = {
    onFinish: () => void;
    onValidationError: (error: Error) => void;
    render: (props: FormikProps<FormValues>) => React.ReactElement;
};

export const UPDATE_LOCATION_DETAILS_FOR_LOCATION_DETAILS_MODAL = gql`
    mutation UpdateLocationDetailsForLocationDetailsModal($input: UpdateLocationDetailsInput!) {
        updateLocationDetails(input: $input) {
            ... on UpdateLocationDetailsError {
                code
                message
            }
            ... on UpdateLocationDetailsPayload {
                location {
                    __typename
                    id
                    addressLineOne
                    addressLineThree
                    addressLineTwo
                    locationEmail
                    phoneNumber
                    websiteUrl
                }
            }
        }
    }
`;

export const GET_LOCATION_FOR_LOCATION_DETAILS_MODAL = gql`
    query GetLocationForLocationDetailsModal($locationId: ID!, $hasLocation: Boolean! = false) {
        hasCurrentLocation @client @export(as: "hasLocation")
        currentLocationId @client @export(as: "locationId")
        location(id: $locationId) @include(if: $hasLocation) {
            __typename
            id
            addressLineOne
            addressLineThree
            addressLineTwo
            displayName
            locationEmail
            phoneNumber
            title
            websiteUrl
        }
    }
`;

export type FormValues = {
    addressLineOne: string;
    addressLineThree: string;
    addressLineTwo: string;
    locationEmail: string;
    phoneNumber: string;
    websiteUrl: string;
};

export const LoadingContext = React.createContext(false);

const LocationDetailsFormikWrapper: React.FC<LocationDetailsFormikWrapperProps> = ({
    render,
    onFinish,
    onValidationError,
}) => {
    const { data } = useQuery<GetLocationForLocationDetailsModal>(GET_LOCATION_FOR_LOCATION_DETAILS_MODAL);
    const [updateLocation, { loading: updateLoading }] = useMutation<
        UpdateLocationDetailsForLocationDetailsModal,
        UpdateLocationDetailsForLocationDetailsModalVariables
    >(UPDATE_LOCATION_DETAILS_FOR_LOCATION_DETAILS_MODAL);

    const notifier = useAngularServiceContext('mvNotifier');

    const currentLocation = data?.location ?? null;

    const onSubmit = React.useCallback(
        async ({
            locationEmail,
            phoneNumber,
            addressLineOne,
            addressLineTwo,
            addressLineThree,
            websiteUrl,
        }: FormValues) => {
            if (currentLocation) {
                let values: Partial<FormValues> = { addressLineOne, locationEmail, phoneNumber, websiteUrl };
                if (addressLineTwo) {
                    values = { ...values, addressLineTwo };
                }
                if (addressLineThree) {
                    values = { ...values, addressLineThree };
                }
                const result = await updateLocation({ variables: { input: { id: currentLocation.id, ...values } } });
                if (result.data?.updateLocationDetails.__typename === 'UpdateLocationDetailsError') {
                    notifier.unexpectedError(result.data.updateLocationDetails.message);
                } else {
                    notifier.success(t('AGENCY.CLIENT.LOCATION.USERS.LOCATIONS_UPDATED'));
                    onFinish();
                }
            } else {
                notifier.unexpectedError('Something went wrong.  Please refresh the page');
            }
        },
        [currentLocation, notifier, onFinish, updateLocation],
    );

    const validate = React.useCallback(({ locationEmail, phoneNumber, addressLineOne, websiteUrl }: FormikValues) => {
        const errors: Partial<FormValues> = {};

        if (!locationEmail) {
            errors.locationEmail = t('COMMON.REQUIRED_FIELD_MESSAGE');
        } else if (!(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i).test(locationEmail)) {
            errors.locationEmail = t('LOGIN.INVALID_EMAIL_ADDRESS');
        }

        if (!phoneNumber) {
            errors.phoneNumber = t('COMMON.REQUIRED_FIELD_MESSAGE');
        }

        if (!websiteUrl) {
            errors.websiteUrl = t('COMMON.REQUIRED_FIELD_MESSAGE');
        }

        if (!addressLineOne) {
            errors.addressLineOne = t('COMMON.REQUIRED_FIELD_MESSAGE');
        }

        return errors;
    }, []);

    if (!currentLocation) {
        return null;
    }

    const initialValues: FormValues = {
        addressLineOne: currentLocation.addressLineOne || '',
        addressLineThree: currentLocation.addressLineThree || '',
        addressLineTwo: currentLocation.addressLineTwo || '',
        locationEmail: currentLocation.locationEmail || '',
        phoneNumber: currentLocation.phoneNumber || '',
        websiteUrl: currentLocation.websiteUrl || '',
    };

    return (
        <>
            {currentLocation && (
                <LoadingContext.Provider value={updateLoading}>
                    <Formik initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
                        {props => render({ ...props })}
                    </Formik>
                </LoadingContext.Provider>
            )}
        </>
    );
};
LocationDetailsFormikWrapper.displayName = 'LocationDetailsFormikWrapper';

export default LocationDetailsFormikWrapper;
