/* eslint-disable camelcase */
import { useQuery } from '@apollo/client';
import { GET_LOCATION_GROUPS_FOR_LOCATION_GROUPS_THUMBNAIL_LIST } from './LocationGroupsThumbnailList.queries';
import {
    GetLocationGroupsForLocationGroupsThumbnailList,
    GetLocationGroupsForLocationGroupsThumbnailListVariables,
    GetLocationGroupsForLocationGroupsThumbnailList_location_groups,
} from './__graphqlTypes/GetLocationGroupsForLocationGroupsThumbnailList';

export type UseLocationGroupsOptions = {
    locationId: string;
};

export function useLocationGroups({ locationId }: UseLocationGroupsOptions): {
    groups: GetLocationGroupsForLocationGroupsThumbnailList_location_groups[] | null;
    loading: boolean;
} {
    const { data, loading } = useQuery<
        GetLocationGroupsForLocationGroupsThumbnailList,
        GetLocationGroupsForLocationGroupsThumbnailListVariables
    >(GET_LOCATION_GROUPS_FOR_LOCATION_GROUPS_THUMBNAIL_LIST, {
        variables: {
            locationId,
        },
    });

    return {
        groups: data?.location?.groups ? [...data.location.groups] : null,
        loading,
    };
}
