import { CurrencyService } from '../payments/currencyService';
import IScope = angular.IScope;
import INgModelController = angular.INgModelController;
import IDirective = angular.IDirective;
import IDirectiveLinkFn = angular.IDirectiveLinkFn;

interface CurrencyScope extends IScope {
    currency : string;
}

export const currencyPattern = /^[0-9]+(\.[0-9]{1,2})?$/;

export const currencySID = 'currency';
export const currencyConfig: ng.IDirective<CurrencyScope> = {
    restrict: 'A',
    scope: {
        currency: '=',
    },
    require: 'ngModel',
};

angular.module('app').directive(currencySID, [
    CurrencyService.SID,
    function (currencyService : CurrencyService): IDirective<CurrencyScope> {
    return {
        ...currencyConfig,
        link: function (scope : CurrencyScope, element, attr, ngModel : INgModelController) {

            ngModel.$formatters.push(value => {
                if (scope.currency) {
                    return currencyService.getLargestUnit(scope.currency, value);
                } else {
                    return value;
                }
            });

            ngModel.$parsers.push(value => {
                if (scope.currency) {
                    return currencyService.getSmallestUnit(scope.currency, value);
                } else {
                    return value;
                }
            });

        } as IDirectiveLinkFn<CurrencyScope>,
    };
},
]);
