/// <reference path="../../../typings/browser.d.ts" />
import { $resourceSID } from '../common/angularData';
import IResourceService = angular.resource.IResourceService;

angular.module('app').factory('mvSpecialRequestResource', [
    $resourceSID,
    function ($resource : IResourceService) {
    const SpecialRequestResource = $resource(
        '/api/specialRequests/:id',
        { id: '@id' },
        {
            assign: {
                url: '/api/specialRequests/:id/assign',
                method: 'POST',
                params: {
                    id: '@id',
                },
            },
            getStatuses: {
                url: '/api/specialRequests/statuses',
                method: 'GET',
                isArray: true,
            },
            query: { // By default, don't get closed requests.
                url: '/api/specialRequests/open',
                method: 'GET',
                isArray: true,
            },
            getClosedSpecialRequests: {
                url: '/api/specialRequests/closed',
                method: 'GET',
                isArray: true,
            },
        },
    );
    return SpecialRequestResource;
},
]);
