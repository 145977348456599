import * as React from 'react';
import { DSGrid, HelpPopper, Translate } from '@deltasierra/components';
import {
    ChangeStateActions,
    ExportReportButton,
    isSuppressedEmailsReportRequest,
    UseExportReportButtonQuery,
} from '../../../../../../../common/exportReportButton';
import { SuppressedEmailsReportsRequestsStatus } from '../../../../../../../../../__graphqlTypes/globalTypes';
import { CLUB_READY_MULTI_LOCATION_SEND_STATS_CAP } from '../../../constants';
import { PublishToClubReadyStep1Data } from '../../PublishToClubReadyStep1/types/publish-to-club-ready-step-1-data';
import { StyledDSAlert, StyledDSFormGroup } from '../../common';
import { GenerateCSVForSuppressedEmailsMutationVariables } from '../graphql/__graphqlTypes/GenerateCSVForSuppressedEmailsMutation';
import { GENERATE_CSV_FOR_SUPPRESSED_EMAILS_MUTATION } from '../graphql/generateCsvForSuppressedEmails.mutation';
import { GET_SUPPRESSED_EMAILS_REPORT_REQUEST_QUERY } from '../graphql/getSuppressedEmailsReportRequest.query';
import { getUserCountQueryVariables } from './ClubReadyCountContainer/hooks/utils';
import { ClubReadyCountContainer, ClubReadyListCountStates } from './ClubReadyCountContainer';

export type ClubReadyStatsProps = {
    step1Data: PublishToClubReadyStep1Data;
    locationIds: string[];
    setListCountState: (isValid: ClubReadyListCountStates) => void;
};

export function ClubReadyStats({ locationIds, setListCountState, step1Data }: ClubReadyStatsProps): JSX.Element {
    const shouldHideClubreadyCount = locationIds.length > CLUB_READY_MULTI_LOCATION_SEND_STATS_CAP;

    const generateCsvPollerFn = (data: UseExportReportButtonQuery): ChangeStateActions => {
        if (isSuppressedEmailsReportRequest(data)) {
            if (data?.SuppressedEmailsReportRequest.__typename === 'SuppressedEmailsReportRequest') {
                if (data.SuppressedEmailsReportRequest.status === SuppressedEmailsReportsRequestsStatus.errored) {
                    return {
                        error: 'The server returned an error state',
                        type: 'error',
                    };
                } else if (
                    data.SuppressedEmailsReportRequest.status === SuppressedEmailsReportsRequestsStatus.succeeded
                ) {
                    if (data.SuppressedEmailsReportRequest.generatedReport?.upload.signedUrl) {
                        return {
                            downloadUrl: data.SuppressedEmailsReportRequest.generatedReport?.upload.signedUrl ?? '',
                            type: 'complete',
                        };
                    } else {
                        return {
                            error: 'The generated report could not be found',
                            type: 'error',
                        };
                    }
                } else if (
                    data.SuppressedEmailsReportRequest.status === SuppressedEmailsReportsRequestsStatus.in_progress ||
                    data.SuppressedEmailsReportRequest.status === SuppressedEmailsReportsRequestsStatus.requested
                ) {
                    return {
                        generateReportRequestId: data.SuppressedEmailsReportRequest.id,
                        type: 'poll',
                    };
                }
            } else if (
                data?.SuppressedEmailsReportRequest.__typename === 'SuppressedEmailsReportRequestNotFoundError'
            ) {
                return {
                    error: data.SuppressedEmailsReportRequest?.message,
                    type: 'error',
                };
            }
        }
        return {
            error: 'There was an issue processing the requested data',
            type: 'error',
        };
    };

    const queryVariables: GenerateCSVForSuppressedEmailsMutationVariables = React.useMemo(
        () => getUserCountQueryVariables(locationIds, step1Data),
        [step1Data, locationIds],
    );

    React.useEffect(() => {
        if (shouldHideClubreadyCount) {
            // Assuming if > 10 locations selected, recipient count will be > 2
            setListCountState(ClubReadyListCountStates.HIDDEN);
        }
    }, [setListCountState, shouldHideClubreadyCount]);

    if (shouldHideClubreadyCount) {
        return (
            <StyledDSAlert severity="info">
                <Translate keyId="BUILD.SUMMARY_HIDDEN_MESSAGE" />
            </StyledDSAlert>
        );
    }

    // This component will be expanded with more elements once DSL-1422 is merged
    return (
        <DSGrid container direction="row">
            <DSGrid item>
                <ClubReadyCountContainer
                    locationIds={locationIds}
                    setListCountState={setListCountState}
                    step1Data={step1Data}
                />
            </DSGrid>
            <DSGrid item style={{ flex: 1 }}>
                <StyledDSFormGroup>
                    <ExportReportButton
                        mutation={GENERATE_CSV_FOR_SUPPRESSED_EMAILS_MUTATION}
                        mutationVariables={queryVariables}
                        pollerFn={generateCsvPollerFn}
                        query={GET_SUPPRESSED_EMAILS_REPORT_REQUEST_QUERY}
                        successTitle="BUILD.PUBLISH.CLUB_READY.EXPORT_TITLE"
                        title="BUILD.PUBLISH.CLUB_READY.EXPORT_TITLE"
                    />
                </StyledDSFormGroup>
            </DSGrid>
            <DSGrid item style={{ paddingTop: '3px' }}>
                <HelpPopper margin="left" popperIcon="none">
                    <Translate keyId="BUILD.PUBLISH.CLUB_READY.EXPORT_INFO" />
                </HelpPopper>
            </DSGrid>
        </DSGrid>
    );
}

ClubReadyStats.displayName = 'ClubReadyStats';
