import { User, UserId, Untyped, generateHSLColorPaletteUsingSatAndLum, HSL, HSLHelper } from '@deltasierra/shared';

export class UserUIService {
    public static SID = 'UserUIService';

    public static readonly EMPTY_PROFILE_PICTURE_COLORS = generateHSLColorPaletteUsingSatAndLum(0.3, 0.5, 4);

    public static readonly $inject: string[] = [];

    public constructor() {
        //
    }

    public getUserProfilePictureColor(userId: UserId): HSL {
        // Use an offset so the author of this feature
        // Gets their favorite color on production 😜
        const ADDITIONAL_OFFSET = 2;
        const colorIndex = (userId + ADDITIONAL_OFFSET) % UserUIService.EMPTY_PROFILE_PICTURE_COLORS.length;
        const color = UserUIService.EMPTY_PROFILE_PICTURE_COLORS[colorIndex];
        return color;
    }

    public getUserInitials(user: User): string {
        if (!user) {
            return '';
        }

        const getInitial = (name: Untyped) => (name || '').substr(0, 1).toUpperCase();

        return getInitial(user.firstName) + getInitial(user.lastName);
    }

    public getUserProfilePictureGradient(userId: UserId): string {
        const hslHelper = new HSLHelper();
        const color1 = this.getUserProfilePictureColor(userId);
        const color2 = {
            ...color1,
            l: color1.l - 0.1,
        };
        const result = `linear-gradient(135deg, ${hslHelper.toString(color1)} 0%, ${hslHelper.toString(color2)} 100%)`;
        return result;
    }
}

angular.module('app').service(UserUIService.SID, UserUIService);
