import { GenerateCSVForSuppressedEmailsMutation } from '../../contentBuilder/email/publish/clubReady/components/PublishToClubReadyStep3/graphql/__graphqlTypes/GenerateCSVForSuppressedEmailsMutation';
import { GetSuppressedEmailsReportRequestQuery } from '../../contentBuilder/email/publish/clubReady/components/PublishToClubReadyStep3/graphql/__graphqlTypes/GetSuppressedEmailsReportRequestQuery';
import {
    GenerateCSVForTemplateUsageMutation,
    GetTemplateUsageReportRequestQuery,
} from '../../domain/client/components/PopularBuilderTemplates/PopularBuilderTemplateDetailsModal/graphql';
import { UseExportReportButtonMutation, UseExportReportButtonQuery } from './types';

export const isSuppressedEmailsReport = (
    object: UseExportReportButtonMutation,
): object is GenerateCSVForSuppressedEmailsMutation => 'generateCSVForSuppressedEmails' in object;

export const isSuppressedEmailsReportRequest = (
    object: UseExportReportButtonQuery,
): object is GetSuppressedEmailsReportRequestQuery => 'SuppressedEmailsReportRequest' in object;

export const isTemplateUsageReport = (
    object: UseExportReportButtonMutation,
): object is GenerateCSVForTemplateUsageMutation => 'generateCSVForTemplateUsage' in object;

export const isTemplateUsageReportRequest = (
    object: UseExportReportButtonQuery,
): object is GetTemplateUsageReportRequestQuery => 'templateUsageReportRequest' in object;
