/// <reference path="../../../typings/browser.d.ts" />
/* global $ */
import {
    BlendMode,
    BuilderDocument,
    BuilderEmailDocument,
    ImageLayer,
    ImageSection,
    isBuilderDocument,
    Layer,
    Section,
    SimpleEditorField,
    TextFit,
    TextLayer,
    VideoLayer,
} from '@deltasierra/shared';
import { $compileSID, $templateCacheSID, $timeoutSID, OptionalExpressionBinding } from '../common/angularData';
import { debounceFactorySID, IDebounce } from '../common/debounceFactory';
import { I18nService } from '../i18n';
import { BuilderConstants, builderConstantsSID } from './builderConstants';
import { FieldValidationResult } from './builderDocumentValidation';
import { MailMergeFieldType } from './email/emailBuilder';
import { EmailBuilderCtrlScope } from './email/mvEmailBuilderCtrl';
import { SimpleEmailEditorScope } from './email/simpleEmailEditor/simpleEmailEditor';
import { ContentBuilderCtrlScope } from './mvContentBuilderCtrl';
import IDirective = angular.IDirective;
import ITimeoutService = angular.ITimeoutService;
import ICompileService = angular.ICompileService;
import ITemplateCacheService = angular.ITemplateCacheService;
import IAugmentedJQuery = angular.IAugmentedJQuery;
import IAttributes = angular.IAttributes;
import IScope = angular.IScope;

interface EditorFieldGroupScope extends ng.IScope {
    label: string;
    showFields: boolean;
    toggleFields: () => void;
}

export const editorFieldGroupSID = 'editorFieldGroup';
export const editorFieldGroupConfig: IDirective<EditorFieldGroupScope> = {
    restrict: 'E',
    scope: {
        label: '@',
    },
    templateUrl: 'builder/components/fieldGroup.html',
    transclude: true,
};

angular.module('app').directive(editorFieldGroupSID, [
    (): IDirective<EditorFieldGroupScope> => ({
        ...editorFieldGroupConfig,
        link: (scope: EditorFieldGroupScope, element, attrs) => {
            scope.label = attrs.label;
            scope.showFields = false;
            scope.toggleFields = () => {
                scope.showFields = !scope.showFields;
            };
        },
    }),
]);

export const editorFieldLabelSID = 'editorFieldLabel';
export const editorFieldLabelConfig: IDirective<IScope> = {
    restrict: 'E',
    scope: false,
    templateUrl: 'builder/components/label.html',
};

angular.module('app').directive(editorFieldLabelSID, [() => editorFieldLabelConfig]);

type AnyBuilderScope = ng.IScope & {
    fieldChanged?: ContentBuilderCtrlScope['fieldChanged'];
} & (ContentBuilderCtrlScope | EmailBuilderCtrlScope | SimpleEmailEditorScope);

interface IValidatableFieldScope extends ng.IScope {
    $parent: AnyBuilderScope;
    layer: BuilderDocument | Layer | Section;
    path: string;
    watchPaths: string;
    validationMessages: FieldValidationResult[];
    validationClassName: string;
}

function getWatchPaths(scope: IValidatableFieldScope) {
    return (scope.watchPaths || '')
        .replace(',', ';')
        .split(';')
        .map(path => path.trim())
        .filter(path => !!path)
        .concat(`layer.${scope.path}`);
}

function setupValidationForField(scope: IValidatableFieldScope) {
    const watchPaths = getWatchPaths(scope);

    const runValidation = () => {
        let validationMessages: FieldValidationResult[] = [];
        if (scope.layer) {
            if (isEmailBuilderParent(scope.$parent)) {
                validationMessages = scope.$parent.emailBuilder.validateField(scope.layer as Section, scope.path);
            } else {
                validationMessages = scope.$parent.validateField(scope.layer as Layer, scope.path);
            }
        }
        scope.validationMessages = validationMessages;
        scope.validationClassName = scope.$parent.getFieldValidationClassName(scope.validationMessages);
    };

    scope.$watchGroup(watchPaths, runValidation);

    runValidation();
}

function setupChangeNotification(scope: IValidatableFieldScope, debounce: IDebounce) {
    if (scope.$parent.fieldChanged) {
        const onFieldChanged = scope.$parent.fieldChanged;
        const watchPaths = getWatchPaths(scope);

        scope.$watchGroup(
            watchPaths,
            debounce(() => onFieldChanged(scope.layer as Layer, scope.path), 500),
        );
    }
}

function isEmailBuilderParent(
    parent: IValidatableFieldScope['$parent'],
): parent is EmailBuilderCtrlScope | SimpleEmailEditorScope {
    return !!(parent as EmailBuilderCtrlScope).emailBuilder;
}

interface EditorFieldScope extends IValidatableFieldScope {
    control: string;
    label: string;
    model: any;
    editable: boolean;

    toggle(): void;
    isEditable(): boolean;
    getDocument(): BuilderDocument | BuilderEmailDocument;
}

export const editorFieldSID = 'editorField';
export const editorFieldConfig: IDirective<EditorFieldScope> = {
    restrict: 'E',
    scope: {
        control: '@',
        editable: '=?',
        label: '@',
        layer: '=',
        model: '=',
        ngBlur: OptionalExpressionBinding,
        path: '@',
        watchPaths: '@?',
    },
};

angular.module('app').directive(editorFieldSID, [
    $compileSID,
    $templateCacheSID,
    debounceFactorySID,
    ($compile: ICompileService, $templateCache: ITemplateCacheService, debounce: IDebounce) => ({
        ...editorFieldConfig,
        // TemplateUrl: getTemplateUrl,
        link: (scope: EditorFieldScope, element: IAugmentedJQuery, attrs: IAttributes) => {
            scope.editable = scope.editable !== null && scope.editable !== undefined ? scope.editable : true;
            scope.toggle = () => {
                if (isEmailBuilderParent(scope.$parent)) {
                    scope.$parent.emailBuilder.toggleEditableField(
                        scope.layer as Section,
                        scope.path,
                        scope.control,
                        scope.label,
                    );
                } else {
                    scope.$parent.toggleEditableField(scope.layer as Layer, scope.path, scope.control, scope.label);
                }
            };
            scope.isEditable = () => {
                if (isEmailBuilderParent(scope.$parent)) {
                    return scope.$parent.emailBuilder.hasEditableField(
                        scope.layer as Section,
                        scope.path,
                        scope.control,
                    );
                } else {
                    return scope.$parent.hasEditableField(
                        isBuilderDocument(scope.layer) ? scope.layer : (scope.layer as Layer),
                        scope.path,
                        scope.control,
                    );
                }
            };
            scope.getDocument = () => {
                if (isEmailBuilderParent(scope.$parent)) {
                    return scope.$parent.emailBuilder.document;
                } else {
                    return scope.$parent.contentBuilder.document;
                }
            };

            setupChangeNotification(scope, debounce);

            setupValidationForField(scope);

            const templateName = `builder/components/${scope.control}.html`;
            const html = $templateCache.get<string>(templateName);
            element.replaceWith($compile(html)(scope));
        },
    }),
]);

export const simpleEditorFieldSID = 'simpleEditorField';
export const simpleEditorFieldConfig = {
    restrict: 'E',
    scope: {
        component: '=',
        watchPaths: '@?',
    },
};

angular.module('app').directive(simpleEditorFieldSID, [
    $compileSID,
    $templateCacheSID,
    builderConstantsSID,
    ($compile: ICompileService, $templateCache: ITemplateCacheService, builderConstants: BuilderConstants) => {
        interface SimpleEditorFieldScope extends IValidatableFieldScope {
            component: SimpleEditorField;
            editable: boolean;
            label: string;
            model: any;
            control: string;

            toggle: () => void;
            isEditable: () => boolean;
            getDocument: () => BuilderDocument | BuilderEmailDocument;
        }

        return {
            ...simpleEditorFieldConfig,
            link: (scope: SimpleEditorFieldScope, element: IAugmentedJQuery, attrs: IAttributes) => {
                // TODO: use a controller instead.
                scope.editable = false;
                scope.layer = scope.component.layer;
                scope.label = `${scope.layer.title} - ${scope.component.config.label}`;
                scope.model = scope.component.value;
                scope.path = scope.component.config.path;
                scope.control = scope.component.config.control;

                scope.toggle = () => {
                    if (isEmailBuilderParent(scope.$parent)) {
                        scope.$parent.emailBuilder.toggleEditableField(
                            scope.layer as Section,
                            scope.path,
                            scope.control,
                            scope.label,
                        );
                    } else if (isBuilderDocument(scope.layer)) {
                        scope.$parent.toggleEditableField(scope.layer, scope.path, scope.control, scope.label);
                    } else {
                        scope.$parent.toggleEditableField(scope.layer as Layer, scope.path, scope.control, scope.label);
                    }
                };
                scope.isEditable = () => {
                    if (isEmailBuilderParent(scope.$parent)) {
                        return scope.$parent.emailBuilder.hasEditableField(
                            scope.layer as Section,
                            scope.path,
                            scope.control,
                        );
                    } else {
                        return scope.$parent.hasEditableField(
                            isBuilderDocument(scope.layer) ? scope.layer : (scope.layer as Layer),
                            scope.path,
                            scope.control,
                        );
                    }
                };
                scope.getDocument = () => {
                    if (isEmailBuilderParent(scope.$parent)) {
                        return scope.$parent.emailBuilder.document;
                    } else {
                        return scope.$parent.contentBuilder.document;
                    }
                };

                scope.$watch('model', () => {
                    if (scope.component.value !== scope.model) {
                        scope.component.value = scope.model; // Dodge
                        scope.$emit(builderConstants.EVENTS.UPDATE_DOCUMENT_FROM_EDITOR);
                    }
                });
                scope.$watch('component.value', () => {
                    if (scope.model !== scope.component.value) {
                        scope.model = scope.component.value;
                    }
                });
                scope.$watch('component.layer.title', () => {
                    scope.label = `${scope.layer.title} - ${scope.component.config.label}`;
                });

                setupValidationForField(scope);

                const templateName = `builder/components/${scope.control}.html`;
                const html = $templateCache.get<string>(templateName);
                element.replaceWith($compile(html)(scope));
            },
        };
    },
]);

export const editorImageSelectSID = 'editorImageSelect';
export const editorImageSelectConfig = {
    restrict: 'E',
    scope: false,
    templateUrl: 'builder/components/imageSelectInner.html',
};

angular.module('app').directive(editorImageSelectSID, [
    () => {
        interface EditorImageSelectScope extends ng.IScope {
            $parent: AnyBuilderScope;
            showImageChooser: () => void;
            layer: ImageLayer | ImageSection;
        }

        return {
            ...editorImageSelectConfig,
            link: (scope: EditorImageSelectScope) => {
                scope.showImageChooser = () => {
                    if (isEmailBuilderParent(scope.$parent)) {
                        scope.$parent.showImageChooser(scope.layer as ImageSection);
                    } else {
                        scope.$parent.showImageChooser(scope.layer as ImageLayer);
                    }
                };
            },
        };
    },
]);

interface EditorNumberParserScope extends ng.IScope {
    model: any;
}

export const editorNumberParserSID = 'editorNumberParser';
export const editorNumberParserConfig = {
    require: 'ngModel',
    restrict: 'A',
    scope: false,
};

angular.module('app').directive(editorNumberParserSID, [
    (): IDirective<EditorNumberParserScope> => ({
        ...editorNumberParserConfig,
        link: (scope: EditorNumberParserScope, element: IAugmentedJQuery, attrs: IAttributes, modelCtrl) => {
            // When user changes input value from DOM
            modelCtrl.$parsers.push(modelValue => {
                if (modelValue) {
                    return modelValue;
                } else {
                    return 0;
                }
            });
        },
    }),
]);

interface EditorVideoSelectScope extends ng.IScope {
    $parent: ContentBuilderCtrlScope & ng.IScope;
    showVideoChooser: () => void;
    layer: VideoLayer;
}

export const editorVideoSelectSID = 'editorVideoSelect';
export const editorVideoSelectConfig = {
    restrict: 'E',
    scope: false,
    templateUrl: 'builder/components/videoSelectInner.html',
};

angular.module('app').directive(editorVideoSelectSID, [
    (): IDirective<EditorVideoSelectScope> => ({
        ...editorVideoSelectConfig,
        link: (scope: EditorVideoSelectScope) => {
            scope.showVideoChooser = () => {
                scope.$parent.showVideoChooser(scope.layer);
            };
        },
    }),
]);

interface EditorSliderScope extends ng.IScope {
    model: any;
}

export const editorSliderSID = 'editorSlider';
export const editorSliderConfig = {
    restrict: 'A',
    scope: false,
};

angular.module('app').directive(editorSliderSID, [
    $timeoutSID,
    ($timeout: ITimeoutService): IDirective<EditorSliderScope> => ({
        ...editorSliderConfig,
        link: (scope: EditorSliderScope, element) => {
            // HACK: for Angular.js issue #6726 - ng-model does not set initial value and position of input[type=range]
            // https://github.com/angular/angular.js/issues/6726
            $timeout(() => {
                if (scope.model !== undefined) {
                    $(element).val(scope.model);
                }
            }, 20);
        },
    }),
]);

export const textAlignEditorSID = 'textAlignEditor';
export const textAlignEditorConfig: IDirective<IValidatableFieldScope> = {
    link: (scope: IValidatableFieldScope) => {
        scope.$watch(`layer.${scope.path}`, (newValue: string) => {
            // If textAlign is set to justify then we should
            // Automatically switch the textFit to wordWrap
            // Since it only really works with word wrap
            const textLayer = scope.layer as TextLayer;

            const shouldUpdateTextFit = scope.layer && newValue === 'justify' && textLayer.textFit !== TextFit.wordWrap;

            if (shouldUpdateTextFit) {
                textLayer.textFit = TextFit.wordWrap;
            }
        });
    },
    restrict: 'E',
    scope: false,
    templateUrl: 'builder/components/textAlignInner.html',
};

angular.module('app').directive(textAlignEditorSID, [() => textAlignEditorConfig]);

export const wordWrapEditorSID = 'wordWrapEditor';
export const wordWrapEditorConfig: IDirective<IValidatableFieldScope> = {
    link: (scope: IValidatableFieldScope) => {
        scope.$watch(`layer.${scope.path}`, (newValue: TextFit) => {
            // If textFit is not set to 'wordWrap' and
            // TextAlign is set to 'justify' then we should
            // Automatically switch textAlign to 'left'
            // Since 'justify' only really works with word wrap
            const textLayer = scope.layer as TextLayer;

            const shouldUpdateTextAlign =
                textLayer && newValue !== TextFit.wordWrap && textLayer.textAlign === 'justify';

            if (shouldUpdateTextAlign) {
                textLayer.textAlign = 'left';
            }
        });
    },
    restrict: 'E',
    scope: false,
    templateUrl: 'builder/components/wordWrapInner.html',
};

angular.module('app').directive(wordWrapEditorSID, [() => wordWrapEditorConfig]);

export const richTextEditorSID = 'richTextEditor';
export const richTextEditorConfig = {
    restrict: 'E',
    scope: true,
    templateUrl: 'builder/components/richTextInner.html',
};

interface IRichTextEditorScope extends IScope {
    $parent: IScope & {
        // Grandparent scope lookup is gross, but "scope: true" avoids polluting the parent scope.
        $parent: AnyBuilderScope;
        model: any; // Blech
    };
    stripUnwantedTagsAndAttributes: (html: string) => string;
    textAngularToolbarConfig: string[][];
}

angular.module('app').directive(richTextEditorSID, [
    (): IDirective<IRichTextEditorScope> => ({
        ...richTextEditorConfig,
        link: {
            // This must be linked before child directives ("pre-linked"), so that the toolbar config is populated
            // Before textAngular links.
            pre(scope: IRichTextEditorScope) {
                const toolbar = [
                    ['h1', 'h2', 'h3', 'p', 'bold', 'italics', 'underline', 'strikeThrough', 'insertLink'],
                    [
                        'ul',
                        'ol',
                        'justifyLeft',
                        'justifyCenter',
                        'justifyRight',
                        'justifyFull',
                        'undo',
                        'redo',
                        'clear',
                    ],
                ];
                const extras = [];
                if (isEmailBuilderParent(scope.$parent.$parent)) {
                    if (scope.$parent.$parent.emailBuilder.mailMergeFieldType === MailMergeFieldType.Fitware) {
                        extras.push('fitwareMailMerge');
                    }
                    if (scope.$parent.$parent.emailBuilder.mailMergeFieldType === MailMergeFieldType.ClubReady) {
                        extras.push('clubReadyMailMerge');
                    }
                }
                if (extras.length > 0) {
                    toolbar.push(extras);
                }
                scope.textAngularToolbarConfig = toolbar;
                scope.stripUnwantedTagsAndAttributes = (html: string): string => {
                    if (!html) {
                        return '';
                    } else if (event && (event as ClipboardEvent).clipboardData) {
                        // DS-2413: access the clipboard data directly, so we can request the content as plain text,
                        //  So that any content copied from MS Word is transformed to plain text by the clipboard,
                        //  Rather than us having to transform it somehow.
                        // (Note that copy/pasting HTML strings from e.g. Notepad++ will just give you a bunch of
                        //  HTML markup.)
                        const plaintext = (event as ClipboardEvent).clipboardData!.getData('text/plain');
                        document.execCommand('inserttext', false, plaintext);
                        return '<span></span>'; // Return a dummy span to keep textAngular happy.
                    } else {
                        // If there's no paste event (for some reason?), fall back to parsing the HTML to DOM nodes
                        //  And retrieving the innerText from there. (This breaks newlines between elements, so
                        //  The user gets garbage text.)
                        const elem = document.createElement('div');
                        elem.innerHTML = html;
                        return elem.innerText;
                    }
                };
            },
        },
    }),
]);

export const blendModeEditorSID = 'blendModeEditor';
export const blendModeEditorConfig = {
    restrict: 'E',
    scope: false,
    templateUrl: 'builder/components/blendModeEditorInner.html',
};

interface IBlendModeEditor extends ng.IScope {
    blendModeOptions: Array<{ value: BlendMode; label: string }>;
}

angular.module('app').directive(blendModeEditorSID, [
    I18nService.SID,
    (i18nService: I18nService): IDirective<IBlendModeEditor> => ({
        ...blendModeEditorConfig,
        link: (scope: IBlendModeEditor) => {
            scope.blendModeOptions = [
                { label: i18nService.translate('BUILD.BLEND_MODE.NORMAL'), value: null },
                { label: i18nService.translate('BUILD.BLEND_MODE.MULTIPLY'), value: 'multiply' },
                { label: i18nService.translate('BUILD.BLEND_MODE.SCREEN'), value: 'screen' },
                { label: i18nService.translate('BUILD.BLEND_MODE.OVERLAY'), value: 'overlay' },
                { label: i18nService.translate('BUILD.BLEND_MODE.SOFT_LIGHT'), value: 'soft-light' },
                { label: i18nService.translate('BUILD.BLEND_MODE.HARD_LIGHT'), value: 'hard-light' },
                { label: i18nService.translate('BUILD.BLEND_MODE.DIFFERENCE'), value: 'difference' },
                { label: i18nService.translate('BUILD.BLEND_MODE.HUE'), value: 'difference' },
                { label: i18nService.translate('BUILD.BLEND_MODE.SATURATION'), value: 'saturation' },
                { label: i18nService.translate('BUILD.BLEND_MODE.COLOR'), value: 'color' },
            ];
        },
    }),
]);
