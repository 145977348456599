/// <reference path="../../_references.d.ts" />
import * as linq from 'linq';
import { noop } from '@deltasierra/object-utilities';
import { FileUtils } from '../../common/fileUtils';
import { UploadService } from '../../common/uploadService';
import { ModalInstance } from '../../typings/angularUIBootstrap/modalService';
import { $modalInstanceSID } from '../../common/angularUIBootstrapData';

const SUPPORTED_FONT_FILE_TYPES = ['.ttf', '.otf'];
const PRINT_SUPPORTED_FONT_FILE_TYPES = ['.ttf', '.otf'];

interface ValidationMessage {
    type: 'error' | 'warning';
    text: string;
}

export class FontChooserController {
    public static SID = 'FontChooserController';

    public static readonly $inject: string[] = [$modalInstanceSID, FileUtils.SID, UploadService.SID];

    public isPermissionConfirmed = false;

    public file?: File;

    public message?: ValidationMessage;

    public constructor(
        private readonly $modalInstance: ModalInstance,
        private readonly fileUtils: FileUtils,
        private readonly uploadService: UploadService,
    ) {}

    public selectLocalFile(files: File[]): void {
        if (files.length !== 1) {
            return this.showMessage('error', 'You can only upload one font at a time');
        }

        this.uploadService
            .checkFileSize(files[0])
            .then(
                () => {
                    const fileType = this.fileUtils.getExtension(files[0].name).toLocaleLowerCase();

                    if (!SUPPORTED_FONT_FILE_TYPES.includes(fileType)) {
                        return this.showMessage('error', this.getUnsupportedFileTypeMessage());
                    }

                    this.file = files[0];

                    if (!PRINT_SUPPORTED_FONT_FILE_TYPES.includes(fileType)) {
                        return this.showMessage('warning', this.getUnsupportedByPrintMessage());
                    }

                    this.message = undefined;
                    return noop();
                },
                err => this.showMessage('error', err.message || err),
            )
            .catch(err => this.showMessage('error', err.message || err));
        return noop();
    }

    public isAcceptable(): boolean {
        const result = this.isPermissionConfirmed && this.file && (!this.message || this.message.type !== 'error');

        return !!result;
    }

    public accept(): void {
        this.$modalInstance.close(this.file);
    }

    public cancel(): void {
        this.$modalInstance.dismiss();
    }

    private fileTypesToText(fileTypes: string[]): string {
        let fileTypesText = '';

        const upperCaseFileTypes = fileTypes.map(type => type.toLocaleUpperCase());

        if (fileTypes.length === 1) {
            fileTypesText = upperCaseFileTypes[0];
        } else {
            fileTypesText = `${linq.from(upperCaseFileTypes).takeExceptLast().toArray().join(', ')} and ${linq
                .from(upperCaseFileTypes)
                .last()}`;
        }

        return fileTypesText;
    }

    private showMessage(type: 'error' | 'warning', text: string) {
        this.message = { text, type };
    }

    private getUnsupportedFileTypeMessage(): string {
        const fileTypesText = this.fileTypesToText(SUPPORTED_FONT_FILE_TYPES);
        return `Only ${fileTypesText} fonts are supported.`;
    }

    private getUnsupportedByPrintMessage(): string {
        const fileTypesText = this.fileTypesToText(PRINT_SUPPORTED_FONT_FILE_TYPES);
        return (
            'Although this font is valid, note that it is not supported in print templates. ' +
            `Only ${fileTypesText} fonts are supported in print templates at this time. ` +
            'If you need to use this font for print templates, please upload a font file in a supported format.'
        );
    }
}

angular.module('app').controller(FontChooserController.SID, FontChooserController);
