/// <reference path="../../../../typings/browser.d.ts" />
import {
    AgencyId,
    LocationCategoryGroupView,
    LocationCategoryOptionView,
    LocationPickerDto,
} from '@deltasierra/shared';
import { DataUtils, SortedEntry } from '../../common/dataUtils';
import IAngularEvent = angular.IAngularEvent;

export const locationPickerCommonSID = 'locationPickerCommon';

interface SearchItems {
    title: string | null;
    categoryOptionLabel: string | null;
    categoryOptionId: number | null;
}

export interface LocationPickerCommonScope {
    chooseCategoryGroup: (event: IAngularEvent, categoryGroup: LocationCategoryGroupView) => void;
    chooseCategoryOption: (event: IAngularEvent, categoryGroup: LocationCategoryGroupView) => void;
    getCountByCategoryOptionId: (categoryGroup: LocationCategoryGroupView) => number;
}

export interface LocationPickerCommon {
    applyLocationFilteringMixin(scope: Partial<LocationPickerCommonScope>): void;
}

export enum LocationPickerView {
    List,
    Categories,
    CategoryOptions,
    CategoryEntries,
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface CategoryGroups {
    // TODO
}

export class BaseLocationPicker {
    public readonly VIEWS = LocationPickerView;

    locations?: LocationPickerDto[];

    clientMap?: { [clientId: number]: string };

    search: SearchItems = {
        title: null,
        categoryOptionLabel: null,
        categoryOptionId: null,
    };

    view = LocationPickerView.List;

    categoryGroups?: CategoryGroups;

    groupedLocations?: Array<SortedEntry<LocationPickerDto[]>>;

    groupedCategoryOptions?: Array<SortedEntry<LocationCategoryOptionView[]>>;

    filteredEntries?: Array<SortedEntry<LocationPickerDto[]>>;

    filteredCategoryOptionEntries?: Array<SortedEntry<LocationCategoryOptionView[]>>;

    filteredEntriesByCategory?: Array<SortedEntry<LocationPickerDto[]>>;

    constructor(protected readonly dataUtils: DataUtils) {}

    getLabel(location: LocationPickerDto): string {
        if (location) {
            return `${location.clientTitle} - ${location.locationTitle}`;
        } else {
            return 'Unknown Client - Unknown Location';
        }
    }

    initFiltering(): void {
        this.groupedLocations = this.dataUtils.groupByOrdered('clientTitle', this.locations || []);
        this.filteredEntries = this.groupedLocations;
        this.clientMap = this.dataUtils.createMapOfProperty(
            entry => entry.clientId,
            entry => entry.clientTitle,
            this.locations || [],
        );
    }

    private filterPredicate<T, K extends keyof T>(
        searchProperty: keyof SearchItems,
        value: T,
        ...valueProperties: K[]
    ): boolean {
        if (!value) {
            return false;
        }
        if (!this.search[searchProperty]) {
            return true;
        }
        const toFind = String(this.search[searchProperty]).toLocaleLowerCase();
        return valueProperties.some(x => String(value[x]).toLocaleLowerCase().indexOf(toFind) > -1); // NOTE: does not handle soft hyphens...
    }

    protected filterCategoryOption = (categoryOption: LocationCategoryOptionView): boolean =>
        this.filterPredicate('categoryOptionLabel', categoryOption, 'label');

    protected filterLocation = (location: LocationPickerDto): boolean =>
        this.filterPredicate('title', location, 'locationTitle', 'clientTitle');

    protected filterLocationByCategoryId = (location: LocationPickerDto): boolean => {
        const toFind = this.search.categoryOptionId;
        return this.filterLocationByCategoryIdPredicate(location, toFind);
    };

    private filterLocationByCategoryIdPredicate(
        location: LocationPickerDto,
        categoryOptionIdToMatch: number | null,
    ): boolean {
        for (const categoryOptionId of location.categories || []) {
            if (categoryOptionId === categoryOptionIdToMatch) {
                return true;
            }
        }
        return false;
    }

    protected createLocationFilterGroup = <T extends LocationPickerDto>(
        groupEntry: SortedEntry<T[]>,
        entries: T[],
    ): SortedEntry<T[]> => {
        const newGroup = {
            key: groupEntry.key,
            value: entries,
        };
        return newGroup;
    };

    protected createLocationCategoryOptionFilterGroup = <T extends LocationCategoryOptionView>(
        groupEntry: SortedEntry<T[]>,
        entries: T[],
    ): SortedEntry<T[]> => {
        const newGroup = {
            key: groupEntry.key,
            value: entries,
        };
        return newGroup;
    };

    private createFilteredItems<T>(
        searchProperty: keyof SearchItems,
        filterFn: (value: T) => boolean,
        values: Array<SortedEntry<T[]>>,
        groupFactory: (groupEntry: SortedEntry<T[]>, entries: T[]) => SortedEntry<T[]>,
    ): Array<SortedEntry<T[]>> {
        if (!this.search[searchProperty]) {
            return values;
        } else {
            const groups: Array<SortedEntry<T[]>> = [];
            for (const groupEntry of values) {
                const options: T[] = [];
                for (const option of groupEntry.value) {
                    if (filterFn(option)) {
                        options.push(option);
                    }
                }
                if (options.length > 0) {
                    const newGroup = groupFactory(groupEntry, options);
                    groups.push(newGroup);
                }
            }
            return groups;
        }
    }

    protected filterByAgencyId<T extends { agencyId: AgencyId }>(locations: T[], agencyId: AgencyId): T[] {
        return this.dataUtils.filterBy('agencyId', locations, agencyId);
    }

    filterLocationsByTitle(): void {
        this.filteredEntries = this.createFilteredItems(
            'title',
            this.filterLocation,
            this.groupedLocations || [],
            this.createLocationFilterGroup,
        );
    }

    filterLocationsByCategoryOptionId(): void {
        this.filteredEntriesByCategory = this.createFilteredItems(
            'categoryOptionId',
            this.filterLocationByCategoryId,
            this.groupedLocations || [],
            this.createLocationFilterGroup,
        );
    }

    filterCategoryOptions() {
        this.filteredCategoryOptionEntries = this.createFilteredItems(
            'categoryOptionLabel',
            this.filterCategoryOption,
            this.groupedCategoryOptions || [],
            this.createLocationCategoryOptionFilterGroup,
        );
    }

    chooseCategoryGroup(event: IAngularEvent, categoryGroup: LocationCategoryGroupView): void {
        event.preventDefault();
        if (event.stopPropagation) {
            event.stopPropagation();
        }
        this.view = LocationPickerView.CategoryOptions;
        // Group category options by client ID. Sort by client name.
        const grouped = this.dataUtils.sortedEntries(
            this.dataUtils.mapKeys(
                this.clientMap,
                this.dataUtils.groupBy('clientId', categoryGroup.categoryOptions || []),
            ),
        );
        this.groupedCategoryOptions = grouped;
        this.filteredCategoryOptionEntries = this.groupedCategoryOptions;
    }

    chooseCategoryOption(
        event: IAngularEvent,
        categoryOption: LocationCategoryGroupView | LocationCategoryOptionView,
    ): void {
        event.preventDefault();
        if (event.stopPropagation) {
            event.stopPropagation();
        }
        this.view = LocationPickerView.CategoryEntries;
        this.filteredEntriesByCategory = this.groupedLocations;
        this.search.categoryOptionId = categoryOption.id;
        this.filterLocationsByCategoryOptionId();
    }

    getCountByCategoryOptionId(categoryOption: LocationCategoryGroupView | LocationCategoryOptionView): number {
        return this.dataUtils.filterByPredicate(
            location => this.filterLocationByCategoryIdPredicate(location, categoryOption.id),
            this.locations || [],
        ).length;
    }

    public resetView(): void {
        this.view = LocationPickerView.List;
        this.search = {
            title: null,
            categoryOptionLabel: null,
            categoryOptionId: null,
        };
        this.filterLocationsByTitle(); // DS-2837
    }
}
