import {
    ConfirmButton,
    Icon,
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableHeaderCell,
    TableRow,
    TextInput,
    Translate,
} from '@deltasierra/components';
import { useOnHitPageBottom } from '@deltasierra/react-hooks';
import { t, Client, UserId } from '@deltasierra/shared';
import * as React from 'react';
import styled from 'styled-components';
import { OneWayBinding } from '../../common/angularData';
import { withAngularIntegration } from '../../common/componentUtils/reactComponentRegistration';
import { StyledLoadMore } from '../../common/react/StyledLoadMore/StyledLoadMore';
import UserInviteStatusButton from '../../common/users/UserInviteStatusButton';
import { useDeleteClientUser } from '../queries/deleteClientUser';
import { useGetClientUsers } from '../queries/useGetClientUsers';

const StyledTableCell = styled(TableCell)`
    max-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const ActionButtonContainer = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    justify-content: right;
`;

export type ClientUsersPaginatedTableProps = {
    client: Client;
    dataCy?: string;
};

const ClientUsersPaginatedTable: React.FC<ClientUsersPaginatedTableProps> = ({ client, dataCy }) => {
    const [firstNameFilter, setFirstNameFilter] = React.useState('');
    const [lastNameFilter, setLastNameFilter] = React.useState('');
    const [usernameFilter, setUsernameFilter] = React.useState('');

    const columnsArray = React.useMemo(
        () => [
            {
                filterCallback: setFirstNameFilter,
                label: t('AGENCY.CLIENT.LOCATION.USERS.FIRST_NAME'),
                placeholder: t('AGENCY.CLIENT.LOCATION.USERS.FILTER_FIRST_NAME'),
            },
            {
                filterCallback: setLastNameFilter,
                label: t('AGENCY.CLIENT.LOCATION.USERS.LAST_NAME'),
                placeholder: t('AGENCY.CLIENT.LOCATION.USERS.FILTER_LAST_NAME'),
            },
            {
                filterCallback: setUsernameFilter,
                label: t('AGENCY.CLIENT.LOCATION.USERS.USERNAME_EMAIL'),
                placeholder: t('AGENCY.CLIENT.LOCATION.USERS.FILTER_USERNAME'),
            },
        ],
        [],
    );

    const [clientUsers, fetchMore, hasNextPage] = useGetClientUsers(client.graphqlId, {
        firstName: firstNameFilter,
        lastName: lastNameFilter,
        username: usernameFilter,
    });

    useOnHitPageBottom(fetchMore, clientUsers.loading);

    const deleteClientUser = useDeleteClientUser({ clientId: client.graphqlId });

    return (
        <>
            <Table id="clientUsersTable">
                <TableHeader>
                    {columnsArray.map((column, index) => (
                        <TableHeaderCell key={index}>
                            <div className={'text-left'}>{column.label}</div>
                        </TableHeaderCell>
                    ))}
                    <TableHeaderCell />
                </TableHeader>
                <TableBody data-cy={dataCy} id="clientUsersTableBody">
                    <TableRow>
                        {columnsArray.map((column, index) => (
                            <TableCell key={index}>
                                <TextInput clearable initialValue={''} placeholder={column.placeholder} onChange={column.filterCallback} />
                            </TableCell>
                        ))}
                    </TableRow>
                    {clientUsers.data?.client &&
                        clientUsers.data.client.users.edges
                            .map(edge => edge.node)
                            .map(user => (
                                <TableRow id="clientUsersTableRow" key={user.legacyId}>
                                    <StyledTableCell title={user.firstName} width="20%">
                                        <a href={`/clients/${client.id}/users/${user.legacyId}/edit`}>{user.firstName}</a>
                                    </StyledTableCell>
                                    <StyledTableCell title={user.lastName} width="20%">
                                        <a href={`/clients/${client.id}/users/${user.legacyId}/edit`}>{user.lastName}</a>
                                    </StyledTableCell>
                                    <StyledTableCell title={user.username}>
                                        <a href={`/clients/${client.id}/users/${user.legacyId}/edit`}>{user.username}</a>
                                    </StyledTableCell>
                                    <TableCell width="20%">
                                        <ActionButtonContainer>
                                            <UserInviteStatusButton
                                                className="space-right"
                                                user={{ ...user, id: UserId.from(user.legacyId) }}
                                            />
                                            <ConfirmButton
                                                id="clientUsersTableRemoveUserButton"
                                                message={t('AGENCY.CLIENT.LOCATION.USERS.DELETE_USER', {
                                                    firstName: user.firstName,
                                                    lastName: user.lastName,
                                                })}
                                                size="xs"
                                                theme="danger"
                                                onConfirm={() => deleteClientUser(user)}
                                            >
                                                <Icon icon="remove" />
                                            </ConfirmButton>
                                        </ActionButtonContainer>
                                    </TableCell>
                                </TableRow>
                            ))}
                    {clientUsers.data?.client && clientUsers.data.client.users.edges.length === 0 && <NoUsersFoundRow />}
                </TableBody>
            </Table>
            {(hasNextPage || clientUsers.loading) && (
                <StyledLoadMore disabled={clientUsers.loading} loading={clientUsers.loading} onClick={fetchMore} />
            )}
        </>
    );
};

ClientUsersPaginatedTable.displayName = 'ClientUsersPaginatedTable';

export default withAngularIntegration(ClientUsersPaginatedTable, 'clientUsersPaginatedTable', {
    client: OneWayBinding,
});

const NoUsersFoundRow: React.FC = () => (
    <TableRow>
        <TableCell className="text-center" colSpan={5}>
            <Translate keyId={'AGENCY.CLIENT.LOCATION.USERS.NO_USERS_FOUND'} />
        </TableCell>
    </TableRow>
);
NoUsersFoundRow.displayName = 'NoUsersFoundRow';
