import { useCurrentLocationDispatch } from './useCurrentLocationDispatch';
import { useCurrentLocationState } from './useCurrentLocationState';

/**
 * Use the current location context provided by the provider
 *
 * @param [useLocalStorageAsFallback=true] - Whether to include the local storage as a fallback?
 * @returns [The current client, The update local storage function]
 */
export function useCurrentLocation(
    useLocalStorageAsFallback = true,
): [ReturnType<typeof useCurrentLocationState>, ReturnType<typeof useCurrentLocationDispatch>] {
    return [useCurrentLocationState(useLocalStorageAsFallback), useCurrentLocationDispatch()];
}
