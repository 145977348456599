import moment from 'moment-timezone';

export function addDays(date: Date, days: number): Date {
    const copy = new Date(date.getTime());
    copy.setDate(date.getDate() + days);
    return copy;
}

/**
 * Add the given number of business days (days other than Saturday and Sunday) to the date and return the new date.
 *
 * @param date - The original date
 * @param days - The number of business days to add
 * @returns The new date
 * @see https://stackoverflow.com/a/58482023
 */
export function addBusinessDays(date: Date, days: number): Date;
export function addBusinessDays(date: moment.Moment, days: number): moment.Moment;
export function addBusinessDays(date: Date | moment.Moment, days: number): Date | moment.Moment {
    const SUNDAY = 6;
    const SATURDAY = 7;

    let daysRemaining = days;
    const newDate = moment.isDate(date) ? moment(date) : date.clone();

    /*
     * It should be possible to optimize this calculation to not use a loop. I'm not going to bother now
     * because it's not performance critical.
     */
    while (daysRemaining > 0) {
        newDate.add(1, 'days');

        const currentWeekday = newDate.isoWeekday();
        if (currentWeekday !== SUNDAY && currentWeekday !== SATURDAY) {
            daysRemaining--;
        }
    }

    return moment.isDate(date) ? newDate.toDate() : newDate;
}
