import { DateTimePicker, DSFormControlLabel, DSGrid, DSRadio } from '@deltasierra/components';
import { t } from '@deltasierra/shared';
import { FormikProps } from 'formik';
import React from 'react';
import { PublishToClubReadyStep1FormValues } from '../../../types/publish-to-club-ready-step-1-form-values';
import { StyledClubReadyRadioGroup } from '../../../../common';
import { ClubReadyListOptionBase } from './ClubReadyListOptionBase';

type ClubReadyInactiveListOptionProps = {
    formik: FormikProps<PublishToClubReadyStep1FormValues>;
};

export function ClubReadyInactiveListOption({ formik }: ClubReadyInactiveListOptionProps): JSX.Element {
    return (
        <ClubReadyListOptionBase formik={formik} listKey="inactive" listName={t('BUILD.PUBLISH.CLUB_READY.INACTIVE')}>
            <StyledClubReadyRadioGroup
                value={formik.values.inactive.listChoice}
                onChange={value =>
                    formik.setFieldValue('inactive', {
                        ...formik.values.inactive,
                        listChoice: value.target.value as 'all' | 'withActivityAfter' | 'withActivityBefore',
                    })
                }
            >
                <DSFormControlLabel
                    control={<DSRadio />}
                    label={t('BUILD.PUBLISH.CLUB_READY.ALL_INACTIVE_MEMBERS')}
                    value="all"
                />
                <DSGrid container justifyContent="space-between">
                    <DSGrid item>
                        <DSFormControlLabel
                            control={<DSRadio />}
                            label={t('BUILD.PUBLISH.CLUB_READY.WITH_ACTIVITY_BEFORE')}
                            value="withActivityBefore"
                        />
                    </DSGrid>
                    <DSGrid item>
                        <DateTimePicker
                            error={
                                !!formik.errors.inactive &&
                                formik.values.inactive.listChoice === 'withActivityBefore' &&
                                (formik.values.inactive.beforeDate === null || formik.values.inactive.beforeDate === '')
                            }
                            helperText={
                                formik.values.inactive.listChoice === 'withActivityBefore' ? formik.errors.inactive : ''
                            }
                            type="date"
                            value={formik.values.inactive.beforeDate ?? undefined}
                            onChange={value =>
                                formik.setFieldValue('inactive', {
                                    ...formik.values.inactive,
                                    beforeDate: value,
                                    listChoice: 'withActivityBefore',
                                })
                            }
                        />
                    </DSGrid>
                </DSGrid>
                <DSGrid container justifyContent="space-between">
                    <DSGrid item>
                        <DSFormControlLabel
                            control={<DSRadio />}
                            label={t('BUILD.PUBLISH.CLUB_READY.WITH_ACTIVITY_AFTER')}
                            value="withActivityAfter"
                        />
                    </DSGrid>
                    <DSGrid item>
                        <DateTimePicker
                            error={
                                !!formik.errors.inactive &&
                                formik.values.inactive.listChoice === 'withActivityAfter' &&
                                (formik.values.inactive.afterDate === null || formik.values.inactive.afterDate === '')
                            }
                            helperText={
                                formik.values.inactive.listChoice === 'withActivityAfter' ? formik.errors.inactive : ''
                            }
                            type="date"
                            value={formik.values.inactive.afterDate ?? undefined}
                            onChange={value =>
                                formik.setFieldValue('inactive', {
                                    ...formik.values.inactive,
                                    afterDate: value,
                                    listChoice: 'withActivityAfter',
                                })
                            }
                        />
                    </DSGrid>
                </DSGrid>
            </StyledClubReadyRadioGroup>
        </ClubReadyListOptionBase>
    );
}
ClubReadyInactiveListOption.displayName = 'ClubReadyInactiveListOption';
