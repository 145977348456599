import { SendgridEmailCampaignReport, AssignedLocation } from '@deltasierra/shared';
import { ReportableService } from '@deltasierra/integrations/integration-types';
import type { IHttpService } from 'angular';

import { $httpSID } from '../../common/angularData';
import { DataUtils } from '../../common/dataUtils';
import { BaseLocationStatsService } from './common';
import { LocationAppsConfigurationFragment } from './__graphqlTypes/LocationAppsConfigurationFragment';

export class SendGridStatsService extends BaseLocationStatsService<
    SendgridEmailCampaignReport[],
    SendgridEmailCampaignReport
> {
    public static SID = 'SendGridStatsService';

    public static readonly $inject: string[] = [$httpSID, DataUtils.SID];

    public serviceName: ReportableService = 'sendgrid';

    public displayName = 'Email Campaigns';

    public constructor($http: IHttpService) {
        super($http);
    }

    public isPlatformConfigured(
        location: AssignedLocation,
        locationApps: LocationAppsConfigurationFragment[] | undefined,
    ): boolean {
        return true;
    }

    public combineStats(stats: SendgridEmailCampaignReport[]): SendgridEmailCampaignReport {
        const dummy: SendgridEmailCampaignReport = {
            bounceCount: 0,
            clickCount: 0,
            createdAt: new Date(),
            id: '',
            invalidEmailCount: 0,
            listName: '',
            openCount: 0,
            platform: 'clubReady',
            recipientCount: 0,
            scheduledTime: new Date(),
            spamReportCount: 0,
            successfulDeliveryCount: 0,
            thumbnailUrl: '',
            title: 'dummy',
        };
        return stats.reduce(
            (prev: SendgridEmailCampaignReport, cur: SendgridEmailCampaignReport) => ({
                ...prev,
                bounceCount: prev.bounceCount + cur.bounceCount,
                clickCount: prev.clickCount + cur.clickCount,
                openCount: prev.openCount + cur.openCount,
                recipientCount: prev.recipientCount + cur.recipientCount,
            }),
            dummy,
        );
    }
}

angular.module('app').service('SendGridStatsService', SendGridStatsService);
