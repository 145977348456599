import * as React from 'react';
import { Result, resultFailure, resultSuccess, justParams, BuilderTemplateApi } from '@deltasierra/shared';


import { isNotNullOrUndefined, isNullOrUndefined } from '@deltasierra/type-utilities';
import { useAngularServiceContext } from '../../../../../common/componentUtils/angularServiceContexts';
import { invokeApiRoute } from '../../../../../common/httpUtils';

const TEMPLATE_PARAM = 'template';

function extractIdFromTemplateUrl(url: string): number | null {
    const params = new URL(url).searchParams;
    const templateId = params.get(TEMPLATE_PARAM);
    if (isNotNullOrUndefined(templateId) && !isNaN(Number(templateId))) {
        return Number(templateId);
    }
    return null;
}

export interface BuilderTemplate {
    id: number;
    graphqlId: string;
    thumbnail?: string;
}

async function getTemplateOrNull(
    httpService: ng.IHttpService,
    templateId: number,
): Promise<Result<null, BuilderTemplate>> {
    try {
        const response = await invokeApiRoute(
            httpService,
            BuilderTemplateApi.getBuilderTemplate,
            justParams({ templateId }),
        );
        if (response) {
            const { compositeImage, graphqlId, id } = response;
            return resultSuccess({ graphqlId, id, thumbnail: compositeImage?.thumb256x256url ?? undefined });
        }
        return resultFailure(null);
    } catch {
        return resultFailure(null);
    }
}

export function useValidateTemplateId(): (url: string) => Promise<Result<null, BuilderTemplate>> {
    const httpService = useAngularServiceContext('$http');

    const validate = React.useCallback(
        async (url: string) => {
            try {
                const templateId = extractIdFromTemplateUrl(url);
                if (isNullOrUndefined(templateId)) {
                    return resultFailure(null);
                }
                // Have to await the promise since it's in a try/catch
                const response = await getTemplateOrNull(httpService, templateId);
                return response;
            } catch {
                return resultFailure(null);
            }
        },
        [httpService],
    );

    return validate;
}
