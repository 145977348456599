import 'openlayers/dist/ol.css';
import * as ol from 'openlayers';
import { openLayersSID } from './openLayersService';

/**
 * We inject a dedicated service here, so that tcat can import the directive & controller without importing the
 * openlayers module, which doesn't work in Node.js.
 */

angular.module('app').service(openLayersSID, [() => ol]);
