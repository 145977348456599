import { gql } from '@apollo/client';

export const INITIALIZE_UPLOAD_FOR_USE_UPLOAD_FILE = gql`
    mutation InitializeUploadForUseUploadFile($input: InitializeUploadInput!) {
        uploadInitialize(input: $input) {
            upload {
                id
                filename
            }
            signedUrl
        }
    }
`;

export const UPLOAD_SUCCESS_FOR_USE_UPLOAD_FILE = gql`
    mutation UploadSuccessForUseUploadFile($id: ID!) {
        uploadSuccess(id: $id) {
            ... on Upload {
                id
                filename
                url
            }
            ... on UploadNotFoundError {
                code
                message
            }
        }
    }
`;

export const UPLOAD_FAILURE_FOR_USE_UPLOAD_FILE = gql`
    mutation UploadFailureForUseUploadFile($failedUploadId: ID!) {
        uploadFailure(id: $failedUploadId) {
            ... on Upload {
                id
            }
            ... on UploadNotFoundError {
                code
                message
            }
        }
    }
`;
