import { TranslatableTextNoParams, TranslatableTextWithParams, translate } from '@deltasierra/i18n';
import * as React from 'react';

export type TranslateProps =
    | Pick<TranslatableTextNoParams, 'keyId' | 'options'>
    | Pick<TranslatableTextWithParams, 'keyId' | 'options'>;

export class Translate extends React.PureComponent<TranslateProps, {}> {
    public constructor(props: TranslateProps) {
        super(props);
    }

    public render() {
        return <>{translate(this.props.keyId, this.props.options)}</>;
    }
}
