import * as React from 'react';
import { Button } from '../../../common/Button';

export type FormButtonProps = {
    disabled: boolean;
    isScheduled: boolean;
    onCancel: () => void;
    onSubmit?: () => void;
};

export const FormButtons: React.FunctionComponent<FormButtonProps> = ({
    disabled,
    isScheduled,
    onCancel,
    onSubmit,
}) => (
    <div className="text-right">
        <Button label={{ keyId: 'COMMON.CANCEL' }} onClick={onCancel} />
        <Button
            disabled={disabled}
            label={{ keyId: isScheduled ? 'COMMON.SCHEDULE' : 'BUILD.PUBLISH.PUBLISH_IMMEDIATELY' }}
            spaceLeft
            theme="primary"
            type={onSubmit ? 'button' : 'submit'}
            onClick={onSubmit ? onSubmit : undefined}
        />
    </div>
);

FormButtons.displayName = 'FormButtons';
