/// <reference path="../../_references.d.ts" />
import { UploadBlockController } from './controller';

export const dsUploadBlockSID = 'dsUploadBlock';
export const dsUploadBlockConfig: ng.IDirective<ng.IScope> = {
    templateUrl: '/partials/directives/uploadBlock/template',
    restrict: 'E',
    replace: true,
    scope: {
        upload: '=',
        deleteUpload: '&?delete',
        width: '=?',
        height: '=?',
        titleVisible: '<?',
    },
    controller: UploadBlockController,
    controllerAs: 'ctrl',
    bindToController: true,
};

angular.module('app').directive(dsUploadBlockSID, [() => dsUploadBlockConfig]);
