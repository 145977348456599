/// <reference path="../../../typings/browser.d.ts" />
import { Comment, Untyped, Upload } from '@deltasierra/shared';
import { $httpSID } from '../common/angularData';

export class MvSpecialRequest {
    static readonly SID = 'mvSpecialRequest';

    static readonly $inject = [$httpSID];

    constructor(private readonly $http: ng.IHttpService) {}

    addComment(specialRequestId: number, commentText: string, uploadIds: Array<Upload | number>, isPrivate: boolean) {
        return this.$http.post(`/api/specialRequests/${specialRequestId}/comments`, {
            comment: commentText,
            uploads: uploadIds,
            isPrivate,
        });
    }

    sendQuote(specialRequestId: number, quote: Untyped) {
        return this.$http.post<Comment>(`/api/specialRequests/${specialRequestId}/quote`, quote);
    }
}

angular.module('app').service(MvSpecialRequest.SID, MvSpecialRequest);
