import * as React from 'react';
import styled from 'styled-components';
import { defaultColor } from './utils';

const StyledPlannerMonthPickerDate = styled.div`
    border-bottom: 2px solid ${defaultColor};
    margin-bottom: 35px;
    h2 {
        user-select: none;
        display: inline-block;
    }
    .right {
        height: 100%;
        margin-top: 2px;
        float: right;
        color: ${defaultColor};
    }
`;

export type PlannerMonthPickerDateProps = {
    month: string;
    onClick: () => void;
};

export const PlannerMonthPickerDate: React.FC<PlannerMonthPickerDateProps> = ({ month, onClick }) => (
    <StyledPlannerMonthPickerDate onClick={onClick}>
        <span className="left">
            <h2 data-cy="planner-month-header">{month}</h2>
        </span>
        <span className="fal fa-angle-down fa-3x right" />
    </StyledPlannerMonthPickerDate>
);
PlannerMonthPickerDate.displayName = 'PlannerMonthPicker';
