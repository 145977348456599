import { gql, useQuery } from '@apollo/client';
import { Loading } from '@deltasierra/components';
import { t } from '@deltasierra/shared';
import * as React from 'react';
import { RecycledAssets } from './RecycledAssets';
import { GetClientBinAssets, GetClientBinAssetsVariables } from './__graphqlTypes/GetClientBinAssets';

const GET_CLIENT_BIN_ASSETS = gql`
    query GetClientBinAssets($locationId: ID!) {
        location(id: $locationId) {
            id
            client {
                id
                recycled {
                    ...AssetConnectionForRecycledAssets
                }
            }
        }
    }
    ${RecycledAssets.fragments.ASSET_CONNECTION_FOR_RECYCLED_ASSETS}
`;

export interface ClientBinAssetsProps {
    locationId: string;
}

export function ClientBinAssets(props: ClientBinAssetsProps): JSX.Element {
    const { data, loading } = useQuery<GetClientBinAssets, GetClientBinAssetsVariables>(GET_CLIENT_BIN_ASSETS, {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first',
        notifyOnNetworkStatusChange: true,
        variables: { locationId: props.locationId },
    });

    if (!data?.location?.client || loading) {
        return <Loading />;
    } else {
        return (
            <RecycledAssets
                assetConnection={data.location.client.recycled}
                headerText={t('ASSET_LIBRARY.BIN_ASSETS')}
            />
        );
    }
}
ClientBinAssets.displayName = 'ClientBinAssets';
