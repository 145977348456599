/// <reference path="../../../typings/browser.d.ts" />
import {
    assertNever,
    AssignedLocation,
    BasicUserDto,
    ClientId,
    LocationId,
    LocationIdHierarchy,
    SanitizedUser,
    User,
} from '@deltasierra/shared';
import { ILocationService, IScope } from 'angular';
import { MvAuth } from '../account/mvAuth';
import { MvIdentity } from '../account/mvIdentity';
import { MvClient } from '../clients/mvClient';
import { ClientResource, MvClientResource, mvClientResourceSID } from '../clients/mvClientResource';
import {
    $locationSID,
    $qSID,
    $routeParamsSID,
    $routeSID,
    $scopeSID,
    IRoute,
    IRouteParams,
} from '../common/angularData';
import { $modalInstanceSID, $modalSID } from '../common/angularUIBootstrapData';
import { DataUtils } from '../common/dataUtils';
import { Future } from '../common/Future';
import { InteractionUtils } from '../common/interactionUtils';
import { MvNotifier } from '../common/mvNotifier';
import { I18nService as i18nService } from '../i18n/i18nService';
import { ModalInstance, ModalService } from '../typings/angularUIBootstrap/modalService';
import { LocationUserApiClient } from './locationUserApiClient';
import { MvLocation } from './mvLocation';
import IQService = angular.IQService;

enum RemoveUserAction {
    Abort,
    RemoveUserFromLocation,
    DeleteUser,
}

export class ActionWillOrphanUserWarningController {
    public static readonly $inject: string[] = [$modalInstanceSID];

    public constructor(protected readonly $modalInstance: ModalInstance) {}

    public abort() {
        return this.$modalInstance.close(RemoveUserAction.Abort);
    }

    public removeUserFromLocation() {
        return this.$modalInstance.close(RemoveUserAction.RemoveUserFromLocation);
    }

    public deleteUser() {
        return this.$modalInstance.close(RemoveUserAction.DeleteUser);
    }
}

export interface MvLocationUsersCtrlScope extends IScope {
    loading: {
        clientUsers: boolean;
    };
    identity: MvIdentity;
    client: ClientResource;
    existingUser: BasicUserDto | null;
    location?: AssignedLocation;
    users: SanitizedUser[];

    removeUser: (user: User) => ng.IPromise<void>;
    onClickAddExistingUser: () => ng.IPromise<void>;
    isNotExistingUserFilter: (value: User, index: number, array: User[]) => boolean;
    setExistingUser: (user: BasicUserDto) => void;
    submitAddExistingUser: Future<void, { existingUser: BasicUserDto; location: AssignedLocation }>;
    onCreateUser: (newUser: SanitizedUser) => void;
}

angular.module('app').controller('mvLocationUsersCtrl', [
    $scopeSID,
    $qSID,
    $routeSID,
    $routeParamsSID,
    $locationSID,
    $modalSID,
    MvIdentity.SID,
    MvClient.SID,
    MvAuth.SID,
    mvClientResourceSID,
    MvLocation.SID,
    MvNotifier.SID,
    DataUtils.SID,
    InteractionUtils.SID,
    i18nService.SID,
    LocationUserApiClient.SID,

    function mvLocationUsersCtrl(
        $scope: MvLocationUsersCtrlScope,
        $q: IQService,
        $route: IRoute,
        $routeParams: IRouteParams,
        $location: ILocationService,
        $modal: ModalService,
        mvIdentity: MvIdentity,
        mvClient: MvClient,
        mvAuth: MvAuth,
        mvClientResource: MvClientResource,
        mvLocation: MvLocation,
        mvNotifier: MvNotifier,
        dataUtils: DataUtils,
        interactionUtils: InteractionUtils,
        I18nService: i18nService,
        locationUserApiClient: LocationUserApiClient,
    ) {
        $scope.submitAddExistingUser = interactionUtils.createFuture(
            'submit add existing user',
            (context: { existingUser: BasicUserDto; location: AssignedLocation }) => {
                const existingUser = context.existingUser;
                return locationUserApiClient.addUserToLocation(existingUser.id, context.location.id).then(
                    () => {
                        mvNotifier.notify(
                            I18nService.text.agency.client.location.users.userAdded({
                                firstName: existingUser.firstName,
                                lastName: existingUser.lastName,
                                location: context.location.title,
                            }),
                        );
                        $route.reload();
                    },
                    reason => {
                        mvNotifier.unexpectedErrorWithData(
                            I18nService.text.agency.client.location.users.failedToAddExistingUser(),
                            reason,
                        );
                    },
                );
            },
        );

        $scope.loading = {
            clientUsers: false,
        };

        $scope.identity = mvIdentity;
        $scope.client = mvClientResource.get({ id: $routeParams.clientId });
        $scope.existingUser = null;
        $scope.users = [];

        function initPage() {
            const clientId = ClientId.from(parseInt($routeParams.clientId, 10));
            const locationId = LocationId.from(parseInt($routeParams.id, 10));
            return $q.all([
                mvLocation.getAssignedLocation(locationId).then(location => {
                    $scope.location = location;
                }),
                locationUserApiClient.getLocationUsers(clientId, locationId).then(users => {
                    $scope.users = users;
                }),
            ]);
        }

        $scope.removeUser = async (user: User) => {
            try {
                const locations = await mvLocation.getAssignedLocationsForUser(user.id);
                const action: RemoveUserAction = await getConfirmRemoveUserAction(locations);
                switch (action) {
                    case RemoveUserAction.Abort:
                        break;
                    case RemoveUserAction.RemoveUserFromLocation:
                        await locationUserApiClient.removeUserFromLocation(user.id, $scope.location!.id);
                        mvNotifier.notify(
                            I18nService.text.agency.client.location.users.userRemoved({
                                firstName: user.firstName,
                                lastName: user.lastName,
                                location: $scope.location!.title,
                            }),
                        );
                        $route.reload();
                        break;
                    case RemoveUserAction.DeleteUser:
                        await mvClient.deleteClientUser(user.id);
                        mvNotifier.notify(
                            I18nService.text.agency.client.location.users.userEdit.userDeleted({
                                firstName: user.firstName,
                                lastName: user.lastName,
                            }),
                        );
                        $route.reload();
                        break;
                    default:
                        assertNever(action);
                }
            } catch (error) {
                mvNotifier.unexpectedError(error);
            }
        };

        $scope.onClickAddExistingUser = () => {
            if ($scope.existingUser && $scope.location) {
                return $scope.submitAddExistingUser.run({
                    existingUser: $scope.existingUser,
                    location: $scope.location,
                });
            } else {
                return $q.resolve();
            }
        };

        $scope.isNotExistingUserFilter = (value: BasicUserDto) =>
            dataUtils.findBy('id', $scope.users, value.id) === null;

        $scope.setExistingUser = (user: BasicUserDto | null) => {
            $scope.existingUser = user;
        };

        $scope.onCreateUser = (newUser: SanitizedUser): void => {
            $scope.users = [...$scope.users, newUser];
        };

        async function getConfirmRemoveUserAction(locations: LocationIdHierarchy[]): Promise<RemoveUserAction> {
            if (locations.length === 1) {
                return $modal
                    .open({
                        controller: ActionWillOrphanUserWarningController,
                        controllerAs: 'ctrl',
                        templateUrl: 'actionWillOrphanUserModal.html',
                    })
                    .result.catch(() => RemoveUserAction.Abort);
            } else {
                return Promise.resolve<RemoveUserAction>(RemoveUserAction.RemoveUserFromLocation);
            }
        }

        initPage();
    },
]);
