import { $modalSID } from '../../common/angularUIBootstrapData';
import { ModalService } from '../../typings/angularUIBootstrap/modalService';
import { GoogleAdWordsSpendAdjustmentRequestController, GoogleAdWordsSpendAdjustmentRequestOptions } from './controller';

export class GoogleAdWordsSpendAdjustmentRequestService {
    static readonly SID = 'GoogleAdWordsSpendAdjustmentRequestService';

    static readonly $inject = [$modalSID];

    constructor(private $modal: ModalService) {
    }

    confirmWithUser(options: GoogleAdWordsSpendAdjustmentRequestOptions) {
        const modalInstance = this.$modal.open({
            animation: true,
            backdrop: 'static',
            keyboard: true,
            templateUrl: 'partials/reports/googleAdWordsSpendAdjustmentRequest/template',
            controller: GoogleAdWordsSpendAdjustmentRequestController,
            controllerAs: 'ctrl',
            resolve: {
                options: () => options,
            },
        });

        return modalInstance.result;
    }
}

angular.module('app').service(GoogleAdWordsSpendAdjustmentRequestService.SID, GoogleAdWordsSpendAdjustmentRequestService);
