import { Button, DSFormLabel, DSTypography } from '@deltasierra/components';
import { justParams, t, ApiRoute, LocationId } from '@deltasierra/shared';
import * as React from 'react';
import { useAngularServiceContext } from '../../../common/componentUtils/angularServiceContexts';
import { DataLoadingSuspense } from '../../../common/componentUtils/asyncComponents';
import { useDataRetrieval } from '../../../common/futures';
import { invokeApiRoute } from '../../../common/httpUtils';
import { Loading } from '../../../common/Loading';
import { Translate } from '../../../directives/Translate';

export type ConfiguredServiceInformationProps<T extends Record<string, unknown>> = {
    children: (arg: T) => JSX.Element | string;
    locations: LocationId[];
    endpoint: ApiRoute<T, { locationId: LocationId }, void, void>;
    fetchMessage: string;
    label: string;
    useNewLabel?: boolean;
};

export function MultipleLocationConfiguredServiceInformation<T extends Record<string, unknown>>({
    children,
    endpoint,
    fetchMessage,
    label,
    locations,
    useNewLabel,
}: ConfiguredServiceInformationProps<T>): JSX.Element {
    const MAX_LENGTH = 5;
    const MAX_LENGTH_EL = 2;
    const http = useAngularServiceContext('$http');
    const [response, refetch] = useDataRetrieval(async (): Promise<T[]> => {
        const requests = locations.slice(0, MAX_LENGTH).map(async locationId => {
            const params = justParams({ locationId });
            return Promise.resolve(invokeApiRoute(http, endpoint, params));
        });
        return Promise.all(requests);
    }, [http, locations, endpoint, MAX_LENGTH]);
    return (
        <div className="form-group">
            {useNewLabel ? <DSFormLabel>{label}</DSFormLabel> : <label className="control-label">{label}</label>}
            <DataLoadingSuspense
                error={() => (
                    <>
                        <p className="static-control">
                            <Translate keyId="COMMON.FAILED_TO" options={{ description: fetchMessage }} />
                        </p>
                        <Button size="sm" theme="default" onClick={refetch}>
                            <Translate keyId="COMMON.RETRY" />
                        </Button>
                    </>
                )}
                loading={() => <Loading size="small" />}
                waitFor={[response]}
            >
                {serviceInfos => {
                    const useJsxEl = serviceInfos.length > 0 && typeof children(serviceInfos[0]) !== 'string';
                    if (useJsxEl) {
                        return (
                            <>
                                {serviceInfos.filter((_, idx) => idx < MAX_LENGTH_EL).map(children)}
                                {locations.length > MAX_LENGTH_EL && (
                                    <DSTypography>{`...${t('COMMON.AND_X_OTHER_LOCATIONS', {
                                        count: locations.length - MAX_LENGTH_EL,
                                    })}`}</DSTypography>
                                )}
                            </>
                        );
                    } else {
                        return (
                            <p className="static-control">
                                {serviceInfos.length === 0 && '-'}
                                {serviceInfos.length > 0 && serviceInfos.map(info => children(info)).join(', ')}
                                {locations.length > MAX_LENGTH &&
                                    `, ${t('COMMON.AND_X_OTHER_LOCATIONS', { count: locations.length - MAX_LENGTH })}`}
                            </p>
                        );
                    }
                }}
            </DataLoadingSuspense>
        </div>
    );
}
MultipleLocationConfiguredServiceInformation.displayName = 'ConfiguredServiceInformation';

export default MultipleLocationConfiguredServiceInformation;
