/// <reference path="../../../../../../typings/browser.d.ts" />
import {
    BuilderTemplateFormatId,
    Currency,
    ICreateOrUpdatePrintProviderSKUBody,
    Nullable,
    pick,
    PrintProviderSKU,
    PrintProviderSKUGroup,
    PrintProviderSKUGroupId,
    PrintProviderSKUId,
    QuantityOptionCrudDto,
} from '@deltasierra/shared';
import { $locationSID, $qSID, $routeParamsSID, $scopeSID } from '../../../../common/angularData';
import { currencyPattern } from '../../../../common/currencyInput';
import { DataUtils } from '../../../../common/dataUtils';
import { DirtyStateChecker, DirtyStateCheckerFactory } from '../../../../common/dirtyStateChecker';
import { TabNavEntry } from '../../../../common/dsTabNav';
import { InteractionUtils } from '../../../../common/interactionUtils';
import { MvNotifier } from '../../../../common/mvNotifier';
import { QuantityOption } from '../../../../contentBuilder/publish/print/sku/basePublishPrintSKUController';
import { I18nService } from '../../../../i18n/i18nService';
import { CurrencyService } from '../../../../payments/currencyService';
import {
    AgencySKUGroupExpectedRouteParams,
    AgencySKUGroupIds,
    extractIdsFromRouteParams,
    getAgencySKUGroupTabNavEntries,
} from '../../common';
import { paymentGatewayOptions } from '../../controller';
import { SKUManagementApiClient } from '../../skuManagementApiClient';
import IScope = angular.IScope;
import IQService = angular.IQService;

export class SKUDetailsController {
    public static SID = 'SKUDetailsController';

    // Futures
    fetchData = this.interactionUtils.createFuture(
        'Fetch SKU data',
        async (context: {
            printProviderSKUId?: PrintProviderSKUId;
            printProviderSKUGroupId: PrintProviderSKUGroupId;
        }) => {
            const promises: Array<ng.IPromise<unknown>> = [
                // Formats won't be updated until a change is made and the callback from the picker is triggered
                this.fetchFormatsDeferred.promise,
            ];
            if (context.printProviderSKUId) {
                const sp = this.skuManagementApiClient.getSKU(context.printProviderSKUId).then(sku => {
                    this.sku = sku;
                    this.formData = this.sku;
                });
                const qp = this.skuManagementApiClient
                    .getSKUQuantityOptions(context.printProviderSKUId)
                    .then(quantityOptions => {
                        this.quantityOptions = quantityOptions.map(quantityOption =>
                            pick(['quantity', 'priceAmountInCents'], quantityOption),
                        );
                    });
                promises.push(sp);
                promises.push(qp);
            }
            const sgp = this.skuManagementApiClient.getSKUGroup(context.printProviderSKUGroupId).then(skuGroup => {
                this.skuGroup = skuGroup;
                if (!context.printProviderSKUId) {
                    this.formData = {
                        exclusive: false,
                        linearPricing: true,
                        priceCurrency: this.skuGroup.defaultPriceCurrency,
                    };
                    if (this.skuGroup.paymentGateway === 'four51' && this.formData.priceAmountInCents === undefined) {
                        this.formData.priceAmountInCents = 0;
                    }
                }
            });
            promises.push(sgp);
            await this.$q.all(promises);
            // Now that the quantity options and formats have been loaded we can watch for everything
            this.startWatchingForChanges();
        },
    );

    createOrUpdateSKU = this.interactionUtils.createFuture(
        'Update SKU',
        async (context: { printProviderSKUId: PrintProviderSKUId; skuData: ICreateOrUpdatePrintProviderSKUBody }) => {
            if (context.printProviderSKUId) {
                await this.skuManagementApiClient.updateSKU(context.printProviderSKUId, context.skuData);
            } else {
                await this.skuManagementApiClient.createSKU(context.skuData);
            }
            this.mvNotifier.notify(this.i18n.text.agency.printSkuGroups.sku.skuUpdated());
            // Return to SKU overview
            if (this.dirtyStateChecker) {
                this.dirtyStateChecker.reset();
            }
            this.backToSKUGroup();
        },
    );

    deleteSKU = this.interactionUtils.createFuture(
        'Delete SKU',
        (context: { printProviderSKUId: PrintProviderSKUId }) =>
            this.skuManagementApiClient.deleteSKU(context.printProviderSKUId).then(() => {
                this.mvNotifier.notify(this.i18n.text.agency.printSkuGroups.sku.skuDeleted());
                this.backToSKUGroup();
            }),
    );

    sku?: PrintProviderSKU;

    skuGroup: PrintProviderSKUGroup | null = null;

    tabNavEntries: ReadonlyArray<TabNavEntry> = [];

    tabNavParams: AgencySKUGroupIds;

    paymentGatewayOptions = paymentGatewayOptions;

    formData: Partial<Nullable<PrintProviderSKU>>;

    formats: BuilderTemplateFormatId[] = [];

    quantityOptions: QuantityOptionCrudDto[] = [];

    newQuantityOption: Partial<QuantityOptionCrudDto> = {};

    readonly currencies: Currency[] | ReadonlyArray<Currency>;

    readonly currencyPattern = currencyPattern;

    readonly dirtyStateChecker: DirtyStateChecker;

    readonly fetchFormatsDeferred = this.$q.defer();

    readonly printProviderSKUId?: PrintProviderSKUId;

    readonly printProviderSKUGroupId?: PrintProviderSKUGroupId;

    static readonly $inject: string[] = [
        $scopeSID,
        $qSID,
        CurrencyService.SID,
        $routeParamsSID,
        $locationSID,
        I18nService.SID,
        InteractionUtils.SID,
        MvNotifier.SID,
        DataUtils.SID,
        SKUManagementApiClient.SID,
        DirtyStateCheckerFactory.SID,
    ];

    constructor(
        private readonly $scope: IScope,
        private readonly $q: IQService,
        currencyService: CurrencyService,
        $routeParams: AgencySKUGroupExpectedRouteParams,
        private readonly $location: ng.ILocationService,
        private readonly i18n: I18nService,
        private readonly interactionUtils: InteractionUtils,
        private readonly mvNotifier: MvNotifier,
        private readonly dataUtils: DataUtils,
        private readonly skuManagementApiClient: SKUManagementApiClient,
        private readonly dirtyStateCheckerFactory: DirtyStateCheckerFactory,
    ) {
        this.currencies = currencyService.CURRENCIES;
        this.dirtyStateChecker = this.dirtyStateCheckerFactory.createChecker(this.$scope, [
            {
                watcher: () => ({
                    formData: this.formData,
                    formats: this.formats,
                    quantityOptions: this.quantityOptions,
                }),
                deep: true,
            },
        ]);

        const ids = extractIdsFromRouteParams($routeParams);
        this.tabNavParams = ids;
        this.tabNavEntries = getAgencySKUGroupTabNavEntries(this.i18n);
        this.formData = {};
        // Expose these IDs to the template, so we can load the format picker before we've finished fetching the SKU and SKU Group.
        this.printProviderSKUId = ids.skuId;
        this.printProviderSKUGroupId = ids.skuGroupId;

        if (ids.skuGroupId) {
            void this.fetchData.run({
                printProviderSKUId: ids.skuId,
                printProviderSKUGroupId: ids.skuGroupId,
            });
        } else {
            this.mvNotifier.notify(this.i18n.text.agency.printSkuGroups.sku.skuGroupMissing(), 'error');
            this.backToSKUGroup();
        }
    }

    backToSKUGroup() {
        if (this.skuGroup) {
            this.$location.path(`/agency/printProviderSKUGroups/${this.skuGroup.id}`);
        } else {
            this.$location.path('/agency/printProviderSKUGroups/');
        }
    }

    /**
     * We need to start watching once all quantity options and formats are loaded.
     * Formats are loaded from another component and passed back to the SKU editor. We can't be sure the order that
     * everything finishes loading, so only start watching once all required data is loaded.
     */
    protected startWatchingForChanges() {
        this.dirtyStateChecker.startWatching();
    }

    /**
     * Formats are set via this method at least once, when the format picker component initialises.
     *
     * @param formats
     */
    onSelectedFormatUpdate(formats: BuilderTemplateFormatId[]): void {
        this.formats = formats;
        this.fetchFormatsDeferred.resolve();
    }

    hasRequiredFormValues(formData: Partial<Nullable<PrintProviderSKU>>): formData is PrintProviderSKU {
        return (
            formData.sku !== null &&
            formData.sku !== undefined &&
            formData.skuName !== null &&
            formData.skuName !== undefined &&
            (!this.formData.linearPricing ||
                (formData.priceAmountInCents !== null && formData.priceAmountInCents !== undefined)) &&
            formData.priceCurrency !== null &&
            formData.priceCurrency !== undefined &&
            formData.exclusive !== null &&
            formData.exclusive !== undefined
        );
    }

    hasValidQuantityOptions(): boolean {
        if (this.formData.linearPricing) {
            return true;
        } else if (!this.formData.linearPricing && this.quantityOptions.length > 0) {
            return true;
        } else {
            return false;
        }
    }

    isAllDataValid() {
        return this.hasRequiredFormValues(this.formData) && this.hasValidQuantityOptions();
    }

    onClickCancel(): void {
        this.backToSKUGroup();
    }

    onClickUpdate(): void {
        if (this.hasRequiredFormValues(this.formData) && this.hasValidQuantityOptions() && this.skuGroup) {
            const skuData: ICreateOrUpdatePrintProviderSKUBody = {
                printProviderSKUGroupId: this.skuGroup.id,
                sku: this.formData.sku,
                skuName: this.formData.skuName,
                priceAmountInCents: this.formData.linearPricing ? this.formData.priceAmountInCents : null,
                priceCurrency: this.formData.priceCurrency,
                exclusive: this.formData.exclusive,
                printProviderSKUQuantityOptions: this.formData.linearPricing ? [] : this.quantityOptions,
                builderTemplateFormatIds: this.formats,
            };
            void this.createOrUpdateSKU.run({
                printProviderSKUId: this.formData.id,
                skuData,
            });
        }
    }

    onClickDelete(): void {
        if (this.sku) {
            void this.deleteSKU.run({ printProviderSKUId: this.sku.id });
        }
    }

    onClickDeleteQuantityOption(qo: QuantityOptionCrudDto) {
        this.dataUtils.removeObject(qo, this.quantityOptions);
    }

    quantityExistsInQuantityOptions(quantity: number): boolean {
        const search = this.dataUtils.findByPredicate(qo => qo.quantity === quantity, this.quantityOptions);

        if (search) {
            this.mvNotifier.notify(
                this.i18n.text.agency.printSkuGroups.quantityOption.quantityAlreadyExists(),
                'error',
            );
            return true;
        } else {
            return false;
        }
    }

    protected isValidQuantityOption(qo: Partial<QuantityOption>): qo is QuantityOptionCrudDto {
        return qo.quantity != undefined && qo.priceAmountInCents != undefined;
    }

    addQuantityOption() {
        const qo = this.newQuantityOption;
        if (this.isValidQuantityOption(qo) && qo.quantity > 0 && !this.quantityExistsInQuantityOptions(qo.quantity)) {
            this.quantityOptions.push(qo);
            this.newQuantityOption = {};
        }
    }
}

angular.module('app').controller(SKUDetailsController.SID, SKUDetailsController);
