import * as React from 'react';
import { LocationMap as AssignedLocationMap, t } from '@deltasierra/shared';
import { DSButton, DSGrid, DSTypography, Translate } from '@deltasierra/components';
import { UploadBlock } from '../UploadBlock';

type LocationMapProps = {
    map?: AssignedLocationMap;
    paymentComplete: boolean;
    createMap: () => Promise<void>;
    deleteMap: () => Promise<void>;
};

export function LocationMap({ map, paymentComplete, createMap, deleteMap }: LocationMapProps): JSX.Element {
    return (
        <DSGrid container style={{ marginTop: 10 }}>
            <DSGrid item md={2} sm={3} xs={12}>
                <DSTypography component="label" variant="inherit">
                    {t('COMMON.MAP')}
                </DSTypography>
            </DSGrid>
            <DSGrid item>
                {!map?.upload && (
                    <DSButton
                        color="success"
                        disabled={paymentComplete}
                        type="button"
                        variant="contained"
                        onClick={createMap}
                    >
                        <Translate keyId="ACCOUNT.MAP.CREATE_NEW_MAP" />
                    </DSButton>
                )}
                {map?.upload && (
                    <UploadBlock
                        deleteUpload={deleteMap}
                        height={128}
                        titleVisible={false}
                        upload={map.upload}
                        width={128}
                    />
                )}
            </DSGrid>
        </DSGrid>
    );
}
LocationMap.displayName = 'LocationMap';
