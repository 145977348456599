import { I18nService } from '../../i18n';

export class ScheduleTimePickerController {
    public static readonly $inject: string[] = [I18nService.SID];

    public isScheduled?: boolean;

    public isToBeScheduled?: boolean;

    public datePickerIsOpen = {
        scheduledTime: false,
    };

    public minDate?: Date | null;

    public timezone!: string;

    public scheduledTime!: Date;

    public constructor(public readonly i18n: I18nService) {}

    public $onInit(): void {
        if (this.isScheduled === undefined) {
            this.isScheduled = false;
        }
    }

    public openDatePicker($event: Event, datePickerName: 'scheduledTime') {
        $event.preventDefault();
        $event.stopPropagation();
        this.datePickerIsOpen[datePickerName] = true;
    }
}
