import {
    actualComponent,
    ExpressionBinding,
    OptionalStringBinding,
} from '../../common/angularData';
import { EmojiPickerController } from './controller';

export const emojiPickerSID = 'emojiPicker';
export const emojiPickerConfig = actualComponent(
    EmojiPickerController,
    '/partials/directives/emojiPicker/template',
    {
        onSelect: ExpressionBinding,
        placement: OptionalStringBinding,
    },
);

angular.module('app').component(emojiPickerSID, emojiPickerConfig);
