import {
    DSFormControl,
    DSFormControlLabel,
    DSFormGroup,
    DSRadio,
    DSRadioGroup,
    Translate,
} from '@deltasierra/components';
import { t } from '@deltasierra/shared';
import { FormikProps } from 'formik';
import React from 'react';
import { StyledLabel } from '../../../common';
import { PublishToClubReadyStep1FormValues } from '../../types/publish-to-club-ready-step-1-form-values';
import { ClubReadyActiveListOption } from './components/ClubReadyActiveListOption';
import { ClubReadyInactiveListOption } from './components/ClubReadyInactiveListOption';
import { ClubReadyProspectsListOption } from './components/ClubReadyProspectsListOption';
import { ClubReadyCooledOffProspectsListOption } from './components/ClubReadyCooledOffProspectsListOption';
import { ClubReadyCustomEmailListOption } from './components/ClubReadyCustomEmailListOption';

type SendToSectionProps = {
    formik: FormikProps<PublishToClubReadyStep1FormValues>;
};

export function SendToSection({ formik }: SendToSectionProps): JSX.Element {
    return (
        <DSFormGroup>
            <DSFormControl>
                <StyledLabel component="label" variant="inherit">
                    <Translate keyId="BUILD.PUBLISH.CLUB_READY.SEND_TO" />
                </StyledLabel>
                <DSRadioGroup
                    value={formik.values.filterType}
                    onChange={(_event, value) => formik.setFieldValue('filterType', value as 'emails' | 'filter')}
                >
                    <DSFormControlLabel
                        control={<DSRadio />}
                        label={t('BUILD.PUBLISH.CLUB_READY.SELECTED_MEMBERS_AND_OR_PROSPECTS')}
                        value="filter"
                    />
                    {formik.values.filterType === 'filter' && (
                        <>
                            <ClubReadyActiveListOption formik={formik} />
                            <ClubReadyInactiveListOption formik={formik} />
                            <ClubReadyProspectsListOption formik={formik} />
                            <ClubReadyCooledOffProspectsListOption formik={formik} />
                        </>
                    )}

                    <DSFormControlLabel
                        control={<DSRadio />}
                        label={t('BUILD.PUBLISH.CLUB_READY.CUSTOM_LIST_OF_EMAIL_ADDRESSES')}
                        value="emails"
                    />
                    {formik.values.filterType === 'emails' && <ClubReadyCustomEmailListOption formik={formik} />}
                </DSRadioGroup>
            </DSFormControl>
        </DSFormGroup>
    );
}
SendToSection.displayName = 'SendToSection';
