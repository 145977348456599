import { Dropdown } from '@deltasierra/components';
import { t } from '@deltasierra/shared';
import * as React from 'react';
import { PickerDisplay } from '../../../campaign/components/CampaignEventForm/TemplateOrCategoryPicker/values/picker-display.enum';

function TemplateOption(props: { label: string; option: PickerDisplay }) {
    if (props.option === PickerDisplay.None) {
        return <span style={{ height: 0 }}></span>;
    }
    return <span key={props.option}>{props.label}</span>;
}
TemplateOption.displayName = 'TemplateOption';

export interface SelectorProps {
    value: PickerDisplay;
    onChange: (value: PickerDisplay) => void;
}

export function Selector(props: SelectorProps): JSX.Element {
    const options: Record<PickerDisplay, string> = {
        [PickerDisplay.Template]: t('PLAN.ASSOCIATED_BUILDER_RESOURCES.SINGLE_TEMPLATE'),
        [PickerDisplay.None]: '',
        [PickerDisplay.Category]: t('COMMON.TEMPLATE_CATEGORY'),
    };
    const items = Object.keys(options) as PickerDisplay[];
    return (
        <Dropdown
            getOptionTemplate={option => <TemplateOption label={options[option]} option={option} />}
            getSelectedTemplate={() => <span>{t('COMMON.ADD')}</span>}
            getValue={option => option}
            items={items}
            value={props.value}
            onSelect={props.onChange}
        />
    );
}
Selector.displayName = 'Selector';
