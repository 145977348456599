import { DSGrid, Loading } from '@deltasierra/components';
import { assertNever, BuilderTemplateId } from '@deltasierra/shared';
import { isNotNullOrUndefined } from '@deltasierra/type-utilities';
import * as React from 'react';
import { EndAdornment } from '../../../campaign/components/CampaignEventForm/TemplateOrCategoryPicker/TemplateSelect/EndAdornment';
import { PickerDisplay } from '../../../campaign/components/CampaignEventForm/TemplateOrCategoryPicker/values/picker-display.enum';
import { ValidationState } from '../../../campaign/components/CampaignEventForm/TemplateOrCategoryPicker/values/validation-state.enum';
import { ExpressionBinding, OneWayBinding } from '../../../common/angularData';
import { withAngularIntegration } from '../../../common/componentUtils/reactComponentRegistration';
import { PlannerCategoryDropdown } from '../PlannerCategoryDropdown';
import { Grid } from './Grid';
import { PlannerTemplateSelector } from './PlannerTemplateSelector';
import { Selector } from './Selector';

function getInitialState(categoryId: number | null, templateId: BuilderTemplateId | null): PickerDisplay {
    if (isNotNullOrUndefined(categoryId)) {
        return PickerDisplay.Category;
    } else if (isNotNullOrUndefined(templateId)) {
        return PickerDisplay.Template;
    }
    return PickerDisplay.None;
}

export interface PlannerCategoryOrTemplateSelectorProps {
    locationId?: string;
    categoryId: number | null;
    eventDate: Date;
    onChangePickerDisplayOption?: (pickerDisplayOption: PickerDisplay) => void;
    onSelectCategory?: (categoryId: number | null) => void;
    onSelectTemplate?: (templateId: BuilderTemplateId | null) => void;
    setIsExpiring?: (isExpiring: boolean) => void;
    templateId: BuilderTemplateId | null;
}

export function PlannerCategoryOrTemplateSelector({
    categoryId,
    eventDate,
    locationId,
    onChangePickerDisplayOption,
    onSelectCategory,
    onSelectTemplate,
    setIsExpiring,
    templateId,
}: PlannerCategoryOrTemplateSelectorProps): JSX.Element {
    const [selectedOption, setSelectedOption] = React.useState<PickerDisplay>(getInitialState(categoryId, templateId));

    function handleBack() {
        onSelectCategory?.(null);
        onSelectTemplate?.(null);
        onChangePickerDisplayOption?.(PickerDisplay.None);
        setSelectedOption(PickerDisplay.None);
    }

    function handleOptionSelect(option: PickerDisplay) {
        onChangePickerDisplayOption?.(option);
        setSelectedOption(option);
    }

    switch (selectedOption) {
        case PickerDisplay.Category:
            return (
                <Grid onBack={handleBack}>
                    {locationId && (
                        <>
                            <DSGrid item xs={true}>
                                <PlannerCategoryDropdown
                                    categoryId={categoryId}
                                    eventDate={eventDate}
                                    locationId={locationId}
                                    setIsExpiring={setIsExpiring}
                                    onChange={onSelectCategory}
                                />
                            </DSGrid>
                            <DSGrid item>
                                <EndAdornment state={categoryId ? ValidationState.Valid : ValidationState.Invalid} />
                            </DSGrid>
                        </>
                    )}
                </Grid>
            );
        case PickerDisplay.None:
            return <Selector value={selectedOption} onChange={handleOptionSelect} />;
        case PickerDisplay.Template:
            return (
                <Grid onBack={handleBack}>
                    <PlannerTemplateSelector initialId={templateId} onChange={onSelectTemplate} />
                </Grid>
            );
        default:
            return assertNever(selectedOption);
    }
}
PlannerCategoryOrTemplateSelector.displayName = 'PlannerCategoryOrTemplateSelector';

// This exists because the mvPlannerEventCtrl loads the planner asynchronously.
// We don't have the correct initial state if we just ask it directly.
function SelectorOrLoading(
    props: PlannerCategoryOrTemplateSelectorProps & { areLinkedResourcesLoaded: boolean },
): JSX.Element {
    if (props.areLinkedResourcesLoaded) {
        return <PlannerCategoryOrTemplateSelector {...props} />;
    }
    return <Loading size="small" />;
}
SelectorOrLoading.displayName = 'SelectorOrLoading';

export const PlannerCategoryOrTemplateSelectorWithAngular = withAngularIntegration(
    SelectorOrLoading,
    'plannerCategoryOrTemplateSelector',
    {
        areLinkedResourcesLoaded: OneWayBinding,
        categoryId: OneWayBinding,
        eventDate: OneWayBinding,
        locationId: OneWayBinding,
        onChangePickerDisplayOption: ExpressionBinding,
        onSelectCategory: ExpressionBinding,
        onSelectTemplate: ExpressionBinding,
        setIsExpiring: ExpressionBinding,
        templateId: OneWayBinding,
    },
);
