/// <reference path="../../../_references.d.ts" />
import { PlannerDetailsBarController } from './controller';

export const plannerDetailsBarSID = 'plannerDetailsBar';
export const plannerDetailsBarConfig = {
    templateUrl: '/partials/contentBuilder/common/plannerDetailsBar/template',
    restrict: 'E',
    replace: true,
    scope: {
        plannerId: '=?',
        plannerDetails: '=?',
        change: '&?',
    },
    controller: PlannerDetailsBarController,
    controllerAs: 'ctrl',
    bindToController: true,
};

angular.module('app').directive(plannerDetailsBarSID, [() => plannerDetailsBarConfig]);
