import * as React from 'react';
import { CurrentLocationIdType, CurrentLocationRouteStateContext } from '../contexts';
import { useCurrentLocationLocalStorageState } from './useCurrentLocationStorageState';

/**
 * Use the current client from the combined contexts
 *
 * @param [useLocalStorageAsFallback=true] - Whether to include the local storage as a fallback?
 * @returns The current client
 */
export function useCurrentLocationState(useLocalStorageAsFallback = true): CurrentLocationIdType | undefined {
    const currentLocationFromContext = React.useContext(CurrentLocationRouteStateContext);
    const currentLocationFromLocalStorage = useCurrentLocationLocalStorageState();
    return currentLocationFromContext ?? (useLocalStorageAsFallback ? currentLocationFromLocalStorage : undefined);
}
