import { Well } from '@deltasierra/components';
import { t, AssignedLocation } from '@deltasierra/shared';
import * as React from 'react';
import { OneWayBinding } from '../../../../common/angularData';
import { withAngularIntegration } from '../../../../common/componentUtils/reactComponentRegistration';
import { ScheduledEmailsTable } from './ScheduledEmailsTable';

type ScheduledEmailsWellProps = {
    location: AssignedLocation;
};

export const ScheduledEmailsWell: React.FC<ScheduledEmailsWellProps> = ({ location }) => (
    <Well title={t('BUILD.MEMBER_ENGAGER.SCHEDULED_EMAILS')}>
        <ScheduledEmailsTable location={location} />
    </Well>
);

export default withAngularIntegration(ScheduledEmailsWell, 'scheduledEmailsWell', {
    location: OneWayBinding,
});
