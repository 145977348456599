import { useQuery } from '@apollo/client';
import React from 'react';
import { relayConnectionToArray } from '../../../../graphql/utils';
import { GET_REQUIRED_HASHTAGS_FOR_LOCATION_QUERY } from './useRequiredHashtags.queries';
import {
    GetRequiredHashtagsForLocationQuery,
    GetRequiredHashtagsForLocationQueryVariables,
} from './__graphqlTypes/GetRequiredHashtagsForLocationQuery';

export function useRequiredHashtags(locationId: string): {
    loading: boolean;
    requiredHashtags: string[];
} {
    const { data, loading } = useQuery<
        GetRequiredHashtagsForLocationQuery,
        GetRequiredHashtagsForLocationQueryVariables
    >(GET_REQUIRED_HASHTAGS_FOR_LOCATION_QUERY, { notifyOnNetworkStatusChange: true, variables: { locationId } });

    const hashtags = React.useMemo(
        () =>
            relayConnectionToArray(data?.location?.client?.hashtagSetsConnection).flatMap(
                hashtagSet => hashtagSet.hashtags,
            ),
        [data?.location?.client?.hashtagSetsConnection],
    );
    return { loading, requiredHashtags: hashtags };
}
