import React from 'react';

export type useDismissTimeoutProps = {
    dismiss: () => void;
    timeoutMs?: number;
    extendTimeoutMs?: number;
};

export function useDismissTimeout({ dismiss, timeoutMs, extendTimeoutMs = timeoutMs }: useDismissTimeoutProps): {
    handleMouseEnter: () => void;
    handleMouseLeave: () => void;
} {
    const [hasInitialTimeoutCompleted, setHasInitialTimeoutCompleted] = React.useState(false);

    const [hasHovered, setHasHovered] = React.useState(false);
    const [isHovering, setIsHovering] = React.useState(false);

    const handleDefaultTimeout = React.useCallback(() => {
        if (!hasHovered) {
            dismiss();
        } else {
            setHasInitialTimeoutCompleted(true);
        }
    }, [dismiss, hasHovered]);

    const handleExtendedTimeout = React.useCallback(() => {
        if (hasInitialTimeoutCompleted) {
            dismiss();
        }
    }, [dismiss, hasInitialTimeoutCompleted]);

    const handleDefaultTimeoutRef = React.useRef<typeof handleDefaultTimeout>();
    const handleExtendedTimeoutRef = React.useRef<typeof handleExtendedTimeout>();

    React.useEffect(() => {
        handleDefaultTimeoutRef.current = handleDefaultTimeout;
    }, [handleDefaultTimeout]);

    React.useEffect(() => {
        handleExtendedTimeoutRef.current = handleExtendedTimeout;
    }, [handleExtendedTimeout]);

    React.useEffect(() => {
        let timeout: ReturnType<typeof setTimeout>;

        if (timeoutMs && timeoutMs > 0) {
            timeout = setTimeout(() => handleDefaultTimeoutRef.current?.(), timeoutMs);
        }

        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleDefaultTimeoutRef]);

    React.useEffect(() => {
        let timeout: ReturnType<typeof setTimeout>;

        if (extendTimeoutMs && extendTimeoutMs > 0 && !isHovering) {
            timeout = setTimeout(() => handleExtendedTimeoutRef.current?.(), extendTimeoutMs);
        }

        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, [extendTimeoutMs, handleExtendedTimeoutRef, isHovering]);

    const handleHoverIn = React.useCallback(() => {
        setHasHovered(true);
        setIsHovering(true);
    }, []);
    const handleHoverOut = React.useCallback(() => {
        setIsHovering(false);
    }, []);

    return {
        handleMouseEnter: handleHoverIn,
        handleMouseLeave: handleHoverOut,
    };
}
