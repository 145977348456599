import { ApolloError } from '@apollo/client';
import { t } from '@deltasierra/shared';
import React from 'react';
import { useNotifier } from '../../common/components';
import {
    UseExportReportButtonState,
    UseExportReportButtonStateReducerDispatch,
} from './use-export-report-button-state-reducer';
import { UseExportReportButtonMutation } from './types';
import { isSuppressedEmailsReport, isTemplateUsageReport } from './utils';

type UseExportReportButtonStateTransitionsOptions = {
    downloadLinkElement: HTMLLinkElement | undefined;
    generateReportMutationData: UseExportReportButtonMutation | null;
    generateReportMutationError: ApolloError | undefined;
    generateReportMutationLoading: boolean;
    poll: (reportRequestId: string) => void;
    pollCount: number;
    pollIntervalMs: number;
    reset: () => void;
    runGenerateReportMutation: () => void;
};

export function useExportReportButtonStateTransitions(
    state: UseExportReportButtonState,
    dispatch: UseExportReportButtonStateReducerDispatch,
    {
        downloadLinkElement,
        generateReportMutationData,
        generateReportMutationError,
        generateReportMutationLoading,
        poll,
        pollCount,
        pollIntervalMs,
        reset,
        runGenerateReportMutation,
    }: UseExportReportButtonStateTransitionsOptions,
): void {
    const notifier = useNotifier();

    React.useEffect(() => {
        // When the state is transitioned to requesting then request to generate the report
        if (state.name === 'requesting') {
            runGenerateReportMutation();
        }
    }, [runGenerateReportMutation, state]);

    React.useEffect(() => {
        // When the state is requesting and we are no longer loading
        if (state.name === 'requesting' && !generateReportMutationLoading && generateReportMutationData) {
            if (isSuppressedEmailsReport(generateReportMutationData)) {
                // If we have a successfully generated report
                if (
                    generateReportMutationData?.generateCSVForSuppressedEmails.__typename ===
                    'GenerateCSVForSuppressedEmailsSuccess'
                ) {
                    // Start polling for the report
                    dispatch({
                        generateReportRequestId: generateReportMutationData.generateCSVForSuppressedEmails.request.id,
                        type: 'poll',
                    });
                } else if (
                    generateReportMutationData?.generateCSVForSuppressedEmails.__typename ===
                    'GenerateCSVForSuppressedEmailsError'
                ) {
                    // Otherwise if we errored out, transition to the error state
                    dispatch({
                        // eslint-disable-next-line max-len
                        error: `The mutation returned an error: ${generateReportMutationData.generateCSVForSuppressedEmails.message}`,
                        type: 'error',
                    });
                }
            } else if (isTemplateUsageReport(generateReportMutationData)) {
                // If we have a successfully generated report
                if (
                    generateReportMutationData?.generateCSVForTemplateUsage.__typename ===
                    'GenerateCSVForTemplateUsageSuccess'
                ) {
                    // Start polling for the report
                    dispatch({
                        generateReportRequestId: generateReportMutationData.generateCSVForTemplateUsage.request.id,
                        type: 'poll',
                    });
                } else if (
                    generateReportMutationData?.generateCSVForTemplateUsage.__typename ===
                    'GenerateCSVForTemplateUsageError'
                ) {
                    // Otherwise if we errored out, transition to the error state
                    dispatch({
                        // eslint-disable-next-line max-len
                        error: `The mutation returned an error: ${generateReportMutationData.generateCSVForTemplateUsage.message}`,
                        type: 'error',
                    });
                }
            } else if (generateReportMutationError) {
                // Otherwise if we errored out, transition to the error state
                dispatch({
                    error: generateReportMutationError,
                    type: 'error',
                });
            }
        }
    }, [dispatch, generateReportMutationData, generateReportMutationError, generateReportMutationLoading, state]);

    React.useEffect(() => {
        // When the state transitions to the polling state, start polling
        if (state.name === 'polling' && pollCount >= 0) {
            /**
             * The poll count will be updated anytime we need to wait for the next poll.
             * If we used interval instead, it will constantly poll every X seconds even if the
             * users internet is slow. We want to wait X seconds instead after the request finished
             * this way we don't overload them with requests.
             */
            const timeoutRef = setTimeout(() => {
                poll(state.generateReportRequestId);
            }, pollIntervalMs);

            return () => {
                clearTimeout(timeoutRef);
            };
        }
        return undefined;
    }, [state, pollCount, pollIntervalMs, poll]);

    React.useEffect(() => {
        // When the state transitions out of the polling state
        if (state.name === 'error' || state.name === 'success') {
            reset();
            if (state.name === 'success') {
                notifier.success(t('REPORT.POPULAR_BUILDER_TEMPLATE_DETAILS.NOTIFICATIONS.EXPORTING_SUCCESS'));
            } else if (state.name === 'error') {
                notifier.handledError(
                    t('REPORT.POPULAR_BUILDER_TEMPLATE_DETAILS.NOTIFICATIONS.EXPORTING_ERROR'),
                    state.error,
                );
            }
        }
    }, [state, notifier, reset]);

    React.useEffect(() => {
        // When the state transitions to success, notify the user
        if (state.name === 'success' && downloadLinkElement) {
            const timeoutRef = setTimeout(() => {
                downloadLinkElement.click();
            }, 3_000);
            return () => {
                clearTimeout(timeoutRef);
            };
        }
        return undefined;
    }, [state, downloadLinkElement]);
}
