import { gql } from '@apollo/client';

export const GET_FACEBOOK_PERMISSIONS_FOR_LOCATION = gql`
    query GetFacebookPermissionsForLocation($ids: [ID!]!) {
        locations(ids: $ids) {
            edges {
                node {
                    id
                    facebook {
                        ... on Facebook {
                            id
                            hasFirstCommentPermissions
                        }
                    }
                }
            }
        }
    }
`;
