import {
    t,
    MAX_INSTAGRAM_SCHEDULED_DAYS,
} from '@deltasierra/shared';

import { FormikConfig, FormikErrors } from 'formik';
import React from 'react';
import { DateTime, DurationObjectUnits } from 'luxon';
import { FormikValidateFunction } from '../../../typings/formik-validate-function';
import { InstagramStoryDirectFormValues } from './instagram-story-direct-form-values';

type useValidatedInstagramStoryDirectFormOptions = { date: Date | undefined };

export function useValidatedInstagramStoryDirectForm({
    date,
}: useValidatedInstagramStoryDirectFormOptions): Pick<
    FormikConfig<InstagramStoryDirectFormValues>,
    'initialValues' | 'validate'
> {
    const initialValues: InstagramStoryDirectFormValues = {
        scheduledTime: date || null,
    };

    const validate = React.useCallback<FormikValidateFunction<InstagramStoryDirectFormValues>>(
        ({ scheduledTime }) => {
            const errors: FormikErrors<InstagramStoryDirectFormValues> = {};

            // Check Meta scheduling limits and date validity
            if (scheduledTime) {
                const scheduledTimeObject = new Date(scheduledTime);
                const luxonScheduledTime = DateTime.fromJSDate(scheduledTimeObject);
                if (!luxonScheduledTime.isValid) {
                    errors.scheduledTime = 'Invalid date';
                }

                const diff: DurationObjectUnits = luxonScheduledTime.diffNow('days').toObject();
                const isValidFacebookJobDate = diff.days ? diff.days < MAX_INSTAGRAM_SCHEDULED_DAYS : false;

                if (!isValidFacebookJobDate) {
                    errors.scheduledTime = t(
                        'BUILD.PUBLISH.INSTAGRAM.SCHEDULED_INSTAGRAM_POST_MUST_BE_NO_MORE_THAN_75_DAYS_IN_FUTURE',
                    );
                }

                const luxonCurrentTime = DateTime.now();
                const isPastDate = luxonScheduledTime < luxonCurrentTime;
                if (isPastDate) {
                    errors.scheduledTime = t('BUILD.PUBLISH.INSTAGRAM.SCHEDULED_POST_MUST_BE_AT_LEAST_MINS_IN_FUTURE', {
                        minutes: 15,
                    });
                }
            }

            return errors;
        },
        [],
    );

    return {
        initialValues,
        validate,
    };
}
