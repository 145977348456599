/// <reference path="../../../../typings/browser.d.ts" />
import { ClientId, LocationId, LocationsAdminView, LocationSubscriptionMapDto } from '@deltasierra/shared';
import { MvClient } from '../../clients/mvClient';
import { $qSID, $routeParamsSID, IRouteParams } from '../../common/angularData';
import { InteractionUtils } from '../../common/interactionUtils';
import { I18nService } from '../../i18n';
import { CurrencyService } from '../../payments/currencyService';

import IQService = angular.IQService;

export class MvSubscriptionLocationListCtrl {
    public static SID = 'mvSubscriptionLocationListCtrl';

    clientId: ClientId;

    locations?: LocationsAdminView[];

    subscriptionsMap?: LocationSubscriptionMapDto;

    fetchLocations = this.interactionUtils.createFuture('retrieve locations', () =>
        this.mvClient.getLocations(this.clientId).then(locations => {
            this.locations = locations;
        }),
    );

    fetchLocationSubscriptions = this.interactionUtils.createFuture('retrieve location subscriptions', () =>
        this.mvClient.getLocationSubscriptions(this.clientId).then(subscriptionsMap => {
            this.subscriptionsMap = subscriptionsMap;
        }),
    );

    static readonly $inject: string[] = [
        $qSID,
        $routeParamsSID,
        MvClient.SID,
        InteractionUtils.SID,
        CurrencyService.SID,
        I18nService.SID,
    ];

    constructor(
        private readonly $q: IQService,
        $routeParams: IRouteParams,
        private readonly mvClient: MvClient,
        private readonly interactionUtils: InteractionUtils,
        private readonly currencyService: CurrencyService,
        private readonly i18n: I18nService,
    ) {
        this.clientId = ClientId.from(parseInt($routeParams.client_id, 10));
        void this.onInit();
    }

    onInit() {
        return this.$q.all([this.fetchLocations.run({}), this.fetchLocationSubscriptions.run({})]);
    }

    getSubscriptionDisplay(locationId: LocationId) {
        if (this.subscriptionsMap) {
            const subscription = this.subscriptionsMap[locationId];
            if (subscription) {
                const amount = this.currencyService.getLargestUnit(subscription.currency, subscription.amountInCents);
                return this.i18n.formatCurrency(amount, { format: 'code', code: subscription.currency });
            }
        }
        return 'None';
    }

    isSubscriptionActive(locationId: LocationId): boolean {
        if (!this.subscriptionsMap) {
            return false;
        }
        return this.subscriptionsMap[locationId].isActive;
    }
}

angular.module('app').controller(MvSubscriptionLocationListCtrl.SID, MvSubscriptionLocationListCtrl);
