import { gql } from '@apollo/client';
import { ActionMenu, DSDialog, DSTableCell, DSTableRow, DSTypography, Translate } from '@deltasierra/components';
import { useToggle } from '@deltasierra/react-hooks';
import * as React from 'react';
import styled from 'styled-components';
import { t } from '@deltasierra/shared';
import { HashtagSetDialogContent } from '../HashtagSetDialogContent';
import { HashtagSetFragmentForTableRow } from './__graphqlTypes/HashtagSetFragmentForTableRow';
import { useHashtagRowMutations } from './use-hashtag-row-mutations';

const hashtagSetFragment = gql`
    fragment HashtagSetFragmentForTableRow on HashtagSet {
        id
        ...HashtagSetFragmentForDialogContent
        ... on ClientHashtagSet {
            description
            title
            hashtags
            isCore
        }
        ... on LocationHashtagSet {
            description
            title
            hashtags
        }
    }
    ${HashtagSetDialogContent.fragments.hashtagSet}
`;

const DescriptionCell = styled(DSTableCell)`
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const ClickableText = styled(DSTypography).attrs({
    color: 'primary',
    tabIndex: 0,
})`
    max-width: 300px;
    font-size: 1rem;
    color: ${({ theme }) => theme.palette.primary.main};
    cursor: pointer;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export interface HashtagSetsTableRowProps {
    hashtagSet: HashtagSetFragmentForTableRow;
}

export function HashtagSetsTableRow({ hashtagSet }: HashtagSetsTableRowProps): JSX.Element {
    const [isUpdateDialogOpen, toggleIsUpdateDialogOpen] = useToggle();
    const { deleteHashtagSet, updateHashtagSet } = useHashtagRowMutations(
        hashtagSet.id,
        hashtagSet.__typename === 'ClientHashtagSet' ? 'client' : 'location',
    );
    const toggleOff = React.useCallback(() => toggleIsUpdateDialogOpen('toggleOff'), [toggleIsUpdateDialogOpen]);
    const toggleOn = React.useCallback(() => toggleIsUpdateDialogOpen('toggleOn'), [toggleIsUpdateDialogOpen]);

    return (
        <>
            <DSTableRow data-cy="hashtag-set-table-row">
                <DSTableCell>
                    <ClickableText onClick={() => toggleIsUpdateDialogOpen('toggleOn')}>
                        {hashtagSet.title}
                    </ClickableText>
                </DSTableCell>
                <DescriptionCell>{hashtagSet.description}</DescriptionCell>
                <DSTableCell align="right">{hashtagSet.hashtags.length}</DSTableCell>
                <DSTableCell align="right">
                    <ActionMenu aria-label={t('COMMON.ACTIONS')}>
                        <ActionMenu.Item
                            data-cy="hashtag-edit-button"
                            style={{ cursor: 'pointer' }}
                            onClick={toggleOn}
                        >
                            <Translate keyId="COMMON.EDIT" />
                        </ActionMenu.Item>
                        <ActionMenu.Item
                            data-cy="hashtag-delete-button"
                            style={{ cursor: 'pointer' }}
                            onClick={deleteHashtagSet}
                        >
                            <Translate keyId="COMMON.DELETE" />
                        </ActionMenu.Item>
                    </ActionMenu>
                </DSTableCell>
            </DSTableRow>
            <DSDialog open={isUpdateDialogOpen}>
                {isUpdateDialogOpen && (
                    <HashtagSetDialogContent
                        allowIsCore={hashtagSet.__typename === 'ClientHashtagSet'}
                        hashtagSet={hashtagSet}
                        headerText={t('AGENCY.HASHTAG_SETS.ACTIONS.UPDATE_HASHTAG_SET')}
                        submitButtonText={t('COMMON.UPDATE')}
                        onCancel={() => toggleIsUpdateDialogOpen('toggleOff')}
                        onSubmit={values => {
                            void updateHashtagSet(values);
                            toggleOff();
                        }}
                    />
                )}
            </DSDialog>
        </>
    );
}
HashtagSetsTableRow.displayName = 'HashtagSetsTableRow';
HashtagSetsTableRow.fragments = { hashtagSet: hashtagSetFragment };
