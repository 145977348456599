/* eslint-disable max-lines-per-function */
import {
    DisabledOverlay,
    DSButton,
    DSConfirmButton,
    DSDialog,
    DSDialogActions,
    DSDialogContent,
    DSDialogTitle,
    DSFormControlLabel,
    DSGrid,
    DSHidden,
    DSIcon,
    DSOutlinedInput,
    DSRadio,
    DSRadioGroup,
    Icon,
    Loading,
    Translate,
} from '@deltasierra/components';
import { t, AssignedLocation, BuilderTemplateId, BuilderType } from '@deltasierra/shared';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Formik, FormikErrors } from 'formik';
import * as React from 'react';
import styled from 'styled-components';
import { useAngularServiceContext } from '../../../../common/componentUtils/angularServiceContexts';
import { useDataRetrieval } from '../../../../common/futures';
import { ContentBuilder } from '../../../contentBuilder';
import { EmailBuilder } from '../../../email/emailBuilder';
import {
    SaveNewBuilderTemplateDraftMutationResponse,
    useSaveNewBuilderTemplateDraftMutation,
} from '../../../hooks/useSaveNewBuilderTemplateDraftMutation';
import { GetForUploadType } from '../../../mvContentBuilderCtrl';
import { SelectedBuilderTemplateDraft } from '../BuilderTemplateDraftPreview';

type SaveStyle = 'new' | 'overwrite';

type SaveModalFormData = {
    newTitle: string;
    saveStyle: SaveStyle;
};

const StyledDiv = styled.div.attrs({
    disabled: false,
})`
    padding: 10px 20px;
    position: relative;
    width: 100%;
`;

const StyledRevisionImg = styled.img`
    max-width: 100%;
    max-height: 256px;
    width: auto;
`;

const StyledDSRadioGroup = styled(DSRadioGroup)`
    margin-bottom: 0;
`;

const StyledImagePreviewContainer = styled(DSGrid).attrs({
    item: true,
    sm: true,
    xs: 12,
})`
    text-align: center;
`;

const StyledIcon = styled(Icon)`
    font-size: 100px;
`;

export interface BuilderTemplateDraftSaveModalProps {
    builderTemplateId: BuilderTemplateId;
    builderType: BuilderType;
    children?: React.ReactNode;
    contentBuilder: ContentBuilder | EmailBuilder;
    getForUpload: GetForUploadType;
    location: AssignedLocation;
    onClose: () => void;
    onSave: (response: SaveNewBuilderTemplateDraftMutationResponse | undefined) => void;
    show: boolean;
    currentDraft?: SelectedBuilderTemplateDraft;
}

export const BuilderTemplateDraftSaveModal: React.FC<BuilderTemplateDraftSaveModalProps> = ({
    builderType,
    children,
    contentBuilder,
    currentDraft,
    onClose,
    onSave,
    show,
    ...props
}) => {
    const mvNotify = useAngularServiceContext('mvNotifier');
    const $timeout = useAngularServiceContext('$timeout');
    const [saveNewBuilderTemplateDraft] = useSaveNewBuilderTemplateDraftMutation(onSave);

    const [previewImageFuture] = useDataRetrieval(async () => {
        if (show) {
            if (builderType === 'contentBuilder') {
                return $timeout(async () => {
                    const image = await (contentBuilder as ContentBuilder).exportAsImage();
                    return image.dataUrl;
                });
            }
            // TODO: Handle email template preview
            // Ticket: https://digitalstack.atlassian.net/browse/DS-6379
        }
        return undefined;
    }, [$timeout, builderType, contentBuilder, show]);

    const initialValues: SaveModalFormData = {
        newTitle: '',
        saveStyle: currentDraft ? 'overwrite' : 'new',
    };

    const canSave = (values: typeof initialValues) =>
        values.saveStyle === 'new' ? values.newTitle && values.newTitle.trim() !== '' : true;

    const handleSave = (values: typeof initialValues) => {
        if (!canSave(values)) {
            mvNotify.unexpectedError(t('ERRORS.LOCATION_DRAFT.CANNOT_SAVE_LOCATION_REVISION_WITHOUT_A_TITLE'));
        }

        if (values.saveStyle === 'new') {
            void saveNewBuilderTemplateDraft({
                ...props,
                title: values.newTitle,
            });
        } else {
            // We should not be able to hit this
            if (!currentDraft) {
                throw new Error('You cannot update a non-existant revision');
            }
            void saveNewBuilderTemplateDraft({
                ...props,
                existingDraftId: currentDraft.id,
                title: currentDraft.title,
            });
        }
        onClose();
    };

    const handleValidation = (values: typeof initialValues): FormikErrors<typeof initialValues> => {
        const errors: FormikErrors<typeof initialValues> = {};
        if (!canSave(values)) {
            errors.newTitle = t('COMMON.VALIDATION.REQUIRED');
        }
        return errors;
    };

    return (
        <DSDialog open={show} onClose={onClose}>
            <Formik<typeof initialValues>
                initialValues={initialValues}
                validate={handleValidation}
                validateOnChange
                validateOnMount
                onSubmit={handleSave}
            >
                {formik => (
                    <>
                        <DSDialogTitle>
                            {t('BUILD.LOCATION_DRAFT.SAVE_LOCATION_REVISION_WITH_TITLE', {
                                title: props.location.title,
                            })}
                        </DSDialogTitle>
                        <DSDialogContent withoutMinWidth>
                            <p>
                                {`${t('BUILD.LOCATION_DRAFT.PLEASE_CONFIRM_LOCATION_REVISION_SAVE')} `}
                                <b>{props.location.title}</b>
                                {'.'}
                            </p>
                            {currentDraft && (
                                <StyledDSRadioGroup
                                    value={formik.values.saveStyle}
                                    onChange={newStyle =>
                                        formik.setFieldValue('saveStyle', newStyle.target.value as SaveStyle)
                                    }
                                >
                                    <DSFormControlLabel
                                        control={<DSRadio />}
                                        label={t('BUILD.LOCATION_DRAFT.SAVE_OVER_CURRENT_REVISION_WITH_TITLE', {
                                            title: currentDraft.title,
                                        })}
                                        value="overwrite"
                                    />
                                    <StyledDiv>
                                        <DSGrid alignItems="center" container justifyContent="center" spacing={1}>
                                            <StyledImagePreviewContainer>
                                                {currentDraft.thumbnailUrl ? (
                                                    <StyledRevisionImg src={currentDraft.thumbnailUrl} />
                                                ) : (
                                                    <StyledRevisionImg
                                                        src={'/img/thumbnail-other.svg'}
                                                        title={'fallback'}
                                                    />
                                                )}
                                            </StyledImagePreviewContainer>
                                            <DSHidden xsDown>
                                                <DSGrid item xs="auto">
                                                    <ArrowForwardIcon />
                                                </DSGrid>
                                            </DSHidden>
                                            <DSHidden smUp>
                                                <DSGrid item xs="auto">
                                                    <ArrowDownwardIcon />
                                                </DSGrid>
                                            </DSHidden>
                                            {builderType === 'contentBuilder' && (
                                                <StyledImagePreviewContainer>
                                                    {previewImageFuture.isLoading && <Loading />}
                                                    {previewImageFuture.isFinished && (
                                                        <StyledRevisionImg src={previewImageFuture.value ?? ''} />
                                                    )}
                                                </StyledImagePreviewContainer>
                                            )}
                                            {builderType === 'emailBuilder' && (
                                                <StyledImagePreviewContainer>
                                                    <DSIcon color="primary" fontSize="large">
                                                        <StyledIcon icon="document" />
                                                    </DSIcon>
                                                </StyledImagePreviewContainer>
                                            )}
                                        </DSGrid>
                                        {formik.values.saveStyle !== 'overwrite' && <DisabledOverlay />}
                                    </StyledDiv>
                                    <DSFormControlLabel
                                        control={<DSRadio />}
                                        label={t('BUILD.LOCATION_DRAFT.SAVE_AS_A_NEW_REVISION')}
                                        value="new"
                                    />
                                </StyledDSRadioGroup>
                            )}
                            <StyledDiv>
                                <DSOutlinedInput
                                    disabled={formik.values.saveStyle !== 'new'}
                                    fullWidth
                                    placeholder={t('BUILD.LOCATION_DRAFT.ENTER_NAME_FOR_NEW_TEMPLATE_REVISION')}
                                    onChange={event => formik.setFieldValue('newTitle', event.target.value)}
                                />
                                {formik.values.saveStyle !== 'new' && <DisabledOverlay />}
                            </StyledDiv>
                        </DSDialogContent>
                        <DSDialogActions>
                            <DSButton color="primary" variant="outlined" onClick={onClose}>
                                <Translate keyId="COMMON.CANCEL" />
                            </DSButton>
                            {formik.values.saveStyle === 'new' && (
                                <DSButton
                                    color="primary"
                                    disabled={!formik.isValid || formik.isSubmitting}
                                    onClick={() => formik.handleSubmit()}
                                >
                                    <Translate keyId="COMMON.SAVE" />
                                </DSButton>
                            )}
                            {formik.values.saveStyle === 'overwrite' && currentDraft && (
                                <DSConfirmButton
                                    color="primary"
                                    dialogConfirmDisabled={formik.isSubmitting}
                                    disabled={!formik.isValid || formik.isSubmitting}
                                    message={t(
                                        'BUILD.LOCATION_DRAFT.ARE_YOU_SURE_YOU_WANT_TO_OVERRIDE_TEMPLATE_REVISION_WITH_TITLE',
                                        { title: currentDraft.title },
                                    )}
                                    onConfirm={formik.handleSubmit}
                                >
                                    <Translate keyId="COMMON.SAVE" />
                                </DSConfirmButton>
                            )}
                        </DSDialogActions>
                    </>
                )}
            </Formik>
        </DSDialog>
    );
};
BuilderTemplateDraftSaveModal.displayName = 'BuilderTemplateDraftSaveModal';
