/// <reference path="../../../../typings/browser.d.ts" />
import {
    AssignedLocation,
    BuilderTemplateGalleryDto,
    ClientId,
    GetSimpleAutomatedEmailTemplatesResponse,
    CLIENTS_TO_DISABLE_AUTOMATED_EMAILS,
    linkToAutomatedEmail,
    linkToTemplate,
    LocationId,
} from '@deltasierra/shared';
import { MvIdentity } from '../../account/mvIdentity';
import { $qSID, $routeParamsSID, $scopeSID, $timeoutSID, IRouteParams } from '../../common/angularData';
import { Debouncer } from '../../common/Debouncer';
import { InteractionUtils } from '../../common/interactionUtils';
import { SentryService } from '../../common/sentryService';
import { GraphqlService } from '../../graphql/GraphqlService';
import { I18nService } from '../../i18n';
import { IntroDataService } from '../../intro/introDataService';
import { IntroService } from '../../intro/introService';
import { GalleryQueryParams } from '../../templateGallery/mvBuilderTemplateGalleryCtrl';
import { TemplateGroup, TemplateGroupTypeEnum } from '../../templateGallery/templateGroup';
import { BuilderTemplateApiClient } from '../builderTemplateApiClient';
import { mvBuilderTemplateFormatResourceSID, MvBuilderTemplateFormatResource } from '../mvBuilderTemplateFormatResource';
import { MemberEngagerApiClient } from './memberEngagerApiClient';
import IQService = angular.IQService;
import ITimeoutService = angular.ITimeoutService;
import IScope = angular.IScope;

export class MemberEngagerCtrl {
    public static readonly SID = 'MemberEngagerCtrl';

    public static readonly $inject: string[] = [
        $scopeSID,
        $qSID,
        $timeoutSID,
        $routeParamsSID,
        InteractionUtils.SID,
        I18nService.SID,
        BuilderTemplateApiClient.SID,
        IntroService.SID,
        IntroDataService.SID,
        MemberEngagerApiClient.SID,
        GraphqlService.SID,
        SentryService.SID,
        mvBuilderTemplateFormatResourceSID,
        MvIdentity.SID,
    ];

    public location?: AssignedLocation;

    public directEmailGroup?: TemplateGroup;

    public automatedEmails?: GetSimpleAutomatedEmailTemplatesResponse[];

    public keywordSearch?: string;

    public linkToAutomatedEmail = linkToAutomatedEmail;

    public automatedEmailCode?: string;

    public searchDebouncer = new Debouncer<
        any,
        {
            clientId: ClientId;
            directEmailFormatId: number;
            keywordSearch: string | undefined;
        }
    >(
        this.$timeout,
        this.$q,
        this.i18nService.text.common.search(),
        context =>
            this.$q.resolve(
                this.onCriteriaChange(context.clientId, context.directEmailFormatId, context.keywordSearch),
            ),
        1000,
    );

    public fetchFitwareDirectEmailFormat = this.interactionUtils.createFuture(
        'fetch Fitware direct e-mail format',
        _context =>
            this.memberEngageApiClient.getFitwareDirectEmailFormat().then(result => {
                this.directEmailFormatId = result.builderTemplateFormatId;
                void this.maybeTriggerFetchTemplates(); // Don't include in this promise chain.
            }),
    );

    public fetchFitwareAutomatedEmails = this.interactionUtils.createFuture<void, { locationId: LocationId }>(
        'fetch Fitware automated e-mails',
        context =>
            this.memberEngageApiClient.getFitwareAutomatedEmails(context.locationId).then(result => {
                this.automatedEmails = result;
            }),
        {
            onError: err => {

                console.log((err && err.data) || err);
                // Swallow error. Do not use the notifier.
            },
        },
    );

    private directEmailFormatId?: number;


    public constructor(
        protected readonly $scope: IScope,
        protected readonly $q: IQService,
        protected readonly $timeout: ITimeoutService,
        protected readonly $routeParams: IRouteParams,
        protected readonly interactionUtils: InteractionUtils,
        protected readonly i18nService: I18nService,
        protected readonly builderTemplateApiClient: BuilderTemplateApiClient,
        protected readonly introService: IntroService,
        protected readonly introDataService: IntroDataService,
        protected readonly memberEngageApiClient: MemberEngagerApiClient,
        private readonly graphqlService: GraphqlService,
        private readonly sentryService: SentryService,
        private readonly mvBuilderTemplateFormatResource: MvBuilderTemplateFormatResource,
        public readonly identity: MvIdentity,
    ) {
        this.$scope.$watch(
            () => this.keywordSearch,
            async (newValue, oldValue): Promise<void> => {
                if (newValue !== oldValue) {
                    return this.maybeTriggerFetchTemplates();
                }
                return Promise.resolve();
            },
        );
        void this.fetchFitwareDirectEmailFormat.run({});
        if ($routeParams.automatedEmailCode) {
            this.automatedEmailCode = $routeParams.automatedEmailCode;
        }
    }

    public updateLocation(location: AssignedLocation): void {
        const oldClientId = this.location ? this.location.clientId : undefined;
        this.location = location;
        if (this.shouldShowAutomatedEmails()) {
            void this.fetchFitwareAutomatedEmails.run({
                locationId: this.location.id,
            });
        }
        // Only re-fetch templates if the client ID changes, and we already know the direct e-mail template format.
        //  (Fetching templates could trigger before or after the location/directEmailFormatId has loaded.)
        if (oldClientId !== location.clientId) {
            void this.maybeTriggerFetchTemplates();
        }
    }

    public getQueryParamsForGroup(
        group: TemplateGroup,
        clientId: ClientId,
        keywordSearch: string | undefined,
    ): GalleryQueryParams {
        let formats: number[] | undefined;
        switch (group.groupType.type) {
            case TemplateGroupTypeEnum.Format:
                formats = [group.groupType.formatId];
                break;
            default:
                throw new Error(`Unexpected template group type: ${TemplateGroupTypeEnum[group.groupType.type]}`);
        }
        const queryParams: GalleryQueryParams = {
            client: clientId,
            formats,
            limit: 10,
        };
        if (keywordSearch) {
            queryParams.tags = keywordSearch;
        }
        return queryParams;
    }

    public lastChildInViewCallback(group: TemplateGroup): void {
        if (!group.allLoaded && this.location && this.directEmailFormatId) {
            void group.loadMore(this.getQueryParamsForGroup(group, this.location.clientId, this.keywordSearch));
        }
    }

    public shouldShowGroup(group: TemplateGroup): boolean {
        switch (group.groupType.type) {
            case TemplateGroupTypeEnum.Format:
                return (
                    group.fetchTemplates.isPending() || group.fetchTemplates.isRunning() || group.templates.length > 0
                );
            default:
                return false;
        }
    }

    public clickSearch(): ng.IPromise<void> {
        return this.maybeTriggerFetchTemplates();
    }

    public clickRetryLoadingAutomatedEmails(): ng.IPromise<void> {
        if (this.location) {
            return this.fetchFitwareAutomatedEmails.run({
                locationId: this.location.id,
            });
        }
        return Promise.resolve();
    }

    public shouldShowAutomatedEmails(): boolean {
        return !this.automatedEmailCode && !this.shouldHideAutomatedEmailsForClient();
    }

    public linkToTemplate(template: BuilderTemplateGalleryDto): string {
        return `/memberEngager${linkToTemplate(
            template.documentFormat,
            template.id,
            undefined,
            this.automatedEmailCode,
        )}`;
    }

    /**
     * DS-2637: fetching templates is triggered under (at least) three conditions:
     *  - The Location has loaded
     *  - The direct e-mail format ID has loaded
     *  - The user has entered a search criteria
     *
     *  However, the template fetching _always_ requires the Location and direct e-mail format ID to have loaded before
     *  fetching anything. This method helps guard against fetch invocations when the data hasn't finished loading.
     *
     * @returns Promise<void>
     */
    protected maybeTriggerFetchTemplates(): ng.IPromise<void> {
        if (this.location && this.directEmailFormatId) {
            return this.searchDebouncer.run({
                clientId: this.location.clientId,
                directEmailFormatId: this.directEmailFormatId,
                keywordSearch: this.keywordSearch,
            });
        } else {
            return this.$q.resolve();
        }
    }

    protected createDirectEmailTemplateGroup(builderTemplateFormatId: number): TemplateGroup {
        return new TemplateGroup(
            this.$q,
            this.interactionUtils,
            this.i18nService,
            this.builderTemplateApiClient,
            this.introService,
            this.introDataService,
            this.graphqlService,
            this.sentryService,
            this.mvBuilderTemplateFormatResource,
            this.identity,
            this.i18nService.text.build.memberEngager.directEmailTitle(),
            {
                formatId: builderTemplateFormatId,
                type: TemplateGroupTypeEnum.Format,
            },
            this.location,
        );
    }

    protected onCriteriaChange(
        clientId: ClientId,
        directEmailFormatId: number,
        keywordSearch: string | undefined,
    ): angular.IPromise<void> | void {
        // Reset the group by recreating it.
        this.directEmailGroup = this.createDirectEmailTemplateGroup(directEmailFormatId);
        return this.directEmailGroup.loadMore(
            this.getQueryParamsForGroup(this.directEmailGroup, clientId, keywordSearch),
        );
    }

    // Check list of hardcoded Client IDs to disable Member Engager automated emails for. https://digitalstack.atlassian.net/browse/DPS-471
    private shouldHideAutomatedEmailsForClient(): boolean {
        return !!this.location && CLIENTS_TO_DISABLE_AUTOMATED_EMAILS.includes(this.location.clientId);
    }
}

angular.module('app').controller(MemberEngagerCtrl.SID, MemberEngagerCtrl);
