import { gql } from '@apollo/client';

export const DUPLICATE_TEMPLATE = gql`
    mutation DuplicateTemplate($input: DuplicateTemplateInput!) {
        duplicateTemplate(input: $input) {
            ... on DuplicateTemplateSuccess {
                buildableTemplate {
                    id
                    title
                    status
                    templateType
                    categories {
                        id
                        title
                    }
                    tags {
                        id
                        title
                    }
                    ... on BuildableEmailTemplate {
                        currentVersion {
                            id
                            thumbnailUrl
                            createdAt
                        }
                    }
                }
                __typename
            }
            ... on DuplicateTemplateError {
                code
                message
            }
            __typename
        }
    }
`;
