import { PublishToClubReadyStep1Data } from '../../../../../PublishToClubReadyStep1/types/publish-to-club-ready-step-1-data';
import { getAddedBetweenRange, getActivityPeriod } from '../../../../utils';
import { GenerateClubReadyListSummaryMutationVariables } from '../__graphqlTypes/GenerateClubReadyListSummaryMutation';

export function getUserCountQueryVariables(
    locationIds: string[],
    step1Data: PublishToClubReadyStep1Data,
): GenerateClubReadyListSummaryMutationVariables {
    return {
        active: {
            fetch: step1Data.filterType === 'filter' && step1Data.active.send,
            options:
                step1Data.filterType === 'filter'
                    ? {
                          activityPeriod: getActivityPeriod(step1Data.active),
                      }
                    : undefined,
        },
        cooledOffProspects: {
            fetch: step1Data.filterType === 'filter' && step1Data.cooledOffProspects.send,
        },
        custom: {
            fetch: step1Data.filterType === 'emails',
            options:
                step1Data.filterType === 'emails'
                    ? {
                          emailListUploadId: step1Data.emailListUploadId ?? '',
                      }
                    : undefined,
        },
        inactive: {
            fetch: step1Data.filterType === 'filter' && step1Data.inactive.send,
            options:
                step1Data.filterType === 'filter'
                    ? {
                          activityPeriod: getActivityPeriod(step1Data.inactive),
                      }
                    : undefined,
        },
        locationIds,
        prospects: {
            fetch: step1Data.filterType === 'filter' && step1Data.prospects.send,
            options:
                step1Data.filterType === 'filter'
                    ? {
                          addedBetweenRange: getAddedBetweenRange(step1Data.prospects),
                      }
                    : undefined,
        },
    };
}
