import { gql, useQuery } from '@apollo/client';
import {
    GetPostArtifactForModalsQuery,
    GetPostArtifactForModalsQuery_postArtifact_PostArtifact,
    GetPostArtifactForModalsQueryVariables,
} from './__graphqlTypes/GetPostArtifactForModalsQuery';

// eslint-disable-next-line camelcase
export type GetPostArtifactForModalsQueryPostArtifact = GetPostArtifactForModalsQuery_postArtifact_PostArtifact;

const GET_POST_ARTIFACT_FOR_MODALS_QUERY = gql`
    query GetPostArtifactForModalsQuery($id: ID!) {
        postArtifact(id: $id) {
            __typename
            ... on PostArtifactNotFoundError {
                code
                message
            }
            ... on PostArtifact {
                id
                status {
                    state
                }
                content {
                    caption
                    firstComment
                }
                location {
                    __typename
                    id
                    title
                }
                meta {
                    ... on InstagramPostArtifactMeta {
                        isStory
                    }
                    ... on ClubReadyPostArtifactMeta {
                        scheduledWorkflowType
                    }
                }
                platform {
                    __typename
                    id
                    displayName
                    name
                }
                subChannel
                timestamps {
                    __typename
                    scheduled
                    deleted
                    created
                }
                thumbnails {
                    medium {
                        url
                    }
                }
                parent {
                    __typename
                    id
                    locationCount
                    locations {
                        edges {
                            node {
                                id
                                title
                            }
                        }
                    }
                    postArtifacts {
                        __typename
                        edges {
                            node {
                                id
                                location {
                                    id
                                }
                            }
                        }
                    }
                }
                type
            }
        }
    }
`;

export function usePostArtifact(postId: string): GetPostArtifactForModalsQueryPostArtifact | null {
    const { data, loading } = useQuery<GetPostArtifactForModalsQuery, GetPostArtifactForModalsQueryVariables>(
        GET_POST_ARTIFACT_FOR_MODALS_QUERY,
        {
            variables: { id: postId },
        },
    );

    return !loading && data?.postArtifact.__typename === 'PostArtifact' ? data.postArtifact : null;
}
