import { DSTextField, DSTextFieldProps } from '@deltasierra/components';
import { KeysOfTypeLoose } from '@deltasierra/type-utilities';
import { FormikProps, useField } from 'formik';
import React from 'react';

type DSTextFieldFormikKeys<T> = Exclude<KeysOfTypeLoose<T, string>, undefined>;

export type DSTextFieldFormikProps<T> = Omit<DSTextFieldProps, 'onBlur' | 'onChange' | 'value'> & {
    formik?: FormikProps<T>;
    name: DSTextFieldFormikKeys<T>;
};

export function DSTextFieldFormik<T>({
    /**
     * We pass formik through for type safety only
     */
    formik: _formik,
    name,
    ...props
}: DSTextFieldFormikProps<T>): JSX.Element {
    const [field, meta] = useField(name);

    const showError = !!meta.error && meta.touched;

    const helperText = (showError ? meta.error : undefined) ?? props.helperText;

    return (
        <DSTextField
            {...props}
            error={showError || props.error}
            helperText={helperText}
            name={field.name}
            value={field.value}
            onBlur={field.onBlur}
            onChange={field.onChange}
        />
    );
}
DSTextFieldFormik.displayName = 'DSTextFieldFormik';
