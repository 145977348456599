import { isValidUrl, translate } from '@deltasierra/shared';
import * as React from 'react';
import { Translate, TranslateProps } from '../../directives/Translate';
import { RequiredFieldMarker } from './helpers';

export type TextInputProps = {
    disabled?: boolean;
    label: TranslateProps;
    onChange: (newValue: string) => void;
    required?: boolean;
    type?: string;
    value: string;
    errorMessage?: string;
};

export const TextInput: React.FunctionComponent<TextInputProps> = ({
    disabled = false,
    errorMessage,
    label,
    onChange,
    required = false,
    type = 'text',
    value,
}) => (
    <div className={`form-group ${errorMessage ? 'has-error' : ''}`}>
        <label>
            <Translate {...label} /> {required && <RequiredFieldMarker />}
        </label>
        <input
            className="form-control"
            disabled={disabled}
            required={required}
            type={type}
            value={value}
            onChange={event => onChange(event.target.value)}
        />
        {!!errorMessage && <p className="help-block">{errorMessage}</p>}
    </div>
);

TextInput.displayName = 'TextInput';

export const UrlInput: React.FunctionComponent<Exclude<TextInputProps, 'type'>> = ({
    disabled = false,
    label,
    onChange,
    required = false,
    value,
}) => {
    const [hasTouched, setHasTouched] = React.useState(false);
    const hasError = isInvalidUrl(value, required);
    const placeholder = 'https://www.example.com';

    const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        setHasTouched(true);
        const newUrl = event.target.value;
        const withHttp = addHttpIfMissing(newUrl);

        if (withHttp !== newUrl) {
            onChange(withHttp);
        }
    };

    return (
        <Label
            error={hasError && hasTouched}
            errorMessage={translate('ERRORS.INVALID_INPUT_ERRORS.INVALID_URL')}
            label={translate(label.keyId, label.options)}
            required={required}
        >
            <input
                className="form-control"
                disabled={disabled}
                placeholder={disabled ? '' : placeholder}
                required={required}
                type="url"
                value={value}
                onBlur={onBlur}
                onChange={event => onChange(event.target.value)}
            />
        </Label>
    );
};

UrlInput.displayName = 'UrlInput';

function addHttpIfMissing(url: string): string {
    return !url.match(/^https?:\/\//) && url ? `http://${url}` : url;
}

function isInvalidUrl(url: string, required: boolean): boolean {
    // URL is considered valid if it's not required and empty
    if (!required && !url) {
        return false;
    }
    return !isValidUrl(url);
}

type LabelProps = {
    children: React.ReactNode;
    error: boolean;
    errorMessage: string;
    label: string;
    required: boolean;
};

const Label: React.FunctionComponent<LabelProps> = ({ children, error, errorMessage, label, required }) => (
    <div className={`form-group ${error ? 'has-error' : ''} `}>
        <label>
            {label} {required && <RequiredFieldMarker />}
        </label>
        {children}
        {error && <span className="help-block">{errorMessage || 'Invalid value'}</span>}
    </div>
);
