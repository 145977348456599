import { coerceServerErrorReasonToString } from '@deltasierra/shared';
import { SegmentKey, translate, TranslateOptions } from '@deltasierra/i18n';

import { isRecordType } from '@deltasierra/type-utilities';

export interface LoggingBypassableErrorOptions {
    bypassLogging?: boolean;
}

export interface LoggingBypassableError {
    bypassLogging: boolean;
}

export class UserError extends Error implements LoggingBypassableError {
    public bypassLogging: boolean;

    public constructor(message: string, options?: LoggingBypassableErrorOptions) {
        super(message);

        // User errors bypass logs by default
        this.bypassLogging = options && options.bypassLogging !== undefined ? options.bypassLogging : true;
    }
}

export interface ITranslatableError {
    key: SegmentKey;
    translateOptions?: TranslateOptions;
    isTranslatable: true;
}

export class TranslatableError extends Error implements LoggingBypassableError, ITranslatableError {
    public bypassLogging: boolean;

    public readonly isTranslatable = true;

    public constructor(
        public key: SegmentKey,
        public translateOptions?: TranslateOptions,
        options?: LoggingBypassableErrorOptions,
    ) {
        super(
            translate(key, {
                localeCode: 'en-AU',
            }),
        );
        this.bypassLogging = options && options.bypassLogging !== undefined ? options.bypassLogging : false;
    }
}

export function isTranslatableError(err: any): err is TranslatableError {
    // Can't seem to rely on instanceof
    // And hacks I tried for TS2.1 didn't work 😫
    // Doing this to save time...
    return err && err.isTranslatable && err.key;
}

export function tryExtractMessage(data?: unknown): string {
    let message = '';
    const newData = isRecordType(data) && data?.data ? data.data : data;
    if (isRecordType(newData)) {
        if (newData.message && typeof newData.message === 'string') {
            message += newData.message;
        } else if (newData.reason) {
            message += coerceServerErrorReasonToString(newData.reason as any);
        } else if (isRecordType(newData.error) && newData.error.message && typeof newData.error.message === 'string') {
            message += newData.error.message;
        }
    } else if (typeof newData === 'string') {
        message += newData;
    }
    return message;
}
