import { gql, useQuery } from '@apollo/client';
import { relayConnectionToArray } from '../../../../graphql/utils';
import {
    GetInstagramPermissionsForLocation,
    GetInstagramPermissionsForLocationVariables,
} from './__graphqlTypes/GetInstagramPermissionsForLocation';

const GET_INSTAGRAM_PERMISSIONS_FOR_LOCATION = gql`
    query GetInstagramPermissionsForLocation($ids: [ID!]!) {
        locations(ids: $ids) {
            edges {
                node {
                    id
                    instagram {
                        ... on Instagram {
                            id
                            hasFirstCommentPermissions
                        }
                    }
                }
            }
        }
    }
`;

export type CheckInstagramFirstCommentPermissionsResult = {
    loading: false;
    hasAtLeastOneWithPermission: boolean;
    areSomeWithoutPermission: boolean;
};

export type CheckInstagramFirstCommentPermissionsLoading = {
    loading: true;
};

export function useCheckInstagramFirstCommentPermissions(
    locationIds: string[],
): CheckInstagramFirstCommentPermissionsLoading | CheckInstagramFirstCommentPermissionsResult {
    const { data, loading } = useQuery<GetInstagramPermissionsForLocation, GetInstagramPermissionsForLocationVariables>(
        GET_INSTAGRAM_PERMISSIONS_FOR_LOCATION,
        { fetchPolicy: 'network-only', variables: { ids: locationIds } },
    );

    if (loading) {
        return { loading: true };
    } else {
        const grantedPermissions = relayConnectionToArray(data?.locations)
            .map(location => location.instagram)
            .map(instagram => (instagram.__typename === 'Instagram' ? instagram.hasFirstCommentPermissions : false))
            .filter((hasPermission): hasPermission is true => hasPermission);
        return {
            areSomeWithoutPermission: grantedPermissions.length !== locationIds.length,
            hasAtLeastOneWithPermission: grantedPermissions.length > 0,
            loading: false,
        };
    }
}
