import { translate } from '@deltasierra/shared';

export const agencyTabNavEntries = [
    {
        label: () => translate('COMMON.CLIENTS'),
        path: '/agency/clients',
    },
    {
        label: () => translate('AGENCY.USERS.CLIENT_USERS'),
        path: '/agency/clientUsers',
    },
    {
        label: () => translate('AGENCY.CONTACT_DETAILS.CONTACT_DETAILS'),
        path: '/agency/contactDetails',
    },
    {
        label: () => translate('COMMON.NOTIFICATIONS'),
        path: '/agency/notifications',
    },
    {
        label: () => translate('AGENCY.PRINT_SKU_GROUPS.PRINT_SKU_GROUPS'),
        path: '/agency/printProviderSKUGroups',
    },

];
