import { gql } from '@apollo/client';

export const GET_POST_ARTIFACT_GROUP = gql`
    query GetPostArtifactGroup($id: ID!) {
        postArtifact(id: $id) {
            __typename
            ... on PostArtifact {
                id
                location {
                    id
                    title
                }
                timestamps {
                    scheduled
                }
                parent {
                    id
                    postArtifacts {
                        edges {
                            node {
                                id
                                location {
                                    id
                                    title
                                }
                                timestamps {
                                    scheduled
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
