/// <reference path="../../../_references.d.ts" />
import { simpleComponent, StringBinding, TwoWayBinding } from '../../../common/angularData';
import { ConnectPrintController } from './connectPrintCtrl';

export const dsConnectPrintSID = 'dsConnectPrint';
export const dsConnectPrint: ng.IDirective<ng.IScope> = simpleComponent(
    ConnectPrintController,
    '/partials/integration/auth/print/connectPrint',
    {
        model: TwoWayBinding,
        type: StringBinding,
    },
);

angular.module('app').directive(dsConnectPrintSID, [() => dsConnectPrint]);
