/// <reference path="../../../typings/browser.d.ts" />
import { $scopeSID, $windowSID } from '../common/angularData';
import IWindowService = angular.IWindowService;

/**
 * Assigns windowWidth to the current scope.
 * Can be used in conjunction with "ng-if" to provide width-responsive behaviour.
 *
 * @param $window
 * @returns An angular directive
 */
angular.module('app').directive('windowWidth', [
    $windowSID,
    function ($window: IWindowService) {
        interface WindowWidthScope extends ng.IScope {
            windowWidth: number;
        }

        return {
            restrict: 'EAM',
            priority: 700, // Must be higher than ng-if, which is 600
            controller: [
                $scopeSID,
                function ($scope: WindowWidthScope) {
                    $scope.windowWidth = $window.innerWidth;
                    angular.element($window).bind('resize', () => {
                        $scope.$apply(() => {
                            $scope.windowWidth = $window.innerWidth;
                        });
                    });
                },
            ],
        };
    },
]);
