/// <reference path="../../../../typings/browser.d.ts" />
import { AssignedLocation, ImageLayer, ImageSection, Upload, VideoLayer } from '@deltasierra/shared';
import { IQService } from 'angular';
import { MvIdentity } from '../../account/mvIdentity';
import { $qSID } from '../../common/angularData';
import { $modalInstanceSID } from '../../common/angularUIBootstrapData';
import { DataUtils } from '../../common/dataUtils';
import { MvNotifier } from '../../common/mvNotifier';
import { UploadService } from '../../common/uploadService';
import { I18nService } from '../../i18n/i18nService';
import { ModalInstance } from '../../typings/angularUIBootstrap/modalService';
import IPromise = angular.IPromise;

const CHUNK_SIZE = 2;

type ResourceTarget = ImageLayer | ImageSection | VideoLayer;
type OnSelectLocalImageCallback = (file: File, layer?: ResourceTarget) => IPromise<void> | void;
type OnSelectPlannerImageCallback = (plannerImage: Upload, layer?: ResourceTarget) => IPromise<void> | void;
type OnSelectLocationLogoCallback = (plannerImage: Upload, layer?: ResourceTarget) => IPromise<void> | void;
type Callbacks = {
    onSelectLocalImage: OnSelectLocalImageCallback;
    onSelectPlannerImage: OnSelectPlannerImageCallback;
    onSelectLocationLogo?: OnSelectLocationLogoCallback;
};

export interface ImageChooserLocals {
    acceptedTypes: () => 'image' | 'video';
    callbacks: () => Callbacks;
    plannerImages: () => Upload[] | null | undefined;
    optionalExistingObject: () => ResourceTarget | undefined;
    location: () => AssignedLocation | null;
}

export class ContentBuilderResourceChooserController {
    static SID = 'ContentBuilderResourceChooserController';

    chunkedImages: Upload[][];

    isPermissionsToUseImageConfirmed = false;

    validationMessage: string | undefined;

    static readonly $inject: string[] = [
        $qSID,
        $modalInstanceSID,
        MvIdentity.SID,
        MvNotifier.SID,
        DataUtils.SID,
        UploadService.SID,
        I18nService.SID,
        <keyof ImageChooserLocals>'acceptedTypes',
        <keyof ImageChooserLocals>'callbacks',
        <keyof ImageChooserLocals>'plannerImages',
        <keyof ImageChooserLocals>'optionalExistingObject',
        <keyof ImageChooserLocals>'location',
    ];

    constructor(
        protected readonly $q: IQService,
        protected readonly $modalInstance: ModalInstance,
        protected readonly mvIdentity: MvIdentity,
        protected readonly mvNotifier: MvNotifier,
        protected readonly dataUtils: DataUtils,
        protected readonly uploadService: UploadService,
        protected readonly i18nService: I18nService,
        protected readonly acceptedTypes: 'image' | 'video',
        protected readonly callbacks: Callbacks,
        plannerImages: Upload[] | null | undefined,
        protected readonly optionalExistingObject: ResourceTarget | undefined,
        public readonly location: AssignedLocation,
    ) {
        this.chunkedImages = dataUtils.chunk(CHUNK_SIZE, plannerImages);
    }

    cancel(): void {
        this.$modalInstance.dismiss();
    }

    selectLocalImage(files: File[]): IPromise<void> {
        return this.uploadService
            .checkFileSize(files[0])
            .then((): IPromise<void> => {
                if (!this.isPermissionsToUseImageConfirmed) {
                    throw this.i18nService.text.build.imageChooser.permissionError();
                }

                if (files.length != 1) {
                    throw this.i18nService.text.build.imageChooser.maxUploadError();
                }

                return this.$q.resolve(this.callbacks.onSelectLocalImage(files[0], this.optionalExistingObject));
            })
            .then((): void => {
                this.$modalInstance.dismiss();
            })
            .catch(err => {
                let error: string;
                if (err) {
                    if (err.message) {
                        error = err.message;
                    } else {
                        error = String(err);
                    }
                } else {
                    error = String(new Error());
                }
                this.validationMessage = error;
            });
    }

    selectPlannerImage(plannerImage: Upload): IPromise<void> | void {
        if (!this.isPermissionsToUseImageConfirmed) {
            this.mvNotifier.expectedError(this.i18nService.text.build.imageChooser.permissionError());
            return;
        }
        return this.$q
            .resolve(this.callbacks.onSelectPlannerImage(plannerImage, this.optionalExistingObject))
            .finally(() => this.$modalInstance.dismiss());
    }

    getUploadGlyph(plannerImage: Upload): string {
        return this.uploadService.getUploadGlyph(plannerImage.ext);
    }

    selectLocationLogo(image: Upload): IPromise<void> | void {
        if (!this.isPermissionsToUseImageConfirmed) {
            this.mvNotifier.expectedError(this.i18nService.text.build.imageChooser.permissionError());
            return;
        }

        if (this.callbacks.onSelectLocationLogo) {
            return this.$q
                .resolve(this.callbacks.onSelectLocationLogo(image, this.optionalExistingObject))
                .finally(() => this.$modalInstance.dismiss());
        }
        // TODO: would the callback ever be undefined if we're in here?
    }

    canChooseLocationLogo(): boolean {
        return (
            !!this.callbacks.onSelectLocationLogo && // The consuming builder code can support location logos
            (this.mvIdentity.isManager() || // Designers can assign "placeholders" for location logos
                !!this.location.logo) // We allow client users to select any existing location logo
        );
    }
}

angular.module('app').controller(ContentBuilderResourceChooserController.SID, ContentBuilderResourceChooserController);
