import { useLazyQuery } from '@apollo/client';
import * as React from 'react';
import { relayConnectionToArray } from '../../../../graphql/utils';
import { GET_FACEBOOK_PERMISSIONS_FOR_LOCATION } from './useCheckFacebookFirstCommentPermissions.queries';
import {
    GetFacebookPermissionsForLocation,
    GetFacebookPermissionsForLocationVariables,
} from './__graphqlTypes/GetFacebookPermissionsForLocation';

export type CheckFacebookFirstCommentPermissionsResult = {
    loading: false;
    hasAtLeastOneWithPermission: boolean;
    areSomeWithoutPermission: boolean;
};

export type CheckFacebookFirstCommentPermissionsLoading = {
    loading: true;
};

export function useCheckFacebookFirstCommentPermissions(
    locationIds: string[],
): CheckFacebookFirstCommentPermissionsLoading | CheckFacebookFirstCommentPermissionsResult {
    const [fetch, { data, loading }] = useLazyQuery<
        GetFacebookPermissionsForLocation,
        GetFacebookPermissionsForLocationVariables
    >(GET_FACEBOOK_PERMISSIONS_FOR_LOCATION, { fetchPolicy: 'network-only' });

    React.useEffect(() => {
        if (locationIds.length > 0) {
            fetch({
                variables: { ids: locationIds },
            });
        }
    }, [fetch, locationIds]);

    if (loading) {
        return { loading: true };
    }

    const grantedPermissions = relayConnectionToArray(data?.locations)
        .map(location => location.facebook)
        .map(facebook => (facebook.__typename === 'Facebook' ? facebook.hasFirstCommentPermissions : false))
        .filter((hasPermission): hasPermission is true => hasPermission);
    return {
        areSomeWithoutPermission: grantedPermissions.length !== locationIds.length,
        hasAtLeastOneWithPermission: grantedPermissions.length > 0,
        loading: false,
    };
}
