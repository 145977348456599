/// <reference path="../../../typings/browser.d.ts" />

import { ExportableFileFormat, t } from '@deltasierra/shared';

import { SegmentKey } from '@deltasierra/i18n';

type ImageFormatOptions = {
    name: ExportableFileFormat;
    messageKey?: SegmentKey;
    mimeType: string;
    fileExtension: string;
    nativeSupport: boolean;
    animatable: boolean;
    hasFileSizeLimit: boolean;
};

export class ImageFormat {
    public name: ExportableFileFormat;

    public messageKey?: SegmentKey;

    public mimeType: string;

    public fileExtension: string;

    public nativeSupport: boolean;

    public animatable: boolean;

    public hasFileSizeLimit: boolean;

    public constructor(options: ImageFormatOptions) {
        this.name = options.name;
        this.messageKey = options.messageKey;
        this.mimeType = options.mimeType;
        this.fileExtension = options.fileExtension;
        this.nativeSupport = options.nativeSupport;
        this.animatable = options.animatable;
        this.hasFileSizeLimit = options.hasFileSizeLimit;
    }

    public getDisplayName() {
        return this.messageKey ? t(this.messageKey) : this.name;
    }
}

/* eslint-disable sort-keys */
export const formats = [
    new ImageFormat({
        name: 'PNG',
        mimeType: 'image/png',
        fileExtension: 'png',
        nativeSupport: true,
        animatable: false,
        hasFileSizeLimit: false,
    }),
    new ImageFormat({
        name: 'JPG',
        mimeType: 'image/jpeg',
        fileExtension: 'jpg',
        nativeSupport: true, // Should really test this one
        animatable: false,
        hasFileSizeLimit: false,
    }),
    new ImageFormat({
        name: 'JPG (limited file size)',
        messageKey: 'BUILD.IMAGE_FORMAT_NAME.JPG_LIMITED_FILE_SIZE',
        mimeType: 'image/jpeg',
        fileExtension: 'jpg',
        nativeSupport: true,
        animatable: false,
        hasFileSizeLimit: true,
    }),
    // GIF is not supported natively by canvas. Can include a client-side lib to produce GIFs, or rely on the
    //  Server to perform conversion.
    new ImageFormat({
        name: 'GIF',
        mimeType: 'image/gif',
        fileExtension: 'gif',
        nativeSupport: false,
        animatable: true,
        hasFileSizeLimit: false,
    }),
    new ImageFormat({
        name: 'MP4',
        mimeType: 'video/mp4',
        fileExtension: 'mp4',
        nativeSupport: false,
        animatable: false,
        hasFileSizeLimit: false,
    }),
];
/* eslint-enable sort-keys */

export function getFormatByName(name: string): ImageFormat {
    for (const format of formats) {
        if (format.name === name) {
            return format;
        }
    }
    throw new Error(`No format with name: ${name}`);
}
