import * as React from 'react';
import {
    DSButton,
    DSDialog,
    DSDialogActions,
    DSDialogContent,
    DSDialogContentText,
    DSDialogTitle,
    Translate,
} from '@deltasierra/components';

export type DeleteTemplateDraftConfirmationModalProps = {
    show: boolean;
    onCancel?: () => void;
    onConfirm?: () => void;
};

export function DeleteTemplateDraftConfirmationModal({
    onCancel,
    onConfirm,
    show,
}: DeleteTemplateDraftConfirmationModalProps): JSX.Element {
    return (
        <DSDialog open={show}>
            <DSDialogTitle>
                <Translate keyId="BUILD.LOCATION_DRAFT.CONFIRM_DELETE_TITLE" />
            </DSDialogTitle>
            <DSDialogContent>
                <DSDialogContentText>
                    <Translate keyId="BUILD.LOCATION_DRAFT.CONFIRM_DELETE" />
                </DSDialogContentText>
            </DSDialogContent>
            <DSDialogActions>
                <DSButton color="default" variant="outlined" onClick={onCancel}>
                    <Translate keyId="COMMON.CANCEL" />
                </DSButton>
                <DSButton color="primary" onClick={onConfirm}>
                    <Translate keyId="COMMON.DELETE" />
                </DSButton>
            </DSDialogActions>
        </DSDialog>
    );
}

DeleteTemplateDraftConfirmationModal.displayName = 'DeleteTemplateDraftConfirmation';
