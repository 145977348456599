import { PayableService, PayableServiceKey,
    CreatePayableServiceCost,
    PayableServiceCost,
    UpdatePayableServiceCost } from '@deltasierra/shared';

import { getData } from '../common/httpUtils';
import { $httpSID } from '../common/angularData';

export class PayableServiceService {
    static SID = 'PayableServiceService';

    static readonly $inject : string[] = [
        $httpSID,
    ];

    constructor(
        private readonly $http: ng.IHttpService,
    ) {
    }

    getAllServices(): ng.IPromise<PayableService[]> {
        return this.$http.get<PayableService[]>('/api/payments/payableService')
        .then(getData);
    }

    getPayableServiceCosts(key: PayableServiceKey): ng.IPromise<PayableServiceCost[]> {
        return this.$http.get<PayableServiceCost[]>(`/api/payments/payableService/${key}/costs`)
            .then(getData);
    }

    getPayableServiceCostForClient(key: PayableServiceKey, clientId: number): ng.IPromise<PayableServiceCost> {
        return this.$http.get<PayableServiceCost>(`/api/payments/payableService/${key}/costs/${clientId}`)
            .then(getData);
    }

    getAllServicesCosts(): ng.IPromise<PayableServiceCost[]> {
        return this.$http.get<PayableServiceCost[]>('/api/payments/payableService/costs')
            .then(getData);
    }

    updateServiceCost(id: number, updateData: UpdatePayableServiceCost): ng.IPromise<PayableServiceCost> {
        return this.$http.post<PayableServiceCost>(`/api/payments/payableService/costs/${id}`, updateData)
            .then(getData);
    }

    createServiceCost(serviceId: number, data: CreatePayableServiceCost): ng.IPromise<PayableServiceCost> {
        return this.$http.put<PayableServiceCost>(`/api/payments/payableService/${serviceId}/costs`, data)
            .then(getData);
    }

    deleteServiceCost(costId: number): ng.IPromise<PayableServiceCost> {
        return this.$http.delete<PayableServiceCost>(`/api/payments/payableService/costs/${costId}`)
            .then(getData);
    }
}
angular.module('app').service(PayableServiceService.SID, PayableServiceService);
