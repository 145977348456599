/// <reference path="../../../typings/browser.d.ts" />
import { ObjectUrl, ProtocolRelativeUrl, Untyped, Url } from '@deltasierra/shared';
import { dataUrlToBlob } from '@deltasierra/web-image-utilities';
import { $httpSID, $qSID } from '../common/angularData';
import { FileCache as newFileCache } from '../common/fileCache';
import { SentryService } from '../common/sentryService';
import { I18nService } from '../i18n';
import IPromise = angular.IPromise;
import IQService = angular.IQService;
import IQResolveReject = angular.IQResolveReject;

type ImageCacheKey = ObjectUrl | ProtocolRelativeUrl | Url | string;
const withCredentialsUrls = ['digitalstack.io', 'ltnetworkdigitalstack.s3-accelerate'];

export class ImageCache {
    private images: { [key: string]: HTMLImageElement } = {};

    public get(key: ImageCacheKey): HTMLImageElement {
        return this.images[key];
    }

    public set(key: ImageCacheKey, value: HTMLImageElement): void {
        this.images[key] = value;
    }

    public remove(key: ImageCacheKey): void {
        delete this.images[key];
    }

    public clear(): void {
        this.images = {};
    }
}
export class FileCache extends newFileCache {}

export class ImageLoaderService {
    public static SID = 'imageLoaderService';

    public static readonly $inject: string[] = [$qSID, $httpSID, I18nService.SID, SentryService.SID];

    public constructor(
        private $q: IQService,
        private $http: ng.IHttpService,
        private i18n: I18nService,
        private sentryService: SentryService,
    ) {}

    public loadImageFromFile(imageCache: ImageCache, fileCache: FileCache, file: File): IPromise<HTMLImageElement> {
        // Create an image element
        const URL = (window as Untyped).webkitURL || window.URL;
        const url = URL.createObjectURL(file);
        fileCache.set(url, file);
        const img = new Image();
        const deferred = this.$q.defer<HTMLImageElement>();
        img.onload = () => {
            imageCache.set(ObjectUrl.from(img.src), img);
            deferred.resolve(img);
        };
        img.src = url;
        return deferred.promise;
    }

    public loadImageFromExternal(cache: ImageCache, location: ProtocolRelativeUrl): IPromise<HTMLImageElement> {
        const img = new Image();
        const deferred = this.$q.defer<HTMLImageElement>();
        img.onload = () => {
            cache.set(location, img);
            deferred.resolve(img);
        };
        img.onerror = err => {
            deferred.reject(`Failed to load image at "${location}"${err ? `: ${err.toString()}` : ''}`);
        };
        img.crossOrigin = 'use-credentials';
        // Setting the src must be the last action, since it initiates the download of the image.
        img.src = `${location}?d=${Date.now()}`; // Avoid WebKit Bug 63090 - img.crossOrigin not respected on cached images
        return deferred.promise;
    }

    public async loadFileFromExternal(location: string): Promise<File> {
        const requiresCredentials = withCredentialsUrls.some(url => location.toLowerCase().includes(url)); // Check for our pages
        const newLocation = `${location}?d=${Date.now()}`; // Avoid WebKit Bug 63090 - img.crossOrigin not respected on cached images
        try {
            return await this.loadFileFromExternalAux(location, newLocation, requiresCredentials);
        } catch (error) {
            this.sentryService.captureException('Failed to load external file', error);
            throw error;
        }
    }

    public convertFileToImage(fileOrBlob: Blob | File): IPromise<HTMLImageElement> {
        const img = new Image();
        const deferred = this.$q.defer<HTMLImageElement>();
        const url = URL.createObjectURL(fileOrBlob);
        img.onload = () => deferred.resolve(img);
        img.onerror = err => {
            let errorMessage = this.i18n.text.common.failedToLoadImage();
            if ((err as any).error) {
                errorMessage += `: ${(err as any).error}`;
            }
            deferred.reject(errorMessage);
        };
        img.src = url;
        return deferred.promise;
    }

    public getFileDimensions(fileOrBlob: Blob | File): IPromise<{ width: number; height: number }> {
        return this.convertFileToImage(fileOrBlob).then(img => {
            const dimensions = {
                height: img.height,
                width: img.width,
            };
            URL.revokeObjectURL(img.src);
            return dimensions;
        });
    }

    /* Adapted from http://stackoverflow.com/a/16245768/953887 */
    public dataUrlToBlob(dataUrl: string, sliceSize?: number): Blob {
        return dataUrlToBlob(dataUrl, sliceSize);
    }

    public blobToDataUrl(blob: Blob): IPromise<string> {
        return this.$q((resolve: IQResolveReject<string>, reject: IQResolveReject<any>) => {
            const reader = new FileReader();
            reader.onload = event => resolve(reader.result as string);
            reader.onerror = event => reject(event ? (event as any).error : event);
            reader.readAsDataURL(blob);
        });
    }

    public canvasToBlob(canvas: HTMLCanvasElement, type?: string): IPromise<Blob> {
        return this.$q(resolve => {
            canvas.toBlob(resolve, type);
        });
    }

    private async loadFileFromExternalAux(
        location: string,
        newLocation: string,
        withCredentials: boolean,
    ): Promise<File> {
        const response = await this.$http.get<Blob>(newLocation, {
            responseType: 'blob',
            withCredentials,
        });
        const blob = response.data;

        if (!blob) {
            throw new Error('The response returned no file');
        }

        const file = new File([blob], location, {
            lastModified: new Date().valueOf(),
            type: blob.type, // The type property needs to be explicitly set.
        });

        if (!file.type) {
            throw new Error('Failed to set type of file when loading from external source');
        }

        return file;
    }
}

angular.module('app').service(ImageLoaderService.SID, ImageLoaderService);
