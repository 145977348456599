import { t, AssignedLocation, getSenderForFitwareEmail, LocationIdHierarchy } from '@deltasierra/shared';
import * as React from 'react';
import { OneWayBinding, OptionalOneWayBinding } from '../../../../common/angularData';
import { useAngularServiceContext } from '../../../../common/componentUtils/angularServiceContexts';
import { withAngularIntegration } from '../../../../common/componentUtils/reactComponentRegistration';
import { useDataRetrieval } from '../../../../common/futures';
import { Loading } from '../../../../common/Loading';
import { Translate } from '../../../../directives/Translate';

export type SelectedLocationsSummaryProps = {
    locations: LocationIdHierarchy[];
    maxLocationsToShow?: number;
};

const SelectedLocationsSummary: React.FunctionComponent<SelectedLocationsSummaryProps> = ({ locations, maxLocationsToShow = 5 }) => {
    const [response] = useRetrieveLocationsDetails(locations);
    return (
        <>
            {response.isRunning && <Loading inline={false} size="small" />}
            {response.isFinished && <LocationsList locations={response.value} maxLocationsToShow={maxLocationsToShow} />}
            {response.isFailed && <p className="text-muted">{t('COMMON.FAILED_TO_RETRIEVE_LOCATIONS')}</p>}
        </>
    );
};

type LocationsListProps = {
    locations: AssignedLocation[];
    maxLocationsToShow: number;
};

const LocationsList: React.FunctionComponent<LocationsListProps> = ({ locations, maxLocationsToShow }) => {
    const [showAllLocations, setShowAllLocations] = React.useState(false);
    const locationsToShow = showAllLocations ? locations : locations.slice(0, maxLocationsToShow);
    const hasMoreThanMaxLocations = locations.length > maxLocationsToShow;
    return (
        <ul className="height-constrained-list">
            {locationsToShow.map((location: AssignedLocation) => (
                <li key={location.id}>{getSenderForFitwareEmail(location)}</li>
            ))}
            {hasMoreThanMaxLocations && !showAllLocations && <ShowMore onClick={() => setShowAllLocations(!showAllLocations)} />}
        </ul>
    );
};

type ShowMoreProps = {
    onClick: () => void;
};

const ShowMore: React.FunctionComponent<ShowMoreProps> = ({ onClick }) => (
    <li>
        <a href="#" onClick={() => onClick()}>
            <Translate keyId="COMMON.SHOW_ALL" />
        </a>
    </li>
);

function useRetrieveLocationsDetails(locations: LocationIdHierarchy[]) {
    const locationIds = locations.map(location => location.locationId);
    const locationService = useAngularServiceContext('mvLocation');
    const { chunkedAsyncMap, sortedBy } = useAngularServiceContext('dataUtils');
    return useDataRetrieval(
        async () => {
            const assignedLocations = await chunkedAsyncMap(5, locationIds, async id => locationService.getAssignedLocation(id));
            return sortedBy(assignedLocations, ({ fitwareClubName, title }) => fitwareClubName || title);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [chunkedAsyncMap, ...locationIds, locationService, sortedBy],
        { description: t('COMMON.FAILED_TO_RETRIEVE_LOCATIONS') },
    );
}

export default withAngularIntegration(SelectedLocationsSummary, 'selectedLocationsSummary', {
    locations: OneWayBinding,
    maxLocationsToShow: OptionalOneWayBinding,
});
