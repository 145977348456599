import { ClientBrandColour, CreateClientBrandColour, ClientId } from '@deltasierra/shared';

import { MvNotifier } from '../../common/mvNotifier';
import { InteractionUtils } from '../../common/interactionUtils';
import { DataUtils } from '../../common/dataUtils';
import { I18nService } from '../../i18n/i18nService';
import { MvClient } from '../mvClient';
import { $qSID, $routeParamsSID, IRouteParams } from '../../common/angularData';
import IQService = angular.IQService;

export class ClientBrandColoursController {
    public static SID = 'ClientBrandColoursController';

    public static readonly $inject: string[] = [
        $routeParamsSID,
        $qSID,
        MvNotifier.SID,
        InteractionUtils.SID,
        DataUtils.SID,
        I18nService.SID,
        MvClient.SID,
    ];


    public client_id: ClientId;

    public brandColours?: ClientBrandColour[];

    public newBrandColour: CreateClientBrandColour = {
        colour: 'rgb(0, 0, 0)',
        name: '',
    };

    public retrieveBrandColours = this.interactionUtils.createFuture(this.i18n.text.common.fetchData(), () =>
        this.clientService.getClientBrandColours(this.client_id).then(colours => {
            this.brandColours = colours.sort((colourA, colourB) => colourA.order - colourB.order);
        }),
    );

    public addNewBrandColour = this.interactionUtils.createFuture(
        this.i18n.text.agency.client.brandColours.createNewBrandColour(),
        () =>
            this.clientService.createNewClientBrandColour(this.client_id, this.newBrandColour).then(brandColour => {
                this.brandColours!.push(brandColour);
                this.newBrandColour = {
                    colour: 'rgb(0, 0, 0)',
                    name: '',
                };
                this.notifier.notify(
                    this.i18n.text.agency.client.brandColours.createdBrandColour({ colour: brandColour.name }),
                );
            }),
    );

    public deleteBrandColour = this.interactionUtils.createFuture(
        this.i18n.text.agency.client.brandColours.deleteBrandColour(),
        (context: { colour: ClientBrandColour }) =>
            this.clientService
                .deleteClientBrandColours(context.colour.clientId, context.colour.id)
                .then(deletedBrandColour => {
                    this.brandColours = this.brandColours!.filter(colour => colour.id !== deletedBrandColour.id);
                    this.notifier.notify(this.i18n.text.agency.client.brandColours.deletedBrandColour());
                })
                .then(() => this.retrieveBrandColours.run({})),
    );

    public updateBrandColour = this.interactionUtils.createFuture(
        this.i18n.text.agency.client.brandColours.deleteBrandColour(),
        (context: { colour: ClientBrandColour }) =>
            this.clientService
                .updateClientBrandColour(context.colour.clientId, context.colour.id, { colour: context.colour.colour })
                .then(updatedBrandColour => {
                    context.colour.colour = updatedBrandColour.colour;
                    this.notifier.notify(
                        this.i18n.text.agency.client.brandColours.updatedBrandColour({
                            colour: updatedBrandColour.name,
                        }),
                    );
                }),
    );

    private updateClientBrandColourOrder = this.interactionUtils.createFuture(
        this.i18n.text.agency.client.brandColours.updateOrder(),
        () => {
            const ids = this.dataUtils.pluckByGetter(brandColour => brandColour.id, this.brandColours!);

            return this.clientService
                .updateClientBrandColoursOrder(ids, this.client_id)
                .then(() => {
                    for (const colour of this.brandColours!) {
                        colour.order = ids.indexOf(colour.id);
                    }
                    this.notifier.notify(this.i18n.text.agency.client.brandColours.updatedBrandColourOrder());
                })
                .finally(() => this.brandColours!.sort((colourA, colourB) => colourA.order - colourB.order));
        },
    );

    // eslint-disable-next-line max-params
    public constructor(
        $routeParams: IRouteParams,
        $q: IQService,
        private readonly notifier: MvNotifier,
        private readonly interactionUtils: InteractionUtils,
        private readonly dataUtils: DataUtils,
        private readonly i18n: I18nService,
        private readonly clientService: MvClient,
    ) {
        this.client_id = ClientId.from(parseInt($routeParams.id, 10));
        void this.retrieveBrandColours.run({});
    }

    public moveOrderUp(colour: ClientBrandColour): ng.IPromise<void> | undefined {
        const wasMoved = this.dataUtils.moveUp(colour, this.brandColours!);
        if (wasMoved) {
            return this.updateClientBrandColourOrder.run({});
        }
        return undefined;
    }

    public moveOrderDown(colour: ClientBrandColour): ng.IPromise<void> | undefined {
        const wasMoved = this.dataUtils.moveDown(colour, this.brandColours!);
        if (wasMoved) {
            return this.updateClientBrandColourOrder.run({});
        }
        return undefined;
    }
}

angular.module('app').controller(ClientBrandColoursController.SID, ClientBrandColoursController);
