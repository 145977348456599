import { IScope, IQService, IAngularEvent } from 'angular';
import { BuilderTemplateId, AssignedLocation, LocationIdHierarchy, GalleryPlannerDetails, getDefaultScheduledTime, ExportPlatforms, TermsAndConditionsChannelData, addTermsAndConditionsToCaption } from '@deltasierra/shared';


import { ContentBuilder } from '../../contentBuilder';
import { MvContentBuilderPublishCtrl } from '../mvContentBuilderPublishCtrl';
import { PublishImmediatelyCheckService } from '../common/publishImmediatelyCheck/service';
import { DataUtils } from '../../../common/dataUtils';
import { LinkedInPublishService } from '../../../integration/publish/linkedInPublishService';
import { BuilderConstants } from '../../builderConstants';
import { ImageLoaderService } from '../../imageLoaderService';
import { MvLocation } from '../../../locations/mvLocation';
import { I18nService } from '../../../i18n';
import { PublishResult } from '../publishResult';
import { MultiImagePublishCallback, MultiImagePublishFlowCallbacks } from '../types';

export interface PublishToLinkedInCtrlScope extends IScope {
    ctrl: MvContentBuilderPublishCtrl;
}

export class CommonPublishData {
    public constructor(
        public caption: string | null,
        public scheduledTime: Date | null,
        public templateId: BuilderTemplateId,
        public plannerId?: number,
    ) {}
}

export abstract class BaseLinkedInPublishCtrl implements MultiImagePublishFlowCallbacks<CommonPublishData> {
    public isToBeScheduled = false;

    public scheduledTime: Date | null = null;

    public caption: string | null = null;

    public captionLabel: string = this.i18n.text.build.publish.linkedin.caption();

    public captionHelpText: string = this.i18n.text.build.publish.linkedin.captionHelpText();

    public suggestedCaption: string | null = null;

    public termsAndConditionsText: string | null = null;

    public termsAndConditionsUrl: string | null = null;

    public datePickerIsOpen = {
        scheduledTime: false,
    };

    public isPublishing = false;

    public contentBuilder!: ContentBuilder;

    public templateId!: BuilderTemplateId;

    public location!: AssignedLocation;

    public chosenLocations!: LocationIdHierarchy[];

    public plannerDetails?: GalleryPlannerDetails;

    public commonData: CommonPublishData | null = null;

    public publishCtrl!: MvContentBuilderPublishCtrl;

    public minDate = new Date();

    public pageDescription = '';

    // eslint-disable-next-line max-params
    public constructor(
        protected readonly $scope: PublishToLinkedInCtrlScope,
        protected readonly $q: IQService,
        protected publishImmediatelyCheckService: PublishImmediatelyCheckService,
        protected readonly dataUtils: DataUtils,
        protected readonly linkedInPublishService: LinkedInPublishService,
        protected readonly builderConstants: BuilderConstants,
        protected readonly imageLoaderService: ImageLoaderService,
        protected readonly mvLocation: MvLocation,
        protected i18n: I18nService,
        public readonly publishType: 'photo',
    ) {
        this.populateInheritedProperties();
        if (this.plannerDetails?.date) {
            this.isToBeScheduled = true;
            this.scheduledTime = this.plannerDetails.date;
        }
        this.setScheduledTime();
        this.getLinkedInPageDescription()
            .then(pageDescription => {
                this.pageDescription = pageDescription;
            })
            .catch(err => {
                throw err;
            });
        this.termsAndConditionsText = this.contentBuilder.getChannelDatum(
            'termsAndConditions',
            (data: TermsAndConditionsChannelData) => data.text,
        );
        this.termsAndConditionsUrl = this.contentBuilder.getChannelDatum(
            'termsAndConditions',
            (data: TermsAndConditionsChannelData) => data.url,
        );
    }

    public cancel(): void {
        this.$scope.$emit(this.builderConstants.EVENTS.PUBLISH_CANCEL);
    }

    public finish(): void {
        this.$scope.$emit(
            this.builderConstants.EVENTS.PUBLISH_PUBLISHED,
            new PublishResult(ExportPlatforms.LinkedIn, this.scheduledTime),
        );
    }

    public getDoFinish(): () => void {
        return this.finish.bind(this);
    }

    public clickPublish(): void {
        if (this.isToBeScheduled) {
            this.publishImmediatelyCheckService.checkTimeDifferenceAndOptionallyShowConfirmModal(
                this.scheduledTime!,
                () => this.publishImmediately(),
                () => this.publish(),
            );
        } else {
            this.publish();
        }
    }

    public openDatePicker(
        $event: IAngularEvent,
        datePickerName: keyof BaseLinkedInPublishCtrl['datePickerIsOpen'],
    ): void {
        $event.preventDefault();
        if ($event.stopPropagation) {
            $event.stopPropagation();
        }
        this.datePickerIsOpen[datePickerName] = true;
    }

    public async getLinkedInPageDescription(): Promise<string> {
        const organizationNames: string[] = [];
        await Promise.all(
            this.chosenLocations.map(async location => {
                const organization = await this.mvLocation.getConfiguredPageNameAndOrganizationUrn(location.locationId);
                if (organization) {
                    organizationNames.push(organization.pageName);
                }
            }),
        );
        if (organizationNames.length === 0) {
            return `${this.chosenLocations.length} page(s)`;
        }
        return organizationNames.join(', ');
    }

    public abstract getDoPublish(): MultiImagePublishCallback<CommonPublishData>;

    protected publish(): void {
        const scheduledTime = this.isToBeScheduled ? this.scheduledTime : null;
        this.scheduledTime = scheduledTime;
        const optionalPlannerId = this.plannerDetails ? this.plannerDetails.id : null;
        if (this.termsAndConditionsText || this.termsAndConditionsUrl) {
            this.caption = addTermsAndConditionsToCaption(
                this.caption,
                this.termsAndConditionsText,
                this.termsAndConditionsUrl,
            );
        }
        const commonData = new CommonPublishData(
            this.caption,
            scheduledTime,
            this.templateId,
            optionalPlannerId || undefined,
        );
        this.commonData = commonData;
        this.isPublishing = true;
    }

    protected publishImmediately(): void {
        this.isToBeScheduled = false;
        this.publish();
    }

    private populateInheritedProperties() {
        this.publishCtrl = this.$scope.ctrl;
        this.contentBuilder = this.publishCtrl.contentBuilder;
        this.templateId = this.publishCtrl.templateId;
        this.location = this.publishCtrl.location;
        this.chosenLocations = this.publishCtrl.locations;
        this.plannerDetails = this.publishCtrl.plannerDetails;
    }

    private setScheduledTime() {
        this.scheduledTime = getDefaultScheduledTime(15, this.plannerDetails, 19);
    }
}
