/// <reference path="../../../../typings/browser.d.ts" />
import {
    ClientId,
    ExternalPrintPackageApi,
    ExternalPrintPackageBanner,
    ExternalPrintPackageBannerId,
    GetExternalAppUrlResponse,
    justParams,
    LocationId,
} from '@deltasierra/shared';
import { $httpSID, $qSID } from '../../common/angularData';
import { invokeApiRoute } from '../httpUtils';

export class ExternalPrintPackageApiClient {
    static SID = 'ExternalPrintPackageApiClient';

    static $inject = [$httpSID, $qSID];

    constructor(protected readonly $http: ng.IHttpService, protected readonly $q: ng.IQService) {}

    getExternalAppUrl(
        clientId: ClientId,
        externalPrintPackageBannerId: ExternalPrintPackageBannerId,
        locationId: LocationId,
    ): ng.IPromise<GetExternalAppUrlResponse> {
        return invokeApiRoute(
            this.$http,
            ExternalPrintPackageApi.getExternalAppUrl,
            justParams({
                clientId,
                externalPrintPackageBannerId,
                locationId,
            }),
        );
    }

    getExternalPrintPackageBanners(clientId: ClientId): ng.IPromise<ExternalPrintPackageBanner[]> {
        return invokeApiRoute(
            this.$http,
            ExternalPrintPackageApi.getExternalPrintPackageBanners,
            justParams({
                clientId,
            }),
        );
    }
}

angular.module('app').service(ExternalPrintPackageApiClient.SID, ExternalPrintPackageApiClient);
