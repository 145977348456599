/* eslint-disable camelcase */
import { gql, useQuery } from '@apollo/client';
import { Loader } from '@deltasierra/components';
import * as React from 'react';
import { Translate } from '../../../directives/Translate';
import { GetLocationAvailableUsers_location_availableUsers_edges_node } from './__graphqlTypes/GetLocationAvailableUsers';
import { GetUserRelatedData, GetUserRelatedDataVariables } from './__graphqlTypes/GetUserRelatedData';

const GET_USER_RELATED_DATA = gql`
    query GetUserRelatedData($id: ID!) {
        user(id: $id) {
            locations {
                edges {
                    node {
                        id
                        title
                        client {
                            title
                        }
                    }
                }
            }
        }
    }
`;

type SelectedUserRelatedInfoProps = {
    selectedUser: GetLocationAvailableUsers_location_availableUsers_edges_node;
};

export const SelectedUserRelatedInfo: React.FC<SelectedUserRelatedInfoProps> = ({ selectedUser }) => {
    const { loading, data } = useQuery<GetUserRelatedData, GetUserRelatedDataVariables>(GET_USER_RELATED_DATA, {
        fetchPolicy: 'cache-and-network',
        variables: {
            id: selectedUser.id,
        },
    });
    return (
        <div>
            <label>
                <Translate keyId="AGENCY.CLIENT.LOCATION.USERS.ADD_EXISTING_USER_SELECTED" />
            </label>
            <p>{selectedUser.username}</p>
            <label>
                <Translate keyId="AGENCY.CLIENT.LOCATION.USERS.USERS_EXISTING_LOCATIONS" />
            </label>
            <Loader loading={loading} size="small">
                {data?.user?.locations.edges.length ? (
                    <ul>
                        {data.user.locations.edges.map(locationEdge => (
                            <li key={locationEdge.node.id}>{`${locationEdge.node.client.title} - ${locationEdge.node.title}`}</li>
                        ))}
                    </ul>
                ) : (
                    <p>
                        <Translate keyId="COMMON.NONE" />
                    </p>
                )}
            </Loader>
        </div>
    );
};
