import { Observable, OperatorFunction, pipe, Subject, Subscriber } from 'rxjs';
import { sanitizeFileNameForUpload, UploadId } from '@deltasierra/shared';
import { mapTo, mergeMap, takeUntil } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { FileUploadStatus } from './common';

type UploadFileToS3StepContext = {
    cancellation$: Observable<unknown>;
    progress$: Subject<FileUploadStatus>;
};

type UploadFileToS3StepInput = {
    file: File;
    filename: string;
    legacyUploadId: UploadId;
    signedUrl: string;
    uploadId: string;
};

type UploadFileToS3StepOutput = {
    file: File;
    legacyUploadId: UploadId;
    uploadId: string;
};

export function uploadFileToS3Step({
    cancellation$,
    progress$,
}: UploadFileToS3StepContext): OperatorFunction<UploadFileToS3StepInput, UploadFileToS3StepOutput> {
    return pipe(
        mergeMap(({ file, legacyUploadId, signedUrl, uploadId }) => {
            const progressSubscriber = Subscriber.create<ProgressEvent>(event => {
                if (event) {
                    progress$.next({
                        status: 'progress',
                        totalBytes: event.lengthComputable ? event.total : file.size,
                        uploadedBytes: event.lengthComputable ? event.loaded : 0,
                    });
                }
            });
            return ajax({
                body: file,
                headers: {
                    'Content-Disposition': `filename="${sanitizeFileNameForUpload(file.name)}"`,
                    ...file.type ? { 'Content-Type': file.type } : {},
                },
                method: 'PUT',
                progressSubscriber,
                url: signedUrl,
                withCredentials: true,
            }).pipe(takeUntil(cancellation$), mapTo({ file, legacyUploadId, uploadId }));
        }),
    );
}
