import { emptyRequest, justBody, justParams, StripeAuthRequest, CreateStripeChargeBody, StripeChargeDto, StripeChargeErrorResponse,
    StripeApi,
    StripePublishableKeyResponse,
    StripeClientIdResponse,
    AuthRequestBody,
    StripeAuthorisationUrlResponse,
    SubmitAuthCodeForStripeAccountBody,
    SubmitAuthCodeForStripeAccountResponse } from '@deltasierra/shared';


import { invokeApiRoute } from './../../common/httpUtils';
import { $httpSID } from './../../common/angularData';
import { MvIdentity } from './../../account/mvIdentity';

export class StripeApiClient {
    static SID = 'StripeApiClient';

    static readonly $inject: string[] = [$httpSID, MvIdentity.SID];

    constructor(protected readonly $http: ng.IHttpService, protected readonly mvIdentity: MvIdentity) {}

    createStripeCharge(data: CreateStripeChargeBody): ng.IPromise<StripeChargeDto | StripeChargeErrorResponse> {
        return invokeApiRoute(this.$http, StripeApi.createStripeCharge, justBody(data));
    }

    getStripeAuthRequest(token: string): ng.IPromise<StripeAuthRequest> {
        return invokeApiRoute(this.$http, StripeApi.getStripeAuthRequest, justParams({ token }));
    }

    getStripeAuthorisationUrl(token: string): ng.IPromise<StripeAuthorisationUrlResponse> {
        return invokeApiRoute(this.$http, StripeApi.getStripeAuthorisationUrl, justParams({ token }));
    }

    getStripeClientId(): ng.IPromise<StripeClientIdResponse> {
        return invokeApiRoute(this.$http, StripeApi.getStripeClientId, emptyRequest());
    }

    getStripePublishableKey(): ng.IPromise<StripePublishableKeyResponse> {
        return invokeApiRoute(this.$http, StripeApi.getStripePublishableKey, emptyRequest());
    }

    sendStripeAuthRequest(authData: AuthRequestBody): ng.IPromise<void> {
        return invokeApiRoute(this.$http, StripeApi.sendStripeAuthRequest, justBody(authData));
    }

    submitAuthCodeForStripeAccount(data: SubmitAuthCodeForStripeAccountBody): ng.IPromise<SubmitAuthCodeForStripeAccountResponse> {
        return invokeApiRoute(this.$http, StripeApi.submitAuthCodeForStripeAccount, justBody(data));
    }
}

angular.module('app').service(StripeApiClient.SID, StripeApiClient);
