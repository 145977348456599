import { useQuery } from '@apollo/client';
import { DSFormControl, Loading, Translate } from '@deltasierra/components';
import { useInterval } from '@deltasierra/react-hooks';
import { FormikProps } from 'formik';
import { DateTime } from 'luxon';
import * as React from 'react';
import { SchedulePublishTimePicker } from '../../../directives/SchedulePublishTimePicker';
import { StyledDSAlert } from '../../components';
import { FacebookFormValues } from './facebook-form-values';
import { GET_LOCATION_FOR_FACEBOOK_PUBLISH_FORM_QUERY } from './FacebookFormScheduleSection.queries';
import { FacebookPublishType } from './FacebookPublishForm';
import {
    GetLocationForFacebookPublishFormQuery,
    GetLocationForFacebookPublishFormQueryVariables,
} from './__graphqlTypes/GetLocationForFacebookPublishFormQuery';

export function getMinScheduleDateFromNowForFacebook(): Date {
    return DateTime.now().plus({ minutes: 10 }).toJSDate();
}

export type FacebookFormScheduleSectionProps = {
    formik: FormikProps<FacebookFormValues>;
    locationId: string;
    publishType: FacebookPublishType;
};

export function FacebookFormScheduleSection({
    formik,
    locationId,
    publishType,
}: FacebookFormScheduleSectionProps): JSX.Element {
    const [minScheduledDate, setMinScheduledDate] = React.useState<Date | null>(
        getMinScheduleDateFromNowForFacebook(),
    );
    const scheduledTime = formik.values.scheduledTime;

    const { data, loading } = useQuery<
        GetLocationForFacebookPublishFormQuery,
        GetLocationForFacebookPublishFormQueryVariables
    >(GET_LOCATION_FOR_FACEBOOK_PUBLISH_FORM_QUERY, {
        variables: {
            id: locationId,
        },
    });

    const handleChange = React.useCallback(
        (newValue: Date | null) => formik.setFieldValue('scheduledTime', newValue),
        [formik],
    );

    // Every minute
    useInterval(60 * 1000, () => {
        // Make sure to adjust the minimum scheduled date
        const nextMinScheduledDate = getMinScheduleDateFromNowForFacebook();
        setMinScheduledDate(nextMinScheduledDate);

        if (scheduledTime && scheduledTime < nextMinScheduledDate) {
            // Use this to set the scheduled date
            handleChange(nextMinScheduledDate);
        }
    });

    return (
        <DSFormControl error={!!formik.errors.scheduledTime} style={{ width: '100%' }}>
            {loading && <Loading inline size="small" />}
            {data?.location && (
                <SchedulePublishTimePicker
                    error={formik.errors.scheduledTime}
                    minDate={minScheduledDate}
                    timezone={data.location.timezone}
                    useNewLabel
                    value={scheduledTime}
                    onChange={handleChange}
                />
            )}
            {publishType === 'video' && (
                <StyledDSAlert classes={{ icon: 'icon' }} severity="info">
                    <Translate keyId="BUILD.VIDEO.PROCESSING_TIME_DISCLAIMER" />
                </StyledDSAlert>
            )}
        </DSFormControl>
    );
}
FacebookFormScheduleSection.displayName = 'FacebookFormScheduleSection';
