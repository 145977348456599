/* eslint-disable max-lines-per-function */
import { Editor } from '@tiptap/react';
import * as React from 'react';
import styled from 'styled-components';

import { t, UploadCategory } from '@deltasierra/shared';
import FormatAlignCenter from '@material-ui/icons/FormatAlignCenterRounded';
import FormatAlignLeft from '@material-ui/icons/FormatAlignLeftRounded';
import FormatAlignRight from '@material-ui/icons/FormatAlignRightRounded';
import FormatBold from '@material-ui/icons/FormatBoldRounded';
import FormatItalic from '@material-ui/icons/FormatItalicRounded';
import FormatListBulleted from '@material-ui/icons/FormatListBulletedRounded';
import FormatListNumbered from '@material-ui/icons/FormatListNumberedRounded';
import ImageIcon from '@material-ui/icons/Image';
import InsertLink from '@material-ui/icons/InsertLink';
import { AddLinkModal } from './AddLinkModal';
import { TipTapEditableContext } from './tip-tap-editable-context';

const HiddenInput = styled.input`
    &&& {
        display: none;
    }
`;

const MenuBarContainerDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const StyledMenuButton = styled.button<{ $isActive?: boolean }>`
    display: inline-flex;

    height: 32px;
    width: 32px;

    align-items: center;
    justify-content: center;

    color: ${props => props.theme.palette.grey[800]};
    background-color: ${props => (props.$isActive ? props.theme.palette.grey[400] : props.theme.palette.common.white)};
    border: 1px solid ${props => props.theme.palette.grey[400]};
    border-radius: 3px;

    & + & {
        margin-left: 8px;
    }

    &:disabled {
        background-color: ${props => props.theme.palette.grey[200]};
        border: 1px solid ${props => props.theme.palette.grey[200]};
        color: ${props => props.theme.palette.grey[500]};
    }
`;

interface ButtonData {
    icon: React.ReactNode;
    isDisabled?: boolean;
    isActive?: boolean;
    name: string;
    onClick: () => void;
}

export interface TipTapEditorMenuProps {
    acceptFileTypes?: string;
    defaultUploadCategory?: UploadCategory;
    editor: Editor | null;
    multipleFileUploadsAllowed?: boolean;
}

export function TipTapEditorMenu({
    acceptFileTypes = 'image/*',
    defaultUploadCategory = 'emailResources',
    editor,
    multipleFileUploadsAllowed = false,
}: TipTapEditorMenuProps): JSX.Element {
    const isEditable = React.useContext(TipTapEditableContext);
    const [showAddLinkModal, setShowAddLinkModal] = React.useState(false);

    const hiddenFileInputRef = React.useRef<HTMLInputElement | null>(null);

    const handleChange: React.ChangeEventHandler<HTMLInputElement> = React.useCallback(
        event => {
            if (!event.target.files) {
                return;
            }
            const files = Array.from(event.target.files);

            editor
                ?.chain()
                .focus()
                .insertUploadFileNodes(
                    files.map(file => ({
                        file,
                        uploadCategory: defaultUploadCategory,
                    })),
                )
                .run();
            // Reset the input so you can upload multiple of the same named files
            event.target.value = '';
        },
        [defaultUploadCategory, editor],
    );

    const handleInsertLink = React.useCallback(() => {
        const previousLinkUrl = editor?.getAttributes('link').href;
        if (previousLinkUrl) {
            editor?.chain().focus().unsetLink().run();
        } else {
            setShowAddLinkModal(true);
        }
    }, [editor]);

    const setLink = React.useCallback(
        (url: string) => {
            editor?.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
            setShowAddLinkModal(false);
        },
        [editor],
    );

    const buttons: ButtonData[] = React.useMemo(
        () => [
            {
                icon: <FormatBold />,
                isActive: editor?.isActive('bold') ?? false,
                name: t('NOTIFICATIONS.SEND_NOTIFICATION_WIZARD.COMPOSE.EMAIL.EDITOR_BUTTONS.BOLD'),
                onClick: () => editor?.chain().focus().toggleBold().run(),
            },
            {
                icon: <FormatItalic />,
                isActive: editor?.isActive('italic') ?? false,
                name: t('NOTIFICATIONS.SEND_NOTIFICATION_WIZARD.COMPOSE.EMAIL.EDITOR_BUTTONS.ITALICS'),
                onClick: () => editor?.chain().focus().toggleItalic().run(),
            },
            {
                icon: <FormatAlignLeft />,
                isActive: editor?.isActive({ textAlign: 'left' }),
                name: t('NOTIFICATIONS.SEND_NOTIFICATION_WIZARD.COMPOSE.EMAIL.EDITOR_BUTTONS.ALIGN_LEFT'),
                onClick: () => editor?.chain().focus().setTextAlign('left').run(),
            },
            {
                icon: <FormatAlignCenter />,
                isActive: editor?.isActive({ textAlign: 'center' }),
                isDisabled: !!editor && (editor.isActive('bulletList') || editor.isActive('orderedList')),
                name: t('NOTIFICATIONS.SEND_NOTIFICATION_WIZARD.COMPOSE.EMAIL.EDITOR_BUTTONS.ALIGN_CENTER'),
                onClick: () => editor?.chain().focus().setTextAlign('center').run(),
            },
            {
                icon: <FormatAlignRight />,
                isActive: editor?.isActive({ textAlign: 'right' }),
                isDisabled: !!editor && (editor.isActive('bulletList') || editor.isActive('orderedList')),
                name: t('NOTIFICATIONS.SEND_NOTIFICATION_WIZARD.COMPOSE.EMAIL.EDITOR_BUTTONS.ALIGN_RIGHT'),
                onClick: () => editor?.chain().focus().setTextAlign('right').run(),
            },
            {
                icon: <FormatListBulleted />,
                isActive: editor?.isActive('bulletList') ?? false,
                name: t('NOTIFICATIONS.SEND_NOTIFICATION_WIZARD.COMPOSE.EMAIL.EDITOR_BUTTONS.BULLETED_LIST'),
                onClick: () => editor?.chain().focus().toggleBulletList().run(),
            },
            {
                icon: <FormatListNumbered />,
                isActive: editor?.isActive('orderedList') ?? false,
                name: t('NOTIFICATIONS.SEND_NOTIFICATION_WIZARD.COMPOSE.EMAIL.EDITOR_BUTTONS.NUMBERED_LIST'),
                onClick: () => editor?.chain().focus().toggleOrderedList().run(),
            },
            {
                icon: <strong>{'H1'}</strong>,
                isActive: editor?.isActive('heading', { level: 1 }),
                name: t('NOTIFICATIONS.SEND_NOTIFICATION_WIZARD.COMPOSE.EMAIL.EDITOR_BUTTONS.HEADING_NUM', { num: 1 }),
                onClick: () => editor?.chain().focus().toggleHeading({ level: 1 }).run(),
            },
            {
                icon: <strong>{'H2'}</strong>,
                isActive: editor?.isActive('heading', { level: 2 }),
                name: t('NOTIFICATIONS.SEND_NOTIFICATION_WIZARD.COMPOSE.EMAIL.EDITOR_BUTTONS.HEADING_NUM', { num: 2 }),
                onClick: () => editor?.chain().focus().toggleHeading({ level: 2 }).run(),
            },
            {
                icon: <strong>{'H3'}</strong>,
                isActive: editor?.isActive('heading', { level: 3 }),
                name: t('NOTIFICATIONS.SEND_NOTIFICATION_WIZARD.COMPOSE.EMAIL.EDITOR_BUTTONS.HEADING_NUM', { num: 3 }),
                onClick: () => editor?.chain().focus().toggleHeading({ level: 3 }).run(),
            },
            {
                icon: <strong>{'H4'}</strong>,
                isActive: editor?.isActive('heading', { level: 4 }),
                name: t('NOTIFICATIONS.SEND_NOTIFICATION_WIZARD.COMPOSE.EMAIL.EDITOR_BUTTONS.HEADING_NUM', { num: 4 }),
                onClick: () => editor?.chain().focus().toggleHeading({ level: 4 }).run(),
            },
            {
                icon: <strong>{'H5'}</strong>,
                isActive: editor?.isActive('heading', { level: 5 }),
                name: t('NOTIFICATIONS.SEND_NOTIFICATION_WIZARD.COMPOSE.EMAIL.EDITOR_BUTTONS.HEADING_NUM', { num: 5 }),
                onClick: () => editor?.chain().focus().toggleHeading({ level: 5 }).run(),
            },
            {
                icon: <strong>{'H6'}</strong>,
                isActive: editor?.isActive('heading', { level: 6 }),
                name: t('NOTIFICATIONS.SEND_NOTIFICATION_WIZARD.COMPOSE.EMAIL.EDITOR_BUTTONS.HEADING_NUM', { num: 6 }),
                onClick: () => editor?.chain().focus().toggleHeading({ level: 6 }).run(),
            },
            {
                icon: <ImageIcon />,
                name: t('NOTIFICATIONS.SEND_NOTIFICATION_WIZARD.COMPOSE.EMAIL.EDITOR_BUTTONS.IMAGE'),
                onClick: () => hiddenFileInputRef.current?.click(),
            },
            {
                icon: <InsertLink />,
                name: t('COMMON.URL'),
                onClick: handleInsertLink,
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [editor?.state],
    );

    return (
        <>
            <MenuBarContainerDiv>
                {buttons.map(button => (
                    <StyledMenuButton
                        $isActive={button.isActive}
                        aria-label={button.name}
                        disabled={!isEditable || button.isDisabled}
                        key={button.name}
                        onClick={() => button.onClick()}
                    >
                        {button.icon}
                    </StyledMenuButton>
                ))}
            </MenuBarContainerDiv>
            <HiddenInput
                accept={acceptFileTypes}
                multiple={multipleFileUploadsAllowed}
                ref={hiddenFileInputRef}
                type="file"
                onChange={handleChange}
            />
            <AddLinkModal open={showAddLinkModal} onCancel={() => setShowAddLinkModal(false)} onSubmit={setLink} />
        </>
    );
}
TipTapEditorMenu.displayName = 'TipTapEditorMenu';
TipTapEditorMenu.padTop = 20;
