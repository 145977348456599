import { gql } from '@apollo/client';
import { LocationGroupThumbnail } from './LocationGroupThumbnail';

export const GET_LOCATION_GROUPS_FOR_LOCATION_GROUPS_THUMBNAIL_LIST = gql`
    query GetLocationGroupsForLocationGroupsThumbnailList($locationId: ID!) {
        location(id: $locationId) {
            id
            groups {
                id
                ...LCOFragmentForLocationGroupThumbnail
            }
        }
    }
    ${LocationGroupThumbnail.fragments.LCO_FRAGMENT_FOR_LOCATION_GROUP_THUMBNAIL}
`;
