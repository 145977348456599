import { gql, useMutation, useQuery } from '@apollo/client';
import { Button, FormGroup, Loading, WizardStepProps } from '@deltasierra/components';
import {
    toNearestNextMinuteInterval,
    toNearestPrevMinuteInterval,
    AssignedLocation,
    SendMailchimpCampaignDto,
} from '@deltasierra/shared';
import { Form, Formik } from 'formik';
import moment from 'moment';
import * as React from 'react';
import { useAngularServiceContext } from '../../../../../common/componentUtils/angularServiceContexts';
import { SchedulePublishTimePicker } from '../../../../../directives/SchedulePublishTimePicker';
import { Translate } from '../../../../../directives/Translate';
import { PublishToMailchimpStep2Data } from './PublishToMailchimpStep2';
import { GetMailchimpAccessLevel, GetMailchimpAccessLevelVariables } from './__graphqlTypes/GetMailchimpAccessLevel';
import { SendMailchimpCampaign, SendMailchimpCampaignVariables } from './__graphqlTypes/SendMailchimpCampaign';

const GET_MAILCHIMP_ACCESS_LEVEL = gql`
    query GetMailchimpAccessLevel($mailchimpId: ID!) {
        mailchimp(id: $mailchimpId) {
            ... on Mailchimp {
                id
                accessLevel {
                    scheduling
                }
            }
        }
    }
`;

const SEND_MAILCHIMP_CAMPAIGN = gql`
    mutation SendMailchimpCampaign($mailchimpId: ID!, $input: SendMailchimpCampaignInput!) {
        sendMailchimpCampaign(id: $mailchimpId, input: $input) {
            __typename
            ... on SendMailchimpCampaignResult {
                campaignId
            }
            ... on MailchimpApiError {
                message
            }
        }
    }
`;

type FormValues = Pick<SendMailchimpCampaignDto, 'scheduleDate'>;

export type PublishToMailchimpStep4Data = {
    formValues: FormValues;
};

export type PublishToMailchimpStep4Props = WizardStepProps<PublishToMailchimpStep4Data> & {
    location: AssignedLocation;
    mailchimpId: string;
    step2Data: PublishToMailchimpStep2Data;
};

// eslint-disable-next-line max-lines-per-function
export const PublishToMailchimpStep4: React.FC<PublishToMailchimpStep4Props> = ({
    CancelButton,
    NextButton,
    PrevButton,
    StepCount,
    gotoNextStep,
    location,
    mailchimpId,
    nextButtonProps,
    step2Data,
}) => {
    const mvNotifier = useAngularServiceContext('mvNotifier');

    const { data: accessLevelData, loading: loadingAccessLevel } = useQuery<
        GetMailchimpAccessLevel,
        GetMailchimpAccessLevelVariables
    >(GET_MAILCHIMP_ACCESS_LEVEL, {
        variables: {
            mailchimpId,
        },
    });

    const [sendMailchimpCampaign, { loading }] = useMutation<SendMailchimpCampaign, SendMailchimpCampaignVariables>(
        SEND_MAILCHIMP_CAMPAIGN,
    );

    const initialValues: FormValues = {};

    if (loadingAccessLevel) {
        return <Loading />;
    }

    const isSchedulingAvailable =
        accessLevelData?.mailchimp.__typename === 'Mailchimp' && accessLevelData.mailchimp.accessLevel.scheduling;

    return (
        <>
            <h4>
                <Translate keyId="BUILD.PUBLISH.MAILCHIMP.SEND" />
            </h4>
            <Formik
                initialValues={initialValues}
                onSubmit={async (values, actions) => {
                    const response = await sendMailchimpCampaign({
                        variables: {
                            input: {
                                campaignId: step2Data.campaignId,
                                scheduleDate: values.scheduleDate?.toISOString(),
                            },
                            mailchimpId,
                        },
                    });

                    if (!response.data) {
                        mvNotifier.unexpectedErrorWithData('The request returned no response data', response);
                        return;
                    }

                    const responseData = response.data;

                    if (responseData.sendMailchimpCampaign.__typename === 'MailchimpApiError') {
                        mvNotifier.unexpectedError(responseData.sendMailchimpCampaign.message);
                        return;
                    }

                    gotoNextStep({
                        formValues: values,
                    });
                }}
            >
                {formIk => (
                    <Form>
                        <SchedulePublishTimePicker
                            disabled={!isSchedulingAvailable}
                            timezone={location.timezone}
                            value={formIk.values.scheduleDate || null}
                            onChange={value => {
                                let newValue = value;
                                const now = new Date();
                                if (newValue) {
                                    const goingDown = (formIk.values.scheduleDate ?? now) > newValue;
                                    newValue = goingDown
                                        ? toNearestPrevMinuteInterval(newValue, 15)
                                        : toNearestNextMinuteInterval(newValue, 15);

                                    if (newValue < now) {
                                        newValue = toNearestNextMinuteInterval(now, 15);
                                    }
                                    newValue = moment(newValue).startOf('minute').toDate();
                                }
                                formIk.setFieldValue('scheduleDate', newValue);
                            }}
                        />
                        {!isSchedulingAvailable && (
                            <div className="help-block">
                                <Translate keyId="BUILD.PUBLISH.MAILCHIMP.SCHEDULING_UNAVAILABLE" />
                            </div>
                        )}
                        <FormGroup fullWidth>
                            <div className="pull-left">
                                <StepCount />
                            </div>
                            <div className="pull-right">
                                <CancelButton />
                                <PrevButton />
                                <Button
                                    {...nextButtonProps}
                                    disabled={loading || !formIk.isValid}
                                    theme="primary"
                                    type="submit"
                                >
                                    <Translate keyId="BUILD.PUBLISH.PUBLISH" />
                                </Button>
                            </div>
                        </FormGroup>
                    </Form>
                )}
            </Formik>
        </>
    );
};
