/// <reference path="../../../typings/browser.d.ts" />
import IDirective = angular.IDirective;

export const selectCountrySID = 'selectCountry';
export const selectCountryConfig: IDirective<ng.IScope> = {
    restrict: 'E',
    replace: true,
    templateUrl: '/partials/directives/selectCountry',
    scope: {
        country: '=',
    },
};

angular.module('app').directive(selectCountrySID, [() => selectCountryConfig]);
