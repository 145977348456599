/// <reference path="../../../typings/browser.d.ts" />
import { $windowSID } from '../common/angularData';
import IWindowService = angular.IWindowService;
import IAugmentedJQuery = angular.IAugmentedJQuery;

export const bottomInViewSID = 'bottomInView';

export const bottomInViewConfig = {
    restrict: 'A',
    scope: {
        bottomInView: '&',
    },
};
angular.module('app').directive(bottomInViewSID, [
    $windowSID,
    ($window: IWindowService) => {
        interface BottomInViewScope extends ng.IScope {
            bottomInView: () => void;
        }

        return {
            ...bottomInViewConfig,
            link(scope: BottomInViewScope, element: IAugmentedJQuery) {
                angular.element($window).bind('scroll', () => {
                    const window = angular.element($window);
                    const bottomOfObject = element.offset()!.top + element.outerHeight();
                    const bottomOfWindow = window.scrollTop() + window.height();
                    if (bottomOfWindow > bottomOfObject) {
                        scope.$apply(() => scope.bottomInView());
                    }
                });
            },
        };
    },
]);
