import { PrintUnits } from '@deltasierra/shared';
import { I18nService } from '../../i18n';

type SelectOption = {
    value: PrintUnits;
    label: string;
};

export class SelectPrintUnitsController {
    // Props
    public printUnits!: PrintUnits;

    // State
    public availableUnits: SelectOption[] = [];

    static readonly $inject: string[] = [I18nService.SID];

    constructor(private readonly i18n: I18nService) {}

    public $onInit(): void {
        this.availableUnits = [
            { value: PrintUnits.inches, label: this.i18n.text.common.units.inches() },
            { value: PrintUnits.millimetres, label: this.i18n.text.common.units.millimeters() },
        ];
    }
}
