
import {
    BuilderTemplateCategoryAdminCreateUpdateDto,
    BuilderTemplateCategoryAdminDto,
    BuilderTemplateCategoryId,
    ClientId,
    isBuilderTemplateCategoryEndDateValid,
    Nullable,
} from '@deltasierra/shared';
import { IQService } from 'angular';
import moment from 'moment-timezone';
import { $qSID, $routeParamsSID, $routeSID, IRoute } from '../../common/angularData';
import { DataUtils } from '../../common/dataUtils';
import { InteractionUtils } from '../../common/interactionUtils';
import { MvNotifier } from '../../common/mvNotifier';
import { I18nService } from '../../i18n';
import { BuilderTemplateCategoryApiClient } from './builderTemplateCategoryApiClient';
import IAngularEvent = angular.IAngularEvent;

type CurrentBuilderTemplateCategory = BuilderTemplateCategoryAdminCreateUpdateDto & {
    id?: BuilderTemplateCategoryId;
};

type BuilderCategoryWithFormattedDate = Omit<BuilderTemplateCategoryAdminDto, 'endDate' | 'startDate'> & {
    endDate: string | null;
    startDate: string;
};

function mapCurrentBuilderTemplateCategoryToAdminDto(
    category: CurrentBuilderTemplateCategory,
): BuilderTemplateCategoryAdminCreateUpdateDto {
    return {
        endDate: category.endDate,
        startDate: category.startDate,
        title: category.title,
    };
}

interface DatePickerState {
    startDate: boolean;
    endDate: boolean;
}

export class BuilderTemplateCategoriesController {
    public static SID = 'BuilderTemplateCategoriesController';

    public static readonly $inject: string[] = [
        $routeParamsSID,
        $routeSID,
        $qSID,
        MvNotifier.SID,
        I18nService.SID,
        InteractionUtils.SID,
        DataUtils.SID,
        BuilderTemplateCategoryApiClient.SID,
    ];

    public clientId: ClientId;

    public builderTemplateCategories: BuilderCategoryWithFormattedDate[] = [];

    public currentBuilderTemplateCategory: Nullable<CurrentBuilderTemplateCategory> = this.newBlankCategory();

    public datePickerIsOpen: DatePickerState = {
        endDate: false,
        startDate: false,
    };

    public fetchCategories = this.interactionUtils.createFuture(this.i18nService.text.common.fetchData(), () =>
        this.builderTemplateCategoryApiClient
            .getAllBuilderTemplateCategoriesForClient(this.clientId)
            .then(categories => {
                const formattedCategories = categories.map(category => ({
                    ...category,
                    endDate: category.endDate ? moment.utc(category.endDate).format('D MMM[.] YYYY') : null,
                    startDate: moment.utc(category.startDate).format('D MMM[.] YYYY'),
                }));
                this.builderTemplateCategories = formattedCategories;
            }),
    );

    public createOrUpdateCategory = this.interactionUtils.createFuture(
        this.i18nService.text.common.save(),
        (): ng.IPromise<void> => {
            if (
                !isBuilderTemplateCategoryEndDateValid(
                    this.currentBuilderTemplateCategory as CurrentBuilderTemplateCategory,
                )
            ) {
                this.mvNotifier.expectedError(
                    this.i18nService.text.agency.client.builderTemplateCategories.endDateMustBeAfterStartDateOrAbsent(),
                );
                // TODO: work out some way to reject a future - that is, mark it as "failed" - without treating it as a catastrophic error.
                return this.$q.resolve();
            } else if (this.currentBuilderTemplateCategory.id) {
                return this.builderTemplateCategoryApiClient
                    .updateBuilderTemplateCategory(
                        this.clientId,
                        this.currentBuilderTemplateCategory.id,
                        mapCurrentBuilderTemplateCategoryToAdminDto(
                            this.currentBuilderTemplateCategory as CurrentBuilderTemplateCategory,
                        ),
                    )
                    .then(() => this.reloadPage());
            } else {
                return this.builderTemplateCategoryApiClient
                    .createBuilderTemplateCategory(
                        this.clientId,
                        mapCurrentBuilderTemplateCategoryToAdminDto(
                            this.currentBuilderTemplateCategory as CurrentBuilderTemplateCategory,
                        ),
                    )
                    .then(() => this.reloadPage());
            }
        },
    );

    public deleteCategory = this.interactionUtils.createFuture<void, { categoryId: BuilderTemplateCategoryId }>(
        this.i18nService.text.common.delete(),
        context =>
            this.builderTemplateCategoryApiClient
                .deleteBuilderTemplateCategory(this.clientId, context.categoryId)
                .then(_response => {
                    this.builderTemplateCategories = this.builderTemplateCategories.filter(
                        x => x.id !== context.categoryId,
                    );
                }),
    );

    public reorderCategory = this.interactionUtils.createFuture<void, { originalOrder: BuilderTemplateCategoryId[] }>(
        this.i18nService.text.common.update(),
        async context => {
            const newOrder = this.getOrder();
            return this.builderTemplateCategoryApiClient
                .reorderBuilderTemplateCategories(this.clientId, newOrder)
                .then(() => {
                    this.applyOrder(newOrder);
                })
                .catch(err => {
                    this.applyOrder(context.originalOrder);
                    throw err;
                });
        },
    );


    public constructor(
        $routeParams: { id: string },
        protected readonly $route: IRoute,
        protected readonly $q: IQService,
        protected readonly mvNotifier: MvNotifier,
        protected readonly i18nService: I18nService,
        protected readonly interactionUtils: InteractionUtils,
        protected readonly dataUtils: DataUtils,
        protected readonly builderTemplateCategoryApiClient: BuilderTemplateCategoryApiClient,
    ) {
        this.clientId = ClientId.from(parseInt($routeParams.id, 10));
        void this.fetchCategories.run({});
    }

    public moveOrderUp(category: BuilderCategoryWithFormattedDate): ng.IPromise<void> | undefined {
        const originalOrder = this.getOrder();
        const wasMoved = this.dataUtils.moveUp(category, this.builderTemplateCategories);
        if (wasMoved) {
            return this.reorderCategory.run({ originalOrder });
        }
    }

    public moveOrderDown(category: BuilderCategoryWithFormattedDate): ng.IPromise<void> | undefined {
        const originalOrder = this.getOrder();
        const wasMoved = this.dataUtils.moveDown(category, this.builderTemplateCategories);
        if (wasMoved) {
            return this.reorderCategory.run({ originalOrder });
        }
    }

    public openDatePicker($event: IAngularEvent, datePickerName: keyof DatePickerState): void {
        $event.preventDefault();
        if ($event.stopPropagation) {
            $event.stopPropagation();
        }
        for (const key in this.datePickerIsOpen) {
            // eslint-disable-next-line no-prototype-builtins
            if (this.datePickerIsOpen.hasOwnProperty(key)) {
                this.datePickerIsOpen[key as keyof DatePickerState] = false;
            }
        }
        this.datePickerIsOpen[datePickerName] = true;
    }

    public editBuilderTemplateCategory(entry: BuilderCategoryWithFormattedDate): void {
        this.currentBuilderTemplateCategory = {
            endDate: entry.endDate ? moment(entry.endDate, 'D MMM YYYY').toDate() : null,
            id: entry.id,
            startDate: moment(entry.startDate, 'D MMM YYYY').toDate(),
            title: entry.title,
        };
    }

    public cancelBuilderTemplateCategory(): void {
        this.currentBuilderTemplateCategory = this.newBlankCategory();
    }

    public isUpdateInProgress(): boolean {
        return (
            this.createOrUpdateCategory.isRunning() ||
            this.deleteCategory.isRunning() ||
            this.reorderCategory.isRunning()
        );
    }

    protected newBlankCategory(): Nullable<BuilderTemplateCategoryAdminCreateUpdateDto> {
        return {
            endDate: null,
            startDate: null,
            title: null,
        };
    }

    protected getOrder(): BuilderTemplateCategoryId[] {
        return this.builderTemplateCategories.map(btc => btc.id);
    }

    protected applyOrder(newOrder: BuilderTemplateCategoryId[]): void {
        const sortedCategories = [];
        for (const category of this.builderTemplateCategories) {
            const newIndex = newOrder.indexOf(category.id);
            if (newIndex !== -1) {
                sortedCategories[newIndex] = category;
                category.order = newIndex + 1;
            }
        }
        this.builderTemplateCategories = sortedCategories;
    }

    protected reloadPage(): void {
        this.$route.reload();
    }
}

angular.module('app').controller(BuilderTemplateCategoriesController.SID, BuilderTemplateCategoriesController);
