/// <reference path="../../../../typings/browser.d.ts" />
import { actualComponent, OneWayBinding, ExpressionBinding } from '../../common/angularData';
import { ShippingAddressEditorController } from './controller';

export const shippingAddressEditorSID = 'shippingAddressEditor';
export const shippingAddressEditorConfig = actualComponent(
    ShippingAddressEditorController,
    '/partials/directives/shippingAddressEditor/template',
    {
        locationShippingDetails: OneWayBinding,
        onChange: ExpressionBinding,
        editingRequired: OneWayBinding,
    },
);

angular.module('app').component(shippingAddressEditorSID, shippingAddressEditorConfig);
