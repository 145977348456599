import * as React from 'react';
import {
    DSAccordionDetailsInline,
    DSAccordionInline,
    DSAccordionSummaryInline,
    DSButton,
    DSDialog,
    DSDialogActions,
    DSDialogContent,
    DSDialogContentText,
    DSDialogTitle,
    DSFormControlLabel,
    DSList,
    DSListItem,
    DSListItemIcon,
    DSListItemText,
    DSRadio,
    DSRadioGroup,
    Translate,
} from '@deltasierra/components';
import styled from 'styled-components';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { LocationDraftLocation } from '../../hooks/use-location-drafts-count';

export type UpdateTemplateConfirmationModalProps = {
    show: boolean;
    locationDraftsCount: number;
    locationDraftsLocationList: LocationDraftLocation[];
    onCancel?: () => void;
    onConfirm?: (deleteLocationDrafts: boolean) => void;
};

const LocationIcon = styled.i.attrs({ className: 'fas fa-location-arrow' })`
    font-size: 18px;
`;

export function UpdateTemplateConfirmationModal({
    locationDraftsCount,
    locationDraftsLocationList,
    onCancel,
    onConfirm,
    show,
}: UpdateTemplateConfirmationModalProps): JSX.Element {
    const [deleteDrafts, setDeleteDrafts] = React.useState<boolean | null>(null);

    const handleClickCancel: React.MouseEventHandler = () => {
        onCancel?.();
    };

    const handleClickUpdate: React.MouseEventHandler = () => {
        if (typeof deleteDrafts === 'boolean') {
            onConfirm?.(deleteDrafts);
        }
    };

    return (
        <DSDialog open={show}>
            <DSDialogTitle>
                <Translate keyId="BUILD.BUILDER_TEMPLATE_UPDATE_CONFIRMATION_MODAL.TITLE" />
            </DSDialogTitle>
            <DSDialogContent>
                <DSDialogContentText>
                    <Translate
                        keyId="BUILD.BUILDER_TEMPLATE_UPDATE_CONFIRMATION_MODAL.CONFIRMATION_MESSAGE"
                        options={{ count: locationDraftsCount }}
                    />
                </DSDialogContentText>

                <DSRadioGroup value={deleteDrafts} onChange={event => setDeleteDrafts(event.target.value === 'true')}>
                    <DSFormControlLabel
                        control={<DSRadio />}
                        data-cy="keep-location-drafts-label"
                        label={
                            <Translate keyId="BUILD.BUILDER_TEMPLATE_UPDATE_CONFIRMATION_MODAL.KEEP_LOCATION_DRAFTS" />
                        }
                        value={false}
                    />
                    <DSFormControlLabel
                        control={<DSRadio />}
                        label={
                            <Translate keyId="BUILD.BUILDER_TEMPLATE_UPDATE_CONFIRMATION_MODAL.DELETE_LOCATION_DRAFTS" />
                        }
                        value={true}
                    />
                </DSRadioGroup>

                {locationDraftsLocationList.length > 0 && (
                    <DSAccordionInline>
                        <DSAccordionSummaryInline expandIcon={<ExpandMoreIcon />}>
                            <Translate keyId="BUILD.BUILDER_TEMPLATE_UPDATE_CONFIRMATION_MODAL.AFFECTED_LOCATIONS" />
                        </DSAccordionSummaryInline>
                        <DSAccordionDetailsInline>
                            <DSList>
                                {locationDraftsLocationList.map(location => (
                                    <DSListItem dense key={location.id}>
                                        <DSListItemIcon>
                                            <LocationIcon />
                                        </DSListItemIcon>
                                        <DSListItemText color="textPrimary">{location.title}</DSListItemText>
                                    </DSListItem>
                                ))}
                            </DSList>
                        </DSAccordionDetailsInline>
                    </DSAccordionInline>
                )}
            </DSDialogContent>

            <DSDialogActions>
                <DSButton color="default" variant="outlined" onClick={handleClickCancel}>
                    <Translate keyId="COMMON.CANCEL" />
                </DSButton>

                <DSButton
                    color="primary"
                    data-cy="confirm-save-template-modal-button"
                    disabled={typeof deleteDrafts !== 'boolean'}
                    onClick={handleClickUpdate}
                >
                    <>
                        {typeof deleteDrafts !== 'boolean' && <Translate keyId="COMMON.UPDATE" />}
                        {typeof deleteDrafts === 'boolean' && !deleteDrafts && (
                            <Translate keyId="BUILD.BUILDER_TEMPLATE_UPDATE_CONFIRMATION_MODAL.KEEP_LOCATION_DRAFTS" />
                        )}
                        {deleteDrafts && (
                            <Translate keyId="BUILD.BUILDER_TEMPLATE_UPDATE_CONFIRMATION_MODAL.DELETE_LOCATION_DRAFTS" />
                        )}
                    </>
                </DSButton>
            </DSDialogActions>
        </DSDialog>
    );
}

UpdateTemplateConfirmationModal.displayName = 'UpdateTemplateConfirmationModal';
