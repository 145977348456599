import { gql } from '@apollo/client';
import { ClubReadyChainIdConfigurationTable } from './ClubReadyChainIdConfigurationTable';

export const GET_ADMIN_CLUBREADY_CONNECT_PAGE_CLIENTS = gql`
    query GetAdminClubReadyConnectPageClients($after: String, $first: Int, $filter: AdminClientsFilterInput) {
        admin {
            id
            clients(after: $after, first: $first, filter: $filter) {
                edges {
                    node {
                        id
                        ...ClubReadyChainIdConfigurationTableFragment
                    }
                }
                pageInfo {
                    hasNextPage
                    endCursor
                }
            }
        }
    }
    ${ClubReadyChainIdConfigurationTable.fragments.CLUBREADY_CHAIN_ID_CONFIGURATION_TABLE_FRAGMENT}
`;
