/// <reference path="../../../../typings/browser.d.ts" />
import { $qSID } from '../../common/angularData';
import IQService = angular.IQService;
import IQResolveReject = angular.IQResolveReject;
import IPromise = angular.IPromise;

export const fileReaderServiceSID = 'fileReaderService';

export interface IFileReaderService {
    readFileAsArrayBuffer(file: File): IPromise<ArrayBuffer>;
    readFileAsText(file: File): IPromise<string>;
}

angular.module('app').service(fileReaderServiceSID, [
    $qSID,
    function ($q: IQService) {
        function readFileAsArrayBuffer(file: File) {
            return $q((resolve: IQResolveReject<ArrayBuffer>, reject: IQResolveReject<any>) => {
                const reader = new FileReader();
                reader.onloadend = function () {
                    return resolve(reader.result as ArrayBuffer);
                };
                reader.onabort = reject;
                reader.onerror = reject;
                reader.readAsArrayBuffer(file);
            });
        }

        function readFileAsText(file: File) {
            return $q((resolve: IQResolveReject<string>, reject: IQResolveReject<any>) => {
                const reader = new FileReader();
                reader.onloadend = function () {
                    return resolve(reader.result as string);
                };
                reader.onabort = reject;
                reader.onerror = reject;
                reader.readAsText(file);
            });
        }

        return {
            readFileAsArrayBuffer,
            readFileAsText,
        } as IFileReaderService;
    },
]);
