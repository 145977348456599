import * as React from 'react';
import {
    DSDialog,
    DSDialogTitle,
    DSDialogContent,
    DSDialogActions,
    DSButton,
    DSTextField,
    Translate,
} from '@deltasierra/components';

export interface AddLinkModalProps {
    onCancel: () => void;
    onSubmit: (url: string) => void;
    open: boolean;
}

export function AddLinkModal({ onCancel, onSubmit, open }: AddLinkModalProps): JSX.Element {
    const [url, setUrl] = React.useState('');

    const handleCancel = React.useCallback(() => {
        onCancel();
        setUrl('');
    }, [onCancel]);

    const handleSubmit = React.useCallback(() => {
        onSubmit(url);
        setUrl('');
    }, [url, onSubmit]);

    return (
        <DSDialog fullWidth maxWidth="sm" open={open} style={{ padding: 10 }}>
            <DSDialogTitle>
                <Translate keyId="COMMON.URL" />
            </DSDialogTitle>
            <DSDialogContent>
                <DSTextField fullWidth value={url} onChange={event => setUrl(event.target.value)} />
            </DSDialogContent>
            <DSDialogActions>
                <DSButton color="default" variant="outlined" onClick={handleCancel}>
                    <Translate keyId="COMMON.CANCEL" />
                </DSButton>
                <DSButton
                    color="primary"
                    disabled={url === ''}
                    type="submit"
                    variant="contained"
                    onClick={handleSubmit}
                >
                    <Translate keyId="COMMON.ADD" />
                </DSButton>
            </DSDialogActions>
        </DSDialog>
    );
}
AddLinkModal.displayName = 'AddLinkModal';
