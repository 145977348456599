import * as React from 'react';
import { Button } from '@deltasierra/components';
import { t } from '@deltasierra/shared';
import { LoadingContext } from './LocationDetailsModalFormikWrapper';

export type LocationDetailsModalFooterProps = {
    submitForm: () => void;
    isValid: boolean;
    onCancel: () => void;
};

const LocationDetailsModalFooter: React.FC<LocationDetailsModalFooterProps> = ({ submitForm, onCancel, isValid }) => {
    const isLoading = React.useContext(LoadingContext);

    return (
        <div>
            <span style={{ float: 'left' }}>
                <Button disabled={isLoading} label={t('COMMON.CANCEL')} onClick={onCancel} />
            </span>
            <Button disabled={isLoading || !isValid} label={t('COMMON.SAVE')} theme="primary" onClick={submitForm} />
        </div>
    );
};
LocationDetailsModalFooter.displayName = 'LocationDetailsModalFooter';

export default LocationDetailsModalFooter;
