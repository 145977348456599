/// <reference path="../../../typings/browser.d.ts" />
import { Tag } from '@deltasierra/shared';
import { TagResource, tagResourceSID } from './tagResource';
import IPromise = angular.IPromise;
export const tagServiceSID = 'tagService';

export interface TagService {
    createTags(text: string): IPromise<Tag[]>;
}

angular.module('app').service(tagServiceSID, [
    tagResourceSID,
    function (tagResource: TagResource) {
        return {
            createTags(text: string): IPromise<Tag[]> {
                const textValues = text.toLocaleLowerCase().replace(/[,]/g, ' ').split(' ');
                for (let i = textValues.length - 1; i >= 0; i--) {
                    const textValue = textValues[i];
                    if (!textValue || !textValue.replace(/^\s+|\s+$/g, '')) {
                        textValues.splice(i, 1);
                    }
                }
                const result = tagResource.saveAll(textValues);
                return result.$promise;
            },
        };
    },
]);
