/// <reference path="../../../typings/browser.d.ts" />
import { ILocationService, IScope, IWindowService } from 'angular';
import { IntroName, assertNever, t } from '@deltasierra/shared';
import * as linq from 'linq';


import { MvIdentity } from '../account/mvIdentity';
import { MvNotifier } from '../common/mvNotifier';
import { Intro, IntroService } from '../intro/introService';
import { IntroWrapper } from '../intro/introWrapper';
import { UserService } from '../account/userService';
import { $locationSID, $scopeSID, $windowSID } from '../common/angularData';
import IPromise = angular.IPromise;

interface CompletedIntro extends Intro {
    isCompleted: boolean;
}

export class IntroController {
    public static SID = 'mvIntroCtrl';

    public static readonly $inject: string[] = [
        $windowSID,
        $locationSID,
        $scopeSID,
        MvIdentity.SID,
        MvNotifier.SID,
        IntroService.SID,
        UserService.SID,
    ];

    public identity = this.mvIdentity;

    public loading = true;

    public finalCompletionPage = false;

    public introApps: IntroWrapper;

    public introBuild: IntroWrapper;

    public introLearn: IntroWrapper;

    public introPlan: IntroWrapper;

    public introReport: IntroWrapper;

    public intros: CompletedIntro[] = [];

    public completionPage = this.introService.getCompletionPage();

    public totalIntros = this.introService.countIntros();

    public totalIntrosComplete = 0;

    // eslint-disable-next-line max-params
    public constructor(
        private readonly $window: IWindowService,
        private readonly $location: ILocationService,
        private readonly $scope: IScope,
        private readonly mvIdentity: MvIdentity,
        private readonly mvNotifier: MvNotifier,
        private readonly introService: IntroService,
        private readonly userService: UserService,
    ) {
        this.introService.abortIntro(); // Initialise to no current Intro in progress

        this.introService.setUpIntro('video', this.$scope);
        this.introApps = this.introService.setUpIntro('apps', this.$scope);
        this.introPlan = this.introService.setUpIntro('plan', this.$scope);
        this.introBuild = this.introService.setUpIntro('build', this.$scope);
        this.introReport = this.introService.setUpIntro('report', this.$scope);
        this.introLearn = this.introService.setUpIntro('learn', this.$scope);

        void this.refreshIntros();
    }

    public areIntrosLoaded(): boolean {
        return this.intros && this.intros.length > 0;
    }

    public isVideoWatched(): boolean {
        return this.isIntroComplete('video');
    }

    public isIntroComplete(name: IntroName): boolean {
        const intro = linq.from(this.intros).firstOrDefault(i => i.name === name);
        return intro && intro.isCompleted;
    }

    public areAllIntrosComplete(): boolean {
        return linq
            .from(this.intros)
            .all(intro => intro.isCompleted === true || (!!intro.accessCheck && !intro.accessCheck()));
    }

    public countIntrosComplete(): number {
        return linq
            .from(this.intros)
            .count(intro => intro.isCompleted === true && (!intro.accessCheck || intro.accessCheck()));
    }

    public refreshIntros(): IPromise<void> {
        return this.introService.getGuidedTour().then(guidedTour => {
            const intros = this.introService.getIntros().map(intro => ({
                ...intro,
                isCompleted: guidedTour[intro.name],
            }));

            this.intros = intros;
            this.totalIntrosComplete = this.countIntrosComplete();
        });
    }

    public showIntros(): IPromise<void> {
        this.introService.setCurrentIntro('video');
        return this.introService
            .completeIntro()
            .then(() => this.refreshIntros())
            .then(() => {
                this.totalIntrosComplete = this.countIntrosComplete();
                this.$window.scrollTo(0, 0);
            });
    }

    public startIntro(name: IntroName): void {
        this.introService.setCurrentIntro(name);
        if (name !== 'video') {
            this.ensureNavbarCollapseMenuIsExpanded();
        }
        let intro: IntroWrapper | undefined;
        switch (name) {
            case 'video':
                this.$window.scrollTo(0, 0);
                break;
            case 'apps':
                intro = this.introApps;
                break;
            case 'plan':
                intro = this.introPlan;
                break;
            case 'build':
                intro = this.introBuild;
                break;
            case 'report':
                intro = this.introReport;
                break;
            case 'learn':
                intro = this.introLearn;
                break;
            default:
                throw assertNever(name);
        }
        if (intro) {
            this.introService.startIntro(intro);
        }
    }

    public hideCompletionPage(): void {
        this.$window.scrollTo(0, 0);
        this.introService.hideCompletionPage();
        this.completionPage = null;
        if (this.areAllIntrosComplete()) {
            this.finalCompletionPage = true;
        }
    }

    public resetIntros(): IPromise<void> {
        return this.userService.resetIntros().then(() => {
            this.$window.scrollTo(0, 0);
            this.$location.url('/');
        });
    }

    public endGuidedTour(): IPromise<void> {
        return this.userService
            .updateGuidedTour({
                guidedTourEnabled: false,
                guidedTourPostponed: false,
            })
            .then(() => {
                this.mvNotifier.info(t('INTRO.NOTIFY_STOP_TOUR'));
                this.mvIdentity.setGuidedTourEnabled(false);
                this.$location.url('/');
            });
    }

    public postponeGuidedTour(): IPromise<void> {
        return this.userService
            .updateGuidedTour({
                guidedTourEnabled: true,
                guidedTourPostponed: true,
            })
            .then(() => {
                this.mvNotifier.info(t('INTRO.NOTIFY_POSTPONE_TOUR'));
                this.mvIdentity.setGuidedTourPostponed(true);
                this.$location.url('/');
            });
    }

    public canAccessIntro(intro: Intro): boolean {
        if (intro && intro.accessCheck) {
            return intro.accessCheck();
        } else {
            return true;
        }
    }

    /**
     * DS-2018: expand the collapse menu if it's present and collapsed.
     */
    protected ensureNavbarCollapseMenuIsExpanded(): void {
        const collapseButton = angular.element('#navbar-collapse-button:visible');
        if (collapseButton.length > 0) {
            $('#navbar-collapse-content').collapse('show');
        }
    }
}

angular.module('app').controller(IntroController.SID, IntroController);
