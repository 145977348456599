import { isNullOrUndefined } from '@deltasierra/type-utilities';

export function relayConnectionToArray<T>(connection?: { edges: ReadonlyArray<{ node: T }> }): T[] {
    return connection?.edges.map(({ node }) => node) ?? [];
}

// This really shouldn't be here, but this PR required a quick way on the front end to get an asset id from an asset node id
/**
 * Parses and validates a graphql node id into it's integer value and casts as T
 *
 * @deprecated We shouldn't need to parse node ids on the front end.  See DS-5128
 * @param id - node id to parse
 * @param type - the type of node that it is e.g. 'brand'
 * @returns integer id as generic type T
 */
export function parseAndValidateUniversalNodeId<T extends number = number>(id: string, type?: string): T | null {
    const parsed = parseUniversalNodeId(id);
    if (!parsed) {
        return null;
    }

    const [typename, integerId] = parsed;
    if (!integerId) {
        return null;
    }

    if (!isNullOrUndefined(type) && type !== typename) {
        return null;
    }

    return integerId as T;
}

// This really shouldn't be here, but this PR required a quick way on the front end to get an asset id from an asset node id
/**
 * @deprecated
 * Splits an encoded node id into a delimited unencoded string and integer
 * @deprecated We shouldn't need to parse node ids on the front end.  See DS-5128
 * @param id - node id to parse
 * @returns [string bit before the ., integer bit after the .]
 */
export function parseUniversalNodeId(id: string): [string, number | null] | null {
    const decryptedId = Buffer.from(id, 'base64').toString();
    const parts = decryptedId.split('.');
    if (parts.length !== 2) {
        return null;
    }

    const [typePart, idPart] = parts;
    const integerId = parseInt(idPart, 10);
    if (isNaN(integerId)) {
        return [typePart, null];
    }
    return [typePart, integerId];
}

// !WARNING!
// This really shouldn't be here, but this PR required a quick way on the front end to create a graphql id from an raw id
/**
 * @deprecated
 * Create a universal Node ID which represents the given entity ID.
 * @example
 * toNodeId('user', 123)
 * @param type - The entity type the ID refers to
 * @param id - The ID of the entity
 * @returns A universal Node ID
 */
export function convertIdToUniversalNodeId(type: string, id: number): string {
    assertTypeIsNotEmptyString(type);
    return Buffer.from(`${type}.${id}`).toString('base64');
}

function assertTypeIsNotEmptyString(type: string) {
    if (!type) {
        throw new Error('Argument \'type\' must not be an empty string');
    }
}
