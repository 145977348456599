import React from 'react';
import { FormikProps } from 'formik';
import { t } from '@deltasierra/shared';
import { PublishToClubReadyStep1FormValues } from '../../../types/publish-to-club-ready-step-1-form-values';
import { ClubReadyListOptionBase } from './ClubReadyListOptionBase';

type ClubReadyCooledOffProspectsListOptionProps = {
    formik: FormikProps<PublishToClubReadyStep1FormValues>;
};

export function ClubReadyCooledOffProspectsListOption({
    formik,
}: ClubReadyCooledOffProspectsListOptionProps): JSX.Element {
    return (
        <ClubReadyListOptionBase
            formik={formik}
            listKey="cooledOffProspects"
            listName={t('BUILD.PUBLISH.CLUB_READY.COOLED_OFF_PROSPECTS')}
        />
    );
}

ClubReadyCooledOffProspectsListOption.displayName = 'ClubReadyCooledOffProspectsListOption';
