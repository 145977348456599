/// <reference path='../../../../typings/browser.d.ts' />
import { Activity, Channel } from '@deltasierra/shared';
import { MvActivity } from '../../activities/mvActivity';
import { $modalInstanceSID } from '../../common/angularUIBootstrapData';
import { InteractionUtils } from '../../common/interactionUtils';
import { MvNotifier } from '../../common/mvNotifier';
import { I18nService } from '../../i18n';
import { ModalInstance } from '../../typings/angularUIBootstrap/modalService';

export class ChannelMigratorController {
    public static SID = 'ChannelMigratorController';

    replacementChannel?: Channel;

    private deleteChannel = this.interactionUtils.createFuture<void, { channel: Channel }>(
        this.i18n.text.agency.client.activities.deleteChannel(),
        context =>
            this.mvActivity
                .deleteChannel(context.channel.id, this.replacementChannel ? this.replacementChannel.id : undefined)
                .then(() =>
                    this.mvNotifier.notify(
                        this.i18n.text.agency.client.location.activities.migrateChannel.channelDeleted(),
                    ),
                ),
    );

    static readonly $inject: string[] = [
        I18nService.SID,
        InteractionUtils.SID,
        MvNotifier.SID,
        MvActivity.SID,
        $modalInstanceSID,
        'channel',
        'activities',
    ];

    constructor(
        private readonly i18n: I18nService,
        private readonly interactionUtils: InteractionUtils,
        private readonly mvNotifier: MvNotifier,
        private readonly mvActivity: MvActivity,
        private readonly $modalInstance: ModalInstance,
        private readonly channel: Channel,
        public readonly activities: Activity[],
    ) {}

    proceed() {
        return this.deleteChannel.run({ channel: this.channel }).then(() => {
            this.$modalInstance.close(this.replacementChannel);
        });
    }

    cancel() {
        this.$modalInstance.dismiss();
    }

    selectReplacementChannel(replacementChannel: Channel) {
        this.replacementChannel = replacementChannel;
    }
}

angular.module('app').controller(ChannelMigratorController.SID, ChannelMigratorController);
