import { gql, useLazyQuery, useQuery } from '@apollo/client';
import {
    DSButton,
    DSDialogActions,
    DSDialogContent,
    DSDialogTitle,
    DSTypography,
    SvgLoading,
} from '@deltasierra/components';
import { t } from '@deltasierra/shared';
import React, { useCallback } from 'react';
import { CollectionBreadcrumbs } from './CollectionBreadcrumbs';
import { FolderList, FolderListProps } from './FolderList';
import {
    GetAssetForMoveFolderDialog,
    GetAssetForMoveFolderDialogVariables,
} from './__graphqlTypes/GetAssetForMoveFolderDialog';
import { GetLocationCollectionsForMoveFolderDialog } from './__graphqlTypes/GetLocationCollectionsForMoveFolderDialog';
import { useMoveAssets } from './useMoveAssets';

const GET_ASSET_FOR_MOVE_FOLDER_DIALOG = gql`
    query GetAssetForMoveFolderDialog($assetOrCollectionId: ID!, $hasAssetOrCollectionId: Boolean!) {
        collectionOrAsset(id: $assetOrCollectionId) @include(if: $hasAssetOrCollectionId) {
            __typename
            ...AssetOrCollectionFragmentForCollectionBreadcrumbs
            ... on AssetFolder {
                id
                assets {
                    ...AssetConnectionFragmentForFolderList
                }
            }
            ... on Collection {
                id
                assets {
                    ...AssetConnectionFragmentForFolderList
                }
            }
        }
    }
    ${CollectionBreadcrumbs.fragments.ASSET_OR_COLLECTION_FRAGMENT_FOR_COLLECTION_BREADCRUMBS}
    ${FolderList.fragments.ASSET_CONNECTION_FRAGMENT_FOR_FOLDER_LIST}
`;

const GET_LOCATION_COLLECTIONS_FOR_MOVE_FOLDER_DIALOG = gql`
    query GetLocationCollectionsForMoveFolderDialog($locationId: ID!, $hasLocation: Boolean! = false) {
        hasCurrentLocation @client @export(as: "hasLocation")
        currentLocationId @client @export(as: "locationId")
        location(id: $locationId) @include(if: $hasLocation) {
            id
            ...LocationFragmentForFolderList
        }
    }
    ${FolderList.fragments.LOCATION_FRAGMENT_FOR_FOLDER_LIST}
`;

function useDialogTitle({
    assetIdsToMove,
    assetsLeftToMove,
    folderData,
    isMoving,
    isSelectable,
}: {
    assetIdsToMove: ReadonlyArray<string>;
    assetsLeftToMove: ReturnType<typeof useMoveAssets>['assetsLeftToMove'];
    folderData: GetAssetForMoveFolderDialog | undefined;
    isMoving: ReturnType<typeof useMoveAssets>['isMoving'];
    isSelectable: boolean;
}): string {
    if (isMoving) {
        return t('ASSET_LIBRARY.MOVE_ASSETS_TO_FOLDER', {
            folder: getTitle(folderData),
            num: assetsLeftToMove,
        });
    }
    if (!folderData || isSelectable) {
        return t('ASSET_LIBRARY.MOVE_ASSETS_TO_FOLDER', {
            folder: getTitle(folderData),
            num: assetIdsToMove.length,
        });
    }
    return t('ASSET_LIBRARY.PLEASE_SELECT_COLLECTION');
}

export type MoveFolderDialogContentProps = {
    assetIdsToMove: ReadonlyArray<string>;
    assetsLeftToMove: ReturnType<typeof useMoveAssets>['assetsLeftToMove'];
    initialCollectionOrFolderId: string;
    isMoving: ReturnType<typeof useMoveAssets>['isMoving'];
    moveAssets: ReturnType<typeof useMoveAssets>['moveAssets'];
    onRequestClose?: () => void;
};

export function MoveFolderDialogContent({
    assetIdsToMove,
    assetsLeftToMove,
    initialCollectionOrFolderId,
    isMoving,
    moveAssets,
    onRequestClose,
}: MoveFolderDialogContentProps): JSX.Element {
    const [currentCollectionOrFolderId, setCurrentCollectionOrFolderId] = React.useState<string | null>(
        initialCollectionOrFolderId,
    );

    const { data: folderData } = useQuery<GetAssetForMoveFolderDialog, GetAssetForMoveFolderDialogVariables>(
        GET_ASSET_FOR_MOVE_FOLDER_DIALOG,
        {
            notifyOnNetworkStatusChange: true,
            variables: {
                assetOrCollectionId: currentCollectionOrFolderId ?? '-',
                hasAssetOrCollectionId: !!currentCollectionOrFolderId,
            },
        },
    );
    const [getCollections, { data: collectionsData }] = useLazyQuery<GetLocationCollectionsForMoveFolderDialog>(
        GET_LOCATION_COLLECTIONS_FOR_MOVE_FOLDER_DIALOG,
    );
    const handleAssetIdChange = React.useCallback(
        (id: string | null) => {
            setCurrentCollectionOrFolderId(id);
            if (id === null) {
                getCollections();
            }
        },
        [getCollections],
    );

    const targetCollectionOrFolderId = currentCollectionOrFolderId ?? '-';

    const handleMove = useCallback(async () => {
        await moveAssets(
            assetIdsToMove.map(assetId => ({
                assetId,
                targetCollectionOrFolderId,
            })),
        );
        onRequestClose?.();
    }, [assetIdsToMove, moveAssets, onRequestClose, targetCollectionOrFolderId]);

    let folders: FolderListProps['folderSource'] = null;
    let isSelectable = false;
    if (
        folderData?.collectionOrAsset?.__typename === 'Collection' ||
        folderData?.collectionOrAsset?.__typename === 'AssetFolder'
    ) {
        isSelectable = true;
        folders = folderData.collectionOrAsset.assets;
    } else if (collectionsData?.location) {
        folders = collectionsData.location;
    }

    const dialogTitle = useDialogTitle({
        assetIdsToMove,
        assetsLeftToMove,
        folderData,
        isMoving,
        isSelectable,
    });

    return (
        <>
            <DSDialogTitle>{dialogTitle}</DSDialogTitle>
            <DSDialogContent>
                {!!folderData && (
                    <CollectionBreadcrumbs
                        assetOrCollection={folderData.collectionOrAsset}
                        onCrumbClicked={handleAssetIdChange}
                    />
                )}
                <FolderList folderSource={folders} onFolderSelected={handleAssetIdChange} />
                {!!folderData && !isSelectable && (
                    <DSTypography color="error">{`* ${t('ASSET_LIBRARY.PLEASE_SELECT_COLLECTION')}`}</DSTypography>
                )}
                {isMoving && <DSTypography>{t('ASSET_LIBRARY.MOVING_ASSETS', { num: assetsLeftToMove })}</DSTypography>}
            </DSDialogContent>
            <DSDialogActions>
                <DSButton color="default" disabled={isMoving} variant="contained" onClick={onRequestClose}>
                    {t('COMMON.CANCEL')}
                </DSButton>
                <DSButton
                    color="primary"
                    data-cy="submit-button"
                    disabled={!isSelectable || isMoving}
                    variant="contained"
                    onClick={handleMove}
                >
                    {isMoving ? <SvgLoading /> : t('COMMON.SUBMIT')}
                </DSButton>
            </DSDialogActions>
        </>
    );
}
MoveFolderDialogContent.displayName = 'MoveFolderDialogContent';

function getTitle(result: GetAssetForMoveFolderDialog | undefined): string {
    return result?.collectionOrAsset?.__typename === 'Collection' ||
        result?.collectionOrAsset?.__typename === 'AssetFolder'
        ? result.collectionOrAsset.title
        : '';
}
