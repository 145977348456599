const calculatePreviousValue = <TValue, TError = any>(
    previousState?: FutureState<TValue, TError>,
): TValue | undefined => {
    if (!previousState) {
        return undefined;
    } else {
        return previousState.isFinished ? previousState.value : previousState.previousValue;
    }
};

export type PendingState<TValue> = {
    isPending: true;
    isLoading: false;
    isRunning: false;
    isFinished: false;
    isFailed: false;
    previousValue?: TValue;
};

export const createPendingState = <TValue, TError = any>(
    previousState?: FutureState<TValue, TError>,
): PendingState<TValue> => ({
    isFailed: false,
    isFinished: false,
    isLoading: false,
    isPending: true,
    isRunning: false,
    previousValue: calculatePreviousValue(previousState),
});

export type LoadingState<TValue> = {
    isPending: false;
    isLoading: true;
    isRunning: true;
    isFinished: false;
    isFailed: false;
    previousValue?: TValue;
};

export const createLoadingState = <TValue, TError = any>(
    previousState?: FutureState<TValue, TError>,
): LoadingState<TValue> => ({
    isFailed: false,
    isFinished: false,
    isLoading: true,
    isPending: false,
    isRunning: true,
    previousValue: calculatePreviousValue(previousState),
});

export type FinishedState<TValue> = {
    isPending: false;
    isLoading: false;
    isRunning: false;
    isFinished: true;
    isFailed: false;
    value: TValue;
    previousValue?: TValue;
};

export const createFinishedState = <TValue, TError = any>(
    value: TValue,
    previousState?: FutureState<TValue, TError>,
): FinishedState<TValue> => ({
    isFailed: false,
    isFinished: true,
    isLoading: false,
    isPending: false,
    isRunning: false,
    previousValue: calculatePreviousValue(previousState),
    value,
});

export type FailedState<TValue, TError> = {
    isPending: false;
    isLoading: false;
    isRunning: false;
    isFinished: false;
    isFailed: true;
    error: TError;
    previousValue?: TValue;
};

export const createFailedState = <TValue, TError>(
    error: TError,
    previousState?: FutureState<TValue, TError>,
): FailedState<TValue, TError> => ({
    error,
    isFailed: true,
    isFinished: false,
    isLoading: false,
    isPending: false,
    isRunning: false,
    previousValue: calculatePreviousValue(previousState),
});

export type FutureState<TValue, TError = any> =
    | FailedState<TValue, TError>
    | FinishedState<TValue>
    | LoadingState<TValue>
    | PendingState<TValue>;

export type FutureStateWithoutPending<TValue, TError = any> =
    | Omit<FailedState<TValue, TError>, 'isPending'>
    | Omit<FinishedState<TValue>, 'isPending'>
    | Omit<LoadingState<TValue>, 'isPending'>;

export const isPendingOrLoading = <TValue, TError>(
    val: FutureState<TValue, TError>,
): val is LoadingState<TValue> | PendingState<TValue> => val.isPending || val.isLoading;

export const getFutureValue = <TValue>(future: FutureState<TValue>, defaultValue: TValue): TValue => {
    if (future.isFinished) {
        return future.value;
    } else if (isPendingOrLoading(future) && future.previousValue) {
        return future.previousValue;
    } else {
        return defaultValue;
    }
};
