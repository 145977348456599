/// <reference path="../../../typings/browser.d.ts" />
import { Untyped } from '@deltasierra/shared';
import IDirective = angular.IDirective;

export const numberSID = 'number';
export const numberConfig = {
};

export function numberDirectiveParser(viewValue : Untyped) {
    return parseFloat(viewValue);
}

angular.module('app').directive(numberSID, [function (): IDirective<ng.IScope> {
    return {
        ...numberConfig,
        require: 'ngModel',
        link(scope, ele, attr, ctrl : any) {
            ctrl.$parsers.unshift(numberDirectiveParser);
        },
    };
}]);
