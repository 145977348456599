import { DSAlert, DSTableCell, DSTableRow, RelativeTimestamp, Timestamp } from '@deltasierra/components';
import { withStyles } from '@material-ui/core';
import React from 'react';
import { FailedPublishesContentCell } from './FailedPublishesContentCell';
import { ScheduledPublishFragmentForFailedPublishesTable } from './__graphqlTypes/ScheduledPublishFragmentForFailedPublishesTable';

const StyledErrorMessage = withStyles({
    message: {
        fontSize: '10px',
        padding: '4px 0',
    },
})(DSAlert);

type Props = {
    row: ScheduledPublishFragmentForFailedPublishesTable;
};

export function FailedPublishesTableRow({ row }: Props): JSX.Element {
    return (
        <>
            <DSTableRow>
                <DSTableCell>
                    <Timestamp date={row.lastUpdatedAt} format="long" />
                    <br />
                    {'('}
                    <RelativeTimestamp autoUpdate date={row.lastUpdatedAt} />
                    {')'}
                </DSTableCell>
                <DSTableCell>{row.location.client.title}</DSTableCell>
                <DSTableCell>{row.location.title}</DSTableCell>
                <DSTableCell>
                    <Timestamp date={row.targetScheduledTime} format="long" />
                    <br />
                    {'('}
                    <RelativeTimestamp autoUpdate date={row.targetScheduledTime} />
                    {')'}
                </DSTableCell>
                <FailedPublishesContentCell row={row} />
                <DSTableCell>
                    <StyledErrorMessage severity="error">{row.debugStatusMessage}</StyledErrorMessage>
                </DSTableCell>
            </DSTableRow>
        </>
    );
}
FailedPublishesTableRow.displayName = 'FailedPublishesTableRow';
