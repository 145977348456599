import { DSAlert, DSAlertTitle, DSFormGroup, DSFormLabel, DSTypography, Translate } from '@deltasierra/components';
import { t } from '@deltasierra/shared';
import { FormikProps } from 'formik';
import * as React from 'react';
import styled from 'styled-components';
import { Textarea } from '../../../common/form/Textarea';
import { InstagramDirectFormValues } from './instagram-direct-form-values';

const StyledAlert = styled(DSAlert)`
    margin-bottom: ${props => props.theme.spacing(2)}px;
`;

export type InstagramDirectFormCaptionSectionProps = {
    formik: FormikProps<InstagramDirectFormValues>;
    suggestedCaption: string | null;
};

export function InstagramDirectFormCaptionSection({
    formik,
    suggestedCaption,
}: InstagramDirectFormCaptionSectionProps): JSX.Element {
    const handleChange = React.useCallback((newValue: string) => {
        formik.setFieldValue('caption', newValue);
    }, [formik]);
    return (
        <>
            <DSFormGroup>
                <DSFormLabel>
                    <Translate keyId="BUILD.PUBLISH.INSTAGRAM.CAPTION" />
                </DSFormLabel>
                <Textarea
                    enableEmojis
                    helpText={t('BUILD.PUBLISH.INSTAGRAM.CAPTION_HELP_TEXT')}
                    initialValue={formik.values.caption}
                    invalidHelpText={formik.errors.caption}
                    isValid={!formik.errors.caption}
                    rows={5}
                    onChange={handleChange}
                />
            </DSFormGroup>
            {suggestedCaption && (
                <StyledAlert severity="info">
                    <DSAlertTitle>
                        <Translate keyId="BUILD.PUBLISH.FACEBOOK.SUGGESTED_CAPTION" />
                    </DSAlertTitle>
                    <DSTypography>{suggestedCaption}</DSTypography>
                </StyledAlert>
            )}
        </>
    );
}

InstagramDirectFormCaptionSection.displayName = 'PublishToInstagramDirectForm';
