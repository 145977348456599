/// <reference path="../../../../typings/browser.d.ts" />
import {
    EmailAddress,
    FitwarePublishApi,
    FitwareSimpleMemberList,
    Html,
    justParams,
    LocationId,
    paramsAndBody,
    SendFitwareAutomatedEmailRequest,
    SendFitwareEmailRequest,
    WorkflowStartResult,
} from '@deltasierra/shared';
import { $httpSID } from '../../common/angularData';
import { invokeApiRoute } from '../../common/httpUtils';

export class FitwarePublishService {
    public static readonly SID = 'FitwarePublishService';

    public static readonly $inject: string[] = [$httpSID];

    constructor(protected readonly $http: ng.IHttpService) {}

    getMemberLists(locationId: LocationId): ng.IPromise<FitwareSimpleMemberList[]> {
        return invokeApiRoute(
            this.$http,
            FitwarePublishApi.getMemberLists,
            justParams({
                locationId,
            }),
        );
    }

    sendPreviewEmail(locationId: LocationId, recipient: EmailAddress, html: Html, subject: string) {
        return invokeApiRoute(
            this.$http,
            FitwarePublishApi.sendPreviewEmail,
            paramsAndBody(
                {
                    locationId,
                },
                {
                    html,
                    recipient,
                    subject,
                },
            ),
        );
    }

    sendEmail(locationId: LocationId, requestBody: SendFitwareEmailRequest): angular.IPromise<WorkflowStartResult> {
        return invokeApiRoute(
            this.$http,
            FitwarePublishApi.sendEmail,
            paramsAndBody(
                {
                    locationId,
                },
                requestBody,
            ),
        );
    }

    sendAutomatedEmail(
        locationId: LocationId,
        requestBody: SendFitwareAutomatedEmailRequest,
    ): angular.IPromise<WorkflowStartResult> {
        return invokeApiRoute(
            this.$http,
            FitwarePublishApi.sendAutomatedEmail,
            paramsAndBody(
                {
                    locationId,
                },
                requestBody,
            ),
        );
    }
}

angular.module('app').service(FitwarePublishService.SID, FitwarePublishService);
