/// <reference path="../../../typings/browser.d.ts" />
import { ClientExternalLink, Location } from '@deltasierra/shared';
import { MvAuth } from '../account/mvAuth';
import { MvClient } from '../clients/mvClient';
import { $scopeSID } from '../common/angularData';
import { InteractionUtils } from '../common/interactionUtils';
import { I18nService } from '../i18n/i18nService';
import { MvIdentity } from './mvIdentity';
import IScope = angular.IScope;

export class NavBarLoginController {
    public static SID = 'mvNavBarLoginCtrl';

    public static readonly $inject: string[] = [
        $scopeSID,
        MvIdentity.SID,
        I18nService.SID,
        InteractionUtils.SID,
        MvClient.SID,
        MvAuth.SID,
    ];

    public fetchData = this.interactionUtils.createFuture(
        this.i18n.text.common.fetchData(),
        (context: { clientId: number }) =>
            this.clientService.getClientLinks(context.clientId).then(links => {
                this.externalClientLinks = links;
            }),
    );

    public externalClientLinks: ClientExternalLink[] = [];

    private readonly LOCATION_CHANGE_EVENT_NAME = 'selectedLocationChanged';

    private location: Location | null = null;


    public constructor(
        private readonly $scope: IScope,
        private readonly identity: MvIdentity,
        private readonly i18n: I18nService,
        private readonly interactionUtils: InteractionUtils,
        private readonly clientService: MvClient,
        private readonly authService: MvAuth,
    ) {
        this.$scope.$on(this.LOCATION_CHANGE_EVENT_NAME, (event, data) => {
            this.location = data.location;
            if (!this.identity.isClient()) {
                void this.getExternalClientLinks();
            }
        });
        this.$scope.watchForChange(
            () => this.identity.currentUser,
            () => this.getExternalClientLinks(),
        );
        void this.getExternalClientLinks();
    }

    public async signout(): Promise<void> {
        await this.authService.logoutUser();
    }

    private getExternalClientLinks() {
        if (this.identity.isAuthenticated()) {
            let clientId = null;
            if (this.location && this.location.clientId) {
                clientId = this.location.clientId;
            }

            if (clientId) {
                return this.fetchData.run({ clientId });
            }
        }
        return Promise.resolve();
    }
}

angular.module('app').controller(NavBarLoginController.SID, NavBarLoginController);
