import { DSGrid } from '@deltasierra/components';
import React from 'react';

export type DSFormButtonsContainerProps = {
    backButton?: JSX.Element;
    nextButton?: JSX.Element;
};

export function DSFormButtonsContainer({ backButton, nextButton }: DSFormButtonsContainerProps): JSX.Element {
    return (
        <DSGrid container justifyContent="space-between">
            <DSGrid item xs="auto">
                {backButton}
            </DSGrid>
            <DSGrid item xs="auto">
                {nextButton}
            </DSGrid>
        </DSGrid>
    );
}
DSFormButtonsContainer.displayName = 'DSFormButtonsContainer';
