/// <reference path="../../../../typings/browser.d.ts" />
import {
    BasicSKUGroupWithProvidersCount,
    Currency,
    CurrencyCode,
    CurrencyNumber,
    ICreatePrintProviderSKUGroupBody,
    Url,
} from '@deltasierra/shared';
import { $routeSID, IRoute } from '../../common/angularData';
import { currencyPattern } from '../../common/currencyInput';
import { InteractionUtils } from '../../common/interactionUtils';
import { MvNotifier } from '../../common/mvNotifier';
import { I18nService } from '../../i18n/i18nService';
import { CurrencyService } from '../../payments/currencyService';
import { agencyTabNavEntries } from '../tabNavEntries';
import { SKUManagementApiClient } from './skuManagementApiClient';

// ? Add a better way to manage these?
export const paymentGatewayOptions = [
    { value: 'bluepay', label: 'Bluepay' },
    { value: 'four51', label: 'Four51' },
    { value: 'stripe', label: 'Stripe' },
];

export class AgencySKUGroupsController {
    public static SID = 'AgencySKUGroupsController';

    createPrintProviderSKUGroup = this.interactionUtils.createFuture(
        'Create new SKU Group',
        (context: { newPrintProviderSKUGroupData: ICreatePrintProviderSKUGroupBody }) =>
            this.skuManagementApiClient.createSKUGroup(context.newPrintProviderSKUGroupData).then(
                () => {
                    this.mvNotifier.notify(this.i18n.text.agency.printSkuGroups.skuGroups.skuGroupAdded());
                    this.$route.reload();
                },
                reason => {
                    this.mvNotifier.unexpectedError(reason);
                },
            ),
    );

    fetchPrintProviderSKUGroups = this.interactionUtils.createFuture('Get print provider SKU details', () =>
        this.skuManagementApiClient.getSKUGroups().then(result => {
            this.printProviderSKUGroups = result;
        }),
    );

    tabNavEntries = agencyTabNavEntries;

    printProviderSKUGroups: BasicSKUGroupWithProvidersCount[] | null = null;

    paymentGatewayOptions = paymentGatewayOptions;

    // Form data
    currencies: Currency[] | ReadonlyArray<Currency>;

    currencyPattern = currencyPattern;

    name = '';

    paymentGateway: 'bluepay' | 'four51' | 'stripe' | null = null;

    paymentUrl: Url | null = null;

    orderInformation: string | null = null;

    defaultPriceCurrency: CurrencyCode = 'USD';

    shippingFeePriceAmountInCents: CurrencyNumber = 0;

    static readonly $inject: string[] = [
        $routeSID,
        I18nService.SID,
        MvNotifier.SID,
        InteractionUtils.SID,
        SKUManagementApiClient.SID,
        CurrencyService.SID,
    ];

    constructor(
        private readonly $route: IRoute,
        private readonly i18n: I18nService,
        private readonly mvNotifier: MvNotifier,
        private readonly interactionUtils: InteractionUtils,
        private readonly skuManagementApiClient: SKUManagementApiClient,
        currencyService: CurrencyService,
    ) {
        this.currencies = currencyService.CURRENCIES;
        void this.fetchPrintProviderSKUGroups.run({});
    }

    addPrintProviderSKUGroup() {
        if (!!this.name && !!this.defaultPriceCurrency) {
            const newPrintProviderSKUGroupData: ICreatePrintProviderSKUGroupBody = {
                name: this.name,
                paymentUrl: this.paymentUrl || null,
                paymentGateway: this.paymentGateway || null,
                orderInformation: this.orderInformation || null,
                defaultPriceCurrency: this.defaultPriceCurrency,
                shippingFeePriceAmountInCents: this.shippingFeePriceAmountInCents || 0,
            };

            return this.createPrintProviderSKUGroup.run({ newPrintProviderSKUGroupData });
        }
    }
}

angular.module('app').controller(AgencySKUGroupsController.SID, AgencySKUGroupsController);
