import { gql } from '@apollo/client';

export const DELETE_INDIVIDUAL_TEMPLATE = gql`
    mutation DeleteIndividualTemplate($input: DeleteTemplateInput!) {
        deleteTemplate(input: $input) {
            ... on DeleteTemplateSuccess {
                success
                __typename
            }
            ... on DeleteTemplateError {
                code
                message
            }
            __typename
        }
    }
`;