import { useDebouncedValue, useInterval } from '@deltasierra/react-hooks';
import { DSFormControl } from '@deltasierra/components';
import { FormikProps } from 'formik';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useRateLimitedLocations } from '../../../common/hooks/useRateLimitedLocations';
import { SchedulePublishTimePicker } from '../../../directives/SchedulePublishTimePicker';
import { InstagramDirectFormValues } from './instagram-direct-form-values';

export function getMinScheduleDateFromNowForInstagram(): Date {
    // The min schedule date is always 15 minutes ahead of now
    return DateTime.now().plus({ minutes: 15 }).toJSDate();
}

export type InstagramDirectFormScheduleSectionProps = {
    formik: FormikProps<InstagramDirectFormValues>;
    locationIds: string[];
    timezone: string;
};

export function InstagramDirectFormScheduleSection({
    formik,
    locationIds,
    timezone,
}: InstagramDirectFormScheduleSectionProps): JSX.Element {
    const [minScheduledDate, setMinScheduledDate] = React.useState<Date | null>(
        getMinScheduleDateFromNowForInstagram(),
    );
    const scheduledTime = formik.values.scheduledTime;

    const debouncedScheduledDate = useDebouncedValue(scheduledTime, 300);

    const handleChange = React.useCallback(
        (newValue: Date | null) => formik.setFieldValue('scheduledTime', newValue),
        [formik],
    );

    // Every minute
    useInterval(60 * 1000, () => {
        // Make sure to adjust the minimum scheduled date
        const nextMinScheduledDate = getMinScheduleDateFromNowForInstagram();
        setMinScheduledDate(nextMinScheduledDate);

        if (scheduledTime && scheduledTime < nextMinScheduledDate) {
            // Use this to set the scheduled date
            handleChange(nextMinScheduledDate);
        }
    });

    const [, rateLimitRenderElement] = useRateLimitedLocations(
        'instagram',
        locationIds,
        'direct',
        debouncedScheduledDate,
    );

    return (
        <DSFormControl error={!!formik.errors.scheduledTime}>
            <SchedulePublishTimePicker
                error={formik.errors.scheduledTime}
                minDate={minScheduledDate}
                timezone={timezone}
                useNewLabel
                value={scheduledTime}
                onChange={handleChange}
            />
            {rateLimitRenderElement}
        </DSFormControl>
    );
}

InstagramDirectFormScheduleSection.displayName = 'InstagramDirectFormScheduleSection';
