/// <reference path="../../../_references.d.ts" />

import { SuggestedTemplatesController } from './controller';

export function SuggestedTemplatesDirective(): ng.IDirective<ng.IScope> {
    return {
        bindToController: true,
        controller: SuggestedTemplatesController,
        controllerAs: 'ctrl',
        restrict: 'E',
        scope: {
            location: '=',
        },
        templateUrl: '/partials/main/dashboard/suggestedTemplatesDirective/template',
    };
}
