import * as React from 'react';
import { FormikProps, FormikConfig, FormikHelpers, Formik } from 'formik';
import { Omit, createPropertyPathAccessor, PropertyPathAccessor } from '@deltasierra/shared';


export type FormDirection = 'horizontal' | 'vertical';

export const FormDirectionContext = React.createContext<FormDirection>('horizontal');

export type FormProps = {
    children?: React.ReactNode;
    isValid: boolean;
    onSubmit: () => void;
};

export const SimpleForm: React.FunctionComponent<FormProps> = ({ children, isValid, onSubmit }) => {
    const submitHandler = (event: React.SyntheticEvent) => {
        event.preventDefault();
        if (isValid) {
            onSubmit();
        }
    };
    return (
        <form className="form" onSubmit={submitHandler}>
            {children}
        </form>
    );
};

SimpleForm.displayName = 'SimpleForm';

export type FormSubmitHandler<TValues> = (values: TValues, actions: FormikHelpers<TValues>) => Promise<void> | void;

export type FormRenderFunction<TValues> = (
    props: FormikProps<TValues>,
    propAccessor: PropertyPathAccessor<TValues>,
) => React.ReactNode;

export type FormikFormWrapperProps<TValues> = Omit<FormikConfig<TValues>, 'render'> & {
    render?: FormRenderFunction<TValues>;
    name?: string;
    direction?: FormDirection;
    dataCy?: string;
};

/**
 * Wrapper for the Formik and Formik.Form components.
 *
 * @param ObjectParam - Configuration options
 * @param ObjectParam.render
 * @param ObjectParam.name
 * @param ObjectParam.direction
 * @param ObjectParam.dataCy
 * @returns React.ReactElement
 * @see https://jaredpalmer.com/formik/docs/api/formik
 */
function Form<TValues extends Record<string, unknown>>({
    dataCy,
    direction = 'horizontal',
    name,
    render,
    ...rest
}: FormikFormWrapperProps<TValues>): React.ReactElement {
    return (
        <Formik {...rest}>
            {props => (
                <form
                    className={direction === 'horizontal' ? 'form-horizontal' : 'form'}
                    data-cy={dataCy}
                    name={name}
                    onSubmit={props.handleSubmit}
                >
                    <FormDirectionContext.Provider value={direction}>
                        {render && render(props as any, createPropertyPathAccessor<TValues>(rest.initialValues))}
                    </FormDirectionContext.Provider>
                </form>
            )}
        </Formik>
    );
}

Form.displayName = 'Form';

export default Form;
