import { PrintButton } from '@deltasierra/components';

import { withAngularIntegration } from '../../componentUtils/reactComponentRegistration';
import { StringBinding, OneWayBinding } from '../../angularData';

export const AngularReactPrintButton = withAngularIntegration(PrintButton, 'reactPrintButton', {
    color: StringBinding,
    paperSize: StringBinding,
    setPrintPageWidth: OneWayBinding,
    targetSelector: StringBinding,
});
