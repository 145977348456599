import * as React from 'react';
import { CurrentLocationIdType } from '../contexts';
import { CurrentLocationStorageDispatchContext } from '../contexts/current-location-storage-dispatch-context';
import { CurrentLocationStorageStateContext } from '../contexts/current-location-storage-state-context';

export type CurrentLocationStorageContextProviderProps = {
    children?: React.ReactNode;
};

export function CurrentLocationStorageContextProvider({
    children,
}: CurrentLocationStorageContextProviderProps): JSX.Element {
    const [contextValue, setContextValue] = React.useState<CurrentLocationIdType>();
    return (
        <CurrentLocationStorageStateContext.Provider value={contextValue}>
            <CurrentLocationStorageDispatchContext.Provider value={setContextValue}>
                {children}
            </CurrentLocationStorageDispatchContext.Provider>
        </CurrentLocationStorageStateContext.Provider>
    );
}
CurrentLocationStorageContextProvider.displayName = 'CurrentLocationRouteContextProvider';
