import { DSFormControl, DSInputLabel, DSMenuItem, DSSelect } from '@deltasierra/components';
import { TranslatableTextNoParams } from '@deltasierra/i18n';
import { t } from '@deltasierra/shared';
import * as React from 'react';
import { OptionalExpressionBinding, OptionalOneWayBinding } from '../../angularData';
import { withAngularIntegration } from '../../componentUtils/reactComponentRegistration';

export type CountryCode =
    | 'AT'
    | 'AU'
    | 'BE'
    | 'CA'
    | 'CH'
    | 'CZ'
    | 'DE'
    | 'DK'
    | 'DO'
    | 'EG'
    | 'ES'
    | 'FI'
    | 'FR'
    | 'GB'
    | 'GE'
    | 'HK'
    | 'HU'
    | 'ID'
    | 'IE'
    | 'IN'
    | 'IT'
    | 'JP'
    | 'KR'
    | 'LT'
    | 'LV'
    | 'MM'
    | 'MU'
    | 'MX'
    | 'MY'
    | 'NL'
    | 'NO'
    | 'NZ'
    | 'PE'
    | 'PH'
    | 'PT'
    | 'SA'
    | 'SE'
    | 'SG'
    | 'TH'
    | 'TR'
    | 'TW'
    | 'UE'
    | 'US'
    | 'VN'
    | 'ZA';

type Country = Pick<TranslatableTextNoParams, 'keyId'> & {
    code: CountryCode;
};

const countries: Country[] = [
    { code: 'AU', keyId: 'COMMON.COUNTRIES.AUSTRALIA' },
    { code: 'AT', keyId: 'COMMON.COUNTRIES.AUSTRIA' },
    { code: 'BE', keyId: 'COMMON.COUNTRIES.BELGIUM' },
    { code: 'CA', keyId: 'COMMON.COUNTRIES.CANADA' },
    { code: 'CZ', keyId: 'COMMON.COUNTRIES.CZECHIA' },
    { code: 'DK', keyId: 'COMMON.COUNTRIES.DENMARK' },
    { code: 'DO', keyId: 'COMMON.COUNTRIES.DOMINICAN_REPUBLIC' },
    { code: 'EG', keyId: 'COMMON.COUNTRIES.EGYPT' },
    { code: 'FI', keyId: 'COMMON.COUNTRIES.FINLAND' },
    { code: 'FR', keyId: 'COMMON.COUNTRIES.FRANCE' },
    { code: 'GE', keyId: 'COMMON.COUNTRIES.GEORGIA' },
    { code: 'DE', keyId: 'COMMON.COUNTRIES.GERMANY' },
    { code: 'HK', keyId: 'COMMON.COUNTRIES.HONG_KONG' },
    { code: 'HU', keyId: 'COMMON.COUNTRIES.HUNGARY' },
    { code: 'IN', keyId: 'COMMON.COUNTRIES.INDIA' },
    { code: 'ID', keyId: 'COMMON.COUNTRIES.INDONESIA' },
    { code: 'IE', keyId: 'COMMON.COUNTRIES.IRELAND' },
    { code: 'IT', keyId: 'COMMON.COUNTRIES.ITALY' },
    { code: 'JP', keyId: 'COMMON.COUNTRIES.JAPAN' },
    { code: 'LV', keyId: 'COMMON.COUNTRIES.LATVIA' },
    { code: 'LT', keyId: 'COMMON.COUNTRIES.LITHUANIA' },
    { code: 'MY', keyId: 'COMMON.COUNTRIES.MALAYSIA' },
    { code: 'MU', keyId: 'COMMON.COUNTRIES.MAURITIUS' },
    { code: 'MX', keyId: 'COMMON.COUNTRIES.MEXICO' },
    { code: 'MM', keyId: 'COMMON.COUNTRIES.MYANMAR' },
    { code: 'NL', keyId: 'COMMON.COUNTRIES.NETHERLANDS' },
    { code: 'NZ', keyId: 'COMMON.COUNTRIES.NEW_ZEALAND' },
    { code: 'NO', keyId: 'COMMON.COUNTRIES.NORWAY' },
    { code: 'PE', keyId: 'COMMON.COUNTRIES.PERU' },
    { code: 'PH', keyId: 'COMMON.COUNTRIES.PHILIPPINES' },
    { code: 'PT', keyId: 'COMMON.COUNTRIES.PORTUGAL' },
    { code: 'SA', keyId: 'COMMON.COUNTRIES.SAUDI_ARABIA' },
    { code: 'SG', keyId: 'COMMON.COUNTRIES.SINGAPORE' },
    { code: 'ZA', keyId: 'COMMON.COUNTRIES.SOUTH_AFRICA' },
    { code: 'KR', keyId: 'COMMON.COUNTRIES.SOUTH_KOREA' },
    { code: 'ES', keyId: 'COMMON.COUNTRIES.SPAIN' },
    { code: 'SE', keyId: 'COMMON.COUNTRIES.SWEDEN' },
    { code: 'CH', keyId: 'COMMON.COUNTRIES.SWITZERLAND' },
    { code: 'TW', keyId: 'COMMON.COUNTRIES.TAIWAN' },
    { code: 'TH', keyId: 'COMMON.COUNTRIES.THAILAND' },
    { code: 'TR', keyId: 'COMMON.COUNTRIES.TURKEY' },
    { code: 'UE', keyId: 'COMMON.COUNTRIES.UNITED_ARAB_EMIRATES' },
    { code: 'GB', keyId: 'COMMON.COUNTRIES.UNITED_KINGDOM' },
    { code: 'US', keyId: 'COMMON.COUNTRIES.UNITED_STATES_OF_AMERICA' },
    { code: 'VN', keyId: 'COMMON.COUNTRIES.VIETNAM' },
];

type CountryInput = CountryCode | '';

export type CountrySelectProps = {
    style?: React.CSSProperties;
    className?: string;
    country?: CountryInput;
    onChange?: (country: CountryCode | '') => void;
    hidePlaceholder?: boolean;
    dataCy?: string;
};

export const CountrySelect: React.FC<CountrySelectProps> = ({
    className,
    country,
    dataCy,
    hidePlaceholder,
    onChange,
    style,
}) => (
    <DSFormControl fullWidth variant="outlined">
        {!hidePlaceholder && <DSInputLabel id="country-select-label">{t('COMMON.COUNTRY')}</DSInputLabel>}
        <DSSelect
            className={className}
            data-cy={dataCy}
            id="country-select"
            labelId="country-select-label"
            style={style}
            value={country ?? ''}
            onChange={ev => onChange?.(ev.target.value as CountryInput)}
        >
            {countries.map(({ code, keyId }) => (
                <DSMenuItem
                    className={`country-select__list-item-${code}`}
                    data-cy="country-select-item"
                    key={code}
                    value={code}
                >
                    {t(keyId)}
                </DSMenuItem>
            ))}
        </DSSelect>
    </DSFormControl>
);
CountrySelect.displayName = 'CountrySelect';

export const CountrySelectWithAngular = withAngularIntegration(CountrySelect, 'countrySelect', {
    country: OptionalOneWayBinding,
    hidePlaceholder: OptionalOneWayBinding,
    onChange: OptionalExpressionBinding,
});
