import { gql, useQuery } from '@apollo/client';
import { createWizardStep, Loading, Translate, Wizard } from '@deltasierra/components';
import * as React from 'react';
import { EmailPublishData } from '../../../email/emailPublishData';
import { PublishToClubReadyStep1 } from './components/PublishToClubReadyStep1/PublishToClubReadyStep1';
import { PublishToClubReadyStep1Data } from './components/PublishToClubReadyStep1/types/publish-to-club-ready-step-1-data';
import { PublishToClubReadyStep2, PublishToClubReadyStep2Data } from './components/PublishToClubReadyStep2';
import { PublishToClubReadyStep3 } from './components/PublishToClubReadyStep3';
import { MultiLocationLoaderPublishFunc } from './hooks/useMultiLocationLoader';
import { GetClubReadyForLocation, GetClubReadyForLocationVariables } from './__graphqlTypes/GetClubReadyForLocation';

const GET_CLUB_READY_FOR_LOCATION = gql`
    query GetClubReadyForLocation($locationId: ID!) {
        location(id: $locationId) {
            id
            clubReady {
                ... on ClubReady {
                    id
                    clubName
                    ...PublishToClubReadyStep2Fragment
                    ...PublishToClubReadyStep3Fragment
                }
            }
        }
    }
    ${PublishToClubReadyStep2.fragments.clubReady}
    ${PublishToClubReadyStep3.fragments.PUBLISH_TO_CLUB_READY_STEP_3_FRAGMENT}
`;

export interface PublishToClubReadyProps {
    publishData: EmailPublishData;
    scheduledDate?: Date;
    onCancel: () => void;
    startPublish: MultiLocationLoaderPublishFunc;
}

export const PublishToClubReadySteps: React.FC<PublishToClubReadyProps> = ({
    onCancel,
    publishData,
    scheduledDate,
    startPublish,
}) => {
    const { data, loading } = useQuery<GetClubReadyForLocation, GetClubReadyForLocationVariables>(
        GET_CLUB_READY_FOR_LOCATION,
        {
            fetchPolicy: 'network-only',
            variables: {
                locationId: publishData.location.graphqlId,
            },
        },
    );

    const locationsToPublishTo = React.useMemo(
        () =>
            publishData.locations?.map(heirarchy => ({
                id: heirarchy.locationGraphqlId,
                title: heirarchy.locationTitle,
            })) ?? [{ id: publishData.location.graphqlId, title: publishData.location.title }],
        [publishData.location.graphqlId, publishData.location.title, publishData.locations],
    );

    const [step1Data, updateStep1Data] = React.useState<PublishToClubReadyStep1Data>();
    const [step2Data, updateStep2Data] = React.useState<PublishToClubReadyStep2Data>();

    if (loading) {
        return <Loading />;
    }

    if (!data?.location?.clubReady || data.location.clubReady.__typename !== 'ClubReady') {
        return <Translate keyId="ERRORS.NOT_FOUND_ERRORS.RESOURCE_NOT_FOUND" />;
    }

    return (
        <Wizard
            steps={[
                createWizardStep(
                    wizardStepData =>
                        data.location?.clubReady && data.location.clubReady.__typename === 'ClubReady' ? (
                            <PublishToClubReadyStep1
                                clubName={data.location.clubReady.clubName}
                                locationsToPublishTo={locationsToPublishTo}
                                step1Data={step1Data}
                                {...wizardStepData}
                            />
                        ) : (
                            <></>
                        ),
                    updateStep1Data,
                ),
                createWizardStep(
                    wizardStepData =>
                        data.location?.clubReady && data.location.clubReady.__typename === 'ClubReady' ? (
                            <PublishToClubReadyStep2
                                clubReady={data.location.clubReady}
                                emailTemplateId={publishData.templateId}
                                publishData={publishData}
                                scheduledDate={scheduledDate}
                                step2Data={step2Data}
                                {...wizardStepData}
                            />
                        ) : (
                            <></>
                        ),
                    updateStep2Data,
                ),
                createWizardStep(wizardStepData =>
                    step1Data &&
                    step2Data &&
                    data.location?.clubReady &&
                    data.location.clubReady.__typename === 'ClubReady' ? (
                        <PublishToClubReadyStep3
                            emailTemplateId={publishData.templateId}
                            fragment={data.location.clubReady}
                            locationsToPublishTo={locationsToPublishTo}
                            publishData={publishData}
                            startPublish={startPublish}
                            step1Data={step1Data}
                            step2Data={step2Data}
                            {...wizardStepData}
                        />
                    ) : (
                        <></>
                    ),
                ),
            ]}
            onCancel={onCancel}
        />
    );
};

PublishToClubReadySteps.displayName = 'PublishToClubReadySteps';
