import { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { PlatformWell } from '../../platforms/components/PlatformPicker';
import { ScheduledPostTable } from '../components/ScheduledPostTable';
import {
    GetLocationPlatformInfoForScheduledPage,
    GetLocationPlatformInfoForScheduledPageVariables,
} from './__graphqlTypes/GetLocationPlatformInfoForScheduledPage';
import {
    GetPlatformsForScheduled,
    GetPlatformsForScheduled_platforms_edges_node,
    GetPlatformsForScheduledVariables,
} from './__graphqlTypes/GetPlatformsForScheduled';
import { PlatformFragmentForUseScheduledPage } from './__graphqlTypes/PlatformFragmentForUseScheduledPage';

export const platformFragment = gql`
    fragment PlatformFragmentForUseScheduledPage on Platform {
        id
        ...PlatformWellFragment
        ...PlatformFragmentForScheduledPostTable
    }
    ${PlatformWell.fragments.platform}
    ${ScheduledPostTable.fragments.platform}
`;

export const GET_LOCATION_INFO_FOR_SCHEDULED_PAGE = gql`
    query GetLocationPlatformInfoForScheduledPage($locationId: ID!, $hasLocation: Boolean! = false) {
        hasCurrentLocation @client @export(as: "hasLocation")
        currentLocationId @client @export(as: "locationId")
        location(id: $locationId) @include(if: $hasLocation) {
            __typename
            id
            client {
                id
                platforms {
                    edges {
                        node {
                            id
                            ...PlatformFragmentForUseScheduledPage
                        }
                    }
                }
            }
        }
    }
    ${platformFragment}
`;

export const GET_USER_PLATFORMS_QUERY = gql`
    query GetPlatformsForScheduled($after: String, $first: Int) {
        platforms(after: $after, first: $first) {
            edges {
                node {
                    id
                    ...PlatformWellFragment
                }
            }
        }
    }
    ${PlatformWell.fragments.platform}
`;

// eslint-disable-next-line camelcase
const allPlatform: GetPlatformsForScheduled_platforms_edges_node = {
    __typename: 'Platform' as const,
    displayName: 'All',
    id: 'all',
    name: 'all',
};

export function useScheduledPage(): [
    PlatformFragmentForUseScheduledPage,
    React.Dispatch<React.SetStateAction<PlatformFragmentForUseScheduledPage>>,
    PlatformFragmentForUseScheduledPage[],
] {
    const { data: locationData } = useQuery<
        GetLocationPlatformInfoForScheduledPage,
        GetLocationPlatformInfoForScheduledPageVariables
    >(GET_LOCATION_INFO_FOR_SCHEDULED_PAGE);

    const { data: userPlatformsData } = useQuery<GetPlatformsForScheduled, GetPlatformsForScheduledVariables>(
        GET_USER_PLATFORMS_QUERY,
    );

    const userPlatformNames = userPlatformsData?.platforms.edges.map(({ node }) => node.name) ?? [];
    const clientPlatforms = locationData?.location?.client?.platforms.edges.map(({ node }) => node) ?? [];
    const availableClientPlatforms = clientPlatforms.filter(node => userPlatformNames.includes(node.name)) ?? [];
    const sortedPlatforms = availableClientPlatforms.sort((nodeA, nodeB) => {
        if (nodeA.name < nodeB.name) {
            return -1;
        }
        if (nodeA.name > nodeB.name) {
            return 1;
        }
        return 0;
    });

    const platforms = [allPlatform, ...sortedPlatforms];
    const [currentPlatform, setCurrentPlatform] = useState(platforms[0]);

    return [currentPlatform, setCurrentPlatform, platforms];
}
