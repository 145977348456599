import { FitwareSimpleTemplate, LocationIdHierarchy } from '@deltasierra/shared';

import {
    $qSID,
    $timeoutSID,
    actualComponent,
    ExpressionBinding,
    ExpressionCallback,
    ILifecycleHooks,
    OneWayBinding,
    OptionalOneWayBinding,
} from '../../../../../common/angularData';
import { MultipleLocationActionProcessor } from '../../../../../locations/MultipleLocationActionProcessor';
import { I18nService } from '../../../../../i18n';
import { MemberEngagerApiClient } from '../../../../memberEngager/memberEngagerApiClient';
import { DataUtils } from '../../../../../common/dataUtils';

enum TemplateView {
    Loading,
    Failed,
    Loaded,
}

export class AutomatedEmailCategoryPickerCtrl implements ILifecycleHooks {
    public static readonly SID = 'AutomatedEmailCategoryPickerCtrl';

    // Inputs
    locations!: LocationIdHierarchy[];

    options!: FitwareSimpleTemplate[];

    onOptionsLoaded!: ExpressionCallback<{ automatedEmailCategories: FitwareSimpleTemplate[] }>;

    onSelectionChange!: ExpressionCallback<{ automatedEmailCategory: FitwareSimpleTemplate }>;

    automatedEmailCode?: string;

    preselectedValue?: FitwareSimpleTemplate; // This differs from the "automatedEmailCode" pre-selection, and is used to support "prev"/"next" behaviour in publishing steps.

    // Other properties
    public readonly TemplateView = TemplateView;

    view = TemplateView.Loading;

    processor?: MultipleLocationActionProcessor;

    selectedCategory?: FitwareSimpleTemplate;

    public preselectedCategoryNotFound = false;

    public static readonly $inject: string[] = [
        $qSID,
        $timeoutSID,
        I18nService.SID,
        DataUtils.SID,
        MemberEngagerApiClient.SID,
    ];

    constructor(
        protected readonly $q: ng.IQService,
        protected readonly $timeout: ng.ITimeoutService,
        protected readonly i18n: I18nService,
        protected readonly dataUtils: DataUtils,
        protected readonly memberEngagerApiClient: MemberEngagerApiClient,
    ) {}

    $onInit() {
        if (this.options == undefined) {
            this.options = [];
        }
        if (this.preselectedValue && this.options.indexOf(this.preselectedValue) > -1) {
            this.selectedCategory = this.preselectedValue;
        }

        if (this.options.length === 0) {
            return this.fetchAutomatedEmailCategories();
        } else {
            this.view = TemplateView.Loaded;
        }
    }

    protected fetchAutomatedEmailCategories() {
        this.view = TemplateView.Loading;
        let categories: FitwareSimpleTemplate[] = [];
        const processor = new MultipleLocationActionProcessor(this.$q, this.i18n, this.$timeout, this.locations, {
            action: location =>
                this.memberEngagerApiClient.getFitwareAutomatedEmails(location.locationId).then(result => {
                    const mappedCategories = result.map(entry => entry.fitwareTemplate);
                    if (categories.length === 0) {
                        categories = mappedCategories;
                    } else {
                        // Keep the intersection of categories, common to all locations.
                        categories = this.dataUtils.filterByPredicate(
                            existingCategory =>
                                mappedCategories.some(category => category.code === existingCategory.code),
                            categories,
                        );
                    }
                }),
            failure: () => {
                this.view = TemplateView.Failed;
            },
            partialSuccess: () => {
                this.view = TemplateView.Failed;
            },
            success: () => {
                this.options = categories;
                this.view = TemplateView.Loaded;
                this.onOptionsLoaded({
                    automatedEmailCategories: categories,
                });
                if (this.automatedEmailCode) {
                    const selectedCategory = this.dataUtils.findByPredicate(
                        category => category.code === this.automatedEmailCode,
                        this.options,
                    );
                    this.preselectedCategoryNotFound = !selectedCategory;
                    this.selectedCategory = selectedCategory || undefined;
                    if (this.selectedCategory) {
                        this.onChange();
                    }
                }
            },
        });
        this.processor = processor;
        return processor.start();
    }

    onChange() {
        if (this.selectedCategory) {
            this.onSelectionChange({
                automatedEmailCategory: this.selectedCategory,
            });
        }
    }
}

export const automatedEmailCategoryPickerSID = 'automatedEmailCategoryPicker';

export const automatedEmailCategoryPickerConfig = actualComponent(
    AutomatedEmailCategoryPickerCtrl,
    '/partials/contentBuilder/email/publish/fitware/automatedEmailCategoryPicker/automatedEmailCategoryPicker',
    {
        automatedEmailCode: OptionalOneWayBinding,
        locations: OneWayBinding,
        onSelectionChange: ExpressionBinding,
        onOptionsLoaded: ExpressionBinding,
        options: OneWayBinding,
        preselectedValue: OptionalOneWayBinding,
    },
);

angular.module('app').component(automatedEmailCategoryPickerSID, automatedEmailCategoryPickerConfig);
