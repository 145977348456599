/// <reference path="../../../typings/browser.d.ts" />
import { $resourceSID } from '../common/angularData';
import IResourceService = angular.resource.IResourceService;

export const mvPlannerResourceSID = 'mvPlannerResource';

angular.module('app').factory(mvPlannerResourceSID, [
    $resourceSID,
    ($resource: IResourceService) => {
        const PlannerResource = $resource(
            '/api/planners',
            {},
            {
                delete: {
                    isArray: false,
                    method: 'DELETE',
                },
                update: {
                    isArray: false,
                    method: 'PUT',
                },
            },
        );

        return PlannerResource;
    },
]);
