import {
    ClientId,
    AssignedLocation,
    AdWordsReportDataOutput,
    BrandAwarenessLocationStats,
    ConversionsLocationStats,
} from '@deltasierra/shared';
import { ReportableService } from '@deltasierra/integrations/integration-types';

import moment from 'moment-timezone';
import { $httpSID } from '../../common/angularData';
import { MvLocation } from '../../locations/mvLocation';
import { BaseLocationStatsService, CanConfigureResponse } from './common';
import IHttpService = angular.IHttpService;

export class GoogleAdWordsStatsService extends BaseLocationStatsService<
    AdWordsReportDataOutput,
    AdWordsReportDataOutput
> {
    public static readonly SID = 'googleAdWordsStatsService';

    public readonly serviceName: ReportableService = 'googleAdWords';

    public readonly displayName: string = 'Google Ads';

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public static readonly $inject: string[] = [$httpSID, MvLocation.SID];

    public constructor(
        $http: IHttpService,
        protected readonly mvLocation: MvLocation,
    ) {
        super($http);
    }

    public dateParameterToString(date: Date): string {
        // Need to override how dates are passed up for getting Google AdWords stats
        // The reason being: The adwords api dates are specified in the account's timezone with *no time component*.
        // So sending a date up in *local midnight* is useless to the server given the server doesn't know what timezone
        // The user is in, in order to transpose the date into the accounts timezone.
        // So instead pass up the date as *UTC midnight* -
        // The adwords endpoint on the server will know to expect it in UTC
        // And will re-encode the the UTC year, month and day, as midnight in the account's timezone.
        // E.g.
        // Browser timezone is: UTC+10
        // Browser time is: 2017-05-20 13:00
        // Sends date as string: '2017-05-20T00:00:00.000Z'
        // AdWords account timezone is: UTC-4
        // Server timezone is: Irrelevant
        // Server uses this date as string in AdWords API: '2017-05-20'

        return `${moment(date).format('YYYY-MM-DD')}T00:00:00.000Z`;
    }

    public canConfigureClient(clientId: ClientId): angular.IPromise<boolean> {
        return (
            this.$http
                // Hmm, auth with stats?
                .get<CanConfigureResponse>(`/api/auth/${this.serviceName}/client/${clientId}/canConfigure`)
                .then((res: ng.IHttpPromiseCallbackArg<CanConfigureResponse>) => res.data!.canConfigure)
        );
    }

    public isPlatformConfigured(location: AssignedLocation): boolean {
        return Boolean((location.googleAdWordsCampaignName ?? '').trim());
    }

    public combineStats(stats: AdWordsReportDataOutput[]): AdWordsReportDataOutput {
        const initialBrandAwarenessStat: BrandAwarenessLocationStats = {
            clicks: 0,
            cost: 0,
            currencyCode: '',
            entityTotals: [],
            impressions: 0,
            locationId: 0,
        };
        const initialConversionsStat: ConversionsLocationStats = {
            conversionTypeTotals: [],
            conversions: 0,
            cost: 0,
            currencyCode: '',
            locationId: 0,
            totalConversionValue: 0,
        };
        const initial: AdWordsReportDataOutput = {
            brandAwareness: {
                byAd: { ...initialBrandAwarenessStat },
                byKeyword: { ...initialBrandAwarenessStat },
            },
            conversions: {
                byAd: { ...initialConversionsStat },
                byKeyword: { ...initialConversionsStat },
            },
            recommendations: {
                cost: 0,
                currencyCode: '',
                impressionShareLostByBudget: {
                    type: 'exactly',
                    value: 0,
                },
                impressions: 0,
                locationId: 0,
                totalConversionValue: 0,
            },
        };
        return stats.reduce((accumulator, currentValue) => this.reduceStats(accumulator, currentValue), initial);
    }

    private reduceStats(
        previousValue: AdWordsReportDataOutput,
        currentValue: AdWordsReportDataOutput,
    ): AdWordsReportDataOutput {
        const result: AdWordsReportDataOutput = {
            brandAwareness: {
                byAd: {
                    clicks: previousValue.brandAwareness.byAd.clicks + currentValue.brandAwareness.byAd.clicks,
                    cost: previousValue.brandAwareness.byAd.cost + currentValue.brandAwareness.byAd.cost,
                    currencyCode:
                        previousValue.brandAwareness.byAd.currencyCode || currentValue.brandAwareness.byAd.currencyCode,
                    entityTotals: [],
                    impressions:
                        previousValue.brandAwareness.byAd.impressions + currentValue.brandAwareness.byAd.impressions,
                    locationId: 0,
                },
                byKeyword: {
                    clicks:
                        previousValue.brandAwareness.byKeyword.clicks + currentValue.brandAwareness.byKeyword.clicks,
                    cost: previousValue.brandAwareness.byKeyword.cost + currentValue.brandAwareness.byKeyword.cost,
                    currencyCode:
                        previousValue.brandAwareness.byKeyword.currencyCode ||
                        currentValue.brandAwareness.byKeyword.currencyCode,
                    entityTotals: [],
                    impressions:
                        previousValue.brandAwareness.byKeyword.impressions +
                        currentValue.brandAwareness.byKeyword.impressions,
                    locationId: 0,
                },
            },
            conversions: {
                byAd: {
                    conversionTypeTotals: [],
                    conversions: previousValue.conversions.byAd.conversions + currentValue.conversions.byAd.conversions,
                    cost: previousValue.conversions.byAd.cost + currentValue.conversions.byAd.cost,
                    currencyCode:
                        previousValue.conversions.byAd.currencyCode || currentValue.conversions.byAd.currencyCode,
                    locationId: 0,
                    totalConversionValue:
                        previousValue.conversions.byAd.totalConversionValue +
                        currentValue.conversions.byAd.totalConversionValue,
                },
                byKeyword: {
                    conversionTypeTotals: [],
                    conversions:
                        previousValue.conversions.byKeyword.conversions +
                        currentValue.conversions.byKeyword.conversions,
                    cost: previousValue.conversions.byKeyword.cost + currentValue.conversions.byKeyword.cost,
                    currencyCode:
                        previousValue.conversions.byKeyword.currencyCode ||
                        currentValue.conversions.byKeyword.currencyCode,
                    locationId: 0,
                    totalConversionValue:
                        previousValue.conversions.byKeyword.totalConversionValue +
                        currentValue.conversions.byKeyword.totalConversionValue,
                },
            },
            recommendations: {
                cost: previousValue.recommendations.cost + currentValue.recommendations.cost,
                currencyCode: previousValue.recommendations.currencyCode || currentValue.recommendations.currencyCode,
                impressionShareLostByBudget: {
                    type:
                        previousValue.recommendations.impressionShareLostByBudget.type === 'exactly' &&
                        currentValue.recommendations.impressionShareLostByBudget.type === 'exactly'
                            ? 'exactly'
                            : 'greaterThan',
                    value: 0, // This is only used by totals and we don't display totals in the recommendations table
                },
                impressions: previousValue.recommendations.impressions + currentValue.recommendations.impressions,
                locationId: 0,
                totalConversionValue:
                    previousValue.recommendations.totalConversionValue +
                    currentValue.recommendations.totalConversionValue,
            },
        };

        return result;
    }
}

angular.module('app').service(GoogleAdWordsStatsService.SID, GoogleAdWordsStatsService);
