import { InviteClientUserDto, SanitizedUser, User, UserId, UserLocationsApi, ClientId, LocationId, LocationUsersApi, justParams, paramsAndBody } from '@deltasierra/shared';
import { invokeApiRoute } from '../common/httpUtils';


import { $httpSID } from '../common/angularData';
import IPromise = angular.IPromise;

export class LocationUserApiClient {
    public static readonly SID = 'LocationUserApiClient';

    public static readonly $inject: string[] = [$httpSID];

    public constructor(protected readonly $http: ng.IHttpService) {}

    public inviteClientUser(clientId: ClientId, locationId: LocationId, userData: InviteClientUserDto): IPromise<SanitizedUser> {
        return invokeApiRoute(this.$http, LocationUsersApi.inviteClientUser, paramsAndBody({ clientId, locationId }, userData));
    }

    public getAssignableUsers(clientId: ClientId, locationId: LocationId): IPromise<User[]> {
        return invokeApiRoute(
            this.$http,
            LocationUsersApi.getAssignableUsers,
            justParams({
                clientId,
                locationId,
            }),
        );
    }

    /**
     * Used in the Location admin, to list all of the client users who can access the Location.
     *
     * @param clientId - The client ID
     * @param locationId - The location ID
     * @returns List of users for the given client and location
     */
    public getLocationUsers(clientId: ClientId, locationId: LocationId): IPromise<SanitizedUser[]> {
        return invokeApiRoute(
            this.$http,
            LocationUsersApi.getUsers,
            justParams({
                clientId,
                locationId,
            }),
        );
    }

    public addUserToLocation(userId: UserId, locationId: LocationId): IPromise<void> {
        return invokeApiRoute(
            this.$http,
            UserLocationsApi.addClientUserToLocation,
            justParams({
                locationId,
                userId,
            }),
        );
    }

    public setUserLocations(userId: UserId, locationIds: LocationId[]): IPromise<void> {
        return invokeApiRoute(
            this.$http,
            UserLocationsApi.setClientUserLocations,
            paramsAndBody(
                {
                    userId,
                },
                {
                    locationIds,
                },
            ),
        );
    }

    public removeUserFromLocation(userId: UserId, locationId: LocationId): IPromise<void> {
        return invokeApiRoute(
            this.$http,
            UserLocationsApi.removeClientUserFromLocation,
            justParams({
                locationId,
                userId,
            }),
        );
    }
}

angular.module('app').service(LocationUserApiClient.SID, LocationUserApiClient);
