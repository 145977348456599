import { gql } from '@apollo/client';
import { DSTypography } from '@deltasierra/components';
import { t } from '@deltasierra/shared';
import * as React from 'react';
import styled from 'styled-components';
import { relayConnectionToArray } from '../../../graphql/utils';
import { CreateCollectionButton } from '../CreateCollectionButton';
import { LocationFragmentForNoCollectionsMessage } from './__graphqlTypes/LocationFragmentForNoCollectionsMessage';

const fragments = {
    LOCATION_FRAGMENT_FOR_NO_COLLECTIONS_MESSAGE: gql`
        fragment LocationFragmentForNoCollectionsMessage on Location {
            id
            collectionLimits {
                current
            }
            clientCollections {
                edges {
                    node {
                        id
                    }
                }
            }
            client {
                id
                title
                collectionLimits {
                    current
                }
            }
        }
    `,
};

export interface NoCollectionsMessageProps {
    children: React.ReactNode;
    location: LocationFragmentForNoCollectionsMessage;
}

export function NoCollectionsMessage(props: NoCollectionsMessageProps): JSX.Element {
    const hasNoLocationCollections = props.location.collectionLimits.current === 0;
    const hasNoClientCollections =
        props.location.client.collectionLimits.current === 0 ||
        relayConnectionToArray(props.location.clientCollections).length === 0;
    if (hasNoLocationCollections && hasNoClientCollections) {
        return (
            <CenterFlex>
                <BigIcon className="far fa-folder-open" />
                <DSTypography component="h2" variant="h2">
                    {props.location.client.title}
                </DSTypography>
                <DSTypography component="h2" variant="h2">
                    {t('COMMON.NO_ASSET_COLLECTIONS_FOUND')}
                </DSTypography>
                <CreateCollectionButton />
            </CenterFlex>
        );
    } else {
        return <>{props.children}</>;
    }
}
NoCollectionsMessage.displayName = 'NoCollectionsMessage';
NoCollectionsMessage.fragments = fragments;

const CenterFlex = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const BigIcon = styled.i`
    font-size: 100px;
    padding-bottom: 10px;
`;
