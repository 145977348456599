import { AssignedLocation } from '@deltasierra/shared';
import * as React from 'react';
import LocationPickerWrapper from '../../../directives/locationPicker/LocationPickerWrapper';
import { CurrentLocationRouteContextProvider } from './CurrentLocationRouteContextProvider';
import { CurrentLocationStorageContextProvider } from './CurrentLocationStorageContextProvider/CurrentLocationStorageContextProvider';
import { useCurrentLocationState } from './hooks';
import { useCurrentLocation } from './hooks/useCurrentLocation';

export type LocationPickerProps = {
    selectedLocationId?: string;
    onChange?: (newLocationId: string, newLocation: AssignedLocation) => void;
};

export function LocationPicker({ onChange }: LocationPickerProps): JSX.Element {
    const [currentLocationId, updateCurrentLocation] = useCurrentLocation(false);

    const handleChange = React.useCallback(
        (newLocation: AssignedLocation) => {
            if (newLocation.graphqlId !== currentLocationId) {
                updateCurrentLocation(newLocation.graphqlId);
                onChange?.(newLocation.graphqlId, newLocation);
            }
        },
        [currentLocationId, onChange, updateCurrentLocation],
    );

    return <LocationPickerWrapper selectedLocationId={currentLocationId} onChange={handleChange} />;
}
LocationPicker.displayName = 'LocationPicker';
LocationPicker.useCurrentLocation = useCurrentLocation;
LocationPicker.useCurrentLocationState = useCurrentLocationState;
LocationPicker.CurrentLocationStorageContextProvider = CurrentLocationStorageContextProvider;
LocationPicker.CurrentLocationRouteContextProvider = CurrentLocationRouteContextProvider;
