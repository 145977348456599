import { gql } from '@apollo/client';

export const GET_GALLERY_TEMPLATE_DRAFTS_FOR_LOCATION_QUERY = gql`
    query GetGalleryTemplateDraftsForLocation($locationId: ID!, $templateIds: [Float!]) {
        location(id: $locationId) {
            id
            locationDrafts(filter: { builderTemplateIds: $templateIds }) {
                edges {
                    node {
                        id
                        builderTemplateId
                    }
                }
            }
        }
    }
`;
