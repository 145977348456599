/// <reference path="../../../typings/browser.d.ts" />
import {
    GetUnreadNotificationsResponse,
    InAppNotification,
    InAppNotificationsApi,
    emptyRequest,
    justParams,
    justQuery,
} from '@deltasierra/shared';
import { $httpSID } from '../common/angularData';
import { invokeApiRoute } from '../common/httpUtils';
import IHttpService = angular.IHttpService;
import IPromise = angular.IPromise;

export class InAppNotificationApiClient {
    static SID = 'InAppNotificationApiClient';

    static readonly $inject: string[] = [$httpSID];

    constructor(protected readonly $http: IHttpService) {}

    getNotification(inAppNotificationId: number): IPromise<InAppNotification> {
        return invokeApiRoute(
            this.$http,
            InAppNotificationsApi.getNotification,
            justParams({
                inAppNotificationId,
            }),
        );
    }

    getNotifications(offset?: { previousId: number; previousDate: Date }): IPromise<InAppNotification[]> {
        return invokeApiRoute(
            this.$http,
            InAppNotificationsApi.getNotifications,
            justQuery(
                offset
                    ? {
                          previousId: offset.previousId,
                          previousDate: offset.previousDate,
                      }
                    : undefined,
            ),
        );
    }

    getUnread(): IPromise<GetUnreadNotificationsResponse> {
        return invokeApiRoute(this.$http, InAppNotificationsApi.getUnread, emptyRequest());
    }

    markAsRead(inAppNotificationId: number): IPromise<void> {
        return invokeApiRoute(
            this.$http,
            InAppNotificationsApi.markAsRead,
            justParams({
                inAppNotificationId,
            }),
        );
    }

    markAllAsRead(): IPromise<void> {
        return invokeApiRoute(this.$http, InAppNotificationsApi.markAllAsRead, emptyRequest());
    }

    markAsClicked(inAppNotificationId: number): IPromise<void> {
        return invokeApiRoute(
            this.$http,
            InAppNotificationsApi.recordClicked,
            justParams({
                inAppNotificationId,
            }),
        );
    }
}

angular.module('app').service(InAppNotificationApiClient.SID, InAppNotificationApiClient);
