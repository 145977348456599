import * as React from 'react';
import { DisplayDateTimeInTimezone } from '../../../directives/DisplayDateTimeInTimezone';
import { withFormGroupAndLabel } from '../helpers';
import { DateOnlyPicker, DateOnlyPickerProps } from './DateOnlyPicker';
import { TimeOnlyPicker, TimeOnlyPickerProps } from './TimeOnlyPicker';

export type DateTimePickerProps = DateOnlyPickerProps &
    TimeOnlyPickerProps & {
        showClarificationInTimezone?: string;
    };

export const DateTimePicker: React.FunctionComponent<DateTimePickerProps> = props => (
    <>
        <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'space-between' }}>
            <TimeOnlyPicker {...props} />
            <div style={{ flexGrow: 1, paddingLeft: '5px' }}>
                <DateOnlyPicker {...props} />
            </div>
        </div>
        {props.showClarificationInTimezone && <DisplayDateTimeInTimezone date={props.value} timezone={props.showClarificationInTimezone} />}
    </>
);

DateTimePicker.displayName = 'DateTimePicker';

export const DateTimePickerFormInput = withFormGroupAndLabel()(DateTimePicker);
