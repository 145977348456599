/// <reference path="../../../../typings/browser.d.ts" />
import { actualComponent, TwoWayBinding } from '../../common/angularData';
import { SelectPrintUnitsController } from './controller';

export const selectPrintUnitsSID = 'selectPrintUnits';
export const selectPrintUnitsConfig = actualComponent(
    SelectPrintUnitsController,
    '/partials/directives/selectPrintUnits/template',
    {
        printUnits: TwoWayBinding,
    },
);

angular.module('app').component(selectPrintUnitsSID, selectPrintUnitsConfig);
