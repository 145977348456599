import { NetworkStatus, useLazyQuery, useQuery } from '@apollo/client';
import { AutocompleteBold, DSTextField, SvgLoading } from '@deltasierra/components';
import { useDebouncedValue } from '@deltasierra/react-hooks';
import { filterUniqueBy } from '@deltasierra/array-utilities';
import * as React from 'react';
import { useConnectionFetchMore } from '../../../graphql/hooks';
import { relayConnectionToArray } from '../../../graphql/utils';
import { CLIENT_PICKER_GET_SELECTED_CLIENT_QUERY, CLIENT_PICKER_GET_USER_CLIENTS_QUERY } from './ClientPicker.query';
import { CurrentClientRouteContextProvider } from './CurrentClientRouteContextProvider';
import { CurrentClientStorageContextProvider } from './CurrentClientStorageContextProvider/CurrentClientStorageContextProvider';
import { useCurrentClientState } from './hooks';
import { useCurrentClient } from './hooks/useCurrentClient';
import { ClientFragmentForClientPicker } from './__graphqlTypes/ClientFragmentForClientPicker';
import {
    ClientPickerGetSelectedClientQuery,
    ClientPickerGetSelectedClientQueryVariables,
} from './__graphqlTypes/ClientPickerGetSelectedClientQuery';
import {
    ClientPickerGetUserClientsQuery,
    ClientPickerGetUserClientsQueryVariables,
} from './__graphqlTypes/ClientPickerGetUserClientsQuery';

export type ClientPickerProps = {
    selectedClientId?: string;
    onChange?: (client: ClientFragmentForClientPicker['id']) => void;
};

export function ClientPicker({ onChange, selectedClientId }: ClientPickerProps): JSX.Element {
    const [currentClientIdFromContext, updateCurrentClient] = useCurrentClient();
    const currentClientId = selectedClientId ?? currentClientIdFromContext;

    const [inputValue, setInputValue] = React.useState('');
    const debouncedInputValue = useDebouncedValue(inputValue);

    const [selected, setSelected] = React.useState<ClientFragmentForClientPicker | null>(null);

    const [
        loadSelectedClient,
        { data: selectedClientData, loading: selectedClientloading, networkStatus: selectedClientNetworkStatus },
    ] = useLazyQuery<ClientPickerGetSelectedClientQuery, ClientPickerGetSelectedClientQueryVariables>(
        CLIENT_PICKER_GET_SELECTED_CLIENT_QUERY,
        {
            notifyOnNetworkStatusChange: true,
        },
    );

    const { data, fetchMore, loading, networkStatus, refetch } = useQuery<
        ClientPickerGetUserClientsQuery,
        ClientPickerGetUserClientsQueryVariables
    >(CLIENT_PICKER_GET_USER_CLIENTS_QUERY, {
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
    });

    const loadingOrDebouncing =
        selectedClientloading || loading || (inputValue !== debouncedInputValue && inputValue !== selected?.title);

    // Only show the loading spinner if we have already fetched the selectedClient and the initial list of clients
    const showLoadingSpinner =
        loadingOrDebouncing &&
        selectedClientNetworkStatus === NetworkStatus.ready &&
        (networkStatus === NetworkStatus.ready || networkStatus === NetworkStatus.fetchMore);

    const options: ClientFragmentForClientPicker[] = React.useMemo(
        () =>
            [
                ...selectedClientData?.client ? [selectedClientData?.client] : [],
                ...relayConnectionToArray(data?.me.agency?.clients),
            ].filter(filterUniqueBy(client => client.id)),
        [data?.me.agency?.clients, selectedClientData?.client],
    );

    const [handleFetchMore, hasNextPage] = useConnectionFetchMore(data?.me.agency.clients, async after =>
        fetchMore({ variables: { after } }),
    );

    React.useEffect(() => {
        if (currentClientId) {
            loadSelectedClient({
                variables: {
                    clientId: currentClientId,
                },
            });
        }
    }, [currentClientId, loadSelectedClient]);

    // On the first render, if we have a loaded an existing client
    React.useEffect(() => {
        if (selectedClientData?.client) {
            setSelected(selectedClientData.client);
        }
    }, [selectedClientData?.client, setSelected]);

    React.useEffect(() => {
        if (debouncedInputValue !== selectedClientData?.client?.title) {
            void refetch({
                title: debouncedInputValue,
            });
        }
    }, [selectedClientData?.client, debouncedInputValue, refetch]);

    return (
        <AutocompleteBold
            ListboxProps={{
                onScroll: (event: React.SyntheticEvent) => {
                    if (hasNextPage) {
                        const listboxNode = event.currentTarget;
                        if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
                            handleFetchMore();
                        }
                    }
                },
            }}
            autoComplete
            autoHighlight
            getOptionLabel={option => option.title}
            loading={loadingOrDebouncing}
            options={options}
            renderInput={params => (
                <DSTextField
                    {...params}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {showLoadingSpinner ? <SvgLoading /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
            renderOption={option => option.title}
            value={selected}
            onBlur={() => {
                if (selected) {
                    setInputValue('');
                }
            }}
            onChange={(_event, newValue) => {
                if (newValue) {
                    const newClientId = newValue.id;
                    // We always use the closest callback first
                    onChange?.(newClientId);
                    // Then bubble up
                    updateCurrentClient(newClientId);
                }
            }}
            onInputChange={(_event, newInputValue) => {
                setInputValue(newInputValue);
            }}
        />
    );
}
ClientPicker.displayName = 'ClientPicker';
ClientPicker.useCurrentClient = useCurrentClient;
ClientPicker.useCurrentClientState = useCurrentClientState;
ClientPicker.CurrentClientStorageContextProvider = CurrentClientStorageContextProvider;
ClientPicker.CurrentClientRouteContextProvider = CurrentClientRouteContextProvider;
