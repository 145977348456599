import * as React from 'react';
import { useSafeRedirect } from '../../hooks/useSafeRedirect';

export type SafeRedirectProps = {
    to: string;
};

export function SafeRedirect({ to }: SafeRedirectProps): JSX.Element {
    const redirect = useSafeRedirect();

    React.useEffect(() => {
        redirect(to);
    }, [redirect, to]);

    return <></>;
}
SafeRedirect.displayName = 'SafeRedirect';
