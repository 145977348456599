import { t } from '@deltasierra/shared';
import { FormikErrors } from 'formik';
import React from 'react';

export type InitialValues = {
    currentClient: {
        currentNumCollections: number;
        maxNumCollections: number;
        title: string;
    };
    currentLocation: {
        currentNumCollections: number;
        maxNumCollections: number;
        title: string;
    };
    modelType: 'client' | 'location';
    selectedClientIds: string[];
    selectedLocationIds: string[];
    title: string;
};

/**
 * Hook provides handleValidation function for use in Formik form in CreateCollectionModel
 *
 * @returns handleValidation - a function to validate Formik form
 */
export function useHandleValidation(): (values: InitialValues) => FormikErrors<InitialValues> {
    const handleValidation = React.useCallback((values: InitialValues): FormikErrors<InitialValues> => {
        const errors: FormikErrors<InitialValues> = {};
        if (!values.title.trim()) {
            errors.title = t('COMMON.REQUIRED_FIELD_MESSAGE');
        }

        const currentClientCollectionLimitReached =
            values.currentClient.currentNumCollections >= values.currentClient.maxNumCollections;
        const currentLocationCollectionLimitReached =
            values.currentLocation.currentNumCollections >= values.currentLocation.maxNumCollections;
        if (values.modelType === 'client' && currentClientCollectionLimitReached) {
            errors.currentClient = {
                currentNumCollections: t('ASSET_LIBRARY.COLLECTION_LIMIT_REACHED', {
                    collectionLimit: values.currentClient.maxNumCollections,
                    title: values.currentClient.title || t('COMMON.CLIENT'),
                }),
                ...errors.currentClient,
            };
        } else if (values.modelType === 'location' && currentLocationCollectionLimitReached) {
            errors.currentLocation = {
                currentNumCollections: t('ASSET_LIBRARY.COLLECTION_LIMIT_REACHED', {
                    collectionLimit: values.currentLocation.maxNumCollections,
                    title: values.currentLocation.title || t('COMMON.LOCATION'),
                    ...errors.currentLocation,
                }),
            };
        }
        return errors;
    }, []);
    return handleValidation;
}
