import * as React from 'react';
import styled from 'styled-components';
import { DSGrid, Tooltip, Translate } from '@deltasierra/components';
import { ScheduledPublishesSchedulingStatus } from '../../../../../../../__graphqlTypes/globalTypes';
import { MultiLocationLocationLoaderData } from '../hooks/useMultiLocationLoader';

const StyledSpan = styled.span`
    margin-left: 5px;
`;

export type LocationProgressProps = {
    locationData: MultiLocationLocationLoaderData;
};

export const LocationProgress: React.FC<LocationProgressProps> = ({ locationData }) => {
    const getIconClass = React.useCallback(() => {
        switch (locationData.scheduledPublish?.schedulingStatus) {
            case ScheduledPublishesSchedulingStatus.success:
                return 'fa-check text-success';
            case ScheduledPublishesSchedulingStatus.failed:
                return 'fa-exclamation-circle text-danger';
            case ScheduledPublishesSchedulingStatus.pending:
                return 'fa-clock-o';
            default:
                return '';
        }
    }, [locationData]);

    const getIconTooltip = React.useCallback(
        () =>
            locationData.scheduledPublish?.schedulingStatus === ScheduledPublishesSchedulingStatus.failed ? (
                <Translate
                    keyId="ERRORS.PUBLISH.ERRORS.FAILED_TO_PUBLISH_TO_LOCATION"
                    options={{ location: locationData.location.title }}
                />
            ) : null,
        [locationData],
    );

    return (
        <>
            <DSGrid item xs={12}>
                {locationData.scheduledPublish === null ? (
                    <Tooltip tip={<Translate keyId="ERRORS.PUBLISH.ERRORS.NOT_SHOWN_MISSING_FROM_LIST" />}>
                        <i className={'fa fa-fw fa-exclamation-triangle text-warning'} />
                    </Tooltip>
                ) : (
                    <Tooltip tip={getIconTooltip()}>
                        <i className={`fa fa-fw ${getIconClass()}`} />
                    </Tooltip>
                )}
                <StyledSpan>{locationData.location.title}</StyledSpan>
            </DSGrid>
        </>
    );
};

LocationProgress.displayName = 'LocationProgress';
