/// <reference path="../../../typings/browser.d.ts" />
import { Agency, AgencyId, UpdateManagerDto, UserId } from '@deltasierra/shared';
import { $locationSID, $routeParamsSID } from '../common/angularData';
import { Future } from '../common/Future';
import { InteractionUtils } from '../common/interactionUtils';
import { MvNotifier } from '../common/mvNotifier';
import { I18nService } from '../i18n';
import { AgencyApiClient } from './agencyApiClient';
import { AgencyUserApiClient } from './agencyUserApiClient';
import ILocationService = angular.ILocationService;
import IRouteParams = angular.IRouteParams;

export class MvAgencyUserEditCtrl {
    public static readonly SID = 'mvAgencyUserEditCtrl';

    public static readonly $inject = [
        $locationSID,
        $routeParamsSID,
        MvNotifier.SID,
        InteractionUtils.SID,
        AgencyApiClient.SID,
        AgencyUserApiClient.SID,
        I18nService.SID,
    ];

    // State

    public readonly userId: UserId;

    public user: UpdateManagerDto | null;

    public readonly agencyId: AgencyId;

    public agency: Agency | null;

    public readonly deleteAgencyUser: Future<void, {}>;

    public readonly fetchData: Future<void, {}>;

    public readonly updateUserDetails: Future<void, {}>;


    public constructor(
        private readonly $location: ILocationService,
        $routeParams: IRouteParams & { agencyId: number; userId: number },
        mvNotifier: MvNotifier,
        interactionUtils: InteractionUtils,
        agencyApiClient: AgencyApiClient,
        agencyUserApiClient: AgencyUserApiClient,
        i18n: I18nService,
    ) {
        this.onChangeTimezone = this.onChangeTimezone.bind(this);
        this.userId = UserId.from($routeParams.userId);
        this.user = null;
        this.agencyId = AgencyId.from($routeParams.agencyId);
        this.agency = null;

        this.fetchData = interactionUtils.createFuture('retrieve agency and user', async () => {
            const [user, agency] = await Promise.all([
                agencyUserApiClient.getManager(this.agencyId, this.userId),
                agencyApiClient.getAgency(this.agencyId),
            ]);
            this.user = user;
            this.agency = agency;
        });
        this.deleteAgencyUser = interactionUtils.createFuture('delete user', async () => {
            await agencyUserApiClient.deleteManager(this.agencyId, this.userId);
            mvNotifier.notify(i18n.text.agencies.user.userRemovedSuccessMessage({ company: this.agency!.company }));
            this.navigateBackToAgencyUsersPage();
        });
        this.updateUserDetails = interactionUtils.createFuture('update agency user', async () => {
            await agencyUserApiClient.updateManager(this.agencyId, this.userId, {
                firstName: this.user!.firstName,
                lastName: this.user!.lastName,
                localeCode: this.user!.localeCode,
                timezone: this.user!.timezone,
                username: this.user!.username,
            });
            mvNotifier.notify(i18n.text.agencies.user.userUpdatedSuccessMessage());
            this.navigateBackToAgencyUsersPage();
        });

        this.fetchData.run({});
    }

    public update() {
        this.updateUserDetails.run({});
    }

    public delete() {
        this.deleteAgencyUser.run({});
    }

    public cancel() {
        this.navigateBackToAgencyUsersPage();
    }

    public onChangeTimezone(newTimezone: string): void {
        this.user!.timezone = newTimezone;
    }

    private navigateBackToAgencyUsersPage() {
        this.$location.path(`/admin/agencies/${this.agencyId}`);
    }
}

angular.module('app').controller(MvAgencyUserEditCtrl.SID, MvAgencyUserEditCtrl);
