import { Untyped, ScheduledPost } from '@deltasierra/shared';
import ClipboardJS from 'clipboard';
import { SentryService } from '../common/sentryService';
import { MvNotifier } from '../common/mvNotifier';
import { InteractionUtils } from '../common/interactionUtils';
import { I18nService } from '../i18n';

import { $filterSID, $routeParamsSID } from '../common/angularData';
import { Future } from '../common/Future';
import { ScheduledPostService } from './service';

export class ScheduledPostController {
    public static SID = 'scheduledPostController';

    public postKey: string | null = null;

    public postDetails: ScheduledPost | null = null;

    public copyCaptionClipboard: ClipboardJS | null = null;

    public failedToFind = false;

    public readonly getPostDetails: Future<void, { postKey: string }>;

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public static readonly $inject: string[] = [
        $routeParamsSID,
        $filterSID,
        MvNotifier.SID,
        InteractionUtils.SID,
        I18nService.SID,
        ScheduledPostService.SID,
        SentryService.SID,
    ];

    // eslint-disable-next-line max-params
    public constructor(
        private readonly $routeParams: { postKey: string },
        private readonly $filter: angular.IFilterService,
        private readonly mvNotifier: MvNotifier,
        private readonly interactionUtils: InteractionUtils,
        private readonly i18n: I18nService,
        private readonly scheduledPostService: ScheduledPostService,
        private readonly sentryService: SentryService,
    ) {
        this.postKey = this.$routeParams.postKey;

        this.getPostDetails = this.interactionUtils.createFuture(
            this.i18n.text.schedule.posts.getScheduledDetails,
            (context: { postKey: string }) =>
                this.scheduledPostService
                    .getPost(context.postKey)
                    .then((response: { data?: ScheduledPost }) => {
                        if (response.data) {
                            this.postDetails = response.data;
                            this.setupClipboard();
                        } else {
                            throw new Error();
                        }
                    })
                    .catch(error => {
                        this.failedToFind = true;
                        this.sentryService.captureException('Failed to find scheduled post', error);
                    }),
        );

        if (!this.postKey) {
            this.failedToFind = true;
        } else {
            this.getPostDetails.run({
                postKey: this.postKey,
            });
        }
    }

    public isCaption() {
        return this.postDetails && this.postDetails.caption !== null;
    }

    public getPostDescription(): string {
        if (this.postDetails && this.postDetails.platform) {
            if (this.postDetails.scheduledTime) {
                return this.i18n.text.schedule.posts.postScheduledFor({
                    location: this.postDetails.locationDisplayName,
                    platform: this.postDetails.platform.label,
                    scheduledTime: this.getScheduledTimeDisplayText(this.postDetails.scheduledTime),
                });
            }

            return this.i18n.text.schedule.posts.postImmediately({
                location: this.postDetails.locationDisplayName,
                platform: this.postDetails.platform.label,
            });
        } else {
            return '';
        }
    }

    private setupClipboard() {
        this.copyCaptionClipboard = new ClipboardJS('.copyToClipboard');

        this.copyCaptionClipboard.on('success', (event: Untyped) => {
            this.mvNotifier.notify(this.i18n.text.schedule.posts.copiedToClipboard());
            event.clearSelection();
        });

        this.copyCaptionClipboard.on('error', (event: Untyped) => {
            this.mvNotifier.notify(this.i18n.text.schedule.posts.copiedToClipboardFailed());
            event.clearSelection();
        });
    }

    private getScheduledTimeDisplayText(scheduledTime: Untyped) {
        return this.$filter('date')(scheduledTime, 'HH:mm (Z) \'on\' MMM d, yyyy'); // TODO: translate/localise this
    }
}

angular.module('app').controller(ScheduledPostController.SID, ScheduledPostController);
