import { gql } from '@apollo/client';
import { GraphqlService } from '../../graphql/GraphqlService';
import { CreatePostArtifactGroupMutation } from './__graphqlTypes/CreatePostArtifactGroupMutation';

const CREATE_POST_ARTIFACT_GROUP_MUTATION = gql`
    mutation CreatePostArtifactGroupMutation {
        createPostArtifactGroup {
            group {
                id
                legacyId
            }
        }
    }
`;

export async function createPostArtifactGroup(
    gqlService: GraphqlService,
): Promise<CreatePostArtifactGroupMutation['createPostArtifactGroup']> {
    const client = gqlService.getClient();
    const result = await client.mutate<CreatePostArtifactGroupMutation>({
        mutation: CREATE_POST_ARTIFACT_GROUP_MUTATION,
    });
    return result.data!.createPostArtifactGroup;
}
