import { NullableOptional } from '@deltasierra/shared';

type CompareNullableOptional<T> = (a: NullableOptional<T>, b: NullableOptional<T>) => number;
type Compare<T> = (a: T, b: T) => number;

function sortNullsToTop<T>(compare: Compare<T>): CompareNullableOptional<T> {
    return (a: NullableOptional<T>, b: NullableOptional<T>) => {
        const isANullOrUndefined = a === null || a === undefined;
        const isBNullOrUndefined = b === null || b === undefined;

        if (isANullOrUndefined) {
            return isBNullOrUndefined ? 0 : 1;
        } else if (isBNullOrUndefined) {
            return -1;
        }

        return compare(a!, b!);
    };
}

export const compareStrings = sortNullsToTop<string>((a, b) => a.localeCompare(b));

export const compareNumbers = sortNullsToTop<number>((a, b) => a - b);

export const compareDates = sortNullsToTop<Date>((a, b) => a.valueOf() - b.valueOf());
