import { gql, QueryResult, useQuery } from '@apollo/client';
import { GetConfig } from './__graphqlTypes/GetConfig';

export const GET_CONFIG_QUERY = gql`
    query GetConfig {
        config {
            id
            appFrontendUrl
            apiKeys {
                google
            }
            features {
                builder {
                    builderTemplateDrafts
                    multiImageTemplates
                }
                campaigns {
                    enabled
                }
                planner {
                    linkTemplateAndCategory
                }
                reports {
                    emailCampaigns
                    facebook
                    googleMyBusiness
                    instagram
                    overview
                    paidMedia
                }
            }
        }
    }
`;

export type ConfigType = GetConfig['config'];

export type UseConfigType = ConfigType | undefined;

export function useConfig(): [UseConfigType, boolean, QueryResult<GetConfig>] {
    const result = useQuery<GetConfig>(GET_CONFIG_QUERY, {
        fetchPolicy: 'cache-first',
        nextFetchPolicy: 'cache-first',
        notifyOnNetworkStatusChange: true,
    });

    return [result?.data?.config, result.loading, result];
}
