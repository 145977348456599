import { Brand } from '@deltasierra/type-utilities';
import { assertIsDateOnly, DateOnly, isDateOnly } from '../DateOnly';

/**
 * A simple branded type to represent a date only range string. A date only range string represents some
 * time period between two dates and is in the form `YYYY-MM-DD/YYYY-MM-DD`.
 *
 * @see https://en.wikipedia.org/wiki/ISO_8601#Time_intervals
 */
export type DateOnlyRange = Brand<`${DateOnly}/${DateOnly}`, 'DateOnlyRange'>;

const DATE_ONLY_RANGE_REGEX = /^\d+-\d+-\d+\/\d+-\d+-\d+$/;

/**
 * Check if the provided value is a DateOnlyRange string.
 *
 * @param dateRange - The string to check
 * @returns Whether or not the provided date range is a valid date only range string
 */
export function isDateOnlyRange(dateRange: string): dateRange is DateOnlyRange {
    if (!DATE_ONLY_RANGE_REGEX.test(dateRange)) {
        return false;
    }
    const [dateA, dateB] = dateRange.split('/');
    return isDateOnly(dateA) && isDateOnly(dateB);
}

/**
 * Assert that the provided value is a date only range value.
 *
 * @param range - The value to assert
 */
export function assertIsDateOnlyRange(range: string): asserts range is DateOnlyRange {
    if (!isDateOnlyRange(range)) {
        throw new Error('Invalid date only range');
    }
}

/**
 * A collection of utility functions for interacting with date only range values
 *
 * @see DateOnlyRange
 */
export const DateOnlyRange = Object.freeze({
    fromParts(since: DateOnly, until: DateOnly): DateOnlyRange {
        const range = `${since}/${until}`;
        assertIsDateOnlyRange(range);
        return range;
    },
    fromString(range: string): DateOnlyRange | null {
        return isDateOnlyRange(range) ? range : null;
    },
    toParts(range: DateOnlyRange): [since: DateOnly, until: DateOnly] {
        const [dateA, dateB] = range.split('/');
        assertIsDateOnly(dateA);
        assertIsDateOnly(dateB);
        return [dateA, dateB];
    },
});
