import { InternalStats } from '@deltasierra/shared';
import { ReportTable, StatsEntry, TextAlignClass } from '../reportTable/reportTable';
import { compareNumbers } from '../compare';
import { CreateReportTableOptions } from './common';

export function createInternalReportTable(options: CreateReportTableOptions): ReportTable<StatsEntry<InternalStats>, InternalStats> {
    return new ReportTable<StatsEntry<InternalStats>, InternalStats>({
        columns: [
            {
                cssClass: TextAlignClass.Left,
                format: options.formatters.formatString,
                getTotal: () => options.i18n.text.report.total(),
                getValue: entry => entry.location.locationTitle,
                label: () => options.i18n.text.report.location(),
            },
            {
                compare: compareNumbers,
                cssClass: TextAlignClass.Right,
                format: options.formatters.formatNumber,
                getTotal: totals => totals.planners.count,
                getValue: entry => entry.planners.count,
                label: () => options.i18n.text.report.totalEvents(),
            },
            {
                compare: compareNumbers,
                cssClass: TextAlignClass.Right,
                format: options.formatters.formatNumber,
                getTotal: totals => totals.planners.selfService,
                getValue: entry => entry.planners.selfService,
                label: () => options.i18n.text.report.selfServiceEvents(),
            },
            {
                compare: compareNumbers,
                cssClass: TextAlignClass.Right,
                format: options.formatters.formatNumber,
                getTotal: totals => totals.planners.supported,
                getValue: entry => entry.planners.supported,
                label: () => options.i18n.text.report.supportedEvents(),
            },
            {
                compare: compareNumbers,
                cssClass: TextAlignClass.Right,
                format: options.formatters.formatNumber,
                getTotal: totals => totals.planners.general,
                getValue: entry => entry.planners.general,
                label: () => options.i18n.text.report.generalEvents(),
            },
            {
                compare: compareNumbers,
                cssClass: TextAlignClass.Right,
                format: options.formatters.formatNumber,
                getTotal: totals => totals.specialRequests.count,
                getValue: entry => entry.specialRequests.count,
                label: () => options.i18n.text.report.specialRequests(),
            },
            {
                compare: compareNumbers,
                cssClass: TextAlignClass.Right,
                format: options.formatters.formatNumber,
                getTotal: totals => totals.publishedArtifacts.count,
                getValue: entry => entry.publishedArtifacts.count,
                label: () => options.i18n.text.common.built(),
            },
        ],
    });
}
