import { assertNever } from '@deltasierra/shared';
import React from 'react';

type InitialState = {
    name: 'initial';
};

type RequestingState = {
    name: 'requesting';
};

type PollingState = {
    name: 'polling';
    generateReportRequestId: string;
};

type SuccessState = {
    downloadUrl: string;
    name: 'success';
};

type ErrorState = {
    name: 'error';
    error: Error | string;
};

export type UseExportReportButtonState = ErrorState | InitialState | PollingState | RequestingState | SuccessState;

export type ChangeStateActions =
    | { type: 'complete'; downloadUrl: string }
    | { type: 'error'; error: Error | string }
    | { type: 'poll'; generateReportRequestId: string }
    | { type: 'start' };

export type UseExportReportButtonStateReducerDispatch = React.Dispatch<ChangeStateActions>;

export function useExportReportButtonStateReducer(): [
    UseExportReportButtonState,
    UseExportReportButtonStateReducerDispatch,
] {
    return React.useReducer(
        (state: UseExportReportButtonState, action: ChangeStateActions): UseExportReportButtonState => {
            switch (state.name) {
                case 'initial': {
                    if (action.type === 'start') {
                        return {
                            name: 'requesting',
                        };
                    }
                    return state;
                }
                case 'requesting': {
                    if (action.type === 'poll') {
                        return {
                            generateReportRequestId: action.generateReportRequestId,
                            name: 'polling',
                        };
                    }

                    if (action.type === 'error') {
                        return {
                            error: action.error,
                            name: 'error',
                        };
                    }

                    return state;
                }
                case 'polling': {
                    if (action.type === 'complete') {
                        return {
                            downloadUrl: action.downloadUrl,
                            name: 'success',
                        };
                    }
                    if (action.type === 'error') {
                        return {
                            error: action.error,
                            name: 'error',
                        };
                    }

                    return state;
                }
                case 'error': {
                    if (action.type === 'start') {
                        return {
                            name: 'requesting',
                        };
                    }
                    return state;
                }
                case 'success': {
                    if (action.type === 'start') {
                        return {
                            name: 'requesting',
                        };
                    }
                    return state;
                }
                default:
                    return assertNever(state);
            }
        },
        {
            name: 'initial',
        },
    );
}
