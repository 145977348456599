/// <reference path="../../../typings/browser.d.ts" />
import {
    Client,
    ClientId,
    LocationCategoryGroup,
    LocationCategoryGroupId,
    LocationCategoryOption,
    t,
} from '@deltasierra/shared';
import { MvActivity } from '../activities/mvActivity';
import { $locationSID, $qSID, $routeParamsSID, $scopeSID } from '../common/angularData';
import { DataUtils } from '../common/dataUtils';
import { InteractionUtils } from '../common/interactionUtils';
import { MvNotifier } from '../common/mvNotifier';
import { NavUtils } from '../common/navUtils';
import { I18nService as II18nService } from '../i18n';
import {
    MvLocationCategoryOptionsResource,
    mvLocationCategoryOptionsResourceSID,
} from '../locations/mvLocationCategoryOptionsResource';
import { LocationCategoryOptionApiClient } from './locationCategoryOptionApiClient';
import { MvClientResource, mvClientResourceSID } from './mvClientResource';
import ILocationService = angular.ILocationService;
import IScope = angular.IScope;
import IRouteParams = angular.IRouteParams;

import IQService = angular.IQService;

export interface MvClientLocationCategoriesCtrlScope extends IScope {
    client: Client | null;
    client_id: ClientId;
    categories: LocationCategoryGroup[] | null;
    newCategoryOptions: { [key: number]: string };
    loading: {
        client: boolean;
        categories: boolean;
        save: boolean;
    };
    isActive(route: string): boolean;
    deleteCategoryOption(categoryOption: LocationCategoryOption): void;
    addCategoryOption(category: LocationCategoryGroup): void;
}

angular.module('app').controller('mvClientLocationCategoriesCtrl', [
    $scopeSID,
    $qSID,
    $routeParamsSID,
    $locationSID,
    MvNotifier.SID,
    MvActivity.SID,
    mvClientResourceSID,
    mvLocationCategoryOptionsResourceSID,
    InteractionUtils.SID,
    DataUtils.SID,
    NavUtils.SID,
    II18nService.SID,
    LocationCategoryOptionApiClient.SID,
    function (
        $scope: MvClientLocationCategoriesCtrlScope,
        $q: IQService,
        $routeParams: IRouteParams,
        $location: ILocationService,
        mvNotifier: MvNotifier,
        mvActivity: MvActivity,
        mvClientResource: MvClientResource,
        mvLocationCategoryOptionsResource: MvLocationCategoryOptionsResource,
        interactionUtils: InteractionUtils,
        dataUtils: DataUtils,
        navUtils: NavUtils,
        I18nService: II18nService,
        locationCategoryOptionApiClient: LocationCategoryOptionApiClient,
    ) {
        $scope.client = null;
        $scope.client_id = ClientId.from(parseInt($routeParams.id, 10));
        $scope.categories = null;
        $scope.newCategoryOptions = {};
        $scope.loading = {
            client: false,
            categories: false,
            save: false,
        };
        $scope.isActive = (route: string) => navUtils.isActive(route);

        function initPage() {
            const clientId = $scope.client_id;
            return $q.all([
                interactionUtils
                    .handleResource($scope, 'retrieve client', 'client', mvClientResource.get({ id: clientId }))
                    .then(dataUtils.setScopeProperty($scope, 'client')),
                interactionUtils
                    .handleResource(
                        $scope,
                        'retrieve categories',
                        'categories',
                        mvLocationCategoryOptionsResource.query({ clientId }),
                    )
                    .then(dataUtils.setScopeProperty($scope, 'categories')),
            ]);
        }

        $scope.deleteCategoryOption = function deleteCategoryOption(categoryOption: LocationCategoryOption) {
            return interactionUtils
                .handleRemoteSimple(
                    $scope,
                    'delete category option',
                    'save',
                    locationCategoryOptionApiClient.deleteLocationCategoryOption(
                        $scope.client_id,
                        categoryOption.groupId,
                        categoryOption.id,
                    ),
                )
                .then(() => {
                    const category = dataUtils.findBy('id', $scope.categories || [], categoryOption.groupId);
                    if (category) {
                        dataUtils.removeById(categoryOption.id, category.categoryOptions || []);
                    }
                    mvNotifier.notify(
                        I18nService.text.agency.client.locationCategories.optionDeleted({
                            label: categoryOption.label,
                        }),
                    );
                });
        };

        function addCategoryOptionToCategory(
            categoryOption: LocationCategoryOption,
            categoryId: LocationCategoryGroupId,
        ) {
            const category = dataUtils.findBy('id', $scope.categories || [], categoryId);
            if (!category) {
                throw new Error(`Couldn't find category group for category option. Group ID:${categoryId}`);
            }
            if (!category.categoryOptions) {
                category.categoryOptions = [];
            }
            category.categoryOptions.push(categoryOption);
        }

        $scope.addCategoryOption = function addCategoryOption(category: LocationCategoryGroup) {
            const groupId = category.id;
            const label = $scope.newCategoryOptions[groupId];
            if (!label) {
                mvNotifier.expectedError(t('AGENCY.CLIENT.LOCATION_CATEGORIES.NOTIFY_ENTER_LABEL_FOR_CATEGORY'));
                return;
            }
            const payload = {
                label,
            };
            return interactionUtils
                .handleRemoteSimple(
                    $scope,
                    'add category option',
                    'save',
                    locationCategoryOptionApiClient.createLocationCategoryOption($scope.client_id, groupId, payload),
                )
                .then((newCategoryOption: LocationCategoryOption) => {
                    delete $scope.newCategoryOptions[groupId];
                    addCategoryOptionToCategory(newCategoryOption, groupId);
                    mvNotifier.notify(
                        I18nService.text.agency.client.locationCategories.optionAdded({
                            label: newCategoryOption.label,
                        }),
                    );
                });
        };

        void initPage();
    },
]);
