/// <reference path="../../../../typings/browser.d.ts" />
import { NotificationsBadgerController } from './controller';

const notificationsBadgerDirectiveName = 'dsNotificationsBadger';

function NotificationsBadgerDirective(): ng.IDirective<ng.IScope> {
    return {
        bindToController: true,
        controller: NotificationsBadgerController,
        controllerAs: 'ctrl',
        replace: true,
        restrict: 'E',
        scope: {},
        templateUrl: '/partials/inAppNotifications/notificationsBadger/template',
    };
}

angular.module('app').directive(notificationsBadgerDirectiveName, [NotificationsBadgerDirective]);

