import { DSDivider, DSTableCell, Translate } from '@deltasierra/components';
import { t } from '@deltasierra/shared';
import React from 'react';
import { ScheduledPublishFragmentForBlockedPublishesTable } from './__graphqlTypes/ScheduledPublishFragmentForBlockedPublishesTable';

function Label(props: { label: string }): JSX.Element {
    return <span style={{ fontWeight: 'bold' }}>{`${props.label}: `}</span>;
}
Label.displayName = 'Label';

type BlockedPublishesContentCellProps = {
    row: ScheduledPublishFragmentForBlockedPublishesTable;
};

export function BlockedPublishesContentCell({ row }: BlockedPublishesContentCellProps): JSX.Element {
    const contentParts = [];
    if (row.builderTemplate) {
        contentParts.push(
            <>
                <Label label={t('ADMIN.SERVICING.BLOCKED_PUBLISHES.TABLE.CONTENT_LABELS.TEMPLATE')} />
                <a href={`/contentBuilder?template=${row.builderTemplate.legacyId}`} rel="noreferrer" target="_blank">
                    <Translate keyId="COMMON.VIEW" />
                </a>
            </>,
        );
    }
    if (row.content.__typename === 'ScheduledPublishEmailContent') {
        contentParts.push(
            <>
                <Label label={t('ADMIN.SERVICING.BLOCKED_PUBLISHES.TABLE.CONTENT_LABELS.SUBJECT')} />
                {row.content.subject}
            </>,
        );
        contentParts.push(
            <>
                <Label label={t('ADMIN.SERVICING.BLOCKED_PUBLISHES.TABLE.CONTENT_LABELS.EMAIL_HTML')} />
                <a href={row.content.htmlDownloadUrl} rel="noreferrer" target="_blank">
                    <Translate keyId="COMMON.VIEW" />
                </a>
            </>,
        );
        contentParts.push(
            <>
                <Label label={t('ADMIN.SERVICING.BLOCKED_PUBLISHES.TABLE.CONTENT_LABELS.RECIPIENTS')} />

                {row.content.recipientListDownloadUrl ? (
                    <a href={row.content.recipientListDownloadUrl} rel="noreferrer" target="_blank">
                        <Translate keyId="COMMON.DOWNLOAD" />
                    </a>
                )
                    : 'N/A'
                }
            </>,
        );
    }
    return (
        <DSTableCell>
            {...contentParts.map((content, index) => (
                <span key={index}>
                    {!!index && <DSDivider />} {content}{' '}
                </span>
            ))}
        </DSTableCell>
    );
}
BlockedPublishesContentCell.displayName = 'BlockedPublishesContentCell';
