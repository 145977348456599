import { useQuery } from '@apollo/client';
import { DSWell, DSWellHeader, DSWellHeaderTitle, Translate } from '@deltasierra/components';
import { PlatformName } from '@deltasierra/integrations/integration-types';
import { t } from '@deltasierra/shared';
import * as React from 'react';
import styled from 'styled-components';
import { ConnectPageLink } from '../../../common/react';
import { PLATFORM_NOT_CONNECTED_MESSAGE_QUERY } from './PlatformNotConnectedMessage.queries';
import {
    PlatformNotConnectedMessageQuery,
    PlatformNotConnectedMessageQueryVariables,
} from './__graphqlTypes/PlatformNotConnectedMessageQuery';

const StyledP = styled.p`
    margin: 0;
`;

export type PlatformNotConnectedMessageProps = {
    headerTitle?: string;
    locationId: string;
    platformName?: PlatformName;
    platformTitle: string;
};

export function PlatformNotConnectedMessage({
    headerTitle,
    locationId,
    platformName,
    platformTitle,
}: PlatformNotConnectedMessageProps): JSX.Element {
    const { data } = useQuery<PlatformNotConnectedMessageQuery, PlatformNotConnectedMessageQueryVariables>(
        PLATFORM_NOT_CONNECTED_MESSAGE_QUERY,
        {
            variables: {
                id: locationId,
            },
        },
    );
    return (
        <DSWell>
            {headerTitle && (
                <DSWellHeader>
                    <DSWellHeaderTitle>{headerTitle}</DSWellHeaderTitle>
                </DSWellHeader>
            )}
            <StyledP>
                <Translate
                    keyId="REPORT.NOT_CONNECTED_PART_1"
                    options={{
                        location: data?.location?.title ?? '',
                        platform: platformTitle,
                    }}
                />
                {platformName && (
                    <>
                        &nbsp;
                        <ConnectPageLink
                            customMessage={t('REPORT.NOT_CONNECTED_PART_2', { platform: platformTitle })}
                            locationId={locationId}
                            platform={platformName}
                            showArrow
                        />
                    </>
                )}
            </StyledP>
        </DSWell>
    );
}
PlatformNotConnectedMessage.displayName = 'PlatformNotConnectedMessage';
