/// <reference path="../../../typings/browser.d.ts" />
import { $parseSID, RestrictToAttribute } from '../common/angularData';
import IAugmentedJQuery = angular.IAugmentedJQuery;
import IScope = angular.IScope;
import IAttributes = angular.IAttributes;
import IParseService = angular.IParseService;

export const scrolledToRightEdgeSID = 'scrolledToRightEdge';

export const scrolledToRightEdgeConfig = {
    restrict: RestrictToAttribute,
};

const THRESHOLD_PIXELS = 4;

angular.module('app').directive(scrolledToRightEdgeSID, [
    $parseSID,
    function (
        $parse : IParseService,
    ) {

    return {
        ...scrolledToRightEdgeConfig,
        link(scope : IScope, element : IAugmentedJQuery, attributes : IAttributes) {
            const callback = $parse(attributes.scrolledToRightEdge);
            function update() {
                const domElement = element[0];
                const rightEdge = domElement.scrollLeft + element.width();
                const scrolledToRightEdge = rightEdge >= domElement.scrollWidth - THRESHOLD_PIXELS;
                return callback(scope, {
                    scrolledToRightEdge,
                });
            }

            scope.$watch(() => element.get(0).scrollWidth, update);
            angular.element(element).bind('scroll', () => scope.$apply(update));
        },
    };
},
]);
