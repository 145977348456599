import styled from 'styled-components';

export const StyledRightSpan = styled.span`
    float: right;
    height: 100%;
`;

export const StyledLeftSpan = styled.span`
    float: left;
`;
