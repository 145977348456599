import * as React from 'react';

export type ClubReadyListCountProps = {
    listName: string;
    filterText?: string;
    count: number;
    hideCount?: boolean;
};

export function ClubReadyListCount({ count, filterText, hideCount = false, listName }: ClubReadyListCountProps): JSX.Element {
    const countWrapped = React.useMemo(() => hideCount ? '' : ` (${count})`, [count, hideCount]);
    const filterTextAppended = filterText ? ` - ${filterText}` : '';
    const listNameCountAndInfo = `${listName}${filterTextAppended}${countWrapped}`;

    return <>{listNameCountAndInfo}</>;
}

ClubReadyListCount.displayName = 'ClubReadyListCount';
