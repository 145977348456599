import { assertNever } from '@deltasierra/shared';
import * as React from 'react';
import { FileType } from '../../../__graphqlTypes/globalTypes';

export type SelectableAsset = { id: string; type: 'document' | 'folder' | 'image' | 'video' };

export function translateFileTypeToString(ft: FileType): 'document' | 'image' | 'video' {
    switch (ft) {
        case FileType.DOCUMENT:
            return 'document';
        case FileType.IMAGE:
            return 'image';
        case FileType.VIDEO:
            return 'video';
        default:
            return assertNever(ft);
    }
}

export type AssetLibraryActions = {
    toggleAssetSelected?: (asset: SelectableAsset) => void;
    toggleAllAssetsSelected?: () => void;
    createFolder?: () => Promise<void>;
    moveAssets?: () => Promise<void>;
    deleteAssets?: (assets: SelectableAsset) => void;
    uploadFiles?: () => void;
    createCollection?: () => void;
};

export const AssetLibraryContext = React.createContext<AssetLibraryActions>({});

export const useAssetLibraryContext = (): AssetLibraryActions => React.useContext(AssetLibraryContext);
