import {
    translate,
    CallToAction,
    CallToActionType,
    Url,
} from '@deltasierra/shared';
import * as React from 'react';
import { StandaloneSelect } from '../../../common/form/Select';
import { UrlInput } from '../../../common/form/TextInput';

export type EditCallToActionButtonProps = {
    value: CallToAction | null;
    onChange: (newValue: CallToAction | null) => void;
};

export const EditCallToActionButton: React.FunctionComponent<EditCallToActionButtonProps> = ({ onChange, value }) => {
    const { actionType = '', url = Url.from('') } = value || {};
    const callToActionTypes = useCallToActionTypes();

    const onChangeType = (newType: CallToActionType | '') => {
        if (newType === '') {
            onChange(null);
        } else if (newType === CallToActionType.call) {
            onChange({ actionType: newType, url: Url.from('') });
        } else {
            onChange({ actionType: newType, url });
        }
    };
    const onChangeUrl = (newUrl: string) => {
        if (actionType !== '') {
            onChange({ actionType, url: Url.from(newUrl) });
        }
    };

    return (
        <>
            <StandaloneSelect
                label={{ keyId: 'BUILD.PUBLISH.GOOGLE_MY_BUSINESS.CALL_TO_ACTION' }}
                options={callToActionTypes}
                value={actionType}
                onChange={onChangeType}
            />
            <UrlInput
                disabled={actionType === '' || actionType === CallToActionType.call}
                label={{ keyId: 'BUILD.PUBLISH.GOOGLE_MY_BUSINESS.BUTTON_LINK' }}
                required={actionType !== '' && actionType !== CallToActionType.call}
                value={url}
                onChange={onChangeUrl}
            />
        </>
    );
};

function useCallToActionTypes(): Array<{ label: string; value: CallToActionType | '' }> {
    return React.useMemo(
        () => [
            { label: translate('COMMON.NONE'), value: '' },
            { label: translate('BUILD.PUBLISH.GOOGLE_MY_BUSINESS.BUTTON_TYPES.BOOK'), value: CallToActionType.book },
            {
                label: translate('BUILD.PUBLISH.GOOGLE_MY_BUSINESS.BUTTON_TYPES.LEARN_MORE'),
                value: CallToActionType.learnMore,
            },
            { label: translate('BUILD.PUBLISH.GOOGLE_MY_BUSINESS.BUTTON_TYPES.ORDER'), value: CallToActionType.order },
            {
                label: translate('BUILD.PUBLISH.GOOGLE_MY_BUSINESS.BUTTON_TYPES.SIGN_UP'),
                value: CallToActionType.signUp,
            },
            { label: translate('BUILD.PUBLISH.GOOGLE_MY_BUSINESS.BUTTON_TYPES.SHOP'), value: CallToActionType.shop },
            { label: translate('BUILD.PUBLISH.GOOGLE_MY_BUSINESS.BUTTON_TYPES.CALL'), value: CallToActionType.call },
        ],
        [],
    );
}

EditCallToActionButton.displayName = 'EditCallToActionButton';
