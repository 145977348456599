import { UserId, justParams, DeviceApi, UserDevicesStatus } from '@deltasierra/shared';


import { invokeApiRoute } from '../common/httpUtils';
import { $httpSID } from '../common/angularData';
import IPromise = angular.IPromise;

export class DeviceApiClient {
    static SID = 'DeviceApiClient';

    static readonly $inject : string[] = [
        $httpSID,
    ];

    constructor(protected readonly $http : ng.IHttpService) {

    }

    getUserDevicesStatus(id : UserId) : IPromise<UserDevicesStatus> {
        return invokeApiRoute(
            this.$http,
            DeviceApi.getUserDeviceStatus,
            justParams({
                id,
            }),
        );
    }
}

angular.module('app').service(DeviceApiClient.SID, DeviceApiClient);
