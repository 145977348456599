import { ExpressionBinding, OneWayBinding, simpleComponent } from '../../../common/angularData';
import { PublishVideoController, PublishVideoControllerScope } from './controller';
import IDirective = angular.IDirective;

export const dsPublishVideoConfig: IDirective<PublishVideoControllerScope> = simpleComponent(
    PublishVideoController,
    '/partials/contentBuilder/publish/publishVideoDirective/template',
    {
        channelData: OneWayBinding,
        contentBuilder: OneWayBinding,
        location: OneWayBinding,
        onFinish: ExpressionBinding,
        onPublishVideo: ExpressionBinding,
        outputDimensions: OneWayBinding,
        plannerDetails: OneWayBinding,
        templateId: OneWayBinding,
    },
);
