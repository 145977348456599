/// <reference path="../../../../_references.d.ts" />

import { CurrencyCode } from '@deltasierra/shared';
import { MoneyFilter, MoneyFilterFormat } from '../../../../filters/money';

export const SID = 'money-metric';

angular.module('app').filter(SID, [
    '$filter',
    ($filter: ng.IFilterService) => {
        const formatMoney = $filter('money') as MoneyFilter;

        // Note this filter is just a lite wrapper around the money filter but written
        // So that params are basic values (strings or numbers) rather than an options object
        // As the countup directive used to animate figures in the dashboard can only handle
        // Basic param types
        function formatMoneyMetric(
            value: number,
            format: MoneyFilterFormat,
            code?: CurrencyCode,
            fractionSize?: number | string,
        ): string {
            if (typeof fractionSize === 'string') {
                if (fractionSize) {
                    fractionSize = parseInt(fractionSize, 10);
                } else {
                    fractionSize = undefined;
                }
            }

            const result = formatMoney(value, {
                format,
                code,
                fractionSize: fractionSize as number,
            });

            return result;
        }

        return formatMoneyMetric;
    },
]);
