/// <reference path="../../../typings/browser.d.ts" />
import { Untyped } from '@deltasierra/shared';
import { MvIdentity } from '../account/mvIdentity';
import { $httpSID, $locationSID, $qSID, $scopeSID, $windowSID } from '../common/angularData';
import { InteractionUtils } from '../common/interactionUtils';
import { CurrencyService } from './currencyService';
import { PaymentData, PaymentService } from './mvPaymentService';
import IQService = angular.IQService;
import IHttpService = angular.IHttpService;
import ILocationService = angular.ILocationService;

interface MvPaymentsConfirmCtrlScope {
    paymentData: PaymentData;
    loading: {
        startPayment: boolean;
        redirect: boolean;
    };
    reviseDetails(): void;
    getLargestUnit(paymentData: PaymentData): number;
    getCurrency(paymentData: PaymentData): string;
    startPayment(): void;
}

angular.module('app').controller('mvPaymentsConfirmCtrl', [
    $scopeSID,
    $qSID,
    $httpSID,
    $locationSID,
    $windowSID,
    InteractionUtils.SID,
    CurrencyService.SID,
    PaymentService.SID,
    MvIdentity.SID,
    function (
        $scope: MvPaymentsConfirmCtrlScope,
        $q: IQService,
        $http: IHttpService,
        $location: ILocationService,
        $window: ng.IWindowService,
        interactionUtils: InteractionUtils,
        currencyService: CurrencyService,
        mvPaymentService: PaymentService,
        mvIdentity: MvIdentity,
    ) {
        $scope.paymentData = $location.search() as PaymentData;

        $scope.loading = {
            startPayment: false,
            redirect: false,
        };

        $scope.reviseDetails = function () {
            if ($scope.paymentData.specialRequestId) {
                $location.url(`/specialRequests/${$scope.paymentData.specialRequestId}`);
            } else {
                $location.path('/payments').search($scope.paymentData);
            }
        };

        function redirectToLogin() {
            const redirPath = encodeURIComponent($location.url());
            window.location.assign(`/login?r=${redirPath}`);
        }

        function initPage() {
            if ($scope.paymentData.specialRequestId && !mvIdentity.currentUser) {
                redirectToLogin();
            }
        }

        $scope.getLargestUnit = function (paymentData: PaymentData) {
            if (paymentData) {
                return currencyService.getLargestUnit(paymentData.currency, paymentData.amount);
            } else {
                return 0;
            }
        };

        $scope.getCurrency = function (paymentData: PaymentData) {
            if (paymentData) {
                return paymentData.currency;
            } else {
                return '';
            }
        };

        $scope.startPayment = function () {
            return interactionUtils
                .handleRemoteSimple(
                    $scope,
                    'start payment',
                    'startPayment',
                    mvPaymentService.startPayment($scope.paymentData),
                )
                .then((result: Untyped) => {
                    $scope.loading.redirect = true;
                    $window.location.assign(result.SharedPaymentUrl);
                });
        };

        initPage();
    },
]);

