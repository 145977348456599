import { Upload } from '@deltasierra/shared';
import { UploadService } from '../../common/uploadService';

export class UploadBlockController {
    deleteUpload?: (upload: Upload) => void;

    upload!: Upload;

    height?: number;

    width?: number;

    titleVisible?: boolean;

    static readonly $inject : string[] = [UploadService.SID];

    constructor(private uploadService: UploadService) {
    }

    canDelete() {
        return this.deleteUpload != null;
    }

    getUploadGlyph() {
        return this.uploadService.getUploadGlyph(this.upload.ext);
    }

    delete() {
        if (this.canDelete() && this.deleteUpload) {
            this.deleteUpload(this.upload);
        }
    }

    isTitleVisible() {
        return (this.titleVisible === undefined) || this.titleVisible;
    }
}
