/// <reference path="../../../../typings/browser.d.ts" />
import { IScope } from 'angular';
import IAugmentedJQuery = angular.IAugmentedJQuery;

export function AppendDirective(): ng.IDirective<ng.IScope & { element: IAugmentedJQuery }> {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            element: '=',
        },
        link: ($scope: IScope & { element: IAugmentedJQuery }, element: IAugmentedJQuery) => {
            angular.element(element).append($scope.element);
        },
    };
}

angular.module('app').directive('dsAppend', [AppendDirective]);
