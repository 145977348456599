import { gql } from '@apollo/client';
import * as React from 'react';
import { GenericListItem } from './GenericListItem';
import { CollectionFragmentForCollectionList } from './__graphqlTypes/CollectionFragmentForCollectionList';

const fragments = {
    COLLECTION_FRAGMENT_FOR_COLLECTION_LIST: gql`
        fragment CollectionFragmentForCollectionList on Collection {
            assetCount
            id
            majorityFileType
            title
        }
    `,
};

export interface CollectionListItemProps {
    collection: CollectionFragmentForCollectionList;
    isSelected?: boolean;
    onClick?: (id: string) => void;
}

/**
 * CollectionListItem
 * List Item to be used in CollectionList that displays available collections to select
 *
 * @param props - Prop object
 * @param props.collection - A CollectionFragemntForCollectionList, created by querying using the provided fragment
 * @param props.isSelected - When true, styles the list item a bit different to signify that it is selected
 * @param props.onClick - Callback that sends the id of the selected collection
 *
 * @returns CollectionListItem
 */
export function CollectionListItem(props: CollectionListItemProps): JSX.Element {
    return (
        <GenericListItem
            count={props.collection.assetCount}
            dataCy="collection-list-item"
            icon="folder-thin"
            isSelected={props.isSelected}
            text={props.collection.title}
            onClick={() => props.onClick?.(props.collection.id)}
        />
    );
}
CollectionListItem.displayName = 'CollectionListItem';
CollectionListItem.fragments = fragments;
