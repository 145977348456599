/* eslint-disable max-lines-per-function */
import { useApolloClient } from '@apollo/client';
import { CUSTOM_LIST_DESCRIPTION_MAX_LENGTH, t } from '@deltasierra/shared';
import { FormikConfig, FormikErrors } from 'formik';
import React from 'react';
import { simpleUploadFile } from '../../../../../../../common/components/uploading/utils/upload-file';
import { PublishToClubReadyStep1Data } from '../types/publish-to-club-ready-step-1-data';
import {
    PublishToClubReadyStep1FormValues,
    PublishToClubReadyStep1FormValuesActiveOptions,
    PublishToClubReadyStep1FormValuesInactiveOptions,
    PublishToClubReadyStep1FormValuesProspectsOptions,
} from '../types/publish-to-club-ready-step-1-form-values';

export function usePublishToClubReadyStep1Form(
    gotoNextStep: (values: PublishToClubReadyStep1Data) => void,
    step1Data?: PublishToClubReadyStep1Data,
): FormikConfig<PublishToClubReadyStep1FormValues> {
    const apolloClient = useApolloClient();
    const initialValues: PublishToClubReadyStep1FormValues = React.useMemo(() => {
        if (step1Data) {
            return step1Data.values;
        }
        return {
            active: {
                afterDate: null,
                beforeDate: null,
                listChoice: 'all',
                send: false,
            },
            cooledOffProspects: {
                send: false,
            },
            emailList: '',
            filterType: 'filter',
            inactive: {
                afterDate: null,
                beforeDate: null,
                listChoice: 'all',
                send: false,
            },
            listDescription: null,
            prospects: {
                from: null,
                listChoice: 'all',
                send: false,
                to: null,
            },
        };
    }, [step1Data]);

    const validateFilterListOptions = React.useCallback(
        (values: PublishToClubReadyStep1FormValues): string | undefined => {
            if (
                !values.active.send &&
                !values.cooledOffProspects.send &&
                !values.inactive.send &&
                !values.prospects.send
            ) {
                return t('BUILD.PUBLISH.CLUB_READY.AT_LEAST_ONE_LIST_MUST_BE_SELECTED');
            }
            return undefined;
        },
        [],
    );

    const validateDateBeforeAfterField = React.useCallback(
        (
            values: PublishToClubReadyStep1FormValuesActiveOptions | PublishToClubReadyStep1FormValuesInactiveOptions,
        ): string | undefined => {
            if (values.send) {
                if (
                    values.listChoice === 'withActivityAfter' &&
                    (values.afterDate === null || values.afterDate === '')
                ) {
                    return t('BUILD.PUBLISH.CLUB_READY.VALID_DATE_REQUIRED_ACTIVITY_AFTER_OPTION');
                } else if (
                    values.listChoice === 'withActivityBefore' &&
                    (values.beforeDate === null || values.beforeDate === '')
                ) {
                    return t('BUILD.PUBLISH.CLUB_READY.VALID_DATE_REQUIRED_ACTIVITY_BEFORE_OPTION');
                }
            }
            return undefined;
        },
        [],
    );

    const validateDateRangeField = React.useCallback(
        (values: PublishToClubReadyStep1FormValuesProspectsOptions): string | undefined => {
            if (values.send && values.listChoice === 'addedBetween') {
                if (
                    values.to === null ||
                    values.to === '' ||
                    values.to === 'Invalid date' ||
                    values.from === null ||
                    values.from === '' ||
                    values.from === 'Invalid date'
                ) {
                    return t('BUILD.PUBLISH.CLUB_READY.VALID_DATE_REQUIRED_ACTIVITY_BETWEEN_OPTION');
                }
            }
            return undefined;
        },
        [],
    );

    const validateListDescription = React.useCallback((listDescription: string | null): string | undefined => {
        if (listDescription && listDescription.length > CUSTOM_LIST_DESCRIPTION_MAX_LENGTH) {
            return t('BUILD.PUBLISH.CLUB_READY.CUSTOM_LIST_DESCRIPTION_WARNING', {
                maxLength: CUSTOM_LIST_DESCRIPTION_MAX_LENGTH,
            });
        }
        return undefined;
    }, []);

    const validateEmailList = React.useCallback((emailList: string): string | undefined => {
        if (emailList.length === 0) {
            return t('BUILD.PUBLISH.CLUB_READY.EMAIL_LIST_MUST_HAVE_VALUES');
        }
        return undefined;
    }, []);

    const validate = React.useCallback(
        (values: PublishToClubReadyStep1FormValues): FormikErrors<Partial<PublishToClubReadyStep1FormValues>> => {
            let errors: FormikErrors<Partial<PublishToClubReadyStep1FormValues>> = {};
            if (values.filterType === 'filter') {
                const filterType = validateFilterListOptions(values);
                const active = validateDateBeforeAfterField(values.active);
                const inactive = validateDateBeforeAfterField(values.inactive);
                const prospects = validateDateRangeField(values.prospects);
                errors = {
                    ...filterType && { filterType },
                    ...active && { active },
                    ...inactive && { inactive },
                    ...prospects && { prospects },
                };
            } else if (values.filterType === 'emails') {
                const listDescription = validateListDescription(values.listDescription);
                const emailList = validateEmailList(values.emailList);
                errors = {
                    ...listDescription && { listDescription },
                    ...emailList && { emailList },
                };
            }
            return errors;
        },
        [
            validateDateBeforeAfterField,
            validateDateRangeField,
            validateEmailList,
            validateFilterListOptions,
            validateListDescription,
        ],
    );

    return {
        initialValues,
        onSubmit: async (values, helpers) => {
            if (values.filterType === 'filter') {
                gotoNextStep({
                    active: values.active,
                    cooledOffProspects: values.cooledOffProspects,
                    filterType: 'filter',
                    inactive: values.inactive,
                    prospects: values.prospects,
                    values,
                });
            } else {
                helpers.setSubmitting(true);
                let uploadId: string | null = null;

                if (step1Data?.filterType === 'emails') {
                    // Previously had submitted step 1 with emails true
                    if (values.emailList === step1Data.emailList && step1Data.emailListUploadId) {
                        // Has the same email list as submitted before
                        uploadId = step1Data.emailListUploadId;
                    }
                }
                if (!uploadId) {
                    const { id } = await simpleUploadFile({
                        category: 'emailPublishing/emailList',
                        client: apolloClient,
                        file: new File([values.emailList], 'emailList.txt', { type: 'text/plain' }),
                    });
                    uploadId = id;
                }

                helpers.setSubmitting(false);

                gotoNextStep({
                    emailList: values.emailList,
                    emailListUploadId: uploadId,
                    filterType: 'emails',
                    listDescription: values.listDescription,
                    values,
                });
            }
        },
        validate,
    };
}
