/// <reference path="../../../typings/browser.d.ts" />
import IScope = angular.IScope;
import IAugmentedJQuery = angular.IAugmentedJQuery;
import INgModelController = angular.INgModelController;
import IAttributes = angular.IAttributes;

function isDate(date : Date | any) : date is Date {
    return angular.isDate(date);
}

const linkFunction: ng.IDirectiveLinkFn<IScope> = function (scope : IScope, element : IAugmentedJQuery, attrs : IAttributes, ngModelCtrl : INgModelController) {
    ngModelCtrl.$parsers.push((datepickerValue : Date | any) => {
        if (isDate(datepickerValue)) {
            return new Date(Date.UTC(datepickerValue.getFullYear(), datepickerValue.getMonth(), datepickerValue.getDate()));
        } else {
            return datepickerValue;
        }
    });
};

export const dateToIsoSID = 'dateToIso';
export const dateToIsoConfig = {
    restrict: 'A',
    require: 'ngModel',
    link: linkFunction,
};

angular.module('app').directive(dateToIsoSID, [() => dateToIsoConfig]);
