import {
    calculateRecommendedSpend,
    calculateBudgetPerDay,
} from '@deltasierra/shared';
import { round } from '@deltasierra/math-utilities';
import { ReportTable, StatsEntry } from '../reportTable/reportTable';
import { AdjustableRecommendationsLocationStats } from '../reportTables/googleAdWords';
import { GoogleAdWordsSpendAdjustmentRequestService } from '../googleAdWordsSpendAdjustmentRequest';

export class GoogleAdWordsRecommendationsRowFooterController {
    reportTable!: ReportTable<
        StatsEntry<AdjustableRecommendationsLocationStats>,
        AdjustableRecommendationsLocationStats
    >;

    entry!: StatsEntry<AdjustableRecommendationsLocationStats>;

    static readonly $inject = [GoogleAdWordsSpendAdjustmentRequestService.SID];

    constructor(private spendAdjustmentRequestService: GoogleAdWordsSpendAdjustmentRequestService) {}

    getRequestedSpendLabelPositionPercentage(): number {
        const result = (this.getRecommendedSpend() - this.entry.cost) / (this.getMaximumSpend() - this.entry.cost);
        return round(result * 100, 2);
    }

    requestSpendAdjustment() {
        const potentialSpend = this.entry.potentialSpend || 0;
        if (potentialSpend <= this.entry.cost) {
            return;
        }

        const recommendedSpendOverDateRange = calculateRecommendedSpend(
            this.entry.cost,
            this.entry.impressionShareLostByBudget,
        );

        const adjustedSpendPerDay = calculateBudgetPerDay(potentialSpend, this.reportTable.data.reportPeriod);
        const recommendedSpendPerDay = calculateBudgetPerDay(
            recommendedSpendOverDateRange,
            this.reportTable.data.reportPeriod,
        );

        return this.spendAdjustmentRequestService.confirmWithUser({
            location: {
                id: this.entry.location.locationId,
                title: this.entry.location.locationTitle,
            },
            currencyCode: this.entry.currencyCode!,
            adjustedSpendPerDay,
            recommendedSpendPerDay,
        });
    }

    getMaximumSpend() {
        const recommendedSpend = this.getRecommendedSpend();
        return round(recommendedSpend * 1.5, 2);
    }

    getRecommendedSpend() {
        return calculateRecommendedSpend(this.entry.cost, this.entry.impressionShareLostByBudget);
    }

    getAdjustedDailyBudget() {
        const dailyBudget = (this.entry.potentialSpend || 0) / this.reportTable.data.reportPeriod;
        return round(dailyBudget, 2);
    }

    clickRecommendedSpend() {
        this.entry.potentialSpend = this.getRecommendedSpend();
    }
}
