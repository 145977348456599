/* eslint-disable max-lines-per-function */
import {
    DSButton,
    DSFormControl,
    DSGrid,
    DSMenuItem,
    DSSelect,
    DSTextField,
    DSTypography,
    Translate,
} from '@deltasierra/components';
import { t, timezones, AssignedLocation, LocationId, LocationMap as AssignedLocationMap, Upload } from '@deltasierra/shared';
import { Form, Formik, FormikHelpers } from 'formik';
import * as React from 'react';
import styled from 'styled-components';
import { OneWayBinding } from '../../../common/angularData';
import { useAngularServiceContext } from '../../../common/componentUtils/angularServiceContexts';
import { withAngularIntegration } from '../../../common/componentUtils/reactComponentRegistration';
import { EditLocationLogo } from '../../components/EditLocationLogo';
import { LocationMap } from '../../components/LocationMap';
import { CreateNewMapController } from '../../createNewMap/controller';

export interface LocationDetailsProps {
    location: AssignedLocation;
}

type FormValues = {
    addressLineOne: string | null;
    addressLineThree: string | null;
    addressLineTwo: string | null;
    locationEmail: string | null;
    locationId: LocationId | null;
    locationLogo: Upload | null;
    locationMap: AssignedLocationMap | null;
    paymentComplete: boolean;
    phoneNumber: string | null;
    shippingAttention: string | null;
    shippingCompanyName: string | null;
    shippingLineOne: string | null;
    shippingLineThree: string | null;
    shippingLineTwo: string | null;
    timezone: string | null;
    websiteUrl: string | null;
};

const StyledButtonGrid = styled(DSGrid)`
    margin-top: 5px;
`;

const StyledLegend = styled.legend`
    margin-top: 10px;
`;

const MINIMUM_LOGO_SIZE_IN_PIXELS = 1080;

const mapLocationToFormValues = (location: AssignedLocation): FormValues => ({
    addressLineOne: location.addressLineOne ?? '',
    addressLineThree: location.addressLineThree ?? '',
    addressLineTwo: location.addressLineTwo ?? '',
    locationEmail: location.locationEmail ?? '',
    locationId: location.id,
    locationLogo: location.logo || null,
    locationMap: location.map || null,
    paymentComplete: false,
    phoneNumber: location.phoneNumber ?? '',
    shippingAttention: location.shippingAttention ?? '',
    shippingCompanyName: location.shippingCompanyName ?? '',
    shippingLineOne: location.shippingLineOne ?? '',
    shippingLineThree: location.shippingLineThree ?? '',
    shippingLineTwo: location.shippingLineTwo ?? '',
    timezone: location.timezone ?? '',
    websiteUrl: location.websiteUrl ?? '',
});
function useTimezones(): Array<{ label: string; value: string }> {
    return React.useMemo(() => timezones.map(({ keyId, value }) => ({ label: t(keyId), value })), []);
}

export function LocationDetails({ location }: LocationDetailsProps): JSX.Element {
    // TODO: Migrate over to graphql
    const imageLoaderService = useAngularServiceContext('imageLoaderService');
    const mvNotifier = useAngularServiceContext('mvNotifier');
    const uploadService = useAngularServiceContext('uploadService');
    const fileUtils = useAngularServiceContext('fileUtils');
    const modalService = useAngularServiceContext('$modal');
    const mvLocation = useAngularServiceContext('mvLocation');
    const timezoneList = useTimezones();

    const onLogoSelect = async (file: File, onUploadComplete: (upload: Upload | undefined) => void): Promise<void> =>
        imageLoaderService.getFileDimensions(file).then(async dimensions => {
            if (dimensions.width !== dimensions.height) {
                mvNotifier.expectedError(t('ACCOUNT.DETAILS.SQUARE_ASPECT_RATIO_REQUIRED'));
                return;
            } else if (dimensions.width < MINIMUM_LOGO_SIZE_IN_PIXELS) {
                mvNotifier.expectedError(
                    t('ACCOUNT.DETAILS.MINIMUM_IMAGE_DIMENSION_REQUIREMENT', {
                        height: MINIMUM_LOGO_SIZE_IN_PIXELS.toString(),
                        width: MINIMUM_LOGO_SIZE_IN_PIXELS.toString(),
                    }),
                );
                return;
            }

            if (!fileUtils.isImageMimeType(file.type)) {
                mvNotifier.expectedError(
                    t('COMMON.UNSUPPORTED_IMAGE_FILE_TYPE', { fileTypes: fileUtils.imageFileTypes.join(',') }),
                );
                return;
            }

            const [uploadResult] = await Promise.all(uploadService.upload([file], 'locationDetails', [], {}));
            onUploadComplete(uploadResult);
        });

    const createNewMap = async (onCreateSuccess: (result: boolean) => void): Promise<void> => {
        const modalInstance = modalService.open({
            controller: CreateNewMapController,
            controllerAs: 'ctrl',
            resolve: {
                clientId: () => location.clientId ?? null,
                initialLocationGeo: () =>
                    location.map ? { lat: parseFloat(location.map.lat), lon: parseFloat(location.map.lon) } : null,
                location: () => location,
            },
            templateUrl: '/partials/account/createNewMap/template',
        });

        return modalInstance.result.then((result: { paymentCompleted: boolean }) => {
            onCreateSuccess(result.paymentCompleted);
        });
    };

    const deleteMap = async (onDeleteSuccess: () => void): Promise<void> =>
        mvLocation.removeLocationMap(location.id).then(onDeleteSuccess);

    const submitSaveLocationDetails = async (
        values: Partial<FormValues>,
        formikHelpers: FormikHelpers<FormValues>,
    ): Promise<void> => {
        formikHelpers.setSubmitting(true);
        return mvLocation
            .updateAccountLocationDetails(values.locationId!, {
                addressLineOne: values.addressLineOne || null,
                addressLineThree: values.addressLineThree || null,
                addressLineTwo: values.addressLineTwo || null,
                locationEmail: values.locationEmail || null,
                logoId: values.locationLogo?.id || null,
                phoneNumber: values.phoneNumber || null,
                shippingAttention: values.shippingAttention || null,
                shippingCompanyName: values.shippingCompanyName || null,
                shippingLineOne: values.shippingLineOne || null,
                shippingLineThree: values.shippingLineThree || null,
                shippingLineTwo: values.shippingLineTwo || null,
                timezone: values.timezone || null,
                websiteUrl: values.websiteUrl || null,
            })
            .then(() => mvNotifier.notify(t('ACCOUNT.DETAILS.LOCATION_DETAILS_SAVED')))
            .finally(() => formikHelpers.setSubmitting(false));
    };

    return (
        <>
            <Formik<FormValues>
                enableReinitialize
                initialValues={mapLocationToFormValues(location)}
                onSubmit={submitSaveLocationDetails}
            >
                {formik => (
                    <>
                        <Form>
                            <DSGrid container direction="column">
                                <StyledLegend>{t('ACCOUNT.DETAILS.LOCATION_DETAILS')}</StyledLegend>
                                <DSGrid alignItems="center" container direction="row" item>
                                    <DSGrid item md={2} sm={3} xs={12}>
                                        <DSTypography component="label" variant="inherit">
                                            {t('ACCOUNT.DETAILS.LOCATION_PHONE_NUMBER')}
                                        </DSTypography>
                                    </DSGrid>
                                    <DSGrid item md={10} sm={9} xs={12}>
                                        <DSTextField
                                            {...formik.getFieldProps('phoneNumber')}
                                            fullWidth
                                            placeholder="Phone number"
                                            variant="outlined"
                                        />
                                    </DSGrid>
                                    <DSGrid item md={2} sm={3} xs={12}>
                                        <DSTypography component="label" variant="h6">
                                            {t('ACCOUNT.DETAILS.LOCATION_EMAIL')}
                                        </DSTypography>
                                    </DSGrid>
                                    <DSGrid item md={10} sm={9} xs={12}>
                                        <DSTextField
                                            {...formik.getFieldProps('locationEmail')}
                                            fullWidth
                                            placeholder="Email"
                                            variant="outlined"
                                        />
                                    </DSGrid>
                                    <DSGrid item md={2} sm={3} xs={12}>
                                        <DSTypography component="label" variant="h6">
                                            {t('ACCOUNT.DETAILS.WEBSITE_URL')}
                                        </DSTypography>
                                    </DSGrid>
                                    <DSGrid item md={10} sm={9} xs={12}>
                                        <DSTextField
                                            {...formik.getFieldProps('websiteUrl')}
                                            fullWidth
                                            placeholder="Website URL"
                                            variant="outlined"
                                        />
                                    </DSGrid>
                                    <DSGrid item md={2} sm={3} xs={12}>
                                        <DSTypography component="label" variant="h6">
                                            {t('COMMON.TIMEZONE')}
                                        </DSTypography>
                                    </DSGrid>
                                    <DSGrid item md={10} sm={9} xs={12}>
                                        <DSFormControl fullWidth={true} margin="dense" variant="outlined">
                                            <DSSelect {...formik.getFieldProps('timezone')}>
                                                {timezoneList.map(timezone => (
                                                    <DSMenuItem key={timezone.value} value={timezone.value}>
                                                        {timezone.label}
                                                    </DSMenuItem>
                                                ))}
                                            </DSSelect>
                                        </DSFormControl>
                                    </DSGrid>
                                    <EditLocationLogo
                                        deleteLogo={() => formik.setFieldValue('locationLogo', null)}
                                        logo={formik.getFieldProps('locationLogo').value}
                                        uploadLogo={async file =>
                                            onLogoSelect(file, upload => formik.setFieldValue('locationLogo', upload))
                                        }
                                    />
                                    <LocationMap
                                        createMap={async () =>
                                            createNewMap(result => formik.setFieldValue('paymentComplete', result))
                                        }
                                        deleteMap={async () =>
                                            deleteMap(() => formik.setFieldValue('locationMap', null))
                                        }
                                        map={formik.getFieldProps('locationMap').value}
                                        paymentComplete={formik.getFieldProps('paymentComplete').value}
                                    />
                                </DSGrid>
                                <StyledLegend>{t('ACCOUNT.DETAILS.DISPLAY_ADDRESS')}</StyledLegend>
                                <DSGrid alignItems="center" container direction="row" item>
                                    <DSGrid item md={2} sm={3} xs={12}>
                                        <DSTypography component="label" variant="h6">
                                            {t('ACCOUNT.DETAILS.ADDRESS_LINE_ONE')}
                                        </DSTypography>
                                    </DSGrid>
                                    <DSGrid item md={10} sm={9} xs={12}>
                                        <DSTextField
                                            {...formik.getFieldProps('addressLineOne')}
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </DSGrid>
                                    <DSGrid item md={2} sm={3} xs={12}>
                                        <DSTypography component="label" variant="h6">
                                            {t('ACCOUNT.DETAILS.ADDRESS_LINE_TWO')}
                                        </DSTypography>
                                    </DSGrid>
                                    <DSGrid item md={10} sm={9} xs={12}>
                                        <DSTextField
                                            {...formik.getFieldProps('addressLineTwo')}
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </DSGrid>
                                    <DSGrid item md={2} sm={3} xs={12}>
                                        <DSTypography component="label" variant="h6">
                                            {t('ACCOUNT.DETAILS.ADDRESS_LINE_THREE')}
                                        </DSTypography>
                                    </DSGrid>
                                    <DSGrid item md={10} sm={9} xs={12}>
                                        <DSTextField
                                            {...formik.getFieldProps('addressLineThree')}
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </DSGrid>
                                </DSGrid>
                                <StyledLegend>{t('ACCOUNT.DETAILS.SHIPPING_ADDRESS')}</StyledLegend>
                                <DSGrid alignItems="center" container direction="row" item>
                                    <DSGrid item md={2} sm={3} xs={12}>
                                        <DSTypography component="label" variant="h6">
                                            {t('ACCOUNT.DETAILS.ATTENTION_TO')}
                                        </DSTypography>
                                    </DSGrid>
                                    <DSGrid item md={10} sm={9} xs={12}>
                                        <DSTextField
                                            {...formik.getFieldProps('shippingAttention')}
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </DSGrid>
                                    <DSGrid item md={2} sm={3} xs={12}>
                                        <DSTypography component="label" variant="h6">
                                            {t('ACCOUNT.DETAILS.COMPANY_NAME')}
                                        </DSTypography>
                                    </DSGrid>
                                    <DSGrid item md={10} sm={9} xs={12}>
                                        <DSTextField
                                            {...formik.getFieldProps('shippingCompanyName')}
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </DSGrid>
                                    <DSGrid item md={2} sm={3} xs={12}>
                                        <DSTypography component="label" variant="h6">
                                            {t('ACCOUNT.DETAILS.ADDRESS_LINE_ONE')}
                                        </DSTypography>
                                    </DSGrid>
                                    <DSGrid item md={10} sm={9} xs={12}>
                                        <DSTextField
                                            {...formik.getFieldProps('shippingLineOne')}
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </DSGrid>
                                    <DSGrid item md={2} sm={3} xs={12}>
                                        <DSTypography component="label" variant="h6">
                                            {t('ACCOUNT.DETAILS.ADDRESS_LINE_TWO')}
                                        </DSTypography>
                                    </DSGrid>
                                    <DSGrid item md={10} sm={9} xs={12}>
                                        <DSTextField
                                            {...formik.getFieldProps('shippingLineTwo')}
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </DSGrid>
                                    <DSGrid item md={2} sm={3} xs={12}>
                                        <DSTypography component="label" variant="h6">
                                            {t('ACCOUNT.DETAILS.ADDRESS_LINE_THREE')}
                                        </DSTypography>
                                    </DSGrid>
                                    <DSGrid item md={10} sm={9} xs={12}>
                                        <DSTextField
                                            {...formik.getFieldProps('shippingLineThree')}
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </DSGrid>
                                </DSGrid>
                                <StyledButtonGrid container direction="row-reverse">
                                    <DSButton
                                        color="primary"
                                        disabled={formik.isSubmitting || !formik.isValid}
                                        type="submit"
                                        variant="contained"
                                    >
                                        <Translate keyId="COMMON.SAVE" />
                                    </DSButton>
                                </StyledButtonGrid>
                            </DSGrid>
                        </Form>
                    </>
                )}
            </Formik>
        </>
    );
}
LocationDetails.displayName = 'LocationDetails';

export const LocationDetailsWithAngular = withAngularIntegration(LocationDetails, 'locationDetails', {
    location: OneWayBinding,
});
