/// <reference path="../../../typings/browser.d.ts" />
import { PlannerOverviewDto, Untyped } from '@deltasierra/shared';

export class MvConfirmBulkActionCtrl {
    public static SID = 'MvConfirmBulkActionCtrl';

    static readonly $inject: string[] = ['$modalInstance', 'planners', 'actionDescription'];

    constructor(
        private $modalInstance: Untyped,
        public planners: PlannerOverviewDto[],
        public actionDescription: string,
    ) {}

    ok() {
        this.$modalInstance.close(true);
    }

    cancel() {
        this.$modalInstance.dismiss();
    }
}

angular.module('app').controller(MvConfirmBulkActionCtrl.SID, MvConfirmBulkActionCtrl);
