import { EditableFieldsFilter } from '@deltasierra/shared';
import { ExpressionCallback } from '../../common/angularData';
import {
    AngularjsDropdownMultiselectEvents,
    AngularjsDropdownMultiselectExtraSettings,
    AngularjsDropdownMultiselectTranslationTexts,
} from '../../typings/angularjsDropdownMultiselect/angularjsDropdownMultiselect';
import { I18nService } from '../../i18n';
import { DataUtils } from '../../common/dataUtils';

interface EditableFieldOption {
    id : keyof EditableFieldsFilter;
    label : string;
}

const NUM_EDITABLE_FIELDS = 3;

export class EditableFieldsPickerController {
    static SID = 'EditableFieldsPickerController';

    readonly change! : ExpressionCallback<{ editableFields : EditableFieldsFilter }>;

    options: EditableFieldOption[] = [
        {
            id: 'text',
            label: this.i18nService.text.common.text(),
        },
        {
            id: 'image',
            label: this.i18nService.text.common.image(),
        },
        {
            id: 'video',
            label: this.i18nService.text.common.video(),
        },
    ];

    selectedOptions: EditableFieldOption[];

    readonly preselectedEditableFields? : EditableFieldsFilter;

    multiselectSettings : AngularjsDropdownMultiselectExtraSettings = {
        checkBoxes: true,
        showCheckAll: false,
        showUncheckAll: true,
        searchField: 'label' as keyof EditableFieldOption,
        keyboardControls: true,
        smartButtonMaxItems: NUM_EDITABLE_FIELDS,
    };

    multiselectEvents : AngularjsDropdownMultiselectEvents = {
        onSelectionChanged: () => this.onEditableFieldsChanged(),
    };

    multiselectTexts : AngularjsDropdownMultiselectTranslationTexts = {
        buttonDefaultText: this.i18nService.text.build.allEditableElements(),
        dynamicButtonTextSuffix: this.i18nService.text.build.editableElements(),
        uncheckAll: this.i18nService.text.common.all(),
    };

    static readonly $inject : string[] = [
        I18nService.SID,
        DataUtils.SID,
    ];

    constructor(
        protected readonly i18nService : I18nService,
        protected readonly dataUtils : DataUtils,
    ) {
        if (this.preselectedEditableFields) {
            this.selectedOptions = this.populateSelectedOptions(
                this.preselectedEditableFields.text,
                this.preselectedEditableFields.image,
                this.preselectedEditableFields.video,
            );
        } else {
            this.selectedOptions = [];
        }
    }

    populateSelectedOptions(text : boolean, image : boolean, video : boolean) : EditableFieldOption[] {
        const options : EditableFieldOption[] = [];
        if (text) {
            const option = this.dataUtils.findBy('id', this.options, 'text');
            if (option) {
                options.push(option);
            }
        }
        if (image) {
            const option = this.dataUtils.findBy('id', this.options, 'image');
            if (option) {
                options.push(option);
            }
        }
        if (video) {
            const option = this.dataUtils.findBy('id', this.options, 'video');
            if (option) {
                options.push(option);
            }
        }
        return options;
    }

    convertOptionsToEditableFieldsFilter(options : EditableFieldOption[]) : EditableFieldsFilter {
        const filter : EditableFieldsFilter = {
            text: false,
            image: false,
            video: false,
        };
        for (const option of options) {
            filter[option.id] = true;
        }
        return filter;
    }

    onEditableFieldsChanged() {
        this.change({ editableFields: this.convertOptionsToEditableFieldsFilter(this.selectedOptions) });
    }
}

angular.module('app').controller(EditableFieldsPickerController.SID, EditableFieldsPickerController);
