/// <reference path="../../_references.d.ts" />

import { OneWayBinding } from '../../common/angularData';
import { ReportPageController } from './controller';
export { AlertFitwareLimitationsWithAngular } from '../components';

export default angular.module('app').directive('dsDefaultReportsPage', [
    () => ({
        bindToController: true,
        controller: ReportPageController,
        controllerAs: 'ctrl',
        restrict: 'E',
        scope: {
            currentLocationId: OneWayBinding,
        },
        templateUrl: '/partials/reports/reportPage/template',
    }),
]);
