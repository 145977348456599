import { AssignedLocation, IBuilderEmailDocument, TextSubstitutionValues, AssetIdAndLayerId } from '@deltasierra/shared';


import {
    $scopeSID,
    $windowSID,
    actualComponent,
    ExpressionBinding,
    ExpressionCallback,
    OneWayBinding,
} from '../../../../common/angularData';
import { FieldValidationResultSeverity } from '../../../builderDocumentValidation';
import { EmailBuilder, EmailBuilderFactory, MailMergeFieldType } from '../../../email/emailBuilder';
import { FileCache } from '../../../../common/fileCache';
import { EmailPublishService } from '../../../email/publish/emailPublishService';
import { BuilderConstants, builderConstantsSID } from '../../../builderConstants';
import IScope = angular.IScope;
import IWindowService = angular.IWindowService;

export interface PartialAutomatedEmailPublishData {
    builderDocument: IBuilderEmailDocument;
    fileCache: FileCache;
    htmlDocument: HTMLDocument;
    textSubstitutionValues: TextSubstitutionValues;
    linkedAssetLibraryAssetIds: AssetIdAndLayerId[];
}

export class MemberEngagerAutomatedEmailContentEditorCtrl {
    public static readonly SID = 'MemberEngagerAutomatedEmailContentEditorCtrl';

    // Props
    public readonly location!: AssignedLocation;

    public readonly builderDocument!: IBuilderEmailDocument;

    public readonly canFindNewTemplate!: boolean;

    public readonly onFindNewTemplate!: ExpressionCallback<unknown>;

    public readonly onPublish!: ExpressionCallback<{
        publishData: PartialAutomatedEmailPublishData;
    }>;

    public readonly textSubstitutionValues!: TextSubstitutionValues;

    public fileCache!: FileCache;

    public emailBuilder!: EmailBuilder;

    public readonly plannerImages: undefined = undefined;

    public desktopViewport: boolean = this.shouldDesktopViewportBeUsed();

    public highlightingEnabled = true;

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public static readonly $inject: string[] = [
        $scopeSID,
        $windowSID,
        builderConstantsSID,
        EmailBuilder.SID,
        EmailPublishService.SID,
    ];

    public constructor(
        protected readonly $scope: IScope,
        protected readonly $window: IWindowService,
        protected readonly builderConstants: BuilderConstants,
        protected readonly emailBuilderFactory: EmailBuilderFactory,
        protected readonly emailPublishService: EmailPublishService,
    ) {}

   public $onInit(): void {
        this.fileCache = new FileCache();
        this.emailBuilder = /* New*/ this.emailBuilderFactory(this.fileCache);
        void this.emailBuilder.loadDocument(this.builderDocument);
        this.emailBuilder.textSubstitutionValues = this.textSubstitutionValues;
        this.emailBuilder.advancedMode = false;
        this.emailBuilder.mailMergeFieldType = MailMergeFieldType.Fitware;

        this.$scope.$on(this.builderConstants.EVENTS.UPDATE_DOCUMENT_FROM_EDITOR, () => {
            this.updateDocumentFromEditor();
        });
    }

    public isTemplateValid(): boolean {
        return (
            this.emailBuilder
                .validateDocument()
                .filter(validationResult => validationResult.severity === FieldValidationResultSeverity.Error)
                .length === 0
        );
    }

    public onClickFindNewTemplate(): void {
        this.onFindNewTemplate({});
    }

    public onClickPublish(): ng.IPromise<unknown> {
        return this.emailPublishService.getCompiledTemplateHtml(this).then(htmlDocument => {
            const publishData: PartialAutomatedEmailPublishData = {
                builderDocument: this.emailBuilder.document,
                fileCache: this.fileCache,
                htmlDocument,
                linkedAssetLibraryAssetIds: this.emailBuilder.linkedAssetLibraryAsset.map(value => ({
                    assetId: value.asset.id,
                    layerId: value.layerId,
                })),
                textSubstitutionValues: this.emailBuilder.textSubstitutionValues,
            };
            this.onPublish({
                publishData,
            });
        });
    }

    protected updateDocumentFromEditor(): void {
        this.emailBuilder.updateDocumentFromEditor();
    }

    protected shouldDesktopViewportBeUsed(): boolean {
        return this.$window.window.innerWidth >= 768;
    }
}

export const memberEngagerAutomatedEmailContentEditorSID = 'memberEngagerAutomatedEmailContentEditor';

export const memberEngagerAutomatedEmailContentEditorConfig = actualComponent(
    MemberEngagerAutomatedEmailContentEditorCtrl,
    '/partials/contentBuilder/memberEngager/automatedEmail/contentEditor/template',
    {
        builderDocument: OneWayBinding,
        canFindNewTemplate: OneWayBinding,
        location: OneWayBinding,
        onFindNewTemplate: ExpressionBinding,
        onPublish: ExpressionBinding,
        textSubstitutionValues: OneWayBinding,
    },
);

angular
    .module('app')
    .component(memberEngagerAutomatedEmailContentEditorSID, memberEngagerAutomatedEmailContentEditorConfig);
