/// <reference path="../../../typings/browser.d.ts" />
import moment from 'moment-timezone';
import { InAppNotification } from '@deltasierra/shared';
import { startsWith } from '@deltasierra/string-utilities';
import { AngularEventDefinition } from '../common/events';
import { InteractionUtils } from '../common/interactionUtils';
import { I18nService } from '../i18n/i18nService';
import { MvIdentity } from '../account/mvIdentity';
import { $rootScopeSID, $scopeSID, EVENT_DESTROY } from '../common/angularData';
import { InAppNotificationApiClient } from './inAppNotificationApiClient';
import IPromise = angular.IPromise;
import IRootScopeService = angular.IRootScopeService;
import IAngularEvent = angular.IAngularEvent;
import IScope = angular.IScope;

function assertNever(never: never): never {
    throw new Error(`Unexpected value: ${never}`);
}

export const inAppNotificationMarkedAsReadEvent = new AngularEventDefinition<InAppNotification>(
    'inAppNotificationMarkedAsRead',
    'An in-app notification has been marked as read; local copies of that notification need to be updated.',
);

export const inAppNotificationMarkedAllAsReadEvent = new AngularEventDefinition<Record<string, never>>(
    'inAppNotificationMarkedAllAsRead',
    'All in-app notifications have been marked as read; local copies of those notifications need to be updated.',
);

export abstract class BaseInAppNotificationsController {
    public static readonly $inject: string[] = [
        $rootScopeSID,
        $scopeSID,
        I18nService.SID,
        InteractionUtils.SID,
        InAppNotificationApiClient.SID,
        MvIdentity.SID,
    ];

    public notifications: InAppNotification[] = [];

    public unreadNotificationsCount = 0;

    public readonly submitMarkAsRead = this.interactionUtils.createFuture(
        'mark notification as read',
        (context: { notification: InAppNotification }) =>
            this.inAppNotificationApiClient
                .markAsRead(context.notification.id)
                .then(() => this.broadcastMarkedAsReadEvent(context.notification)),
    );

    public readonly submitMarkAllAsRead = this.interactionUtils.createFuture(
        'mark all notification as read',
        (context: Record<string, never>) =>
            this.inAppNotificationApiClient.markAllAsRead().then(() => this.broadcastMarkedAllAsReadEvent()),
    );

    public readonly submitMarkAsClicked = this.interactionUtils.createFuture(
        'mark notification as clicked',
        (context: { notification: InAppNotification }) =>
            this.inAppNotificationApiClient.markAsClicked(context.notification.id),
    );

    // eslint-disable-next-line @typescript-eslint/ban-types
    protected readonly eventDeregisters: Function[] = [];

    // eslint-disable-next-line max-params
    public constructor(
        protected readonly $rootScope: IRootScopeService,
        protected readonly $scope: IScope,
        protected readonly i18nService: I18nService,
        protected readonly interactionUtils: InteractionUtils,
        protected readonly inAppNotificationApiClient: InAppNotificationApiClient,
        protected readonly mvIdentity: MvIdentity,
    ) {
        $scope.$on(EVENT_DESTROY, () => this.deregisterEvents());
    }

    public translateMessage(notification: InAppNotification): string {
        return this.i18nService.translate(notification.messageKey, notification.messageOptions);
    }

    public translateSubject(notification: InAppNotification): string | null {
        return notification.subjectKey
            ? this.i18nService.translate(notification.subjectKey, notification.subjectOptions || undefined)
            : null;
    }

    public markAsRead(notification: InAppNotification): IPromise<any> | void {
        if (!notification.readAt) {
            return this.submitMarkAsRead.run({ notification });
        }
        return Promise.resolve();
    }

    public markAllAsRead(): IPromise<any> {
        return this.submitMarkAllAsRead.run({});
    }

    public markAsClicked(notification: InAppNotification): IPromise<any> | void {
        if (!notification.clickedAt) {
            return this.submitMarkAsClicked.run({ notification });
        }
        return Promise.resolve();
    }

    public clickMarkAsRead(event: IAngularEvent, notification: InAppNotification): IPromise<any> | void {
        event.preventDefault();
        if (event.stopPropagation) {
            event.stopPropagation();
        }
        return this.markAsRead(notification);
    }

    public clickMarkAllAsRead(): IPromise<any> {
        this.unreadNotificationsCount = 0;
        return this.markAllAsRead();
    }

    public getTextClass(notification: InAppNotification): string {
        switch (notification.severity) {
            case 'info':
            case 'success':
            case 'warning':
            case 'danger':
                return `text-${notification.severity}`;
            default:
                throw assertNever(notification.severity);
        }
    }

    public getIconClass(notification: InAppNotification): string {
        switch (notification.type) {
            case 'VideoPublishedLocally':
            case 'VideoPublishedExternally':
            case 'VideoPublishFailed':
                return 'fa-film';
            case 'ImagePublishFailed':
                return 'fa-photo';
            case 'MapGenerated':
                return 'fa-map-o';
            case 'ScheduledPost.NoNotifiableDevices':
                return 'fa-bell';
            case 'Agency.CustomMessage':
                return 'fa-paper-plane';
            case 'TestInAppNotifications':
                return 'fa-envelope';
            case 'EmailPublishSuccess':
                return 'fa-envelope';
            case 'EmailPublishFailed':
                return 'fa-envelope';
            default:
                throw assertNever(notification.type);
        }
    }

    public displayDate(notification: InAppNotification): string {
        return moment(notification.createdAt).tz(this.mvIdentity.currentUser!.timezone).format('D MMM YYYY @ h:mma');
    }

    public getNotificationLink(notification: InAppNotification): string | null {
        if (notification.type === 'Agency.CustomMessage') {
            return `/notifications/${notification.id}`;
        } else {
            return notification.link;
        }
    }

    public getLinkTargetWindow(notification: InAppNotification): '_blank' | undefined {
        if (notification.type === 'Agency.CustomMessage') {
            return undefined;
        } else {
            const link = this.getNotificationLink(notification);
            if (link && startsWith(link, 'http')) {
                return '_blank';
            }
            return undefined;
        }
    }

    public shouldShowSubject(notification: InAppNotification): boolean {
        if (notification.type === 'Agency.CustomMessage') {
            return !!this.translateSubject(notification);
        } else {
            return false;
        }
    }

    public shouldShowMessage(notification: InAppNotification): boolean {
        if (notification.type === 'Agency.CustomMessage') {
            return !this.translateSubject(notification);
        } else {
            return true;
        }
    }

    public shouldShowCallToActionButton(notification: InAppNotification): boolean {
        return false;
    }

    protected listenToMarkedAsReadEvent(listener: (event: IAngularEvent, data: InAppNotification) => any): void {
        const deregister = inAppNotificationMarkedAsReadEvent.on(this.$scope, listener);
        this.eventDeregisters.push(deregister);
    }

    protected listenToMarkedAllAsReadEvent(listener: (event: IAngularEvent) => any): void {
        const deregister = inAppNotificationMarkedAllAsReadEvent.on(this.$scope, listener);
        this.eventDeregisters.push(deregister);
    }

    protected deregisterEvents(): void {
        this.eventDeregisters.forEach(deregister => deregister());
        this.eventDeregisters.length = 0;
    }

    protected broadcastMarkedAsReadEvent(notification: InAppNotification): void {
        inAppNotificationMarkedAsReadEvent.broadcast(this.$rootScope, notification);
    }

    protected broadcastMarkedAllAsReadEvent(): void {
        inAppNotificationMarkedAllAsReadEvent.broadcast(this.$rootScope, {});
    }

    protected getUnreadNotificationsLimit(): number {
        return Number.POSITIVE_INFINITY;
    }

    protected readonly socketHandler = (notification: InAppNotification): void => {
        this.$scope.$apply(() => {
            this.notifications.unshift(notification);
            if (this.notifications.length > this.getUnreadNotificationsLimit()) {
                this.notifications.pop();
            }
            this.unreadNotificationsCount++;
        });
    };
}
