import * as React from 'react';
import { SvgLoader } from '@deltasierra/components';
import styled from 'styled-components';
import { gql } from '@apollo/client';
import { PlatformLogo } from '../PlatformLogo';
import { PlatformTitleFragment } from './__graphqlTypes/PlatformTitleFragment';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    img,
    span {
        margin-right: 12px;
    }
    h3 {
        margin: 0px;
    }
`;
const fragments = {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    title: gql`
        fragment PlatformTitleFragment on Platform {
            name
            displayName
        }
    `,
};

export type PlatformTitleProps = Pick<React.HTMLAttributes<HTMLDivElement>, 'className' | 'style'> & {
    loading?: boolean;
    platform?: PlatformTitleFragment;
};

export const PlatformTitle: React.FC<PlatformTitleProps> & { fragments: typeof fragments } = ({
    className,
    loading = false,
    platform = null,
}) => (
    <SvgLoader loading={loading} size="small">
        <Wrapper className={className}>
            <PlatformLogo name={platform?.name ?? ''} />
            <h3>{platform?.displayName ?? ''}</h3>
        </Wrapper>
    </SvgLoader>
);
PlatformTitle.fragments = fragments;
