import * as React from 'react';
import moment from 'moment-timezone';
import { DSTypography } from '@deltasierra/components';
import { gql } from '@apollo/client';
import { ScheduledPostDateDisplayFragment } from './__graphqlTypes/ScheduledPostDateDisplayFragment';

const now = moment.tz(new Date().toUTCString(), moment.tz.guess());

const fragments = {
    ScheduledPostDateDisplayFragment: gql`
        fragment ScheduledPostDateDisplayFragment on PostArtifact {
            id
            timestamps {
                scheduled
            }
        }
    `,
};

export type ScheduledPostDateDisplayProps = {
    post: ScheduledPostDateDisplayFragment;
};

export function ScheduledPostDateDisplay({ post }: ScheduledPostDateDisplayProps): JSX.Element {
    const date = React.useMemo(() => new Date(post.timestamps.scheduled!), [post.timestamps.scheduled]);
    const [lastValidDate, setLastValidDate] = React.useState(now);

    React.useEffect(() => {
        if (moment(date).isValid()) {
            setLastValidDate(moment.tz(date.toUTCString(), moment.tz.guess()));
        }
    }, [date]);

    return (
        <div>
            <DSTypography align="center" color="textSecondary" component="p" variant="h4">
                {lastValidDate.format('hh:mma').slice(0, -1)}
            </DSTypography>
            <DSTypography align="center" color="textPrimary" component="p" style={{ fontWeight: 700 }} variant="h4">
                {lastValidDate.format('ddd')}
            </DSTypography>
            <DSTypography
                align="center"
                color="textPrimary"
                component="p"
                noWrap
                style={{ fontWeight: 700 }}
                variant="h4"
            >
                {lastValidDate.format('MMM D')}
            </DSTypography>
            <DSTypography align="center" color="textSecondary" component="p" variant="body1">
                {lastValidDate.format('z')}
            </DSTypography>
        </div>
    );
}

ScheduledPostDateDisplay.displayName = 'ScheduledPostDateDisplay';
ScheduledPostDateDisplay.fragments = fragments;
