import * as React from 'react';
import { AssetThumbnailContent } from '@deltasierra/components';
import { gql } from '@apollo/client';
import { FolderTypeIcon } from '../../../../common/FileTypeIcon/FolderTypeIcon';
import { AssetFolderThumbnailContentFragment } from './__graphqlTypes/AssetFolderThumbnailContentFragment';

const fragments = {
    AssetFolderThumbnailContentFragment: gql`
        fragment AssetFolderThumbnailContentFragment on AssetFolder {
            id
            title
        }
    `,
};

export type AssetFolderThumbnailContentProps = {
    asset: AssetFolderThumbnailContentFragment;
    height: number;
};

export const AssetFolderThumbnailContent: React.FC<AssetFolderThumbnailContentProps> & { fragments: typeof fragments } = ({
    asset,
    height,
}) => (
    <AssetThumbnailContent>
        <div style={{ padding: '5px' }}>{asset.title}</div>
        <FolderTypeIcon alt={asset.title} height={height} style={{ padding: '5px' }} />
    </AssetThumbnailContent>
);

AssetFolderThumbnailContent.displayName = 'AssetFolderThumbnailContent';
AssetFolderThumbnailContent.fragments = fragments;
