/// <reference path="../../../typings/browser.d.ts" />
import { $locationSID, $scopeSID } from '../common/angularData';
import ILocationService = angular.ILocationService;
import IScope = angular.IScope;

interface MvUnsupportedBrowserCtrl extends IScope {
    redirectUrl: string;
}

angular.module('app').controller('mvUnsupportedBrowserCtrl', [
    $scopeSID,
    $locationSID,
    function ($scope: MvUnsupportedBrowserCtrl, $location: ILocationService) {
        $scope.redirectUrl = '/';
        const params = $location.search();
        const redirect = params.r;
        if (redirect) {
            $scope.redirectUrl = redirect;
        }
    },
]);
