/// <reference path="../../../typings/browser.d.ts" />
import ITemplateCacheService = angular.ITemplateCacheService;
import IPromise = angular.IPromise;
import IHttpService = angular.IHttpService;
import IQService = angular.IQService;

export class TemplateLoaderService {
    static SID = 'TemplateLoaderService';

    static readonly $inject : string[] = [
        '$q',
        '$http',
        '$templateCache',
    ];

    constructor(
        protected $q : IQService,
        protected $http : IHttpService,
        protected $templateCache : ITemplateCacheService,
    ) {

    }

    preloadPartial(url : string) : IPromise<string> {
        if (url.lastIndexOf('/partials/') != 0) {
            return this.$q.reject(new Error("When preloading a partial, the URL must start with '/partials/'."));
        }
        return this.$http.get<string>(url)
            .then(res => this.$templateCache.put<string>(url, res.data));
    }
}

angular.module('app').service(TemplateLoaderService.SID, TemplateLoaderService);
