import { AssignedLocation, FEATURED_TEMPLATE_TAG_TITLE } from '@deltasierra/shared';

import * as Sentry from '@sentry/browser';
import { MvIdentity } from '../../../account/mvIdentity';
import { $qSID, $rootScopeSID } from '../../../common/angularData';
import { InteractionUtils } from '../../../common/interactionUtils';
import { SentryService } from '../../../common/sentryService';
import { BuilderTemplateApiClient } from '../../../contentBuilder/builderTemplateApiClient';
import { mvBuilderTemplateFormatResourceSID, MvBuilderTemplateFormatResource } from '../../../contentBuilder/mvBuilderTemplateFormatResource';
import { GraphqlService } from '../../../graphql/GraphqlService';
import { I18nService } from '../../../i18n';
import { IntroDataService } from '../../../intro/introDataService';
import { IntroService } from '../../../intro/introService';
import { TemplateGroup, TemplateGroupTypeEnum } from '../../../templateGallery/templateGroup';

export class SuggestedTemplatesController {
    public static readonly SID = 'SuggestedTemplatesController';

    public location!: AssignedLocation | null;

    public featuredTemplateGroup = new TemplateGroup(
        this.$q,
        this.interactionUtils,
        this.i18n,
        this.builderTemplateApiClient,
        this.introService,
        this.introDataService,
        this.graphqlService,
        this.sentryService,
        this.mvBuilderTemplateFormatResource,
        this.identity,
        this.i18n.text.dashboard.suggestedTemplates.suggestedTemplates(),
        {
            type: TemplateGroupTypeEnum.Uncategorised,
        },
        this.location,
    );

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public static readonly $inject: string[] = [
        $rootScopeSID,
        $qSID,
        InteractionUtils.SID,
        I18nService.SID,
        MvIdentity.SID,
        BuilderTemplateApiClient.SID,
        IntroService.SID,
        IntroDataService.SID,
        GraphqlService.SID,
        SentryService.SID,
        mvBuilderTemplateFormatResourceSID,
    ];

    // eslint-disable-next-line max-params
    public constructor(
        private readonly $rootScope: ng.IRootScopeService,
        private readonly $q: ng.IQService,
        private readonly interactionUtils: InteractionUtils,
        protected readonly i18n: I18nService,
        public readonly identity: MvIdentity,
        private readonly builderTemplateApiClient: BuilderTemplateApiClient,
        protected readonly introService: IntroService,
        protected readonly introDataService: IntroDataService,
        private readonly graphqlService: GraphqlService,
        private readonly sentryService: SentryService,
        private readonly mvBuilderTemplateFormatResource: MvBuilderTemplateFormatResource,
    ) {}

    public $onInit(): void {
        this.$rootScope.$watchGroup([() => this.location, () => this.identity.currentUser], async () =>
            this.loadMore(this.featuredTemplateGroup, true),
        );
    }

    public isUserPermittedToSeeSection(): boolean {
        return this.identity.canAccessBuilder();
    }

    public async onTemplateGroupInView(group: TemplateGroup): Promise<void> {
        if (group.templates.length === 0 && group.fetchTemplates.isPending()) {
            return this.loadMore(group);
        }
        return Promise.resolve();
    }

    public async lastChildInViewCallback(group: TemplateGroup): Promise<void> {
        return this.loadMore(group);
    }

    private async loadMore(group: TemplateGroup, forceReload = false): Promise<void> {
        if (!this.isUserPermittedToSeeSection() || !this.location || this.location.id === undefined) {
            return;
        }
        if (!forceReload && group.allLoaded) {
            Sentry.withScope(scope => {
                scope.addBreadcrumb({ data: { location: this.location, user: this.identity.currentUser } });
                Sentry.captureMessage('Unable to load suggested builder templates');
            });
            return;
        }
        if (forceReload) {
            group.reset();
        }
        await group.loadMore({
            client: this.location.clientId,
            exactTag: FEATURED_TEMPLATE_TAG_TITLE,
            hideDraft: true,
            limit: 10,
            location: this.location.id,
        });
    }
}
