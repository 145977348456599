import { gql } from '@apollo/client';

export const CREATE_EMAIL_TEMPLATE = gql`
    mutation CreateEmailTemplate($input: CreateEmailTemplateInput!) {
        createEmailTemplate(input: $input) {
            ... on CreateEmailTemplateSuccess {
                emailTemplate {
                    id
                }
            }
            ... on CreateEmailTemplateError {
                code
                message
            }
        }
    }
`;
