import { gql } from '@apollo/client';
import {
    DSDivider,
    DSList,
    DSListItem,
    DSListItemText,
    DSTypography,
    DSWell,
    DSWellHeader,
    DSWellHeaderTitle,
} from '@deltasierra/components';
import * as React from 'react';
import { t } from '@deltasierra/shared';
import { useAssetLibrarySettings } from '../../contexts';
import { relayConnectionToArray } from '../../../graphql/utils';
import { CollectionListItem } from './CollectionListItem';
import { GenericListItem } from './GenericListItem';
import { CollectionConnectionForCollectionList } from './__graphqlTypes/CollectionConnectionForCollectionList';

const fragments = {
    COLLECTION_CONNECTION_FOR_COLLECTION_LIST: gql`
        fragment CollectionConnectionForCollectionList on CollectionConnection {
            edges {
                node {
                    id
                    ...CollectionFragmentForCollectionList
                }
            }
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }
        }
        ${CollectionListItem.fragments.COLLECTION_FRAGMENT_FOR_COLLECTION_LIST}
    `,
};

export interface BinData {
    count: number;
    id: string;
    title: string;
}

export interface CollectionListProps {
    binData?: BinData;
    expiredData?: BinData;
    binSectionLoading?: boolean;
    dataCy?: string;
    collections: CollectionConnectionForCollectionList;
    currentId?: string;
    isBinSectionOpen?: boolean;
    headerText: string;
    searchTerm?: string;
    onClick?: (id: string) => void;
    onRequestBinSection?: () => void;
}

/**
 * CollectionList
 * Displays a bunch of collections from a CollectionConnection
 *
 * @param props - CollectionListProps
 * @param props.collections - Fragment of a CollectionConnection, use provided fragment
 * @param props.currentId - The id of the current selected collection in case one wants to display it in the list
 * @param props.headerText - Text to show at the top of the Well
 * @param props.onClick - Callback that provides the id of a clicked collection as the param
 * @returns CollectionList
 */
export function CollectionList(props: CollectionListProps): JSX.Element {
    const { hideBin } = useAssetLibrarySettings();
    const collectionArray = relayConnectionToArray(props.collections).filter(({ title }) =>
        filterFunction(props.searchTerm ?? '', title),
    );

    return (
        <DSWell>
            <DSWellHeader>
                <DSWellHeaderTitle>{props.headerText}</DSWellHeaderTitle>
            </DSWellHeader>
            <DSList data-cy={props.dataCy}>
                {collectionArray.map(collection => (
                    <CollectionListItem
                        collection={collection}
                        isSelected={collection.id === props.currentId}
                        key={collection.id}
                        onClick={props.onClick}
                    />
                ))}
                {collectionArray.length === 0 && (
                    <DSTypography align="center" color="textSecondary" gutterBottom variant="body1">
                        {t('ASSET_LIBRARY.NO_COLLECTIONS_FOUND')}
                    </DSTypography>
                )}
                {!hideBin && (
                    <>
                        <DSDivider light />
                        {props.isBinSectionOpen && (
                            <>
                                <GenericListItem
                                    count={props.binData?.count}
                                    dataCy="collection-list-bin"
                                    icon="trash"
                                    isSelected={props.currentId === props.binData?.id}
                                    text={props.binData?.title ?? t('ASSET_LIBRARY.BIN')}
                                    onClick={() => props.onClick?.(props.binData?.id ?? 'bin')}
                                />
                                <GenericListItem
                                    count={props.expiredData?.count}
                                    icon="clock"
                                    isSelected={props.currentId === props.expiredData?.id}
                                    text={props.expiredData?.title ?? t('ASSET_LIBRARY.BIN')}
                                    onClick={() => props.onClick?.(props.expiredData?.id ?? 'bin')}
                                />
                            </>
                        )}
                        <DSListItem dense onClick={() => props.onRequestBinSection?.()}>
                            <DSListItemText>
                                <DSTypography align="center" color="primary">
                                    {props.isBinSectionOpen ? t('ASSET_LIBRARY.HIDE_BIN') : t('ASSET_LIBRARY.SHOW_BIN')}
                                </DSTypography>
                            </DSListItemText>
                        </DSListItem>
                    </>
                )}
            </DSList>
        </DSWell>
    );
}
CollectionList.displayName = 'CollectionList';
CollectionList.fragments = fragments;

function filterFunction(searchTerm: string, match: string): boolean {
    return match.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase());
}
