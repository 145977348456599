import { LocationId, TwitterPublishApi, WorkflowStartResult, justParams, PublishMultiImageRequest, TwitterChannelData } from '@deltasierra/shared';
import { IHttpService, IPromise } from 'angular';


import { invokeApiRoute } from '../../common/httpUtils';
import { GraphqlService } from '../../graphql/GraphqlService';
import { SentryService } from '../../common/sentryService';
import { PublishTwitterImage, PublishTwitterImageVariables } from './__graphqlTypes/PublishTwitterImage';
import { PUBLISH_TWITTER_IMAGE } from './publish-twitter-image.mutation';

export class TwitterPublishService {
    public static SID = 'twitterPublishService';

    public static readonly $inject: string[] = ['$http', GraphqlService.SID, SentryService.SID];

    public constructor(
        private $http: IHttpService,
        private readonly graphqlService: GraphqlService,
        private readonly sentryService: SentryService,
    ) {}

    public async publishPhoto(
        input: PublishMultiImageRequest<TwitterChannelData & { scheduledTime: Date | undefined }>,
    ): Promise<WorkflowStartResult> {
        const gqlClient = this.graphqlService.getClient();
        const result = await gqlClient.mutate<PublishTwitterImage, PublishTwitterImageVariables>({
            mutation: PUBLISH_TWITTER_IMAGE,
            variables: {
                input: {
                    ...input,
                    channelData: { description: input.channelData.status },
                    scheduledTime: input.scheduledTime?.toISOString(),
                },
            },
        });
        if (result.data?.publishTwitterImage.__typename === 'PublishError' || !result.data) {
            this.sentryService.captureException(
                'Failed to publish image to Twitter via "PUBLISH_TWITTER_IMAGE" GraphQL query.',
                {
                    errors:
                        result.data?.publishTwitterImage.__typename === 'PublishError'
                            ? result.data.publishTwitterImage.message
                            : undefined,
                },
            );
            throw new Error('Failed to publish image to Twitter');
        } else {
            return {
                executionArn: result.data.publishTwitterImage.workflow.id,
                executionId: result.data.publishTwitterImage.workflow.executionId,
                startDate: new Date(result.data.publishTwitterImage.workflow.startDate),
            };
        }
    }

    public canPostToTwitterPageForLocation(locationId: LocationId): IPromise<boolean> {
        return invokeApiRoute(
            this.$http,
            TwitterPublishApi.canPostToTwitterForLocation,
            justParams({
                locationId,
            }),
        ).then(result => result.result);
    }
}

angular.module('app').service('twitterPublishService', TwitterPublishService);
