import { noop } from '@deltasierra/object-utilities';
import 'react';
import { useAngularComponentRenderer, useAngularScope } from '../../componentUtils/angularRendering';

type ColorPickerScope = {
    color: string;
    keepOpen?: boolean;
};

export type ColorPickerProps = {
    color: string;
    keepOpen?: boolean;
    onChange?: (color: string | undefined) => void;
};

export function ColorPicker({ color, keepOpen = false, onChange = noop }: ColorPickerProps): JSX.Element {
    const scope = useAngularScope<ColorPickerScope>(
        {
            color,
            keepOpen,
        },
        {
            color: newValue => onChange(newValue),
        },
    );
    const html = `
        <ds-color-picker color="color" keep-open="keepOpen"/>
    `;
    return useAngularComponentRenderer(html, scope);
}

ColorPicker.displayName = 'ColorPicker';
