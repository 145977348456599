import { oneLine } from 'common-tags';
import * as React from 'react';
import { useAngularComponentRenderer, useAngularScope } from '../componentUtils/angularRendering';

type TextareaScope = {
    dataCy?: string;
    enableEmojis?: boolean;
    helpText?: string;
    invalidHelpText?: string;
    isValid?: boolean;
    label: string;
    required?: boolean;
    rows?: number;
    value: string;
};

export type TextareaProps = {
    dataCy?: string;
    enableEmojis?: boolean;
    helpText?: string;
    invalidHelpText?: string;
    isValid?: boolean;
    label?: string;
    onChange: (newValue: string) => void;
    required?: boolean;
    rows?: number;
    initialValue?: string;
};

export const Textarea: React.FunctionComponent<TextareaProps> = ({
    dataCy,
    enableEmojis = false,
    helpText,
    initialValue = '',
    invalidHelpText,
    isValid = true,
    label,
    onChange,
    required = false,
    rows = 3,
}) => {
    const [value, setValue] = React.useState(initialValue);

    const handleValueChange = (newValue: string) => {
        setValue(newValue);
        onChange(newValue);
    }

    const scope = useAngularScope<TextareaScope>(
        {
            dataCy,
            enableEmojis,
            helpText,
            invalidHelpText,
            isValid,
            label,
            required,
            rows,
            value,
        },
        {
            value: handleValueChange,
        },
    );

    return useAngularComponentRenderer(
        oneLine`
            <ds-textarea
                ng-model="value"
                label="label"
                help-text="helpText"
                rows="rows"
                enable-emojis="enableEmojis"
                required="required"
                is-valid="isValid"
                invalid-help-text="invalidHelpText"
                datacy="dataCy"
            ></ds-textarea>
        `,
        scope,
    );
};

Textarea.displayName = 'Textarea';
