import { AssignedLocation, ExportPlatforms, FacebookPublishData, LocationIdHierarchy } from '@deltasierra/shared';
import * as React from 'react';
import { MultipleLocationMultiImagePublish } from '../multipleLocationPublish/MultipleLocationMultiImagePublish';
import { MvContentBuilderPublishCtrl } from '../mvContentBuilderPublishCtrl';
import { PublishResult } from '../publishResult';
import { PublishFinishCallback } from '../types';
import { FacebookPublishForm } from './FacebookPublishForm';
import { useFacebookImagePublishCallback } from './useFacebookImagePublishCallback';

export type PublishImageToFacebookStepProps = {
    location: AssignedLocation;
    locations: LocationIdHierarchy[];
    onCancel: () => void;
    onFinish: (data: PublishResult) => void;
    publishController: MvContentBuilderPublishCtrl;
};

export function PublishImageToFacebookStep({
    location,
    locations,
    onCancel,
    onFinish,
    publishController,
}: PublishImageToFacebookStepProps): JSX.Element {
    const [isPublishing, setIsPublishing] = React.useState(false);
    const [publishData, setPublishData] = React.useState<FacebookPublishData | null>(null);

    const imagePublishCallback = useFacebookImagePublishCallback(publishController);

    const onPublish = (data: FacebookPublishData) => {
        setIsPublishing(true);
        setPublishData(data);
    };

    const finishCallback: PublishFinishCallback = () => {
        const scheduledTime = (publishData && publishData.scheduledTime) || null;
        onFinish(new PublishResult(ExportPlatforms.InstagramDirect, scheduledTime));
    };

    return (
        <>
            {!isPublishing && (
                <FacebookPublishForm
                    locationId={location.graphqlId}
                    locationIds={locations.map(loc => loc.locationGraphqlId)}
                    publishController={publishController}
                    publishType="image"
                    onCancel={onCancel}
                    onPublish={onPublish}
                />
            )}
            {isPublishing && publishData && (
                <MultipleLocationMultiImagePublish
                    commonData={publishData}
                    contentBuilder={publishController.contentBuilder}
                    locations={locations}
                    originalLocation={location}
                    publishCtrl={publishController}
                    publishMultiImageCallback={imagePublishCallback}
                    onCancel={onCancel}
                    onFinish={finishCallback}
                />
            )}
        </>
    );
}

PublishImageToFacebookStep.displayName = 'PublishImageToFacebookStep';
