import { AssignedLocation } from '@deltasierra/shared';
import React from 'react';
import { LocationGroupsDashboard } from '..';
import { OptionalOneWayBinding } from '../../../../common/angularData';
import { withAngularIntegration } from '../../../../common/componentUtils/reactComponentRegistration';
import { useRedirectToNewDashboardCheck } from './useRedirectToNewDashboardCheck';

export type LocationGroupsDashboardAngularWrapperProps = {
    location?: AssignedLocation | null;
};

export function LocationGroupsDashboardAngularWrapper({
    location,
}: LocationGroupsDashboardAngularWrapperProps): JSX.Element {
    const { loading } = useRedirectToNewDashboardCheck(location)
    return location && !loading ? <LocationGroupsDashboard location={location} /> : <div />;
}
LocationGroupsDashboardAngularWrapper.displayName = 'LocationGroupsDashboardAngularWrapper';

export const LocationGroupsDashboardAngularWrapperWithAngular = withAngularIntegration(
    LocationGroupsDashboardAngularWrapper,
    'locationGroupsDashboard',
    {
        location: OptionalOneWayBinding,
    },
);
