/// <reference path="../../../typings/browser.d.ts" />
import { t, AssignedLocation } from '@deltasierra/shared';
import { $timeoutSID } from '../common/angularData';
import { InteractionUtils } from '../common/interactionUtils';
import { MvNotifier } from '../common/mvNotifier';
import { AdminService } from './adminService';
import ITimeoutService = angular.ITimeoutService;

const LOGOUT_DELAY_MS = 3000;

export class TasksCtrl {
    static SID = 'TasksCtrl';

    submitGenerateThumbnails = this.interactionUtils.createHttpFuture('generate thumbnails', () =>
        this.adminService.generateThumbnails(),
    );

    submitPurgeSessions = this.interactionUtils.createHttpFuture('purge sessions', () =>
        this.adminService.purgeSessions(),
    );

    submitTestInAppNotifications = this.interactionUtils.createHttpFuture('test in-app notifications', () =>
        this.adminService.testInAppNotifications(),
    );

    submitGenerateLocationMap = this.interactionUtils.createHttpFuture('generate locations map', () =>
        this.adminService.generateLocationMap(this.location!.id),
    );

    location: AssignedLocation | null = null;

    static readonly $inject: string[] = [$timeoutSID, MvNotifier.SID, InteractionUtils.SID, AdminService.SID];

    constructor(
        private $timeout: ITimeoutService,
        private notifier: MvNotifier,
        private interactionUtils: InteractionUtils,
        private adminService: AdminService,
    ) {}

    clickGenerateThumbnails() {
        return this.submitGenerateThumbnails
            .run({})
            .then(() => this.notifier.info(t('ADMIN.NOTIFY_THUMBNAIL_GENERATION_STARTED')));
    }

    clickPurgeSessions() {
        return this.submitPurgeSessions.run({}).then(() => {
            this.notifier.info(t('ADMIN.NOTIFY_PURGE_SESSIONS_STARTED'));
            return this.$timeout(() => {
                window.location.assign('/login');
            }, LOGOUT_DELAY_MS);
        });
    }

    clickTestInAppNotifications() {
        return this.submitTestInAppNotifications.run({}).then(() => {
            this.notifier.info(t('ADMIN.NOTIFY_TEST_IN_APP_NOTIFICATIONS_STARTED'));
        });
    }

    clickGenerateLocationMap() {
        return this.submitGenerateLocationMap.run({}).then(() => {
            this.notifier.info(t('ADMIN.NOTIFY_GENERATE_LOCATION_MAP_STARTED'));
        });
    }

    changeLocation(location: AssignedLocation) {
        this.location = location;
    }
}

angular.module('app').controller(TasksCtrl.SID, TasksCtrl);
