import * as React from 'react';
import { t } from '@deltasierra/shared';
import { StyledList, StyledListItem } from '../../../../common';
import { ClubReadyListTotals } from '../hooks/utils';
import { ClubReadySuppressedCount } from './ClubReadySuppressedCount';

export type ClubReadySuppressedCountListProps = {
    totals: ClubReadyListTotals;
};

export function ClubReadySuppressedCountList(
    { totals }: ClubReadySuppressedCountListProps,
): JSX.Element {
    const optOutCount = React.useMemo(
        () =>
            totals.active.suppressedOptedOutUserCount +
            totals.cooledOffProspects.suppressedOptedOutUserCount +
            totals.custom.suppressedOptedOutUserCount +
            totals.inactive.suppressedOptedOutUserCount +
            totals.prospects.suppressedOptedOutUserCount,
        [totals],
    );
    const filteredCount = React.useMemo(
        () =>
            totals.active.suppressedFilteredUserCount +
            totals.inactive.suppressedFilteredUserCount +
            totals.prospects.suppressedFilteredUserCount,
        [totals],
    );
    const invalidCount = React.useMemo(
        () =>
            totals.active.suppressedInvalidEmailUserCount +
            totals.cooledOffProspects.suppressedInvalidEmailUserCount +
            totals.custom.suppressedInvalidEmailUserCount +
            totals.inactive.suppressedInvalidEmailUserCount +
            totals.prospects.suppressedInvalidEmailUserCount,
        [totals],
    );
    const duplicateCount = React.useMemo(
        () =>
            totals.active.suppressedDuplicateUserCount +
            totals.cooledOffProspects.suppressedDuplicateUserCount +
            totals.custom.suppressedDuplicateUserCount +
            totals.inactive.suppressedDuplicateUserCount +
            totals.prospects.suppressedDuplicateUserCount,
        [totals],
    );
    const notInterestedCount = React.useMemo(
        () => totals.prospects.suppressedNotInterestedUserCount + totals.custom.suppressedNotInterestedUserCount,
        [totals],
    );

    return (
        <StyledList>
            <StyledListItem>
                <ClubReadySuppressedCount
                    count={optOutCount}
                    suppressionName={t('BUILD.PUBLISH.CLUB_READY.OPTOUT')}
                />
            </StyledListItem>
            <StyledListItem>
                <ClubReadySuppressedCount
                    count={filteredCount}
                    suppressionName={t('BUILD.PUBLISH.CLUB_READY.FILTERED')}
                />
            </StyledListItem>
            <StyledListItem>
                <ClubReadySuppressedCount
                    count={invalidCount}
                    suppressionName={t('BUILD.PUBLISH.CLUB_READY.INVALID')}
                />
            </StyledListItem>
            <StyledListItem>
                <ClubReadySuppressedCount
                    count={duplicateCount}
                    suppressionName={t('BUILD.PUBLISH.CLUB_READY.DUPLICATE_EMAILS')}
                />
            </StyledListItem>
            <StyledListItem>
                <ClubReadySuppressedCount
                    count={notInterestedCount}
                    suppressionName={t('BUILD.PUBLISH.CLUB_READY.NOT_INTERESTED')}
                />
            </StyledListItem>
        </StyledList>
    );
}
ClubReadySuppressedCountList.displayName = 'ClubReadySuppressedCountList';
