import { gql } from '@apollo/client';

export const USE_SCHEDULED_PUBLISH_POLLER_QUERY = gql`
    query UseScheduledPublishPollerQuery($ids: [ID!]!) {
        scheduledPublishes(ids: $ids) {
            __typename
            id
            schedulingStatus
        }
    }
`;
