import {
    AssignedLocation,
    assignedLocationToLocationIdHierarchy,
    LocationId,
    LocationIdHierarchy,
    ExternalService,
    IAccessCheckMultiLocationPlatformName,
} from '@deltasierra/shared';

import {
    $rootScopeSID,
    $scopeSID,
    $windowSID,
    ExpressionBinding,
    ExpressionCallback,
    OneWayBinding,
    OptionalOneWayBinding,
    simpleComponent,
} from '../../../common/angularData';
import { I18nService } from '../../../i18n/index';
import { REFRESH_LOCATIONS_EVENT_NAME } from '../../../directives/locationPicker/multipleLocationPicker';
import { InteractionUtils } from '../../../common/interactionUtils';
import { MvAuth } from '../../../account/mvAuth';

export class MultipleLocationPublishingPickerCtrl {
    public static readonly SID = 'MultipleLocationPublishingPickerCtrl';

    public static readonly $inject: string[] = [
        $windowSID,
        $scopeSID,
        $rootScopeSID,
        I18nService.SID,
        InteractionUtils.SID,
        MvAuth.SID,
    ];

    // Inputs
    public location!: AssignedLocation;

    public platforms!: ExternalService | ExternalService[] | IAccessCheckMultiLocationPlatformName | null;

    public onCancel!: ExpressionCallback<unknown>;

    public onContinue!: ExpressionCallback<{ locations: LocationIdHierarchy[] }>;

    public preselectedLocations!: LocationIdHierarchy[];

    public enabledLocationIds: LocationId[] = [];

    public disabledLocations: LocationIdHierarchy[] = [];

    public locations!: LocationIdHierarchy[];

    public hasFetchedEnabledLocationIds = false;

    public fetchAuthorizedAndConfiguredLocationIds = this.interactionUtils.createFuture(
        'fetch authorized and configured locations',
        async (context: { platform: IAccessCheckMultiLocationPlatformName }) =>
            this.mvAuth.getAuthorizedAndConfiguredLocations(context.platform).then(result => {
                this.hasFetchedEnabledLocationIds = true;
                this.enabledLocationIds = result;
                this.$rootScope.$broadcast(REFRESH_LOCATIONS_EVENT_NAME);
            }),
    );

    public fetchAuthorizedAndConfiguredLocationIdsForMultiplePlatforms = this.interactionUtils.createFuture(
        'fetch authorized and configured locations for multiple platforms',
        async (context: { platforms: IAccessCheckMultiLocationPlatformName[] }) => {
            // TODO: Move into arrayUtils?
            const getIntersection = <T>(arrayOne: T[], arrayTwo: T[]): T[] => {
                const setA = new Set(arrayOne);
                const setB = new Set(arrayTwo);
                const intersection = new Set(Array.from(setA.values()).filter(value => setB.has(value)));
                return Array.from(intersection);
            };

            const results = await Promise.all(
                context.platforms.map(async platform => this.mvAuth.getAuthorizedAndConfiguredLocations(platform)),
            );
            const commonLocationIds = results.reduce((acc, current) => getIntersection(acc, current), results[0]);
            this.enabledLocationIds = commonLocationIds;
            this.hasFetchedEnabledLocationIds = true;
            this.$rootScope.$broadcast(REFRESH_LOCATIONS_EVENT_NAME);
        },
    );

    // eslint-disable-next-line max-params
    public constructor(
        protected readonly $window: ng.IWindowService,
        protected readonly $scope: ng.IScope,
        protected readonly $rootScope: ng.IRootScopeService,
        protected readonly i18n: I18nService,
        protected readonly interactionUtils: InteractionUtils,
        protected readonly mvAuth: MvAuth,
    ) {}

    public $onInit(): void {
        this.locations = [assignedLocationToLocationIdHierarchy(this.location)];
        if (this.preselectedLocations && this.preselectedLocations.length > 0) {
            this.locations = this.preselectedLocations.slice();
        }
        void this.refreshMultipleLocationPicker();
        const windowFocused = () => this.refreshMultipleLocationPicker();
        this.$window.addEventListener('focus', windowFocused);
        this.$scope.$on('$destroy', () => {
            this.$window.removeEventListener('focus', windowFocused);
        });
    }

    public updateSelectedLocations(locations: LocationIdHierarchy[]): void {
        this.locations = locations;
    }

    public filterAuthorizedAndConfiguredLocations(location: LocationIdHierarchy): boolean {
        return this.enabledLocationIds.indexOf(location.locationId) > -1;
    }

    public getMultiLocationFailedAccessHelp(): string {
        return this.i18n.text.build.publish.multipleLocations.noAccessHelp();
    }

    public refreshMultipleLocationPicker(): ng.IPromise<void> {
        if (this.platforms) {
            return Array.isArray(this.platforms)
                ? this.fetchAuthorizedAndConfiguredLocationIdsForMultiplePlatforms.run({
                      platforms: this.platforms.map(({ name }) => name as IAccessCheckMultiLocationPlatformName),
                  })
                : this.fetchAuthorizedAndConfiguredLocationIds.run({
                      // Being passed down as a string in multiple places, e.g. member engager email
                      platform:
                          typeof this.platforms === 'string'
                              ? this.platforms
                              : (this.platforms.name as IAccessCheckMultiLocationPlatformName),
                  });
        }
        return Promise.resolve();
    }

    public onClickCancel(): void {
        this.onCancel({});
    }

    public onClickContinue(): void {
        this.onContinue({
            locations: this.locations,
        });
    }
}

export const multipleLocationPublishingPickerSID = 'multipleLocationPublishingPicker';

export const multipleLocationPublishingPickerConfig = simpleComponent(
    MultipleLocationPublishingPickerCtrl,
    '/partials/contentBuilder/publish/multipleLocationPublishingPicker/multipleLocationPublishingPicker',
    {
        location: OneWayBinding,
        onCancel: ExpressionBinding,
        onContinue: ExpressionBinding,
        platforms: OneWayBinding,
        preselectedLocations: OptionalOneWayBinding,
    },
);

angular.module('app').directive(multipleLocationPublishingPickerSID, [() => multipleLocationPublishingPickerConfig]);
