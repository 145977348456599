const defaultPageOptions: PagingOptions = {
    perPageOptions: [25, 50, 100, 250],
    initialShowPerPage: 50,
    initialPage: 1,
};

export interface PagingOptions {
    initialShowPerPage?: number;
    initialPage?: number;
    perPageOptions?: number[];
}

export class Paging<T> {
    showPerPageOptions: number[];

    showPerPage: number;

    page: number;

    constructor(options: PagingOptions = {}) {
        options = {
            ...defaultPageOptions,
            ...options,
        };
        this.page = options.initialPage!;
        this.showPerPage = options.initialShowPerPage!;
        this.showPerPageOptions = options.perPageOptions!;
    }

    start() {
        this.page = 1;
    }

    previous() {
        this.page = Math.max(this.page - 1, 1);
    }

    previous10() {
        this.page = Math.max(this.page - 10, 1);
    }

    next(items: T[]) {
        this.page = Math.min(this.page + 1, this.getNumPages(items));
    }

    next10(items: T[]) {
        this.page = Math.min(this.page + 10, this.getNumPages(items));
    }

    end(items: T[]) {
        this.page = this.getNumPages(items);
    }

    getNumPages(items: T[]) {
        if (!items) {
            return 1;
        }
        return Math.ceil(items.length / this.showPerPage);
    }

    getOffset() {
        return (this.page - 1) * this.showPerPage;
    }

    resetPage() {
        this.page = 1;
    }

    clampPage(items: T[]) {
        this.page = Math.max(Math.min(this.page, this.getNumPages(items)), 1);
    }
}
