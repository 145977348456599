import { MvMultipleLocationPublishCtrl } from './imagePublishCtrl';

export const multipleLocationPublishConfig = {
    restrict: 'E',
    scope: {},
    templateUrl: '/partials/contentBuilder/publish/multipleLocationPublish',
    controller: MvMultipleLocationPublishCtrl,
    controllerAs: 'ctrl',
    bindToController: {
        publishCtrl: '=',
        contentBuilder: '=',
        originalLocation: '=',
        chosenLocations: '=',
        commonData: '=',
        publishCallback: '&',
        finishCallback: '&',
    },
};

function createDirective() : ng.IDirective<ng.IScope> {
    return multipleLocationPublishConfig;
}

export const multipleLocationPublishSID = 'multipleLocationPublish';

angular.module('app').directive(multipleLocationPublishSID, [createDirective]);
