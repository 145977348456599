import { hasProperty } from '@deltasierra/type-utilities';
import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';

function isHTMLInputElement(el: Element): el is HTMLInputElement {
    return el && hasProperty(el, 'focus');
}

export function DSScrollToErrorFormik(): JSX.Element {
    const formik = useFormikContext();
    const submitting = formik?.isSubmitting;

    useEffect(() => {
        const erroredFieldNames = Object.keys(formik.errors);
        if (!(erroredFieldNames.length > 0)) {
            return;
        }
        const fieldNamesToHtmlNames = erroredFieldNames.map(name => formik.getFieldProps(name).name);
        const el = document.querySelector(fieldNamesToHtmlNames.map(name => `[name="${name}"]`).join(', '));
        if (!el || !isHTMLInputElement(el)) {
            return;
        }
        el.scrollIntoView({ behavior: 'smooth', block: 'center' });
        el.focus({ preventScroll: true });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submitting]);
    return <></>;
}
DSScrollToErrorFormik.displayName = 'DSScrollToErrorFormik';
