import { SocketIOSocket, BaseSocketClientService } from '@deltasierra/shared';

import * as SocketIOClient from 'socket.io-client';
import { MvIdentity } from '../account/mvIdentity';
import { $qSID, $rootScopeSID } from '../common/angularData';
import { SocketServicesContainer } from './socketServicesContainer';
import IQService = angular.IQService;

export class SocketService extends BaseSocketClientService {
    public static SID = 'SocketService';

    public static readonly $inject: string[] = [$qSID, $rootScopeSID, MvIdentity.SID, SocketServicesContainer.SID];

    public constructor(
        private $q: IQService,
        private $rootScope: ng.IRootScopeService,
        private identity: MvIdentity,
        private readonly socketServicesContainer: SocketServicesContainer,
    ) {
        // Also store socket in window.socket, solely for easy access via js console
        // (window as Untyped).socket = this.socket;
        /**
         * ^ Jordan says no! You can log the value for dev if you need to, but you do NOT
         * push this.socket into the window on a prod environment!
         */
        super($q as PromiseConstructorLike);
    }

    protected createSocket(): SocketIOSocket {
        return SocketIOClient.io({
            autoConnect: false, // Do not automatically connect to the server
            transports: ['websocket'],
        });
    }

    protected async getInitialLoggedInStatus(): Promise<boolean> {
        return this.$q.resolve(this.identity.isAuthenticated());
    }

    protected startWatchers(onAuthenticated: () => void, onUnauthenticated: () => void): void {
        this.$rootScope.$on('authenticated', onAuthenticated);
        this.$rootScope.$on('unauthenticated', onUnauthenticated);
    }

    protected onSocketCreated(): void {
        super.onSocketCreated();
        this.socketServicesContainer.onSocketConnected(this);
    }

    protected onConnected(): void {
        super.onConnected();
    }

    protected onDisconnected(): void {
        super.onDisconnected();
    }
}

angular.module('app').service(SocketService.SID, SocketService);
