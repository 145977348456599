import { gql } from '@apollo/client';
import { DSWell, DSWellHeader, DSWellHeaderTitle, SvgLoader, WellList } from '@deltasierra/components';
import { t } from '@deltasierra/shared';
import * as React from 'react';
import { PlatformLogo } from '..//PlatformLogo';
import { PlatformWellFragment } from './__graphqlTypes/PlatformWellFragment';

const fragments = {
    platform: gql`
        fragment PlatformWellFragment on Platform {
            id
            name
            displayName
        }
    `,
};

export type PlatformWellProps = {
    className?: string;
    loading?: boolean;
    /**
     * Callback when the user selects a different platform
     *
     * @param platform - The name of the platform the user has selected
     */
    onChange: (platform: string) => void;
    platform?: PlatformWellFragment;
    platforms: PlatformWellFragment[];
    style?: React.CSSProperties;
};

export const PlatformWell: React.FC<PlatformWellProps> & { fragments: typeof fragments } = ({
    className,
    loading = false,
    onChange,
    platform = null,
    platforms,
    style,
}) => (
    <DSWell {...{ className, style }}>
        <DSWellHeader>
            <DSWellHeaderTitle>{t('AGENCY.CLIENT.LOCATION.NAV.PLATFORMS')}</DSWellHeaderTitle>
        </DSWellHeader>
        <SvgLoader loading={loading}>
            <WellList
                options={platforms.map(({ displayName, name }) => ({
                    display: displayName,
                    icon: <PlatformLogo name={name} size={26} />,
                    key: name,
                }))}
                selected={platform?.name}
                onChange={onChange}
            />
        </SvgLoader>
    </DSWell>
);
PlatformWell.displayName = 'PlatformWell';
PlatformWell.fragments = fragments;
