/// <reference path="../../../../typings/browser.d.ts" />
import { StripeAuthRequest } from '@deltasierra/shared';
import moment from 'moment';
import { InteractionUtils } from '../../common/interactionUtils';
import { I18nService } from '../../i18n/i18nService';
import { $locationSID } from '../../common/angularData';
import { StripeApiClient } from './stripeApiClient';

export class StripeAuthRequestCtrl {
    public static readonly SID = 'StripeAuthRequestCtrl';

    public readonly checkForValidToken = this.interactionUtils.createFuture(
        this.i18n.text.payments.stripe.fetchStripeAuthRequest(),
        async (context: { token: string }) => {
            const authRequest = await this.stripeApiClient.getStripeAuthRequest(context.token);

            if (this.isAuthRequestValid(authRequest)) {
                this.tokenInvalid = false;
                await this.fetchStripeAuthRequestUrl.run({ token: context.token });
            }
        },
    );

    public readonly fetchStripeAuthRequestUrl = this.interactionUtils.createFuture(
        this.i18n.text.payments.stripe.fetchStripeAuthUrl(),
        async (context: { token: string }) => {
            const authUrlResponse = await this.stripeApiClient.getStripeAuthorisationUrl(context.token);
            this.authUrl = authUrlResponse.authUrl.toString();
        },
    );

    public authUrl: string | null = null;

    public token: string | null = null;

    public tokenInvalid = true;

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public static readonly $inject: string[] = [
        $locationSID,
        InteractionUtils.SID,
        I18nService.SID,
        StripeApiClient.SID,
    ];

    public constructor(
        private readonly $location: ng.ILocationService,
        private readonly interactionUtils: InteractionUtils,
        private readonly i18n: I18nService,
        private readonly stripeApiClient: StripeApiClient,
    ) {
        const params = this.$location.search();
        this.token = params.token;
        if (this.token) {
            void this.checkForValidToken.run({ token: this.token });
        }
    }

    public isAuthRequestValid(authRequest: StripeAuthRequest): boolean {
        const now = moment(new Date());
        const createdDate = moment(authRequest.createdAt);
        const duration = moment.duration(now.diff(createdDate));
        const durationDifference = duration.asHours();

        return durationDifference < 24;
    }
}

angular.module('app').controller(StripeAuthRequestCtrl.SID, StripeAuthRequestCtrl);
