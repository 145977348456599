import { mergeAttributes, Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';
import classNames from 'classnames';
import { UploadedImageNodeAttributes } from './uploaded-image-node-attributes';
import { UploadedImageNodeOptions } from './uploaded-image-node-options';
import { UploadedImg } from './UploadedImg';

type InsertUploadedImageOptions = {
    insertPos?: number;
    src: string;
    textAlign?: TipTapTextAlignType;
    uploadId: string;
    width?: number;
};

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        insertUploadedImage: {
            /**
             * Add an image
             */
            insertUploadedImage: (options: InsertUploadedImageOptions) => ReturnType;
        };
    }
}

export const UploadImageNodeQuerySelector = 'img[src][data-upload-id]';

export const UploadedImageNode = Node.create<UploadedImageNodeOptions>({
    addAttributes(): TypedTipTapAttributes<UploadedImageNodeAttributes> {
        return {
            class: {
                default: this.options.imageClassName,
                parseHTML: element => classNames(this.options.imageClassName, element.getAttribute('class')),
                renderHTML: attributes => ({
                    class: classNames(this.options.imageClassName, attributes.class),
                }),
            },
            src: {
                default: null,
            },
            uploadId: {
                default: null,
                parseHTML: element => element.getAttribute('data-upload-id'),
                renderHTML: attributes => ({
                    'data-upload-id': attributes.uploadId,
                }),
            },
            width: {
                default: undefined,
            },
        };
    },
    addCommands() {
        return {
            insertUploadedImage:
                ({ insertPos, src, textAlign, uploadId, width }) =>
                props => {
                    const pos = props.editor.state.selection.anchor;
                    return props.commands.insertContentAt(insertPos ?? pos, {
                        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                        attrs: {
                            src,
                            textAlign,
                            uploadId,
                            width,
                        } as UploadedImageNodeAttributes,
                        type: this.name,
                    });
                },
        };
    },
    addNodeView() {
        return ReactNodeViewRenderer(UploadedImg);
    },
    atom: true,
    draggable: true,
    group: 'block',
    name: 'uploaded-image',
    parseHTML() {
        return [
            {
                tag: UploadImageNodeQuerySelector,
            },
        ];
    },
    renderHTML(props) {
        const { textAlign } = props.node.attrs as UploadedImageNodeAttributes;
        return [
            'div',
            {
                style: `text-align: ${textAlign ?? 'left'};`,
            },
            [
                'img',
                mergeAttributes(this.options.HTMLAttributes, props.HTMLAttributes),
            ],
        ];
    },
    selectable: true,
});
