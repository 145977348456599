import { Tooltip } from '@deltasierra/components';
import { t, TemplateGroupItem } from '@deltasierra/shared';
import LayersOutlinedIcon from '@material-ui/icons/LayersOutlined';
import * as React from 'react';
import styled from 'styled-components';
import { OptionalOneWayBinding } from '../../../common/angularData';
import { withAngularIntegration } from '../../../common/componentUtils/reactComponentRegistration';

const StyledIcon = styled(LayersOutlinedIcon)`
    color: ${props => props.theme.palette.primary.main};
`;

export type BuilderTemplateDraftsIconProps = {
    builderTemplate?: TemplateGroupItem;
};

export function BuilderTemplateDraftsIcon({ builderTemplate }: BuilderTemplateDraftsIconProps): JSX.Element {
    return (
        <>
            {builderTemplate?.hasRelatedDraft && (
                <Tooltip tip={t('BUILD.LOCATION_DRAFT.TEMPLATE_HAS_DRAFT_TOOLTIP')}>
                    <StyledIcon />
                </Tooltip>
            )}
        </>
    );
}
BuilderTemplateDraftsIcon.displayName = 'BuilderTemplateDraftsIcon';

export const BuilderTemplateDraftsIconWithAngular = withAngularIntegration(
    BuilderTemplateDraftsIcon,
    'builderTemplateDraftsIcon',
    {
        builderTemplate: OptionalOneWayBinding,
    },
);
