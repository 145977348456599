import { AssetAndLayerId,
    BuilderTemplateApi,
    BuilderTemplateGalleryDto,
    BuilderTemplateId,
    BuilderTemplateWithBasicAssociations,
    DocumentTemplateToSave,
    GetAllBuilderTemplatesQuery,
    TemplateDraftModeDto,
    TemplateMetadataDto,
 justBody, justParams, justQuery, paramsAndBody, Html, Upload } from '@deltasierra/shared';

import { noop } from '@deltasierra/object-utilities';


import { $httpSID } from '../common/angularData';
import { invokeApiRoute } from '../common/httpUtils';
import IHttpService = angular.IHttpService;
import IPromise = angular.IPromise;

export class BuilderTemplateApiClient {
    public static readonly SID = 'BuilderTemplateApiClient';

    public static readonly $inject: string[] = [$httpSID];

    public constructor(protected readonly $http: IHttpService) {}

    public createOrUpdateBuilderTemplate(
        builderTemplate: DocumentTemplateToSave,
        templateId?: number,
        deleteLocationDrafts?: boolean,
    ): IPromise<BuilderTemplateWithBasicAssociations> {
        if (templateId) {
            return invokeApiRoute(this.$http, BuilderTemplateApi.updateBuilderTemplate, {
                body: builderTemplate,
                params: {
                    templateId,
                },
                query: {
                    deleteLocationDrafts,
                },
            });
        } else {
            return invokeApiRoute(this.$http, BuilderTemplateApi.createBuilderTemplate, justBody(builderTemplate));
        }
    }

    public deleteBuilderTemplate(templateId: number): IPromise<void> {
        return invokeApiRoute(
            this.$http,
            BuilderTemplateApi.deleteBuilderTemplate,
            justParams({
                templateId,
            }),
        ).then(noop);
    }

    public getAllBuilderTemplates(criteria: GetAllBuilderTemplatesQuery): IPromise<BuilderTemplateGalleryDto[]> {
        return invokeApiRoute(this.$http, BuilderTemplateApi.getAllBuilderTemplates, justQuery(criteria));
    }

    public getBuilderTemplate(templateId: number): IPromise<BuilderTemplateWithBasicAssociations> {
        return invokeApiRoute(
            this.$http,
            BuilderTemplateApi.getBuilderTemplate,
            justParams({
                templateId,
            }),
        );
    }

    public getLocationDraftAsBuilderTemplate(
        locationDraftGraphqlId: string,
    ): IPromise<BuilderTemplateWithBasicAssociations> {
        return invokeApiRoute(
            this.$http,
            BuilderTemplateApi.getLocationDraftAsBuilderTemplate,
            justParams({
                locationDraftGraphqlId,
            }),
        );
    }

    public getAssetLibraryAssetsUsedInTemplate(templateId: BuilderTemplateId): IPromise<AssetAndLayerId[]> {
        return invokeApiRoute(
            this.$http,
            BuilderTemplateApi.getAllAssetLibraryAssetsUsedInTemplate,
            justParams({ templateId }),
        );
    }

    public getAllAssetLibraryAssetsUsedInLocationDraft(locationDraftGraphqlId: string): IPromise<AssetAndLayerId[]> {
        return invokeApiRoute(
            this.$http,
            BuilderTemplateApi.getAllAssetLibraryAssetsUsedInLocationDraft,
            justParams({
                locationDraftGraphqlId,
            }),
        );
    }

    public updateBuilderTemplateMetadata(
        templateId: number,
        data: TemplateMetadataDto,
    ): IPromise<BuilderTemplateWithBasicAssociations> {
        return invokeApiRoute(
            this.$http,
            BuilderTemplateApi.updateMetadata,
            paramsAndBody(
                {
                    templateId,
                },
                data,
            ),
        );
    }

    public updateBuilderTemplateDraftMode(
        templateId: number,
        data: TemplateDraftModeDto,
    ): IPromise<BuilderTemplateWithBasicAssociations> {
        return invokeApiRoute(
            this.$http,
            BuilderTemplateApi.updateDraftMode,
            paramsAndBody(
                {
                    templateId,
                },
                data,
            ),
        );
    }

    public takeHtmlScreenshot(html: Html): IPromise<Upload> {
        return invokeApiRoute(
            this.$http,
            BuilderTemplateApi.takeHtmlScreenshot,
            justBody({
                html,
            }),
        );
    }
}

angular.module('app').service(BuilderTemplateApiClient.SID, BuilderTemplateApiClient);
