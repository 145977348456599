/// <reference path="../../../typings/browser.d.ts" />
import { Location, TryThis, TryThisSection, TryThisSubSection, Untyped } from '@deltasierra/shared';
import { IKookies } from '../common/angularData';
import { DataUtils } from '../common/dataUtils';
import { InteractionUtils } from '../common/interactionUtils';
import { MvTryThis } from './mvTryThis';
import IScope = angular.IScope;
import IQService = angular.IQService;
import IPromise = angular.IPromise;

interface Section {
    title: string;
    subSections: SubSection[];
}

class SubSection {
    items: SectionItem[] = [];

    loading = false;

    loaded = false;

    expanded = false;

    constructor(public id: number | null, public title: string, public tag: string) {}
}

interface SectionItem {
    title: string;
    tryThisId: number;
}

const COOKIE_NAME = 'tryThisMenuSubSection';

export class TryThisMenuCtrl {
    static SID = 'TryThisMenuCtrl';

    // Directive attributes
    private location!: Location;

    public currentTryThis!: TryThis;

    public sections: Section[] = [];

    public loading = {
        // Tslint:disable-line:no-unused-variable
        sections: false,
        sectionItems: false,
        uncategorizedItems: false,
    };

    static readonly $inject: string[] = [
        '$scope',
        '$q',
        'Slug',
        '$kookies',
        InteractionUtils.SID,
        DataUtils.SID,
        MvTryThis.SID,
    ];

    constructor(
        private $scope: IScope,
        private $q: IQService,
        private Slug: Untyped,
        private $kookies: IKookies,
        private interactionUtils: InteractionUtils,
        private dataUtils: DataUtils,
        private mvTryThis: MvTryThis,
    ) {
        this.initWatchers();
    }

    private getSubSectionIdFromCookie(): number | null {
        return this.$kookies.get(COOKIE_NAME, Number) || null;
    }

    private setSubSectionIdInCookie(subSectionId: number | null): void {
        this.$kookies.set(COOKIE_NAME, subSectionId, { path: '/', secure: true });
    }

    private initWatchers(): void {
        this.$scope.$watch(() => this.location, this.onLocationChange.bind(this));
        this.$scope.$watch(() => this.currentTryThis, this.onCurrentTryThisChange.bind(this));
    }

    private onLocationChange(newValue: Location, oldValue: Location): ng.IPromise<void> | void {
        if (oldValue !== newValue) {
            if (newValue && (!oldValue || oldValue.clientId != newValue.clientId)) {
                return this.loadSections();
            }
        }
    }

    private onCurrentTryThisChange(newValue: TryThis, oldValue: TryThis): void {
        if (oldValue !== newValue) {
            this.expandSubSectionForCurrentTryThis();
        }
    }

    private loadSections(): IPromise<void> {
        return this.$q
            .all([
                this.interactionUtils
                    .handleRemote(
                        this,
                        'retrieve learn sections',
                        'sections',
                        this.mvTryThis.getTryThisSections(this.location.clientId),
                    )
                    .then<Section[]>(this.mapSections.bind(this)),
                this.interactionUtils.handleRemote(
                    this,
                    'retrieve uncategorized learn articles',
                    'uncategorizedItems',
                    this.mvTryThis.getUncategorizedTryThese(this.location.clientId),
                ),
            ])
            .then((result: [Section[], TryThis[]]) => {
                const sections = result[0];
                const uncategorizedItems = result[1];
                if (uncategorizedItems && uncategorizedItems.length > 0) {
                    this.addUncategorizedSection(sections, uncategorizedItems);
                }
                this.sections = sections;
                this.expandSubSectionForCurrentTryThis();
            });
    }

    private mapSections(input: TryThisSection[]): Section[] {
        if (input) {
            return input.map(
                (tts: TryThisSection): Section => ({
                    title: tts.title,
                    subSections:
                        tts && tts.subSections
                            ? tts.subSections.map(
                                  (ttss: TryThisSubSection): SubSection =>
                                      new SubSection(ttss.id, ttss.title, ttss.tag),
                              )
                            : [],
                }),
            );
        } else {
            return [];
        }
    }

    private addUncategorizedSection(sections: Section[], uncategorizedItems: TryThis[]): void {
        const subSection = new SubSection(null, 'Uncategorized', 'uncategorized');
        subSection.items = this.mapTryThisesToSectionItems(uncategorizedItems);
        subSection.loaded = true;
        const section: Section = {
            title: 'Uncategorized',
            subSections: [subSection],
        };
        sections.push(section);
    }

    toggleSubSection(subSection: SubSection): ng.IPromise<void> | void {
        subSection.expanded = !subSection.expanded;
        if (subSection.expanded && !subSection.loaded && !subSection.loading) {
            return this.loadSectionItems(subSection);
        }
    }

    private findActiveSubSection(): SubSection | null {
        let subSectionId: number | null = this.getSubSectionIdFromCookie();
        if (subSectionId == 0) {
            subSectionId = null;
        }
        for (const section of this.sections) {
            const subSection = this.dataUtils.findByPredicate(
                filterSubSection => filterSubSection.id === subSectionId,
                section.subSections,
            );
            if (subSection) {
                return subSection;
            }
        }
        return null;
    }

    private expandSubSectionForCurrentTryThis() {
        if (this.sections && this.sections.length > 0) {
            const subSection = this.findActiveSubSection();
            if (subSection && !subSection.expanded) {
                this.toggleSubSection(subSection);
            }
        }
    }

    private loadSectionItems(subSection: SubSection): IPromise<void> {
        subSection.loading = true;
        return this.interactionUtils
            .handleRemoteSimple(
                this,
                'retrieve learn articles',
                'sectionItems',
                this.mvTryThis.getTryThese(this.location.clientId, undefined, 0, subSection.tag),
            )
            .then<SectionItem[]>(this.mapTryThisesToSectionItems.bind(this))
            .then((sectionItems: SectionItem[]) => {
                subSection.items = sectionItems;
                subSection.loading = false;
                subSection.loaded = true;
            })
            .catch(err => {
                subSection.loading = false;
                throw err;
            });
    }

    private mapTryThisesToSectionItems(tryThises: TryThis[]): SectionItem[] {
        return tryThises.map(
            (tryThis: TryThis): SectionItem => ({
                title: tryThis.title,
                tryThisId: tryThis.id,
            }),
        );
    }

    slugify(item: SectionItem): string {
        if (item) {
            return this.Slug.slugify(item.title);
        } else {
            return '';
        }
    }

    clickItem(item: SectionItem, subSection: SubSection): void {
        this.setSubSectionIdInCookie(subSection.id);
    }
}

export const dsTryThisMenuSID = 'dsTryThisMenu';
export const dsTryThisMenuConfig: ng.IDirective<ng.IScope> = {
    restrict: 'E',
    scope: {},
    templateUrl: '/partials/trythis/tryThisMenu',
    controller: TryThisMenuCtrl,
    controllerAs: 'ctrl',
    bindToController: {
        location: '=',
        currentTryThis: '=',
    },
};

angular.module('app').controller(TryThisMenuCtrl.SID, TryThisMenuCtrl);
angular.module('app').directive(dsTryThisMenuSID, [() => dsTryThisMenuConfig]);
