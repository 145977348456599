import buttonSection = require('./buttonSection');
import imageSection = require('./imageSection');
import section = require('./section');
import textSection = require('./textSection');

export {
    buttonSection,
    imageSection,
    section,
    textSection,
};
