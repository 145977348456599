import { gql } from '@apollo/client';
import { FailedPublishesTable } from './FailedPublishesTable';

export const GET_FAILED_PUBLISHES_QUERY = gql`
    query GetFailedPublishes($after: String, $first: Int) {
        admin {
            id
            recentFailedPublishes(after: $after, first: $first) {
                edges {
                    node {
                        id
                        ...ScheduledPublishFragmentForFailedPublishesTable
                    }
                }
                pageInfo {
                    hasNextPage
                    endCursor
                }
            }
        }
    }
    ${FailedPublishesTable.fragments.SCHEDULED_PUBLISH_FRAGMENT_FOR_FAILED_PUBLISHES_TABLE}
`;
