import * as React from 'react';
import { SetCurrentAssetContext } from '../contexts';
import { useRouter } from '../../common/routes';

export type UseUrlParameterReturnType = [
    urlParameter: string, setUrlParameter: SetCurrentAssetContext
]

/**
 * @param param - name of the url parameter which you would like to interacte with (get/set)
 * @returns [urlParameter, setUrlParameter] returns an array with the current parameter
 * value (urlParameter), along wiht a function to set it (setUrlParameter)
 */
export function useUrlParameter(param: string): UseUrlParameterReturnType {
    const { query } = useRouter<Record<string, never>, { [param: string]: string }>();

    const urlParameter = query.get(param);

    const setUrlParameter = React.useCallback(
        (value?: string): void => {
            if (value) {
                query.set(param, value);
            } else {
                query.remove(param);
            }
        },
        [param, query],
    );

    return [urlParameter, setUrlParameter];
}
