import { gql, useQuery } from '@apollo/client';
import { SplitButton } from '@deltasierra/components';
import { useToggle } from '@deltasierra/react-hooks';
import { t, AssignedLocation, BuilderTemplate, BuilderType, Url } from '@deltasierra/shared';
import * as React from 'react';
import styled from 'styled-components';
import { ContentBuilder } from '../../../contentBuilder';
import { EmailBuilder } from '../../../email/emailBuilder';
import { SaveNewBuilderTemplateDraftMutationResponse } from '../../../hooks/useSaveNewBuilderTemplateDraftMutation';
import { GetForUploadType } from '../../../mvContentBuilderCtrl';
import { BuilderTemplateDraftLoadModal } from '../BuilderTemplateDraftLoadModal';
import { SelectedBuilderTemplateDraft } from '../BuilderTemplateDraftPreview';
import { BuilderTemplateDraftSaveModal } from '../BuilderTemplateDraftSaveModal/BuilderTemplateDraftSaveModal';
import { GetBuilderTemplateDraft, GetBuilderTemplateDraftVariables } from './__graphqlTypes/GetBuilderTemplateDraft';

export const GET_BUILDER_TEMPLATE_DRAFT = gql`
    query GetBuilderTemplateDraft($locationId: ID!, $builderTemplateId: Float!) {
        location(id: $locationId) {
            id
            locationDrafts(filter: { builderTemplateIds: [$builderTemplateId] }) {
                edges {
                    node {
                        id
                    }
                }
                ...BuilderTemplateDraftLoadModalFragment
            }
        }
    }
    ${BuilderTemplateDraftLoadModal.fragments.locationDrafts}
`;

const StyledSplitButton = styled(SplitButton)`
    height: 54px;
`;

export interface BuilderTemplateDraftActionsButtonProps {
    builderTemplate: BuilderTemplate;
    builderType: BuilderType;
    contentBuilder: ContentBuilder | EmailBuilder;
    getForUpload: GetForUploadType;
    location: AssignedLocation;
    loading?: boolean;
    onDraftSelect?: (locationDraftGraphqlId: string) => void;
    onOriginalTemplateSelect?: () => void;
}

// eslint-disable-next-line max-lines-per-function
export function BuilderTemplateDraftActionsButton(props: BuilderTemplateDraftActionsButtonProps): JSX.Element {
    const { data, loading } = useQuery<GetBuilderTemplateDraft, GetBuilderTemplateDraftVariables>(
        GET_BUILDER_TEMPLATE_DRAFT,
        {
            fetchPolicy: 'cache-and-network',
            nextFetchPolicy: 'cache-first',
            notifyOnNetworkStatusChange: true,
            variables: { builderTemplateId: props.builderTemplate.id, locationId: props.location.graphqlId },
        },
    );
    const locationDrafts = data?.location?.locationDrafts;

    const [hasPreviousRevision, setHasPreviousRevision] = React.useState(false);
    const [saveModalWasOpened, setSaveModalWasOpened] = React.useState(false);
    const [currentDraft, setCurrentDraft] = React.useState<SelectedBuilderTemplateDraft>();

    const [showingSaveModal, toggleSaveModal] = useToggle();
    const [showingLoadModal, toggleLoadModal] = useToggle();

    React.useEffect(() => {
        const locationDraftCount = locationDrafts?.edges.length;
        if (!loading && !hasPreviousRevision && locationDraftCount && locationDraftCount > 0) {
            setHasPreviousRevision(true);
            // We only want to open the modal if the user hasn't opened the save modal.
            if (!saveModalWasOpened) {
                toggleLoadModal('toggleOn');
            }
        }
    }, [locationDrafts?.edges.length, hasPreviousRevision, loading, saveModalWasOpened, toggleLoadModal]);

    const handleDraftSave = (response: SaveNewBuilderTemplateDraftMutationResponse | undefined) => {
        // Save modal has already closed. This handles updating the currently selected draft to the newly saved draft.
        if (response) {
            setCurrentDraft({
                ...response,
                id: response.locationDraftId,
                thumbnailUrl: response.compositeImage?.thumb256x256url
                    ? Url.from(`https:${response.compositeImage.thumb256x256url}`)
                    : undefined,
            });
        }
    };

    const handleDraftSelect = (selectedDraft: SelectedBuilderTemplateDraft) => {
        setCurrentDraft(selectedDraft);
        toggleLoadModal('toggleOff');
        if (props.onDraftSelect) {
            props.onDraftSelect(selectedDraft.id);
        }
    };

    const handleDraftRemove = (selectedDraftId: string) => {
        if (currentDraft && currentDraft.id === selectedDraftId) {
            setCurrentDraft(undefined);
            if (props.onOriginalTemplateSelect) {
                props.onOriginalTemplateSelect();
            }
        }
    };

    const handleOriginalTemplateSelect = () => {
        setCurrentDraft(undefined);
        toggleLoadModal('toggleOff');
        if (props.onOriginalTemplateSelect) {
            props.onOriginalTemplateSelect();
        }
    };

    return (
        <>
            <StyledSplitButton
                loading={loading || props.loading}
                options={
                    hasPreviousRevision
                        ? [
                              {
                                  label: t('BUILD.LOCATION_DRAFT.LOAD_A_PREVIOUS_REVISION'),
                                  onClick: () => {
                                      toggleLoadModal('toggleOn');
                                  },
                              },
                          ]
                        : undefined
                }
                onClick={() => {
                    toggleSaveModal('toggleOn');
                }}
            >
                {t('BUILD.LOCATION_DRAFT.SAVE_LOCATION_REVISION')}
            </StyledSplitButton>
            <BuilderTemplateDraftSaveModal
                {...props}
                builderTemplateId={props.builderTemplate.id}
                currentDraft={currentDraft}
                show={showingSaveModal}
                onClose={() => {
                    toggleSaveModal('toggleOff');
                    setSaveModalWasOpened(true);
                }}
                onSave={handleDraftSave}
            />
            <BuilderTemplateDraftLoadModal
                {...props}
                locationDrafts={locationDrafts}
                show={showingLoadModal}
                onClose={() => toggleLoadModal('toggleOff')}
                onDelete={handleDraftRemove}
                onDraftSelect={handleDraftSelect}
                onOriginalTemplateSelect={handleOriginalTemplateSelect}
            />
        </>
    );
}

BuilderTemplateDraftActionsButton.displayName = 'BuilderTemplateDraftActionsButton';
