/// <reference path="../_references.d.ts" />

import { Untyped } from '@deltasierra/shared';
import IAugmentedJQuery = angular.IAugmentedJQuery;

const VALIDATORS_SID = 'dsValidators';
const ASYNC_VALIDATORS_SID = 'dsAsyncValidators';

interface ValidatorMap {
    [key: string]: Untyped;
}

interface ValidatorScope extends ng.IScope {
    dsValidators?: ValidatorMap;
    dsAsyncValidators?: ValidatorMap;
}

function registerValidatorsDirective(sid: 'dsAsyncValidators' | 'dsValidators', isAsync: boolean) {

    function ValidatorsDirective(): ng.IDirective<ValidatorScope> {
        return {
            restrict: 'A',
            scope: {
                [sid]: '<',
            },
            require: 'ngModel',
            link: function (scope: ValidatorScope, element: IAugmentedJQuery, attrs: ng.IAttributes, modelController: ng.INgModelController) {
                const validators = scope[sid];
                if (validators) {
                    const $validators = modelController[isAsync ? '$asyncValidators' : '$validators'];
                    for (const key of Object.getOwnPropertyNames(validators)) {
                        $validators[key] = validators[key];
                    }
                }
            } as ng.IDirectiveLinkFn<ValidatorScope>,
        };
    }

    angular.module('app').directive(sid, [ValidatorsDirective]);
}

registerValidatorsDirective(VALIDATORS_SID, false);
registerValidatorsDirective(ASYNC_VALIDATORS_SID, true);
