/// <reference path="../../../typings/browser.d.ts" />
import { BuilderTemplateFormat, ISize, platformSupportsMultiImage } from '@deltasierra/shared';

type BuilderTemplateFormatEntry = BuilderTemplateFormat & {
    disabled?: boolean;
};

export interface FormatSelectScope extends ng.IScope {
    formats: BuilderTemplateFormat[];
    formatOptions: BuilderTemplateFormatEntry[];
    dimensions?: ISize;
    animation: boolean;
    multiImage: boolean;
    change?: (format: BuilderTemplateFormat) => any;
    showDimensions?: boolean;
    templateFormatType: 'image' | 'print' | 'video';

    showAll: boolean;
    toggleFormat: (format: BuilderTemplateFormat) => void;
    isSelected: (format: BuilderTemplateFormat) => boolean;
}

export const builderTemplateFormatSelectSID = 'builderTemplateFormatSelect';
export const builderTemplateFormatSelectConfig = {
    restrict: 'E',
    scope: {
        animation: '=',
        change: '&?',
        dimensions: '=?',
        formatOptions: '=',
        formats: '=',
        multiImage: '=',
        showDimensions: '=?',
        templateFormatType: '=',
    },
    templateUrl: '/partials/contentBuilder/formatSelect',
};

angular.module('app').directive(builderTemplateFormatSelectSID, [
    () => {
        const ASPECT_RATIO_THRESHOLD = 0.01;

        function indexOfId(formats: BuilderTemplateFormat[], id: number) {
            if (formats) {
                for (let i = 0; i < formats.length; i++) {
                    const format = formats[i];
                    if (format.id === id) {
                        return i;
                    }
                }
            }
            return -1;
        }

        return {
            ...builderTemplateFormatSelectConfig,
            link(scope: FormatSelectScope) {
                function filterFormats() {
                    if (scope.formatOptions && scope.formatOptions.length > 0 && scope.dimensions) {
                        const aspectRatio = scope.dimensions.width / scope.dimensions.height;
                        for (const format of scope.formatOptions) {
                            if (scope.animation && !format.animatable) {
                                disableAndRemoveFormat(format);
                            } else if (
                                scope.multiImage &&
                                (!format.platform || !platformSupportsMultiImage(format.platform.name))
                            ) {
                                disableAndRemoveFormat(format);
                            } else if (format.type !== scope.templateFormatType) {
                                disableAndRemoveFormat(format);
                            } else {
                                const formatAspectRatio = format.width / format.height;
                                if (Math.abs(aspectRatio - formatAspectRatio) <= ASPECT_RATIO_THRESHOLD) {
                                    format.disabled = false;
                                } else {
                                    disableAndRemoveFormat(format);
                                }
                            }
                        }
                    }
                }

                function removeFormat(format: BuilderTemplateFormat) {
                    const index = indexOfId(scope.formats, format.id);
                    if (index > -1) {
                        scope.formats.splice(index, 1);
                    }
                }

                function disableAndRemoveFormat(format: BuilderTemplateFormatEntry) {
                    format.disabled = true;
                    removeFormat(format);
                }

                scope.showAll = false;

                scope.toggleFormat = format => {
                    const index = indexOfId(scope.formats, format.id);
                    if (index > -1) {
                        scope.formats.splice(index, 1);
                    } else {
                        scope.formats.push(format);
                    }
                    if (scope.change) {
                        scope.change(format);
                    }
                };

                scope.isSelected = format => indexOfId(scope.formats, format.id) > -1;

                scope.$watch('dimensions', filterFormats, true);

                scope.$watch('formatOptions', filterFormats, true);

                scope.$watch('animation', filterFormats, true);

                scope.$watch('multiImage', filterFormats, true);
            },
        };
    },
]);
