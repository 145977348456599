import { LocationId } from '@deltasierra/shared';
import {
    $locationSID,
    ChangesObject,
    ILifecycleHooks,
    OneWayBinding,
    simpleComponent,
} from '../../../common/angularData';
import { InteractionUtils } from '../../../common/interactionUtils';
import { MvAuth } from '../../../account/mvAuth';

enum ActiveTab {
    BuilderTemplateGallery,
    MemberEngager,
    Other,
}

export class MemberEngagerNavCtrl implements ILifecycleHooks {
    public static readonly SID = 'MemberEngagerNavCtrl';

    // Props
    public readonly locationId!: LocationId;

    // State
    public readonly ActiveTab: typeof ActiveTab = ActiveTab;

    public currentActiveTab!: ActiveTab;

    public canAccessFitware!: boolean;

    protected readonly fetchFitwareAccess = this.interactionUtils.createFuture(
        'fetch Fitware access',
        (context: { locationId: LocationId }) =>
            this.mvAuth.isAuthorizedAndConfigured('fitware', context.locationId).then((result: boolean) => {
                this.canAccessFitware = result;
            }),
    );

    public static readonly $inject: string[] = [$locationSID, InteractionUtils.SID, MvAuth.SID];

    constructor(
        protected readonly $location: ng.ILocationService,
        protected readonly interactionUtils: InteractionUtils,
        protected readonly mvAuth: MvAuth,
    ) {}

    public $onInit(): void {
        this.canAccessFitware = false;
        this.currentActiveTab = this.getCurrentActiveTab();
    }

    protected getCurrentActiveTab() {
        const path = this.$location.path();
        if (path.indexOf('/memberEngager') > -1) {
            return ActiveTab.MemberEngager;
        } else if (path.indexOf('/builderTemplateGallery') > -1) {
            return ActiveTab.BuilderTemplateGallery;
        } else {
            return ActiveTab.Other;
        }
    }

    $onChanges(changes: ChangesObject<this>) {
        if (changes.locationId && changes.locationId.currentValue) {
            return this.fetchFitwareAccess.run({
                locationId: this.locationId,
            });
        }
    }
}

export const memberEngagerNavSID = 'memberEngagerNav';

export const memberEngagerNavConfig = simpleComponent(
    MemberEngagerNavCtrl,
    '/partials/contentBuilder/memberEngager/memberEngagerNav/memberEngagerNav',
    {
        locationId: OneWayBinding,
    },
);

angular.module('app').directive(memberEngagerNavSID, [
    (): ng.IDirective<ng.IScope> => ({
        ...memberEngagerNavConfig,
        link($scope, element) {
            // Move this navbar to directly under the top navbar.
            element.remove();
            angular.element('#topNavbar').after(element);
            // We gotta remember to remove the element when the scope is destroyed, otherwise we get duplicate elements.
            $scope.$on('$destroy', () => {
                element.remove();
            });
        },
    }),
]);
