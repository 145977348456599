import { DSTypography } from '@deltasierra/components';
import { assertNever } from '@deltasierra/shared';
import * as React from 'react';
import { Link } from 'react-router-dom';

interface ExternalPathBreadcrumbProps {
    display: string;
    external: true;
    href: string;
}

interface LinkPathBreadcrumbProps {
    display: string;
    href: string;
}

interface NoLinkPathBreadcrumbProps {
    display: string;
}

export type PathBreadcrumbProps = ExternalPathBreadcrumbProps | LinkPathBreadcrumbProps | NoLinkPathBreadcrumbProps;

export function PathBreadcrumb(crumb: PathBreadcrumbProps): JSX.Element {
    if ('external' in crumb) {
        return <a href={crumb.href}>{crumb.display}</a>;
    } else if ('href' in crumb) {
        return <Link to={crumb.href}>{crumb.display}</Link>;
    } else if ('display' in crumb) {
        return <DSTypography color="textSecondary">{crumb.display}</DSTypography>;
    } else {
        return assertNever(crumb);
    }
}
