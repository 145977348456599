/* eslint-disable @typescript-eslint/no-unused-vars */
import { BaseInviteUserDto, ErrorCode, isErrorByCode, ServerError, t } from '@deltasierra/shared';
import * as Formik from 'formik';
import * as React from 'react';
import { SelectTimezoneFormInput } from '../../directives/SelectTimezone';
import { Translate } from '../../directives/Translate';
import { Button } from '../Button';
import { useAngularServiceContext } from '../componentUtils/angularServiceContexts';
import Form, { FormRenderFunction } from '../form/Form';
import { FieldIntegrationProps, withFormField } from '../form/helpers';
import Select from '../form/Select';
import Well from '../layout/Well';
import { Loading } from '../Loading';

type TextInputProps<TValue> = {
    type: 'email' | 'password' | 'text' | 'url';
};

function TextField<TValue extends number | string>({
    field,
    type,
    ...props
}: FieldIntegrationProps<TValue> & TextInputProps<TValue>): React.ReactElement {
    return (
        <input
            {...props}
            className="form-control"
            disabled={field.disabled}
            name={field.name}
            required={field.required}
            type={type}
            value={field.value}
            onBlur={field.handleBlurEvent}
            onChange={field.handleChangeEvent}
        />
    );
}
TextField.displayName = 'TextField';

const TextInputField = withFormField()(TextField);

export type UserInviteFormProps<TValues extends {}> = {
    /**
     * A custom render function so that you can add additional fields to the user invite form.
     *
     * @param props - The form state
     * @param propAccessor - The property path accessor object
     */
    children?: FormRenderFunction<BaseInviteUserDto & TValues>;
    dataCyButton?: string;
    dataCyInput?: string;
    initialValues: BaseInviteUserDto & TValues;
    title: string;
    /**
     * The submit handler for the user invite form. Error handling and form resetting is already taken care of.
     *
     * @param values - The values from the form
     * @param actions - The form actions
     */
    onSubmit: (
        values: BaseInviteUserDto & TValues,
        actions: Formik.FormikHelpers<BaseInviteUserDto & TValues>,
    ) => Promise<void>;
};

export default function UserInviteForm<TValues extends {}>({
    children,
    initialValues,
    onSubmit,
    title,
}: UserInviteFormProps<TValues>): JSX.Element {
    const notifier = useAngularServiceContext('mvNotifier');
    const locales = useLocales();
    const handleOnSubmit = async (
        values: BaseInviteUserDto & TValues,
        actions: Formik.FormikHelpers<BaseInviteUserDto & TValues>,
    ) => {
        try {
            await onSubmit(values, actions);
        } catch (error) {
            if (isErrorByCode<ServerError>(ErrorCode.AccountAlreadyExistsError, error.data)) {
                notifier.expectedError(error.data.reason.toString());
                actions.setErrors({ username: error.data.reason.toString() });
            } else {
                notifier.unexpectedError(error);
            }
        } finally {
            actions.setSubmitting(false);
        }
    };

    return (
        <Well title={title}>
            <Form
                dataCy="user-invite-form"
                initialValues={initialValues}
                name="user-invite-form"
                render={(props, inputNames) => (
                    <>
                        <TextInputField
                            data-cy="email-input"
                            disabled={props.isSubmitting}
                            label={t('COMMON.EMAIL')}
                            name={inputNames.username}
                            required
                            type="email"
                        />
                        <SelectTimezoneFormInput name={inputNames.timezone.__path} />
                        <Select
                            data-cy="locale-select"
                            disabled={props.isSubmitting}
                            label={t('COMMON.LOCALE')}
                            name={inputNames.localeCode.__path}
                            options={locales}
                        />
                        {children && children(props, inputNames)}
                        <div className="form-group text-right">
                            {props.isSubmitting && <Loading inline size="small" />}
                            <Button
                                className="space-right"
                                data-cy="submit-button"
                                disabled={props.isSubmitting || !props.isValid}
                                theme="primary"
                                type="submit"
                            >
                                <span className="glyphicon glyphicon-plus icon-space" />
                                <span>
                                    <Translate keyId="COMMON.ADD" />
                                </span>
                            </Button>
                        </div>
                    </>
                )}
                onSubmit={handleOnSubmit}
            />
        </Well>
    );
}
UserInviteForm.displayName = 'UserInviteForm';

function useLocales() {
    const i18n = useAngularServiceContext('I18nService');
    return React.useMemo(() => i18n.getLocales().map(locale => ({ label: locale.title, value: locale.code })), [i18n]);
}
