/// <reference path="../../../../../typings/browser.d.ts" />
import { ImageSection as IImageSection, SectionType } from '@deltasierra/shared';
import { Section } from './section';

export class ImageSection extends Section implements IImageSection {
    type!: SectionType.image;

    width = 0;

    height = 0;

    isLinkable = false;

    link = '';

    locationType: 'external' | 'local' = 'local';

    location: string | null = null;

    originalLocation: string | null = null;

    constructor(id: number) {
        super(id, SectionType.image);
    }
}
