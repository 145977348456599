import { useLocalStorage } from '@deltasierra/react-hooks';
import * as React from 'react';
import { CurrentLocationIdType, CurrentLocationStorageStateContext } from '../contexts';

export const CurrentLocationContextLocalStorageKey = 'ds_current_location' as const;

/**
 * Use the current client state from the local storage context or the actual local storage.
 *
 * @returns The local storage actions for the current client key
 */
export function useCurrentLocationLocalStorageState(): CurrentLocationIdType | undefined {
    const valueFromContext = React.useContext(CurrentLocationStorageStateContext);
    const [valueFromLocalStorage] = useLocalStorage<CurrentLocationIdType>(CurrentLocationContextLocalStorageKey);
    return valueFromContext ?? valueFromLocalStorage ?? undefined;
}
