import * as Sentry from '@sentry/browser';
import { AssetMoverState } from './asset-mover-state';
import { MoveAssetOption } from './move-asset-option';

const ASSETS_TO_PROCESS_IN_BATCH = 25;

type OnStateChangeHandler = (changes: AssetMoverState) => void;

type ExecuteMoveFunc = (idsToMove: MoveAssetOption[], isLastMove: boolean) => Promise<void>;

export type AssetMoverOptions = {
    onStateChange: OnStateChangeHandler;
    executeMove: ExecuteMoveFunc;
};

export class AssetMoverBatchProcessor {
    private readonly assetsToMove: MoveAssetOption[] = [];

    private loopPromise: Promise<void> | null = null;

    private options: AssetMoverOptions;

    public constructor(options: AssetMoverOptions) {
        this.options = options;
    }

    public syncOptions(options: AssetMoverOptions): void {
        this.options = options;
    }

    public async moveAssets(idsToMove: ReadonlyArray<MoveAssetOption>): Promise<void> {
        this.assetsToMove.push(...idsToMove);
        return this.startOrReturnActivePromise();
    }

    private async startOrReturnActivePromise() {
        if (!this.loopPromise) {
            this.loopPromise = this.loop();
        }
        return this.loopPromise;
    }

    private async loop() {
        const assetsToProcess = ASSETS_TO_PROCESS_IN_BATCH;
        // At the start, update the state
        this.syncState();

        // Keep track of the failures
        const failures: Map<MoveAssetOption, unknown> = new Map();
        // If we have more assets
        while (this.assetsToMove.length > 0) {
            const batch: MoveAssetOption[] = [];
            // Take the next batch of assets
            for (let i = 0; i < assetsToProcess; i++) {
                const next = this.assetsToMove.shift();
                if (next) {
                    batch.push(next);
                }
            }
            try {
                // eslint-disable-next-line no-await-in-loop
                await this.options.executeMove(batch, !(this.assetsToMove.length > 0));
            } catch (error) {
                // Capture any failures
                Sentry.captureException(error);
                for (const item of batch) {
                    failures.set(item, error);
                }
            }
            // Finally update the state with how many are still to be processed
            this.syncState();
        }
        this.loopPromise = null;
    }

    private syncState() {
        this.options.onStateChange({
            assetsLeftToMove: this.assetsToMove.length,
        });
    }
}
