import {
    actualComponent,
    OptionalExpressionBinding,
    OptionalOneWayBinding,
    OptionalStringBinding,
    TwoWayBinding,
} from '../angularData';
import { DsTextarea } from './controller';

export const dsTextareaSID = 'dsTextarea';
export const dsTextareaConfig = actualComponent(DsTextarea, '/partials/common/dsTextarea/template', {
    characterCounter: OptionalExpressionBinding,
    datacy: OptionalStringBinding,
    enableEmojis: OptionalOneWayBinding,
    helpText: OptionalOneWayBinding,
    invalidHelpText: OptionalOneWayBinding,
    isValid: OptionalOneWayBinding,
    label: OptionalOneWayBinding,
    maxCharacter: OptionalOneWayBinding,
    ngModel: TwoWayBinding,
    required: OptionalOneWayBinding,
    rows: OptionalOneWayBinding,
});

angular.module('app').component(dsTextareaSID, dsTextareaConfig);
