import { Roles, User } from '@deltasierra/shared';
import * as React from 'react';
import { useAngularServiceContext } from './angularServiceContexts';

/**
 * A hook to set the active tab for the current route.
 * Setting an active tab will add an active/selected styling to the tab in the main navbar of the app.
 *
 * @param activeTab - a string representing the name of the tab to set to active
 */
export function useSetRouteActiveTab(activeTab: string): void {
    const $route = useAngularServiceContext('$route');
    const $timeout = useAngularServiceContext('$timeout');
    React.useEffect(() => {
        const $promise = $timeout(() => {
            $route.current.activeTab = activeTab;
        });
        return () => {
            $timeout.cancel($promise);
        };
    }, [$route, $timeout, activeTab]);
}

export function useCurrentUser(): User | undefined {
    const mvIdentity = useAngularServiceContext('mvIdentity');
    return React.useMemo(() => mvIdentity.currentUser, [mvIdentity.currentUser]);
}

export function useHasAuthRoles(...roles: Roles[]): boolean {
    const user = useCurrentUser();

    if (!user) {
        return false;
    }

    return roles.every(role => user.roles.find(currRole => currRole === role));
}

export interface AuthRoles {
    isAdmin: boolean;
    isAnalyst: boolean;
    isBuilder: boolean;
    isClient: boolean;
    isManager: boolean;
    isSpecialRequester: boolean;
}

export function useAuthRoles(): AuthRoles {
    return {
        isAdmin: useHasAuthRoles(Roles.admin),
        isAnalyst: useHasAuthRoles(Roles.analyst),
        isBuilder: useHasAuthRoles(Roles.builder),
        isClient: useHasAuthRoles(Roles.client),
        isManager: useHasAuthRoles(Roles.manager),
        isSpecialRequester: useHasAuthRoles(Roles.specialRequester),
    };
}
