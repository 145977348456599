/// <reference path="../../../../typings/browser.d.ts" />
import {
    AgencyId,
    ClientId,
    LocationCategoryGroupView,
    LocationCategoryOptionView,
    LocationId,
    LocationPickerDto,
} from '@deltasierra/shared';
import {
    $kookiesSID,
    $scopeSID,
    actualComponent,
    ExpressionBinding,
    IKookies,
    ILifecycleHooks,
    OptionalExpressionBinding,
    OptionalOneWayBinding,
    OptionalTwoWayBinding,
} from '../../common/angularData';
import { DataUtils, SortedEntry } from '../../common/dataUtils';
import { InteractionUtils } from '../../common/interactionUtils';
import { MvNotifier } from '../../common/mvNotifier';
import { MvLocation } from '../../locations/mvLocation';
import {
    MvLocationCategoryOptionsResource,
    mvLocationCategoryOptionsResourceSID,
} from '../../locations/mvLocationCategoryOptionsResource';
import { BaseLocationPicker, LocationPickerView } from './locationPickerCommon';
import IAngularEvent = angular.IAngularEvent;
import IScope = angular.IScope;

export const REFRESH_LOCATIONS_EVENT_NAME = 'multiLocationPickerRefresh';

export interface LocationEntry extends LocationPickerDto {
    selected?: boolean;
    disabled?: boolean;
}

type DisableFilter = (locals: { location: LocationEntry }) => boolean;

interface ChangeLocals {
    locations: LocationPickerDto[];
}

interface FilteredCategoryGroup extends SortedEntry<LocationEntry[]> {
    isAllSelected?: boolean;
}

export class MultipleLocationPickerCtrl extends BaseLocationPicker implements ILifecycleHooks {
    // Props
    public readonly change!: (locals: ChangeLocals) => void;

    public readonly restrictToAgencyId?: AgencyId;

    public readonly restrictToClientId?: () => ClientId;

    public readonly excludeLocationId?: () => LocationId;

    public readonly preselectedLocations!: () => Array<LocationEntry | number>;

    public readonly disableFilter?: DisableFilter;

    public disabledLocations?: LocationEntry[];

    // State
    public locations?: LocationEntry[];

    public categories?: LocationCategoryGroupView[];

    public filteredEntries?: FilteredCategoryGroup[];

    public filteredEntriesByCategory?: FilteredCategoryGroup[];

    public readonly fetchCategories = this.interactionUtils.createFuture('fetch categories', async () =>
        this.mvLocationCategoryOptionsResource
            .queryAssigned()
            .$promise.then(categories => {
                if (this.restrictToClientId && this.restrictToClientId()) {
                    for (const category of categories) {
                        category.categoryOptions = this.filterByClientId(
                            category.categoryOptions || [],
                            this.restrictToClientId(),
                        );
                    }
                }
                this.categories = categories;
            })
            .catch(() => {
                this.resetView();
            }),
    );


    public static readonly $inject: string[] = [
        DataUtils.SID,
        $scopeSID,
        $kookiesSID,
        MvLocation.SID,
        mvLocationCategoryOptionsResourceSID,
        MvNotifier.SID,
        InteractionUtils.SID,
    ];


    public constructor(
        protected readonly dataUtils: DataUtils,
        protected readonly $scope: IScope,
        protected readonly $kookies: IKookies,
        protected readonly mvLocation: MvLocation,
        protected readonly mvLocationCategoryOptionsResource: MvLocationCategoryOptionsResource,
        protected readonly mvNotifier: MvNotifier,
        protected readonly interactionUtils: InteractionUtils,
    ) {
        super(dataUtils);
    }

    public createLocationFilterGroup = <T extends LocationEntry>(
        groupEntry: SortedEntry<T[]>,
        entries: T[],
    ): SortedEntry<T[]> => {
        const newGroup = {
            isAllSelected: false,
            key: groupEntry.key,
            value: entries,
        };
        newGroup.isAllSelected = this.areLocationsSelectedForGroupEntry(newGroup);
        return newGroup;
    };

    public createLocationCategoryOptionFilterGroup = <T extends LocationCategoryOptionView>(
        groupEntry: SortedEntry<T[]>,
        entries: T[],
    ): SortedEntry<T[]> => {
        const newGroup = {
            isAllSelected: false,
            key: groupEntry.key,
            value: entries,
        };
        /*
         * Don't do this, because Location Category Options don't have any locations
         * newGroup.isAllSelected = this.areLocationsSelectedForGroupEntry(newGroup);
         */
        return newGroup;
    };

    public $onInit(): void {
        void this.mvLocation
            .getSimpleAssignedLocations()
            .then((data: LocationPickerDto[]) => {
                let filteredData = data;
                if (this.restrictToAgencyId) {
                    filteredData = this.filterByAgencyId(filteredData, this.restrictToAgencyId);
                }
                if (this.restrictToClientId && this.restrictToClientId()) {
                    filteredData = this.filterByClientId(filteredData, this.restrictToClientId());
                }
                if (this.excludeLocationId && this.excludeLocationId()) {
                    filteredData = this.excludeByLocationId(filteredData, this.excludeLocationId());
                }
                if (this.disableFilter) {
                    const disabledLocations = this.disableLocations(filteredData, this.disableFilter);
                    if (this.disabledLocations) {
                        this.disabledLocations = disabledLocations;
                    }
                }
                if (this.preselectedLocations && this.preselectedLocations()) {
                    this.selectPreselectedLocations(filteredData, this.preselectedLocations());
                }
                this.locations = filteredData;
                this.initFiltering();
                if (this.preselectedLocations && this.preselectedLocations()) {
                    this.calculateIsAllSelected();
                    this.onLocationSelected();
                }
            })
            .catch(data => {
                this.mvNotifier.unexpectedErrorWithData('Failed to retrieve assigned locations', data);
            })
            .then(() => {
                this.listenToRefreshEvent();
            });
    }

    public resetView(): void {
        super.resetView();
        this.calculateIsAllSelected();
    }

    public async browse(): Promise<unknown> {
        // TODO: de-duplicate this from the LocationPickerCtrl
        this.view = LocationPickerView.Categories;
        if (!this.categoryGroups) {
            return this.fetchCategories.run({});
        }
        return Promise.resolve();
    }

    public toggleLocationsForGroupEntry(groupEntry: FilteredCategoryGroup): void {
        let numSelected = 0;
        for (const location of groupEntry.value) {
            if (!location.selected && !location.disabled) {
                location.selected = true;
                numSelected++;
            }
        }
        if (numSelected === 0) {
            for (const location of groupEntry.value) {
                location.selected = false;
            }
        }
        this.onLocationSelected(groupEntry);
    }

    public areLocationsSelectedForGroupEntry(groupEntry: FilteredCategoryGroup): boolean {
        if (!groupEntry) {
            return false;
        }

        const locations: LocationEntry[] = groupEntry.value;
        const someNotSelected = locations.some(x => !x.selected && !x.disabled);
        if (someNotSelected) {
            return false;
        }

        const allDisabled = locations.every(x => !!x.disabled);
        if (allDisabled) {
            return false;
        }

        return true;
    }

    public updateLocation(groupEntry: FilteredCategoryGroup, location: LocationEntry): void {
        this.onLocationSelected(groupEntry);
    }

    public toggleLocation(event: IAngularEvent, groupEntry: FilteredCategoryGroup, location: LocationEntry): void {
        location.selected = !location.selected;
        this.updateLocation(groupEntry, location);
    }

    public goBack(): void {
        if (this.view === LocationPickerView.Categories) {
            this.resetView();
        } else if (this.view === LocationPickerView.CategoryOptions) {
            this.browse();
        } else if (this.view === LocationPickerView.CategoryEntries) {
            this.view = LocationPickerView.CategoryOptions;
        }
    }

    private listenToRefreshEvent() {
        this.$scope.$on(REFRESH_LOCATIONS_EVENT_NAME, () => {
            if (this.disableFilter) {
                const disabledLocations = this.disableLocations(this.locations || [], this.disableFilter);
                if (this.disabledLocations) {
                    this.disabledLocations = disabledLocations;
                }
            }
            this.onLocationSelected();
            this.calculateIsAllSelected();
        });
    }

    private filterByClientId<T extends { clientId: ClientId }>(locations: T[], clientId: ClientId): T[] {
        return this.dataUtils.filterBy('clientId', locations, clientId);
    }

    private excludeByLocationId(locations: LocationEntry[], locationId: LocationId) {
        return this.dataUtils.excludeBy('locationId', locations, locationId);
    }

    private selectPreselectedLocations(
        locations: LocationEntry[],
        preselectedLocations: Array<LocationEntry | number>,
    ) {
        if (preselectedLocations && preselectedLocations.length > 0) {
            let locationIds;
            if (typeof preselectedLocations[0] === 'number') {
                locationIds = preselectedLocations;
            } else {
                locationIds = this.dataUtils.pluckByGetter(
                    entry => entry.locationId,
                    preselectedLocations as LocationEntry[],
                );
            }
            for (const location of locations) {
                const isSelected = locationIds.indexOf(location.locationId) > -1;
                location.selected = isSelected && !location.disabled;
            }
        }
    }

    private disableLocations(locations: LocationEntry[], disableFilter: DisableFilter) {
        const disabledLocations = [];
        for (const location of locations) {
            location.disabled = !disableFilter({ location });
            if (location.disabled) {
                location.selected = false;
                disabledLocations.push(location);
            }
        }
        return disabledLocations;
    }

    private calculateIsAllSelected() {
        if (this.filteredEntries) {
            for (const categoryGroup of this.filteredEntries) {
                categoryGroup.isAllSelected = this.areLocationsSelectedForGroupEntry(categoryGroup);
            }
        }
    }

    private filterSelectedLocations() {
        return this.dataUtils.filterBy('selected', this.locations || [], true);
    }

    private onLocationSelected(groupEntry?: FilteredCategoryGroup) {
        if (groupEntry) {
            groupEntry.isAllSelected = this.areLocationsSelectedForGroupEntry(groupEntry);
        }
        const selectedLocations = this.filterSelectedLocations();
        this.change({ locations: selectedLocations });
    }
}

export const multipleLocationPickerSID = 'multipleLocationPicker';
export const multipleLocationPickerConfig = actualComponent(
    MultipleLocationPickerCtrl,
    '/partials/directives/locationPicker/multipleLocationPicker',
    {
        change: ExpressionBinding,
        disableFilter: OptionalExpressionBinding,
        disabledLocations: OptionalTwoWayBinding,
        excludeLocationId: OptionalExpressionBinding,
        preselectedLocations: ExpressionBinding,
        restrictToAgencyId: OptionalOneWayBinding,
        restrictToClientId: OptionalExpressionBinding,
    },
);

angular.module('app').component(multipleLocationPickerSID, multipleLocationPickerConfig);
