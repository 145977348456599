/**
 * Takes a string, seperates by the character parameter, then returns an array of the non-empty parts
 *
 * @param str - The string with possible hashtags in it
 * @param char - The character to seperate it with
 * @returns An array containing the non-empty parts of the split string.
 */
export function splitStringWithCharacterRemoveEmpty(str: string, char: string): string[] {
    const parts = str
        .split(char)
        .map(part => part.trim())
        .filter(part => part.length !== 0);
    return parts;
}
