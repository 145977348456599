/// <reference path="../../../../typings/browser.d.ts" />
import { ExpressionBinding, OptionalOneWayBinding, simpleComponent } from '../../common/angularData';
import { EditableFieldsPickerController } from './editableFieldsPickerController';

export const EditableFieldsPickerSID = 'dsBuilderTemplateGalleryEditableFieldsPicker';

export const EditableFieldsPickerConfig = simpleComponent(
    EditableFieldsPickerController,
    'partials/templateGallery/editableFieldsPicker/editableFieldsPickerTemplate',
    {
        change: ExpressionBinding,
        preselectedEditableFields: OptionalOneWayBinding,
    },
);

angular.module('app').directive(EditableFieldsPickerSID, [() => EditableFieldsPickerConfig]);
