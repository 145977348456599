import * as React from 'react';
import { CurrentLocationRouteDispatchContext } from '../contexts';
import { useCurrentLocationLocalStorageDispatch } from './useCurrentLocationStorageDispatch';

/**
 * Use the current client dispatch function from the combined contexts
 *
 * @returns The current client dispatch update
 */
export function useCurrentLocationDispatch(): ReturnType<typeof useCurrentLocationLocalStorageDispatch> {
    const dispatchToLocalStorageContext = useCurrentLocationLocalStorageDispatch();
    const dispatchToRouteContext = React.useContext(CurrentLocationRouteDispatchContext);
    return newValue => {
        dispatchToLocalStorageContext(newValue);
        dispatchToRouteContext?.(newValue);
    };
}
