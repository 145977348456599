import { ControlledDateRangePicker, DSFormControlLabel, DSGrid, DSRadio } from '@deltasierra/components';
import { FormikProps } from 'formik';
import React from 'react';
import { t } from '@deltasierra/shared';
import { StyledClubReadyRadioGroup, StyledDSAlert } from '../../../../common';
import { PublishToClubReadyStep1FormValues } from '../../../types/publish-to-club-ready-step-1-form-values';
import { ClubReadyListOptionBase } from './ClubReadyListOptionBase';

type ClubReadyProspectsListOptionProps = {
    formik: FormikProps<PublishToClubReadyStep1FormValues>;
};

export function ClubReadyProspectsListOption({ formik }: ClubReadyProspectsListOptionProps): JSX.Element {
    return (
        <ClubReadyListOptionBase formik={formik} listKey="prospects" listName={t('BUILD.PUBLISH.CLUB_READY.PROSPECTS')}>
            <StyledClubReadyRadioGroup
                value={formik.values.prospects.listChoice}
                onChange={value =>
                    formik.setFieldValue('prospects', {
                        ...formik.values.prospects,
                        listChoice: value.target.value as 'addedBetween' | 'all',
                    })
                }
            >
                <DSFormControlLabel
                    control={<DSRadio />}
                    label={t('BUILD.PUBLISH.CLUB_READY.ADDED_AT_ANY_TIME')}
                    value="all"
                />
                <DSGrid container justifyContent="space-between">
                    <DSGrid item>
                        <DSFormControlLabel
                            control={<DSRadio />}
                            label={t('BUILD.PUBLISH.CLUB_READY.ADDED_BETWEEN')}
                            value="addedBetween"
                        />
                    </DSGrid>
                    <DSGrid item>
                        <ControlledDateRangePicker
                            initialFrom={formik.values.prospects.from ?? undefined}
                            initialTo={formik.values.prospects.to ?? undefined}
                            shouldReportErrorOnToAfterFrom
                            style={{ maxWidth: '500px' }}
                            type="date"
                            onChange={({ from, to }) => {
                                formik.setFieldValue('prospects', {
                                    ...formik.values.prospects,
                                    from: from ?? null,
                                    listChoice: 'addedBetween',
                                    to: to ?? null,
                                });
                            }}
                        />
                    </DSGrid>
                </DSGrid>
                {formik.errors.prospects && <StyledDSAlert severity="error">{formik.errors.prospects}</StyledDSAlert>}
            </StyledClubReadyRadioGroup>
        </ClubReadyListOptionBase>
    );
}
ClubReadyProspectsListOption.displayName = 'ClubReadyProspectsListOption';
