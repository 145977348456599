import type { IPromise, IQService } from 'angular';
import { VideoOrientation } from '@deltasierra/shared';
import { $qSID } from '../common/angularData';
import { I18nService } from '../i18n';
import { SentryService } from '../common';
import { FileCache } from './imageLoaderService';

export class VideoCache {
    private videos: { [key: string]: LoadedVideo } = {};

    public get(key: string): LoadedVideo {
        return this.videos[key];
    }

    public set(key: string, value: LoadedVideo): void {
        this.videos[key] = value;
    }

    public remove(key: string): void {
        delete this.videos[key];
    }

    public clear(): void {
        this.videos = {};
    }
}

export interface LoadedVideo {
    element: HTMLVideoElement;
    frame: HTMLVideoElement;
    orientation?: VideoOrientation;

    // Some loaded video info
    aspectRatio: number;
    width: number;
    height: number;
    durationMs: number;
}

export class VideoLoaderService {
    public static SID = 'videoLoaderService';

    public static readonly $inject: string[] = [$qSID, I18nService.SID, SentryService.SID];

    public constructor(
        private readonly $q: IQService,
        private readonly i18nService: I18nService,
        private readonly sentryService: SentryService,
    ) {}

    public extractFrame(video: HTMLVideoElement): HTMLCanvasElement {
        const canvas = document.createElement('canvas');
        const width = video.videoWidth;
        const height = video.videoHeight;
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        if (!ctx) {
            throw new Error('Could not created canvas 2D context');
        }
        ctx.drawImage(video, 0, 0);
        return canvas;
    }

    public loadVideoFromFile(videoCache: VideoCache, fileCache: FileCache, file: File): IPromise<LoadedVideo> {
        const URL = (window as { webkitURL?: typeof window.URL }).webkitURL || window.URL;
        const url = URL.createObjectURL(file);
        fileCache.set(url, file);
        return this.loadVideoFromUrl(videoCache, url, true);
    }

    public loadVideoFromUrl(videoCache: VideoCache, url: string, isLocal = false): IPromise<LoadedVideo> {
        return this.createVideoElementAndWaitForLoad(url, isLocal).then(videoElement => {
            const width = videoElement.videoWidth;
            const height = videoElement.videoHeight;

            const loadedVideo: LoadedVideo = {
                aspectRatio: width / height,
                durationMs: videoElement.duration * 1000,
                element: videoElement,
                frame: videoElement,
                height,
                width,
            };

            videoCache.set(url, loadedVideo);
            return loadedVideo;
        });
    }

    private createVideoElementAndWaitForLoad(url: string, isLocal = false): IPromise<HTMLVideoElement> {
        // Work around Chrome devtools warning: "IntersectionObserver.observe(target): target element is not a descendant of root"
        const videoElement: HTMLVideoElement = document.createElement('video');

        // Video.style.display = 'none';
        document.getElementsByTagName('body').item(0)!.appendChild(videoElement); // Need to remove the element when we're done...

        const deferred = this.$q.defer<HTMLVideoElement>();

        videoElement.addEventListener('loadeddata', () => {
            if (videoElement.videoWidth === 0 || videoElement.videoHeight === 0) {
                // DS-1923: some videos give 0 width/height.
                deferred.reject(new Error(this.i18nService.text.errors.invalidInputErrors.zeroWidthOrHeightVideo()));
            } else {
                deferred.resolve(videoElement);
            }
        });

        videoElement.addEventListener('error', () => {
            /*
             * Retrieve the latest error from the video element.
             *
             * @see https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/error
             */
            const error = videoElement.error;
            this.sentryService.captureException('Failed to load video', {
                error: videoElement.error?.code,
                message: videoElement.error?.message,
            });

            /*
             * @see https://developer.mozilla.org/en-US/docs/Web/API/MediaError/code
             */
            if (error?.code === MediaError.MEDIA_ERR_ABORTED) {
                deferred.reject(new Error(this.i18nService.text.errors.videoImport.networkError()));
            } else if (error?.code === MediaError.MEDIA_ERR_NETWORK) {
                deferred.reject(new Error(this.i18nService.text.errors.videoImport.networkError()));
            } else if (error?.code === MediaError.MEDIA_ERR_DECODE) {
                deferred.reject(new Error(this.i18nService.text.errors.videoImport.unsupportedVideoType()));
            } else if (error?.code === MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED) {
                deferred.reject(new Error(this.i18nService.text.errors.videoImport.unsupportedFileType()));
            } else {
                deferred.reject(new Error(this.i18nService.text.errors.videoImport.unknownError()));
            }
        });
        if (isLocal) {
            videoElement.src = url;
        } else {
            videoElement.crossOrigin = 'use-credentials';
            videoElement.src = `${url}?d=${Date.now()}`;
        }
        videoElement.muted = true;
        videoElement.load();

        return deferred.promise
            .then(() => this.loadInitialFrame(videoElement))
            .then(() => {
                // Once the video is loaded, remove it from the DOM
                // Work around Chrome devtools warning: "IntersectionObserver.observe(target): target element is not a descendant of root"
                document.getElementsByTagName('body').item(0)?.removeChild(videoElement);

                return videoElement;
            });
    }

    private loadInitialFrame(video: HTMLVideoElement): IPromise<void> {
        const deferred = this.$q.defer<void>();

        const listener = () => {
            video.removeEventListener('play', listener);
            video.removeEventListener('error', listener);
            video.pause();
            deferred.resolve();
        };

        video.addEventListener('play', listener);
        video.addEventListener('error', listener);

        void video.play();

        return deferred.promise;
    }
}

angular.module('app').service(VideoLoaderService.SID, VideoLoaderService);
