import { gql } from '@apollo/client';

export const TOGGLE_PUBLISH_STATUS = gql`
    mutation ToggleTemplateStatus($input: SaveTemplateInput!) {
        saveTemplate(input: $input) {
            ... on SaveTemplateSuccess {
                buildableTemplate {
                    id
                    status
                }
                __typename
            }
            ... on SaveTemplateError {
                code
                message
            }
            __typename
        }
    }
`;