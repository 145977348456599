import { DSGrid } from '@deltasierra/components';
import { AssignedLocation } from '@deltasierra/shared';
import React from 'react';
import { useAngularComponentRenderer, useAngularScope } from '../../../../common/componentUtils/angularRendering';

type SharedProps = {
    location: AssignedLocation | null;
};

type ScopeType = {
    location: AssignedLocation | null;
};

function PerformanceSummary({ location }: SharedProps): JSX.Element {
    const scope = useAngularScope<ScopeType>({ location });
    return useAngularComponentRenderer('<ds-performance-summary location="location" />', scope);
}
PerformanceSummary.displayName = 'PerformanceSummary';

function SuggestedTemplates({ location }: SharedProps): JSX.Element {
    const scope = useAngularScope<ScopeType>({ location });
    return useAngularComponentRenderer('<ds-suggested-templates location="location" />', scope);
}
SuggestedTemplates.displayName = 'SuggestedTemplates';

function LatestWebinar({ location }: SharedProps): JSX.Element {
    const scope = useAngularScope<ScopeType>({ location });
    return useAngularComponentRenderer('<ds-latest-webinar location="location" />', scope);
}
LatestWebinar.displayName = 'LatestWebinar';

function UpcomingEvents({ location }: SharedProps): JSX.Element {
    const scope = useAngularScope<ScopeType>({ location });
    return useAngularComponentRenderer('<ds-upcoming-events location="location" />', scope);
}
UpcomingEvents.displayName = 'UpcomingEvents';

export type OldLocationDashboardProps = {
    location: AssignedLocation;
};

export function OldLocationDashboard({ location }: OldLocationDashboardProps): JSX.Element {
    const [fixedLocation, setFixedLocation] = React.useState<AssignedLocation | null>(null);
    React.useEffect(() => {
        // Something funky in the angular controllers is breaking the rendering, without this
        // The angular components render empty
        const timeout = setTimeout(() => setFixedLocation(location), 200);
        return () => {
            clearTimeout(timeout);
        };
    }, [location]);
    return (
        <DSGrid container spacing={4}>
            <DSGrid className="main-content" item md={9}>
                <PerformanceSummary location={fixedLocation} />
                <SuggestedTemplates location={fixedLocation} />
            </DSGrid>
            <DSGrid className="side-content" item md={3}>
                <LatestWebinar location={fixedLocation} />
                <UpcomingEvents location={fixedLocation} />
            </DSGrid>
        </DSGrid>
    );
}
OldLocationDashboard.displayName = 'OldLocationDashboard';
