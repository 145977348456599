/// <reference path="../../../typings/browser.d.ts" />
import { getMimeType, Untyped } from '@deltasierra/shared';
import { ModalInstance, ModalService } from '../typings/angularUIBootstrap/modalService';
import { $httpSID, $qSID } from './angularData';
import { $modalSID } from './angularUIBootstrapData';
import { getData } from './httpUtils';
import IPromise = angular.IPromise;
import IQResolveReject = angular.IQResolveReject;


export interface IFileNameParseResult {
    fileName: string;

    dir: string;
    name: string;
    ext: string;
}


export interface IFileNameParseOptions {
    pathSeparator: string;
}

enum ScriptLoadingState {
    Downloading,
    Loading,
}

export class FileUtils {
    public static SID = 'fileUtils';

    public static readonly $inject: string[] = [$qSID, $httpSID, $modalSID];

    public imageMimeTypes: ReadonlyArray<string> = Object.freeze(['image/gif', 'image/jpeg', 'image/png']);

    public imageFileTypes: ReadonlyArray<string> = Object.freeze(['png', 'jpeg', 'jpg', 'gif']);

    private scriptStates: { [url: string]: ScriptLoadingState } = {};

    public constructor(
        private readonly $q: ng.IQService,
        private readonly $http: ng.IHttpService,
        private readonly $modal: ModalService,
    ) {}

    public isImageMimeType(mimeType: string | null | undefined): boolean {
        return this.imageMimeTypes.indexOf((mimeType || '').toLowerCase()) > -1;
    }

    public parseFileName(fileName: string, options?: IFileNameParseOptions): IFileNameParseResult {
        const fileNameoOptions = options || { pathSeparator: '' };

        const pathSeparatorIndex = fileNameoOptions.pathSeparator
            ? fileName.lastIndexOf(fileNameoOptions.pathSeparator)
            : Math.max(fileName.lastIndexOf('/'), fileName.lastIndexOf('\\'));

        const nameAndExtension = fileName.substr(pathSeparatorIndex + 1);

        const extensionIndex = nameAndExtension.lastIndexOf('.');

        // '.gitignore' will return '' as the extension (this is how node's path class works too)

        const result = {
            dir: fileName.substr(0, pathSeparatorIndex),
            ext: extensionIndex > 0 ? nameAndExtension.substr(extensionIndex) : '',
            fileName,
            name: extensionIndex > 0 ? nameAndExtension.substr(0, extensionIndex) : nameAndExtension,
        };

        return result;
    }

    /**
     * Joins multiple paths safely inserting path separators as needed
     *
     * @param paths - string[]
     * @param pathSeparator - string
     * @returns string of joined paths
     */
    public joinPaths(paths: string[], pathSeparator = '/'): string {
        let result = '';
        for (const path of paths) {
            if (result !== '') {
                if (result[result.length] !== pathSeparator) {
                    result += pathSeparator;
                }
            }
            result += path;
        }
        return result;
    }

    /**
     * Accepts a filename and returns the file extension (including the period)
     *
     * @param fileName - string
     * @returns file extension
     */
    public getExtension(fileName: string): string {
        return this.parseFileName(fileName).ext;
    }

    /**
     * Returns an array with two elements:
     * The first element is the path and filename excluding the extension
     * The second element is just the extension (including the period)
     *
     * @param fileName - string
     * @returns array with filename and extension
     */
    public splitExtension(fileName: string): string[] {
        const result = this.parseFileName(fileName);
        return [result.name, result.ext];
    }

    /**
     * Accepts a filename and returns the path and filename excluding the extension
     *
     * @param fileName - string
     * @returns - filename without extension
     */
    public removeExtension(fileName: string): string {
        const result = this.parseFileName(fileName);
        return this.joinPaths([result.dir, result.name]);
    }

    /**
     * Accepts a full filename and returns just the filename and extension excluding the path
     *
     * @param fileName - string
     * @param pathSeparator - string
     * @returns - filename excluding the path
     */
    public removePath(fileName: string, pathSeparator?: string): string {
        const result = this.parseFileName(fileName);
        return result.name + result.ext;
    }

    /**
     * Accepts a filename or extension and returns the mime type for that extension
     * Limited supported MimeTypes
     *
     * @param fileNameOrExtension - string
     * @returns - MIME type from filename or extension
     */
    public getMimeType(fileNameOrExtension: string): string {
        // Get the extension without the period
        const extension = this.parseFileName(fileNameOrExtension).ext.substr(1).toLowerCase();

        return getMimeType(extension, '');
    }

    /**
     * Accepts a filename and returns whether the file or one of its parent directories is hidden
     *
     * @param fileName - string
     * @returns boolean
     */
    public isHidden(fileName: string): boolean {
        const components = fileName.split(/(\/|\\)/gi);
        return components.some(name => name.toLowerCase() === '__macosx' || name.lastIndexOf('.', 0) === 0);
    }

    /**
     * Accepts a file object, reads from it and returns a promise of the file as an array buffer
     *
     * @param file - File to convert to array buffer
     * @returns ng.IPromise<ArrayBuffer>
     */
    public fileToArrayBuffer(file: File): ng.IPromise<ArrayBuffer> {
        return this.$q((resolve: Untyped, reject: Untyped) => {
            const reader = new FileReader();
            let errored = false;
            reader.addEventListener('loadend', () => (errored ? null : resolve(reader.result)));
            reader.addEventListener('error', err => {
                errored = true;
                reject(err);
            });
            reader.readAsArrayBuffer(file);
        });
    }

    public isDownloadAttributeSupported(): boolean {
        const anchor = document.createElement('a');
        return anchor.download !== undefined;
    }

    /**
     * Will try to download a file instead of it opening inside the browser, as tends to happen with a pdf, image, video, etc.
     * Unfortunately, Safari does not support the HTML5 download attribute. Also, Safari's popup blocker is too tenatious, and
     * will sometime block the new window from opening.
     * So, we will fall back to showing a button, linking to the file.
     *
     * @param url - string
     * @param fileName - string
     * @returns IPromise<void>
     */
    public downloadFile(url: string, fileName: string): IPromise<void> {
        return this.$q<void>((resolve: IQResolveReject<void>, reject: IQResolveReject<void>) => {
            const canDownload = this.isDownloadAttributeSupported();

            // MS IE/Edge and Safari does not support this
            if (canDownload) {
                const anchor = document.createElement('a');
                anchor.download = fileName;
                anchor.href = url;
                anchor.rel = 'noopener noreferrer';
                // Anchor.target = '_blank'; // Don't set this, it opens the file in a new tab instead of downloading it
                anchor.style.display = 'none';

                document.body.appendChild(anchor);
                anchor.click();
                document.body.removeChild(anchor);
                return resolve();
            } else {
                this.$modal
                    .open({
                        backdrop: 'static',
                        controller: class DownloadLinkCtrl {
                            public static readonly $inject: string[] = ['$modalInstance', 'linkUrl'];

                            public constructor(
                                private readonly $modalInstance: ModalInstance,
                                public readonly linkUrl: string,
                            ) {}

                            public close() {
                                this.$modalInstance.close();
                            }
                        },
                        controllerAs: 'ctrl',
                        resolve: {
                            linkUrl: () => url,
                        },
                        templateUrl: '/partials/common/downloadLink',
                    })
                    .result.then(resolve, reject)
                    .catch(err => reject(err));
            }
            return resolve();
        });
    }

    /**
     * Downloads an asset, stores it in a blob, and returns
     *
     * @param url - string
     * @returns ng.IPromise<string>
     */
    public downloadToDataUrl(url: string): ng.IPromise<string> {
        return this.$q((resolve: Untyped, reject: Untyped) => {
            const xhr = new XMLHttpRequest();
            xhr.responseType = 'blob';

            xhr.addEventListener('error', event => reject((event as any).error || new Error(event.toString())));

            xhr.addEventListener('load', () => {
                const reader = new FileReader();

                reader.addEventListener('error', () => reject('Failed to download asset'));
                reader.addEventListener('loadend', () => resolve(reader.result));

                reader.readAsDataURL(xhr.response);
            });

            xhr.open('GET', url);
            xhr.send();
        });
    }

    public downloadToArrayBuffer(location: string): ng.IPromise<ArrayBuffer> {
        return this.$http
            .get<ArrayBuffer>(location, {
                responseType: 'arraybuffer',
                withCredentials: true,
            })
            .then(getData);
    }

    /**
     * Downloads and includes script on demand, returns a Promise that resolves once its loaded.
     * If script has already been loaded (via calling requireScript) the promise will resolve immediately.
     *
     * @param url - string
     * @param type - MIME type
     * @returns ng.IPromise<void>
     */
    public requireScript(url: string, type = 'text/javascript'): ng.IPromise<void> {
        return this.$q<void>((resolve: Untyped, reject: Untyped) => {
            const state = this.scriptStates[url];

            switch (state) {
                case undefined:
                    // eslint-disable-next-line no-case-declarations
                    const $script = angular.element('<script></script>');
                    // eslint-disable-next-line no-case-declarations
                    const script = $script[0] as HTMLScriptElement;

                    this.scriptStates[url] = ScriptLoadingState.Downloading;

                    angular.element('body:first-of-type').append(script);

                    $script.on('load', () => {
                        this.scriptStates[url] = ScriptLoadingState.Loading;
                        resolve();
                    });

                    script.type = type;
                    script.src = url;
                    break;

                case ScriptLoadingState.Downloading:
                    // eslint-disable-next-line no-case-declarations
                    const $existing = angular.element(`script[src="${url}"]`);
                    $existing.load(() => resolve());
                    break;

                case ScriptLoadingState.Loading:
                    resolve();
                    break;

                default:
                    throw new Error(`Unexpected ScriptLoadingState: ${state}`);
            }
        });
    }

    /**
     * Returns the height and width of an image given an image data URL.
     *
     * @param dataUrl - data URL string on an image
     * @returns An object containing the height and width of the image
     */
    public getImageDimensionsFromDataUrl(dataUrl: string): Promise<{ height: number; width: number }> {
        return new Promise(resolve => {
            const image = new Image();
            image.src = dataUrl;
            image.onload = () =>
                resolve({
                    height: image.height,
                    width: image.width,
                });
        });
    }

    /**
     * Returns the height and width of an image givne an image file.
     *
     * @param file - File of image
     * @returns An object containing the height and width of the image
     */
    public getImageDimensionsFromFile(file: File): Promise<{ height: number; width: number }> {
        return new Promise(resolve => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                resolve(this.getImageDimensionsFromDataUrl(reader.result as string));
            };
        });
    }
}

angular.module('app').service(FileUtils.SID, FileUtils);
