import { IScope } from 'angular';
import { $elementSID, $scopeSID, $timeoutSID, ExpressionCallback, ILifecycleHooks } from '../angularData';
import IAugmentedJQuery = angular.IAugmentedJQuery;
import ITimeoutService = angular.ITimeoutService;

export class DsTextarea implements ILifecycleHooks {
    // Props
    public ngModel!: string;

    public helpText?: string;

    public label!: string;

    public rows?: number;

    public enableEmojis?: boolean;

    public required?: boolean;

    public maxCharacter?: number;

    public characterCounter?: ExpressionCallback<{ text: string }, number>;

    public isValid?: boolean;

    public invalidHelpText?: string;

    public datacy?: string;

    // State
    public readonly requiredSymbol = '*';

    private textArea!: IAugmentedJQuery;

    private characterCount = 0;

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public static readonly $inject: string[] = [$scopeSID, $elementSID, $timeoutSID];

    public constructor(
        private readonly $scope: IScope,
        private readonly $element: IAugmentedJQuery,
        private $timeout: ITimeoutService,
    ) {}

    public $onInit(): void {
        this.textArea = this.$element.find('textarea');

        this.ngModel ||= '';
        this.$scope.$watch(
            () => this.ngModel,
            () => this.updateCharacterCount(),
        );
    }

    public onEmojiSelection(emoji: string): void {
        const element = this.textArea[0] as HTMLTextAreaElement;
        const start = this.ngModel.substring(0, element.selectionStart);
        const end = this.ngModel.substring(element.selectionEnd);
        this.ngModel = `${start}${emoji}${end}`;

        this.$timeout(() => {
            const focusIndex = start.length + emoji.length;
            element.focus();
            element.setSelectionRange(focusIndex, focusIndex);
        });
    }

    private updateCharacterCount(): void {
        this.characterCount = this.characterCounter
            ? this.characterCounter({ text: this.ngModel })
            : this.characterCount;
    }
}
