import { gql } from '@apollo/client';

export const GET_PLANNER_CONFIG = gql`
    query GetPlannerConfig {
        config {
            id
            features {
                planner {
                    linkTemplateAndCategory
                }
            }
        }
    }
`;
