import { InteractionUtils } from '../../../../common/interactionUtils';
import { PrintProviderApiClient } from '../../../../integration/auth/printProviderApiClient';
import {
    $qSID,
    actualComponent,
    ExpressionBinding,
    ILifecycleHooks,
    OneWayBinding,
} from '../../../../common/angularData';
import { BuilderConstants, builderConstantsSID } from '../../../builderConstants';
import { MvIdentity } from '../../../../account/mvIdentity';
import { PdfBuilderService } from '../../../print/pdfBuilderService';
import { ConfirmModal, confirmModalSID } from '../../../../common/confirmModal';
import { FileUtils } from '../../../../common/fileUtils';
import { I18nService } from '../../../../i18n';
import { UploadService } from '../../../../common/uploadService';
import { PrintPublishService } from '../../../../integration/publish/printPublishService';
import { CurrencyService } from '../../../../payments/currencyService';
import { MvLocation } from '../../../../locations/mvLocation';
import { DataUtils } from '../../../../common/dataUtils';
import { MvNotifier } from '../../../../common/mvNotifier';
import { PublishResultOptions } from '../../publishResult';
import { BasePrintPublishViews, NextViewMap } from '../basePublishPrintController';
import { BasePublishPrintSKUController, BaseSKUPrintPublishViews } from './basePublishPrintSKUController';

type SKUPrintPublishViews = 'Payment';

const publishPrintSKUNextViewMap: NextViewMap<BasePrintPublishViews | BaseSKUPrintPublishViews | SKUPrintPublishViews> = {
    Review: 'ReviewConfirmation',
    ReviewConfirmation: 'Details',
    Details: 'Summary',
    Summary: 'Publishing',
    Publishing: 'Payment',
    Payment: 'Payment',
};

export class PublishPrintSKUController extends BasePublishPrintSKUController<SKUPrintPublishViews> implements ILifecycleHooks {
    static readonly $inject: string[] = [
        $qSID,
        InteractionUtils.SID,
        DataUtils.SID,
        MvNotifier.SID,
        PrintProviderApiClient.SID,
        builderConstantsSID,
        MvIdentity.SID,
        PdfBuilderService.SID,
        confirmModalSID,
        FileUtils.SID,
        I18nService.SID,
        UploadService.SID,
        PrintPublishService.SID,
        CurrencyService.SID,
        MvLocation.SID,
    ];

    constructor(
        $q: ng.IQService,
        interactionUtils: InteractionUtils,
        dataUtils: DataUtils,
        mvNotifier: MvNotifier,
        printProviderApiClient: PrintProviderApiClient,
        builderConstants: BuilderConstants,
        identity: MvIdentity,
        pdfBuilderService: PdfBuilderService,
        modal: ConfirmModal,
        fileUtils: FileUtils,
        i18n: I18nService,
        uploadService: UploadService,
        printPublishService: PrintPublishService,
        currencyService: CurrencyService,
        mvLocation: MvLocation,
    ) {
        super(
            $q,
            interactionUtils,
            dataUtils,
            mvNotifier,
            printProviderApiClient,
            builderConstants,
            identity,
            pdfBuilderService,
            modal,
            fileUtils,
            i18n,
            uploadService,
            printPublishService,
            currencyService,
            mvLocation,
            publishPrintSKUNextViewMap,
        );
    }

    hasValidPaymentGateway(): boolean {
        return (
            this.skuDetails.paymentGateway === 'bluepay' &&
            this.skuDetails.paymentUrl != null
        );
    }

    protected onSent(options?: PublishResultOptions) {
        if (this.paymentGatewayInitialiser) {
            this.setView('Payment');
        } else {
            this.finishPublishing();
        }
    }

    invokePaymentGateway() {
        if (this.paymentGatewayInitialiser) {
            this.paymentGatewayInitialiser();
        }
        this.finishPublishing();
    }

    protected finishPublishing() {
        const options: PublishResultOptions = {
            extraInfo: this.getExtraPublishInfo(),
        };
        return super.onSent(options);
    }
}

// Use title case for Sku to avoid issues with unrecognized HTML tag
export const publishPrintSKUControllerSID = 'publishPrintSkuController';

export const publishPrintSKUControllerConfig: ng.IComponentOptions = actualComponent(
    PublishPrintSKUController,
    '/partials/contentBuilder/publish/print/sku/publishPrintSKU',
    {
        publishData: OneWayBinding,
        generatePrintPdf: ExpressionBinding,
        generateImageFileName: ExpressionBinding,
        onCancel: ExpressionBinding,
        publishPublished: ExpressionBinding,
        openPaymentWindow: ExpressionBinding,
    },
);

angular.module('app').component(publishPrintSKUControllerSID, publishPrintSKUControllerConfig);
